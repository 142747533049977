// Components
// import skintones from 'character-creator/colours/skin'
import HairColours from "character-creator/colours/hair";
import hairBackFullList from "character-creator/components/hair/back/full/HairBackFullList";
import hairBackLeftList from "character-creator/components/hair/back/left/HairBackLeftList";
import hairBackRightList from "character-creator/components/hair/back/right/HairBackRightList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

const hairBackPath = "hair.back.";

const HairBackPageConfig = {
  id: "hairBackPage",
  name: "Back",
  description: "",
  controls: [
    {
      id: "hairBackFull",
      name: "Full",
      description: "This will override the individual left/right options",
      dataAttribute: [hairBackPath + "full.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: hairBackFullList,
      colourControls: [
        {
          id: "hairBackFullColour",
          name: "Full colour",
          description: "",
          dataAttribute: [hairBackPath + "full.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
    },
    {
      id: "hairBackLeft",
      name: "Left style",
      description: "",
      dataAttribute: [hairBackPath + "left.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: hairBackLeftList,
      colourControls: [
        {
          id: "hairBackLeftColour",
          name: "Left colour",
          description: "",
          dataAttribute: [hairBackPath + "left.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
    },
    {
      id: "hairBackRight",
      name: "Right style",
      description: "",
      dataAttribute: [hairBackPath + "right.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: hairBackRightList,
      colourControls: [
        {
          id: "hairBackRightColour",
          name: "Right colour",
          description: "",
          dataAttribute: [hairBackPath + "right.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
    },
  ],
};

const HairBackPage = {
  id: "hairBackPage",
  name: "Back",
  description: "",
  content: <SubPage page={HairBackPageConfig} />,
};

export default HairBackPage;

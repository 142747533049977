import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import BeardList from "./BeardList";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import HairColours from "character-creator/colours/hair";
import { filterListById } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const defaultHairColour =
    getCharacterConfigValue(state, "hair.colour") ?? HairColours[0];
  const beardConfig = getCharacterConfigValue(state, "beard");
  let beardObject = null;

  if (ownProps.preview && ownProps.beard) {
    beardObject = ownProps.beard;
  } else if (beardConfig?.shape && beardConfig.shape !== "0") {
    beardObject = filterListById(BeardList, beardConfig.shape);
  }
  const colour = beardConfig?.colour ? beardConfig.colour : defaultHairColour;

  return {
    colour: colour,
    Beard: beardObject,
  };
}

const Beard = (props) => {
  const { colour, Beard, previewId } = props;

  if (Beard) {
    return (
      <g id={previewId ? `g-beard~${previewId}` : "g-beard"}>
        <ItemComponent
          id={previewId ? `beard~${previewId}` : "beard"}
          component={Beard}
          colour={colour}
        />
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Beard);

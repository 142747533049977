import React from "react";
import { connect } from "react-redux";
import {
  colourStyles,
  getGlobalStyle,
} from "character-creator/components/StyleOptions";
import { linearGradientFade } from "./GradientConfig";
import { filterListById } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  // Get the ID for the component, e.g. top_abc_sleeve, then add a previewID to it if necessary, e.g. top_abc_sleeve_123
  const id = ownProps.previewId
    ? ownProps.id + "_" + ownProps.previewId
    : ownProps.id;

  // Find out if there's a style override in place, e.g. user might have said for all clothes to be sepia. If not, check whether there's a global style set (e.g. greyscale), and get the associated
  const styleConfig = ownProps.styleOverride
    ? ownProps.styleOverride
    : getGlobalStyle(state);
  const styleConfigColour =
    styleConfig.colour !== "" &&
    filterListById(colourStyles, styleConfig.colour).style.colour;

  // Whether globalStyle enables and sets an outline. For colouring mode (currently named coloursBlank) this is enabled
  const outlineEnabled =
    styleConfig.colour === "coloursBlank" || styleConfig.outline;
  const globalStyleOutline =
    outlineEnabled && styleConfigColour?.outline
      ? styleConfigColour.outline
      : null;

  // Get the colours, defaulting to invisible
  let colour = { base: "hsla(0,0,0,0)" };
  // If shape is visible and there's a style override, use that
  if (!ownProps.invisible && styleConfigColour) {
    colour = styleConfigColour;
    // Otherwise if shape is visible and colours provided, use them
  } else if (!ownProps.invisible && ownProps.colour) {
    colour = ownProps.colour;
  }

  // Get the outline colour, defaulting to "none"
  let outlineColour = "none";
  // If outline enabled and style override, use style outline colour
  if (!ownProps.invisible && globalStyleOutline) {
    outlineColour = styleConfigColour.outline;
    // Otherwise use provided colours
  } else if (
    !ownProps.invisible &&
    outlineEnabled &&
    ownProps.colour &&
    ownProps.colour.outline
  ) {
    outlineColour = ownProps.colour.outline;
  }

  // Get the correct shading object from the config
  // Accounts for original shape object structure, but all will be written with all layers in "shape" object now
  let shapeLayers = ownProps.component.shape.basic
    ? { base: ownProps.component.shape.base, ...ownProps.component.shape.basic }
    : ownProps.component.shape;

  // Get shadow details
  let shadow = ownProps.component.shape.shadow;
  let shadowMask = ownProps.component.shadowMask;

  // Get mask details
  let componentMaskId = ownProps.component.maskId ?? "";
  let propMaskId = ownProps.maskId ?? "";
  let usedMaskId = componentMaskId ? componentMaskId : propMaskId;

  return {
    id,
    shadow,
    shadowMask,
    colour,
    shading: styleConfig.shading, // Whether shading is enabled for the overall style
    outline: ownProps.invisible ? "none" : globalStyleOutline ?? outlineColour,
    // strokeOverride,
    useClip:
      ownProps.component.useClip != null ? ownProps.component.useClip : true,
    maskId: usedMaskId ?? "",
    customStyle: ownProps.style,
    shapeLayers,
    transform: ownProps.transform ?? "",
    gradient:
      ownProps.gradient &&
      linearGradientFade(colour.base, ownProps.gradient, id),
  };
}

const Component = (props) => {
  const {
    id,
    outline,
    shading,
    shadow,
    shadowMask,
    shadowOnBase = false,
    useClip,
    maskId,
    colour,
    customStyle,
    shapeLayers,
    transform,
    preview,
    previewTransform,
    invisible,
    gradient,
  } = props;

  ///////////////////////////////////////////////////////
  // Maketh the thing!
  ////////////////////////////////////////////////////////
  const maskPathId = maskId !== "" ? `url(#${maskId})` : "none";

  const style = {
    mask: maskId ? maskPathId : "none",
    ...customStyle,
  };

  const baseMask = {
    mask: !useClip ? "none" : `url(#${id}_mask)`,
  };

  const transformProps = (!preview || previewTransform) &&
    (transform.position || transform.opacity || transform.scale) && {
      transform: transform.position,
      opacity: transform.opacity ?? 1,
      scale: transform.scale ?? 1,
    };

  const componentCode = (
    <g
      id={id}
      style={style}
      transform-box={"fill-box"}
      transform-origin="center center"
      {...transformProps}
    >
      {gradient && <defs>{gradient}</defs>}
      {useClip && (
        <g>
          <mask id={id + "_mask"} fill="white" fillRule="evenodd">
            {shapeLayers.clip ?? shapeLayers.base}
          </mask>
        </g>
      )}
      <g>
        {shadow && !shadowOnBase && (
          <g
            id={id + "_shadow"}
            fill={colour.dark}
            style={{ mask: `url(#${shadowMask})` }}
          >
            {shadow}
          </g>
        )}
        <g
          id={id + "_base"}
          fill={
            invisible ? "none" : gradient ? `url(#gradient_${id})` : colour.base
          }
          stroke={"none"}
          style={baseMask}
        >
          {shapeLayers.base}
        </g>
        {!invisible && shading && (
          <g data-name="shading-layers" style={baseMask} stroke={"none"}>
            {shapeLayers.light && (
              <g id={id + "_light"} fill={colour.light}>
                {shapeLayers.light}
              </g>
            )}
            {shapeLayers.lighter && (
              <g id={id + "_lighter"} fill={colour.lighter}>
                {shapeLayers.lighter}
              </g>
            )}
            {shapeLayers.dark && (
              <g id={id + "_dark"} fill={colour.dark}>
                {shapeLayers.dark}
                {shadowOnBase && shadow}
              </g>
            )}
            {shapeLayers.darker && (
              <g id={id + "_darker"} fill={colour.darker}>
                {shapeLayers.darker}
              </g>
            )}

            {outline && outline !== "none" && (
              <g id="outline" fill="none" stroke={outline} strokeWidth="2">
                {shapeLayers.base}
              </g>
            )}
          </g>
        )}
      </g>
    </g>
  );
  ////////////////////////////////////////////////////////
  // Returneth the thing!
  ////////////////////////////////////////////////////////
  return <>{componentCode}</>;
};

export default connect(mapStateToProps)(Component);

import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import hairMidRightList from "./right/HairMidRightList";
import hairMidLeftList from "./left/HairMidLeftList";
import hairMidTopList from "./top/HairMidTopList";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";
import HairColours from "character-creator/colours/hair";

function mapStateToProps(state, ownProps) {
  const hairConfig = getCharacterConfigValue(state, "hair.mid");

  const defaultHairColour =
    getCharacterConfigValue(state, "hair.colour") ?? HairColours[0];

  const hairMidColour = hairConfig?.colour
    ? hairConfig.colour
    : defaultHairColour;

  ////////////////////////
  // Left
  ////////////////////////
  const hairConfigLeft = hairConfig?.left;
  let hairObjectLeft = null;
  if (ownProps.preview) {
    hairObjectLeft = ownProps.hair.shape;
  } else if (hairConfigLeft?.shape && hairConfigLeft.shape !== "0") {
    hairObjectLeft = filterListById(
      hairMidLeftList,
      hairConfigLeft.shape
    ).shape;
  }

  const coloursLeft = hairConfigLeft?.colour
    ? hairConfigLeft.colour
    : hairMidColour;

  ////////////////////////
  // Right
  ////////////////////////
  const hairConfigRight = hairConfig?.right;
  let hairObjectRight = null;
  if (ownProps.preview) {
    hairObjectRight = ownProps.hair.shape;
  } else if (hairConfigRight?.shape && hairConfigRight.shape !== "0") {
    hairObjectRight = filterListById(
      hairMidRightList,
      hairConfigRight.shape
    ).shape;
  }

  const coloursRight = hairConfigRight?.colour
    ? hairConfigRight.colour
    : hairMidColour;

  ////////////////////////
  // Top
  ////////////////////////
  const hairConfigTop = hairConfig?.top;
  let hairObjectTop = null;
  if (ownProps.preview) {
    hairObjectTop = ownProps.hair.shape;
  } else if (hairConfigTop?.shape && hairConfigTop.shape !== "0") {
    hairObjectTop = filterListById(hairMidTopList, hairConfigTop.shape).shape;
  }

  const coloursTop = hairConfigTop?.colour
    ? hairConfigTop.colour
    : hairMidColour;

  return {
    left: {
      shape: hairObjectLeft,
      colour: coloursLeft,
    },
    right: {
      shape: hairObjectRight,
      colour: coloursRight,
    },
    top: {
      shape: hairObjectTop,
      colour: coloursTop,
    },
  };
}

const CharacterHairMid = (props) => {
  const { left, right, top, previewId } = props;

  if (top.shape || left.shape || right.shape) {
    return (
      <g id={previewId ? `g-hair-mid~${previewId}` : "g-hair-mid"}>
        {top.shape && (
          <ItemComponent
            id={previewId ? `hair-mid-top~${previewId}` : "hair-mid-top"}
            component={top}
            colour={top.colour}
            preview={previewId ? true : false}
          />
        )}
        {left.shape && (
          <ItemComponent
            id={previewId ? `hair-mid-left~${previewId}` : "hair-mid-left"}
            component={left}
            colour={left.colour}
            preview={previewId ? true : false}
          />
        )}
        {right.shape && (
          <ItemComponent
            id={previewId ? `hair-mid-right~${previewId}` : "hair-mid-right"}
            component={right}
            colour={right.colour}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(CharacterHairMid);

import React, { Fragment, useState } from "react";
import { connect, useDispatch } from "react-redux";
import SubSection from "./SubSection";
import { checkDependencies, SymmetryControl } from "../menu-helper";
import {
  getCharacterConfigValue,
  updateCharacterConfig,
} from "character-creator/utilities/storeHelpers";
import { BuildControlContainer } from "./controls/ControlContainer";

// const mapDispatchToProps = {
//   updateMenu,
// };

const mapStateToProps = (state, ownProps) => {
  // Is there a symmetry config item? If so, user can toggle
  const { section, symmetryConfigValue } = ownProps;

  const symmetryOption = section.symmetryAttribute && true;

  let symmetryOn = true;
  if (symmetryOption) {
    if (symmetryConfigValue) {
      symmetryOn = symmetryConfigValue;
    } else {
      const symmetryStateValue = getCharacterConfigValue(
        state,
        section.symmetryAttribute[0]
      );
      if (symmetryStateValue !== undefined) {
        symmetryOn = symmetryStateValue;
      }
    }
  }

  // If config option, get value, otherwise true
  // const symmetry = ownProps.symmetry;
  // Switch control list based on chosen symmetry option
  const hasControls = section.controls;
  let controlGroups = section.controls;

  if (section.controls.symmetrical) {
    if (symmetryOn) {
      controlGroups = section.controls.symmetrical;
    } else {
      controlGroups = section.controls.asymmetrical;
    }
  }

  // Is there a dependency?
  const dependenciesMet = section.dependencies
    ? !!checkDependencies(section.dependencies, state)
    : true;

  const dependencyMessage = !dependenciesMet && section.dependencyMessage;

  let sectionData = null;
  if (section.dataAttribute) {
    sectionData = getCharacterConfigValue(state, section.dataAttribute);
  }

  return {
    symmetryOption,
    symmetryOn,
    controlGroups: hasControls && controlGroups,
    dependenciesMet,
    dependencyMessage,
    sectionData,
  };
};

const Section = (props) => {
  const {
    section,
    symmetryOption,
    symmetryOn,
    controlGroups,
    dependenciesMet,
    dependencyMessage,
    sectionData,
    pageLevel,
  } = props;

  const dispatch = useDispatch();

  const updateSymmetry = (symmetryAttribute, updateValue = true) => {
    dispatch(
      updateCharacterConfig({
        attribute: symmetryAttribute,
        value: !symmetryOn,
      })
    );
  };

  const [isAccordionOpen, toggleAccordion] = useState(
    props.firstSection ? props.firstSection : false
  );

  // const BuildControlContainers =
  //   controlGroups && BuildControlContainer(controlGroups, sectionData, section);

  const BuildSubSections = (props) => {
    const { subSections } = props;
    return subSections.map((subSection, index) => {
      return (
        <Fragment key={index}>
          <SubSection
            subSection={subSection}
            showHeader={section.name !== subSection.name}
          />
        </Fragment>
      );
    });
  };

  const sectionActions = (
    <div className="actions">
      {symmetryOption && (
        <SymmetryControl
          controlId={section.id}
          symmetryValue={symmetryOn}
          symmetryAttribute={section.symmetryAttribute[0]}
          updateSymmetry={updateSymmetry}
        />
      )}
    </div>
  );

  const sectionContent = (
    <>
      {section.subSections && (
        <BuildSubSections subSections={section.subSections} />
      )}
      {section.controls && (
        <BuildControlContainer
          controlGroups={controlGroups}
          sectionData={sectionData}
          section={section}
          symmetry={symmetryOn}
        />
      )}
    </>
  );

  //   const classNamesContent = "subpage-section " + (isSubPageOpen? "selected":"hidden");

  return (
    <>
      <section id={section.id} className="subpage-section">
        {!pageLevel && (
          <div className="header" {...{ disabled: !dependenciesMet }}>
            <h3 data-name="section-name">{section.name}</h3>
            {!dependenciesMet && (
              <span className="dependency-message">{dependencyMessage}</span>
            )}
            {dependenciesMet && sectionActions}
            {section.description && (
              <p className="menu-description">{section.description}</p>
            )}
            <div
              className="accordion-button"
              aria-controls={"sectionContent~" + section.id}
              onClick={() => toggleAccordion(!isAccordionOpen)}
              role="button"
            >
              {isAccordionOpen ? `-` : `+`}
            </div>
          </div>
        )}

        <div
          id={"sectionContent~" + section.id}
          className="accordion-content"
          aria-expanded={pageLevel ? true : isAccordionOpen}
          {...{ hidden: pageLevel ? false : isAccordionOpen }}
        >
          {dependenciesMet && sectionContent}
          {!dependenciesMet && (
            <span className="dependency-message">{dependencyMessage}</span>
          )}
        </div>
      </section>
    </>
  );
};

export default connect(mapStateToProps)(Section);

import React from "react";
import { connect } from "react-redux";
import BackHornsList, { BackHornsListArray } from "./BackHornsList";
import ItemComponent from "character-creator/components/ItemComponent";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  // Config path
  // const backHornsConfig = getCharacterConfigValue(state, "horns.back");

  // Left
  const hornConfigLeft = getCharacterConfigValue(state, "horns.back.left");

  let hornObjectLeft = null;
  if (ownProps.preview && ownProps.horns.shape.left) {
    hornObjectLeft = ownProps.horns.shape.left;
  } else if (hornConfigLeft && hornConfigLeft.shape !== "0") {
    hornObjectLeft = filterListById(BackHornsListArray, hornConfigLeft.shape)
      .shape.left;
  }

  const coloursLeft =
    hornConfigLeft && hornConfigLeft.colour
      ? hornConfigLeft.colour
      : getCharacterConfigValue(state, "skintone");

  // Right
  const hornConfigRight = getCharacterConfigValue(state, "horns.back.right");

  let hornObjectRight = null;
  if (ownProps.preview && ownProps.horns.shape.right) {
    hornObjectRight = ownProps.horns.shape.right;
  } else if (hornConfigRight && hornConfigRight.shape !== "0") {
    hornObjectRight = filterListById(BackHornsListArray, hornConfigRight.shape)
      .shape.right;
  }

  const coloursRight =
    hornConfigRight && hornConfigRight.colour
      ? hornConfigRight.colour
      : getCharacterConfigValue(state, "skintone");

  return {
    left: {
      shape: hornObjectLeft,
      colour: coloursLeft,
    },
    right: {
      shape: hornObjectRight,
      colour: coloursRight,
    },
  };
}

const BackHorns = (props) => {
  const { left, right, previewId } = props;

  if (left.shape || right.shape) {
    return (
      <g id={previewId ? `g-horns-back~${previewId}` : "g-horns-back"}>
        {left.shape && (
          <ItemComponent
            id={previewId ? `horns-back-left~${previewId}` : "horns-back-left"}
            component={left}
            colour={left.colour}
            preview={previewId ? true : false}
          />
        )}
        {right.shape && (
          <ItemComponent
            id={
              previewId ? `horns-back-right~${previewId}` : "horns-back-right"
            }
            component={right}
            colour={right.colour}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(BackHorns);

import React from "react";
import { connect } from "react-redux";
import { buildTransform } from "character-creator/menu/menu-helper";
import { BodyExtrasList } from "./BodyExtrasList";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import ItemComponent from "character-creator/components/ItemComponent";

// ////////////////////////////////
// Extras like gills etc
// ////////////////////////////////

function mapStateToProps(state, ownProps) {
  const bodyShape = getCharacterConfigValue(state, "body.shape");
  const extraConfig = getCharacterConfigValue(state, "bodyExtras");

  const index = ownProps.index;

  let extraObject = {};

  if (!ownProps.preview && extraConfig && extraConfig[index]) {
    // extrasConfig.forEach((thisExtra, index) => {
    // const thisExtraConfig = thisExtra;
    let thisExtraObject = BodyExtrasList[extraConfig[index].shape];

    let thisExtraShape =
      thisExtraObject.shape[bodyShape] ?? thisExtraObject.shape;

    if (thisExtraShape.left) {
      let newShape = {
        base: (
          <>
            {thisExtraShape.left.base}
            {thisExtraShape.right.base}
          </>
        ),
        light: (
          <>
            {thisExtraShape.left.light}
            {thisExtraShape.right.light}
          </>
        ),
        dark: (
          <>
            {thisExtraShape.left.dark}
            {thisExtraShape.right.dark}
          </>
        ),
      };

      thisExtraShape = newShape;
    }

    const thisExtraTransform =
      extraConfig[index].transform &&
      buildTransform(extraConfig[index].transform);

    const thisExtraColours = extraConfig[index].colour
      ? extraConfig[index].colour
      : { ...getCharacterConfigValue(state, "skintone") };

    // Assign to Extras object array
    extraObject = {
      shape: { shape: thisExtraShape },
      colour: thisExtraColours,
      transform: thisExtraTransform,
    };
    // });
  } else if (ownProps.preview && ownProps.extra) {
    // const thisExtraConfig = ownProps.extra;
    let thisExtraObject = ownProps.extra;

    let thisExtraShape =
      thisExtraObject.shape[bodyShape] ?? thisExtraObject.shape;

    if (thisExtraShape.left) {
      let newShape = {
        base: (
          <>
            {thisExtraShape.left.base}
            {thisExtraShape.right.base}
          </>
        ),
        light: (
          <>
            {thisExtraShape.left.light}
            {thisExtraShape.right.light}
          </>
        ),
        dark: (
          <>
            {thisExtraShape.left.dark}
            {thisExtraShape.right.dark}
          </>
        ),
      };

      thisExtraShape = newShape;
    }

    const thisExtraColours = {
      ...getCharacterConfigValue(state, "skintone"),
    };

    // Assign to Extras object array
    extraObject = {
      shape: { shape: thisExtraShape },
      colour: thisExtraColours,
    };
  }

  return {
    extraObject,
    previewId: ownProps.previewId,
    index,
  };
}

const BodyExtras = (props) => {
  const { extraObject, previewId, index } = props;

  if (extraObject.shape) {
    return (
      <g id={previewId ? `g-body-extra~${previewId}` : `g-body-extra-${index}`}>
        <ItemComponent
          id={
            previewId ? `body-extra${index}~${previewId}` : `body-extra${index}`
          }
          component={extraObject.shape}
          colour={extraObject.colour}
          preview={previewId ? true : false}
          transform={extraObject.transform ?? null}
          strokeOverride={false}
        />
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(BodyExtras);

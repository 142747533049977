import React from "react";
import { connect } from "react-redux";
import { NoseShapeListArray } from "character-creator/components/head/face/nose/NoseShapeList";
import {
  buildTransform,
  filterListById,
  translateTransformObject,
} from "character-creator/menu/menu-helper";
import ClippedMarkings from "../../markings/ClippedMarkings";
import NoseAccessories from "character-creator/components/clothing/Accessories/Face/Nose/NoseAccessories";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import Item from "character-creator/components/Item";

function mapStateToProps(state, ownProps) {
  const noseConfig = getCharacterConfigValue(state, "face.nose");
  const skintone = getCharacterConfigValue(state, "skintone");

  const transform =
    !ownProps.previewId && noseConfig && noseConfig.transform
      ? translateTransformObject(buildTransform(noseConfig.transform))
      : "";

  return {
    noseConfig,
    skintone: {
      darker: skintone.darker,
      dark: skintone.dark,
      base: skintone.light,
      light: skintone.lighter,
    },
    transform,
  };
}

const Nose = (props) => {
  const { noseConfig, skintone, transform, previewId } = props;

  // Add other things, like extras, markings and all that fun stuff

  if (noseConfig && noseConfig.shape !== "0") {
    const NoseElement = (
      <Item
        id={"nose"}
        previewId={previewId}
        itemState={noseConfig}
        itemStateShape={filterListById(NoseShapeListArray, noseConfig.shape)}
        defaultColour={skintone}
      />
    );

    return (
      <>
        <g id={"g-nose"} transform-origin="50% 50%" {...transform}>
          {NoseElement}

          {!previewId && <NoseAccessories />}
        </g>
        {!previewId && (
          <ClippedMarkings
            componentName="nose"
            maskUrl="nose_base"
            maskTransform={transform}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Nose);

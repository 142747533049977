import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { nosePack1 } from "./packs/NosePack1";
import { nosePack2 } from "./packs/NosePack2";

const noseShapeList = {
  "0": BlankListOption,
  ...nosePack1,
  ...nosePack2,
};

export default noseShapeList;

export const NoseShapeListArray = Object.values(noseShapeList);

import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const eye_shape_Uugu5 = {
  id: "eye_shape_Uugu5",
  name: "Eye 1",
  description: "Round eyes",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base: (
            <path d="M230.84 246.78c-.58 0-1.09-.45-1.12-1.03-.53-9.19-8.15-16.47-17.47-16.47-9.32 0-16.94 7.29-17.47 16.47-.03.58-.54 1.03-1.12 1.03-.57 0-1.04-.43-1.12-.99-.12-.92-.19-1.86-.19-2.81 0-11.34 8.91-20.53 19.9-20.53s19.9 9.19 19.9 20.53c0 .96-.07 1.89-.19 2.81-.07.56-.54.99-1.12.99z" />
          ),
        },
        bottom: {
          base: (
            <path d="M212.25 264.28c9.3 0 16.9-7.25 17.47-16.4.04-.62.57-1.1 1.2-1.1.71 0 1.25.6 1.2 1.31-.67 10.38-9.31 18.6-19.86 18.6s-19.19-8.21-19.86-18.6c-.05-.7.5-1.31 1.2-1.31.62 0 1.16.48 1.2 1.1.55 9.15 8.16 16.4 17.45 16.4z" />
          ),
        },
      },
      base: <circle cx="212.25" cy="246.78" r="17.5" />,
      eyelidShadow: {
        base: (
          <path d="M230.84 246.78c-.58 0-1.09-.36-1.12-.83-.53-7.37-8.15-13.22-17.47-13.22-9.32 0-16.94 5.85-17.47 13.22-.03.47-.54.83-1.12.83-.57 0-1.04-.34-1.12-.8-.12-.74-.19-1.49-.19-2.26 0-9.1 8.91-16.47 19.9-16.47s19.9 7.38 19.9 16.47c0 .77-.07 1.52-.19 2.26-.07.45-.54.8-1.12.8z" />
        ),
      },
    },
    right: {
      eyelid: {
        top: {
          base: (
            <path d="M269.16 246.78c.58 0 1.09-.45 1.12-1.03.53-9.19 8.15-16.47 17.47-16.47s16.94 7.29 17.47 16.47c.03.58.54 1.03 1.12 1.03.57 0 1.04-.43 1.12-.99.12-.92.19-1.86.19-2.81 0-11.34-8.91-20.53-19.9-20.53s-19.9 9.19-19.9 20.53c0 .96.07 1.89.19 2.81.07.56.54.99 1.12.99z" />
          ),
        },
        bottom: {
          base: (
            <path d="M287.75 264.28c-9.3 0-16.9-7.25-17.47-16.4-.04-.62-.57-1.1-1.2-1.1-.71 0-1.25.6-1.2 1.31.67 10.38 9.31 18.6 19.86 18.6s19.19-8.21 19.86-18.6c.05-.7-.5-1.31-1.2-1.31-.62 0-1.16.48-1.2 1.1-.55 9.15-8.16 16.4-17.45 16.4z" />
          ),
        },
      },
      base: <circle cx="287.75" cy="246.78" r="17.5" />,
      eyelidShadow: {
        base: (
          <path d="M269.16 246.78c.58 0 1.09-.36 1.12-.83.53-7.37 8.15-13.22 17.47-13.22s16.94 5.85 17.47 13.22c.03.47.54.83 1.12.83.57 0 1.04-.34 1.12-.8.12-.74.19-1.49.19-2.26 0-9.1-8.91-16.47-19.9-16.47s-19.9 7.38-19.9 16.47c0 .77.07 1.52.19 2.26.07.45.54.8 1.12.8z" />
        ),
      },
    },
  },
};

const eye_shape_rGwCe = {
  id: "eye_shape_rGwCe",
  name: "Eye 2",
  description:
    "Puppy-dog eyes that are lower on the outside corners and curved on the bottom",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base: (
            <path d="M234.04,236.14c2.07,5.14-0.85,5.01-1.67,4.01c-6.46-7.84-18.45-13.95-38.12,4.44c-5.59,5.23-7.15-3.42,1.21-8.77C202.02,231.63,227.42,219.71,234.04,236.14z" />
          ),
        },
        bottom: {
          base: (
            <path d="M192.45 246.28c-.01.16-.03.31-.03.46 0 7.92 7.57 15.66 16.63 14.35 13.17-1.91 21.25-11.43 23.01-19.09.09-.41.42-.72.84-.77.69-.09 1.25.54 1.1 1.21-1.92 8.28-10.37 18.56-24.66 20.63-.77.11-1.54.17-2.31.17-8.61 0-16.19-7.31-16.59-15.8.34-.39.55-.63.88-1.02l1.13-.14z" />
          ),
        },
      },
      base: (
        <path d="M232.25,238.98c0-7.92-14.19-6.58-23.37-6.58c-9.18,0-16.63,6.42-16.63,14.35s7.57,15.66,16.63,14.35C223.86,258.92,232.25,246.9,232.25,238.98z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M235.99,239.83c0.78,1.89-2.97,2.74-4.71,1.67c-6.48-3.98-23.93-11.53-39.6,9.18c-2.06,2.72-2.4-16.56,18.08-22.97C226.51,222.47,232.42,231.21,235.99,239.83z" />
        ),
      },
    },
    right: {
      eyelid: {
        top: {
          base: (
            <path d="M266.13 236.14c-2.07 5.14.85 5.01 1.67 4.01 6.46-7.84 18.45-13.95 38.12 4.44 5.59 5.23 7.15-3.42-1.21-8.77-6.56-4.19-31.96-16.11-38.58.32z" />
          ),
        },
        bottom: {
          base: (
            <path d="M307.72 246.28c.01.16.03.31.03.46 0 7.92-7.57 15.66-16.63 14.35-13.17-1.91-21.25-11.43-23.01-19.09-.09-.41-.42-.72-.84-.77-.69-.09-1.25.54-1.1 1.21 1.92 8.28 10.37 18.56 24.66 20.63.77.11 1.54.17 2.31.17 8.61 0 16.19-7.31 16.59-15.8-.34-.39-.55-.63-.88-1.02l-1.13-.14z" />
          ),
        },
      },
      base: (
        <path d="M267.75,238.98c0-7.92,14.19-6.58,23.37-6.58c9.18,0,16.63,6.42,16.63,14.35s-7.57,15.66-16.63,14.35C276.14,258.92,267.75,246.9,267.75,238.98z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M264.18,239.83c-0.78,1.89,2.97,2.74,4.71,1.67c6.48-3.98,23.93-11.53,39.6,9.18c2.06,2.72,2.4-16.56-18.08-22.97C273.66,222.47,267.75,231.21,264.18,239.83z" />
        ),
      },
    },
  },
};

const eye_shape_r3r2a = {
  id: "eye_shape_r3r2a",
  name: "Eye 3",
  description:
    "Cat-like eyes that are higher in the outer corner with a curved bottom",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base: (
            <path d="M231.35,250.42c-4.27-30.1-44.26-10.33-40.69-15.37c3.42-4.83,24.18-11.79,35.5-4.7c5.68,3.56,6.66,10.45,7.65,15.65C234.87,251.61,231.6,252.82,231.35,250.42z" />
          ),
        },
        bottom: {
          base: (
            <path d="M232.51 251.82c-.53-.21-1.11.08-1.31.61-2.38 6.38-8.53 11.32-15.57 10.19-14.98-2.41-23.37-15.71-23.37-24.47 0-.36.03-.71.08-1.04.07-.46-.19-.9-.63-1.07h-.01c-.59-.23-1.26.13-1.35.75-.06.43-.1.88-.1 1.35 0 9.4 8.71 23.82 25.06 26.45.78.12 1.56.19 2.34.19 6.81 0 12.98-5.01 15.45-11.7.19-.5-.09-1.07-.59-1.26z" />
          ),
        },
      },
      base: (
        <path d="M192.25,238.14c0-8.77,14.19-7.28,23.37-7.28s16.63,7.11,16.63,15.87c0,8.77-7.57,17.33-16.63,15.87C200.65,260.21,192.25,246.91,192.25,238.14z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M207.62 235.44c16.25-.57 22.6 21.92 25.49 18.26 2.38-10.83-6.44-22.48-17.33-23.88-7.5-1.71-22.14-.76-25.56 6.86.44 1.1 2.36.11 3.1-.07 4.67-1.13 9.51-1.53 14.3-1.17z" />
        ),
      },
    },
    right: {
      eyelid: {
        top: {
          base: (
            <path d="M268.52,250.42c4.27-30.1,44.26-10.33,40.69-15.37c-3.42-4.83-24.18-11.79-35.5-4.7c-5.68,3.56-6.66,10.45-7.65,15.65C265,251.61,268.26,252.82,268.52,250.42z" />
          ),
        },
        bottom: {
          base: (
            <path d="M267.36 251.82c.53-.21 1.11.08 1.31.61 2.38 6.38 8.53 11.32 15.57 10.19 14.98-2.41 23.37-15.71 23.37-24.47 0-.36-.03-.71-.08-1.04-.07-.46.19-.9.63-1.07h.01c.59-.23 1.26.13 1.35.75.06.43.1.88.1 1.35 0 9.4-8.71 23.82-25.06 26.45-.78.12-1.56.19-2.34.19-6.81 0-12.98-5.01-15.45-11.7-.19-.5.08-1.07.59-1.26z" />
          ),
        },
      },
      base: (
        <path d="M307.75,238.14c0-8.77-14.19-7.28-23.37-7.28s-16.63,7.11-16.63,15.87c0,8.77,7.57,17.33,16.63,15.87C299.35,260.21,307.75,246.91,307.75,238.14z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M292.24 235.44c-16.25-.57-22.6 21.92-25.49 18.26-2.38-10.83 6.44-22.48 17.33-23.88 7.5-1.71 22.14-.76 25.56 6.86-.44 1.1-2.36.11-3.1-.07-4.66-1.13-9.51-1.53-14.3-1.17z" />
        ),
      },
    },
  },
};

const eye_shape_qiCEL = {
  id: "eye_shape_qiCEL",
  name: "Eye 4",
  description:
    "Eyes that are rounded in the inner corner and taper downwards in the outer corner with a flat bottom",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base: (
            <path d="M211.48,237.12c6.34-2.77,14.92-3.43,20.28,7.66c3.25,6.73,4.02-6.87,0.09-11.38c-13.7-15.7-39.08,3.93-41.88,16.07c-0.67,2.91,0.45,10.7,3.92,3.89C198.12,245.05,205.97,239.54,211.48,237.12z" />
          ),
        },
        bottom: {
          base: (
            <path d="M232.18 245.62c.04.39.07.79.07 1.18 0 7.92-7.44 14.35-16.63 14.35-8.62 0-21.65 1.18-23.21-5.23-.13-.55-.66-.9-1.21-.77-.54.13-.89.67-.76 1.22 1.55 6.88 12.21 6.88 17.54 6.88 1.39 0 2.77-.02 4.11-.05 1.23-.02 2.42-.04 3.53-.04 10.27 0 18.63-7.33 18.63-16.35 0-.14 0-.28-.01-.42-.02-.64-.62-1.1-1.25-.96l-.81.19z" />
          ),
        },
      },
      base: (
        <path d="M192.25,254.58c0,7.92,14.19,6.58,23.37,6.58s16.63-6.42,16.63-14.35s-7.57-15.66-16.63-14.35C200.65,234.64,192.25,246.65,192.25,254.58z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M210.95 241.82c9.56-2.69 12.18-2.59 22.15 2.47-.25-15.51-23.66-14.29-32.79-7.09-6.18 4.14-13.29 17.55-7.64 15.96 4.49-1.26 13.8-10.08 18.28-11.34z" />
        ),
      },
    },
    right: {
      eyelid: {
        top: {
          base: (
            <path d="M288.41,237.12c-6.34-2.77-14.92-3.43-20.28,7.66c-3.25,6.73-4.02-6.87-0.09-11.38c13.7-15.7,39.08,3.93,41.88,16.07c0.67,2.91-0.45,10.7-3.92,3.89C301.77,245.05,293.92,239.54,288.41,237.12z" />
          ),
        },
        bottom: {
          base: (
            <path d="M267.71 245.62c-.04.39-.07.79-.07 1.18 0 7.92 7.44 14.35 16.63 14.35 8.62 0 21.65 1.18 23.21-5.23.13-.55.66-.9 1.21-.77.54.13.89.67.76 1.22-1.55 6.88-12.21 6.88-17.54 6.88-1.39 0-2.77-.02-4.11-.05-1.23-.02-2.42-.04-3.53-.04-10.27 0-18.63-7.33-18.63-16.35 0-.14 0-.28.01-.42.02-.64.62-1.1 1.25-.96l.81.19z" />
          ),
        },
      },
      base: (
        <path d="M307.75,254.58c0,7.92-14.19,6.58-23.37,6.58s-16.63-6.42-16.63-14.35s7.57-15.66,16.63-14.35C299.35,234.64,307.75,246.65,307.75,254.58z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M288.94 241.82c-9.56-2.69-12.18-2.59-22.15 2.47.25-15.51 23.66-14.29 32.79-7.09 6.18 4.14 13.29 17.55 7.64 15.96-4.49-1.26-13.8-10.07-18.28-11.34z" />
        ),
      },
    },
  },
};

const eye_shape_sSpzY = {
  id: "eye_shape_sSpzY",
  name: "Eye 5",
  description:
    "Eyes that are rounded in the outer corner and taper downwards in the inner corner with a flat bottom",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base: (
            <path d="M233.2,255.98c1.5,2.47,4.93-6.16-4.65-15.83c-27.25-27.52-47.01,0.42-38.2,6.65c3.14,2.22,7.06-25.81,36.16-0.61C230.7,249.82,232.35,254.58,233.2,255.98z" />
          ),
        },
        bottom: {
          base: (
            <path d="M191.72 244.33c.47.13.78.58.72 1.07-.06.47-.09.94-.09 1.41 0 7.92 7.44 14.35 16.63 14.35 8.31 0 20.72 1.1 23-4.57.19-.48.7-.75 1.2-.61l.02.01c.56.16.88.77.68 1.31-2.27 5.95-12.18 5.95-17.26 5.95-1.39 0-2.77-.02-4.11-.05-1.23-.02-2.42-.04-3.53-.04-10.27 0-18.63-7.33-18.63-16.35 0-.55.04-1.09.1-1.62.07-.62.67-1.03 1.27-.86z" />
          ),
        },
      },
      base: (
        <path d="M232.25,254.58c0,7.92-14.19,6.58-23.37,6.58c-9.18,0-16.63-6.42-16.63-14.35s7.57-15.66,16.63-14.35C223.86,234.64,232.25,246.65,232.25,254.58z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M226.8 249.53c-9.64-5.85-22.05-10.76-32.94-5.53-2.91 1.87-4.85-.66-2.56-3.22 5.89-7.18 16.19-10.17 25.2-7.93 9.02 2.25 16.51 9.34 19.91 17.99 2.41 10.67-6.04.92-9.61-1.31z" />
        ),
      },
    },
    right: {
      eyelid: {
        top: {
          base: (
            <path d="M266.9,255.98c-1.5,2.47-4.93-6.16,4.65-15.83c27.25-27.52,47.01,0.42,38.2,6.65c-3.14,2.22-7.06-25.81-36.16-0.61C269.4,249.82,267.75,254.58,266.9,255.98z" />
          ),
        },
        bottom: {
          base: (
            <path d="M308.38 244.33c-.47.13-.78.58-.72 1.07.06.47.09.94.09 1.41 0 7.92-7.44 14.35-16.63 14.35-8.31 0-20.72 1.1-23-4.57-.19-.48-.7-.75-1.2-.61l-.02.01c-.56.16-.88.77-.68 1.31 2.27 5.95 12.18 5.95 17.26 5.95 1.39 0 2.77-.02 4.11-.05 1.23-.02 2.42-.04 3.53-.04 10.27 0 18.63-7.33 18.63-16.35 0-.55-.04-1.09-.1-1.62-.07-.62-.68-1.03-1.27-.86z" />
          ),
        },
      },
      base: (
        <path d="M267.75,254.58c0,7.92,14.19,6.58,23.37,6.58c9.18,0,16.63-6.42,16.63-14.35s-7.57-15.66-16.63-14.35C276.14,234.64,267.75,246.65,267.75,254.58z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M273.3 249.53c9.64-5.85 22.05-10.76 32.94-5.53 2.91 1.87 4.85-.66 2.56-3.22-5.89-7.18-16.19-10.17-25.2-7.93-9.02 2.25-16.51 9.34-19.91 17.99-2.41 10.67 6.04.92 9.61-1.31z" />
        ),
      },
    },
  },
};

const eye_shape_LfHiz = {
  id: "eye_shape_LfHiz",
  name: "Eye 6",
  description:
    "Rounded eyes with a slightly flat bottom - almost upside-down U shaped",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base: (
            <path d="M231.15 251.96c2.8-31.74-40.85-31.75-37.81-.01.06.63-.41 1.18-1.04 1.19h-.03c-.5.01-.95-.34-1.06-.82-3.23-14.38 7.15-30.51 21.04-30.14 13.89-.38 24.28 15.76 21.04 30.14-.11.49-.56.83-1.06.82h-.04c-.62-.02-1.09-.56-1.04-1.18z" />
          ),
        },
        bottom: {
          base: (
            <path d="M192.9 253.12c.33 0 .59.25.62.58.67 6.68 6.29 11.89 13.14 11.89h11.17c6.62 0 12.09-4.87 13.06-11.22.11-.72.72-1.25 1.44-1.25.37 0 .67.32.64.68-.73 7.72-7.23 13.78-15.14 13.78h-11.17c-7.91 0-14.41-6.06-15.14-13.78-.03-.37.27-.68.64-.68h.74z" />
          ),
        },
      },
      base: (
        <path d="M212.25 227.96c-10.39 0-18.81 8.42-18.81 18.81v5.59c0 7.31 5.92 13.23 13.23 13.23h11.17c7.31 0 13.23-5.92 13.23-13.23v-5.59c0-10.38-8.43-18.81-18.82-18.81z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M212.25 231.57c-10.13 0-18.49 7.84-19.87 18.02-.13-.92-.22-1.86-.22-2.82 0-11.09 8.99-20.09 20.09-20.09s20.09 8.99 20.09 20.09c0 .96-.09 1.89-.22 2.82-1.37-10.18-9.73-18.02-19.87-18.02z" />
        ),
      },
    },
    right: {
      eyelid: {
        top: {
          base: (
            <path d="M268.85 251.96c-2.8-31.74 40.85-31.75 37.81-.01-.06.63.41 1.18 1.04 1.19h.03c.5.01.95-.34 1.06-.82 3.23-14.38-7.15-30.51-21.04-30.14-13.89-.38-24.28 15.76-21.04 30.14.11.49.56.83 1.06.82h.04c.62-.02 1.09-.56 1.04-1.18z" />
          ),
        },
        bottom: {
          base: (
            <path d="M307.1 253.12c-.33 0-.59.25-.62.58-.67 6.68-6.29 11.89-13.14 11.89h-11.17c-6.62 0-12.09-4.87-13.06-11.22-.11-.72-.72-1.25-1.44-1.25-.37 0-.67.32-.64.68.73 7.72 7.23 13.78 15.14 13.78h11.17c7.91 0 14.41-6.06 15.14-13.78.03-.37-.27-.68-.64-.68h-.74z" />
          ),
        },
      },
      base: (
        <path d="M287.75 227.96c-10.39 0-18.81 8.42-18.81 18.81v5.59c0 7.31 5.92 13.23 13.23 13.23h11.17c7.31 0 13.23-5.92 13.23-13.23v-5.59c-.01-10.38-8.43-18.81-18.82-18.81z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M287.75 231.57c10.13 0 18.49 7.84 19.87 18.02.13-.92.22-1.86.22-2.82 0-11.09-8.99-20.09-20.09-20.09s-20.09 8.99-20.09 20.09c0 .96.09 1.89.22 2.82 1.37-10.18 9.73-18.02 19.87-18.02z" />
        ),
      },
    },
  },
};

const eye_shape_aXygD = {
  id: "eye_shape_aXygD",
  name: "Eye 7",
  description: "Rounded eyes with a slightly flat top - almost U shaped",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base: (
            <path d="M216.4 224.75h-8.3c-9.87 0-17.88 8-17.88 17.88v2.65c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-.79c0-6.38 5.99-11.55 13.38-11.55h11.3c7.39 0 13.38 5.17 13.38 11.55v.79c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-2.65c0-9.88-8-17.88-17.88-17.88z" />
          ),
        },
        bottom: {
          base: (
            <path d="M233.26 247.57v.01c0 .16-.01.32-.03.48-.66 10.99-9.82 19.74-20.98 19.74-5.79 0-11.05-2.36-14.86-6.17-3.52-3.52-5.81-8.28-6.12-13.56-.02-.16-.03-.32-.03-.49v-.01c.1-.46.51-.8 1-.8.52 0 .94.39 1 .89 0 .05.01.11.01.16.54 10.02 8.84 17.98 19 17.98 10.12 0 18.4-7.9 19-17.87 0-.13.01-.27.01-.4.11-.44.52-.75 1-.75.49 0 .9.33 1 .79z" />
          ),
        },
      },
      base: (
        <path d="M212.25 265.8c-10.51 0-19.03-8.52-19.03-19.03v-5.65c0-7.39 5.99-13.38 13.38-13.38h11.3c7.39 0 13.38 5.99 13.38 13.38v5.65c0 10.51-8.52 19.03-19.03 19.03z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M216.4 229.33h-8.3c-9.87 0-17.88 8-17.88 17.88v2.65c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-.79c0-6.38 5.99-11.55 13.38-11.55h11.3c7.39 0 13.38 5.17 13.38 11.55v.79c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-2.65c0-9.88-8-17.88-17.88-17.88z" />
        ),
      },
    },
    right: {
      eyelid: {
        top: {
          base: (
            <path d="M283.6 224.75h8.3c9.87 0 17.88 8 17.88 17.88v2.65c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5v-.79c0-6.38-5.99-11.55-13.38-11.55h-11.3c-7.39 0-13.38 5.17-13.38 11.55v.79c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5v-2.65c0-9.88 8-17.88 17.88-17.88z" />
          ),
        },
        bottom: {
          base: (
            <path d="M266.74 247.57v.01c0 .16.01.32.03.48.66 10.99 9.82 19.74 20.98 19.74 5.79 0 11.05-2.36 14.86-6.17 3.52-3.52 5.81-8.28 6.12-13.56.02-.16.03-.32.03-.49v-.01c-.1-.46-.51-.8-1-.8-.52 0-.94.39-1 .89 0 .05-.01.11-.01.16-.54 10.02-8.84 17.98-19 17.98-10.12 0-18.4-7.9-19-17.87 0-.13-.01-.27-.01-.4-.11-.44-.52-.75-1-.75-.49 0-.9.33-1 .79z" />
          ),
        },
      },
      base: (
        <path d="M287.75 265.8c-10.51 0-19.03-8.52-19.03-19.03v-5.65c0-7.39 5.99-13.38 13.38-13.38h11.3c7.39 0 13.38 5.99 13.38 13.38v5.65c-.01 10.51-8.52 19.03-19.03 19.03z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M283.6 229.33h8.3c9.87 0 17.88 8 17.88 17.88v2.65c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5v-.79c0-6.38-5.99-11.55-13.38-11.55h-11.3c-7.39 0-13.38 5.17-13.38 11.55v.79c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5v-2.65c0-9.88 8-17.88 17.88-17.88z" />
        ),
      },
    },
  },
};

const eye_shape_nWP1r = {
  id: "eye_shape_nWP1r",
  name: "Eye 8",
  description: "Almond-shaped eyes",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base: (
            <path d="M212.25 229.37c-12.15 0-21.82 6.78-22.9 15.73-.11.89.62 1.68 1.52 1.68.75 0 1.36-.56 1.51-1.29 1.14-5.61 9.6-9.96 19.87-9.96 10.27 0 18.73 4.35 19.87 9.96.15.74.75 1.29 1.51 1.29.9 0 1.63-.78 1.52-1.68-1.08-8.94-10.75-15.73-22.9-15.73z" />
          ),
        },
        bottom: {
          base: (
            <path d="M234.2 247.66c-.01.17-.04.33-.06.48 0 .02 0 .03-.01.05-1.14 6.74-10.34 11.83-21.88 11.83-6.17 0-11.67-1.45-15.63-3.84-3.4-2.04-5.66-4.77-6.22-7.86-.05-.25-.09-.5-.11-.76.11-.45.52-.79 1.01-.79.48 0 .89.33 1 .78.01.09.02.18.04.26v.02c.95 5.72 9.5 10.19 19.91 10.19 10.44 0 19-4.49 19.92-10.23 0-.07.01-.14.02-.2.1-.47.51-.82 1-.82.52 0 .95.39 1.01.89z" />
          ),
        },
      },
      base: <ellipse cx="212.25" cy="246.78" rx="20" ry="11.25" />,
      eyelidShadow: {
        base: (
          <path d="M212.25 234.56c-12.15 0-21.82 4.76-22.9 11.04-.11.63.62 1.18 1.52 1.18.75 0 1.36-.39 1.51-.91 1.14-3.93 9.6-6.99 19.87-6.99 10.27 0 18.73 3.06 19.87 6.99.15.52.75.91 1.51.91.9 0 1.63-.55 1.52-1.18-1.08-6.28-10.75-11.04-22.9-11.04z" />
        ),
      },
    },
    right: {
      eyelid: {
        top: {
          base: (
            <path d="M287.75 229.37c12.15 0 21.82 6.78 22.9 15.73.11.89-.62 1.68-1.52 1.68-.75 0-1.36-.56-1.51-1.29-1.14-5.61-9.6-9.96-19.87-9.96-10.27 0-18.73 4.35-19.87 9.96-.15.74-.75 1.29-1.51 1.29-.9 0-1.63-.78-1.52-1.68 1.08-8.94 10.75-15.73 22.9-15.73z" />
          ),
        },
        bottom: {
          base: (
            <path d="M265.8 247.66c.01.17.04.33.06.48 0 .02 0 .03.01.05 1.14 6.74 10.34 11.83 21.88 11.83 6.17 0 11.67-1.45 15.63-3.84 3.4-2.04 5.66-4.77 6.22-7.86.05-.25.09-.5.11-.76-.11-.45-.52-.79-1.01-.79-.48 0-.89.33-1 .78-.01.09-.02.18-.04.26v.02c-.95 5.72-9.5 10.19-19.91 10.19-10.44 0-19-4.49-19.92-10.23 0-.07-.01-.14-.02-.2-.1-.47-.51-.82-1-.82-.52 0-.95.39-1.01.89z" />
          ),
        },
      },
      base: <ellipse cx="287.75" cy="246.78" rx="20" ry="11.25" />,
      eyelidShadow: {
        base: (
          <path d="M287.75 234.56c12.15 0 21.82 4.76 22.9 11.04.11.63-.62 1.18-1.52 1.18-.75 0-1.36-.39-1.51-.91-1.14-3.93-9.6-6.99-19.87-6.99-10.27 0-18.73 3.06-19.87 6.99-.15.52-.75.91-1.51.91-.9 0-1.63-.55-1.52-1.18 1.08-6.28 10.75-11.04 22.9-11.04z" />
        ),
      },
    },
  },
};

const eye_shape_9Nzgw = {
  id: "eye_shape_9Nzgw",
  name: "Eye 9",
  description: "Narrow, almond-shaped eyes with a slightly flat top",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base: (
            <path d="M212.25 231.84c-5.69 0-23 0-23 12.97 0 .17 0 .34.01.51.03.81.69 1.46 1.5 1.46.86 0 1.5-.72 1.49-1.58v-.08c0-6.22 8.95-7.94 20-7.94s20 1.72 20 7.94v.08c-.01.86.63 1.58 1.49 1.58.81 0 1.47-.65 1.5-1.46.01-.17.01-.34.01-.51 0-12.97-17.31-12.97-23-12.97z" />
          ),
        },
        bottom: {
          base: (
            <path d="M232.72 246.78c-.42 0-.77.28-.92.67-1.91 5.1-9.92 8.92-19.55 8.92-9.62 0-17.64-3.83-19.55-8.92-.15-.39-.5-.67-.92-.67h-.08c-.66 0-1.14.65-.92 1.27 2.16 5.98 10.8 10.33 21.46 10.33s19.31-4.35 21.46-10.33c.22-.62-.26-1.27-.92-1.27h-.06z" />
          ),
        },
      },
      base: (
        <path d="M192.25 245.12c0 6.22 8.95 11.25 20 11.25s20-5.04 20-11.25c0-6.22-8.95-7.94-20-7.94-11.04 0-20 1.72-20 7.94z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M212.25 236.4c-5.69 0-23 0-23 9.01 0 .12 0 .24.01.35.03.56.69 1.01 1.5 1.01.86 0 1.5-.5 1.49-1.1v-.06c0-4.32 8.95-5.52 20-5.52s20 1.2 20 5.52v.06c-.01.6.63 1.1 1.49 1.1.81 0 1.47-.45 1.5-1.01.01-.12.01-.24.01-.35 0-9.01-17.31-9.01-23-9.01z" />
        ),
      },
    },
    right: {
      eyelid: {
        top: {
          base: (
            <path d="M287.75 231.84c5.69 0 23 0 23 12.97 0 .17 0 .34-.01.51-.03.81-.69 1.46-1.5 1.46-.86 0-1.5-.72-1.49-1.58v-.08c0-6.22-8.95-7.94-20-7.94s-20 1.72-20 7.94v.08c.01.86-.63 1.58-1.49 1.58-.81 0-1.47-.65-1.5-1.46-.01-.17-.01-.34-.01-.51 0-12.97 17.31-12.97 23-12.97z" />
          ),
        },
        bottom: {
          base: (
            <path d="M267.28 246.78c.42 0 .77.28.92.67 1.91 5.1 9.92 8.92 19.55 8.92 9.62 0 17.64-3.83 19.55-8.92.15-.39.5-.67.92-.67h.08c.66 0 1.14.65.92 1.27-2.16 5.98-10.8 10.33-21.46 10.33s-19.31-4.35-21.46-10.33c-.22-.62.26-1.27.92-1.27h.06z" />
          ),
        },
      },
      base: (
        <path d="M307.75 245.12c0 6.22-8.95 11.25-20 11.25s-20-5.04-20-11.25c0-6.22 8.95-7.94 20-7.94 11.04 0 20 1.72 20 7.94z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M287.75 236.4c5.69 0 23 0 23 9.01 0 .12 0 .24-.01.35-.03.56-.69 1.01-1.5 1.01-.86 0-1.5-.5-1.49-1.1v-.06c0-4.32-8.95-5.52-20-5.52s-20 1.2-20 5.52v.06c.01.6-.63 1.1-1.49 1.1-.81 0-1.47-.45-1.5-1.01-.01-.12-.01-.24-.01-.35 0-9.01 17.31-9.01 23-9.01z" />
        ),
      },
    },
  },
};

const eye_shape_l7N07 = {
  id: "eye_shape_l7N07",
  name: "Eye 10",
  description: "Narrow, almond-shaped eyes with a slightly flat bottom",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base: (
            <path d="M235.23 247.9c-.19.66-.81 1.15-1.53 1.15-.66 0-1.23-.4-1.47-.98-.01-.3-.04-.6-.1-.89-1.11-5.63-9.58-10-19.88-10-10.23 0-18.66 4.31-19.85 9.89-.08.37-.13.74-.14 1.12-.26.51-.79.86-1.41.86-.77 0-1.42-.54-1.57-1.28v-.02c.02-.33.06-.64.12-.96 1.27-7.19 10.85-12.61 22.85-12.61 11.91 0 21.44 5.34 22.82 12.45.09.42.14.84.16 1.27z" />
          ),
        },
        bottom: {
          base: (
            <path d="M233.04 249.06c-.46 0-.86.3-.97.75-1.26 5.09-9.64 6.56-19.81 6.56-10.18 0-18.55-1.47-19.81-6.56-.11-.44-.51-.75-.97-.75-.64 0-1.15.6-1 1.22 1.94 8.08 16.7 8.09 21.78 8.09 5.08 0 19.84-.01 21.78-8.09.14-.62-.36-1.22-1-1.22z" />
          ),
        },
      },
      base: (
        <path d="M192.25 248.43c0-6.22 8.95-11.25 20-11.25s20 5.04 20 11.25c0 6.22-8.95 7.94-20 7.94-11.04 0-20-1.72-20-7.94z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M235.23 248.14c-.19.53-.81.92-1.53.92-.66 0-1.23-.32-1.47-.78-.01-.24-.04-.48-.1-.71-1.11-4.49-9.58-7.98-19.88-7.98-10.23 0-18.66 3.44-19.85 7.89-.08.3-.13.59-.14.89-.26.41-.79.69-1.41.69-.77 0-1.42-.43-1.57-1.02v-.02c.02-.26.06-.51.12-.77 1.27-5.74 10.85-10.07 22.85-10.07 11.91 0 21.44 4.26 22.82 9.94.09.34.14.67.16 1.02z" />
        ),
      },
    },
    right: {
      eyelid: {
        top: {
          base: (
            <path d="M264.77 247.9c.19.66.81 1.15 1.53 1.15.66 0 1.23-.4 1.47-.98.01-.3.04-.6.1-.89 1.11-5.63 9.58-10 19.88-10 10.23 0 18.66 4.31 19.85 9.89.08.37.13.74.14 1.12.26.51.79.86 1.41.86.77 0 1.42-.54 1.57-1.28v-.02c-.02-.33-.06-.64-.12-.96-1.27-7.19-10.85-12.61-22.85-12.61-11.91 0-21.44 5.34-22.82 12.45-.09.42-.14.84-.16 1.27z" />
          ),
        },
        bottom: {
          base: (
            <path d="M266.96 249.06c.46 0 .86.3.97.75 1.26 5.09 9.64 6.56 19.81 6.56 10.18 0 18.55-1.47 19.81-6.56.11-.44.51-.75.97-.75.64 0 1.15.6 1 1.22-1.94 8.08-16.7 8.09-21.78 8.09s-19.84-.01-21.78-8.09c-.14-.62.36-1.22 1-1.22z" />
          ),
        },
      },
      base: (
        <path d="M307.75 248.43c0-6.22-8.95-11.25-20-11.25s-20 5.04-20 11.25c0 6.22 8.95 7.94 20 7.94 11.04 0 20-1.72 20-7.94z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M264.77 248.14c.19.53.81.92 1.53.92.66 0 1.23-.32 1.47-.78.01-.24.04-.48.1-.71 1.11-4.49 9.58-7.98 19.88-7.98 10.23 0 18.66 3.44 19.85 7.89.08.3.13.59.14.89.26.41.79.69 1.41.69.77 0 1.42-.43 1.57-1.02v-.02c-.02-.26-.06-.51-.12-.77-1.27-5.74-10.85-10.07-22.85-10.07-11.91 0-21.44 4.26-22.82 9.94-.09.34-.14.67-.16 1.02z" />
        ),
      },
    },
  },
};

const eye_shape_E2knT = {
  id: "eye_shape_E2knT",
  name: "Eye 11",
  description:
    "Narrow, almond-shaped eyes with a slightly sharper outer corner",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base: (
            <path d="M235.21 247.72c-.02.73-.54 1.32-1.23 1.47h-.01l-.96-.11c-.33-.14-.6-.4-.76-.72v-.01c0-.32-.05-.66-.14-1.01v-.01c-.93-3.53-6.56-8.04-17.88-9.26-9.37-1-19.29 2.6-23.88 4.57-.14.06-.27.13-.38.22-.36.25-.6.61-.72 1.01-.27.17-.59.27-.93.27-.08 0-.15-.01-.23-.02-.04 0-.08-.01-.13-.01-.77-.17-1.35-.86-1.35-1.68 0-.03 0-.05.01-.08.27-.58.66-1.11 1.13-1.53.41-.39.89-.7 1.42-.93 4.33-1.86 12.91-4.99 21.72-4.99 1.25 0 2.48.06 3.66.19 13.16 1.41 19.69 7.02 20.59 12.06.03.19.06.38.07.57z" />
          ),
        },
        bottom: {
          base: (
            <path d="M233.01 249.09c-.46-.05-.87.22-1.04.65-1.52 3.84-9.57 5.92-21.08 5.92-9.41 0-17.48-6.35-21.11-9.73-.31-.29-.5-.65-.58-1.02-.09-.41-.44-.7-.86-.74-.66-.07-1.24.52-1.1 1.17.17.78.57 1.49 1.17 2.06 4.12 3.83 12.52 10.27 22.47 10.27 13.18 0 21.17-2.53 22.96-7.18.24-.62-.16-1.31-.82-1.38l-.01-.02z" />
          ),
        },
      },
      base: (
        <path d="M232.25,248.36c0,4.72-8.54,7.29-21.36,7.29c-9.41,0-17.48-6.35-21.11-9.73c-1.05-0.98-0.75-2.7,0.57-3.27c4.59-1.98,14.51-5.58,23.88-4.57C226.69,239.41,232.25,244.75,232.25,248.36z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M235.21 248.03c-.02.58-.54 1.04-1.23 1.16h-.01l-.96-.09c-.33-.11-.6-.32-.76-.57v-.01c0-.25-.05-.52-.14-.8v-.01c-.93-2.79-6.56-6.35-17.88-7.32-9.37-.79-19.29 2.05-23.88 3.61-.14.05-.27.1-.38.17-.36.2-.6.48-.72.8-.27.13-.59.21-.93.21-.08 0-.15-.01-.23-.02-.04 0-.08-.01-.13-.01-.77-.13-1.35-.68-1.35-1.33 0-.02 0-.04.01-.06.27-.46.66-.88 1.13-1.21.41-.31.89-.55 1.42-.73 4.33-1.47 12.91-3.94 21.72-3.94 1.25 0 2.48.05 3.66.15 13.16 1.11 19.69 5.55 20.59 9.53.03.17.06.32.07.47z" />
        ),
      },
    },
    right: {
      eyelid: {
        top: {
          base: (
            <path d="M263.24 247.72c.02.73.54 1.32 1.23 1.47h.01l.96-.11c.33-.14.6-.4.76-.72v-.01c0-.32.05-.66.14-1.01v-.01c.93-3.53 6.56-8.04 17.88-9.26 9.37-1 19.29 2.6 23.88 4.57.14.06.27.13.38.22.36.25.6.61.72 1.01.27.17.59.27.93.27.08 0 .15-.01.23-.02.04 0 .08-.01.13-.01.77-.17 1.35-.86 1.35-1.68 0-.03 0-.05-.01-.08-.27-.58-.66-1.11-1.13-1.53-.41-.39-.89-.7-1.42-.93-4.33-1.86-12.91-4.99-21.72-4.99-1.25 0-2.48.06-3.66.19-13.16 1.41-19.69 7.02-20.59 12.06-.03.19-.06.38-.07.57z" />
          ),
        },
        bottom: {
          base: (
            <path d="M265.44 249.09c.46-.05.87.22 1.04.65 1.52 3.84 9.57 5.92 21.08 5.92 9.41 0 17.48-6.35 21.11-9.73.31-.29.5-.65.58-1.02.09-.41.44-.7.86-.74.66-.07 1.24.52 1.1 1.17-.17.78-.57 1.49-1.17 2.06-4.12 3.83-12.52 10.27-22.47 10.27-13.18 0-21.17-2.53-22.96-7.18-.24-.62.16-1.31.82-1.38l.01-.02z" />
          ),
        },
      },
      base: (
        <path d="M266.2,248.36c0,4.72,8.54,7.29,21.36,7.29c9.41,0,17.48-6.35,21.11-9.73c1.05-0.98,0.75-2.7-0.57-3.27c-4.59-1.98-14.51-5.58-23.88-4.57C271.76,239.41,266.2,244.75,266.2,248.36z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M263.24 248.03c.02.58.54 1.04 1.23 1.16h.01l.96-.09c.33-.11.6-.32.76-.57v-.01c0-.25.05-.52.14-.8v-.01c.93-2.79 6.56-6.35 17.88-7.32 9.37-.79 19.29 2.05 23.88 3.61.14.05.27.1.38.17.36.2.6.48.72.8.27.13.59.21.93.21.08 0 .15-.01.23-.02.04 0 .08-.01.13-.01.77-.13 1.35-.68 1.35-1.33 0-.02 0-.04-.01-.06-.27-.46-.66-.88-1.13-1.21-.41-.31-.89-.55-1.42-.73-4.33-1.47-12.91-3.94-21.72-3.94-1.25 0-2.48.05-3.66.15-13.16 1.11-19.69 5.55-20.59 9.53-.03.17-.06.32-.07.47z" />
        ),
      },
    },
  },
};

const eye_shape_cN3Qm = {
  id: "eye_shape_cN3Qm",
  name: "Eye 12",
  description:
    "Narrow, almond-shaped eyes that taper upwards in the outer corners",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base: (
            <path d="M233.52 244.98c-5.26-7.02-15.38-11.55-25.79-11.55-8.76 0-15.57 2.73-17.77 7.13-.05.09-.09.18-.13.28-.4.88.16 1.9 1.11 2.08.67.13 1.35-.21 1.62-.83 1.49-3.36 7.65-5.66 15.17-5.66 10.16 0 19.11 4.64 23.39 10.35.72.96 1.09 1.9 1.16 2.78.05.67.55 1.21 1.21 1.33.96.18 1.86-.59 1.78-1.56-.11-1.47-.7-2.94-1.75-4.35z" />
          ),
        },
        bottom: {
          base: (
            <path d="M233.07 250.81c-.47-.09-.92.17-1.1.61-1.45 3.38-7.63 5.71-15.19 5.71-10.16 0-19.1-4.63-23.39-10.35-.74-.99-1.11-1.94-1.17-2.85-.03-.46-.35-.84-.81-.93-.62-.12-1.22.37-1.19 1 .07 1.33.6 2.68 1.57 3.98 5.08 6.77 14.89 11.15 24.99 11.15 8.25 0 14.88-2.58 16.88-6.58.06-.11.11-.23.16-.35.24-.59-.11-1.27-.75-1.39z" />
          ),
        },
      },
      base: (
        <path d="M193.39,246.78c4.28,5.72,13.23,10.35,23.39,10.35c10.67,0,18.62-4.63,14.34-10.35c-4.28-5.72-13.23-10.35-23.39-10.35C197.05,236.42,189.11,241.06,193.39,246.78z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M233.52 246c-5.26-5.82-15.38-9.57-25.79-9.57-8.76 0-15.57 2.26-17.77 5.91-.05.08-.09.15-.13.23-.4.73.16 1.57 1.11 1.72.67.1 1.35-.17 1.62-.69 1.49-2.78 7.65-4.69 15.17-4.69 10.16 0 19.11 3.84 23.39 8.58.72.8 1.09 1.57 1.16 2.31.05.55.55 1 1.21 1.1.96.15 1.86-.49 1.78-1.29-.11-1.22-.7-2.45-1.75-3.61z" />
        ),
      },
    },
    right: {
      eyelid: {
        top: {
          base: (
            <path d="M266.48 244.98c5.26-7.02 15.38-11.55 25.79-11.55 8.76 0 15.57 2.73 17.77 7.13.05.09.09.18.13.28.4.88-.16 1.9-1.11 2.08-.67.13-1.35-.21-1.62-.83-1.49-3.36-7.65-5.66-15.17-5.66-10.16 0-19.11 4.64-23.39 10.35-.72.96-1.09 1.9-1.16 2.78-.05.67-.55 1.21-1.21 1.33-.96.18-1.86-.59-1.78-1.56.11-1.47.7-2.94 1.75-4.35z" />
          ),
        },
        bottom: {
          base: (
            <path d="M266.93 250.81c.47-.09.92.17 1.1.61 1.45 3.38 7.63 5.71 15.19 5.71 10.16 0 19.1-4.63 23.39-10.35.74-.99 1.11-1.94 1.17-2.85.03-.46.35-.84.81-.93.62-.12 1.22.37 1.19 1-.07 1.33-.6 2.68-1.57 3.98-5.08 6.77-14.89 11.15-24.99 11.15-8.25 0-14.88-2.58-16.88-6.58-.06-.11-.11-.23-.16-.35-.24-.59.11-1.27.75-1.39z" />
          ),
        },
      },
      base: (
        <path d="M306.61,246.78c-4.28,5.72-13.23,10.35-23.39,10.35c-10.67,0-18.62-4.63-14.34-10.35c4.28-5.72,13.23-10.35,23.39-10.35C302.95,236.42,310.89,241.06,306.61,246.78z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M266.48 246c5.26-5.82 15.38-9.57 25.79-9.57 8.76 0 15.57 2.26 17.77 5.91.05.08.09.15.13.23.4.73-.16 1.57-1.11 1.72-.67.1-1.35-.17-1.62-.69-1.49-2.78-7.65-4.69-15.17-4.69-10.16 0-19.11 3.84-23.39 8.58-.72.8-1.09 1.57-1.16 2.31-.05.55-.55 1-1.21 1.1-.96.15-1.86-.49-1.78-1.29.11-1.22.7-2.45 1.75-3.61z" />
        ),
      },
    },
  },
};

const eye_shape_NTvVF = {
  id: "eye_shape_NTvVF",
  name: "Eye 13",
  description: "Narrow, almond-shaped eyes",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base: (
            <path d="M206 238.19c10-1.61 19.62 1.14 24.83 5.6.79.67 1.3 1.37 1.55 2.07.21.57.78.92 1.38.92 1.02 0 1.78-1.01 1.43-1.97-.39-1.06-1.13-2.2-2.42-3.3-4.96-4.25-12.8-6.78-20.96-6.78-2.11 0-4.23.17-6.3.5-6.92 1.11-14.85 4.22-16.36 9.61-.01.02-.01.04-.02.07-.25.95.48 1.88 1.46 1.88.67 0 1.26-.44 1.44-1.09.89-3.27 6.56-6.32 13.97-7.51z" />
          ),
        },
        bottom: {
          base: (
            <path d="M234.54 247.59c-.03.32-.09.61-.16.85-.01.02-.01.05-.02.07-1.33 4.5-8.72 8.27-18.44 9.19-1.03.1-2.08.12-3.12.12h-.12c-7.93 0-15.52-2.44-20.31-6.54-1.06-.91-1.69-1.84-2.05-2.7-.01 0-.01 0-.01-.01-.13-.33-.22-.66-.28-.97.1-.48.52-.83 1.02-.83.44 0 .82.26.98.65.02.06.04.13.06.2.25.72.77 1.44 1.58 2.14 5.22 4.46 14.83 7.21 24.84 5.6 7.28-1.17 12.89-4.14 13.91-7.33.08-.25.13-.5.15-.75.19-.31.53-.51.92-.51.51 0 .93.35 1.05.82z" />
          ),
        },
      },
      base: (
        <path d="M193.68,249.77c5.21,4.46,14.83,7.21,24.83,5.6c10.51-1.69,17.53-7.12,12.32-11.58c-5.21-4.46-14.83-7.21-24.83-5.6C195.48,239.88,188.47,245.31,193.68,249.77z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M206 240.33c10-1.21 19.62.86 24.83 4.21.79.51 1.3 1.03 1.55 1.56.21.43.78.69 1.38.69 1.02 0 1.78-.76 1.43-1.48-.39-.8-1.13-1.65-2.42-2.48-4.96-3.19-12.8-5.1-20.96-5.1-2.11 0-4.23.13-6.3.38-6.92.84-14.85 3.17-16.36 7.22-.01.02-.01.03-.02.05-.25.71.48 1.41 1.46 1.41.67 0 1.26-.33 1.44-.82.89-2.46 6.56-4.75 13.97-5.64z" />
        ),
      },
    },
    right: {
      eyelid: {
        top: {
          base: (
            <path d="M294 238.19c-10-1.61-19.62 1.14-24.83 5.6-.79.67-1.3 1.37-1.55 2.07-.21.57-.78.92-1.38.92-1.02 0-1.78-1.01-1.43-1.97.39-1.06 1.13-2.2 2.42-3.3 4.96-4.25 12.8-6.78 20.96-6.78 2.11 0 4.23.17 6.3.5 6.92 1.11 14.85 4.22 16.36 9.61.01.02.01.04.02.07.25.95-.48 1.88-1.46 1.88-.67 0-1.26-.44-1.44-1.09-.89-3.27-6.56-6.32-13.97-7.51z" />
          ),
        },
        bottom: {
          base: (
            <path d="M265.46 247.59c.03.32.09.61.16.85.01.02.01.05.02.07 1.33 4.5 8.72 8.27 18.44 9.19 1.03.1 2.08.12 3.12.12h.12c7.93 0 15.52-2.44 20.31-6.54 1.06-.91 1.69-1.84 2.05-2.7.01 0 .01 0 .01-.01.13-.33.22-.66.28-.97-.1-.48-.52-.83-1.02-.83-.44 0-.82.26-.98.65-.02.06-.04.13-.06.2-.25.72-.77 1.44-1.58 2.14-5.22 4.46-14.83 7.21-24.84 5.6-7.28-1.17-12.89-4.14-13.91-7.33-.08-.25-.13-.5-.15-.75-.19-.31-.53-.51-.92-.51-.51 0-.93.35-1.05.82z" />
          ),
        },
      },
      base: (
        <path d="M306.32,249.77c-5.21,4.46-14.83,7.21-24.83,5.6c-10.51-1.69-17.53-7.12-12.32-11.58c5.21-4.46,14.83-7.21,24.83-5.6C304.52,239.88,311.53,245.31,306.32,249.77z" />
      ),
      eyelidShadow: {
        base: (
          <path d="M294 240.33c-10-1.21-19.62.86-24.83 4.21-.79.51-1.3 1.03-1.55 1.56-.21.43-.78.69-1.38.69-1.02 0-1.78-.76-1.43-1.48.39-.8 1.13-1.65 2.42-2.48 4.96-3.19 12.8-5.1 20.96-5.1 2.11 0 4.23.13 6.3.38 6.92.84 14.85 3.17 16.36 7.22.01.02.01.03.02.05.25.71-.48 1.41-1.46 1.41-.67 0-1.26-.33-1.44-.82-.89-2.46-6.56-4.75-13.97-5.64z" />
        ),
      },
    },
  },
};

export const EyeShapePack1 = {
  eye_shape_Uugu5,
  eye_shape_rGwCe,
  eye_shape_r3r2a,
  eye_shape_qiCEL,
  eye_shape_sSpzY,
  eye_shape_LfHiz,
  eye_shape_aXygD,
  eye_shape_nWP1r,
  eye_shape_9Nzgw,
  eye_shape_l7N07,
  eye_shape_E2knT,
  eye_shape_cN3Qm,
  eye_shape_NTvVF,
};

import React from "react";
import CharacterHead from "character-creator/components/head/CharacterHead";
import Mouth from "character-creator/components/head/face/mouth/Mouth";
import { connect } from "react-redux";
import { MouthShapeListArray } from "character-creator/components/head/face/mouth/MouthShapeList";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const mouthConfigShape = getCharacterConfigValue(state, "mouth.shape");
  return {
    defaultMouthShape:
      mouthConfigShape && mouthConfigShape !== "0"
        ? mouthConfigShape
        : "mouth_shape_Pf59F",
    ...ownProps,
  };
}

export const PreviewBase = (props) => {
  const { component, defaultMouthShape, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <Mouth
        preview={true}
        previewId={previewId}
        // previewItem={filterListById(MouthShapeListArray, defaultMouthShape)}
        teeth={component}
        // defaultMouthShape={defaultMouthShape}
      />
    </>
  );
};

export const previewViewbox = "210 270 80 80";

export default connect(mapStateToProps)(PreviewBase);

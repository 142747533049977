import React from "react";
import { Link } from "react-router-dom";

const UnauthorisedPage = (props) => {
  return (
    <section className="panel">
      <h2>Oops! Clipping error...</h2>
      <p>
        You need to <Link to="/login">log in to view this page.</Link>
      </p>
    </section>
  );
};

export default UnauthorisedPage;

// Sections
import TabbedPanelContainer from "character-creator/menu/elements/TabbedPanelContainer";
import BodyExtrasPage from "./pages/body/BodyExtrasPage";
import BodyShapePage from "./pages/body/BodyPage";
import BodyScarsPageConfig from "./pages/body/BodyScarsPage";
import bodyMarkingsPageConfig from "./pages/body/MarkingsPage";
import TailsPage from "./pages/body/TailsPage";
import EarsPage from "./pages/head/EarsPage";
import HeadShapePage from "./pages/head/HeadPage";
import HornsPage from "./pages/head/HornsPage";
import HeadMarkingsPage from "./pages/head/MarkingsPage";
import HeadScarsPage from "./pages/head/ScarsPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

// Attribute paths
// export const basePath = "base.";

const baseMenuSubTabs = {
  pageBody: {
    id: "pageBody",
    name: "Body",
    content: (
      <TabbedPanelContainer
        id="tabbedPanelBody"
        tabs={{
          bodyShapePage: BodyShapePage,
          tailsPage: TailsPage,
          bodyMarkingsPage: bodyMarkingsPageConfig,
          bodyExtrasPage: BodyExtrasPage,
          bodyScarsPage: BodyScarsPageConfig,
        }}
      />
    ),
  },
  pageHead: {
    id: "pageHead",
    name: "Head",
    content: (
      <TabbedPanelContainer
        id="tabbedPanelHead"
        tabs={{
          headShapePage: HeadShapePage,
          earsPage: EarsPage,
          headHornsPage: HornsPage,
          headMarkingsPage: HeadMarkingsPage,
          headScarsPage: HeadScarsPage,
        }}
      />
    ),
  },
};

const BaseMenuConfig = {
  id: "tabBase",
  name: "Base",
  // icon: icon,
  description: "Build the foundation of your character.",
  content: (
    <TabbedPanelContainer
      id="tabbedPanelBase"
      tabs={baseMenuSubTabs}
      containerClasses={"menu-block"}
      navClasses={"nav-inline"}
    />
  ),
};

export default BaseMenuConfig;

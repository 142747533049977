import React from "react";
import { connect } from "react-redux";
import { EarShapeListArray } from "character-creator/components/head/ears/EarShapeList";
import ItemComponent from "character-creator/components/ItemComponent";
import EarAccessoriesBackLayers from "character-creator/components/clothing/Accessories/Ears/EarAccessoriesBackLayers";
import EarAccessoriesFrontLayers from "character-creator/components/clothing/Accessories/Ears/EarAccessoriesFrontLayers";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";

// TODO: Ear accessories
function mapStateToProps(state, ownProps) {
  // Left
  const earConfigLeft = getCharacterConfigValue(state, "ears.left");

  let earObjectLeft = null;
  if (ownProps.preview && ownProps.ears.shape.left) {
    earObjectLeft = ownProps.ears.shape.left;
  } else if (earConfigLeft && earConfigLeft.shape !== "0") {
    earObjectLeft = filterListById(EarShapeListArray, earConfigLeft.shape).shape
      .left;
  }

  const coloursLeft =
    earConfigLeft && earConfigLeft.colour
      ? earConfigLeft.colour
      : getCharacterConfigValue(state, "skintone");

  // Right
  const earConfigRight = getCharacterConfigValue(state, "ears.right");

  let earObjectRight = null;
  if (ownProps.preview && ownProps.ears.shape.right) {
    earObjectRight = ownProps.ears.shape.right;
  } else if (earConfigRight && earConfigRight.shape !== "0") {
    earObjectRight = filterListById(EarShapeListArray, earConfigRight.shape)
      .shape.right;
  }

  const coloursRight =
    earConfigRight && earConfigRight.colour
      ? earConfigRight.colour
      : getCharacterConfigValue(state, "skintone");

  return {
    previewId: ownProps.previewId,
    left: {
      shape: earObjectLeft,
      colour: coloursLeft,
    },
    right: {
      shape: earObjectRight,
      colour: coloursRight,
    },
  };
}

const Ears = (props) => {
  const { left, right, previewId } = props;

  if (left.shape || right.shape) {
    return (
      <g id={previewId ? `g-ears~${previewId}` : "g-ears"}>
        {!previewId && <EarAccessoriesBackLayers />}
        {left.shape && (
          <ItemComponent
            id={previewId ? `ear-left~${previewId}` : "ear-left"}
            component={left}
            colour={left.colour}
            preview={previewId ? true : false}
          />
        )}
        {right.shape && (
          <ItemComponent
            id={previewId ? `ear-right~${previewId}` : "ear-right"}
            component={right}
            colour={right.colour}
            preview={previewId ? true : false}
          />
        )}
        {!previewId && <EarAccessoriesFrontLayers />}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Ears);

import React from "react";
import {
  viewbox,
  PreviewBase,
} from "character-creator/components/head/ears/Preview";

const ear_shape_T4KrT = {
  id: "ear_shape_T4KrT",
  name: "Ears 1 (Bumpy)",
  description: "Bumpy human-like ears",
  tags: ["side", "rounded", "bumpy"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M173.11 243.23c2.18-1.73 1.88-5.2.62-7.69-1.26-2.49-3.24-4.64-3.96-7.33-.72-2.71-.29-6.1-2.53-7.78-2.7-2.02-6.52.41-9.86-.04-3.73-.5-6.72-4.61-10.4-3.82-2.24.48-3.69 2.67-4.43 4.84-.73 2.17-1 4.51-2.07 6.53-1.49 2.82-4.5 4.97-4.8 8.14-.32 3.33 2.53 6.24 2.62 9.58.1 3.74-3.26 7.33-1.97 10.83 1.08 2.95 4.89 4.02 6.39 6.79 1.61 2.98.16 7.21 2.49 9.66 2.22 2.34 6.29 1.35 9.05 3.02 2.1 1.28 3.03 3.83 4.75 5.58 1.78 1.82 4.47 2.71 6.99 2.32 2.52-.4 4.8-2.07 5.94-4.35 1.18-2.36 1.13-5.13 1.91-7.65.92-2.99 2.95-5.48 4.53-8.18 1.58-2.7 2.76-5.96 1.75-8.92-.85-2.52-3.08-4.27-4.75-6.34-1.66-2.08-2.76-5.2-2.27-5.19z" />
      ),
      basic: {
        dark: (
          <path d="M174.06 273.44c-2.16-5.82 1.22-65.52-6.74-37.11-4.06-11.89-26.06-15.06-21.73-.44 3.72-11.06 20.35-.65 10.33 8.31-6.59 9.68-4.37 28.28 9.76 17.81 4.23 3.39 2.14 12.99 6.46 17.48 3.75 2.97 2.07-4.79 1.92-6.05z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M326.93 243.27c-2.18-1.73-1.88-5.2-.62-7.69 1.26-2.49 3.24-4.64 3.96-7.33.72-2.71.29-6.1 2.53-7.78 2.7-2.02 6.52.41 9.86-.04 3.73-.5 6.72-4.61 10.4-3.82 2.24.48 3.69 2.67 4.43 4.84.73 2.17 1 4.51 2.07 6.53 1.49 2.82 4.5 4.97 4.8 8.14.32 3.33-2.53 6.24-2.62 9.58-.1 3.74 3.26 7.33 1.97 10.83-1.08 2.95-4.89 4.02-6.39 6.79-1.61 2.98-.16 7.21-2.49 9.66-2.22 2.34-6.29 1.35-9.05 3.02-2.1 1.28-3.03 3.83-4.75 5.58-1.78 1.82-4.47 2.71-6.99 2.32s-4.8-2.07-5.94-4.35c-1.18-2.36-1.13-5.13-1.91-7.65-.92-2.99-2.95-5.48-4.53-8.18-1.58-2.7-2.76-5.96-1.75-8.92.85-2.52 3.08-4.27 4.75-6.34s2.76-5.19 2.27-5.19z" />
      ),
      basic: {
        dark: (
          <path d="M325.94 273.44c2.16-5.82-1.22-65.52 6.74-37.11 4.06-11.89 26.06-15.06 21.73-.44-3.72-11.06-20.35-.65-10.33 8.31 6.59 9.68 4.37 28.28-9.76 17.81-4.23 3.39-2.14 12.99-6.46 17.48-3.75 2.97-2.07-4.79-1.92-6.05z" />
        ),
      },
    },
  },
};

const ear_shape_047rT = {
  id: "ear_shape_047rT",
  name: "Ears 2 (Bumpy)",
  description: "Bumpy human-like ears with a long dangling lobe",
  tags: ["rounded", "side", "long", "bumpy"],
  preview: {
    viewbox: "110 210 100 100",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M174.99 252.57c2.26-1.34 2.88-4.5 2.09-7.01s-2.63-4.53-4.44-6.44c-1.8-1.92-3.67-3.91-4.5-6.41-.98-2.96-.48-6.5-2.44-8.92-1.32-1.63-3.51-2.35-5.6-2.36s-4.14.59-6.14 1.19c-4.27 1.27-9.08 3.01-10.75 7.14-.95 2.37-.64 5.06-1.28 7.53-1.07 4.16-4.77 7.55-4.78 11.84-.01 5.32 5.6 9.1 6.57 14.33.47 2.5-.18 5.14.5 7.6.97 3.52 4.45 5.93 5.35 9.47.51 2.02.12 4.13.05 6.21-.07 2.08.32 4.39 1.94 5.69 1.44 1.16 3.53 1.25 4.95 2.42 1.56 1.29 1.93 3.52 3.08 5.2 1.3 1.91 3.7 3 5.99 2.72 2.29-.29 4.36-1.96 5.09-4.15.76-2.28.12-4.78.31-7.18.19-2.49 1.25-4.81 2.07-7.17 3.6-10.39 2.45-21.74 1.94-31.7z" />
      ),
      basic: {
        dark: (
          <path d="M158.26 288.29c13.18 4.92 9.92-22.62 7.8-29.46-2.63 2.28-.62 4.96 1.03 6.99-.43 9.53-14.17 2.1-11.73-5.21 8.86 11.17 11.47-5.77 5.64-11.55-2.21-3.4-7.04 1.65-7.72-1.93-1.23-2.82 1.55-4.51 2.02-7.27.23-3.38-7.68 3.47-6.82-1.58 3.24-13.36 12.91-5.32 17.31 4.82.6-3.56 1.73-7.01 3.52-10.15 4.3 8.73-.45 19.83 1.79 29.33.6 5.21 4.94 13.03 1.09 17.39-.5-.65-.84-1.38-1.13-2.15-1.89 24.91-12.34 24.94-20.19 2.17 2.72 2.6 4.03 6.64 7.39 8.6zm-16.13-44.73c1.8 7.79 5.14 14.96 8.21 22.32.15.41-.16.68-.54.81-1.66.75-1.36 2.25-1.69 3.61-3.02-8.19-8.75-17.82-5.98-26.74z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M325 252.59c-2.26-1.34-2.88-4.5-2.09-7.01s2.63-4.53 4.44-6.44 3.67-3.91 4.5-6.41c.98-2.96.48-6.5 2.44-8.92 1.32-1.63 3.51-2.35 5.6-2.36 2.09-.01 4.14.59 6.14 1.19 4.27 1.27 9.08 3.01 10.75 7.14.95 2.37.64 5.06 1.28 7.53 1.07 4.16 4.77 7.55 4.78 11.84.01 5.32-5.6 9.1-6.57 14.33-.47 2.5.18 5.14-.5 7.6-.97 3.52-4.45 5.93-5.35 9.47-.51 2.02-.12 4.13-.05 6.21s-.32 4.39-1.94 5.69c-1.44 1.16-3.53 1.25-4.95 2.42-1.56 1.29-1.93 3.52-3.08 5.2-1.3 1.91-3.7 3-5.99 2.72-2.29-.29-4.36-1.96-5.09-4.15-.76-2.28-.12-4.78-.31-7.18-.19-2.49-1.25-4.81-2.07-7.17-3.6-10.39-2.44-21.74-1.94-31.7z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M341.74 288.29c-13.18 4.92-9.92-22.62-7.8-29.46 2.63 2.28.62 4.96-1.03 6.99.43 9.53 14.17 2.1 11.73-5.21-8.86 11.17-11.47-5.77-5.64-11.55 2.21-3.4 7.04 1.65 7.72-1.93 1.23-2.82-1.55-4.51-2.02-7.27-.23-3.38 7.68 3.47 6.82-1.58-3.24-13.36-12.91-5.32-17.31 4.82-.6-3.56-1.73-7.01-3.52-10.15-4.3 8.73.45 19.83-1.79 29.33-.6 5.21-4.94 13.03-1.09 17.39.5-.65.84-1.38 1.13-2.15 1.89 24.91 12.34 24.94 20.19 2.17-2.72 2.6-4.03 6.64-7.39 8.6z" />
            <path d="M357.87 243.56c-1.8 7.79-5.14 14.96-8.21 22.32-.15.41.16.68.54.81 1.66.75 1.36 2.25 1.69 3.61 3.02-8.19 8.75-17.82 5.98-26.74z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_H4ZrT = {
  id: "ear_shape_H4ZrT",
  name: "Ears 3 (Bumpy)",
  description: "Bumpy, tall and large double-pointed ears",
  tags: ["pointed", "large", "side", "bumpy"],
  preview: {
    viewbox: "80 140 160 160",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M181.46 251.9c-5.2-8.84-10.41-17.69-15.61-26.53-1.9-3.23-3.81-6.49-5.13-10-.87-2.33-1.57-4.91-3.49-6.49-1.77-1.46-4.44-1.92-5.57-3.92-1.31-2.31.2-5.26-.41-7.84-.85-3.57-5.12-4.9-7.82-7.38-2.93-2.69-4-6.81-4.93-10.68-1.26-5.25-2.52-10.5-3.77-15.75-.91-3.8-2.06-7.96-5.3-10.15-2.09-1.41-4.9-1.88-6.37-3.93-.68-.95-1.17-2.29-2.32-2.49-1.31-.23-2.26 1.21-2.75 2.45-2.98 7.55-3.66 15.98-1.94 23.91.54 2.49 1.32 5.03.82 7.52-.42 2.13-1.75 4.05-1.91 6.21-.26 3.5 2.52 6.55 2.81 10.04.23 2.78-1.18 5.48-3.11 7.49s-4.36 3.45-6.69 4.97c-2.06 1.33-4.23 2.96-4.76 5.36-.63 2.86 1.29 5.6 3.11 7.89 2.98 3.76 6.35 7.78 11.06 8.72 1.66.33 3.49.29 4.85 1.28 2.12 1.55 2.23 5 4.47 6.37 2.06 1.26 5.1.22 6.86 1.87 2.16 2.02.23 5.67.88 8.55.67 2.99 3.97 4.6 5.46 7.28 1.74 3.13.72 7.01 1.16 10.56.5 4.01 2.87 7.49 5.18 10.81 1.72 2.48 3.48 5 5.86 6.85 1.87 1.46 4.07 2.45 6.3 3.26 5.92 2.15 12.69 3.05 18.41.42 1.06-.49 2.11-1.13 2.69-2.15 2.15-3.76-3.66-8.18-2.31-12.3.51-1.54 1.9-2.58 2.96-3.81 4.34-5.07 4.25-13.4 1.31-18.39z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M160.87 280.98c2.88 1.41 6.56 2.29 9.18-.11-3.74-16.22-3.8-33.29-.14-49.53 4.49-1.93 1.04 27.72 2.64 32.17.36 4.89.96 9.76 2 14.55.4 1.84 3.99 10.92 2.42 11.78-1.72.94-3.27-.59-4.46-2.61-14.61 1.78-31.17-12.03-30.54-27.26 3.14 9.17 10.13 16.93 18.9 21.01zm-12-46.92c-.11 2.24-1.36 4.49-2.13 6.59-1.24-1.98-.97-4.96-2.06-7.08-1.19-2.71-3.72-4.64-4.96-7.33 2.64 2.96 7.26 4.42 9.15 7.82zm-36.27-13.02c7.91 1.81 15.87-.47 18.49 9.7 1.13 4.06 4.63 8.68 4.78 12.75-7.16-12.83-25.31-14.42-31.37-27.9 1.78 2.81 4.91 4.51 8.1 5.45z" />
            <path d="M139.35 204.81c10.07 17.1 26.27 33.15 27.01 53.97-1.14 1.83-3.98.27-5.74.99-.49.16-1.62 2.19-2.16 2.07-1.28-.28-1.14-3.89-1.38-5.06.3-4.75-10.07-5.37-4.77-9.8 21.85-3.93-11.22-23.56-13.17-33.39-2.47-5.17-9.61-27.06-9.57-32.79 1.89 2.32 2.94 12.14 9.78 24.01z" />
          </>
        ),
        light: (
          <path d="M153.77 265.98c1.19.39 2.49-.1 3.56-.75 1.07-.65 2.07-1.47 3.27-1.82 1.2-.35 2.73-.05 3.29 1.08.37.74.22 1.63 0 2.43-.41 1.49-1.05 2.92-1.88 4.21-.58.9-1.28 1.76-2.23 2.24-1.87.95-4.25.16-5.74-1.32-1.23-1.22-2.7-3.43-3.31-5.06-1.06-2.81 1.57-1.49 3.04-1.01zm-20.85-48.02c-.68-2.36-.84-4.84-1-7.29-1.65 3.58-1.11 7.85.42 11.48 1.53 3.64 3.95 6.81 6.14 10.09 1.58 2.36 3.08 4.84 4 7.54.64 1.89.37 4.87 1.45 6.44 2.51-3.5-.55-10.4-2.35-13.5-2.87-4.93-7.05-9.18-8.66-14.76zm-10.71-69.41c-.25-.31-.53-.64-.92-.71-.88-.18-1.4.97-1.47 1.87-.17 2.51.57 4.98 1.3 7.38 3.37 11.03 6.87 22.13 12.6 32.13 5.37 9.36 12.58 17.54 20.3 25.08-8.96-12.91-14.28-27.93-19.53-42.74-1.55-4.36-3.11-8.74-5.21-12.88-1.89-3.74-4.48-6.87-7.07-10.13z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M318.54 251.9c5.2-8.84 10.41-17.69 15.61-26.53 1.9-3.23 3.81-6.49 5.13-10 .87-2.33 1.57-4.91 3.49-6.49 1.77-1.46 4.44-1.92 5.57-3.92 1.31-2.31-.2-5.26.41-7.84.85-3.57 5.12-4.9 7.82-7.38 2.93-2.69 4-6.81 4.93-10.68 1.26-5.25 2.52-10.5 3.77-15.75.91-3.8 2.06-7.96 5.3-10.15 2.09-1.41 4.9-1.88 6.37-3.93.68-.95 1.17-2.29 2.32-2.49 1.31-.23 2.26 1.21 2.75 2.45 2.98 7.55 3.66 15.98 1.94 23.91-.54 2.49-1.32 5.03-.82 7.52.42 2.13 1.75 4.05 1.91 6.21.26 3.5-2.52 6.55-2.81 10.04-.23 2.78 1.18 5.48 3.11 7.49s4.36 3.45 6.69 4.97c2.06 1.33 4.23 2.96 4.76 5.36.63 2.86-1.29 5.6-3.11 7.89-2.98 3.76-6.35 7.78-11.06 8.72-1.66.33-3.49.29-4.85 1.28-2.12 1.55-2.23 5-4.47 6.37-2.06 1.26-5.1.22-6.86 1.87-2.16 2.02-.23 5.67-.88 8.55-.67 2.99-3.97 4.6-5.46 7.28-1.74 3.13-.72 7.01-1.16 10.56-.5 4.01-2.87 7.49-5.18 10.81-1.72 2.48-3.48 5-5.86 6.85-1.87 1.46-4.07 2.45-6.3 3.26-5.92 2.15-12.69 3.05-18.41.42-1.06-.49-2.11-1.13-2.69-2.15-2.15-3.76 3.66-8.18 2.31-12.3-.51-1.54-1.9-2.58-2.96-3.81-4.34-5.07-4.25-13.4-1.31-18.39z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M339.13 280.98c-2.88 1.41-6.56 2.29-9.18-.11 3.74-16.22 3.8-33.29.14-49.53-4.49-1.93-1.04 27.72-2.64 32.17-.36 4.89-.96 9.76-2 14.55-.4 1.84-3.99 10.92-2.42 11.78 1.72.94 3.27-.59 4.46-2.61 14.61 1.78 31.17-12.03 30.54-27.26-3.14 9.17-10.13 16.93-18.9 21.01zm12-46.92c.11 2.24 1.36 4.49 2.13 6.59 1.24-1.98.97-4.96 2.06-7.08 1.19-2.71 3.72-4.64 4.96-7.33-2.64 2.96-7.26 4.42-9.15 7.82zm36.27-13.02c-7.91 1.81-15.87-.47-18.49 9.7-1.13 4.06-4.63 8.68-4.78 12.75 7.16-12.83 25.31-14.42 31.37-27.9-1.78 2.81-4.91 4.51-8.1 5.45z" />
            <path d="M360.65 204.81c-10.07 17.1-26.27 33.15-27.01 53.97 1.14 1.83 3.98.27 5.74.99.49.16 1.62 2.19 2.16 2.07 1.28-.28 1.14-3.89 1.38-5.06-.3-4.75 10.07-5.37 4.77-9.8-21.85-3.93 11.22-23.56 13.17-33.39 2.47-5.17 9.61-27.06 9.57-32.79-1.89 2.32-2.94 12.14-9.78 24.01z" />
          </>
        ),
        light: (
          <path d="M346.23 265.98c-1.19.39-2.49-.1-3.56-.75-1.07-.65-2.07-1.47-3.27-1.82-1.2-.35-2.73-.05-3.29 1.08-.37.74-.22 1.63 0 2.43.41 1.49 1.05 2.92 1.88 4.21.58.9 1.28 1.76 2.23 2.24 1.87.95 4.25.16 5.74-1.32 1.23-1.22 2.7-3.43 3.31-5.06 1.06-2.81-1.57-1.49-3.04-1.01zm20.85-48.02c.68-2.36.84-4.84 1-7.29 1.65 3.58 1.11 7.85-.42 11.48-1.53 3.64-3.95 6.81-6.14 10.09-1.58 2.36-3.08 4.84-4 7.54-.64 1.89-.37 4.87-1.45 6.44-2.51-3.5.55-10.4 2.35-13.5 2.87-4.93 7.05-9.18 8.66-14.76zm10.71-69.41c.25-.31.53-.64.92-.71.88-.18 1.4.97 1.47 1.87.17 2.51-.57 4.98-1.3 7.38-3.37 11.03-6.87 22.13-12.6 32.13-5.37 9.36-12.58 17.54-20.3 25.08 8.96-12.91 14.28-27.93 19.53-42.74 1.55-4.36 3.11-8.74 5.21-12.88 1.89-3.74 4.48-6.87 7.07-10.13z" />
        ),
      },
    },
  },
};

const ear_shape_v4JrT = {
  id: "ear_shape_v4JrT",
  name: "Ears 4 (Bumpy)",
  description: "Bumpy sharp-pointed ears that point up and out",
  tags: ["pointed", "side", "bumpy"],
  preview: {
    viewbox: "100 170 130 130",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M179.11 228.08c-.32-4.49-.86-9.46-4.21-12.48-2.24-2.02-5.46-2.85-7.32-5.22-1.68-2.14-2.02-5.27-4.19-6.91-2.07-1.56-5.15-1.22-7.14-2.87-2.52-2.08-2.22-6.38-4.8-8.38-2.63-2.03-6.9-.63-9.39-2.84-2.39-2.12-1.91-6.59-4.71-8.13-2.33-1.27-5.43.41-7.8-.78-1.73-.86-2.45-2.94-3.89-4.22-2.3-2.04-5.79-1.65-8.83-1.14-1.15.19-2.49.53-2.94 1.61-.59 1.44.92 2.9 2.39 3.41 1.47.51 3.2.69 4.21 1.87 1.94 2.27-.38 5.95.79 8.69 1.11 2.58 4.71 3.1 6.3 5.42 2.04 2.97-.1 7.38 1.71 10.5 1.49 2.55 5.05 3.22 6.7 5.67 2.46 3.66-.64 8.93 1.24 12.91 1.27 2.7 4.58 4.17 5.41 7.04 1.04 3.59-2.35 7.43-1.14 10.96.76 2.2 3.1 3.55 3.91 5.74 1.41 3.86-2.55 8.25-.95 12.04 1.04 2.45 4.07 3.62 5.03 6.1 1.06 2.75-.82 6.06.46 8.72 1.53 3.18 6.25 3.06 8.84 5.46 1.62 1.5 2.23 3.86 3.87 5.34 1.99 1.81 4.96 1.9 7.66 1.88 3.83-.02 8.43-.48 10.18-3.9 1.08-2.11.62-4.7-.27-6.9s-2.18-4.25-2.77-6.56c-1.09-4.27.37-8.71 1.24-13.03 2.08-10.38.7-21.11-.69-31.6" />
      ),
      basic: {
        dark: (
          <path d="M175.74 280.82c-6.14-15.78-.82-30.32-2.45-46.86.72-5.69 3.37-11.25 3.21-16.95-3.41-7.77-5.26 4.3-6.4 7.47-8.78-8.99-17.94-19.58-29.18-25.37 3.77 9.4 15.01 15.34 17.01 25.98 2.56 12.17-9.97 35.14 8.84 38.45.75 7.78 2.32 15.48 4.75 22.91 4.48 8.66 6.65-.91 4.22-5.63z" />
        ),
        light: (
          <path d="M161.94 263.6c1.1.63 2.34 1.19 2.95 2.3s-.01 2.91-1.28 2.84c-.48-.03-.9-.32-1.29-.61-4.67-3.48-8.4-8.18-10.84-13.46-1.79-3.88-4.32-10.43-3.08-14.72 1.97 2.35 2.45 6.68 3.47 9.56 1.95 5.49 4.85 11.08 10.07 14.09zm1.39-57.02c1.31 1.24 2.71 2.87 2.26 4.62-4.97-2.6-9.44-6.03-13.98-9.33-6.66-4.85-13.5-9.45-20.5-13.8-2.16-1.34-17.58-8.63-16.6-11.31 2.12-5.78 26.61 11.58 28.56 12.99 7.13 5.15 13.9 10.78 20.26 16.83z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M320.89 228.08c.32-4.49.86-9.46 4.21-12.48 2.24-2.02 5.46-2.85 7.32-5.22 1.68-2.14 2.02-5.27 4.19-6.91 2.07-1.56 5.15-1.22 7.14-2.87 2.52-2.08 2.22-6.38 4.8-8.38 2.63-2.03 6.9-.63 9.39-2.84 2.39-2.12 1.91-6.59 4.71-8.13 2.33-1.27 5.43.41 7.8-.78 1.73-.86 2.45-2.94 3.89-4.22 2.3-2.04 5.79-1.65 8.83-1.14 1.15.19 2.49.53 2.94 1.61.59 1.44-.92 2.9-2.39 3.41-1.47.51-3.2.69-4.21 1.87-1.94 2.27.38 5.95-.79 8.69-1.11 2.58-4.71 3.1-6.3 5.42-2.04 2.97.1 7.38-1.71 10.5-1.49 2.55-5.05 3.22-6.7 5.67-2.46 3.66.64 8.93-1.24 12.91-1.27 2.7-4.58 4.17-5.41 7.04-1.04 3.59 2.35 7.43 1.14 10.96-.76 2.2-3.1 3.55-3.91 5.74-1.41 3.86 2.55 8.25.95 12.04-1.04 2.45-4.07 3.62-5.03 6.1-1.06 2.75.82 6.06-.46 8.72-1.53 3.18-6.25 3.06-8.84 5.46-1.62 1.5-2.23 3.86-3.87 5.34-1.99 1.81-4.96 1.9-7.66 1.88-3.83-.02-8.43-.48-10.18-3.9-1.08-2.11-.62-4.7.27-6.9s2.18-4.25 2.77-6.56c1.09-4.27-.37-8.71-1.24-13.03-2.08-10.38-.7-21.11.69-31.6" />
      ),
      basic: {
        dark: (
          <path d="M324.26 280.82c6.14-15.78.82-30.32 2.45-46.86-.72-5.69-3.37-11.25-3.21-16.95 3.41-7.77 5.26 4.3 6.4 7.47 8.78-8.99 17.94-19.58 29.18-25.37-3.77 9.4-15.01 15.34-17.01 25.98-2.56 12.17 9.97 35.14-8.84 38.45-.75 7.78-2.32 15.48-4.75 22.91-4.48 8.66-6.65-.91-4.22-5.63z" />
        ),
        light: (
          <path d="M338.06 263.6c-1.1.63-2.34 1.19-2.95 2.3-.61 1.11.01 2.91 1.28 2.84.48-.03.9-.32 1.29-.61 4.67-3.48 8.4-8.18 10.84-13.46 1.79-3.88 4.32-10.43 3.08-14.72-1.97 2.35-2.45 6.68-3.47 9.56-1.95 5.49-4.85 11.08-10.07 14.09zm-1.39-57.02c-1.31 1.24-2.71 2.87-2.26 4.62 4.97-2.6 9.44-6.03 13.98-9.33 6.66-4.85 13.5-9.45 20.5-13.8 2.16-1.34 17.58-8.63 16.6-11.31-2.12-5.78-26.61 11.58-28.56 12.99-7.13 5.15-13.9 10.78-20.26 16.83z" />
        ),
      },
    },
  },
};

const ear_shape_F4HrT = {
  id: "ear_shape_F4HrT",
  name: "Ears 5 (Bumpy)",
  description: "Bumpy, wide rounded ears",
  tags: ["rounded", "side", "bumpy"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M176.55 289.31c7.25-6.59 11.65-16.21 11.88-26 .24-9.79-3.69-19.62-10.6-26.55-3.51-3.52-7.71-6.28-11.24-9.77-1.97-1.94-3.75-4.12-6.06-5.64-3.4-2.24-7.6-2.83-11.64-3.37-2.04-.27-4.13-.54-6.16-.19-2.03.35-4.04 1.42-5.02 3.24-1.16 2.16-.68 4.95-1.92 7.07-1.38 2.36-4.53 3.29-5.78 5.72-1.14 2.23-.3 4.9-.18 7.4.26 5.46-3.04 11.03-1.22 16.19 1.17 3.3 4.25 5.67 5.31 9 1.27 3.98-.48 8.88 2.09 12.17 2.15 2.74 6.25 2.91 9.12 4.89 3.72 2.58 5.05 8 9.21 9.78 2.56 1.1 5.52.47 8.15-.46 2.62-.93 5.21-2.18 7.99-2.3 1.53-.07 3.08.2 4.59-.04 1.53-.25 3.1-1.22 1.48-1.14z" />
      ),
      basic: {
        dark: (
          <path d="M160.29 243.26c-.27 6.59-7.47 8.22-12.75 5.84-19.7-11.92-2.04-35 19.12-12.64-.21-7.1 7.02-11.19 6.59-1.69-2.36 13.39 1.11 26.73 1.8 39.9-.46-.48-.95-.94-1.46-1.37.26 5.3 4.7 11.02 3.21 16.07-6.96 1.15-17.6 1.9-23.24-3.52 9.48 2.48 17.53-6.14 12.8-14.86-6.72 2.29-15.99 8.57-19.47-1.59-4.18-11.07 17.59-16.24 13.28-28.32.12.73.17 1.45.12 2.18z" />
        ),
        light: (
          <path d="M155.47 273.33c1.84-1.11 3.68-3.02 5.75-2.43 1.61.46 2.45 2.36 2.28 4.03-.34 3.45-4.03 5.83-7.5 5.77s-6.63-1.96-9.32-4.15c-2.6-2.11-4.99-4.61-6.36-7.67-.37-.83-.67-1.74-.53-2.63.14-.9.86-1.76 1.77-1.79 1.43-.05 2.12 1.67 2.54 3.03 1.61 5.24 5.97 9.1 11.37 5.84zm9.33-42.85c-.27.25-.7.14-1.04.02-3.94-1.46-7.6-3.58-11.58-4.91-2.03-.68-4.13-1.18-6.29-1.2-1.39-.02-5.04 1.06-6.15.05-1.8-1.63 2.78-3 4.07-3.31 3.41-.81 7.2.08 10.4 1.34 4.18 1.62 7.91 4.4 10.59 8.01z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M323.46 289.3c-7.25-6.59-11.65-16.21-11.88-26-.24-9.79 3.69-19.62 10.6-26.55 3.51-3.52 7.71-6.28 11.24-9.77 1.97-1.94 3.75-4.12 6.06-5.64 3.4-2.24 7.6-2.83 11.64-3.37 2.04-.27 4.13-.54 6.16-.19 2.03.35 4.04 1.42 5.02 3.24 1.16 2.16.68 4.95 1.92 7.07 1.38 2.36 4.53 3.29 5.78 5.72 1.14 2.23.3 4.9.18 7.4-.26 5.46 3.04 11.03 1.22 16.19-1.17 3.3-4.25 5.67-5.31 9-1.27 3.98.48 8.88-2.09 12.17-2.15 2.74-6.25 2.91-9.12 4.89-3.72 2.58-5.05 8-9.21 9.78-2.56 1.1-5.52.47-8.15-.46-2.62-.93-5.21-2.18-7.99-2.3-1.53-.07-3.08.2-4.59-.04s-3.1-1.22-1.48-1.14z" />
      ),
      basic: {
        dark: (
          <path d="M339.71 243.26c.27 6.59 7.47 8.22 12.75 5.84 19.7-11.92 2.04-35-19.12-12.64.21-7.1-7.02-11.19-6.59-1.69 2.36 13.39-1.11 26.73-1.8 39.9.46-.48.95-.94 1.46-1.37-.26 5.3-4.7 11.02-3.21 16.07 6.96 1.15 17.6 1.9 23.24-3.52-9.48 2.48-17.53-6.14-12.8-14.86 6.72 2.29 15.99 8.57 19.47-1.59 4.18-11.07-17.59-16.24-13.28-28.32-.12.73-.17 1.45-.12 2.18z" />
        ),
        light: (
          <path d="M344.53 273.33c-1.85-1.11-3.68-3.02-5.75-2.43-1.61.46-2.45 2.36-2.28 4.03.34 3.45 4.03 5.83 7.5 5.77 3.46-.06 6.63-1.96 9.32-4.15 2.6-2.11 4.99-4.61 6.36-7.67.37-.83.67-1.74.53-2.63-.14-.9-.86-1.76-1.77-1.79-1.43-.05-2.12 1.67-2.54 3.03-1.61 5.24-5.97 9.1-11.37 5.84zm-9.33-42.85c.27.25.7.14 1.04.02 3.94-1.46 7.6-3.58 11.58-4.91 2.03-.68 4.13-1.18 6.29-1.2 1.39-.02 5.04 1.06 6.15.05 1.8-1.63-2.78-3-4.07-3.31-3.41-.81-7.2.08-10.4 1.34-4.18 1.62-7.91 4.4-10.59 8.01z" />
        ),
      },
    },
  },
};

const ear_shape_a4GrT = {
  id: "ear_shape_a4GrT",
  name: "Ears 6 (Bumpy)",
  description: "Bumpy, very wide rounded ears",
  tags: ["rounded", "side", "bumpy"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M174.28 279.02c5.29-.72 9.65-5.06 11.36-10.12 1.71-5.06 1.08-10.68-.7-15.71-1.78-5.04-4.65-9.6-7.5-14.12-1.8-2.86-3.61-5.73-5.41-8.59-1.39-2.2-2.78-4.41-4.46-6.4-2.07-2.45-4.54-4.52-6.99-6.58-2.68-2.25-5.42-4.54-8.68-5.81-1.97-.77-4.06-1.15-6.14-1.52-3.85-.69-8.21-1.28-11.35 1.07-1.85 1.38-3.15 3.72-5.42 4.16-1.35.26-2.73-.24-4.11-.21-2.69.06-4.94 2.15-6.35 4.44s-2.28 4.9-3.83 7.11c-2.18 3.1-5.78 5.66-5.93 9.45-.16 4.08 3.81 7.09 4.99 10.99 1.17 3.86-.37 8.6 2.2 11.71 2.34 2.84 6.77 2.63 9.92 4.53 4.27 2.58 5.68 8.71 10.28 10.66 3.37 1.43 7.34.08 10.87 1.07 4.04 1.13 6.67 5.05 10.47 6.82 3.09 1.43 6.71 1.32 9.98.38s6.28-2.66 6.8-3.33z" />
      ),
      basic: {
        dark: (
          <path d="M172.8 265.28c-1.5-6.3 1.87-54.72-5.12-26.87-6.77-7.92-23.53-27.41-33.24-14.6 8.09-.27 15.28 1.32 20.49 8.5.75.67.94 1.68-.04 2.17-15.64 11.11-3.23 38.76 14.02 39.38 6.28 21.4 6.82-.81 3.89-8.58z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M325.83 279.02c-5.29-.72-9.65-5.06-11.36-10.12-1.71-5.06-1.08-10.68.7-15.71s4.65-9.6 7.5-14.12c1.8-2.86 3.61-5.73 5.41-8.59 1.39-2.2 2.78-4.41 4.46-6.4 2.07-2.45 4.54-4.52 6.99-6.58 2.68-2.25 5.42-4.54 8.68-5.81 1.97-.77 4.06-1.15 6.14-1.52 3.85-.69 8.21-1.28 11.35 1.07 1.85 1.38 3.15 3.72 5.42 4.16 1.35.26 2.73-.24 4.11-.21 2.69.06 4.94 2.15 6.35 4.44 1.41 2.29 2.28 4.9 3.83 7.11 2.18 3.1 5.78 5.66 5.93 9.45.16 4.08-3.81 7.09-4.99 10.99-1.17 3.86.37 8.6-2.2 11.71-2.34 2.84-6.77 2.63-9.92 4.53-4.27 2.58-5.68 8.71-10.28 10.66-3.37 1.43-7.34.08-10.87 1.07-4.04 1.13-6.67 5.05-10.47 6.82-3.09 1.43-6.71 1.32-9.98.38s-6.28-2.66-6.8-3.33z" />
      ),
      basic: {
        dark: (
          <path d="M327.46 265.28c1.5-6.3-1.87-54.72 5.12-26.87 6.77-7.92 23.53-27.41 33.24-14.6-8.09-.27-15.28 1.32-20.49 8.5-.75.67-.94 1.68.04 2.17 15.64 11.11 3.23 38.76-14.02 39.38-6.28 21.4-6.82-.81-3.89-8.58z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_Z4RrT = {
  id: "ear_shape_Z4RrT",
  name: "Ears 7 (Bumpy)",
  description: "Bumpy, long and very wide pointed ears",
  tags: ["pointed", "side", "long", "bumpy"],
  preview: {
    viewbox: "60 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M177.67,282.9c7.35-8.09,9.41-20.35,5.86-30.69s-12.29-18.55-22.57-22.26
						c-2.3-0.83-4.76-1.5-6.57-3.14c-1.39-1.25-2.37-3.06-4.12-3.73c-2.22-0.86-4.64,0.45-7.01,0.64c-2.4,0.18-4.71-0.81-7.05-1.37
						c-3.47-0.84-6.7-2.25-10.28-2.15c-4.25,0.11-8.9,1.73-13.15,1.84c-4.71,0.13-8.91-1.25-13.62-1.12
						c-3.82,0.1-8.23,1.72-11.8,3.1c-2.56,0.99-4.87,2.61-7.54,3.24c-2.58,0.61-5.7,0.44-7.35,2.52c-1.18,1.48,4.04,6.55,5.49,5.34
						c0.67,3.16,4.01,5,7.15,5.8c3.14,0.8,6.53,1.05,9.21,2.87c1.49,1.02,2.68,2.48,4.28,3.31c2.95,1.53,6.82,0.67,9.54,2.58
						c3.38,2.36,3.23,7.64,6.18,10.52c3.25,3.17,8.79,2.19,12.65,4.58c3.94,2.45,5.28,7.89,9.32,10.18c4.02,2.28,9.32,0.63,13.5,2.6
						c2.9,1.37,4.84,4.29,7.75,5.66c2.96,1.4,6.42,0.96,9.67,0.5C171.02,283.15,174.83,282.61,177.67,282.9z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M174.14,270.15c4.33-3.11,1.14-9.07-1.05-12.84c-1.74-5.88,2.39-24.49-4.73-25.4c-2,5.44-3.01,11.11-3.29,16.86c-9.48-10.78-23.36-17.63-37.71-18.42c-1.19-0.25-1.82,1.17-1.16,2.13c4.2,2.96,10.64,2.94,13.87,7.37c4.54,5.51,2.41,13.91,5.08,20.54c3.48,8.55,13.57,11.97,22.24,11.19c1.31,4.02,1.5,9.03,4.45,12.18c178.19,288.01,173.78,272.79,174.14,270.15z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M322.33,282.9
						c-7.35-8.09-9.41-20.35-5.86-30.69s12.29-18.55,22.57-22.26c2.3-0.83,4.76-1.5,6.57-3.14c1.39-1.25,2.37-3.06,4.12-3.73
						c2.22-0.86,4.64,0.45,7.01,0.64c2.4,0.18,4.71-0.81,7.05-1.37c3.47-0.84,6.7-2.25,10.28-2.15c4.25,0.11,8.9,1.73,13.15,1.84
						c4.71,0.13,8.91-1.25,13.62-1.12c3.82,0.1,8.23,1.72,11.8,3.1c2.56,0.99,4.87,2.61,7.54,3.24c2.58,0.61,5.7,0.44,7.35,2.52
						c1.18,1.48-4.04,6.55-5.49,5.34c-0.67,3.16-4.01,5-7.15,5.8c-3.14,0.8-6.53,1.05-9.21,2.87c-1.49,1.02-2.68,2.48-4.28,3.31
						c-2.95,1.53-6.82,0.67-9.54,2.58c-3.38,2.36-3.23,7.64-6.18,10.52c-3.25,3.17-8.79,2.19-12.65,4.58
						c-3.94,2.45-5.28,7.89-9.32,10.18c-4.02,2.28-9.32,0.63-13.5,2.6c-2.9,1.37-4.84,4.29-7.75,5.66c-2.96,1.4-6.42,0.96-9.67,0.5
						C328.98,283.15,325.17,282.61,322.33,282.9z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M325.86,270.15c-4.33-3.11-1.14-9.07,1.05-12.84c1.74-5.88-2.39-24.49,4.73-25.4c2,5.44,3.01,11.11,3.29,16.86c9.48-10.78,23.36-17.63,37.71-18.42c1.19-0.25,1.82,1.17,1.16,2.13c-4.2,2.96-10.64,2.94-13.87,7.37c-4.54,5.51-2.41,13.91-5.08,20.54c-3.48,8.55-13.57,11.97-22.24,11.19c-1.31,4.02-1.5,9.03-4.45,12.18C321.81,288.01,326.22,272.79,325.86,270.15z" />
          </>
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_v4yrT = {
  id: "ear_shape_v4yrT",
  name: "Ears 8 (Bumpy)",
  description: "Bumpy, long drooping ears",
  tags: ["long", "side", "bumpy"],
  preview: {
    viewbox: "90 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M172.25,254.45c9.88-13.53,15.84-29.9,16.97-46.62
						c0.29-4.35,0.17-9.06-2.41-12.57c-2-2.72-5.17-4.3-8.21-5.77c-2.82-1.36-5.66-2.74-8.7-3.48c-7.85-1.92-16.61,0.81-22.13,6.71
						c-3.45,3.69-5.61,8.36-7.72,12.95c-1.8,3.93-3.63,7.94-4.13,12.23c-0.58,5.04,0.68,10.37-1.24,15.06
						c-1.54,3.78-5.04,6.8-5.42,10.86c-0.3,3.23-1.03,9.28-0.59,12.49c0.56,4.15-0.64,8.23-2.16,12.13
						c-1.79,4.57-2.89,7.21-4.5,11.85c-2.68,7.74-1.75,13.95-6.53,22.22c-2.2,3.81-2.63,9.42,0.59,12.41
						c1.72,1.6,4.22,1.98,6.55,2.28c3.42,0.45,7.28,0.77,9.92-1.44c1.37-1.14,2.2-2.82,3.58-3.96c2.86-2.37,7.31-1.85,10.22-4.16
						c3.39-2.69,3.38-8.14,6.63-11c2.58-2.26,6.76-2.45,8.71-5.28c2.58-3.76-0.66-9.22,1.24-13.36c1.24-2.69,4.25-4.01,6.31-6.13
						C173.79,267.16,172.96,259.61,172.25,254.45z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M187.35,193.02c-2.26-7.17-15.43,21.86-15.83,24.32c-14.49,22.35-20.09,49.22-32.1,72.9c-0.55,1.1-1.1,2.44-0.48,3.5c2.33,2.77,5.89-0.69,7.32-2.9c7.48-10.01,14.74-20.24,20.65-31.23c0.27,1.99,1.92,9.11,4.65,5.61c0.73-1.27-0.42-5.5-0.47-7.02c-0.08-2.44-0.02-4.89,0.15-7.33c4.5-11.08,9.38-23.54,7.97-35.55c1.56-4.88,2.96-9.9,5.51-14.37c186.12,198.47,187.97,195.81,187.35,193.02z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M327.75,254.45
						c-9.88-13.53-15.84-29.9-16.97-46.62c-0.29-4.35-0.17-9.06,2.41-12.57c2-2.72,5.17-4.3,8.21-5.77c2.82-1.36,5.66-2.74,8.7-3.48
						c7.85-1.92,16.61,0.81,22.13,6.71c3.45,3.69,5.61,8.36,7.72,12.95c1.8,3.93,3.63,7.94,4.13,12.23
						c0.58,5.04-0.68,10.37,1.24,15.06c1.54,3.78,5.04,6.8,5.42,10.86c0.3,3.23,1.03,9.28,0.59,12.49
						c-0.56,4.15,0.64,8.23,2.16,12.13c1.79,4.57,2.89,7.21,4.5,11.85c2.68,7.74,1.75,13.95,6.53,22.22
						c2.2,3.81,2.63,9.42-0.59,12.41c-1.72,1.6-4.22,1.98-6.55,2.28c-3.42,0.45-7.28,0.77-9.92-1.44c-1.37-1.14-2.2-2.82-3.58-3.96
						c-2.86-2.37-7.31-1.85-10.22-4.16c-3.39-2.69-3.38-8.14-6.63-11c-2.58-2.26-6.76-2.45-8.71-5.28
						c-2.58-3.76,0.66-9.22-1.24-13.36c-1.24-2.69-4.25-4.01-6.31-6.13C326.21,267.16,327.04,259.61,327.75,254.45z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M312.65,193.02c2.26-7.17,15.43,21.86,15.83,24.32c14.49,22.35,20.09,49.22,32.1,72.9c0.55,1.1,1.1,2.44,0.48,3.5c-2.33,2.77-5.89-0.69-7.32-2.9c-7.48-10.01-14.74-20.24-20.65-31.23c-0.27,1.99-1.92,9.11-4.65,5.61c-0.73-1.27,0.42-5.5,0.47-7.02c0.08-2.44,0.02-4.89-0.15-7.33c-4.5-11.08-9.38-23.54-7.97-35.55c-1.56-4.88-2.96-9.9-5.51-14.37C313.88,198.47,312.03,195.81,312.65,193.02z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_T4LrT = {
  id: "ear_shape_T4LrT",
  name: "Ears 9 (Bumpy)",
  description: "Bumpy, large pointed ears on the top of the head",
  tags: ["pointed", "top", "bumpy"],
  preview: {
    viewbox: "110 110 120 120",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M179.75 211.18c7.03-.7 14.04-3.2 19.19-8.02 5.16-4.82 8.2-12.15 6.9-19.09-.67-3.56-2.39-6.86-3.03-10.42-.49-2.73-.43-5.82-2.29-7.87-1.94-2.13-5.27-2.35-7.51-4.16-2.94-2.39-3.39-7.02-6.48-9.21-3.2-2.27-8.09-1.07-11.04-3.65-1.99-1.73-2.51-4.72-4.49-6.46-2.52-2.22-6.45-1.68-9.46-3.17-3.86-1.91-5.31-6.53-7.09-10.46-1.12-2.46-2.5-4.79-4.12-6.95-1.12-1.49-2.39-2.93-4.03-3.82-1.64-.88-3.74-1.11-5.36-.19-2.02 1.15-2.77 3.7-2.88 6.03-.11 2.32.23 4.7-.38 6.95-1.04 3.84-4.72 6.71-5.17 10.67-.38 3.43 1.79 6.66 1.92 10.1.1 2.74-1.09 5.33-1.95 7.93-.87 2.6-1.39 5.58-.07 7.97.83 1.51 2.29 2.57 3.24 4.01 3.27 4.96-.52 12.37 2.84 17.28 1.71 2.51 4.84 3.62 7.03 5.73 3.48 3.36 4.34 9.03 8.37 11.71 3.03 2.01 7.01 1.75 10.41 3.04 2.89 1.1 5.26 3.28 8.16 4.36s6.91.45 7.29-2.31z" />
      ),
      basic: {
        dark: (
          <path d="M203.39 172.86c-1.76-1.27-4.14.28-5.7 1.79-5.51 1.85-10.26-12.36-18.28-12.01-24.6-5.96-19.63 34.87-2.55 39.13-3.88 4.48-4.43 20.47 3.16 10.29 5.13-13.6 24.54-25.32 23.37-39.2z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M320.23 211.16c-7.03-.7-14.04-3.2-19.19-8.02s-8.2-12.15-6.9-19.09c.67-3.56 2.39-6.86 3.03-10.42.49-2.73.43-5.82 2.29-7.87 1.94-2.13 5.27-2.35 7.51-4.16 2.94-2.39 3.39-7.02 6.48-9.21 3.2-2.27 8.09-1.07 11.04-3.65 1.99-1.73 2.51-4.72 4.49-6.46 2.52-2.22 6.45-1.68 9.46-3.17 3.86-1.91 5.31-6.53 7.09-10.46 1.12-2.46 2.5-4.79 4.12-6.95 1.12-1.49 2.39-2.93 4.03-3.82 1.64-.88 3.74-1.11 5.36-.19 2.02 1.15 2.77 3.7 2.88 6.03.11 2.32-.23 4.7.38 6.95 1.04 3.84 4.72 6.71 5.17 10.67.38 3.43-1.79 6.66-1.92 10.1-.1 2.74 1.09 5.33 1.95 7.93s1.39 5.58.07 7.97c-.83 1.51-2.29 2.57-3.24 4.01-3.27 4.96.52 12.37-2.84 17.28-1.71 2.51-4.84 3.62-7.03 5.73-3.48 3.36-4.34 9.03-8.37 11.71-3.03 2.01-7.01 1.75-10.41 3.04-2.89 1.1-5.26 3.28-8.16 4.36s-6.91.45-7.29-2.31z" />
      ),
      basic: {
        dark: (
          <path d="M296.61 172.86c1.76-1.27 4.14.28 5.7 1.79 5.51 1.85 10.26-12.36 18.28-12.01 24.6-5.96 19.63 34.87 2.55 39.13 3.88 4.48 4.43 20.47-3.16 10.29-5.13-13.6-24.54-25.32-23.37-39.2z" />
        ),
      },
    },
  },
};

const ear_shape_34nrT = {
  id: "ear_shape_34nrT",
  name: "Ears 10 (Bumpy)",
  description: "Bumpy, large round ears on the top of the head",
  tags: ["rounded", "top", "bumpy"],
  preview: {
    viewbox: "110 110 120 120",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M177.17 211.76c7.07-.12 14.02-3.46 18.53-8.91s6.5-12.89 5.3-19.86c-.62-3.64-2.07-7.3-1.33-10.92.63-3.07 2.82-6.02 2.01-9.05-.77-2.9-4.01-4.54-5.08-7.35-1.39-3.62 1.23-8.01-.56-11.45-1.63-3.13-5.84-3.6-8.86-5.42-4.38-2.64-6.81-8.57-11.87-9.33-2.66-.4-5.27.82-7.94 1.08-5.7.56-11.91-3.14-16.87-.27-2.74 1.58-4.2 4.8-6.89 6.47-2.85 1.77-6.63 1.53-9.43 3.38-2.72 1.8-3.91 5.17-4.45 8.38-.54 3.21-.62 6.55-1.86 9.56-1.54 3.74-4.88 7.39-3.61 11.23.88 2.64 3.72 4.2 4.8 6.77 1.76 4.19-1.66 9.64 1.03 13.31 2.01 2.74 6.24 2.68 8.79 4.92 2.94 2.59 3.2 7.84 6.85 9.28 2.26.89 4.81-.18 7.22.12 4.51.58 7 5.53 10.92 7.84 4.57 2.71 10.09.27 13.3.22z" />
      ),
      basic: {
        dark: (
          <path d="M200.96 176.58c-1.42-4.06-6.29 3.66-8.04 4.85-11.39-49.86-64.66 11.61-16.31 23.22-1.14 2.73-3.44 5.54-3.02 8.6 1.66 6.93 8.61-5.71 9.53-7.09 5.4-9.92 15.19-18.67 17.84-29.58z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M322.92 211.76c-7.07-.12-14.02-3.46-18.53-8.91s-6.5-12.89-5.3-19.86c.62-3.64 2.07-7.3 1.33-10.92-.63-3.07-2.82-6.02-2.01-9.05.77-2.9 4.01-4.54 5.08-7.35 1.39-3.62-1.23-8.01.56-11.45 1.63-3.13 5.84-3.6 8.86-5.42 4.38-2.64 6.81-8.57 11.87-9.33 2.66-.4 5.27.82 7.94 1.08 5.7.56 11.91-3.14 16.87-.27 2.74 1.58 4.2 4.8 6.89 6.47 2.85 1.77 6.63 1.53 9.43 3.38 2.72 1.8 3.91 5.17 4.45 8.38.54 3.21.62 6.55 1.86 9.56 1.54 3.74 4.88 7.39 3.61 11.23-.88 2.64-3.72 4.2-4.8 6.77-1.76 4.19 1.66 9.64-1.03 13.31-2.01 2.74-6.24 2.68-8.79 4.92-2.94 2.59-3.2 7.84-6.85 9.28-2.26.89-4.81-.18-7.22.12-4.51.58-7 5.53-10.92 7.84-4.56 2.71-10.08.27-13.3.22z" />
      ),
      basic: {
        dark: (
          <path d="M299.04 176.58c1.42-4.06 6.29 3.66 8.04 4.85 11.39-49.86 64.66 11.61 16.31 23.22 1.14 2.73 3.44 5.54 3.02 8.6-1.66 6.93-8.61-5.71-9.53-7.09-5.4-9.92-15.19-18.67-17.84-29.58z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_P4UrT = {
  id: "ear_shape_P4UrT",
  name: "Ears 11 (Bumpy)",
  description: "Bumpy, small finned ears with three points",
  tags: ["pointed", "side", "bumpy"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M173.53,272.75c5.93-3.54,9.87-10.1,10.16-16.91c0.29-6.81-3.09-13.67-8.7-17.68
						c-2.04-1.46-4.38-2.6-5.94-4.54c-1.82-2.28-2.43-5.53-4.87-7.15c-2.41-1.61-5.77-1.03-8.34-2.39c-3.28-1.74-4.3-6.06-7.39-8.12
						c-2.83-1.89-6.65-1.42-9.82-2.68c-3.93-1.57-6.78-5.77-11.03-5.92c-4.31-0.15-7.65,4.59-6.94,8.77
						c0.59,3.5,3.19,6.27,5.19,9.23s3.43,6.91,1.56,9.95c-1.37,2.23-4.28,3.48-4.95,6c-0.26,0.97-0.14,2.03-0.55,2.95
						c-0.99,2.2-4.21,2.31-5.93,4.02c-1.54,1.53-1.52,4.1-0.54,6.04s2.71,3.37,4.4,4.75c2.79,2.28,5.58,4.57,8.36,6.85
						c1.9,1.56,3.94,3.36,4.19,5.78c0.26,2.53-1.52,4.76-2.69,7.02s-1.47,5.6,0.8,6.84c1.93,1.06,4.32-0.19,6.53-0.09
						c2.53,0.11,4.68,1.98,7.19,2.32c5.2,0.7,9.07-5.13,14.27-5.81c2.49-0.33,5.05,0.57,7.51,0.1c2.23-0.43,4.11-1.97,5.42-3.8
						C172.72,276.44,173.52,274.33,173.53,272.75z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M171.49,261.13c0.42-7.47,3.21-47.18-5.12-19.35c-3.31-2.88-30.41-23.21-26.49-15.66c4.12,3.13,7.75,6.89,10.73,11.12c5.4,6.04,2.3,13.19-1.85,19.1c-4.59,6.34-2.18,16.62,6.78,14.4c4.28-0.62,6.79-5.44,10.86-6.09c169.54,289.57,176.14,279.22,171.49,261.13z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M326.46,272.75
						c-5.93-3.54-9.87-10.1-10.16-16.91c-0.29-6.81,3.09-13.67,8.7-17.68c2.04-1.46,4.38-2.6,5.94-4.54
						c1.82-2.28,2.43-5.53,4.87-7.15c2.41-1.61,5.77-1.03,8.34-2.39c3.28-1.74,4.3-6.06,7.39-8.12c2.83-1.89,6.65-1.42,9.82-2.68
						c3.93-1.57,6.78-5.77,11.03-5.92c4.31-0.15,7.65,4.59,6.94,8.77c-0.59,3.5-3.19,6.27-5.19,9.23s-3.43,6.91-1.56,9.95
						c1.37,2.23,4.28,3.48,4.95,6c0.26,0.97,0.14,2.03,0.55,2.95c0.99,2.2,4.21,2.31,5.93,4.02c1.54,1.53,1.52,4.1,0.54,6.04
						c-0.98,1.93-2.71,3.37-4.4,4.75c-2.79,2.28-5.58,4.57-8.36,6.85c-1.9,1.56-3.94,3.36-4.19,5.78c-0.26,2.53,1.52,4.76,2.69,7.02
						s1.47,5.6-0.8,6.84c-1.93,1.06-4.32-0.19-6.53-0.09c-2.53,0.11-4.68,1.98-7.19,2.32c-5.2,0.7-9.07-5.13-14.27-5.81
						c-2.49-0.33-5.05,0.57-7.51,0.1c-2.23-0.43-4.11-1.97-5.42-3.8C327.27,276.44,326.46,274.33,326.46,272.75z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M328.51,261.13c-0.42-7.47-3.21-47.18,5.12-19.35c3.31-2.88,30.41-23.21,26.49-15.66c-4.12,3.13-7.75,6.89-10.73,11.12c-5.4,6.04-2.3,13.19,1.85,19.1c4.59,6.34,2.18,16.62-6.78,14.4c-4.28-0.62-6.79-5.44-10.86-6.09C330.46,289.57,323.86,279.22,328.51,261.13z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_k4BrT = {
  id: "ear_shape_k4BrT",
  name: "Ears 12 (Bumpy)",
  description: "Bumpy, tall finned ears with three points",
  tags: ["pointed", "side", "bumpy"],
  preview: {
    viewbox: "100 180 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M171.6 270.39c7.51-9.08 7.76-23.4.58-32.74-1.71-2.22-3.85-4.32-4.39-7.07-.46-2.32.29-4.87-.75-7-1.39-2.85-5.29-3.56-7.12-6.16-2.13-3.03-.73-7.51-2.71-10.64-1.39-2.2-4.13-3.21-5.65-5.32-1.86-2.58-1.5-6.19-2.97-9.01-1.43-2.77-4.36-4.37-7.13-5.81-1.45-.76-2.96-1.53-4.59-1.63-1.63-.11-3.44.63-4.06 2.15-1.19 2.88 2.42 5.78 2.01 8.87-.25 1.92-2 3.38-2.24 5.3-.51 4.18 6.03 6.31 6.19 10.52.11 2.95-3.09 5.52-2.32 8.38.55 2.02 2.91 3.16 3.37 5.2.53 2.32-1.66 4.35-3.8 5.4-2.13 1.05-4.64 1.88-5.77 3.98-.58 1.08-.71 2.37-1.34 3.43-1.01 1.7-3.06 2.4-4.69 3.53-1.62 1.13-2.91 3.47-1.65 5 .98 1.18 2.88 1.08 4.05 2.07 1.12.94 1.3 2.64 2.31 3.7 2.2 2.33 6.71.33 9 2.58 1.74 1.71 1.2 5.04 3.19 6.44.95.66 2.2.68 3.23 1.2 2.24 1.14 2.63 4.13 2.72 6.64.13 3.4.27 6.79.4 10.19.05 1.14.14 2.4.96 3.19 1.06 1.03 2.82.78 4.19.21s2.74-1.38 4.22-1.26c2.22.18 4.5 2.41 6.32 1.12 1.41-1 .99-3.2 1.71-4.77.84-1.82 3.01-2.49 4.75-3.47 1.76-1 3.34-3.25 1.98-4.22z" />
      ),
      basic: {
        dark: (
          <path d="M172 262.52c-.52-6.61-.56-13.25-.14-19.87.28-4.29.68-8.9-1.51-12.6-1.6 3.78-2.79 7.73-3.56 11.76-5.23-8.32-10.95-16.34-16.37-24.54.04 6.81 4.75 12.83 5.38 19.61.99 8.48-5.69 17.86-.77 25.81 1.19 1.59 3.22 2.65 5.17 2.29 2.36-.57 4.3-2.63 6.6-.74.29 1.88.61 3.76.94 5.64.26 1.47.55 3.03 1.57 4.12 2.31 2.48 3.76.47 3.78-1.76.05-3.16-.84-6.55-1.09-9.72z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M328.4 270.38c-7.51-9.08-7.76-23.4-.58-32.74 1.71-2.22 3.85-4.32 4.39-7.07.46-2.32-.29-4.87.75-7 1.39-2.85 5.29-3.56 7.12-6.16 2.13-3.03.73-7.51 2.71-10.64 1.39-2.2 4.13-3.21 5.65-5.32 1.86-2.58 1.5-6.19 2.97-9.01 1.43-2.77 4.36-4.37 7.13-5.81 1.45-.76 2.96-1.53 4.59-1.63 1.63-.11 3.44.63 4.06 2.15 1.19 2.88-2.42 5.78-2.01 8.87.25 1.92 2 3.38 2.24 5.3.51 4.18-6.03 6.31-6.19 10.52-.11 2.95 3.09 5.52 2.32 8.38-.55 2.02-2.91 3.16-3.37 5.2-.53 2.32 1.66 4.35 3.8 5.4 2.13 1.05 4.64 1.88 5.77 3.98.58 1.08.71 2.37 1.34 3.43 1.01 1.7 3.06 2.4 4.69 3.53s2.91 3.47 1.65 5c-.98 1.18-2.88 1.08-4.05 2.07-1.12.94-1.3 2.64-2.31 3.7-2.2 2.33-6.71.33-9 2.58-1.74 1.71-1.2 5.04-3.19 6.44-.95.66-2.2.68-3.23 1.2-2.24 1.14-2.63 4.13-2.72 6.64-.13 3.4-.27 6.79-.4 10.19-.05 1.14-.14 2.4-.96 3.19-1.06 1.03-2.82.78-4.19.21-1.37-.56-2.74-1.38-4.22-1.26-2.22.18-4.5 2.41-6.32 1.12-1.41-1-.99-3.2-1.71-4.77-.84-1.82-3.01-2.49-4.75-3.47-1.76-.99-3.34-3.24-1.98-4.22z" />
      ),
      basic: {
        dark: (
          <path d="M328 262.52c.52-6.61.56-13.25.14-19.87-.28-4.29-.68-8.9 1.51-12.6 1.6 3.78 2.79 7.73 3.56 11.76 5.23-8.32 10.95-16.34 16.37-24.54-.04 6.81-4.75 12.83-5.38 19.61-.99 8.48 5.69 17.86.77 25.81-1.19 1.59-3.22 2.65-5.17 2.29-2.36-.57-4.3-2.63-6.6-.74-.29 1.88-.61 3.76-.94 5.64-.26 1.47-.55 3.03-1.57 4.12-2.31 2.48-3.76.47-3.78-1.76-.05-3.16.84-6.55 1.09-9.72z" />
        ),
      },
    },
  },
};

const ear_shape_y4TrT = {
  id: "ear_shape_y4TrT",
  name: "Ears 13 (Bumpy)",
  description: "Bumpy, large and wide pointed ears that droop down",
  tags: ["pointed", "side", "drooping", "large", "bumpy"],
  preview: {
    viewbox: "80 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M174.28 278.85c5.29-8.59 10.61-17.25 14.05-26.73 3.44-9.48 4.89-19.96 2.25-29.69-1.35-4.99-3.84-9.78-7.71-13.2s-9.24-5.33-14.32-4.38c-3.51.66-6.76 2.61-10.32 2.68-3.2.07-6.43-1.38-9.51-.51-4.41 1.25-6.27 6.49-9.95 9.23-3.01 2.24-7.29 2.82-9.5 5.86-2.18 2.99-1.54 7.31-3.52 10.43-2.28 3.59-7.34 4.57-9.71 8.1-2.48 3.69-1.18 8.78-2.86 12.9-1.92 4.71-7.37 7.32-9.11 12.1-1.69 4.64.58 10.09-1.39 14.62-1.46 3.35-5.01 5.56-6.02 9.07-1.13 3.91 1.27 7.99 1.12 12.06-.09 2.63-1.25 5.13-1.48 7.75-.23 2.62.97 5.77 3.56 6.26 1.74.33 3.43-.66 4.93-1.6 5.88-3.71 11.76-7.43 17.63-11.14 2.92-1.84 6.94-3.63 9.52-1.34 1.27 1.13 2.09 3.17 3.78 3.13 1.35-.03 2.27-1.45 3.58-1.78 2.86-.72 5.81 2.59 10.54.88 2.82-1.02 5.92-5.81 8.54-7.26 1.83-1.02 4.15-.97 5.88-2.16 2.27-1.55 2.81-4.64 4.43-6.87 1.61-2.21 4.21-3.46 6.11-5.42 1.91-1.96 2.92-5.44-.52-2.99z" />
      ),
      basic: {
        dark: (
          <path d="M182.35 204.02c-7.83-2.79-10.5 10.27-12.57 15.8-18.91 2.78-46.55 38.88-44.84 57.34 25.25-39.65 20.5-6.61 40.79-16.35 1.42 8.25 1.52 18.36 6.69 25.12 5.73 3.42 2.57-9.97 2.34-11.78-.81-6.4-1.46-12.83-1.7-19.28-.47-12.69.65-25.58 5.19-37.53 1.4-4.34 4.35-8.75 4.1-13.32z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M325.73 278.86c-5.29-8.59-10.61-17.25-14.05-26.73-3.44-9.48-4.89-19.96-2.25-29.69 1.35-4.99 3.84-9.78 7.71-13.2s9.24-5.33 14.32-4.38c3.51.66 6.76 2.61 10.32 2.68 3.2.07 6.43-1.38 9.51-.51 4.41 1.25 6.27 6.49 9.95 9.23 3.01 2.24 7.29 2.82 9.5 5.86 2.18 2.99 1.54 7.31 3.52 10.43 2.28 3.59 7.34 4.57 9.71 8.1 2.48 3.69 1.18 8.78 2.86 12.9 1.92 4.71 7.37 7.32 9.11 12.1 1.69 4.64-.58 10.09 1.39 14.62 1.46 3.35 5.01 5.56 6.02 9.07 1.13 3.91-1.27 7.99-1.12 12.06.09 2.63 1.25 5.13 1.48 7.75.23 2.62-.97 5.77-3.56 6.26-1.74.33-3.43-.66-4.93-1.6-5.88-3.71-11.76-7.43-17.63-11.14-2.92-1.84-6.94-3.63-9.52-1.34-1.27 1.13-2.09 3.17-3.78 3.13-1.35-.03-2.27-1.45-3.58-1.78-2.86-.72-5.81 2.59-10.54.88-2.82-1.02-5.92-5.81-8.54-7.26-1.83-1.02-4.15-.97-5.88-2.16-2.27-1.55-2.81-4.64-4.43-6.87-1.61-2.21-4.21-3.46-6.11-5.42-1.91-1.96-2.92-5.43.52-2.99z" />
      ),
      basic: {
        dark: (
          <path d="M317.65 204.02c7.83-2.79 10.5 10.27 12.57 15.8 18.91 2.78 46.55 38.88 44.84 57.34-25.25-39.65-20.5-6.61-40.79-16.35-1.42 8.25-1.52 18.36-6.69 25.12-5.73 3.42-2.57-9.97-2.34-11.78.81-6.4 1.46-12.83 1.7-19.28.47-12.69-.65-25.58-5.19-37.53-1.4-4.34-4.35-8.75-4.1-13.32z" />
        ),
      },
    },
  },
};

const ear_shape_84ArT = {
  id: "ear_shape_84ArT",
  name: "Ears 14 (Bumpy)",
  description: "Bumpy, wide ears with a long dangling lobe",
  tags: ["rounded", "side", "bumpy"],
  preview: {
    viewbox: "100 200 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M172.51 273.55c6.92-8.19 8.77-20.33 4.62-30.22-1.44-3.43-3.54-6.58-4.72-10.11-1.2-3.57-1.72-7.87-4.91-9.87-2.55-1.6-5.99-1.08-8.61-2.57-1.72-.97-2.87-2.68-4.17-4.18-1.3-1.5-2.99-2.9-4.97-2.89-3.6.02-5.48 4.39-8.69 6.03-2.58 1.31-5.91.75-8.29 2.41-2.91 2.03-3.02 6.29-5 9.22-1.4 2.07-3.64 3.36-5.65 4.84-2.01 1.48-3.95 3.44-4.21 5.92-.38 3.53 2.75 6.57 3 10.11.26 3.69-2.64 7.32-1.53 10.84 1.02 3.24 5.07 4.92 5.79 8.24.52 2.4-.81 5.26.73 7.17 1.76 2.21 5.43.93 7.98 2.13 2.3 1.08 3.15 3.88 3.6 6.38s.84 5.28 2.78 6.92c1.38 1.18 3.39 1.62 4.4 3.12.95 1.42.7 3.32 1.33 4.91 1.22 3.09 5.1 3.94 8.4 4.35 3.21.4 7.04.57 9.03-1.98 1.23-1.56 1.36-3.75 2.47-5.4 1.72-2.56 5.5-3.43 6.61-6.31 1.13-2.94-1.2-6.37-.1-9.33.49-1.33 1.61-2.34 2.19-3.63 1.3-2.93-.47-6.26-2.08-6.1z" />
      ),
      basic: {
        dark: (
          <path d="M173.79 265.4c-.58-6.18 2.73-51.43-5.77-31.7-10.93-9.09-27.52-11.09-34.32 3.46-.08.81.57 1.73 1.38 1.6 14.71-18.41 30.18 8.4 14.75 20.93-7.51 5.69-6.5 20.01 2.64 22.88 3.85-.65 3.57-5.57 4.81-8.43 8-10.93 10.86 4.09 13.61 10.1 6.06-5.2 2.92-11.63 2.9-18.84z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M327.49 273.55c-6.92-8.19-8.77-20.33-4.62-30.22 1.44-3.43 3.54-6.58 4.72-10.11 1.2-3.57 1.72-7.87 4.91-9.87 2.55-1.6 5.99-1.08 8.61-2.57 1.72-.97 2.87-2.68 4.17-4.18 1.3-1.5 2.99-2.9 4.97-2.89 3.61.02 5.48 4.39 8.69 6.03 2.58 1.31 5.91.75 8.29 2.41 2.91 2.03 3.02 6.29 5 9.22 1.4 2.07 3.64 3.36 5.65 4.84 2.01 1.48 3.95 3.44 4.21 5.92.38 3.53-2.75 6.57-3 10.11-.26 3.69 2.64 7.32 1.53 10.84-1.02 3.24-5.07 4.92-5.79 8.24-.52 2.4.81 5.26-.73 7.17-1.76 2.21-5.43.93-7.98 2.13-2.3 1.08-3.15 3.88-3.6 6.38-.44 2.5-.84 5.28-2.78 6.92-1.38 1.18-3.39 1.62-4.4 3.12-.95 1.42-.7 3.32-1.33 4.91-1.22 3.09-5.1 3.94-8.4 4.35-3.21.4-7.04.57-9.03-1.98-1.23-1.56-1.36-3.75-2.47-5.4-1.72-2.56-5.5-3.43-6.61-6.31-1.13-2.94 1.2-6.37.1-9.33-.49-1.33-1.61-2.34-2.19-3.63-1.3-2.93.47-6.26 2.08-6.1z" />
      ),
      basic: {
        dark: (
          <path d="M326.21 265.4c.58-6.18-2.73-51.43 5.77-31.7 10.93-9.09 27.52-11.09 34.32 3.46.08.81-.57 1.73-1.38 1.6-14.71-18.41-30.18 8.4-14.75 20.93 7.51 5.69 6.5 20.01-2.64 22.88-3.85-.65-3.57-5.57-4.81-8.43-8-10.93-10.86 4.09-13.61 10.1-6.06-5.2-2.92-11.63-2.9-18.84z" />
        ),
      },
    },
  },
};

// TODO: Tweak right manually - looked strange on SVGomg
const ear_shape_g4orT = {
  id: "ear_shape_g4orT",
  name: "Ears 15 (Bumpy)",
  description: "Bumpy, wide ears with a slight point",
  tags: ["pointed", "side", "bumpy"],
  preview: {
    viewbox: "90 200 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M177 273.73c4.62-5.55 8.69-11.87 9.89-19s-1-15.17-6.89-19.37c-2.69-1.92-6.01-2.97-8.36-5.3-2.14-2.11-3.49-5.26-6.32-6.26-2.73-.97-5.84.47-8.6-.39-3.12-.97-4.86-4.54-7.97-5.55-4.24-1.37-8.45 2.64-12.9 2.55-3.19-.06-6.2-2.22-9.35-1.72-3.44.55-5.55 3.98-8.47 5.87-2.37 1.54-5.27 2.04-7.74 3.42s-4.56 4.29-3.5 6.91c.98 2.42 4.14 3.34 5.24 5.71 1.24 2.65-.61 5.8 0 8.66.56 2.6 3 4.34 5.39 5.51 2.39 1.17 5.03 2.1 6.77 4.12 3.85 4.46 1.95 12.52 6.75 15.92 2.09 1.48 4.91 1.58 7.03 3.01 3.83 2.59 4.54 8.91 8.98 10.21 2.63.77 5.72-.63 8.07.77 1.15.68 1.6 2.26 2.81 2.82 3.89 1.81 7.59-.87 10.09-3.68 2.32-2.6 2.4-3.18 4.05-6.24 1.12-2.08 2.93-3.7 4.39-5.56s2.61-4.22.64-2.41z" />
      ),
      basic: {
        dark: (
          <path d="M176 279.78c-2.14-12.51-3.22-25.21-3.23-37.9 0-2.77 1.98-13.57-1.11-14.73-3.71-1.39-5.3 5.41-5.89 9.55-6.21-4.5-28.93-13.13-34.78-7.29 36.84-.49 6.66 33.35 17.02 43.56 7.66 5.7 12-6.43 18.41-7.33 1.09 7.26 2.03 16.3 8.47 20.62 1.76-1.49 1.49-4.21 1.11-6.48z" />
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M323,273.73c-4.62-5.55-8.69-11.87-9.89-19c-1.2-7.12,1-15.17,6.89-19.37
						c2.69-1.92,6.01-2.97,8.36-5.3c2.14-2.11,3.49-5.26,6.32-6.26c2.73-0.97,5.84,0.47,8.6-0.39c3.12-0.97,4.86-4.54,7.97-5.55
						c4.24-1.37,8.45,2.64,12.9,2.55c3.19-0.06,6.2-2.22,9.35-1.72c3.44,0.55,5.55,3.98,8.47,5.87c2.37,1.54,5.27,2.04,7.74,3.42
						s4.56,4.29,3.5,6.91c-0.98,2.42-4.14,3.34-5.24,5.71c-1.24,2.65,0.61,5.8,0,8.66c-0.56,2.6-3,4.34-5.39,5.51
						c-2.39,1.17-5.03,2.1-6.77,4.12c-3.85,4.46-1.95,12.52-6.75,15.92c-2.09,1.48-4.91,1.58-7.03,3.01
						c-3.83,2.59-4.54,8.91-8.98,10.21c-2.63,0.77-5.72-0.63-8.07,0.77c-1.15,0.68-1.6,2.26-2.81,2.82
						c-3.89,1.81-7.59-0.87-10.09-3.68c-2.32-2.6-2.4-3.18-4.05-6.24c-1.12-2.08-2.93-3.7-4.39-5.56
						C322.19,274.29,321.03,271.92,323,273.73z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M324,279.78c2.14-12.51,3.22-25.21,3.23-37.9c0-2.77-1.98-13.57,1.11-14.73c3.71-1.39,5.3,5.41,5.89,9.55c6.21-4.5,28.93-13.13,34.78-7.29c-36.84-0.49-6.66,33.35-17.02,43.56c-7.66,5.7-12-6.43-18.41-7.33c-1.09,7.26-2.03,16.3-8.47,20.62c323.35,284.77,323.62,282.05,324,279.78z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_b4DrT = {
  id: "ear_shape_b4DrT",
  name: "Ears 16 (Bumpy)",
  description: "Bumpy, small ears with a slight point that point upwards",
  tags: ["pointed", "side", "bumpy"],
  preview: {
    viewbox: "110 190 100 100",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M174.85 276.18c3.24-5 5.89-10.51 6.72-16.41.82-5.9-.35-12.25-4.08-16.89-2.21-2.75-5.28-4.91-6.66-8.16-1.09-2.57-1.01-5.6-2.54-7.93-2.19-3.34-7.07-4.31-8.83-7.89-1.47-2.99-.38-7.14-2.82-9.4-1.66-1.54-4.24-1.45-6.29-2.42-2.33-1.1-3.77-3.43-5.38-5.44-1.61-2.01-3.87-3.94-6.43-3.65-3.01.34-4.77 3.46-6.04 6.21-1.93 4.17-3.91 8.79-2.71 13.23.87 3.21 3.32 5.87 3.96 9.13.93 4.75-2.15 9.8-.54 14.36 1.27 3.6 5.1 5.73 6.77 9.16 1.41 2.9 1.09 6.3.98 9.52-.11 3.22.14 6.76 2.34 9.12 1.95 2.1 5 2.77 7.26 4.52 2.14 1.66 3.44 4.15 5.22 6.2 2.81 3.25 7.14 5.4 11.38 4.74 4.24-.64 7.98-4.61 7.69-8z" />
      ),
      basic: {
        dark: (
          <path d="M175.05 279.45c-3.65-14.96-2.45-30.34-2.38-45.53-.15-4.23-4.02-3.05-4.54.61-.52 2.49-.95 5.01-1.3 7.54-4.75-12.6-13.01-26.72-25.46-32.51 20.85 19.68-.34 52.86 17.15 57.43 2.73.2 5.84-1.4 8.09-.08 1.37 5.69 1.55 13.55 6.33 17.42 4.07 1.63 2.48-3.27 2.11-4.88z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M325.15 276.18c-3.24-5-5.89-10.51-6.71-16.41-.82-5.9.35-12.25 4.08-16.89 2.21-2.75 5.28-4.91 6.66-8.16 1.09-2.57 1.01-5.6 2.54-7.93 2.19-3.34 7.07-4.31 8.83-7.89 1.47-2.99.38-7.14 2.82-9.4 1.66-1.54 4.24-1.45 6.29-2.42 2.33-1.1 3.77-3.43 5.38-5.44 1.61-2.01 3.87-3.94 6.43-3.65 3.01.34 4.77 3.46 6.04 6.21 1.93 4.17 3.91 8.79 2.71 13.23-.87 3.21-3.32 5.87-3.96 9.13-.93 4.75 2.15 9.8.54 14.36-1.27 3.6-5.1 5.73-6.77 9.16-1.41 2.9-1.09 6.3-.98 9.52.11 3.22-.14 6.76-2.34 9.12-1.95 2.1-5 2.77-7.26 4.52-2.14 1.66-3.44 4.15-5.22 6.2-2.81 3.25-7.14 5.4-11.38 4.74s-7.99-4.61-7.7-8z" />
      ),
      basic: {
        dark: (
          <path d="M324.95 279.45c3.65-14.96 2.45-30.34 2.38-45.53.15-4.23 4.02-3.05 4.54.61.52 2.49.95 5.01 1.3 7.54 4.75-12.6 13.01-26.72 25.46-32.51-20.85 19.68.34 52.86-17.15 57.43-2.73.2-5.84-1.4-8.09-.08-1.37 5.69-1.55 13.55-6.33 17.42-4.07 1.63-2.48-3.27-2.11-4.88z" />
        ),
      },
    },
  },
};

// TODO: Tweak right manually - looked strange on SVGomg
const ear_shape_o4ArT = {
  id: "ear_shape_o4ArT",
  name: "Ears 17 (Bumpy)",
  description: "Bumpy, small ears with a slight point",
  tags: ["pointed", "side", "bumpy"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M175.16,283.08c4.63-8.64,8.94-17.9,8.89-27.71
						c-0.05-9.51-4.47-18.91-11.76-25.01c-3.01-2.52-6.69-4.55-10.61-4.46c-2.81,0.07-5.52,1.22-8.33,1.26
						c-4.67,0.07-8.91-2.9-13.57-3.28c-4-0.33-8.12,1.41-10.67,4.52c-1.8,2.2-2.65,5.81-0.5,7.68c0.87,0.76,2.09,1.08,2.85,1.95
						c1.51,1.73,0.43,4.4,0.72,6.68c0.32,2.52,2.36,4.46,4.42,5.96c2.06,1.5,4.34,2.87,5.63,5.07c2.22,3.81,0.86,9.12,3.6,12.57
						c1.76,2.21,4.83,3.11,6.43,5.44c2.1,3.07,1.1,7.83,3.93,10.24c1.77,1.5,4.38,1.45,6.44,2.52c2.17,1.13,3.72,3.51,6.14,3.89
						c2.23,0.35,4.39-1.23,5.45-3.23C175.27,285.18,175.45,282.86,175.16,283.08z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M174.6,261.03c-3-11.42-3.38-22.02-0.77-33.5c-0.45-0.74-1.03-1.4-1.81-1.91c-2.59,1.45-3.7,4.56-4.44,7.43c-0.28,1.07-0.53,2.15-0.76,3.24c-2.24-1.11-4.72-1.67-7.19-2.07c-6.12-0.98-12.4-1.02-18.53-0.13c0.73,2.02,3.19,2.64,5.13,3.55c4.08,1.92,6.7,6.09,7.97,10.41c1.27,4.32,1.4,8.88,1.83,13.37c0.22,2.37,0.56,4.82,1.81,6.85c1.24,2.03,3.63,3.55,5.97,3.08c0.96-0.2,1.89-0.71,2.87-0.6c0.19,0.02,0.36,0.07,0.53,0.13c0.77,3.32,1.73,6.6,2.92,9.8c1.01,2.71,7.41,17.89,7.9,7.98c0.02-0.38,0.03-0.76,0.03-1.13c172.62,275.76,177.63,272.54,174.6,261.03z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M324.83,283.08
						c-4.63-8.64-8.94-17.9-8.89-27.71c0.05-9.51,4.47-18.91,11.76-25.01c3.01-2.52,6.69-4.55,10.61-4.46
						c2.81,0.07,5.52,1.22,8.33,1.26c4.67,0.07,8.91-2.9,13.57-3.28c4-0.33,8.12,1.41,10.67,4.52c1.8,2.2,2.65,5.81,0.5,7.68
						c-0.87,0.76-2.09,1.08-2.85,1.95c-1.51,1.73-0.43,4.4-0.72,6.68c-0.32,2.52-2.36,4.46-4.42,5.96c-2.06,1.5-4.34,2.87-5.63,5.07
						c-2.22,3.81-0.86,9.12-3.6,12.57c-1.76,2.21-4.83,3.11-6.43,5.44c-2.1,3.07-1.1,7.83-3.93,10.24c-1.77,1.5-4.38,1.45-6.44,2.52
						c-2.17,1.13-3.72,3.51-6.14,3.89c-2.23,0.35-4.39-1.23-5.45-3.23C324.73,285.18,324.55,282.86,324.83,283.08z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M325.4,261.03c3-11.42,3.38-22.02,0.77-33.5c0.45-0.74,1.03-1.4,1.81-1.91c2.59,1.45,3.7,4.56,4.44,7.43c0.28,1.07,0.53,2.15,0.76,3.24c2.24-1.11,4.72-1.67,7.19-2.07c6.12-0.98,12.4-1.02,18.53-0.13c-0.73,2.02-3.19,2.64-5.13,3.55c-4.08,1.92-6.7,6.09-7.97,10.41c-1.27,4.32-1.4,8.88-1.83,13.37c-0.22,2.37-0.56,4.82-1.81,6.85c-1.24,2.03-3.63,3.55-5.97,3.08c-0.96-0.2-1.89-0.71-2.87-0.6c-0.19,0.02-0.36,0.07-0.53,0.13c-0.77,3.32-1.73,6.6-2.92,9.8c-1.01,2.71-7.41,17.89-7.9,7.98c-0.02-0.38-0.03-0.76-0.03-1.13C327.38,275.76,322.37,272.54,325.4,261.03z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_D46rT = {
  id: "ear_shape_D46rT",
  name: "Ears 18 (Bumpy)",
  description:
    "Bumpy, wide ears with a slight point that reach slightly upwards",
  tags: ["pointed", "side", "bumpy"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M178.62 291.26c6.45-6.96 9.99-16.54 9.62-26.02-.37-9.48-4.64-18.75-11.61-25.19-2.3-2.13-4.96-4.06-6.28-6.9-1.18-2.55-1.24-5.75-3.31-7.65-2.26-2.08-5.98-1.7-8.36-3.63-1.9-1.54-2.49-4.16-3.86-6.18-1.53-2.26-4.03-3.73-6.64-4.54-2.61-.8-5.36-1.01-8.09-1.21-2.74-.2-6-.17-7.59 2.07-2.05 2.88.33 6.85-.06 10.37-.34 3.13-2.91 5.82-2.76 8.97.14 3.01 2.71 5.34 3.58 8.22 1.42 4.71-1.84 10.16.41 14.54 1.21 2.35 3.81 3.84 4.71 6.33.82 2.25.05 4.82.8 7.1 1.22 3.69 5.71 5.06 8.22 8.02 2.59 3.06 3.16 7.94 6.74 9.76 2.08 1.06 4.61.71 6.83 1.46 3.43 1.16 5.54 4.7 8.82 6.23 3.97 1.84 9.26-.06 8.83-1.75z" />
      ),
      basic: {
        dark: (
          <path d="M176.9 284.23c-1.21-4.1-2.79-8.36-3.01-12.36-5.07-11.16-4.58-23.8-2-35.8-.77-5.21-4.28-6.51-5.24.94-6.7-4.73-12.26-14.39-20.9-14.87 7.8 8.65 11.84 19.51 7.87 30.8-4.48 11.03 2.28 25.96 15.55 22.1 1.86 6.36 2.46 13.96 7.19 18.92 5.6 3.18 1.27-7.35.54-9.73z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M321.38 291.27c-6.45-6.96-9.99-16.54-9.62-26.02.37-9.48 4.64-18.75 11.61-25.19 2.3-2.13 4.96-4.06 6.28-6.9 1.18-2.55 1.24-5.75 3.31-7.65 2.26-2.08 5.98-1.7 8.36-3.63 1.9-1.54 2.49-4.16 3.86-6.18 1.53-2.26 4.03-3.73 6.64-4.54 2.61-.8 5.36-1.01 8.09-1.21 2.74-.2 6-.17 7.59 2.07 2.05 2.88-.33 6.85.06 10.37.34 3.13 2.91 5.82 2.76 8.97-.14 3.01-2.71 5.34-3.58 8.22-1.42 4.71 1.84 10.16-.41 14.54-1.21 2.35-3.81 3.84-4.71 6.33-.82 2.25-.05 4.82-.8 7.1-1.22 3.69-5.71 5.06-8.22 8.02-2.59 3.06-3.16 7.94-6.74 9.76-2.08 1.06-4.61.71-6.83 1.46-3.43 1.16-5.54 4.7-8.82 6.23-3.97 1.84-9.26-.06-8.83-1.75z" />
      ),
      basic: {
        dark: (
          <path d="M323.1 284.23c1.21-4.1 2.79-8.36 3.01-12.36 5.07-11.16 4.58-23.8 2-35.8.77-5.21 4.28-6.51 5.24.94 6.7-4.73 12.26-14.39 20.9-14.87-7.8 8.65-11.84 19.51-7.87 30.8 4.48 11.03-2.28 25.96-15.55 22.1-1.86 6.36-2.46 13.96-7.19 18.92-5.6 3.18-1.27-7.35-.54-9.73z" />
        ),
      },
    },
  },
};

const ear_shape_k48rT = {
  id: "ear_shape_k48rT",
  name: "Ears 19 (Bumpy)",
  description: "Bumpy, large and wavy ears on the top of the head",
  tags: ["top", "bumpy"],
  preview: {
    viewbox: "90 75 150 150",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M183.93 206.04c7.02-3.62 13.77-9.95 13.65-17.85-.05-3.54-1.5-6.91-1.94-10.42-.46-3.66-.31-5.13-.67-8.8-.42-4.34-2.23-7.17-3.99-11.16-1.9-4.31-.58-12.18-3.51-15.87-1.95-2.45-7.13-4.68-8.08-7.67-.86-2.68-.42-5.74-1.77-8.21-2.09-3.83-7.52-4.66-9.92-8.31-2.55-3.88-.63-9.03-.93-13.67-.25-3.92-2.13-7.53-3.97-11-1.39-2.63-3.31-5.61-6.29-5.72-3.36-.13-5.51 3.43-6.91 6.49-1.77 3.89-3.58 7.93-3.59 12.2-.01 2.97.82 6.14-.51 8.8-.98 1.97-3.09 3.52-3.06 5.72.02 1.27.79 2.47.64 3.74-.21 1.8-2.22 2.87-4.02 2.92-1.81.05-3.56-.59-5.37-.74-3.39-.29-6.89 1.27-8.93 4s-2.56 6.52-1.32 9.69c1 2.57 3.07 4.78 3.32 7.53.29 3.13-1.86 6.42-.54 9.27.78 1.69 2.58 2.67 3.66 4.2 1.34 1.9 1.39 4.53.5 6.68-.9 2.15-2.63 3.87-4.57 5.16-1.31.87-2.75 1.59-3.89 2.68-1.14 1.09-1.95 2.7-1.58 4.23.4 1.66 2.01 2.72 2.84 4.21 1.05 1.89.72 4.22 1.16 6.34.69 3.3 3.19 5.87 5.57 8.25 2.73 2.72 5.7 5.57 9.49 6.29 1.73.33 3.57.19 5.17.91 2.03.92 3.4 3.1 5.56 3.63 3.3.8 6.36-2.62 9.74-2.31 1.86.18 3.42 1.46 5.19 2.08 2.78.97 5.83.22 8.68-.53 3.8-1.01 7.78-1.53 10.19-2.76z" />
      ),
      basic: {
        dark: (
          <path d="M194.86 177.98c-2.61.55-4.42 2.9-6.06 4.93-1.32-13.62-14.29-32.58-29.41-23.48-17.13 15.81-8.48 45.96 17.62 39.1-4.98 6.32-4.91 16.44 4.49 8.6 7.3-7.84 14.42-17.95 13.36-29.15z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M316.13 206.04c-7.02-3.62-13.77-9.95-13.65-17.85.05-3.54 1.5-6.91 1.94-10.42.46-3.66.31-5.13.67-8.8.42-4.34 2.23-7.17 3.99-11.16 1.9-4.31.58-12.18 3.51-15.87 1.95-2.45 7.13-4.68 8.08-7.67.86-2.68.42-5.74 1.77-8.21 2.09-3.83 7.52-4.66 9.92-8.31 2.55-3.88.63-9.03.93-13.67.25-3.92 2.13-7.53 3.97-11 1.39-2.63 3.31-5.61 6.29-5.72 3.36-.13 5.51 3.43 6.91 6.49 1.77 3.89 3.58 7.93 3.59 12.2.01 2.97-.82 6.14.51 8.8.98 1.97 3.09 3.52 3.06 5.72-.02 1.27-.79 2.47-.64 3.74.21 1.8 2.22 2.87 4.02 2.92 1.81.05 3.56-.59 5.37-.74 3.39-.29 6.89 1.27 8.93 4s2.56 6.52 1.32 9.69c-1 2.57-3.07 4.78-3.32 7.53-.29 3.13 1.86 6.42.54 9.27-.78 1.69-2.58 2.67-3.66 4.2-1.34 1.9-1.39 4.53-.5 6.68.9 2.15 2.63 3.87 4.57 5.16 1.31.87 2.75 1.59 3.89 2.68s1.95 2.7 1.58 4.23c-.4 1.66-2.01 2.72-2.84 4.21-1.05 1.89-.72 4.22-1.16 6.34-.69 3.3-3.19 5.87-5.57 8.25-2.73 2.72-5.7 5.57-9.49 6.29-1.73.33-3.57.19-5.17.91-2.03.92-3.4 3.1-5.56 3.63-3.3.8-6.36-2.62-9.74-2.31-1.86.18-3.42 1.46-5.19 2.08-2.78.97-5.83.22-8.68-.53-3.8-1.01-7.78-1.53-10.19-2.76z" />
      ),
      basic: {
        dark: (
          <path d="M305.14 177.98c2.61.55 4.42 2.9 6.06 4.93 1.32-13.62 14.29-32.58 29.41-23.48 17.13 15.81 8.48 45.96-17.62 39.1 4.98 6.32 4.91 16.44-4.49 8.6-7.3-7.84-14.42-17.95-13.36-29.15z" />
        ),
      },
    },
  },
};

const ear_shape_84DrT = {
  id: "ear_shape_84DrT",
  name: "Ears 20 (Bumpy)",
  description: "Bumpy, wide ears that droop downwards from the top of the head",
  tags: ["rounded", "side", "drooping", "large", "bumpy"],
  preview: {
    viewbox: "90 160 190 190",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M180.84 288.51c-7.19-35.14-.81-72.89 17.53-103.72 1.58-2.65 3.29-5.41 3.4-8.48.22-5.93-5.99-10.6-11.93-10.57-5.94.02-11.32 3.49-15.72 7.48s-8.25 8.64-13.18 11.95c-4.01 2.68-9.04 4.84-10.62 9.4-1.04 3.01-.26 6.36-.91 9.49-1.46 7.04-9.43 10.78-12.44 17.3-2.49 5.39-1.2 11.78-2.76 17.5-2.11 7.73-9.16 13.46-10.89 21.29-1.56 7.07 1.53 14.36 1.16 21.59-.36 7.04-3.97 13.56-4.76 20.56-1.32 11.66 5.22 22.61 11.5 32.52 1.62 2.56 3.56 5.35 6.53 5.98 3 .63 6-1.23 8.05-3.5s3.57-5.03 5.83-7.11c4.26-3.91 10.84-5.12 14.01-9.95 2.92-4.47 1.89-10.53 4.21-15.34 1.87-3.85 5.63-6.38 8.38-9.65 2.74-3.28 4.36-8.47 2.61-6.74z" />
      ),
      basic: {
        dark: (
          <path d="M203.4 174.95c-13.18-5.84-40.86 53.43-44.22 64.97-4.69 16.25-18.7 54.51-7.77 68.37 9.77 1.34 17.32-8.75 20.8-17.17 6.74 14.21 11.83 6.91 5.84-3.64-4.3-11.85-2-24.65-2.44-37.07.86-14.74 3.24-25.84 8.23-40.39 3.92-8.71 8.81-16.99 14.49-24.66 1.87-3.14 6.1-6.51 5.07-10.41z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M319.16 288.51c7.19-35.14.81-72.89-17.53-103.72-1.58-2.65-3.29-5.41-3.4-8.48-.22-5.93 5.99-10.6 11.93-10.57 5.94.02 11.32 3.49 15.72 7.48s8.25 8.64 13.18 11.95c4.01 2.68 9.04 4.84 10.62 9.4 1.04 3.01.26 6.36.91 9.49 1.46 7.04 9.43 10.78 12.44 17.3 2.49 5.39 1.2 11.78 2.76 17.5 2.11 7.73 9.16 13.46 10.89 21.29 1.56 7.07-1.53 14.36-1.16 21.59.36 7.04 3.97 13.56 4.76 20.56 1.32 11.66-5.22 22.61-11.5 32.52-1.62 2.56-3.56 5.35-6.53 5.98-3 .63-6-1.23-8.05-3.5s-3.57-5.03-5.83-7.11c-4.26-3.91-10.84-5.12-14.01-9.95-2.92-4.47-1.89-10.53-4.21-15.34-1.87-3.85-5.63-6.38-8.38-9.65-2.74-3.28-4.36-8.47-2.61-6.74z" />
      ),
      basic: {
        dark: (
          <path d="M296.6 174.95c13.18-5.84 40.86 53.43 44.22 64.97 4.69 16.25 18.7 54.51 7.77 68.37-9.77 1.34-17.32-8.75-20.8-17.17-6.74 14.21-11.83 6.91-5.84-3.64 4.3-11.85 2-24.65 2.44-37.07-.86-14.74-3.24-25.84-8.23-40.39-3.92-8.71-8.81-16.99-14.49-24.66-1.87-3.14-6.1-6.51-5.07-10.41z" />
        ),
      },
    },
  },
};

const ear_shape_k4vrT = {
  id: "ear_shape_k4vrT",
  name: "Ears 21 (Bumpy)",
  description: "Bumpy, large finned ears with three sharp points",
  tags: ["pointed", "side", "bumpy"],
  preview: {
    viewbox: "100 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M189.42 308.22c-7.89-8.77-10.41-21.01-12.21-32.67-1.86-12.04-3.27-24.23-2.42-36.39.84-12.15 4.06-24.34 10.81-34.48 1.75-2.63 3.83-5.9 1.77-8.68-.57-.77-1.39-1.33-2.28-1.68-5.51-2.18-12.28.17-15.43 5.12-2.63 4.12-3.45 10.21-8.1 11.71-1.8.58-3.92.32-5.41 1.47-1.44 1.11-1.75 3.11-2.44 4.79-2.1 5.12-9.01 7.58-13.87 4.93-1.32-.72-2.58-1.77-4.09-1.79-2.05-.03-3.72 1.9-5.77 1.99-2.01.09-3.6-1.56-5.34-2.55-1.74-1-4.65-.88-5.02 1.09 2.15 5.68 6 10.7 10.95 14.23.81.58 1.56 1.25 2.09 2.1 2.87 4.62-3.16 7.82-3.71 12.25-.3 2.45 1.15 4.99.35 7.33-.76 2.22-3.19 3.3-5.23 4.45-3.86 2.17-7.1 5.44-9.23 9.32-.3.54-.58 1.14-.49 1.75.14.96 1.19 1.55 2.16 1.58.97.03 1.86-.75 2.82-.62 4.27.6 11.09.55 16.31 2.62 3.28 1.31 6.93 2.99 8.05 6.34.93 2.79-.24 5.78-.49 8.7-.15 1.67.11 2.94.91 3.72 1.84 1.79 2.86 4.29 2.16 6.77-.16.57-.35 1.07-.56 1.45-1.13 2.04-2.34 4.24-1.53 6.64.41 1.24 1.53 2.18 2.83 2.17 2.09-.02 2.92-2.18 4.26-3.54 2.26-2.28 5.97-2.17 9.07-1.36 3.1.81 6.17 2.18 9.36 1.9 3.22-.28 6.35-2.22 9.48-1.44 2.07.52 3.67 2.15 5.64 2.97 1.93.82 4.87.28 4.6-2.19z" />
      ),
      basic: {
        dark: (
          <path d="M186.51 303.37c-20.53-33.61-14.43-74.59 1.24-107.74-8.16-3.17-12.92 17.75-16.13 23.55-17.69 15.77-21.87 39.02-11.61 60.37 2.92 5.72 7.69 10.71 13.56 13.14 2.76 6.18 5.42 12.81 9.85 17.97 7.06 3.1 7.44-2.91 3.09-7.29z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M310.58 308.21c7.89-8.77 10.41-21.01 12.21-32.67 1.86-12.04 3.27-24.23 2.42-36.39-.84-12.15-4.06-24.34-10.81-34.48-1.75-2.63-3.83-5.9-1.77-8.68.57-.77 1.39-1.33 2.28-1.68 5.51-2.18 12.28.17 15.43 5.12 2.63 4.12 3.45 10.21 8.1 11.71 1.8.58 3.92.32 5.41 1.47 1.44 1.11 1.75 3.11 2.44 4.79 2.1 5.12 9.01 7.58 13.87 4.93 1.32-.72 2.58-1.77 4.09-1.79 2.05-.03 3.72 1.9 5.77 1.99 2.01.09 3.6-1.56 5.34-2.55 1.74-1 4.65-.88 5.02 1.09-2.15 5.68-6 10.7-10.95 14.23-.81.58-1.56 1.25-2.09 2.1-2.87 4.62 3.16 7.82 3.71 12.25.3 2.45-1.15 4.99-.35 7.33.76 2.22 3.19 3.3 5.23 4.45 3.86 2.17 7.1 5.44 9.23 9.32.3.54.58 1.14.49 1.75-.14.96-1.19 1.55-2.16 1.58s-1.86-.75-2.82-.62c-4.27.6-11.09.55-16.31 2.62-3.28 1.31-6.93 2.99-8.05 6.34-.93 2.79.24 5.78.49 8.7.15 1.67-.11 2.94-.91 3.72-1.84 1.79-2.86 4.29-2.16 6.77.16.57.35 1.07.56 1.45 1.13 2.04 2.34 4.24 1.53 6.64-.41 1.24-1.53 2.18-2.83 2.17-2.09-.02-2.92-2.18-4.26-3.54-2.26-2.28-5.97-2.17-9.07-1.36s-6.17 2.18-9.36 1.9c-3.22-.28-6.35-2.22-9.48-1.44-2.07.52-3.67 2.15-5.64 2.97-1.94.82-4.87.28-4.6-2.19z" />
      ),
      basic: {
        dark: (
          <path d="M313.49 303.37c20.53-33.61 14.43-74.59-1.24-107.74 8.16-3.17 12.92 17.75 16.13 23.55 17.69 15.77 21.87 39.02 11.61 60.37-2.92 5.72-7.69 10.71-13.56 13.14-2.76 6.18-5.42 12.81-9.85 17.97-7.06 3.1-7.44-2.91-3.09-7.29z" />
        ),
      },
    },
  },
};

const ear_shape_a4jrT = {
  id: "ear_shape_a4jrT",
  name: "Ears 22 (Bumpy)",
  description: "Bumpy, large finned ears with three rounded points",
  tags: ["pointed", "side", "bumpy"],
  preview: {
    viewbox: "100 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M190.42 310.4c2.39-6.56.25-13.85-1.74-20.56-4.11-13.86-7.58-28.02-8.59-42.49-1-14.46.55-29.3 6.13-42.61 1.22-2.9 2.64-6.19 1.38-9.07-1.31-3.01-5.18-4.08-8.24-3.13-3.06.96-5.44 3.43-7.49 5.96s-3.98 5.26-6.64 7.1c-2.92 2.01-6.65 2.88-8.91 5.65-2.12 2.59-2.47 6.27-4.22 9.13-1.97 3.23-5.56 5.11-9.18 5.89-3.62.79-7.37.65-11.07.73-2.27.05-4.75.28-6.4 1.89-2.02 1.98-2.05 5.24-1.93 8.11.13 3.25.59 7.08 3.35 8.65.97.55 2.1.73 3.11 1.21 2.42 1.16 3.73 3.85 4.86 6.35 1.34 2.96 2.7 6.31 1.66 9.4-1.01 2.99-4.02 4.76-6.97 5.61-1.98.57-4.05.85-5.95 1.68-1.89.84-3.65 2.39-4.04 4.48-.61 3.29 2.31 6.12 5.19 7.66 2.88 1.54 6.24 2.75 7.85 5.65.91 1.64 1.21 3.73 2.64 4.9 1.93 1.59 5.01.72 6.98 2.26 1.91 1.49 1.83 4.53.91 6.82-.92 2.28-2.48 4.29-3.06 6.69-.57 2.4.38 5.52 2.74 5.99 1.43.29 2.94-.49 4.33-.07 1.2.36 1.99 1.5 3.04 2.2 2.71 1.82 6.37.31 8.92-1.74s4.88-4.71 8.04-5.42c3.92-.88 7.75 1.51 11.68 2.34 4.62.99 9.61-.3 11.62-1.26z" />
      ),
      basic: {
        dark: (
          <path d="M185.2 295.84c-14.66-30.6-13.85-67.22 2.3-97.03 1.34-2.85-.26-6.85-3.24-4.4-9.39 8.18-12.43 21.73-15.63 33.32-24.16 17.2-21.35 50.71 4.48 64.71 2.39 5.38 9.82 27.51 17.67 18.15 2.01-3.93-3.99-11.3-5.58-14.75z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M309.58 310.4c-2.39-6.56-.25-13.85 1.74-20.56 4.11-13.86 7.58-28.02 8.59-42.49 1-14.46-.55-29.3-6.13-42.61-1.22-2.9-2.64-6.19-1.38-9.07 1.31-3.01 5.18-4.08 8.24-3.13s5.44 3.43 7.49 5.96 3.98 5.26 6.64 7.1c2.92 2.01 6.65 2.88 8.91 5.65 2.12 2.59 2.47 6.27 4.22 9.13 1.97 3.23 5.56 5.11 9.18 5.89 3.62.79 7.37.65 11.07.73 2.27.05 4.75.28 6.4 1.89 2.02 1.98 2.05 5.24 1.93 8.11-.13 3.25-.59 7.08-3.35 8.65-.97.55-2.1.73-3.11 1.21-2.42 1.16-3.73 3.85-4.86 6.35-1.34 2.96-2.7 6.31-1.66 9.4 1.01 2.99 4.02 4.76 6.97 5.61 1.98.57 4.05.85 5.95 1.68s3.65 2.39 4.04 4.48c.61 3.29-2.31 6.12-5.19 7.66s-6.24 2.75-7.85 5.65c-.91 1.64-1.21 3.73-2.64 4.9-1.93 1.59-5.01.72-6.98 2.26-1.91 1.49-1.83 4.53-.91 6.82.92 2.28 2.48 4.29 3.06 6.69.57 2.4-.38 5.52-2.74 5.99-1.43.29-2.94-.49-4.33-.07-1.2.36-1.99 1.5-3.04 2.2-2.71 1.82-6.37.31-8.92-1.74s-4.88-4.71-8.04-5.42c-3.92-.88-7.75 1.51-11.68 2.34-4.62.99-9.61-.3-11.62-1.26z" />
      ),
      basic: {
        dark: (
          <path d="M314.8 295.84c14.66-30.6 13.85-67.22-2.3-97.03-1.34-2.85.26-6.85 3.24-4.4 9.39 8.18 12.43 21.73 15.63 33.32 24.16 17.2 21.35 50.71-4.48 64.71-2.39 5.38-9.82 27.51-17.67 18.15-2.01-3.93 3.99-11.3 5.58-14.75z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_24srT = {
  id: "ear_shape_24srT",
  name: "Ears 23 (Bumpy)",
  description: "Bumpy, wide rounded ears that droop slightly downwards",
  tags: ["rounded", "side", "bumpy"],
  preview: {
    viewbox: "100 190 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M176.93,279.16c4.48-3.18,7.28-8.61,7.25-14.11c-0.03-5.82-2.95-11.16-4.88-16.64
						c-3.12-8.82-3.73-18.5-1.75-27.64c0.73-3.36,1.66-7.3-0.61-9.88c-2.08-2.37-5.71-2.23-8.8-2.87
						c-4.24-0.88-8.42-3.56-12.58-2.35c-5,1.45-7.2,7.78-12.08,9.59c-2.62,0.97-5.93,0.61-7.8,2.69c-1.77,1.97-1.23,5.03-1.85,7.61
						c-0.85,3.58-3.91,6.12-6.69,8.52c-2.79,2.4-5.66,5.27-5.93,8.94c-0.14,1.99,0.52,3.96,0.52,5.95c0,3.23-1.71,6.17-3.05,9.11
						c-1.34,2.94-2.31,6.43-0.88,9.32c0.89,1.8,2.59,3.09,3.47,4.9c1.28,2.62,0.88,6.33,3.36,7.85c1.84,1.13,4.22,0.32,6.16-0.64
						c1.93-0.96,4.06-2.08,6.14-1.49c1.86,0.53,3.06,2.27,4.54,3.51c2.84,2.35,6.73,2.85,10.39,3.25c3.83,0.42,7.68,0.84,11.52,0.53
						c3.06-0.24,6.06-0.94,9.05-1.63c1.27-0.3,2.59-0.61,3.6-1.43C177.03,281.4,177.63,279.92,176.93,279.16z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M173.88,210.32c-2.57,1.88-3.38,6.83-4.68,9.99c-0.26,0.62-0.9,1-1.57,0.94c-14.64-1.36-26.59,20.11-29.6,34.89c-0.33,1.61,1.74,2.56,2.78,1.3c15.87-19.18,10.48,10.05,22.34,7.75c0.83-0.16,1.64,0.39,1.81,1.22c1.41,6.8,2.5,22.65,10.05,18.4c0.55-0.31,0.86-0.92,0.79-1.55c-0.78-7.28-2.44-14.99-2.17-22.5c0-0.09,0-0.18-0.02-0.27c-2.71-15.89,7.16-34.91,2.73-49.34c176.02,210.1,174.76,209.68,173.88,210.32z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M323.09,279.16
						c-4.48-3.18-7.28-8.61-7.25-14.11c0.03-5.82,2.95-11.16,4.88-16.64c3.12-8.82,3.73-18.5,1.75-27.64
						c-0.73-3.36-1.66-7.3,0.61-9.88c2.08-2.37,5.71-2.23,8.8-2.87c4.24-0.88,8.42-3.56,12.58-2.35c5,1.45,7.2,7.78,12.08,9.59
						c2.62,0.97,5.93,0.61,7.8,2.69c1.77,1.97,1.23,5.03,1.85,7.61c0.85,3.58,3.91,6.12,6.69,8.52c2.79,2.4,5.66,5.27,5.93,8.94
						c0.14,1.99-0.52,3.96-0.52,5.95c0,3.23,1.71,6.17,3.05,9.11c1.34,2.94,2.31,6.43,0.88,9.32c-0.89,1.8-2.59,3.09-3.47,4.9
						c-1.28,2.62-0.88,6.33-3.36,7.85c-1.84,1.13-4.22,0.32-6.16-0.64c-1.93-0.96-4.06-2.08-6.14-1.49
						c-1.86,0.53-3.06,2.27-4.54,3.51c-2.84,2.35-6.73,2.85-10.39,3.25c-3.83,0.42-7.68,0.84-11.52,0.53
						c-3.06-0.24-6.06-0.94-9.05-1.63c-1.27-0.3-2.59-0.61-3.6-1.43C322.99,281.4,322.39,279.92,323.09,279.16z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M326.12,210.32c2.57,1.88,3.38,6.83,4.68,9.99c0.26,0.62,0.9,1,1.57,0.94c14.64-1.36,26.59,20.11,29.6,34.89c0.33,1.61-1.74,2.56-2.78,1.3c-15.87-19.18-10.48,10.05-22.34,7.75c-0.83-0.16-1.64,0.39-1.81,1.22c-1.41,6.8-2.5,22.65-10.05,18.4c-0.55-0.31-0.86-0.92-0.79-1.55c0.78-7.28,2.44-14.99,2.17-22.5c0-0.09,0-0.18,0.02-0.27c2.71-15.89-7.16-34.91-2.73-49.34C323.98,210.1,325.24,209.68,326.12,210.32z" />
          </>
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_a442T = {
  id: "ear_shape_a442T",
  name: "Ears 24 (Bumpy)",
  description:
    "Bumpy, very wide ears with double points that droop slightly downwards",
  tags: ["pointed", "side", "drooping", "large", "bumpy"],
  preview: {
    viewbox: "70 190 140 140",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M174.93,277.31c5.64-7.17,6.45-16.97,6.02-26.08
						c-0.58-12.32-3.5-25.47-12.66-33.73c-5.78-5.21-13.36-7.86-20.73-10.38c-3.53-1.21-7.1-2.43-10.81-2.79
						c-3.71-0.37-7.64,0.18-10.74,2.26c-3.41,2.3-5.5,6.2-8.93,8.47c-2.71,1.79-6.3,2.64-7.89,5.47c-1.83,3.26-0.02,7.32-0.32,11.05
						c-0.58,7.11-8.46,11.37-10.9,18.08c-1.85,5.07-0.63,11.57-4.6,15.22c-2.72,2.51-6.91,2.61-10.01,4.63
						c-4.25,2.76-5.52,8.3-6.43,13.29c-0.43,2.34-0.74,5.1,0.94,6.79c1.55,1.56,4.14,1.44,6.21,0.71s4-1.95,6.17-2.3
						c3.83-0.61,8.07,1.56,11.46-0.31c1.98-1.09,3.07-3.28,4.72-4.83s4.73-2.19,5.91-0.25c1.26,2.06-0.96,4.5-3.06,5.69
						s-4.7,2.72-4.5,5.13c0.1,1.14,0.86,2.23,0.57,3.34c-0.51,1.93-3.68,2.11-4.18,4.05c-0.33,1.27,0.74,2.48,1.85,3.19
						c4.54,2.91,11.22,1.73,14.5-2.55c4.44,3.23,10.71,1.85,15.66-0.52c4.95-2.37,9.73-5.66,15.21-5.99
						c2.98-0.18,6.17,0.52,8.84-0.81c2.91-1.45,4.29-4.84,6.82-6.88c2.52-2.03,5.94-2.52,8.75-4.12
						C175.61,281.54,177.77,277.66,174.93,277.31z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M175.41,224.77c0.65-2.48,0.18-6.77-3.16-6.43c-4.3,2.67-4.76,9.48-6.49,13.99c-20.39-4.97-26,21.71-33.39,35.26c-6.38,16.04,27.66,5.55,33.06,0.62c1.4,5.96,3.54,11.74,6.41,17.16c4.27-1.77,2.02-3.77,1.49-7.91c172.42,259.94,173.12,242.16,175.41,224.77z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M325.07,277.31
						c-5.64-7.17-6.45-16.97-6.02-26.08c0.58-12.32,3.5-25.47,12.66-33.73c5.78-5.21,13.36-7.86,20.73-10.38
						c3.53-1.21,7.1-2.43,10.81-2.79c3.71-0.37,7.64,0.18,10.74,2.26c3.41,2.3,5.5,6.2,8.93,8.47c2.71,1.79,6.3,2.64,7.89,5.47
						c1.83,3.26,0.02,7.32,0.32,11.05c0.58,7.11,8.46,11.37,10.9,18.08c1.85,5.07,0.63,11.57,4.6,15.22
						c2.72,2.51,6.91,2.61,10.01,4.63c4.25,2.76,5.52,8.3,6.43,13.29c0.43,2.34,0.74,5.1-0.94,6.79c-1.55,1.56-4.14,1.44-6.21,0.71
						s-4-1.95-6.17-2.3c-3.83-0.61-8.07,1.56-11.46-0.31c-1.98-1.09-3.07-3.28-4.72-4.83s-4.73-2.19-5.91-0.25
						c-1.26,2.06,0.96,4.5,3.06,5.69s4.7,2.72,4.5,5.13c-0.1,1.14-0.86,2.23-0.57,3.34c0.51,1.93,3.68,2.11,4.18,4.05
						c0.33,1.27-0.74,2.48-1.85,3.19c-4.54,2.91-11.22,1.73-14.5-2.55c-4.44,3.23-10.71,1.85-15.66-0.52
						c-4.95-2.37-9.73-5.66-15.21-5.99c-2.98-0.18-6.17,0.52-8.84-0.81c-2.91-1.45-4.29-4.84-6.82-6.88
						c-2.52-2.03-5.94-2.52-8.75-4.12S322.23,277.66,325.07,277.31z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M324.59,224.77c-0.65-2.48-0.18-6.77,3.16-6.43c4.3,2.67,4.76,9.48,6.49,13.99c20.39-4.97,26,21.71,33.39,35.26c6.38,16.04-27.66,5.55-33.06,0.62c-1.4,5.96-3.54,11.74-6.41,17.16c-4.27-1.77-2.02-3.77-1.49-7.91C327.58,259.94,326.88,242.16,324.59,224.77z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_Z4prT = {
  id: "ear_shape_Z4prT",
  name: "Ears 25 (Bumpy)",
  description: "Bumpy, swooping ears on the top of the head",
  tags: ["pointed", "top", "large", "bumpy"],
  preview: {
    viewbox: "100 80 150 150",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M185.34 203.66c6.19-3.76 11.34-9.2 14.77-15.58 1.96-3.65 3.24-8.46.66-11.7-2.22-2.79-6.35-3.16-9.13-5.4-3.82-3.07-4.15-8.72-6.69-12.91-3.66-6.04-11.73-8.77-14.3-15.35-2.03-5.21.06-11.37-2.1-16.53-2.38-5.67-9.1-8.34-12.18-13.66-2.2-3.8-2.26-8.43-3.67-12.59-1.61-4.72-5.04-8.79-9.43-11.15-2.79-1.51-6.51-2.23-8.96-.2-1.6 1.33-2.21 3.48-2.74 5.49-1.78 6.81-3.56 13.61-5.34 20.42-.65 2.47-1.3 5-1.13 7.55.24 3.51 2.01 6.79 2.09 10.31.14 6.32-5.2 12.06-4.13 18.29.75 4.4 4.57 7.8 5.32 12.19.97 5.68-3.4 11.59-1.41 17 1.98 5.4 9.24 7.22 11.61 12.46 1.13 2.49.99 5.49 2.46 7.79 1.62 2.53 4.8 3.62 7.8 3.82 3 .2 6.01-.31 9.01-.1 3.8.26 7.41 1.64 11.04 2.77 3.63 1.13 7.49 2.03 11.22 1.29 3.74-.74 7.31-3.5 5.23-4.21z" />
      ),
      basic: {
        dark: (
          <path d="M200.57 173.09c-2.39 2.06-4.68 4.23-6.88 6.49-13.18-6.2-25.32-27.44-35.23-27.04-9.41 17.21-.9 45.83 21.63 44.09-1.01 2.93-7.95 9.15-3.77 11.1 7.07 1.1 8.02-6.54 11.27-11.41 2.34-6.88 19.67-16.5 12.98-23.23z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M314.66 203.66c-6.19-3.76-11.34-9.2-14.77-15.58-1.96-3.65-3.24-8.46-.66-11.7 2.22-2.79 6.35-3.16 9.13-5.4 3.82-3.07 4.15-8.72 6.69-12.91 3.66-6.04 11.73-8.77 14.3-15.35 2.03-5.21-.06-11.37 2.1-16.53 2.38-5.67 9.1-8.34 12.18-13.66 2.2-3.8 2.26-8.43 3.67-12.59 1.61-4.72 5.04-8.79 9.43-11.15 2.79-1.51 6.51-2.23 8.96-.2 1.6 1.33 2.21 3.48 2.74 5.49 1.78 6.81 3.56 13.61 5.34 20.42.65 2.47 1.3 5 1.13 7.55-.24 3.51-2.01 6.79-2.09 10.31-.14 6.32 5.2 12.06 4.13 18.29-.75 4.4-4.57 7.8-5.32 12.19-.97 5.68 3.4 11.59 1.41 17-1.98 5.4-9.24 7.22-11.61 12.46-1.13 2.49-.99 5.49-2.46 7.79-1.62 2.53-4.8 3.62-7.8 3.82-3 .2-6.01-.31-9.01-.1-3.8.26-7.41 1.64-11.04 2.77-3.63 1.13-7.49 2.03-11.22 1.29-3.74-.74-7.31-3.5-5.23-4.21z" />
      ),
      basic: {
        dark: (
          <path d="M299.43 173.09c2.39 2.06 4.68 4.23 6.88 6.49 13.18-6.2 25.32-27.44 35.23-27.04 9.41 17.21.9 45.83-21.63 44.09 1.01 2.93 7.95 9.15 3.77 11.1-7.07 1.1-8.02-6.54-11.27-11.41-2.34-6.88-19.67-16.5-12.98-23.23z" />
        ),
      },
    },
  },
};

const ear_shape_j4OrT = {
  id: "ear_shape_j4OrT",
  name: "Ears 26 (Bumpy)",
  description: "Bumpy, wide and long drooping ears",
  tags: ["side", "large", "drooping", "bumpy"],
  preview: {
    viewbox: "90 170 180 180",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M171.95 260.51c9.59-6.32 15.39-17.98 14.64-29.43-.65-9.96-5.76-19.01-10.83-27.61-2.25-3.82-4.55-7.68-7.67-10.83-3.6-3.63-8.14-6.17-12.61-8.65-5.03-2.8-10.77-5.69-16.3-4.07-4.02 1.18-7.03 4.57-9.05 8.23-2.02 3.67-3.25 7.71-5.09 11.48-3 6.14-7.68 11.77-8.29 18.57-.57 6.31 2.52 12.48 2.2 18.8-.32 6.16-3.86 12.02-3.11 18.14.75 6.06 5.55 10.91 6.89 16.86 1.54 6.83-1.66 14.25.66 20.86 1.84 5.22 6.77 8.81 8.93 13.9 2.22 5.22 1.25 11.28 2.87 16.71 1.54 5.14 5.25 9.3 8.85 13.27 3.39 3.73 7.71 7.82 12.68 6.96 5.6-.96 8.13-7.41 9.64-12.89 2.03-7.38 4.09-14.94 3.51-22.57-.36-4.74-1.73-9.36-2.13-14.1-.47-5.54.4-11.09 1.27-16.58.96-6.05 1.91-12.09 2.94-17.05z" />
      ),
      basic: {
        dark: (
          <path d="M175.35 208.8c-2.16 3.89-3.96 7.97-5.45 12.15-26.54-43.66-33.82 81.39-17.07 82.77 6.29-15.47 4.93-33.86 12.43-49.39 3.72 30.36 7.65 9.33 6.64-6.29-1.98-12.93 9.68-28.04 3.45-39.24z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M328.05 260.51c-9.59-6.32-15.39-17.98-14.64-29.43.65-9.96 5.76-19.01 10.83-27.61 2.25-3.82 4.55-7.68 7.67-10.83 3.6-3.63 8.14-6.17 12.61-8.65 5.03-2.8 10.77-5.69 16.3-4.07 4.02 1.18 7.03 4.57 9.05 8.23 2.02 3.67 3.25 7.71 5.09 11.48 3 6.14 7.68 11.77 8.29 18.57.57 6.31-2.52 12.48-2.2 18.8.32 6.16 3.86 12.02 3.11 18.14-.75 6.06-5.55 10.91-6.89 16.86-1.54 6.83 1.66 14.25-.66 20.86-1.84 5.22-6.77 8.81-8.93 13.9-2.22 5.22-1.25 11.28-2.87 16.71-1.54 5.14-5.25 9.3-8.85 13.27-3.39 3.73-7.71 7.82-12.68 6.96-5.6-.96-8.13-7.41-9.64-12.89-2.03-7.38-4.09-14.94-3.51-22.57.36-4.74 1.73-9.36 2.13-14.1.47-5.54-.4-11.09-1.27-16.58-.96-6.05-1.91-12.09-2.94-17.05z" />
      ),
      basic: {
        dark: (
          <path d="M324.65 208.8c2.16 3.89 3.96 7.97 5.45 12.15 26.54-43.66 33.82 81.39 17.07 82.77-6.29-15.47-4.93-33.86-12.43-49.39-3.72 30.36-7.65 9.33-6.64-6.29 1.98-12.93-9.68-28.04-3.45-39.24z" />
        ),
      },
    },
  },
};

const ear_shape_T4UrT = {
  id: "ear_shape_T4UrT",
  name: "Ears 27 (Bumpy)",
  description:
    "Bumpy, wide ears that have a bump on the top and a slight point to the side",
  tags: ["rounded", "side", "bumpy"],
  preview: {
    viewbox: "90 190 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M175.4 279.64c6.95-7.99 7.21-21.01.57-29.27-3.47-4.31-8.75-7.86-9.35-13.36-.27-2.44.47-4.99-.26-7.34-.89-2.87-3.66-4.64-5.72-6.82-3.35-3.55-4.9-8.39-7.24-12.67-2.34-4.29-6.16-8.44-11.04-8.58-4.06-.11-7.59 2.56-10.76 5.1-4.64 3.73-9.6 7.95-10.72 13.8-.6 3.12.2 6.42-.53 9.52-1.94 8.24-5.73 8.17-7.39 11.01-2.26 3.88-1.13 5.87-4.77 8.49-2.26 1.62-4.94 2.67-6.94 4.6-2 1.93-3.09 5.3-1.36 7.48.81 1.01 2.06 1.56 2.98 2.48 2.93 2.9 1.86 8.63 5.31 10.88 2.97 1.93 7.36-.13 10.27 1.89 2.91 2.02 2.47 6.79 5.16 9.1 3.07 2.64 7.9.46 11.79 1.58 4.27 1.23 6.89 6.31 11.31 6.73 4 .38 7.27-3.25 11.2-4.06 3.97-.82 8.53 1.24 11.93-.96 3.78-2.45 4.14-7.96 5.56-9.6z" />
      ),
      basic: {
        dark: (
          <path d="M174 274.4c-.75-6.18-1.12-12.41-1.09-18.63-.44-5.01 1.34-11.52-1.85-15.68-5.93-1.39-4.4 7.92-5.2 11.52-3.8-6.2-9.31-23.81-16.9-24.09 7.21 17.5 10.8 18.62-4.66 32.62-5.58 5.99-3.85 18.2 5.38 18.73 5.94.04 10.65-5.63 16.82-4.3.86 4.26 2.25 8.39 4.38 12.16 7.73 1.85 3.62-8.19 3.12-12.33z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M324.58 279.61c-6.95-7.99-7.21-21.01-.57-29.27 3.47-4.31 8.75-7.86 9.35-13.36.27-2.44-.47-4.99.26-7.34.89-2.87 3.66-4.64 5.72-6.82 3.35-3.55 4.9-8.39 7.24-12.67s6.16-8.44 11.04-8.58c4.06-.11 7.59 2.56 10.76 5.1 4.64 3.73 9.6 7.95 10.72 13.8.6 3.12-.2 6.42.53 9.52 1.94 8.24 5.73 8.17 7.39 11.01 2.26 3.88 1.13 5.87 4.77 8.49 2.26 1.62 4.94 2.67 6.94 4.6 2 1.93 3.09 5.3 1.36 7.48-.81 1.01-2.06 1.56-2.98 2.48-2.93 2.9-1.86 8.63-5.31 10.88-2.97 1.93-7.36-.13-10.27 1.89-2.91 2.02-2.47 6.79-5.16 9.1-3.07 2.64-7.9.46-11.79 1.58-4.27 1.23-6.89 6.31-11.31 6.73-4 .38-7.27-3.25-11.2-4.06-3.97-.82-8.53 1.24-11.93-.96-3.78-2.45-4.13-7.96-5.56-9.6z" />
      ),
      basic: {
        dark: (
          <path d="M326 274.4c.75-6.18 1.12-12.41 1.09-18.63.44-5.01-1.34-11.52 1.85-15.68 5.93-1.39 4.4 7.92 5.2 11.52 3.8-6.2 9.31-23.81 16.9-24.09-7.21 17.5-10.8 18.62 4.66 32.62 5.58 5.99 3.85 18.2-5.38 18.73-5.94.04-10.65-5.63-16.82-4.3-.86 4.26-2.25 8.39-4.38 12.16-7.73 1.85-3.62-8.19-3.12-12.33z" />
        ),
      },
    },
  },
};

const ear_shape_W43rT = {
  id: "ear_shape_W43rT",
  name: "Ears 28 (Bumpy)",
  description:
    "Bumpy, long rounded ears that stand diagonally from the top of the head",
  tags: ["rounded", "top", "large", "bumpy"],
  preview: {
    viewbox: "80 80 150 150",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M179.68 211.22c5.04 4.74 11.97 7.97 18.88 7.11 6.91-.86 13.32-6.63 13.36-13.49.03-4.51-2.49-8.64-5.36-12.15s-6.2-6.72-8.31-10.71c-3.94-7.45-3.37-17.14-9.17-23.29-3.49-3.7-8.65-5.38-12.6-8.6-9.3-7.6-10.02-22.02-18.85-30.14-5.3-4.88-12.86-6.82-18.17-11.69-4.56-4.19-7.2-10.31-12.44-13.63-3.9-2.46-8.79-3.02-13.39-2.44s-8.98 2.18-13.32 3.78c-2.57.95-5.41 2.14-6.47 4.64-1.4 3.31 1.04 6.84 2.44 10.16 4 9.45-.09 21.76 6.68 29.51 2.88 3.3 7.21 5.02 10.46 7.97 7.15 6.5 8.04 17.92 15.47 24.1 3.74 3.11 8.76 4.52 12.32 7.83 4.25 3.95 5.78 9.98 9.39 14.51 3.2 4.01 7.84 6.61 12.36 9.11 7.89 4.35 15.77 8.71 16.72 7.42z" />
      ),
      basic: {
        dark: (
          <path d="M197.24 181.1c-2.84-3.16-6.32 1.56-8.36 3.51-53.52-74.39-71.29-30.97-10.79 14.6-1.75 3.59-8.98 14.67-1.27 15.25 3.16-.4 4.67-6.2 5.95-8.53 2.51-6.87 15.15-19.25 14.47-24.83z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M320.31 211.22c-5.04 4.74-11.97 7.97-18.88 7.11-6.91-.86-13.32-6.63-13.36-13.49-.03-4.51 2.49-8.64 5.36-12.15s6.2-6.72 8.31-10.71c3.94-7.45 3.37-17.14 9.17-23.29 3.49-3.7 8.65-5.38 12.6-8.6 9.3-7.6 10.02-22.02 18.85-30.14 5.3-4.88 12.86-6.82 18.17-11.69 4.56-4.19 7.2-10.31 12.44-13.63 3.9-2.46 8.79-3.02 13.39-2.44s8.98 2.18 13.32 3.78c2.57.95 5.41 2.14 6.47 4.64 1.4 3.31-1.04 6.84-2.44 10.16-4 9.45.09 21.76-6.68 29.51-2.88 3.3-7.21 5.02-10.46 7.97-7.15 6.5-8.04 17.92-15.47 24.1-3.74 3.11-8.76 4.52-12.32 7.83-4.25 3.95-5.78 9.98-9.39 14.51-3.2 4.01-7.84 6.61-12.36 9.11-7.88 4.35-15.77 8.71-16.72 7.42z" />
      ),
      basic: {
        dark: (
          <path d="M302.76 181.1c2.84-3.16 6.32 1.56 8.36 3.51 53.52-74.39 71.29-30.97 10.79 14.6 1.75 3.59 8.98 14.67 1.27 15.25-3.16-.4-4.67-6.2-5.95-8.53-2.51-6.87-15.15-19.25-14.47-24.83z" />
        ),
      },
    },
  },
};

const ear_shape_X4DrT = {
  id: "ear_shape_X4DrT",
  name: "Ears 29 (Bumpy)",
  description:
    "Bumpy, long rounded ears that stand diagonally from the side of the head",
  tags: ["rounded", "side", "large", "bumpy"],
  preview: {
    viewbox: "20 120 180 180",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M171.35 248.92c3.14 2.68 7.94 3.21 11.59 1.3s5.95-6.15 5.56-10.26c-.49-5.01-4.4-8.9-8.08-12.33-4.95-4.62-9.91-9.24-14.86-13.86-4.99-4.65-10.04-9.35-16.03-12.61-4.65-2.53-9.88-4.21-13.81-7.76-3.17-2.87-5.27-6.76-8.41-9.66-6.79-6.26-16.92-6.72-25.6-9.88-5.56-2.02-10.62-5.22-16.11-7.42-7.52-3.02-15.65-4.11-23.69-5.17-3.88-.51-7.84-1.02-11.69-.31s-7.64 2.88-9.3 6.43c-1.98 4.22-.57 9.17.85 13.6 2.19 6.8 4.69 14.04 10.4 18.34 3.58 2.69 8.13 3.97 11.43 6.99 3.91 3.59 5.72 9.33 10.31 12 4.39 2.56 10.04 1.52 14.77 3.37 5.71 2.22 9.19 8.22 14.75 10.79 5.08 2.35 11.08 1.48 16.41 3.17 8.91 2.82 15.21 12.48 24.55 12.78 4.11.13 8.36-1.64 12.22-.24 3.63 1.32 6.65 5.33 10.4 4.41 1.8-.44 3.1-1.95 4.67-2.93 1.58-.99 4.02-1.25-.33-.75z" />
      ),
      basic: {
        dark: (
          <path d="M174.82 222.5c-4.35-5.59-5.59 3.22-6.31 6.36-15.25-10.36-31.02-19.97-46.45-30.06-5.28-3.39-11.46-6.93-17.47-5.12-28.29 16.76 49.06 45.51 61.67 48.46.06 3.37-2.95 14.76 2.89 13.9 1.72-.76 1.76-6.1 2.03-7.62.51-2.85 1.01-5.71 1.52-8.56.79-5.49 3.07-11.83 2.12-17.36z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M328.64 248.92c-3.14 2.68-7.94 3.21-11.59 1.3-3.66-1.91-5.95-6.15-5.56-10.26.49-5.01 4.4-8.9 8.08-12.33 4.95-4.62 9.91-9.24 14.86-13.86 4.99-4.65 10.04-9.35 16.03-12.61 4.65-2.53 9.88-4.21 13.81-7.76 3.17-2.87 5.27-6.76 8.41-9.66 6.79-6.26 16.92-6.72 25.6-9.88 5.56-2.02 10.62-5.22 16.11-7.42 7.52-3.02 15.65-4.11 23.69-5.17 3.88-.51 7.84-1.02 11.69-.31s7.64 2.88 9.3 6.43c1.98 4.22.57 9.17-.85 13.6-2.19 6.8-4.69 14.04-10.4 18.34-3.58 2.69-8.13 3.97-11.43 6.99-3.91 3.59-5.72 9.33-10.31 12-4.39 2.56-10.04 1.52-14.77 3.37-5.71 2.22-9.19 8.22-14.75 10.79-5.08 2.35-11.08 1.48-16.41 3.17-8.91 2.82-15.21 12.48-24.55 12.78-4.11.13-8.36-1.64-12.22-.24-3.63 1.32-6.65 5.33-10.4 4.41-1.8-.44-3.1-1.95-4.67-2.93-1.57-.99-4.01-1.25.33-.75z" />
      ),
      basic: {
        dark: (
          <path d="M325.18 222.5c4.35-5.59 5.59 3.22 6.31 6.36 15.25-10.36 31.02-19.97 46.45-30.06 5.28-3.39 11.46-6.93 17.47-5.12 28.29 16.76-49.06 45.51-61.67 48.46-.06 3.37 2.95 14.76-2.89 13.9-1.72-.76-1.76-6.1-2.03-7.62-.51-2.85-1.01-5.71-1.52-8.56-.79-5.49-3.07-11.83-2.12-17.36z" />
        ),
      },
    },
  },
};

const ear_shape_x4zrT = {
  id: "ear_shape_x4zrT",
  name: "Ears 30 (Bumpy)",
  description: "Bumpy, skinny tapered ears that stand from the top of the head",
  tags: ["rounded", "top", "bumpy"],
  preview: {
    viewbox: "90 75 150 150",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M184.32 202.51c2.61 3.15 7.31 4.36 11.12 2.86 3.8-1.5 6.42-5.6 6.17-9.68-.29-4.83-4.05-8.85-4.67-13.65-.74-5.75 3.07-12.06.19-17.09-1.12-1.95-3.09-3.3-4.24-5.24-1.75-2.95-1.31-6.71-2.52-9.92-1.32-3.5-4.43-5.95-7.03-8.64-3.43-3.55-6.11-7.73-8.77-11.89-2.48-3.88-5.06-8.07-4.93-12.68.09-3.26 1.52-6.65.29-9.67-.89-2.18-3.04-3.72-3.7-5.98-.68-2.34.39-4.82.25-7.25-.18-3.33-2.92-6.33-6.21-6.83s-6.79 1.57-7.95 4.7c-1.47 3.98.65 8.71-1.13 12.56-1.04 2.24-3.29 3.88-3.83 6.3-.69 3.07 1.63 5.95 2.19 9.04 1.1 6.07-4.64 11.58-4.33 17.74.13 2.53 1.29 4.92 1.41 7.45.25 5.34-4.11 10.45-2.71 15.61.95 3.5 4.29 5.81 5.93 9.04 1.89 3.72 1.52 8.6 4.48 11.54 2.05 2.04 5.23 2.61 7.22 4.7 2.83 2.98 2.53 8.26 5.89 10.61 3 2.1 7.99 1.1 9.82 4.28.46.8.63 1.78 1.3 2.42 2.1 2.02 6.58-1.27 5.76-.33z" />
      ),
      basic: {
        dark: (
          <path d="M196.51 176.81c-2.18.29-3.64 2.44-5.22 3.81-17.87-54.07-47.08-21.46-9.26 11.34-2.24 3.16-5.2 7.5-3.04 11.12 4.7 4.57 8.09-4.31 10-6.95 2.06-4.89 15.96-15.7 7.52-19.32z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M315.65 202.51c-2.61 3.15-7.31 4.36-11.12 2.86-3.8-1.5-6.42-5.6-6.17-9.68.29-4.83 4.05-8.85 4.67-13.65.74-5.75-3.07-12.06-.19-17.09 1.12-1.95 3.09-3.3 4.24-5.24 1.75-2.95 1.31-6.71 2.52-9.92 1.32-3.5 4.43-5.95 7.03-8.64 3.43-3.55 6.11-7.73 8.77-11.89 2.48-3.88 5.06-8.07 4.93-12.68-.09-3.26-1.52-6.65-.29-9.67.89-2.18 3.04-3.72 3.7-5.98.68-2.34-.39-4.82-.25-7.25.18-3.33 2.92-6.33 6.21-6.83s6.79 1.57 7.95 4.7c1.47 3.98-.65 8.71 1.13 12.56 1.04 2.24 3.29 3.88 3.83 6.3.69 3.07-1.63 5.95-2.19 9.04-1.1 6.07 4.64 11.58 4.33 17.74-.13 2.53-1.29 4.92-1.41 7.45-.25 5.34 4.11 10.45 2.71 15.61-.95 3.5-4.29 5.81-5.93 9.04-1.89 3.72-1.52 8.6-4.48 11.54-2.05 2.04-5.23 2.61-7.22 4.7-2.83 2.98-2.53 8.26-5.89 10.61-3 2.1-7.99 1.1-9.82 4.28-.46.8-.63 1.78-1.3 2.42-2.1 2.02-6.57-1.27-5.76-.33z" />
      ),
      basic: {
        dark: (
          <path d="M303.49 176.81c2.18.29 3.64 2.44 5.22 3.81 17.87-54.07 47.08-21.46 9.26 11.34 2.24 3.16 5.2 7.5 3.04 11.12-4.7 4.57-8.09-4.31-10-6.95-2.06-4.89-15.96-15.7-7.52-19.32z" />
        ),
      },
    },
  },
};

const ear_shape_e4GrT = {
  id: "ear_shape_e4GrT",
  name: "Ears 31 (Bumpy)",
  description:
    "Bumpy, large and wide ears that look a little like a butterfly wing",
  tags: ["pointed", "side", "bumpy"],
  preview: {
    viewbox: "80 170 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M174.93 280.65c8.98-6.86 11.78-20.61 6.19-30.43-3.72-6.53-10.4-11.05-13.81-17.75-2.2-4.32-3.04-9.52-6.59-12.82-2.96-2.74-7.25-3.6-10.34-6.19-3.97-3.33-5.35-8.96-9.19-12.44-2.69-2.44-6.29-3.55-9.76-4.6-4.16-1.25-8.33-2.51-12.49-3.76-2.16-.65-4.36-1.31-6.61-1.25-2.25.05-4.61.94-5.87 2.8-2.32 3.41-.12 7.96-.06 12.08.08 5.19-3.35 10.42-1.59 15.3 1.1 3.05 4.03 5.14 5.27 8.13 1.93 4.63-.56 10.33 1.73 14.79 2.09 4.08 7.45 5.63 9.59 9.68.93 1.75 1.19 3.87 2.51 5.35 2.65 2.98 8.13 1.88 10.65 4.98 1.61 1.98 1.29 4.94.16 7.22-1.12 2.28-2.92 4.17-4.24 6.34-3.79 6.25-3.8 7.58-3.2 14.86.17 2.02-.65 8.09.2 9.93s4.04 5.93 6.06 6.09c3.38.27 5.72-3.22 8.73-4.8 3.82-2.01 8.79-.86 12.47-3.12 3.34-2.05 4.62-6.26 7.42-9 2.26-2.21 5.36-3.32 7.96-5.11 2.6-1.79 4.85-4.88 4.81-6.28z" />
      ),
      basic: {
        dark: (
          <path d="M172.98 269.81c-2.45-11.56-1.64-23.24-1.91-34.81-.4-.98-1.46-1.79-2.49-1.54-2.74 1.3-1.81 5.7-2.38 8.26-5.04-5.74-10.31-11.48-16.14-16.46-1.63-1.42-13.98-9.16-13.98-10.56 1.35 11.55 20.86 16.94 18.45 34.51-.97 5.62-5.25 10.9-3.98 16.8.64 2.85 3.05 5.47 5.97 5.47 4.42-.77 7.37-2.17 11 1.61.4 2.38.86 4.75 1.35 7.11.59 2.85 4.72 8.68 6.8 3.54 1.26-3.1-2.11-10.61-2.69-13.93z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M325.07 280.71c-8.98-6.86-11.78-20.61-6.19-30.43 3.72-6.53 10.4-11.05 13.81-17.75 2.2-4.32 3.04-9.52 6.6-12.82 2.96-2.74 7.25-3.6 10.34-6.19 3.97-3.33 5.35-8.96 9.19-12.44 2.69-2.44 6.29-3.55 9.76-4.6 4.16-1.25 8.33-2.51 12.49-3.76 2.16-.65 4.36-1.31 6.61-1.25 2.25.05 4.61.94 5.87 2.8 2.32 3.41.12 7.96.06 12.08-.08 5.19 3.35 10.42 1.59 15.3-1.1 3.05-4.03 5.14-5.27 8.13-1.93 4.63.56 10.33-1.73 14.79-2.09 4.08-7.45 5.63-9.59 9.68-.93 1.75-1.19 3.87-2.51 5.35-2.65 2.98-8.13 1.88-10.65 4.98-1.61 1.98-1.29 4.94-.16 7.22 1.12 2.28 2.92 4.17 4.24 6.34 3.79 6.25 3.8 7.58 3.2 14.86-.17 2.02.65 8.09-.2 9.93-.86 1.84-4.04 5.93-6.06 6.09-3.38.27-5.72-3.22-8.73-4.8-3.82-2.01-8.79-.86-12.47-3.12-3.34-2.05-4.62-6.26-7.42-9-2.26-2.21-5.36-3.32-7.96-5.11s-4.86-4.88-4.82-6.28z" />
      ),
      basic: {
        dark: (
          <path d="M327.02 269.81c2.45-11.56 1.64-23.24 1.91-34.81.4-.98 1.46-1.79 2.49-1.54 2.74 1.3 1.81 5.7 2.38 8.26 5.04-5.74 10.31-11.48 16.14-16.46 1.63-1.42 13.98-9.16 13.98-10.56-1.35 11.55-20.86 16.94-18.45 34.51.97 5.62 5.25 10.9 3.98 16.8-.64 2.85-3.05 5.47-5.97 5.47-4.42-.77-7.37-2.17-11 1.61-.4 2.38-.86 4.75-1.35 7.11-.59 2.85-4.72 8.68-6.8 3.54-1.26-3.1 2.11-10.61 2.69-13.93z" />
        ),
      },
    },
  },
};

const ear_shape_44BrT = {
  id: "ear_shape_44BrT",
  name: "Ears 32 (Bumpy)",
  description: "Bumpy, wide ears that sweep down and outwards",
  tags: ["pointed", "side", "drooping", "bumpy"],
  preview: {
    viewbox: "70 180 130 130",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M176.74 249.57c4.93-3.29 8.83-8.44 9.65-14.31.82-5.87-1.92-12.34-7.2-15.04-2.48-1.27-5.32-1.67-7.86-2.82-3.78-1.72-6.74-5.02-10.67-6.35-4.99-1.69-10.41.13-15.36 1.93-5.25 1.91-10.96 4.21-13.56 9.16-1.61 3.07-1.83 6.89-4.12 9.49-3.07 3.49-8.67 3.48-12.2 6.5-3.46 2.96-4.4 8.36-8.35 10.62-2.7 1.55-6.04 1.16-9.15 1.09-3.11-.07-6.68.48-8.39 3.08-1.95 2.97-.47 6.91 1.04 10.13 1.64 3.48 3.47 7.17 6.8 9.09 2.34 1.35 5.3 1.75 7.08 3.78 1.73 1.98 1.88 5.04 3.77 6.86 2.8 2.71 7.45 1.18 11.25 2.05 3.35.76 5.93 3.4 9.09 4.75 4.79 2.05 10.28.96 15.37-.17 5-1.11 10.71-2.77 12.72-7.48 1.63-3.8.38-8.81 3.25-11.79 2.01-2.08 5.42-2.38 7.27-4.61 1.95-2.35 1.39-5.82 2.18-8.76 1.01-3.75 4.99-5.6 7.39-7.2z" />
      ),
      basic: {
        dark: (
          <path d="M175.57 218.76c-3.36-6.54-5.5 4.13-5.97 7.02-26.63 2.56-17.27 45.34-40.73 53.2 20.29 9.61 24.84-22.86 37.32-31.94.24 2.97-.13 14.72 4.95 10.95.37-12.71.68-26.7 4.43-39.23z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M323.26 249.57c-4.93-3.29-8.83-8.44-9.65-14.31-.82-5.87 1.92-12.34 7.2-15.04 2.48-1.27 5.32-1.67 7.86-2.82 3.78-1.72 6.74-5.02 10.67-6.35 4.99-1.69 10.41.13 15.36 1.93 5.25 1.91 10.96 4.21 13.56 9.16 1.61 3.07 1.83 6.89 4.12 9.49 3.07 3.49 8.67 3.48 12.2 6.5 3.46 2.96 4.4 8.36 8.35 10.62 2.7 1.55 6.04 1.16 9.15 1.09 3.11-.07 6.68.48 8.39 3.08 1.95 2.97.47 6.91-1.04 10.13-1.64 3.48-3.47 7.17-6.8 9.09-2.34 1.35-5.3 1.75-7.08 3.78-1.73 1.98-1.88 5.04-3.77 6.86-2.8 2.71-7.45 1.18-11.25 2.05-3.35.76-5.93 3.4-9.09 4.75-4.79 2.05-10.28.96-15.37-.17-5-1.11-10.71-2.77-12.72-7.48-1.63-3.8-.38-8.81-3.25-11.79-2.01-2.08-5.42-2.38-7.27-4.61-1.95-2.35-1.39-5.82-2.18-8.76-1.01-3.75-4.99-5.6-7.39-7.2z" />
      ),
      basic: {
        dark: (
          <path d="M324.43 218.76c3.36-6.54 5.5 4.13 5.97 7.02 26.63 2.56 17.27 45.34 40.73 53.2-20.29 9.61-24.84-22.86-37.32-31.94-.24 2.97.13 14.72-4.95 10.95-.37-12.71-.68-26.7-4.43-39.23z" />
        ),
      },
    },
  },
};

const ear_shape_z4yrT = {
  id: "ear_shape_z4yrT",
  name: "Ears 33 (Bumpy)",
  description: "Bumpy, small but wide ears that bump outwards at the top",
  tags: ["rounded", "side", "bumpy"],
  preview: {
    viewbox: "100 190 100 100",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M171.66 270.57c5.43-6.09 6.26-15.83 1.92-22.74-1.94-3.1-4.77-5.64-6.23-8.99-1.5-3.48-1.37-7.49-2.87-10.97-2.61-6.04-9.33-8.98-14.17-13.45-2.84-2.63-5.12-5.89-8.31-8.1-3.19-2.2-7.83-3.06-10.79-.57-1.93 1.63-2.67 4.23-4.02 6.37-1.77 2.81-4.66 4.91-5.87 8-1.44 3.67-.19 7.86-.98 11.72-.44 2.13-1.49 4.09-2.04 6.19s-.49 4.58 1.02 6.16c1.46 1.53 3.85 1.77 5.55 3.04 2.43 1.81 2.96 5.3 5.09 7.44 3.42 3.45 10.05 2.65 12.57 6.8 2.05 3.37.17 8.39 2.86 11.27 1.86 1.99 5.11 2 7.17 3.78 1.2 1.04 6.24 2.45 7.83 2.37 4.37-.22 5.45-2.2 7.55-4.13 3.25-2.99 2.46-2.79 3.72-4.19z" />
      ),
      basic: {
        dark: (
          <path d="M171.57 266.17c1.2-6.44-1.49-45.81-5.58-18-4.43-10-15.87-21.33-22.87-28.59.44 7.35 10.58 12.47 9.57 20.83-3.21 12.4-5.65 30.78 13.67 26.78.46 14.02 8.46 11.89 5.21-1.02z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M328.34 270.57c-5.43-6.09-6.26-15.83-1.92-22.74 1.94-3.1 4.77-5.64 6.23-8.99 1.5-3.48 1.37-7.49 2.87-10.97 2.61-6.04 9.33-8.98 14.17-13.45 2.84-2.63 5.12-5.89 8.31-8.1 3.19-2.2 7.83-3.06 10.79-.57 1.93 1.63 2.67 4.23 4.02 6.37 1.77 2.81 4.66 4.91 5.87 8 1.44 3.67.19 7.86.98 11.72.44 2.13 1.49 4.09 2.04 6.19s.49 4.58-1.02 6.16c-1.46 1.53-3.85 1.77-5.55 3.04-2.43 1.81-2.96 5.3-5.09 7.44-3.42 3.45-10.05 2.65-12.57 6.8-2.05 3.37-.17 8.39-2.86 11.27-1.86 1.99-5.11 2-7.17 3.78-1.2 1.04-6.24 2.45-7.83 2.37-4.37-.22-5.45-2.2-7.55-4.13-3.25-2.99-2.46-2.79-3.72-4.19z" />
      ),
      basic: {
        dark: (
          <path d="M328.43 266.18c-1.2-6.44 1.49-45.81 5.58-18 4.43-10 15.87-21.33 22.87-28.59-.44 7.35-10.58 12.47-9.57 20.83 3.21 12.4 5.65 30.78-13.67 26.78-.46 14.02-8.46 11.89-5.21-1.02z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_k4DrT = {
  id: "ear_shape_k4DrT",
  name: "Ears 34 (Bumpy)",
  description: "Bumpy, wide ears that droop downwards into a slight point",
  tags: ["pointed", "side", "drooping", "bumpy"],
  preview: {
    viewbox: "110 190 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M174.15,270.9c12.72-14.26,17.47-35.52,12.06-54.03
						c-0.93-3.18-2.27-6.45-4.91-8.36c-2.75-1.98-6.35-2.1-9.72-2.13c-3.28-0.04-6.73-0.04-9.62,1.56c-2.51,1.38-4.29,3.8-6.53,5.6
						c-5.26,4.21-13.24,5.27-16.31,11.34c-1.97,3.9-1.19,8.72-2.81,12.78c-1.94,4.87-7.11,8.09-8.11,13.25
						c-0.79,4.09,1.29,8.18,1.43,12.35c0.21,6.45-4.19,12.18-4.79,18.61c-0.45,4.74,1.21,9.39,2.84,13.85
						c1.01,2.75,2.41,5.88,5.21,6.53c2.79,0.65,5.45-1.59,7-4.05c1.56-2.46,2.6-5.34,4.76-7.25c2.08-1.84,4.96-2.55,6.98-4.46
						c3.35-3.19,3.61-9.03,7.39-11.66c2.73-1.9,6.33-1.46,9.62-1.71C171.93,272.87,175.79,271.11,174.15,270.9z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M180.18,209.14c0.33-1.11-0.08-2.36-1.1-2.9c-4.94-2.64-7.22,17.25-11.93,18.47c-7.6,2.74-36.8,61.73-19.26,54.87c0.35-0.14,0.67-0.36,0.93-0.64c3.2-3.56,4.41-8.85,8.28-11.66c0.09-0.07,0.19-0.13,0.29-0.19c1.77-1.03,3.73-1.86,5.41-3.02c1.51-1.04,3.58-0.22,3.95,1.58c3.9,18.59,9.88,5.21,5.75-9.1c-0.05-0.17-0.08-0.35-0.1-0.53c171.31,239.9,175.63,224.27,180.18,209.14z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M325.85,270.9
						c-12.72-14.26-17.47-35.52-12.06-54.03c0.93-3.18,2.27-6.45,4.91-8.36c2.75-1.98,6.35-2.1,9.72-2.13
						c3.28-0.04,6.73-0.04,9.62,1.56c2.51,1.38,4.29,3.8,6.53,5.6c5.26,4.21,13.24,5.27,16.31,11.34c1.97,3.9,1.19,8.72,2.81,12.78
						c1.94,4.87,7.11,8.09,8.11,13.25c0.79,4.09-1.29,8.18-1.43,12.35c-0.21,6.45,4.19,12.18,4.79,18.61
						c0.45,4.74-1.21,9.39-2.84,13.85c-1.01,2.75-2.41,5.88-5.21,6.53c-2.79,0.65-5.45-1.59-7-4.05c-1.56-2.46-2.6-5.34-4.76-7.25
						c-2.08-1.84-4.96-2.55-6.98-4.46c-3.35-3.19-3.61-9.03-7.39-11.66c-2.73-1.9-6.33-1.46-9.62-1.71
						C328.07,272.87,324.21,271.11,325.85,270.9z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M319.82,209.14c-0.33-1.11,0.08-2.36,1.1-2.9c4.94-2.64,7.22,17.25,11.93,18.47c7.6,2.74,36.8,61.73,19.26,54.87c-0.35-0.14-0.67-0.36-0.93-0.64c-3.2-3.56-4.41-8.85-8.28-11.66c-0.09-0.07-0.19-0.13-0.29-0.19c-1.77-1.03-3.73-1.86-5.41-3.02c-1.51-1.04-3.58-0.22-3.95,1.58c-3.9,18.59-9.88,5.21-5.75-9.1c0.05-0.17,0.08-0.35,0.1-0.53C328.69,239.9,324.37,224.27,319.82,209.14z" />
          </>
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_A4TrT = {
  id: "ear_shape_A4TrT",
  name: "Ears 35 (Bumpy)",
  description:
    "Bumpy, long ears that sweep upwards to a slight point, with another point/fin shape partway down",
  tags: ["pointed", "side", "bumpy"],
  preview: {
    viewbox: "90 180 130 130",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M178.74,296.04c3.03,0.51,6.12-1.23,7.93-3.73c1.8-2.49,2.52-5.6,2.88-8.66
						c1.33-11.5-2.15-23.49-9.44-32.49c-5.87-7.25-14.37-13.17-16.35-22.29c-1.05-4.85-0.17-10.38-3.1-14.4
						c-2.46-3.38-6.95-4.65-9.85-7.67c-2.36-2.46-3.45-5.83-4.65-9.01c-1.2-3.19-2.74-6.48-5.61-8.33
						c-2.86-1.84-7.39-1.46-8.92,1.58c-1.47,2.93,0.43,6.39,0.37,9.67c-0.07,3.8-2.76,7.08-3.33,10.84
						c-0.77,5.06,2.38,9.92,2.59,15.04c0.2,4.73-2.36,9.48-6.43,11.91c-2.6,1.56-5.73,2.22-8.03,4.19c-2.3,1.97-3.17,6.17-0.6,7.78
						c1.25,0.78,2.85,0.65,4.27,1.06c4.78,1.38,6.07,8.12,10.64,10.08c2.7,1.16,6.02,0.36,8.57,1.81c4.75,2.71,3.23,10.55,7.2,14.3
						c2.74,2.59,7.2,2.38,10.18,4.69c3.4,2.63,3.82,7.58,6.26,11.12c1.93,2.8,5.21,4.62,8.61,4.78
						C175.34,298.47,178.78,296.97,178.74,296.04z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M178.95,291.52c-6.5-17.48-7.11-36.82-6.85-55.3c-0.9,0.3-1.98-0.05-2.52-0.82c-4.29,2.17-3.49,8.83-4.35,12.98c-5.71-12.06-9.62-29.96-20.7-37.38c2.44,8.6,10.58,15.44,10.46,24.38c-0.07,5.25-3.05,9.97-4.36,15.05c-3.01,10.6,3.04,27.88,16.44,24.22c2.03,8.65,5.44,16.97,10.16,24.5c1.18,1.88,5.14,5.85,5.59,1.37c183.1,297.83,180.03,293.88,178.95,291.52z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M321.26,296.04
						c-3.03,0.51-6.12-1.23-7.93-3.73c-1.8-2.49-2.52-5.6-2.88-8.66c-1.33-11.5,2.15-23.49,9.44-32.49
						c5.87-7.25,14.37-13.17,16.35-22.29c1.05-4.85,0.17-10.38,3.1-14.4c2.46-3.38,6.95-4.65,9.85-7.67
						c2.36-2.46,3.45-5.83,4.65-9.01c1.2-3.19,2.74-6.48,5.61-8.33c2.86-1.84,7.39-1.46,8.92,1.58c1.47,2.93-0.43,6.39-0.37,9.67
						c0.07,3.8,2.76,7.08,3.33,10.84c0.77,5.06-2.38,9.92-2.59,15.04c-0.2,4.73,2.36,9.48,6.43,11.91c2.6,1.56,5.73,2.22,8.03,4.19
						c2.3,1.97,3.17,6.17,0.6,7.78c-1.25,0.78-2.85,0.65-4.27,1.06c-4.78,1.38-6.07,8.12-10.64,10.08c-2.7,1.16-6.02,0.36-8.57,1.81
						c-4.75,2.71-3.23,10.55-7.2,14.3c-2.74,2.59-7.2,2.38-10.18,4.69c-3.4,2.63-3.82,7.58-6.26,11.12
						c-1.93,2.8-5.21,4.62-8.61,4.78C324.66,298.47,321.22,296.97,321.26,296.04z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M321.05,291.52c6.5-17.48,7.11-36.82,6.85-55.3c0.9,0.3,1.98-0.05,2.52-0.82c4.29,2.17,3.49,8.83,4.35,12.98c5.71-12.06,9.62-29.96,20.7-37.38c-2.44,8.6-10.58,15.44-10.46,24.38c0.07,5.25,3.05,9.97,4.36,15.05c3.01,10.6-3.04,27.88-16.44,24.22c-2.03,8.65-5.44,16.97-10.16,24.5c-1.18,1.88-5.14,5.85-5.59,1.37C316.9,297.83,319.97,293.88,321.05,291.52z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_046rT = {
  id: "ear_shape_046rT",
  name: "Ears 36 (Bumpy)",
  description:
    "Bumpy, extra wide ears that reach outwards and droop slightly at the tip",
  tags: ["pointed", "side", "bumpy"],
  preview: {
    viewbox: "70 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M184.82 298.12c7.94-14.57 7.04-32.39 3.34-48.56-1.38-6.05-3.17-12.11-6.49-17.35-3.33-5.24-8.38-9.63-14.44-10.99-4.08-.92-8.34-.43-12.48-.99-5.41-.73-10.45-3.22-15.84-4.11-5.46-.89-11.03-.1-16.5.7-7.2 1.04-14.78 2.26-20.41 6.87-2.56 2.1-4.56 4.79-6.53 7.46-.77 1.03-1.57 2.23-1.35 3.5.3 1.69 2.3 2.53 4.01 2.47s3.38-.69 5.1-.63c5.02.18 8.03 5.9 12.77 7.58 2.82 1 6.06.48 8.77 1.74 3.29 1.53 4.94 5.23 5.89 8.74.95 3.51 1.54 7.26 3.77 10.13 2.05 2.64 5.25 4.18 7.39 6.75 4.21 5.05 3.59 13.05 8.21 17.73 3.34 3.39 8.51 4.08 12.64 6.44 3.96 2.25 7.12 6.1 11.56 7.08 5.39 1.18 11.3-3.08 10.59-4.56z" />
      ),
      basic: {
        dark: (
          <path d="M177.59 278.28c-10.72-4.89-.02-82.77-9.82-46.39-16.09-3.61-36.87-13.06-52.13-4.05 27.88-4.53 34.98 20.64 38.56 42.68 1.88 3.72 5.79 6.97 10.16 6.61 8.45-1.02 7.73 20.98 17.71 26.08 4.08-8.31-3.07-16.42-4.48-24.93z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M315.18 298.12c-7.94-14.57-7.04-32.39-3.34-48.56 1.38-6.05 3.17-12.11 6.49-17.35 3.33-5.24 8.38-9.63 14.44-10.99 4.08-.92 8.34-.43 12.48-.99 5.41-.73 10.45-3.22 15.84-4.11 5.46-.89 11.03-.1 16.5.7 7.2 1.04 14.78 2.26 20.41 6.87 2.56 2.1 4.56 4.79 6.53 7.46.77 1.03 1.57 2.23 1.35 3.5-.3 1.69-2.3 2.53-4.01 2.47s-3.38-.69-5.1-.63c-5.02.18-8.03 5.9-12.77 7.58-2.82 1-6.06.48-8.77 1.74-3.29 1.53-4.94 5.23-5.89 8.74-.95 3.51-1.54 7.26-3.77 10.13-2.05 2.64-5.25 4.18-7.39 6.75-4.21 5.05-3.59 13.05-8.21 17.73-3.34 3.39-8.51 4.08-12.64 6.44-3.96 2.25-7.12 6.1-11.56 7.08-5.39 1.18-11.3-3.08-10.59-4.56z" />
      ),
      basic: {
        dark: (
          <path d="M322.41 278.28c10.72-4.89.02-82.77 9.82-46.39 16.09-3.61 36.87-13.06 52.13-4.05-27.88-4.53-34.98 20.64-38.56 42.68-1.88 3.72-5.79 6.97-10.16 6.61-8.45-1.02-7.73 20.98-17.71 26.08-4.08-8.31 3.07-16.42 4.48-24.93z" />
        ),
      },
    },
  },
};

const ear_shape_B4IrT = {
  id: "ear_shape_B4IrT",
  name: "Ears 37 (Bumpy)",
  description: "Bumpy, large ears that bump slightly outwards at the top",
  tags: ["rounded", "side", "bumpy"],
  preview: {
    viewbox: "100 190 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M177.28 291c4.72-9.33 8.65-19.23 9.82-29.62 1.18-10.39-.6-21.38-6.49-30.02-4.14-6.08-10.1-10.74-14.32-16.75-1.84-2.62-3.39-5.53-5.84-7.6-3.66-3.09-8.71-3.79-13.47-4.37-4.73-.57-10.01-1.01-13.77 1.92-3.54 2.76-4.55 7.59-5.31 12.01l-2.73 15.72c-.6 3.47-1.13 7.35.87 10.26 1.69 2.46 4.78 3.56 6.85 5.72 3.89 4.04 3.32 10.62 6.03 15.54 1.61 2.92 4.34 5.18 5.57 8.28 2.15 5.4-.79 11.85 1.45 17.21 1.45 3.48 4.78 5.75 7.93 7.82 2.01 1.32 4.1 2.67 6.47 3.05 4.28.69 8.34-1.87 11.93-4.31 2.72-1.88 5.67-4.07 5.01-4.86z" />
      ),
      basic: {
        dark: (
          <path d="M173.95 235.54c-1.06-5.16 1.33-12.55-3.24-15.66-1.28 2.85-2.06 5.92-2.79 8.92-6.77-6.2-16.17-17.62-25.83-16.08 16.13 11.18 18.68 33.6 13.08 51.25-6.77 13.48 5.33 26 14.24 10.13 1.95 6.89 2.52 14.69 6.41 20.74 8.11-6.61-4.8-22.12-2.8-32.46-.82-8.94.9-17.89.93-26.84z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M322.72 291c-4.72-9.33-8.65-19.23-9.82-29.62-1.18-10.39.6-21.38 6.49-30.02 4.14-6.08 10.1-10.74 14.32-16.75 1.84-2.62 3.39-5.53 5.84-7.6 3.66-3.09 8.71-3.79 13.47-4.37 4.73-.57 10.01-1.01 13.77 1.92 3.54 2.76 4.55 7.59 5.31 12.01l2.73 15.72c.6 3.47 1.13 7.35-.87 10.26-1.69 2.46-4.78 3.56-6.85 5.72-3.89 4.04-3.32 10.62-6.03 15.54-1.61 2.92-4.34 5.18-5.57 8.28-2.15 5.4.79 11.85-1.45 17.21-1.45 3.48-4.78 5.75-7.93 7.82-2.01 1.32-4.1 2.67-6.47 3.05-4.28.69-8.34-1.87-11.93-4.31-2.72-1.88-5.67-4.07-5.01-4.86z" />
      ),
      basic: {
        dark: (
          <path d="M326.05 235.54c1.06-5.16-1.33-12.55 3.24-15.66 1.28 2.85 2.06 5.92 2.79 8.92 6.77-6.2 16.17-17.62 25.83-16.08-16.13 11.18-18.68 33.6-13.08 51.25 6.77 13.48-5.33 26-14.24 10.13-1.95 6.89-2.52 14.69-6.41 20.74-8.11-6.61 4.8-22.12 2.8-32.46.82-8.94-.9-17.89-.93-26.84z" />
        ),
      },
    },
  },
};

const ear_shape_c4XrT = {
  id: "ear_shape_c4XrT",
  name: "Ears 38 (Bumpy)",
  description:
    "Bumpy, tall ears that sweep upwards and slightly out from the side of the head into a slight point",
  tags: ["pointed", "side", "large", "bumpy"],
  preview: {
    viewbox: "80 150 160 160",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M175.88 287.56c4.3.2 7.65-3.81 9.15-7.84 2.57-6.9 1.75-14.98-2.16-21.23-2.83-4.53-7.14-8.09-9.4-12.92-3-6.4-2.01-14.19-5.32-20.44-3.62-6.82-11.64-10.44-15.2-17.29-2.48-4.76-2.49-10.53-5.12-15.21-2.54-4.5-7.14-7.38-11.56-10.06-7.12-4.32-14.23-8.65-21.35-12.97-1.78-1.08-4.2-2.13-5.77-.76-1.42 1.24-.99 3.52-.44 5.33l4.38 14.37c1.55 5.08 3.14 10.25 6.13 14.64 2.29 3.36 5.37 6.22 7.06 9.92 3.11 6.82.85 14.95 2.83 22.19 1.69 6.19 6.37 11.32 7.61 17.62.66 3.33.31 6.78.55 10.16s1.24 6.98 3.89 9.1c2.49 1.99 6.02 2.33 8.44 4.41 2.79 2.39 3.45 6.47 5.76 9.33 2.8 3.45 7.69 4.61 12.1 4.03 4.4-.58 8.46-2.63 8.42-2.38z" />
      ),
      basic: {
        dark: (
          <path d="M178.75 285.07c-4.33-17.6-3-35.86-3.49-53.81.41-6.18-5.53-6.33-7.62-.93-1.04 2.46-1.66 5.07-2.09 7.71-7.17-12.55-21.4-43.01-34.31-48.12 17.77 17.71 20.35 43.22 17.25 66.85.34 5.57 3.41 13.77 9.92 13.44 2.46-.93 4.38-3.77 7.39-2.83 2.04 9.15 3.77 19.64 9.95 26.93 8.96 4.54 4.72-5 3-9.24z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M324.12 287.71c-4.3.2-7.65-3.81-9.15-7.84-2.57-6.9-1.75-14.98 2.16-21.23 2.83-4.53 7.14-8.09 9.4-12.92 3-6.4 2.01-14.19 5.32-20.44 3.62-6.82 11.64-10.44 15.2-17.29 2.48-4.76 2.49-10.53 5.12-15.21 2.54-4.5 7.14-7.38 11.56-10.06 7.12-4.32 14.23-8.65 21.35-12.97 1.78-1.08 4.2-2.13 5.77-.76 1.42 1.24.99 3.52.44 5.33l-4.38 14.37c-1.55 5.08-3.14 10.25-6.13 14.64-2.29 3.36-5.37 6.22-7.06 9.92-3.11 6.82-.85 14.95-2.83 22.19-1.69 6.19-6.37 11.32-7.61 17.62-.66 3.33-.31 6.78-.55 10.16-.24 3.39-1.24 6.98-3.89 9.1-2.49 1.99-6.02 2.33-8.44 4.41-2.79 2.39-3.45 6.47-5.76 9.33-2.8 3.45-7.69 4.61-12.1 4.03-4.4-.59-8.46-2.64-8.42-2.38z" />
      ),
      basic: {
        dark: (
          <path d="M321.25 285.07c4.33-17.6 3-35.86 3.49-53.81-.41-6.18 5.53-6.33 7.62-.93 1.04 2.46 1.66 5.07 2.09 7.71 7.17-12.55 21.4-43.01 34.31-48.12-17.77 17.71-20.35 43.22-17.25 66.85-.34 5.57-3.41 13.77-9.92 13.44-2.46-.93-4.38-3.77-7.39-2.83-2.04 9.15-3.77 19.64-9.95 26.93-8.96 4.54-4.72-5-3-9.24z" />
        ),
      },
    },
  },
};

const ear_shape_U4VrT = {
  id: "ear_shape_U4VrT",
  name: "Ears 39 (Bumpy)",
  description:
    "Bumpy, tall and rounded ears that sweep upwards and slightly out from the side of the head",
  tags: ["rounded", "side", "bumpy"],
  preview: {
    viewbox: "80 150 160 160",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M177.77 289.64c4.21 1.71 9.19-1.24 11.15-5.35s1.58-8.91.74-13.38c-3.37-17.99-15.59-29.76-23.21-49.49-2.27-5.89-9.32-8.85-12.89-14.05-4.31-6.27-8.09-13.19-14.25-17.65-5.06-3.66-11.32-5.33-16.55-8.76-2.37-1.55-4.52-3.46-7.03-4.76-2.52-1.3-5.59-1.94-8.17-.78-4.26 1.91-5.23 7.68-4.06 12.2 1.17 4.51 3.84 8.56 4.74 13.14 1.04 5.3-.28 11.2 2.42 15.87 2.38 4.12 7.3 6.15 10.05 10.03 5.11 7.2 1.38 18.48 7.31 25.04 2.07 2.29 5.04 3.6 7.16 5.85 2.79 2.96 3.76 7.12 5.1 10.95 1.34 3.83 3.51 7.85 7.34 9.19 2.43.85 5.16.47 7.59 1.33 4.04 1.43 6.13 5.8 9.34 8.64 4.67 4.14 12.16 4.67 13.22 1.98z" />
      ),
      basic: {
        dark: (
          <path d="M183.51 287.65c-5.48-20.85-8.15-42.7-6.91-63.78-8.48-4.05-9.28 9.02-9.81 14.57-4.27-8.51-42.68-55.73-51.29-48.2 12.92 7.35 26.35 17.47 28.62 33.42 1.37 8.2-.23 16.69 1.22 24.88 1.22 8.59 12.67 30.44 20.86 16.48 1.93 9.9 1.36 32.41 13.13 34.93 5.49-1.05 5.44-7.97 4.18-12.3z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M322.23 289.64c-4.21 1.71-9.19-1.24-11.15-5.35s-1.58-8.91-.74-13.38c3.37-17.99 15.59-29.76 23.21-49.49 2.27-5.89 9.32-8.85 12.89-14.05 4.31-6.27 8.09-13.19 14.25-17.65 5.06-3.66 11.32-5.33 16.55-8.76 2.37-1.55 4.52-3.46 7.03-4.76 2.52-1.3 5.59-1.94 8.17-.78 4.26 1.91 5.23 7.68 4.06 12.2-1.17 4.51-3.84 8.56-4.74 13.14-1.04 5.3.28 11.2-2.42 15.87-2.38 4.12-7.3 6.15-10.05 10.03-5.11 7.2-1.38 18.48-7.31 25.04-2.07 2.29-5.04 3.6-7.16 5.85-2.79 2.96-3.76 7.12-5.1 10.95-1.34 3.83-3.51 7.85-7.34 9.19-2.43.85-5.16.47-7.59 1.33-4.04 1.43-6.13 5.8-9.34 8.64-4.67 4.14-12.16 4.67-13.22 1.98z" />
      ),
      basic: {
        dark: (
          <path d="M316.49 287.65c5.48-20.85 8.15-42.7 6.91-63.78 8.48-4.05 9.28 9.02 9.81 14.57 4.27-8.51 42.68-55.73 51.29-48.2-12.92 7.35-26.35 17.47-28.62 33.42-1.37 8.2.23 16.69-1.22 24.88-1.22 8.59-12.67 30.44-20.86 16.48-1.93 9.9-1.36 32.41-13.13 34.93-5.49-1.05-5.44-7.97-4.18-12.3z" />
        ),
      },
    },
  },
};

export const EarShapePack3 = {
  ear_shape_T4KrT,
  ear_shape_047rT,
  ear_shape_H4ZrT,
  ear_shape_v4JrT,
  ear_shape_F4HrT,
  ear_shape_a4GrT,
  ear_shape_Z4RrT,
  ear_shape_v4yrT,
  ear_shape_T4LrT,
  ear_shape_34nrT,
  ear_shape_P4UrT,
  ear_shape_k4BrT,
  ear_shape_y4TrT,
  ear_shape_84ArT,
  ear_shape_g4orT,
  ear_shape_b4DrT,
  ear_shape_o4ArT,
  ear_shape_D46rT,
  ear_shape_k48rT,
  ear_shape_84DrT,
  ear_shape_k4vrT,
  ear_shape_a4jrT,
  ear_shape_24srT,
  ear_shape_a442T,
  ear_shape_Z4prT,
  ear_shape_j4OrT,
  ear_shape_T4UrT,
  ear_shape_W43rT,
  ear_shape_X4DrT,
  ear_shape_x4zrT,
  ear_shape_e4GrT,
  ear_shape_44BrT,
  ear_shape_z4yrT,
  ear_shape_k4DrT,
  ear_shape_A4TrT,
  ear_shape_046rT,
  ear_shape_B4IrT,
  ear_shape_c4XrT,
  ear_shape_U4VrT,
};

import React from "react";
import ItemComponent from "character-creator/components/ItemComponent";

const BlankOptionPreview = (props) => {
  return (
    <g fill="none" stroke="#F0F0F0" strokeWidth="8" strokeMiterlimit="10">
      <circle cx="50" cy="50" r="29.9" />
      <line x1="28.86" y1="71.14" x2="71.14" y2="28.86" />
    </g>
  );
};

export const BlankListOption = {
  id: "0",
  name: "Blank",
  description: "No item",
  preview: {
    viewbox: "0 0 100 100",
    base: BlankOptionPreview,
  },
  shape: {
    base: <></>,
  },
};

export const BlankControl = (props) => {
  return (
    <ItemComponent
      id={props.id}
      component={BlankListOption}
      colour={{
        base: "None",
        outline: "None",
      }}
    />
  );
};

export default BlankControl;

import React from "react";
import { connect } from "react-redux";
import FacePaint from "character-creator/components/head/markings/facePaint/CharacterFacePaint";
import HeadScar from "./scars/HeadScar";
import Tattoo from "./tattoos/Tattoo";
import HeadMarkings from "./HeadMarkings";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";

function mapStateToProps(state, ownProps) {
  const headMarkingsConfig = getCharacterConfigValue(state, "head-markings");
  const faceMarkingsConfig = getCharacterConfigValue(state, "face-markings");
  const faceExtrasConfig = getCharacterConfigValue(state, "face-extras");

  return {
    markings: {
      scar:
        faceMarkingsConfig?.scars?.scar1?.shape &&
        faceMarkingsConfig?.scars?.scar1?.shape !== "0",
      facePaint:
        faceExtrasConfig?.facePaint?.shape &&
        faceExtrasConfig?.facePaint?.shape !== "0",
      marking1:
        headMarkingsConfig?.marking1?.shape &&
        headMarkingsConfig?.marking1?.shape !== "0",
      tattoo:
        faceMarkingsConfig?.tattoo?.shape &&
        faceMarkingsConfig?.tattoo?.shape !== "0",
    },
    ...ownProps,
  };
}

//alternateMask={maskUrl}

const ClippedMarkings = (props) => {
  const { componentName, maskUrl, markings, maskTransform } = props;
  return (
    <g id={"g-" + componentName + "-markings"}>
      <mask id={maskUrl + "_markings"} fill="white" fillRule="evenodd">
        <use
          href={`#${maskUrl}`}
          transform-origin="50% 50%"
          {...maskTransform}
        />
      </mask>

      {markings.marking1 && (
        <HeadMarkings
          alternateId={`facePaint-${componentName}`}
          alternateMask={maskUrl + "_markings"}
        />
      )}
      {markings.scar && (
        <HeadScar
          alternateId={`facePaint-${componentName}`}
          alternateMask={maskUrl + "_markings"}
        />
      )}
      {markings.tattoo && (
        <Tattoo
          alternateId={`facePaint-${componentName}`}
          alternateMask={maskUrl + "_markings"}
        />
      )}
      {markings.facePaint && (
        <FacePaint
          alternateId={`facePaint-${componentName}`}
          alternateMask={maskUrl + "_markings"}
        />
      )}
    </g>
  );
};

export default connect(mapStateToProps)(ClippedMarkings);

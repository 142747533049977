import AllPinks from "character-creator/colours/collections/Pinks";
import LipColours from "character-creator/colours/lips";
import teethColours from "character-creator/colours/teeth";
import Item from "character-creator/components/Item";
import {
  buildTransform,
  filterListById,
  translateTransformObject,
} from "character-creator/menu/menu-helper";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import React from "react";
import { connect } from "react-redux";
import { MouthShapeListArray } from "./MouthShapeList";
import {
  TeethLowerShapeListArray,
  TeethUpperShapeListArray,
} from "./teeth/TeethShapeList";
import ClippedMarkings from "../../markings/ClippedMarkings";

function mapStateToProps(state, ownProps) {
  const mouthConfig = getCharacterConfigValue(state, "face.mouth");
  const skintone = getCharacterConfigValue(state, "skintone");

  const teethStateConfig = mouthConfig?.teeth;
  const teethFromMouthShape = mouthConfig?.shape.teeth;

  let teethConfig;
  if (ownProps.previewId && !ownProps.previewItem && ownProps.teeth) {
    teethConfig = {
      upper: {
        shape:
          ownProps.teeth.preview.side === "upper"
            ? ownProps.teeth.shape[
                mouthConfig.shape ?? ownProps.defaultMouthShape
              ]
            : null,
        colour: teethStateConfig?.upper?.colour ?? null,
      },
      lower: {
        shape:
          ownProps.teeth.preview.side === "lower"
            ? ownProps.teeth.shape[
                mouthConfig.shape ?? ownProps.defaultMouthShape
              ]
            : null,
        colour: teethStateConfig?.lower?.colour ?? null,
      },
    };
  } else {
    teethConfig = {
      upper: {
        shape: teethStateConfig?.upper?.shape
          ? filterListById(
              TeethUpperShapeListArray,
              teethStateConfig?.upper?.shape
            ).shape[
              ownProps.previewItem ? ownProps.previewItem.id : mouthConfig.shape
            ]
          : teethFromMouthShape?.upper?.shape,
        colour: teethStateConfig?.upper?.colour ?? null,
      },
      lower: {
        shape: teethStateConfig?.lower?.shape
          ? filterListById(
              TeethLowerShapeListArray,
              teethStateConfig?.lower?.shape
            ).shape[
              ownProps.previewItem ? ownProps.previewItem.id : mouthConfig.shape
            ]
          : teethFromMouthShape?.lower?.shape,
        colour: teethStateConfig?.upper?.colour ?? null,
      },
    };
  }

  const transform =
    !ownProps.previewId && mouthConfig && mouthConfig.transform
      ? translateTransformObject(buildTransform(mouthConfig.transform))
      : "";

  return {
    mouthConfig,
    mouthShape:
      ownProps.previewId && ownProps.previewItem
        ? ownProps.previewItem
        : mouthConfig?.shape
        ? filterListById(MouthShapeListArray, mouthConfig.shape)
        : null,
    teethConfig,
    underbite:
      ownProps.preview && ownProps.teeth
        ? ownProps.teeth.preview.side === "upper"
          ? false
          : true
        : !!mouthConfig?.underbite,
    defaultColours: {
      lips: LipColours[Object.keys(LipColours)[3]],
      tongue: AllPinks[Object.keys(AllPinks)[1]],
      teeth: teethColours[Object.keys(teethColours)[0]],
      inside: { base: skintone.darker },
      skintone,
    },
    transform,
  };
}

const Mouth = (props) => {
  const {
    mouthConfig,
    mouthShape,
    teethConfig,
    defaultColours,
    transform,
    previewId,
    underbite,
  } = props;

  if (mouthShape) {
    ////////////////////////////////
    // Shadow
    const mouthShadow = {
      shape: mouthShape?.shape.shadow?.shape,
      colour: { base: defaultColours.skintone.dark },
    };

    ////////////////////////////////
    // Invert colours (e.g. for beaks/reptilian mouths)
    const invertLipColours = !!mouthShape.invertLipColours;

    ////////////////////////////////
    // Inside
    const mouthInside = {
      shape: mouthShape?.shape.inside?.shape,
      colour:
        mouthConfig && mouthConfig.colour?.inside
          ? mouthConfig.colour.inside
          : defaultColours.inside,
    };

    ////////////////////////////////
    // Tongue
    const tongue = {
      shape: mouthShape?.shape.tongue?.shape,
      colour:
        mouthConfig && mouthConfig.colour?.tongue
          ? mouthConfig.colour.tongue
          : defaultColours.tongue,
    };

    // Inner

    // Outer

    const lipColourDark = {
      dark: defaultColours.lips.darker,
      base: defaultColours.lips.dark,
      light: defaultColours.lips.base,
    };

    // const lipColourLight = {dark: "", base: "", light: ""}

    ////////////////////////////////
    // Teeth

    const teethColourUpperDark =
      mouthConfig && mouthConfig.teeth?.upper?.colour
        ? { base: mouthConfig.teeth.upper.colour.dark }
        : { base: defaultColours.teeth.dark };

    const teethColourUpperLight =
      mouthConfig && mouthConfig.teeth?.upper?.colour
        ? mouthConfig.teeth.upper.colour
        : defaultColours.teeth;

    const teethColourLowerDark =
      mouthConfig && mouthConfig.teeth?.lower?.colour
        ? { base: mouthConfig.teeth.lower.colour.dark }
        : { base: defaultColours.teeth.dark };

    const teethColourLowerLight =
      mouthConfig && mouthConfig.teeth?.lower?.colour
        ? mouthConfig.teeth.lower.colour
        : defaultColours.teeth;

    // Upper
    const upperTeeth = {
      shape: teethConfig?.upper?.shape,
      colour: !underbite ? teethColourUpperLight : teethColourUpperDark,
    };

    // Lower
    const lowerTeeth = {
      shape: teethConfig?.lower?.shape,
      colour: underbite ? teethColourLowerLight : teethColourLowerDark,
    };

    ////////////////////////////////
    // Lips

    // Upper
    const upperLip = {
      shape: mouthShape?.shape.lips?.upper?.shape,
      colour:
        mouthConfig && mouthConfig.colour?.lips
          ? {
              dark: mouthConfig.colour.lips.darker,
              base: mouthConfig.colour.lips.dark,
              light: mouthConfig.colour.lips.base,
            }
          : invertLipColours
          ? defaultColours.lips
          : lipColourDark,
    };

    // Lower
    const lowerLip = {
      shape: mouthShape?.shape.lips?.lower?.shape,
      colour:
        mouthConfig && mouthConfig.colour?.lips
          ? mouthConfig.colour.lips
          : invertLipColours
          ? lipColourDark
          : defaultColours.lips,
    };

    return (
      <g id={"g-mouth"} transform-origin="50% 50%" {...transform}>
        {/* Shadow */}
        {mouthShadow.shape && (
          <Item
            id={"mouth-shadow"}
            previewId={previewId}
            itemState={mouthShadow}
            itemStateShape={mouthShadow}
            // defaultColour={mouthShadow.colour}
          />
        )}
        {/* Inner mouth */}
        {mouthInside.shape && (
          <Item
            id={"mouth-inside"}
            previewId={previewId}
            itemState={mouthInside}
            itemStateShape={mouthInside}
            // defaultColour={mouthInside.colour}
          />
        )}

        {/* Inner tongue */}
        {/* {tongue?.inner && (
          <ItemComponent
            id={previewId ? `tongue-inside~${previewId}` : "tongue-inside"}
            component={tongue.inner}
            colour={colour.tongue}
            preview={previewId ? true : false}
          />
          )} */}

        {/* If underbite, upper then lower, otherwise lower first */}

        {/* Upper */}
        {underbite && teethConfig?.upper.shape && (
          <Item
            id={"teeth-upper"}
            previewId={previewId}
            itemState={upperTeeth}
            itemStateShape={upperTeeth}
          />
        )}
        {underbite && upperLip.shape && (
          <Item
            id={"lip-upper"}
            previewId={previewId}
            itemState={upperLip}
            itemStateShape={upperLip}
          />
        )}
        {underbite && upperLip.shape && (
          <ClippedMarkings
            componentName="lip-upper"
            maskUrl="lip-upper_base"
            maskTransform={transform}
          />
        )}

        {/* Lower */}

        {teethConfig?.lower.shape && (
          <Item
            id={"teeth-lower"}
            previewId={previewId}
            itemState={lowerTeeth}
            itemStateShape={lowerTeeth}
          />
        )}
        {lowerLip.shape && (
          <Item
            id={"lip-lower"}
            previewId={previewId}
            itemState={lowerLip}
            itemStateShape={lowerLip}
          />
        )}
        {lowerLip.shape && (
          <ClippedMarkings
            componentName="lip-lower"
            maskUrl="lip-lower_base"
            maskTransform={transform}
          />
        )}

        {/* Not underbite */}

        {!underbite && teethConfig?.upper.shape && (
          <Item
            id={"teeth-upper"}
            previewId={previewId}
            itemState={upperTeeth}
            itemStateShape={upperTeeth}
          />
        )}
        {!underbite && upperLip.shape && (
          <Item
            id={"lip-upper"}
            previewId={previewId}
            itemState={upperLip}
            itemStateShape={upperLip}
          />
        )}
        {!underbite && upperLip.shape && (
          <ClippedMarkings
            componentName="lip-upper"
            maskUrl="lip-upper_base"
            maskTransform={transform}
          />
        )}

        {/* Outer tongue */}
        {/* {tongue?.outer && (
          <ItemComponent
            id={previewId ? `tongue-outside~${previewId}` : "tongue-outside"}
            component={tongue.outer}
            colour={colour.tongue}
            preview={previewId ? true : false}
          />
        )} */}

        {/* Clipped markings on lips */}
        {!previewId && (
          <ClippedMarkings
            componentName="mouth"
            maskUrl="mouth_base"
            maskTransform={transform}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Mouth);

import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import { facePaintShapeList } from "./FacePaintShapeList";
import {
  buildTransform,
  filterListById,
} from "character-creator/menu/menu-helper";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";

function mapStateToProps(state, ownProps) {
  const facePaintConfig = getCharacterConfigValue(state, "face-paint");

  let facePaintObject = null;
  if (ownProps.preview && ownProps.facePaint) {
    facePaintObject = ownProps.facePaint;
  } else if (
    facePaintConfig &&
    facePaintConfig.shape &&
    facePaintConfig.shape !== "0"
  ) {
    facePaintObject = filterListById(facePaintShapeList, facePaintConfig.shape);
  }

  const colour = facePaintConfig?.colour
    ? facePaintConfig.colour
    : { base: getCharacterConfigValue(state, "skintone").lighter };

  const transform =
    facePaintConfig && facePaintConfig.transform
      ? buildTransform(facePaintConfig.transform)
      : null;

  return {
    colour,
    transform,
    FacePaint: facePaintObject,
  };
}

const FacePaint = (props) => {
  const {
    colour,
    FacePaint,
    previewId,
    transform,
    alternateId,
    alternateMask,
  } = props;

  let componentId = "facePaint";
  let maskId = previewId ? `head~${previewId}_mask` : "head_mask";

  if (alternateId) {
    componentId = alternateId;
  }
  if (alternateMask) {
    maskId = alternateMask;
  }

  if (FacePaint) {
    return (
      <g
        id={previewId ? `g-${componentId}~${previewId}` : `g-${componentId}`}
        style={{
          mask: `url(#${maskId})`,
        }}
      >
        <ItemComponent
          id={previewId ? `${componentId}~${previewId}` : componentId}
          component={FacePaint}
          colour={colour}
          transform={transform}
          // strokeOverride={false}
        />
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(FacePaint);

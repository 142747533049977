import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDoc, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC5KCpJ24t9RyBwZwAxEwecVUo3JKDkT0c",
  authDomain: "character-art.firebaseapp.com",
  databaseURL:
    "https://character-art-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "character-art",
  storageBucket: "character-art.appspot.com",
  messagingSenderId: "519280826880",
  appId: "1:519280826880:web:ef3010635bac42e86d96e5",
  measurementId: "G-6YS9STRRXL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export function getDocument(docRef) {
  return getDoc(docRef).then((newDoc) => {
    if (newDoc.exists()) {
      // console.log("a. Document data:", newDoc.data());
      return newDoc.data();
    } else {
      // docSnap.data() will be undefined in this case
      // console.log("a. No such document!");
      return null;
    }
  });
}

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;

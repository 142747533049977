import React from "react";
import { connect } from "react-redux";
import { hairBaseList } from "./HairBaseList";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";
import HairColours from "character-creator/colours/hair";
import ItemComponent from "character-creator/components/ItemComponent";

function mapStateToProps(state, ownProps) {
  const defaultHairColour =
    getCharacterConfigValue(state, "hair.colour") ?? HairColours[0];

  const hairConfig = getCharacterConfigValue(state, "hair.base");

  const hairBaseColour = hairConfig?.colour
    ? hairConfig.colour
    : defaultHairColour;

  let hairObject = null;
  if (ownProps.preview) {
    hairObject = ownProps.hair.shape;
  } else if (hairConfig?.shape && hairConfig.shape !== "0") {
    hairObject = filterListById(hairBaseList, hairConfig.shape).shape;
  }

  return {
    full: {
      shape: hairObject,
      colour: hairBaseColour,
    },
  };
}

const CharacterHairBase = (props) => {
  const { full, previewId } = props;

  if (full.shape) {
    return (
      <g
        id={previewId ? `g-hair-base~${previewId}` : "g-hair-base"}
        mask="url(#head_mask)"
      >
        <ItemComponent
          id={previewId ? `hair-base~${previewId}` : "hair-base"}
          component={full}
          colour={full.colour}
          preview={previewId ? true : false}
        />
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(CharacterHairBase);

import React from "react";
import { viewbox, PreviewBase } from "../Preview";

// All optimised

const mole_ydgE53CYEv = {
  id: "mole_ydgE53CYEv",
  name: "Mole 1",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <circle cx="280.685" cy="327.79" r="2" />,
  },
};

const mole_KWkvCFPD6Z = {
  id: "mole_KWkvCFPD6Z",
  name: "Mole 2",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <circle cx="219.315" cy="327.79" r="2" />,
  },
};

const mole_GAVR4aFbSN = {
  id: "mole_GAVR4aFbSN",
  name: "Mole 3",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M280.685 325.79c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2Zm-3.25-41.51c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2Z" />
    ),
  },
};

const mole_MhTD6P8xNh = {
  id: "mole_MhTD6P8xNh",
  name: "Mole 4",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M219.315 325.79c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2Zm3.25-41.51c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2Z" />
    ),
  },
};

const mole_3JS4Vyu5a8 = {
  id: "mole_3JS4Vyu5a8",
  name: "Mole 5",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <circle cx="277.435" cy="286.28" r="2" />,
  },
};

const mole_gSfBzZpM7D = {
  id: "mole_gSfBzZpM7D",
  name: "Mole 6",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <circle cx="222.565" cy="286.28" r="2" />,
  },
};

const mole_rBycpLykD9 = {
  id: "mole_rBycpLykD9",
  name: "Mole 7",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M277.435 284.28c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2Zm35.23-26.45c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2Z" />
    ),
  },
};

const mole_qa5RZHzTep = {
  id: "mole_qa5RZHzTep",
  name: "Mole 8",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M222.565 284.28c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2Zm-35.23-26.45c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2Z" />
    ),
  },
};

const mole_GkEEKe9znK = {
  id: "mole_GkEEKe9znK",
  name: "Mole 9",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <circle cx="312.665" cy="259.83" r="2" />,
  },
};

const mole_gAtRYByqeA = {
  id: "mole_gAtRYByqeA",
  name: "Mole 10",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <circle cx="187.335" cy="259.83" r="2" />,
  },
};

const mole_PFrMxJUbiT = {
  id: "mole_PFrMxJUbiT",
  name: "Mole 11",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M277.435 284.28c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2Zm35.23-76.8c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2Z" />
    ),
  },
};

const mole_GxSY4Pi5zC = {
  id: "mole_GxSY4Pi5zC",
  name: "Mole 12",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M222.565 284.28c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2Zm-35.23-76.8c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2Z" />
    ),
  },
};

const mole_LiD3gWwpJC = {
  id: "mole_LiD3gWwpJC",
  name: "Mole 13",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M187.335 257.83c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2Zm0-50.35c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2Z" />
    ),
  },
};

const mole_S2EGGLViTr = {
  id: "mole_S2EGGLViTr",
  name: "Mole 14",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M312.665 257.83c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2Zm0-50.35c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2Z" />
    ),
  },
};

const mole_dALapkEQzu = {
  id: "mole_dALapkEQzu",
  name: "Mole 15",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <circle cx="312.665" cy="209.48" r="2" />,
  },
};

const mole_XvAwvaGcP7 = {
  id: "mole_XvAwvaGcP7",
  name: "Mole 16",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <circle cx="187.335" cy="209.48" r="2" />,
  },
};

export const molesPack1 = {
  mole_ydgE53CYEv,
  mole_KWkvCFPD6Z,
  mole_GAVR4aFbSN,
  mole_MhTD6P8xNh,
  mole_3JS4Vyu5a8,
  mole_gSfBzZpM7D,
  mole_rBycpLykD9,
  mole_qa5RZHzTep,
  mole_GkEEKe9znK,
  mole_gAtRYByqeA,
  mole_PFrMxJUbiT,
  mole_GxSY4Pi5zC,
  mole_LiD3gWwpJC,
  mole_S2EGGLViTr,
  mole_dALapkEQzu,
  mole_XvAwvaGcP7,
};

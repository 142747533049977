import React from "react";
import { connect } from "react-redux";
import AllBlues from "character-creator/colours/collections/Blues";
import { EarAccessoriesListArray } from "./EarAccessoriesList";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import {
  buildTransform,
  filterListById,
  translateTransformObject,
} from "character-creator/menu/menu-helper";
import ItemComponent from "character-creator/components/ItemComponent";

function mapStateToProps(state, ownProps) {
  const { side, layerConfig } = ownProps;
  let earConfig = getCharacterConfigValue(state, "ears." + side);
  let accessoryObject =
    ownProps.accessory ??
    filterListById(EarAccessoriesListArray, layerConfig.shape);
  let accessoryShape = null;

  // initial config
  if (earConfig && accessoryObject.shape[earConfig.shape]) {
    accessoryShape =
      accessoryObject.shape[earConfig.shape][side].back ??
      accessoryObject.shape[earConfig.shape][side];
  } else if (earConfig && accessoryObject.shape[side]) {
    accessoryShape =
      accessoryObject.shape[side].back ?? accessoryObject.shape[side];
  }

  const colour = layerConfig?.colour ? layerConfig.colour : AllBlues[3];

  const AccessoryLayer = {
    shape: accessoryShape,
    colour: colour,
    transform: layerConfig?.transform
      ? buildTransform(layerConfig.transform)
      : "",
  };

  return {
    AccessoryLayer,
  };
}

const EarAccessoryBack = (props) => {
  const { AccessoryLayer, previewId, layer, side } = props;

  if (AccessoryLayer.shape && AccessoryLayer.shape !== "0") {
    return (
      <ItemComponent
        id={
          previewId
            ? `ear-accessory-layer-back-${layer}-${side}~${previewId}`
            : `ear-accessory-layer-back-${layer}-${side}`
        }
        component={AccessoryLayer}
        colour={AccessoryLayer.colour}
        transform={AccessoryLayer.transform}
      />
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(EarAccessoryBack);

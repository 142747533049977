import React from "react";
import { PreviewBase, previewViewbox } from "../Preview";

const body_patch_jCTwu = {
  id: "body_patch_jCTwu",
  name: "Body patch 1",
  description: "A single, very irregular-edged patch which covers the shoulder",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  useClip: false,
  shape: {
    left: {
      base: (
        <>
          <path d="M102.178 389.406c.394-2.65-7.439 2.395-8.13 2.827-6.502 4.063-11.758 10.419-17.883 15.012-4.549 3.413-8.787 6.8-12.804 10.818-2.363 2.363-3.684 6.365-5.299 9.273-6.556 11.8-9.75 22.689-6.844 35.765 1.518 6.83 1.928 15.267 9.493 18.104 3.511 1.316 5.064-.807 8.169-1.325 6.275-1.046 9.847-2.944 13.246-8.61 1.138-1.897 3.259-7.195 5.52-7.948 1.272-.424 2.492.536 3.753.221 1.785-.446 3.14-2.044 5.299-1.325 1.995.665 5 1.769 7.064.221 6.352-4.764 1.634-9.051 2.87-15.234.604-3.016 4.124-6.331 5.741-7.947 5.41-5.411 13.839.522 18.545-7.948.638-1.149 1.232-2.722 1.545-3.974 1.617-6.468-6.849-3.944-5.74-10.597 1.157-6.941 11.055-6.877 13.688-12.143 2.438-4.876 1.491-10.488 3.753-15.013 1.086-2.171 7.648-10.195 3.312-12.363-6.051-3.025-16.818-3.244-22.961.441-3.225 1.935-5.929 4.731-9.272 6.403-3.353 1.676-6.6 1.539-10.156 2.428-1.334.334-2.505 3.091-3.312 3.091" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <path d="M398.177 389.406c-.394-2.65 7.438 2.395 8.129 2.827 6.502 4.063 11.758 10.419 17.883 15.012 4.55 3.413 8.787 6.8 12.805 10.818 2.362 2.363 3.683 6.365 5.298 9.273 6.556 11.8 9.75 22.689 6.844 35.765-1.518 6.83-1.927 15.267-9.493 18.104-3.511 1.316-5.064-.807-8.169-1.325-6.275-1.046-9.846-2.944-13.246-8.61-1.138-1.897-3.259-7.195-5.52-7.948-1.272-.424-2.492.536-3.753.221-1.784-.446-3.14-2.044-5.298-1.325-1.996.665-5.001 1.769-7.065.221-6.352-4.764-1.634-9.051-2.87-15.234-.603-3.016-4.123-6.331-5.74-7.947-5.41-5.411-13.84.522-18.545-7.948-.638-1.149-1.233-2.722-1.546-3.974-1.617-6.468 6.849-3.944 5.74-10.597-1.156-6.941-11.055-6.877-13.688-12.143-2.438-4.876-1.49-10.488-3.753-15.013-1.085-2.171-7.647-10.195-3.311-12.363 6.05-3.025 16.818-3.244 22.96.441 3.225 1.935 5.929 4.731 9.273 6.403 3.353 1.676 6.599 1.539 10.155 2.428 1.334.334 2.506 3.091 3.312 3.091" />
        </>
      ),
    },
  },
};

const body_patch_asfk3 = {
  id: "body_patch_asfk3",
  name: "Body patch 2",
  description:
    "A single, very irregular-edged patch which covers the shoulder and a smaller, similar patch which goes from the shoulder to the neck",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  useClip: false,
  shape: {
    left: {
      base: (
        <>
          <path d="M186.091 379.471c.138-1.78-1.874-1.589-3.291-1.589-3.705 0-7.285.589-10.818 1.766-1.32.44-2.624 2.244-3.754 3.091-6.502 4.877-9.375 12.206-14.129 18.545-2.047 2.73-3.729 6.771-1.766 9.714 3.65 5.476 12.79 2.937 16.999-.22 1.783-1.337 2.756-3.936 4.857-4.637 3.494-1.164 6.837 2.358 10.598 1.104 7.49-2.497 15.141-3.928 22.298-7.506 3.895-1.948 6.604-6.024 8.168-9.935.444-1.11 1.159-4.168-.22-4.857-1.932-.966-5.62-5.469-8.169-4.195-5.034 2.517-6.649 1.767-12.143 1.767-.661 0-3.226 1.63-3.753 1.103-.717-.717-2.676-4.857-3.974-4.857m-84.816 10.641c.394-2.65-7.439 2.395-8.13 2.827-6.502 4.063-11.758 10.419-17.883 15.012-4.549 3.413-8.787 6.8-12.804 10.818-2.363 2.363-3.684 6.365-5.299 9.273-6.556 11.8-9.75 22.689-6.844 35.765 1.518 6.83 1.928 15.267 9.493 18.104 3.511 1.316 5.064-.807 8.169-1.325 6.275-1.046 9.847-2.944 13.246-8.61 1.138-1.897 3.259-7.195 5.52-7.948 1.272-.424 2.492.536 3.753.221 1.785-.446 3.14-2.044 5.299-1.325 1.995.665 5 1.769 7.064.221 6.352-4.764 1.634-9.051 2.87-15.234.604-3.016 4.124-6.331 5.741-7.947 5.41-5.411 13.839.522 18.545-7.948.638-1.149 1.232-2.722 1.545-3.974 1.617-6.468-6.849-3.944-5.74-10.597 1.157-6.941 11.055-6.877 13.688-12.143 2.438-4.876 1.491-10.488 3.753-15.013 1.086-2.171 7.648-10.195 3.312-12.363-6.051-3.025-16.818-3.244-22.961.441-3.225 1.935-5.929 4.731-9.272 6.403-3.353 1.676-6.6 1.539-10.156 2.428-1.334.334-2.505 3.091-3.312 3.091" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <path d="M313.888 379.471c-.138-1.78 1.874-1.589 3.291-1.589 3.705 0 7.285.589 10.818 1.766 1.32.44 2.624 2.244 3.754 3.091 6.502 4.877 9.374 12.206 14.129 18.545 2.047 2.73 3.729 6.771 1.766 9.714-3.65 5.476-12.79 2.937-16.999-.22-1.783-1.337-2.756-3.936-4.857-4.637-3.494-1.164-6.837 2.358-10.598 1.104-7.49-2.497-15.141-3.928-22.298-7.506-3.895-1.948-6.604-6.024-8.168-9.935-.444-1.11-1.159-4.168.22-4.857 1.932-.966 5.62-5.469 8.169-4.195 5.034 2.517 6.648 1.767 12.143 1.767.661 0 3.226 1.63 3.753 1.103.717-.717 2.676-4.857 3.974-4.857m85.192 10.641c-.394-2.65 7.438 2.395 8.129 2.827 6.502 4.063 11.758 10.419 17.883 15.012 4.55 3.413 8.787 6.8 12.805 10.818 2.362 2.363 3.683 6.365 5.298 9.273 6.556 11.8 9.75 22.689 6.844 35.765-1.518 6.83-1.927 15.267-9.493 18.104-3.511 1.316-5.064-.807-8.169-1.325-6.275-1.046-9.846-2.944-13.246-8.61-1.138-1.897-3.259-7.195-5.52-7.948-1.272-.424-2.492.536-3.753.221-1.784-.446-3.14-2.044-5.298-1.325-1.996.665-5.001 1.769-7.065.221-6.352-4.764-1.634-9.051-2.87-15.234-.603-3.016-4.123-6.331-5.74-7.947-5.41-5.411-13.84.522-18.545-7.948-.638-1.149-1.233-2.722-1.546-3.974-1.617-6.468 6.849-3.944 5.74-10.597-1.156-6.941-11.055-6.877-13.688-12.143-2.438-4.876-1.49-10.488-3.753-15.013-1.085-2.171-7.647-10.195-3.311-12.363 6.05-3.025 16.818-3.244 22.96.441 3.225 1.935 5.929 4.731 9.273 6.403 3.353 1.676 6.599 1.539 10.155 2.428 1.334.334 2.506 3.091 3.312 3.091" />
        </>
      ),
    },
  },
};

const body_patch_03glf = {
  id: "body_patch_03glf",
  name: "Body patch 3",
  description:
    "A single, irregular-edged patch which covers most of the shoulder",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  useClip: false,
  shape: {
    left: {
      base: (
        <>
          <path d="M101.475 392.584c-4.902-.462-12.959 6.018-16.785 8.568-14.856 9.904-24.26 22.874-29.142 39.96-2.011 7.037-4.658 15.425 0 21.636.847 1.13 2.145 3.512 3.532 3.974 4.895 1.632 11.992-2.525 16.338-3.974 5.242-1.747 7.836 4.57 13.025 3.532 2.57-.513 4.995-2.937 7.065-4.415 2.578-1.841 5.721-3.454 7.727-5.961 1.702-2.127 2.037-5.057 2.65-7.506.659-2.64 4.467-5.406 6.402-7.065 6.066-5.199 17.461-9.152 19.207-17.883 1.675-8.371-10.865-6.142-13.688-10.376-1.731-2.597-.006-5.286 1.104-7.507 1.027-2.052 2.248-9.985.663-12.363-1.322-1.982-3.549-2.156-5.52-2.649-1.267-.317-2.557-1.017-3.974-.663-3.223.806-7.08 3.974-10.376 3.974" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <path d="M398.525 392.584c4.902-.462 12.959 6.018 16.785 8.568 14.856 9.904 24.26 22.874 29.142 39.96 2.011 7.037 4.658 15.425 0 21.636-.847 1.13-2.145 3.512-3.532 3.974-4.895 1.632-11.992-2.525-16.338-3.974-5.242-1.747-7.836 4.57-13.025 3.532-2.57-.513-4.995-2.937-7.065-4.415-2.578-1.841-5.721-3.454-7.727-5.961-1.702-2.127-2.037-5.057-2.65-7.506-.659-2.64-4.467-5.406-6.402-7.065-6.066-5.199-17.461-9.152-19.207-17.883-1.675-8.371 10.865-6.142 13.688-10.376 1.731-2.597.006-5.286-1.104-7.507-1.027-2.052-2.248-9.985-.663-12.363 1.322-1.982 3.549-2.156 5.52-2.649 1.267-.317 2.557-1.017 3.974-.663 3.223.806 7.08 3.974 10.376 3.974" />
        </>
      ),
    },
  },
};

const body_patch_731kj = {
  id: "body_patch_731kj",
  name: "Body patch 4",
  description:
    "A single, irregular-edged patch which covers most of the shoulder and a smaller, similar patch which goes from the shoulder to the neck",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  useClip: false,
  shape: {
    left: {
      base: (
        <>
          <path d="M101.475 392.584c-4.902-.462-12.959 6.018-16.785 8.568-14.856 9.904-24.26 22.874-29.142 39.96-2.011 7.037-4.658 15.425 0 21.636.847 1.13 2.145 3.512 3.532 3.974 4.895 1.632 11.992-2.525 16.338-3.974 5.242-1.747 7.836 4.57 13.025 3.532 2.57-.513 4.995-2.937 7.065-4.415 2.578-1.841 5.721-3.454 7.727-5.961 1.702-2.127 2.037-5.057 2.65-7.506.659-2.64 4.467-5.406 6.402-7.065 6.066-5.199 17.461-9.152 19.207-17.883 1.675-8.371-10.865-6.142-13.688-10.376-1.731-2.597-.006-5.286 1.104-7.507 1.027-2.052 2.248-9.985.663-12.363-1.322-1.982-3.549-2.156-5.52-2.649-1.267-.317-2.557-1.017-3.974-.663-3.223.806-7.08 3.974-10.376 3.974m86.388-14.395c.138-1.78-1.874-1.589-3.291-1.589-3.705 0-7.285.589-10.818 1.766-1.32.44-2.624 2.244-3.754 3.091-6.502 4.877-9.375 12.206-14.129 18.545-2.047 2.73-3.729 6.771-1.766 9.714 3.65 5.476 12.79 2.937 16.999-.22 1.783-1.337 2.756-3.936 4.857-4.637 3.494-1.164 6.837 2.358 10.598 1.104 7.49-2.497 15.141-3.928 22.298-7.506 3.895-1.948 6.604-6.024 8.168-9.935.444-1.11 1.159-4.168-.22-4.857-1.932-.966-5.62-5.469-8.169-4.195-5.034 2.517-6.649 1.767-12.143 1.767-.661 0-3.226 1.63-3.753 1.103-.717-.717-2.676-4.857-3.974-4.857" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <path d="M101.475 392.584c-4.902-.462-12.959 6.018-16.785 8.568-14.856 9.904-24.26 22.874-29.142 39.96-2.011 7.037-4.658 15.425 0 21.636.847 1.13 2.145 3.512 3.532 3.974 4.895 1.632 11.992-2.525 16.338-3.974 5.242-1.747 7.836 4.57 13.025 3.532 2.57-.513 4.995-2.937 7.065-4.415 2.578-1.841 5.721-3.454 7.727-5.961 1.702-2.127 2.037-5.057 2.65-7.506.659-2.64 4.467-5.406 6.402-7.065 6.066-5.199 17.461-9.152 19.207-17.883 1.675-8.371-10.865-6.142-13.688-10.376-1.731-2.597-.006-5.286 1.104-7.507 1.027-2.052 2.248-9.985.663-12.363-1.322-1.982-3.549-2.156-5.52-2.649-1.267-.317-2.557-1.017-3.974-.663-3.223.806-7.08 3.974-10.376 3.974m86.388-14.395c.138-1.78-1.874-1.589-3.291-1.589-3.705 0-7.285.589-10.818 1.766-1.32.44-2.624 2.244-3.754 3.091-6.502 4.877-9.375 12.206-14.129 18.545-2.047 2.73-3.729 6.771-1.766 9.714 3.65 5.476 12.79 2.937 16.999-.22 1.783-1.337 2.756-3.936 4.857-4.637 3.494-1.164 6.837 2.358 10.598 1.104 7.49-2.497 15.141-3.928 22.298-7.506 3.895-1.948 6.604-6.024 8.168-9.935.444-1.11 1.159-4.168-.22-4.857-1.932-.966-5.62-5.469-8.169-4.195-5.034 2.517-6.649 1.767-12.143 1.767-.661 0-3.226 1.63-3.753 1.103-.717-.717-2.676-4.857-3.974-4.857" />
        </>
      ),
    },
  },
};

const body_patch_faore = {
  id: "body_patch_faore",
  name: "Body patch 5",
  description:
    "Three irregular-edged patches - one larger one covering the shoulder, a smaller one just above it and another just below",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  useClip: false,
  shape: {
    left: {
      base: (
        <>
          <path d="M99.679 401.854c-2.767-.175-5.183 1.587-7.475 3.074-4.502 2.921-8.384 6.483-12.297 10.224-7.63 7.296-18.205 13.916-22.801 23.959-1.136 2.483-.956 4.851-1.678 7.38-.383 1.34-1.584 2.852-1.307 4.293.676 3.52 6.014 4.254 7.497 7.33 1.614 3.349-1.743 8.452-2.674 11.711-.87 3.049.936 4.489 2.004 6.999 1.825 4.287 1.114 8.237 5.158 11.005.684.468 2.194 1.576 3.175 1.206 1.663-.628 3.909-.935 5.202-2.506 1.929-2.344 2.865-5.468 4.756-7.999 3.395-4.541 9.672-5.627 13.329-9.605 1.766-1.921 1.916-3.709 2.924-5.912 1.625-3.551-1.739-4.899-2.705-7.742-.975-2.872-.394-7.828.648-10.636.82-2.212 3.664-3.306 5.436-4.456 3.304-2.143 6.864-3.956 9.237-7.13 2.51-3.358 1.891-6.006 1.27-9.242-.135-.701.156-2.808-.233-3.325-.58-.771-1.317-2.104-1.518-3.149-.669-3.482 4.987-7.305 6.277-10.124 1.324-2.894-1.325-4.603-3.134-5.487-.572-.278-2.589.14-2.961-.356-.605-.804-.821-1.93-2.337-1.599-1.424.31-2.732.891-4.111 1.318-1.494.463-2.927.472-4.461.288m43.094-7.807c.054-1.505-2.621-1.215-3.656-.956-3.039.759-8.269 3.278-10.278 5.689-4.089 4.907 1.352 8.058-.367 13.215-.753 2.259-2.555 3.19-3.854 5.138-2.036 3.054-4.744 9.756-2.57 11.93 1.1 1.1 5.338-.726 6.791 0 5.328 2.664 13.964-1.315 16.701-6.791 1.156-2.311 1.124-5.356 2.57-7.524 2.1-3.15 5.208-4.798 6.423-8.443 1.539-4.614-1.275-8.621-4.588-10.278-1.206-.603-7.172-.511-7.172-1.98ZM75.392 503.834c-2.232-.049-.949-5.352.013-6.794.825-1.237 2.028-1.984 2.936-3.12 2.999-3.748 4.976-9.49 10.095-11.196 1.19-.396 2.321.774 3.303 1.101.919.307 4.577.516 4.956 1.652 2.13 6.39-.805 20.113-6.975 24.226-1.349.9-4.513.454-5.873 0-4.132-1.377-9.176-2.649-9.176-8.259" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <path d="M400.321 401.854c2.767-.175 5.183 1.587 7.475 3.074 4.502 2.921 8.384 6.483 12.297 10.224 7.63 7.296 18.205 13.916 22.801 23.959 1.136 2.483.956 4.851 1.678 7.38.383 1.34 1.584 2.852 1.307 4.293-.676 3.52-6.014 4.254-7.497 7.33-1.614 3.349 1.743 8.452 2.674 11.711.87 3.049-.936 4.489-2.004 6.999-1.825 4.287-1.114 8.237-5.158 11.005-.684.468-2.194 1.576-3.175 1.206-1.663-.628-3.909-.935-5.202-2.506-1.929-2.344-2.865-5.468-4.756-7.999-3.395-4.541-9.672-5.627-13.329-9.605-1.766-1.921-1.916-3.709-2.924-5.912-1.625-3.551 1.739-4.899 2.705-7.742.975-2.872.394-7.828-.648-10.636-.82-2.212-3.664-3.306-5.436-4.456-3.304-2.143-6.864-3.956-9.237-7.13-2.51-3.358-1.891-6.006-1.27-9.242.135-.701-.156-2.808.233-3.325.58-.771 1.317-2.104 1.518-3.149.669-3.482-4.987-7.305-6.277-10.124-1.324-2.894 1.325-4.603 3.134-5.487.572-.278 2.589.14 2.961-.356.605-.804.821-1.93 2.337-1.599 1.424.31 2.732.891 4.111 1.318 1.494.463 2.927.472 4.461.288m-43.094-7.807c-.054-1.505 2.621-1.215 3.656-.956 3.039.759 8.269 3.278 10.278 5.689 4.089 4.907-1.352 8.058.367 13.215.753 2.259 2.555 3.19 3.854 5.138 2.036 3.054 4.744 9.756 2.57 11.93-1.1 1.1-5.338-.726-6.791 0-5.328 2.664-13.964-1.315-16.701-6.791-1.156-2.311-1.124-5.356-2.57-7.524-2.1-3.15-5.208-4.798-6.423-8.443-1.539-4.614 1.275-8.621 4.588-10.278 1.206-.603 7.172-.511 7.172-1.98Zm64.602 110.268c2.232-.049.949-5.352-.013-6.794-.825-1.237-2.028-1.984-2.936-3.12-2.999-3.748-4.976-9.49-10.095-11.196-1.19-.396-2.321.774-3.303 1.101-.919.307-4.577.516-4.956 1.652-2.13 6.39.805 20.113 6.975 24.226 1.349.9 4.513.454 5.873 0 4.132-1.377 9.176-2.649 9.176-8.259" />
        </>
      ),
    },
  },
};

export const BodyPatchesPack1 = {
  body_patch_jCTwu,
  body_patch_asfk3,
  body_patch_03glf,
  body_patch_731kj,
  body_patch_faore,
};

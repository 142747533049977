import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { TailPack1 } from "./packs/TailsPack1";
import { TailPack2 } from "./packs/TailsPack2";

const TailShapeList = {
  "0": BlankListOption,
  ...TailPack1,
  ...TailPack2,
};

export const TailShapeListArray = Object.values(TailShapeList);

import React from "react";
import { PreviewBase, viewbox } from "../../Preview";

const shapeEar1 = {
  left: {
    base: <circle cx="164.021" cy="274.008" r="3.562" />,
    basic: {
      dark: (
        <path d="m162.743 275.977-.384 1.181c-.231-.122-.446-.268-.643-.435l1.027-.746Zm2.573-.004.784-2.429h1.453c.019.152.03.307.03.464 0 1.083-.485 2.055-1.25 2.708l-1.023-.743h.006Z" />
      ),
      light: (
        <path d="m167.145 277.306-1.835-1.333.79-2.429h1.743c-.007-.72-.062-1.231-.062-1.231l-1.694 1.231-2.066-1.501-.789-2.429-3.344 2.429 2.067 1.501h-2.551l1.277 3.93 2.062-1.497.001-.004h2.554l.715 2.202c.498-.102.864-.424 1.132-.869Z" />
      ),
      lighter: (
        <path d="m163.367 270.031 1.197.34-.543 1.672 2.066 1.501-.771 2.429h-2.568l-.79-2.429h-1.754l-.009-1.278 1.76 1.278 2.066-1.501-.654-2.012Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="274.008" r="3.562" />,
    basic: {
      dark: (
        <path d="m334.701 275.977-.384 1.181c-.23-.122-.446-.268-.643-.435l1.027-.746Zm2.574-.004.783-2.429h1.453c.02.152.03.307.03.464 0 1.083-.485 2.055-1.249 2.708l-1.023-.743h.006Z" />
      ),
      light: (
        <path d="m339.103 277.306-1.834-1.333.789-2.429h1.744c-.008-.72-.063-1.231-.063-1.231l-1.694 1.231-2.066-1.501-.789-2.429-3.343 2.429 2.066 1.501h-2.55l1.277 3.93 2.061-1.497.001-.004h2.554l.716 2.202c.497-.102.863-.424 1.131-.869Z" />
      ),
      lighter: (
        <path d="m335.326 270.031 1.196.34-.543 1.672 2.066 1.501-.77 2.429h-2.569l-.789-2.429h-1.755l-.008-1.278 1.759 1.278 2.066-1.501-.653-2.012Z" />
      ),
    },
  },
};

const shapeEar2 = {
  left: {
    base: <circle cx="164.021" cy="274.574" r="3.562" />,
    basic: {
      dark: (
        <path d="m162.743 276.543-.384 1.181c-.231-.122-.446-.268-.643-.436l1.027-.745Zm2.573-.004.784-2.429h1.453c.019.152.03.307.03.464 0 1.083-.485 2.054-1.25 2.708l-1.023-.743h.006Z" />
      ),
      light: (
        <path d="m167.145 277.872-1.835-1.333.79-2.429h1.743c-.007-.721-.062-1.231-.062-1.231l-1.694 1.231-2.066-1.501-.789-2.429-3.344 2.429 2.067 1.501h-2.551l1.277 3.93 2.062-1.497.001-.004h2.554l.715 2.202c.498-.102.864-.425 1.132-.869Z" />
      ),
      lighter: (
        <path d="m163.367 270.597 1.197.34-.543 1.672 2.066 1.501-.771 2.429h-2.568l-.79-2.429h-1.754l-.009-1.278 1.76 1.278 2.066-1.501-.654-2.012Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="274.574" r="3.562" />,
    basic: {
      dark: (
        <path d="m334.701 276.543-.384 1.181c-.23-.122-.446-.268-.643-.436l1.027-.745Zm2.574-.004.783-2.429h1.453c.02.152.03.307.03.464 0 1.083-.485 2.054-1.249 2.708l-1.023-.743h.006Z" />
      ),
      light: (
        <path d="m339.103 277.872-1.834-1.333.789-2.429h1.744c-.008-.721-.063-1.231-.063-1.231l-1.694 1.231-2.066-1.501-.789-2.429-3.343 2.429 2.066 1.501h-2.55l1.277 3.93 2.061-1.497.001-.004h2.554l.716 2.202c.497-.102.863-.425 1.131-.869Z" />
      ),
      lighter: (
        <path d="m335.326 270.597 1.196.34-.543 1.672 2.066 1.501-.77 2.429h-2.569l-.789-2.429h-1.755l-.008-1.278 1.759 1.278 2.066-1.501-.653-2.012Z" />
      ),
    },
  },
};

const shapeEar3 = {
  left: {
    base: <circle cx="164.021" cy="277.139" r="3.562" />,
    basic: {
      dark: (
        <path d="m162.743 279.108-.384 1.181c-.231-.121-.446-.268-.643-.435l1.027-.746Zm2.573-.004.784-2.429h1.453c.019.152.03.307.03.464 0 1.083-.485 2.055-1.25 2.708l-1.023-.743h.006Z" />
      ),
      light: (
        <path d="m167.145 280.437-1.835-1.333.79-2.429h1.743c-.007-.72-.062-1.23-.062-1.23l-1.694 1.23-2.066-1.501-.789-2.429-3.344 2.429 2.067 1.501h-2.551l1.277 3.93 2.062-1.497.001-.004h2.554l.715 2.203c.498-.103.864-.425 1.132-.87Z" />
      ),
      lighter: (
        <path d="m163.367 273.162 1.197.34-.543 1.672 2.066 1.501-.771 2.429h-2.568l-.79-2.429h-1.754l-.009-1.278 1.76 1.278 2.066-1.501-.654-2.012Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="277.139" r="3.562" />,
    basic: {
      dark: (
        <path d="m334.701 279.108-.384 1.181c-.23-.121-.446-.268-.643-.435l1.027-.746Zm2.574-.004.783-2.429h1.453c.02.152.03.307.03.464 0 1.083-.485 2.055-1.249 2.708l-1.023-.743h.006Z" />
      ),
      light: (
        <path d="m339.103 280.437-1.834-1.333.789-2.429h1.744c-.008-.72-.063-1.23-.063-1.23l-1.694 1.23-2.066-1.501-.789-2.429-3.343 2.429 2.066 1.501h-2.55l1.277 3.93 2.061-1.497.001-.004h2.554l.716 2.203c.497-.103.863-.425 1.131-.87Z" />
      ),
      lighter: (
        <path d="m335.326 273.162 1.196.34-.543 1.672 2.066 1.501-.77 2.429h-2.569l-.789-2.429h-1.755l-.008-1.278 1.759 1.278 2.066-1.501-.653-2.012Z" />
      ),
    },
  },
};

const shapeEar4 = {
  left: {
    base: <circle cx="164.021" cy="275.917" r="3.562" />,
    basic: {
      dark: (
        <path d="M162.743,277.886L162.359,279.067C162.128,278.946 161.913,278.799 161.716,278.632L162.743,277.886ZM165.316,277.882L166.1,275.453L167.553,275.453C167.572,275.605 167.583,275.76 167.583,275.917C167.583,277 167.098,277.972 166.333,278.625L165.31,277.882L165.31,277.882L165.316,277.882Z" />
      ),
      light: (
        <path d="M167.145,279.215L165.31,277.882L166.1,275.453L167.843,275.453C167.836,274.733 167.781,274.223 167.781,274.223L166.087,275.453L164.021,273.952L163.232,271.523L159.888,273.952L161.955,275.453L159.404,275.453L160.681,279.383L162.743,277.886L162.744,277.882L165.298,277.882L166.013,280.085C166.511,279.982 166.877,279.66 167.145,279.215Z" />
      ),
      lighter: (
        <path d="M163.367,271.94L164.564,272.28L164.021,273.952L166.087,275.453L165.316,277.882L162.748,277.882L161.958,275.453L160.204,275.453L160.195,274.175L161.955,275.453L164.021,273.952L163.367,271.94Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="275.917" r="3.562" />,
    basic: {
      dark: (
        <path d="M334.701,277.886L334.317,279.067C334.087,278.946 333.871,278.799 333.674,278.632L334.701,277.886ZM337.275,277.882L338.058,275.453L339.511,275.453C339.531,275.605 339.541,275.76 339.541,275.917C339.541,277 339.056,277.972 338.292,278.625L337.269,277.882L337.269,277.882L337.275,277.882Z" />
      ),
      light: (
        <path d="M339.103,279.215L337.269,277.882L338.058,275.453L339.802,275.453C339.794,274.733 339.739,274.223 339.739,274.223L338.045,275.453L335.979,273.952L335.19,271.523L331.847,273.952L333.913,275.453L331.363,275.453L332.64,279.383L334.701,277.886L334.702,277.882L337.256,277.882L337.972,280.085C338.469,279.982 338.835,279.66 339.103,279.215Z" />
      ),
      lighter: (
        <path d="M335.326,271.94L336.522,272.28L335.979,273.952L338.045,275.453L337.275,277.882L334.706,277.882L333.917,275.453L332.162,275.453L332.154,274.175L333.913,275.453L335.979,273.952L335.326,271.94Z" />
      ),
    },
  },
};

const shapeEar5 = {
  left: {
    base: <circle cx="164.021" cy="282.248" r="3.562" />,
    basic: {
      dark: (
        <path
          id="dark128"
          d="M162.743,284.217L162.359,285.398C162.128,285.276 161.913,285.13 161.716,284.963L162.743,284.217ZM165.316,284.213L166.1,281.784L167.553,281.784C167.572,281.936 167.583,282.091 167.583,282.248C167.583,283.331 167.098,284.303 166.333,284.956L165.31,284.213L165.31,284.213L165.316,284.213Z"
        />
      ),
      light: (
        <path
          id="light124"
          d="M167.145,285.546L165.31,284.213L166.1,281.784L167.843,281.784C167.836,281.064 167.781,280.553 167.781,280.553L166.087,281.784L164.021,280.283L163.232,277.854L159.888,280.283L161.955,281.784L159.404,281.784L160.681,285.714L162.743,284.217L162.744,284.213L165.298,284.213L166.013,286.415C166.511,286.313 166.877,285.991 167.145,285.546Z"
        />
      ),
      lighter: (
        <path
          id="lighter50"
          d="M163.367,278.271L164.564,278.611L164.021,280.283L166.087,281.784L165.316,284.213L162.748,284.213L161.958,281.784L160.204,281.784L160.195,280.506L161.955,281.784L164.021,280.283L163.367,278.271Z"
        />
      ),
    },
  },
  right: {
    base: <circle id="right45" cx="335.979" cy="282.248" r="3.562" />,
    basic: {
      dark: (
        <path
          id="dark127"
          d="M334.701,284.217L334.317,285.398C334.087,285.276 333.871,285.13 333.674,284.963L334.701,284.217ZM337.275,284.213L338.058,281.784L339.511,281.784C339.531,281.936 339.541,282.091 339.541,282.248C339.541,283.331 339.056,284.303 338.292,284.956L337.269,284.213L337.269,284.213L337.275,284.213Z"
        />
      ),
      light: (
        <path
          id="light123"
          d="M339.103,285.546L337.269,284.213L338.058,281.784L339.802,281.784C339.794,281.064 339.739,280.553 339.739,280.553L338.045,281.784L335.979,280.283L335.19,277.854L331.847,280.283L333.913,281.784L331.363,281.784L332.64,285.714L334.701,284.217L334.702,284.213L337.256,284.213L337.972,286.415C338.469,286.313 338.835,285.991 339.103,285.546Z"
        />
      ),
      lighter: (
        <path
          id="lighter49"
          d="M335.326,278.271L336.522,278.611L335.979,280.283L338.045,281.784L337.275,284.213L334.706,284.213L333.917,281.784L332.162,281.784L332.154,280.506L333.913,281.784L335.979,280.283L335.326,278.271Z"
        />
      ),
    },
  },
};

const shapeEar6 = {
  left: {
    base: <circle cx="164.021" cy="272.892" r="3.562" />,
    basic: {
      dark: (
        <path d="M162.743,274.861L162.359,276.042C162.128,275.92 161.913,275.774 161.716,275.607L162.743,274.861ZM165.316,274.857L166.1,272.428L167.553,272.428C167.572,272.58 167.583,272.735 167.583,272.892C167.583,273.975 167.098,274.946 166.333,275.6L165.31,274.857L165.31,274.857L165.316,274.857Z" />
      ),
      light: (
        <path d="M167.145,276.19L165.31,274.857L166.1,272.428L167.843,272.428C167.836,271.708 167.781,271.197 167.781,271.197L166.087,272.428L164.021,270.927L163.232,268.498L159.888,270.927L161.955,272.428L159.404,272.428L160.681,276.358L162.743,274.861L162.744,274.857L165.298,274.857L166.013,277.059C166.511,276.957 166.877,276.634 167.145,276.19Z" />
      ),
      lighter: (
        <path d="M163.367,268.915L164.564,269.255L164.021,270.927L166.087,272.428L165.316,274.857L162.748,274.857L161.958,272.428L160.204,272.428L160.195,271.15L161.955,272.428L164.021,270.927L163.367,268.915Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="272.892" r="3.562" />,
    basic: {
      dark: (
        <path d="M334.701,274.861L334.317,276.042C334.087,275.92 333.871,275.774 333.674,275.607L334.701,274.861ZM337.275,274.857L338.058,272.428L339.511,272.428C339.531,272.58 339.541,272.735 339.541,272.892C339.541,273.975 339.056,274.946 338.292,275.6L337.269,274.857L337.269,274.857L337.275,274.857Z" />
      ),
      light: (
        <path d="M339.103,276.19L337.269,274.857L338.058,272.428L339.802,272.428C339.794,271.708 339.739,271.197 339.739,271.197L338.045,272.428L335.979,270.927L335.19,268.498L331.847,270.927L333.913,272.428L331.363,272.428L332.64,276.358L334.701,274.861L334.702,274.857L337.256,274.857L337.972,277.059C338.469,276.957 338.835,276.634 339.103,276.19Z" />
      ),
      lighter: (
        <path d="M335.326,268.915L336.522,269.255L335.979,270.927L338.045,272.428L337.275,274.857L334.706,274.857L333.917,272.428L332.162,272.428L332.154,271.15L333.913,272.428L335.979,270.927L335.326,268.915Z" />
      ),
    },
  },
};

const shapeEar7 = {
  left: {
    base: <circle cx="164.021" cy="272.892" r="3.562" />,
    basic: {
      dark: (
        <path d="M162.743,274.861L162.359,276.042C162.128,275.92 161.913,275.774 161.716,275.607L162.743,274.861ZM165.316,274.857L166.1,272.428L167.553,272.428C167.572,272.58 167.583,272.735 167.583,272.892C167.583,273.975 167.098,274.946 166.333,275.6L165.31,274.857L165.31,274.857L165.316,274.857Z" />
      ),
      light: (
        <path d="M167.145,276.19L165.31,274.857L166.1,272.428L167.843,272.428C167.836,271.708 167.781,271.197 167.781,271.197L166.087,272.428L164.021,270.927L163.232,268.498L159.888,270.927L161.955,272.428L159.404,272.428L160.681,276.358L162.743,274.861L162.744,274.857L165.298,274.857L166.013,277.059C166.511,276.957 166.877,276.634 167.145,276.19Z" />
      ),
      lighter: (
        <path d="M163.367,268.915L164.564,269.255L164.021,270.927L166.087,272.428L165.316,274.857L162.748,274.857L161.958,272.428L160.204,272.428L160.195,271.15L161.955,272.428L164.021,270.927L163.367,268.915Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="272.892" r="3.562" />,
    basic: {
      dark: (
        <path d="M334.701,274.861L334.317,276.042C334.087,275.92 333.871,275.774 333.674,275.607L334.701,274.861ZM337.275,274.857L338.058,272.428L339.511,272.428C339.531,272.58 339.541,272.735 339.541,272.892C339.541,273.975 339.056,274.946 338.292,275.6L337.269,274.857L337.269,274.857L337.275,274.857Z" />
      ),
      light: (
        <path d="M339.103,276.19L337.269,274.857L338.058,272.428L339.802,272.428C339.794,271.708 339.739,271.197 339.739,271.197L338.045,272.428L335.979,270.927L335.19,268.498L331.847,270.927L333.913,272.428L331.363,272.428L332.64,276.358L334.701,274.861L334.702,274.857L337.256,274.857L337.972,277.059C338.469,276.957 338.835,276.634 339.103,276.19Z" />
      ),
      lighter: (
        <path d="M335.326,268.915L336.522,269.255L335.979,270.927L338.045,272.428L337.275,274.857L334.706,274.857L333.917,272.428L332.162,272.428L332.154,271.15L333.913,272.428L335.979,270.927L335.326,268.915Z" />
      ),
    },
  },
};

const shapeEar8 = {
  left: {
    base: <circle cx="147.412" cy="290.748" r="3.562" />,
    basic: {
      dark: (
        <path d="M146.134,292.717L145.75,293.899C145.52,293.777 145.304,293.63 145.107,293.463L146.134,292.717ZM148.708,292.713L149.491,290.284L150.944,290.284C150.964,290.436 150.974,290.591 150.974,290.748C150.974,291.832 150.489,292.803 149.725,293.456L148.702,292.713L148.702,292.713L148.708,292.713Z" />
      ),
      light: (
        <path d="M150.536,294.046L148.702,292.713L149.491,290.284L151.235,290.284C151.227,289.564 151.172,289.054 151.172,289.054L149.478,290.284L147.412,288.783L146.623,286.354L143.28,288.783L145.346,290.284L142.796,290.284L144.073,294.214L146.134,292.717L146.135,292.713L148.689,292.713L149.405,294.916C149.902,294.813 150.268,294.491 150.536,294.046Z" />
      ),
      lighter: (
        <path d="M146.759,286.771L147.955,287.111L147.412,288.783L149.478,290.284L148.708,292.713L146.139,292.713L145.35,290.284L143.595,290.284L143.587,289.006L145.346,290.284L147.412,288.783L146.759,286.771Z" />
      ),
    },
  },
  right: {
    base: <circle cx="352.629" cy="290.748" r="3.562" />,
    basic: {
      dark: (
        <path d="M351.351,292.717L350.967,293.899C350.736,293.777 350.521,293.63 350.324,293.463L351.351,292.717ZM353.924,292.713L354.708,290.284L356.161,290.284C356.18,290.436 356.191,290.591 356.191,290.748C356.191,291.832 355.706,292.803 354.941,293.456L353.918,292.713L353.918,292.713L353.924,292.713Z" />
      ),
      light: (
        <path d="M355.753,294.046L353.918,292.713L354.708,290.284L356.451,290.284C356.444,289.564 356.389,289.054 356.389,289.054L354.695,290.284L352.629,288.783L351.84,286.354L348.496,288.783L350.563,290.284L348.012,290.284L349.289,294.214L351.351,292.717L351.352,292.713L353.906,292.713L354.621,294.916C355.119,294.813 355.485,294.491 355.753,294.046Z" />
      ),
      lighter: (
        <path d="M351.975,286.771L353.172,287.111L352.629,288.783L354.695,290.284L353.924,292.713L351.356,292.713L350.566,290.284L348.812,290.284L348.803,289.006L350.563,290.284L352.629,288.783L351.975,286.771Z" />
      ),
    },
  },
};

const shapeEar9 = {
  left: {
    base: <circle cx="167.433" cy="201.782" r="3.562" />,
    basic: {
      dark: (
        <path d="M166.155,203.751L165.771,204.933C165.541,204.811 165.325,204.664 165.128,204.497L166.155,203.751ZM168.729,203.747L169.512,201.318L170.965,201.318C170.985,201.47 170.995,201.625 170.995,201.782C170.995,202.866 170.51,203.837 169.746,204.491L168.723,203.747L168.723,203.747L168.729,203.747Z" />
      ),
      light: (
        <path d="M170.557,205.08L168.723,203.747L169.512,201.318L171.256,201.318C171.248,200.598 171.193,200.088 171.193,200.088L169.499,201.318L167.433,199.817L166.644,197.388L163.301,199.817L165.367,201.318L162.817,201.318L164.094,205.249L166.155,203.751L166.156,203.747L168.71,203.747L169.426,205.95C169.923,205.847 170.289,205.525 170.557,205.08Z" />
      ),
      lighter: (
        <path d="M166.779,197.805L167.976,198.145L167.433,199.817L169.499,201.318L168.729,203.747L166.16,203.747L165.371,201.318L163.616,201.318L163.608,200.04L165.367,201.318L167.433,199.817L166.779,197.805Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.567" cy="201.782" r="3.562" />,
    basic: {
      dark: (
        <path d="M331.289,203.751L330.905,204.933C330.674,204.811 330.459,204.664 330.262,204.497L331.289,203.751ZM333.862,203.747L334.646,201.318L336.099,201.318C336.119,201.47 336.129,201.625 336.129,201.782C336.129,202.866 335.644,203.837 334.879,204.491L333.856,203.747L333.856,203.747L333.862,203.747Z" />
      ),
      light: (
        <path d="M335.691,205.08L333.856,203.747L334.646,201.318L336.389,201.318C336.382,200.598 336.327,200.088 336.327,200.088L334.633,201.318L332.567,199.817L331.778,197.388L328.434,199.817L330.501,201.318L327.95,201.318L329.227,205.249L331.289,203.751L331.29,203.747L333.844,203.747L334.56,205.95C335.057,205.847 335.423,205.525 335.691,205.08Z" />
      ),
      lighter: (
        <path d="M331.913,197.805L333.11,198.145L332.567,199.817L334.633,201.318L333.862,203.747L331.294,203.747L330.504,201.318L328.75,201.318L328.741,200.04L330.501,201.318L332.567,199.817L331.913,197.805Z" />
      ),
    },
  },
};

const shapeEar10 = {
  left: {
    base: <circle cx="167.433" cy="203.292" r="3.562" />,
    basic: {
      dark: (
        <path d="M166.155,205.261L165.771,206.442C165.541,206.32 165.325,206.174 165.128,206.006L166.155,205.261ZM168.729,205.257L169.512,202.828L170.965,202.828C170.985,202.98 170.995,203.135 170.995,203.292C170.995,204.375 170.51,205.346 169.746,206L168.723,205.257L168.723,205.257L168.729,205.257Z" />
      ),
      light: (
        <path d="M170.557,206.59L168.723,205.257L169.512,202.828L171.256,202.828C171.248,202.107 171.193,201.597 171.193,201.597L169.499,202.828L167.433,201.327L166.644,198.898L163.301,201.327L165.367,202.828L162.817,202.828L164.094,206.758L166.155,205.261L166.156,205.257L168.71,205.257L169.426,207.459C169.923,207.357 170.289,207.034 170.557,206.59Z" />
      ),
      lighter: (
        <path d="M166.779,199.315L167.976,199.655L167.433,201.327L169.499,202.828L168.729,205.257L166.16,205.257L165.371,202.828L163.616,202.828L163.608,201.55L165.367,202.828L167.433,201.327L166.779,199.315Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.567" cy="203.292" r="3.562" />,
    basic: {
      dark: (
        <path d="M331.289,205.261L330.905,206.442C330.674,206.32 330.459,206.174 330.262,206.006L331.289,205.261ZM333.862,205.257L334.646,202.828L336.099,202.828C336.119,202.98 336.129,203.135 336.129,203.292C336.129,204.375 335.644,205.346 334.879,206L333.856,205.257L333.856,205.257L333.862,205.257Z" />
      ),
      light: (
        <path d="M335.691,206.59L333.856,205.257L334.646,202.828L336.389,202.828C336.382,202.107 336.327,201.597 336.327,201.597L334.633,202.828L332.567,201.327L331.778,198.898L328.434,201.327L330.501,202.828L327.95,202.828L329.227,206.758L331.289,205.261L331.29,205.257L333.844,205.257L334.56,207.459C335.057,207.357 335.423,207.034 335.691,206.59Z" />
      ),
      lighter: (
        <path d="M331.913,199.315L333.11,199.655L332.567,201.327L334.633,202.828L333.862,205.257L331.294,205.257L330.504,202.828L328.75,202.828L328.741,201.55L330.501,202.828L332.567,201.327L331.913,199.315Z" />
      ),
    },
  },
};

const shapeEar11 = {
  left: {
    base: <circle cx="164.021" cy="271.219" r="3.562" />,
    basic: {
      dark: (
        <path d="M162.743,273.188L162.359,274.369C162.128,274.248 161.913,274.101 161.716,273.934L162.743,273.188ZM165.316,273.184L166.1,270.755L167.553,270.755C167.572,270.907 167.583,271.062 167.583,271.219C167.583,272.302 167.098,273.274 166.333,273.927L165.31,273.184L165.31,273.184L165.316,273.184Z" />
      ),
      light: (
        <path d="M167.145,274.517L165.31,273.184L166.1,270.755L167.843,270.755C167.836,270.035 167.781,269.525 167.781,269.525L166.087,270.755L164.021,269.254L163.232,266.825L159.888,269.254L161.955,270.755L159.404,270.755L160.681,274.685L162.743,273.188L162.744,273.184L165.298,273.184L166.013,275.387C166.511,275.284 166.877,274.962 167.145,274.517Z" />
      ),
      lighter: (
        <path d="M163.367,267.242L164.564,267.582L164.021,269.254L166.087,270.755L165.316,273.184L162.748,273.184L161.958,270.755L160.204,270.755L160.195,269.477L161.955,270.755L164.021,269.254L163.367,267.242Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="271.219" r="3.562" />,
    basic: {
      dark: (
        <path d="M334.701,273.188L334.317,274.369C334.087,274.248 333.871,274.101 333.674,273.934L334.701,273.188ZM337.275,273.184L338.058,270.755L339.511,270.755C339.531,270.907 339.541,271.062 339.541,271.219C339.541,272.302 339.056,273.274 338.292,273.927L337.269,273.184L337.269,273.184L337.275,273.184Z" />
      ),
      light: (
        <path d="M339.103,274.517L337.269,273.184L338.058,270.755L339.802,270.755C339.794,270.035 339.739,269.525 339.739,269.525L338.045,270.755L335.979,269.254L335.19,266.825L331.847,269.254L333.913,270.755L331.363,270.755L332.64,274.685L334.701,273.188L334.702,273.184L337.256,273.184L337.972,275.387C338.469,275.284 338.835,274.962 339.103,274.517Z" />
      ),
      lighter: (
        <path d="M335.326,267.242L336.522,267.582L335.979,269.254L338.045,270.755L337.275,273.184L334.706,273.184L333.917,270.755L332.162,270.755L332.154,269.477L333.913,270.755L335.979,269.254L335.326,267.242Z" />
      ),
    },
  },
};

const shapeEar12 = {
  left: {
    base: <circle cx="164.021" cy="268.779" r="3.562" />,
    basic: {
      dark: (
        <path d="M162.743,270.748L162.359,271.93C162.128,271.808 161.913,271.662 161.716,271.494L162.743,270.748ZM165.316,270.745L166.1,268.316L167.553,268.316C167.572,268.468 167.583,268.622 167.583,268.779C167.583,269.863 167.098,270.834 166.333,271.488L165.31,270.745L165.31,270.745L165.316,270.745Z" />
      ),
      light: (
        <path d="M167.145,272.078L165.31,270.745L166.1,268.316L167.843,268.316C167.836,267.595 167.781,267.085 167.781,267.085L166.087,268.316L164.021,266.814L163.232,264.385L159.888,266.814L161.955,268.316L159.404,268.316L160.681,272.246L162.743,270.748L162.744,270.745L165.298,270.745L166.013,272.947C166.511,272.845 166.877,272.522 167.145,272.078Z" />
      ),
      lighter: (
        <path d="M163.367,264.803L164.564,265.143L164.021,266.814L166.087,268.316L165.316,270.745L162.748,270.745L161.958,268.316L160.204,268.316L160.195,267.038L161.955,268.316L164.021,266.814L163.367,264.803Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="268.779" r="3.562" />,
    basic: {
      dark: (
        <path d="M334.701,270.748L334.317,271.93C334.087,271.808 333.871,271.662 333.674,271.494L334.701,270.748ZM337.275,270.745L338.058,268.316L339.511,268.316C339.531,268.468 339.541,268.622 339.541,268.779C339.541,269.863 339.056,270.834 338.292,271.488L337.269,270.745L337.269,270.745L337.275,270.745Z" />
      ),
      light: (
        <path d="M339.103,272.078L337.269,270.745L338.058,268.316L339.802,268.316C339.794,267.595 339.739,267.085 339.739,267.085L338.045,268.316L335.979,266.814L335.19,264.385L331.847,266.814L333.913,268.316L331.363,268.316L332.64,272.246L334.701,270.748L334.702,270.745L337.256,270.745L337.972,272.947C338.469,272.845 338.835,272.522 339.103,272.078Z" />
      ),
      lighter: (
        <path d="M335.326,264.803L336.522,265.143L335.979,266.814L338.045,268.316L337.275,270.745L334.706,270.745L333.917,268.316L332.162,268.316L332.154,267.038L333.913,268.316L335.979,266.814L335.326,264.803Z" />
      ),
    },
  },
};

const shapeEar13 = {
  left: {
    base: <circle cx="160.845" cy="282.739" r="3.562" />,
    basic: {
      dark: (
        <path d="M159.567,284.708L159.183,285.889C158.952,285.767 158.737,285.621 158.54,285.454L159.567,284.708ZM162.141,284.704L162.924,282.275L164.377,282.275C164.397,282.427 164.407,282.582 164.407,282.739C164.407,283.822 163.922,284.794 163.157,285.447L162.135,284.704L162.135,284.704L162.141,284.704Z" />
      ),
      light: (
        <path d="M163.969,286.037L162.135,284.704L162.924,282.275L164.667,282.275C164.66,281.555 164.605,281.044 164.605,281.044L162.911,282.275L160.845,280.774L160.056,278.345L156.712,280.774L158.779,282.275L156.229,282.275L157.506,286.205L159.567,284.708L159.568,284.704L162.122,284.704L162.838,286.906C163.335,286.804 163.701,286.481 163.969,286.037Z" />
      ),
      lighter: (
        <path d="M160.191,278.762L161.388,279.102L160.845,280.774L162.911,282.275L162.141,284.704L159.572,284.704L158.783,282.275L157.028,282.275L157.019,280.997L158.779,282.275L160.845,280.774L160.191,278.762Z" />
      ),
    },
  },
  right: {
    base: <circle cx="339.155" cy="282.739" r="3.562" />,
    basic: {
      dark: (
        <path d="M337.877,284.708L337.493,285.889C337.263,285.767 337.047,285.621 336.85,285.454L337.877,284.708ZM340.451,284.704L341.234,282.275L342.687,282.275C342.707,282.427 342.717,282.582 342.717,282.739C342.717,283.822 342.232,284.794 341.467,285.447L340.445,284.704L340.445,284.704L340.451,284.704Z" />
      ),
      light: (
        <path d="M342.279,286.037L340.445,284.704L341.234,282.275L342.978,282.275C342.97,281.555 342.915,281.044 342.915,281.044L341.221,282.275L339.155,280.774L338.366,278.345L335.023,280.774L337.089,282.275L334.539,282.275L335.816,286.205L337.877,284.708L337.878,284.704L340.432,284.704L341.148,286.906C341.645,286.804 342.011,286.481 342.279,286.037Z" />
      ),
      lighter: (
        <path d="M338.501,278.762L339.698,279.102L339.155,280.774L341.221,282.275L340.451,284.704L337.882,284.704L337.093,282.275L335.338,282.275L335.33,280.997L337.089,282.275L339.155,280.774L338.501,278.762Z" />
      ),
    },
  },
};

const shapeEar14 = {
  left: {
    base: <circle cx="164.021" cy="282.305" r="3.562" />,
    basic: {
      dark: (
        <path d="M162.743,284.274L162.359,285.456C162.128,285.334 161.913,285.188 161.716,285.02L162.743,284.274ZM165.316,284.271L166.1,281.842L167.553,281.842C167.572,281.994 167.583,282.148 167.583,282.305C167.583,283.389 167.098,284.36 166.333,285.014L165.31,284.271L165.31,284.271L165.316,284.271Z" />
      ),
      light: (
        <path d="M167.145,285.604L165.31,284.271L166.1,281.842L167.843,281.842C167.836,281.121 167.781,280.611 167.781,280.611L166.087,281.842L164.021,280.34L163.232,277.911L159.888,280.34L161.955,281.842L159.404,281.842L160.681,285.772L162.743,284.274L162.744,284.271L165.298,284.271L166.013,286.473C166.511,286.37 166.877,286.048 167.145,285.604Z" />
      ),
      lighter: (
        <path d="M163.367,278.329L164.564,278.669L164.021,280.34L166.087,281.842L165.316,284.271L162.748,284.271L161.958,281.842L160.204,281.842L160.195,280.564L161.955,281.842L164.021,280.34L163.367,278.329Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="282.305" r="3.562" />,
    basic: {
      dark: (
        <path d="M334.701,284.274L334.317,285.456C334.087,285.334 333.871,285.188 333.674,285.02L334.701,284.274ZM337.275,284.271L338.058,281.842L339.511,281.842C339.531,281.994 339.541,282.148 339.541,282.305C339.541,283.389 339.056,284.36 338.292,285.014L337.269,284.271L337.269,284.271L337.275,284.271Z" />
      ),
      light: (
        <path d="M339.103,285.604L337.269,284.271L338.058,281.842L339.802,281.842C339.794,281.121 339.739,280.611 339.739,280.611L338.045,281.842L335.979,280.34L335.19,277.911L331.847,280.34L333.913,281.842L331.363,281.842L332.64,285.772L334.701,284.274L334.702,284.271L337.256,284.271L337.972,286.473C338.469,286.37 338.835,286.048 339.103,285.604Z" />
      ),
      lighter: (
        <path d="M335.326,278.329L336.522,278.669L335.979,280.34L338.045,281.842L337.275,284.271L334.706,284.271L333.917,281.842L332.162,281.842L332.154,280.564L333.913,281.842L335.979,280.34L335.326,278.329Z" />
      ),
    },
  },
};

const shapeEar15 = {
  left: {
    base: <circle cx="157.914" cy="281.61" r="3.562" />,
    basic: {
      dark: (
        <path d="M156.636,283.579L156.252,284.76C156.021,284.639 155.806,284.492 155.609,284.325L156.636,283.579ZM159.21,283.575L159.993,281.146L161.446,281.146C161.466,281.298 161.476,281.453 161.476,281.61C161.476,282.693 160.991,283.665 160.226,284.318L159.203,283.575L159.204,283.575L159.21,283.575Z" />
      ),
      light: (
        <path d="M161.038,284.908L159.203,283.575L159.993,281.146L161.736,281.146C161.729,280.426 161.674,279.916 161.674,279.916L159.98,281.146L157.914,279.645L157.125,277.216L153.781,279.645L155.848,281.146L153.298,281.146L154.575,285.076L156.636,283.579L156.637,283.575L159.191,283.575L159.907,285.778C160.404,285.675 160.77,285.353 161.038,284.908Z" />
      ),
      lighter: (
        <path d="M157.26,277.633L158.457,277.973L157.914,279.645L159.98,281.146L159.21,283.575L156.641,283.575L155.852,281.146L154.097,281.146L154.088,279.868L155.848,281.146L157.914,279.645L157.26,277.633Z" />
      ),
    },
  },
  right: {
    base: <circle cx="342.086" cy="281.61" r="3.562" />,
    basic: {
      dark: (
        <path d="M340.808,283.579L340.424,284.76C340.194,284.639 339.978,284.492 339.781,284.325L340.808,283.579ZM343.382,283.575L344.165,281.146L345.618,281.146C345.638,281.298 345.648,281.453 345.648,281.61C345.648,282.693 345.163,283.665 344.398,284.318L343.376,283.575L343.376,283.575L343.382,283.575Z" />
      ),
      light: (
        <path d="M345.21,284.908L343.376,283.575L344.165,281.146L345.909,281.146C345.901,280.426 345.846,279.916 345.846,279.916L344.152,281.146L342.086,279.645L341.297,277.216L337.954,279.645L340.02,281.146L337.47,281.146L338.747,285.076L340.808,283.579L340.809,283.575L343.363,283.575L344.079,285.778C344.576,285.675 344.942,285.353 345.21,284.908Z" />
      ),
      lighter: (
        <path d="M341.432,277.633L342.629,277.973L342.086,279.645L344.152,281.146L343.382,283.575L340.813,283.575L340.024,281.146L338.269,281.146L338.261,279.868L340.02,281.146L342.086,279.645L341.432,277.633Z" />
      ),
    },
  },
};

const shapeEar16 = {
  left: {
    base: <circle cx="164.021" cy="273.568" r="3.562" />,
    basic: {
      dark: (
        <path d="M162.743,275.537L162.359,276.719C162.128,276.597 161.913,276.451 161.716,276.283L162.743,275.537ZM165.316,275.534L166.1,273.105L167.553,273.105C167.572,273.257 167.583,273.411 167.583,273.568C167.583,274.652 167.098,275.623 166.333,276.277L165.31,275.534L165.31,275.534L165.316,275.534Z" />
      ),
      light: (
        <path d="M167.145,276.867L165.31,275.534L166.1,273.105L167.843,273.105C167.836,272.384 167.781,271.874 167.781,271.874L166.087,273.105L164.021,271.603L163.232,269.174L159.888,271.603L161.955,273.105L159.404,273.105L160.681,277.035L162.743,275.537L162.744,275.534L165.298,275.534L166.013,277.736C166.511,277.634 166.877,277.311 167.145,276.867Z" />
      ),
      lighter: (
        <path d="M163.367,269.592L164.564,269.932L164.021,271.603L166.087,273.105L165.316,275.534L162.748,275.534L161.958,273.105L160.204,273.105L160.195,271.827L161.955,273.105L164.021,271.603L163.367,269.592Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="273.568" r="3.562" />,
    basic: {
      dark: (
        <path d="M334.701,275.537L334.317,276.719C334.087,276.597 333.871,276.451 333.674,276.283L334.701,275.537ZM337.275,275.534L338.058,273.105L339.511,273.105C339.531,273.257 339.541,273.411 339.541,273.568C339.541,274.652 339.056,275.623 338.292,276.277L337.269,275.534L337.269,275.534L337.275,275.534Z" />
      ),
      light: (
        <path d="M339.103,276.867L337.269,275.534L338.058,273.105L339.802,273.105C339.794,272.384 339.739,271.874 339.739,271.874L338.045,273.105L335.979,271.603L335.19,269.174L331.847,271.603L333.913,273.105L331.363,273.105L332.64,277.035L334.701,275.537L334.702,275.534L337.256,275.534L337.972,277.736C338.469,277.634 338.835,277.311 339.103,276.867Z" />
      ),
      lighter: (
        <path d="M335.326,269.592L336.522,269.932L335.979,271.603L338.045,273.105L337.275,275.534L334.706,275.534L333.917,273.105L332.162,273.105L332.154,271.827L333.913,273.105L335.979,271.603L335.326,269.592Z" />
      ),
    },
  },
};

const shapeEar17 = {
  left: {
    base: <circle cx="167.196" cy="281.61" r="3.562" />,
    basic: {
      dark: (
        <path d="M165.918,283.579L165.534,284.76C165.303,284.639 165.088,284.492 164.891,284.325L165.918,283.579ZM168.491,283.575L169.275,281.146L170.728,281.146C170.748,281.298 170.758,281.453 170.758,281.61C170.758,282.693 170.273,283.665 169.508,284.318L168.485,283.575L168.485,283.575L168.491,283.575Z" />
      ),
      light: (
        <path d="M170.32,284.908L168.485,283.575L169.275,281.146L171.018,281.146C171.011,280.426 170.956,279.916 170.956,279.916L169.262,281.146L167.196,279.645L166.407,277.216L163.063,279.645L165.13,281.146L162.579,281.146L163.856,285.076L165.918,283.579L165.919,283.575L168.473,283.575L169.189,285.778C169.686,285.675 170.052,285.353 170.32,284.908Z" />
      ),
      lighter: (
        <path d="M166.542,277.633L167.739,277.973L167.196,279.645L169.262,281.146L168.491,283.575L165.923,283.575L165.133,281.146L163.379,281.146L163.37,279.868L165.13,281.146L167.196,279.645L166.542,277.633Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.804" cy="281.61" r="3.562" />,
    basic: {
      dark: (
        <path d="M331.526,283.579L331.142,284.76C330.912,284.639 330.696,284.492 330.499,284.325L331.526,283.579ZM334.1,283.575L334.883,281.146L336.336,281.146C336.356,281.298 336.366,281.453 336.366,281.61C336.366,282.693 335.881,283.665 335.117,284.318L334.094,283.575L334.094,283.575L334.1,283.575Z" />
      ),
      light: (
        <path d="M335.928,284.908L334.094,283.575L334.883,281.146L336.627,281.146C336.619,280.426 336.564,279.916 336.564,279.916L334.87,281.146L332.804,279.645L332.015,277.216L328.672,279.645L330.738,281.146L328.188,281.146L329.465,285.076L331.526,283.579L331.527,283.575L334.081,283.575L334.797,285.778C335.294,285.675 335.66,285.353 335.928,284.908Z" />
      ),
      lighter: (
        <path d="M332.15,277.633L333.347,277.973L332.804,279.645L334.87,281.146L334.1,283.575L331.531,283.575L330.742,281.146L328.987,281.146L328.979,279.868L330.738,281.146L332.804,279.645L332.15,277.633Z" />
      ),
    },
  },
};

const shapeEar18 = {
  left: {
    base: <circle cx="164.021" cy="282.176" r="3.562" />,
    basic: {
      dark: (
        <path d="M162.743,284.145L162.359,285.327C162.128,285.205 161.913,285.058 161.716,284.891L162.743,284.145ZM165.316,284.141L166.1,281.712L167.553,281.712C167.572,281.864 167.583,282.019 167.583,282.176C167.583,283.26 167.098,284.231 166.333,284.884L165.31,284.141L165.31,284.141L165.316,284.141Z" />
      ),
      light: (
        <path d="M167.145,285.474L165.31,284.141L166.1,281.712L167.843,281.712C167.836,280.992 167.781,280.482 167.781,280.482L166.087,281.712L164.021,280.211L163.232,277.782L159.888,280.211L161.955,281.712L159.404,281.712L160.681,285.643L162.743,284.145L162.744,284.141L165.298,284.141L166.013,286.344C166.511,286.241 166.877,285.919 167.145,285.474Z" />
      ),
      lighter: (
        <path d="M163.367,278.199L164.564,278.539L164.021,280.211L166.087,281.712L165.316,284.141L162.748,284.141L161.958,281.712L160.204,281.712L160.195,280.434L161.955,281.712L164.021,280.211L163.367,278.199Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="282.176" r="3.562" />,
    basic: {
      dark: (
        <path d="M334.701,284.145L334.317,285.327C334.087,285.205 333.871,285.058 333.674,284.891L334.701,284.145ZM337.275,284.141L338.058,281.712L339.511,281.712C339.531,281.864 339.541,282.019 339.541,282.176C339.541,283.26 339.056,284.231 338.292,284.884L337.269,284.141L337.269,284.141L337.275,284.141Z" />
      ),
      light: (
        <path d="M339.103,285.474L337.269,284.141L338.058,281.712L339.802,281.712C339.794,280.992 339.739,280.482 339.739,280.482L338.045,281.712L335.979,280.211L335.19,277.782L331.847,280.211L333.913,281.712L331.363,281.712L332.64,285.643L334.701,284.145L334.702,284.141L337.256,284.141L337.972,286.344C338.469,286.241 338.835,285.919 339.103,285.474Z" />
      ),
      lighter: (
        <path d="M335.326,278.199L336.522,278.539L335.979,280.211L338.045,281.712L337.275,284.141L334.706,284.141L333.917,281.712L332.162,281.712L332.154,280.434L333.913,281.712L335.979,280.211L335.326,278.199Z" />
      ),
    },
  },
};

const shapeEar19 = {
  left: {
    base: <circle cx="167.433" cy="200.184" r="3.562" />,
    basic: {
      dark: (
        <path d="M166.155,202.153L165.771,203.335C165.541,203.213 165.325,203.066 165.128,202.899L166.155,202.153ZM168.729,202.149L169.512,199.72L170.965,199.72C170.985,199.872 170.995,200.027 170.995,200.184C170.995,201.268 170.51,202.239 169.746,202.893L168.723,202.149L168.723,202.149L168.729,202.149Z" />
      ),
      light: (
        <path d="M170.557,203.482L168.723,202.149L169.512,199.72L171.256,199.72C171.248,199 171.193,198.49 171.193,198.49L169.499,199.72L167.433,198.219L166.644,195.79L163.301,198.219L165.367,199.72L162.817,199.72L164.094,203.651L166.155,202.153L166.156,202.149L168.71,202.149L169.426,204.352C169.923,204.249 170.289,203.927 170.557,203.482Z" />
      ),
      lighter: (
        <path d="M166.779,196.207L167.976,196.547L167.433,198.219L169.499,199.72L168.729,202.149L166.16,202.149L165.371,199.72L163.616,199.72L163.608,198.442L165.367,199.72L167.433,198.219L166.779,196.207Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.567" cy="200.184" r="3.562" />,
    basic: {
      dark: (
        <path d="M331.289,202.153L330.905,203.335C330.674,203.213 330.459,203.066 330.262,202.899L331.289,202.153ZM333.862,202.149L334.646,199.72L336.099,199.72C336.119,199.872 336.129,200.027 336.129,200.184C336.129,201.268 335.644,202.239 334.879,202.893L333.856,202.149L333.856,202.149L333.862,202.149Z" />
      ),
      light: (
        <path d="M335.691,203.482L333.856,202.149L334.646,199.72L336.389,199.72C336.382,199 336.327,198.49 336.327,198.49L334.633,199.72L332.567,198.219L331.778,195.79L328.434,198.219L330.501,199.72L327.95,199.72L329.227,203.651L331.289,202.153L331.29,202.149L333.844,202.149L334.56,204.352C335.057,204.249 335.423,203.927 335.691,203.482Z" />
      ),
      lighter: (
        <path d="M331.913,196.207L333.11,196.547L332.567,198.219L334.633,199.72L333.862,202.149L331.294,202.149L330.504,199.72L328.75,199.72L328.741,198.442L330.501,199.72L332.567,198.219L331.913,196.207Z" />
      ),
    },
  },
};

const shapeEar20 = {
  left: {
    base: <circle cx="147.412" cy="324.473" r="3.562" />,
    basic: {
      dark: (
        <path d="M146.134,326.442L145.75,327.623C145.52,327.501 145.304,327.355 145.107,327.188L146.134,326.442ZM148.708,326.438L149.491,324.009L150.944,324.009C150.964,324.161 150.974,324.316 150.974,324.473C150.974,325.556 150.489,326.528 149.725,327.181L148.702,326.438L148.702,326.438L148.708,326.438Z" />
      ),
      light: (
        <path d="M150.536,327.771L148.702,326.438L149.491,324.009L151.235,324.009C151.227,323.289 151.172,322.778 151.172,322.778L149.478,324.009L147.412,322.508L146.623,320.079L143.28,322.508L145.346,324.009L142.796,324.009L144.073,327.939L146.134,326.442L146.135,326.438L148.689,326.438L149.405,328.64C149.902,328.538 150.268,328.215 150.536,327.771Z" />
      ),
      lighter: (
        <path d="M146.759,320.496L147.955,320.836L147.412,322.508L149.478,324.009L148.708,326.438L146.139,326.438L145.35,324.009L143.595,324.009L143.587,322.731L145.346,324.009L147.412,322.508L146.759,320.496Z" />
      ),
    },
  },
  right: {
    base: <circle cx="352.629" cy="324.473" r="3.562" />,
    basic: {
      dark: (
        <path d="M351.351,326.442L350.967,327.623C350.736,327.501 350.521,327.355 350.324,327.188L351.351,326.442ZM353.924,326.438L354.708,324.009L356.161,324.009C356.18,324.161 356.191,324.316 356.191,324.473C356.191,325.556 355.706,326.528 354.941,327.181L353.918,326.438L353.918,326.438L353.924,326.438Z" />
      ),
      light: (
        <path d="M355.753,327.771L353.918,326.438L354.708,324.009L356.451,324.009C356.444,323.289 356.389,322.778 356.389,322.778L354.695,324.009L352.629,322.508L351.84,320.079L348.496,322.508L350.563,324.009L348.012,324.009L349.289,327.939L351.351,326.442L351.352,326.438L353.906,326.438L354.621,328.64C355.119,328.538 355.485,328.215 355.753,327.771Z" />
      ),
      lighter: (
        <path d="M351.975,320.496L353.172,320.836L352.629,322.508L354.695,324.009L353.924,326.438L351.356,326.438L350.566,324.009L348.812,324.009L348.803,322.731L350.563,324.009L352.629,322.508L351.975,320.496Z" />
      ),
    },
  },
};

const shapeEar21 = {
  left: {
    base: <circle id="left221" cx="154.883" cy="297.407" r="3.562" />,
    basic: {
      dark: (
        <path
          id="dark544"
          d="M153.605,299.376L153.221,300.557C152.991,300.435 152.775,300.289 152.578,300.121L153.605,299.376ZM156.179,299.372L156.962,296.943L158.415,296.943C158.435,297.095 158.445,297.25 158.445,297.407C158.445,298.49 157.96,299.461 157.196,300.115L156.173,299.372L156.173,299.372L156.179,299.372Z"
        />
      ),
      light: (
        <path
          id="light540"
          d="M158.007,300.705L156.173,299.372L156.962,296.943L158.706,296.943C158.698,296.222 158.643,295.712 158.643,295.712L156.95,296.943L154.883,295.442L154.094,293.013L150.751,295.442L152.817,296.943L150.267,296.943L151.544,300.873L153.605,299.376L153.606,299.372L156.16,299.372L156.876,301.574C157.373,301.472 157.739,301.149 158.007,300.705Z"
        />
      ),
      lighter: (
        <path
          id="lighter242"
          d="M154.23,293.43L155.427,293.77L154.883,295.442L156.95,296.943L156.179,299.372L153.61,299.372L152.821,296.943L151.066,296.943L151.058,295.665L152.817,296.943L154.883,295.442L154.23,293.43Z"
        />
      ),
    },
  },
  right: {
    base: <circle cx="345.117" cy="297.407" r="3.562" />,
    basic: {
      dark: (
        <path d="M343.838,299.376L343.455,300.557C343.224,300.435 343.009,300.289 342.812,300.121L343.838,299.376ZM346.412,299.372L347.196,296.943L348.649,296.943C348.668,297.095 348.679,297.25 348.679,297.407C348.679,298.49 348.194,299.461 347.429,300.115L346.406,299.372L346.406,299.372L346.412,299.372Z" />
      ),
      light: (
        <path d="M348.241,300.705L346.406,299.372L347.196,296.943L348.939,296.943C348.932,296.222 348.877,295.712 348.877,295.712L347.183,296.943L345.117,295.442L344.327,293.013L340.984,295.442L343.05,296.943L340.5,296.943L341.777,300.873L343.838,299.376L343.84,299.372L346.394,299.372L347.109,301.574C347.607,301.472 347.973,301.149 348.241,300.705Z" />
      ),
      lighter: (
        <path d="M344.463,293.43L345.66,293.77L345.117,295.442L347.183,296.943L346.412,299.372L343.844,299.372L343.054,296.943L341.3,296.943L341.291,295.665L343.05,296.943L345.117,295.442L344.463,293.43Z" />
      ),
    },
  },
};

const shapeEar22 = {
  left: {
    base: <circle cx="150.372" cy="307.979" r="3.562" />,
    basic: {
      dark: (
        <path d="M149.093,309.948L148.71,311.129C148.479,311.008 148.264,310.861 148.067,310.694L149.093,309.948ZM151.667,309.944L152.451,307.515L153.904,307.515C153.923,307.667 153.934,307.822 153.934,307.979C153.934,309.063 153.449,310.034 152.684,310.687L151.661,309.944L151.661,309.944L151.667,309.944Z" />
      ),
      light: (
        <path d="M153.496,311.277L151.661,309.944L152.451,307.515L154.194,307.515C154.187,306.795 154.132,306.285 154.132,306.285L152.438,307.515L150.372,306.014L149.583,303.585L146.239,306.014L148.305,307.515L145.755,307.515L147.032,311.445L149.093,309.948L149.095,309.944L151.649,309.944L152.364,312.147C152.862,312.044 153.228,311.722 153.496,311.277Z" />
      ),
      lighter: (
        <path d="M149.718,304.002L150.915,304.342L150.372,306.014L152.438,307.515L151.667,309.944L149.099,309.944L148.309,307.515L146.555,307.515L146.546,306.237L148.305,307.515L150.372,306.014L149.718,304.002Z" />
      ),
    },
  },
  right: {
    base: <circle cx="350.564" cy="307.979" r="3.562" />,
    basic: {
      dark: (
        <path d="M349.286,309.948L348.902,311.129C348.672,311.008 348.456,310.861 348.259,310.694L349.286,309.948ZM351.86,309.944L352.643,307.515L354.096,307.515C354.116,307.667 354.126,307.822 354.126,307.979C354.126,309.063 353.641,310.034 352.877,310.687L351.854,309.944L351.854,309.944L351.86,309.944Z" />
      ),
      light: (
        <path d="M353.688,311.277L351.854,309.944L352.643,307.515L354.387,307.515C354.379,306.795 354.324,306.285 354.324,306.285L352.631,307.515L350.564,306.014L349.775,303.585L346.432,306.014L348.498,307.515L345.948,307.515L347.225,311.445L349.286,309.948L349.287,309.944L351.841,309.944L352.557,312.147C353.054,312.044 353.42,311.722 353.688,311.277Z" />
      ),
      lighter: (
        <path d="M349.911,304.002L351.108,304.342L350.564,306.014L352.631,307.515L351.86,309.944L349.291,309.944L348.502,307.515L346.747,307.515L346.739,306.237L348.498,307.515L350.564,306.014L349.911,304.002Z" />
      ),
    },
  },
};

const shapeEar23 = {
  left: {
    base: <circle cx="157.263" cy="278.514" r="3.562" />,
    basic: {
      dark: (
        <path d="M155.985,280.483L155.601,281.664C155.371,281.542 155.155,281.396 154.958,281.228L155.985,280.483ZM158.559,280.479L159.342,278.05L160.795,278.05C160.815,278.202 160.825,278.357 160.825,278.514C160.825,279.597 160.34,280.568 159.575,281.222L158.553,280.479L158.553,280.479L158.559,280.479Z" />
      ),
      light: (
        <path d="M160.387,281.812L158.553,280.479L159.342,278.05L161.086,278.05C161.078,277.329 161.023,276.819 161.023,276.819L159.329,278.05L157.263,276.549L156.474,274.12L153.131,276.549L155.197,278.05L152.647,278.05L153.924,281.98L155.985,280.483L155.986,280.479L158.54,280.479L159.256,282.681C159.753,282.579 160.119,282.256 160.387,281.812Z" />
      ),
      lighter: (
        <path d="M156.609,274.537L157.806,274.877L157.263,276.549L159.329,278.05L158.559,280.479L155.99,280.479L155.201,278.05L153.446,278.05L153.438,276.772L155.197,278.05L157.263,276.549L156.609,274.537Z" />
      ),
    },
  },
  right: {
    base: <circle cx="342.737" cy="278.514" r="3.562" />,
    basic: {
      dark: (
        <path d="M341.459,280.483L341.075,281.664C340.844,281.542 340.629,281.396 340.432,281.228L341.459,280.483ZM344.033,280.479L344.816,278.05L346.269,278.05C346.289,278.202 346.299,278.357 346.299,278.514C346.299,279.597 345.814,280.568 345.049,281.222L344.026,280.479L344.027,280.479L344.033,280.479Z" />
      ),
      light: (
        <path d="M345.861,281.812L344.026,280.479L344.816,278.05L346.559,278.05C346.552,277.329 346.497,276.819 346.497,276.819L344.803,278.05L342.737,276.549L341.948,274.12L338.604,276.549L340.671,278.05L338.121,278.05L339.398,281.98L341.459,280.483L341.46,280.479L344.014,280.479L344.73,282.681C345.227,282.579 345.593,282.256 345.861,281.812Z" />
      ),
      lighter: (
        <path d="M342.083,274.537L343.28,274.877L342.737,276.549L344.803,278.05L344.033,280.479L341.464,280.479L340.675,278.05L338.92,278.05L338.911,276.772L340.671,278.05L342.737,276.549L342.083,274.537Z" />
      ),
    },
  },
};

const shapeEar24 = {
  left: {
    base: <circle cx="164.021" cy="279.325" r="3.562" />,
    basic: {
      dark: (
        <path d="M162.743,281.294L162.359,282.476C162.128,282.354 161.913,282.208 161.716,282.04L162.743,281.294ZM165.316,281.291L166.1,278.862L167.553,278.862C167.572,279.014 167.583,279.168 167.583,279.325C167.583,280.409 167.098,281.38 166.333,282.034L165.31,281.291L165.31,281.291L165.316,281.291Z" />
      ),
      light: (
        <path d="M167.145,282.624L165.31,281.291L166.1,278.862L167.843,278.862C167.836,278.141 167.781,277.631 167.781,277.631L166.087,278.862L164.021,277.36L163.232,274.931L159.888,277.36L161.955,278.862L159.404,278.862L160.681,282.792L162.743,281.294L162.744,281.291L165.298,281.291L166.013,283.493C166.511,283.391 166.877,283.068 167.145,282.624Z" />
      ),
      lighter: (
        <path d="M163.367,275.349L164.564,275.689L164.021,277.36L166.087,278.862L165.316,281.291L162.748,281.291L161.958,278.862L160.204,278.862L160.195,277.584L161.955,278.862L164.021,277.36L163.367,275.349Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="279.325" r="3.562" />,
    basic: {
      dark: (
        <path d="M334.701,281.294L334.317,282.476C334.087,282.354 333.871,282.208 333.674,282.04L334.701,281.294ZM337.275,281.291L338.058,278.862L339.511,278.862C339.531,279.014 339.541,279.168 339.541,279.325C339.541,280.409 339.056,281.38 338.292,282.034L337.269,281.291L337.269,281.291L337.275,281.291Z" />
      ),
      light: (
        <path d="M339.103,282.624L337.269,281.291L338.058,278.862L339.802,278.862C339.794,278.141 339.739,277.631 339.739,277.631L338.045,278.862L335.979,277.36L335.19,274.931L331.847,277.36L333.913,278.862L331.363,278.862L332.64,282.792L334.701,281.294L334.702,281.291L337.256,281.291L337.972,283.493C338.469,283.391 338.835,283.068 339.103,282.624Z" />
      ),
      lighter: (
        <path d="M335.326,275.349L336.522,275.689L335.979,277.36L338.045,278.862L337.275,281.291L334.706,281.291L333.917,278.862L332.162,278.862L332.154,277.584L333.913,278.862L335.979,277.36L335.326,275.349Z" />
      ),
    },
  },
};

const shapeEar25 = {
  left: {
    base: <circle cx="167.433" cy="198.58" r="3.562" />,
    basic: {
      dark: (
        <path d="M166.155,200.549L165.771,201.731C165.541,201.609 165.325,201.462 165.128,201.295L166.155,200.549ZM168.729,200.545L169.512,198.116L170.965,198.116C170.985,198.268 170.995,198.423 170.995,198.58C170.995,199.664 170.51,200.635 169.746,201.288L168.723,200.545L168.723,200.545L168.729,200.545Z" />
      ),
      light: (
        <path d="M170.557,201.878L168.723,200.545L169.512,198.116L171.256,198.116C171.248,197.396 171.193,196.886 171.193,196.886L169.499,198.116L167.433,196.615L166.644,194.186L163.301,196.615L165.367,198.116L162.817,198.116L164.094,202.047L166.155,200.549L166.156,200.545L168.71,200.545L169.426,202.748C169.923,202.645 170.289,202.323 170.557,201.878Z" />
      ),
      lighter: (
        <path d="M166.779,194.603L167.976,194.943L167.433,196.615L169.499,198.116L168.729,200.545L166.16,200.545L165.371,198.116L163.616,198.116L163.608,196.838L165.367,198.116L167.433,196.615L166.779,194.603Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.567" cy="198.58" r="3.562" />,
    basic: {
      dark: (
        <path d="M331.289,200.549L330.905,201.731C330.674,201.609 330.459,201.462 330.262,201.295L331.289,200.549ZM333.862,200.545L334.646,198.116L336.099,198.116C336.119,198.268 336.129,198.423 336.129,198.58C336.129,199.664 335.644,200.635 334.879,201.288L333.856,200.545L333.856,200.545L333.862,200.545Z" />
      ),
      light: (
        <path d="M335.691,201.878L333.856,200.545L334.646,198.116L336.389,198.116C336.382,197.396 336.327,196.886 336.327,196.886L334.633,198.116L332.567,196.615L331.778,194.186L328.434,196.615L330.501,198.116L327.95,198.116L329.227,202.047L331.289,200.549L331.29,200.545L333.844,200.545L334.56,202.748C335.057,202.645 335.423,202.323 335.691,201.878Z" />
      ),
      lighter: (
        <path d="M331.913,194.603L333.11,194.943L332.567,196.615L334.633,198.116L333.862,200.545L331.294,200.545L330.504,198.116L328.75,198.116L328.741,196.838L330.501,198.116L332.567,196.615L331.913,194.603Z" />
      ),
    },
  },
};

const shapeEar26 = {
  left: {
    base: <circle cx="160.908" cy="290.748" r="3.562" />,
    basic: {
      dark: (
        <path d="M159.63,292.717L159.246,293.899C159.015,293.777 158.8,293.63 158.603,293.463L159.63,292.717ZM162.203,292.713L162.987,290.284L164.44,290.284C164.46,290.436 164.47,290.591 164.47,290.748C164.47,291.832 163.985,292.803 163.22,293.456L162.197,292.713L162.197,292.713L162.203,292.713Z" />
      ),
      light: (
        <path d="M164.032,294.046L162.197,292.713L162.987,290.284L164.73,290.284C164.723,289.564 164.668,289.054 164.668,289.054L162.974,290.284L160.908,288.783L160.119,286.354L156.775,288.783L158.842,290.284L156.291,290.284L157.568,294.214L159.63,292.717L159.631,292.713L162.185,292.713L162.9,294.916C163.398,294.813 163.764,294.491 164.032,294.046Z" />
      ),
      lighter: (
        <path d="M160.254,286.771L161.451,287.111L160.908,288.783L162.974,290.284L162.203,292.713L159.635,292.713L158.845,290.284L157.091,290.284L157.082,289.006L158.842,290.284L160.908,288.783L160.254,286.771Z" />
      ),
    },
  },
  right: {
    base: <circle cx="339.096" cy="290.748" r="3.562" />,
    basic: {
      dark: (
        <path d="M337.817,292.717L337.433,293.899C337.203,293.777 336.988,293.63 336.791,293.463L337.817,292.717ZM340.391,292.713L341.174,290.284L342.627,290.284C342.647,290.436 342.657,290.591 342.657,290.748C342.657,291.832 342.173,292.803 341.408,293.456L340.385,292.713L340.385,292.713L340.391,292.713Z" />
      ),
      light: (
        <path d="M342.22,294.046L340.385,292.713L341.174,290.284L342.918,290.284C342.911,289.564 342.856,289.054 342.856,289.054L341.162,290.284L339.096,288.783L338.306,286.354L334.963,288.783L337.029,290.284L334.479,290.284L335.756,294.214L337.817,292.717L337.819,292.713L340.373,292.713L341.088,294.916C341.586,294.813 341.952,294.491 342.22,294.046Z" />
      ),
      lighter: (
        <path d="M338.442,286.771L339.639,287.111L339.096,288.783L341.162,290.284L340.391,292.713L337.822,292.713L337.033,290.284L335.278,290.284L335.27,289.006L337.029,290.284L339.096,288.783L338.442,286.771Z" />
      ),
    },
  },
};

const shapeEar27 = {
  left: {
    base: <circle cx="150.057" cy="285.24" r="3.562" />,
    basic: {
      dark: (
        <path d="M148.779,287.209L148.395,288.39C148.164,288.268 147.949,288.122 147.752,287.955L148.779,287.209ZM151.352,287.205L152.136,284.776L153.589,284.776C153.609,284.928 153.619,285.083 153.619,285.24C153.619,286.323 153.134,287.295 152.369,287.948L151.346,287.205L151.346,287.205L151.352,287.205Z" />
      ),
      light: (
        <path d="M153.181,288.538L151.346,287.205L152.136,284.776L153.879,284.776C153.872,284.056 153.817,283.545 153.817,283.545L152.123,284.776L150.057,283.275L149.268,280.846L145.924,283.275L147.991,284.776L145.44,284.776L146.717,288.706L148.779,287.209L148.78,287.205L151.334,287.205L152.05,289.407C152.547,289.305 152.913,288.983 153.181,288.538Z" />
      ),
      lighter: (
        <path d="M149.403,281.263L150.6,281.603L150.057,283.275L152.123,284.776L151.352,287.205L148.784,287.205L147.994,284.776L146.24,284.776L146.231,283.498L147.991,284.776L150.057,283.275L149.403,281.263Z" />
      ),
    },
  },
  right: {
    base: <circle cx="349.943" cy="285.24" r="3.562" />,
    basic: {
      dark: (
        <path d="M348.665,287.209L348.281,288.39C348.051,288.268 347.835,288.122 347.638,287.955L348.665,287.209ZM351.239,287.205L352.022,284.776L353.475,284.776C353.495,284.928 353.505,285.083 353.505,285.24C353.505,286.323 353.02,287.295 352.256,287.948L351.233,287.205L351.233,287.205L351.239,287.205Z" />
      ),
      light: (
        <path d="M353.067,288.538L351.233,287.205L352.022,284.776L353.766,284.776C353.758,284.056 353.703,283.545 353.703,283.545L352.009,284.776L349.943,283.275L349.154,280.846L345.811,283.275L347.877,284.776L345.327,284.776L346.604,288.706L348.665,287.209L348.666,287.205L351.22,287.205L351.936,289.407C352.433,289.305 352.799,288.983 353.067,288.538Z" />
      ),
      lighter: (
        <path d="M349.289,281.263L350.486,281.603L349.943,283.275L352.009,284.776L351.239,287.205L348.67,287.205L347.881,284.776L346.126,284.776L346.118,283.498L347.877,284.776L349.943,283.275L349.289,281.263Z" />
      ),
    },
  },
};

const shapeEar28 = {
  left: {
    base: <circle cx="167.433" cy="194.79" r="3.562" />,
    basic: {
      dark: (
        <path d="M166.155,196.759L165.771,197.941C165.541,197.819 165.325,197.672 165.128,197.505L166.155,196.759ZM168.729,196.755L169.512,194.326L170.965,194.326C170.985,194.478 170.995,194.633 170.995,194.79C170.995,195.874 170.51,196.845 169.746,197.498L168.723,196.755L168.723,196.755L168.729,196.755Z" />
      ),
      light: (
        <path d="M170.557,198.088L168.723,196.755L169.512,194.326L171.256,194.326C171.248,193.606 171.193,193.096 171.193,193.096L169.499,194.326L167.433,192.825L166.644,190.396L163.301,192.825L165.367,194.326L162.817,194.326L164.094,198.256L166.155,196.759L166.156,196.755L168.71,196.755L169.426,198.958C169.923,198.855 170.289,198.533 170.557,198.088Z" />
      ),
      lighter: (
        <path d="M166.779,190.813L167.976,191.153L167.433,192.825L169.499,194.326L168.729,196.755L166.16,196.755L165.371,194.326L163.616,194.326L163.608,193.048L165.367,194.326L167.433,192.825L166.779,190.813Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.567" cy="194.79" r="3.562" />,
    basic: {
      dark: (
        <path d="M331.289,196.759L330.905,197.941C330.674,197.819 330.459,197.672 330.262,197.505L331.289,196.759ZM333.862,196.755L334.646,194.326L336.099,194.326C336.119,194.478 336.129,194.633 336.129,194.79C336.129,195.874 335.644,196.845 334.879,197.498L333.856,196.755L333.856,196.755L333.862,196.755Z" />
      ),
      light: (
        <path d="M335.691,198.088L333.856,196.755L334.646,194.326L336.389,194.326C336.382,193.606 336.327,193.096 336.327,193.096L334.633,194.326L332.567,192.825L331.778,190.396L328.434,192.825L330.501,194.326L327.95,194.326L329.227,198.256L331.289,196.759L331.29,196.755L333.844,196.755L334.56,198.958C335.057,198.855 335.423,198.533 335.691,198.088Z" />
      ),
      lighter: (
        <path d="M331.913,190.813L333.11,191.153L332.567,192.825L334.633,194.326L333.862,196.755L331.294,196.755L330.504,194.326L328.75,194.326L328.741,193.048L330.501,194.326L332.567,192.825L331.913,190.813Z" />
      ),
    },
  },
};

const shapeEar29 = {
  left: {
    base: <circle cx="151.528" cy="239.752" r="3.562" />,
    basic: {
      dark: (
        <path d="M150.25,241.721L149.866,242.902C149.636,242.78 149.42,242.634 149.223,242.466L150.25,241.721ZM152.824,241.717L153.607,239.288L155.06,239.288C155.08,239.44 155.09,239.594 155.09,239.752C155.09,240.835 154.605,241.806 153.841,242.46L152.818,241.717L152.818,241.717L152.824,241.717Z" />
      ),
      light: (
        <path d="M154.652,243.05L152.818,241.717L153.607,239.288L155.351,239.288C155.343,238.567 155.288,238.057 155.288,238.057L153.594,239.288L151.528,237.787L150.739,235.358L147.396,237.787L149.462,239.288L146.912,239.288L148.189,243.218L150.25,241.721L150.251,241.717L152.805,241.717L153.521,243.919C154.018,243.817 154.384,243.494 154.652,243.05Z" />
      ),
      lighter: (
        <path d="M150.874,235.775L152.071,236.115L151.528,237.787L153.594,239.288L152.824,241.717L150.255,241.717L149.466,239.288L147.711,239.288L147.703,238.01L149.462,239.288L151.528,237.787L150.874,235.775Z" />
      ),
    },
  },
  right: {
    base: <circle cx="348.472" cy="239.752" r="3.562" />,
    basic: {
      dark: (
        <path d="M347.194,241.721L346.81,242.902C346.579,242.78 346.364,242.634 346.167,242.466L347.194,241.721ZM349.768,241.717L350.551,239.288L352.004,239.288C352.024,239.44 352.034,239.594 352.034,239.752C352.034,240.835 351.549,241.806 350.784,242.46L349.761,241.717L349.761,241.717L349.768,241.717Z" />
      ),
      light: (
        <path d="M351.596,243.05L349.761,241.717L350.551,239.288L352.294,239.288C352.287,238.567 352.232,238.057 352.232,238.057L350.538,239.288L348.472,237.787L347.683,235.358L344.339,237.787L346.406,239.288L343.855,239.288L345.132,243.218L347.194,241.721L347.195,241.717L349.749,241.717L350.465,243.919C350.962,243.817 351.328,243.494 351.596,243.05Z" />
      ),
      lighter: (
        <path d="M347.818,235.775L349.015,236.115L348.472,237.787L350.538,239.288L349.768,241.717L347.199,241.717L346.41,239.288L344.655,239.288L344.646,238.01L346.406,239.288L348.472,237.787L347.818,235.775Z" />
      ),
    },
  },
};

const shapeEar30 = {
  left: {
    base: <circle cx="173.722" cy="188.823" r="3.562" />,
    basic: {
      dark: (
        <path d="M172.444,190.792L172.06,191.973C171.829,191.852 171.614,191.705 171.417,191.538L172.444,190.792ZM175.018,190.788L175.801,188.359L177.254,188.359C177.274,188.511 177.284,188.666 177.284,188.823C177.284,189.907 176.799,190.878 176.034,191.531L175.012,190.788L175.012,190.788L175.018,190.788Z" />
      ),
      light: (
        <path d="M176.846,192.121L175.012,190.788L175.801,188.359L177.544,188.359C177.537,187.639 177.482,187.129 177.482,187.129L175.788,188.359L173.722,186.858L172.933,184.429L169.589,186.858L171.656,188.359L169.106,188.359L170.383,192.289L172.444,190.792L172.445,190.788L174.999,190.788L175.715,192.991C176.212,192.888 176.578,192.566 176.846,192.121Z" />
      ),
      lighter: (
        <path d="M173.068,184.846L174.265,185.186L173.722,186.858L175.788,188.359L175.018,190.788L172.449,190.788L171.66,188.359L169.905,188.359L169.897,187.081L171.656,188.359L173.722,186.858L173.068,184.846Z" />
      ),
    },
  },
  right: {
    base: <circle cx="326.278" cy="188.823" r="3.562" />,
    basic: {
      dark: (
        <path d="M325,190.792L324.616,191.973C324.386,191.852 324.17,191.705 323.973,191.538L325,190.792ZM327.574,190.788L328.357,188.359L329.81,188.359C329.83,188.511 329.84,188.666 329.84,188.823C329.84,189.907 329.355,190.878 328.59,191.531L327.568,190.788L327.568,190.788L327.574,190.788Z" />
      ),
      light: (
        <path d="M329.402,192.121L327.568,190.788L328.357,188.359L330.101,188.359C330.093,187.639 330.038,187.129 330.038,187.129L328.344,188.359L326.278,186.858L325.489,184.429L322.146,186.858L324.212,188.359L321.662,188.359L322.939,192.289L325,190.792L325.001,190.788L327.555,190.788L328.271,192.991C328.768,192.888 329.134,192.566 329.402,192.121Z" />
      ),
      lighter: (
        <path d="M325.624,184.846L326.821,185.186L326.278,186.858L328.344,188.359L327.574,190.788L325.005,190.788L324.216,188.359L322.461,188.359L322.453,187.081L324.212,188.359L326.278,186.858L325.624,184.846Z" />
      ),
    },
  },
};

const shapeEar31 = {
  left: {
    base: <circle cx="160.845" cy="286.007" r="3.562" />,
    basic: {
      dark: (
        <path d="M159.567,287.976L159.183,289.158C158.952,289.036 158.737,288.889 158.54,288.722L159.567,287.976ZM162.141,287.972L162.924,285.543L164.377,285.543C164.397,285.695 164.407,285.85 164.407,286.007C164.407,287.091 163.922,288.062 163.157,288.715L162.135,287.972L162.135,287.972L162.141,287.972Z" />
      ),
      light: (
        <path d="M163.969,289.305L162.135,287.972L162.924,285.543L164.667,285.543C164.66,284.823 164.605,284.313 164.605,284.313L162.911,285.543L160.845,284.042L160.056,281.613L156.712,284.042L158.779,285.543L156.229,285.543L157.506,289.474L159.567,287.976L159.568,287.972L162.122,287.972L162.838,290.175C163.335,290.072 163.701,289.75 163.969,289.305Z" />
      ),
      lighter: (
        <path d="M160.191,282.03L161.388,282.37L160.845,284.042L162.911,285.543L162.141,287.972L159.572,287.972L158.783,285.543L157.028,285.543L157.019,284.265L158.779,285.543L160.845,284.042L160.191,282.03Z" />
      ),
    },
  },
  right: {
    base: <circle cx="339.155" cy="286.007" r="3.562" />,
    basic: {
      dark: (
        <path d="M337.877,287.976L337.493,289.158C337.263,289.036 337.047,288.889 336.85,288.722L337.877,287.976ZM340.451,287.972L341.234,285.543L342.687,285.543C342.707,285.695 342.717,285.85 342.717,286.007C342.717,287.091 342.232,288.062 341.467,288.715L340.445,287.972L340.445,287.972L340.451,287.972Z" />
      ),
      light: (
        <path d="M342.279,289.305L340.445,287.972L341.234,285.543L342.978,285.543C342.97,284.823 342.915,284.313 342.915,284.313L341.221,285.543L339.155,284.042L338.366,281.613L335.023,284.042L337.089,285.543L334.539,285.543L335.816,289.474L337.877,287.976L337.878,287.972L340.432,287.972L341.148,290.175C341.645,290.072 342.011,289.75 342.279,289.305Z" />
      ),
      lighter: (
        <path d="M338.501,282.03L339.698,282.37L339.155,284.042L341.221,285.543L340.451,287.972L337.882,287.972L337.093,285.543L335.338,285.543L335.33,284.265L337.089,285.543L339.155,284.042L338.501,282.03Z" />
      ),
    },
  },
};

const shapeEar32 = {
  left: {
    base: <circle cx="149.512" cy="275.16" r="3.562" />,
    basic: {
      dark: (
        <path d="M148.234,277.129L147.85,278.311C147.619,278.189 147.404,278.042 147.207,277.875L148.234,277.129ZM150.807,277.125L151.591,274.696L153.044,274.696C153.063,274.848 153.074,275.003 153.074,275.16C153.074,276.244 152.589,277.215 151.824,277.869L150.801,277.125L150.801,277.125L150.807,277.125Z" />
      ),
      light: (
        <path d="M152.636,278.458L150.801,277.125L151.591,274.696L153.334,274.696C153.327,273.976 153.272,273.466 153.272,273.466L151.578,274.696L149.512,273.195L148.723,270.766L145.379,273.195L147.446,274.696L144.895,274.696L146.172,278.627L148.234,277.129L148.235,277.125L150.789,277.125L151.504,279.328C152.002,279.225 152.368,278.903 152.636,278.458Z" />
      ),
      lighter: (
        <path d="M148.858,271.183L150.055,271.523L149.512,273.195L151.578,274.696L150.807,277.125L148.239,277.125L147.449,274.696L145.695,274.696L145.686,273.418L147.446,274.696L149.512,273.195L148.858,271.183Z" />
      ),
    },
  },
  right: {
    base: <circle cx="350.488" cy="275.16" r="3.562" />,
    basic: {
      dark: (
        <path d="M349.21,277.129L348.826,278.311C348.596,278.189 348.38,278.042 348.183,277.875L349.21,277.129ZM351.784,277.125L352.567,274.696L354.02,274.696C354.04,274.848 354.05,275.003 354.05,275.16C354.05,276.244 353.565,277.215 352.801,277.869L351.778,277.125L351.778,277.125L351.784,277.125Z" />
      ),
      light: (
        <path d="M353.612,278.458L351.778,277.125L352.567,274.696L354.311,274.696C354.303,273.976 354.248,273.466 354.248,273.466L352.554,274.696L350.488,273.195L349.699,270.766L346.356,273.195L348.422,274.696L345.872,274.696L347.149,278.627L349.21,277.129L349.211,277.125L351.765,277.125L352.481,279.328C352.978,279.225 353.344,278.903 353.612,278.458Z" />
      ),
      lighter: (
        <path d="M349.835,271.183L351.031,271.523L350.488,273.195L352.554,274.696L351.784,277.125L349.215,277.125L348.426,274.696L346.671,274.696L346.663,273.418L348.422,274.696L350.488,273.195L349.835,271.183Z" />
      ),
    },
  },
};

const shapeEar33 = {
  left: {
    base: <circle cx="158.027" cy="270.854" r="3.562" />,
    basic: {
      dark: (
        <path d="M156.749,272.823L156.365,274.005C156.135,273.883 155.919,273.736 155.722,273.569L156.749,272.823ZM159.323,272.819L160.106,270.39L161.559,270.39C161.579,270.542 161.589,270.697 161.589,270.854C161.589,271.938 161.104,272.909 160.34,273.562L159.317,272.819L159.317,272.819L159.323,272.819Z" />
      ),
      light: (
        <path d="M161.151,274.152L159.317,272.819L160.106,270.39L161.85,270.39C161.842,269.67 161.787,269.16 161.787,269.16L160.093,270.39L158.027,268.889L157.238,266.46L153.895,268.889L155.961,270.39L153.411,270.39L154.688,274.321L156.749,272.823L156.75,272.819L159.304,272.819L160.02,275.022C160.517,274.919 160.883,274.597 161.151,274.152Z" />
      ),
      lighter: (
        <path d="M157.373,266.877L158.57,267.217L158.027,268.889L160.093,270.39L159.323,272.819L156.754,272.819L155.965,270.39L154.21,270.39L154.202,269.112L155.961,270.39L158.027,268.889L157.373,266.877Z" />
      ),
    },
  },
  right: {
    base: <circle cx="341.973" cy="270.854" r="3.562" />,
    basic: {
      dark: (
        <path d="M340.695,272.823L340.311,274.005C340.08,273.883 339.865,273.736 339.668,273.569L340.695,272.823ZM343.268,272.819L344.052,270.39L345.505,270.39C345.525,270.542 345.535,270.697 345.535,270.854C345.535,271.938 345.05,272.909 344.285,273.562L343.262,272.819L343.262,272.819L343.268,272.819Z" />
      ),
      light: (
        <path d="M345.097,274.152L343.262,272.819L344.052,270.39L345.795,270.39C345.788,269.67 345.733,269.16 345.733,269.16L344.039,270.39L341.973,268.889L341.184,266.46L337.84,268.889L339.907,270.39L337.356,270.39L338.633,274.321L340.695,272.823L340.696,272.819L343.25,272.819L343.966,275.022C344.463,274.919 344.829,274.597 345.097,274.152Z" />
      ),
      lighter: (
        <path d="M341.319,266.877L342.516,267.217L341.973,268.889L344.039,270.39L343.268,272.819L340.7,272.819L339.91,270.39L338.156,270.39L338.147,269.112L339.907,270.39L341.973,268.889L341.319,266.877Z" />
      ),
    },
  },
};

const shapeEar34 = {
  left: {
    base: <circle cx="160.845" cy="269.491" r="3.562" />,
    basic: {
      dark: (
        <path d="M159.567,271.46L159.183,272.642C158.952,272.52 158.737,272.373 158.54,272.206L159.567,271.46ZM162.141,271.456L162.924,269.027L164.377,269.027C164.397,269.179 164.407,269.334 164.407,269.491C164.407,270.575 163.922,271.546 163.157,272.2L162.135,271.456L162.135,271.456L162.141,271.456Z" />
      ),
      light: (
        <path d="M163.969,272.789L162.135,271.456L162.924,269.027L164.667,269.027C164.66,268.307 164.605,267.797 164.605,267.797L162.911,269.027L160.845,267.526L160.056,265.097L156.712,267.526L158.779,269.027L156.229,269.027L157.506,272.958L159.567,271.46L159.568,271.456L162.122,271.456L162.838,273.659C163.335,273.556 163.701,273.234 163.969,272.789Z" />
      ),
      lighter: (
        <path d="M160.191,265.514L161.388,265.854L160.845,267.526L162.911,269.027L162.141,271.456L159.572,271.456L158.783,269.027L157.028,269.027L157.019,267.749L158.779,269.027L160.845,267.526L160.191,265.514Z" />
      ),
    },
  },
  right: {
    base: <circle cx="339.155" cy="269.491" r="3.562" />,
    basic: {
      dark: (
        <path d="M337.877,271.46L337.493,272.642C337.263,272.52 337.047,272.373 336.85,272.206L337.877,271.46ZM340.451,271.456L341.234,269.027L342.687,269.027C342.707,269.179 342.717,269.334 342.717,269.491C342.717,270.575 342.232,271.546 341.467,272.2L340.445,271.456L340.445,271.456L340.451,271.456Z" />
      ),
      light: (
        <path d="M342.279,272.789L340.445,271.456L341.234,269.027L342.978,269.027C342.97,268.307 342.915,267.797 342.915,267.797L341.221,269.027L339.155,267.526L338.366,265.097L335.023,267.526L337.089,269.027L334.539,269.027L335.816,272.958L337.877,271.46L337.878,271.456L340.432,271.456L341.148,273.659C341.645,273.556 342.011,273.234 342.279,272.789Z" />
      ),
      lighter: (
        <path d="M338.501,265.514L339.698,265.854L339.155,267.526L341.221,269.027L340.451,271.456L337.882,271.456L337.093,269.027L335.338,269.027L335.33,267.749L337.089,269.027L339.155,267.526L338.501,265.514Z" />
      ),
    },
  },
};

const shapeEar35 = {
  left: {
    base: <circle cx="164.021" cy="283.77" r="3.562" />,
    basic: {
      dark: (
        <path d="M162.743,285.739L162.359,286.921C162.128,286.799 161.913,286.653 161.716,286.485L162.743,285.739ZM165.316,285.735L166.1,283.307L167.553,283.307C167.572,283.459 167.583,283.613 167.583,283.77C167.583,284.854 167.098,285.825 166.333,286.479L165.31,285.736L165.31,285.735L165.316,285.735Z" />
      ),
      light: (
        <path d="M167.145,287.068L165.31,285.736L166.1,283.307L167.843,283.307C167.836,282.586 167.781,282.076 167.781,282.076L166.087,283.307L164.021,281.805L163.232,279.376L159.888,281.805L161.955,283.307L159.404,283.307L160.681,287.237L162.743,285.739L162.744,285.735L165.298,285.735L166.013,287.938C166.511,287.835 166.877,287.513 167.145,287.068Z" />
      ),
      lighter: (
        <path d="M163.367,279.793L164.564,280.133L164.021,281.805L166.087,283.307L165.316,285.735L162.748,285.735L161.958,283.307L160.204,283.307L160.195,282.028L161.955,283.307L164.021,281.805L163.367,279.793Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="283.77" r="3.562" />,
    basic: {
      dark: (
        <path d="M334.701,285.739L334.317,286.921C334.087,286.799 333.871,286.653 333.674,286.485L334.701,285.739ZM337.275,285.735L338.058,283.307L339.511,283.307C339.531,283.459 339.541,283.613 339.541,283.77C339.541,284.854 339.056,285.825 338.292,286.479L337.269,285.736L337.269,285.735L337.275,285.735Z" />
      ),
      light: (
        <path d="M339.103,287.068L337.269,285.736L338.058,283.307L339.802,283.307C339.794,282.586 339.739,282.076 339.739,282.076L338.045,283.307L335.979,281.805L335.19,279.376L331.847,281.805L333.913,283.307L331.363,283.307L332.64,287.237L334.701,285.739L334.702,285.735L337.256,285.735L337.972,287.938C338.469,287.835 338.835,287.513 339.103,287.068Z" />
      ),
      lighter: (
        <path d="M335.326,279.793L336.522,280.133L335.979,281.805L338.045,283.307L337.275,285.735L334.706,285.735L333.917,283.307L332.162,283.307L332.154,282.028L333.913,283.307L335.979,281.805L335.326,279.793Z" />
      ),
    },
  },
};

const shapeEar36 = {
  left: {
    base: <circle cx="167.196" cy="289.941" r="3.562" />,
    basic: {
      dark: (
        <path d="M165.918,291.91L165.534,293.091C165.303,292.97 165.088,292.823 164.891,292.656L165.918,291.91ZM168.491,291.906L169.275,289.477L170.728,289.477C170.748,289.629 170.758,289.784 170.758,289.941C170.758,291.025 170.273,291.996 169.508,292.649L168.485,291.906L168.485,291.906L168.491,291.906Z" />
      ),
      light: (
        <path d="M170.32,293.239L168.485,291.906L169.275,289.477L171.018,289.477C171.011,288.757 170.956,288.247 170.956,288.247L169.262,289.477L167.196,287.976L166.407,285.547L163.063,287.976L165.13,289.477L162.579,289.477L163.856,293.407L165.918,291.91L165.919,291.906L168.473,291.906L169.189,294.109C169.686,294.006 170.052,293.684 170.32,293.239Z" />
      ),
      lighter: (
        <path d="M166.542,285.964L167.739,286.304L167.196,287.976L169.262,289.477L168.491,291.906L165.923,291.906L165.133,289.477L163.379,289.477L163.37,288.199L165.13,289.477L167.196,287.976L166.542,285.964Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.804" cy="289.941" r="3.562" />,
    basic: {
      dark: (
        <path d="M331.526,291.91L331.142,293.091C330.912,292.97 330.696,292.823 330.499,292.656L331.526,291.91ZM334.1,291.906L334.883,289.477L336.336,289.477C336.356,289.629 336.366,289.784 336.366,289.941C336.366,291.025 335.881,291.996 335.117,292.649L334.094,291.906L334.094,291.906L334.1,291.906Z" />
      ),
      light: (
        <path d="M335.928,293.239L334.094,291.906L334.883,289.477L336.627,289.477C336.619,288.757 336.564,288.247 336.564,288.247L334.87,289.477L332.804,287.976L332.015,285.547L328.672,287.976L330.738,289.477L328.188,289.477L329.465,293.407L331.526,291.91L331.527,291.906L334.081,291.906L334.797,294.109C335.294,294.006 335.66,293.684 335.928,293.239Z" />
      ),
      lighter: (
        <path d="M332.15,285.964L333.347,286.304L332.804,287.976L334.87,289.477L334.1,291.906L331.531,291.906L330.742,289.477L328.987,289.477L328.979,288.199L330.738,289.477L332.804,287.976L332.15,285.964Z" />
      ),
    },
  },
};

const shapeEar37 = {
  left: {
    base: <circle cx="162.243" cy="289.032" r="3.562" />,
    basic: {
      dark: (
        <path d="M160.965,291.001L160.581,292.182C160.35,292.061 160.135,291.914 159.938,291.747L160.965,291.001ZM163.539,290.997L164.322,288.568L165.775,288.568C165.795,288.72 165.805,288.875 165.805,289.032C165.805,290.115 165.32,291.087 164.555,291.74L163.533,290.997L163.533,290.997L163.539,290.997Z" />
      ),
      light: (
        <path d="M165.367,292.33L163.533,290.997L164.322,288.568L166.066,288.568C166.058,287.848 166.003,287.338 166.003,287.338L164.309,288.568L162.243,287.067L161.454,284.638L158.11,287.067L160.177,288.568L157.627,288.568L158.904,292.498L160.965,291.001L160.966,290.997L163.52,290.997L164.236,293.199C164.733,293.097 165.099,292.775 165.367,292.33Z" />
      ),
      lighter: (
        <path d="M161.589,285.055L162.786,285.395L162.243,287.067L164.309,288.568L163.539,290.997L160.97,290.997L160.181,288.568L158.426,288.568L158.418,287.29L160.177,288.568L162.243,287.067L161.589,285.055Z" />
      ),
    },
  },
  right: {
    base: <circle cx="337.757" cy="289.032" r="3.562" />,
    basic: {
      dark: (
        <path d="M336.479,291.001L336.095,292.182C335.864,292.061 335.649,291.914 335.452,291.747L336.479,291.001ZM339.053,290.997L339.836,288.568L341.289,288.568C341.309,288.72 341.319,288.875 341.319,289.032C341.319,290.115 340.834,291.087 340.069,291.74L339.047,290.997L339.047,290.997L339.053,290.997Z" />
      ),
      light: (
        <path d="M340.881,292.33L339.047,290.997L339.836,288.568L341.58,288.568C341.572,287.848 341.517,287.338 341.517,287.338L339.823,288.568L337.757,287.067L336.968,284.638L333.624,287.067L335.691,288.568L333.141,288.568L334.418,292.498L336.479,291.001L336.48,290.997L339.034,290.997L339.75,293.199C340.247,293.097 340.613,292.775 340.881,292.33Z" />
      ),
      lighter: (
        <path d="M337.103,285.055L338.3,285.395L337.757,287.067L339.823,288.568L339.053,290.997L336.484,290.997L335.695,288.568L333.94,288.568L333.932,287.29L335.691,288.568L337.757,287.067L337.103,285.055Z" />
      ),
    },
  },
};

const shapeEar38 = {
  left: {
    base: <circle cx="164.021" cy="279.942" r="3.562" />,
    basic: {
      dark: (
        <path d="M162.743,281.911L162.359,283.092C162.128,282.97 161.913,282.824 161.716,282.657L162.743,281.911ZM165.316,281.907L166.1,279.478L167.553,279.478C167.572,279.63 167.583,279.785 167.583,279.942C167.583,281.025 167.098,281.997 166.333,282.65L165.31,281.907L165.31,281.907L165.316,281.907Z" />
      ),
      light: (
        <path d="M167.145,283.24L165.31,281.907L166.1,279.478L167.843,279.478C167.836,278.758 167.781,278.247 167.781,278.247L166.087,279.478L164.021,277.977L163.232,275.548L159.888,277.977L161.955,279.478L159.404,279.478L160.681,283.408L162.743,281.911L162.744,281.907L165.298,281.907L166.013,284.109C166.511,284.007 166.877,283.685 167.145,283.24Z" />
      ),
      lighter: (
        <path d="M163.367,275.965L164.564,276.305L164.021,277.977L166.087,279.478L165.316,281.907L162.748,281.907L161.958,279.478L160.204,279.478L160.195,278.2L161.955,279.478L164.021,277.977L163.367,275.965Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="279.942" r="3.562" />,
    basic: {
      dark: (
        <path d="M334.701,281.911L334.317,283.092C334.087,282.97 333.871,282.824 333.674,282.657L334.701,281.911ZM337.275,281.907L338.058,279.478L339.511,279.478C339.531,279.63 339.541,279.785 339.541,279.942C339.541,281.025 339.056,281.997 338.292,282.65L337.269,281.907L337.269,281.907L337.275,281.907Z" />
      ),
      light: (
        <path d="M339.103,283.24L337.269,281.907L338.058,279.478L339.802,279.478C339.794,278.758 339.739,278.247 339.739,278.247L338.045,279.478L335.979,277.977L335.19,275.548L331.847,277.977L333.913,279.478L331.363,279.478L332.64,283.408L334.701,281.911L334.702,281.907L337.256,281.907L337.972,284.109C338.469,284.007 338.835,283.685 339.103,283.24Z" />
      ),
      lighter: (
        <path d="M335.326,275.965L336.522,276.305L335.979,277.977L338.045,279.478L337.275,281.907L334.706,281.907L333.917,279.478L332.162,279.478L332.154,278.2L333.913,279.478L335.979,277.977L335.326,275.965Z" />
      ),
    },
  },
};

const shapeEar39 = {
  left: {
    base: <circle cx="164.021" cy="278.36" r="3.562" />,
    basic: {
      dark: (
        <path d="M162.743,280.329L162.359,281.511C162.128,281.389 161.913,281.242 161.716,281.075L162.743,280.329ZM165.316,280.325L166.1,277.896L167.553,277.896C167.572,278.048 167.583,278.203 167.583,278.36C167.583,279.444 167.098,280.415 166.333,281.068L165.31,280.325L165.31,280.325L165.316,280.325Z" />
      ),
      light: (
        <path d="M167.145,281.658L165.31,280.325L166.1,277.896L167.843,277.896C167.836,277.176 167.781,276.666 167.781,276.666L166.087,277.896L164.021,276.395L163.232,273.966L159.888,276.395L161.955,277.896L159.404,277.896L160.681,281.827L162.743,280.329L162.744,280.325L165.298,280.325L166.013,282.528C166.511,282.425 166.877,282.103 167.145,281.658Z" />
      ),
      lighter: (
        <path d="M163.367,274.383L164.564,274.723L164.021,276.395L166.087,277.896L165.316,280.325L162.748,280.325L161.958,277.896L160.204,277.896L160.195,276.618L161.955,277.896L164.021,276.395L163.367,274.383Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="278.36" r="3.562" />,
    basic: {
      dark: (
        <path d="M334.701,280.329L334.317,281.511C334.087,281.389 333.871,281.242 333.674,281.075L334.701,280.329ZM337.275,280.325L338.058,277.896L339.511,277.896C339.531,278.048 339.541,278.203 339.541,278.36C339.541,279.444 339.056,280.415 338.292,281.068L337.269,280.325L337.269,280.325L337.275,280.325Z" />
      ),
      light: (
        <path d="M339.103,281.658L337.269,280.325L338.058,277.896L339.802,277.896C339.794,277.176 339.739,276.666 339.739,276.666L338.045,277.896L335.979,276.395L335.19,273.966L331.847,276.395L333.913,277.896L331.363,277.896L332.64,281.827L334.701,280.329L334.702,280.325L337.256,280.325L337.972,282.528C338.469,282.425 338.835,282.103 339.103,281.658Z" />
      ),
      lighter: (
        <path d="M335.326,274.383L336.522,274.723L335.979,276.395L338.045,277.896L337.275,280.325L334.706,280.325L333.917,277.896L332.162,277.896L332.154,276.618L333.913,277.896L335.979,276.395L335.326,274.383Z" />
      ),
    },
  },
};

export const earring2 = {
  id: "earring2",
  name: "Earring 2",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Smooth ears
    ear_shape_TEKWA: { ...shapeEar1 },
    ear_shape_0775v: { ...shapeEar2 },
    ear_shape_HOZ9g: { ...shapeEar3 },
    ear_shape_vBJ7j: { ...shapeEar4 },
    ear_shape_FYH9c: { ...shapeEar5 },
    ear_shape_aoGZG: { ...shapeEar6 },
    ear_shape_ZgR2p: { ...shapeEar7 },
    ear_shape_vdyix: { ...shapeEar8 },
    ear_shape_TbLPe: { ...shapeEar9 },
    ear_shape_3wnpn: { ...shapeEar10 },
    ear_shape_PDULw: { ...shapeEar11 },
    ear_shape_kOBSt: { ...shapeEar12 },
    ear_shape_yAT34: { ...shapeEar13 },
    ear_shape_8wAS4: { ...shapeEar14 },
    ear_shape_gcoC9: { ...shapeEar15 },
    ear_shape_bPDFx: { ...shapeEar16 },
    ear_shape_oEAG6: { ...shapeEar17 },
    ear_shape_Dp6oZ: { ...shapeEar18 },
    ear_shape_kf82L: { ...shapeEar19 },
    ear_shape_8sDmX: { ...shapeEar20 },
    ear_shape_k9vVR: { ...shapeEar21 },
    ear_shape_aKjrI: { ...shapeEar22 },
    ear_shape_2rsVH: { ...shapeEar23 },
    ear_shape_axjXx: { ...shapeEar24 },
    ear_shape_Zxpa2: { ...shapeEar25 },
    ear_shape_jWOL0: { ...shapeEar26 },
    ear_shape_TUUUj: { ...shapeEar27 },
    ear_shape_Wk3es: { ...shapeEar28 },
    ear_shape_XkDvO: { ...shapeEar29 },
    ear_shape_xyzCw: { ...shapeEar30 },
    ear_shape_epGZS: { ...shapeEar31 },
    ear_shape_4qBKl: { ...shapeEar32 },
    ear_shape_zwyyL: { ...shapeEar33 },
    ear_shape_kDDB4: { ...shapeEar34 },
    ear_shape_AwTvI: { ...shapeEar35 },
    ear_shape_0U6iQ: { ...shapeEar36 },
    ear_shape_BTIMM: { ...shapeEar37 },
    ear_shape_cnX1o: { ...shapeEar38 },
    ear_shape_UEVQI: { ...shapeEar39 },
    // Fuzzy ears
    ear_shape_T2K38: { ...shapeEar1 },
    ear_shape_02738: { ...shapeEar2 },
    ear_shape_H2Z38: { ...shapeEar3 },
    ear_shape_v2J38: { ...shapeEar4 },
    ear_shape_F2H38: { ...shapeEar5 },
    ear_shape_a2G38: { ...shapeEar6 },
    ear_shape_Z2R38: { ...shapeEar7 },
    ear_shape_v2y38: { ...shapeEar8 },
    ear_shape_T2L38: { ...shapeEar9 },
    ear_shape_32n38: { ...shapeEar10 },
    ear_shape_P2U38: { ...shapeEar11 },
    ear_shape_k2B38: { ...shapeEar12 },
    ear_shape_y2T38: { ...shapeEar13 },
    ear_shape_82A38: { ...shapeEar14 },
    ear_shape_g2o38: { ...shapeEar15 },
    ear_shape_b2D38: { ...shapeEar16 },
    ear_shape_o2A38: { ...shapeEar17 },
    ear_shape_D2638: { ...shapeEar18 },
    ear_shape_k2838: { ...shapeEar19 },
    ear_shape_82D38: { ...shapeEar20 },
    ear_shape_k2v38: { ...shapeEar21 },
    ear_shape_a2j38: { ...shapeEar22 },
    ear_shape_22s38: { ...shapeEar23 },
    ear_shape_a34f8: { ...shapeEar24 },
    ear_shape_Z2p38: { ...shapeEar25 },
    ear_shape_j2O38: { ...shapeEar26 },
    ear_shape_T2U38: { ...shapeEar27 },
    ear_shape_W2338: { ...shapeEar28 },
    ear_shape_X2D38: { ...shapeEar29 },
    ear_shape_x2z38: { ...shapeEar30 },
    ear_shape_e2G38: { ...shapeEar31 },
    ear_shape_42B38: { ...shapeEar32 },
    ear_shape_z2y38: { ...shapeEar33 },
    ear_shape_k2D38: { ...shapeEar34 },
    ear_shape_A2T38: { ...shapeEar35 },
    ear_shape_02638: { ...shapeEar36 },
    ear_shape_B2I38: { ...shapeEar37 },
    ear_shape_c2X38: { ...shapeEar38 },
    ear_shape_U2V38: { ...shapeEar39 },
    // Bumpy
    ear_shape_T4KrT: { ...shapeEar1 },
    ear_shape_047rT: { ...shapeEar2 },
    ear_shape_H4ZrT: { ...shapeEar3 },
    ear_shape_v4JrT: { ...shapeEar4 },
    ear_shape_F4HrT: { ...shapeEar5 },
    ear_shape_a4GrT: { ...shapeEar6 },
    ear_shape_Z4RrT: { ...shapeEar7 },
    ear_shape_v4yrT: { ...shapeEar8 },
    ear_shape_T4LrT: { ...shapeEar9 },
    ear_shape_34nrT: { ...shapeEar10 },
    ear_shape_P4UrT: { ...shapeEar11 },
    ear_shape_k4BrT: { ...shapeEar12 },
    ear_shape_y4TrT: { ...shapeEar13 },
    ear_shape_84ArT: { ...shapeEar14 },
    ear_shape_g4orT: { ...shapeEar15 },
    ear_shape_b4DrT: { ...shapeEar16 },
    ear_shape_o4ArT: { ...shapeEar17 },
    ear_shape_D46rT: { ...shapeEar18 },
    ear_shape_k48rT: { ...shapeEar19 },
    ear_shape_84DrT: { ...shapeEar20 },
    ear_shape_k4vrT: { ...shapeEar21 },
    ear_shape_a4jrT: { ...shapeEar22 },
    ear_shape_24srT: { ...shapeEar23 },
    ear_shape_a442T: { ...shapeEar24 },
    ear_shape_Z4prT: { ...shapeEar25 },
    ear_shape_j4OrT: { ...shapeEar26 },
    ear_shape_T4UrT: { ...shapeEar27 },
    ear_shape_W43rT: { ...shapeEar28 },
    ear_shape_X4DrT: { ...shapeEar29 },
    ear_shape_x4zrT: { ...shapeEar30 },
    ear_shape_e4GrT: { ...shapeEar31 },
    ear_shape_44BrT: { ...shapeEar32 },
    ear_shape_z4yrT: { ...shapeEar33 },
    ear_shape_k4DrT: { ...shapeEar34 },
    ear_shape_A4TrT: { ...shapeEar35 },
    ear_shape_046rT: { ...shapeEar36 },
    ear_shape_B4IrT: { ...shapeEar37 },
    ear_shape_c4XrT: { ...shapeEar38 },
    ear_shape_U4VrT: { ...shapeEar39 },
    // Furry
    ear_shape_1TEKWA: { ...shapeEar1 },
    ear_shape_10775v: { ...shapeEar2 },
    ear_shape_1HOZ9g: { ...shapeEar3 },
    ear_shape_1vBJ7j: { ...shapeEar4 },
    ear_shape_1FYH9c: { ...shapeEar5 },
    ear_shape_1aoGZG: { ...shapeEar6 },
    ear_shape_1ZgR2p: { ...shapeEar7 },
    ear_shape_1vdyix: { ...shapeEar8 },
    ear_shape_1TbLPe: { ...shapeEar9 },
    ear_shape_13wnpn: { ...shapeEar10 },
    ear_shape_1PDULw: { ...shapeEar11 },
    ear_shape_1kOBSt: { ...shapeEar12 },
    ear_shape_1yAT34: { ...shapeEar13 },
    ear_shape_18wAS4: { ...shapeEar14 },
    ear_shape_1gcoC9: { ...shapeEar15 },
    ear_shape_1bPDFx: { ...shapeEar16 },
    ear_shape_1oEAG6: { ...shapeEar17 },
    ear_shape_1Dp6oZ: { ...shapeEar18 },
    ear_shape_1kf82L: { ...shapeEar19 },
    ear_shape_18sDmX: { ...shapeEar20 },
    ear_shape_1k9vVR: { ...shapeEar21 },
    ear_shape_1aKjrI: { ...shapeEar22 },
    ear_shape_12rsVH: { ...shapeEar23 },
    ear_shape_1axjXx: { ...shapeEar24 },
    ear_shape_1Zxpa2: { ...shapeEar25 },
    ear_shape_1jWOL0: { ...shapeEar26 },
    ear_shape_1TUUUj: { ...shapeEar27 },
    ear_shape_1Wk3es: { ...shapeEar28 },
    ear_shape_1XkDvO: { ...shapeEar29 },
    ear_shape_1xyzCw: { ...shapeEar30 },
    ear_shape_1epGZS: { ...shapeEar31 },
    ear_shape_14qBKl: { ...shapeEar32 },
    ear_shape_1zwyyL: { ...shapeEar33 },
    ear_shape_1kDDB4: { ...shapeEar34 },
    ear_shape_1AwTvI: { ...shapeEar35 },
    ear_shape_10U6iQ: { ...shapeEar36 },
    ear_shape_1BTIMM: { ...shapeEar37 },
    ear_shape_1cnX1o: { ...shapeEar38 },
    ear_shape_1UEVQI: { ...shapeEar39 },
  },
};

import React from "react";
import { connect } from "react-redux";
import { hairFrontLeftList } from "character-creator/components/hair/front/left/HairFrontLeftList";
import ItemComponent from "character-creator/components/ItemComponent";
import { hairFrontFullList } from "./full/HairFrontFullList";
import { hairFrontRightList } from "./right/HairFrontRightList";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import HairColours from "character-creator/colours/hair";
import { filterListById } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const hairConfig = getCharacterConfigValue(state, "hair.front");

  const defaultHairColour =
    getCharacterConfigValue(state, "hair.colour") ?? HairColours[0];

  const hairFrontColour = hairConfig?.colour
    ? hairConfig.colour
    : defaultHairColour;

  ////////////////////////
  // Full style
  ////////////////////////
  const hairConfigFull = hairConfig?.full;
  let hairObjectFull =
    ownProps.preview && ownProps.hair.shape
      ? ownProps.hair.shape
      : hairConfigFull?.shape && hairConfigFull.shape !== "0"
      ? filterListById(hairFrontFullList, hairConfigFull.shape).shape
      : null;

  const coloursFull = hairConfigFull?.colour
    ? hairConfigFull.colour
    : hairFrontColour;

  ////////////////////////
  // Left
  ////////////////////////
  const hairConfigLeft = hairConfig?.left;
  let hairObjectLeft =
    ownProps.preview && ownProps.hair.shape
      ? ownProps.hair.shape
      : hairConfigLeft?.shape && hairConfigLeft.shape !== "0"
      ? filterListById(hairFrontLeftList, hairConfigLeft.shape).shape
      : null;

  const coloursLeft = hairConfigLeft?.colour
    ? hairConfigLeft.colour
    : hairFrontColour;

  ////////////////////////
  // Right
  ////////////////////////
  const hairConfigRight = hairConfig?.right;
  let hairObjectRight =
    ownProps.preview && ownProps.hair.shape
      ? ownProps.hair.shape
      : hairConfigRight?.shape && hairConfigRight.shape !== "0"
      ? filterListById(hairFrontRightList, hairConfigRight.shape).shape
      : null;

  const coloursRight = hairConfigRight?.colour
    ? hairConfigRight.colour
    : hairFrontColour;

  return {
    fullStyle: hairConfigFull?.shape && hairConfigFull?.shape !== "0",
    full: {
      shape: hairObjectFull,
      colour: coloursFull,
    },
    left: {
      shape: hairObjectLeft,
      colour: coloursLeft,
    },
    right: {
      shape: hairObjectRight,
      colour: coloursRight,
    },
  };
}

const CharacterHairFront = (props) => {
  const { fullStyle, full, left, right, previewId } = props;

  if (fullStyle || left.shape || right.shape) {
    return (
      <g id={previewId ? `g-hair-front~${previewId}` : "g-hair-front"}>
        {fullStyle && full.shape && (
          <ItemComponent
            id={previewId ? `hair-front-full~${previewId}` : "hair-front-full"}
            component={full}
            colour={full.colour}
            preview={previewId ? true : false}
          />
        )}
        {!fullStyle && left.shape && (
          <ItemComponent
            id={previewId ? `hair-front-left~${previewId}` : "hair-front-left"}
            component={left}
            colour={left.colour}
            preview={previewId ? true : false}
          />
        )}
        {!fullStyle && right.shape && (
          <ItemComponent
            id={
              previewId ? `hair-front-right~${previewId}` : "hair_front_right"
            }
            component={right}
            colour={right.colour}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(CharacterHairFront);

import React from "react";

import PreviewBase, { previewViewbox } from "../Preview";

const teeth_shape_fg941_upper = {
  id: "teeth_shape_fg941_upper",
  name: "Teeth 1 (Upper)",
  description: "Short pointed upper fangs",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <>
          <path d="M240.549 302.58a6.102 6.102 0 0 0-1.252.74c-1.353 1.003-2.983 2.44-4.629 3.956.848.952 1.875 1.82 2.882 2.645.29.24.76.09.82-.26.526-3.34 1.194-3.608 2.179-7.081Z" />
          <path d="M259.449 302.576c.397.166.813.407 1.264.744 1.346 1.004 2.971 2.441 4.617 3.959-.848.951-1.874 1.818-2.88 2.642-.29.24-.76.09-.82-.26-.526-3.341-1.194-3.608-2.181-7.085Z" />
        </>
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <>
          <path d="M239.408 309.868c1.133-1.578 2.245-3.056 3.157-4.048.871-.956 1.548-1.162 2.29-1.046-.977 3.423-1.641 3.706-2.164 7.028-.06.35-.53.5-.82.26-.846-.692-1.706-1.416-2.463-2.194Z" />
          <path d="M255.146 304.778c.744-.115 1.423.09 2.299 1.042.909.992 2.019 2.469 3.15 4.045-.758.779-1.619 1.504-2.466 2.197-.29.24-.76.09-.82-.26-.522-3.321-1.186-3.604-2.163-7.024Z" />
        </>
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <>
          <path d="M240.527 303.623c-.973 3.4-1.635 3.689-2.157 7.003-.06.35-.53.5-.82.26-1.33-1.089-2.696-2.255-3.638-3.589 2.065-1.276 4.232-2.588 5.908-3.377a7.99 7.99 0 0 1 .707-.297Z" />
          <path d="M259.473 303.624c.227.082.461.179.707.296 1.673.793 3.841 2.104 5.906 3.38-.942 1.333-2.307 2.497-3.636 3.586-.29.24-.76.09-.82-.26-.522-3.314-1.184-3.603-2.157-7.002Z" />
        </>
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <>
          <path d="M240.443 309.989c-2.158.998-4.536 1.967-7.168 2.315.871 1.796 2.601 3.287 4.275 4.658.29.24.76.09.82-.26.507-3.218 1.145-3.584 2.073-6.713Z" />
          <path d="M259.605 310.148c2.124.981 4.473 1.908 7.094 2.208-.879 1.773-2.592 3.248-4.249 4.606-.29.24-.76.09-.82-.26-.498-3.163-1.123-3.571-2.025-6.554Z" />
        </>
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <>
          <path d="M237.659 306.723c.61 2.28 2.68 4.08 4.67 5.71.29.24.76.09.82-.26.54-3.43 1.23-3.62 2.26-7.37.15-.57-7.87 1.5-7.75 1.92Z" />
          <path d="M262.341 306.723c-.61 2.28-2.68 4.08-4.67 5.71-.29.24-.76.09-.82-.26-.54-3.43-1.23-3.62-2.26-7.37-.15-.57 7.87 1.5 7.75 1.92Z" />
        </>
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <>
          <path d="M232.88 301.965c.61 2.28 2.68 4.08 4.67 5.71.29.24.76.09.82-.26.54-3.43 1.23-3.62 2.26-7.37.15-.57-7.87 1.5-7.75 1.92Z" />
          <path d="M267.12 301.965c-.61 2.28-2.68 4.08-4.67 5.71-.29.24-.76.09-.82-.26-.54-3.43-1.23-3.62-2.26-7.37-.15-.57 7.87 1.5 7.75 1.92Z" />
        </>
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <>
          <path d="M232.88 303.558c.61 2.28 2.68 4.08 4.67 5.71.29.24.76.09.82-.26.54-3.43 1.23-3.62 2.26-7.37.15-.57-7.87 1.5-7.75 1.92Z" />
          <path d="M267.12 303.558c-.61 2.28-2.68 4.08-4.67 5.71-.29.24-.76.09-.82-.26-.54-3.43-1.23-3.62-2.26-7.37-.15-.57 7.87 1.5 7.75 1.92Z" />
        </>
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M232.88 309.687c.61 2.28 2.68 4.08 4.67 5.71.29.24.76.09.82-.26.54-3.43 1.23-3.62 2.26-7.37.15-.57-7.87 1.5-7.75 1.92Z" />
          <path d="M267.12 309.687c-.61 2.28-2.68 4.08-4.67 5.71-.29.24-.76.09-.82-.26-.54-3.43-1.23-3.62-2.26-7.37-.15-.57 7.87 1.5 7.75 1.92Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <>
          <path d="M232.88 307.709c.61 2.28 2.68 4.08 4.67 5.71.29.24.76.09.82-.26.54-3.43 1.23-3.62 2.26-7.37.15-.57-7.87 1.5-7.75 1.92Z" />
          <path d="M267.12 307.709c-.61 2.28-2.68 4.08-4.67 5.71-.29.24-.76.09-.82-.26-.54-3.43-1.23-3.62-2.26-7.37-.15-.57 7.87 1.5 7.75 1.92Z" />
        </>
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="M232.88 302.953c.61 2.28 2.68 4.08 4.67 5.71.29.24.76.09.82-.26.54-3.43 1.23-3.62 2.26-7.37.15-.57-7.87 1.5-7.75 1.92Z" />
          <path d="M267.12 302.953c-.61 2.28-2.68 4.08-4.67 5.71-.29.24-.76.09-.82-.26-.54-3.43-1.23-3.62-2.26-7.37-.15-.57 7.87 1.5 7.75 1.92Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <>
          <path d="M237.712 303.152c.61 2.28 2.68 4.08 4.67 5.71.29.24.76.09.82-.26.54-3.43 1.23-3.62 2.26-7.37.15-.57-7.87 1.5-7.75 1.92Z" />
          <path d="M262.288 303.152c-.61 2.28-2.68 4.08-4.67 5.71-.29.24-.76.09-.82-.26-.54-3.43-1.23-3.62-2.26-7.37-.15-.57 7.87 1.5 7.75 1.92Z" />
        </>
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <>
          <path d="M232.88 307.709c.61 2.28 2.68 4.08 4.67 5.71.29.24.76.09.82-.26.54-3.43 1.23-3.62 2.26-7.37.15-.57-7.87 1.5-7.75 1.92Z" />
          <path d="M267.12 307.709c-.61 2.28-2.68 4.08-4.67 5.71-.29.24-.76.09-.82-.26-.54-3.43-1.23-3.62-2.26-7.37-.15-.57 7.87 1.5 7.75 1.92Z" />
        </>
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="M263.345 301.755c-.02 2.294-1.456 4.54-2.865 6.61-.21.312-.703.301-.86-.017-.713-1.504-1.249-2.284-1.839-3.052 1.813-1.288 3.716-2.572 5.564-3.541Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="M236.655 301.755c.02 2.294 1.456 4.54 2.865 6.61.21.312.703.301.86-.017.713-1.504 1.249-2.284 1.839-3.052-1.813-1.288-3.716-2.572-5.564-3.541Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <>
          <path d="M263.322 306.47c-.762 2.037-2.667 3.686-4.503 5.19-.29.24-.76.09-.82-.26-.39-2.48-.859-3.266-1.479-4.93h6.802Z" />
          <path d="M243.48 306.47h-6.802c.762 2.037 2.667 3.686 4.503 5.19.29.24.76.09.82-.26.39-2.48.859-3.266 1.479-4.93Z" />
        </>
      ),
    },
    mouth_shape_74Nrw: {
      base: (
        <>
          <path d="M239.888 306.47h-6.879c.735 2.085 2.673 3.765 4.541 5.295.29.24.76.09.82-.26.399-2.532.879-3.299 1.518-5.035Z" />
          <path d="M266.991 306.47c-.735 2.085-2.673 3.765-4.541 5.295-.29.24-.76.09-.82-.26-.399-2.532-.879-3.299-1.518-5.035h6.879Z" />
        </>
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <>
          <path d="M263.993 303.884c-.711 2.127-2.678 3.833-4.572 5.385-.29.24-.76.09-.82-.26-.216-1.373-.456-2.226-.733-2.997a33.409 33.409 0 0 0 6.125-2.128Z" />
          <path d="M236.009 303.89c.712 2.124 2.677 3.829 4.57 5.379.29.24.76.09.82-.26.216-1.371.456-2.225.732-2.995a33.489 33.489 0 0 1-6.122-2.124Z" />
        </>
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <>
          <path d="M232.981 304.074c.713 2.123 2.677 3.827 4.569 5.376.29.24.76.09.82-.26.272-1.726.582-2.632.954-3.584a58.954 58.954 0 0 1-6.343-1.532Z" />
          <path d="M267.02 304.071c-.712 2.125-2.677 3.829-4.57 5.379-.29.24-.76.09-.82-.26-.272-1.727-.582-2.633-.955-3.586a58.757 58.757 0 0 0 6.345-1.533Z" />
        </>
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <>
          <path d="M243.675 304.21a32.49 32.49 0 0 0-7.456 2.445c.808 1.949 2.65 3.541 4.428 4.998.29.24.76.09.82-.26.531-3.372 1.207-3.613 2.208-7.183Z" />
          <path d="M256.315 304.21a32.49 32.49 0 0 1 7.456 2.445c-.808 1.949-2.65 3.541-4.428 4.998-.29.24-.76.09-.82-.26-.531-3.372-1.207-3.613-2.208-7.183Z" />
        </>
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <>
          <path d="M259.527 301.39a58.116 58.116 0 0 1 7.473 1.758c-.729 2.097-2.675 3.784-4.55 5.319-.29.24-.76.09-.82-.26-.512-3.253-1.159-3.591-2.103-6.817Z" />
          <path d="M240.474 301.388a58.141 58.141 0 0 0-7.475 1.757c.728 2.099 2.675 3.786 4.551 5.322.29.24.76.09.82-.26.512-3.253 1.159-3.591 2.104-6.819Z" />
        </>
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <>
          <path d="M232.996 305.375c2.043.026 4.379.046 6.883.06-.634 1.719-1.112 2.49-1.509 5.011-.06.35-.53.5-.82.26-1.878-1.539-3.828-3.229-4.554-5.331Z" />
          <path d="M267.004 305.375c-.726 2.102-2.676 3.792-4.554 5.331-.29.24-.76.09-.82-.26-.397-2.521-.875-3.292-1.509-5.011 2.503-.014 4.84-.034 6.883-.06Z" />
        </>
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <>
          <path d="M240.558 300.842c-.99 3.503-1.661 3.763-2.188 7.112-.06.35-.53.5-.82.26-1.869-1.531-3.809-3.212-4.543-5.3 6.106-.262 5.31-1.688 7.551-2.072Z" />
          <path d="M259.442 300.842c2.242.384 1.444 1.81 7.551 2.072-.734 2.088-2.674 3.769-4.543 5.3-.29.24-.76.09-.82-.26-.527-3.349-1.198-3.609-2.188-7.112Z" />
        </>
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <>
          <path d="M240.519 305.258c-.969 3.374-1.629 3.67-2.149 6.976-.06.35-.53.5-.82.26-1.53-1.254-3.108-2.607-4.032-4.203 1.018-.197 1.843-.447 2.307-.717 1.8-1.05 3.339-1.79 4.694-2.316Z" />
          <path d="M259.481 305.258c1.355.526 2.894 1.266 4.694 2.316.464.27 1.289.52 2.307.717-.924 1.596-2.502 2.949-4.032 4.203-.29.24-.76.09-.82-.26-.52-3.306-1.18-3.602-2.149-6.976Z" />
        </>
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <>
          <path d="M245.425 304.744c-.942 3.218-1.589 3.559-2.1 6.809-.06.35-.53.5-.82.26-1.436-1.176-2.912-2.44-3.853-3.908 1.72-1.107 3.509-2.456 6.773-3.161Z" />
          <path d="M254.575 304.744c3.264.705 5.053 2.054 6.773 3.161-.941 1.468-2.417 2.732-3.853 3.908-.29.24-.76.09-.82-.26-.511-3.25-1.158-3.591-2.1-6.809Z" />
        </>
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <>
          <path d="M240.445 305.397c-.929 3.135-1.568 3.5-2.075 6.72-.06.35-.53.5-.82.26-1.904-1.56-3.88-3.274-4.582-5.416 2.278-.602 4.936-1.251 7.477-1.564Z" />
          <path d="M259.555 305.397c2.54.313 5.199.962 7.477 1.564-.702 2.142-2.678 3.856-4.582 5.416-.29.24-.76.09-.82-.26-.507-3.22-1.146-3.585-2.075-6.72Z" />
        </>
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <>
          <path d="M239.923 303.351c-.656 1.805-1.147 2.553-1.553 5.131-.06.35-.53.5-.82.26-1.891-1.549-3.854-3.251-4.568-5.373 2.254.01 4.63-.001 6.941-.018Z" />
          <path d="M260.079 303.355c2.326.016 4.701.024 6.94.011-.713 2.123-2.677 3.826-4.569 5.376-.29.24-.76.09-.82-.26-.406-2.577-.896-3.325-1.551-5.127Z" />
        </>
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <>
          <path d="M233.092 311.238c2.082.016 4.34.029 6.698.038-.59 1.559-1.042 2.373-1.42 4.775-.06.35-.53.5-.82.26-1.801-1.475-3.667-3.09-4.458-5.073Z" />
          <path d="M266.908 311.238c-.791 1.983-2.657 3.598-4.458 5.073-.29.24-.76.09-.82-.26-.378-2.402-.83-3.216-1.42-4.775 2.359-.009 4.616-.022 6.698-.038Z" />
        </>
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <>
          <path d="M240.185 297.149c-.791 2.399-1.357 2.977-1.815 5.887-.06.35-.53.5-.82.26-1.837-1.504-3.743-3.154-4.504-5.193 2.262-.065 4.961-.348 7.139-.954Z" />
          <path d="M259.815 297.149c2.178.606 4.877.889 7.139.954-.761 2.039-2.667 3.689-4.504 5.193-.29.24-.76.09-.82-.26-.458-2.91-1.024-3.488-1.815-5.887Z" />
        </>
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <>
          <path d="M240.374 301.238c-.89 2.92-1.51 3.347-2.004 6.485-.06.35-.53.5-.82.26-1.306-1.07-2.647-2.214-3.587-3.518l.241-.044c2.481-.462 3.949-1.895 6.17-3.183Z" />
          <path d="M259.626 301.238c2.221 1.288 3.689 2.721 6.17 3.183l.241.044c-.94 1.304-2.281 2.448-3.587 3.518-.29.24-.76.09-.82-.26-.494-3.138-1.114-3.565-2.004-6.485Z" />
        </>
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M263.736 301.981c-.924 1.599-2.504 2.955-4.036 4.21-.29.24-.76.09-.82-.26-.402-2.55-.886-3.309-1.531-5.071 1.48.093 2.847.345 4.224.711.664.176 1.399.31 2.163.41Zm-21.085-1.121c-.645 1.762-1.129 2.521-1.531 5.071-.06.35-.53.5-.82.26-1.532-1.255-3.112-2.611-4.036-4.21a17.32 17.32 0 0 0 2.163-.41c1.377-.366 2.744-.618 4.224-.711Z" />
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <>
          <path d="M232.966 302.227c2.295.013 4.69.011 6.968 0-.662 1.827-1.156 2.569-1.564 5.162-.06.35-.53.5-.82.26-1.905-1.561-3.884-3.278-4.584-5.422Z" />
          <path d="M267.034 302.227c-.7 2.144-2.679 3.861-4.584 5.422-.29.24-.76.09-.82-.26-.408-2.593-.902-3.335-1.564-5.162 2.278.011 4.674.013 6.968 0Z" />
        </>
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <>
          <path d="M240.058 307.646c-.725 2.094-1.255 2.76-1.688 5.513-.06.35-.53.5-.82.26-1.495-1.225-3.035-2.546-3.968-4.094 1.9-.487 4.346-1.137 6.476-1.679Z" />
          <path d="M260.011 307.843c2.172.586 4.555 1.24 6.332 1.603-.938 1.496-2.437 2.78-3.893 3.973-.29.24-.76.09-.82-.26-.419-2.665-.929-3.374-1.619-5.316Z" />
        </>
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <>
          <path d="M233.023 304.965c2.174.025 4.519.042 6.83.055-.621 1.671-1.092 2.455-1.483 4.941-.06.35-.53.5-.82.26-1.856-1.521-3.782-3.189-4.527-5.256Z" />
          <path d="M266.977 304.965c-.745 2.067-2.671 3.735-4.527 5.256-.29.24-.76.09-.82-.26-.391-2.486-.862-3.27-1.483-4.941 2.311-.013 4.656-.03 6.83-.055Z" />
        </>
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <>
          <path d="M232.963 305.5c2.436-.952 4.855-1.602 7.492-1.588-.934 3.169-1.576 3.524-2.085 6.756-.06.35-.53.5-.82.26-1.907-1.562-3.888-3.281-4.587-5.428Z" />
          <path d="M259.545 303.912c2.637-.014 5.056.636 7.492 1.588-.699 2.147-2.68 3.866-4.587 5.428-.29.24-.76.09-.82-.26-.509-3.232-1.151-3.587-2.085-6.756Z" />
        </>
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <>
          <path d="M233.328 303.422c2.069.494 4.115 1.069 5.671 1.591-.233.698-.44 1.501-.629 2.699-.06.35-.53.5-.82.26-1.64-1.343-3.335-2.802-4.222-4.55Z" />
          <path d="M266.672 303.422c-.887 1.748-2.582 3.207-4.222 4.55-.29.24-.76.09-.82-.26-.189-1.198-.396-2.001-.629-2.699 1.556-.522 3.601-1.097 5.671-1.591Z" />
        </>
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <>
          <path d="M233.076 302.023c.781 2.003 2.661 3.63 4.474 5.115.29.24.76.09.82-.26.449-2.851 1.001-3.464 1.767-5.743-2.625-.1-4.991.275-7.061.888Z" />
          <path d="M259.863 301.135c2.625-.1 4.991.274 7.061.888-.781 2.003-2.661 3.63-4.474 5.115-.29.24-.76.09-.82-.26-.449-2.851-1.001-3.464-1.767-5.743Z" />
        </>
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <>
          <path d="M233.048 301.034c.161-.001.31 0 .446.005 2.066.077 3.801.416 6.067.738-.48 1.214-.863 2.103-1.191 4.184-.06.35-.53.5-.82.26-1.836-1.503-3.739-3.151-4.502-5.187Z" />
          <path d="M260.439 301.777c2.266-.322 4.001-.661 6.067-.738.136-.005.285-.006.446-.005-.763 2.036-2.666 3.684-4.502 5.187-.29.24-.76.09-.82-.26-.328-2.081-.711-2.97-1.191-4.184Z" />
        </>
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M239.937 296.642c-.663 1.835-1.158 2.574-1.567 5.172-.06.35-.53.5-.82.26-1.841-1.508-3.75-3.162-4.509-5.206 2.405-.097 4.764-.166 6.896-.226Z" />
          <path d="M260.063 296.642c2.132.06 4.491.129 6.896.226-.759 2.044-2.668 3.698-4.509 5.206-.29.24-.76.09-.82-.26-.409-2.598-.904-3.337-1.567-5.172Z" />
        </>
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="M233.087 297.038c1.686.087 4.189.068 6.718.012-.597 1.585-1.054 2.391-1.435 4.814-.06.35-.53.5-.82.26-1.805-1.478-3.675-3.097-4.463-5.086Z" />
          <path d="M266.913 297.038c-.788 1.989-2.658 3.608-4.463 5.086-.29.24-.76.09-.82-.26-.381-2.423-.838-3.229-1.435-4.814 2.529.056 5.032.075 6.718-.012Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="M240.345 299.333c-.875 2.836-1.486 3.287-1.975 6.391-.06.35-.53.5-.82.26-1.808-1.481-3.682-3.102-4.467-5.097a48.838 48.838 0 0 0 5.035-1.194c.632-.189 1.395-.301 2.227-.36Z" />
          <path d="M259.655 299.333c.832.059 1.595.171 2.227.36 1.612.482 3.381.89 5.035 1.194-.785 1.995-2.659 3.616-4.467 5.097-.29.24-.76.09-.82-.26-.489-3.104-1.1-3.555-1.975-6.391Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="M240.508 300.777c-.963 3.337-1.62 3.644-2.138 6.937-.06.35-.53.5-.82.26-1.891-1.549-3.855-3.252-4.569-5.374l.055-.015c2.283-.615 5.08-1.33 7.472-1.808Z" />
          <path d="M259.492 300.777c2.392.478 5.189 1.193 7.472 1.808l.055.015c-.714 2.122-2.678 3.825-4.569 5.374-.29.24-.76.09-.82-.26-.518-3.293-1.175-3.6-2.138-6.937Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="M233.021 304.892c1.851.042 4.327.063 6.827.072-.619 1.662-1.088 2.449-1.478 4.928-.06.35-.53.5-.82.26-1.857-1.521-3.785-3.191-4.529-5.26Z" />
          <path d="M266.979 304.892c-.744 2.069-2.672 3.739-4.529 5.26-.29.24-.76.09-.82-.26-.39-2.479-.859-3.266-1.478-4.928 2.5-.009 4.976-.03 6.827-.072Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="M240.441 299.85c-.927 3.123-1.565 3.491-2.071 6.707-.06.35-.53.5-.82.26-1.896-1.553-3.864-3.26-4.574-5.389.963.001 1.86-.027 2.55-.075 2.089-.143 3.45-1.432 4.542-1.489.129-.007.253-.012.373-.014Z" />
          <path d="M259.559 299.85c.12.002.244.007.373.014 1.092.057 2.453 1.346 4.542 1.489.69.048 1.587.076 2.55.075-.71 2.129-2.678 3.836-4.574 5.389-.29.24-.76.09-.82-.26-.506-3.216-1.144-3.584-2.071-6.707Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="M232.982 301.07a58.025 58.025 0 0 0 6.177 1.962c-.3.813-.558 1.687-.789 3.152-.06.35-.53.5-.82.26-1.891-1.549-3.854-3.252-4.568-5.374Z" />
          <path d="M267.018 301.07c-.714 2.122-2.677 3.825-4.568 5.374-.29.24-.76.09-.82-.26-.231-1.465-.489-2.339-.789-3.152a58.17 58.17 0 0 0 6.177-1.962Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="M240.24 300.947c-.819 2.544-1.401 3.079-1.87 6.058-.06.35-.53.5-.82.26-1.849-1.515-3.768-3.177-4.519-5.234 2.239-.385 4.589-.848 7.209-1.084Z" />
          <path d="M259.76 300.947c2.621.237 4.97.699 7.209 1.084-.751 2.057-2.67 3.719-4.519 5.234-.29.24-.76.09-.82-.26-.469-2.979-1.051-3.514-1.87-6.058Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="M232.951 299.511c2.253.484 4.677.724 6.831.455-.586 1.545-1.036 2.361-1.412 4.752-.06.35-.53.5-.82.26-1.919-1.572-3.912-3.302-4.599-5.467Z" />
          <path d="M267.049 299.511c-.687 2.165-2.68 3.895-4.599 5.467-.29.24-.76.09-.82-.26-.376-2.391-.826-3.207-1.412-4.752 2.154.269 4.578.029 6.831-.455Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="M240.504 301.031c-.961 3.325-1.616 3.635-2.134 6.924-.06.35-.53.5-.82.26-1.615-1.323-3.283-2.758-4.18-4.47 2.628-.522 4.915-1.727 7.134-2.714Z" />
          <path d="M259.496 301.031c2.218.987 4.505 2.192 7.134 2.714-.897 1.712-2.565 3.147-4.18 4.47-.29.24-.76.09-.82-.26-.518-3.289-1.173-3.599-2.134-6.924Z" />
        </>
      ),
    },
  },
};

const teeth_shape_fg941_lower = {
  id: "teeth_shape_fg941_lower",
  name: "Teeth 1 (Lower)",
  description: "Short pointed lower fangs",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <>
          <path d="M233.125 314.254c.811-1.942 2.648-3.529 4.421-4.981.29-.24.76-.09.82.26.515 3.272 1.166 3.596 2.12 6.874-2.521-.126-4.694-.538-6.416-1.597-.326-.2-.641-.385-.945-.556Z" />
          <path d="M259.498 316.432c.957-3.302 1.611-3.619 2.128-6.899.06-.35.53-.5.82-.26 1.771 1.451 3.607 3.037 4.418 4.977a32.51 32.51 0 0 0-.944.56c-1.865 1.144-3.947 1.533-6.422 1.622Z" />
        </>
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <>
          <path d="M237.736 312.232c-.207-2.286 1.002-4.664 2.201-6.863.179-.332.671-.37.858-.068.355.591.668 1.061.96 1.459-1.226 1.498-2.673 3.552-4.019 5.472Z" />
          <path d="M258.235 306.738c.287-.393.594-.857.943-1.437.187-.302.679-.264.858.068 1.192 2.187 2.395 4.55 2.204 6.825-1.344-1.918-2.786-3.965-4.005-5.456Z" />
        </>
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <>
          <path d="M261.244 304.451c1.779.942 3.907 2.262 5.849 3.466-.641-2.235-2.681-4.007-4.643-5.614-.29-.24-.76-.09-.82.26-.121.766-.249 1.37-.386 1.888Z" />
          <path d="M232.904 307.927c.638-2.239 2.681-4.015 4.646-5.624.29-.24.76-.09.82.26.121.769.25 1.375.388 1.895-1.783.942-3.912 2.265-5.854 3.469Z" />
        </>
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <>
          <path d="M233.059 311.33c.77-2.022 2.664-3.662 4.491-5.158.29-.24.76-.09.82.26.223 1.418.472 2.282.76 3.073-1.854.822-3.871 1.554-6.071 1.825Z" />
          <path d="M260.827 309.622c1.861.82 3.895 1.535 6.134 1.762-.757-2.047-2.668-3.702-4.511-5.212-.29-.24-.76-.09-.82.26-.234 1.488-.497 2.366-.803 3.19Z" />
        </>
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <>
          <path d="M237.78 310.911c.61-2.28 2.68-4.08 4.67-5.71.29-.24.76-.09.82.26.54 3.43 1.23 3.62 2.26 7.37.15.57-7.87-1.5-7.75-1.92Z" />
          <path d="M262.22 310.911c-.61-2.28-2.68-4.08-4.67-5.71-.29-.24-.76-.09-.82.26-.54 3.43-1.23 3.62-2.26 7.37-.15.57 7.87-1.5 7.75-1.92Z" />
        </>
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <>
          <path d="M232.88 303.328c.61-2.28 2.68-4.08 4.67-5.71.29-.24.76-.09.82.26.54 3.43 1.23 3.62 2.26 7.37.15.57-7.87-1.5-7.75-1.92Z" />
          <path d="M267.12 303.328c-.61-2.28-2.68-4.08-4.67-5.71-.29-.24-.76-.09-.82.26-.54 3.43-1.23 3.62-2.26 7.37-.15.57 7.87-1.5 7.75-1.92Z" />
        </>
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <>
          <path d="M232.88 305.051c.61-2.28 2.68-4.08 4.67-5.71.29-.24.76-.09.82.26.54 3.43 1.23 3.62 2.26 7.37.15.57-7.87-1.5-7.75-1.92Z" />
          <path d="M267.12 305.051c-.61-2.28-2.68-4.08-4.67-5.71-.29-.24-.76-.09-.82.26-.54 3.43-1.23 3.62-2.26 7.37-.15.57 7.87-1.5 7.75-1.92Z" />
        </>
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M232.88 311.132c.61-2.28 2.68-4.08 4.67-5.71.29-.24.76-.09.82.26.54 3.43 1.23 3.62 2.26 7.37.15.57-7.87-1.5-7.75-1.92Z" />
          <path d="M267.12 311.132c-.61-2.28-2.68-4.08-4.67-5.71-.29-.24-.76-.09-.82.26-.54 3.43-1.23 3.62-2.26 7.37-.15.57 7.87-1.5 7.75-1.92Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <>
          <path d="M234.269 310.296c.61-2.28 2.68-4.08 4.67-5.71.29-.24.76-.09.82.26.54 3.43 1.23 3.62 2.26 7.37.15.57-7.87-1.5-7.75-1.92Z" />
          <path d="M265.731 310.296c-.61-2.28-2.68-4.08-4.67-5.71-.29-.24-.76-.09-.82.26-.54 3.43-1.23 3.62-2.26 7.37-.15.57 7.87-1.5 7.75-1.92Z" />
        </>
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="M232.88 312.393c.61-2.28 2.68-4.08 4.67-5.71.29-.24.76-.09.82.26.54 3.43 1.23 3.62 2.26 7.37.15.57-7.87-1.5-7.75-1.92Z" />
          <path d="M267.12 312.393c-.61-2.28-2.68-4.08-4.67-5.71-.29-.24-.76-.09-.82.26-.54 3.43-1.23 3.62-2.26 7.37-.15.57 7.87-1.5 7.75-1.92Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <>
          <path d="M237.712 312.04c.61-2.28 2.68-4.08 4.67-5.71.29-.24.76-.09.82.26.54 3.43 1.23 3.62 2.26 7.37.15.57-7.87-1.5-7.75-1.92Z" />
          <path d="M262.288 312.04c-.61-2.28-2.68-4.08-4.67-5.71-.29-.24-.76-.09-.82.26-.54 3.43-1.23 3.62-2.26 7.37-.15.57 7.87-1.5 7.75-1.92Z" />
        </>
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <>
          <path d="M232.88 308.606c.61-2.28 2.68-4.08 4.67-5.71.29-.24.76-.09.82.26.54 3.43 1.23 3.62 2.26 7.37.15.57-7.87-1.5-7.75-1.92Z" />
          <path d="M267.12 308.606c-.61-2.28-2.68-4.08-4.67-5.71-.29-.24-.76-.09-.82.26-.54 3.43-1.23 3.62-2.26 7.37-.15.57 7.87-1.5 7.75-1.92Z" />
        </>
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="M261.648 302.708c-.818-.887-1.783-1.701-2.731-2.477-.29-.24-.76-.09-.82.26-.456 2.901-1.02 3.484-1.807 5.864 1.708-1.232 3.538-2.545 5.358-3.647Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="M238.352 302.708c.818-.887 1.783-1.701 2.731-2.477.29-.24.76-.09.82.26.456 2.901 1.02 3.484 1.807 5.864-1.708-1.232-3.538-2.545-5.358-3.647Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <>
          <path d="M237.089 305.47c.776-2.011 2.662-3.643 4.481-5.133.29-.24.76-.09.82.26.386 2.452.849 3.248 1.458 4.873h-6.759Z" />
          <path d="M256.152 305.47h6.759c-.776-2.011-2.662-3.643-4.481-5.133-.29-.24-.76-.09-.82.26-.386 2.452-.849 3.248-1.458 4.873Z" />
        </>
      ),
    },
    mouth_shape_74Nrw: {
      base: (
        <>
          <path d="M260.063 305.47h6.974c-.697-2.149-2.679-3.868-4.587-5.431-.29-.24-.76-.09-.82.26-.409 2.597-.904 3.337-1.567 5.171Z" />
          <path d="M232.963 305.47c.697-2.149 2.679-3.868 4.587-5.431.29-.24.76-.09.82.26.409 2.597.904 3.337 1.567 5.171h-6.974Z" />
        </>
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <>
          <path d="M237.046 303.258c.802-1.96 2.651-3.558 4.437-5.021.29-.24.76-.09.82.26.52 3.306 1.18 3.602 2.149 6.977a32.447 32.447 0 0 1-7.406-2.216Z" />
          <path d="M255.549 305.472a32.451 32.451 0 0 0 7.404-2.218c-.804-1.958-2.652-3.555-4.436-5.017-.29-.24-.76-.09-.82.26-.52 3.306-1.179 3.602-2.148 6.975Z" />
        </>
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <>
          <path d="M259.531 304.789a57.907 57.907 0 0 0 7.454-1.753c-.736-2.083-2.673-3.762-4.54-5.291-.29-.24-.76-.09-.82.26-.51 3.242-1.155 3.59-2.094 6.784Z" />
          <path d="M233.005 303.036c.736-2.083 2.673-3.762 4.54-5.291.29-.24.76-.09.82.26.51 3.242 1.155 3.59 2.094 6.784a57.946 57.946 0 0 1-7.454-1.753Z" />
        </>
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <>
          <path d="M236.358 305.492c.684-2.171 2.681-3.904 4.603-5.479.29-.24.76-.09.82.26.231 1.464.488 2.338.788 3.15a33.468 33.468 0 0 0-6.211 2.069Z" />
          <path d="M257.421 303.423a33.518 33.518 0 0 1 6.211 2.069c-.684-2.171-2.681-3.904-4.603-5.479-.29-.24-.76-.09-.82.26-.231 1.464-.488 2.338-.788 3.15Z" />
        </>
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <>
          <path d="M260.298 300.508c2.168.384 4.318.889 6.441 1.517-.323-2.038-1.663-3.999-2.978-5.82-.219-.306-.711-.281-.859.042-.983 2.23-1.629 2.898-2.604 4.261Z" />
          <path d="M233.262 302.022c.324-2.038 1.663-3.997 2.977-5.817.219-.306.711-.281.859.042.983 2.229 1.629 2.897 2.603 4.259a59.137 59.137 0 0 0-6.439 1.516Z" />
        </>
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <>
          <path d="M232.987 305.375c.718-2.115 2.677-3.813 4.563-5.358.29-.24.76-.09.82.26.408 2.591.902 3.333 1.563 5.159-2.526-.015-4.886-.035-6.946-.061Z" />
          <path d="M260.067 305.436c.661-1.826 1.155-2.568 1.563-5.159.06-.35.53-.5.82-.26 1.886 1.545 3.845 3.243 4.563 5.358-2.06.026-4.42.046-6.946.061Z" />
        </>
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <>
          <path d="M233.009 302.914c.735-2.086 2.673-3.765 4.541-5.295.29-.24.76-.09.82.26.257 1.632.548 2.531.893 3.428-.993.575-1.719 1.412-6.254 1.607Z" />
          <path d="M260.737 301.307c.345-.897.636-1.796.893-3.428.06-.35.53-.5.82-.26 1.868 1.53 3.806 3.209 4.541 5.295-4.535-.195-5.261-1.032-6.254-1.607Z" />
        </>
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <>
          <path d="M234.252 308.694c.78-2.005 2.661-3.633 4.477-5.12.29-.24.76-.09.82.26.45 2.858 1.004 3.467 1.772 5.76-3.196.004-4.581.002-6.966-.862a4.283 4.283 0 0 1-.103-.038Z" />
          <path d="M258.679 309.594c.768-2.293 1.322-2.902 1.772-5.76.06-.35.53-.5.82-.26 1.816 1.487 3.697 3.115 4.477 5.12a4.283 4.283 0 0 1-.103.038c-2.385.864-3.77.866-6.966.862Z" />
        </>
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <>
          <path d="M239.14 308.346c.931-1.555 2.477-2.881 3.977-4.11.29-.24.76-.09.82.26.503 3.196 1.137 3.579 2.053 6.648-2.594-.702-4.813-1.892-6.85-2.798Z" />
          <path d="M254.01 311.144c.916-3.069 1.55-3.452 2.053-6.648.06-.35.53-.5.82-.26 1.5 1.229 3.046 2.555 3.977 4.11-2.037.906-4.256 2.096-6.85 2.798Z" />
        </>
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <>
          <path d="M232.928 306.971c.665-2.2 2.682-3.95 4.622-5.54.29-.24.76-.09.82.26.297 1.885.639 2.791 1.057 3.848-2.237.345-4.513.907-6.499 1.432Z" />
          <path d="M260.573 305.539c.418-1.057.76-1.963 1.057-3.848.06-.35.53-.5.82-.26 1.94 1.59 3.957 3.34 4.622 5.54-1.986-.525-4.263-1.087-6.499-1.432Z" />
        </>
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <>
          <path d="M232.908 303.368c.644-2.231 2.682-4.002 4.642-5.608.29-.24.76-.09.82.26.421 2.672.932 3.378 1.624 5.33-2.359.018-4.787.029-7.086.018Z" />
          <path d="M260.004 303.354c.693-1.955 1.205-2.661 1.626-5.334.06-.35.53-.5.82-.26 1.959 1.605 3.996 3.375 4.641 5.605-2.284.015-4.712.006-7.087-.011Z" />
        </>
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <>
          <path d="M233.541 311.252c.927-1.578 2.491-2.92 4.009-4.163.29-.24.76-.09.82.26.304 1.93.655 2.834 1.088 3.924a2345.9 2345.9 0 0 1-5.917-.021Z" />
          <path d="M260.542 311.273c.433-1.09.784-1.994 1.088-3.924.06-.35.53-.5.82-.26 1.518 1.243 3.082 2.585 4.009 4.163a2345.9 2345.9 0 0 1-5.917.021Z" />
        </>
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <>
          <path d="M232.986 298.105c.717-2.117 2.676-3.816 4.564-5.362.29-.24.76-.09.82.26.338 2.146.734 3.023 1.236 4.297-2.085.508-4.542.749-6.62.805Z" />
          <path d="M260.394 297.3c.502-1.274.898-2.151 1.236-4.297.06-.35.53-.5.82-.26 1.888 1.546 3.847 3.245 4.564 5.362-2.078-.056-4.535-.297-6.62-.805Z" />
        </>
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <>
          <path d="M235.891 304.016c.743-2.073 2.672-3.744 4.532-5.267.29-.24.76-.09.82.26.366 2.328.802 3.164 1.366 4.633-1.43.198-2.827.377-4.241.396-.85.011-1.681 0-2.477-.022Z" />
          <path d="M257.391 303.642c.564-1.469 1-2.305 1.366-4.633.06-.35.53-.5.82-.26 1.86 1.523 3.789 3.194 4.532 5.267-.796.022-1.627.033-2.477.022-1.414-.019-2.811-.198-4.241-.396Z" />
        </>
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M235.138 302.104c.7-2.145 2.679-3.861 4.585-5.422.29-.24.76-.09.82.26.414 2.63.916 3.355 1.592 5.241-1.364-.144-3.117-.243-5.438-.263a26.28 26.28 0 0 1-1.559.184Zm22.727.079c.676-1.886 1.178-2.611 1.592-5.241.06-.35.53-.5.82-.26 1.906 1.561 3.885 3.277 4.585 5.422a26.28 26.28 0 0 1-1.559-.184c-2.321.02-4.074.119-5.438.263Z" />
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <>
          <path d="M232.956 302.227c.692-2.159 2.68-3.883 4.594-5.451.29-.24.76-.09.82.26.41 2.607.907 3.342 1.575 5.191a678.42 678.42 0 0 1-6.989 0Z" />
          <path d="M260.055 302.227c.668-1.849 1.165-2.584 1.575-5.191.06-.35.53-.5.82-.26 1.914 1.568 3.902 3.292 4.594 5.451a678.42 678.42 0 0 1-6.989 0Z" />
        </>
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <>
          <path d="M234.266 310.319c.913-1.141 2.112-2.16 3.284-3.119.29-.24.76-.09.82.26.235 1.494.499 2.374.807 3.201a77.657 77.657 0 0 1-4.911-.342Z" />
          <path d="M259.636 313.91c2.342.206 4.38.001 6.087-1.002.382-.224.777-.439 1.182-.643-.793-1.98-2.656-3.592-4.455-5.065-.29-.24-.76-.09-.82.26-.492 3.125-1.109 3.56-1.994 6.45Z" />
        </>
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <>
          <path d="M233.028 304.965c.75-2.061 2.671-3.724 4.522-5.241.29-.24.76-.09.82.26.399 2.533.879 3.299 1.518 5.036-2.32-.012-4.676-.03-6.86-.055Z" />
          <path d="M260.112 305.02c.639-1.737 1.119-2.503 1.518-5.036.06-.35.53-.5.82-.26 1.851 1.517 3.772 3.18 4.522 5.241-2.184.025-4.54.043-6.86.055Z" />
        </>
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <>
          <path d="M233.089 305.451c.789-1.987 2.658-3.603 4.461-5.08.29-.24.76-.09.82.26.247 1.568.525 2.459.853 3.323-2.124.154-4.125.72-6.134 1.497Z" />
          <path d="M260.777 303.954c.328-.864.606-1.755.853-3.323.06-.35.53-.5.82-.26 1.803 1.477 3.672 3.093 4.461 5.08-2.01-.777-4.01-1.343-6.134-1.497Z" />
        </>
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <>
          <path d="M233.267 303.407c.869-1.803 2.604-3.299 4.283-4.674.29-.24.76-.09.82.26.496 3.15 1.118 3.567 2.015 6.519a3.463 3.463 0 0 1-.168-.061c-1.604-.625-4.26-1.403-6.95-2.044Z" />
          <path d="M259.615 305.512c.897-2.952 1.519-3.369 2.015-6.519.06-.35.53-.5.82-.26 1.679 1.375 3.414 2.871 4.283 4.674-2.689.64-5.346 1.419-6.95 2.044-.052.02-.107.041-.168.061Z" />
        </>
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <>
          <path d="M260.498 301.12c.453-1.14.818-2.039 1.132-4.034.06-.35.53-.5.82-.26 1.842 1.508 3.752 3.163 4.51 5.208a21.832 21.832 0 0 0-6.462-.914Z" />
          <path d="M233.04 302.034c.758-2.045 2.668-3.7 4.51-5.208.29-.24.76-.09.82.26.314 1.995.679 2.894 1.132 4.034a21.827 21.827 0 0 0-6.462.914Z" />
        </>
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <>
          <path d="M232.995 301.034c.725-2.104 2.675-3.795 4.555-5.334.29-.24.76-.09.82.26.459 2.917 1.027 3.49 1.82 5.903-2.584-.344-4.443-.741-6.696-.824a11.35 11.35 0 0 0-.499-.005Z" />
          <path d="M259.81 301.863c.793-2.413 1.361-2.986 1.82-5.903.06-.35.53-.5.82-.26 1.88 1.539 3.83 3.23 4.555 5.334-.181-.002-.348 0-.499.005-2.253.083-4.112.48-6.696.824Z" />
        </>
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M233.009 310.307c.736-2.086 2.674-3.765 4.541-5.295.29-.24.76-.09.82.26.496 3.15 1.118 3.568 2.014 6.519-2.393-.227-4.94-.662-7.375-1.484Z" />
          <path d="M259.616 311.791c.896-2.951 1.518-3.369 2.014-6.519.06-.35.53-.5.82-.26 1.867 1.53 3.805 3.209 4.541 5.295-2.435.822-4.983 1.257-7.375 1.484Z" />
        </>
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="M232.996 297.033c.725-2.102 2.675-3.793 4.554-5.332.29-.24.76-.09.82.26.403 2.557.888 3.314 1.536 5.086-2.61.059-5.202.08-6.91-.014Z" />
          <path d="M260.094 297.047c.648-1.772 1.133-2.529 1.536-5.086.06-.35.53-.5.82-.26 1.879 1.539 3.829 3.23 4.554 5.332-1.708.094-4.301.073-6.91.014Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="M233.56 302.52c.93-1.564 2.483-2.896 3.99-4.13.29-.24.76-.09.82.26.525 3.332 1.191 3.607 2.172 7.055-1.443-.673-4.253-2.087-6.982-3.185Z" />
          <path d="M259.458 305.705c.981-3.448 1.647-3.723 2.172-7.055.06-.35.53-.5.82-.26 1.507 1.234 3.06 2.566 3.99 4.13-2.729 1.098-5.539 2.512-6.982 3.185Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="M238.114 301.519c.935-1.529 2.46-2.836 3.941-4.049.29-.24.76-.09.82.26.523 3.324 1.187 3.605 2.165 7.031-3.173-.684-4.334-1.884-5.332-2.419-.595-.319-1.087-.606-1.594-.823Z" />
          <path d="M254.96 304.761c.978-3.426 1.642-3.707 2.165-7.031.06-.35.53-.5.82-.26 1.481 1.213 3.006 2.52 3.941 4.049-.507.217-.999.504-1.594.823-.998.535-2.159 1.735-5.332 2.419Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="M232.956 304.89c.691-2.159 2.68-3.884 4.594-5.452.29-.24.76-.09.82.26.416 2.642.921 3.362 1.602 5.267a385.793 385.793 0 0 1-7.016-.075Z" />
          <path d="M260.028 304.965c.681-1.905 1.186-2.625 1.602-5.267.06-.35.53-.5.82-.26 1.914 1.568 3.903 3.293 4.594 5.452-1.888.044-4.444.066-7.016.075Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="M233.02 301.428c.744-2.071 2.672-3.741 4.53-5.263.29-.24.76-.09.82.26.275 1.746.589 2.653.967 3.617-.967.372-2.163 1.198-3.811 1.311-.679.047-1.56.075-2.506.075Z" />
          <path d="M260.663 300.042c.378-.964.692-1.871.967-3.617.06-.35.53-.5.82-.26 1.858 1.522 3.786 3.192 4.53 5.263-.946 0-1.827-.028-2.506-.075-1.648-.113-2.844-.939-3.811-1.311Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="M233.131 301.126c.812-1.94 2.647-3.526 4.419-4.977.29-.24.76-.09.82.26.519 3.296 1.177 3.6 2.141 6.948a56.53 56.53 0 0 1-7.38-2.231Z" />
          <path d="M259.489 303.357c.964-3.348 1.622-3.652 2.141-6.948.06-.35.53-.5.82-.26 1.772 1.451 3.607 3.037 4.419 4.977a56.53 56.53 0 0 1-7.38 2.231Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="M232.943 302.046c.679-2.178 2.68-3.916 4.607-5.494.29-.24.76-.09.82.26.329 2.091.714 2.978 1.198 4.201-2.387.253-4.553.678-6.625 1.033Z" />
          <path d="M260.432 301.013c.484-1.223.869-2.11 1.198-4.201.06-.35.53-.5.82-.26 1.927 1.578 3.928 3.316 4.607 5.494-2.072-.355-4.238-.78-6.625-1.033Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="M232.958 299.512c.693-2.156 2.679-3.88 4.592-5.447.29-.24.76-.09.82.26.439 2.79.978 3.437 1.718 5.599-2.227.333-4.772.094-7.13-.412Z" />
          <path d="M259.912 299.924c.74-2.162 1.279-2.809 1.718-5.599.06-.35.53-.5.82-.26 1.913 1.567 3.899 3.291 4.593 5.447-2.36.506-4.905.745-7.131.412Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="M234.875 303.367c.656-2.214 2.682-3.973 4.63-5.569.29-.24.76-.09.82.26.195 1.237.409 2.053.652 2.766-1.935.833-3.908 1.882-6.102 2.543Z" />
          <path d="M259.023 300.824c.243-.713.457-1.529.652-2.766.06-.35.53-.5.82-.26 1.948 1.596 3.974 3.355 4.63 5.569-2.194-.661-4.167-1.71-6.102-2.543Z" />
        </>
      ),
    },
  },
};

const teeth_shape_g23ff_upper = {
  id: "teeth_shape_g23ff_upper",
  name: "Teeth 2 (Upper)",
  description: "Long pointed upper fangs",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <>
          <path d="M240.663 302.534c-.427.167-.875.42-1.366.786-1.72 1.275-3.886 3.25-5.967 5.201.944 3.134 2.603 5.785 4.22 8.234.3.45.79.16.84-.5.55-6.452 1.244-6.925 2.273-13.721Z" />
          <path d="M259.336 302.53c.431.166.882.421 1.377.79 1.712 1.277 3.875 3.254 5.955 5.205-.944 3.133-2.602 5.783-4.218 8.23-.3.45-.79.16-.84-.5-.55-6.453-1.245-6.925-2.274-13.725Z" />
        </>
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <>
          <path d="M256.837 305.251c.193.15.395.337.608.569 1.63 1.78 3.907 5.118 5.79 7.792-.929 2.1-2.126 3.985-3.3 5.763-.3.45-.79.16-.84-.5-.547-6.421-1.238-6.92-2.258-13.624Z" />
          <path d="M243.163 305.255c-1.02 6.7-1.711 7.2-2.258 13.62-.05.66-.54.95-.84.5-1.174-1.777-2.37-3.662-3.299-5.761 1.885-2.674 4.164-6.016 5.799-7.794a4.84 4.84 0 0 1 .598-.565Z" />
        </>
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <>
          <path d="M259.281 303.559c.285.092.583.211.899.361 1.967.932 4.616 2.58 6.98 4.045-.741 4.046-2.757 7.3-4.71 10.258-.3.45-.79.16-.84-.5-.56-6.57-1.27-6.94-2.33-14.1a.17.17 0 0 1 .001-.064Z" />
          <path d="M240.719 303.558a.175.175 0 0 1 .001.065c-1.06 7.16-1.77 7.53-2.33 14.1-.05.66-.54.95-.84.5-1.954-2.959-3.97-6.214-4.711-10.262 2.364-1.465 5.012-3.114 6.981-4.041.316-.151.614-.27.899-.362Z" />
        </>
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <>
          <path d="M240.508 309.958c-2.318 1.076-4.891 2.124-7.762 2.407.65 4.308 2.762 7.726 4.804 10.819.3.45.79.16.84-.5.522-6.122 1.174-6.861 2.118-12.726Z" />
          <path d="M259.516 310.107c2.294 1.064 4.851 2.074 7.731 2.302-.658 4.287-2.762 7.692-4.797 10.775-.3.45-.79.16-.84-.5-.517-6.07-1.163-6.848-2.094-12.577Z" />
        </>
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <>
          <path d="M237.671 307.898c.63 4.36 2.76 7.81 4.82 10.93.3.45.79.16.84-.5.56-6.57 1.27-6.94 2.33-14.1.17-1.1-8.1 2.87-7.99 3.67Z" />
          <path d="M262.329 307.898c-.63 4.36-2.76 7.81-4.82 10.93-.3.45-.79.16-.84-.5-.56-6.57-1.27-6.94-2.33-14.1-.17-1.1 8.1 2.87 7.99 3.67Z" />
        </>
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <>
          <path d="M232.73 301.896c.63 4.36 2.76 7.81 4.82 10.93.3.45.79.16.84-.5.56-6.57 1.27-6.94 2.33-14.1.17-1.1-8.1 2.87-7.99 3.67Z" />
          <path d="M267.27 301.896c-.63 4.36-2.76 7.81-4.82 10.93-.3.45-.79.16-.84-.5-.56-6.57-1.27-6.94-2.33-14.1-.17-1.1 8.1 2.87 7.99 3.67Z" />
        </>
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <>
          <path d="M232.73 304.488c.63 4.36 2.76 7.81 4.82 10.93.3.45.79.16.84-.5.56-6.57 1.27-6.94 2.33-14.1.17-1.1-8.1 2.87-7.99 3.67Z" />
          <path d="M267.27 304.488c-.63 4.36-2.76 7.81-4.82 10.93-.3.45-.79.16-.84-.5-.56-6.57-1.27-6.94-2.33-14.1-.17-1.1 8.1 2.87 7.99 3.67Z" />
        </>
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M232.73 309.968c.63 4.36 2.76 7.81 4.82 10.93.3.45.79.16.84-.5.56-6.57 1.27-6.94 2.33-14.1.17-1.1-8.1 2.87-7.99 3.67Z" />
          <path d="M267.27 309.968c-.63 4.36-2.76 7.81-4.82 10.93-.3.45-.79.16-.84-.5-.56-6.57-1.27-6.94-2.33-14.1-.17-1.1 8.1 2.87 7.99 3.67Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <>
          <path d="M232.73 307.3c.63 4.36 2.76 7.81 4.82 10.93.3.45.79.16.84-.5.56-6.57 1.27-6.94 2.33-14.1.17-1.1-8.1 2.87-7.99 3.67Z" />
          <path d="M267.27 307.3c-.63 4.36-2.76 7.81-4.82 10.93-.3.45-.79.16-.84-.5-.56-6.57-1.27-6.94-2.33-14.1-.17-1.1 8.1 2.87 7.99 3.67Z" />
        </>
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="M232.73 302.669c.63 4.36 2.76 7.81 4.82 10.93.3.45.79.16.84-.5.56-6.57 1.27-6.94 2.33-14.1.17-1.1-8.1 2.87-7.99 3.67Z" />
          <path d="M267.27 302.669c-.63 4.36-2.76 7.81-4.82 10.93-.3.45-.79.16-.84-.5-.56-6.57-1.27-6.94-2.33-14.1-.17-1.1 8.1 2.87 7.99 3.67Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <>
          <path d="M237.094 305.585c.63 4.36 2.76 7.81 4.82 10.93.3.45.79.16.84-.5.56-6.57 1.27-6.94 2.33-14.1.17-1.1-8.1 2.87-7.99 3.67Z" />
          <path d="M262.906 305.585c-.63 4.36-2.76 7.81-4.82 10.93-.3.45-.79.16-.84-.5-.56-6.57-1.27-6.94-2.33-14.1-.17-1.1 8.1 2.87 7.99 3.67Z" />
        </>
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <>
          <path d="M232.73 307.3c.63 4.36 2.76 7.81 4.82 10.93.3.45.79.16.84-.5.56-6.57 1.27-6.94 2.33-14.1.17-1.1-8.1 2.87-7.99 3.67Z" />
          <path d="M267.27 307.3c-.63 4.36-2.76 7.81-4.82 10.93-.3.45-.79.16-.84-.5-.56-6.57-1.27-6.94-2.33-14.1-.17-1.1 8.1 2.87 7.99 3.67Z" />
        </>
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="M263.405 301.724c-.725 4.094-2.759 7.379-4.729 10.363-.3.45-.79.16-.84-.5-.226-2.655-.477-4.297-.766-5.784 2.041-1.465 4.225-2.981 6.335-4.079Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="M236.595 301.724c.725 4.094 2.759 7.379 4.729 10.363.3.45.79.16.84-.5.226-2.655.477-4.297.766-5.784-2.041-1.465-4.225-2.981-6.335-4.079Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <>
          <path d="M263.505 306.47c-.718 4.117-2.76 7.417-4.738 10.412-.3.45-.79.16-.84-.5-.424-4.981-.935-6.398-1.619-9.912h7.197Z" />
          <path d="M243.692 306.47h-7.197c.718 4.117 2.76 7.417 4.738 10.412.3.45.79.16.84-.5.424-4.981.935-6.398 1.619-9.912Z" />
        </>
      ),
    },
    mouth_shape_74Nrw: {
      base: (
        <>
          <path d="M240.019 306.47h-7.215c.71 4.14 2.76 7.454 4.746 10.462.3.45.79.16.84-.5.426-5.004.94-6.412 1.629-9.962Z" />
          <path d="M267.196 306.47c-.71 4.14-2.76 7.454-4.746 10.462-.3.45-.79.16-.84-.5-.426-5.004-.94-6.412-1.629-9.962h7.215Z" />
        </>
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <>
          <path d="M263.279 304.203c-.711 4.134-2.76 7.444-4.743 10.449-.3.45-.79.16-.84-.5-.332-3.887-.716-5.604-1.193-7.84a33.532 33.532 0 0 0 6.776-2.109Z" />
          <path d="M236.721 304.207c.713 4.133 2.76 7.442 4.743 10.445.3.45.79.16.84-.5.332-3.885.715-5.602 1.193-7.838a33.482 33.482 0 0 1-6.776-2.107Z" />
        </>
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <>
          <path d="M232.795 304.018c.701 4.166 2.761 7.496 4.755 10.516.3.45.79.16.84-.5.357-4.183.774-5.853 1.304-8.361a59.201 59.201 0 0 1-6.899-1.655Z" />
          <path d="M267.206 304.015c-.701 4.167-2.761 7.498-4.756 10.519-.3.45-.79.16-.84-.5-.357-4.184-.774-5.853-1.304-8.363a59.117 59.117 0 0 0 6.9-1.656Z" />
        </>
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <>
          <path d="M244.172 304.116a32.46 32.46 0 0 0-7.784 2.461c.642 4.328 2.76 7.759 4.81 10.863.3.45.79.16.84-.5.525-6.156 1.181-6.869 2.134-12.824Z" />
          <path d="M255.829 304.118a32.489 32.489 0 0 1 7.783 2.463c-.643 4.327-2.761 7.756-4.81 10.859-.3.45-.79.16-.84-.5-.525-6.156-1.181-6.869-2.133-12.822Z" />
        </>
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <>
          <path d="M259.603 301.403a58.015 58.015 0 0 1 7.638 1.819c-.665 4.268-2.762 7.661-4.791 10.735-.3.45-.79.16-.84-.5-.501-5.881-1.123-6.794-2.007-12.054Z" />
          <path d="M240.398 301.401a57.875 57.875 0 0 0-7.639 1.818c.664 4.27 2.761 7.664 4.791 10.738.3.45.79.16.84-.5.501-5.881 1.123-6.795 2.008-12.056Z" />
        </>
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <>
          <path d="M232.801 305.372c2.124.028 4.577.049 7.208.064-.684 3.514-1.194 4.932-1.619 9.912-.05.66-.54.95-.84.5-1.988-3.01-4.041-6.328-4.749-10.476Z" />
          <path d="M267.199 305.372c-.708 4.148-2.761 7.466-4.749 10.476-.3.45-.79.16-.84-.5-.425-4.98-.935-6.398-1.619-9.912a987.41 987.41 0 0 0 7.208-.064Z" />
        </>
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <>
          <path d="M240.405 300.871c-.889 5.298-1.512 6.2-2.015 12.096-.05.66-.54.95-.84.5-1.999-3.027-4.064-6.366-4.76-10.544 6.195-.232 5.536-1.626 7.615-2.052Z" />
          <path d="M259.595 300.871c2.079.426 1.42 1.82 7.615 2.052-.696 4.178-2.761 7.517-4.76 10.544-.3.45-.79.16-.84-.5-.503-5.896-1.126-6.798-2.015-12.096Z" />
        </>
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <>
          <path d="M240.507 305.263c-.944 5.86-1.595 6.601-2.117 12.72-.05.66-.54.95-.84.5-1.924-2.914-3.909-6.116-4.676-10.078 1.311-.211 2.393-.506 2.951-.831 1.795-1.047 3.329-1.785 4.682-2.311Z" />
          <path d="M259.493 305.263c1.353.526 2.887 1.264 4.682 2.311.558.325 1.64.62 2.951.831-.767 3.962-2.752 7.164-4.676 10.078-.3.45-.79.16-.84-.5-.522-6.119-1.173-6.86-2.117-12.72Z" />
        </>
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <>
          <path d="M253.579 304.558c3.948.632 5.919 2.161 7.822 3.381-.699 4.169-2.761 7.502-4.757 10.524-.3.45-.79.16-.84-.5-.541-6.35-1.222-6.908-2.225-13.405Z" />
          <path d="M246.421 304.558c-1.003 6.497-1.684 7.055-2.225 13.405-.05.66-.54.95-.84.5-1.996-3.022-4.058-6.355-4.757-10.524 1.903-1.22 3.874-2.749 7.822-3.381Z" />
        </>
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <>
          <path d="M240.262 305.42c-.814 4.582-1.397 5.693-1.872 11.268-.05.66-.54.95-.84.5-1.943-2.943-3.948-6.179-4.698-10.196 2.25-.597 4.882-1.245 7.41-1.572Z" />
          <path d="M259.738 305.42c2.528.327 5.161.976 7.41 1.572-.75 4.017-2.755 7.253-4.698 10.196-.3.45-.79.16-.84-.5-.475-5.575-1.058-6.686-1.872-11.268Z" />
        </>
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <>
          <path d="M240.03 303.35c-.694 3.594-1.211 4.989-1.64 10.02-.05.66-.54.95-.84.5-1.992-3.017-4.05-6.343-4.753-10.502 2.344.011 4.825 0 7.233-.018Z" />
          <path d="M259.97 303.354c2.425.017 4.906.026 7.233.011-.702 4.161-2.76 7.487-4.753 10.505-.3.45-.79.16-.84-.5-.429-5.029-.945-6.425-1.64-10.016Z" />
        </>
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <>
          <path d="M232.789 311.236c2.232.017 4.676.031 7.24.041-.694 3.586-1.21 4.984-1.639 10.011-.05.66-.54.95-.84.5-2-3.029-4.066-6.37-4.761-10.552Z" />
          <path d="M267.211 311.236c-.695 4.182-2.761 7.523-4.761 10.552-.3.45-.79.16-.84-.5-.429-5.027-.945-6.425-1.639-10.011 2.563-.01 5.008-.024 7.24-.041Z" />
        </>
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <>
          <path d="M240.275 297.124c-.82 4.645-1.407 5.738-1.885 11.342-.05.66-.54.95-.84.5-2.048-3.102-4.166-6.53-4.809-10.855 2.352-.048 5.243-.336 7.534-.987Z" />
          <path d="M259.725 297.124c2.291.651 5.182.939 7.534.987-.643 4.325-2.761 7.753-4.809 10.855-.3.45-.79.16-.84-.5-.478-5.604-1.065-6.697-1.885-11.342Z" />
        </>
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <>
          <path d="M240.454 301.192c-.915 5.565-1.552 6.39-2.064 12.396-.05.66-.54.95-.84.5-1.824-2.762-3.702-5.782-4.546-9.464.377-.056.776-.124 1.2-.203 2.511-.467 3.984-1.929 6.25-3.229Z" />
          <path d="M259.546 301.192c2.266 1.3 3.739 2.762 6.25 3.229.424.079.823.147 1.2.203-.844 3.682-2.722 6.702-4.546 9.464-.3.45-.79.16-.84-.5-.512-6.006-1.149-6.831-2.064-12.396Z" />
        </>
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M264.155 302.032c-.871 3.565-2.704 6.509-4.485 9.206-.3.45-.79.16-.84-.5-.424-4.968-.933-6.391-1.614-9.886 1.531.086 2.939.342 4.357.719.785.208 1.669.357 2.582.461Zm-21.371-1.18c-.681 3.495-1.19 4.918-1.614 9.886-.05.66-.54.95-.84.5-1.781-2.697-3.614-5.641-4.485-9.206a18.368 18.368 0 0 0 2.582-.461c1.418-.377 2.826-.633 4.357-.719Z" />
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <>
          <path d="M232.782 302.226c2.389.015 4.892.012 7.263.001-.702 3.649-1.223 5.028-1.655 10.094-.05.66-.54.95-.84.5-2.007-3.039-4.081-6.392-4.768-10.595Z" />
          <path d="M267.218 302.226c-.687 4.203-2.761 7.556-4.768 10.595-.3.45-.79.16-.84-.5-.432-5.066-.953-6.445-1.655-10.094 2.372.011 4.876.014 7.263-.001Z" />
        </>
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <>
          <path d="M240.373 307.566c-.872 5.134-1.486 6.085-1.983 11.911-.05.66-.54.95-.84.5-1.984-3.005-4.034-6.317-4.745-10.454 2.058-.519 5.068-1.324 7.568-1.957Z" />
          <path d="M259.658 307.748c2.586.696 5.545 1.519 7.445 1.841l.078.013c-.723 4.1-2.759 7.389-4.731 10.375-.3.45-.79.16-.84-.5-.491-5.757-1.097-6.753-1.952-11.729Z" />
        </>
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <>
          <path d="M232.828 304.962c2.269.027 4.732.046 7.153.058-.67 3.408-1.172 4.855-1.591 9.766-.05.66-.54.95-.84.5-1.964-2.974-3.991-6.248-4.722-10.324Z" />
          <path d="M267.172 304.962c-.731 4.076-2.758 7.35-4.722 10.324-.3.45-.79.16-.84-.5-.419-4.911-.921-6.358-1.591-9.766 2.421-.012 4.885-.031 7.153-.058Z" />
        </>
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <>
          <path d="M240.334 303.912c-.851 4.935-1.455 5.943-1.944 11.682-.05.66-.54.95-.84.5-1.996-3.023-4.058-6.356-4.757-10.527 2.456-.973 4.889-1.648 7.541-1.655Z" />
          <path d="M259.666 303.912c2.652.007 5.085.682 7.541 1.655-.699 4.171-2.761 7.504-4.757 10.527-.3.45-.79.16-.84-.5-.489-5.739-1.093-6.747-1.944-11.682Z" />
        </>
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <>
          <path d="M232.785 303.294c2.552.591 5.128 1.321 6.859 1.943-.506 2.383-.909 4.077-1.254 8.129-.05.66-.54.95-.84.5-2.003-3.034-4.073-6.381-4.765-10.572Z" />
          <path d="M267.215 303.294c-.692 4.191-2.762 7.538-4.765 10.572-.3.45-.79.16-.84-.5-.345-4.052-.748-5.746-1.254-8.129 1.732-.622 4.308-1.353 6.859-1.943Z" />
        </>
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <>
          <path d="M259.785 301.139a21.82 21.82 0 0 1 7.424.971c-.698 4.175-2.761 7.511-4.759 10.537-.3.45-.79.16-.84-.5-.466-5.468-1.036-6.641-1.825-11.008Z" />
          <path d="M232.791 302.11c.698 4.175 2.761 7.511 4.759 10.537.3.45.79.16.84-.5.466-5.468 1.036-6.641 1.825-11.008-2.777-.116-5.266.3-7.424.971Z" />
        </>
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <>
          <path d="M232.811 301.037c.254-.005.483-.005.683.002 2.154.08 3.949.446 6.361.779-.607 2.978-1.073 4.539-1.465 9.138-.05.66-.54.95-.84.5-1.979-2.997-4.022-6.299-4.739-10.419Z" />
          <path d="M260.145 301.818c2.412-.333 4.207-.699 6.361-.779.2-.007.429-.007.683-.002-.717 4.12-2.76 7.422-4.739 10.419-.3.45-.79.16-.84-.5-.392-4.599-.857-6.16-1.465-9.138Z" />
        </>
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M259.863 296.637c2.271.063 4.811.137 7.394.243-.646 4.319-2.761 7.743-4.807 10.842-.3.45-.79.16-.84-.5-.451-5.287-.998-6.559-1.747-10.585Z" />
          <path d="M240.137 296.637c-.749 4.026-1.296 5.298-1.747 10.585-.05.66-.54.95-.84.5-2.046-3.099-4.161-6.523-4.807-10.842a556.86 556.86 0 0 1 7.394-.243Z" />
        </>
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="M232.757 297.019c1.732.112 4.524.089 7.313.025-.715 3.749-1.243 5.101-1.68 10.228-.05.66-.54.95-.84.5-2.032-3.078-4.132-6.476-4.793-10.753Z" />
          <path d="M267.243 297.019c-.661 4.277-2.761 7.675-4.793 10.753-.3.45-.79.16-.84-.5-.437-5.127-.965-6.479-1.68-10.228 2.79.064 5.581.087 7.313-.025Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="M240.359 299.332c-.864 5.061-1.475 6.033-1.969 11.828-.05.66-.54.95-.84.5-2.026-3.069-4.12-6.457-4.788-10.715 1.745-.31 3.64-.738 5.356-1.252.636-.19 1.403-.302 2.241-.361Z" />
          <path d="M259.641 299.332c.838.059 1.605.171 2.241.361 1.716.514 3.611.942 5.356 1.252-.668 4.258-2.762 7.646-4.788 10.715-.3.45-.79.16-.84-.5-.494-5.795-1.105-6.767-1.969-11.828Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="M240.401 300.798c-.887 5.278-1.509 6.187-2.011 12.074-.05.66-.54.95-.84.5-2.026-3.069-4.12-6.457-4.788-10.716l.274-.071c2.249-.606 4.996-1.308 7.365-1.787Z" />
          <path d="M259.599 300.798c2.369.479 5.116 1.181 7.365 1.787l.274.071c-.668 4.259-2.762 7.647-4.788 10.716-.3.45-.79.16-.84-.5-.502-5.887-1.124-6.796-2.011-12.074Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="M232.757 304.885c1.926.048 4.61.071 7.302.08-.709 3.706-1.234 5.07-1.669 10.171-.05.66-.54.95-.84.5-2.032-3.077-4.131-6.475-4.793-10.751Z" />
          <path d="M267.243 304.885c-.662 4.276-2.761 7.674-4.793 10.751-.3.45-.79.16-.84-.5-.435-5.101-.96-6.465-1.669-10.171 2.692-.009 5.376-.032 7.302-.08Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="M240.262 299.855c-.813 4.587-1.397 5.696-1.872 11.273-.05.66-.54.95-.84.5-1.944-2.944-3.95-6.182-4.699-10.201 1.01.004 1.955-.024 2.675-.074 2.089-.143 3.45-1.432 4.542-1.489l.194-.009Z" />
          <path d="m259.738 299.855.194.009c1.092.057 2.453 1.346 4.542 1.489.72.05 1.665.078 2.675.074-.749 4.019-2.755 7.257-4.699 10.201-.3.45-.79.16-.84-.5-.475-5.577-1.059-6.686-1.872-11.273Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="M232.775 300.992a58.195 58.195 0 0 0 6.838 2.154c-.492 2.307-.885 4.013-1.223 7.982-.05.66-.54.95-.84.5-2.013-3.05-4.094-6.414-4.775-10.636Z" />
          <path d="M267.225 300.992c-.681 4.222-2.762 7.586-4.775 10.636-.3.45-.79.16-.84-.5-.338-3.969-.731-5.675-1.223-7.982a58.065 58.065 0 0 0 6.838-2.154Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="M240.26 300.945c-.812 4.575-1.395 5.688-1.87 11.259-.05.66-.54.95-.84.5-2.012-3.048-4.092-6.411-4.774-10.63 2.321-.393 4.755-.884 7.484-1.129Z" />
          <path d="M259.74 300.945c2.728.245 5.162.736 7.484 1.129-.682 4.219-2.762 7.582-4.774 10.63-.3.45-.79.16-.84-.5-.475-5.571-1.058-6.684-1.87-11.259Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="M232.82 299.482c2.334.513 4.862.768 7.088.467-.633 3.154-1.115 4.669-1.518 9.402-.05.66-.54.95-.84.5-1.971-2.985-4.006-6.271-4.73-10.369Z" />
          <path d="M267.18 299.482c-.724 4.098-2.759 7.384-4.73 10.369-.3.45-.79.16-.84-.5-.403-4.733-.885-6.248-1.518-9.402 2.226.3 4.753.046 7.088-.467Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="M240.521 301.024c-.951 5.941-1.607 6.658-2.131 12.809-.05.66-.54.95-.84.5-1.99-3.014-4.045-6.335-4.75-10.487 2.877-.451 5.339-1.764 7.721-2.822Z" />
          <path d="M259.479 301.024c2.383 1.058 4.844 2.372 7.721 2.822-.705 4.152-2.76 7.473-4.75 10.487-.3.45-.79.16-.84-.5-.524-6.151-1.18-6.868-2.131-12.809Z" />
        </>
      ),
    },
  },
};

const teeth_shape_g23ff_lower = {
  id: "teeth_shape_g23ff_lower",
  name: "Teeth 2 (Lower)",
  description: "Long pointed lower fangs",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <>
          <path d="M233.126 314.254c.894-3.457 2.684-6.327 4.424-8.963.3-.45.79-.16.84.5.451 5.292.999 6.562 1.75 10.597-2.374-.146-4.427-.568-6.07-1.578-.326-.2-.64-.385-.944-.556Z" />
          <path d="M259.855 316.417c.753-4.058 1.303-5.321 1.755-10.626.05-.66.54-.95.84-.5 1.739 2.634 3.528 5.502 4.422 8.955-.306.174-.623.361-.952.564-1.774 1.088-3.746 1.494-6.065 1.607Z" />
        </>
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <>
          <path d="M258.281 306.796c.074-.645.145-1.359.214-2.166.05-.66.54-.95.84-.5 2.029 3.073 4.127 6.467 4.791 10.735-1.731-2.402-4.028-5.838-5.845-8.069Z" />
          <path d="M235.874 314.866c.664-4.269 2.762-7.663 4.791-10.736.3-.45.79-.16.84.5.069.81.141 1.527.215 2.173-1.82 2.231-4.116 5.666-5.846 8.063Z" />
        </>
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <>
          <path d="m232.739 308.029.001-.023c.63-4.36 2.76-7.81 4.82-10.93.3-.45.79-.16.84.5.268 3.14.57 4.863.927 6.589-1.935.97-4.392 2.503-6.588 3.864Z" />
          <path d="M260.692 304.166c1.935.974 4.393 2.506 6.589 3.867l-.001-.027c-.63-4.36-2.76-7.81-4.82-10.93-.3-.45-.79-.16-.84.5-.268 3.14-.57 4.864-.928 6.59Z" />
        </>
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <>
          <path d="M232.799 311.36c.705-4.154 2.761-7.476 4.751-10.491.3-.45.79-.16.84.5.335 3.935.725 5.646 1.211 7.924-2.055.938-4.312 1.804-6.802 2.067Z" />
          <path d="M260.373 309.418c2.05.934 4.314 1.776 6.836 1.989-.698-4.176-2.761-7.512-4.759-10.538-.3-.45-.79-.16-.84.5-.342 4.007-.739 5.708-1.237 8.049Z" />
        </>
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <>
          <path d="M238.247 310.994c.63-4.36 2.76-7.81 4.82-10.93.3-.45.79-.16.84.5.56 6.57 1.27 6.94 2.33 14.1.17 1.1-8.1-2.87-7.99-3.67Z" />
          <path d="M261.753 310.994c-.63-4.36-2.76-7.81-4.82-10.93-.3-.45-.79-.16-.84.5-.56 6.57-1.27 6.94-2.33 14.1-.17 1.1 8.1-2.87 7.99-3.67Z" />
        </>
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <>
          <path d="M232.73 303.003c.63-4.36 2.76-7.81 4.82-10.93.3-.45.79-.16.84.5.56 6.57 1.27 6.94 2.33 14.1.17 1.1-8.1-2.87-7.99-3.67Z" />
          <path d="M267.27 303.003c-.63-4.36-2.76-7.81-4.82-10.93-.3-.45-.79-.16-.84.5-.56 6.57-1.27 6.94-2.33 14.1-.17 1.1 8.1-2.87 7.99-3.67Z" />
        </>
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <>
          <path d="M232.73 305.238c.63-4.36 2.76-7.81 4.82-10.93.3-.45.79-.16.84.5.56 6.57 1.27 6.94 2.33 14.1.17 1.1-8.1-2.87-7.99-3.67Z" />
          <path d="M267.27 305.238c-.63-4.36-2.76-7.81-4.82-10.93-.3-.45-.79-.16-.84.5-.56 6.57-1.27 6.94-2.33 14.1-.17 1.1 8.1-2.87 7.99-3.67Z" />
        </>
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M232.73 312.802c.63-4.36 2.76-7.81 4.82-10.93.3-.45.79-.16.84.5.56 6.57 1.27 6.94 2.33 14.1.17 1.1-8.1-2.87-7.99-3.67Z" />
          <path d="M267.27 312.802c-.63-4.36-2.76-7.81-4.82-10.93-.3-.45-.79-.16-.84.5-.56 6.57-1.27 6.94-2.33 14.1-.17 1.1 8.1-2.87 7.99-3.67Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <>
          <path d="M234.269 312.035c.63-4.36 2.76-7.81 4.82-10.93.3-.45.79-.16.84.5.56 6.57 1.27 6.94 2.33 14.1.17 1.1-8.1-2.87-7.99-3.67Z" />
          <path d="M265.731 312.035c-.63-4.36-2.76-7.81-4.82-10.93-.3-.45-.79-.16-.84.5-.56 6.57-1.27 6.94-2.33 14.1-.17 1.1 8.1-2.87 7.99-3.67Z" />
        </>
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="M232.73 311.832c.63-4.36 2.76-7.81 4.82-10.93.3-.45.79-.16.84.5.56 6.57 1.27 6.94 2.33 14.1.17 1.1-8.1-2.87-7.99-3.67Z" />
          <path d="M267.27 311.832c-.63-4.36-2.76-7.81-4.82-10.93-.3-.45-.79-.16-.84.5-.56 6.57-1.27 6.94-2.33 14.1-.17 1.1 8.1-2.87 7.99-3.67Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <>
          <path d="M237.094 312.257c.63-4.36 2.76-7.81 4.82-10.93.3-.45.79-.16.84.5.56 6.57 1.27 6.94 2.33 14.1.17 1.1-8.1-2.87-7.99-3.67Z" />
          <path d="M262.906 312.257c-.63-4.36-2.76-7.81-4.82-10.93-.3-.45-.79-.16-.84.5-.56 6.57-1.27 6.94-2.33 14.1-.17 1.1 8.1-2.87 7.99-3.67Z" />
        </>
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <>
          <path d="M232.73 309.015c.63-4.36 2.76-7.81 4.82-10.93.3-.45.79-.16.84.5.56 6.57 1.27 6.94 2.33 14.1.17 1.1-8.1-2.87-7.99-3.67Z" />
          <path d="M267.27 309.015c-.63-4.36-2.76-7.81-4.82-10.93-.3-.45-.79-.16-.84.5-.56 6.57-1.27 6.94-2.33 14.1-.17 1.1 8.1-2.87 7.99-3.67Z" />
        </>
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="M262.616 302.141c-.97-2.804-2.489-5.221-3.972-7.467-.3-.45-.79-.16-.84.5-.482 5.653-1.075 6.716-1.906 11.465 2.116-1.529 4.444-3.219 6.718-4.498Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="M237.384 302.141c.97-2.804 2.489-5.221 3.972-7.467.3-.45.79-.16.84.5.482 5.653 1.075 6.716 1.906 11.465-2.116-1.529-4.444-3.219-6.718-4.498Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <>
          <path d="M236.4 305.47c.719-4.111 2.759-7.407 4.735-10.399.3-.45.79-.16.84.5.424 4.974.934 6.394 1.617 9.899H236.4Z" />
          <path d="M256.408 305.47h7.192c-.719-4.111-2.759-7.407-4.735-10.399-.3-.45-.79-.16-.84.5-.424 4.974-.934 6.394-1.617 9.899Z" />
        </>
      ),
    },
    mouth_shape_74Nrw: {
      base: (
        <>
          <path d="M232.813 305.47c.718-4.116 2.76-7.415 4.737-10.41.3-.45.79-.16.84.5.424 4.98.935 6.398 1.619 9.91h-7.196Z" />
          <path d="M259.991 305.47h7.196c-.718-4.116-2.76-7.415-4.737-10.41-.3-.45-.79-.16-.84.5-.424 4.98-.935 6.398-1.619 9.91Z" />
        </>
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <>
          <path d="M237.099 303.281c.718-4.116 2.76-7.415 4.737-10.41.3-.45.79-.16.84.5.504 5.914 1.13 6.804 2.023 12.145a32.497 32.497 0 0 1-7.6-2.235Z" />
          <path d="M255.302 305.514a32.467 32.467 0 0 0 7.598-2.237c-.718-4.114-2.759-7.412-4.736-10.406-.3-.45-.79-.16-.84.5-.504 5.913-1.129 6.804-2.022 12.143Z" />
        </>
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <>
          <path d="M259.613 304.776a58.018 58.018 0 0 0 7.609-1.813c-.679-4.228-2.761-7.597-4.777-10.65-.3-.45-.79-.16-.84.5-.498 5.846-1.115 6.783-1.992 11.963Z" />
          <path d="M232.768 302.963c.679-4.228 2.761-7.597 4.777-10.65.3-.45.79-.16.84.5.498 5.846 1.115 6.783 1.992 11.963a57.98 57.98 0 0 1-7.609-1.813Z" />
        </>
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <>
          <path d="M256.601 303.248a33.468 33.468 0 0 1 6.83 2.155c-.685-4.211-2.762-7.57-4.772-10.615-.3-.45-.79-.16-.84.5-.337 3.956-.729 5.664-1.218 7.96Z" />
          <path d="M236.559 305.403c.685-4.211 2.762-7.57 4.772-10.615.3-.45.79-.16.84.5.337 3.956.729 5.664 1.218 7.96a33.445 33.445 0 0 0-6.83 2.155Z" />
        </>
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <>
          <path d="M232.791 302.163c.697-4.176 2.761-7.513 4.759-10.539.3-.45.79-.16.84.5.358 4.194.776 5.862 1.308 8.383a58.875 58.875 0 0 0-6.907 1.656Z" />
          <path d="M260.301 300.508c2.327.413 4.634.965 6.909 1.659-.697-4.179-2.761-7.516-4.76-10.543-.3-.45-.79-.16-.84.5-.358 4.195-.776 5.862-1.309 8.384Z" />
        </>
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <>
          <path d="M232.808 305.372c.714-4.128 2.76-7.434 4.742-10.435.3-.45.79-.16.84.5.428 5.021.944 6.421 1.636 9.999a989.486 989.486 0 0 1-7.218-.064Z" />
          <path d="M259.974 305.436c.692-3.578 1.208-4.978 1.636-9.999.05-.66.54-.95.84-.5 1.982 3.001 4.028 6.307 4.742 10.435-2.127.028-4.582.049-7.218.064Z" />
        </>
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <>
          <path d="M232.785 302.923c.691-4.193 2.761-7.541 4.765-10.576.3-.45.79-.16.84.5.351 4.122.762 5.804 1.28 8.252-1.294.564-1.547 1.625-6.885 1.824Z" />
          <path d="M260.33 301.099c.518-2.448.929-4.13 1.28-8.252.05-.66.54-.95.84-.5 2.004 3.035 4.074 6.383 4.765 10.576-5.338-.199-5.591-1.26-6.885-1.824Z" />
        </>
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <>
          <path d="M234.038 308.612c.705-4.155 2.761-7.478 4.752-10.493.3-.45.79-.16.84.5.465 5.454 1.033 6.635 1.819 10.974-3.288.005-4.678.015-7.094-.861a9.128 9.128 0 0 1-.317-.12Z" />
          <path d="M258.551 309.593c.786-4.339 1.354-5.52 1.819-10.974.05-.66.54-.95.84-.5 1.991 3.015 4.047 6.338 4.752 10.493a9.128 9.128 0 0 1-.317.12c-2.416.876-3.806.866-7.094.861Z" />
        </>
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <>
          <path d="M238.517 308.076c.663-4.273 2.762-7.669 4.792-10.745.3-.45.79-.16.84.5.542 6.353 1.224 6.909 2.227 13.413-3.033-.752-5.552-2.201-7.859-3.168Z" />
          <path d="M253.624 311.244c1.003-6.504 1.685-7.06 2.227-13.413.05-.66.54-.95.84-.5 2.03 3.076 4.129 6.472 4.792 10.745-2.307.967-4.827 2.417-7.859 3.168Z" />
        </>
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <>
          <path d="M232.762 307.016c.667-4.261 2.761-7.65 4.788-10.719.3-.45.79-.16.84.5.372 4.363.81 5.992 1.373 8.691-2.407.349-4.879.964-7.001 1.528Z" />
          <path d="M260.237 305.488c.563-2.699 1.001-4.328 1.373-8.691.05-.66.54-.95.84-.5 2.027 3.069 4.121 6.458 4.788 10.719-2.122-.564-4.594-1.179-7.001-1.528Z" />
        </>
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <>
          <path d="M232.827 303.368c.73-4.079 2.758-7.355 4.723-10.331.3-.45.79-.16.84.5.421 4.934.926 6.371 1.6 9.814-2.385.017-4.84.028-7.163.017Z" />
          <path d="M260.009 303.355c.675-3.445 1.18-4.882 1.601-9.818.05-.66.54-.95.84-.5 1.964 2.975 3.992 6.25 4.723 10.328-2.307.015-4.763.006-7.164-.01Z" />
        </>
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <>
          <path d="M232.814 311.249c.719-4.113 2.76-7.41 4.736-10.403.3-.45.79-.16.84.5.425 4.988.937 6.403 1.623 9.928a2684.39 2684.39 0 0 1-7.199-.025Z" />
          <path d="M259.987 311.274c.686-3.525 1.198-4.94 1.623-9.928.05-.66.54-.95.84-.5 1.976 2.993 4.017 6.29 4.736 10.403-2.174.011-4.612.019-7.199.025Z" />
        </>
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <>
          <path d="M232.78 298.11c.687-4.207 2.762-7.562 4.77-10.604.3-.45.79-.16.84.5.396 4.645.867 6.191 1.483 9.227-2.208.571-4.884.829-7.093.877Z" />
          <path d="M260.127 297.233c.616-3.036 1.087-4.582 1.483-9.227.05-.66.54-.95.84-.5 2.008 3.042 4.083 6.397 4.77 10.604-2.209-.048-4.885-.306-7.093-.877Z" />
        </>
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <>
          <path d="M235.895 304.016c.664-4.268 2.761-7.661 4.79-10.734.3-.45.79-.16.84.5.42 4.923.923 6.365 1.595 9.789-1.61.225-3.17.445-4.752.467-.849.011-1.679 0-2.473-.022Z" />
          <path d="M256.88 303.571c.672-3.424 1.175-4.866 1.595-9.789.05-.66.54-.95.84-.5 2.029 3.073 4.126 6.466 4.79 10.734a60.87 60.87 0 0 1-2.473.022c-1.582-.022-3.142-.242-4.752-.467Z" />
        </>
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M235.254 302.093c.745-4.033 2.757-7.279 4.705-10.231.3-.45.79-.16.84.5.422 4.952.93 6.382 1.608 9.851-1.401-.16-3.232-.272-5.71-.293-.47.071-.954.128-1.443.173Zm22.339.12c.678-3.469 1.186-4.899 1.608-9.851.05-.66.54-.95.84-.5 1.948 2.952 3.96 6.198 4.705 10.231a25.08 25.08 0 0 1-1.443-.173c-2.478.021-4.309.133-5.71.293Z" />
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <>
          <path d="M232.791 302.226c.697-4.177 2.761-7.513 4.759-10.539.3-.45.79-.16.84.5.43 5.041.948 6.432 1.644 10.04-2.365.011-4.861.014-7.243-.001Z" />
          <path d="M259.966 302.227c.696-3.608 1.214-4.999 1.644-10.04.05-.66.54-.95.84-.5 1.998 3.026 4.062 6.362 4.759 10.539-2.382.015-4.879.012-7.243.001Z" />
        </>
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <>
          <path d="M233.295 310.223c.938-3.186 2.619-5.873 4.255-8.351.3-.45.79-.16.84.5.354 4.151.767 5.827 1.291 8.304l-.201-.006c-2.108-.056-4.217-.248-6.185-.447Z" />
          <path d="M259.69 313.915c2.318.198 4.339-.012 6.033-1.007.462-.271.944-.529 1.438-.771-.74-4.049-2.757-7.306-4.711-10.265-.3-.45-.79-.16-.84.5-.485 5.684-1.081 6.727-1.92 11.543Z" />
        </>
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <>
          <path d="M232.776 304.962c.683-4.217 2.762-7.58 4.774-10.627.3-.45.79-.16.84.5.435 5.108.961 6.469 1.672 10.186-2.465-.013-4.977-.032-7.286-.059Z" />
          <path d="M259.938 305.021c.711-3.717 1.237-5.078 1.672-10.186.05-.66.54-.95.84-.5 2.012 3.047 4.091 6.41 4.774 10.627-2.309.027-4.82.046-7.286.059Z" />
        </>
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <>
          <path d="M232.853 305.543c.752-4.012 2.756-7.245 4.697-10.186.3-.45.79-.16.84.5.343 4.021.741 5.719 1.242 8.072-2.361.111-4.561.74-6.779 1.614Z" />
          <path d="M260.368 303.929c.501-2.353.899-4.051 1.242-8.072.05-.66.54-.95.84-.5 1.941 2.941 3.945 6.174 4.697 10.186-2.218-.874-4.418-1.503-6.779-1.614Z" />
        </>
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <>
          <path d="M232.95 303.332c.816-3.791 2.737-6.883 4.6-9.705.3-.45.79-.16.84.5.478 5.606 1.065 6.698 1.885 11.346a.995.995 0 0 1-.058-.022c-1.667-.65-4.471-1.465-7.267-2.119Z" />
          <path d="M259.725 305.473c.82-4.648 1.407-5.74 1.885-11.346.05-.66.54-.95.84-.5 1.863 2.822 3.784 5.914 4.6 9.705-2.796.654-5.6 1.469-7.267 2.119a.995.995 0 0 1-.058.022Z" />
        </>
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <>
          <path d="M232.792 302.11c.698-4.174 2.761-7.509 4.758-10.534.3-.45.79-.16.84.5.388 4.553.848 6.129 1.447 9.05a21.816 21.816 0 0 0-7.045.984Z" />
          <path d="M260.163 301.126c.599-2.921 1.059-4.497 1.447-9.05.05-.66.54-.95.84-.5 1.997 3.025 4.06 6.36 4.758 10.534a21.792 21.792 0 0 0-7.045-.984Z" />
        </>
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <>
          <path d="M259.773 301.868c.795-4.421 1.369-5.579 1.837-11.074.05-.66.54-.95.84-.5 2.031 3.076 4.129 6.472 4.792 10.744a12.775 12.775 0 0 0-.736.001c-2.263.084-4.13.483-6.733.829Z" />
          <path d="M232.758 301.038c.663-4.272 2.761-7.668 4.792-10.744.3-.45.79-.16.84.5.468 5.495 1.042 6.653 1.837 11.074-2.603-.346-4.47-.745-6.733-.829a12.775 12.775 0 0 0-.736-.001Z" />
        </>
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M232.781 310.229c.688-4.204 2.762-7.557 4.769-10.597.3-.45.79-.16.84.5.488 5.728 1.09 6.743 1.939 11.654-2.451-.236-5.062-.691-7.548-1.557Z" />
          <path d="M259.671 311.786c.849-4.911 1.451-5.926 1.939-11.654.05-.66.54-.95.84-.5 2.007 3.04 4.081 6.393 4.769 10.597-2.485.866-5.097 1.321-7.548 1.557Z" />
        </>
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="M232.825 297.024c.728-4.084 2.759-7.364 4.725-10.342.3-.45.79-.16.84.5.423 4.958.931 6.385 1.61 9.863-2.73.063-5.453.085-7.175-.021Z" />
          <path d="M260 297.045c.679-3.478 1.187-4.905 1.61-9.863.05-.66.54-.95.84-.5 1.966 2.978 3.997 6.258 4.725 10.342-1.722.106-4.445.084-7.175.021Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="M232.875 302.251c.768-3.96 2.752-7.16 4.675-10.073.3-.45.79-.16.84.5.531 6.227 1.196 6.884 2.167 13.035-1.559-.727-4.74-2.334-7.682-3.462Z" />
          <path d="M259.443 305.713c.971-6.151 1.636-6.808 2.167-13.035.05-.66.54-.95.84-.5 1.923 2.913 3.907 6.113 4.675 10.073-2.942 1.128-6.124 2.735-7.682 3.462Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="M238.012 301.476c.765-3.968 2.753-7.174 4.679-10.091.3-.45.79-.16.84.5.53 6.218 1.194 6.883 2.162 13.004-3.672-.651-4.921-1.977-5.985-2.547-.635-.34-1.152-.644-1.696-.866Z" />
          <path d="M254.307 304.889c.968-6.121 1.632-6.786 2.162-13.004.05-.66.54-.95.84-.5 1.926 2.917 3.914 6.123 4.679 10.091-.544.222-1.061.526-1.696.866-1.064.57-2.313 1.896-5.985 2.547Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="M232.768 304.886c.673-4.243 2.761-7.622 4.782-10.682.3-.45.79-.16.84.5.438 5.142.969 6.486 1.687 10.261-2.694-.009-5.38-.032-7.309-.079Z" />
          <path d="M259.923 304.965c.718-3.775 1.249-5.119 1.687-10.261.05-.66.54-.95.84-.5 2.021 3.06 4.109 6.439 4.782 10.682-1.929.047-4.615.07-7.309.079Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="M232.758 301.427c.662-4.273 2.761-7.669 4.792-10.745.3-.45.79-.16.84.5.373 4.382.814 6.006 1.38 8.725-1.042.235-2.341 1.316-4.244 1.446-.741.051-1.723.08-2.768.074Z" />
          <path d="M260.23 299.907c.566-2.719 1.007-4.343 1.38-8.725.05-.66.54-.95.84-.5 2.031 3.076 4.13 6.472 4.792 10.745a39.006 39.006 0 0 1-2.768-.074c-1.903-.13-3.202-1.211-4.244-1.446Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="M232.785 300.996c.693-4.192 2.762-7.538 4.765-10.572.3-.45.79-.16.84.5.513 6.014 1.151 6.833 2.068 12.421-2.168-.492-4.739-1.231-7.673-2.349Z" />
          <path d="M259.542 303.345c.917-5.588 1.555-6.407 2.068-12.421.05-.66.54-.95.84-.5 2.003 3.034 4.072 6.38 4.765 10.572-2.934 1.118-5.506 1.857-7.673 2.349Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="M232.781 302.074c.686-4.206 2.761-7.561 4.769-10.602.3-.45.79-.16.84.5.387 4.535.844 6.116 1.44 9.014-2.554.256-4.852.715-7.049 1.088Z" />
          <path d="M260.17 300.986c.596-2.898 1.053-4.479 1.44-9.014.05-.66.54-.95.84-.5 2.008 3.041 4.083 6.396 4.769 10.602-2.196-.373-4.495-.832-7.049-1.088Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="m232.729 299.462.001-.019c.63-4.36 2.76-7.81 4.82-10.93.3-.45.79-.16.84.5.463 5.435 1.029 6.627 1.811 10.93l-.107-.02c-2.3.345-4.94.08-7.365-.461Z" />
          <path d="M259.799 299.943c.782-4.303 1.348-5.495 1.811-10.93.05-.66.54-.95.84-.5 2.06 3.12 4.19 6.57 4.82 10.93a.115.115 0 0 1 .001.019c-2.424.541-5.066.806-7.365.461l-.107.02Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="M232.747 303.854c.651-4.305 2.762-7.721 4.803-10.813.3-.45.79-.16.84.5.334 3.922.722 5.635 1.206 7.902-2.113.97-4.32 2.025-6.849 2.411Z" />
          <path d="M260.404 301.443c.484-2.267.872-3.98 1.206-7.902.05-.66.54-.95.84-.5 2.041 3.092 4.152 6.508 4.803 10.813-2.529-.386-4.735-1.441-6.849-2.411Z" />
        </>
      ),
    },
  },
};

const teeth_shape_K7ZtymTwRY_upper = {
  id: "teeth_shape_K7ZtymTwRY_upper",
  name: "Teeth 3 (Upper)",
  description: "Straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <path d="M236.139 305.942v2.966a1.01 1.01 0 0 0 1.011 1.011h4.155a1.01 1.01 0 0 0 1.011-1.011v-6.153c0-.165-.015-.327-.044-.484-.946-.04-1.848.209-2.975 1.049-.947.702-2.029 1.616-3.158 2.622Zm21.589-3.67c.949-.043 1.854.205 2.985 1.048.942.703 2.021 1.618 3.148 2.624v2.964a1.01 1.01 0 0 1-1.011 1.011h-4.155a1.01 1.01 0 0 1-1.011-1.011v-6.153c0-.165.015-.327.044-.483Zm-14.66.092c-.137.375-.21.775-.21 1.182v5.362a1.01 1.01 0 0 0 1.011 1.011h4.855a1.01 1.01 0 0 0 1.011-1.011v-5.365c-3.231-.104-5.096-.899-6.667-1.179Zm13.864.002c.137.374.21.773.21 1.18v5.362c0 .559-.453 1.011-1.012 1.011h-4.854a1.011 1.011 0 0 1-1.012-1.011v-5.365c3.232-.103 5.099-.896 6.668-1.177Zm-21.284 4.016a232.049 232.049 0 0 0-3.72 3.459c.215.052.437.078.662.078h.288a2.77 2.77 0 0 0 2.77-2.77v-.767Zm28.704.003c1.235 1.117 2.511 2.321 3.715 3.457a2.83 2.83 0 0 1-.657.077h-.288a2.77 2.77 0 0 1-2.77-2.77v-.764Z" />
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <path d="M249.736 303.056v4.616c0 .558-.453 1.011-1.012 1.011h-4.855a1.01 1.01 0 0 1-.689-.271c1.051-2.085 3.502-5.113 6.556-5.356Zm.528-.001c3.055.236 5.509 3.263 6.563 5.35a1.005 1.005 0 0 1-.696.278h-4.855a1.012 1.012 0 0 1-1.012-1.011v-4.617Z" />
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M242.654 310.563v-1.646a80.495 80.495 0 0 1 6.878-.232v2.381c-2.422-.077-4.731-.282-6.878-.503Zm7.407.503v-2.379a80.845 80.845 0 0 1 6.877.199v1.677c-2.145.221-4.455.426-6.877.503Zm7.419-.56v-1.579c2.422.194 4.468.48 6.177.773v.202c-1.825.118-3.904.365-6.177.604Zm-15.368 0c-2.273-.239-4.352-.486-6.177-.604v-.131a68.537 68.537 0 0 1 6.177-.81v1.545Z" />
        </>
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="m257.141 304.565.001.088v5.354c-2.229.314-4.489.48-6.752.497a1.01 1.01 0 0 1-.126-.489v-5.108a93.541 93.541 0 0 0 6.877-.342Zm-14.282-.002-.001.09v5.352c2.23.315 4.49.481 6.752.498.08-.144.126-.311.126-.488v-5.109a93.49 93.49 0 0 1-6.877-.343Zm20.882-.81c.079.291.12.593.12.9v3.92a48.2 48.2 0 0 1-6.177 1.354v-5.411a95.431 95.431 0 0 0 6.057-.763Zm-27.481-.003c-.08.292-.121.595-.121.903v3.917a48.451 48.451 0 0 0 6.177 1.356v-5.412a94.642 94.642 0 0 1-6.056-.764Zm33.992-1.227v3.172c0 .17-.015.339-.045.505a43.129 43.129 0 0 1-4.657 1.848 2.767 2.767 0 0 1-1.198-2.281v-2.113a128.65 128.65 0 0 0 5.528-1.044l.372-.087Zm-40.504-.011v3.183c0 .169.015.336.044.5a43.293 43.293 0 0 0 4.661 1.851 2.767 2.767 0 0 0 1.195-2.279v-2.116c-2.002-.33-3.822-.692-5.478-1.041l-.422-.098Zm45.448-1.212c.092.266.143.551.144.849l.004.877a6.518 6.518 0 0 1-1.514 1.254 39.41 39.41 0 0 1-2.898 1.573l-.015-3.487a169.84 169.84 0 0 0 4.279-1.066Zm-50.39-.006a2.655 2.655 0 0 0-.146.855l-.004.865c.41.466.924.905 1.524 1.266.936.56 1.899 1.083 2.888 1.568l.015-3.493a168.146 168.146 0 0 1-4.277-1.061Zm51.613-.328c-.051.358-.195.751-.42 1.148l-.004-1.03.424-.118Zm-52.828-.002c.05.353.19.74.41 1.132l.004-1.017-.414-.115Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <path d="M242.316 303.274v3.427a1.01 1.01 0 0 1-1.011 1.011h-3.179a14.04 14.04 0 0 1 .979-2.101c.159-.284.365-.555.613-.814a16.823 16.823 0 0 1 2.598-1.523Zm15.368.001c.97.46 1.839.979 2.595 1.522.248.259.454.53.613.814.466.833.77 1.525.978 2.101h-3.175a1.01 1.01 0 0 1-1.011-1.011v-3.426Zm-7.948-1.771v5.197c0 .559-.453 1.011-1.012 1.011h-4.855a1.01 1.01 0 0 1-1.011-1.011v-3.673a18.81 18.81 0 0 1 6.878-1.524Zm.528 0a18.78 18.78 0 0 1 6.878 1.526v3.671a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.012-1.011v-5.197Z" />
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <path d="M249.736 303.762v5.67c0 .1-.015.196-.042.286a40.506 40.506 0 0 1-1.211-.015c-2.32-.092-4.135-.115-5.614-.12a1.073 1.073 0 0 1-.011-.151v-4.941c2.564-.655 4.471-.551 6.574-.72.057-.004.17-.008.304-.009Zm.528 0c.134.001.247.005.304.009 2.103.169 4.01.065 6.574.72v4.941c0 .051-.004.102-.011.151-1.479.005-3.294.028-5.614.12-.22.009-.695.014-1.211.015a.994.994 0 0 1-.042-.286v-5.67Zm-7.948.876v4.794c0 .051-.004.101-.011.15-2.947-.002-4.477.048-6.166-.329v-1.872c2.497-1.43 4.482-2.255 6.177-2.743Zm15.368 0c1.695.488 3.68 1.313 6.177 2.743v1.872c-1.689.377-3.22.327-6.166.329a1.06 1.06 0 0 1-.011-.15v-4.794Zm-22.036 3.018v.018c0 .493-.129.956-.355 1.358a14.92 14.92 0 0 1-1.94-.721c.972-.18 1.783-.406 2.295-.655Zm28.704 0c.511.249 1.322.475 2.295.655a14.92 14.92 0 0 1-1.94.721 2.762 2.762 0 0 1-.355-1.358v-.018Z" />
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <path d="M242.885 310.049a.982.982 0 0 1-.027-.232v-4.293c1.679-.676 3.831-1.182 6.878-1.253v5.546c0 .558-.453 1.011-1.012 1.011h-3.785c-.711-.234-1.394-.5-2.054-.779Zm12.176.779h-3.785a1.012 1.012 0 0 1-1.012-1.011v-5.546c3.047.071 5.199.577 6.878 1.253v4.293c0 .08-.009.158-.027.232-.66.279-1.343.545-2.054.779Zm2.623-1.024v-4.049c1.58.712 2.734 1.564 3.884 2.29l-.004-.003c-1.22.506-2.498 1.152-3.88 1.762Zm-15.368 0c-1.382-.61-2.66-1.256-3.88-1.762 1.149-.725 2.302-1.576 3.88-2.287v4.049Zm-5.98-.668-.197.071.197-.071Zm26.207-.516a9.064 9.064 0 0 1 0 0Zm-26.11.478-.045.018-.048.018.093-.036Zm.052-.02-.044.017.044-.017Zm.043-.018-.041.017.041-.017Zm.052-.021-.04.017a.684.684 0 0 1 .04-.017Zm.042-.017-.032.013.032-.013Zm.051-.022-.035.015.035-.015Zm.042-.018-.024.01.024-.01Zm.045-.02-.025.011.025-.011Zm.047-.021-.017.008.017-.008Zm.034-.015-.01.004.01-.004Zm.057-.027-.011.005.011-.005Zm.09-.042-.004.003.004-.003Zm25.503-.265.028.015-.028-.015Zm-.049-.027.02.011-.021-.012-.022-.012.023.013Zm-.077-.043.027.015-.027-.015Zm-.052-.029.026.015-.026-.015Zm-.067-.038.036.02-.018-.01-.018-.01Zm-.057-.033.024.014-.024-.014Zm-.052-.03.023.013-.023-.013Zm-.057-.033.023.013-.023-.013Zm-.065-.039.021.012-.021-.012Zm-.054-.032.022.013-.022-.013Zm-.051-.032.015.01-.015-.01Zm-.068-.04.017.01-.017-.01Zm-.054-.034.017.011-.017-.011Zm-.053-.032.012.007-.012-.007Zm-.063-.039.01.006-.01-.006Zm-.057-.036.013.008-.013-.008Z" />
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <path d="M242.316 300.271v3.412c-1.328.181-2.63.337-3.948.355a58.5 58.5 0 0 1-2.229-.016v-.215c1.992-.906 3.506-2.429 6.177-3.536Zm15.368 0c2.671 1.107 4.185 2.63 6.177 3.536v.215a58.5 58.5 0 0 1-2.229.016c-1.318-.018-2.62-.174-3.948-.355v-3.412Zm-7.42-1.353c2.995.051 5.168.507 6.878 1.141v3.549c-2.092-.292-4.262-.608-6.672-.518l-.206.003v-4.175Zm-.528 0v4.175l-.206-.003c-2.41-.09-4.58.226-6.672.518v-3.549c1.71-.633 3.882-1.09 6.878-1.141Z" />
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M236.697 301.92a16.165 16.165 0 0 0 1.73-.349 21.684 21.684 0 0 1 3.889-.687v1.319c-1.389-.155-3.194-.262-5.619-.283Zm6.161.349v-.93c0-.166.012-.33.036-.493 1.645-.083 3.436.029 5.534.396.48.083.915.13 1.308.144v2.565c-2.668-.2-2.845-1.155-6.878-1.682Zm7.406 1.682v-2.565a9.755 9.755 0 0 0 1.308-.144c2.098-.367 3.889-.479 5.534-.396.024.163.036.327.036.493v.93c-4.033.527-4.21 1.482-6.878 1.682Zm7.42-1.748v-1.319c1.352.108 2.616.349 3.889.687.537.143 1.121.257 1.73.349-2.425.021-4.23.128-5.619.283Z" />
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <path d="M257.692 307.23c1.818.463 4.116 1.114 6.169 1.636v4.721c0 .029-.001.057-.003.085-1.789.466-3.853.361-6.171-.009a.947.947 0 0 1-.003-.076v-6.153c0-.069.002-.137.008-.204Zm-7.428-.49c.888-.001 1.662-.003 1.751-.007 1.46-.064 2.901-.152 4.34.173.183.041.375.086.575.135.139.375.212.776.212 1.184v5.347c-2.099-.364-4.397-.918-6.878-1.446v-5.386Zm14.088 2.249a39.23 39.23 0 0 0 2.751.6c1.157.196 2.206.375 3.149.533v.752a24.148 24.148 0 0 0-4.529 2.034 6.933 6.933 0 0 1-.911.449 2.754 2.754 0 0 1-.46-1.529v-2.839Zm-21.227-2.09c1.429-.316 2.861-.23 4.31-.166.108.005 1.206.007 2.301.007v5.274c-2.161-.451-4.457-.871-6.878-1.121v-2.668c0-.46.093-.91.267-1.326Zm-.831.193c.015.112.022.226.022.342v3.406a43.373 43.373 0 0 0-2.836-.17 64.215 64.215 0 0 1-3.341-.185v-1.823c2.132-.557 4.444-1.162 6.155-1.57Zm-6.646 1.698v1.654c-1.659-.14-3.262-.318-4.733-.464.386-.088.783-.182 1.188-.281.943-.232 2.194-.557 3.545-.909Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M241.426 296.601a2.63 2.63 0 0 1 .89 1.975v6.153c0 .558-.453 1.011-1.011 1.011h-4.155a1.011 1.011 0 0 1-1.011-1.011v-5.362a3.429 3.429 0 0 1 1.256-2.651c1.419-.043 2.778-.08 4.031-.115Zm17.148 0c1.253.035 2.612.072 4.031.115a3.429 3.429 0 0 1 1.256 2.651v5.362c0 .558-.453 1.011-1.011 1.011h-4.155a1.011 1.011 0 0 1-1.011-1.011v-6.153c0-.786.344-1.492.89-1.975Zm-9.325-.117c.301.316.487.743.487 1.214v7.031c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362a3.426 3.426 0 0 1 1.526-2.851l.19-.006c.485-.015 2.501-.024 4.675-.026Zm1.502 0c2.174.002 4.19.011 4.675.026l.19.006a3.426 3.426 0 0 1 1.526 2.851v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-7.031c0-.471.186-.898.487-1.214Zm-16.227.327a2.553 2.553 0 0 1 1.124 2.117v4.042a2.77 2.77 0 0 1-2.77 2.77h-.288a2.842 2.842 0 0 1-2.842-2.842v-3.178a3.342 3.342 0 0 1 1.473-2.773c1.107-.051 2.214-.096 3.303-.136Zm30.952 0c1.089.04 2.196.084 3.303.136a3.342 3.342 0 0 1 1.473 2.773v3.178a2.842 2.842 0 0 1-2.842 2.842h-.288a2.77 2.77 0 0 1-2.77-2.77v-4.042a2.553 2.553 0 0 1 1.124-2.117Zm-37.101.283a1.792 1.792 0 0 1 .713 1.439l-.021 4.711a1.39 1.39 0 0 1-1.39 1.384h-.003a3.026 3.026 0 0 1-3.024-3.038l.01-2.238a2.63 2.63 0 0 1 1.037-2.082c.875-.065 1.771-.123 2.678-.176Zm43.25 0c.906.053 1.803.111 2.678.176a2.63 2.63 0 0 1 1.037 2.082l.01 2.238a3.027 3.027 0 0 1-3.024 3.038h-.003a1.39 1.39 0 0 1-1.39-1.384l-.021-4.711a1.792 1.792 0 0 1 .713-1.439Zm-47.618 2.512a1.756 1.756 0 0 0-1.754-1.762h-.003a1.47 1.47 0 0 0-1.47 1.464l-.012 2.706a1.583 1.583 0 0 0 1.584 1.591h.003c.904 0 1.638-.731 1.642-1.635l.01-2.364Zm51.986 0a1.756 1.756 0 0 1 1.754-1.762h.003a1.47 1.47 0 0 1 1.47 1.464l.012 2.706a1.583 1.583 0 0 1-1.584 1.591h-.003a1.642 1.642 0 0 1-1.642-1.635l-.01-2.364Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="M242.933 299.279c2.107.035 4.28.203 5.798.178.216-.004.596-.006 1.005-.007v5.905c0 .559-.453 1.012-1.012 1.012h-4.855a1.012 1.012 0 0 1-1.011-1.012v-5.361c0-.242.026-.482.075-.715Zm14.134 0c.049.233.075.473.075.715v5.361c0 .559-.453 1.012-1.011 1.012h-4.855a1.012 1.012 0 0 1-1.012-1.012v-5.905c.409.001.789.003 1.005.007 1.518.025 3.691-.143 5.798-.178Zm.617-.006c1.585-.004 3.09.088 4.198.42.64.192 1.306.371 1.979.538v3.397c-2.281 1.032-4.276 2.05-5.05 2.366a4.064 4.064 0 0 1-.707.182 1.01 1.01 0 0 1-.42-.821v-6.082Zm-21.545.958a42.77 42.77 0 0 0 1.979-.538c1.108-.332 2.613-.424 4.198-.42v6.082c0 .338-.165.637-.42.821a4.064 4.064 0 0 1-.707-.182c-.774-.316-2.769-1.334-5.05-2.366v-3.397Zm28.213.118c1.956.462 3.942.806 5.545.99-1.66.426-3.674 1.236-5.545 2.069v-3.059Zm-28.704 0v3.059c-1.871-.833-3.885-1.643-5.545-2.069a49.833 49.833 0 0 0 5.545-.99Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="M242.858 304.111v-3.701c3.416-.291 2.738 1.294 6.64 1.572.006.008.104.014.238.017v3.256c-3.302-.071-5.418-.565-6.878-1.144Zm7.406 1.144v-3.256c.134-.003.232-.009.238-.017 3.902-.278 3.224-1.863 6.64-1.572v3.701c-1.46.579-3.576 1.073-6.878 1.144Zm7.42-1.375v-3.413c.372.047.786.113 1.251.201 1.034.196 2.162.443 3.304.713-.64.225-1.215.569-1.947.961-.64.343-1.347.959-2.608 1.538Zm-15.368 0c-1.261-.579-1.968-1.195-2.608-1.538-.732-.392-1.307-.736-1.947-.961 1.142-.27 2.27-.517 3.304-.713.465-.088.879-.154 1.251-.201v3.413Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="M242.858 299.101c4.518-2.047 4.604-1.235 6.878-1.246v2.81c-1.308.002-2.564.011-3.023.028a39.572 39.572 0 0 1-3.855-.318v-1.274Zm7.406-1.246c2.273.011 2.36-.801 6.878 1.246v1.274a39.572 39.572 0 0 1-3.855.318 122.07 122.07 0 0 0-3.023-.028v-2.81Zm7.42 1.484a9.952 9.952 0 0 0 2.222.584c-.72.14-1.461.268-2.222.377v-.961Zm-15.368 0v.961a45.246 45.246 0 0 1-2.222-.377 9.952 9.952 0 0 0 2.222-.584Z" />
        </>
      ),
    },
  },
};

const teeth_shape_K7ZtymTwRY_lower = {
  id: "teeth_shape_K7ZtymTwRY_lower",
  name: "Teeth 3 (Upper)",
  description: "Straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <path d="M237.821 316.129v-4.602a1.124 1.124 0 0 1 1.123-1.123h3.294a1.12 1.12 0 0 1 1.123 1.123v4.922c-2.027.025-3.888-.034-5.54-.32Zm18.818.303v-4.905a1.12 1.12 0 0 1 1.123-1.123h3.294a1.122 1.122 0 0 1 1.123 1.123v4.645c-1.642.288-3.464.312-5.54.26Zm-12.861.01v-4.915a1.124 1.124 0 0 1 1.123-1.123h3.711a1.124 1.124 0 0 1 1.124 1.123v4.74c-2.101.049-4.096.143-5.958.175Zm6.486-.186v-4.729a1.124 1.124 0 0 1 1.124-1.123h3.711a1.123 1.123 0 0 1 1.123 1.123v4.894c-1.692-.049-3.55-.138-5.612-.171l-.346.006Zm12.298-.157v-3.935a1.757 1.757 0 0 1 1.76-1.76h1.076a1.758 1.758 0 0 1 1.76 1.76v1.922c-.393.217-.806.457-1.238.724-1.041.639-2.151 1.042-3.358 1.289Zm-29.72-2.003v-1.932a1.761 1.761 0 0 1 1.76-1.76h1.076a1.758 1.758 0 0 1 1.76 1.76v3.894c-1.257-.25-2.386-.644-3.368-1.248-.429-.263-.838-.5-1.228-.714Zm-3.859-1.503c.663-.603 1.405-1.299 2.192-2.041a2.296 2.296 0 0 1 1.389 2.11v1.284c-1.474-.783-2.661-1.211-3.577-1.357l-.004.004Zm38.453 1.343v-1.274c0-.945.57-1.756 1.384-2.108.78.735 1.516 1.425 2.175 2.024a8.427 8.427 0 0 0-1.633.464c-.651.25-1.28.552-1.896.878l-.03.016Z" />
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_VCgvh: {
      base: (
        <path d="M250.264 312.318v-2.765c0-.298.119-.584.329-.795.211-.21.497-.329.795-.329h3.711c.297 0 .583.119.794.329.21.211.329.497.329.795v1.452c-2.348.525-4.558 1.144-5.958 1.313Zm-6.486-1.316v-1.449c0-.298.119-.584.329-.795.211-.21.497-.329.794-.329h3.711c.298 0 .584.119.795.329.21.211.329.497.329.795v2.763c-1.405-.172-3.613-.79-5.958-1.314Zm12.861-.089v-1.36a1.124 1.124 0 0 1 1.123-1.124h3.294a1.126 1.126 0 0 1 1.123 1.124v.644l-.195-.005c-1.596-.055-3.368.257-5.067.661l-.278.06Zm-18.818-.716v-.644a1.126 1.126 0 0 1 1.123-1.124h3.294a1.124 1.124 0 0 1 1.123 1.124v1.357l-.264-.057c-1.698-.404-3.471-.716-5.067-.661l-.209.005Z" />
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M242.316 300.548a2.634 2.634 0 0 0-2.634-2.635h-.117a3.426 3.426 0 0 0-3.426 3.426v5.362a1.01 1.01 0 0 0 1.011 1.011h4.155a1.01 1.01 0 0 0 1.011-1.011v-6.153Zm15.368 0a2.634 2.634 0 0 1 2.634-2.635h.117a3.426 3.426 0 0 1 3.426 3.426v5.362a1.01 1.01 0 0 1-1.011 1.011h-4.155a1.01 1.01 0 0 1-1.011-1.011v-6.153Zm-7.948-.878c0-.97-.787-1.757-1.758-1.757h-1.694c-.909 0-1.78.361-2.422 1.004a3.423 3.423 0 0 0-1.004 2.422v5.362a1.01 1.01 0 0 0 1.011 1.011h4.855c.559 0 1.012-.452 1.012-1.011v-7.031Zm.528 0c0-.97.787-1.757 1.758-1.757h1.694c.909 0 1.78.361 2.422 1.004a3.423 3.423 0 0 1 1.004 2.422v5.362a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.012-1.011v-7.031Zm-14.616 1.23a2.555 2.555 0 0 0-2.554-2.554 3.346 3.346 0 0 0-3.346 3.346v3.178a2.842 2.842 0 0 0 2.842 2.842h.288a2.77 2.77 0 0 0 2.77-2.77V300.9Zm28.704 0a2.555 2.555 0 0 1 2.554-2.554 3.346 3.346 0 0 1 3.346 3.346v3.178a2.842 2.842 0 0 1-2.842 2.842h-.288a2.77 2.77 0 0 1-2.77-2.77V300.9Zm-35.264-.394a1.794 1.794 0 0 0-1.794-1.802h-.002a2.632 2.632 0 0 0-2.632 2.62l-.01 2.239a3.025 3.025 0 0 0 3.024 3.037h.003a1.39 1.39 0 0 0 1.39-1.384l.021-4.71Zm41.824 0a1.794 1.794 0 0 1 1.794-1.802h.002a2.632 2.632 0 0 1 2.632 2.62l.01 2.239a3.025 3.025 0 0 1-3.024 3.037h-.003a1.39 1.39 0 0 1-1.39-1.384l-.021-4.71Zm-46.905 1.072a1.754 1.754 0 0 0-1.754-1.762h-.003c-.809 0-1.466.655-1.47 1.464l-.012 2.706a1.587 1.587 0 0 0 1.584 1.591h.003c.904 0 1.638-.731 1.642-1.635l.01-2.364Zm51.986 0a1.754 1.754 0 0 1 1.754-1.762h.003c.809 0 1.466.655 1.47 1.464l.012 2.706a1.587 1.587 0 0 1-1.584 1.591h-.003a1.642 1.642 0 0 1-1.642-1.635l-.01-2.364Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M243.778 311.997v-4.285a1.126 1.126 0 0 1 1.123-1.123h3.711a1.124 1.124 0 0 1 1.124 1.123v4.306c-1.334-.03-3.456.054-5.958-.021Zm6.486.021v-4.306a1.126 1.126 0 0 1 1.124-1.123h3.711a1.123 1.123 0 0 1 1.123 1.123v4.285c-2.502.075-4.624-.009-5.958.021Zm-12.443-.55v-3.756a1.126 1.126 0 0 1 1.123-1.123h3.294a1.12 1.12 0 0 1 1.123 1.123v4.271c-1.724-.064-3.614-.207-5.54-.515Zm18.818.515v-4.271a1.122 1.122 0 0 1 1.123-1.123h3.294a1.122 1.122 0 0 1 1.123 1.123v3.756c-1.927.308-3.816.451-5.54.515Zm5.923-.579v-3.055a1.76 1.76 0 0 1 1.76-1.76h1.076a1.76 1.76 0 0 1 1.76 1.76v1.901a29.084 29.084 0 0 1-4.596 1.154Zm-29.72-1.154v-1.901a1.76 1.76 0 0 1 1.76-1.76h1.076a1.76 1.76 0 0 1 1.76 1.76v3.055a29.084 29.084 0 0 1-4.596-1.154Zm34.594-.098v-1.305a2.296 2.296 0 0 1 2.296-2.296h.004c.949 0 1.763.576 2.113 1.397l-.12.081c-1.339.889-2.791 1.583-4.293 2.123Zm-39.285-2.204a2.297 2.297 0 0 1 2.113-1.397h.004a2.296 2.296 0 0 1 2.296 2.296v1.305c-1.502-.54-2.954-1.234-4.293-2.123l-.12-.081Z" />
        </>
      ),
    },
  },
};

const teeth_shape_ye9BrrMRe7_upper = {
  id: "teeth_shape_ye9BrrMRe7_upper",
  name: "Teeth 4 (Upper)",
  description: "Short, squared teeth/tusks",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <>
          <path d="m256.854 302.38.004.016 1.693 6.49a.759.759 0 0 0 .733.566h3.008a.758.758 0 0 0 .733-.566l.781-2.991c-1.107-.987-2.166-1.883-3.093-2.575-1.47-1.096-2.558-1.185-3.859-.94Z" />
          <path d="m236.194 305.893.781 2.993a.758.758 0 0 0 .733.566h3.008a.759.759 0 0 0 .733-.566l1.693-6.49.005-.018c-1.297-.243-2.383-.152-3.85.942-.932.69-1.994 1.586-3.103 2.573Z" />
        </>
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <>
          <path d="M256.839 305.253c.193.149.393.336.606.567 1.392 1.52 3.255 4.175 4.942 6.584a.746.746 0 0 1-.095.006h-3.008a.758.758 0 0 1-.733-.567l-1.693-6.489a.717.717 0 0 1-.019-.101Z" />
          <path d="M243.161 305.256a.678.678 0 0 1-.019.098l-1.693 6.489a.758.758 0 0 1-.733.567h-3.008a.726.726 0 0 1-.093-.006c1.689-2.408 3.554-5.066 4.95-6.584.208-.229.406-.415.596-.564Z" />
        </>
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <>
          <path d="M236.013 306.015c1.37-.819 2.696-1.572 3.807-2.095 1.256-.598 2.217-.703 3.297-.611l-1.668 6.393a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-.962-3.687Z" />
          <path d="M256.884 303.311c1.077-.092 2.039.013 3.296.609 1.109.526 2.436 1.279 3.806 2.099l-.961 3.683a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.667-6.391Z" />
        </>
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <>
          <path d="m256.885 308.85 1.666 6.387a.759.759 0 0 0 .733.566h3.008a.758.758 0 0 0 .733-.566l.897-3.436c-2.624-.738-4.95-1.963-7.037-2.951Z" />
          <path d="M243.151 308.71c-2.114.978-4.466 2.22-7.098 2.995l.922 3.532a.758.758 0 0 0 .733.566h3.008a.759.759 0 0 0 .733-.566l1.693-6.49.009-.037Z" />
        </>
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <>
          <path d="M262.195 305.058a.758.758 0 0 0-.733-.949h-6.395a.758.758 0 0 0-.733.949l1.693 6.489a.758.758 0 0 0 .733.567h3.008a.757.757 0 0 0 .733-.567l1.694-6.489Z" />
          <path d="M245.666 305.058a.758.758 0 0 0-.733-.949h-6.395a.759.759 0 0 0-.733.949l1.694 6.489a.757.757 0 0 0 .733.567h3.008a.758.758 0 0 0 .733-.567l1.693-6.489Z" />
        </>
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <>
          <path d="M264.719 302.225a.757.757 0 0 0-.733-.949h-6.395a.758.758 0 0 0-.733.949l1.693 6.49a.759.759 0 0 0 .733.566h3.008a.758.758 0 0 0 .733-.566l1.694-6.49Z" />
          <path d="M243.142 302.225a.758.758 0 0 0-.733-.949h-6.395a.757.757 0 0 0-.733.949l1.694 6.49a.758.758 0 0 0 .733.566h3.008a.759.759 0 0 0 .733-.566l1.693-6.49Z" />
        </>
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <>
          <path d="M264.719 301.9a.758.758 0 0 0-.733-.949h-6.395a.757.757 0 0 0-.733.949l1.693 6.489a.758.758 0 0 0 .733.567h3.008a.757.757 0 0 0 .733-.567l1.694-6.489Z" />
          <path d="M243.142 301.9a.757.757 0 0 0-.733-.949h-6.395a.756.756 0 0 0-.733.949l1.694 6.489a.757.757 0 0 0 .733.567h3.008a.758.758 0 0 0 .733-.567l1.693-6.489Z" />
        </>
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M264.719 308.274a.757.757 0 0 0-.733-.949h-6.395a.758.758 0 0 0-.733.949l1.693 6.49a.757.757 0 0 0 .733.566h3.008a.757.757 0 0 0 .733-.566l1.694-6.49Z" />
          <path d="M243.142 308.274a.758.758 0 0 0-.733-.949h-6.395a.757.757 0 0 0-.733.949l1.694 6.49a.757.757 0 0 0 .733.566h3.008a.757.757 0 0 0 .733-.566l1.693-6.49Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <>
          <path d="M264.719 305.342a.756.756 0 0 0-.733-.948h-6.395a.757.757 0 0 0-.733.948l1.693 6.49a.757.757 0 0 0 .733.566h3.008a.757.757 0 0 0 .733-.566l1.694-6.49Z" />
          <path d="M243.142 305.342a.759.759 0 0 0-.733-.948h-6.395a.756.756 0 0 0-.733.948l1.694 6.49a.757.757 0 0 0 .733.566h3.008a.757.757 0 0 0 .733-.566l1.693-6.49Z" />
        </>
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="M264.719 302.507a.758.758 0 0 0-.733-.949h-6.395a.76.76 0 0 0-.6.294.761.761 0 0 0-.133.655l1.693 6.49a.759.759 0 0 0 .733.566h3.008a.758.758 0 0 0 .733-.566l1.694-6.49Z" />
          <path d="M243.142 302.507a.761.761 0 0 0-.133-.655.76.76 0 0 0-.6-.294h-6.395a.759.759 0 0 0-.733.949l1.694 6.49a.758.758 0 0 0 .733.566h3.008a.759.759 0 0 0 .733-.566l1.693-6.49Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <>
          <path d="M262.855 301.903a.758.758 0 0 0-.733-.949h-6.395a.759.759 0 0 0-.733.949l1.694 6.49a.758.758 0 0 0 .733.566h3.008a.759.759 0 0 0 .733-.566l1.693-6.49Z" />
          <path d="M245.006 301.903a.758.758 0 0 0-.733-.949h-6.395a.758.758 0 0 0-.733.949l1.693 6.49a.759.759 0 0 0 .733.566h3.008a.758.758 0 0 0 .733-.566l1.694-6.49Z" />
        </>
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <>
          <path d="M264.719 305.342a.756.756 0 0 0-.733-.948h-6.395a.757.757 0 0 0-.733.948l1.693 6.49a.757.757 0 0 0 .733.566h3.008a.757.757 0 0 0 .733-.566l1.694-6.49Z" />
          <path d="M243.142 305.342a.759.759 0 0 0-.733-.948h-6.395a.756.756 0 0 0-.733.948l1.694 6.49a.757.757 0 0 0 .733.566h3.008a.757.757 0 0 0 .733-.566l1.693-6.49Z" />
        </>
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="m262.933 301.976-1.601 6.134a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-.473-1.813c2.085-1.505 4.348-3.119 6.548-4.321Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="m237.067 301.976 1.601 6.134a.758.758 0 0 0 .733.566h3.008a.759.759 0 0 0 .733-.566l.473-1.813c-2.085-1.505-4.348-3.119-6.548-4.321Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <>
          <path d="M262.295 306.47h-7.909a.784.784 0 0 0 .024.215l1.694 6.49a.758.758 0 0 0 .733.566h3.008a.759.759 0 0 0 .733-.566l1.693-6.49a.714.714 0 0 0 .024-.215Z" />
          <path d="M245.614 306.47h-7.909a.714.714 0 0 0 .024.215l1.693 6.49a.759.759 0 0 0 .733.566h3.008a.758.758 0 0 0 .733-.566l1.694-6.49a.784.784 0 0 0 .024-.215Z" />
        </>
      ),
    },
    mouth_shape_74Nrw: {
      base: (
        <>
          <path d="M264.73 306.47h-7.883a.448.448 0 0 0 .011.05l1.693 6.489a.758.758 0 0 0 .733.567h3.008a.757.757 0 0 0 .733-.567l1.694-6.489.011-.05Z" />
          <path d="M243.153 306.47h-7.883l.011.05 1.694 6.489a.757.757 0 0 0 .733.567h3.008a.758.758 0 0 0 .733-.567l1.693-6.489a.448.448 0 0 0 .011-.05Z" />
        </>
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <>
          <path d="M236.682 304.19a.817.817 0 0 0 .024.156l1.694 6.49a.757.757 0 0 0 .733.566h3.007a.757.757 0 0 0 .733-.566l1.154-4.422a33.46 33.46 0 0 1-7.345-2.224Z" />
          <path d="M263.318 304.186a33.497 33.497 0 0 1-7.346 2.227l1.155 4.423a.757.757 0 0 0 .733.566h3.007a.757.757 0 0 0 .733-.566l1.694-6.49a.728.728 0 0 0 .024-.16Z" />
        </>
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <>
          <path d="M264.705 304.717a59.054 59.054 0 0 1-7.464 1.415l1.31 5.021a.759.759 0 0 0 .733.566h3.008a.758.758 0 0 0 .733-.566l1.68-6.436Z" />
          <path d="m235.296 304.72 1.679 6.433a.758.758 0 0 0 .733.566h3.008a.759.759 0 0 0 .733-.566l1.31-5.02a59.177 59.177 0 0 1-7.463-1.413Z" />
        </>
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <>
          <path d="m255.907 304.132 1.638 6.276a.758.758 0 0 0 .733.566h3.007a.758.758 0 0 0 .733-.566l1.061-4.067a32.45 32.45 0 0 0-7.172-2.209Z" />
          <path d="M244.094 304.13a32.501 32.501 0 0 0-7.175 2.207l1.063 4.071a.758.758 0 0 0 .733.566h3.007a.758.758 0 0 0 .733-.566l1.639-6.278Z" />
        </>
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <>
          <path d="M243.133 301.009a58.067 58.067 0 0 0-7.48 1.392l1.322 5.064a.757.757 0 0 0 .733.567h3.008a.758.758 0 0 0 .733-.567l1.684-6.456Z" />
          <path d="m256.867 301.01 1.684 6.455a.758.758 0 0 0 .733.567h3.008a.757.757 0 0 0 .733-.567l1.321-5.061a58.026 58.026 0 0 0-7.479-1.394Z" />
        </>
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <>
          <path d="m264.704 305.4-1.679 6.432a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.665-6.381c2.783-.011 5.44-.027 7.818-.051Z" />
          <path d="M235.296 305.4c2.377.024 5.034.04 7.818.051l-1.665 6.381a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.679-6.432Z" />
        </>
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <>
          <path d="M256.865 300.836a7.988 7.988 0 0 1 1.306-.089c3.283.015 2.316 1.316 6.065 1.914l-1.211 4.639a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.686-6.464Z" />
          <path d="M235.764 302.661c3.749-.598 2.782-1.899 6.065-1.914.515-.002.942.03 1.306.089l-1.686 6.464a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.211-4.639Z" />
        </>
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <>
          <path d="M256.837 304.427c1.897.45 4.149 1.309 7.123 3.022l-.935 3.581a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.693-6.49a.725.725 0 0 1-.021-.113Z" />
          <path d="M243.163 304.427a.725.725 0 0 1-.021.113l-1.693 6.49a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-.935-3.581c2.974-1.713 5.226-2.572 7.123-3.022Z" />
        </>
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <>
          <path d="M254.061 304.641c3.404.634 5.274 1.951 6.998 3.076l-.861 3.298a.758.758 0 0 1-.733.566h-3.007a.758.758 0 0 1-.733-.566l-1.664-6.374Z" />
          <path d="m245.939 304.641-1.664 6.374a.758.758 0 0 1-.733.566h-3.007a.758.758 0 0 1-.733-.566l-.861-3.298c1.724-1.125 3.594-2.442 6.998-3.076Z" />
        </>
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <>
          <path d="M256.852 305.287a9.38 9.38 0 0 1 1.815.014c1.901.174 3.904.567 5.79 1.012l-1.432 5.488a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.693-6.49-.006-.024Z" />
          <path d="M235.543 306.313c1.886-.445 3.889-.838 5.79-1.012a9.38 9.38 0 0 1 1.815-.014l-.006.024-1.693 6.49a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.432-5.488Z" />
        </>
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <>
          <path d="M256.984 303.33c2.454.022 5.067.042 7.597.043l-1.556 5.962a.757.757 0 0 1-.733.567h-3.008a.758.758 0 0 1-.733-.567l-1.567-6.005Z" />
          <path d="m243.017 303.326-1.568 6.009a.758.758 0 0 1-.733.567h-3.008a.757.757 0 0 1-.733-.567l-1.556-5.963c2.548-.003 5.163-.025 7.598-.046Z" />
        </>
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <>
          <path d="m264.585 311.254-1.56 5.976a.757.757 0 0 1-.733.567h-3.008a.758.758 0 0 1-.733-.567L257 311.286c2.641-.007 5.201-.017 7.585-.032Z" />
          <path d="M235.415 311.254c2.384.015 4.945.025 7.585.032l-1.551 5.944a.758.758 0 0 1-.733.567h-3.008a.757.757 0 0 1-.733-.567l-1.56-5.976Z" />
        </>
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <>
          <path d="M256.962 296.587c.48-.119.896-.138 1.296.031 1.626.684 3.845 1.099 6.006 1.313l-1.239 4.747a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.589-6.091Z" />
          <path d="M235.736 297.931c2.161-.214 4.38-.629 6.006-1.313.4-.169.816-.15 1.296-.031l-1.589 6.091a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-1.239-4.747Z" />
        </>
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <>
          <path d="M256.928 299.981c3.065 1.083 4.666 2.738 6.745 3.738l-.648 2.482a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.623-6.22Z" />
          <path d="m243.072 299.981-1.623 6.22a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-.648-2.482c2.079-1 3.68-2.654 6.745-3.738Z" />
        </>
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="m264.105 302.026-1.08 4.139a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.387-5.316c1.551.083 2.975.341 4.409.722.77.205 1.636.351 2.532.455Zm-21.269-1.177-1.387 5.316a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-1.08-4.139c.896-.104 1.762-.25 2.532-.455 1.434-.381 2.858-.639 4.409-.722Z" />
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <>
          <path d="M256.97 302.208c2.37.018 5.014.031 7.629.028l-1.574 6.032a.757.757 0 0 1-.733.567h-3.008a.758.758 0 0 1-.733-.567l-1.581-6.06Z" />
          <path d="m243.03 302.208-1.581 6.06a.758.758 0 0 1-.733.567h-3.008a.757.757 0 0 1-.733-.567l-1.574-6.032c2.615.003 5.259-.01 7.629-.028Z" />
        </>
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <>
          <path d="M257.023 307.063c1.957.477 4.683 1.261 7.046 1.855l-1.044 4a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.528-5.855Z" />
          <path d="m243.013 306.925-1.564 5.993a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-1.093-4.189c2.514-.657 5.329-1.395 7.131-1.804Z" />
        </>
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <>
          <path d="m264.666 304.988-1.641 6.29a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.629-6.244c2.513-.009 5.193-.023 7.744-.046Z" />
          <path d="M235.334 304.988c2.552.023 5.231.037 7.744.046l-1.629 6.244a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.641-6.29Z" />
        </>
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <>
          <path d="M256.942 304.211a7.354 7.354 0 0 1 1.264-.235c2.236-.202 4.298.087 6.316.664l-1.497 5.737a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.609-6.166Z" />
          <path d="M235.478 304.64c2.018-.577 4.08-.866 6.316-.664.431.039.851.123 1.264.235l-1.609 6.166a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-1.497-5.737Z" />
        </>
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <>
          <path d="M235.322 303.925c1.933.513 3.704 1.062 4.895 1.526.473.185 1.308.393 2.332.591l-1.1 4.215a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.653-6.332Z" />
          <path d="m264.678 303.925-1.653 6.332a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.1-4.215c1.024-.198 1.859-.406 2.332-.591 1.191-.464 2.962-1.013 4.895-1.526Z" />
        </>
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <>
          <path d="m257.23 301.39 1.321 5.063a.759.759 0 0 0 .733.566h3.008a.758.758 0 0 0 .733-.566l1.313-5.03a22.136 22.136 0 0 0-7.108-.033Z" />
          <path d="m235.662 301.423 1.313 5.03a.758.758 0 0 0 .733.566h3.008a.759.759 0 0 0 .733-.566l1.321-5.063a22.107 22.107 0 0 0-7.108.033Z" />
        </>
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <>
          <path d="m264.68 301.175-1.655 6.343a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.398-5.359c3.24-.286 5.341-.747 7.527-.984Z" />
          <path d="M235.32 301.175c2.186.237 4.287.698 7.527.984l-1.398 5.359a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.655-6.343Z" />
        </>
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M256.94 296.556c2.193.063 4.841.128 7.638.224l-1.553 5.952a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.611-6.176Z" />
          <path d="m243.06 296.556-1.611 6.176a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-1.553-5.952c2.796-.096 5.445-.161 7.638-.224Z" />
        </>
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="M256.841 296.958c2.568.081 5.515.155 7.864.137l-1.68 6.436a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.693-6.49a.763.763 0 0 1-.017-.083Z" />
          <path d="M243.159 296.958a.763.763 0 0 1-.017.083l-1.693 6.49a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-1.68-6.436c2.349.018 5.295-.056 7.864-.137Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="M256.854 299.283c1.881-.038 3.728.021 5.028.41.823.246 1.688.473 2.557.677l-1.414 5.418a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.693-6.49-.004-.015Z" />
          <path d="M235.561 300.37a44.535 44.535 0 0 0 2.557-.677c1.3-.389 3.147-.448 5.028-.41a.083.083 0 0 1-.004.015l-1.693 6.49a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566l-1.414-5.418Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="M256.839 300.388c.563.032 1.245.119 2.096.28 1.672.316 3.591.769 5.414 1.232l-1.324 5.074a.757.757 0 0 1-.733.567h-3.008a.758.758 0 0 1-.733-.567l-1.693-6.49a.627.627 0 0 1-.019-.096Z" />
          <path d="M243.161 300.388a.627.627 0 0 1-.019.096l-1.693 6.49a.758.758 0 0 1-.733.567h-3.008a.757.757 0 0 1-.733-.567l-1.324-5.074c1.823-.463 3.742-.916 5.414-1.232a16.437 16.437 0 0 1 2.096-.28Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="m264.618 304.931-1.593 6.106a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.583-6.067a674.41 674.41 0 0 0 7.65-.039Z" />
          <path d="M235.382 304.931c2.346.029 5.126.038 7.65.039l-1.583 6.067a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-1.593-6.106Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="M256.897 300.308c.798-.306 1.644-.518 3.035-.444 1.08.057 2.424 1.319 4.476 1.484l-1.383 5.3a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.654-6.34Z" />
          <path d="M235.592 301.348c2.052-.165 3.396-1.427 4.476-1.484 1.391-.074 2.237.138 3.035.444l-1.654 6.34a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-1.383-5.3Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="m264.714 301.89-1.689 6.473a.757.757 0 0 1-.733.567h-3.008a.758.758 0 0 1-.733-.567l-1.194-4.575c1.995-.354 4.464-.933 7.357-1.898Z" />
          <path d="M235.286 301.89c2.893.965 5.363 1.544 7.357 1.898l-1.194 4.575a.758.758 0 0 1-.733.567h-3.008a.757.757 0 0 1-.733-.567l-1.689-6.473Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="M256.919 300.794c2.774.042 5.234.403 7.53.797l-1.424 5.458a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.632-6.255Z" />
          <path d="m243.081 300.794-1.632 6.255a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-1.424-5.458c2.296-.394 4.755-.755 7.53-.797Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="M257.001 299.038c.111.049.224.101.34.154 1.95.894 4.472 1.034 6.996.761l-1.312 5.026a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.55-5.941Z" />
          <path d="m242.999 299.038-1.55 5.941a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-1.312-5.026c2.524.273 5.046.133 6.996-.761.116-.053.229-.105.34-.154Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="M256.938 300.037c2.255.699 4.472 1.989 6.941 2.909l-.854 3.272a.757.757 0 0 1-.733.567h-3.008a.758.758 0 0 1-.733-.567l-1.613-6.181Z" />
          <path d="m243.062 300.037-1.613 6.181a.758.758 0 0 1-.733.567h-3.008a.757.757 0 0 1-.733-.567l-.854-3.272c2.469-.92 4.686-2.211 6.941-2.909Z" />
        </>
      ),
    },
  },
};

const teeth_shape_ye9BrrMRe7_lower = {
  id: "teeth_shape_ye9BrrMRe7_lower",
  name: "Teeth 4 (Lower)",
  description: "Short, squared teeth/tusks",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <>
          <path d="m256.865 316.438 1.686-6.464a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.451 5.56c-2.155.863-4.617.97-7.611.904Z" />
          <path d="m235.528 315.521 1.447-5.547a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.69 6.477c-2.939.028-5.518-.135-7.611-.93Z" />
        </>
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <>
          <path d="M257.371 305.742h2.835c.344 0 .646.232.733.566l1.675 6.419c-1.745-2.49-3.714-5.319-5.169-6.907l-.074-.078Z" />
          <path d="M242.637 305.742a2.769 2.769 0 0 0-.072.078c-1.46 1.587-3.433 4.422-5.181 6.914l1.677-6.426a.757.757 0 0 1 .733-.566h2.843Z" />
        </>
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <>
          <path d="m257.629 303.287.922-3.533a.758.758 0 0 1 .733-.567h3.008c.345 0 .646.233.733.567l1.694 6.489a.756.756 0 0 1 .023.226c-1.629-.99-3.242-1.928-4.552-2.549-.967-.454-1.759-.621-2.561-.633Z" />
          <path d="M235.258 306.477a.773.773 0 0 1 .023-.234l1.694-6.489a.757.757 0 0 1 .733-.567h3.008c.344 0 .646.233.733.567l.92 3.527c-.797.014-1.582.184-2.539.639-1.316.62-2.936 1.562-4.572 2.557Z" />
        </>
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <>
          <path d="m257.645 308.111.906-3.475a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.656 6.346c-2.628-.654-4.952-1.862-7.036-2.871Z" />
          <path d="m235.345 310.881 1.63-6.245a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l.877 3.361c-2.081.99-4.394 2.193-6.981 2.884Z" />
        </>
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <>
          <path d="M261.985 311.97a.761.761 0 0 1-.133.655.76.76 0 0 1-.6.294h-6.395a.759.759 0 0 1-.733-.949l1.694-6.489a.757.757 0 0 1 .733-.567h3.008c.344 0 .645.233.732.567l1.694 6.489Z" />
          <path d="M245.876 311.97a.758.758 0 0 1-.733.949h-6.395a.76.76 0 0 1-.6-.294.761.761 0 0 1-.133-.655l1.694-6.489a.757.757 0 0 1 .732-.567h3.008c.345 0 .646.233.733.567l1.694 6.489Z" />
        </>
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <>
          <path d="M264.719 304.96a.757.757 0 0 1-.733.949h-6.395a.758.758 0 0 1-.733-.949l1.693-6.49a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.694 6.49Z" />
          <path d="M243.142 304.96a.758.758 0 0 1-.733.949h-6.395a.757.757 0 0 1-.733-.949l1.694-6.49a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.693 6.49Z" />
        </>
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <>
          <path d="M264.719 304.787a.758.758 0 0 1-.733.949h-6.395a.757.757 0 0 1-.733-.949l1.693-6.489a.758.758 0 0 1 .733-.567h3.008c.345 0 .646.233.733.567l1.694 6.489Z" />
          <path d="M243.142 304.787a.757.757 0 0 1-.733.949h-6.395a.756.756 0 0 1-.733-.949l1.694-6.489a.757.757 0 0 1 .733-.567h3.008c.344 0 .646.233.733.567l1.693 6.489Z" />
        </>
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M264.719 312.704a.756.756 0 0 1-.733.949h-6.395a.758.758 0 0 1-.733-.949l1.693-6.49a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l1.694 6.49Z" />
          <path d="M243.142 312.704a.759.759 0 0 1-.733.949h-6.395a.757.757 0 0 1-.733-.949l1.694-6.49a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l1.693 6.49Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <>
          <path d="M243.142 311.525a.758.758 0 0 1-.733.949h-6.395a.757.757 0 0 1-.733-.949l1.694-6.49a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.693 6.49Z" />
          <path d="M264.719 311.525a.757.757 0 0 1-.733.949h-6.395a.758.758 0 0 1-.733-.949l1.693-6.49a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.694 6.49Z" />
        </>
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="M264.719 312.517a.757.757 0 0 1-.733.949h-6.395a.758.758 0 0 1-.733-.949l1.693-6.49a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.694 6.49Z" />
          <path d="M243.142 312.517a.758.758 0 0 1-.733.949h-6.395a.757.757 0 0 1-.733-.949l1.694-6.49a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.693 6.49Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <>
          <path d="M262.855 313.058a.756.756 0 0 1-.733.949h-6.395a.757.757 0 0 1-.733-.949l1.694-6.49a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l1.693 6.49Z" />
          <path d="M245.006 313.058a.756.756 0 0 1-.733.949h-6.395a.758.758 0 0 1-.733-.949l1.693-6.49a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l1.694 6.49Z" />
        </>
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <>
          <path d="M264.719 310.973a.756.756 0 0 1-.733.948h-6.395a.757.757 0 0 1-.733-.948l1.693-6.49a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l1.694 6.49Z" />
          <path d="M243.142 310.973a.759.759 0 0 1-.733.948h-6.395a.756.756 0 0 1-.733-.948l1.694-6.49a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l1.693 6.49Z" />
        </>
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="m262.259 302.345-.507-1.943a.757.757 0 0 0-.733-.566h-3.007a.757.757 0 0 0-.733.566l-1.685 6.456c2.087-1.506 4.397-3.199 6.665-4.513Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="m237.741 302.345.507-1.943a.757.757 0 0 1 .733-.566h3.007c.345 0 .646.232.733.566l1.685 6.456c-2.087-1.506-4.397-3.199-6.665-4.513Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <>
          <path d="M255.475 305.47a.766.766 0 0 1 .021-.273l1.693-6.49a.758.758 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.693 6.49c.024.09.03.183.021.273h-7.902Z" />
          <path d="M236.623 305.47a.766.766 0 0 1 .021-.273l1.693-6.49a.758.758 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.693 6.49c.024.09.03.183.021.273h-7.902Z" />
        </>
      ),
    },
    mouth_shape_74Nrw: {
      base: (
        <>
          <path d="m235.295 305.47 1.68-6.439a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.68 6.439h-7.834Z" />
          <path d="m256.871 305.47 1.68-6.439a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.68 6.439h-7.834Z" />
        </>
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <>
          <path d="m255.906 305.408.006-.025 1.694-6.49a.758.758 0 0 1 .733-.566h3.007c.345 0 .646.233.733.566l1.111 4.257a32.453 32.453 0 0 1-7.284 2.258Z" />
          <path d="m236.809 303.154 1.112-4.261a.758.758 0 0 1 .733-.566h3.007c.345 0 .646.233.733.566a22381.537 22381.537 0 0 0 1.7 6.517 32.44 32.44 0 0 1-7.285-2.256Z" />
        </>
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <>
          <path d="m235.603 303.766 1.372-5.257a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.693 6.49a.812.812 0 0 1 .025.176 57.95 57.95 0 0 1-7.564-1.409Z" />
          <path d="M256.833 305.174a.803.803 0 0 1 .025-.175l1.693-6.49a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.371 5.255a58.243 58.243 0 0 1-7.563 1.41Z" />
        </>
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <>
          <path d="m236.416 305.467 1.69-6.479a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.097 4.203a33.41 33.41 0 0 0-7.261 2.276Z" />
          <path d="m256.323 303.193 1.097-4.205a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.692 6.484a33.398 33.398 0 0 0-7.263-2.279Z" />
        </>
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <>
          <path d="m257.17 300.04 1.381-5.295a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l1.694 6.49c.02.078.028.159.023.238a59.025 59.025 0 0 0-7.572-1.433Z" />
          <path d="M235.258 301.47a.749.749 0 0 1 .023-.235l1.694-6.49a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l1.381 5.293a59.162 59.162 0 0 0-7.572 1.432Z" />
        </>
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <>
          <path d="m257.242 305.449 1.309-5.016a.758.758 0 0 1 .733-.567h3.008c.345 0 .646.233.733.567l1.297 4.971c-2.176.02-4.574.035-7.08.045Z" />
          <path d="m235.678 305.404 1.297-4.971a.757.757 0 0 1 .733-.567h3.008c.344 0 .646.233.733.567l1.309 5.016c-2.506-.01-4.905-.025-7.08-.045Z" />
        </>
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <>
          <path d="m257.411 300.773 1.14-4.371a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l1.65 6.323c-4.266-.564-3.099-1.963-6.504-1.978a9.327 9.327 0 0 0-.76.026Z" />
          <path d="m235.325 302.725 1.65-6.323a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l1.14 4.371a9.327 9.327 0 0 0-.76-.026c-3.405.015-2.238 1.414-6.504 1.978Z" />
        </>
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <>
          <path d="m256.896 309.595 1.655-6.344a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l1.524 5.838c-2.147.61-3.744.488-7.653.506Z" />
          <path d="m235.451 309.089 1.524-5.838a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l1.655 6.344c-3.909-.018-5.506.104-7.653-.506Z" />
        </>
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <>
          <path d="m254.197 311.092 1.693-6.486a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l.926 3.552c-2.106.902-4.396 2.175-7.093 2.934Z" />
          <path d="m238.71 308.158.926-3.552a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.693 6.486c-2.697-.759-4.987-2.033-7.093-2.934Z" />
        </>
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <>
          <path d="m257.111 305.266 1.44-5.519a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.694 6.49c.012.047.02.095.023.144-1.969-.473-4.078-.897-6.075-1.08a9.931 9.931 0 0 0-1.556-.035Z" />
          <path d="M235.258 306.381a.772.772 0 0 1 .023-.144l1.694-6.49a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.44 5.519a9.931 9.931 0 0 0-1.556.035c-1.997.183-4.106.607-6.075 1.08Z" />
        </>
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <>
          <path d="m256.915 303.33 1.636-6.271a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.648 6.314c-2.581 0-5.254-.021-7.758-.043Z" />
          <path d="m235.327 303.372 1.648-6.313a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.635 6.266c-2.484.022-5.156.044-7.757.047Z" />
        </>
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <>
          <path d="m256.903 311.28 1.648-6.314a.758.758 0 0 1 .733-.567h3.008c.345 0 .646.233.733.567l1.643 6.294c-2.401.009-5.032.016-7.765.02Z" />
          <path d="m235.332 311.26 1.643-6.294a.757.757 0 0 1 .733-.567h3.008c.344 0 .646.233.733.567l1.648 6.314c-2.734-.004-5.364-.011-7.765-.02Z" />
        </>
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <>
          <path d="m257.282 296.522 1.269-4.864a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.647 6.312c-2.286-.202-4.686-.624-6.414-1.352-.309-.13-.627-.149-.976-.096Z" />
          <path d="m235.328 297.97 1.647-6.312a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.269 4.864c-.349-.053-.667-.034-.976.096-1.728.728-4.128 1.15-6.414 1.352Z" />
        </>
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <>
          <path d="m256.987 303.586 1.564-5.993a.758.758 0 0 1 .733-.567h3.008c.345 0 .646.233.733.567l1.694 6.489.014.067a8.994 8.994 0 0 1-.391-.14c-.866.027-1.777.041-2.71.029-1.547-.021-3.073-.232-4.645-.452Z" />
          <path d="m235.267 304.149.014-.067 1.694-6.489a.757.757 0 0 1 .733-.567h3.008c.344 0 .646.233.733.567l1.564 5.993c-1.572.22-3.098.431-4.645.452a61.634 61.634 0 0 1-2.71-.029 8.994 8.994 0 0 1-.391.14Z" />
        </>
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="m234.998 302.116 1.301-4.985a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.317 5.048c-1.358-.142-3.098-.239-5.393-.259-.552.083-1.124.147-1.699.196Zm22.912.063 1.317-5.048a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.301 4.985a26.782 26.782 0 0 1-1.699-.196c-2.295.02-4.035.117-5.393.259Z" />
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <>
          <path d="m256.916 302.207 1.635-6.267a.758.758 0 0 1 .733-.567h3.008c.345 0 .646.233.733.567l1.643 6.295c-2.657.005-5.347-.009-7.752-.028Z" />
          <path d="m235.332 302.235 1.643-6.295a.757.757 0 0 1 .733-.567h3.008c.344 0 .646.233.733.567l1.635 6.267c-2.405.019-5.095.033-7.752.028Z" />
        </>
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <>
          <path d="m257 313.547 1.551-5.944a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.528 5.854c-2.11.765-4.642.605-7.553.09Z" />
          <path d="m236.221 310.491.754-2.888a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l.844 3.235a43.416 43.416 0 0 0-2.813-.168 63.53 63.53 0 0 1-3.259-.179Z" />
        </>
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <>
          <path d="m235.34 304.988 1.635-6.266a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.647 6.312c-2.517-.009-5.2-.023-7.756-.046Z" />
          <path d="m256.904 305.034 1.647-6.312a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.635 6.266c-2.556.023-5.239.037-7.756.046Z" />
        </>
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <>
          <path d="m257.228 304.138 1.323-5.071a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l1.451 5.56c-2.004-.568-4.051-.851-6.27-.651a7.1 7.1 0 0 0-.978.162Z" />
          <path d="m235.524 304.627 1.451-5.56a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l1.323 5.071a7.1 7.1 0 0 0-.978-.162c-2.219-.2-4.266.083-6.27.651Z" />
        </>
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <>
          <path d="m256.919 306.141 1.632-6.254a.758.758 0 0 1 .733-.567h3.008c.345 0 .646.233.733.567l1.093 4.188c-1.713.469-3.26.957-4.335 1.376-.552.216-1.599.463-2.864.69Z" />
          <path d="m235.882 304.075 1.093-4.188a.757.757 0 0 1 .733-.567h3.008c.344 0 .646.233.733.567l1.632 6.254c-1.265-.227-2.312-.474-2.864-.69-1.075-.419-2.622-.907-4.335-1.376Z" />
        </>
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <>
          <path d="m256.935 301.438 1.616-6.195a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l1.627 6.236a22.122 22.122 0 0 0-7.717-.041Z" />
          <path d="m235.348 301.479 1.627-6.236a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l1.616 6.195a22.123 22.123 0 0 0-7.717.041Z" />
        </>
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <>
          <path d="M256.835 302.186a.737.737 0 0 1 .023-.138l1.693-6.49a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.471 5.637c-2.192.251-4.332.716-7.661.991Z" />
          <path d="m235.504 301.195 1.471-5.637a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.693 6.49c.012.046.02.092.023.138-3.329-.275-5.469-.74-7.661-.991Z" />
        </>
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="m256.923 311.972 1.628-6.241a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l1.384 5.303c-2.574.596-5.169.841-7.486.938Z" />
          <path d="m235.591 311.034 1.384-5.303a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l1.628 6.241c-2.317-.097-4.912-.342-7.486-.938Z" />
        </>
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="m256.856 296.959.002-.008 1.693-6.49a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l1.694 6.49c.012.047.02.095.023.144-2.35.019-5.308-.056-7.886-.136Z" />
          <path d="M235.258 297.095a.772.772 0 0 1 .023-.144l1.694-6.49a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l1.693 6.49.002.008c-2.578.08-5.536.155-7.886.136Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="m257.086 306.336 1.465-5.616a.758.758 0 0 1 .733-.567h3.008c.345 0 .646.233.733.567l.767 2.939c-2.252 1.022-4.215 2.022-4.981 2.335-.283.116-.919.233-1.725.342Z" />
          <path d="m236.208 303.659.767-2.939a.757.757 0 0 1 .733-.567h3.008c.344 0 .646.233.733.567l1.465 5.616c-.806-.109-1.442-.226-1.725-.342-.766-.313-2.729-1.313-4.981-2.335Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="m254.952 304.763 1.654-6.337a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l.807 3.093c-.508.217-.999.504-1.595.823-.999.535-2.161 1.737-5.34 2.421Z" />
          <path d="m238.113 301.519.807-3.093a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.654 6.337c-3.179-.684-4.341-1.886-5.34-2.421-.596-.319-1.087-.606-1.595-.823Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="m256.891 304.97 1.66-6.362a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l1.65 6.323c-2.382.029-5.219.039-7.784.039Z" />
          <path d="m235.325 304.931 1.65-6.323a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l1.66 6.362c-2.565 0-5.402-.01-7.784-.039Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="m257.192 300.201 1.359-5.21a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l1.664 6.376a11.695 11.695 0 0 1-.215-.014c-2.089-.143-3.45-1.432-4.542-1.489-1.222-.065-2.022.091-2.74.337Z" />
          <path d="m235.311 301.367 1.664-6.376a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l1.359 5.21c-.718-.246-1.518-.402-2.74-.337-1.092.057-2.453 1.346-4.542 1.489l-.215.014Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="m257.06 303.84 1.491-5.716a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.038 3.979a51.111 51.111 0 0 1-7.003 1.737Z" />
          <path d="m235.937 302.103 1.038-3.979a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.491 5.716a51.111 51.111 0 0 1-7.003-1.737Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="m257.096 300.797 1.455-5.576a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.673 6.412c-2.319-.401-4.8-.779-7.602-.836Z" />
          <path d="m235.302 301.633 1.673-6.412a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.455 5.576c-2.802.057-5.283.435-7.602.836Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="m256.936 300.401 1.615-6.189a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.493 5.72c-1.58.184-3.164.208-4.612-.009-.953.186-1.945.349-2.97.478Z" />
          <path d="m235.482 299.932 1.493-5.72a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.615 6.189a44.548 44.548 0 0 1-2.97-.478c-1.448.217-3.032.192-4.612.009Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="m257.62 300.266.931-3.571a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l1.694 6.49.013.058c-2.564-.833-4.834-2.159-7.112-2.977Z" />
          <path d="m235.268 303.243.013-.058 1.694-6.49a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l.931 3.571c-2.279.818-4.548 2.144-7.112 2.977Z" />
        </>
      ),
    },
  },
};

const teeth_shape_fNzHNiDDtj_upper = {
  id: "teeth_shape_fNzHNiDDtj_upper",
  name: "Teeth 5 (Upper)",
  description: "Short, rounded teeth/tusks",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <>
          <path d="m256.854 302.38.004.016 1.314 5.035a2.705 2.705 0 0 0 5.233 0l.401-1.536c-1.107-.987-2.166-1.883-3.093-2.575-1.47-1.096-2.558-1.185-3.859-.94Z" />
          <path d="m236.194 305.893.401 1.538a2.704 2.704 0 0 0 5.233 0l1.314-5.035.005-.018c-1.297-.243-2.383-.152-3.85.942-.932.69-1.994 1.586-3.103 2.573Z" />
        </>
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <>
          <path d="M256.839 305.253c.193.149.393.336.606.567 1.324 1.446 3.074 3.918 4.693 6.229a2.704 2.704 0 0 1-3.966-1.661l-1.314-5.034a.717.717 0 0 1-.019-.101Z" />
          <path d="M243.161 305.256a.678.678 0 0 1-.019.098l-1.314 5.034a2.704 2.704 0 0 1-2.616 2.022 2.7 2.7 0 0 1-1.348-.36c1.621-2.311 3.373-4.786 4.701-6.23.208-.229.406-.415.596-.564Z" />
        </>
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <>
          <path d="M236.013 306.015c1.37-.819 2.696-1.572 3.807-2.095 1.256-.598 2.217-.703 3.297-.611l-1.289 4.938a2.705 2.705 0 0 1-5.233 0l-.582-2.232Z" />
          <path d="M256.884 303.311c1.077-.092 2.039.013 3.296.609 1.109.526 2.436 1.279 3.806 2.099l-.581 2.228a2.704 2.704 0 0 1-5.233 0l-1.288-4.936Z" />
        </>
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <>
          <path d="m256.929 308.871 1.243 4.763a2.705 2.705 0 0 0 5.233 0l.481-1.843c-2.59-.734-4.891-1.94-6.957-2.92Z" />
          <path d="M243.108 308.73c-2.093.97-4.42 2.193-7.019 2.964l.506 1.94a2.704 2.704 0 0 0 5.233 0l1.28-4.904Z" />
        </>
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <>
          <path d="M262.195 305.058a.758.758 0 0 0-.733-.949h-6.395a.758.758 0 0 0-.733.949l1.314 5.034a2.704 2.704 0 0 0 2.616 2.022h.467a2.1 2.1 0 0 0 2.032-1.57l1.432-5.486Z" />
          <path d="M245.666 305.058a.758.758 0 0 0-.733-.949h-6.395a.759.759 0 0 0-.733.949l1.314 5.034a2.705 2.705 0 0 0 2.617 2.022h.467c.955 0 1.79-.645 2.031-1.57l1.432-5.486Z" />
        </>
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <>
          <path d="M264.719 302.225a.757.757 0 0 0-.733-.949h-6.395a.758.758 0 0 0-.733.949l1.314 5.035a2.704 2.704 0 0 0 2.616 2.021h.467a2.1 2.1 0 0 0 2.032-1.57l1.432-5.486Z" />
          <path d="M243.142 302.225a.758.758 0 0 0-.733-.949h-6.395a.757.757 0 0 0-.733.949l1.314 5.035a2.705 2.705 0 0 0 2.617 2.021h.467c.955 0 1.79-.645 2.031-1.57l1.432-5.486Z" />
        </>
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <>
          <path d="M264.719 301.9a.758.758 0 0 0-.733-.949h-6.395a.757.757 0 0 0-.733.949l1.314 5.034a2.704 2.704 0 0 0 2.616 2.022h.467a2.1 2.1 0 0 0 2.032-1.57l1.432-5.486Z" />
          <path d="M243.142 301.9a.757.757 0 0 0-.733-.949h-6.395a.756.756 0 0 0-.733.949l1.314 5.034a2.705 2.705 0 0 0 2.617 2.022h.467a2.1 2.1 0 0 0 2.031-1.57l1.432-5.486Z" />
        </>
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M264.719 308.274a.757.757 0 0 0-.733-.949h-6.395a.758.758 0 0 0-.733.949l1.314 5.035a2.703 2.703 0 0 0 2.616 2.021h.467a2.1 2.1 0 0 0 2.032-1.569l1.432-5.487Z" />
          <path d="M243.142 308.274a.758.758 0 0 0-.733-.949h-6.395a.757.757 0 0 0-.733.949l1.314 5.035a2.704 2.704 0 0 0 2.617 2.021h.467a2.1 2.1 0 0 0 2.031-1.569l1.432-5.487Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <>
          <path d="M264.719 305.342a.756.756 0 0 0-.733-.948h-6.395a.757.757 0 0 0-.733.948l1.314 5.035a2.703 2.703 0 0 0 2.616 2.021h.467a2.1 2.1 0 0 0 2.032-1.569l1.432-5.487Z" />
          <path d="M243.142 305.342a.759.759 0 0 0-.733-.948h-6.395a.756.756 0 0 0-.733.948l1.314 5.035a2.704 2.704 0 0 0 2.617 2.021h.467a2.1 2.1 0 0 0 2.031-1.569l1.432-5.487Z" />
        </>
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="M264.719 302.507a.758.758 0 0 0-.733-.949h-6.395a.76.76 0 0 0-.6.294.761.761 0 0 0-.133.655l1.314 5.035a2.704 2.704 0 0 0 2.616 2.021h.467a2.1 2.1 0 0 0 2.032-1.57l1.432-5.486Z" />
          <path d="M243.142 302.507a.761.761 0 0 0-.133-.655.76.76 0 0 0-.6-.294h-6.395a.759.759 0 0 0-.733.949l1.314 5.035a2.705 2.705 0 0 0 2.617 2.021h.467c.955 0 1.79-.645 2.031-1.57l1.432-5.486Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <>
          <path d="M262.855 301.903a.758.758 0 0 0-.733-.949h-6.395a.759.759 0 0 0-.733.949l1.314 5.035a2.705 2.705 0 0 0 2.617 2.021h.467c.955 0 1.79-.645 2.032-1.57l1.431-5.486Z" />
          <path d="M245.006 301.903a.758.758 0 0 0-.733-.949h-6.395a.758.758 0 0 0-.733.949l1.314 5.035a2.704 2.704 0 0 0 2.616 2.021h.467a2.1 2.1 0 0 0 2.032-1.57l1.432-5.486Z" />
        </>
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <>
          <path d="M264.719 305.342a.756.756 0 0 0-.733-.948h-6.395a.757.757 0 0 0-.733.948l1.314 5.035a2.703 2.703 0 0 0 2.616 2.021h.467a2.1 2.1 0 0 0 2.032-1.569l1.432-5.487Z" />
          <path d="M243.142 305.342a.759.759 0 0 0-.733-.948h-6.395a.756.756 0 0 0-.733.948l1.314 5.035a2.704 2.704 0 0 0 2.617 2.021h.467a2.1 2.1 0 0 0 2.031-1.569l1.432-5.487Z" />
        </>
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="m262.933 301.976-1.221 4.679a2.704 2.704 0 0 1-5.233 0l-.094-.358c2.085-1.505 4.348-3.119 6.548-4.321Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="m237.067 301.976 1.221 4.679a2.704 2.704 0 0 0 5.233 0l.094-.358c-2.085-1.505-4.348-3.119-6.548-4.321Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <>
          <path d="M245.614 306.47h-7.909a.714.714 0 0 0 .024.215l1.314 5.035a2.705 2.705 0 0 0 5.233 0l1.314-5.035a.784.784 0 0 0 .024-.215Z" />
          <path d="M262.295 306.47h-7.909a.784.784 0 0 0 .024.215l1.314 5.035a2.704 2.704 0 0 0 5.233 0l1.314-5.035a.714.714 0 0 0 .024-.215Z" />
        </>
      ),
    },
    mouth_shape_74Nrw: {
      base: (
        <>
          <path d="M264.73 306.47h-7.883a2.704 2.704 0 0 0 3.941 7.106 2.705 2.705 0 0 0 2.617-2.021l1.314-5.035.011-.05Z" />
          <path d="M243.153 306.47h-7.883l.011.05 1.314 5.035a2.704 2.704 0 0 0 5.233 0 21584.375 21584.375 0 0 1 1.325-5.085Z" />
        </>
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <>
          <path d="M236.682 304.19a.817.817 0 0 0 .024.156l1.314 5.035a2.703 2.703 0 0 0 2.616 2.021h.001c1.23 0 2.305-.83 2.616-2.021l.774-2.967a33.46 33.46 0 0 1-7.345-2.224Z" />
          <path d="M263.318 304.186a33.497 33.497 0 0 1-7.346 2.227l.775 2.968a2.703 2.703 0 0 0 2.616 2.021h.001c1.23 0 2.305-.83 2.616-2.021l1.314-5.035a.728.728 0 0 0 .024-.16Z" />
        </>
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <>
          <path d="M264.705 304.717a59.054 59.054 0 0 1-7.464 1.415l.931 3.566a2.705 2.705 0 0 0 5.233 0l1.3-4.981Z" />
          <path d="m235.296 304.72 1.299 4.978a2.704 2.704 0 0 0 5.233 0l.931-3.565a59.177 59.177 0 0 1-7.463-1.413Z" />
        </>
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <>
          <path d="m255.907 304.132 1.258 4.821a2.705 2.705 0 0 0 2.616 2.021h.001c1.23 0 2.305-.831 2.616-2.021l.681-2.612a32.45 32.45 0 0 0-7.172-2.209Z" />
          <path d="M244.094 304.13a32.501 32.501 0 0 0-7.175 2.207l.683 2.616a2.705 2.705 0 0 0 2.616 2.021h.001c1.23 0 2.305-.831 2.616-2.021l1.259-4.823Z" />
        </>
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <>
          <path d="M243.133 301.009a58.067 58.067 0 0 0-7.48 1.392l.942 3.609a2.704 2.704 0 0 0 5.233 0l1.305-5.001Z" />
          <path d="m256.867 301.01 1.305 5a2.705 2.705 0 0 0 5.233 0l.941-3.606a58.026 58.026 0 0 0-7.479-1.394Z" />
        </>
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <>
          <path d="m264.704 305.4-1.299 4.977a2.703 2.703 0 0 1-5.233 0l-1.286-4.926c2.783-.011 5.44-.027 7.818-.051Z" />
          <path d="M235.296 305.4c2.377.024 5.034.04 7.818.051l-1.286 4.926a2.704 2.704 0 0 1-5.233 0l-1.299-4.977Z" />
        </>
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <>
          <path d="M256.865 300.836a7.988 7.988 0 0 1 1.306-.089c3.283.015 2.316 1.316 6.065 1.914l-.831 3.184a2.703 2.703 0 0 1-5.233 0l-1.307-5.009Z" />
          <path d="M235.764 302.661c3.749-.598 2.782-1.899 6.065-1.914.515-.002.942.03 1.306.089l-1.307 5.009a2.704 2.704 0 0 1-5.233 0l-.831-3.184Z" />
        </>
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <>
          <path d="M256.837 304.427c1.897.45 4.149 1.309 7.123 3.022l-.555 2.126a2.704 2.704 0 0 1-5.233 0l-1.314-5.035a.725.725 0 0 1-.021-.113Z" />
          <path d="M243.163 304.427a.725.725 0 0 1-.021.113l-1.314 5.035a2.705 2.705 0 0 1-5.233 0l-.555-2.126c2.974-1.713 5.226-2.572 7.123-3.022Z" />
        </>
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <>
          <path d="M254.061 304.641c3.404.634 5.274 1.951 6.998 3.076l-.481 1.843a2.705 2.705 0 0 1-2.616 2.021h-.001a2.705 2.705 0 0 1-2.616-2.021l-1.284-4.919Z" />
          <path d="m245.939 304.641-1.284 4.919a2.705 2.705 0 0 1-2.616 2.021h-.001a2.705 2.705 0 0 1-2.616-2.021l-.481-1.843c1.724-1.125 3.594-2.442 6.998-3.076Z" />
        </>
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <>
          <path d="M256.852 305.287a9.38 9.38 0 0 1 1.815.014c1.901.174 3.904.567 5.79 1.012l-1.052 4.033a2.703 2.703 0 0 1-5.233 0l-1.314-5.035-.006-.024Z" />
          <path d="M235.543 306.313c1.886-.445 3.889-.838 5.79-1.012a9.38 9.38 0 0 1 1.815-.014l-.006.024-1.314 5.035a2.704 2.704 0 0 1-5.233 0l-1.052-4.033Z" />
        </>
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <>
          <path d="M256.984 303.33c2.454.022 5.067.042 7.597.043l-1.176 4.507a2.704 2.704 0 0 1-5.233 0l-1.188-4.55Z" />
          <path d="m243.017 303.326-1.189 4.554a2.705 2.705 0 0 1-5.233 0l-1.176-4.508c2.548-.003 5.163-.025 7.598-.046Z" />
        </>
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <>
          <path d="m264.585 311.254-1.18 4.521a2.704 2.704 0 0 1-5.233 0L257 311.286c2.641-.007 5.201-.017 7.585-.032Z" />
          <path d="M235.415 311.254c2.384.015 4.945.025 7.585.032l-1.172 4.489a2.705 2.705 0 0 1-5.233 0l-1.18-4.521Z" />
        </>
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <>
          <path d="M256.962 296.587c.48-.119.896-.138 1.296.031 1.626.684 3.845 1.099 6.006 1.313l-.859 3.292a2.704 2.704 0 0 1-5.233 0l-1.21-4.636Z" />
          <path d="M235.736 297.931c2.161-.214 4.38-.629 6.006-1.313.4-.169.816-.15 1.296-.031l-1.21 4.636a2.705 2.705 0 0 1-5.233 0l-.859-3.292Z" />
        </>
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <>
          <path d="M256.928 299.981c3.065 1.083 4.666 2.738 6.745 3.738l-.268 1.027a2.704 2.704 0 0 1-5.233 0l-1.244-4.765Z" />
          <path d="m243.072 299.981-1.244 4.765a2.705 2.705 0 0 1-5.233 0l-.268-1.027c2.079-1 3.68-2.654 6.745-3.738Z" />
        </>
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="m264.105 302.026-.7 2.684a2.704 2.704 0 0 1-2.617 2.021h-.467c-.955 0-1.79-.645-2.031-1.57l-1.126-4.312c1.551.083 2.975.341 4.409.722.77.205 1.636.351 2.532.455Zm-21.269-1.177-1.126 4.312a2.099 2.099 0 0 1-2.031 1.57h-.467a2.704 2.704 0 0 1-2.617-2.021l-.7-2.684c.896-.104 1.762-.25 2.532-.455 1.434-.381 2.858-.639 4.409-.722Z" />
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <>
          <path d="M256.97 302.208c2.37.018 5.014.031 7.629.028l-1.194 4.577a2.704 2.704 0 0 1-5.233 0l-1.202-4.605Z" />
          <path d="m243.03 302.208-1.202 4.605a2.705 2.705 0 0 1-5.233 0l-1.194-4.577c2.615.003 5.259-.01 7.629-.028Z" />
        </>
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <>
          <path d="M257.023 307.063c1.957.477 4.683 1.261 7.046 1.855l-.664 2.545a2.703 2.703 0 0 1-5.233 0l-1.149-4.4Z" />
          <path d="m243.013 306.925-1.185 4.538a2.704 2.704 0 0 1-5.233 0l-.713-2.734c2.514-.657 5.329-1.395 7.131-1.804Z" />
        </>
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <>
          <path d="m264.666 304.988-1.261 4.835a2.703 2.703 0 0 1-5.233 0l-1.25-4.789c2.513-.009 5.193-.023 7.744-.046Z" />
          <path d="M235.334 304.988c2.552.023 5.231.037 7.744.046l-1.25 4.789a2.704 2.704 0 0 1-5.233 0l-1.261-4.835Z" />
        </>
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <>
          <path d="M256.942 304.211a7.354 7.354 0 0 1 1.264-.235c2.236-.202 4.298.087 6.316.664l-1.117 4.282a2.704 2.704 0 0 1-5.233 0l-1.23-4.711Z" />
          <path d="M235.478 304.64c2.018-.577 4.08-.866 6.316-.664.431.039.851.123 1.264.235l-1.23 4.711a2.705 2.705 0 0 1-5.233 0l-1.117-4.282Z" />
        </>
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <>
          <path d="m264.678 303.925-1.273 4.877a2.703 2.703 0 0 1-5.233 0l-.721-2.76c1.024-.198 1.859-.406 2.332-.591 1.191-.464 2.962-1.013 4.895-1.526Z" />
          <path d="M235.322 303.925c1.933.513 3.704 1.062 4.895 1.526.473.185 1.308.393 2.332.591l-.721 2.76a2.704 2.704 0 0 1-5.233 0l-1.273-4.877Z" />
        </>
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <>
          <path d="m257.23 301.39.942 3.608a2.705 2.705 0 0 0 5.233 0l.933-3.575a22.136 22.136 0 0 0-7.108-.033Z" />
          <path d="m235.662 301.423.933 3.575a2.704 2.704 0 0 0 5.233 0l.942-3.608a22.107 22.107 0 0 0-7.108.033Z" />
        </>
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <>
          <path d="m264.68 301.175-1.275 4.888a2.703 2.703 0 0 1-5.233 0l-1.019-3.904c3.24-.286 5.341-.747 7.527-.984Z" />
          <path d="M235.32 301.175c2.186.237 4.287.698 7.527.984l-1.019 3.904a2.704 2.704 0 0 1-5.233 0l-1.275-4.888Z" />
        </>
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M256.94 296.556c2.193.063 4.841.128 7.638.224l-1.173 4.497a2.703 2.703 0 0 1-5.233 0l-1.232-4.721Z" />
          <path d="m243.06 296.556-1.232 4.721a2.704 2.704 0 0 1-5.233 0l-1.173-4.497c2.796-.096 5.445-.161 7.638-.224Z" />
        </>
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="M256.841 296.958c2.568.081 5.515.155 7.864.137l-1.3 4.981a2.704 2.704 0 0 1-5.233 0l-1.314-5.035a.763.763 0 0 1-.017-.083Z" />
          <path d="M243.159 296.958a.763.763 0 0 1-.017.083l-1.314 5.035a2.705 2.705 0 0 1-5.233 0l-1.3-4.981c2.349.018 5.295-.056 7.864-.137Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <path d="M256.854 299.283c1.881-.038 3.728.021 5.028.41.823.246 1.688.473 2.557.677l-1.034 3.963a2.704 2.704 0 0 1-5.233 0l-1.314-5.035-.004-.015Zm-21.293 1.087a44.535 44.535 0 0 0 2.557-.677c1.3-.389 3.147-.448 5.028-.41a.083.083 0 0 1-.004.015l-1.314 5.035a2.703 2.703 0 0 1-5.233 0l-1.034-3.963Z" />
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="M256.839 300.388c.563.032 1.245.119 2.096.28 1.672.316 3.591.769 5.414 1.232l-.944 3.619a2.704 2.704 0 0 1-5.233 0l-1.314-5.035a.627.627 0 0 1-.019-.096Z" />
          <path d="M243.161 300.388a.627.627 0 0 1-.019.096l-1.314 5.035a2.705 2.705 0 0 1-5.233 0l-.944-3.619c1.823-.463 3.742-.916 5.414-1.232a16.437 16.437 0 0 1 2.096-.28Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="m264.618 304.931-1.213 4.651a2.704 2.704 0 0 1-5.233 0l-1.204-4.612a674.41 674.41 0 0 0 7.65-.039Z" />
          <path d="M235.382 304.931c2.346.029 5.126.038 7.65.039l-1.204 4.612a2.705 2.705 0 0 1-5.233 0l-1.213-4.651Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="M256.897 300.308c.798-.306 1.644-.518 3.035-.444 1.08.057 2.424 1.319 4.476 1.484l-1.003 3.845a2.704 2.704 0 0 1-5.233 0l-1.275-4.885Z" />
          <path d="M235.592 301.348c2.052-.165 3.396-1.427 4.476-1.484 1.391-.074 2.237.138 3.035.444l-1.275 4.885a2.705 2.705 0 0 1-5.233 0l-1.003-3.845Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="m264.714 301.89-1.309 5.018a2.704 2.704 0 0 1-5.233 0l-.815-3.12c1.995-.354 4.464-.933 7.357-1.898Z" />
          <path d="M235.286 301.89c2.893.965 5.363 1.544 7.357 1.898l-.815 3.12a2.705 2.705 0 0 1-5.233 0l-1.309-5.018Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="M256.919 300.794c2.774.042 5.234.403 7.53.797l-1.044 4.003a2.704 2.704 0 0 1-5.233 0l-1.253-4.8Z" />
          <path d="m243.081 300.794-1.253 4.8a2.705 2.705 0 0 1-5.233 0l-1.044-4.003c2.296-.394 4.755-.755 7.53-.797Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="M257.001 299.038c.111.049.224.101.34.154 1.95.894 4.472 1.034 6.996.761l-.932 3.571a2.704 2.704 0 0 1-5.233 0l-1.171-4.486Z" />
          <path d="m242.999 299.038-1.171 4.486a2.705 2.705 0 0 1-5.233 0l-.932-3.571c2.524.273 5.046.133 6.996-.761.116-.053.229-.105.34-.154Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="M256.938 300.037c2.255.699 4.472 1.989 6.941 2.909l-.474 1.817a2.704 2.704 0 0 1-5.233 0l-1.234-4.726Z" />
          <path d="m243.062 300.037-1.234 4.726a2.705 2.705 0 0 1-5.233 0l-.474-1.817c2.469-.92 4.686-2.211 6.941-2.909Z" />
        </>
      ),
    },
  },
};

const teeth_shape_fNzHNiDDtj_lower = {
  id: "teeth_shape_fNzHNiDDtj_lower",
  name: "Teeth 5 (Lower)",
  description: "Short, rounded teeth/tusks",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <>
          <path d="m256.865 316.438 1.307-5.009a2.705 2.705 0 0 1 5.233 0l1.071 4.105c-2.155.863-4.617.97-7.611.904Z" />
          <path d="m235.528 315.521 1.067-4.092a2.704 2.704 0 0 1 5.233 0l1.311 5.022c-2.939.028-5.518-.135-7.611-.93Z" />
        </>
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <>
          <path d="m237.384 312.734 1.298-4.971a2.703 2.703 0 0 1 2.616-2.021c.396 0 .775.086 1.118.242-1.443 1.613-3.342 4.342-5.032 6.75Z" />
          <path d="M257.59 305.981a2.703 2.703 0 0 1 3.728 1.782l1.296 4.964c-1.688-2.408-3.585-5.133-5.024-6.746Z" />
        </>
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <>
          <path d="M235.258 306.477a.773.773 0 0 1 .023-.234l1.314-5.034a2.704 2.704 0 0 1 5.233 0l.541 2.072c-.797.014-1.582.184-2.539.639-1.316.62-2.936 1.562-4.572 2.557Z" />
          <path d="m257.629 303.287.543-2.078a2.705 2.705 0 0 1 5.233 0l1.314 5.034a.756.756 0 0 1 .023.226c-1.629-.99-3.242-1.928-4.552-2.549-.967-.454-1.759-.621-2.561-.633Z" />
        </>
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <>
          <path d="m257.645 308.111.527-2.02a2.705 2.705 0 0 1 5.233 0l1.276 4.891c-2.628-.654-4.952-1.862-7.036-2.871Z" />
          <path d="m235.345 310.881 1.25-4.79a2.704 2.704 0 0 1 5.233 0l.498 1.906c-2.081.99-4.394 2.193-6.981 2.884Z" />
        </>
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <>
          <path d="M261.985 311.97a.761.761 0 0 1-.133.655.76.76 0 0 1-.6.294h-6.395a.759.759 0 0 1-.733-.949l1.314-5.034a2.705 2.705 0 0 1 2.617-2.022h.466a2.1 2.1 0 0 1 2.032 1.57l1.432 5.486Z" />
          <path d="M245.876 311.97a.758.758 0 0 1-.733.949h-6.395a.76.76 0 0 1-.6-.294.761.761 0 0 1-.133-.655l1.314-5.034a2.704 2.704 0 0 1 2.616-2.022h.467a2.1 2.1 0 0 1 2.032 1.57l1.432 5.486Z" />
        </>
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <>
          <path d="M264.719 304.96a.757.757 0 0 1-.733.949h-6.395a.758.758 0 0 1-.733-.949l1.314-5.035a2.704 2.704 0 0 1 2.616-2.021h.467a2.1 2.1 0 0 1 2.032 1.57l1.432 5.486Z" />
          <path d="M243.142 304.96a.758.758 0 0 1-.733.949h-6.395a.757.757 0 0 1-.733-.949l1.314-5.035a2.705 2.705 0 0 1 2.617-2.021h.467c.955 0 1.79.645 2.031 1.57l1.432 5.486Z" />
        </>
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <>
          <path d="M264.719 304.787a.758.758 0 0 1-.733.949h-6.395a.757.757 0 0 1-.733-.949l1.314-5.034a2.704 2.704 0 0 1 2.616-2.022h.467a2.1 2.1 0 0 1 2.032 1.57l1.432 5.486Z" />
          <path d="M243.142 304.787a.757.757 0 0 1-.733.949h-6.395a.756.756 0 0 1-.733-.949l1.314-5.034a2.705 2.705 0 0 1 2.617-2.022h.467a2.1 2.1 0 0 1 2.031 1.57l1.432 5.486Z" />
        </>
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M264.719 312.704a.756.756 0 0 1-.733.949h-6.395a.758.758 0 0 1-.733-.949l1.314-5.035a2.703 2.703 0 0 1 2.616-2.021h.467a2.1 2.1 0 0 1 2.032 1.569l1.432 5.487Z" />
          <path d="M243.142 312.704a.759.759 0 0 1-.733.949h-6.395a.757.757 0 0 1-.733-.949l1.314-5.035a2.704 2.704 0 0 1 2.617-2.021h.467a2.1 2.1 0 0 1 2.031 1.569l1.432 5.487Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <>
          <path d="M243.142 311.525a.758.758 0 0 1-.733.949h-6.395a.757.757 0 0 1-.733-.949l1.314-5.035a2.705 2.705 0 0 1 2.617-2.021h.467c.955 0 1.79.645 2.031 1.57l1.432 5.486Z" />
          <path d="M264.719 311.525a.757.757 0 0 1-.733.949h-6.395a.758.758 0 0 1-.733-.949l1.314-5.035a2.704 2.704 0 0 1 2.616-2.021h.467a2.1 2.1 0 0 1 2.032 1.57l1.432 5.486Z" />
        </>
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="M264.719 312.517a.757.757 0 0 1-.733.949h-6.395a.758.758 0 0 1-.733-.949l1.314-5.035a2.704 2.704 0 0 1 2.616-2.021h.467a2.1 2.1 0 0 1 2.032 1.57l1.432 5.486Z" />
          <path d="M243.142 312.517a.758.758 0 0 1-.733.949h-6.395a.757.757 0 0 1-.733-.949l1.314-5.035a2.705 2.705 0 0 1 2.617-2.021h.467c.955 0 1.79.645 2.031 1.57l1.432 5.486Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <>
          <path d="M262.855 313.058a.756.756 0 0 1-.733.949h-6.395a.757.757 0 0 1-.733-.949l1.314-5.035a2.704 2.704 0 0 1 2.617-2.021h.467c.955 0 1.79.645 2.032 1.569l1.431 5.487Z" />
          <path d="M245.006 313.058a.756.756 0 0 1-.733.949h-6.395a.758.758 0 0 1-.733-.949l1.314-5.035a2.703 2.703 0 0 1 2.616-2.021h.467a2.1 2.1 0 0 1 2.032 1.569l1.432 5.487Z" />
        </>
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <>
          <path d="M264.719 310.973a.756.756 0 0 1-.733.948h-6.395a.757.757 0 0 1-.733-.948l1.314-5.035a2.703 2.703 0 0 1 2.616-2.021h.467a2.1 2.1 0 0 1 2.032 1.569l1.432 5.487Z" />
          <path d="M243.142 310.973a.759.759 0 0 1-.733.948h-6.395a.756.756 0 0 1-.733-.948l1.314-5.035a2.704 2.704 0 0 1 2.617-2.021h.467a2.1 2.1 0 0 1 2.031 1.569l1.432 5.487Z" />
        </>
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="m262.259 302.345-.127-.488a2.703 2.703 0 0 0-2.616-2.021h-.001c-1.23 0-2.305.83-2.616 2.021l-1.305 5.001c2.087-1.506 4.397-3.199 6.665-4.513Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="m237.741 302.345.127-.488a2.703 2.703 0 0 1 2.616-2.021h.001c1.23 0 2.305.83 2.616 2.021l1.305 5.001c-2.087-1.506-4.397-3.199-6.665-4.513Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <>
          <path d="M255.475 305.47a.766.766 0 0 1 .021-.273l1.313-5.035a2.703 2.703 0 0 1 5.233 0c.422 1.613.992 3.801 1.314 5.035.024.09.03.183.021.273h-7.902Z" />
          <path d="M236.623 305.47a.766.766 0 0 1 .021-.273c.322-1.234.892-3.422 1.314-5.035a2.705 2.705 0 0 1 5.233 0l1.313 5.035c.024.09.03.183.021.273h-7.902Z" />
        </>
      ),
    },
    mouth_shape_74Nrw: {
      base: (
        <>
          <path d="m256.871 305.47 1.301-4.984a2.705 2.705 0 0 1 5.233 0l1.3 4.984h-7.834Z" />
          <path d="m235.295 305.47 1.3-4.984a2.704 2.704 0 0 1 5.233 0l1.301 4.984h-7.834Z" />
        </>
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <>
          <path d="m255.906 305.408.006-.025 1.314-5.035a2.705 2.705 0 0 1 2.616-2.021h.001c1.23 0 2.305.831 2.616 2.021l.731 2.802a32.453 32.453 0 0 1-7.284 2.258Z" />
          <path d="m236.809 303.154.732-2.806a2.705 2.705 0 0 1 2.616-2.021h.001c1.23 0 2.305.831 2.616 2.021a101999.982 101999.982 0 0 1 1.32 5.062 32.44 32.44 0 0 1-7.285-2.256Z" />
        </>
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <>
          <path d="m235.603 303.766.992-3.802a2.704 2.704 0 0 1 5.233 0l1.314 5.035a.812.812 0 0 1 .025.176 57.95 57.95 0 0 1-7.564-1.409Z" />
          <path d="M256.833 305.174a.803.803 0 0 1 .025-.175l1.314-5.035a2.705 2.705 0 0 1 5.233 0l.991 3.8a58.243 58.243 0 0 1-7.563 1.41Z" />
        </>
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <>
          <path d="m236.416 305.467 1.311-5.024a2.705 2.705 0 0 1 5.233 0l.717 2.748a33.41 33.41 0 0 0-7.261 2.276Z" />
          <path d="m256.323 303.193.717-2.75a2.704 2.704 0 0 1 5.233 0l1.313 5.029a33.398 33.398 0 0 0-7.263-2.279Z" />
        </>
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <>
          <path d="m257.17 300.04 1.002-3.84a2.704 2.704 0 0 1 5.233 0l1.314 5.035c.02.078.028.159.023.238a59.025 59.025 0 0 0-7.572-1.433Z" />
          <path d="M235.258 301.47a.749.749 0 0 1 .023-.235l1.314-5.035a2.703 2.703 0 0 1 5.233 0l1.002 3.838a59.162 59.162 0 0 0-7.572 1.432Z" />
        </>
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <>
          <path d="m257.242 305.449.93-3.561a2.705 2.705 0 0 1 5.233 0l.917 3.516c-2.176.02-4.574.035-7.08.045Z" />
          <path d="m235.678 305.404.917-3.516a2.704 2.704 0 0 1 5.233 0l.93 3.561c-2.506-.01-4.905-.025-7.08-.045Z" />
        </>
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <>
          <path d="m257.411 300.773.761-2.916a2.704 2.704 0 0 1 5.233 0l1.27 4.868c-4.266-.564-3.099-1.963-6.504-1.978a9.327 9.327 0 0 0-.76.026Z" />
          <path d="m235.325 302.725 1.27-4.868a2.703 2.703 0 0 1 5.233 0l.761 2.916a9.327 9.327 0 0 0-.76-.026c-3.405.015-2.238 1.414-6.504 1.978Z" />
        </>
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <>
          <path d="m235.451 309.089 1.144-4.383a2.703 2.703 0 0 1 5.233 0l1.276 4.889c-3.909-.018-5.506.104-7.653-.506Z" />
          <path d="m256.896 309.595 1.276-4.889a2.704 2.704 0 0 1 5.233 0l1.144 4.383c-2.147.61-3.744.488-7.653.506Z" />
        </>
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <>
          <path d="m254.197 311.092 1.313-5.031a2.703 2.703 0 0 1 5.233 0l.547 2.097c-2.106.902-4.396 2.175-7.093 2.934Z" />
          <path d="m238.71 308.158.547-2.097a2.704 2.704 0 0 1 5.233 0l1.313 5.031c-2.697-.759-4.987-2.033-7.093-2.934Z" />
        </>
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <>
          <path d="m257.111 305.266 1.061-4.064a2.704 2.704 0 0 1 5.233 0l1.314 5.035c.012.047.02.095.023.144-1.969-.473-4.078-.897-6.075-1.08a9.931 9.931 0 0 0-1.556-.035Z" />
          <path d="M235.258 306.381a.772.772 0 0 1 .023-.144l1.314-5.035a2.703 2.703 0 0 1 5.233 0l1.061 4.064a9.931 9.931 0 0 0-1.556.035c-1.997.183-4.106.607-6.075 1.08Z" />
        </>
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <>
          <path d="m256.915 303.33 1.257-4.816a2.705 2.705 0 0 1 5.233 0l1.268 4.859c-2.581 0-5.254-.021-7.758-.043Z" />
          <path d="m235.327 303.372 1.268-4.858a2.704 2.704 0 0 1 5.233 0l1.256 4.811c-2.484.022-5.156.044-7.757.047Z" />
        </>
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <>
          <path d="m256.903 311.28 1.269-4.86a2.705 2.705 0 0 1 5.233 0l1.263 4.84c-2.401.009-5.032.016-7.765.02Z" />
          <path d="m235.332 311.26 1.263-4.84a2.704 2.704 0 0 1 5.233 0l1.269 4.86c-2.734-.004-5.364-.011-7.765-.02Z" />
        </>
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <>
          <path d="m257.282 296.522.89-3.409a2.705 2.705 0 0 1 5.233 0l1.267 4.857c-2.286-.202-4.686-.624-6.414-1.352-.309-.13-.627-.149-.976-.096Z" />
          <path d="m235.328 297.97 1.267-4.857a2.704 2.704 0 0 1 5.233 0l.89 3.409c-.349-.053-.667-.034-.976.096-1.728.728-4.128 1.15-6.414 1.352Z" />
        </>
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <>
          <path d="m256.987 303.586 1.185-4.538a2.705 2.705 0 0 1 5.233 0l1.314 5.034.014.067a8.994 8.994 0 0 1-.391-.14c-.866.027-1.777.041-2.71.029-1.547-.021-3.073-.232-4.645-.452Z" />
          <path d="m235.267 304.149.014-.067 1.314-5.034a2.704 2.704 0 0 1 5.233 0l1.185 4.538c-1.572.22-3.098.431-4.645.452a61.634 61.634 0 0 1-2.71-.029 8.994 8.994 0 0 1-.391.14Z" />
        </>
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="m234.998 302.116.922-3.53a2.702 2.702 0 0 1 2.616-2.021h.467c.955 0 1.79.645 2.032 1.57l1.055 4.044c-1.358-.142-3.098-.239-5.393-.259-.552.083-1.124.147-1.699.196Zm22.912.063 1.055-4.044a2.101 2.101 0 0 1 2.032-1.57h.467c1.231 0 2.306.83 2.616 2.021l.922 3.53a26.782 26.782 0 0 1-1.699-.196c-2.295.02-4.035.117-5.393.259Z" />
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <>
          <path d="m256.916 302.207 1.256-4.812a2.705 2.705 0 0 1 5.233 0l1.263 4.84c-2.657.005-5.347-.009-7.752-.028Z" />
          <path d="m235.332 302.235 1.263-4.84a2.704 2.704 0 0 1 5.233 0l1.256 4.812c-2.405.019-5.095.033-7.752.028Z" />
        </>
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <>
          <path d="m257 313.547 1.172-4.489a2.705 2.705 0 0 1 5.233 0l1.148 4.399c-2.11.765-4.642.605-7.553.09Z" />
          <path d="m236.221 310.491.374-1.433a2.704 2.704 0 0 1 5.233 0l.465 1.78a43.416 43.416 0 0 0-2.813-.168 63.53 63.53 0 0 1-3.259-.179Z" />
        </>
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <>
          <path d="m256.904 305.034 1.268-4.857a2.705 2.705 0 0 1 5.233 0l1.255 4.811c-2.556.023-5.239.037-7.756.046Z" />
          <path d="m235.34 304.988 1.255-4.811a2.704 2.704 0 0 1 5.233 0l1.268 4.857c-2.517-.009-5.2-.023-7.756-.046Z" />
        </>
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <>
          <path d="m257.228 304.138.944-3.616a2.704 2.704 0 0 1 5.233 0l1.071 4.105c-2.004-.568-4.051-.851-6.27-.651a7.1 7.1 0 0 0-.978.162Z" />
          <path d="m235.524 304.627 1.071-4.105a2.703 2.703 0 0 1 5.233 0l.944 3.616a7.1 7.1 0 0 0-.978-.162c-2.219-.2-4.266.083-6.27.651Z" />
        </>
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <>
          <path d="m256.919 306.141 1.253-4.8a2.705 2.705 0 0 1 5.233 0l.713 2.734c-1.713.469-3.26.957-4.335 1.376-.552.216-1.599.463-2.864.69Z" />
          <path d="m235.882 304.075.713-2.734a2.704 2.704 0 0 1 5.233 0l1.253 4.8c-1.265-.227-2.312-.474-2.864-.69-1.075-.419-2.622-.907-4.335-1.376Z" />
        </>
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <>
          <path d="m256.935 301.438 1.237-4.74a2.704 2.704 0 0 1 5.233 0l1.247 4.781a22.122 22.122 0 0 0-7.717-.041Z" />
          <path d="m235.348 301.479 1.247-4.781a2.703 2.703 0 0 1 5.233 0l1.237 4.74a22.123 22.123 0 0 0-7.717.041Z" />
        </>
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <>
          <path d="M256.835 302.186a.737.737 0 0 1 .023-.138l1.314-5.035a2.705 2.705 0 0 1 5.233 0l1.091 4.182c-2.192.251-4.332.716-7.661.991Z" />
          <path d="m235.504 301.195 1.091-4.182a2.704 2.704 0 0 1 5.233 0l1.314 5.035c.012.046.02.092.023.138-3.329-.275-5.469-.74-7.661-.991Z" />
        </>
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="m256.923 311.972 1.249-4.786a2.704 2.704 0 0 1 5.233 0l1.004 3.848c-2.574.596-5.169.841-7.486.938Z" />
          <path d="m235.591 311.034 1.004-3.848a2.703 2.703 0 0 1 5.233 0l1.249 4.786c-2.317-.097-4.912-.342-7.486-.938Z" />
        </>
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="m256.856 296.959.002-.008 1.314-5.035a2.704 2.704 0 0 1 5.233 0l1.314 5.035c.012.047.02.095.023.144-2.35.019-5.308-.056-7.886-.136Z" />
          <path d="M235.258 297.095a.772.772 0 0 1 .023-.144l1.314-5.035a2.703 2.703 0 0 1 5.233 0l1.314 5.035.002.008c-2.578.08-5.536.155-7.886.136Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="m257.086 306.336 1.086-4.162a2.705 2.705 0 0 1 5.233 0l.387 1.485c-2.252 1.022-4.215 2.022-4.981 2.335-.283.116-.919.233-1.725.342Z" />
          <path d="m236.208 303.659.387-1.485a2.704 2.704 0 0 1 5.233 0l1.086 4.162c-.806-.109-1.442-.226-1.725-.342-.766-.313-2.729-1.313-4.981-2.335Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="m254.952 304.763 1.274-4.882a2.704 2.704 0 0 1 5.233 0l.428 1.638c-.508.217-.999.504-1.595.823-.999.535-2.161 1.737-5.34 2.421Z" />
          <path d="m238.113 301.519.428-1.638a2.705 2.705 0 0 1 5.233 0l1.274 4.882c-3.179-.684-4.341-1.886-5.34-2.421-.596-.319-1.087-.606-1.595-.823Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="m256.891 304.97 1.281-4.907a2.704 2.704 0 0 1 5.233 0l1.27 4.868c-2.382.029-5.219.039-7.784.039Z" />
          <path d="m235.325 304.931 1.27-4.868a2.703 2.703 0 0 1 5.233 0l1.281 4.907c-2.565 0-5.402-.01-7.784-.039Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="m257.192 300.201.98-3.755a2.704 2.704 0 0 1 5.233 0l1.284 4.921a11.695 11.695 0 0 1-.215-.014c-2.089-.143-3.45-1.432-4.542-1.489-1.222-.065-2.022.091-2.74.337Z" />
          <path d="m235.311 301.367 1.284-4.921a2.703 2.703 0 0 1 5.233 0l.98 3.755c-.718-.246-1.518-.402-2.74-.337-1.092.057-2.453 1.346-4.542 1.489l-.215.014Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="m257.06 303.84 1.112-4.261a2.705 2.705 0 0 1 5.233 0l.658 2.524a51.111 51.111 0 0 1-7.003 1.737Z" />
          <path d="m235.937 302.103.658-2.524a2.704 2.704 0 0 1 5.233 0l1.112 4.261a51.111 51.111 0 0 1-7.003-1.737Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="m257.096 300.797 1.076-4.121a2.705 2.705 0 0 1 5.233 0l1.293 4.957c-2.319-.401-4.8-.779-7.602-.836Z" />
          <path d="m235.302 301.633 1.293-4.957a2.704 2.704 0 0 1 5.233 0l1.076 4.121c-2.802.057-5.283.435-7.602.836Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="m256.936 300.401 1.236-4.734a2.705 2.705 0 0 1 5.233 0l1.113 4.265c-1.58.184-3.164.208-4.612-.009-.953.186-1.945.349-2.97.478Z" />
          <path d="m235.482 299.932 1.113-4.265a2.704 2.704 0 0 1 5.233 0l1.236 4.734a44.548 44.548 0 0 1-2.97-.478c-1.448.217-3.032.192-4.612.009Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="m257.62 300.266.552-2.116a2.704 2.704 0 0 1 5.233 0l1.314 5.035.013.058c-2.564-.833-4.834-2.159-7.112-2.977Z" />
          <path d="m235.268 303.243.013-.058 1.314-5.035a2.703 2.703 0 0 1 5.233 0l.552 2.116c-2.279.818-4.548 2.144-7.112 2.977Z" />
        </>
      ),
    },
  },
};

const teeth_shape_LzydJYaCxp_upper = {
  id: "teeth_shape_LzydJYaCxp_upper",
  name: "Teeth 6 (Upper)",
  description: "Long, squared teeth/tusks",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <>
          <path d="M256.76 302.398a.774.774 0 0 0-.008.284l1.844 11.41c.06.367.376.636.748.636h2.888a.757.757 0 0 0 .748-.636l1.262-7.806c-1.264-1.14-2.481-2.184-3.529-2.966-1.505-1.122-2.61-1.189-3.953-.922Z" />
          <path d="m235.758 306.283 1.262 7.809a.757.757 0 0 0 .748.636h2.888a.757.757 0 0 0 .748-.636l1.844-11.41a.763.763 0 0 0-.009-.286c-1.338-.265-2.44-.195-3.942.924-1.053.78-2.273 1.823-3.539 2.963Z" />
        </>
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <>
          <path d="M256.806 305.228c.203.153.415.348.639.592 1.702 1.859 4.109 5.415 6.036 8.141l-.501 3.102a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.844-11.409a.764.764 0 0 1 .054-.426Z" />
          <path d="M243.195 305.23a.767.767 0 0 1 .053.424l-1.844 11.409a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-.501-3.1c1.93-2.725 4.339-6.287 6.046-8.143.221-.243.429-.437.63-.59Z" />
        </>
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <>
          <path d="M235.611 306.257c1.511-.912 2.99-1.763 4.209-2.337 1.306-.622 2.293-.71 3.426-.599a.81.81 0 0 1-.007.19l-1.844 11.409a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.4-8.663Z" />
          <path d="M256.736 303.325c1.138-.114 2.13-.029 3.444.595 1.213.575 2.687 1.422 4.193 2.331l-1.402 8.669a.757.757 0 0 1-.747.637h-2.889a.757.757 0 0 1-.747-.637l-1.845-11.409a.775.775 0 0 1-.007-.186Z" />
        </>
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <>
          <path d="m256.815 308.817 1.781 11.022c.06.367.376.637.748.637h2.888c.372 0 .689-.27.748-.637l1.284-7.945c-2.794-.733-5.254-2.041-7.449-3.077Z" />
          <path d="M243.207 308.684c-2.217 1.023-4.697 2.344-7.487 3.116l1.3 8.039a.758.758 0 0 0 .748.637h2.888c.372 0 .688-.27.748-.637l1.803-11.155Z" />
        </>
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <>
          <path d="M262.21 304.33a.758.758 0 0 0-.748-.878h-6.577a.758.758 0 0 0-.748.878l1.844 11.409a.76.76 0 0 0 .748.637h2.889c.371 0 .688-.27.747-.637l1.845-11.409Z" />
          <path d="M245.863 304.33a.76.76 0 0 0-.748-.878h-6.577a.758.758 0 0 0-.748.878l1.845 11.409a.757.757 0 0 0 .747.637h2.889a.76.76 0 0 0 .748-.637l1.844-11.409Z" />
        </>
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <>
          <path d="M264.824 300.316a.755.755 0 0 0-.748-.879H257.5a.759.759 0 0 0-.748.879l1.844 11.409c.06.367.376.637.748.637h2.888c.372 0 .689-.27.748-.637l1.844-11.409Z" />
          <path d="M243.248 300.316a.76.76 0 0 0-.748-.879h-6.576a.757.757 0 0 0-.748.879l1.844 11.409a.758.758 0 0 0 .748.637h2.888c.372 0 .688-.27.748-.637l1.844-11.409Z" />
        </>
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <>
          <path d="M264.824 301.51a.755.755 0 0 0-.748-.878H257.5a.758.758 0 0 0-.748.878l1.844 11.409c.06.367.376.637.748.637h2.888c.372 0 .689-.27.748-.637l1.844-11.409Z" />
          <path d="M243.248 301.51a.76.76 0 0 0-.748-.878h-6.576a.756.756 0 0 0-.748.878l1.844 11.409a.758.758 0 0 0 .748.637h2.888c.372 0 .688-.27.748-.637l1.844-11.409Z" />
        </>
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M264.824 308.586a.755.755 0 0 0-.748-.879H257.5a.759.759 0 0 0-.748.879l1.844 11.409c.06.367.376.637.748.637h2.888c.372 0 .689-.27.748-.637l1.844-11.409Z" />
          <path d="M243.248 308.586a.76.76 0 0 0-.748-.879h-6.576a.757.757 0 0 0-.748.879l1.844 11.409a.758.758 0 0 0 .748.637h2.888c.372 0 .688-.27.748-.637l1.844-11.409Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <>
          <path d="M264.824 302.533a.755.755 0 0 0-.748-.878H257.5a.758.758 0 0 0-.748.878l1.844 11.409c.06.367.376.637.748.637h2.888c.372 0 .689-.27.748-.637l1.844-11.409Z" />
          <path d="M243.248 302.533a.76.76 0 0 0-.748-.878h-6.576a.756.756 0 0 0-.748.878l1.844 11.409a.758.758 0 0 0 .748.637h2.888c.372 0 .688-.27.748-.637l1.844-11.409Z" />
        </>
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="M264.824 302.533a.755.755 0 0 0-.748-.878H257.5a.758.758 0 0 0-.748.878l1.844 11.409c.06.367.376.637.748.637h2.888c.372 0 .689-.27.748-.637l1.844-11.409Z" />
          <path d="M243.248 302.533a.76.76 0 0 0-.748-.878h-6.576a.756.756 0 0 0-.748.878l1.844 11.409a.758.758 0 0 0 .748.637h2.888c.372 0 .688-.27.748-.637l1.844-11.409Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <>
          <path d="M262.756 301.858a.758.758 0 0 0-.748-.879h-6.576a.759.759 0 0 0-.748.879l1.844 11.409a.756.756 0 0 0 .748.636h2.888a.756.756 0 0 0 .748-.636l1.844-11.409Z" />
          <path d="M245.316 301.858a.759.759 0 0 0-.748-.879h-6.576a.759.759 0 0 0-.748.879l1.844 11.409c.06.367.376.637.748.636h2.888a.756.756 0 0 0 .748-.636l1.844-11.409Z" />
        </>
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <>
          <path d="M264.824 302.533a.755.755 0 0 0-.748-.878H257.5a.758.758 0 0 0-.748.878l1.844 11.409c.06.367.376.637.748.637h2.888c.372 0 .689-.27.748-.637l1.844-11.409Z" />
          <path d="M243.248 302.533a.76.76 0 0 0-.748-.878h-6.576a.756.756 0 0 0-.748.878l1.844 11.409a.758.758 0 0 0 .748.637h2.888c.372 0 .688-.27.748-.637l1.844-11.409Z" />
        </>
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="m263.348 301.753-1.836 11.361a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.064-6.585c2.301-1.663 4.841-3.496 7.284-4.776Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="m236.652 301.753 1.836 11.361a.758.758 0 0 0 .748.637h2.888c.372 0 .689-.27.748-.637l1.064-6.585c-2.301-1.663-4.841-3.496-7.284-4.776Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <>
          <path d="M245.836 306.47h-8.072l1.844 11.408c.06.367.377.636.748.636h2.888a.757.757 0 0 0 .748-.636l1.844-11.408Z" />
          <path d="M262.226 306.47h-8.072l1.844 11.408a.757.757 0 0 0 .748.636h2.888a.757.757 0 0 0 .748-.636l1.844-11.408Z" />
        </>
      ),
    },
    mouth_shape_74Nrw: {
      base: (
        <>
          <path d="M264.805 306.47h-8.033l1.824 11.288c.06.367.376.636.748.636h2.888a.757.757 0 0 0 .748-.636l1.825-11.288Z" />
          <path d="M243.228 306.47h-8.033l1.825 11.288a.757.757 0 0 0 .748.636h2.888a.757.757 0 0 0 .748-.636l1.824-11.288Z" />
        </>
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <>
          <path d="M263.135 304.264a33.446 33.446 0 0 1-7.626 2.229l1.441 8.916c.06.366.376.636.748.636h2.888c.372 0 .689-.27.748-.636l1.801-11.145Z" />
          <path d="m236.865 304.269 1.801 11.14a.758.758 0 0 0 .748.636h2.888c.372 0 .688-.27.748-.636l1.441-8.914a33.453 33.453 0 0 1-7.626-2.226Z" />
        </>
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <>
          <path d="M264.75 304.705a59.202 59.202 0 0 1-7.69 1.449l1.536 9.503c.06.367.376.636.748.636h2.888a.757.757 0 0 0 .748-.636l1.77-10.952Z" />
          <path d="m235.25 304.708 1.77 10.949a.757.757 0 0 0 .748.636h2.888a.757.757 0 0 0 .748-.636l1.536-9.502a59.028 59.028 0 0 1-7.69-1.447Z" />
        </>
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <>
          <path d="M244.174 304.115a32.498 32.498 0 0 0-7.694 2.419l1.46 9.034a.758.758 0 0 0 .748.636h2.888c.372 0 .689-.27.748-.636l1.844-11.41.006-.043Z" />
          <path d="M255.827 304.117a.51.51 0 0 0 .005.041l1.844 11.41a.758.758 0 0 0 .748.636h2.888c.372 0 .689-.27.748-.636l1.459-9.029a32.445 32.445 0 0 0-7.692-2.422Z" />
        </>
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <>
          <path d="M243.254 300.995a57.917 57.917 0 0 0-7.848 1.47l1.614 9.986a.758.758 0 0 0 .748.637h2.888c.372 0 .688-.27.748-.637l1.844-11.409.006-.047Z" />
          <path d="m256.746 300.996.006.046 1.844 11.409c.06.367.376.637.748.637h2.888c.372 0 .689-.27.748-.637l1.614-9.984a58.202 58.202 0 0 0-7.848-1.471Z" />
        </>
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <>
          <path d="M235.221 305.4c2.42.023 5.133.04 7.973.051l-1.79 11.075a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.799-11.126Z" />
          <path d="m264.779 305.4-1.799 11.126a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.79-11.075c2.841-.011 5.554-.028 7.973-.051Z" />
        </>
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <>
          <path d="M235.549 302.694c4.002-.582 2.936-1.932 6.28-1.947.533-.002.972.033 1.344.096l-1.769 10.949a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.471-9.098Z" />
          <path d="M256.827 300.843a7.886 7.886 0 0 1 1.344-.096c3.344.015 2.278 1.365 6.28 1.947l-1.471 9.098a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.769-10.949Z" />
        </>
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <>
          <path d="M256.784 304.415c1.953.457 4.282 1.346 7.391 3.159l.098.054-1.293 7.996a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.812-11.209Z" />
          <path d="m243.216 304.415-1.812 11.209a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.293-7.996.098-.054c3.109-1.813 5.438-2.702 7.391-3.159Z" />
        </>
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <>
          <path d="M253.668 304.572c3.762.62 5.715 2.059 7.548 3.247l-1.32 8.164a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.844-11.409v-.002Z" />
          <path d="M246.332 304.572v.002l-1.844 11.409a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.32-8.164c1.833-1.189 3.786-2.627 7.548-3.247Z" />
        </>
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <>
          <path d="M256.823 305.289a9.415 9.415 0 0 1 1.844.012c1.943.178 3.993.585 5.916 1.042l-1.603 9.916a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.773-10.97Z" />
          <path d="M235.417 306.343c1.923-.457 3.973-.864 5.916-1.042a9.415 9.415 0 0 1 1.844-.012l-1.773 10.97a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.603-9.916Z" />
        </>
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <>
          <path d="M256.881 303.33c2.518.021 5.209.043 7.808.043l-1.709 10.569a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.715-10.612Z" />
          <path d="m243.12 303.325-1.716 10.617a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.709-10.57c2.618-.002 5.31-.025 7.809-.047Z" />
        </>
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <>
          <path d="m264.784 311.253-1.804 11.162a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.798-11.128c2.787-.007 5.486-.018 7.986-.034Z" />
          <path d="M235.216 311.253c2.5.015 5.199.027 7.986.034l-1.798 11.128a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.804-11.162Z" />
        </>
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <>
          <path d="M256.852 296.616c.527-.144.975-.18 1.406.002 1.687.71 4.014 1.129 6.25 1.337l-1.528 9.453a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.744-10.792Z" />
          <path d="M235.492 297.955c2.236-.208 4.563-.627 6.25-1.337.431-.182.879-.146 1.406-.002l-1.744 10.792a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.528-9.453Z" />
        </>
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <>
          <path d="M256.8 299.937c3.373 1.158 4.987 3.012 7.332 3.988l-1.152 7.124a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.796-11.112Z" />
          <path d="m243.2 299.937-1.796 11.112a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.152-7.124c2.345-.976 3.959-2.829 7.332-3.988Z" />
        </>
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <>
          <path d="M264.824 302.533a.755.755 0 0 0-.748-.878H257.5a.758.758 0 0 0-.748.878l1.844 11.409c.06.367.376.637.748.637h2.888c.372 0 .689-.27.748-.637l1.844-11.409Z" />
          <path d="M243.248 302.533a.76.76 0 0 0-.748-.878h-6.576a.756.756 0 0 0-.748.878l1.844 11.409a.758.758 0 0 0 .748.637h2.888c.372 0 .688-.27.748-.637l1.844-11.409Z" />
        </>
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <>
          <path d="M256.823 302.206c2.453.02 5.209.034 7.926.029l-1.769 10.941a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.773-10.97Z" />
          <path d="m243.177 302.206-1.773 10.97a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.769-10.941c2.718.005 5.474-.009 7.926-.029Z" />
        </>
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <>
          <path d="M256.811 307.012c2.069.494 5.101 1.375 7.632 1.999l-1.463 9.048a.756.756 0 0 1-.748.636h-2.888a.756.756 0 0 1-.748-.636l-1.785-11.047Z" />
          <path d="m243.211 306.881-1.807 11.178a.756.756 0 0 1-.748.636h-2.888a.756.756 0 0 1-.748-.636l-1.493-9.238c2.639-.688 5.687-1.491 7.568-1.915l.116-.025Z" />
        </>
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <>
          <path d="m264.709 304.988-1.729 10.697a.758.758 0 0 1-.748.636h-2.888a.758.758 0 0 1-.748-.636l-1.721-10.651c2.541-.009 5.253-.023 7.834-.046Z" />
          <path d="M235.291 304.988c2.581.023 5.294.037 7.834.046l-1.721 10.651a.758.758 0 0 1-.748.636h-2.888a.758.758 0 0 1-.748-.636l-1.729-10.697Z" />
        </>
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <>
          <path d="M256.852 304.236a7.446 7.446 0 0 1 1.354-.26c2.285-.206 4.387.1 6.446.702l-1.672 10.346a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.744-10.788Z" />
          <path d="M235.348 304.678c2.059-.602 4.161-.908 6.446-.702.462.042.912.135 1.354.26l-1.744 10.788a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.672-10.346Z" />
        </>
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <>
          <path d="m264.8 303.892-1.82 11.261a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.463-9.051c1.169-.216 2.129-.448 2.65-.651 1.216-.474 3.036-1.036 5.017-1.559Z" />
          <path d="M235.2 303.892c1.981.523 3.801 1.085 5.017 1.559.521.203 1.481.435 2.65.651l-1.463 9.051a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.82-11.261Z" />
        </>
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <>
          <path d="m235.362 301.476 1.658 10.256a.758.758 0 0 0 .748.637h2.888c.372 0 .688-.27.748-.637l1.664-10.294a22.138 22.138 0 0 0-7.706.038Z" />
          <path d="m256.932 301.438 1.664 10.294c.06.367.376.637.748.637h2.888c.372 0 .689-.27.748-.637l1.658-10.256a22.162 22.162 0 0 0-7.706-.038Z" />
        </>
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <>
          <path d="m264.751 301.167-1.771 10.954a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.608-9.948c3.37-.287 5.514-.767 7.763-1.006Z" />
          <path d="M235.249 301.167c2.249.239 4.393.719 7.763 1.006l-1.608 9.948a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.771-10.954Z" />
        </>
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M256.782 296.551c2.27.066 5.044.133 7.974.235l-1.776 10.987a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.814-11.222Z" />
          <path d="m243.218 296.551-1.814 11.222a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.776-10.987c2.93-.102 5.705-.169 7.974-.235Z" />
        </>
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="M256.743 296.955c2.633.083 5.68.161 8.077.139l-1.84 11.38a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.844-11.41a.659.659 0 0 1-.009-.109Z" />
          <path d="M243.257 296.955a.659.659 0 0 1-.009.109l-1.844 11.41a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.84-11.38c2.397.022 5.444-.056 8.077-.139Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="M256.815 299.284c1.894-.04 3.758.017 5.067.409.868.26 1.781.498 2.698.71l-1.6 9.901a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.781-11.02Z" />
          <path d="M235.42 300.403c.917-.212 1.83-.45 2.698-.71 1.309-.392 3.173-.449 5.067-.409l-1.781 11.02a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.6-9.901Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="M256.792 300.386c.571.029 1.268.117 2.143.282 1.728.327 3.72.799 5.597 1.278l-1.552 9.602a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.804-11.162Z" />
          <path d="m243.208 300.386-1.804 11.162a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.552-9.602c1.877-.479 3.869-.951 5.597-1.278.875-.165 1.572-.253 2.143-.282Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="m264.764 304.929-1.784 11.034a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.777-10.993c2.616 0 5.523-.01 7.945-.041Z" />
          <path d="M235.236 304.929c2.422.031 5.329.041 7.945.041l-1.777 10.993a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.784-11.034Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="M256.78 300.354c.833-.33 1.692-.568 3.152-.49 1.092.057 2.453 1.346 4.542 1.489l.159.011-1.653 10.229a.758.758 0 0 1-.748.636h-2.888a.758.758 0 0 1-.748-.636l-1.816-11.239Z" />
          <path d="m235.367 301.364.159-.011c2.089-.143 3.45-1.432 4.542-1.489 1.46-.078 2.319.16 3.152.49l-1.816 11.239a.758.758 0 0 1-.748.636h-2.888a.758.758 0 0 1-.748-.636l-1.653-10.229Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="m264.768 301.872-1.788 11.063a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.472-9.106c2.046-.352 4.614-.943 7.644-1.957Z" />
          <path d="M235.232 301.872c3.031 1.014 5.598 1.605 7.644 1.957l-1.472 9.106a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.788-11.063Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="M256.743 300.792c2.948.03 5.541.424 7.959.842l-1.722 10.651a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.844-11.41a1.109 1.109 0 0 1-.009-.083Z" />
          <path d="M243.257 300.792a1.109 1.109 0 0 1-.009.083l-1.844 11.41a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.722-10.651c2.417-.418 5.01-.812 7.959-.842Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="M256.841 298.966c.16.071.327.146.5.226 2.012.923 4.635 1.042 7.24.733l-1.601 9.903a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.755-10.862Z" />
          <path d="m243.159 298.966-1.755 10.862a.757.757 0 0 1-.748.636h-2.888a.757.757 0 0 1-.748-.636l-1.601-9.903c2.605.309 5.228.19 7.24-.733.173-.08.34-.155.5-.226Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="M256.84 300.007c2.398.724 4.749 2.133 7.401 3.07l-1.261 7.798a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.756-10.868Z" />
          <path d="m243.16 300.007-1.756 10.868a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.261-7.798c2.652-.937 5.002-2.346 7.401-3.07Z" />
        </>
      ),
    },
  },
};

const teeth_shape_LzydJYaCxp_lower = {
  id: "teeth_shape_LzydJYaCxp_lower",
  name: "Teeth 6 (Lower)",
  description: "Long, squared teeth/tusks",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <>
          <path d="m256.933 316.439 1.663-10.289a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.516 9.376c-2.143.864-4.59.977-7.563.913Z" />
          <path d="m235.506 315.513 1.514-9.363a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.665 10.302c-2.919.024-5.482-.143-7.563-.939Z" />
        </>
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <>
          <path d="m256.88 305.286.441-2.725a.756.756 0 0 1 .747-.636h2.889c.371 0 .688.269.748.636l1.844 11.409a.847.847 0 0 1 .009.099c-1.94-2.739-4.388-6.366-6.113-8.249a4.952 4.952 0 0 0-.565-.534Z" />
          <path d="M236.449 314.062a.716.716 0 0 1 .009-.092l1.844-11.409a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l.44 2.723a5.058 5.058 0 0 0-.561.536c-1.728 1.879-4.175 5.505-6.116 8.242Z" />
        </>
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <>
          <path d="m257.293 303.291 1.303-8.06a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.823 11.275c-1.65-1.004-3.287-1.958-4.613-2.586-1.101-.517-1.975-.662-2.897-.629Z" />
          <path d="m235.196 306.515 1.824-11.284a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.301 8.053c-.916-.031-1.784.117-2.875.636-1.333.628-2.978 1.586-4.634 2.595Z" />
        </>
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <>
          <path d="m257.267 307.93 1.329-8.221a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.827 11.304c-2.84-.685-5.326-2.026-7.54-3.083Z" />
          <path d="m235.208 310.917 1.812-11.208a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.31 8.105c-2.217 1.037-4.701 2.377-7.506 3.103Z" />
        </>
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <>
          <path d="M261.902 313.275a.76.76 0 0 1-.748.879h-6.577a.757.757 0 0 1-.747-.879l1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.844 11.409Z" />
          <path d="M246.17 313.275a.755.755 0 0 1-.747.879h-6.577a.759.759 0 0 1-.748-.879l1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.844 11.409Z" />
        </>
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <>
          <path d="M264.824 305.641a.755.755 0 0 1-.748.878H257.5a.758.758 0 0 1-.748-.878l1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.844 11.409Z" />
          <path d="M243.248 305.641a.76.76 0 0 1-.748.878h-6.576a.756.756 0 0 1-.748-.878l1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.844 11.409Z" />
        </>
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <>
          <path d="M264.824 305.814a.755.755 0 0 1-.748.878H257.5a.758.758 0 0 1-.748-.878l1.844-11.41a.758.758 0 0 1 .748-.636h2.888c.372 0 .689.27.748.636l1.844 11.41Z" />
          <path d="M243.248 305.814a.76.76 0 0 1-.748.878h-6.576a.756.756 0 0 1-.748-.878l1.844-11.41a.758.758 0 0 1 .748-.636h2.888c.372 0 .688.27.748.636l1.844 11.41Z" />
        </>
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M264.824 314.214a.757.757 0 0 1-.748.879H257.5a.759.759 0 0 1-.748-.879l1.844-11.409a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.844 11.409Z" />
          <path d="M243.248 314.214a.758.758 0 0 1-.748.879h-6.576a.757.757 0 0 1-.748-.879l1.844-11.409a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.844 11.409Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <>
          <path d="M243.248 312.514a.76.76 0 0 1-.748.878h-6.576a.756.756 0 0 1-.748-.878l1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.844 11.409Z" />
          <path d="M264.824 312.514a.755.755 0 0 1-.748.878H257.5a.758.758 0 0 1-.748-.878l1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.844 11.409Z" />
        </>
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="M264.824 313.687a.755.755 0 0 1-.748.879H257.5a.759.759 0 0 1-.748-.879l1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.844 11.409Z" />
          <path d="M243.248 313.687a.76.76 0 0 1-.748.879h-6.576a.757.757 0 0 1-.748-.879l1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.844 11.409Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <>
          <path d="M262.756 313.564a.76.76 0 0 1-.748.878h-6.576a.758.758 0 0 1-.748-.878l1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.844 11.409Z" />
          <path d="M245.316 313.564a.755.755 0 0 1-.748.878h-6.576a.758.758 0 0 1-.748-.878l1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.844 11.409Z" />
        </>
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <>
          <path d="M264.824 313.782a.755.755 0 0 1-.748.878H257.5a.758.758 0 0 1-.748-.878l1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.844 11.409Z" />
          <path d="M243.248 313.782a.76.76 0 0 1-.748.878h-6.577a.756.756 0 0 1-.747-.878l1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.844 11.409Z" />
        </>
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="m262.723 302.081-1.019-6.309a.758.758 0 0 0-.748-.637h-2.888a.758.758 0 0 0-.748.637l-1.801 11.14c2.25-1.622 4.761-3.47 7.204-4.831Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="m237.277 302.081 1.019-6.309a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.801 11.14c-2.25-1.622-4.761-3.47-7.204-4.831Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <>
          <path d="m255.535 305.47 1.705-10.545a.757.757 0 0 1 .747-.637h2.889a.76.76 0 0 1 .748.637l1.704 10.545h-7.793Z" />
          <path d="m236.672 305.47 1.704-10.545a.76.76 0 0 1 .748-.637h2.889c.371 0 .688.27.747.637l1.705 10.545h-7.793Z" />
        </>
      ),
    },
    mouth_shape_74Nrw: {
      base: (
        <>
          <path d="m256.772 305.47 1.824-11.288a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.825 11.288h-8.033Z" />
          <path d="m235.195 305.47 1.825-11.288a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.824 11.288h-8.033Z" />
        </>
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <>
          <path d="m253.835 305.722 1.788-11.06a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.485 9.186a32.472 32.472 0 0 1-7.657 1.874Z" />
          <path d="m238.498 303.848 1.485-9.186a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.788 11.06a32.472 32.472 0 0 1-7.657-1.874Z" />
        </>
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <>
          <path d="m235.408 303.716 1.607-9.942a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.844 11.41a58.222 58.222 0 0 1-7.835-1.468Z" />
          <path d="m256.747 305.184 1.844-11.41a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.607 9.942a58.222 58.222 0 0 1-7.835 1.468Z" />
        </>
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <>
          <path d="m256.165 303.163 1.452-8.983a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.844 11.408a33.428 33.428 0 0 0-7.68-2.425Z" />
          <path d="m236.156 305.583 1.843-11.403a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.451 8.981a33.438 33.438 0 0 0-7.678 2.422Z" />
        </>
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <>
          <path d="m256.998 300.019 1.598-9.891a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.837 11.364a59.123 59.123 0 0 0-7.819-1.473Z" />
          <path d="m235.183 301.489 1.837-11.361a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.598 9.89c-2.628.313-5.24.803-7.819 1.471Z" />
        </>
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <>
          <path d="m256.898 305.451 1.698-10.508a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.69 10.458c-2.365.023-5.007.039-7.772.05Z" />
          <path d="m235.33 305.401 1.69-10.458a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.698 10.508a1379.64 1379.64 0 0 1-7.772-.05Z" />
        </>
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <>
          <path d="m257.071 300.807 1.525-9.434a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.838 11.37c-4.435-.551-3.205-1.981-6.647-1.996-.42-.002-.782.02-1.1.06Z" />
          <path d="m235.182 302.743 1.838-11.37a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.525 9.434a8.488 8.488 0 0 0-1.1-.06c-3.442.015-2.212 1.445-6.647 1.996Z" />
        </>
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <>
          <path d="m235.309 309.048 1.711-10.584a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.799 11.131c-4.067-.02-5.656.115-7.894-.547Z" />
          <path d="m256.797 309.595 1.799-11.131a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.711 10.584c-2.238.662-3.826.527-7.894.547Z" />
        </>
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <>
          <path d="m253.835 311.19 1.83-11.32a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.333 8.249c-2.226.943-4.652 2.318-7.547 3.071Z" />
          <path d="m238.618 308.119 1.333-8.249a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.83 11.32c-2.895-.753-5.322-2.128-7.547-3.071Z" />
        </>
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <>
          <path d="m256.97 305.276 1.626-10.059a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.806 11.174c-1.981-.476-4.107-.906-6.119-1.09a9.651 9.651 0 0 0-1.697-.025Z" />
          <path d="m235.214 306.391 1.806-11.174a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.626 10.059a9.651 9.651 0 0 0-1.697.025c-2.012.184-4.138.614-6.119 1.09Z" />
        </>
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <>
          <path d="m256.854 303.329 1.742-10.779a.758.758 0 0 1 .748-.636h2.888c.372 0 .689.27.748.636l1.749 10.823c-2.619 0-5.334-.022-7.875-.044Z" />
          <path d="m235.271 303.372 1.749-10.822a.758.758 0 0 1 .748-.636h2.888c.372 0 .688.27.748.636l1.741 10.774a951.2 951.2 0 0 1-7.874.048Z" />
        </>
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <>
          <path d="m256.806 311.28 1.79-11.076a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.787 11.055c-2.455.01-5.154.017-7.961.021Z" />
          <path d="m235.233 311.259 1.787-11.055a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.79 11.076a3045.79 3045.79 0 0 1-7.961-.021Z" />
        </>
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <>
          <path d="m256.971 296.585 1.625-10.057a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.844 11.409a.362.362 0 0 1 .006.046c-2.333-.196-4.804-.62-6.572-1.365-.398-.168-.811-.15-1.287-.033Z" />
          <path d="M235.17 297.983a.362.362 0 0 1 .006-.046l1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.625 10.057c-.476-.117-.889-.135-1.287.033-1.768.745-4.239 1.169-6.572 1.365Z" />
        </>
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <>
          <path d="m256.849 303.567 1.747-10.811a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.844 11.409.002.014a8.115 8.115 0 0 1-.484-.17c-.866.027-1.777.041-2.71.029-1.592-.022-3.162-.245-4.783-.471Z" />
          <path d="m235.174 304.179.002-.014 1.844-11.409a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.747 10.811c-1.621.226-3.191.449-4.783.471a61.634 61.634 0 0 1-2.71-.029 8.115 8.115 0 0 1-.484.17Z" />
        </>
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <>
          <path d="M264.824 302.533a.755.755 0 0 0-.748-.878H257.5a.758.758 0 0 0-.748.878l1.567 9.691a2.802 2.802 0 0 0 2.766 2.355h.175c.932 0 1.727-.676 1.875-1.597l1.689-10.449Z" />
          <path d="M243.248 302.533a.76.76 0 0 0-.748-.878h-6.576a.756.756 0 0 0-.748.878l1.566 9.691a2.803 2.803 0 0 0 2.766 2.355h.175a1.9 1.9 0 0 0 1.876-1.597l1.689-10.449Z" />
        </>
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <>
          <path d="m256.832 302.207 1.764-10.913a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.769 10.941a837.56 837.56 0 0 1-7.917-.028Z" />
          <path d="m235.251 302.235 1.769-10.941a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.764 10.912c-2.451.02-5.203.034-7.917.029Z" />
        </>
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <>
          <path d="m256.883 313.527 1.713-10.598a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.695 10.482c-2.162.828-4.775.657-7.792.116Z" />
          <path d="m235.803 310.457 1.217-7.528a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.284 7.946a44.33 44.33 0 0 0-3.208-.205 65.07 65.07 0 0 1-3.677-.213Z" />
        </>
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <>
          <path d="m256.804 305.034 1.792-11.091a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.785 11.044c-2.622.024-5.382.038-7.961.047Z" />
          <path d="m235.235 304.987 1.785-11.044a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.792 11.091c-2.58-.009-5.34-.023-7.961-.047Z" />
        </>
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <>
          <path d="m256.983 304.2 1.613-9.982a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.692 10.466c-2.066-.606-4.174-.915-6.466-.708a7.278 7.278 0 0 0-1.223.224Z" />
          <path d="m235.328 304.684 1.692-10.466a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.613 9.982a7.278 7.278 0 0 0-1.223-.224c-2.292-.207-4.4.102-6.466.708Z" />
        </>
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <>
          <path d="m256.918 306.141 1.678-10.382a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.336 8.263c-1.792.485-3.417.994-4.533 1.429-.552.216-1.6.464-2.865.69Z" />
          <path d="m235.684 304.022 1.336-8.263a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.678 10.382c-1.265-.226-2.313-.474-2.865-.69-1.116-.435-2.741-.944-4.533-1.429Z" />
        </>
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <>
          <path d="m256.857 301.451 1.739-10.761a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.746 10.803c-2.336-.439-4.973-.538-7.869-.042Z" />
          <path d="m235.274 301.493 1.746-10.803a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.739 10.761c-2.896-.496-5.533-.397-7.869.042Z" />
        </>
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <>
          <path d="m256.887 302.182 1.709-10.577a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.55 9.587c-2.192.248-4.327.713-7.643.99Z" />
          <path d="m235.47 301.192 1.55-9.587a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.709 10.577c-3.316-.277-5.451-.742-7.643-.99Z" />
        </>
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="m256.847 311.975 1.749-10.825a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.592 9.846c-2.656.63-5.341.883-7.725.979Z" />
          <path d="m235.428 310.996 1.592-9.846a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.749 10.825c-2.384-.096-5.069-.349-7.725-.979Z" />
        </>
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="m256.889 296.96 1.707-10.565a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.73 10.7c-2.335.018-5.263-.055-7.821-.135Z" />
          <path d="m235.29 297.095 1.73-10.7a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.707 10.565c-2.559.08-5.486.153-7.821.135Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="m256.776 306.377 1.82-11.261a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.343 8.305c-2.47 1.1-4.687 2.235-5.512 2.573-.318.131-1.083.262-2.035.383Z" />
          <path d="m235.677 303.421 1.343-8.305a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.82 11.261c-.952-.121-1.717-.252-2.035-.383-.825-.338-3.042-1.473-5.512-2.573Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="m254.541 304.846 1.787-11.056a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.245 7.699c-.533.22-1.042.519-1.665.853-1.041.558-2.259 1.839-5.751 2.504Z" />
          <path d="m238.043 301.489 1.245-7.699a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.787 11.056c-3.492-.665-4.71-1.946-5.751-2.504-.623-.334-1.132-.633-1.665-.853Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="m256.785 304.97 1.811-11.206a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.805 11.165a666.49 666.49 0 0 1-8 .041Z" />
          <path d="m235.215 304.929 1.805-11.165a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.811 11.206c-2.632 0-5.563-.01-8-.041Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="m256.947 300.289 1.649-10.206a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.825 11.291c-.115-.007-.226-.013-.331-.021-2.089-.143-3.45-1.432-4.542-1.489-1.362-.072-2.201.13-2.985.425Z" />
          <path d="m235.195 301.374 1.825-11.291a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.649 10.206c-.784-.295-1.623-.497-2.985-.425-1.092.057-2.453 1.346-4.542 1.489-.105.008-.216.014-.331.021Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="m256.849 303.876 1.747-10.809a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.442 8.919c-3.017.989-5.561 1.556-7.573 1.89Z" />
          <path d="m235.578 301.986 1.442-8.919a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.747 10.809c-2.013-.334-4.556-.901-7.573-1.89Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="m256.912 300.794 1.684-10.422a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.823 11.28c-2.399-.417-4.971-.814-7.891-.858Z" />
          <path d="m235.197 301.652 1.823-11.28a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.684 10.422c-2.92.044-5.492.441-7.891.858Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="m256.831 300.414 1.765-10.919a.757.757 0 0 1 .748-.636h2.888c.372 0 .689.269.748.636l1.684 10.42c-1.628.199-3.265.232-4.758.008a44.66 44.66 0 0 1-3.075.491Z" />
          <path d="m235.336 299.915 1.684-10.42a.757.757 0 0 1 .748-.636h2.888c.372 0 .688.269.748.636l1.765 10.919a44.66 44.66 0 0 1-3.075-.491c-1.493.224-3.13.191-4.758-.008Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="m257.236 300.133 1.36-8.415a.758.758 0 0 1 .748-.636h2.888c.372 0 .689.27.748.636l1.844 11.41a.736.736 0 0 1 .009.148c-2.748-.877-5.157-2.332-7.597-3.143Z" />
          <path d="M235.167 303.276a.736.736 0 0 1 .009-.148l1.844-11.41a.758.758 0 0 1 .748-.636h2.888c.372 0 .688.27.748.636l1.36 8.415c-2.439.811-4.848 2.266-7.597 3.143Z" />
        </>
      ),
    },
  },
};

const teeth_shape_PXFkweRWP9_upper = {
  id: "teeth_shape_PXFkweRWP9_upper",
  name: "Teeth 7 (Upper)",
  description: "Long, rounded teeth/tusks",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <>
          <path d="m235.758 306.283 1.032 6.385a2.452 2.452 0 0 0 2.42 2.06h.003a2.453 2.453 0 0 0 2.421-2.06l1.614-9.986a.763.763 0 0 0-.009-.286c-1.338-.265-2.44-.195-3.942.924-1.053.78-2.273 1.823-3.539 2.963Z" />
          <path d="M256.76 302.398a.774.774 0 0 0-.008.284l1.614 9.986a2.452 2.452 0 0 0 2.421 2.06h.003a2.452 2.452 0 0 0 2.42-2.06l1.032-6.382c-1.264-1.14-2.481-2.184-3.529-2.966-1.505-1.122-2.61-1.189-3.953-.922Z" />
        </>
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <>
          <path d="M256.806 305.228c.203.153.415.348.639.592 1.702 1.859 4.109 5.415 6.036 8.141l-.271 1.678a2.452 2.452 0 0 1-2.42 2.06h-.003a2.453 2.453 0 0 1-2.421-2.06l-1.614-9.985a.764.764 0 0 1 .054-.426Z" />
          <path d="M243.195 305.23a.767.767 0 0 1 .053.424l-1.614 9.985a2.453 2.453 0 0 1-2.421 2.06h-.003a2.452 2.452 0 0 1-2.42-2.06l-.271-1.676c1.93-2.725 4.339-6.287 6.046-8.143.221-.243.429-.437.63-.59Z" />
        </>
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <>
          <path d="M235.61 306.257c1.511-.912 2.991-1.763 4.21-2.337 1.306-.621 2.293-.71 3.425-.599a.76.76 0 0 1-.006.19l-1.614 9.985a2.453 2.453 0 0 1-2.421 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.17-7.239Z" />
          <path d="M256.736 303.325c1.138-.114 2.13-.029 3.444.595 1.213.575 2.687 1.422 4.192 2.331l-1.171 7.245a2.452 2.452 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.614-9.985a.775.775 0 0 1-.007-.186Z" />
        </>
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <>
          <path d="m256.815 308.817 1.551 9.598a2.453 2.453 0 0 0 2.421 2.061h.003a2.452 2.452 0 0 0 2.42-2.061l1.054-6.521c-2.794-.733-5.254-2.041-7.449-3.077Z" />
          <path d="M243.207 308.684c-2.217 1.023-4.697 2.344-7.487 3.116l1.07 6.615a2.452 2.452 0 0 0 2.42 2.061h.003a2.453 2.453 0 0 0 2.421-2.061l1.573-9.731Z" />
        </>
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <>
          <path d="M262.21 304.33a.758.758 0 0 0-.748-.878h-6.577a.758.758 0 0 0-.748.878l1.567 9.691a2.802 2.802 0 0 0 2.766 2.355h.175a1.9 1.9 0 0 0 1.876-1.597l1.689-10.449Z" />
          <path d="M245.863 304.33a.76.76 0 0 0-.748-.878h-6.577a.758.758 0 0 0-.748.878l1.567 9.691a2.802 2.802 0 0 0 2.766 2.355h.175a1.9 1.9 0 0 0 1.876-1.597l1.689-10.449Z" />
        </>
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <>
          <path d="M264.824 300.316a.755.755 0 0 0-.748-.879H257.5a.759.759 0 0 0-.748.879l1.567 9.691a2.802 2.802 0 0 0 2.766 2.355h.175a1.9 1.9 0 0 0 1.875-1.597l1.689-10.449Z" />
          <path d="M243.248 300.316a.76.76 0 0 0-.748-.879h-6.576a.757.757 0 0 0-.748.879l1.566 9.691a2.803 2.803 0 0 0 2.766 2.355h.175a1.9 1.9 0 0 0 1.876-1.597l1.689-10.449Z" />
        </>
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <>
          <path d="M264.824 301.51a.755.755 0 0 0-.748-.878H257.5a.758.758 0 0 0-.748.878l1.567 9.691a2.802 2.802 0 0 0 2.766 2.355h.175a1.9 1.9 0 0 0 1.875-1.597l1.689-10.449Z" />
          <path d="M243.248 301.51a.76.76 0 0 0-.748-.878h-6.576a.756.756 0 0 0-.748.878l1.566 9.691a2.803 2.803 0 0 0 2.766 2.355h.175a1.9 1.9 0 0 0 1.876-1.597l1.689-10.449Z" />
        </>
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M264.824 314.214a.757.757 0 0 1-.748.879H257.5a.759.759 0 0 1-.748-.879l1.567-9.691a2.801 2.801 0 0 1 2.766-2.354h.175c.932 0 1.727.676 1.875 1.596l1.689 10.449Z" />
          <path d="M243.248 314.214a.758.758 0 0 1-.748.879h-6.576a.757.757 0 0 1-.748-.879l1.566-9.691a2.802 2.802 0 0 1 2.766-2.354h.175a1.9 1.9 0 0 1 1.876 1.596l1.689 10.449Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <>
          <path d="M264.824 302.533a.755.755 0 0 0-.748-.878H257.5a.758.758 0 0 0-.748.878l1.567 9.691a2.802 2.802 0 0 0 2.766 2.355h.175c.932 0 1.727-.676 1.875-1.597l1.689-10.449Z" />
          <path d="M243.248 302.533a.76.76 0 0 0-.748-.878h-6.576a.756.756 0 0 0-.748.878l1.566 9.691a2.803 2.803 0 0 0 2.766 2.355h.175a1.9 1.9 0 0 0 1.876-1.597l1.689-10.449Z" />
        </>
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="M264.824 302.533a.755.755 0 0 0-.748-.878H257.5a.758.758 0 0 0-.748.878l1.614 9.985a2.453 2.453 0 0 0 2.421 2.061h.003a2.452 2.452 0 0 0 2.42-2.061l1.614-9.985Z" />
          <path d="M243.248 302.533a.76.76 0 0 0-.748-.878h-6.576a.756.756 0 0 0-.748.878l1.614 9.985a2.452 2.452 0 0 0 2.42 2.061h.003a2.453 2.453 0 0 0 2.421-2.061l1.614-9.985Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <>
          <path d="M245.321 301.858a.758.758 0 0 0-.748-.879h-6.577a.755.755 0 0 0-.747.879l1.566 9.691a2.801 2.801 0 0 0 2.766 2.354h.175a1.9 1.9 0 0 0 1.876-1.596l1.689-10.449Z" />
          <path d="M262.76 301.858a.757.757 0 0 0-.747-.879h-6.577a.759.759 0 0 0-.748.879l1.567 9.691a2.801 2.801 0 0 0 2.766 2.354h.175c.932 0 1.727-.676 1.875-1.596l1.689-10.449Z" />
        </>
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <>
          <path d="M264.824 302.533a.755.755 0 0 0-.748-.878H257.5a.758.758 0 0 0-.748.878l1.567 9.691a2.802 2.802 0 0 0 2.766 2.355h.175c.932 0 1.727-.676 1.875-1.597l1.689-10.449Z" />
          <path d="M243.248 302.533a.76.76 0 0 0-.748-.878h-6.576a.756.756 0 0 0-.748.878l1.566 9.691a2.803 2.803 0 0 0 2.766 2.355h.175a1.9 1.9 0 0 0 1.876-1.597l1.689-10.449Z" />
        </>
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="m263.348 301.753-1.606 9.937a2.452 2.452 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-.834-5.161c2.301-1.663 4.841-3.496 7.284-4.776Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="m236.652 301.753 1.606 9.937a2.452 2.452 0 0 0 2.421 2.061h.003a2.452 2.452 0 0 0 2.42-2.061l.834-5.161c-2.301-1.663-4.841-3.496-7.284-4.776Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <>
          <path d="M245.846 306.47h-8.072l1.614 9.984a2.452 2.452 0 0 0 2.421 2.06h.003a2.45 2.45 0 0 0 2.42-2.06l1.614-9.984Z" />
          <path d="M262.226 306.47h-8.072l1.614 9.984a2.45 2.45 0 0 0 2.42 2.06h.003a2.452 2.452 0 0 0 2.421-2.06l1.614-9.984Z" />
        </>
      ),
    },
    mouth_shape_74Nrw: {
      base: (
        <>
          <path d="M264.805 306.47h-8.033l1.594 9.864a2.453 2.453 0 0 0 2.421 2.06h.003a2.452 2.452 0 0 0 2.42-2.06l1.595-9.864Z" />
          <path d="M243.228 306.47h-8.033l1.595 9.864a2.452 2.452 0 0 0 2.42 2.06h.003a2.453 2.453 0 0 0 2.421-2.06l1.594-9.864Z" />
        </>
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <>
          <path d="m236.865 304.269 1.571 9.715a2.452 2.452 0 0 0 2.42 2.061h.003a2.453 2.453 0 0 0 2.421-2.061l1.211-7.489a33.453 33.453 0 0 1-7.626-2.226Z" />
          <path d="M263.135 304.264a33.446 33.446 0 0 1-7.626 2.229l1.211 7.491a2.453 2.453 0 0 0 2.421 2.061h.003a2.452 2.452 0 0 0 2.42-2.061l1.571-9.72Z" />
        </>
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <>
          <path d="M264.75 304.705a59.202 59.202 0 0 1-7.69 1.449l1.306 8.079a2.453 2.453 0 0 0 2.421 2.06h.003a2.452 2.452 0 0 0 2.42-2.06l1.54-9.528Z" />
          <path d="m235.25 304.708 1.54 9.525a2.452 2.452 0 0 0 2.42 2.06h.003a2.453 2.453 0 0 0 2.421-2.06l1.306-8.078a59.028 59.028 0 0 1-7.69-1.447Z" />
        </>
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <>
          <path d="M244.174 304.115a32.498 32.498 0 0 0-7.694 2.419l1.23 7.609a2.452 2.452 0 0 0 2.42 2.061h.003a2.452 2.452 0 0 0 2.421-2.061l1.614-9.985.006-.043Z" />
          <path d="M255.827 304.117a2.452 2.452 0 0 0 4.04 12.087h.003a2.452 2.452 0 0 0 2.42-2.061l1.229-7.604a32.445 32.445 0 0 0-7.692-2.422Z" />
        </>
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <>
          <path d="m256.746 300.996.006.046 1.614 9.985a2.453 2.453 0 0 0 2.421 2.061h.003a2.452 2.452 0 0 0 2.42-2.061l1.384-8.56a58.202 58.202 0 0 0-7.848-1.471Z" />
          <path d="M243.254 300.995a57.917 57.917 0 0 0-7.848 1.47l1.384 8.562a2.452 2.452 0 0 0 2.42 2.061h.003a2.453 2.453 0 0 0 2.421-2.061l1.614-9.985.006-.047Z" />
        </>
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <>
          <path d="m264.779 305.4-1.569 9.702a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.56-9.651c2.841-.011 5.554-.028 7.973-.051Z" />
          <path d="M235.221 305.4c2.42.023 5.133.04 7.973.051l-1.56 9.651a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.569-9.702Z" />
        </>
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <>
          <path d="M256.827 300.843a7.886 7.886 0 0 1 1.344-.096c3.344.015 2.278 1.365 6.28 1.947l-1.241 7.673a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.539-9.524Z" />
          <path d="M235.549 302.694c4.002-.582 2.936-1.932 6.28-1.947.533-.002.972.033 1.344.096l-1.539 9.524a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.241-7.673Z" />
        </>
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <>
          <path d="M256.784 304.415c1.953.457 4.282 1.346 7.391 3.159l.098.054-1.063 6.572a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.582-9.785Z" />
          <path d="m243.216 304.415-1.582 9.785a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.063-6.572.098-.054c3.109-1.813 5.438-2.702 7.391-3.159Z" />
        </>
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <>
          <path d="M253.668 304.572c3.762.62 5.715 2.059 7.548 3.247l-1.09 6.74a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.614-9.985v-.002Z" />
          <path d="M246.332 304.572v.002l-1.614 9.985a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.09-6.74c1.833-1.189 3.786-2.627 7.548-3.247Z" />
        </>
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <>
          <path d="M256.823 305.289a9.415 9.415 0 0 1 1.844.012c1.943.178 3.993.585 5.916 1.042l-1.373 8.492a2.452 2.452 0 0 1-2.42 2.06h-.003a2.453 2.453 0 0 1-2.421-2.06l-1.543-9.546Z" />
          <path d="M235.417 306.343c1.923-.457 3.973-.864 5.916-1.042a9.415 9.415 0 0 1 1.844-.012l-1.543 9.546a2.453 2.453 0 0 1-2.421 2.06h-.003a2.452 2.452 0 0 1-2.42-2.06l-1.373-8.492Z" />
        </>
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <>
          <path d="M256.881 303.33c2.518.021 5.209.043 7.808.043l-1.479 9.145a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.485-9.188Z" />
          <path d="m243.12 303.325-1.486 9.193a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.479-9.146c2.618-.002 5.31-.025 7.809-.047Z" />
        </>
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <>
          <path d="m264.784 311.253-1.574 9.738a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.568-9.704c2.787-.007 5.486-.018 7.986-.034Z" />
          <path d="M235.216 311.253c2.5.015 5.199.027 7.986.034l-1.568 9.704a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.574-9.738Z" />
        </>
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <>
          <path d="M256.852 296.616c.527-.144.975-.18 1.406.002 1.687.71 4.014 1.129 6.25 1.337l-1.298 8.029a2.452 2.452 0 0 1-2.42 2.06h-.003a2.453 2.453 0 0 1-2.421-2.06l-1.514-9.368Z" />
          <path d="M235.492 297.955c2.236-.208 4.563-.627 6.25-1.337.431-.182.879-.146 1.406-.002l-1.514 9.368a2.453 2.453 0 0 1-2.421 2.06h-.003a2.452 2.452 0 0 1-2.42-2.06l-1.298-8.029Z" />
        </>
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <>
          <path d="M256.8 299.937c3.373 1.158 4.987 3.012 7.332 3.988l-.922 5.7a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.566-9.688Z" />
          <path d="m243.2 299.937-1.566 9.688a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-.922-5.7c2.345-.976 3.959-2.829 7.332-3.988Z" />
        </>
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="m264.105 302.026-.847 5.239a2.803 2.803 0 0 1-2.766 2.355h-.175a1.9 1.9 0 0 1-1.876-1.597l-1.158-7.167c1.505.09 2.892.343 4.29.715.77.205 1.636.351 2.532.455Zm-21.388-1.17-1.158 7.167a1.9 1.9 0 0 1-1.876 1.597h-.175a2.803 2.803 0 0 1-2.766-2.355l-.847-5.239c.896-.104 1.762-.25 2.532-.455 1.398-.372 2.785-.625 4.29-.715Z" />
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <>
          <path d="M256.823 302.206c2.453.02 5.209.034 7.926.029l-1.539 9.517a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.543-9.546Z" />
          <path d="m243.177 302.206-1.543 9.546a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.539-9.517c2.718.005 5.474-.009 7.926-.029Z" />
        </>
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <>
          <path d="M256.811 307.012c2.069.494 5.101 1.375 7.632 1.999l-1.233 7.624a2.45 2.45 0 0 1-2.42 2.06h-.003a2.45 2.45 0 0 1-2.421-2.06l-1.555-9.623Z" />
          <path d="m243.211 306.881-1.577 9.754a2.45 2.45 0 0 1-2.421 2.06h-.003a2.45 2.45 0 0 1-2.42-2.06l-1.263-7.814c2.639-.688 5.687-1.491 7.568-1.915l.116-.025Z" />
        </>
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <>
          <path d="m264.709 304.988-1.499 9.272a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.491-9.226c2.541-.009 5.253-.023 7.834-.046Z" />
          <path d="M235.291 304.988c2.581.023 5.294.037 7.834.046l-1.491 9.226a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.499-9.272Z" />
        </>
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <>
          <path d="M256.852 304.236a7.446 7.446 0 0 1 1.354-.26c2.285-.206 4.387.1 6.446.702l-1.442 8.922a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.514-9.364Z" />
          <path d="M235.348 304.678c2.059-.602 4.161-.908 6.446-.702.462.042.912.135 1.354.26l-1.514 9.364a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.442-8.922Z" />
        </>
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <>
          <path d="m264.8 303.892-1.59 9.837a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.233-7.627c1.169-.216 2.129-.448 2.65-.651 1.216-.474 3.036-1.036 5.017-1.559Z" />
          <path d="M235.2 303.892c1.981.523 3.801 1.085 5.017 1.559.521.203 1.481.435 2.65.651l-1.233 7.627a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.59-9.837Z" />
        </>
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <>
          <path d="m256.932 301.438 1.434 8.87a2.453 2.453 0 0 0 2.421 2.061h.003a2.452 2.452 0 0 0 2.42-2.061l1.428-8.832a22.162 22.162 0 0 0-7.706-.038Z" />
          <path d="m235.362 301.476 1.428 8.832a2.452 2.452 0 0 0 2.42 2.061h.003a2.453 2.453 0 0 0 2.421-2.061l1.434-8.87a22.138 22.138 0 0 0-7.706.038Z" />
        </>
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <>
          <path d="m264.751 301.167-1.541 9.529a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.378-8.523c3.37-.287 5.514-.767 7.763-1.006Z" />
          <path d="M235.249 301.167c2.249.239 4.393.719 7.763 1.006l-1.378 8.523a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.541-9.529Z" />
        </>
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M256.782 296.551c2.27.066 5.044.133 7.974.235l-1.546 9.563a2.452 2.452 0 0 1-2.42 2.06h-.003a2.453 2.453 0 0 1-2.421-2.06l-1.584-9.798Z" />
          <path d="m243.218 296.551-1.584 9.798a2.453 2.453 0 0 1-2.421 2.06h-.003a2.452 2.452 0 0 1-2.42-2.06l-1.546-9.563c2.93-.102 5.705-.169 7.974-.235Z" />
        </>
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="M256.743 296.955c2.633.083 5.68.161 8.077.139l-1.61 9.955a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.614-9.985a.659.659 0 0 1-.009-.109Z" />
          <path d="M243.257 296.955a.659.659 0 0 1-.009.109l-1.614 9.985a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.61-9.955c2.397.022 5.444-.056 8.077-.139Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="M256.815 299.284c1.894-.04 3.758.017 5.067.409.868.26 1.781.498 2.698.71l-1.37 8.476a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.551-9.595Z" />
          <path d="M235.42 300.403c.917-.212 1.83-.45 2.698-.71 1.309-.392 3.173-.449 5.067-.409l-1.551 9.595a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.37-8.476Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="M256.792 300.386c.571.029 1.268.117 2.143.282 1.728.327 3.72.799 5.597 1.278l-1.322 8.178a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.574-9.738Z" />
          <path d="m243.208 300.386-1.574 9.738a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.322-8.178c1.877-.479 3.869-.951 5.597-1.278.875-.165 1.572-.253 2.143-.282Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="m264.764 304.929-1.554 9.61a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.547-9.569c2.616 0 5.523-.01 7.945-.041Z" />
          <path d="M235.236 304.929c2.422.031 5.329.041 7.945.041l-1.547 9.569a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.554-9.61Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="M256.78 300.354c.833-.33 1.692-.568 3.152-.49 1.092.057 2.453 1.346 4.542 1.489l.159.011-1.423 8.804a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.586-9.814Z" />
          <path d="m235.367 301.364.159-.011c2.089-.143 3.45-1.432 4.542-1.489 1.46-.078 2.319.16 3.152.49l-1.586 9.814a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.423-8.804Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="m264.768 301.872-1.558 9.639a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.242-7.682c2.046-.352 4.614-.943 7.644-1.957Z" />
          <path d="M235.232 301.872c3.031 1.014 5.598 1.605 7.644 1.957l-1.242 7.682a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.558-9.639Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="M256.743 300.792c2.948.03 5.541.424 7.959.842l-1.492 9.226a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.614-9.985a1.109 1.109 0 0 1-.009-.083Z" />
          <path d="M243.257 300.792a1.109 1.109 0 0 1-.009.083l-1.614 9.985a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.492-9.226c2.417-.418 5.01-.812 7.959-.842Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="M256.841 298.966c.16.071.327.146.5.226 2.012.923 4.635 1.042 7.24.733l-1.371 8.479a2.452 2.452 0 0 1-2.42 2.06h-.003a2.453 2.453 0 0 1-2.421-2.06l-1.525-9.438Z" />
          <path d="m243.159 298.966-1.525 9.438a2.453 2.453 0 0 1-2.421 2.06h-.003a2.452 2.452 0 0 1-2.42-2.06l-1.371-8.479c2.605.309 5.228.19 7.24-.733.173-.08.34-.155.5-.226Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="M256.84 300.007c2.398.724 4.749 2.133 7.401 3.07l-1.031 6.374a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-1.526-9.444Z" />
          <path d="m243.16 300.007-1.526 9.444a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-1.031-6.374c2.652-.937 5.002-2.346 7.401-3.07Z" />
        </>
      ),
    },
  },
};

const teeth_shape_PXFkweRWP9_lower = {
  id: "teeth_shape_PXFkweRWP9_lower",
  name: "Teeth 7 (Lower)",
  description: "Long, rounded teeth/tusks",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <>
          <path d="m256.933 316.439 1.433-8.865a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.286 7.952c-2.143.864-4.59.977-7.563.913Z" />
          <path d="m235.506 315.513 1.284-7.939a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.435 8.878c-2.919.024-5.482-.143-7.563-.939Z" />
        </>
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <>
          <path d="m256.88 305.286.21-1.301a2.453 2.453 0 0 1 2.421-2.06h.003c1.203 0 2.229.873 2.421 2.06l1.614 9.985a.847.847 0 0 1 .009.099c-1.94-2.739-4.388-6.366-6.113-8.249a4.952 4.952 0 0 0-.565-.534Z" />
          <path d="M236.449 314.062a.716.716 0 0 1 .009-.092l1.614-9.985a2.452 2.452 0 0 1 2.42-2.06h.004c1.203 0 2.228.873 2.42 2.06l.21 1.299a5.058 5.058 0 0 0-.561.536c-1.728 1.879-4.175 5.505-6.116 8.242Z" />
        </>
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <>
          <path d="m257.293 303.291 1.073-6.636a2.452 2.452 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.593 9.851c-1.65-1.004-3.287-1.958-4.613-2.586-1.101-.517-1.975-.662-2.897-.629Z" />
          <path d="m235.196 306.515 1.594-9.86a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.071 6.629c-.916-.031-1.784.117-2.875.636-1.333.628-2.978 1.586-4.634 2.595Z" />
        </>
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <>
          <path d="m257.267 307.93 1.099-6.797a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.597 9.88c-2.84-.685-5.326-2.026-7.54-3.083Z" />
          <path d="m235.208 310.917 1.582-9.784a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.08 6.681c-2.217 1.037-4.701 2.377-7.506 3.103Z" />
        </>
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <>
          <path d="M261.902 313.275a.76.76 0 0 1-.748.879h-6.577a.757.757 0 0 1-.747-.879l1.566-9.691a2.802 2.802 0 0 1 2.766-2.355h.175a1.9 1.9 0 0 1 1.876 1.597l1.689 10.449Z" />
          <path d="M246.17 313.275a.755.755 0 0 1-.747.879h-6.577a.759.759 0 0 1-.748-.879l1.567-9.691a2.802 2.802 0 0 1 2.766-2.355h.175a1.9 1.9 0 0 1 1.875 1.597l1.689 10.449Z" />
        </>
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <>
          <path d="M264.824 305.641a.755.755 0 0 1-.748.878H257.5a.758.758 0 0 1-.748-.878l1.567-9.691a2.802 2.802 0 0 1 2.766-2.355h.175a1.9 1.9 0 0 1 1.875 1.597l1.689 10.449Z" />
          <path d="M243.248 305.641a.76.76 0 0 1-.748.878h-6.576a.756.756 0 0 1-.748-.878l1.566-9.691a2.803 2.803 0 0 1 2.766-2.355h.175a1.9 1.9 0 0 1 1.876 1.597l1.689 10.449Z" />
        </>
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <>
          <path d="M264.824 305.814a.755.755 0 0 1-.748.878H257.5a.758.758 0 0 1-.748-.878l1.567-9.691a2.801 2.801 0 0 1 2.766-2.355h.175c.932 0 1.727.676 1.875 1.597l1.689 10.449Z" />
          <path d="M243.248 305.814a.76.76 0 0 1-.748.878h-6.576a.756.756 0 0 1-.748-.878l1.566-9.691a2.802 2.802 0 0 1 2.766-2.355h.175a1.9 1.9 0 0 1 1.876 1.597l1.689 10.449Z" />
        </>
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M264.824 314.214a.757.757 0 0 1-.748.879H257.5a.759.759 0 0 1-.748-.879l1.614-9.985a2.452 2.452 0 0 1 2.421-2.06h.003a2.45 2.45 0 0 1 2.42 2.06l1.614 9.985Z" />
          <path d="M243.248 314.214a.758.758 0 0 1-.748.879h-6.576a.757.757 0 0 1-.748-.879l1.614-9.985a2.45 2.45 0 0 1 2.42-2.06h.003c1.203 0 2.229.872 2.421 2.06l1.614 9.985Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <>
          <path d="M243.248 312.514a.76.76 0 0 1-.748.878h-6.576a.756.756 0 0 1-.748-.878l1.566-9.691a2.803 2.803 0 0 1 2.766-2.355h.175a1.9 1.9 0 0 1 1.876 1.597l1.689 10.449Z" />
          <path d="M264.824 312.514a.755.755 0 0 1-.748.878H257.5a.758.758 0 0 1-.748-.878l1.567-9.691a2.802 2.802 0 0 1 2.766-2.355h.175c.932 0 1.727.676 1.875 1.597l1.689 10.449Z" />
        </>
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="M264.824 313.687a.755.755 0 0 1-.748.879H257.5a.759.759 0 0 1-.748-.879l1.567-9.691a2.802 2.802 0 0 1 2.766-2.355h.175a1.9 1.9 0 0 1 1.875 1.597l1.689 10.449Z" />
          <path d="M243.248 313.687a.76.76 0 0 1-.748.879h-6.576a.757.757 0 0 1-.748-.879l1.566-9.691a2.803 2.803 0 0 1 2.766-2.355h.175a1.9 1.9 0 0 1 1.876 1.597l1.689 10.449Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <>
          <path d="M262.756 313.564a.76.76 0 0 1-.748.878h-6.576a.758.758 0 0 1-.748-.878l1.566-9.691a2.803 2.803 0 0 1 2.766-2.355h.175a1.9 1.9 0 0 1 1.876 1.597l1.689 10.449Z" />
          <path d="M245.316 313.564a.755.755 0 0 1-.748.878h-6.576a.758.758 0 0 1-.748-.878l1.567-9.691a2.802 2.802 0 0 1 2.766-2.355h.175a1.9 1.9 0 0 1 1.875 1.597l1.689 10.449Z" />
        </>
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <>
          <path d="M264.824 313.782a.755.755 0 0 1-.748.878H257.5a.758.758 0 0 1-.748-.878l1.567-9.691a2.802 2.802 0 0 1 2.766-2.355h.175c.932 0 1.727.676 1.875 1.597l1.689 10.449Z" />
          <path d="M243.248 313.782a.76.76 0 0 1-.748.878h-6.577a.756.756 0 0 1-.747-.878l1.566-9.691a2.802 2.802 0 0 1 2.766-2.355h.175a1.9 1.9 0 0 1 1.876 1.597l1.689 10.449Z" />
        </>
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="m262.723 302.081-.789-4.885a2.453 2.453 0 0 0-2.421-2.061h-.003a2.452 2.452 0 0 0-2.42 2.061l-1.571 9.716c2.25-1.622 4.761-3.47 7.204-4.831Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="m237.277 302.081.789-4.885a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.571 9.716c-2.25-1.622-4.761-3.47-7.204-4.831Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <>
          <path d="m255.535 305.47 1.474-9.121a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.474 9.121h-7.793Z" />
          <path d="m236.672 305.47 1.474-9.121a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.474 9.121h-7.793Z" />
        </>
      ),
    },
    mouth_shape_74Nrw: {
      base: (
        <>
          <path d="m256.772 305.47 1.594-9.864a2.453 2.453 0 0 1 2.421-2.06h.003c1.203 0 2.228.873 2.42 2.06l1.595 9.864h-8.033Z" />
          <path d="m235.195 305.47 1.595-9.864a2.452 2.452 0 0 1 2.42-2.06h.003c1.203 0 2.229.873 2.421 2.06l1.594 9.864h-8.033Z" />
        </>
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <>
          <path d="m253.835 305.722 1.558-9.636a2.452 2.452 0 0 1 2.42-2.06h.003c1.204 0 2.229.873 2.421 2.06l1.255 7.762a32.472 32.472 0 0 1-7.657 1.874Z" />
          <path d="m238.498 303.848 1.255-7.762a2.452 2.452 0 0 1 2.421-2.06h.003c1.203 0 2.228.873 2.42 2.06l1.558 9.636a32.472 32.472 0 0 1-7.657-1.874Z" />
        </>
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <>
          <path d="m235.408 303.716 1.377-8.517a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.614 9.985a58.222 58.222 0 0 1-7.835-1.468Z" />
          <path d="m256.747 305.184 1.614-9.985a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.377 8.517a58.222 58.222 0 0 1-7.835 1.468Z" />
        </>
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <>
          <path d="m256.165 303.163 1.222-7.559a2.452 2.452 0 0 1 2.421-2.06h.003c1.203 0 2.228.873 2.42 2.06l1.614 9.984a33.428 33.428 0 0 0-7.68-2.425Z" />
          <path d="m236.156 305.583 1.613-9.979a2.452 2.452 0 0 1 2.42-2.06h.003c1.204 0 2.229.873 2.421 2.06l1.221 7.557a33.438 33.438 0 0 0-7.678 2.422Z" />
        </>
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <>
          <path d="m256.998 300.019 1.368-8.466a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.607 9.939a59.123 59.123 0 0 0-7.819-1.473Z" />
          <path d="m235.183 301.489 1.607-9.936a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.368 8.465c-2.628.313-5.24.803-7.819 1.471Z" />
        </>
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <>
          <path d="m256.898 305.451 1.468-9.083a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.46 9.033c-2.365.023-5.007.039-7.772.05Z" />
          <path d="m235.33 305.401 1.46-9.033a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.468 9.083a1379.64 1379.64 0 0 1-7.772-.05Z" />
        </>
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <>
          <path d="m257.071 300.807 1.295-8.01a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.608 9.946c-4.435-.551-3.205-1.981-6.647-1.996-.42-.002-.782.02-1.1.06Z" />
          <path d="m235.182 302.743 1.608-9.946a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.295 8.01a8.488 8.488 0 0 0-1.1-.06c-3.442.015-2.212 1.445-6.647 1.996Z" />
        </>
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <>
          <path d="m256.797 309.595 1.569-9.707a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.481 9.16c-2.238.662-3.826.527-7.894.547Z" />
          <path d="m235.309 309.048 1.481-9.16a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.569 9.707c-4.067-.02-5.656.115-7.894-.547Z" />
        </>
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <>
          <path d="m253.835 311.19 1.6-9.896a2.452 2.452 0 0 1 2.42-2.06h.003c1.203 0 2.229.873 2.421 2.06l1.103 6.825c-2.226.943-4.652 2.318-7.547 3.071Z" />
          <path d="m238.618 308.119 1.103-6.825a2.453 2.453 0 0 1 2.421-2.06h.003c1.203 0 2.228.873 2.42 2.06l1.6 9.896c-2.895-.753-5.322-2.128-7.547-3.071Z" />
        </>
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <>
          <path d="m256.97 305.276 1.396-8.635a2.453 2.453 0 0 1 2.421-2.06h.003c1.203 0 2.228.873 2.42 2.06l1.576 9.75c-1.981-.476-4.107-.906-6.119-1.09a9.651 9.651 0 0 0-1.697-.025Z" />
          <path d="m235.214 306.391 1.576-9.75a2.452 2.452 0 0 1 2.42-2.06h.003c1.203 0 2.229.873 2.421 2.06l1.396 8.635a9.651 9.651 0 0 0-1.697.025c-2.012.184-4.138.614-6.119 1.09Z" />
        </>
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <>
          <path d="m256.854 303.329 1.512-9.354a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.519 9.398c-2.619 0-5.334-.022-7.875-.044Z" />
          <path d="m235.271 303.372 1.519-9.397a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.511 9.349a951.2 951.2 0 0 1-7.874.048Z" />
        </>
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <>
          <path d="m256.806 311.28 1.56-9.652a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.557 9.631c-2.455.01-5.154.017-7.961.021Z" />
          <path d="m235.233 311.259 1.557-9.631a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.56 9.652a3045.79 3045.79 0 0 1-7.961-.021Z" />
        </>
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <>
          <path d="m256.971 296.585 1.395-8.633a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.614 9.985a.362.362 0 0 1 .006.046c-2.333-.196-4.804-.62-6.572-1.365-.398-.168-.811-.15-1.287-.033Z" />
          <path d="M235.17 297.983a.362.362 0 0 1 .006-.046l1.614-9.985a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.395 8.633c-.476-.117-.889-.135-1.287.033-1.768.745-4.239 1.169-6.572 1.365Z" />
        </>
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <>
          <path d="m256.849 303.567 1.517-9.387a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.614 9.985.002.014a8.115 8.115 0 0 1-.484-.17c-.866.027-1.777.041-2.71.029-1.592-.022-3.162-.245-4.783-.471Z" />
          <path d="m235.174 304.179.002-.014 1.614-9.985a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.517 9.387c-1.621.226-3.191.449-4.783.471a61.634 61.634 0 0 1-2.71-.029 8.115 8.115 0 0 1-.484.17Z" />
        </>
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="m234.843 302.129 1.314-8.129a2.803 2.803 0 0 1 2.766-2.355h.175a1.9 1.9 0 0 1 1.876 1.597l1.45 8.973c-1.403-.161-3.239-.273-5.727-.295-.601.091-1.227.159-1.854.209Zm22.733.086 1.45-8.973a1.9 1.9 0 0 1 1.876-1.597h.175c1.375 0 2.546.998 2.766 2.355l1.314 8.129a26.586 26.586 0 0 1-1.854-.209c-2.488.022-4.324.134-5.727.295Z" />
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <>
          <path d="m256.832 302.207 1.534-9.488a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.539 9.516a837.56 837.56 0 0 1-7.917-.028Z" />
          <path d="m235.251 302.235 1.539-9.516a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.534 9.487c-2.451.02-5.203.034-7.917.029Z" />
        </>
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <>
          <path d="m256.883 313.527 1.483-9.174a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.465 9.058c-2.162.828-4.775.657-7.792.116Z" />
          <path d="m235.803 310.457.987-6.104a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.054 6.522a44.33 44.33 0 0 0-3.208-.205 65.07 65.07 0 0 1-3.677-.213Z" />
        </>
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <>
          <path d="m256.804 305.034 1.562-9.667a2.453 2.453 0 0 1 2.421-2.06h.003c1.203 0 2.228.873 2.42 2.06l1.555 9.62c-2.622.024-5.382.038-7.961.047Z" />
          <path d="m235.235 304.987 1.555-9.62a2.452 2.452 0 0 1 2.42-2.06h.003c1.203 0 2.229.873 2.421 2.06l1.562 9.667c-2.58-.009-5.34-.023-7.961-.047Z" />
        </>
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <>
          <path d="m256.983 304.2 1.383-8.558a2.453 2.453 0 0 1 2.421-2.06h.003c1.203 0 2.228.873 2.42 2.06l1.462 9.042c-2.066-.606-4.174-.915-6.466-.708a7.278 7.278 0 0 0-1.223.224Z" />
          <path d="m235.328 304.684 1.462-9.042a2.452 2.452 0 0 1 2.42-2.06h.003c1.203 0 2.229.873 2.421 2.06l1.383 8.558a7.278 7.278 0 0 0-1.223-.224c-2.292-.207-4.4.102-6.466.708Z" />
        </>
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <>
          <path d="m256.918 306.141 1.448-8.958a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.106 6.839c-1.792.485-3.417.994-4.533 1.429-.552.216-1.6.464-2.865.69Z" />
          <path d="m235.684 304.022 1.106-6.839a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.448 8.958c-1.265-.226-2.313-.474-2.865-.69-1.116-.435-2.741-.944-4.533-1.429Z" />
        </>
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <>
          <path d="m256.857 301.451 1.509-9.337a2.453 2.453 0 0 1 2.421-2.06h.003c1.203 0 2.228.873 2.42 2.06l1.516 9.379c-2.336-.439-4.973-.538-7.869-.042Z" />
          <path d="m235.274 301.493 1.516-9.379a2.452 2.452 0 0 1 2.42-2.06h.003c1.203 0 2.229.873 2.421 2.06l1.509 9.337c-2.896-.496-5.533-.397-7.869.042Z" />
        </>
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <>
          <path d="m256.887 302.182 1.479-9.153a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.32 8.163c-2.192.248-4.327.713-7.643.99Z" />
          <path d="m235.47 301.192 1.32-8.163a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.479 9.153c-3.316-.277-5.451-.742-7.643-.99Z" />
        </>
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="m256.847 311.975 1.519-9.401a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.362 8.422c-2.656.63-5.341.883-7.725.979Z" />
          <path d="m235.428 310.996 1.362-8.422a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.519 9.401c-2.384-.096-5.069-.349-7.725-.979Z" />
        </>
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="m256.889 296.96 1.477-9.141a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.5 9.276c-2.335.018-5.263-.055-7.821-.135Z" />
          <path d="m235.29 297.095 1.5-9.276a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.477 9.141c-2.559.08-5.486.153-7.821.135Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="m256.776 306.377 1.59-9.837a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.113 6.881c-2.47 1.1-4.687 2.235-5.512 2.573-.318.131-1.083.262-2.035.383Z" />
          <path d="m235.677 303.421 1.113-6.881a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.59 9.837c-.952-.121-1.717-.252-2.035-.383-.825-.338-3.042-1.473-5.512-2.573Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="m254.541 304.846 1.557-9.632a2.452 2.452 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.015 6.275c-.533.22-1.042.519-1.665.853-1.041.558-2.259 1.839-5.751 2.504Z" />
          <path d="m238.043 301.489 1.015-6.275a2.452 2.452 0 0 1 2.42-2.061h.003c1.204 0 2.229.873 2.421 2.061l1.557 9.632c-3.492-.665-4.71-1.946-5.751-2.504-.623-.334-1.132-.633-1.665-.853Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="m256.785 304.97 1.581-9.782a2.453 2.453 0 0 1 2.421-2.06h.003c1.203 0 2.228.873 2.42 2.06l1.575 9.741a666.49 666.49 0 0 1-8 .041Z" />
          <path d="m235.215 304.929 1.575-9.741a2.452 2.452 0 0 1 2.42-2.06h.003c1.203 0 2.229.873 2.421 2.06l1.581 9.782c-2.632 0-5.563-.01-8-.041Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="m256.947 300.289 1.419-8.782a2.453 2.453 0 0 1 2.421-2.06h.003c1.203 0 2.228.873 2.42 2.06l1.595 9.867c-.115-.007-.226-.013-.331-.021-2.089-.143-3.45-1.432-4.542-1.489-1.362-.072-2.201.13-2.985.425Z" />
          <path d="m235.195 301.374 1.595-9.867a2.452 2.452 0 0 1 2.42-2.06h.003c1.203 0 2.229.873 2.421 2.06l1.419 8.782c-.784-.295-1.623-.497-2.985-.425-1.092.057-2.453 1.346-4.542 1.489-.105.008-.216.014-.331.021Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="m256.849 303.876 1.517-9.385a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.212 7.495c-3.017.989-5.561 1.556-7.573 1.89Z" />
          <path d="m235.578 301.986 1.212-7.495a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.517 9.385c-2.013-.334-4.556-.901-7.573-1.89Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="m256.912 300.794 1.454-8.998a2.453 2.453 0 0 1 2.421-2.06h.003c1.203 0 2.228.873 2.42 2.06l1.593 9.856c-2.399-.417-4.971-.814-7.891-.858Z" />
          <path d="m235.197 301.652 1.593-9.856a2.452 2.452 0 0 1 2.42-2.06h.003c1.203 0 2.229.873 2.421 2.06l1.454 8.998c-2.92.044-5.492.441-7.891.858Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="m256.831 300.414 1.535-9.494a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.454 8.995c-1.628.199-3.265.232-4.758.008a44.66 44.66 0 0 1-3.075.491Z" />
          <path d="m235.336 299.915 1.454-8.995a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.535 9.494a44.66 44.66 0 0 1-3.075-.491c-1.493.224-3.13.191-4.758-.008Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="m257.236 300.133 1.13-6.99a2.453 2.453 0 0 1 2.421-2.061h.003c1.203 0 2.228.873 2.42 2.061l1.614 9.985a.736.736 0 0 1 .009.148c-2.748-.877-5.157-2.332-7.597-3.143Z" />
          <path d="M235.167 303.276a.736.736 0 0 1 .009-.148l1.614-9.985a2.452 2.452 0 0 1 2.42-2.061h.003c1.203 0 2.229.873 2.421 2.061l1.13 6.99c-2.439.811-4.848 2.266-7.597 3.143Z" />
        </>
      ),
    },
  },
};

const teeth_shape_pQCNhQrh9Z_upper = {
  id: "teeth_shape_pQCNhQrh9Z_upper",
  name: "Teeth 8 (Upper)",
  description: "Buck teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <path d="M242.893 302.335a3.45 3.45 0 0 0-.035.488v5.362c0 .558.453 1.011 1.011 1.011h4.855c.559 0 1.012-.453 1.012-1.011v-4.642c-3.353-.107-5.235-.96-6.843-1.208Zm14.214.001c.023.16.035.323.035.487v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-4.642c3.353-.107 5.236-.957 6.843-1.207Z" />
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <path d="M242.903 305.477c1.991-1.845 3.048.354 6.832.552v5.361a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.01 1.01 0 0 1-1.011-1.011v-5.362c0-.185.015-.37.045-.551Zm7.361.553c3.777-.195 4.837-2.384 6.832-.56.03.184.046.37.046.558v5.362c0 .559-.453 1.011-1.012 1.011h-4.854a1.011 1.011 0 0 1-1.012-1.011v-5.36Z" />
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <path d="M242.996 303.299c1.58.114 3.384.679 6.739.747v5.579a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362c0-.329.047-.653.138-.964Zm14.008.003c.091.309.138.633.138.961v5.362c0 .558-.453 1.011-1.012 1.011h-4.854a1.012 1.012 0 0 1-1.012-1.011v-5.579c3.355-.063 5.158-.631 6.74-.744Z" />
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <path d="M249.732 307.074a14.184 14.184 0 0 0-5.388 1.107h-.001c-.481.202-.976.427-1.485.665v5.367c0 .558.453 1.011 1.011 1.011h4.855c.559 0 1.012-.453 1.012-1.011v-7.031c0-.036-.002-.072-.004-.108Zm.535.005c1.783.052 3.559.434 5.228 1.141.532.227 1.081.482 1.647.752v5.241c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-7.031l.003-.103Z" />
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <path d="M249.739 303.406c0-.97-.787-1.757-1.757-1.757h-1.694c-.909 0-1.78.361-2.423 1.003a3.429 3.429 0 0 0-1.003 2.423v5.362a1.01 1.01 0 0 0 1.011 1.011h4.855c.558 0 1.011-.453 1.011-1.011v-7.031Zm.529 0c0-.97.787-1.757 1.757-1.757h1.694c.909 0 1.78.361 2.423 1.003a3.429 3.429 0 0 1 1.003 2.423v5.362a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-7.031Z" />
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <path d="M249.735 302.009c0-.971-.786-1.757-1.757-1.757h-1.694a3.425 3.425 0 0 0-3.426 3.426v5.361c0 .559.453 1.012 1.011 1.012h4.855c.559 0 1.011-.453 1.011-1.012v-7.03Zm.529 0c0-.971.787-1.757 1.758-1.757h1.694a3.427 3.427 0 0 1 3.426 3.426v5.361c0 .559-.453 1.012-1.012 1.012h-4.854a1.012 1.012 0 0 1-1.012-1.012v-7.03Z" />
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <path d="M249.736 300.695c0-.97-.787-1.757-1.758-1.757h-1.694c-.909 0-1.78.361-2.422 1.004a3.423 3.423 0 0 0-1.004 2.422v5.362a1.01 1.01 0 0 0 1.011 1.011h4.855c.559 0 1.012-.452 1.012-1.011v-7.031Zm.528 0c0-.97.787-1.757 1.758-1.757h1.694c.909 0 1.78.361 2.422 1.004a3.423 3.423 0 0 1 1.004 2.422v5.362a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.012-1.011v-7.031Z" />
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M249.532 306.796c0-.971-.787-1.757-1.757-1.757h-1.695a3.422 3.422 0 0 0-3.426 3.426v5.361c0 .559.453 1.012 1.012 1.012h4.854c.559 0 1.012-.453 1.012-1.012v-7.03Zm.529 0c0-.971.786-1.757 1.757-1.757h1.694a3.425 3.425 0 0 1 3.426 3.426v5.361c0 .559-.453 1.012-1.011 1.012h-4.855a1.012 1.012 0 0 1-1.011-1.012v-7.03Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <path d="M249.735 305.126c0-.97-.786-1.757-1.757-1.757h-1.694c-.909 0-1.78.361-2.422 1.004a3.422 3.422 0 0 0-1.004 2.422v5.362c0 .558.453 1.011 1.011 1.011h4.855a1.01 1.01 0 0 0 1.011-1.011v-7.031Zm.529 0c0-.97.787-1.757 1.758-1.757h1.694c.908 0 1.78.361 2.422 1.004a3.422 3.422 0 0 1 1.004 2.422v5.362c0 .558-.453 1.011-1.012 1.011h-4.854a1.012 1.012 0 0 1-1.012-1.011v-7.031Z" />
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <>
          <path d="M249.736 303.315c0-.97-.787-1.757-1.758-1.757h-1.694a3.425 3.425 0 0 0-3.426 3.426v5.362c0 .558.453 1.011 1.011 1.011h4.855c.559 0 1.012-.453 1.012-1.011v-7.031Zm.528 0c0-.97.787-1.757 1.758-1.757h1.694a3.425 3.425 0 0 1 3.426 3.426v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-7.031Z" />
        </>
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <path d="M249.736 300.594c0-.97-.787-1.757-1.758-1.757h-1.694a3.425 3.425 0 0 0-3.426 3.426v5.362c0 .558.453 1.011 1.011 1.011h4.855c.559 0 1.012-.453 1.012-1.011v-7.031Zm.528 0c0-.97.787-1.757 1.758-1.757h1.694a3.425 3.425 0 0 1 3.426 3.426v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-7.031Z" />
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <path d="M249.735 305.126c0-.97-.786-1.757-1.757-1.757h-1.694c-.909 0-1.78.361-2.422 1.004a3.422 3.422 0 0 0-1.004 2.422v5.362c0 .558.453 1.011 1.011 1.011h4.855a1.01 1.01 0 0 0 1.011-1.011v-7.031Zm.529 0c0-.97.787-1.757 1.758-1.757h1.694c.908 0 1.78.361 2.422 1.004a3.422 3.422 0 0 1 1.004 2.422v5.362c0 .558-.453 1.011-1.012 1.011h-4.854a1.012 1.012 0 0 1-1.012-1.011v-7.031Z" />
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="M257.142 305.752v3.597c0 .559-.453 1.012-1.011 1.012h-4.855c-.257 0-.491-.096-.669-.253 1.694-.798 4.008-2.544 6.535-4.356Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="M242.858 305.752v3.597c0 .559.453 1.012 1.011 1.012h4.855c.257 0 .491-.096.669-.253-1.694-.798-4.008-2.544-6.535-4.356Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <path d="M249.735 306.47h-6.861a3.2 3.2 0 0 0-.016.325v5.362c0 .558.453 1.011 1.011 1.011h4.855a1.01 1.01 0 0 0 1.011-1.011v-5.687Zm7.391 0a3.2 3.2 0 0 1 .016.325v5.362c0 .558-.453 1.011-1.012 1.011h-4.854a1.012 1.012 0 0 1-1.012-1.011v-5.687h6.862Z" />
      ),
    }, // Not a bug that these two are the same!!
    mouth_shape_74Nrw: {
      base: (
        <path d="M249.735 306.47h-6.861a3.2 3.2 0 0 0-.016.325v5.362c0 .558.453 1.011 1.011 1.011h4.855a1.01 1.01 0 0 0 1.011-1.011v-5.687Zm7.391 0a3.2 3.2 0 0 1 .016.325v5.362c0 .558-.453 1.011-1.012 1.011h-4.854a1.012 1.012 0 0 1-1.012-1.011v-5.687h6.862Z" />
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <path d="M242.912 306.193a3.353 3.353 0 0 0-.054.602v5.362c0 .558.453 1.011 1.011 1.011h4.855a1.01 1.01 0 0 0 1.011-1.011v-5.208a33.541 33.541 0 0 1-6.823-.756Zm14.176-.002c.036.198.054.4.054.604v5.362c0 .558-.453 1.011-1.012 1.011h-4.854a1.012 1.012 0 0 1-1.012-1.011v-5.208a33.475 33.475 0 0 0 6.824-.758Z" />
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <path d="M242.919 306.152a3.45 3.45 0 0 0-.061.643v5.362c0 .558.453 1.011 1.011 1.011h4.855a1.01 1.01 0 0 0 1.011-1.011v-5.58a59.12 59.12 0 0 1-6.816-.425Zm14.162-.001c.04.211.061.426.061.644v5.362c0 .558-.453 1.011-1.012 1.011h-4.854a1.012 1.012 0 0 1-1.012-1.011v-5.58a58.889 58.889 0 0 0 6.817-.426Z" />
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <path d="M249.736 303.591a32.493 32.493 0 0 0-6.778.77c-.066.266-.1.541-.1.82v5.362c0 .558.453 1.011 1.011 1.011h4.855c.559 0 1.012-.453 1.012-1.011v-6.952Zm.528 0c2.274.019 4.546.276 6.779.772.065.266.099.54.099.818v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-6.952Z" />
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <path d="M249.735 300.603c-2.265.01-4.528.153-6.779.428a3.452 3.452 0 0 0-.098.813v5.362c0 .558.453 1.011 1.011 1.011h4.855a1.01 1.01 0 0 0 1.011-1.011v-6.603Zm.529 0c2.265.011 4.529.153 6.78.429.065.263.098.536.098.812v5.362c0 .558-.453 1.011-1.012 1.011h-4.854a1.012 1.012 0 0 1-1.012-1.011v-6.603Z" />
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <path d="M243.133 305.451c2.15.008 4.374.012 6.602.012v6.694a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362c0-.467.095-.923.275-1.344Zm13.734 0c.18.421.275.877.275 1.344v5.362c0 .558-.453 1.011-1.012 1.011h-4.854a1.012 1.012 0 0 1-1.012-1.011v-6.694c2.228 0 4.452-.004 6.603-.012Z" />
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <path d="M242.992 300.815c2.32.316 2.199 1.651 5.459 2.136.163.052.7.082 1.285.089v4.086c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362c0-.324.046-.643.134-.949Zm14.016 0c.088.306.134.625.134.949v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-4.086c.585-.007 1.122-.037 1.285-.089 3.26-.485 3.139-1.82 5.459-2.136Z" />
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <path d="M249.736 303.773v6.663c0 .559-.453 1.012-1.012 1.012h-4.855a1.012 1.012 0 0 1-1.011-1.012v-5.362c0-.197.017-.393.05-.585 2.539-.641 4.435-.539 6.524-.707a6.95 6.95 0 0 1 .304-.009Zm.528 0c.134.002.247.005.304.009 2.089.168 3.985.066 6.524.707.033.192.05.388.05.585v5.362c0 .559-.453 1.012-1.011 1.012h-4.855a1.012 1.012 0 0 1-1.012-1.012v-6.663Z" />
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <path d="M249.736 304.271V311c0 .559-.453 1.011-1.012 1.011h-4.855a1.01 1.01 0 0 1-1.011-1.011v-5.362l.002-.114c1.679-.676 3.83-1.182 6.876-1.253Zm.528 0c3.046.071 5.198.577 6.876 1.253l.002.114V311a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.012-1.011v-6.729Z" />
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <path d="M242.893 305.266c2.055.131 2.562 1.011 6.842.745v5.104a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.01 1.01 0 0 1-1.011-1.011v-5.362c0-.164.012-.327.035-.487Zm14.214 0c.023.16.035.323.035.487v5.362c0 .559-.453 1.011-1.012 1.011h-4.854a1.011 1.011 0 0 1-1.012-1.011v-5.104c4.281.266 4.788-.614 6.843-.745Z" />
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <path d="M249.736 303.282v5.387c0 .559-.453 1.012-1.012 1.012h-4.855a1.012 1.012 0 0 1-1.011-1.012v-5.342c2.576-.023 4.96-.045 6.863-.045h.015Zm.528 0c1.949.004 4.332.028 6.878.05v5.337c0 .559-.453 1.012-1.011 1.012h-4.855a1.012 1.012 0 0 1-1.012-1.012v-5.387Z" />
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <path d="M242.882 311.286c2.244.006 4.548.009 6.854.009v5.754c0 .559-.453 1.012-1.012 1.012h-4.855a1.012 1.012 0 0 1-1.011-1.012v-5.361c0-.135.008-.27.024-.402Zm14.236 0c.016.132.024.267.024.402v5.361c0 .559-.453 1.012-1.011 1.012h-4.855a1.012 1.012 0 0 1-1.012-1.012v-5.754c2.307 0 4.611-.003 6.854-.009Z" />
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <path d="M242.933 296.563c1.361.299 3.231 1.485 6.803 1.42v4.653c0 .559-.453 1.011-1.012 1.011h-4.855a1.01 1.01 0 0 1-1.011-1.011v-5.362c0-.241.026-.479.075-.711Zm14.134 0c.049.232.075.47.075.711v5.362a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.012-1.011v-4.653c3.572.065 5.442-1.121 6.803-1.42Z" />
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <path d="M249.736 298.918v6.373c0 .559-.453 1.012-1.012 1.012h-4.855a1.012 1.012 0 0 1-1.011-1.012v-5.232c1.71-.633 3.882-1.09 6.878-1.141Zm.528 0c2.995.051 5.168.507 6.878 1.141v5.232c0 .559-.453 1.012-1.011 1.012h-4.855a1.012 1.012 0 0 1-1.012-1.012v-6.373Z" />
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M249.735 305.126c0-.97-.786-1.757-1.757-1.757h-1.694c-.909 0-1.78.361-2.422 1.004a3.422 3.422 0 0 0-1.004 2.422v5.362c0 .558.453 1.011 1.011 1.011h4.855a1.01 1.01 0 0 0 1.011-1.011v-7.031Zm.529 0c0-.97.787-1.757 1.758-1.757h1.694c.908 0 1.78.361 2.422 1.004a3.422 3.422 0 0 1 1.004 2.422v5.362c0 .558-.453 1.011-1.012 1.011h-4.854a1.012 1.012 0 0 1-1.012-1.011v-7.031Z" />
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <path d="M249.736 302.134v5.676c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362c0-.08.003-.16.009-.239 2.842-.022 5.303-.053 6.869-.075Zm.528 0c1.567.022 4.029.053 6.869.075.006.079.009.159.009.239v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-5.676Z" />
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <path d="m242.858 306.96.237-.054c1.439-.325 2.881-.237 4.34-.173.108.005 1.206.007 2.3.007v5.417a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.197Zm7.406-.22c.888-.001 1.661-.003 1.751-.007 1.46-.064 2.901-.152 4.34.173.246.055.509.118.787.186v5.065c0 .558-.453 1.011-1.012 1.011h-4.854a1.012 1.012 0 0 1-1.012-1.011v-5.417Z" />
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <path d="m242.858 305.033 2.878.008v.002s1.567.003 4 .003v5.367c0 .559-.453 1.012-1.012 1.012h-4.855a1.012 1.012 0 0 1-1.011-1.012v-5.38Zm14.284 0v5.38c0 .559-.453 1.012-1.011 1.012h-4.855a1.012 1.012 0 0 1-1.012-1.012v-5.367c2.433 0 4-.003 4-.003v-.002l2.878-.008Z" />
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <path d="M242.861 304.16c2.061.509 3.932 1.783 6.064 1.836.144.004.46.006.81.007v3.664a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362c0-.049.001-.097.003-.145Zm14.278 0c.002.048.003.096.003.145v5.362c0 .558-.453 1.011-1.012 1.011h-4.854a1.012 1.012 0 0 1-1.012-1.011v-3.664c.351-.001.667-.003.811-.007 2.132-.053 4.003-1.327 6.064-1.836Z" />
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <path d="M242.927 306.113c2.059.376 4.744.699 6.808.725v5.319a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362c0-.231.023-.459.069-.682Zm14.146 0c.046.223.069.451.069.682v5.362c0 .558-.453 1.011-1.012 1.011h-4.854a1.012 1.012 0 0 1-1.012-1.011v-5.319c2.065-.026 4.75-.349 6.809-.725Z" />
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <path d="M242.896 301.41a3.42 3.42 0 0 0-.038.507v5.362a1.01 1.01 0 0 0 1.011 1.011h4.855c.559 0 1.012-.452 1.012-1.011v-3.755c-2.412-1.101-4.698-1.77-6.84-2.114Zm14.208 0c.025.167.038.336.038.507v5.362a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.012-1.011v-3.755c2.412-1.1 4.697-1.77 6.84-2.114Z" />
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <path d="M242.89 302.163c1.785.156 3.916.259 6.595.242l.251-.001v5.584c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362c0-.156.011-.31.032-.463Zm14.22 0c.021.153.032.307.032.463v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-5.584l.251.001c2.679.017 4.81-.086 6.595-.242Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <path d="M249.249 296.484c.301.316.487.743.487 1.214v10.876c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-9.207a3.426 3.426 0 0 1 1.526-2.851l.19-.006c.485-.015 2.501-.024 4.675-.026Zm1.502 0c2.174.002 4.19.011 4.675.026l.19.006a3.426 3.426 0 0 1 1.526 2.851v9.207c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-10.876c0-.471.186-.898.487-1.214Z" />
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="M249.736 296.781v6.031c0 .559-.453 1.011-1.012 1.011h-4.855a1.01 1.01 0 0 1-1.011-1.011v-5.362c0-.162.012-.324.034-.483 2.304-.071 4.335-.149 5.41-.175.294-.006.847-.01 1.434-.011Zm.528 0c.587.001 1.14.005 1.434.011 1.075.026 3.106.104 5.41.175.022.159.034.321.034.483v5.362a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.012-1.011v-6.031Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="M242.86 299.278c2.129.032 4.336.205 5.871.179.216-.004.596-.006 1.005-.007v5.31c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362l.002-.12Zm14.28 0 .002.12v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-5.31c.409.001.789.003 1.005.007 1.535.026 3.742-.147 5.871-.179Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="M242.993 300.399c3.264-.237 2.656 1.309 6.505 1.583.006.008.104.014.237.017v4.713c0 .559-.452 1.012-1.011 1.012h-4.855a1.012 1.012 0 0 1-1.011-1.012v-5.361c0-.325.046-.645.135-.952Zm7.271 1.6c.134-.003.232-.009.238-.017 3.849-.274 3.241-1.82 6.505-1.583.089.307.135.627.135.952v5.361c0 .559-.453 1.012-1.012 1.012h-4.854a1.012 1.012 0 0 1-1.012-1.012v-4.713Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="M249.736 304.954v5.395c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011V304.97c3.021.001 5.702-.01 6.878-.016Zm.528 0c1.177.006 3.857.017 6.878.016V310.349c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-5.395Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="M242.862 300.219c1.382.485 2.483 1.296 5.757 1.486.05.006.553.01 1.117.011v4.025c0 .559-.453 1.012-1.012 1.012h-4.855a1.012 1.012 0 0 1-1.011-1.012v-5.361c0-.054.001-.107.004-.161Zm14.276 0c.003.054.004.107.004.161v5.361c0 .559-.453 1.012-1.011 1.012h-4.855a1.012 1.012 0 0 1-1.012-1.012v-4.025a36.42 36.42 0 0 0 1.117-.011c3.274-.19 4.375-1.001 5.757-1.486Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="M242.858 303.826c2.267.392 3.897.491 4.809.548.301.019 1.16.029 2.069.031v4.731c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.31Zm14.284 0v5.31c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-4.731a52.023 52.023 0 0 0 2.069-.031c.912-.057 2.542-.156 4.809-.548Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="M242.858 300.798a35.139 35.139 0 0 1 4.85.231c.251.154 1.112.237 2.028.25v4.885c0 .559-.453 1.012-1.012 1.012h-4.855a1.012 1.012 0 0 1-1.011-1.012v-5.366Zm7.406.481c.916-.013 1.778-.097 2.028-.25a35.139 35.139 0 0 1 4.85-.231v5.366c0 .559-.453 1.012-1.011 1.012h-4.855a1.012 1.012 0 0 1-1.012-1.012v-4.885Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="M242.858 299.101c4.518-2.047 4.604-1.235 6.878-1.246v6.111c0 .559-.453 1.011-1.012 1.011h-4.855a1.01 1.01 0 0 1-1.011-1.011v-4.865Zm7.406-1.246c2.273.011 2.36-.801 6.878 1.246v4.865a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.012-1.011v-6.111Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="M242.858 300.102c1.108-.36 2.222-.584 3.378-.558.717.016 2.089.024 3.5.025v4.946c0 .559-.453 1.012-1.012 1.012h-4.855a1.012 1.012 0 0 1-1.011-1.012v-4.413Zm7.406-.533c1.411-.001 2.783-.009 3.5-.025 1.156-.026 2.27.198 3.378.558v4.413c0 .559-.453 1.012-1.011 1.012h-4.855a1.012 1.012 0 0 1-1.012-1.012v-4.946Z" />
        </>
      ),
    },
  },
};

const teeth_shape_pQCNhQrh9Z_lower = {
  id: "teeth_shape_pQCNhQrh9Z_lower",
  name: "Teeth 8 (Lower)",
  description: "Buck teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_Pf59F: {
      base: (
        <path d="M242.863 316.453a3.487 3.487 0 0 1-.009-.251v-5.362a1.01 1.01 0 0 1 1.011-1.011h4.855c.558 0 1.011.453 1.011 1.011v5.427c-2.447.057-4.753.175-6.868.186Zm7.397-.197v-5.416c0-.558.453-1.011 1.011-1.011h4.855a1.01 1.01 0 0 1 1.011 1.011v5.362c0 .081-.002.161-.008.241-1.93-.037-4.082-.154-6.519-.193l-.35.006Z" />
      ),
    },
    mouth_shape_7EGax: {
      base: (
        <path d="M250.278 306.029a1.716 1.716 0 0 1-.014-.216v-7.031c0-.559.453-1.011 1.012-1.011h4.854c.559 0 1.012.452 1.012 1.011v5.362c0 .407-.073.807-.211 1.183-1.889-1.558-2.993.509-6.653.702Zm-7.208-.699a3.423 3.423 0 0 1-.212-1.186v-5.362a1.01 1.01 0 0 1 1.011-1.011h4.855a1.01 1.01 0 0 1 1.011 1.011v7.031c0 .073-.004.145-.013.216-3.666-.197-4.768-2.272-6.652-.699Z" />
      ),
    },
    mouth_shape_lLo77: {
      base: (
        <path d="M250.264 304.046c3.36-.061 5.162-.628 6.75-.74.084-.3.128-.612.128-.928v-5.362c0-.558-.453-1.011-1.012-1.011h-4.854c-.559 0-1.012.453-1.012 1.011v7.03Zm-7.28-.748a3.416 3.416 0 0 1-.126-.92v-5.362c0-.558.453-1.011 1.011-1.011h4.855a1.01 1.01 0 0 1 1.011 1.011v7.029c-3.364-.069-5.167-.638-6.751-.747Z" />
      ),
    },
    mouth_shape_iXJrz: {
      base: (
        <path d="M250.264 306.079c1.917.051 3.826.46 5.621 1.221h.001c.377.16.763.337 1.157.523.065-.265.099-.538.099-.815v-5.362a1.01 1.01 0 0 0-1.011-1.011h-4.855c-.559 0-1.012.452-1.012 1.011v4.433Zm-7.332 1.634a3.381 3.381 0 0 1-.074-.705v-5.362a1.01 1.01 0 0 1 1.011-1.011h4.855c.559 0 1.012.452 1.012 1.011v4.428a15.18 15.18 0 0 0-5.78 1.185c-.335.141-.676.293-1.024.454Z" />
      ),
    },
    // mouth_shape_6hpUa: {},
    mouth_shape_jca3e: {
      base: (
        <path d="M249.736 312.167c0 .97-.787 1.757-1.758 1.757h-1.694a3.425 3.425 0 0 1-3.426-3.426v-5.362c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v7.031Zm.528 0c0 .97.787 1.757 1.758 1.757h1.694a3.425 3.425 0 0 0 3.426-3.426v-5.362c0-.558-.453-1.011-1.011-1.011h-4.855c-.559 0-1.012.453-1.012 1.011v7.031Z" />
      ),
    },
    mouth_shape_Y5i4J: {
      base: (
        <path d="M249.735 305.176c0 .971-.786 1.757-1.757 1.757h-1.694a3.425 3.425 0 0 1-3.426-3.426v-5.361c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.011.453 1.011 1.012v7.03Zm.529 0c0 .971.787 1.757 1.758 1.757h1.694a3.427 3.427 0 0 0 3.426-3.426v-5.361c0-.559-.453-1.012-1.012-1.012h-4.854c-.559 0-1.012.453-1.012 1.012v7.03Z" />
      ),
    },
    mouth_shape_cWzSG: {
      base: (
        <path d="M249.736 306.188c0 .97-.787 1.757-1.758 1.757h-1.694a3.425 3.425 0 0 1-3.426-3.426v-5.362c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v7.031Zm.528 0c0 .97.787 1.757 1.758 1.757h1.694a3.425 3.425 0 0 0 3.426-3.426v-5.362c0-.558-.453-1.011-1.011-1.011h-4.855c-.559 0-1.012.453-1.012 1.011v7.031Z" />
      ),
    },
    mouth_shape_AJqb6: {
      base: (
        <>
          <path d="M249.735 314.975c0 .971-.786 1.757-1.757 1.757h-1.694a3.424 3.424 0 0 1-3.426-3.426v-5.361c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.011.453 1.011 1.012v7.03Zm.529 0c0 .971.787 1.757 1.758 1.757h1.694a3.422 3.422 0 0 0 3.426-3.426v-5.361c0-.559-.453-1.012-1.012-1.012h-4.854c-.559 0-1.012.453-1.012 1.012v7.03Z" />
        </>
      ),
    },
    mouth_shape_TBIsG: {
      base: (
        <path d="M249.736 312.511c0 .97-.787 1.757-1.758 1.757h-1.694a3.425 3.425 0 0 1-3.426-3.426v-5.362c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v7.031Zm.528 0c0 .97.787 1.757 1.758 1.757h1.694a3.425 3.425 0 0 0 3.426-3.426v-5.362c0-.558-.453-1.011-1.011-1.011h-4.855c-.559 0-1.012.453-1.012 1.011v7.031Z" />
      ),
    },
    mouth_shape_Z5QrA: {
      base: (
        <path d="M249.735 314.975c0 .971-.786 1.757-1.757 1.757h-1.694a3.424 3.424 0 0 1-3.426-3.426v-5.361c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.011.453 1.011 1.012v7.03Zm.529 0c0 .971.787 1.757 1.758 1.757h1.694a3.422 3.422 0 0 0 3.426-3.426v-5.361c0-.559-.453-1.012-1.012-1.012h-4.854c-.559 0-1.012.453-1.012 1.012v7.03Z" />
      ),
    },
    mouth_shape_VCgvh: {
      base: (
        <path d="M249.735 314.975c0 .971-.786 1.757-1.757 1.757h-1.694a3.424 3.424 0 0 1-3.426-3.426v-5.361c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.011.453 1.011 1.012v7.03Zm.529 0c0 .971.787 1.757 1.758 1.757h1.694a3.422 3.422 0 0 0 3.426-3.426v-5.361c0-.559-.453-1.012-1.012-1.012h-4.854c-.559 0-1.012.453-1.012 1.012v7.03Z" />
      ),
    },
    mouth_shape_BrKMP: {
      base: (
        <path d="M249.735 311.189c0 .97-.786 1.757-1.757 1.757h-1.694c-.909 0-1.78-.361-2.422-1.004a3.423 3.423 0 0 1-1.004-2.422v-5.362c0-.558.453-1.011 1.011-1.011h4.855a1.01 1.01 0 0 1 1.011 1.011v7.031Zm.529 0c0 .97.787 1.757 1.758 1.757h1.694c.908 0 1.78-.361 2.422-1.004a3.423 3.423 0 0 0 1.004-2.422v-5.362c0-.558-.453-1.011-1.012-1.011h-4.854c-.559 0-1.012.453-1.012 1.011v7.031Z" />
      ),
    },
    mouth_shape_Bri0F: {
      base: (
        <path d="M257.142 305.742v-2.397a1.01 1.01 0 0 0-1.011-1.011h-4.855c-.559 0-1.012.452-1.012 1.011v6.901c1.716-.705 4.175-2.566 6.878-4.504Z" />
      ),
    },
    mouth_shape_gg924: {
      base: (
        <path d="M242.858 305.742v-2.397a1.01 1.01 0 0 1 1.011-1.011h4.855c.559 0 1.012.452 1.012 1.011v6.901c-1.716-.705-4.175-2.566-6.878-4.504Z" />
      ),
    },
    mouth_shape_kwY93: {
      base: (
        <path d="M242.947 305.47a3.4 3.4 0 0 1-.089-.775v-5.362c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v6.137h-6.789Zm7.317 0h6.789a3.4 3.4 0 0 0 .089-.775v-5.362c0-.558-.453-1.011-1.011-1.011h-4.855c-.559 0-1.012.453-1.012 1.011v6.137Z" />
      ),
    },
    mouth_shape_74Nrw: {
      base: (
        <path d="M242.906 305.47a3.418 3.418 0 0 1-.048-.573v-5.362a1.01 1.01 0 0 1 1.011-1.011h4.855c.559 0 1.012.452 1.012 1.011v5.935h-6.83Zm7.358 0h6.83c.032-.188.048-.38.048-.573v-5.362a1.01 1.01 0 0 0-1.011-1.011h-4.855c-.559 0-1.012.452-1.012 1.011v5.935Z" />
      ),
    },
    mouth_shape_4K66J: {
      base: (
        <path d="M242.858 305.157v-5.302c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.012.453 1.012 1.012v6.094a32.465 32.465 0 0 1-6.878-.792Zm7.406.792a32.517 32.517 0 0 0 6.878-.794v-5.3c0-.559-.453-1.012-1.011-1.012h-4.855c-.559 0-1.012.453-1.012 1.012v6.094Z" />
      ),
    },
    mouth_shape_8fH3J: {
      base: (
        <path d="M242.908 305.144a3.421 3.421 0 0 1-.05-.582v-5.361c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.012.453 1.012 1.012v6.376c-2.28-.01-4.56-.155-6.828-.433Zm7.356.433a58.183 58.183 0 0 0 6.828-.435c.033-.19.05-.384.05-.58v-5.361c0-.559-.453-1.012-1.011-1.012h-4.855c-.559 0-1.012.453-1.012 1.012v6.376Z" />
      ),
    },
    mouth_shape_mUU93: {
      base: (
        <path d="M250.264 302.591c2.306.019 4.609.275 6.873.769.003-.061.005-.122.005-.183v-5.362c0-.558-.453-1.011-1.011-1.011h-4.855c-.559 0-1.012.453-1.012 1.011v4.776Zm-7.401.767a3.645 3.645 0 0 1-.005-.181v-5.362c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v4.776a33.53 33.53 0 0 0-6.873.767Z" />
      ),
    },
    mouth_shape_n8jHw: {
      base: (
        <path d="M250.265 299.603c2.277.01 4.556.153 6.823.427.036-.2.054-.403.054-.609v-5.362c0-.558-.453-1.011-1.011-1.011h-4.855c-.558 0-1.011.453-1.011 1.011v5.544Zm-7.352.425a3.41 3.41 0 0 1-.055-.607v-5.362c0-.558.453-1.011 1.012-1.011h4.854c.559 0 1.012.453 1.012 1.011v5.544a59.2 59.2 0 0 0-6.823.425Z" />
      ),
    },
    mouth_shape_gAGfv48Jyv: {
      base: (
        <path d="M242.858 305.45v-5.17c0-.558.453-1.011 1.011-1.011h4.855a1.01 1.01 0 0 1 1.011 1.011v5.183c-2.322 0-4.641-.005-6.877-.013Zm7.406.013v-5.183c0-.558.453-1.011 1.012-1.011h4.854c.559 0 1.012.453 1.012 1.011v5.17c-2.236.008-4.556.013-6.878.013Z" />
      ),
    },
    mouth_shape_UVELjYPJVv: {
      base: (
        <path d="M242.858 300.798v-5.259c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v7.031c0 .162-.023.32-.064.469-.56-.008-1.064-.038-1.221-.088-3.321-.494-3.133-1.871-5.593-2.153Zm7.47 2.241a1.77 1.77 0 0 1-.064-.469v-7.031c0-.558.453-1.011 1.012-1.011h4.855c.558 0 1.011.453 1.011 1.011v5.259c-2.46.282-2.272 1.659-5.593 2.153-.157.05-.661.08-1.221.088Z" />
      ),
    },
    mouth_shape_CLeGkY6rtV: {
      base: (
        <path d="M242.91 309.594a3.475 3.475 0 0 1-.052-.596v-5.362a1.01 1.01 0 0 1 1.011-1.011h4.855c.559 0 1.012.452 1.012 1.011v6.094a46.32 46.32 0 0 1-1.253-.016 150.09 150.09 0 0 0-5.573-.12Zm7.354.136v-6.094c0-.559.453-1.011 1.012-1.011h4.855a1.01 1.01 0 0 1 1.011 1.011v5.362c0 .201-.018.401-.052.596a150.09 150.09 0 0 0-5.573.12c-.226.009-.72.014-1.253.016Z" />
      ),
    },
    mouth_shape_hi86bznFC8: {
      base: (
        <path d="M250.31 311.723a1.765 1.765 0 0 1-.046-.4v-7.031c0-.558.453-1.011 1.012-1.011h4.855c.558 0 1.011.453 1.011 1.011v5.362c0 .132-.008.264-.023.394-2.006.848-4.233 1.575-6.809 1.675Zm-7.429-1.675a3.443 3.443 0 0 1-.023-.394v-5.362c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v7.031c0 .137-.016.271-.046.4-2.576-.1-4.804-.827-6.809-1.675Z" />
      ),
    },
    mouth_shape_Nhz9eUYRLP: {
      base: (
        <path d="M242.884 305.265a3.412 3.412 0 0 1-.026-.418v-5.362c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v6.526c-4.288.266-4.789-.617-6.852-.746Zm7.38.746v-6.526c0-.558.453-1.011 1.012-1.011h4.855c.558 0 1.011.453 1.011 1.011v5.362c0 .141-.009.28-.026.418-2.063.129-2.564 1.012-6.852.746Z" />
      ),
    },
    mouth_shape_3dYVwhmj3K: {
      base: (
        <path d="M250.264 303.282v-5.48c0-.558.453-1.011 1.012-1.011h4.854c.559 0 1.012.453 1.012 1.011v5.362c0 .056-.002.112-.004.168-2.545-.022-4.926-.046-6.874-.05Zm-7.402.045a2.955 2.955 0 0 1-.004-.163v-5.362c0-.558.453-1.011 1.011-1.011h4.855a1.01 1.01 0 0 1 1.011 1.011v5.48h-.014c-1.902 0-4.284.022-6.859.045Z" />
      ),
    },
    mouth_shape_mMfCqd34QA: {
      base: (
        <path d="M242.858 311.28v-5.358c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v5.363c-2.319 0-4.635-.002-6.878-.005Zm7.406.005v-5.363c0-.558.453-1.011 1.012-1.011h4.855c.558 0 1.011.453 1.011 1.011v5.358c-2.243.003-4.558.005-6.878.005Z" />
      ),
    },
    mouth_shape_DK55jdpk4Z: {
      base: (
        <path d="M250.264 297.983v-6.573c0-.559.453-1.011 1.012-1.011h4.855a1.01 1.01 0 0 1 1.011 1.011v5.137c-1.368.271-3.24 1.502-6.878 1.436Zm-7.406-1.436v-5.137a1.01 1.01 0 0 1 1.011-1.011h4.855c.559 0 1.012.452 1.012 1.011v6.573c-3.638.066-5.51-1.165-6.878-1.436Z" />
      ),
    },
    mouth_shape_UAKGBhCAKt: {
      base: (
        <path d="M250.264 303.093v-5.359c0-.558.453-1.011 1.012-1.011h4.855c.558 0 1.011.453 1.011 1.011v5.362c0 .171-.013.34-.038.506-2.08-.29-4.239-.602-6.634-.512l-.206.003Zm-7.368.509a3.396 3.396 0 0 1-.038-.506v-5.362c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v5.359l-.206-.003c-2.395-.09-4.554.222-6.634.512Z" />
      ),
    },
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M249.735 314.975c0 .971-.786 1.757-1.757 1.757h-1.694a3.424 3.424 0 0 1-3.426-3.426v-5.361c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.011.453 1.011 1.012v7.03Zm.529 0c0 .971.787 1.757 1.758 1.757h1.694a3.422 3.422 0 0 0 3.426-3.426v-5.361c0-.559-.453-1.012-1.012-1.012h-4.854c-.559 0-1.012.453-1.012 1.012v7.03Z" />
      ),
    },
    mouth_shape_FmanKGMWfG: {
      base: (
        <path d="M242.908 302.209a3.456 3.456 0 0 1-.05-.585v-5.362c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v5.872c-1.559.022-4.003.052-6.828.075Zm7.356-.075v-5.872c0-.558.453-1.011 1.012-1.011h4.855c.558 0 1.011.453 1.011 1.011v5.362c0 .197-.017.393-.05.585-2.824-.023-5.269-.053-6.828-.075Z" />
      ),
    },
    mouth_shape_D3GhGBGTx6: {
      base: (
        <path d="M250.264 312.126c2.46.523 4.741 1.073 6.825 1.437.035-.196.053-.397.053-.599v-5.362c0-.558-.453-1.011-1.011-1.011h-4.855c-.559 0-1.012.453-1.012 1.011v4.524Zm-7.406-1.233v-3.291c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v4.412c-2.161-.451-4.457-.871-6.878-1.121Z" />
      ),
    },
    mouth_shape_PBugyVCrju: {
      base: (
        <path d="M242.864 305.033a3.55 3.55 0 0 1-.006-.207v-5.361c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.011.453 1.011 1.012v5.581c-2.432 0-3.999-.003-3.999-.003v-.002l-2.872-.008Zm7.4.013v-5.581c0-.559.453-1.012 1.012-1.012h4.854c.559 0 1.012.453 1.012 1.012v5.361c0 .07-.002.138-.006.207l-2.872.008v.002s-1.567.003-4 .003Z" />
      ),
    },
    mouth_shape_3n4RtRa4TA: {
      base: (
        <path d="M250.264 306.003v-6.859c0-.559.453-1.012 1.012-1.012h4.854c.559 0 1.012.453 1.012 1.012v5.015c-2.063.509-3.934 1.784-6.067 1.837-.144.004-.46.006-.811.007Zm-7.406-1.844v-5.015c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.011.453 1.011 1.012v6.859c-.35-.001-.666-.003-.81-.007-2.133-.053-4.004-1.328-6.067-1.837Z" />
      ),
    },
    mouth_shape_x8Srb5MwHe: {
      base: (
        <path d="M242.933 306.114a3.458 3.458 0 0 1-.075-.713v-5.361c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.012.453 1.012 1.012v6.798c-2.063-.026-4.744-.348-6.803-.724Zm7.331.724v-6.798c0-.559.453-1.012 1.012-1.012h4.855c.558 0 1.011.453 1.011 1.012v5.361c0 .242-.026.48-.075.713-2.058.376-4.74.698-6.803.724Z" />
      ),
    },
    mouth_shape_JbVGJk9wZC: {
      base: (
        <path d="M242.858 301.404v-5.09c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.012.453 1.012 1.012v7.03a1.7 1.7 0 0 1-.009.176c-2.423-1.104-4.718-1.774-6.869-2.116Zm7.415 2.116a1.7 1.7 0 0 1-.009-.176v-7.03c0-.559.453-1.012 1.012-1.012h4.855c.558 0 1.011.453 1.011 1.012v5.09c-2.15.342-4.446 1.011-6.869 2.116Z" />
      ),
    },
    mouth_shape_zw5c2PLccV: {
      base: (
        <path d="M242.858 302.16v-3.642c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v3.886l-.251.001c-2.695.017-4.836-.087-6.627-.245Zm7.406.244v-3.886c0-.558.453-1.011 1.012-1.011h4.855c.558 0 1.011.453 1.011 1.011v3.642c-1.791.158-3.932.262-6.627.245l-.251-.001Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <path d="M242.892 311.964a3.324 3.324 0 0 1-.034-.479v-5.361c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.012.453 1.012 1.012v5.894c-1.489-.034-3.96.075-6.844-.054Zm7.372.054v-5.894c0-.559.453-1.012 1.012-1.012h4.855c.558 0 1.011.453 1.011 1.012v5.361c0 .161-.011.321-.034.479-2.884.129-5.354.02-6.844.054Z" />
      ),
    },
    mouth_shape_Kwgjp7TLZ5: {
      base: (
        <>
          <path d="M242.858 296.968v-7.36a1.01 1.01 0 0 1 1.011-1.011h4.855c.559 0 1.012.452 1.012 1.011v7.173c-.587.001-1.14.005-1.434.011-1.081.026-3.127.105-5.444.176Zm7.406-.187v-7.173c0-.559.453-1.011 1.012-1.011h4.855a1.01 1.01 0 0 1 1.011 1.011v7.36c-2.317-.071-4.363-.15-5.444-.176-.294-.006-.847-.01-1.434-.011Z" />
        </>
      ),
    },
    mouth_shape_hHCq3fFJwU: {
      base: (
        <>
          <path d="M242.858 306.329v-4.868c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v5.454a11.634 11.634 0 0 1-.283-.005c-1.224-.055-4.507-.294-6.595-.581Zm7.406.586v-5.454c0-.558.453-1.011 1.012-1.011h4.855c.558 0 1.011.453 1.011 1.011v4.868c-2.088.287-5.371.526-6.595.581-.055.002-.159.004-.283.005Z" />
        </>
      ),
    },
    mouth_shape_624xfSaVMq: {
      base: (
        <>
          <path d="M242.858 304.111v-4.801c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v5.945c-3.302-.071-5.418-.565-6.878-1.144Zm7.406 1.144v-5.945c0-.558.453-1.011 1.012-1.011h4.855c.558 0 1.011.453 1.011 1.011v4.801c-1.46.579-3.576 1.073-6.878 1.144Z" />
        </>
      ),
    },
    mouth_shape_EqiShmqdqQ: {
      base: (
        <>
          <path d="M242.882 304.97a3.38 3.38 0 0 1-.024-.403v-5.362c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v5.749c-1.174.006-3.844.017-6.854.016Zm7.382-.016v-5.749c0-.558.453-1.011 1.012-1.011h4.855c.558 0 1.011.453 1.011 1.011v5.362c0 .135-.008.27-.024.403-3.011.001-5.681-.01-6.854-.016Z" />
        </>
      ),
    },
    mouth_shape_rQHM8VUjnC: {
      base: (
        <>
          <path d="M242.858 300.218v-5.141c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.012.453 1.012 1.012v6.639a36.42 36.42 0 0 1-1.117-.011c-3.277-.19-4.377-1.002-5.761-1.487Zm7.406 1.498v-6.639c0-.559.453-1.012 1.012-1.012h4.855c.558 0 1.011.453 1.011 1.012v5.141c-1.384.485-2.484 1.297-5.761 1.487-.05.006-.553.01-1.117.011Z" />
        </>
      ),
    },
    mouth_shape_PuNamW3UM3: {
      base: (
        <>
          <path d="M242.871 303.828a3.181 3.181 0 0 1-.013-.294v-5.362c0-.558.453-1.011 1.011-1.011h4.855c.559 0 1.012.453 1.012 1.011v6.233a52.023 52.023 0 0 1-2.069-.031c-.91-.057-2.535-.156-4.796-.546Zm7.393.577v-6.233c0-.558.453-1.011 1.012-1.011h4.855c.558 0 1.011.453 1.011 1.011v5.362c0 .099-.004.197-.013.294-2.261.39-3.886.489-4.796.546-.301.019-1.16.029-2.069.031Z" />
        </>
      ),
    },
    mouth_shape_qk6tSCphc4: {
      base: (
        <>
          <path d="M242.887 300.797a3.433 3.433 0 0 1-.029-.443v-5.362a1.01 1.01 0 0 1 1.011-1.011h4.855c.559 0 1.012.452 1.012 1.011v6.287c-.916-.013-1.777-.096-2.028-.25a35.036 35.036 0 0 0-4.821-.232Zm7.377.482v-6.287c0-.559.453-1.011 1.012-1.011h4.855a1.01 1.01 0 0 1 1.011 1.011v5.362c0 .149-.01.297-.029.443a35.036 35.036 0 0 0-4.821.232c-.25.153-1.112.237-2.028.25Z" />
        </>
      ),
    },
    mouth_shape_UJUEWSLgNE: {
      base: (
        <>
          <path d="M242.858 300.375v-5.339c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.012.453 1.012 1.012v5.629c-1.308.002-2.564.011-3.023.028a39.572 39.572 0 0 1-3.855-.318Zm7.406.29v-5.629c0-.559.453-1.012 1.012-1.012h4.855c.558 0 1.011.453 1.011 1.012v5.339a39.572 39.572 0 0 1-3.855.318 122.07 122.07 0 0 0-3.023-.028Z" />
        </>
      ),
    },
    mouth_shape_cDFSBvBKut: {
      base: (
        <>
          <path d="m242.859 300.102-.001-.084v-5.361c0-.559.453-1.012 1.011-1.012h4.855c.559 0 1.012.453 1.012 1.012v4.912a200.101 200.101 0 0 1-3.5-.025c-1.156-.026-2.27.198-3.377.558Zm7.405-.533v-4.912c0-.559.453-1.012 1.012-1.012h4.855c.558 0 1.011.453 1.011 1.012v5.361l-.001.084c-1.107-.36-2.221-.584-3.377-.558-.717.016-2.089.024-3.5.025Z" />
        </>
      ),
    },
  },
};

export const teethUpperPack1 = {
  teeth_shape_fg941_upper,
  teeth_shape_g23ff_upper,
  teeth_shape_K7ZtymTwRY_upper,
  teeth_shape_ye9BrrMRe7_upper,
  teeth_shape_fNzHNiDDtj_upper,
  teeth_shape_LzydJYaCxp_upper,
  teeth_shape_PXFkweRWP9_upper,
  teeth_shape_pQCNhQrh9Z_upper,
};

export const teethLowerPack1 = {
  teeth_shape_fg941_lower,
  teeth_shape_g23ff_lower,
  teeth_shape_K7ZtymTwRY_lower,
  teeth_shape_ye9BrrMRe7_lower,
  teeth_shape_fNzHNiDDtj_lower,
  teeth_shape_LzydJYaCxp_lower,
  teeth_shape_PXFkweRWP9_lower,
  teeth_shape_pQCNhQrh9Z_lower,
};

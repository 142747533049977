import React from "react";
import { connect } from "react-redux";
import { NeckAccessoriesList } from "./NeckAccessoriesList";
import AllMetals from "character-creator/colours/collections/Metals";
import { filterListById } from "character-creator/menu/menu-helper";
import ItemComponent from "character-creator/components/ItemComponent";

function mapStateToProps(state, ownProps) {
  const layerConfig = ownProps.layerConfig;
  let layerObject = null;

  if (ownProps.preview && ownProps.accessory) {
    layerObject = ownProps.accessory.shape.back
      ? ownProps.accessory.shape.back
      : ownProps.accessory.shape;
  } else if (layerConfig?.shape && layerConfig.shape !== "0") {
    layerObject =
      filterListById(NeckAccessoriesList, layerConfig.shape).shape.back ??
      filterListById(NeckAccessoriesList, layerConfig.shape).shape;
  }

  const colour =
    layerConfig && layerConfig.colour !== ""
      ? layerConfig.colour
      : AllMetals[3];

  const AccessoryLayer = {
    shape: layerObject,
    colour: colour,
  };

  return {
    AccessoryLayer,
  };
}

const NeckAccessoryBack = (props) => {
  const { AccessoryLayer, previewId, layer } = props;

  // Add other things, like extras, markings and all that fun stuff

  if (AccessoryLayer.shape) {
    return (
      <ItemComponent
        id={
          previewId
            ? `neckAccessory-layer-back-${layer}~${previewId}`
            : `neckAccessory-layer-back-${layer}`
        }
        component={AccessoryLayer}
        colour={AccessoryLayer.colour}
      />
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(NeckAccessoryBack);

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import ControlContainer from "./ControlContainer";
import { checkDependencies } from "../../menu-helper";
import ControlsTransform from "./ControlsTransform";
import {
  getCharacterConfigValue,
  getStyleConfigValue,
} from "character-creator/utilities/storeHelpers";

const mapStateToProps = (state, ownProps) => {
  // Get the main control object, which houses all subcontrols, conditions etc
  const mainControlObject = ownProps.group;
  const controlDependencies = mainControlObject.dependencies;

  const dependenciesMet = controlDependencies
    ? checkDependencies(controlDependencies, state)
    : true;

  const isColouringStyle =
    getStyleConfigValue(state, "colour") === "coloursBlank";

  // Colour controls! Only visible when the main object has been selected
  const colourControlsList = mainControlObject.colourControls
    ? mainControlObject.colourControls
    : null;

  // Determines whether the attribute value is actually part of a list (i.e. "parent" data attribute is "bodyExtras" and then control data attribute is "shape" - e.g. bodyExtras: [{shape: "x", colour: "..."}, {shape: y, colour: "..."}]). Gets the overall list if parent, otherwise just normal
  let mainDataAttributeValue = ownProps.parentDataAttribute
    ? getCharacterConfigValue(state, ownProps.parentDataAttribute)
    : getCharacterConfigValue(state, mainControlObject.dataAttribute[0]);

  // If now there is a value / not NULL parent
  if (mainDataAttributeValue && ownProps.parentDataAttribute) {
    // If the parent list has values
    if (
      mainDataAttributeValue.length > 0 &&
      mainDataAttributeValue[ownProps.itemIndex]
    ) {
      let newValue =
        mainDataAttributeValue[ownProps.itemIndex][
          mainControlObject.dataAttribute
        ];
      mainDataAttributeValue = newValue;
    }
  } else if (ownProps.parentDataAttribute) {
    // If parentDataAttribute but doesn't exist in config yet
    mainDataAttributeValue = [{}];
    mainDataAttributeValue[ownProps.itemIndex][
      mainControlObject.dataAttribute
    ] = "0";
    mainDataAttributeValue.itemIndex = 0;
  }

  // Transform controls!
  const transformControlsList = mainControlObject.transformControls ? (
    <ControlsTransform
      controls={mainControlObject.transformControls}
      sectionLevel={false}
      parentDataAttribute={ownProps.parentDataAttribute}
      itemIndex={ownProps.itemIndex}
    />
  ) : null;

  // If after all that there's a mainDataAttributeValue, use that, otherwise default to "0" (blank)
  const mainSelectedOption =
    mainDataAttributeValue && mainControlObject.attributeType
      ? mainDataAttributeValue.id
      : mainDataAttributeValue ?? "0";
  const extraControlsVisible =
    mainSelectedOption &&
    mainSelectedOption !== "" &&
    mainSelectedOption !== "0";

  if (ownProps.parentDataAttribute) {
    mainControlObject["parentDataAttribute"] = ownProps.parentDataAttribute;
    mainControlObject["parentDataArray"] = mainDataAttributeValue;
  }

  return {
    mainControlObject,
    colourControlsList,
    extraControlsVisible,
    dependenciesMet,
    isColouringStyle,
    transformControlsList,
  };
};

/**
 * Colour controls - for each Component in an Item, add a colour control. Also have a toggle
 * @param prop
 */
// export const ColourControls = (props) => {
//   return <></>;
// };

const ControlGroup = (props) => {
  const {
    mainControlObject,
    colourControlsList,
    extraControlsVisible,
    sectionLevel = false,
    dependenciesMet,
    isColouringStyle = false,
    transformControlsList,
  } = props;

  // Main control - usually the shape
  const MainControl = (
    <ControlContainer sectionLevel={sectionLevel} control={mainControlObject} />
  );

  const ColourControls =
    extraControlsVisible &&
    colourControlsList &&
    colourControlsList.map((colourControl, index) => {
      return (
        <Fragment key={index}>
          <ControlContainer
            sectionLevel={false}
            control={colourControl}
            colourControl={true}
            // parentDataAttribute={mainControlObject.parentDataAttribute}
            // itemIndex={mainControlObject.itemIndex}
          />
        </Fragment>
      );
    });

  const controlGroupElement = (
    <div className="control-group">
      {MainControl}
      {extraControlsVisible && transformControlsList && transformControlsList}
      {ColourControls && !isColouringStyle && ColourControls}
    </div>
  );

  return <>{dependenciesMet && controlGroupElement}</>;
};

export default connect(mapStateToProps)(ControlGroup);

import React from "react";
import { connect, useDispatch } from "react-redux";
import { get } from "lodash";
import ControlGroup from "./controls/ControlGroup";
import { updateConfig } from "character-creator/utilities/characterSlice";
import { checkDependencies, SymmetryControl } from "../menu-helper";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";

const mapDispatchToProps = {
  updateConfig,
};

const mapStateToProps = (state, ownProps) => {
  const subSection = ownProps.subSection;

  // Is there a symmetry config item? If so, user can toggle
  const symmetryOption = subSection.symmetryAttribute && true;

  // If config option, get value, otherwise true
  const symmetry = symmetryOption
    ? getCharacterConfigValue(state, subSection.symmetryAttribute)
    : true;
  // Switch control list based on chosen symmetry option
  const controlsList = symmetry
    ? subSection.symmetrical
    : subSection.asymmetrical;

  // Is there a dependency?
  const dependenciesMet = subSection.dependencies
    ? checkDependencies(subSection.dependencies, state.character)
    : true;

  const dependencyMessage = !dependenciesMet && subSection.dependencyMessage;

  // const dataAttributeValue = get(getConfig(state), ownProps.control.dataAttribute[0]);
  // const selectedOption = ownProps.control.attributeType? dataAttributeValue.id: dataAttributeValue

  return {
    symmetryOption,
    symmetry,
    controlsList,
    dependenciesMet,
    dependencyMessage,
  };
};

const SubSection = (props) => {
  const {
    subSection,
    symmetryOption,
    symmetry,
    controlsList,
    dependenciesMet,
    dependencyMessage,
    showHeader,
  } = props;

  const dispatch = useDispatch();

  const updateSymmetry = (symmetryAttribute, updateValue = true) => {
    dispatch(
      updateConfig({ attribute: symmetryAttribute, value: updateValue })
    );
  };

  const controlGroups = controlsList.map((control, index) => {
    return (
      <div key={index}>
        <ControlGroup group={control} sectionLevel={!showHeader} />
      </div>
    );
  });

  const subSectionDescription = subSection.description && (
    <p className="menu-description">{subSection.description}</p>
  );

  const subSectionActions = (
    <div className="actions">
      {symmetryOption && (
        <SymmetryControl
          controlId={subSection.id}
          symmetryValue={symmetry}
          symmetryAttribute={subSection.symmetryAttribute}
          updateSymmetry={updateSymmetry}
        />
      )}
    </div>
  );

  const subSectionContent = (
    <>
      {subSectionDescription}
      {controlGroups}
    </>
  );

  const dependencyNotification = (
    <span className="dependency-message">{dependencyMessage}</span>
  );

  return (
    <div className="subsection">
      {!!showHeader && (
        <div className="header">
          <h2 data-name="subsection-name">{subSection.name}</h2>
          {!dependenciesMet && dependencyNotification}
          {dependenciesMet && subSectionActions}
          <div className="accordion-button">+</div>
        </div>
      )}
      {dependenciesMet && subSectionContent}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubSection);

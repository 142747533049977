import React from "react";
import { PreviewBase, previewViewbox } from "../Preview";

const whiskers_shape_xnxot = {
  id: "whiskers_shape_xnxot",
  name: "Whiskers 1",
  description: "Straight, regular whiskers",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  // stroke: true,
  // linework: true,
  shape: {
    base: (
      // <g strokeWidth="1px" strokeMiterlimit="1.5">
      <path d="m228.27 269.192-85.413-7.684m82.052 14.602-75.135-.018m78.427 8.72-74.218 11.808m70.063-14.57-74.964 5.114m72.055-16.451-75.042-3.779m85.491 7.358-95.437-6.051m92.69 9.865-92.452 7.967m82.757-1.41-89.525 7.715m96.619-4.064-76.023 16.815m121.515-35.937 85.413-7.684m-82.052 14.602 75.135-.018m-78.427 8.72 74.218 11.808m-70.063-14.57 74.964 5.114m-72.055-16.451 75.042-3.779m-85.491 7.358 95.437-6.051m-92.69 9.865 92.452 7.967m-82.757-1.41 89.525 7.715m-96.619-4.064 76.023 16.815" />
      // </g>
    ),
  },
};

const whiskers_shape_2ZLO8 = {
  id: "whiskers_shape_2ZLO8",
  name: "Whiskers 2",
  description: "Slightly curved, regular whiskers",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  // stroke: true,
  // linework: true,
  shape: {
    base: (
      <>
        <path d="M228.93 269.192c-33.019-6.866-62.747-10.617-85.413-7.684m82.052 14.602c-28.271-4.206-54.51-5.766-75.135-.018m78.427 8.72c-27.464.908-53.255 3.675-74.218 11.808m70.063-14.57c-26.08-2.411-51.161-1.058-74.964 5.114m72.056-16.451c-25.574-4.677-50.585-5.918-75.043-3.779" />
        <path d="M230.386 275.628c-31.377-8.584-62.851-12.332-92.247-3.001m91.36 5.479c-32.138-1.708-63.063.594-92.452 7.967m83.292-2.383c-31.792-1.382-49.044-.203-81.63 11.239m88.19-6.615s-25.99 1.545-38.635 4.342c-12.671 2.802-37.389 12.473-37.389 12.473m120.195-35.937c33.019-6.866 62.747-10.617 85.413-7.684m-82.052 14.602c28.271-4.206 54.51-5.766 75.135-.018m-78.427 8.72c27.464.908 53.255 3.675 74.218 11.808m-70.063-14.57c26.08-2.411 51.161-1.058 74.964 5.114m-72.056-16.451c25.574-4.677 50.585-5.918 75.043-3.779" />
        <path d="M269.614 275.628c31.377-8.584 62.851-12.332 92.247-3.001m-91.36 5.479c32.138-1.708 63.063.594 92.452 7.967m-83.292-2.383c31.792-1.382 49.044-.203 81.63 11.239m-88.19-6.615s25.99 1.545 38.635 4.342c12.671 2.802 37.389 12.473 37.389 12.473" />
      </>
    ),
  },
};

export const WhiskersPack1 = {
  whiskers_shape_xnxot,
  whiskers_shape_2ZLO8,
};

import React from "react";
import { connect, useDispatch } from "react-redux";
import {
  updateCharacterConfig,
  updateConfig,
} from "character-creator/utilities/storeHelpers";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";

const mapStateToProps = (state, ownProps) => {
  const controlsConfig = ownProps.controls.map((control, index) => {
    const controlValue = getCharacterConfigValue(
      state,
      control.dataAttribute[0]
    );
    return {
      key: index,
      value: controlValue ?? "1",
      ...control,
    };
  });

  return {
    controlsConfig,
  };
};

const ControlsTransform = (props) => {
  const { controlsConfig } = props;
  const dispatch = useDispatch();

  // const updateCharacterConfig = (attributeList, updateValue) => {
  //   attributeList.forEach((thisAttribute) => {
  //     dispatch(updateConfig({ attribute: thisAttribute, value: updateValue }));
  //   });
  // };

  const updateSymmetricalConfig = (
    dataAttributeArray,
    transformOpposite,
    value
  ) => {
    if (transformOpposite) {
      // Left
      updateConfig(
        dispatch,
        updateCharacterConfig,
        [dataAttributeArray[0]],
        value
      );
      // Right
      if (dataAttributeArray[1]) {
        updateConfig(
          dispatch,
          updateCharacterConfig,
          [dataAttributeArray[1]],
          -value + ""
        );
      }
    } else {
      updateConfig(dispatch, updateCharacterConfig, dataAttributeArray, value);
    }
  };

  const controlsList = controlsConfig.map((control, index) => {
    return (
      <div key={index} className="control-container">
        <label key={index} htmlFor={control.id}>
          {control.name}
          {control.description && (
            <p className="menu-description">{control.description}</p>
          )}
        </label>
        <input
          type="range"
          min={control.range.min}
          max={control.range.max}
          step={control.range.step ? control.range.step : 1}
          value={control.value}
          className="slider"
          id={control.id}
          onChange={($this) =>
            updateSymmetricalConfig(
              control.dataAttribute,
              !!control.transformOpposite,
              $this.target.value
            )
          }
        ></input>
      </div>
    );
  });

  /**
   * Build the lot!
   * */

  return (
    <div className="control-container">
      <div className="header">
        {" "}
        <h3>Transform</h3>
      </div>
      <fieldset>
        <legend className="sr-only">Transform</legend>
        {controlsList}
      </fieldset>
    </div>
  );
};

export default connect(mapStateToProps)(ControlsTransform);

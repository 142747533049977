// Components
// import skintones from 'character-creator/colours/skin'
import HairColours from "character-creator/colours/hair";
import hairMidLeftList from "character-creator/components/hair/mid/left/HairMidLeftList";
import hairMidRightList from "character-creator/components/hair/mid/right/HairMidRightList";
import hairMidTopList from "character-creator/components/hair/mid/top/HairMidTopList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

const hairMidPath = "hair.mid.";

const HairMidPageConfig = {
  id: "hairMidPage",
  name: "Mid",
  description: "Behind the ears but in front of the body.",
  controls: [
    {
      id: "hairMidTop",
      name: "Top style",
      description: "",
      dataAttribute: [hairMidPath + "top.shape"],
      variationAttribute: "",
      previewType: "preview",
      previewSide: "L",
      previewViewbox: "0 0 500 500",
      options: hairMidTopList,
      colourControls: [
        {
          id: "hairMidTopColour",
          name: "Top colour",
          description: "",
          dataAttribute: [hairMidPath + "top.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
    },
    {
      id: "hairMidLeft",
      name: "Left style",
      description: "",
      dataAttribute: [hairMidPath + "left.shape"],
      variationAttribute: "",
      previewType: "preview",
      previewSide: "L",
      previewViewbox: "0 0 500 500",
      options: hairMidLeftList,
      colourControls: [
        {
          id: "hairMidLeftColour",
          name: "Left colour",
          description: "",
          dataAttribute: [hairMidPath + "left.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
    },
    {
      id: "hairMidRight",
      name: "Right style",
      description: "",
      dataAttribute: [hairMidPath + "right.shape"],
      variationAttribute: "",
      previewType: "preview",
      previewSide: "R",
      previewViewbox: "0 0 500 500",
      options: hairMidRightList,
      colourControls: [
        {
          id: "hairMidRightColour",
          name: "Right colour",
          description: "",
          dataAttribute: [hairMidPath + "right.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
    },
  ],
};

const HairMidPage = {
  id: "hairMidPage",
  name: "Mid",
  description: "",
  content: <SubPage page={HairMidPageConfig} />,
};

export default HairMidPage;

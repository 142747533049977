import { collection, doc, getDocs, getDoc } from "firebase/firestore";
import { db, getDocument} from "../../firebase";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InvalidPage from "pages/Invalid";
import React from "react";


export const Feature = (props) => {
  const {featureId} = props;
  const [feature, setFeature] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  // const [authorDocRef, setAuthorDocRef] = useState();
  let featureData = {}

  const featureDocRef = doc(db, "features", featureId);
  const featureDocVotes = collection(db, "features", featureId, "votes");
  const featureDocComments = collection(db, "features", featureId, "comments");

  function fetchFeature(){ 
    getDocument(featureDocRef).then((retrievedFeature) => {
    if (retrievedFeature) {
      featureData = retrievedFeature;
      return retrievedFeature
    } else {
      return null
    }
}).then((retrievedData) => {
  if (retrievedData && retrievedData.author){
    const featureAuthor = getDocument(retrievedData.author).then((authorDocument) => {
      featureData.author = authorDocument.name
      setFeature(featureData)
      setIsLoading(false)
    })
    
  } else {
    setIsLoading(false)
  }
  
}, function(error) {
  // Something went wrong.
  console.error(error);})
}

useEffect(()=>{
  setIsLoading(true)
    fetchFeature();
    
    return () => {
      setIsLoading(false)
    }
  }, [featureId]);

if (isLoading) {
  return (<section className="panel"><h2>Loading...</h2></section>)
} else if (feature){
    return (
      <><section className="panel cta-panel">
        <div class="cta-text">
        <h2>{feature.name}</h2>
        <div className="vote-main">
          <p>Submitted {feature.dateSuggested && `on ${feature.dateSuggested.toDate().toDateString()}`} by {feature.author}</p>
          <p>{feature.description}</p>
          </div>
        </div>
        <div className="cta-buttons cta-buttons-col">
          <button className="button-primary">Vote</button>
        </div>
      </section>
      <section className="panel feature-panel">
        <h3>Comments</h3>
        <ul className="vote-list">
          <li className="cta-panel">
            <div className="cta-text">
              <div>
                Comment author | Comment date
              </div>
              <div>
                Comment will go here
              </div>
            </div>
            <div class="cta-buttons cta-buttons-col">
              <button className="button-secondary">Report</button>
            </div>
          </li>
        </ul>
      </section>
      </>);
  } else {
    return <InvalidPage type="feature"/>;
  }
}

export const FeaturesList = (props) => {
  const [features, setFeatures] = useState([]);

    const fetchFeatures = async () => {
        await getDocs(collection(db, "features"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({id:doc.id, name:doc.data().name, description: doc.data().description, status: doc.data().status }));
                setFeatures(newData);                
            })
    }
   
    useEffect(()=>{
        fetchFeatures();
    }, [])


  return (
    <ul className="vote-list">
      {features?.map((feature, index) => (
        <li id={`feature-${feature.id}`} key={index}>
          <div className="vote-main">
            <div className="vote-tags">
              <span className="vote-status">
                {feature.status}
              </span>
              <span>Test tag 1</span>
              <span>Test tag 2</span>
            </div>
            <div className="vote-name">
              <Link to={`/feature-voting/${feature.id}`}>
                {feature.name}</Link>
              </div>
            <div className="vote-description">
              {feature.description}
            </div>
          </div>
          <div className="vote-actions"><button className="button-primary">Vote</button>
          <Link to={`/feature-voting/${feature.id}`} className="button-secondary">View</Link></div>
        </li>
      ) )}
    </ul>
  );
};

import React from "react";
import { connect } from "react-redux";
import { BodyShapeListArray } from "./shape/BodyShapeList";
import BodyMarkings from "./markings/BodyMarkings";
import { getGlobalStyle } from "../StyleOptions";
import { outlineOnly } from "character-creator/colours/colourSchemes";
import CharacterHeadShadow from "../head/CharacterHeadShadow";
import BodyExtras from "./extras/BodyExtras";
import BodyScars from "./markings/Scars/BodyScar";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";
import ItemComponent from "../ItemComponent";

function mapStateToProps(state, ownProps) {
  const useBodyOutline = getGlobalStyle(state).outline;

  const bodyConfig = getCharacterConfigValue(state, "body");
  const bodyObject =
    ownProps.preview && ownProps.body
      ? ownProps.body
      : filterListById(BodyShapeListArray, bodyConfig.shape);
  const colour = bodyConfig.colour
    ? bodyConfig.colour
    : getCharacterConfigValue(state, "skintone");

  return {
    Body: bodyObject,
    colour: colour,
    useBodyOutline,
  };
}

const CharacterBody = (props) => {
  const { Body, colour, preview, previewId, useBodyOutline } = props;

  return (
    <g id={previewId ? `g-body~${previewId}` : "g-body"}>
      <ItemComponent
        id={previewId ? `body~${previewId}` : "body"}
        component={Body}
        colour={colour}
        preview={previewId ? true : false}
      />

      {!preview && (
        <>
          <BodyExtras index={"0"} />
          <BodyExtras index={"1"} />
          <BodyExtras index={"2"} />
        </>
      )}
      {!preview && (
        <>
          <BodyMarkings index={"0"} />
          <BodyMarkings index={"1"} />
          <BodyMarkings index={"2"} />
        </>
      )}
      {!preview && (
        <>
          <BodyScars index={"0"} />
          <BodyScars index={"1"} />
          <BodyScars index={"2"} />
        </>
      )}

      {useBodyOutline && (
        <ItemComponent
          id={previewId ? `bodyOutline~${previewId}` : "bodyOutline"}
          component={Body}
          // linework={true}
          colour={{ ...outlineOnly, outline: colour.outline }}
          preview={previewId ? true : false}
        />
      )}

      {!preview && (
        <CharacterHeadShadow id="body" maskId={"body_mask"} colour={colour} />
      )}
    </g>
  );
};

export default connect(mapStateToProps)(CharacterBody);

import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const base_body_shape_fds8g = {
  id: "base_body_shape_fds8g",
  name: "Body 4A",
  description: "A mid-weight body with fur",
  tags: ["medium", "humanoid", "furry"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M60.53 465.84c-.96 6.58-1.51 13.21-1.65 19.85-.07 3.32-.03 6.65.11 9.97.07 1.6.54 2.98 1.08 4.34h379.86c.54-1.35 1.01-2.73 1.07-4.34.14-3.32.18-6.65.11-9.97-.13-6.64-.68-13.27-1.65-19.85 1.41.87 2.82 1.75 4.23 2.62-1.11-6-2.55-11.94-4.33-17.78.92.57 1.85 1.13 2.78 1.68-.49-1.98-1.1-3.92-1.81-5.8-.01-.03-.02-.05-.03-.08-4.13-9.75-11.43-18.17-20.59-23.52.86-.3 1.76-.5 2.67-.59-1.9-1.51-3.9-2.9-5.99-4.16-4.15-1.92-8.3-3.85-12.45-5.78-5.17-2.4-10.35-4.81-15.52-7.21-.95-.44-1.93-1.88-.95-2.26-8.32-1.68-16.65-3.36-24.98-5.05-.81-.16-1.74-.43-2.04-1.2-.23-.6 1.5-.94 1.66-1-.03 0-.14.01-.34.03-3.63-1.02-26.59-4.49-39.47-8.45 1.52-.64 2.84-1.72 3.77-3.08-7.89 2.59-16.88-1.28-21.66-7.93-1.85-2.58-3.07-5.58-3.34-8.78.28 1.33 1.28 3.76 1.7 3.3-.05-.25-.08-.5-.12-.75v-4.23c-.01-.11-.04-.42-.13-1.24-.89-8.15-3.74-16.21-1.4-24.07.15 1.32.7 2.59 1.53 3.62v-9.74c-.01-.06-.02-.13-.03-.18-.66-3.99-1.31-7.98-1.97-11.96-.53-3.25-1.44-6.39-2.15-9.54h-97c-.71 3.15-1.63 6.29-2.16 9.54-.65 3.98-1.31 7.97-1.96 11.96-.01.05-.02.12-.03.18v9.74c.83-1.03 1.38-2.3 1.53-3.62 2.33 7.86-.52 15.92-1.41 24.07-.06.57-.1.9-.12 1.08v4.39c-.04.25-.07.5-.12.75.42.46 1.41-1.97 1.69-3.3-.27 3.18-1.47 6.17-3.32 8.74-4.76 6.67-13.77 10.57-21.68 7.97.93 1.36 2.26 2.44 3.77 3.08-12.87 3.96-35.84 7.43-39.47 8.45-.2-.02-.3-.03-.33-.03.16.06 1.89.4 1.66 1-.3.77-1.24 1.04-2.05 1.2-8.32 1.69-16.65 3.37-24.98 5.05.98.38.01 1.82-.94 2.26-5.17 2.4-10.33 4.8-15.49 7.19-4.17 1.94-8.34 3.88-12.51 5.81-2.08 1.26-4.08 2.65-5.97 4.15.91.09 1.81.29 2.67.59-9.17 5.37-16.49 13.81-20.62 23.6-.02.03-.03.07-.04.11-.7 1.85-1.29 3.76-1.77 5.7.93-.56 1.86-1.12 2.79-1.69-1.78 5.84-3.23 11.78-4.33 17.78 1.41-.87 2.81-1.75 4.22-2.62z" />
    ),
    basic: {
      dark: (
        <path d="M110 477.81s-7.52 55.14-3.4 86.48.39-61.8 7.88-100.7M390 477.81s7.52 55.14 3.4 86.48-.39-61.8-7.88-100.7" />
      ),
    },
  },
};

const base_body_shape_sg894 = {
  id: "base_body_shape_sg894",
  name: "Body 4B",
  description: "A heavier-weight body with fur",
  tags: ["heavier", "humanoid", "furry"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M58.89 475.94c-2.36 7.76-3.03 16.01-2.01 24.06h386.26c.98-7.98.31-16.17-2.03-23.86 1.1 2.04 3.6 3.22 5.87 2.75-3.93-7.03-6.91-14.59-8.83-22.4.54 1.13 1.31 2.13 2.26 2.94-3.49-19.36-14.46-37.24-30.07-49.2.43.09.88.07 1.29-.08-13.91-10.34-29.39-18.57-45.74-24.34 1.74.42 3.77-.43 4.58-2.05-13.31-1.21-26.46-4.21-38.97-8.9 1.06-.37 1.83-1.46 1.82-2.58-4.19 1.49-9.07.83-12.72-1.7 1.79.87 4.17.28 5.34-1.33-13.29-2.86-23.61-15.95-23.29-29.55.87 2.88 3.42 5.18 6.37 5.76-7.73-11.63-10.52-26.44-7.54-40.09-3.51-.14-6.99-1.46-10.63-1.59-3.64-.13-7.53 1.16-9.62 4.14-.15.21-.28.43-.4.66h-61.54c-.16-.29-.33-.57-.52-.85-2.09-2.98-5.98-4.27-9.62-4.14-3.64.13-7.11 1.45-10.63 1.59 2.98 13.65.19 28.46-7.54 40.09 2.95-.57 5.5-2.88 6.37-5.76.32 13.59-10 26.69-23.29 29.55 1.17 1.61 3.55 2.2 5.34 1.33-3.65 2.53-8.54 3.19-12.72 1.7-.01 1.12.76 2.21 1.82 2.58-12.52 4.69-25.66 7.69-38.97 8.9.81 1.62 2.84 2.47 4.58 2.05-16.35 5.77-31.82 14-45.74 24.34.41.15.86.17 1.29.08-15.61 11.96-26.58 29.84-30.07 49.2.95-.8 1.73-1.81 2.26-2.94-1.92 7.82-4.9 15.38-8.83 22.4 2.27.46 4.78-.72 5.87-2.76z" />
    ),
    basic: {
      dark: (
        <path d="M110 477.81s-7.52 55.14-3.4 86.48.39-61.8 7.88-100.7M390 477.81s7.52 55.14 3.4 86.48-.39-61.8-7.88-100.7" />
      ),
    },
  },
};

const base_body_shape_fa84m = {
  id: "base_body_shape_fa84m",
  name: "Body 4C",
  description: "A lighter-weight body with fur",
  tags: ["lighter", "humanoid", "furry"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M58.04 487.31c-.94 4.17-1.87 8.52-1.24 12.69h386.4c.62-4.17-.31-8.52-1.24-12.69 1.09 2.49 4.07 3.99 6.72 3.38-2.08-.89-3.31-3.11-3.7-5.34s-.09-4.5.06-6.75c.67-10.17-1.96-20.54-7.38-29.17 1.58.78 3.58.61 5-.43-3.47-2.02-6.22-5.23-7.71-8.96 1.78 1.73 4.76 2.05 6.87.75-3.38.11-6.48-2.01-8.68-4.59-2.2-2.58-3.74-5.65-5.75-8.37-5.06-6.85-12.9-11.23-21.07-13.63-7.05-2.07-14.39-2.81-21.73-3.35 2.21-.87 4.36-1.91 6.4-3.2-3.74 1.83-8.23 1.54-12.16.15-3.92-1.39-7.4-3.79-10.82-6.15 1.75 1.19 4.56-.62 4.2-2.7-3.47 1.61-7.51.96-11.27.27l-26.61-4.86c2.07.48 4.39-.46 5.54-2.24-10.32.42-20.72-1.37-30.3-5.21 1.84-.1 3.53-1.54 3.93-3.33-4.92 1.31-10.39.3-14.54-2.65 1.56.82 3.89.08 4.45-1.65-4.8-2.12-7.85-7.1-8.98-12.22-1.13-5.12-.64-10.45-.13-15.68.99 2.39 2.76 4.46 4.97 5.81-5.5-9.07-7.61-20.14-5.84-30.59.43-2.56.84-5.77-1.33-7.2-.32-.21-.67-.35-1.04-.45-.02-.12-.03-.25-.04-.37-.83-.05-1.67-.11-2.5-.16v-.23h-79.1v.38c-.11.01-.23.02-.34.02-.02.12-.03.23-.04.35-.4.1-.78.25-1.13.48-2.17 1.43-1.77 4.64-1.33 7.2 1.78 10.46-.33 21.53-5.84 30.59 2.21-1.35 3.98-3.41 4.97-5.81.5 5.22 1 10.55-.13 15.68-1.13 5.12-4.18 10.11-8.98 12.22.56 1.73 2.89 2.47 4.45 1.65-4.14 2.95-9.62 3.96-14.54 2.65.4 1.8 2.09 3.23 3.93 3.33-9.58 3.85-19.99 5.64-30.3 5.21 1.15 1.78 3.47 2.72 5.54 2.24l-26.61 4.86c-3.76.69-7.81 1.34-11.27-.27-.36 2.09 2.45 3.89 4.2 2.7-3.42 2.37-6.9 4.76-10.82 6.15-3.92 1.39-8.42 1.68-12.16-.15 2.05 1.29 4.2 2.33 6.4 3.2-7.34.54-14.68 1.28-21.73 3.35-8.17 2.4-16.01 6.78-21.07 13.63-2.01 2.72-3.55 5.79-5.75 8.37-2.2 2.58-5.29 4.7-8.68 4.59 2.11 1.31 5.09.98 6.87-.75-1.48 3.73-4.24 6.94-7.71 8.96 1.42 1.03 3.42 1.2 5 .43-5.43 8.63-8.05 18.99-7.38 29.17.15 2.25.45 4.53.06 6.75s-1.63 4.45-3.7 5.34c2.64.59 5.62-.9 6.71-3.4z" />
    ),
    basic: {
      dark: (
        <path d="M110 477.81s-7.52 55.14-3.4 86.48.39-61.8 7.88-100.7M390 477.81s7.52 55.14 3.4 86.48-.39-61.8-7.88-100.7" />
      ),
    },
  },
};

export const BodyShapePack4 = {
  base_body_shape_fds8g,
  base_body_shape_sg894,
  base_body_shape_fa84m,
};

import React from "react";
import { connect } from "react-redux";
import { ClothingList } from "./ClothingList";
import AllBlues from "character-creator/colours/collections/Blues";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";
import ItemComponent from "character-creator/components/ItemComponent";

function mapStateToProps(state, ownProps) {
  const bodyShape = getCharacterConfigValue(state, "body.shape");
  const layerConfig = ownProps.layerConfig;
  let layerObject = null;

  if (ownProps.preview && ownProps.clothing) {
    layerObject = ownProps.clothing.shape[bodyShape].back
      ? ownProps.clothing.shape[bodyShape].back
      : ownProps.clothing.shape[bodyShape];
  } else if (layerConfig?.shape && layerConfig.shape !== "0") {
    layerObject = filterListById(ClothingList, layerConfig.shape).shape[
      bodyShape
    ].back
      ? filterListById(ClothingList, layerConfig.shape).shape[bodyShape].back
      : filterListById(ClothingList, layerConfig.shape).shape[bodyShape];
  }

  const colour = layerConfig?.colour
    ? layerConfig.colour
    : AllBlues[Object.keys(AllBlues)[3]];

  const ClothingLayer = {
    shape: layerObject,
    colour: colour,
  };

  return {
    ClothingLayer,
  };
}

const ClothingBack = (props) => {
  const { ClothingLayer, previewId, layer } = props;

  if (ClothingLayer.shape) {
    return (
      <ItemComponent
        id={
          previewId
            ? `clothing-layer-back-${layer}~${previewId}`
            : `clothing-layer-back-${layer}`
        }
        component={ClothingLayer}
        colour={ClothingLayer.colour}
      />
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(ClothingBack);

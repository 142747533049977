import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import { ClothingList } from "./ClothingList";
import AllBlues from "character-creator/colours/collections/Blues";
import Pattern from "character-creator/components/patterns/Pattern";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const bodyShape = getCharacterConfigValue(state, "body.shape");
  const layerConfig = ownProps.layerConfig;
  let layerObject = null;

  if (ownProps.preview && ownProps.clothing) {
    layerObject = ownProps.clothing.shape[bodyShape].front
      ? ownProps.clothing.shape[bodyShape].front
      : ownProps.clothing.shape[bodyShape];
  } else if (layerConfig?.shape && layerConfig.shape !== "0") {
    layerObject = filterListById(ClothingList, layerConfig.shape).shape[
      bodyShape
    ].front
      ? filterListById(ClothingList, layerConfig.shape).shape[bodyShape].front
      : filterListById(ClothingList, layerConfig.shape).shape[bodyShape];
  }

  const colour = layerConfig?.colour
    ? layerConfig.colour
    : AllBlues[Object.keys(AllBlues)[3]];

  let clothingPattern = null;
  if (!ownProps.preview && layerConfig && layerConfig.pattern?.shape) {
    clothingPattern = layerConfig.pattern;
  }

  const ClothingLayer = {
    shape: layerObject,
    colour: colour,
    pattern: clothingPattern,
  };

  return {
    ClothingLayer,
  };
}

const ClothingFront = (props) => {
  const { ClothingLayer, previewId, layer } = props;

  if (ClothingLayer.shape) {
    return (
      <>
        <ItemComponent
          id={
            previewId
              ? `clothing-layer-front-${layer}~${previewId}`
              : `clothing-layer-front-${layer}`
          }
          component={ClothingLayer}
          colour={ClothingLayer.colour}
        />
        {ClothingLayer.pattern && (
          <Pattern
            patternId={
              previewId
                ? `clothing-layer-front-${layer}-pattern~${previewId}`
                : `clothing-layer-front-${layer}-pattern`
            }
            pattern={ClothingLayer.pattern}
            colour={ClothingLayer.pattern.colour}
            maskId={
              previewId
                ? `clothing-layer-front-${layer}~${previewId}_mask`
                : `clothing-layer-front-${layer}_mask`
            }
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(ClothingFront);

import React from "react";
import Portrait from "character-creator/components/Portrait";
import {
  KofiButton,
  PatreonButton,
} from "character-creator/utilities/HomeElements";
import saveSvgAsPng from "save-svg-as-png";
import TabbedPanelContainer from "character-creator/menu/elements/TabbedPanelContainer";
import { mainMenu } from "character-creator/menu/config/menuConfig";

// function mapStateToProps(state){
//   return {
//     config: state.config
//   }
// }

const downloadPng = (props) => {
  const portrait = document.getElementById("portrait-svg");
  saveSvgAsPng.saveSvgAsPng(portrait, "characterArtPortrait.png", {
    backgroundColor: "#FFFFFF",
  });
};

// TODO: Option to hide top banner (save in localStorage)

const AvatarCreator = (props) => {
  return (
    <>
      <section className="panel cta-panel">
        <div>
          <h2>Like what you see?</h2>
          <p>
            Join us on Patreon or Ko-fi to vote on the next content pack and
            more!
          </p>
        </div>
        <div className="cta-buttons cta-buttons-row">
          <PatreonButton />
          <KofiButton />
        </div>
      </section>
      <section className="creator-container">
        <div className="creator-view">
          <div id="portrait-container" className="panel">
            <Portrait />
            <ul className="actions">
              <li>
                <button onClick={downloadPng}>Save as PNG</button>
              </li>
            </ul>
          </div>
        </div>
        <div className="creator-menu">
          <TabbedPanelContainer
            tabs={mainMenu}
            containerClasses={"menu-block panel"}
            navClasses={"nav-primary nav-inline"}
          />
        </div>
      </section>
    </>
  );
};

// export default connect(mapStateToProps)(AvatarCreator);
export default AvatarCreator;

import React from "react";
import CharacterHead from "character-creator/components/head/CharacterHead";
import Muzzle from "./CharacterMuzzle";
import Mouth from "character-creator/components/head/face/mouth/Mouth";
import Nose from "character-creator/components/head/face/nose/Nose";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <Muzzle preview={true} previewId={previewId} muzzle={component} />
      <Mouth preview={true} previewId={previewId} />
      <Nose preview={true} previewId={previewId} />
    </>
  );
};

export const previewViewbox = "200 245 100 100";

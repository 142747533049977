import React from "react";
import { PreviewBaseLips, viewboxLips } from "../Preview";

const contouring_g4hVZMyBJx = {
  id: "contouring_g4hVZMyBJx",
  name: "Contouring g4hVZMyBJx",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLips,
    base: PreviewBaseLips,
  },
  stroke: false,
  shape: {
    base: (
      <path d="M250 271.515s-3.214 5.117-3.214 19.721a3.216 3.216 0 0 0 3.214 3.215 3.216 3.216 0 0 0 3.214-3.215c0-14.604-3.214-19.721-3.214-19.721Z" />
    ),
  },
  gradient: "matrix(0 -8.69167 8.69167 0 250 294.451)",
};

const contouring_nWHv4XWw96 = {
  id: "contouring_nWHv4XWw96",
  name: "Contouring nWHv4XWw96",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLips,
    base: PreviewBaseLips,
  },
  stroke: false,
  shape: {
    base: (
      <path d="M250 271.515s-3.214 5.117-3.214 19.721a3.216 3.216 0 0 0 3.214 3.215 3.216 3.216 0 0 0 3.214-3.215c0-14.604-3.214-19.721-3.214-19.721Z" />
    ),
  },
};

const contouring_6gthxHBegF = {
  id: "contouring_6gthxHBegF",
  name: "Contouring 6gthxHBegF",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLips,
    base: PreviewBaseLips,
  },
  stroke: false,
  shape: {
    base: (
      <path d="M246.812 289.004c.828 1.25 1.952 2.018 3.188 2.018 1.236 0 2.36-.768 3.188-2.018.017.719.026 1.463.026 2.232a3.216 3.216 0 0 1-3.214 3.215 3.216 3.216 0 0 1-3.214-3.215c0-.769.009-1.513.026-2.232Z" />
    ),
  },
};

export const ContouringLipsPack1 = {
  contouring_g4hVZMyBJx,
  contouring_nWHv4XWw96,
  contouring_6gthxHBegF,
};

import {
  eyelashShapeListLower,
  eyelashShapeListUpper,
} from "character-creator/components/head/face/eyes/eyelashes/EyelashShapeList";
import HairColours from "character-creator/colours/hair";
import { allColours } from "character-creator/colours/colourSchemes";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const eyePath = "eyes.";
const eyeBasePath = eyePath + "base.";
const eyelashPath = eyePath + "eyelashes.";

const eyelashesPageConfig = {
  id: "eyelashesPage",
  name: "Eyelashes",
  description: "Oh I'm all aflutter",
  dependencies: [
    {
      description: "Eye shape",
      attribute: [eyeBasePath + "left.shape", eyeBasePath + "right.shape"],
    },
  ],
  dependencyMessage: "Please choose an eye shape first",
  sections: [
    {
      id: "eyelashesUpperGroup",
      name: "Upper",
      description: "",
      symmetryAttribute: eyelashPath + "symmetry.upper",
      controls: {
        symmetrical: [
          {
            id: "eyelashUpperShape",
            name: "Shape",
            description: "",
            dataAttribute: [
              eyelashPath + "left.upper.shape",
              eyelashPath + "right.upper.shape",
            ],
            variationAttribute: "",
            previewType: "preview",
            options: eyelashShapeListUpper,
            colourControls: [
              {
                id: "eyelashUpperColour",
                name: "Colour",
                description: "",
                dataAttribute: [
                  eyelashPath + "left.upper.colour",
                  eyelashPath + "right.upper.colour",
                ],
                variationAttribute: "",
                previewType: "swatch",
                attributeType: "multi",
                options: allColours,
              },
            ],
          },
        ],
        asymmetrical: [
          {
            id: "leftEyelashUpperShape",
            name: "Left Shape",
            description: "",
            dataAttribute: [eyelashPath + "left.upper.shape"],
            variationAttribute: "",
            previewType: "preview",
            options: eyelashShapeListUpper,
            dependencies: [
              {
                description: "Eye shape",
                attribute: [eyeBasePath + "left.shape"],
              },
            ],
            colourControls: [
              {
                id: "leftEyelashUpperColour",
                name: "Left Colour",
                description: "",
                dataAttribute: [eyelashPath + "left.upper.colour"],
                attributeType: "multi",
                variationAttribute: "",
                previewType: "swatch",
                options: HairColours,
              },
            ],
          },
          {
            id: "rightEyelashUpperShape",
            name: "Right Shape",
            description: "",
            dataAttribute: [eyelashPath + "right.upper.shape"],
            variationAttribute: "",
            previewType: "preview",
            options: eyelashShapeListUpper,
            dependencies: [
              {
                description: "Eye shape",
                attribute: [eyeBasePath + "right.shape"],
              },
            ],
            colourControls: [
              {
                id: "rightEyelashUpperColour",
                name: "Right Colour",
                description: "",
                dataAttribute: [eyelashPath + "right.upper.colour"],
                attributeType: "multi",
                variationAttribute: "",
                previewType: "swatch",
                options: HairColours,
              },
            ],
          },
        ],
      },
    },
    {
      id: "eyelashesLowerGroup",
      name: "Lower",
      description: "",
      symmetryAttribute: eyelashPath + "symmetry.lower",
      controls: {
        symmetrical: [
          {
            id: "eyelashLowerShape",
            name: "Shape",
            description: "",
            dataAttribute: [
              eyelashPath + "left.lower.shape",
              eyelashPath + "right.lower.shape",
            ],
            variationAttribute: "",
            previewType: "preview",
            options: eyelashShapeListLower,
            colourControls: [
              {
                id: "eyelashLowerColour",
                name: "Colour",
                description: "",
                dataAttribute: [
                  eyelashPath + "left.lower.colour",
                  eyelashPath + "right.lower.colour",
                ],
                variationAttribute: "",
                previewType: "swatch",
                attributeType: "multi",
                options: allColours,
              },
            ],
          },
        ],
        asymmetrical: [
          {
            id: "leftEyelashLowerShape",
            name: "Left Shape",
            description: "",
            dataAttribute: [eyelashPath + "left.lower.shape"],
            variationAttribute: "",
            previewType: "preview",
            options: eyelashShapeListLower,
            dependencies: [
              {
                description: "Eye shape",
                attribute: [eyeBasePath + "left.shape"],
              },
            ],
            colourControls: [
              {
                id: "leftEyelashLowerColour",
                name: "Left Colour",
                description: "",
                dataAttribute: [eyelashPath + "left.lower.colour"],
                attributeType: "multi",
                variationAttribute: "",
                previewType: "swatch",
                options: HairColours,
              },
            ],
          },
          {
            id: "rightEyelashLowerShape",
            name: "Right Shape",
            description: "",
            dataAttribute: [eyelashPath + "right.lower.shape"],
            variationAttribute: "",
            previewType: "preview",
            options: eyelashShapeListLower,
            dependencies: [
              {
                description: "Eye shape",
                attribute: [eyeBasePath + "right.shape"],
              },
            ],
            colourControls: [
              {
                id: "rightEyelashLowerColour",
                name: "Right Colour",
                description: "",
                dataAttribute: [eyelashPath + "right.lower.colour"],
                attributeType: "multi",
                variationAttribute: "",
                previewType: "swatch",
                options: HairColours,
              },
            ],
          },
        ],
      },
    },
  ],
};

const EyelashesPage = {
  id: "eyelashesPage",
  name: "Eyelashes",
  description: "",
  content: <SubPage page={eyelashesPageConfig} />,
};

export default EyelashesPage;

import { allColours } from "character-creator/colours/colourSchemes";
import { BackgroundShapesListArray } from "character-creator/components/background/shapes/BackgroundShapesList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

const backgroundShapesPath = "background.shapes.";
const backgroundShape1Path = backgroundShapesPath + "shape1.";
const backgroundShape2Path = backgroundShapesPath + "shape2.";
const backgroundShape3Path = backgroundShapesPath + "shape3.";

// TODO: Add transform controls - only if allowed for certain shapes? Scale shouldn't be available for all shapes

const BackgroundShapesPageConfig = {
  id: "backgroundShapesPage",
  name: "Shapes",
  controls: [
    {
      id: "backgroundShape1Shape",
      name: "Shape 1",
      description: "",
      dataAttribute: [backgroundShape1Path + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BackgroundShapesListArray,
      colourControls: [
        {
          id: "backgroundShape1Colour",
          name: "Colour",
          description: "",
          dataAttribute: [backgroundShape1Path + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
    },
    {
      id: "backgroundShape2Shape",
      name: "Shape 2",
      description:
        "Choose your second shape (this will be above your first shape)",
      dependencies: [
        {
          description: "Shape 1",
          attribute: [backgroundShape1Path + "shape"],
        },
      ],
      dependencyMessage: "Please pick a first shape first",
      dataAttribute: [backgroundShape2Path + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BackgroundShapesListArray,
      colourControls: [
        {
          id: "backgroundShape2Colour",
          name: "Colour",
          description: "",
          dataAttribute: [backgroundShape2Path + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
    },
    {
      id: "backgroundShape3Shape",
      name: "Shape 3",
      description:
        "Choose your third shape (this will be above your second shape)",
      dependencies: [
        {
          description: "Shape 2",
          attribute: [backgroundShape2Path + "shape"],
        },
      ],
      dependencyMessage: "Please pick a second shape first",
      dataAttribute: [backgroundShape3Path + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BackgroundShapesListArray,
      colourControls: [
        {
          id: "backgroundShape3Colour",
          name: "Colour",
          description: "",
          dataAttribute: [backgroundShape3Path + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
    },
  ],
};

const BackgroundShapesPage = {
  id: "backgroundShapesPage",
  name: "Shapes",
  description: "Get ahead of the game.",
  content: <SubPage page={BackgroundShapesPageConfig} />,
};

export default BackgroundShapesPage;

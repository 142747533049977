import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TabList from "./TabList";
import SubPage from "character-creator/menu/elements/SubPage";
import { TabPanel } from "./TabPanel";

const mapStateToProps = (state) => {
  return {
    menuMeta: state.menu,
  };
};


const TabbedPanelContainer = (props) => {
  const {
    tabs,
    containerClasses = "menu-block",
    navClasses = "nav-inline",
  } = props;

  const tabKeys = Object.keys(tabs);

  const [openTabId, setOpenTabId] = useState(tabKeys[0]);

  tabKeys.indexOf(openTabId) === -1 && setOpenTabId(tabKeys[0]);

  return (
    <div className={containerClasses}>
      <nav className={navClasses}>
        <TabList
          tabs={tabs}
          openTab={openTabId}
          setOpenTabFunction={setOpenTabId}
        />
      </nav>
      <TabPanel panel={tabs[openTabId]} classes={"menu-page"} />
    </div>
  );
};

export default connect(mapStateToProps)(TabbedPanelContainer);

import skintonesList from "character-creator/colours/collections/Browns";

const symmetryAttribute = {
  symmetry: true,
};

const standardItemConfig = {
  shape: "0",
  colour: "",
  transform: {
    x: 0,
    y: 0,
    scale: 1,
    opacity: 1,
  },
};

const basicItemConfig = {
  shape: "",
  colour: "",
};

export const settingsConfig = {
  style: {
    shading: "shadingBasic",
    outline: "outlinesNone",
    colour: "coloursNormal",
  },
};

export const colourConfig = {
  recentColours: [],
  customPalettes: [],
};

/**
Character components - here are all of the character components!
**/
export const characterConfig = {
  skintone: skintonesList[0],
  body: {
    // ...basicItemConfig,
    shape: "base_body_shape_lcUGV",
  },
  head: {
    // ...basicItemConfig,
    shape: "head_shape_ZH6yu",
  },
  eyes: {
    base: {
      left: { shape: "eye_shape_Uugu5" },
      right: { shape: "eye_shape_Uugu5" },
    },
    iris: {
      outer: {
        left: { shape: "iris_shape_agUl3" },
        right: { shape: "iris_shape_agUl3" },
      },
      inner: {
        left: { shape: "iris_inner_7LfyGmUUvh" },
        right: { shape: "iris_inner_7LfyGmUUvh" },
      },
    },
    pupil: {
      left: { shape: "pupil_shape_35BUu" },
      right: { shape: "pupil_shape_35BUu" },
    },
    shine: {
      left: { shape: "shine_eT7FCUrpAS" },
      right: { shape: "shine_eT7FCUrpAS" },
    },
  },
  face: {
    nose: { shape: "nose_shape_PFrMxJUbiT" },
    mouth: { shape: "mouth_shape_FmanKGMWfG" },
  },
  ears: {
    left: { shape: "ear_shape_TEKWA" },
    right: { shape: "ear_shape_TEKWA" },
  },
};

import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import { BodyScarsListArray } from "./BodyScarsList";
import {
  buildTransform,
  filterListById,
} from "character-creator/menu/menu-helper";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";

function mapStateToProps(state, ownProps) {
  const itemConfig = getCharacterConfigValue(state, "bodyScars");

  const index = ownProps.index;

  let itemObject = {};

  if (!ownProps.preview && itemConfig && itemConfig[index]) {
    let thisItemObject = filterListById(
      BodyScarsListArray,
      itemConfig[index].shape
    );

    let thisItemShape = thisItemObject.shape;

    if (thisItemShape.left) {
      let newShape = {
        base: (
          <>
            {thisItemShape.left.base}
            {thisItemShape.right.base}
          </>
        ),
        light: (
          <>
            {thisItemShape.left.light}
            {thisItemShape.right.light}
          </>
        ),
        dark: (
          <>
            {thisItemShape.left.dark}
            {thisItemShape.right.dark}
          </>
        ),
      };

      thisItemShape = newShape;
    }

    const thisExtraTransform =
      itemConfig[index].transform &&
      buildTransform(itemConfig[index].transform);

    const thisItemColours = itemConfig[index].colour
      ? itemConfig[index].colour
      : { base: getCharacterConfigValue(state, "skintone").light };

    // Assign to Extras object array
    itemObject = {
      shape: { shape: thisItemShape },
      colour: thisItemColours,
      transform: thisExtraTransform,
    };
    // });
  } else if (ownProps.preview && ownProps.scar) {
    // const thisitemConfig = ownProps.extra;
    let thisItemObject = ownProps.scar;

    let thisItemShape = thisItemObject.shape;

    if (thisItemShape.left) {
      let newShape = {
        base: (
          <>
            {thisItemShape.left.base}
            {thisItemShape.right.base}
          </>
        ),
        light: (
          <>
            {thisItemShape.left.light}
            {thisItemShape.right.light}
          </>
        ),
        dark: (
          <>
            {thisItemShape.left.dark}
            {thisItemShape.right.dark}
          </>
        ),
      };

      thisItemShape = newShape;
    }

    const thisItemColours =
      itemConfig && itemConfig["0"].colour
        ? itemConfig["0"].colour
        : { base: getCharacterConfigValue(state, "skintone").light };

    // Assign to Extras object array
    itemObject = {
      shape: { shape: thisItemShape },
      colour: thisItemColours,
    };
  }

  return {
    itemObject,
    previewId: ownProps.previewId,
    index,
  };
}

const BodyScars = (props) => {
  const { itemObject, previewId, index, alternateId, alternateMask } = props;

  let componentId = "bodyScars";
  let maskId = previewId ? `body~${previewId}_mask` : "body_mask";

  if (alternateId) {
    componentId = alternateId;
  }
  if (alternateMask) {
    maskId = alternateMask;
  }

  if (itemObject.shape) {
    return (
      <g
        id={previewId ? `g-${componentId}~${previewId}` : `g-${componentId}`}
        style={{
          mask: `url(#${maskId})`,
        }}
      >
        <ItemComponent
          id={
            previewId ? `body-scar${index}~${previewId}` : `body-scar${index}`
          }
          component={itemObject.shape}
          colour={itemObject.colour}
          transform={itemObject.transform ?? null}
          previewTransform={false}
        />
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(BodyScars);

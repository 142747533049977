import { allColours } from "character-creator/colours/colourSchemes";
import {
  NoseAccessoriesListLeft,
  NoseAccessoriesListRight,
} from "character-creator/components/clothing/Accessories/Face/Nose/NoseAccessoriesList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

const nosePath = "face.nose.";
const noseAccessoriesPath = "accessories.nose.";
const noseAccessoriesLeftPath = noseAccessoriesPath + "left.";
const noseAccessoriesRightPath = noseAccessoriesPath + "right.";

const NoseAccessoriesPageConfig = {
  id: "noseAccessoriesSidePage",
  name: "Nose",
  description: "Depending on your nose you may need to adjust the placement",
  // symmetryAttribute: noseAccessoriesPath + "symmetry",
  dependencies: [
    {
      description: "Nose shape",
      attribute: [nosePath + "shape"],
    },
  ],
  dependencyMessage: "Please choose a nose shape first",
  controls: [
    {
      id: "accessoriesNoseLeftStyleGroup",
      name: "Style",
      description: "",
      dataAttribute: [noseAccessoriesLeftPath + "shape"],
      previewType: "preview",
      options: NoseAccessoriesListLeft,

      colourControls: [
        {
          id: "accessoriesNoseLeftColour",
          name: "Colour",
          description: "",
          dataAttribute: [noseAccessoriesLeftPath + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "accessoriesNoseLeftTransformX",
          name: "Horizontal",
          description: "",
          dataAttribute: [noseAccessoriesLeftPath + "transform.x"],
          range: {
            min: -50,
            max: 50,
            step: 5,
          },
        },
        {
          id: "accessoriesNoseLeftTransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [noseAccessoriesLeftPath + "transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 5,
          },
        },
        {
          id: "accessoriesNoseLeftTransformScale",
          name: "Size",
          description: "",
          dataAttribute: [noseAccessoriesLeftPath + "transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "accessoriesNoseRightStyleGroup",
      name: "Right Style",
      description: "",
      dataAttribute: [noseAccessoriesRightPath + "shape"],
      previewType: "preview",
      options: NoseAccessoriesListRight,
      colourControls: [
        {
          id: "accessoriesNoseRightColour",
          name: "Right Colour",
          description: "",
          dataAttribute: [noseAccessoriesRightPath + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "accessoriesNoseRightTransformX",
          name: "Horizontal",
          description: "",
          dataAttribute: [noseAccessoriesRightPath + "transform.x"],
          range: {
            min: -50,
            max: 50,
            step: 5,
          },
        },
        {
          id: "accessoriesNoseRightTransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [noseAccessoriesRightPath + "transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 5,
          },
        },
        {
          id: "accessoriesNoseRightTransformScale",
          name: "Size",
          description: "",
          dataAttribute: [noseAccessoriesRightPath + "transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

const NoseAccessoriesPage = {
  id: "noseAccessoriesPage",
  name: "Nose",
  description: "",
  content: <SubPage page={NoseAccessoriesPageConfig} />,
};

export default NoseAccessoriesPage;

import React from "react";
import Ears from "character-creator/components/head/ears/CharacterEars";
import CharacterHead from "character-creator/components/head/CharacterHead";
import EarAccessoryBack from "./EarAccessoryBack";
import EarAccessoryFront from "./EarAccessoryFront";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <EarAccessoryBack
        layer="1"
        side="left"
        accessory={component}
        preview={true}
        previewId={previewId}
      />
      <Ears preview={true} ears={component} previewId={previewId} />

      <EarAccessoryFront
        layer="1"
        side="left"
        accessory={component}
        preview={true}
        previewId={previewId}
      />

      <CharacterHead preview={true} previewId={previewId} />
    </>
  );
};

export const viewbox = "110 200 100 100";

import React, { Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import Section from "./Section";
import {
  getCharacterConfigValue,
  updateCharacterConfig,
} from "character-creator/utilities/storeHelpers";
import { checkDependencies, SymmetryControl } from "../menu-helper";

// const mapDispatchToProps = {
//   updateMenu,
// };

const mapStateToProps = (state, ownProps) => {
  const symmetryOption = ownProps.page.symmetryAttribute && true;

  let symmetryOn = true;
  if (symmetryOption) {
    if (ownProps.symmetryConfigValue) {
      symmetryOn = ownProps.symmetryConfigValue;
    } else {
      const symmetryStateValue = getCharacterConfigValue(
        state,
        ownProps.page.symmetryAttribute[0]
      );
      if (symmetryStateValue !== undefined) {
        symmetryOn = symmetryStateValue;
      }
    }
  }

  const dependenciesMet = ownProps.page.dependencies
    ? !!checkDependencies(ownProps.page.dependencies, state)
    : true;

  const dependencyMessage = !dependenciesMet && ownProps.page.dependencyMessage;

  return {
    menu: state.menu,
    symmetryConfigValue: symmetryOn,
    dependenciesMet,
    dependencyMessage,
  };
};

const SubPage = (props) => {
  const { symmetryConfigValue, dependenciesMet, dependencyMessage } = props;
  const subPage = props.page;

  const dispatch = useDispatch();

  const updateSymmetry = (symmetryAttribute, updateValue = true) => {
    dispatch(
      updateCharacterConfig({
        attribute: symmetryAttribute,
        value: updateValue,
      })
    );
  };

  let subPageContent = <></>;

  if (subPage.controls) {
    subPageContent = (
      <Section
        pageLevel={true}
        section={subPage}
        symmetryConfigValue={symmetryConfigValue}
      />
    );
  } else if (subPage.sections) {
    subPageContent = subPage.sections.map((subPageSection, index) => {
      return <Section key={index} pageLevel={false} section={subPageSection} />;
    });
  }

  return (
    <>
      <section
        id={`subPage-${subPage.id}`}
        className={"subpage"}
        role="tabpanel"
        aria-labelledby={`tab-${subPage.id}`}
      >
        <div className="header">
          <h2 className="">{subPage.name}</h2>
          {subPage.description && (
            <p className="menu-description">{subPage.description}</p>
          )}

          {subPage.controls && (
            <div className="actions">
              {subPage.symmetryAttribute && (
                <SymmetryControl
                  controlId={subPage.id}
                  symmetryValue={symmetryConfigValue}
                  symmetryAttribute={subPage.symmetryAttribute[0]}
                  updateSymmetry={updateSymmetry}
                />
              )}
            </div>
          )}
        </div>

        {/* {Sections} */}
        {dependenciesMet && subPageContent}
        {!dependenciesMet && (
          <span className="dependency-message">{dependencyMessage}</span>
        )}
      </section>
    </>
  );
};

export default connect(mapStateToProps)(SubPage);
// export default SubPage;

import React from "react";
import { PreviewBase, BridgePreviewBase, viewbox } from "../Preview";

const variationConfigAngled = {
  id: "angled",
  name: "Angled",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: BridgePreviewBase,
  },
};

const variationConfigCurved = {
  id: "curved",
  name: "Curved",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: BridgePreviewBase,
  },
};

const variationConfigStraight = {
  id: "straight",
  name: "Straight",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: BridgePreviewBase,
  },
};

const glasses_Ut9JpD7fwu = {
  id: "glasses_Ut9JpD7fwu",
  name: "Glasses 1",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      frame: {
        base: (
          <path d="M200.985 245.785c-2.097 0-4.064 1.012-5.283 2.719-1.219 1.706-1.539 3.895-.859 5.879 3.314 9.663 12.6 16.666 23.554 16.666 10.957 0 20.244-7.007 23.478-16.7.669-1.956.352-4.115-.85-5.798-1.202-1.683-3.141-2.682-5.208-2.685-7.027-.081-17.42-.081-17.42-.081h-17.412Zm0 2h17.412s10.382 0 17.4.081h.011c1.425 0 2.762.688 3.59 1.847.828 1.16 1.045 2.647.583 3.995-.001.002-.002.005-.003.007-2.972 8.907-11.513 15.334-21.581 15.334-10.072 0-18.615-6.431-21.662-15.315-.471-1.372-.249-2.887.594-4.068.843-1.18 2.205-1.881 3.656-1.881Z" />
        ),
      },
      lens: {
        base: (
          <path d="M200.985 246.785c-1.774 0-3.438.857-4.47 2.3-1.031 1.443-1.301 3.296-.726 4.974 3.181 9.273 12.095 15.99 22.608 15.99 10.512 0 19.427-6.717 22.53-16.017.567-1.653.3-3.478-.716-4.9-1.015-1.422-2.655-2.266-4.403-2.266-7.022-.081-17.411-.081-17.411-.081h-17.412Z" />
        ),
      },
    },
    right: {
      frame: {
        base: (
          <path
            id="frame"
            d="M264.189 245.785c-2.097 0-4.065 1.012-5.283 2.719-1.219 1.706-1.539 3.895-.859 5.879 3.314 9.663 12.599 16.666 23.554 16.666 10.957 0 20.243-7.007 23.478-16.7.668-1.956.352-4.115-.85-5.798-1.202-1.683-3.141-2.682-5.208-2.685-7.027-.081-17.42-.081-17.42-.081h-17.412Zm0 2h17.412s10.382 0 17.4.081h.011c1.425 0 2.762.688 3.59 1.847.828 1.16 1.045 2.647.583 3.995-.001.002-.002.005-.003.007-2.972 8.907-11.513 15.334-21.581 15.334-10.072 0-18.615-6.431-21.662-15.315-.471-1.372-.249-2.887.594-4.068.843-1.18 2.205-1.881 3.656-1.881Z"
          />
        ),
      },
      lens: {
        base: (
          <path d="M264.189 246.785c-1.774 0-3.439.857-4.47 2.3-1.031 1.443-1.301 3.296-.726 4.974 3.18 9.273 12.095 15.99 22.608 15.99 10.512 0 19.427-6.717 22.53-16.017.567-1.653.3-3.478-.716-4.9-1.015-1.422-2.655-2.266-4.403-2.266-7.022-.081-17.411-.081-17.411-.081h-17.412Z" />
        ),
      },
    },
  },
  variations: {
    bridge: {
      bridgeMask: (
        <path d="M266.331 246.785v-6.235h-32.662v6.295c.737.006 1.454.013 2.139.021 1.748 0 3.388.844 4.403 2.266 1.016 1.422 1.283 3.247.716 4.9-.852 2.553-2.141 4.911-3.781 6.988h25.668c-1.648-2.071-2.95-4.421-3.821-6.961-.575-1.678-.305-3.531.726-4.974s2.696-2.3 4.47-2.3h2.142Z" />
      ),
      options: {
        angled: {
          ...variationConfigAngled,
          shape: {
            base: (
              <path d="m236.108 261.02 6.331-14.739c.157-.368.519-.606.919-.606h13.284c.4 0 .762.238.919.606l6.331 14.739h-2.177l-5.731-13.345h-11.968l-5.731 13.345h-2.177Z" />
            ),
          },
        },
        curved: {
          ...variationConfigCurved,
          shape: {
            base: (
              <path d="M237.411 261.02h-2c0-4.025 1.635-7.673 4.275-10.313 2.641-2.641 6.288-4.275 10.314-4.275s7.673 1.634 10.314 4.275c2.64 2.64 4.275 6.288 4.275 10.313h-2c0-3.473-1.411-6.62-3.689-8.899-2.279-2.279-5.426-3.689-8.9-3.689s-6.621 1.41-8.9 3.689c-2.278 2.279-3.689 5.426-3.689 8.899Z" />
            ),
          },
        },
        straight: {
          ...variationConfigStraight,
          shape: {
            base: (
              <path d="M233.669 261.02v-3.104c0-.398.079-.788.228-1.148.15-.361.369-.692.651-.974.281-.281.612-.501.973-.65.36-.149.75-.228 1.148-.228h26.662c.398 0 .788.079 1.148.228.361.149.692.369.973.65.282.282.501.613.651.974.149.36.228.75.228 1.148v3.104h-2v-3.104c0-.133-.026-.263-.076-.383s-.123-.231-.217-.324c-.094-.094-.204-.167-.324-.217-.12-.05-.25-.076-.383-.076h-26.662c-.133 0-.263.026-.383.076s-.23.123-.324.217c-.094.093-.167.204-.217.324-.05.12-.076.25-.076.383v3.104h-2Z" />
            ),
          },
        },
      },
    },
  },
};

// BUG: (Affinity) Frames on glasses 2 seem no thicker than glasses 1
const glasses_VYMGCPp2em = {
  id: "glasses_VYMGCPp2em",
  name: "Glasses 2",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      frame: {
        base: (
          <path d="M187.319 245.785c-1.98 0-3.851.903-5.083 2.453-1.232 1.55-1.689 3.577-1.242 5.505.001.003.001.006.002.009 3.288 13.602 15.718 23.762 30.569 23.762 14.854 0 27.285-10.164 30.488-23.787.001-.001.001-.002.001-.003.441-1.904-.01-3.904-1.226-5.434-1.214-1.527-3.058-2.418-5.009-2.421-8.841-.084-24.254-.084-24.254-.084h-24.246Zm0 2h24.246s15.403 0 24.237.084h.009c1.344 0 2.615.614 3.451 1.666.836 1.052 1.147 2.427.844 3.736-2.999 12.748-14.642 22.243-28.541 22.243-13.9 0-25.544-9.497-28.624-22.226-.307-1.334.009-2.734.861-3.806s2.147-1.697 3.517-1.697Z" />
        ),
      },
      lens: {
        base: (
          <path d="M187.319 246.785c-1.675 0-3.258.764-4.3 2.075-1.042 1.311-1.429 3.026-1.051 4.657 3.183 13.167 15.221 22.997 29.597 22.997 14.377 0 26.414-9.83 29.515-23.016.372-1.606-.009-3.295-1.035-4.585-1.026-1.291-2.585-2.044-4.234-2.044-8.837-.084-24.246-.084-24.246-.084h-24.246Z" />
        ),
      },
    },
    right: {
      frame: {
        base: (
          <path d="M264.189 245.785c-1.98 0-3.851.903-5.083 2.453-1.232 1.55-1.689 3.577-1.242 5.505 0 .003.001.006.002.009 3.288 13.602 15.718 23.762 30.569 23.762 14.853 0 27.284-10.164 30.488-23.787 0-.001 0-.002.001-.003.441-1.904-.011-3.904-1.226-5.434-1.215-1.527-3.058-2.418-5.009-2.421-8.842-.084-24.254-.084-24.254-.084h-24.246Zm0 2h24.246s15.403 0 24.237.084h.009c1.344 0 2.615.614 3.451 1.666.836 1.052 1.146 2.427.844 3.736-2.999 12.748-14.642 22.243-28.541 22.243-13.9 0-25.544-9.497-28.624-22.226-.308-1.334.009-2.734.861-3.806s2.147-1.697 3.517-1.697Z" />
        ),
      },
      lens: {
        base: (
          <path d="M264.189 246.785c-1.675 0-3.258.764-4.3 2.075-1.042 1.311-1.429 3.026-1.051 4.657 3.183 13.167 15.22 22.997 29.597 22.997 14.376 0 26.414-9.83 29.515-23.016.372-1.606-.009-3.295-1.035-4.585-1.026-1.291-2.585-2.044-4.234-2.044-8.837-.084-24.246-.084-24.246-.084h-24.246Z" />
        ),
      },
    },
  },
  variations: {
    bridge: {
      bridgeMask: (
        <path d="M266.331 246.785v-5.995h-32.662v6.062c.746.005 1.464.011 2.142.017 1.649 0 3.208.753 4.234 2.044 1.026 1.29 1.407 2.979 1.035 4.585-.789 3.353-2.155 6.489-3.991 9.302h25.782c-1.844-2.807-3.224-5.937-4.033-9.283-.378-1.631.009-3.346 1.051-4.657s2.625-2.075 4.3-2.075h2.142Z" />
      ),
      options: {
        angled: {
          ...variationConfigAngled,
          shape: {
            base: (
              <path d="m236.108 262.8 6.331-14.74c.157-.367.519-.605.919-.605h13.284c.4 0 .762.238.919.605l6.331 14.74h-2.177l-5.731-13.345h-11.968l-5.731 13.345h-2.177Z" />
            ),
          },
        },
        curved: {
          ...variationConfigCurved,
          shape: {
            base: (
              <path d="M237.411 262.8h-2c0-4.026 1.635-7.673 4.275-10.314 2.641-2.64 6.288-4.275 10.314-4.275s7.673 1.635 10.314 4.275c2.64 2.641 4.275 6.288 4.275 10.314h-2c0-3.474-1.411-6.621-3.689-8.9-2.279-2.278-5.426-3.689-8.9-3.689s-6.621 1.411-8.9 3.689c-2.278 2.279-3.689 5.426-3.689 8.9Z" />
            ),
          },
        },
        straight: {
          ...variationConfigStraight,
          shape: {
            base: (
              <path d="M233.669 262.8v-3.105c0-.398.079-.787.228-1.148.15-.36.369-.692.651-.973.281-.281.612-.501.973-.65.36-.15.75-.229 1.148-.229h26.662c.398 0 .788.079 1.148.229.361.149.692.369.973.65.282.281.501.613.651.973.149.361.228.75.228 1.148v3.105h-2v-3.105c0-.132-.026-.262-.076-.383-.05-.12-.123-.23-.217-.324s-.204-.167-.324-.217c-.12-.049-.25-.076-.383-.076h-26.662c-.133 0-.263.027-.383.076-.12.05-.23.123-.324.217s-.167.204-.217.324c-.05.121-.076.251-.076.383v3.105h-2Z" />
            ),
          },
        },
      },
    },
  },
};

const glasses_bVUqhSRAAz = {
  id: "glasses_bVUqhSRAAz",
  name: "Glasses 3",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      frame: {
        base: (
          <path d="M241.092 250.57c0-2.614-2.119-4.733-4.733-4.733h-51.657c-2.614 0-4.733 2.119-4.733 4.733v10.741c0 7.763 6.293 14.056 14.056 14.056h33.01c7.764 0 14.057-6.293 14.057-14.056V250.57Zm-2 0v10.741c0 6.659-5.398 12.056-12.057 12.056h-33.01c-6.659 0-12.056-5.397-12.056-12.056V250.57c0-1.509 1.223-2.733 2.733-2.733h51.657c1.509 0 2.733 1.224 2.733 2.733Z" />
        ),
      },
      lens: {
        base: (
          <path d="M240.092 250.57c0-2.062-1.672-3.733-3.733-3.733h-51.657c-2.062 0-3.733 1.671-3.733 3.733v10.741c0 7.211 5.845 13.056 13.056 13.056h33.01c7.211 0 13.057-5.845 13.057-13.056V250.57Z" />
        ),
      },
    },
    right: {
      frame: {
        base: (
          <path d="M320.031 250.57c0-2.614-2.119-4.733-4.733-4.733h-51.657c-2.614 0-4.733 2.119-4.733 4.733v10.741c0 7.763 6.293 14.056 14.057 14.056h33.01c7.763 0 14.056-6.293 14.056-14.056V250.57Zm-2 0v10.741c0 6.659-5.397 12.056-12.056 12.056h-33.01c-6.659 0-12.057-5.397-12.057-12.056V250.57c0-1.509 1.224-2.733 2.733-2.733h51.657c1.51 0 2.733 1.224 2.733 2.733Z" />
        ),
      },
      lens: {
        base: (
          <path d="M319.031 250.57c0-2.062-1.671-3.733-3.733-3.733h-51.657c-2.061 0-3.733 1.671-3.733 3.733v10.741c0 7.211 5.846 13.056 13.057 13.056h33.01c7.211 0 13.056-5.845 13.056-13.056V250.57Z" />
        ),
      },
    },
  },
  variations: {
    bridge: {
      bridgeMask: (
        <path d="M266.331 246.837v-5.939h-32.662v5.939h2.69c2.061 0 3.733 1.671 3.733 3.733v10.741c0 3.149-1.115 6.038-2.971 8.292h25.758c-1.856-2.254-2.971-5.143-2.971-8.292V250.57c0-2.062 1.672-3.733 3.733-3.733h2.69Z" />
      ),
      options: {
        angled: {
          ...variationConfigAngled,
          shape: {
            base: (
              <path d="m236.108 269.603 6.331-14.74c.157-.367.519-.605.919-.605h13.284c.4 0 .762.238.919.605l6.331 14.74h-2.177l-5.731-13.345h-11.968l-5.731 13.345h-2.177Z" />
            ),
          },
        },
        curved: {
          ...variationConfigCurved,
          shape: {
            base: (
              <path d="M237.411 269.603h-2c0-4.026 1.635-7.673 4.275-10.314 2.641-2.641 6.288-4.275 10.314-4.275s7.673 1.634 10.314 4.275c2.64 2.641 4.275 6.288 4.275 10.314h-2c0-3.474-1.411-6.621-3.689-8.9-2.279-2.278-5.426-3.689-8.9-3.689s-6.621 1.411-8.9 3.689c-2.278 2.279-3.689 5.426-3.689 8.9Z" />
            ),
          },
        },
        straight: {
          ...variationConfigStraight,
          shape: {
            base: (
              <path d="M233.669 269.603v-3.105c0-.398.079-.787.228-1.148.15-.36.369-.692.651-.973.281-.282.612-.501.973-.651.36-.149.75-.228 1.148-.228h26.662c.398 0 .788.079 1.148.228.361.15.692.369.973.651.282.281.501.613.651.973.149.361.228.75.228 1.148v3.105h-2v-3.105c0-.133-.026-.262-.076-.383-.05-.12-.123-.23-.217-.324s-.204-.167-.324-.217c-.12-.05-.25-.076-.383-.076h-26.662c-.133 0-.263.026-.383.076s-.23.123-.324.217-.167.204-.217.324c-.05.121-.076.25-.076.383v3.105h-2Z" />
            ),
          },
        },
      },
    },
  },
};

const glasses_uzgAn9mdAu = {
  id: "glasses_uzgAn9mdAu",
  name: "Glasses 4",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      frame: {
        base: (
          <path d="M241.092 236.664c0-2.614-2.119-4.733-4.733-4.733h-51.657c-2.614 0-4.733 2.119-4.733 4.733v24.647c0 7.763 6.293 14.056 14.056 14.056h33.01c7.764 0 14.057-6.293 14.057-14.056v-24.647Zm-2 0v24.647c0 6.659-5.398 12.056-12.057 12.056h-33.01c-6.659 0-12.056-5.397-12.056-12.056v-24.647c0-1.51 1.223-2.733 2.733-2.733h51.657c1.509 0 2.733 1.223 2.733 2.733Z" />
        ),
      },
      lens: {
        base: (
          <path d="M240.092 236.664c0-2.062-1.672-3.733-3.733-3.733h-51.657c-2.062 0-3.733 1.671-3.733 3.733v24.647c0 7.211 5.845 13.056 13.056 13.056h33.01c7.211 0 13.057-5.845 13.057-13.056v-24.647Z" />
        ),
      },
    },
    right: {
      frame: {
        base: (
          <path d="M320.031 236.664c0-2.614-2.119-4.733-4.733-4.733h-51.657c-2.614 0-4.733 2.119-4.733 4.733v24.647c0 7.763 6.293 14.056 14.057 14.056h33.01c7.763 0 14.056-6.293 14.056-14.056v-24.647Zm-2 0v24.647c0 6.659-5.397 12.056-12.056 12.056h-33.01c-6.659 0-12.057-5.397-12.057-12.056v-24.647c0-1.51 1.224-2.733 2.733-2.733h51.657c1.51 0 2.733 1.223 2.733 2.733Z" />
        ),
      },
      lens: {
        base: (
          <path d="M319.031 236.664c0-2.062-1.671-3.733-3.733-3.733h-51.657c-2.061 0-3.733 1.671-3.733 3.733v24.647c0 7.211 5.846 13.056 13.057 13.056h33.01c7.211 0 13.056-5.845 13.056-13.056v-24.647Z" />
        ),
      },
    },
  },
  variations: {
    bridge: {
      bridgeMask: (
        <path d="M266.331 232.931v-6.798h-32.662v6.798h2.69c2.061 0 3.733 1.671 3.733 3.733v24.647c0 3.166-1.127 6.069-3.003 8.33h25.822c-1.876-2.261-3.003-5.164-3.003-8.33v-24.647c0-2.062 1.672-3.733 3.733-3.733h2.69Z" />
      ),
      options: {
        angled: {
          ...variationConfigAngled,
          shape: {
            base: (
              <path d="m236.108 269.641 6.331-14.739c.157-.368.519-.606.919-.606h13.284c.4 0 .762.238.919.606l6.331 14.739h-2.177l-5.731-13.345h-11.968l-5.731 13.345h-2.177Z" />
            ),
          },
        },
        curved: {
          ...variationConfigCurved,
          shape: {
            base: (
              <path d="M237.411 269.641h-2c0-4.025 1.635-7.673 4.275-10.313 2.641-2.641 6.288-4.275 10.314-4.275s7.673 1.634 10.314 4.275c2.64 2.64 4.275 6.288 4.275 10.313h-2c0-3.473-1.411-6.62-3.689-8.899-2.279-2.279-5.426-3.689-8.9-3.689s-6.621 1.41-8.9 3.689c-2.278 2.279-3.689 5.426-3.689 8.899Z" />
            ),
          },
        },
        straight: {
          ...variationConfigStraight,
          shape: {
            base: (
              <path d="M233.669 269.641v-3.104c0-.398.079-.788.228-1.148.15-.361.369-.692.651-.974.281-.281.612-.501.973-.65.36-.149.75-.228 1.148-.228h26.662c.398 0 .788.079 1.148.228.361.149.692.369.973.65.282.282.501.613.651.974.149.36.228.75.228 1.148v3.104h-2v-3.104c0-.133-.026-.263-.076-.383s-.123-.231-.217-.325c-.094-.093-.204-.167-.324-.216-.12-.05-.25-.076-.383-.076h-26.662c-.133 0-.263.026-.383.076-.12.049-.23.123-.324.216-.094.094-.167.205-.217.325-.05.12-.076.25-.076.383v3.104h-2Z" />
            ),
          },
        },
      },
    },
  },
};

const glasses_Sd7tGgSpXu = {
  id: "glasses_Sd7tGgSpXu",
  name: "Glasses 5",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      frame: {
        base: (
          <path d="M242.092 222.518c0-3.167-2.567-5.734-5.733-5.734h-51.657c-3.167 0-5.733 2.567-5.733 5.734v38.793c0 3.993 1.586 7.823 4.41 10.646 2.823 2.824 6.653 4.41 10.646 4.41h33.01c3.994 0 7.823-1.586 10.647-4.41 2.824-2.823 4.41-6.653 4.41-10.646v-38.793Zm-4 0v38.793c0 2.932-1.165 5.745-3.238 7.818-2.074 2.073-4.886 3.238-7.819 3.238h-33.01c-2.932 0-5.745-1.165-7.818-3.238-2.074-2.073-3.238-4.886-3.238-7.818v-38.793c0-.958.776-1.734 1.733-1.734h51.657c.957 0 1.733.776 1.733 1.734Z" />
        ),
      },
      lens: {
        base: (
          <path d="M240.092 222.518c0-2.062-1.672-3.734-3.733-3.734h-51.657c-2.062 0-3.733 1.672-3.733 3.734v38.793c0 3.463 1.375 6.784 3.824 9.232 2.448 2.449 5.769 3.824 9.232 3.824h33.01c3.463 0 6.784-1.375 9.233-3.824 2.448-2.448 3.824-5.769 3.824-9.232v-38.793Z" />
        ),
      },
    },
    right: {
      frame: {
        base: (
          <path d="M321.031 222.518c0-3.167-2.566-5.734-5.733-5.734h-51.657c-3.166 0-5.733 2.567-5.733 5.734v38.793c0 3.993 1.586 7.823 4.41 10.646 2.824 2.824 6.653 4.41 10.647 4.41h33.01c3.993 0 7.823-1.586 10.646-4.41 2.824-2.823 4.41-6.653 4.41-10.646v-38.793Zm-4 0v38.793c0 2.932-1.164 5.745-3.238 7.818-2.073 2.073-4.886 3.238-7.818 3.238h-33.01c-2.933 0-5.745-1.165-7.819-3.238-2.073-2.073-3.238-4.886-3.238-7.818v-38.793c0-.958.776-1.734 1.733-1.734h51.657c.957 0 1.733.776 1.733 1.734Z" />
        ),
      },
      lens: {
        base: (
          <path d="M319.031 222.518c0-2.062-1.671-3.734-3.733-3.734h-51.657c-2.061 0-3.733 1.672-3.733 3.734v38.793c0 3.463 1.376 6.784 3.824 9.232 2.449 2.449 5.77 3.824 9.233 3.824h33.01c3.463 0 6.784-1.375 9.232-3.824 2.449-2.448 3.824-5.769 3.824-9.232v-38.793Z" />
        ),
      },
    },
  },
  variations: {
    bridge: {
      bridgeMask: (
        <path d="M271.016 218.784v-11.58h-42.032v11.58h7.375c2.061 0 3.733 1.672 3.733 3.734v38.793c0 .554-.035 1.105-.105 1.649h20.026c-.07-.544-.105-1.095-.105-1.649v-38.793c0-2.062 1.672-3.734 3.733-3.734h7.375Z" />
      ),
      options: {
        angled: {
          ...variationConfigAngled,
          shape: {
            base: (
              <path d="m236.655 248.516 8.736-9.946c.38-.433.927-.681 1.502-.681h6.213c.576 0 1.123.248 1.503.681l8.736 9.946h-5.324l-5.82-6.627h-4.402l-5.82 6.627h-5.324Z" />
            ),
          },
        },
        curved: {
          ...variationConfigCurved,
          shape: {
            base: (
              <path d="M235.843 256.046h-4c0-5.01 2.034-9.55 5.32-12.836 3.287-3.287 7.826-5.321 12.837-5.321s9.55 2.034 12.837 5.321c3.286 3.286 5.32 7.826 5.32 12.836h-4c0-3.906-1.586-7.445-4.149-10.008-2.562-2.562-6.101-4.149-10.008-4.149-3.907 0-7.446 1.587-10.008 4.149-2.563 2.563-4.149 6.102-4.149 10.008Z" />
            ),
          },
        },
        straight: {
          ...variationConfigStraight,
          shape: {
            base: (
              <path d="M228.984 245.071v-5.777c0-.558.106-1.106.305-1.612s.492-.971.867-1.366c.375-.395.817-.704 1.297-.913.481-.21 1.001-.321 1.531-.321h34.032c.53 0 1.05.111 1.531.321.48.209.922.518 1.297.913.375.395.668.86.867 1.366.199.506.305 1.054.305 1.612v5.777h-4v-5.777h-34.032v5.777h-4Z" />
            ),
          },
        },
      },
    },
  },
};

const glasses_KJk3NeHk5V = {
  id: "glasses_KJk3NeHk5V",
  name: "Glasses 6",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      frame: {
        base: (
          <path d="M241.978 247.133c0-5.742-4.471-10.381-9.949-10.381h-40.651c-5.478 0-9.949 4.639-9.949 10.381v18.763c0 5.742 4.471 10.381 9.949 10.381h40.651c5.478 0 9.949-4.639 9.949-10.381v-18.763Zm-2 0v18.763c0 4.613-3.549 8.381-7.949 8.381h-40.651c-4.4 0-7.949-3.768-7.949-8.381v-18.763c0-4.613 3.549-8.381 7.949-8.381h40.651c4.4 0 7.949 3.768 7.949 8.381Z" />
        ),
      },
      lens: {
        base: (
          <path d="M240.978 247.133c0-5.177-4.01-9.381-8.949-9.381h-40.651c-4.939 0-8.949 4.204-8.949 9.381v18.763c0 5.178 4.01 9.381 8.949 9.381h40.651c4.939 0 8.949-4.203 8.949-9.381v-18.763Z" />
        ),
      },
    },
    right: {
      frame: {
        base: (
          <path d="M318.571 247.133c0-5.742-4.471-10.381-9.949-10.381h-40.651c-5.478 0-9.949 4.639-9.949 10.381v18.763c0 5.742 4.471 10.381 9.949 10.381h40.651c5.478 0 9.949-4.639 9.949-10.381v-18.763Zm-2 0v18.763c0 4.613-3.549 8.381-7.949 8.381h-40.651c-4.4 0-7.949-3.768-7.949-8.381v-18.763c0-4.613 3.549-8.381 7.949-8.381h40.651c4.4 0 7.949 3.768 7.949 8.381Z" />
        ),
      },
      lens: {
        base: (
          <path d="M317.571 247.133c0-5.177-4.01-9.381-8.949-9.381h-40.651c-4.939 0-8.949 4.204-8.949 9.381v18.763c0 5.178 4.01 9.381 8.949 9.381h40.651c4.939 0 8.949-4.203 8.949-9.381v-18.763Z" />
        ),
      },
    },
  },
  variations: {
    bridge: {
      bridgeMask: (
        <path
          id="bridge-mask5"
          d="M266.331 237.91v-6.318h-32.662v6.318c4.157.808 7.309 4.633 7.309 9.223v18.763c0 1.286-.247 2.511-.695 3.628h19.434c-.448-1.117-.695-2.342-.695-3.628v-18.763c0-4.59 3.152-8.415 7.309-9.223Z"
        />
      ),
      options: {
        angled: {
          ...variationConfigAngled,
          shape: {
            base: (
              <path d="M239.178 263.809c.001-.02.002-.039.004-.058.033-.369.174-.732.425-1.043l3.841-4.755c.204-.252.468-.454.771-.59.277-.124.586-.192.904-.192h9.754c.318 0 .627.068.904.192.303.136.567.338.771.59l3.841 4.755c.251.311.392.674.425 1.043.002.019.003.038.004.058h-2.111l-3.714-4.599-.12-.039h-9.754l-.12.039-3.714 4.599h-2.111Z" />
            ),
          },
        },
        curved: {
          ...variationConfigCurved,
          shape: {
            base: (
              <path d="M237.411 263.809h-2c0-4.026 1.635-7.673 4.275-10.314 2.641-2.641 6.288-4.275 10.314-4.275s7.673 1.634 10.314 4.275c2.64 2.641 4.275 6.288 4.275 10.314h-2c0-3.474-1.411-6.621-3.689-8.9-2.279-2.278-5.426-3.689-8.9-3.689s-6.621 1.411-8.9 3.689c-2.278 2.279-3.689 5.426-3.689 8.9Z" />
            ),
          },
        },
        straight: {
          ...variationConfigStraight,
          shape: {
            base: (
              <path d="M233.669 263.809v-3.105c0-.398.079-.787.228-1.148.15-.361.369-.692.651-.973.281-.282.612-.501.973-.651.36-.149.75-.228 1.148-.228h26.662c.398 0 .788.079 1.148.228.361.15.692.369.973.651.282.281.501.612.651.973.149.361.228.75.228 1.148v3.105h-2v-3.105c0-.133-.026-.262-.076-.383-.05-.12-.123-.23-.217-.324s-.204-.167-.324-.217c-.12-.05-.25-.076-.383-.076h-26.662c-.133 0-.263.026-.383.076s-.23.123-.324.217-.167.204-.217.324c-.05.121-.076.25-.076.383v3.105h-2Z" />
            ),
          },
        },
      },
    },
  },
};

const glasses_qDMZnBbFKK = {
  id: "glasses_qDMZnBbFKK",
  name: "Glasses 7",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      frame: {
        base: (
          <path d="M242.978 228.773c0-6.331-5.141-11.472-11.473-11.472h-39.603c-6.332 0-11.473 5.141-11.473 11.472v36.032c0 6.332 5.141 11.472 11.473 11.472h39.603c6.332 0 11.473-5.14 11.473-11.472v-36.032Zm-4 0v36.032c0 4.124-3.348 7.472-7.473 7.472h-39.603c-4.124 0-7.473-3.348-7.473-7.472v-36.032c0-4.124 3.349-7.472 7.473-7.472h39.603c4.125 0 7.473 3.348 7.473 7.472Z" />
        ),
      },
      lens: {
        base: (
          <path d="M240.978 228.773c0-5.228-4.245-9.472-9.473-9.472h-39.603c-5.228 0-9.473 4.244-9.473 9.472v36.032c0 5.228 4.245 9.472 9.473 9.472h39.603c5.228 0 9.473-4.244 9.473-9.472v-36.032Z" />
        ),
      },
    },
    right: {
      frame: {
        base: (
          <path d="M319.571 228.773c0-6.331-5.141-11.472-11.473-11.472h-39.603c-6.332 0-11.473 5.141-11.473 11.472v36.032c0 6.332 5.141 11.472 11.473 11.472h39.603c6.332 0 11.473-5.14 11.473-11.472v-36.032Zm-4 0v36.032c0 4.124-3.349 7.472-7.473 7.472h-39.603c-4.125 0-7.473-3.348-7.473-7.472v-36.032c0-4.124 3.348-7.472 7.473-7.472h39.603c4.124 0 7.473 3.348 7.473 7.472Z" />
        ),
      },
      lens: {
        base: (
          <path d="M317.571 228.773c0-5.228-4.245-9.472-9.473-9.472h-39.603c-5.228 0-9.473 4.244-9.473 9.472v36.032c0 5.228 4.245 9.472 9.473 9.472h39.603c5.228 0 9.473-4.244 9.473-9.472v-36.032Z" />
        ),
      },
    },
  },
  variations: {
    bridge: {
      bridgeMask: (
        <path d="M271.016 219.301v-10.303h-42.032v10.303h2.521c5.228 0 9.473 4.244 9.473 9.472v36.032c0 1.582-.389 3.074-1.076 4.385h20.196c-.687-1.311-1.076-2.803-1.076-4.385v-36.032c0-5.228 4.245-9.472 9.473-9.472h2.521Z" />
      ),
      options: {
        angled: {
          ...variationConfigAngled,
          shape: {
            base: (
              <path d="m236.655 257.251 8.736-9.947c.38-.432.927-.68 1.502-.68h6.213c.576 0 1.123.248 1.503.68l8.736 9.947h-5.324l-5.82-6.627h-4.402l-5.82 6.627h-5.324Z" />
            ),
          },
        },
        curved: {
          ...variationConfigCurved,
          shape: {
            base: (
              <path d="M235.843 257.251h-4c0-5.011 2.034-9.55 5.32-12.837 3.287-3.287 7.826-5.32 12.837-5.32s9.55 2.033 12.837 5.32c3.286 3.287 5.32 7.826 5.32 12.837h-4c0-3.907-1.586-7.446-4.149-10.008-2.562-2.563-6.101-4.149-10.008-4.149-3.907 0-7.446 1.586-10.008 4.149-2.563 2.562-4.149 6.101-4.149 10.008Z" />
            ),
          },
        },
        straight: {
          ...variationConfigStraight,
          shape: {
            base: (
              <path d="M228.984 257.251v-5.486c0-.53.106-1.05.305-1.531.199-.48.492-.922.867-1.297.375-.375.817-.668 1.297-.867.481-.2 1.001-.305 1.531-.305h34.032c.53 0 1.05.105 1.531.305.48.199.922.492 1.297.867.375.375.668.817.867 1.297.199.481.305 1.001.305 1.531v5.486h-4v-5.486h-34.032v5.486h-4Z" />
            ),
          },
        },
      },
    },
  },
};

const glasses_GHpt362yDN = {
  id: "glasses_GHpt362yDN",
  name: "Glasses 8",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      frame: {
        base: (
          <path d="M212.153 232.753c-8.313 0-15.68 2.327-20.249 5.862-3.23 2.498-5.086 5.608-5.086 8.94s1.856 6.442 5.086 8.94c4.569 3.535 11.936 5.862 20.249 5.862 8.313 0 15.68-2.327 20.249-5.862 3.23-2.498 5.086-5.608 5.086-8.94s-1.856-6.442-5.086-8.94c-4.569-3.535-11.936-5.862-20.249-5.862Zm0 2c7.804 0 14.736 2.125 19.026 5.444 2.677 2.07 4.309 4.595 4.309 7.358 0 2.762-1.632 5.287-4.309 7.358-4.29 3.318-11.222 5.444-19.026 5.444s-14.736-2.126-19.026-5.444c-2.677-2.071-4.309-4.596-4.309-7.358 0-2.763 1.632-5.288 4.309-7.358 4.29-3.319 11.222-5.444 19.026-5.444Z" />
        ),
      },
      lens: {
        base: <ellipse cx="212.153" cy="247.555" rx="24.335" ry="13.802" />,
      },
    },
    right: {
      frame: {
        base: (
          <path d="M287.847 232.753c-8.313 0-15.68 2.327-20.249 5.862-3.23 2.498-5.086 5.608-5.086 8.94s1.856 6.442 5.086 8.94c4.569 3.535 11.936 5.862 20.249 5.862 8.313 0 15.68-2.327 20.249-5.862 3.23-2.498 5.086-5.608 5.086-8.94s-1.856-6.442-5.086-8.94c-4.569-3.535-11.936-5.862-20.249-5.862Zm0 2c7.804 0 14.736 2.125 19.026 5.444 2.677 2.07 4.309 4.595 4.309 7.358 0 2.762-1.632 5.287-4.309 7.358-4.29 3.318-11.222 5.444-19.026 5.444s-14.736-2.126-19.026-5.444c-2.677-2.071-4.309-4.596-4.309-7.358 0-2.763 1.632-5.288 4.309-7.358 4.29-3.319 11.222-5.444 19.026-5.444Z" />
        ),
      },
      lens: {
        base: <ellipse cx="287.847" cy="247.555" rx="24.335" ry="13.802" />,
      },
    },
  },
  variations: {
    bridge: {
      bridgeMask: (
        <path d="M266.563 240.862c-1.944 1.983-3.051 4.265-3.051 6.693 0 1.15.248 2.267.716 3.335h-28.456c.468-1.068.716-2.185.716-3.335 0-2.428-1.107-4.71-3.051-6.693v-11.695h33.126v11.695Z" />
      ),
      options: {
        angled: {
          ...variationConfigAngled,
          shape: {
            base: (
              <path d="m233.437 250.89 10.032-7.658c.174-.133.387-.205.606-.205h11.85c.219 0 .432.072.606.205l10.032 7.658h-3.296l-7.68-5.863h-11.174l-7.68 5.863h-3.296Z" />
            ),
          },
        },
        curved: {
          ...variationConfigCurved,
          shape: {
            base: (
              <path d="M236.712 249.238h-2c0-4.277 1.684-8.154 4.408-10.975 2.774-2.873 6.626-4.653 10.88-4.653 4.254 0 8.106 1.78 10.88 4.653 2.724 2.821 4.408 6.698 4.408 10.975h-2c0-3.735-1.468-7.122-3.847-9.586-2.408-2.494-5.749-4.042-9.441-4.042-3.692 0-7.033 1.548-9.441 4.042-2.379 2.464-3.847 5.851-3.847 9.586Z" />
            ),
          },
        },
        straight: {
          ...variationConfigStraight,
          shape: {
            base: (
              <path d="M233.669 242.084v-3.105c0-.398.079-.787.228-1.148.15-.36.369-.692.651-.973.281-.281.612-.501.973-.65.36-.15.75-.229 1.148-.229h26.662c.398 0 .788.079 1.148.229.361.149.692.369.973.65.282.281.501.613.651.973.149.361.228.75.228 1.148v3.105h-2v-3.105c0-.132-.026-.262-.076-.382-.05-.121-.123-.231-.217-.325s-.204-.167-.324-.217c-.12-.049-.25-.076-.383-.076h-26.662c-.133 0-.263.027-.383.076-.12.05-.23.123-.324.217s-.167.204-.217.325c-.05.12-.076.25-.076.382v3.105h-2Z" />
            ),
          },
        },
      },
    },
  },
};

const glasses_5PaAMRX7B2 = {
  id: "glasses_5PaAMRX7B2",
  name: "Glasses 9",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      frame: {
        base: (
          <path d="M212.153 231.753c-11.098 0-20.932 2.365-27.033 5.925-4.668 2.725-7.218 6.242-7.218 9.877 0 3.634 2.55 7.151 7.218 9.876 6.101 3.561 15.935 5.926 27.033 5.926s20.932-2.365 27.033-5.926c4.668-2.725 7.218-6.242 7.218-9.876 0-3.635-2.55-7.152-7.218-9.877-6.101-3.56-15.935-5.925-27.033-5.925Zm0 4c10.262 0 19.376 2.088 25.017 5.38 3.161 1.845 5.234 3.96 5.234 6.422 0 2.461-2.073 4.576-5.234 6.421-5.641 3.293-14.755 5.381-25.017 5.381-10.261 0-19.376-2.088-25.017-5.381-3.161-1.845-5.234-3.96-5.234-6.421 0-2.462 2.073-4.577 5.234-6.422 5.641-3.292 14.756-5.38 25.017-5.38Z" />
        ),
      },
      lens: {
        base: <ellipse cx="212.153" cy="247.555" rx="32.251" ry="13.802" />,
      },
    },
    right: {
      frame: {
        base: (
          <path d="M287.847 231.753c-11.098 0-20.932 2.365-27.033 5.925-4.668 2.725-7.218 6.242-7.218 9.877 0 3.634 2.55 7.151 7.218 9.876 6.101 3.561 15.935 5.926 27.033 5.926s20.932-2.365 27.033-5.926c4.668-2.725 7.218-6.242 7.218-9.876 0-3.635-2.55-7.152-7.218-9.877-6.101-3.56-15.935-5.925-27.033-5.925Zm0 4c10.261 0 19.376 2.088 25.017 5.38 3.161 1.845 5.234 3.96 5.234 6.422 0 2.461-2.073 4.576-5.234 6.421-5.641 3.293-14.756 5.381-25.017 5.381-10.262 0-19.376-2.088-25.017-5.381-3.161-1.845-5.234-3.96-5.234-6.421 0-2.462 2.073-4.577 5.234-6.422 5.641-3.292 14.755-5.38 25.017-5.38Z" />
        ),
      },
      lens: {
        base: <ellipse cx="287.847" cy="247.555" rx="32.251" ry="13.802" />,
      },
    },
  },
  variations: {
    bridge: {
      bridgeMask: (
        <path d="M271.016 235.782c-9.246 2.427-15.42 6.794-15.42 11.773 0 2.968 2.195 5.719 5.927 7.971h-23.046c3.732-2.252 5.927-5.003 5.927-7.971 0-4.979-6.174-9.346-15.42-11.773v-9.967h42.032v9.967Z" />
      ),
      options: {
        angled: {
          ...variationConfigAngled,
          shape: {
            base: (
              <path d="M237.527 255.526c.039-.559.196-1.112.472-1.621l3.756-6.947c.349-.646.861-1.17 1.47-1.533s1.314-.564 2.048-.564h9.454c.734 0 1.439.201 2.048.564.609.363 1.121.887 1.47 1.533l3.756 6.947c.276.509.433 1.062.472 1.621h-4.142l-3.604-6.665h-9.454l-3.604 6.665h-4.142Z" />
            ),
          },
        },
        curved: {
          ...variationConfigCurved,
          shape: {
            base: (
              <path d="M235.843 255.526h-4c0-5.011 2.034-9.55 5.32-12.837 3.287-3.286 7.826-5.32 12.837-5.32s9.55 2.034 12.837 5.32c3.286 3.287 5.32 7.826 5.32 12.837h-4c0-3.907-1.586-7.446-4.149-10.008-2.562-2.563-6.101-4.149-10.008-4.149-3.907 0-7.446 1.586-10.008 4.149-2.563 2.562-4.149 6.101-4.149 10.008Z" />
            ),
          },
        },
        straight: {
          ...variationConfigStraight,
          shape: {
            base: (
              <path d="M228.984 255.526v-5.486c0-.53.106-1.05.305-1.531.199-.48.492-.922.867-1.297.375-.375.817-.668 1.297-.867.481-.2 1.001-.305 1.531-.305h34.032c.53 0 1.05.105 1.531.305.48.199.922.492 1.297.867.375.375.668.817.867 1.297.199.481.305 1.001.305 1.531v5.486h-4v-5.486h-34.032v5.486h-4Z" />
            ),
          },
        },
      },
    },
  },
};

/*


const glasses_template = {
  name: "Component",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      frame: {
        base: <></>
      },
      lens: {
        base: <></>
      },
    },
    right: {
      frame: {
        base: <></>
      },
      lens: {
        base: <></>
      },
    },
  },
  variations: {
    bridge: {
      bridgeMask: <></>,
      options: {
        angled: {
          base: <></>
        },
        curved: {
          base: <></>
        },
        straight: {
          base: <></>
        },
      }
    },
  },
};


*/

export const GlassesPack1 = {
  glasses_Ut9JpD7fwu,
  glasses_VYMGCPp2em,
  glasses_bVUqhSRAAz,
  glasses_uzgAn9mdAu,
  glasses_Sd7tGgSpXu,
  glasses_KJk3NeHk5V,
  glasses_qDMZnBbFKK,
  glasses_GHpt362yDN,
  glasses_5PaAMRX7B2,
};

// Sections
import TattoosPage from "./pages/cosmetics/FaceTattoosPage";
import EyeShapePage from "./pages/eyes/EyeShapePage";
import IrisPage from "./pages/eyes/IrisPage";
import PupilPage from "./pages/eyes/PupilPage";
import EyeShinePage from "./pages/eyes/EyeShinePage";
import EyelashesPage from "./pages/eyes/EyelashesPage";
import EyesUndereyePage from "./pages/eyes/UndereyePage";
import MouthPage from "./pages/mouth/MouthPage";
import TeethPage from "./pages/mouth/TeethPage";
import MuzzlePage from "./pages/extras/MuzzlesPage";
import WhiskersPage from "./pages/extras/WhiskersPage";
import FacePaintPage from "./pages/cosmetics/FacePaintPage";
import CheeksPage from "./pages/cosmetics/CheeksPage";
import ContouringPage from "./pages/cosmetics/ContouringPage";
import TabbedPanelContainer from "character-creator/menu/elements/TabbedPanelContainer";
import React from "react";
import NosePage from "./pages/NosePage";
import EyebrowsPage from "../Hair/pages/face/EyebrowsPage";
import MoustachePage from "../Hair/pages/face/MoustachePage";
import BeardPage from "../Hair/pages/face/BeardPage";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const faceMenuSubTabs = {
  pageEyes: {
    id: "pageEyes",
    name: "Eyes",
    content: (
      <TabbedPanelContainer
        id="tabbedPanelEyes"
        tabs={{
          eyesPage: EyeShapePage,
          irisPage: IrisPage,
          pupilPage: PupilPage,
          eyeShinePage: EyeShinePage,
          eyelashesPage: EyelashesPage,
          eyesUndereyePage: EyesUndereyePage,
        }}
      />
    ),
  },
  pageNose: {
    id: "pageNose",
    name: "Nose",
    content: (
      <TabbedPanelContainer
        id="tabbedPanelNose"
        tabs={{
          nosePage: NosePage,
        }}
      />
    ),
  },
  pageMouth: {
    id: "pageMouth",
    name: "Mouth",
    content: (
      <TabbedPanelContainer
        id="tabbedPanelMouth"
        tabs={{
          mouthShapePage: MouthPage,
          teethPage: TeethPage,
        }}
      />
    ),
  },
  pageFacialHair: {
    id: "groupHairFacial",
    name: "Facial Hair",
    content: (
      <TabbedPanelContainer
        id="tabbedPanelExtras"
        tabs={{
          hairEyebrowsPage: EyebrowsPage,
          hairMoustachePage: MoustachePage,
          hairBeardPage: BeardPage,
        }}
      />
    ),
  },
  pageExtras: {
    id: "pageExtras",
    name: "Extras",
    content: (
      <TabbedPanelContainer
        id="tabbedPanelExtras"
        tabs={{
          muzzlePage: MuzzlePage,
          whiskersPage: WhiskersPage,
        }}
      />
    ),
  },
  pageCosmetics: {
    id: "pageCosmetics",
    name: "Cosmetics",
    content: (
      <TabbedPanelContainer
        id="tabbedPanelCosmetics"
        tabs={{
          CheeksPage,
          ContouringPage,
          faceTattoosPage: TattoosPage,
          facePaintPage: FacePaintPage,
        }}
      />
    ),
  },
};

const FaceMenuConfig = {
  id: "Face",
  name: "Face",
  // icon: icon,
  description: "Add your face",
  content: (
    <TabbedPanelContainer
      id="tabbedPanelBase"
      tabs={faceMenuSubTabs}
      containerClasses={"menu-block"}
      navClasses={"nav-inline"}
    />
  ),
};

export default FaceMenuConfig;

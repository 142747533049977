import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import { TailShapeListArray } from "./TailShapeList";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const tailConfig = getCharacterConfigValue(state, "tail");

  let tailObject = null;
  if (ownProps.preview && ownProps.tail) {
    tailObject = ownProps.tail;
  } else if (tailConfig && tailConfig.shape !== "0") {
    tailObject = filterListById(TailShapeListArray, tailConfig.shape);
    //TailShapeList
  }

  const colour = tailConfig?.colour
    ? tailConfig.colour
    : getCharacterConfigValue(state, "skintone");

  return {
    Tail: tailObject,
    colour: colour,
  };
}

const CharacterTail = (props) => {
  const { Tail, colour, previewId } = props;

  if (Tail) {
    return (
      <g id={previewId ? `g-tail~${previewId}` : "g-tail"}>
        <ItemComponent
          id={previewId ? `tail~${previewId}` : "tail"}
          component={Tail}
          colour={colour}
          preview={previewId ? true : false}
        />
        {/* <ItemComponent
          id={previewId ? `tail-tip~${previewId}` : "tail-tip"}
          component={Tail}
          colour={colour}
          preview={previewId ? true : false}
        /> */}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(CharacterTail);

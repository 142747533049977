/**
 * Variations/options
 */
import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { hairBackLeftPack1 } from "./packs/HairBackLeftPack1";
import { hairBackLeftPack2 } from "./packs/HairBackLeftPack2";

export const hairBackLeftList = Object.values({
  "0": BlankListOption,
  ...hairBackLeftPack1,
  ...hairBackLeftPack2,
});

export default hairBackLeftList;

import { AllBlues } from "./collections/Blues";
import { AllBrowns } from "./collections/Browns";
import { AllGreens } from "./collections/Greens";
import { AllGreys } from "./collections/Greys";
import { AllMetals } from "./collections/Metals";
import { AllPinks } from "./collections/Pinks";
import { AllPurples } from "./collections/Purples";
import { AllReds } from "./collections/Reds";

const AllSkintones = [
  ...AllBrowns,
  ...AllPinks,
  ...AllBlues,
  ...AllGreens,
  ...AllGreys,
  ...AllMetals,
  ...AllPurples,
  ...AllReds,
];

export default AllSkintones;

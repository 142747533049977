import React from "react";

import colourSchemesList from "character-creator/colours/colourSchemes";
import { getSettingsConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";
import ItemComponent from "./ItemComponent";

const PreviewBase = (props) => {
  const { previewId } = props;
  return (
    <ItemComponent
      id={`style~${previewId}`}
      component={props.component}
      preview={true}
    />
  );
};

export const getGlobalStyle = (state) => {
  const styleConfig = getSettingsConfigValue(state, "style");
  const globalColours = styleConfig.colour;
  const globalOutline =
    styleConfig.outline !== ""
      ? filterListById(outlineStyles, styleConfig.outline).style.outline
      : "";
  const globalShading =
    styleConfig.shading !== ""
      ? filterListById(shadingStyles, styleConfig.shading).style.shading
      : "";

  return {
    colour: globalColours,
    outline: globalOutline,
    shading: globalShading,
  };
};

const viewbox = "0 0 500 500";

const coloursBlank = {
  id: "coloursBlank",
  name: "Colour yourself",
  description: "Outlines only - good for colouring!",
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  style: {
    colour: colourSchemesList[0],
  },
  useClip: false,
  shape: {
    base: (
      <g
        fill="#fff"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="0"
        strokeWidth="5"
      >
        {/* <ellipse cx="196.269" cy="436.399" rx="164.766" ry="40.342" /> */}
        <circle cx="250" cy="250" r="186.399" />
      </g>
    ),
  },
};

const coloursNormal = {
  id: "coloursNormal",
  name: "The whole gamut",
  description: "The standard experience - pick your colours for each item",
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  style: {
    colour: "",
  },
  shape: {
    base: (
      <>
        <ellipse
          cx="196.269"
          cy="436.399"
          fill="#0080b0"
          rx="164.766"
          ry="40.342"
        />
        <circle cx="250" cy="250" r="186.399" fill="#00aff1" />
        <clipPath id="_clipSettingsColoursFull">
          <circle cx="250" cy="250" r="186.399" />
        </clipPath>
        <g clipPath="url(#_clipSettingsColoursFull)">
          <circle cx="277.31" cy="222.69" r="159.088" fill="#32c7ff" />
          <circle cx="316.334" cy="183.666" r="120.065" fill="#79dbff" />
        </g>
      </>
    ),
  },
};

const shadingFlat = {
  id: "shadingFlat",
  name: "Flat colours",
  description: "Flat colours - minimal shading",
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  style: {
    shading: false,
  },
  shape: {
    base: (
      <>
        <ellipse
          cx="196.269"
          cy="436.399"
          fill="#0080b0"
          rx="164.766"
          ry="40.342"
        />
        <circle cx="250" cy="250" r="186.399" fill="#00aff1" />
      </>
    ),
  },
};

const shadingBasic = {
  id: "shadingBasic",
  name: "Shading",
  description: "Adds shading!",
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  style: {
    shading: true,
  },
  shape: {
    base: (
      <>
        <ellipse
          cx="196.269"
          cy="436.399"
          fill="#0080b0"
          rx="164.766"
          ry="40.342"
        />
        <circle cx="250" cy="250" r="186.399" fill="#00aff1" />
        <clipPath id="_clipSettingsColoursFull">
          <circle cx="250" cy="250" r="186.399" />
        </clipPath>
        <g clipPath="url(#_clipSettingsColoursFull)">
          <circle cx="277.31" cy="222.69" r="159.088" fill="#32c7ff" />
          <circle cx="316.334" cy="183.666" r="120.065" fill="#79dbff" />
        </g>
      </>
    ),
  },
};

const outlinesNone = {
  id: "outlinesNone",
  name: "No outlines",
  description: "No outlines",
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  style: {
    outline: false,
  },
  shape: {
    base: (
      <>
        <ellipse
          cx="196.269"
          cy="436.399"
          fill="#0080b0"
          rx="164.766"
          ry="40.342"
        />
        <circle cx="250" cy="250" r="186.399" fill="#00aff1" />
      </>
    ),
  },
};

const outlinesStandard = {
  id: "outlinesStandard",
  name: "Standard outlines",
  description: "Thin outlines to help separate the different items",
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  style: {
    outline: true,
  },
  shape: {
    base: (
      <g
        stroke="#000"
        stroke-linejoin="round"
        stroke-miterlimit="0"
        stroke-width="4.87"
      >
        <ellipse
          cx="196.269"
          cy="436.399"
          fill="#0080b0"
          rx="164.766"
          ry="40.342"
        />
        <circle cx="250" cy="250" r="186.399" fill="#00aff1" />
      </g>
    ),
  },
};

export const colourStyles = Object.values({
  coloursNormal,
  coloursBlank,
});

export const shadingStyles = Object.values({
  shadingFlat,
  shadingBasic,
});

export const outlineStyles = Object.values({
  outlinesNone,
  outlinesStandard,
});

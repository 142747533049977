import React from "react";
import { PreviewBase } from "../Preview";
import { viewbox } from "../../Viewboxes";
import {
  horn_1ejoH,
  horn_1o7sQ,
  horn_2KAy6,
  horn_5hJxn,
  horn_8VORY,
  horn_a6buF,
  horn_akeSD,
  horn_E9d6o,
  horn_EEtKp,
  horn_gzKJD,
  horn_krtQg,
  horn_OIlzY,
  horn_PS7EE,
  horn_SBneR,
  horn_sKEBM,
  horn_T0Axo,
  horn_Wa16s,
  horn_WDF2W,
  horn_XtBFK,
  horn_yi4mB,
  horn_zkrvm,
} from "../../shared/packs/HornsSharedPack1";

const horn_front_side_gzKJD = {
  ...horn_gzKJD,
  id: "horn_front_side_gzKJD",
  preview: {
    ...horn_gzKJD.preview,
    base: PreviewBase,
  },
};

const horn_front_side_WDF2W = {
  ...horn_WDF2W,
  id: "horn_front_side_WDF2W",
  preview: {
    ...horn_WDF2W.preview,
    base: PreviewBase,
  },
};

const horn_front_side_5hJxn = {
  ...horn_5hJxn,
  id: "horn_front_side_5hJxn",
  preview: {
    ...horn_5hJxn.preview,
    base: PreviewBase,
  },
};

const horn_front_side_yi4mB = {
  ...horn_yi4mB,
  id: "horn_front_side_yi4mB",
  preview: {
    ...horn_yi4mB.preview,
    base: PreviewBase,
  },
};

const horn_front_side_2KAy6 = {
  ...horn_2KAy6,
  id: "horn_front_side_2KAy6",
  preview: {
    ...horn_2KAy6.preview,
    base: PreviewBase,
  },
};

const horn_front_side_a6buF = {
  ...horn_a6buF,
  id: "horn_front_side_a6buF",
  preview: {
    ...horn_a6buF.preview,
    base: PreviewBase,
  },
};

const horn_front_side_XtBFK = {
  ...horn_XtBFK,
  id: "horn_front_side_XtBFK",
  preview: {
    ...horn_XtBFK.preview,
    base: PreviewBase,
  },
};

const horn_front_side_EEtKp = {
  ...horn_EEtKp,
  id: "horn_front_side_EEtKp",
  preview: {
    ...horn_EEtKp.preview,
    base: PreviewBase,
  },
};

const horn_front_side_PS7EE = {
  ...horn_PS7EE,
  id: "horn_front_side_PS7EE",
  preview: {
    ...horn_PS7EE.preview,
    base: PreviewBase,
  },
};

const horn_front_side_Wa16s = {
  ...horn_Wa16s,
  id: "horn_front_side_Wa16s",
  preview: {
    ...horn_Wa16s.preview,
    base: PreviewBase,
  },
};

const horn_front_side_1ejoH = {
  ...horn_1ejoH,
  id: "horn_front_side_1ejoH",
  preview: {
    ...horn_1ejoH.preview,
    base: PreviewBase,
  },
};

const horn_front_side_E9d6o = {
  ...horn_E9d6o,
  id: "horn_front_side_E9d6o",
  preview: {
    ...horn_E9d6o.preview,
    base: PreviewBase,
  },
};

const horn_front_side_1o7sQ = {
  ...horn_1o7sQ,
  id: "horn_front_side_1o7sQ",
  preview: {
    ...horn_1o7sQ.preview,
    base: PreviewBase,
  },
};

// Not shared!
const horn_front_side_PSo7i = {
  id: "horn_front_side_PSo7i",
  name: "Side Horn 14",
  description:
    "Horns from the sides of the forehead that have been filed down almost to the skin",
  tags: ["short", "small", "front", "filed"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <>
          <ellipse
            transform="matrix(0.4296 -0.903 0.903 0.4296 -54.6062 298.6103)"
            cx="209.07"
            cy="192.53"
            rx="18.5"
            ry="16.43"
          />
        </>
      ),
      basic: {
        dark: (
          <g>
            <path d="M192.39,203.05c6.66,17.62,32.88,9.02,32.2-7.68c-1.77,2.03-3.09,4.71-5.15,6.39c-1.73-0.49-3.01,0.43-4.12,1.89c-5.36,5.6-4.62,0.81-7.34,2.6c-3.95,0.34-10.23-0.07-12.06-4.23C192.97,194.2,189.48,195.13,192.39,203.05z" />
          </g>
        ),
        light: (
          <g>
            <path d="M194.78,184.37c3.07-0.92,4.55-4.9,7.69-5.56c-0.61,0.53-1.02,1.28-1.15,2.07c2.67-0.75,13.82-7.43,13.75-2.02c5.28-0.57,7.95,6.41,9,10.68C231.49,173.33,202.74,164.07,194.78,184.37z" />
          </g>
        ),
      },
    },
    right: {
      base: (
        <>
          <ellipse
            transform="matrix(0.903 -0.4296 0.4296 0.903 -54.4985 143.6633)"
            cx="290.93"
            cy="192.53"
            rx="16.43"
            ry="18.5"
          />
        </>
      ),
      basic: {
        dark: (
          <g>
            <path d="M307.61,203.05c-6.66,17.62-32.88,9.02-32.2-7.68c1.77,2.03,3.09,4.71,5.15,6.39c1.73-0.49,3.01,0.43,4.12,1.89c5.36,5.6,4.62,0.81,7.34,2.6c3.95,0.34,10.23-0.07,12.06-4.23C307.03,194.2,310.52,195.13,307.61,203.05z" />
          </g>
        ),
        light: (
          <g>
            <path d="M305.22,184.37c-3.07-0.92-4.55-4.9-7.69-5.56c0.61,0.53,1.02,1.28,1.15,2.07c-2.67-0.75-13.82-7.43-13.75-2.02c-5.28-0.57-7.95,6.41-9,10.68C268.51,173.33,297.26,164.07,305.22,184.37z" />
          </g>
        ),
      },
    },
  },
};

const horn_front_side_SBneR = {
  ...horn_SBneR,
  id: "horn_front_side_SBneR",
  preview: {
    base: PreviewBase,
  },
};

const horn_front_side_sKEBM = {
  ...horn_sKEBM,
  id: "horn_front_side_sKEBM",
  preview: {
    base: PreviewBase,
  },
};

const horn_front_side_zkrvm = {
  ...horn_zkrvm,
  id: "horn_front_side_zkrvm",
  preview: {
    base: PreviewBase,
  },
};

const horn_front_side_krtQg = {
  ...horn_krtQg,
  id: "horn_front_side_krtQg",
  preview: {
    base: PreviewBase,
  },
};

const horn_front_side_akeSD = {
  ...horn_akeSD,
  id: "horn_front_side_akeSD",
  preview: {
    base: PreviewBase,
  },
};

const horn_front_side_OIlzY = {
  ...horn_OIlzY,
  id: "horn_front_side_OIlzY",
  preview: {
    base: PreviewBase,
  },
};

const horn_front_side_8VORY = {
  ...horn_8VORY,
  id: "horn_front_side_8VORY",
  preview: {
    base: PreviewBase,
  },
};

const horn_front_side_T0Axo = {
  ...horn_T0Axo,
  id: "horn_front_side_T0Axo",
  preview: {
    base: PreviewBase,
  },
};

export const FrontHornsPack1 = {
  horn_front_side_gzKJD,
  horn_front_side_WDF2W,
  horn_front_side_5hJxn,
  horn_front_side_yi4mB,
  horn_front_side_2KAy6,
  horn_front_side_a6buF,
  horn_front_side_XtBFK,
  horn_front_side_EEtKp,
  horn_front_side_PS7EE,
  horn_front_side_Wa16s,
  horn_front_side_1ejoH,
  horn_front_side_E9d6o,
  horn_front_side_1o7sQ,
  horn_front_side_PSo7i,
  horn_front_side_SBneR,
  horn_front_side_sKEBM,
  horn_front_side_zkrvm,
  horn_front_side_krtQg,
  horn_front_side_akeSD,
  horn_front_side_OIlzY,
  horn_front_side_8VORY,
  horn_front_side_T0Axo,
};

import { allColours } from "character-creator/colours/colourSchemes";

import { BodyMarkingsListArray } from "character-creator/components/body/markings/BodyMarkingsList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const bodyMarkingsPath = "bodyMarkings.";

const bodyMarkingsPageConfig = {
  id: "bodyMarkingsPage",
  name: "Markings",
  description: "",
  controls: [
    {
      id: "bodyMarkingShape0",
      name: "Marking 1",
      description: "",
      dataAttribute: [bodyMarkingsPath + "0.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyMarkingsListArray,
      colourControls: [
        {
          id: "bodyMarkingColour0",
          name: "Colour",
          description: "",
          dataAttribute: [bodyMarkingsPath + "0.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "bodyMarkingTransformO0",
          name: "Opacity",
          description: "",
          dataAttribute: [bodyMarkingsPath + "0.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "bodyMarkingShape1",
      name: "Marking 2",
      description: "",
      dataAttribute: [bodyMarkingsPath + "1.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyMarkingsListArray,
      colourControls: [
        {
          id: "bodyMarkingColour1",
          name: "Colour",
          description: "",
          dataAttribute: [bodyMarkingsPath + "1.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "bodyMarkingTransformO1",
          name: "Opacity",
          description: "",
          dataAttribute: [bodyMarkingsPath + "1.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "bodyMarkingShape2",
      name: "Marking 3",
      description: "",
      dataAttribute: [bodyMarkingsPath + "2.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyMarkingsListArray,
      colourControls: [
        {
          id: "bodyMarkingColour2",
          name: "Colour",
          description: "",
          dataAttribute: [bodyMarkingsPath + "2.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "bodyMarkingTransformO2",
          name: "Opacity",
          description: "",
          dataAttribute: [bodyMarkingsPath + "2.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

const BodyMarkingsPage = {
  id: "bodyMarkingsPage",
  name: "Markings",
  description: "",
  content: <SubPage page={bodyMarkingsPageConfig} />,
};

export default BodyMarkingsPage;

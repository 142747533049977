import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { EyeShapePack1 } from "./packs/EyesPack1";

/**
const eyeTemplate = {
id: "eyeTemplate",
  name: "Eye 1",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      eyelid: {
        top: {
          base:
            <>
            </>
        },
        bottom: {
          base:
            <>
            </>
        }
      },
      base: 
        <>

        </>,
      basic: {
        dark:
          <>

          </>
      }
    },
    right: {
      eyelid: {
        top: {
          base:
            <>
            </>
        },
        bottom: {
          base:
            <>
            </>
        }
      },
      base: 
        <>

        </>,
      basic: {
        dark:
          <>
          
          </>
      }
    }
  }
}
**/

export const EyeShapesList = {
  "0": BlankListOption,
  ...EyeShapePack1,
};

export const EyeShapesListArray = Object.values(EyeShapesList);

import React from "react";
import { connect } from "react-redux";
import NeckAccessoryFront from "./NeckAccessoryFront";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
/**
This will be used to build the different front layers of clothing
 */

function mapStateToProps(state, ownProps) {
  const neckAccessoriesConfig = getCharacterConfigValue(
    state,
    "accessories.neck"
  );
  const Layer1 = neckAccessoriesConfig?.layer1;
  const Layer2 = neckAccessoriesConfig?.layer2;
  const Layer3 = neckAccessoriesConfig?.layer3;

  return { Layer1, Layer2, Layer3 };
}

const NeckAccessoriesFrontLayers = (props) => {
  const { previewId, Layer1, Layer2, Layer3 } = props;

  if (Layer1?.shape) {
    return (
      <g
        id={
          previewId
            ? `g-neckAccessory-front-layers~${previewId}`
            : "g-neckAccessory-front-layers"
        }
      >
        {Layer1.shape && <NeckAccessoryFront layer="1" layerConfig={Layer1} />}
        {Layer2?.shape && <NeckAccessoryFront layer="2" layerConfig={Layer2} />}
        {Layer3?.shape && <NeckAccessoryFront layer="3" layerConfig={Layer3} />}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(NeckAccessoriesFrontLayers);

import React from "react";
import { connect } from "react-redux";
import eyebrowList from "character-creator/components/head/face/facialHair/eyebrows/EyebrowShapeList";
import ItemComponent from "character-creator/components/ItemComponent";
import {
  buildTransform,
  filterListById,
} from "character-creator/menu/menu-helper";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import HairColours from "character-creator/colours/hair";

function mapStateToProps(state, ownProps) {
  const defaultHairColour =
    getCharacterConfigValue(state, "hair.colour") ?? HairColours[0];

  // Left
  const eyebrowConfigLeft = getCharacterConfigValue(state, "eyebrows.left");
  let eyebrowObjectLeft = null;

  if (ownProps.preview && ownProps.eyebrows.shape.left) {
    eyebrowObjectLeft = ownProps.eyebrows.shape.left;
  } else if (eyebrowConfigLeft?.shape && eyebrowConfigLeft.shape !== "0") {
    eyebrowObjectLeft = filterListById(eyebrowList, eyebrowConfigLeft.shape)
      .shape.left;
  }

  const coloursLeft = eyebrowConfigLeft?.colour
    ? eyebrowConfigLeft.colour
    : defaultHairColour;

  // Right
  const eyebrowConfigRight = getCharacterConfigValue(state, "eyebrows.right");
  let eyebrowObjectRight = null;

  if (ownProps.preview && ownProps.eyebrows.shape.right) {
    eyebrowObjectRight = ownProps.eyebrows.shape.right;
  } else if (eyebrowConfigRight?.shape && eyebrowConfigRight.shape !== "0") {
    eyebrowObjectRight = filterListById(eyebrowList, eyebrowConfigRight.shape)
      .shape.right;
  }

  const coloursRight = eyebrowConfigRight?.colour
    ? eyebrowConfigRight.colour
    : defaultHairColour;

  const transformLeft =
    eyebrowConfigRight?.transform &&
    buildTransform(eyebrowConfigLeft.transform);
  const transformRight =
    eyebrowConfigRight?.transform &&
    buildTransform(eyebrowConfigRight.transform);

  return {
    left: {
      shape: eyebrowObjectLeft,
      colour: coloursLeft,
      transform: transformLeft,
    },
    right: {
      shape: eyebrowObjectRight,
      colour: coloursRight,
      transform: transformRight,
    },
  };
}

export function Eyebrows(props) {
  const { left, right, previewId } = props;

  if (right.shape || left.shape) {
    return (
      <g id={previewId ? `g-eyebrows~${previewId}` : "g-eyebrows"}>
        {left.shape && (
          <g
            id={"g-eyebrow-left"}
            transform={left.transform ?? ""}
            transform-origin="50% 50%"
          >
            <ItemComponent
              id={previewId ? `eyebrow-left~${previewId}` : "eyebrow-left"}
              component={left}
              colour={left.colour}
              // linework={left.linework}
            />
          </g>
        )}
        {right.shape && (
          <g
            id={"g-eyebrow-right"}
            transform={right.transform ?? ""}
            transform-origin="50% 50%"
          >
            <ItemComponent
              id={previewId ? `eyebrow-right~${previewId}` : "eyebrow-right"}
              component={right}
              colour={right.colour}
            />
          </g>
        )}
      </g>
    );
  } else {
    return <></>;
  }
}

export default connect(mapStateToProps)(Eyebrows);

import { combineReducers, configureStore, createSlice } from "@reduxjs/toolkit";
import {
  characterConfig,
  colourConfig,
  settingsConfig,
} from "character-creator/utilities/characterConfig";
import { set, get, unset } from "lodash";
import { initialMenuConfig } from "./menuConfig";

const settingsSlice = createSlice({
  name: "settings",
  initialState: settingsConfig,
  reducers: {
    updateSettingsConfig: (state, action) => {
      set(state, action.payload.attribute, action.payload.value);
    },
    updateStyleConfig: (state, action) => {
      set(state.style, action.payload.attribute, action.payload.value);
    },
  },
});

const menuSlice = createSlice({
  name: "menu",
  initialState: initialMenuConfig,
  reducers: {
    updateMenuConfig: (state, action) => {
      set(state, action.payload.attribute, action.payload.value);
    },
  },
});

const characterSlice = createSlice({
  name: "character",
  initialState: characterConfig,
  reducers: {
    updateCharacterConfig: (state, action) => {
      if (action.payload.value === "0") {
        unset(state, action.payload.attribute);
      } else {
        set(state, action.payload.attribute, action.payload.value);
      }
    },
  },
});

const colourSlice = createSlice({
  name: "colours",
  initialState: colourConfig,
  reducers: {
    updateColourConfig: (state, action) => {
      set(state, action.payload.attribute, action.payload.value);
    },
    updateRecentColoursConfig: (state, action) => {
      set(state, "recentColours", action.payload.value);
    },
    updateCustomColoursConfig: (state, action) => {
      set(state, "customPalettes", action.payload.value);
    },
  },
});

export const getSettingsConfigValue = (state, path) => {
  return get(state.settings, path);
};

export const getStyleConfigValue = (state, path) => {
  return get(state.settings.style, path);
};

export const getMenuConfigValue = (state, path) => {
  return get(state.menu, path);
};

export const getCharacterConfigValue = (state, path) => {
  return get(state.character, path);
};

export const getColourConfigValue = (state, path) => {
  return get(state.colours, path);
};

export const getConfigValue = (configType, state, path) => {
  return configType === "settings"
    ? getSettingsConfigValue(state, path)
    : configType === "style"
    ? getStyleConfigValue(state, path)
    : configType === "swatch" || configType === "colour"
    ? getColourConfigValue(state, path)
    : getCharacterConfigValue(state, path);
};

export const updateConfig = (
  dispatch,
  updateFunction,
  attributeList,
  updateValue = ""
) => {
  if (Array.isArray(attributeList)) {
    attributeList.forEach((thisAttribute) => {
      dispatch(
        updateFunction({ attribute: thisAttribute, value: updateValue })
      );
    });
  } else {
    dispatch(updateFunction({ attribute: attributeList, value: updateValue }));
  }
};

export const { updateSettingsConfig, updateStyleConfig } =
  settingsSlice.actions;
export const { updateMenuConfig } = menuSlice.actions;
export const { updateCharacterConfig } = characterSlice.actions;
export const { updateRecentColoursConfig, updateCustomColoursConfig } =
  colourSlice.actions;

const combinedReducers = combineReducers({
  menu: menuSlice.reducer,
  settings: settingsSlice.reducer,
  character: characterSlice.reducer,
  colours: colourSlice.reducer,
});

const store = configureStore({
  reducer: combinedReducers,
});

export default store;

import React from "react";
import { PreviewBase, viewbox } from "../../Preview";

const shapeEar1 = {
  left: {
    front: {
      base: (
        <path d="M164.716 275.577c1.904.105 2.417-3.486 0-3.625-3.994-.229-7.25 3.249-7.25 7.25 0 4.001 3.625 2.001 3.625 0s1.628-3.735 3.625-3.625Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737 283.946c.129.233 2.483 1.057 3.351 1.172 2.968.392 7.265-.835 7.067-5.519-.089-2.114-1.613-4.219-3.903-4.31-.505-.02-1.782-.012-2.907.979-1.99 1.752-1.293 3.996-1.407 3.769-.035-.07-.495-1.202-.325-2.252.389-2.401 2.521-3.801 4.778-3.729 2.238.07 4.439 1.366 5.712 3.19 2.769 3.97.135 9.92-4.468 10.356-7.496.71-7.898-3.656-7.898-3.656Z" />
        ),
        light: (
          <path d="M170.446 274.435c-.129-.233-2.484-1.056-3.352-1.171-2.968-.392-7.265.834-7.067 5.518.09 2.114 1.613 4.22 3.903 4.31.505.02 1.782.013 2.907-.978 1.991-1.753 1.294-3.997 1.407-3.77.035.071.495 1.203.325 2.253-.389 2.401-2.521 3.801-4.778 3.729-2.238-.071-5.293-1.83-5.596-5.124-.444-4.821 2.896-7.309 5.366-7.681 7.445-1.123 6.885 2.914 6.885 2.914Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716 271.952c-4.001 0-7.25 3.249-7.25 7.25 0 4.001 3.249 7.25 7.25 7.25 4.002 0 7.25-3.249 7.25-7.25 0-4.001-3.248-7.25-7.25-7.25Zm0 3.625c-2 0-3.625 1.624-3.625 3.625s1.625 3.625 3.625 3.625c2.001 0 3.625-1.624 3.625-3.625s-1.624-3.625-3.625-3.625Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737 283.946c.129.233 2.483 1.057 3.351 1.172 2.968.392 7.265-.835 7.067-5.519-.089-2.114-1.613-4.219-3.903-4.31-.505-.02-1.782-.012-2.907.979-1.99 1.752-1.293 3.996-1.407 3.769-.035-.07-.495-1.202-.325-2.252.389-2.401 2.521-3.801 4.778-3.729 2.238.07 4.439 1.366 5.712 3.19 2.769 3.97.135 9.92-4.468 10.356-7.496.71-7.898-3.656-7.898-3.656Z" />
        ),
        light: (
          <path d="M170.446 274.435c-.129-.233-2.484-1.056-3.352-1.171-2.968-.392-7.265.834-7.067 5.518.09 2.114 1.613 4.22 3.903 4.31.505.02 1.782.013 2.907-.978 1.991-1.753 1.294-3.997 1.407-3.77.035.071.495 1.203.325 2.253-.389 2.401-2.521 3.801-4.778 3.729-2.238-.071-5.293-1.83-5.596-5.124-.444-4.821 2.896-7.309 5.366-7.681 7.445-1.123 6.885 2.914 6.885 2.914Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.284 275.577c-1.904.105-2.417-3.486 0-3.625 3.994-.229 7.25 3.249 7.25 7.25 0 4.001-3.625 2.001-3.625 0s-1.628-3.735-3.625-3.625Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263 283.946c-.129.233-2.483 1.057-3.351 1.172-2.968.392-7.265-.835-7.067-5.519.089-2.114 1.613-4.219 3.903-4.31.505-.02 1.782-.012 2.907.979 1.99 1.752 1.293 3.996 1.407 3.769.035-.07.495-1.202.325-2.252-.389-2.401-2.521-3.801-4.778-3.729-2.238.07-4.439 1.366-5.712 3.19-2.769 3.97-.135 9.92 4.468 10.356 7.496.71 7.898-3.656 7.898-3.656Z" />
        ),
        light: (
          <path d="M329.554 274.435c.129-.233 2.484-1.056 3.352-1.171 2.968-.392 7.265.834 7.067 5.518-.09 2.114-1.613 4.22-3.903 4.31-.505.02-1.782.013-2.907-.978-1.991-1.753-1.294-3.997-1.407-3.77-.035.071-.495 1.203-.325 2.253.389 2.401 2.521 3.801 4.778 3.729 2.238-.071 5.293-1.83 5.596-5.124.444-4.821-2.896-7.309-5.366-7.681-7.445-1.123-6.885 2.914-6.885 2.914Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.284 271.952c4.001 0 7.25 3.249 7.25 7.25 0 4.001-3.249 7.25-7.25 7.25-4.002 0-7.25-3.249-7.25-7.25 0-4.001 3.248-7.25 7.25-7.25Zm0 3.625c2 0 3.625 1.624 3.625 3.625s-1.625 3.625-3.625 3.625c-2.001 0-3.625-1.624-3.625-3.625s1.624-3.625 3.625-3.625Z" />
      ),

      basic: {
        dark: (
          <path d="M341.263 283.946c-.129.233-2.483 1.057-3.351 1.172-2.968.392-7.265-.835-7.067-5.519.089-2.114 1.613-4.219 3.903-4.31.505-.02 1.782-.012 2.907.979 1.99 1.752 1.293 3.996 1.407 3.769.035-.07.495-1.202.325-2.252-.389-2.401-2.521-3.801-4.778-3.729-2.238.07-4.439 1.366-5.712 3.19-2.769 3.97-.135 9.92 4.468 10.356 7.496.71 7.898-3.656 7.898-3.656Z" />
        ),
        light: (
          <path d="M329.554 274.435c.129-.233 2.484-1.056 3.352-1.171 2.968-.392 7.265.834 7.067 5.518-.09 2.114-1.613 4.22-3.903 4.31-.505.02-1.782.013-2.907-.978-1.991-1.753-1.294-3.997-1.407-3.77-.035.071-.495 1.203-.325 2.253.389 2.401 2.521 3.801 4.778 3.729 2.238-.071 5.293-1.83 5.596-5.124.444-4.821-2.896-7.309-5.366-7.681-7.445-1.123-6.885 2.914-6.885 2.914Z" />
        ),
      },
    },
  },
};

const shapeEar2 = {
  left: {
    front: {
      base: (
        <path d="M164.716 291.887c1.904.105 2.417-3.486 0-3.625-3.994-.229-7.25 3.249-7.25 7.25 0 4.001 3.625 2.001 3.625 0s1.628-3.735 3.625-3.625Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737 300.256c.129.233 2.483 1.057 3.351 1.171 2.968.393 7.265-.834 7.067-5.518-.089-2.114-1.613-4.219-3.903-4.31-.505-.02-1.782-.012-2.907.978-1.99 1.753-1.293 3.997-1.407 3.77-.035-.07-.495-1.202-.325-2.252.389-2.402 2.521-3.801 4.778-3.73 2.238.071 4.439 1.367 5.712 3.191 2.769 3.97.135 9.92-4.468 10.356-7.496.71-7.898-3.656-7.898-3.656Z" />
        ),
        light: (
          <path d="M170.446 290.745c-.129-.233-2.484-1.056-3.352-1.171-2.968-.393-7.265.834-7.067 5.518.09 2.114 1.613 4.22 3.903 4.31.505.02 1.782.013 2.907-.978 1.991-1.753 1.294-3.997 1.407-3.77.035.071.495 1.202.325 2.252-.389 2.402-2.521 3.801-4.778 3.73-2.238-.071-5.293-1.83-5.596-5.124-.444-4.821 2.896-7.309 5.366-7.681 7.445-1.123 6.885 2.914 6.885 2.914Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716 288.262c-4.001 0-7.25 3.249-7.25 7.25 0 4.001 3.249 7.25 7.25 7.25 4.002 0 7.25-3.249 7.25-7.25 0-4.001-3.248-7.25-7.25-7.25Zm0 3.625c-2 0-3.625 1.624-3.625 3.625s1.625 3.625 3.625 3.625c2.001 0 3.625-1.624 3.625-3.625s-1.624-3.625-3.625-3.625Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737 300.256c.129.233 2.483 1.057 3.351 1.171 2.968.393 7.265-.834 7.067-5.518-.089-2.114-1.613-4.219-3.903-4.31-.505-.02-1.782-.012-2.907.978-1.99 1.753-1.293 3.997-1.407 3.77-.035-.07-.495-1.202-.325-2.252.389-2.402 2.521-3.801 4.778-3.73 2.238.071 4.439 1.367 5.712 3.191 2.769 3.97.135 9.92-4.468 10.356-7.496.71-7.898-3.656-7.898-3.656Z" />
        ),
        light: (
          <path d="M170.446 290.745c-.129-.233-2.484-1.056-3.352-1.171-2.968-.393-7.265.834-7.067 5.518.09 2.114 1.613 4.22 3.903 4.31.505.02 1.782.013 2.907-.978 1.991-1.753 1.294-3.997 1.407-3.77.035.071.495 1.202.325 2.252-.389 2.402-2.521 3.801-4.778 3.73-2.238-.071-5.293-1.83-5.596-5.124-.444-4.821 2.896-7.309 5.366-7.681 7.445-1.123 6.885 2.914 6.885 2.914Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path
          id="base"
          d="M335.284 291.887c-1.904.105-2.417-3.486 0-3.625 3.994-.229 7.25 3.249 7.25 7.25 0 4.001-3.625 2.001-3.625 0s-1.628-3.735-3.625-3.625Z"
        />
      ),
      basic: {
        dark: (
          <path
            id="dark"
            d="M341.263 300.256c-.129.233-2.483 1.057-3.351 1.171-2.968.393-7.265-.834-7.067-5.518.089-2.114 1.613-4.219 3.903-4.31.505-.02 1.782-.012 2.907.978 1.99 1.753 1.293 3.997 1.407 3.77.035-.07.495-1.202.325-2.252-.389-2.402-2.521-3.801-4.778-3.73-2.238.071-4.439 1.367-5.712 3.191-2.769 3.97-.135 9.92 4.468 10.356 7.496.71 7.898-3.656 7.898-3.656Z"
          />
        ),
        light: (
          <path
            id="light"
            d="M329.554 290.745c.129-.233 2.484-1.056 3.352-1.171 2.968-.393 7.265.834 7.067 5.518-.09 2.114-1.613 4.22-3.903 4.31-.505.02-1.782.013-2.907-.978-1.991-1.753-1.294-3.997-1.407-3.77-.035.071-.495 1.202-.325 2.252.389 2.402 2.521 3.801 4.778 3.73 2.238-.071 5.293-1.83 5.596-5.124.444-4.821-2.896-7.309-5.366-7.681-7.445-1.123-6.885 2.914-6.885 2.914Z"
          />
        ),
      },
    },
    back: {
      base: (
        <path
          id="base"
          d="M335.284 288.262c4.001 0 7.25 3.249 7.25 7.25 0 4.001-3.249 7.25-7.25 7.25-4.002 0-7.25-3.249-7.25-7.25 0-4.001 3.248-7.25 7.25-7.25Zm0 3.625c2 0 3.625 1.624 3.625 3.625s-1.625 3.625-3.625 3.625c-2.001 0-3.625-1.624-3.625-3.625s1.624-3.625 3.625-3.625Z"
        />
      ),
      basic: {
        dark: (
          <path
            id="dark"
            d="M341.263 300.256c-.129.233-2.483 1.057-3.351 1.171-2.968.393-7.265-.834-7.067-5.518.089-2.114 1.613-4.219 3.903-4.31.505-.02 1.782-.012 2.907.978 1.99 1.753 1.293 3.997 1.407 3.77.035-.07.495-1.202.325-2.252-.389-2.402-2.521-3.801-4.778-3.73-2.238.071-4.439 1.367-5.712 3.191-2.769 3.97-.135 9.92 4.468 10.356 7.496.71 7.898-3.656 7.898-3.656Z"
          />
        ),
        light: (
          <path
            id="light"
            d="M329.554 290.745c.129-.233 2.484-1.056 3.352-1.171 2.968-.393 7.265.834 7.067 5.518-.09 2.114-1.613 4.22-3.903 4.31-.505.02-1.782.013-2.907-.978-1.991-1.753-1.294-3.997-1.407-3.77-.035.071-.495 1.202-.325 2.252.389 2.402 2.521 3.801 4.778 3.73 2.238-.071 5.293-1.83 5.596-5.124.444-4.821-2.896-7.309-5.366-7.681-7.445-1.123-6.885 2.914-6.885 2.914Z"
          />
        ),
      },
    },
  },
};

const shapeEar3 = {
  left: {
    front: {
      base: (
        <path
          id="base"
          d="M164.716 278.481c1.904.105 2.417-3.486 0-3.625-3.994-.229-7.25 3.249-7.25 7.25 0 4.002 3.625 2.001 3.625 0 0-2 1.628-3.735 3.625-3.625Z"
        />
      ),
      basic: {
        dark: (
          <path
            id="dark"
            d="M158.737 286.85c.129.233 2.483 1.057 3.351 1.172 2.968.392 7.265-.834 7.067-5.519-.089-2.114-1.613-4.219-3.903-4.31-.505-.02-1.782-.012-2.907.979-1.99 1.752-1.293 3.996-1.407 3.769-.035-.07-.495-1.202-.325-2.252.389-2.401 2.521-3.801 4.778-3.729 2.238.071 4.439 1.367 5.712 3.19 2.769 3.971.135 9.92-4.468 10.357-7.496.71-7.898-3.657-7.898-3.657Z"
          />
        ),
        light: (
          <path
            id="light"
            d="M170.446 277.339c-.129-.232-2.484-1.056-3.352-1.171-2.968-.392-7.265.834-7.067 5.518.09 2.115 1.613 4.22 3.903 4.311.505.02 1.782.012 2.907-.979 1.991-1.752 1.294-3.997 1.407-3.769.035.07.495 1.202.325 2.252-.389 2.401-2.521 3.801-4.778 3.729-2.238-.071-5.293-1.83-5.596-5.124-.444-4.821 2.896-7.309 5.366-7.681 7.445-1.122 6.885 2.914 6.885 2.914Z"
          />
        ),
      },
    },
    back: {
      base: (
        <path
          id="base"
          d="M164.716 274.856c-4.001 0-7.25 3.249-7.25 7.25 0 4.002 3.249 7.25 7.25 7.25 4.002 0 7.25-3.248 7.25-7.25 0-4.001-3.248-7.25-7.25-7.25Zm0 3.625c-2 0-3.625 1.625-3.625 3.625 0 2.001 1.625 3.625 3.625 3.625 2.001 0 3.625-1.624 3.625-3.625 0-2-1.624-3.625-3.625-3.625Z"
        />
      ),
      basic: {
        dark: (
          <path
            id="dark"
            d="M158.737 286.85c.129.233 2.483 1.057 3.351 1.172 2.968.392 7.265-.834 7.067-5.519-.089-2.114-1.613-4.219-3.903-4.31-.505-.02-1.782-.012-2.907.979-1.99 1.752-1.293 3.996-1.407 3.769-.035-.07-.495-1.202-.325-2.252.389-2.401 2.521-3.801 4.778-3.729 2.238.071 4.439 1.367 5.712 3.19 2.769 3.971.135 9.92-4.468 10.357-7.496.71-7.898-3.657-7.898-3.657Z"
          />
        ),
        light: (
          <path
            id="light"
            d="M170.446 277.339c-.129-.232-2.484-1.056-3.352-1.171-2.968-.392-7.265.834-7.067 5.518.09 2.115 1.613 4.22 3.903 4.311.505.02 1.782.012 2.907-.979 1.991-1.752 1.294-3.997 1.407-3.769.035.07.495 1.202.325 2.252-.389 2.401-2.521 3.801-4.778 3.729-2.238-.071-5.293-1.83-5.596-5.124-.444-4.821 2.896-7.309 5.366-7.681 7.445-1.122 6.885 2.914 6.885 2.914Z"
          />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path
          id="base"
          d="M335.284 278.481c-1.904.105-2.417-3.486 0-3.625 3.994-.229 7.25 3.249 7.25 7.25 0 4.002-3.625 2.001-3.625 0 0-2-1.628-3.735-3.625-3.625Z"
        />
      ),
      basic: {
        dark: (
          <path
            id="dark"
            d="M341.263 286.85c-.129.233-2.483 1.057-3.351 1.172-2.968.392-7.265-.834-7.067-5.519.089-2.114 1.613-4.219 3.903-4.31.505-.02 1.782-.012 2.907.979 1.99 1.752 1.293 3.996 1.407 3.769.035-.07.495-1.202.325-2.252-.389-2.401-2.521-3.801-4.778-3.729-2.238.071-4.439 1.367-5.712 3.19-2.769 3.971-.135 9.92 4.468 10.357 7.496.71 7.898-3.657 7.898-3.657Z"
          />
        ),
        light: (
          <path
            id="light"
            d="M329.554 277.339c.129-.232 2.484-1.056 3.352-1.171 2.968-.392 7.265.834 7.067 5.518-.09 2.115-1.613 4.22-3.903 4.311-.505.02-1.782.012-2.907-.979-1.991-1.752-1.294-3.997-1.407-3.769-.035.07-.495 1.202-.325 2.252.389 2.401 2.521 3.801 4.778 3.729 2.238-.071 5.293-1.83 5.596-5.124.444-4.821-2.896-7.309-5.366-7.681-7.445-1.122-6.885 2.914-6.885 2.914Z"
          />
        ),
      },
    },
    back: {
      base: (
        <path
          id="base"
          d="M335.284 274.856c4.001 0 7.25 3.249 7.25 7.25 0 4.002-3.249 7.25-7.25 7.25-4.002 0-7.25-3.248-7.25-7.25 0-4.001 3.248-7.25 7.25-7.25Zm0 3.625c2 0 3.625 1.625 3.625 3.625 0 2.001-1.625 3.625-3.625 3.625-2.001 0-3.625-1.624-3.625-3.625 0-2 1.624-3.625 3.625-3.625Z"
        />
      ),
      basic: {
        dark: (
          <path
            id="dark"
            d="M341.263 286.85c-.129.233-2.483 1.057-3.351 1.172-2.968.392-7.265-.834-7.067-5.519.089-2.114 1.613-4.219 3.903-4.31.505-.02 1.782-.012 2.907.979 1.99 1.752 1.293 3.996 1.407 3.769.035-.07.495-1.202.325-2.252-.389-2.401-2.521-3.801-4.778-3.729-2.238.071-4.439 1.367-5.712 3.19-2.769 3.971-.135 9.92 4.468 10.357 7.496.71 7.898-3.657 7.898-3.657Z"
          />
        ),
        light: (
          <path
            id="light"
            d="M329.554 277.339c.129-.232 2.484-1.056 3.352-1.171 2.968-.392 7.265.834 7.067 5.518-.09 2.115-1.613 4.22-3.903 4.311-.505.02-1.782.012-2.907-.979-1.991-1.752-1.294-3.997-1.407-3.769-.035.07-.495 1.202-.325 2.252.389 2.401 2.521 3.801 4.778 3.729 2.238-.071 5.293-1.83 5.596-5.124.444-4.821-2.896-7.309-5.366-7.681-7.445-1.122-6.885 2.914-6.885 2.914Z"
          />
        ),
      },
    },
  },
};

const shapeEar4 = {
  left: {
    front: {
      base: (
        <path d="M164.716,278.481C166.62,278.586 167.133,274.995 164.716,274.856C160.722,274.627 157.466,278.105 157.466,282.106C157.466,286.108 161.091,284.107 161.091,282.106C161.091,280.106 162.719,278.371 164.716,278.481Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,286.85C158.866,287.083 161.22,287.907 162.088,288.022C165.056,288.414 169.353,287.188 169.155,282.503C169.066,280.389 167.542,278.284 165.252,278.193C164.747,278.173 163.47,278.181 162.345,279.172C160.355,280.924 161.052,283.168 160.938,282.941C160.903,282.871 160.443,281.739 160.613,280.689C161.002,278.288 163.134,276.888 165.391,276.96C167.629,277.031 169.83,278.327 171.103,280.15C173.872,284.121 171.238,290.07 166.635,290.507C159.139,291.217 158.737,286.85 158.737,286.85Z" />
        ),
        light: (
          <path d="M170.446,277.339C170.317,277.107 167.962,276.283 167.094,276.168C164.126,275.776 159.829,277.002 160.027,281.686C160.117,283.801 161.64,285.906 163.93,285.997C164.435,286.017 165.712,286.009 166.837,285.018C168.828,283.266 168.131,281.021 168.244,281.249C168.279,281.319 168.739,282.451 168.569,283.501C168.18,285.902 166.048,287.302 163.791,287.23C161.553,287.159 158.498,285.4 158.195,282.106C157.751,277.285 161.091,274.797 163.561,274.425C171.006,273.303 170.446,277.339 170.446,277.339Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,274.856C160.715,274.856 157.466,278.105 157.466,282.106C157.466,286.108 160.715,289.356 164.716,289.356C168.718,289.356 171.966,286.108 171.966,282.106C171.966,278.105 168.718,274.856 164.716,274.856ZM164.716,278.481C162.716,278.481 161.091,280.106 161.091,282.106C161.091,284.107 162.716,285.731 164.716,285.731C166.717,285.731 168.341,284.107 168.341,282.106C168.341,280.106 166.717,278.481 164.716,278.481Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,286.85C158.866,287.083 161.22,287.907 162.088,288.022C165.056,288.414 169.353,287.188 169.155,282.503C169.066,280.389 167.542,278.284 165.252,278.193C164.747,278.173 163.47,278.181 162.345,279.172C160.355,280.924 161.052,283.168 160.938,282.941C160.903,282.871 160.443,281.739 160.613,280.689C161.002,278.288 163.134,276.888 165.391,276.96C167.629,277.031 169.83,278.327 171.103,280.15C173.872,284.121 171.238,290.07 166.635,290.507C159.139,291.217 158.737,286.85 158.737,286.85Z" />
        ),
        light: (
          <path d="M170.446,277.339C170.317,277.107 167.962,276.283 167.094,276.168C164.126,275.776 159.829,277.002 160.027,281.686C160.117,283.801 161.64,285.906 163.93,285.997C164.435,286.017 165.712,286.009 166.837,285.018C168.828,283.266 168.131,281.021 168.244,281.249C168.279,281.319 168.739,282.451 168.569,283.501C168.18,285.902 166.048,287.302 163.791,287.23C161.553,287.159 158.498,285.4 158.195,282.106C157.751,277.285 161.091,274.797 163.561,274.425C171.006,273.303 170.446,277.339 170.446,277.339Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.284,278.481C333.38,278.586 332.867,274.995 335.284,274.856C339.278,274.627 342.534,278.105 342.534,282.106C342.534,286.108 338.909,284.107 338.909,282.106C338.909,280.106 337.281,278.371 335.284,278.481Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,286.85C341.134,287.083 338.78,287.907 337.912,288.022C334.944,288.414 330.647,287.188 330.845,282.503C330.934,280.389 332.458,278.284 334.748,278.193C335.253,278.173 336.53,278.181 337.655,279.172C339.645,280.924 338.948,283.168 339.062,282.941C339.097,282.871 339.557,281.739 339.387,280.689C338.998,278.288 336.866,276.888 334.609,276.96C332.371,277.031 330.17,278.327 328.897,280.15C326.128,284.121 328.762,290.07 333.365,290.507C340.861,291.217 341.263,286.85 341.263,286.85Z" />
        ),
        light: (
          <path d="M329.554,277.339C329.683,277.107 332.038,276.283 332.906,276.168C335.874,275.776 340.171,277.002 339.973,281.686C339.883,283.801 338.36,285.906 336.07,285.997C335.565,286.017 334.288,286.009 333.163,285.018C331.172,283.266 331.869,281.021 331.756,281.249C331.721,281.319 331.261,282.451 331.431,283.501C331.82,285.902 333.952,287.302 336.209,287.23C338.447,287.159 341.502,285.4 341.805,282.106C342.249,277.285 338.909,274.797 336.439,274.425C328.994,273.303 329.554,277.339 329.554,277.339Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.284,274.856C339.285,274.856 342.534,278.105 342.534,282.106C342.534,286.108 339.285,289.356 335.284,289.356C331.282,289.356 328.034,286.108 328.034,282.106C328.034,278.105 331.282,274.856 335.284,274.856ZM335.284,278.481C337.284,278.481 338.909,280.106 338.909,282.106C338.909,284.107 337.284,285.731 335.284,285.731C333.283,285.731 331.659,284.107 331.659,282.106C331.659,280.106 333.283,278.481 335.284,278.481Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,286.85C341.134,287.083 338.78,287.907 337.912,288.022C334.944,288.414 330.647,287.188 330.845,282.503C330.934,280.389 332.458,278.284 334.748,278.193C335.253,278.173 336.53,278.181 337.655,279.172C339.645,280.924 338.948,283.168 339.062,282.941C339.097,282.871 339.557,281.739 339.387,280.689C338.998,278.288 336.866,276.888 334.609,276.96C332.371,277.031 330.17,278.327 328.897,280.15C326.128,284.121 328.762,290.07 333.365,290.507C340.861,291.217 341.263,286.85 341.263,286.85Z" />
        ),
        light: (
          <path d="M329.554,277.339C329.683,277.107 332.038,276.283 332.906,276.168C335.874,275.776 340.171,277.002 339.973,281.686C339.883,283.801 338.36,285.906 336.07,285.997C335.565,286.017 334.288,286.009 333.163,285.018C331.172,283.266 331.869,281.021 331.756,281.249C331.721,281.319 331.261,282.451 331.431,283.501C331.82,285.902 333.952,287.302 336.209,287.23C338.447,287.159 341.502,285.4 341.805,282.106C342.249,277.285 338.909,274.797 336.439,274.425C328.994,273.303 329.554,277.339 329.554,277.339Z" />
        ),
      },
    },
  },
};

const shapeEar5 = {
  left: {
    front: {
      base: (
        <path d="M164.716,283.817C166.62,283.922 167.133,280.331 164.716,280.192C160.722,279.963 157.466,283.441 157.466,287.442C157.466,291.443 161.091,289.443 161.091,287.442C161.091,285.441 162.719,283.707 164.716,283.817Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,292.186C158.866,292.419 161.22,293.243 162.088,293.358C165.056,293.75 169.353,292.523 169.155,287.839C169.066,285.725 167.542,283.62 165.252,283.529C164.747,283.509 163.47,283.517 162.345,284.508C160.355,286.26 161.052,288.504 160.938,288.277C160.903,288.207 160.443,287.075 160.613,286.025C161.002,283.624 163.134,282.224 165.391,282.295C167.629,282.366 169.83,283.662 171.103,285.486C173.872,289.456 171.238,295.406 166.635,295.842C159.139,296.552 158.737,292.186 158.737,292.186Z" />
        ),
        light: (
          <path d="M170.446,282.675C170.317,282.442 167.962,281.619 167.094,281.504C164.126,281.111 159.829,282.338 160.027,287.022C160.117,289.136 161.64,291.242 163.93,291.332C164.435,291.352 165.712,291.345 166.837,290.354C168.828,288.601 168.131,286.357 168.244,286.584C168.279,286.655 168.739,287.787 168.569,288.837C168.18,291.238 166.048,292.638 163.791,292.566C161.553,292.495 158.498,290.736 158.195,287.442C157.751,282.621 161.091,280.133 163.561,279.761C171.006,278.638 170.446,282.675 170.446,282.675Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,280.192C160.715,280.192 157.466,283.441 157.466,287.442C157.466,291.443 160.715,294.692 164.716,294.692C168.718,294.692 171.966,291.443 171.966,287.442C171.966,283.441 168.718,280.192 164.716,280.192ZM164.716,283.817C162.716,283.817 161.091,285.441 161.091,287.442C161.091,289.443 162.716,291.067 164.716,291.067C166.717,291.067 168.341,289.443 168.341,287.442C168.341,285.441 166.717,283.817 164.716,283.817Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,292.186C158.866,292.419 161.22,293.243 162.088,293.358C165.056,293.75 169.353,292.523 169.155,287.839C169.066,285.725 167.542,283.62 165.252,283.529C164.747,283.509 163.47,283.517 162.345,284.508C160.355,286.26 161.052,288.504 160.938,288.277C160.903,288.207 160.443,287.075 160.613,286.025C161.002,283.624 163.134,282.224 165.391,282.295C167.629,282.366 169.83,283.662 171.103,285.486C173.872,289.456 171.238,295.406 166.635,295.842C159.139,296.552 158.737,292.186 158.737,292.186Z" />
        ),
        light: (
          <path d="M170.446,282.675C170.317,282.442 167.962,281.619 167.094,281.504C164.126,281.111 159.829,282.338 160.027,287.022C160.117,289.136 161.64,291.242 163.93,291.332C164.435,291.352 165.712,291.345 166.837,290.354C168.828,288.601 168.131,286.357 168.244,286.584C168.279,286.655 168.739,287.787 168.569,288.837C168.18,291.238 166.048,292.638 163.791,292.566C161.553,292.495 158.498,290.736 158.195,287.442C157.751,282.621 161.091,280.133 163.561,279.761C171.006,278.638 170.446,282.675 170.446,282.675Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.284,283.817C333.38,283.922 332.867,280.331 335.284,280.192C339.278,279.963 342.534,283.441 342.534,287.442C342.534,291.443 338.909,289.443 338.909,287.442C338.909,285.441 337.281,283.707 335.284,283.817Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,292.186C341.134,292.419 338.78,293.243 337.912,293.358C334.944,293.75 330.647,292.523 330.845,287.839C330.934,285.725 332.458,283.62 334.748,283.529C335.253,283.509 336.53,283.517 337.655,284.508C339.645,286.26 338.948,288.504 339.062,288.277C339.097,288.207 339.557,287.075 339.387,286.025C338.998,283.624 336.866,282.224 334.609,282.295C332.371,282.366 330.17,283.662 328.897,285.486C326.128,289.456 328.762,295.406 333.365,295.842C340.861,296.552 341.263,292.186 341.263,292.186Z" />
        ),
        light: (
          <path d="M329.554,282.675C329.683,282.442 332.038,281.619 332.906,281.504C335.874,281.111 340.171,282.338 339.973,287.022C339.883,289.136 338.36,291.242 336.07,291.332C335.565,291.352 334.288,291.345 333.163,290.354C331.172,288.601 331.869,286.357 331.756,286.584C331.721,286.655 331.261,287.787 331.431,288.837C331.82,291.238 333.952,292.638 336.209,292.566C338.447,292.495 341.502,290.736 341.805,287.442C342.249,282.621 338.909,280.133 336.439,279.761C328.994,278.638 329.554,282.675 329.554,282.675Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.284,280.192C339.285,280.192 342.534,283.441 342.534,287.442C342.534,291.443 339.285,294.692 335.284,294.692C331.282,294.692 328.034,291.443 328.034,287.442C328.034,283.441 331.282,280.192 335.284,280.192ZM335.284,283.817C337.284,283.817 338.909,285.441 338.909,287.442C338.909,289.443 337.284,291.067 335.284,291.067C333.283,291.067 331.659,289.443 331.659,287.442C331.659,285.441 333.283,283.817 335.284,283.817Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,292.186C341.134,292.419 338.78,293.243 337.912,293.358C334.944,293.75 330.647,292.523 330.845,287.839C330.934,285.725 332.458,283.62 334.748,283.529C335.253,283.509 336.53,283.517 337.655,284.508C339.645,286.26 338.948,288.504 339.062,288.277C339.097,288.207 339.557,287.075 339.387,286.025C338.998,283.624 336.866,282.224 334.609,282.295C332.371,282.366 330.17,283.662 328.897,285.486C326.128,289.456 328.762,295.406 333.365,295.842C340.861,296.552 341.263,292.186 341.263,292.186Z" />
        ),
        light: (
          <path d="M329.554,282.675C329.683,282.442 332.038,281.619 332.906,281.504C335.874,281.111 340.171,282.338 339.973,287.022C339.883,289.136 338.36,291.242 336.07,291.332C335.565,291.352 334.288,291.345 333.163,290.354C331.172,288.601 331.869,286.357 331.756,286.584C331.721,286.655 331.261,287.787 331.431,288.837C331.82,291.238 333.952,292.638 336.209,292.566C338.447,292.495 341.502,290.736 341.805,287.442C342.249,282.621 338.909,280.133 336.439,279.761C328.994,278.638 329.554,282.675 329.554,282.675Z" />
        ),
      },
    },
  },
};

const shapeEar6 = {
  left: {
    front: {
      base: (
        <path d="M164.716,274.461C166.62,274.566 167.133,270.975 164.716,270.836C160.722,270.607 157.466,274.085 157.466,278.086C157.466,282.087 161.091,280.087 161.091,278.086C161.091,276.085 162.719,274.351 164.716,274.461Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,282.83C158.866,283.063 161.22,283.887 162.088,284.001C165.056,284.394 169.353,283.167 169.155,278.483C169.066,276.369 167.542,274.264 165.252,274.173C164.747,274.153 163.47,274.161 162.345,275.151C160.355,276.904 161.052,279.148 160.938,278.921C160.903,278.851 160.443,277.719 160.613,276.669C161.002,274.267 163.134,272.868 165.391,272.939C167.629,273.01 169.83,274.306 171.103,276.13C173.872,280.1 171.238,286.05 166.635,286.486C159.139,287.196 158.737,282.83 158.737,282.83Z" />
        ),
        light: (
          <path d="M170.446,273.319C170.317,273.086 167.962,272.263 167.094,272.148C164.126,271.755 159.829,272.982 160.027,277.666C160.117,279.78 161.64,281.886 163.93,281.976C164.435,281.996 165.712,281.989 166.837,280.998C168.828,279.245 168.131,277.001 168.244,277.228C168.279,277.299 168.739,278.43 168.569,279.48C168.18,281.882 166.048,283.281 163.791,283.21C161.553,283.139 158.498,281.38 158.195,278.086C157.751,273.265 161.091,270.777 163.561,270.405C171.006,269.282 170.446,273.319 170.446,273.319Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,270.836C160.715,270.836 157.466,274.085 157.466,278.086C157.466,282.087 160.715,285.336 164.716,285.336C168.718,285.336 171.966,282.087 171.966,278.086C171.966,274.085 168.718,270.836 164.716,270.836ZM164.716,274.461C162.716,274.461 161.091,276.085 161.091,278.086C161.091,280.087 162.716,281.711 164.716,281.711C166.717,281.711 168.341,280.087 168.341,278.086C168.341,276.085 166.717,274.461 164.716,274.461Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,282.83C158.866,283.063 161.22,283.887 162.088,284.001C165.056,284.394 169.353,283.167 169.155,278.483C169.066,276.369 167.542,274.264 165.252,274.173C164.747,274.153 163.47,274.161 162.345,275.151C160.355,276.904 161.052,279.148 160.938,278.921C160.903,278.851 160.443,277.719 160.613,276.669C161.002,274.267 163.134,272.868 165.391,272.939C167.629,273.01 169.83,274.306 171.103,276.13C173.872,280.1 171.238,286.05 166.635,286.486C159.139,287.196 158.737,282.83 158.737,282.83Z" />
        ),
        light: (
          <path d="M170.446,273.319C170.317,273.086 167.962,272.263 167.094,272.148C164.126,271.755 159.829,272.982 160.027,277.666C160.117,279.78 161.64,281.886 163.93,281.976C164.435,281.996 165.712,281.989 166.837,280.998C168.828,279.245 168.131,277.001 168.244,277.228C168.279,277.299 168.739,278.43 168.569,279.48C168.18,281.882 166.048,283.281 163.791,283.21C161.553,283.139 158.498,281.38 158.195,278.086C157.751,273.265 161.091,270.777 163.561,270.405C171.006,269.282 170.446,273.319 170.446,273.319Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.284,274.461C333.38,274.566 332.867,270.975 335.284,270.836C339.278,270.607 342.534,274.085 342.534,278.086C342.534,282.087 338.909,280.087 338.909,278.086C338.909,276.085 337.281,274.351 335.284,274.461Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,282.83C341.134,283.063 338.78,283.887 337.912,284.001C334.944,284.394 330.647,283.167 330.845,278.483C330.934,276.369 332.458,274.264 334.748,274.173C335.253,274.153 336.53,274.161 337.655,275.151C339.645,276.904 338.948,279.148 339.062,278.921C339.097,278.851 339.557,277.719 339.387,276.669C338.998,274.267 336.866,272.868 334.609,272.939C332.371,273.01 330.17,274.306 328.897,276.13C326.128,280.1 328.762,286.05 333.365,286.486C340.861,287.196 341.263,282.83 341.263,282.83Z" />
        ),
        light: (
          <path d="M329.554,273.319C329.683,273.086 332.038,272.263 332.906,272.148C335.874,271.755 340.171,272.982 339.973,277.666C339.883,279.78 338.36,281.886 336.07,281.976C335.565,281.996 334.288,281.989 333.163,280.998C331.172,279.245 331.869,277.001 331.756,277.228C331.721,277.299 331.261,278.43 331.431,279.48C331.82,281.882 333.952,283.281 336.209,283.21C338.447,283.139 341.502,281.38 341.805,278.086C342.249,273.265 338.909,270.777 336.439,270.405C328.994,269.282 329.554,273.319 329.554,273.319Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.284,270.836C339.285,270.836 342.534,274.085 342.534,278.086C342.534,282.087 339.285,285.336 335.284,285.336C331.282,285.336 328.034,282.087 328.034,278.086C328.034,274.085 331.282,270.836 335.284,270.836ZM335.284,274.461C337.284,274.461 338.909,276.085 338.909,278.086C338.909,280.087 337.284,281.711 335.284,281.711C333.283,281.711 331.659,280.087 331.659,278.086C331.659,276.085 333.283,274.461 335.284,274.461Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,282.83C341.134,283.063 338.78,283.887 337.912,284.001C334.944,284.394 330.647,283.167 330.845,278.483C330.934,276.369 332.458,274.264 334.748,274.173C335.253,274.153 336.53,274.161 337.655,275.151C339.645,276.904 338.948,279.148 339.062,278.921C339.097,278.851 339.557,277.719 339.387,276.669C338.998,274.267 336.866,272.868 334.609,272.939C332.371,273.01 330.17,274.306 328.897,276.13C326.128,280.1 328.762,286.05 333.365,286.486C340.861,287.196 341.263,282.83 341.263,282.83Z" />
        ),
        light: (
          <path d="M329.554,273.319C329.683,273.086 332.038,272.263 332.906,272.148C335.874,271.755 340.171,272.982 339.973,277.666C339.883,279.78 338.36,281.886 336.07,281.976C335.565,281.996 334.288,281.989 333.163,280.998C331.172,279.245 331.869,277.001 331.756,277.228C331.721,277.299 331.261,278.43 331.431,279.48C331.82,281.882 333.952,283.281 336.209,283.21C338.447,283.139 341.502,281.38 341.805,278.086C342.249,273.265 338.909,270.777 336.439,270.405C328.994,269.282 329.554,273.319 329.554,273.319Z" />
        ),
      },
    },
  },
};

const shapeEar7 = {
  left: {
    front: {
      base: (
        <path d="M164.716,274.461C166.62,274.566 167.133,270.975 164.716,270.836C160.722,270.607 157.466,274.085 157.466,278.086C157.466,282.087 161.091,280.087 161.091,278.086C161.091,276.085 162.719,274.351 164.716,274.461Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,282.83C158.866,283.063 161.22,283.887 162.088,284.001C165.056,284.394 169.353,283.167 169.155,278.483C169.066,276.369 167.542,274.264 165.252,274.173C164.747,274.153 163.47,274.161 162.345,275.151C160.355,276.904 161.052,279.148 160.938,278.921C160.903,278.851 160.443,277.719 160.613,276.669C161.002,274.267 163.134,272.868 165.391,272.939C167.629,273.01 169.83,274.306 171.103,276.13C173.872,280.1 171.238,286.05 166.635,286.486C159.139,287.196 158.737,282.83 158.737,282.83Z" />
        ),
        light: (
          <path d="M170.446,273.319C170.317,273.086 167.962,272.263 167.094,272.148C164.126,271.755 159.829,272.982 160.027,277.666C160.117,279.78 161.64,281.886 163.93,281.976C164.435,281.996 165.712,281.989 166.837,280.998C168.828,279.245 168.131,277.001 168.244,277.228C168.279,277.299 168.739,278.43 168.569,279.48C168.18,281.882 166.048,283.281 163.791,283.21C161.553,283.139 158.498,281.38 158.195,278.086C157.751,273.265 161.091,270.777 163.561,270.405C171.006,269.282 170.446,273.319 170.446,273.319Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,270.836C160.715,270.836 157.466,274.085 157.466,278.086C157.466,282.087 160.715,285.336 164.716,285.336C168.718,285.336 171.966,282.087 171.966,278.086C171.966,274.085 168.718,270.836 164.716,270.836ZM164.716,274.461C162.716,274.461 161.091,276.085 161.091,278.086C161.091,280.087 162.716,281.711 164.716,281.711C166.717,281.711 168.341,280.087 168.341,278.086C168.341,276.085 166.717,274.461 164.716,274.461Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,282.83C158.866,283.063 161.22,283.887 162.088,284.001C165.056,284.394 169.353,283.167 169.155,278.483C169.066,276.369 167.542,274.264 165.252,274.173C164.747,274.153 163.47,274.161 162.345,275.151C160.355,276.904 161.052,279.148 160.938,278.921C160.903,278.851 160.443,277.719 160.613,276.669C161.002,274.267 163.134,272.868 165.391,272.939C167.629,273.01 169.83,274.306 171.103,276.13C173.872,280.1 171.238,286.05 166.635,286.486C159.139,287.196 158.737,282.83 158.737,282.83Z" />
        ),
        light: (
          <path d="M170.446,273.319C170.317,273.086 167.962,272.263 167.094,272.148C164.126,271.755 159.829,272.982 160.027,277.666C160.117,279.78 161.64,281.886 163.93,281.976C164.435,281.996 165.712,281.989 166.837,280.998C168.828,279.245 168.131,277.001 168.244,277.228C168.279,277.299 168.739,278.43 168.569,279.48C168.18,281.882 166.048,283.281 163.791,283.21C161.553,283.139 158.498,281.38 158.195,278.086C157.751,273.265 161.091,270.777 163.561,270.405C171.006,269.282 170.446,273.319 170.446,273.319Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.284,274.461C333.38,274.566 332.867,270.975 335.284,270.836C339.278,270.607 342.534,274.085 342.534,278.086C342.534,282.087 338.909,280.087 338.909,278.086C338.909,276.085 337.281,274.351 335.284,274.461Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,282.83C341.134,283.063 338.78,283.887 337.912,284.001C334.944,284.394 330.647,283.167 330.845,278.483C330.934,276.369 332.458,274.264 334.748,274.173C335.253,274.153 336.53,274.161 337.655,275.151C339.645,276.904 338.948,279.148 339.062,278.921C339.097,278.851 339.557,277.719 339.387,276.669C338.998,274.267 336.866,272.868 334.609,272.939C332.371,273.01 330.17,274.306 328.897,276.13C326.128,280.1 328.762,286.05 333.365,286.486C340.861,287.196 341.263,282.83 341.263,282.83Z" />
        ),
        light: (
          <path d="M329.554,273.319C329.683,273.086 332.038,272.263 332.906,272.148C335.874,271.755 340.171,272.982 339.973,277.666C339.883,279.78 338.36,281.886 336.07,281.976C335.565,281.996 334.288,281.989 333.163,280.998C331.172,279.245 331.869,277.001 331.756,277.228C331.721,277.299 331.261,278.43 331.431,279.48C331.82,281.882 333.952,283.281 336.209,283.21C338.447,283.139 341.502,281.38 341.805,278.086C342.249,273.265 338.909,270.777 336.439,270.405C328.994,269.282 329.554,273.319 329.554,273.319Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.284,270.836C339.285,270.836 342.534,274.085 342.534,278.086C342.534,282.087 339.285,285.336 335.284,285.336C331.282,285.336 328.034,282.087 328.034,278.086C328.034,274.085 331.282,270.836 335.284,270.836ZM335.284,274.461C337.284,274.461 338.909,276.085 338.909,278.086C338.909,280.087 337.284,281.711 335.284,281.711C333.283,281.711 331.659,280.087 331.659,278.086C331.659,276.085 333.283,274.461 335.284,274.461Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,282.83C341.134,283.063 338.78,283.887 337.912,284.001C334.944,284.394 330.647,283.167 330.845,278.483C330.934,276.369 332.458,274.264 334.748,274.173C335.253,274.153 336.53,274.161 337.655,275.151C339.645,276.904 338.948,279.148 339.062,278.921C339.097,278.851 339.557,277.719 339.387,276.669C338.998,274.267 336.866,272.868 334.609,272.939C332.371,273.01 330.17,274.306 328.897,276.13C326.128,280.1 328.762,286.05 333.365,286.486C340.861,287.196 341.263,282.83 341.263,282.83Z" />
        ),
        light: (
          <path d="M329.554,273.319C329.683,273.086 332.038,272.263 332.906,272.148C335.874,271.755 340.171,272.982 339.973,277.666C339.883,279.78 338.36,281.886 336.07,281.976C335.565,281.996 334.288,281.989 333.163,280.998C331.172,279.245 331.869,277.001 331.756,277.228C331.721,277.299 331.261,278.43 331.431,279.48C331.82,281.882 333.952,283.281 336.209,283.21C338.447,283.139 341.502,281.38 341.805,278.086C342.249,273.265 338.909,270.777 336.439,270.405C328.994,269.282 329.554,273.319 329.554,273.319Z" />
        ),
      },
    },
  },
};

const shapeEar8 = {
  left: {
    front: {
      base: (
        <path d="M150.093,290.441C151.997,290.546 152.51,286.955 150.093,286.816C146.098,286.587 142.843,290.065 142.843,294.066C142.843,298.067 146.468,296.067 146.468,294.066C146.468,292.065 148.095,290.331 150.093,290.441Z" />
      ),
      basic: {
        dark: (
          <path d="M144.113,298.81C144.242,299.043 146.597,299.867 147.464,299.981C150.433,300.374 154.73,299.147 154.532,294.463C154.442,292.349 152.919,290.244 150.629,290.153C150.124,290.133 148.847,290.141 147.721,291.131C145.731,292.884 146.428,295.128 146.314,294.901C146.279,294.831 145.82,293.699 145.99,292.649C146.378,290.247 148.511,288.848 150.767,288.919C153.006,288.99 155.207,290.286 156.479,292.11C159.249,296.08 156.615,302.03 152.011,302.466C144.516,303.176 144.113,298.81 144.113,298.81Z" />
        ),
        light: (
          <path d="M155.822,289.299C155.693,289.066 153.339,288.243 152.471,288.128C149.502,287.735 145.205,288.962 145.404,293.646C145.493,295.76 147.016,297.866 149.306,297.956C149.812,297.976 151.089,297.969 152.214,296.978C154.204,295.225 153.507,292.981 153.621,293.208C153.656,293.279 154.115,294.41 153.945,295.46C153.557,297.862 151.425,299.261 149.168,299.19C146.929,299.119 143.874,297.36 143.571,294.066C143.128,289.245 146.467,286.757 148.937,286.385C156.382,285.262 155.822,289.299 155.822,289.299Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M150.093,286.816C146.092,286.816 142.843,290.065 142.843,294.066C142.843,298.067 146.092,301.316 150.093,301.316C154.094,301.316 157.343,298.067 157.343,294.066C157.343,290.065 154.094,286.816 150.093,286.816ZM150.093,290.441C148.092,290.441 146.468,292.065 146.468,294.066C146.468,296.067 148.092,297.691 150.093,297.691C152.094,297.691 153.718,296.067 153.718,294.066C153.718,292.065 152.094,290.441 150.093,290.441Z" />
      ),
      basic: {
        dark: (
          <path d="M144.113,298.81C144.242,299.043 146.597,299.867 147.464,299.981C150.433,300.374 154.73,299.147 154.532,294.463C154.442,292.349 152.919,290.244 150.629,290.153C150.124,290.133 148.847,290.141 147.721,291.131C145.731,292.884 146.428,295.128 146.314,294.901C146.279,294.831 145.82,293.699 145.99,292.649C146.378,290.247 148.511,288.848 150.767,288.919C153.006,288.99 155.207,290.286 156.479,292.11C159.249,296.08 156.615,302.03 152.011,302.466C144.516,303.176 144.113,298.81 144.113,298.81Z" />
        ),
        light: (
          <path d="M155.822,289.299C155.693,289.066 153.339,288.243 152.471,288.128C149.502,287.735 145.205,288.962 145.404,293.646C145.493,295.76 147.016,297.866 149.306,297.956C149.812,297.976 151.089,297.969 152.214,296.978C154.204,295.225 153.507,292.981 153.621,293.208C153.656,293.279 154.115,294.41 153.945,295.46C153.557,297.862 151.425,299.261 149.168,299.19C146.929,299.119 143.874,297.36 143.571,294.066C143.128,289.245 146.467,286.757 148.937,286.385C156.382,285.262 155.822,289.299 155.822,289.299Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M349.907,290.441C348.003,290.546 347.49,286.955 349.907,286.816C353.902,286.587 357.157,290.065 357.157,294.066C357.157,298.067 353.532,296.067 353.532,294.066C353.532,292.065 351.905,290.331 349.907,290.441Z" />
      ),
      basic: {
        dark: (
          <path d="M355.887,298.81C355.758,299.043 353.403,299.867 352.536,299.981C349.567,300.374 345.27,299.147 345.468,294.463C345.558,292.349 347.081,290.244 349.371,290.153C349.876,290.133 351.153,290.141 352.279,291.131C354.269,292.884 353.572,295.128 353.686,294.901C353.721,294.831 354.18,293.699 354.01,292.649C353.622,290.247 351.489,288.848 349.233,288.919C346.994,288.99 344.793,290.286 343.521,292.11C340.751,296.08 343.385,302.03 347.989,302.466C355.484,303.176 355.887,298.81 355.887,298.81Z" />
        ),
        light: (
          <path d="M344.178,289.299C344.307,289.066 346.661,288.243 347.529,288.128C350.498,287.735 354.795,288.962 354.596,293.646C354.507,295.76 352.984,297.866 350.694,297.956C350.188,297.976 348.911,297.969 347.786,296.978C345.796,295.225 346.493,292.981 346.379,293.208C346.344,293.279 345.885,294.41 346.055,295.46C346.443,297.862 348.575,299.261 350.832,299.19C353.071,299.119 356.126,297.36 356.429,294.066C356.872,289.245 353.533,286.757 351.063,286.385C343.618,285.262 344.178,289.299 344.178,289.299Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M349.907,286.816C353.908,286.816 357.157,290.065 357.157,294.066C357.157,298.067 353.908,301.316 349.907,301.316C345.906,301.316 342.657,298.067 342.657,294.066C342.657,290.065 345.906,286.816 349.907,286.816ZM349.907,290.441C351.908,290.441 353.532,292.065 353.532,294.066C353.532,296.067 351.908,297.691 349.907,297.691C347.906,297.691 346.282,296.067 346.282,294.066C346.282,292.065 347.906,290.441 349.907,290.441Z" />
      ),
      basic: {
        dark: (
          <path d="M355.887,298.81C355.758,299.043 353.403,299.867 352.536,299.981C349.567,300.374 345.27,299.147 345.468,294.463C345.558,292.349 347.081,290.244 349.371,290.153C349.876,290.133 351.153,290.141 352.279,291.131C354.269,292.884 353.572,295.128 353.686,294.901C353.721,294.831 354.18,293.699 354.01,292.649C353.622,290.247 351.489,288.848 349.233,288.919C346.994,288.99 344.793,290.286 343.521,292.11C340.751,296.08 343.385,302.03 347.989,302.466C355.484,303.176 355.887,298.81 355.887,298.81Z" />
        ),
        light: (
          <path d="M344.178,289.299C344.307,289.066 346.661,288.243 347.529,288.128C350.498,287.735 354.795,288.962 354.596,293.646C354.507,295.76 352.984,297.866 350.694,297.956C350.188,297.976 348.911,297.969 347.786,296.978C345.796,295.225 346.493,292.981 346.379,293.208C346.344,293.279 345.885,294.41 346.055,295.46C346.443,297.862 348.575,299.261 350.832,299.19C353.071,299.119 356.126,297.36 356.429,294.066C356.872,289.245 353.533,286.757 351.063,286.385C343.618,285.262 344.178,289.299 344.178,289.299Z" />
        ),
      },
    },
  },
};

const shapeEar9 = {
  left: {
    front: {
      base: (
        <path d="M168.129,203.351C170.033,203.456 170.545,199.865 168.129,199.726C164.134,199.497 160.879,202.975 160.879,206.976C160.879,210.978 164.504,208.977 164.504,206.976C164.504,204.976 166.131,203.241 168.129,203.351Z" />
      ),
      basic: {
        dark: (
          <path d="M162.149,211.721C162.278,211.954 164.632,212.777 165.5,212.892C168.469,213.284 172.766,212.058 172.568,207.374C172.478,205.259 170.955,203.154 168.665,203.064C168.159,203.044 166.882,203.051 165.757,204.042C163.767,205.794 164.464,208.039 164.35,207.811C164.315,207.741 163.856,206.609 164.026,205.559C164.414,203.158 166.547,201.758 168.803,201.83C171.042,201.901 173.243,203.197 174.515,205.021C177.285,208.991 174.651,214.941 170.047,215.377C162.552,216.087 162.149,211.721 162.149,211.721Z" />
        ),
        light: (
          <path d="M173.858,202.21C173.729,201.977 171.375,201.153 170.507,201.038C167.538,200.646 163.241,201.872 163.44,206.557C163.529,208.671 165.052,210.776 167.342,210.867C167.848,210.887 169.125,210.879 170.25,209.888C172.24,208.136 171.543,205.892 171.657,206.119C171.692,206.189 172.151,207.321 171.981,208.371C171.593,210.772 169.461,212.172 167.204,212.1C164.965,212.03 161.91,210.271 161.607,206.976C161.164,202.156 164.503,199.667 166.973,199.295C174.418,198.173 173.858,202.21 173.858,202.21Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M168.129,199.726C164.127,199.726 160.879,202.975 160.879,206.976C160.879,210.978 164.127,214.226 168.129,214.226C172.13,214.226 175.379,210.978 175.379,206.976C175.379,202.975 172.13,199.726 168.129,199.726ZM168.129,203.351C166.128,203.351 164.504,204.976 164.504,206.976C164.504,208.977 166.128,210.601 168.129,210.601C170.13,210.601 171.754,208.977 171.754,206.976C171.754,204.976 170.13,203.351 168.129,203.351Z" />
      ),
      basic: {
        dark: (
          <path d="M162.149,211.721C162.278,211.954 164.632,212.777 165.5,212.892C168.469,213.284 172.766,212.058 172.568,207.374C172.478,205.259 170.955,203.154 168.665,203.064C168.159,203.044 166.882,203.051 165.757,204.042C163.767,205.794 164.464,208.039 164.35,207.811C164.315,207.741 163.856,206.609 164.026,205.559C164.414,203.158 166.547,201.758 168.803,201.83C171.042,201.901 173.243,203.197 174.515,205.021C177.285,208.991 174.651,214.941 170.047,215.377C162.552,216.087 162.149,211.721 162.149,211.721Z" />
        ),
        light: (
          <path d="M173.858,202.21C173.729,201.977 171.375,201.153 170.507,201.038C167.538,200.646 163.241,201.872 163.44,206.557C163.529,208.671 165.052,210.776 167.342,210.867C167.848,210.887 169.125,210.879 170.25,209.888C172.24,208.136 171.543,205.892 171.657,206.119C171.692,206.189 172.151,207.321 171.981,208.371C171.593,210.772 169.461,212.172 167.204,212.1C164.965,212.03 161.91,210.271 161.607,206.976C161.164,202.156 164.503,199.667 166.973,199.295C174.418,198.173 173.858,202.21 173.858,202.21Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M331.871,203.351C329.967,203.456 329.455,199.865 331.871,199.726C335.866,199.497 339.121,202.975 339.121,206.976C339.121,210.978 335.496,208.977 335.496,206.976C335.496,204.976 333.869,203.241 331.871,203.351Z" />
      ),
      basic: {
        dark: (
          <path d="M337.851,211.721C337.722,211.954 335.368,212.777 334.5,212.892C331.531,213.284 327.234,212.058 327.432,207.374C327.522,205.259 329.045,203.154 331.335,203.064C331.841,203.044 333.118,203.051 334.243,204.042C336.233,205.794 335.536,208.039 335.65,207.811C335.685,207.741 336.144,206.609 335.974,205.559C335.586,203.158 333.453,201.758 331.197,201.83C328.958,201.901 326.757,203.197 325.485,205.021C322.715,208.991 325.349,214.941 329.953,215.377C337.448,216.087 337.851,211.721 337.851,211.721Z" />
        ),
        light: (
          <path d="M326.142,202.21C326.271,201.977 328.625,201.153 329.493,201.038C332.462,200.646 336.759,201.872 336.56,206.557C336.471,208.671 334.948,210.776 332.658,210.867C332.152,210.887 330.875,210.879 329.75,209.888C327.76,208.136 328.457,205.892 328.343,206.119C328.308,206.189 327.849,207.321 328.019,208.371C328.407,210.772 330.539,212.172 332.796,212.1C335.035,212.03 338.09,210.271 338.393,206.976C338.836,202.156 335.497,199.667 333.027,199.295C325.582,198.173 326.142,202.21 326.142,202.21Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M331.871,199.726C335.873,199.726 339.121,202.975 339.121,206.976C339.121,210.978 335.873,214.226 331.871,214.226C327.87,214.226 324.621,210.978 324.621,206.976C324.621,202.975 327.87,199.726 331.871,199.726ZM331.871,203.351C333.872,203.351 335.496,204.976 335.496,206.976C335.496,208.977 333.872,210.601 331.871,210.601C329.87,210.601 328.246,208.977 328.246,206.976C328.246,204.976 329.87,203.351 331.871,203.351Z" />
      ),
      basic: {
        dark: (
          <path d="M337.851,211.721C337.722,211.954 335.368,212.777 334.5,212.892C331.531,213.284 327.234,212.058 327.432,207.374C327.522,205.259 329.045,203.154 331.335,203.064C331.841,203.044 333.118,203.051 334.243,204.042C336.233,205.794 335.536,208.039 335.65,207.811C335.685,207.741 336.144,206.609 335.974,205.559C335.586,203.158 333.453,201.758 331.197,201.83C328.958,201.901 326.757,203.197 325.485,205.021C322.715,208.991 325.349,214.941 329.953,215.377C337.448,216.087 337.851,211.721 337.851,211.721Z" />
        ),
        light: (
          <path d="M326.142,202.21C326.271,201.977 328.625,201.153 329.493,201.038C332.462,200.646 336.759,201.872 336.56,206.557C336.471,208.671 334.948,210.776 332.658,210.867C332.152,210.887 330.875,210.879 329.75,209.888C327.76,208.136 328.457,205.892 328.343,206.119C328.308,206.189 327.849,207.321 328.019,208.371C328.407,210.772 330.539,212.172 332.796,212.1C335.035,212.03 338.09,210.271 338.393,206.976C338.836,202.156 335.497,199.667 333.027,199.295C325.582,198.173 326.142,202.21 326.142,202.21Z" />
        ),
      },
    },
  },
};

const shapeEar10 = {
  left: {
    front: {
      base: (
        <path d="M168.129,204.861C170.033,204.966 170.545,201.375 168.129,201.236C164.134,201.006 160.879,204.484 160.879,208.486C160.879,212.487 164.504,210.487 164.504,208.486C164.504,206.485 166.131,204.751 168.129,204.861Z" />
      ),
      basic: {
        dark: (
          <path d="M162.149,213.23C162.278,213.463 164.632,214.287 165.5,214.401C168.469,214.794 172.766,213.567 172.568,208.883C172.478,206.769 170.955,204.664 168.665,204.573C168.159,204.553 166.882,204.561 165.757,205.551C163.767,207.304 164.464,209.548 164.35,209.321C164.315,209.251 163.856,208.119 164.026,207.069C164.414,204.667 166.547,203.268 168.803,203.339C171.042,203.41 173.243,204.706 174.515,206.53C177.285,210.5 174.651,216.45 170.047,216.886C162.552,217.596 162.149,213.23 162.149,213.23Z" />
        ),
        light: (
          <path d="M173.858,203.719C173.729,203.486 171.375,202.662 170.507,202.548C167.538,202.155 163.241,203.382 163.44,208.066C163.529,210.18 165.052,212.286 167.342,212.376C167.848,212.396 169.125,212.389 170.25,211.398C172.24,209.645 171.543,207.401 171.657,207.628C171.692,207.698 172.151,208.83 171.981,209.88C171.593,212.282 169.461,213.681 167.204,213.61C164.965,213.539 161.91,211.78 161.607,208.486C161.164,203.665 164.503,201.177 166.973,200.804C174.418,199.682 173.858,203.719 173.858,203.719Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M168.129,201.236C164.127,201.236 160.879,204.484 160.879,208.486C160.879,212.487 164.127,215.736 168.129,215.736C172.13,215.736 175.379,212.487 175.379,208.486C175.379,204.484 172.13,201.236 168.129,201.236ZM168.129,204.861C166.128,204.861 164.504,206.485 164.504,208.486C164.504,210.487 166.128,212.111 168.129,212.111C170.13,212.111 171.754,210.487 171.754,208.486C171.754,206.485 170.13,204.861 168.129,204.861Z" />
      ),
      basic: {
        dark: (
          <path d="M162.149,213.23C162.278,213.463 164.632,214.287 165.5,214.401C168.469,214.794 172.766,213.567 172.568,208.883C172.478,206.769 170.955,204.664 168.665,204.573C168.159,204.553 166.882,204.561 165.757,205.551C163.767,207.304 164.464,209.548 164.35,209.321C164.315,209.251 163.856,208.119 164.026,207.069C164.414,204.667 166.547,203.268 168.803,203.339C171.042,203.41 173.243,204.706 174.515,206.53C177.285,210.5 174.651,216.45 170.047,216.886C162.552,217.596 162.149,213.23 162.149,213.23Z" />
        ),
        light: (
          <path d="M173.858,203.719C173.729,203.486 171.375,202.662 170.507,202.548C167.538,202.155 163.241,203.382 163.44,208.066C163.529,210.18 165.052,212.286 167.342,212.376C167.848,212.396 169.125,212.389 170.25,211.398C172.24,209.645 171.543,207.401 171.657,207.628C171.692,207.698 172.151,208.83 171.981,209.88C171.593,212.282 169.461,213.681 167.204,213.61C164.965,213.539 161.91,211.78 161.607,208.486C161.164,203.665 164.503,201.177 166.973,200.804C174.418,199.682 173.858,203.719 173.858,203.719Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M331.871,204.861C329.967,204.966 329.455,201.375 331.871,201.236C335.866,201.006 339.121,204.484 339.121,208.486C339.121,212.487 335.496,210.487 335.496,208.486C335.496,206.485 333.869,204.751 331.871,204.861Z" />
      ),
      basic: {
        dark: (
          <path d="M337.851,213.23C337.722,213.463 335.368,214.287 334.5,214.401C331.531,214.794 327.234,213.567 327.432,208.883C327.522,206.769 329.045,204.664 331.335,204.573C331.841,204.553 333.118,204.561 334.243,205.551C336.233,207.304 335.536,209.548 335.65,209.321C335.685,209.251 336.144,208.119 335.974,207.069C335.586,204.667 333.453,203.268 331.197,203.339C328.958,203.41 326.757,204.706 325.485,206.53C322.715,210.5 325.349,216.45 329.953,216.886C337.448,217.596 337.851,213.23 337.851,213.23Z" />
        ),
        light: (
          <path d="M326.142,203.719C326.271,203.486 328.625,202.662 329.493,202.548C332.462,202.155 336.759,203.382 336.56,208.066C336.471,210.18 334.948,212.286 332.658,212.376C332.152,212.396 330.875,212.389 329.75,211.398C327.76,209.645 328.457,207.401 328.343,207.628C328.308,207.698 327.849,208.83 328.019,209.88C328.407,212.282 330.539,213.681 332.796,213.61C335.035,213.539 338.09,211.78 338.393,208.486C338.836,203.665 335.497,201.177 333.027,200.804C325.582,199.682 326.142,203.719 326.142,203.719Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M331.871,201.236C335.873,201.236 339.121,204.484 339.121,208.486C339.121,212.487 335.873,215.736 331.871,215.736C327.87,215.736 324.621,212.487 324.621,208.486C324.621,204.484 327.87,201.236 331.871,201.236ZM331.871,204.861C333.872,204.861 335.496,206.485 335.496,208.486C335.496,210.487 333.872,212.111 331.871,212.111C329.87,212.111 328.246,210.487 328.246,208.486C328.246,206.485 329.87,204.861 331.871,204.861Z" />
      ),
      basic: {
        dark: (
          <path d="M337.851,213.23C337.722,213.463 335.368,214.287 334.5,214.401C331.531,214.794 327.234,213.567 327.432,208.883C327.522,206.769 329.045,204.664 331.335,204.573C331.841,204.553 333.118,204.561 334.243,205.551C336.233,207.304 335.536,209.548 335.65,209.321C335.685,209.251 336.144,208.119 335.974,207.069C335.586,204.667 333.453,203.268 331.197,203.339C328.958,203.41 326.757,204.706 325.485,206.53C322.715,210.5 325.349,216.45 329.953,216.886C337.448,217.596 337.851,213.23 337.851,213.23Z" />
        ),
        light: (
          <path d="M326.142,203.719C326.271,203.486 328.625,202.662 329.493,202.548C332.462,202.155 336.759,203.382 336.56,208.066C336.471,210.18 334.948,212.286 332.658,212.376C332.152,212.396 330.875,212.389 329.75,211.398C327.76,209.645 328.457,207.401 328.343,207.628C328.308,207.698 327.849,208.83 328.019,209.88C328.407,212.282 330.539,213.681 332.796,213.61C335.035,213.539 338.09,211.78 338.393,208.486C338.836,203.665 335.497,201.177 333.027,200.804C325.582,199.682 326.142,203.719 326.142,203.719Z" />
        ),
      },
    },
  },
};

const shapeEar11 = {
  left: {
    front: {
      base: (
        <path d="M164.716,272.788C166.62,272.893 167.133,269.302 164.716,269.163C160.722,268.934 157.466,272.412 157.466,276.413C157.466,280.415 161.091,278.414 161.091,276.413C161.091,274.412 162.719,272.678 164.716,272.788Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,281.157C158.866,281.39 161.22,282.214 162.088,282.329C165.056,282.721 169.353,281.495 169.155,276.81C169.066,274.696 167.542,272.591 165.252,272.5C164.747,272.48 163.47,272.488 162.345,273.479C160.355,275.231 161.052,277.475 160.938,277.248C160.903,277.178 160.443,276.046 160.613,274.996C161.002,272.595 163.134,271.195 165.391,271.267C167.629,271.338 169.83,272.634 171.103,274.457C173.872,278.428 171.238,284.377 166.635,284.813C159.139,285.524 158.737,281.157 158.737,281.157Z" />
        ),
        light: (
          <path d="M170.446,271.646C170.317,271.414 167.962,270.59 167.094,270.475C164.126,270.083 159.829,271.309 160.027,275.993C160.117,278.108 161.64,280.213 163.93,280.304C164.435,280.324 165.712,280.316 166.837,279.325C168.828,277.573 168.131,275.328 168.244,275.556C168.279,275.626 168.739,276.758 168.569,277.808C168.18,280.209 166.048,281.609 163.791,281.537C161.553,281.466 158.498,279.707 158.195,276.413C157.751,271.592 161.091,269.104 163.561,268.732C171.006,267.609 170.446,271.646 170.446,271.646Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,269.163C160.715,269.163 157.466,272.412 157.466,276.413C157.466,280.415 160.715,283.663 164.716,283.663C168.718,283.663 171.966,280.415 171.966,276.413C171.966,272.412 168.718,269.163 164.716,269.163ZM164.716,272.788C162.716,272.788 161.091,274.412 161.091,276.413C161.091,278.414 162.716,280.038 164.716,280.038C166.717,280.038 168.341,278.414 168.341,276.413C168.341,274.412 166.717,272.788 164.716,272.788Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,281.157C158.866,281.39 161.22,282.214 162.088,282.329C165.056,282.721 169.353,281.495 169.155,276.81C169.066,274.696 167.542,272.591 165.252,272.5C164.747,272.48 163.47,272.488 162.345,273.479C160.355,275.231 161.052,277.475 160.938,277.248C160.903,277.178 160.443,276.046 160.613,274.996C161.002,272.595 163.134,271.195 165.391,271.267C167.629,271.338 169.83,272.634 171.103,274.457C173.872,278.428 171.238,284.377 166.635,284.813C159.139,285.524 158.737,281.157 158.737,281.157Z" />
        ),
        light: (
          <path d="M170.446,271.646C170.317,271.414 167.962,270.59 167.094,270.475C164.126,270.083 159.829,271.309 160.027,275.993C160.117,278.108 161.64,280.213 163.93,280.304C164.435,280.324 165.712,280.316 166.837,279.325C168.828,277.573 168.131,275.328 168.244,275.556C168.279,275.626 168.739,276.758 168.569,277.808C168.18,280.209 166.048,281.609 163.791,281.537C161.553,281.466 158.498,279.707 158.195,276.413C157.751,271.592 161.091,269.104 163.561,268.732C171.006,267.609 170.446,271.646 170.446,271.646Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.284,272.788C333.38,272.893 332.867,269.302 335.284,269.163C339.278,268.934 342.534,272.412 342.534,276.413C342.534,280.415 338.909,278.414 338.909,276.413C338.909,274.412 337.281,272.678 335.284,272.788Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,281.157C341.134,281.39 338.78,282.214 337.912,282.329C334.944,282.721 330.647,281.495 330.845,276.81C330.934,274.696 332.458,272.591 334.748,272.5C335.253,272.48 336.53,272.488 337.655,273.479C339.645,275.231 338.948,277.475 339.062,277.248C339.097,277.178 339.557,276.046 339.387,274.996C338.998,272.595 336.866,271.195 334.609,271.267C332.371,271.338 330.17,272.634 328.897,274.457C326.128,278.428 328.762,284.377 333.365,284.813C340.861,285.524 341.263,281.157 341.263,281.157Z" />
        ),
        light: (
          <path d="M329.554,271.646C329.683,271.414 332.038,270.59 332.906,270.475C335.874,270.083 340.171,271.309 339.973,275.993C339.883,278.108 338.36,280.213 336.07,280.304C335.565,280.324 334.288,280.316 333.163,279.325C331.172,277.573 331.869,275.328 331.756,275.556C331.721,275.626 331.261,276.758 331.431,277.808C331.82,280.209 333.952,281.609 336.209,281.537C338.447,281.466 341.502,279.707 341.805,276.413C342.249,271.592 338.909,269.104 336.439,268.732C328.994,267.609 329.554,271.646 329.554,271.646Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.284,269.163C339.285,269.163 342.534,272.412 342.534,276.413C342.534,280.415 339.285,283.663 335.284,283.663C331.282,283.663 328.034,280.415 328.034,276.413C328.034,272.412 331.282,269.163 335.284,269.163ZM335.284,272.788C337.284,272.788 338.909,274.412 338.909,276.413C338.909,278.414 337.284,280.038 335.284,280.038C333.283,280.038 331.659,278.414 331.659,276.413C331.659,274.412 333.283,272.788 335.284,272.788Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,281.157C341.134,281.39 338.78,282.214 337.912,282.329C334.944,282.721 330.647,281.495 330.845,276.81C330.934,274.696 332.458,272.591 334.748,272.5C335.253,272.48 336.53,272.488 337.655,273.479C339.645,275.231 338.948,277.475 339.062,277.248C339.097,277.178 339.557,276.046 339.387,274.996C338.998,272.595 336.866,271.195 334.609,271.267C332.371,271.338 330.17,272.634 328.897,274.457C326.128,278.428 328.762,284.377 333.365,284.813C340.861,285.524 341.263,281.157 341.263,281.157Z" />
        ),
        light: (
          <path d="M329.554,271.646C329.683,271.414 332.038,270.59 332.906,270.475C335.874,270.083 340.171,271.309 339.973,275.993C339.883,278.108 338.36,280.213 336.07,280.304C335.565,280.324 334.288,280.316 333.163,279.325C331.172,277.573 331.869,275.328 331.756,275.556C331.721,275.626 331.261,276.758 331.431,277.808C331.82,280.209 333.952,281.609 336.209,281.537C338.447,281.466 341.502,279.707 341.805,276.413C342.249,271.592 338.909,269.104 336.439,268.732C328.994,267.609 329.554,271.646 329.554,271.646Z" />
        ),
      },
    },
  },
};

const shapeEar12 = {
  left: {
    front: {
      base: (
        <path d="M164.716,270.349C166.62,270.454 167.133,266.863 164.716,266.724C160.722,266.494 157.466,269.972 157.466,273.974C157.466,277.975 161.091,275.974 161.091,273.974C161.091,271.973 162.719,270.239 164.716,270.349Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,278.718C158.866,278.951 161.22,279.775 162.088,279.889C165.056,280.282 169.353,279.055 169.155,274.371C169.066,272.257 167.542,270.151 165.252,270.061C164.747,270.041 163.47,270.048 162.345,271.039C160.355,272.792 161.052,275.036 160.938,274.809C160.903,274.738 160.443,273.607 160.613,272.557C161.002,270.155 163.134,268.756 165.391,268.827C167.629,268.898 169.83,270.194 171.103,272.018C173.872,275.988 171.238,281.938 166.635,282.374C159.139,283.084 158.737,278.718 158.737,278.718Z" />
        ),
        light: (
          <path d="M170.446,269.207C170.317,268.974 167.962,268.15 167.094,268.036C164.126,267.643 159.829,268.87 160.027,273.554C160.117,275.668 161.64,277.773 163.93,277.864C164.435,277.884 165.712,277.876 166.837,276.886C168.828,275.133 168.131,272.889 168.244,273.116C168.279,273.186 168.739,274.318 168.569,275.368C168.18,277.77 166.048,279.169 163.791,279.098C161.553,279.027 158.498,277.268 158.195,273.974C157.751,269.153 161.091,266.665 163.561,266.292C171.006,265.17 170.446,269.207 170.446,269.207Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,266.724C160.715,266.724 157.466,269.972 157.466,273.974C157.466,277.975 160.715,281.224 164.716,281.224C168.718,281.224 171.966,277.975 171.966,273.974C171.966,269.972 168.718,266.724 164.716,266.724ZM164.716,270.349C162.716,270.349 161.091,271.973 161.091,273.974C161.091,275.974 162.716,277.599 164.716,277.599C166.717,277.599 168.341,275.974 168.341,273.974C168.341,271.973 166.717,270.349 164.716,270.349Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,278.718C158.866,278.951 161.22,279.775 162.088,279.889C165.056,280.282 169.353,279.055 169.155,274.371C169.066,272.257 167.542,270.151 165.252,270.061C164.747,270.041 163.47,270.048 162.345,271.039C160.355,272.792 161.052,275.036 160.938,274.809C160.903,274.738 160.443,273.607 160.613,272.557C161.002,270.155 163.134,268.756 165.391,268.827C167.629,268.898 169.83,270.194 171.103,272.018C173.872,275.988 171.238,281.938 166.635,282.374C159.139,283.084 158.737,278.718 158.737,278.718Z" />
        ),
        light: (
          <path d="M170.446,269.207C170.317,268.974 167.962,268.15 167.094,268.036C164.126,267.643 159.829,268.87 160.027,273.554C160.117,275.668 161.64,277.773 163.93,277.864C164.435,277.884 165.712,277.876 166.837,276.886C168.828,275.133 168.131,272.889 168.244,273.116C168.279,273.186 168.739,274.318 168.569,275.368C168.18,277.77 166.048,279.169 163.791,279.098C161.553,279.027 158.498,277.268 158.195,273.974C157.751,269.153 161.091,266.665 163.561,266.292C171.006,265.17 170.446,269.207 170.446,269.207Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.284,270.349C333.38,270.454 332.867,266.863 335.284,266.724C339.278,266.494 342.534,269.972 342.534,273.974C342.534,277.975 338.909,275.974 338.909,273.974C338.909,271.973 337.281,270.239 335.284,270.349Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,278.718C341.134,278.951 338.78,279.775 337.912,279.889C334.944,280.282 330.647,279.055 330.845,274.371C330.934,272.257 332.458,270.151 334.748,270.061C335.253,270.041 336.53,270.048 337.655,271.039C339.645,272.792 338.948,275.036 339.062,274.809C339.097,274.738 339.557,273.607 339.387,272.557C338.998,270.155 336.866,268.756 334.609,268.827C332.371,268.898 330.17,270.194 328.897,272.018C326.128,275.988 328.762,281.938 333.365,282.374C340.861,283.084 341.263,278.718 341.263,278.718Z" />
        ),
        light: (
          <path d="M329.554,269.207C329.683,268.974 332.038,268.15 332.906,268.036C335.874,267.643 340.171,268.87 339.973,273.554C339.883,275.668 338.36,277.773 336.07,277.864C335.565,277.884 334.288,277.876 333.163,276.886C331.172,275.133 331.869,272.889 331.756,273.116C331.721,273.186 331.261,274.318 331.431,275.368C331.82,277.77 333.952,279.169 336.209,279.098C338.447,279.027 341.502,277.268 341.805,273.974C342.249,269.153 338.909,266.665 336.439,266.292C328.994,265.17 329.554,269.207 329.554,269.207Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.284,266.724C339.285,266.724 342.534,269.972 342.534,273.974C342.534,277.975 339.285,281.224 335.284,281.224C331.282,281.224 328.034,277.975 328.034,273.974C328.034,269.972 331.282,266.724 335.284,266.724ZM335.284,270.349C337.284,270.349 338.909,271.973 338.909,273.974C338.909,275.974 337.284,277.599 335.284,277.599C333.283,277.599 331.659,275.974 331.659,273.974C331.659,271.973 333.283,270.349 335.284,270.349Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,278.718C341.134,278.951 338.78,279.775 337.912,279.889C334.944,280.282 330.647,279.055 330.845,274.371C330.934,272.257 332.458,270.151 334.748,270.061C335.253,270.041 336.53,270.048 337.655,271.039C339.645,272.792 338.948,275.036 339.062,274.809C339.097,274.738 339.557,273.607 339.387,272.557C338.998,270.155 336.866,268.756 334.609,268.827C332.371,268.898 330.17,270.194 328.897,272.018C326.128,275.988 328.762,281.938 333.365,282.374C340.861,283.084 341.263,278.718 341.263,278.718Z" />
        ),
        light: (
          <path d="M329.554,269.207C329.683,268.974 332.038,268.15 332.906,268.036C335.874,267.643 340.171,268.87 339.973,273.554C339.883,275.668 338.36,277.773 336.07,277.864C335.565,277.884 334.288,277.876 333.163,276.886C331.172,275.133 331.869,272.889 331.756,273.116C331.721,273.186 331.261,274.318 331.431,275.368C331.82,277.77 333.952,279.169 336.209,279.098C338.447,279.027 341.502,277.268 341.805,273.974C342.249,269.153 338.909,266.665 336.439,266.292C328.994,265.17 329.554,269.207 329.554,269.207Z" />
        ),
      },
    },
  },
};

const shapeEar13 = {
  left: {
    front: {
      base: (
        <path d="M163.583,282.432C165.487,282.537 166,278.945 163.583,278.807C159.589,278.577 156.333,282.055 156.333,286.057C156.333,290.058 159.958,288.057 159.958,286.057C159.958,284.056 161.586,282.322 163.583,282.432Z" />
      ),
      basic: {
        dark: (
          <path d="M157.604,290.801C157.733,291.034 160.087,291.857 160.955,291.972C163.923,292.365 168.22,291.138 168.022,286.454C167.933,284.34 166.409,282.234 164.119,282.144C163.614,282.124 162.337,282.131 161.212,283.122C159.222,284.875 159.919,287.119 159.805,286.892C159.77,286.821 159.31,285.69 159.48,284.64C159.869,282.238 162.001,280.839 164.258,280.91C166.496,280.981 168.697,282.277 169.97,284.101C172.739,288.071 170.105,294.021 165.502,294.457C158.006,295.167 157.604,290.801 157.604,290.801Z" />
        ),
        light: (
          <path d="M169.313,281.29C169.184,281.057 166.829,280.233 165.961,280.119C162.993,279.726 158.696,280.953 158.894,285.637C158.984,287.751 160.507,289.856 162.797,289.947C163.302,289.967 164.579,289.959 165.704,288.969C167.695,287.216 166.998,284.972 167.111,285.199C167.146,285.269 167.606,286.401 167.436,287.451C167.047,289.852 164.915,291.252 162.658,291.181C160.42,291.11 157.365,289.351 157.062,286.057C156.618,281.236 159.958,278.748 162.428,278.375C169.873,277.253 169.313,281.29 169.313,281.29Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M163.583,278.807C159.582,278.807 156.333,282.055 156.333,286.057C156.333,290.058 159.582,293.307 163.583,293.307C167.585,293.307 170.833,290.058 170.833,286.057C170.833,282.055 167.585,278.807 163.583,278.807ZM163.583,282.432C161.583,282.432 159.958,284.056 159.958,286.057C159.958,288.057 161.583,289.682 163.583,289.682C165.584,289.682 167.208,288.057 167.208,286.057C167.208,284.056 165.584,282.432 163.583,282.432Z" />
      ),
      basic: {
        dark: (
          <path d="M157.604,290.801C157.733,291.034 160.087,291.857 160.955,291.972C163.923,292.365 168.22,291.138 168.022,286.454C167.933,284.34 166.409,282.234 164.119,282.144C163.614,282.124 162.337,282.131 161.212,283.122C159.222,284.875 159.919,287.119 159.805,286.892C159.77,286.821 159.31,285.69 159.48,284.64C159.869,282.238 162.001,280.839 164.258,280.91C166.496,280.981 168.697,282.277 169.97,284.101C172.739,288.071 170.105,294.021 165.502,294.457C158.006,295.167 157.604,290.801 157.604,290.801Z" />
        ),
        light: (
          <path d="M169.313,281.29C169.184,281.057 166.829,280.233 165.961,280.119C162.993,279.726 158.696,280.953 158.894,285.637C158.984,287.751 160.507,289.856 162.797,289.947C163.302,289.967 164.579,289.959 165.704,288.969C167.695,287.216 166.998,284.972 167.111,285.199C167.146,285.269 167.606,286.401 167.436,287.451C167.047,289.852 164.915,291.252 162.658,291.181C160.42,291.11 157.365,289.351 157.062,286.057C156.618,281.236 159.958,278.748 162.428,278.375C169.873,277.253 169.313,281.29 169.313,281.29Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M336.417,282.432C334.513,282.537 334,278.945 336.417,278.807C340.411,278.577 343.667,282.055 343.667,286.057C343.667,290.058 340.042,288.057 340.042,286.057C340.042,284.056 338.414,282.322 336.417,282.432Z" />
      ),
      basic: {
        dark: (
          <path d="M342.396,290.801C342.267,291.034 339.913,291.857 339.045,291.972C336.077,292.365 331.78,291.138 331.978,286.454C332.067,284.34 333.591,282.234 335.881,282.144C336.386,282.124 337.663,282.131 338.788,283.122C340.778,284.875 340.081,287.119 340.195,286.892C340.23,286.821 340.69,285.69 340.52,284.64C340.131,282.238 337.999,280.839 335.742,280.91C333.504,280.981 331.303,282.277 330.03,284.101C327.261,288.071 329.895,294.021 334.498,294.457C341.994,295.167 342.396,290.801 342.396,290.801Z" />
        ),
        light: (
          <path d="M330.687,281.29C330.816,281.057 333.171,280.233 334.039,280.119C337.007,279.726 341.304,280.953 341.106,285.637C341.016,287.751 339.493,289.856 337.203,289.947C336.698,289.967 335.421,289.959 334.296,288.969C332.305,287.216 333.002,284.972 332.889,285.199C332.854,285.269 332.394,286.401 332.564,287.451C332.953,289.852 335.085,291.252 337.342,291.181C339.58,291.11 342.635,289.351 342.938,286.057C343.382,281.236 340.042,278.748 337.572,278.375C330.127,277.253 330.687,281.29 330.687,281.29Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M336.417,278.807C340.418,278.807 343.667,282.055 343.667,286.057C343.667,290.058 340.418,293.307 336.417,293.307C332.415,293.307 329.167,290.058 329.167,286.057C329.167,282.055 332.415,278.807 336.417,278.807ZM336.417,282.432C338.417,282.432 340.042,284.056 340.042,286.057C340.042,288.057 338.417,289.682 336.417,289.682C334.416,289.682 332.792,288.057 332.792,286.057C332.792,284.056 334.416,282.432 336.417,282.432Z" />
      ),
      basic: {
        dark: (
          <path d="M342.396,290.801C342.267,291.034 339.913,291.857 339.045,291.972C336.077,292.365 331.78,291.138 331.978,286.454C332.067,284.34 333.591,282.234 335.881,282.144C336.386,282.124 337.663,282.131 338.788,283.122C340.778,284.875 340.081,287.119 340.195,286.892C340.23,286.821 340.69,285.69 340.52,284.64C340.131,282.238 337.999,280.839 335.742,280.91C333.504,280.981 331.303,282.277 330.03,284.101C327.261,288.071 329.895,294.021 334.498,294.457C341.994,295.167 342.396,290.801 342.396,290.801Z" />
        ),
        light: (
          <path d="M330.687,281.29C330.816,281.057 333.171,280.233 334.039,280.119C337.007,279.726 341.304,280.953 341.106,285.637C341.016,287.751 339.493,289.856 337.203,289.947C336.698,289.967 335.421,289.959 334.296,288.969C332.305,287.216 333.002,284.972 332.889,285.199C332.854,285.269 332.394,286.401 332.564,287.451C332.953,289.852 335.085,291.252 337.342,291.181C339.58,291.11 342.635,289.351 342.938,286.057C343.382,281.236 340.042,278.748 337.572,278.375C330.127,277.253 330.687,281.29 330.687,281.29Z" />
        ),
      },
    },
  },
};

const shapeEar14 = {
  left: {
    front: {
      base: (
        <path d="M164.716,280.951C166.62,281.056 167.133,277.465 164.716,277.326C160.722,277.097 157.466,280.575 157.466,284.576C157.466,288.577 161.091,286.577 161.091,284.576C161.091,282.575 162.719,280.841 164.716,280.951Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,289.32C158.866,289.553 161.22,290.377 162.088,290.492C165.056,290.884 169.353,289.657 169.155,284.973C169.066,282.859 167.542,280.754 165.252,280.663C164.747,280.643 163.47,280.651 162.345,281.642C160.355,283.394 161.052,285.638 160.938,285.411C160.903,285.341 160.443,284.209 160.613,283.159C161.002,280.758 163.134,279.358 165.391,279.429C167.629,279.5 169.83,280.796 171.103,282.62C173.872,286.59 171.238,292.54 166.635,292.976C159.139,293.686 158.737,289.32 158.737,289.32Z" />
        ),
        light: (
          <path d="M170.446,279.809C170.317,279.576 167.962,278.753 167.094,278.638C164.126,278.245 159.829,279.472 160.027,284.156C160.117,286.27 161.64,288.376 163.93,288.466C164.435,288.486 165.712,288.479 166.837,287.488C168.828,285.735 168.131,283.491 168.244,283.718C168.279,283.789 168.739,284.92 168.569,285.971C168.18,288.372 166.048,289.771 163.791,289.7C161.553,289.629 158.498,287.87 158.195,284.576C157.751,279.755 161.091,277.267 163.561,276.895C171.006,275.772 170.446,279.809 170.446,279.809Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,277.326C160.715,277.326 157.466,280.575 157.466,284.576C157.466,288.577 160.715,291.826 164.716,291.826C168.718,291.826 171.966,288.577 171.966,284.576C171.966,280.575 168.718,277.326 164.716,277.326ZM164.716,280.951C162.716,280.951 161.091,282.575 161.091,284.576C161.091,286.577 162.716,288.201 164.716,288.201C166.717,288.201 168.341,286.577 168.341,284.576C168.341,282.575 166.717,280.951 164.716,280.951Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,289.32C158.866,289.553 161.22,290.377 162.088,290.492C165.056,290.884 169.353,289.657 169.155,284.973C169.066,282.859 167.542,280.754 165.252,280.663C164.747,280.643 163.47,280.651 162.345,281.642C160.355,283.394 161.052,285.638 160.938,285.411C160.903,285.341 160.443,284.209 160.613,283.159C161.002,280.758 163.134,279.358 165.391,279.429C167.629,279.5 169.83,280.796 171.103,282.62C173.872,286.59 171.238,292.54 166.635,292.976C159.139,293.686 158.737,289.32 158.737,289.32Z" />
        ),
        light: (
          <path d="M170.446,279.809C170.317,279.576 167.962,278.753 167.094,278.638C164.126,278.245 159.829,279.472 160.027,284.156C160.117,286.27 161.64,288.376 163.93,288.466C164.435,288.486 165.712,288.479 166.837,287.488C168.828,285.735 168.131,283.491 168.244,283.718C168.279,283.789 168.739,284.92 168.569,285.971C168.18,288.372 166.048,289.771 163.791,289.7C161.553,289.629 158.498,287.87 158.195,284.576C157.751,279.755 161.091,277.267 163.561,276.895C171.006,275.772 170.446,279.809 170.446,279.809Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.321,280.951C333.417,281.056 332.904,277.465 335.321,277.326C339.316,277.097 342.571,280.575 342.571,284.576C342.571,288.577 338.946,286.577 338.946,284.576C338.946,282.575 337.319,280.841 335.321,280.951Z" />
      ),
      basic: {
        dark: (
          <path d="M341.301,289.32C341.172,289.553 338.817,290.377 337.949,290.492C334.981,290.884 330.684,289.657 330.882,284.973C330.972,282.859 332.495,280.754 334.785,280.663C335.29,280.643 336.567,280.651 337.692,281.642C339.683,283.394 338.986,285.638 339.099,285.411C339.134,285.341 339.594,284.209 339.424,283.159C339.036,280.758 336.903,279.358 334.646,279.429C332.408,279.5 330.207,280.796 328.935,282.62C326.165,286.59 328.799,292.54 333.403,292.976C340.898,293.686 341.301,289.32 341.301,289.32Z" />
        ),
        light: (
          <path d="M329.592,279.809C329.721,279.576 332.075,278.753 332.943,278.638C335.911,278.245 340.208,279.472 340.01,284.156C339.921,286.27 338.397,288.376 336.107,288.466C335.602,288.486 334.325,288.479 333.2,287.488C331.21,285.735 331.907,283.491 331.793,283.718C331.758,283.789 331.298,284.92 331.468,285.971C331.857,288.372 333.989,289.771 336.246,289.7C338.484,289.629 341.54,287.87 341.843,284.576C342.286,279.755 338.947,277.267 336.476,276.895C329.032,275.772 329.592,279.809 329.592,279.809Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.321,277.326C339.322,277.326 342.571,280.575 342.571,284.576C342.571,288.577 339.322,291.826 335.321,291.826C331.319,291.826 328.071,288.577 328.071,284.576C328.071,280.575 331.319,277.326 335.321,277.326ZM335.321,280.951C337.322,280.951 338.946,282.575 338.946,284.576C338.946,286.577 337.322,288.201 335.321,288.201C333.32,288.201 331.696,286.577 331.696,284.576C331.696,282.575 333.32,280.951 335.321,280.951Z" />
      ),
      basic: {
        dark: (
          <path d="M341.301,289.32C341.172,289.553 338.817,290.377 337.949,290.492C334.981,290.884 330.684,289.657 330.882,284.973C330.972,282.859 332.495,280.754 334.785,280.663C335.29,280.643 336.567,280.651 337.692,281.642C339.683,283.394 338.986,285.638 339.099,285.411C339.134,285.341 339.594,284.209 339.424,283.159C339.036,280.758 336.903,279.358 334.646,279.429C332.408,279.5 330.207,280.796 328.935,282.62C326.165,286.59 328.799,292.54 333.403,292.976C340.898,293.686 341.301,289.32 341.301,289.32Z" />
        ),
        light: (
          <path d="M329.592,279.809C329.721,279.576 332.075,278.753 332.943,278.638C335.911,278.245 340.208,279.472 340.01,284.156C339.921,286.27 338.397,288.376 336.107,288.466C335.602,288.486 334.325,288.479 333.2,287.488C331.21,285.735 331.907,283.491 331.793,283.718C331.758,283.789 331.298,284.92 331.468,285.971C331.857,288.372 333.989,289.771 336.246,289.7C338.484,289.629 341.54,287.87 341.843,284.576C342.286,279.755 338.947,277.267 336.476,276.895C329.032,275.772 329.592,279.809 329.592,279.809Z" />
        ),
      },
    },
  },
};

const shapeEar15 = {
  left: {
    front: {
      base: (
        <path d="M158.572,283.206C160.476,283.311 160.989,279.72 158.572,279.581C154.578,279.351 151.322,282.829 151.322,286.831C151.322,290.832 154.947,288.831 154.947,286.831C154.947,284.83 156.575,283.096 158.572,283.206Z" />
      ),
      basic: {
        dark: (
          <path d="M152.592,291.575C152.721,291.808 155.076,292.632 155.944,292.746C158.912,293.139 163.209,291.912 163.011,287.228C162.922,285.114 161.398,283.008 159.108,282.918C158.603,282.898 157.326,282.905 156.201,283.896C154.21,285.649 154.907,287.893 154.794,287.666C154.759,287.595 154.299,286.464 154.469,285.414C154.858,283.012 156.99,281.613 159.247,281.684C161.485,281.755 163.686,283.051 164.958,284.875C167.728,288.845 165.094,294.795 160.49,295.231C152.995,295.941 152.592,291.575 152.592,291.575Z" />
        ),
        light: (
          <path d="M164.302,282.064C164.173,281.831 161.818,281.007 160.95,280.893C157.982,280.5 153.685,281.727 153.883,286.411C153.972,288.525 155.496,290.63 157.786,290.721C158.291,290.741 159.568,290.733 160.693,289.743C162.684,287.99 161.986,285.746 162.1,285.973C162.135,286.043 162.595,287.175 162.425,288.225C162.036,290.627 159.904,292.026 157.647,291.955C155.409,291.884 152.353,290.125 152.05,286.831C151.607,282.01 154.947,279.522 157.417,279.149C164.862,278.027 164.302,282.064 164.302,282.064Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M158.572,279.581C154.571,279.581 151.322,282.829 151.322,286.831C151.322,290.832 154.571,294.081 158.572,294.081C162.574,294.081 165.822,290.832 165.822,286.831C165.822,282.829 162.574,279.581 158.572,279.581ZM158.572,283.206C156.572,283.206 154.947,284.83 154.947,286.831C154.947,288.831 156.572,290.456 158.572,290.456C160.573,290.456 162.197,288.831 162.197,286.831C162.197,284.83 160.573,283.206 158.572,283.206Z" />
      ),
      basic: {
        dark: (
          <path d="M152.592,291.575C152.721,291.808 155.076,292.632 155.944,292.746C158.912,293.139 163.209,291.912 163.011,287.228C162.922,285.114 161.398,283.008 159.108,282.918C158.603,282.898 157.326,282.905 156.201,283.896C154.21,285.649 154.907,287.893 154.794,287.666C154.759,287.595 154.299,286.464 154.469,285.414C154.858,283.012 156.99,281.613 159.247,281.684C161.485,281.755 163.686,283.051 164.958,284.875C167.728,288.845 165.094,294.795 160.49,295.231C152.995,295.941 152.592,291.575 152.592,291.575Z" />
        ),
        light: (
          <path d="M164.302,282.064C164.173,281.831 161.818,281.007 160.95,280.893C157.982,280.5 153.685,281.727 153.883,286.411C153.972,288.525 155.496,290.63 157.786,290.721C158.291,290.741 159.568,290.733 160.693,289.743C162.684,287.99 161.986,285.746 162.1,285.973C162.135,286.043 162.595,287.175 162.425,288.225C162.036,290.627 159.904,292.026 157.647,291.955C155.409,291.884 152.353,290.125 152.05,286.831C151.607,282.01 154.947,279.522 157.417,279.149C164.862,278.027 164.302,282.064 164.302,282.064Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M341.428,283.206C339.524,283.311 339.011,279.72 341.428,279.581C345.422,279.351 348.678,282.829 348.678,286.831C348.678,290.832 345.053,288.831 345.053,286.831C345.053,284.83 343.425,283.096 341.428,283.206Z" />
      ),
      basic: {
        dark: (
          <path d="M347.408,291.575C347.279,291.808 344.924,292.632 344.056,292.746C341.088,293.139 336.791,291.912 336.989,287.228C337.078,285.114 338.602,283.008 340.892,282.918C341.397,282.898 342.674,282.905 343.799,283.896C345.79,285.649 345.093,287.893 345.206,287.666C345.241,287.595 345.701,286.464 345.531,285.414C345.142,283.012 343.01,281.613 340.753,281.684C338.515,281.755 336.314,283.051 335.042,284.875C332.272,288.845 334.906,294.795 339.51,295.231C347.005,295.941 347.408,291.575 347.408,291.575Z" />
        ),
        light: (
          <path d="M335.698,282.064C335.827,281.831 338.182,281.007 339.05,280.893C342.018,280.5 346.315,281.727 346.117,286.411C346.028,288.525 344.504,290.63 342.214,290.721C341.709,290.741 340.432,290.733 339.307,289.743C337.316,287.99 338.014,285.746 337.9,285.973C337.865,286.043 337.405,287.175 337.575,288.225C337.964,290.627 340.096,292.026 342.353,291.955C344.591,291.884 347.647,290.125 347.95,286.831C348.393,282.01 345.053,279.522 342.583,279.149C335.138,278.027 335.698,282.064 335.698,282.064Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M341.428,279.581C345.429,279.581 348.678,282.829 348.678,286.831C348.678,290.832 345.429,294.081 341.428,294.081C337.426,294.081 334.178,290.832 334.178,286.831C334.178,282.829 337.426,279.581 341.428,279.581ZM341.428,283.206C343.428,283.206 345.053,284.83 345.053,286.831C345.053,288.831 343.428,290.456 341.428,290.456C339.427,290.456 337.803,288.831 337.803,286.831C337.803,284.83 339.427,283.206 341.428,283.206Z" />
      ),
      basic: {
        dark: (
          <path d="M347.408,291.575C347.279,291.808 344.924,292.632 344.056,292.746C341.088,293.139 336.791,291.912 336.989,287.228C337.078,285.114 338.602,283.008 340.892,282.918C341.397,282.898 342.674,282.905 343.799,283.896C345.79,285.649 345.093,287.893 345.206,287.666C345.241,287.595 345.701,286.464 345.531,285.414C345.142,283.012 343.01,281.613 340.753,281.684C338.515,281.755 336.314,283.051 335.042,284.875C332.272,288.845 334.906,294.795 339.51,295.231C347.005,295.941 347.408,291.575 347.408,291.575Z" />
        ),
        light: (
          <path d="M335.698,282.064C335.827,281.831 338.182,281.007 339.05,280.893C342.018,280.5 346.315,281.727 346.117,286.411C346.028,288.525 344.504,290.63 342.214,290.721C341.709,290.741 340.432,290.733 339.307,289.743C337.316,287.99 338.014,285.746 337.9,285.973C337.865,286.043 337.405,287.175 337.575,288.225C337.964,290.627 340.096,292.026 342.353,291.955C344.591,291.884 347.647,290.125 347.95,286.831C348.393,282.01 345.053,279.522 342.583,279.149C335.138,278.027 335.698,282.064 335.698,282.064Z" />
        ),
      },
    },
  },
};

const shapeEar16 = {
  left: {
    front: {
      base: (
        <path d="M164.716,276.133C166.62,276.238 167.133,272.647 164.716,272.508C160.722,272.278 157.466,275.756 157.466,279.758C157.466,283.759 161.091,281.758 161.091,279.758C161.091,277.757 162.719,276.023 164.716,276.133Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,284.502C158.866,284.735 161.22,285.559 162.088,285.673C165.056,286.066 169.353,284.839 169.155,280.155C169.066,278.041 167.542,275.935 165.252,275.845C164.747,275.825 163.47,275.833 162.345,276.823C160.355,278.576 161.052,280.82 160.938,280.593C160.903,280.523 160.443,279.391 160.613,278.341C161.002,275.939 163.134,274.54 165.391,274.611C167.629,274.682 169.83,275.978 171.103,277.802C173.872,281.772 171.238,287.722 166.635,288.158C159.139,288.868 158.737,284.502 158.737,284.502Z" />
        ),
        light: (
          <path d="M170.446,274.991C170.317,274.758 167.962,273.934 167.094,273.82C164.126,273.427 159.829,274.654 160.027,279.338C160.117,281.452 161.64,283.557 163.93,283.648C164.435,283.668 165.712,283.66 166.837,282.67C168.828,280.917 168.131,278.673 168.244,278.9C168.279,278.97 168.739,280.102 168.569,281.152C168.18,283.554 166.048,284.953 163.791,284.882C161.553,284.811 158.498,283.052 158.195,279.758C157.751,274.937 161.091,272.449 163.561,272.076C171.006,270.954 170.446,274.991 170.446,274.991Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,272.508C160.715,272.508 157.466,275.756 157.466,279.758C157.466,283.759 160.715,287.008 164.716,287.008C168.718,287.008 171.966,283.759 171.966,279.758C171.966,275.756 168.718,272.508 164.716,272.508ZM164.716,276.133C162.716,276.133 161.091,277.757 161.091,279.758C161.091,281.758 162.716,283.383 164.716,283.383C166.717,283.383 168.341,281.758 168.341,279.758C168.341,277.757 166.717,276.133 164.716,276.133Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,284.502C158.866,284.735 161.22,285.559 162.088,285.673C165.056,286.066 169.353,284.839 169.155,280.155C169.066,278.041 167.542,275.935 165.252,275.845C164.747,275.825 163.47,275.833 162.345,276.823C160.355,278.576 161.052,280.82 160.938,280.593C160.903,280.523 160.443,279.391 160.613,278.341C161.002,275.939 163.134,274.54 165.391,274.611C167.629,274.682 169.83,275.978 171.103,277.802C173.872,281.772 171.238,287.722 166.635,288.158C159.139,288.868 158.737,284.502 158.737,284.502Z" />
        ),
        light: (
          <path d="M170.446,274.991C170.317,274.758 167.962,273.934 167.094,273.82C164.126,273.427 159.829,274.654 160.027,279.338C160.117,281.452 161.64,283.557 163.93,283.648C164.435,283.668 165.712,283.66 166.837,282.67C168.828,280.917 168.131,278.673 168.244,278.9C168.279,278.97 168.739,280.102 168.569,281.152C168.18,283.554 166.048,284.953 163.791,284.882C161.553,284.811 158.498,283.052 158.195,279.758C157.751,274.937 161.091,272.449 163.561,272.076C171.006,270.954 170.446,274.991 170.446,274.991Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.284,276.133C333.38,276.238 332.867,272.647 335.284,272.508C339.278,272.278 342.534,275.756 342.534,279.758C342.534,283.759 338.909,281.758 338.909,279.758C338.909,277.757 337.281,276.023 335.284,276.133Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,284.502C341.134,284.735 338.78,285.559 337.912,285.673C334.944,286.066 330.647,284.839 330.845,280.155C330.934,278.041 332.458,275.935 334.748,275.845C335.253,275.825 336.53,275.833 337.655,276.823C339.645,278.576 338.948,280.82 339.062,280.593C339.097,280.523 339.557,279.391 339.387,278.341C338.998,275.939 336.866,274.54 334.609,274.611C332.371,274.682 330.17,275.978 328.897,277.802C326.128,281.772 328.762,287.722 333.365,288.158C340.861,288.868 341.263,284.502 341.263,284.502Z" />
        ),
        light: (
          <path d="M329.554,274.991C329.683,274.758 332.038,273.934 332.906,273.82C335.874,273.427 340.171,274.654 339.973,279.338C339.883,281.452 338.36,283.557 336.07,283.648C335.565,283.668 334.288,283.66 333.163,282.67C331.172,280.917 331.869,278.673 331.756,278.9C331.721,278.97 331.261,280.102 331.431,281.152C331.82,283.554 333.952,284.953 336.209,284.882C338.447,284.811 341.502,283.052 341.805,279.758C342.249,274.937 338.909,272.449 336.439,272.076C328.994,270.954 329.554,274.991 329.554,274.991Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.284,272.508C339.285,272.508 342.534,275.756 342.534,279.758C342.534,283.759 339.285,287.008 335.284,287.008C331.282,287.008 328.034,283.759 328.034,279.758C328.034,275.756 331.282,272.508 335.284,272.508ZM335.284,276.133C337.284,276.133 338.909,277.757 338.909,279.758C338.909,281.758 337.284,283.383 335.284,283.383C333.283,283.383 331.659,281.758 331.659,279.758C331.659,277.757 333.283,276.133 335.284,276.133Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,284.502C341.134,284.735 338.78,285.559 337.912,285.673C334.944,286.066 330.647,284.839 330.845,280.155C330.934,278.041 332.458,275.935 334.748,275.845C335.253,275.825 336.53,275.833 337.655,276.823C339.645,278.576 338.948,280.82 339.062,280.593C339.097,280.523 339.557,279.391 339.387,278.341C338.998,275.939 336.866,274.54 334.609,274.611C332.371,274.682 330.17,275.978 328.897,277.802C326.128,281.772 328.762,287.722 333.365,288.158C340.861,288.868 341.263,284.502 341.263,284.502Z" />
        ),
        light: (
          <path d="M329.554,274.991C329.683,274.758 332.038,273.934 332.906,273.82C335.874,273.427 340.171,274.654 339.973,279.338C339.883,281.452 338.36,283.557 336.07,283.648C335.565,283.668 334.288,283.66 333.163,282.67C331.172,280.917 331.869,278.673 331.756,278.9C331.721,278.97 331.261,280.102 331.431,281.152C331.82,283.554 333.952,284.953 336.209,284.882C338.447,284.811 341.502,283.052 341.805,279.758C342.249,274.937 338.909,272.449 336.439,272.076C328.994,270.954 329.554,274.991 329.554,274.991Z" />
        ),
      },
    },
  },
};

const shapeEar17 = {
  left: {
    front: {
      base: (
        <path d="M167.892,283.206C169.795,283.311 170.308,279.72 167.892,279.581C163.897,279.351 160.642,282.829 160.642,286.831C160.642,290.832 164.267,288.831 164.267,286.831C164.267,284.83 165.894,283.096 167.892,283.206Z" />
      ),
      basic: {
        dark: (
          <path d="M161.912,291.575C162.041,291.808 164.395,292.632 165.263,292.746C168.232,293.139 172.528,291.912 172.33,287.228C172.241,285.114 170.718,283.008 168.428,282.918C167.922,282.898 166.645,282.905 165.52,283.896C163.53,285.649 164.227,287.893 164.113,287.666C164.078,287.595 163.619,286.464 163.788,285.414C164.177,283.012 166.309,281.613 168.566,281.684C170.804,281.755 173.006,283.051 174.278,284.875C177.047,288.845 174.414,294.795 169.81,295.231C162.314,295.941 161.912,291.575 161.912,291.575Z" />
        ),
        light: (
          <path d="M173.621,282.064C173.492,281.831 171.137,281.007 170.269,280.893C167.301,280.5 163.004,281.727 163.202,286.411C163.292,288.525 164.815,290.63 167.105,290.721C167.61,290.741 168.887,290.733 170.013,289.743C172.003,287.99 171.306,285.746 171.419,285.973C171.454,286.043 171.914,287.175 171.744,288.225C171.356,290.627 169.223,292.026 166.967,291.955C164.728,291.884 161.673,290.125 161.37,286.831C160.926,282.01 164.266,279.522 166.736,279.149C174.181,278.027 173.621,282.064 173.621,282.064Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M167.892,279.581C163.89,279.581 160.642,282.829 160.642,286.831C160.642,290.832 163.89,294.081 167.892,294.081C171.893,294.081 175.142,290.832 175.142,286.831C175.142,282.829 171.893,279.581 167.892,279.581ZM167.892,283.206C165.891,283.206 164.267,284.83 164.267,286.831C164.267,288.831 165.891,290.456 167.892,290.456C169.892,290.456 171.517,288.831 171.517,286.831C171.517,284.83 169.892,283.206 167.892,283.206Z" />
      ),
      basic: {
        dark: (
          <path d="M161.912,291.575C162.041,291.808 164.395,292.632 165.263,292.746C168.232,293.139 172.528,291.912 172.33,287.228C172.241,285.114 170.718,283.008 168.428,282.918C167.922,282.898 166.645,282.905 165.52,283.896C163.53,285.649 164.227,287.893 164.113,287.666C164.078,287.595 163.619,286.464 163.788,285.414C164.177,283.012 166.309,281.613 168.566,281.684C170.804,281.755 173.006,283.051 174.278,284.875C177.047,288.845 174.414,294.795 169.81,295.231C162.314,295.941 161.912,291.575 161.912,291.575Z" />
        ),
        light: (
          <path d="M173.621,282.064C173.492,281.831 171.137,281.007 170.269,280.893C167.301,280.5 163.004,281.727 163.202,286.411C163.292,288.525 164.815,290.63 167.105,290.721C167.61,290.741 168.887,290.733 170.013,289.743C172.003,287.99 171.306,285.746 171.419,285.973C171.454,286.043 171.914,287.175 171.744,288.225C171.356,290.627 169.223,292.026 166.967,291.955C164.728,291.884 161.673,290.125 161.37,286.831C160.926,282.01 164.266,279.522 166.736,279.149C174.181,278.027 173.621,282.064 173.621,282.064Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M332.108,283.206C330.205,283.311 329.692,279.72 332.108,279.581C336.103,279.351 339.358,282.829 339.358,286.831C339.358,290.832 335.733,288.831 335.733,286.831C335.733,284.83 334.106,283.096 332.108,283.206Z" />
      ),
      basic: {
        dark: (
          <path d="M338.088,291.575C337.959,291.808 335.605,292.632 334.737,292.746C331.768,293.139 327.472,291.912 327.67,287.228C327.759,285.114 329.282,283.008 331.572,282.918C332.078,282.898 333.355,282.905 334.48,283.896C336.47,285.649 335.773,287.893 335.887,287.666C335.922,287.595 336.381,286.464 336.212,285.414C335.823,283.012 333.691,281.613 331.434,281.684C329.196,281.755 326.994,283.051 325.722,284.875C322.953,288.845 325.586,294.795 330.19,295.231C337.686,295.941 338.088,291.575 338.088,291.575Z" />
        ),
        light: (
          <path d="M326.379,282.064C326.508,281.831 328.863,281.007 329.731,280.893C332.699,280.5 336.996,281.727 336.798,286.411C336.708,288.525 335.185,290.63 332.895,290.721C332.39,290.741 331.113,290.733 329.987,289.743C327.997,287.99 328.694,285.746 328.581,285.973C328.546,286.043 328.086,287.175 328.256,288.225C328.644,290.627 330.777,292.026 333.033,291.955C335.272,291.884 338.327,290.125 338.63,286.831C339.074,282.01 335.734,279.522 333.264,279.149C325.819,278.027 326.379,282.064 326.379,282.064Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M332.108,279.581C336.11,279.581 339.358,282.829 339.358,286.831C339.358,290.832 336.11,294.081 332.108,294.081C328.107,294.081 324.858,290.832 324.858,286.831C324.858,282.829 328.107,279.581 332.108,279.581ZM332.108,283.206C334.109,283.206 335.733,284.83 335.733,286.831C335.733,288.831 334.109,290.456 332.108,290.456C330.108,290.456 328.483,288.831 328.483,286.831C328.483,284.83 330.108,283.206 332.108,283.206Z" />
      ),
      basic: {
        dark: (
          <path d="M338.088,291.575C337.959,291.808 335.605,292.632 334.737,292.746C331.768,293.139 327.472,291.912 327.67,287.228C327.759,285.114 329.282,283.008 331.572,282.918C332.078,282.898 333.355,282.905 334.48,283.896C336.47,285.649 335.773,287.893 335.887,287.666C335.922,287.595 336.381,286.464 336.212,285.414C335.823,283.012 333.691,281.613 331.434,281.684C329.196,281.755 326.994,283.051 325.722,284.875C322.953,288.845 325.586,294.795 330.19,295.231C337.686,295.941 338.088,291.575 338.088,291.575Z" />
        ),
        light: (
          <path d="M326.379,282.064C326.508,281.831 328.863,281.007 329.731,280.893C332.699,280.5 336.996,281.727 336.798,286.411C336.708,288.525 335.185,290.63 332.895,290.721C332.39,290.741 331.113,290.733 329.987,289.743C327.997,287.99 328.694,285.746 328.581,285.973C328.546,286.043 328.086,287.175 328.256,288.225C328.644,290.627 330.777,292.026 333.033,291.955C335.272,291.884 338.327,290.125 338.63,286.831C339.074,282.01 335.734,279.522 333.264,279.149C325.819,278.027 326.379,282.064 326.379,282.064Z" />
        ),
      },
    },
  },
};

const shapeEar18 = {
  left: {
    front: {
      base: (
        <path d="M164.716,284.767C166.62,284.872 167.133,281.281 164.716,281.142C160.722,280.913 157.466,284.391 157.466,288.392C157.466,292.393 161.091,290.393 161.091,288.392C161.091,286.391 162.719,284.657 164.716,284.767Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,293.136C158.866,293.369 161.22,294.193 162.088,294.307C165.056,294.7 169.353,293.473 169.155,288.789C169.066,286.675 167.542,284.57 165.252,284.479C164.747,284.459 163.47,284.467 162.345,285.457C160.355,287.21 161.052,289.454 160.938,289.227C160.903,289.157 160.443,288.025 160.613,286.975C161.002,284.574 163.134,283.174 165.391,283.245C167.629,283.316 169.83,284.612 171.103,286.436C173.872,290.406 171.238,296.356 166.635,296.792C159.139,297.502 158.737,293.136 158.737,293.136Z" />
        ),
        light: (
          <path d="M170.446,283.625C170.317,283.392 167.962,282.569 167.094,282.454C164.126,282.061 159.829,283.288 160.027,287.972C160.117,290.086 161.64,292.192 163.93,292.282C164.435,292.302 165.712,292.295 166.837,291.304C168.828,289.551 168.131,287.307 168.244,287.534C168.279,287.605 168.739,288.736 168.569,289.786C168.18,292.188 166.048,293.587 163.791,293.516C161.553,293.445 158.498,291.686 158.195,288.392C157.751,283.571 161.091,281.083 163.561,280.711C171.006,279.588 170.446,283.625 170.446,283.625Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,281.142C160.715,281.142 157.466,284.391 157.466,288.392C157.466,292.393 160.715,295.642 164.716,295.642C168.718,295.642 171.966,292.393 171.966,288.392C171.966,284.391 168.718,281.142 164.716,281.142ZM164.716,284.767C162.716,284.767 161.091,286.391 161.091,288.392C161.091,290.393 162.716,292.017 164.716,292.017C166.717,292.017 168.341,290.393 168.341,288.392C168.341,286.391 166.717,284.767 164.716,284.767Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,293.136C158.866,293.369 161.22,294.193 162.088,294.307C165.056,294.7 169.353,293.473 169.155,288.789C169.066,286.675 167.542,284.57 165.252,284.479C164.747,284.459 163.47,284.467 162.345,285.457C160.355,287.21 161.052,289.454 160.938,289.227C160.903,289.157 160.443,288.025 160.613,286.975C161.002,284.574 163.134,283.174 165.391,283.245C167.629,283.316 169.83,284.612 171.103,286.436C173.872,290.406 171.238,296.356 166.635,296.792C159.139,297.502 158.737,293.136 158.737,293.136Z" />
        ),
        light: (
          <path d="M170.446,283.625C170.317,283.392 167.962,282.569 167.094,282.454C164.126,282.061 159.829,283.288 160.027,287.972C160.117,290.086 161.64,292.192 163.93,292.282C164.435,292.302 165.712,292.295 166.837,291.304C168.828,289.551 168.131,287.307 168.244,287.534C168.279,287.605 168.739,288.736 168.569,289.786C168.18,292.188 166.048,293.587 163.791,293.516C161.553,293.445 158.498,291.686 158.195,288.392C157.751,283.571 161.091,281.083 163.561,280.711C171.006,279.588 170.446,283.625 170.446,283.625Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.284,284.767C333.38,284.872 332.867,281.281 335.284,281.142C339.278,280.913 342.534,284.391 342.534,288.392C342.534,292.393 338.909,290.393 338.909,288.392C338.909,286.391 337.281,284.657 335.284,284.767Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,293.136C341.134,293.369 338.78,294.193 337.912,294.307C334.944,294.7 330.647,293.473 330.845,288.789C330.934,286.675 332.458,284.57 334.748,284.479C335.253,284.459 336.53,284.467 337.655,285.457C339.645,287.21 338.948,289.454 339.062,289.227C339.097,289.157 339.557,288.025 339.387,286.975C338.998,284.574 336.866,283.174 334.609,283.245C332.371,283.316 330.17,284.612 328.897,286.436C326.128,290.406 328.762,296.356 333.365,296.792C340.861,297.502 341.263,293.136 341.263,293.136Z" />
        ),
        light: (
          <path d="M329.554,283.625C329.683,283.392 332.038,282.569 332.906,282.454C335.874,282.061 340.171,283.288 339.973,287.972C339.883,290.086 338.36,292.192 336.07,292.282C335.565,292.302 334.288,292.295 333.163,291.304C331.172,289.551 331.869,287.307 331.756,287.534C331.721,287.605 331.261,288.736 331.431,289.786C331.82,292.188 333.952,293.587 336.209,293.516C338.447,293.445 341.502,291.686 341.805,288.392C342.249,283.571 338.909,281.083 336.439,280.711C328.994,279.588 329.554,283.625 329.554,283.625Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.284,281.142C339.285,281.142 342.534,284.391 342.534,288.392C342.534,292.393 339.285,295.642 335.284,295.642C331.282,295.642 328.034,292.393 328.034,288.392C328.034,284.391 331.282,281.142 335.284,281.142ZM335.284,284.767C337.284,284.767 338.909,286.391 338.909,288.392C338.909,290.393 337.284,292.017 335.284,292.017C333.283,292.017 331.659,290.393 331.659,288.392C331.659,286.391 333.283,284.767 335.284,284.767Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,293.136C341.134,293.369 338.78,294.193 337.912,294.307C334.944,294.7 330.647,293.473 330.845,288.789C330.934,286.675 332.458,284.57 334.748,284.479C335.253,284.459 336.53,284.467 337.655,285.457C339.645,287.21 338.948,289.454 339.062,289.227C339.097,289.157 339.557,288.025 339.387,286.975C338.998,284.574 336.866,283.174 334.609,283.245C332.371,283.316 330.17,284.612 328.897,286.436C326.128,290.406 328.762,296.356 333.365,296.792C340.861,297.502 341.263,293.136 341.263,293.136Z" />
        ),
        light: (
          <path d="M329.554,283.625C329.683,283.392 332.038,282.569 332.906,282.454C335.874,282.061 340.171,283.288 339.973,287.972C339.883,290.086 338.36,292.192 336.07,292.282C335.565,292.302 334.288,292.295 333.163,291.304C331.172,289.551 331.869,287.307 331.756,287.534C331.721,287.605 331.261,288.736 331.431,289.786C331.82,292.188 333.952,293.587 336.209,293.516C338.447,293.445 341.502,291.686 341.805,288.392C342.249,283.571 338.909,281.083 336.439,280.711C328.994,279.588 329.554,283.625 329.554,283.625Z" />
        ),
      },
    },
  },
};

const shapeEar19 = {
  left: {
    front: {
      base: (
        <path d="M168.129,201.753C170.033,201.858 170.545,198.267 168.129,198.128C164.134,197.899 160.879,201.377 160.879,205.378C160.879,209.38 164.504,207.379 164.504,205.378C164.504,203.378 166.131,201.643 168.129,201.753Z" />
      ),
      basic: {
        dark: (
          <path d="M162.149,210.123C162.278,210.355 164.632,211.179 165.5,211.294C168.469,211.686 172.766,210.46 172.568,205.776C172.478,203.661 170.955,201.556 168.665,201.465C168.159,201.445 166.882,201.453 165.757,202.444C163.767,204.196 164.464,206.441 164.35,206.213C164.315,206.143 163.856,205.011 164.026,203.961C164.414,201.56 166.547,200.16 168.803,200.232C171.042,200.303 173.243,201.599 174.515,203.422C177.285,207.393 174.651,213.343 170.047,213.779C162.552,214.489 162.149,210.123 162.149,210.123Z" />
        ),
        light: (
          <path d="M173.858,200.612C173.729,200.379 171.375,199.555 170.507,199.44C167.538,199.048 163.241,200.274 163.44,204.959C163.529,207.073 165.052,209.178 167.342,209.269C167.848,209.289 169.125,209.281 170.25,208.29C172.24,206.538 171.543,204.294 171.657,204.521C171.692,204.591 172.151,205.723 171.981,206.773C171.593,209.174 169.461,210.574 167.204,210.502C164.965,210.431 161.91,208.673 161.607,205.378C161.164,200.558 164.503,198.069 166.973,197.697C174.418,196.575 173.858,200.612 173.858,200.612Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M168.129,198.128C164.127,198.128 160.879,201.377 160.879,205.378C160.879,209.38 164.127,212.628 168.129,212.628C172.13,212.628 175.379,209.38 175.379,205.378C175.379,201.377 172.13,198.128 168.129,198.128ZM168.129,201.753C166.128,201.753 164.504,203.378 164.504,205.378C164.504,207.379 166.128,209.003 168.129,209.003C170.13,209.003 171.754,207.379 171.754,205.378C171.754,203.378 170.13,201.753 168.129,201.753Z" />
      ),
      basic: {
        dark: (
          <path d="M162.149,210.123C162.278,210.355 164.632,211.179 165.5,211.294C168.469,211.686 172.766,210.46 172.568,205.776C172.478,203.661 170.955,201.556 168.665,201.465C168.159,201.445 166.882,201.453 165.757,202.444C163.767,204.196 164.464,206.441 164.35,206.213C164.315,206.143 163.856,205.011 164.026,203.961C164.414,201.56 166.547,200.16 168.803,200.232C171.042,200.303 173.243,201.599 174.515,203.422C177.285,207.393 174.651,213.343 170.047,213.779C162.552,214.489 162.149,210.123 162.149,210.123Z" />
        ),
        light: (
          <path d="M173.858,200.612C173.729,200.379 171.375,199.555 170.507,199.44C167.538,199.048 163.241,200.274 163.44,204.959C163.529,207.073 165.052,209.178 167.342,209.269C167.848,209.289 169.125,209.281 170.25,208.29C172.24,206.538 171.543,204.294 171.657,204.521C171.692,204.591 172.151,205.723 171.981,206.773C171.593,209.174 169.461,210.574 167.204,210.502C164.965,210.431 161.91,208.673 161.607,205.378C161.164,200.558 164.503,198.069 166.973,197.697C174.418,196.575 173.858,200.612 173.858,200.612Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M331.871,201.753C329.967,201.858 329.455,198.267 331.871,198.128C335.866,197.899 339.121,201.377 339.121,205.378C339.121,209.38 335.496,207.379 335.496,205.378C335.496,203.378 333.869,201.643 331.871,201.753Z" />
      ),
      basic: {
        dark: (
          <path d="M337.851,210.123C337.722,210.355 335.368,211.179 334.5,211.294C331.531,211.686 327.234,210.46 327.432,205.776C327.522,203.661 329.045,201.556 331.335,201.465C331.841,201.445 333.118,201.453 334.243,202.444C336.233,204.196 335.536,206.441 335.65,206.213C335.685,206.143 336.144,205.011 335.974,203.961C335.586,201.56 333.453,200.16 331.197,200.232C328.958,200.303 326.757,201.599 325.485,203.422C322.715,207.393 325.349,213.343 329.953,213.779C337.448,214.489 337.851,210.123 337.851,210.123Z" />
        ),
        light: (
          <path d="M326.142,200.612C326.271,200.379 328.625,199.555 329.493,199.44C332.462,199.048 336.759,200.274 336.56,204.959C336.471,207.073 334.948,209.178 332.658,209.269C332.152,209.289 330.875,209.281 329.75,208.29C327.76,206.538 328.457,204.294 328.343,204.521C328.308,204.591 327.849,205.723 328.019,206.773C328.407,209.174 330.539,210.574 332.796,210.502C335.035,210.431 338.09,208.673 338.393,205.378C338.836,200.558 335.497,198.069 333.027,197.697C325.582,196.575 326.142,200.612 326.142,200.612Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M331.871,198.128C335.873,198.128 339.121,201.377 339.121,205.378C339.121,209.38 335.873,212.628 331.871,212.628C327.87,212.628 324.621,209.38 324.621,205.378C324.621,201.377 327.87,198.128 331.871,198.128ZM331.871,201.753C333.872,201.753 335.496,203.378 335.496,205.378C335.496,207.379 333.872,209.003 331.871,209.003C329.87,209.003 328.246,207.379 328.246,205.378C328.246,203.378 329.87,201.753 331.871,201.753Z" />
      ),
      basic: {
        dark: (
          <path d="M337.851,210.123C337.722,210.355 335.368,211.179 334.5,211.294C331.531,211.686 327.234,210.46 327.432,205.776C327.522,203.661 329.045,201.556 331.335,201.465C331.841,201.445 333.118,201.453 334.243,202.444C336.233,204.196 335.536,206.441 335.65,206.213C335.685,206.143 336.144,205.011 335.974,203.961C335.586,201.56 333.453,200.16 331.197,200.232C328.958,200.303 326.757,201.599 325.485,203.422C322.715,207.393 325.349,213.343 329.953,213.779C337.448,214.489 337.851,210.123 337.851,210.123Z" />
        ),
        light: (
          <path d="M326.142,200.612C326.271,200.379 328.625,199.555 329.493,199.44C332.462,199.048 336.759,200.274 336.56,204.959C336.471,207.073 334.948,209.178 332.658,209.269C332.152,209.289 330.875,209.281 329.75,208.29C327.76,206.538 328.457,204.294 328.343,204.521C328.308,204.591 327.849,205.723 328.019,206.773C328.407,209.174 330.539,210.574 332.796,210.502C335.035,210.431 338.09,208.673 338.393,205.378C338.836,200.558 335.497,198.069 333.027,197.697C325.582,196.575 326.142,200.612 326.142,200.612Z" />
        ),
      },
    },
  },
};

const shapeEar20 = {
  left: {
    front: {
      base: (
        <path d="M150.093,324.166C151.997,324.271 152.51,320.679 150.093,320.541C146.098,320.311 142.843,323.789 142.843,327.791C142.843,331.792 146.468,329.791 146.468,327.791C146.468,325.79 148.095,324.056 150.093,324.166Z" />
      ),
      basic: {
        dark: (
          <path d="M144.113,332.535C144.242,332.768 146.597,333.591 147.464,333.706C150.433,334.099 154.73,332.872 154.532,328.188C154.442,326.074 152.919,323.968 150.629,323.878C150.124,323.858 148.847,323.865 147.721,324.856C145.731,326.609 146.428,328.853 146.314,328.626C146.279,328.555 145.82,327.424 145.99,326.374C146.378,323.972 148.511,322.573 150.767,322.644C153.006,322.715 155.207,324.011 156.479,325.835C159.249,329.805 156.615,335.755 152.011,336.191C144.516,336.901 144.113,332.535 144.113,332.535Z" />
        ),
        light: (
          <path d="M155.822,323.024C155.693,322.791 153.339,321.967 152.471,321.853C149.502,321.46 145.205,322.687 145.404,327.371C145.493,329.485 147.016,331.59 149.306,331.681C149.812,331.701 151.089,331.693 152.214,330.703C154.204,328.95 153.507,326.706 153.621,326.933C153.656,327.003 154.115,328.135 153.945,329.185C153.557,331.587 151.425,332.986 149.168,332.915C146.929,332.844 143.874,331.085 143.571,327.791C143.128,322.97 146.467,320.482 148.937,320.109C156.382,318.987 155.822,323.024 155.822,323.024Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M150.093,320.541C146.092,320.541 142.843,323.789 142.843,327.791C142.843,331.792 146.092,335.041 150.093,335.041C154.094,335.041 157.343,331.792 157.343,327.791C157.343,323.789 154.094,320.541 150.093,320.541ZM150.093,324.166C148.092,324.166 146.468,325.79 146.468,327.791C146.468,329.791 148.092,331.416 150.093,331.416C152.094,331.416 153.718,329.791 153.718,327.791C153.718,325.79 152.094,324.166 150.093,324.166Z" />
      ),
      basic: {
        dark: (
          <path d="M144.113,332.535C144.242,332.768 146.597,333.591 147.464,333.706C150.433,334.099 154.73,332.872 154.532,328.188C154.442,326.074 152.919,323.968 150.629,323.878C150.124,323.858 148.847,323.865 147.721,324.856C145.731,326.609 146.428,328.853 146.314,328.626C146.279,328.555 145.82,327.424 145.99,326.374C146.378,323.972 148.511,322.573 150.767,322.644C153.006,322.715 155.207,324.011 156.479,325.835C159.249,329.805 156.615,335.755 152.011,336.191C144.516,336.901 144.113,332.535 144.113,332.535Z" />
        ),
        light: (
          <path d="M155.822,323.024C155.693,322.791 153.339,321.967 152.471,321.853C149.502,321.46 145.205,322.687 145.404,327.371C145.493,329.485 147.016,331.59 149.306,331.681C149.812,331.701 151.089,331.693 152.214,330.703C154.204,328.95 153.507,326.706 153.621,326.933C153.656,327.003 154.115,328.135 153.945,329.185C153.557,331.587 151.425,332.986 149.168,332.915C146.929,332.844 143.874,331.085 143.571,327.791C143.128,322.97 146.467,320.482 148.937,320.109C156.382,318.987 155.822,323.024 155.822,323.024Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M349.907,324.166C348.003,324.271 347.49,320.679 349.907,320.541C353.902,320.311 357.157,323.789 357.157,327.791C357.157,331.792 353.532,329.791 353.532,327.791C353.532,325.79 351.905,324.056 349.907,324.166Z" />
      ),
      basic: {
        dark: (
          <path d="M355.887,332.535C355.758,332.768 353.403,333.591 352.536,333.706C349.567,334.099 345.27,332.872 345.468,328.188C345.558,326.074 347.081,323.968 349.371,323.878C349.876,323.858 351.153,323.865 352.279,324.856C354.269,326.609 353.572,328.853 353.686,328.626C353.721,328.555 354.18,327.424 354.01,326.374C353.622,323.972 351.489,322.573 349.233,322.644C346.994,322.715 344.793,324.011 343.521,325.835C340.751,329.805 343.385,335.755 347.989,336.191C355.484,336.901 355.887,332.535 355.887,332.535Z" />
        ),
        light: (
          <path d="M344.178,323.024C344.307,322.791 346.661,321.967 347.529,321.853C350.498,321.46 354.795,322.687 354.596,327.371C354.507,329.485 352.984,331.59 350.694,331.681C350.188,331.701 348.911,331.693 347.786,330.703C345.796,328.95 346.493,326.706 346.379,326.933C346.344,327.003 345.885,328.135 346.055,329.185C346.443,331.587 348.575,332.986 350.832,332.915C353.071,332.844 356.126,331.085 356.429,327.791C356.872,322.97 353.533,320.482 351.063,320.109C343.618,318.987 344.178,323.024 344.178,323.024Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M349.907,320.541C353.908,320.541 357.157,323.789 357.157,327.791C357.157,331.792 353.908,335.041 349.907,335.041C345.906,335.041 342.657,331.792 342.657,327.791C342.657,323.789 345.906,320.541 349.907,320.541ZM349.907,324.166C351.908,324.166 353.532,325.79 353.532,327.791C353.532,329.791 351.908,331.416 349.907,331.416C347.906,331.416 346.282,329.791 346.282,327.791C346.282,325.79 347.906,324.166 349.907,324.166Z" />
      ),
      basic: {
        dark: (
          <path d="M355.887,332.535C355.758,332.768 353.403,333.591 352.536,333.706C349.567,334.099 345.27,332.872 345.468,328.188C345.558,326.074 347.081,323.968 349.371,323.878C349.876,323.858 351.153,323.865 352.279,324.856C354.269,326.609 353.572,328.853 353.686,328.626C353.721,328.555 354.18,327.424 354.01,326.374C353.622,323.972 351.489,322.573 349.233,322.644C346.994,322.715 344.793,324.011 343.521,325.835C340.751,329.805 343.385,335.755 347.989,336.191C355.484,336.901 355.887,332.535 355.887,332.535Z" />
        ),
        light: (
          <path d="M344.178,323.024C344.307,322.791 346.661,321.967 347.529,321.853C350.498,321.46 354.795,322.687 354.596,327.371C354.507,329.485 352.984,331.59 350.694,331.681C350.188,331.701 348.911,331.693 347.786,330.703C345.796,328.95 346.493,326.706 346.379,326.933C346.344,327.003 345.885,328.135 346.055,329.185C346.443,331.587 348.575,332.986 350.832,332.915C353.071,332.844 356.126,331.085 356.429,327.791C356.872,322.97 353.533,320.482 351.063,320.109C343.618,318.987 344.178,323.024 344.178,323.024Z" />
        ),
      },
    },
  },
};

const shapeEar21 = {
  left: {
    front: {
      base: (
        <path d="M157.585,297.099C159.488,297.204 160.001,293.613 157.585,293.474C153.59,293.245 150.335,296.723 150.335,300.724C150.335,304.726 153.96,302.725 153.96,300.724C153.96,298.724 155.587,296.989 157.585,297.099Z" />
      ),
      basic: {
        dark: (
          <path d="M151.605,305.469C151.734,305.702 154.088,306.525 154.956,306.64C157.924,307.032 162.221,305.806 162.023,301.122C161.934,299.007 160.41,296.902 158.12,296.812C157.615,296.791 156.338,296.799 155.213,297.79C153.223,299.542 153.92,301.787 153.806,301.559C153.771,301.489 153.311,300.357 153.481,299.307C153.87,296.906 156.002,295.506 158.259,295.578C160.497,295.649 162.698,296.945 163.971,298.769C166.74,302.739 164.106,308.689 159.503,309.125C152.007,309.835 151.605,305.469 151.605,305.469Z" />
        ),
        light: (
          <path d="M163.314,295.958C163.185,295.725 160.83,294.901 159.962,294.786C156.994,294.394 152.697,295.62 152.895,300.305C152.985,302.419 154.508,304.524 156.798,304.615C157.303,304.635 158.58,304.627 159.705,303.636C161.696,301.884 160.999,299.64 161.112,299.867C161.147,299.937 161.607,301.069 161.437,302.119C161.048,304.52 158.916,305.92 156.659,305.848C154.421,305.778 151.366,304.019 151.063,300.724C150.619,295.904 153.959,293.415 156.429,293.043C163.874,291.921 163.314,295.958 163.314,295.958Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M157.585,293.474C153.583,293.474 150.335,296.723 150.335,300.724C150.335,304.726 153.583,307.974 157.585,307.974C161.586,307.974 164.835,304.726 164.835,300.724C164.835,296.723 161.586,293.474 157.585,293.474ZM157.585,297.099C155.584,297.099 153.96,298.724 153.96,300.724C153.96,302.725 155.584,304.349 157.585,304.349C159.585,304.349 161.21,302.725 161.21,300.724C161.21,298.724 159.585,297.099 157.585,297.099Z" />
      ),
      basic: {
        dark: (
          <path d="M151.605,305.469C151.734,305.702 154.088,306.525 154.956,306.64C157.924,307.032 162.221,305.806 162.023,301.122C161.934,299.007 160.41,296.902 158.12,296.812C157.615,296.791 156.338,296.799 155.213,297.79C153.223,299.542 153.92,301.787 153.806,301.559C153.771,301.489 153.311,300.357 153.481,299.307C153.87,296.906 156.002,295.506 158.259,295.578C160.497,295.649 162.698,296.945 163.971,298.769C166.74,302.739 164.106,308.689 159.503,309.125C152.007,309.835 151.605,305.469 151.605,305.469Z" />
        ),
        light: (
          <path d="M163.314,295.958C163.185,295.725 160.83,294.901 159.962,294.786C156.994,294.394 152.697,295.62 152.895,300.305C152.985,302.419 154.508,304.524 156.798,304.615C157.303,304.635 158.58,304.627 159.705,303.636C161.696,301.884 160.999,299.64 161.112,299.867C161.147,299.937 161.607,301.069 161.437,302.119C161.048,304.52 158.916,305.92 156.659,305.848C154.421,305.778 151.366,304.019 151.063,300.724C150.619,295.904 153.959,293.415 156.429,293.043C163.874,291.921 163.314,295.958 163.314,295.958Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M342.415,297.099C340.512,297.204 339.999,293.613 342.415,293.474C346.41,293.245 349.665,296.723 349.665,300.724C349.665,304.726 346.04,302.725 346.04,300.724C346.04,298.724 344.413,296.989 342.415,297.099Z" />
      ),
      basic: {
        dark: (
          <path d="M348.395,305.469C348.266,305.702 345.912,306.525 345.044,306.64C342.076,307.032 337.779,305.806 337.977,301.122C338.066,299.007 339.59,296.902 341.88,296.812C342.385,296.791 343.662,296.799 344.787,297.79C346.777,299.542 346.08,301.787 346.194,301.559C346.229,301.489 346.689,300.357 346.519,299.307C346.13,296.906 343.998,295.506 341.741,295.578C339.503,295.649 337.302,296.945 336.029,298.769C333.26,302.739 335.894,308.689 340.497,309.125C347.993,309.835 348.395,305.469 348.395,305.469Z" />
        ),
        light: (
          <path d="M336.686,295.958C336.815,295.725 339.17,294.901 340.038,294.786C343.006,294.394 347.303,295.62 347.105,300.305C347.015,302.419 345.492,304.524 343.202,304.615C342.697,304.635 341.42,304.627 340.295,303.636C338.304,301.884 339.001,299.64 338.888,299.867C338.853,299.937 338.393,301.069 338.563,302.119C338.952,304.52 341.084,305.92 343.341,305.848C345.579,305.778 348.634,304.019 348.937,300.724C349.381,295.904 346.041,293.415 343.571,293.043C336.126,291.921 336.686,295.958 336.686,295.958Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M342.415,293.474C346.417,293.474 349.665,296.723 349.665,300.724C349.665,304.726 346.417,307.974 342.415,307.974C338.414,307.974 335.165,304.726 335.165,300.724C335.165,296.723 338.414,293.474 342.415,293.474ZM342.415,297.099C344.416,297.099 346.04,298.724 346.04,300.724C346.04,302.725 344.416,304.349 342.415,304.349C340.415,304.349 338.79,302.725 338.79,300.724C338.79,298.724 340.415,297.099 342.415,297.099Z" />
      ),
      basic: {
        dark: (
          <path d="M348.395,305.469C348.266,305.702 345.912,306.525 345.044,306.64C342.076,307.032 337.779,305.806 337.977,301.122C338.066,299.007 339.59,296.902 341.88,296.812C342.385,296.791 343.662,296.799 344.787,297.79C346.777,299.542 346.08,301.787 346.194,301.559C346.229,301.489 346.689,300.357 346.519,299.307C346.13,296.906 343.998,295.506 341.741,295.578C339.503,295.649 337.302,296.945 336.029,298.769C333.26,302.739 335.894,308.689 340.497,309.125C347.993,309.835 348.395,305.469 348.395,305.469Z" />
        ),
        light: (
          <path d="M336.686,295.958C336.815,295.725 339.17,294.901 340.038,294.786C343.006,294.394 347.303,295.62 347.105,300.305C347.015,302.419 345.492,304.524 343.202,304.615C342.697,304.635 341.42,304.627 340.295,303.636C338.304,301.884 339.001,299.64 338.888,299.867C338.853,299.937 338.393,301.069 338.563,302.119C338.952,304.52 341.084,305.92 343.341,305.848C345.579,305.778 348.634,304.019 348.937,300.724C349.381,295.904 346.041,293.415 343.571,293.043C336.126,291.921 336.686,295.958 336.686,295.958Z" />
        ),
      },
    },
  },
};

const shapeEar22 = {
  left: {
    front: {
      base: (
        <path d="M164.716,280.951C166.62,281.056 167.133,277.465 164.716,277.326C160.722,277.097 157.466,280.575 157.466,284.576C157.466,288.577 161.091,286.577 161.091,284.576C161.091,282.575 162.719,280.841 164.716,280.951Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,289.32C158.866,289.553 161.22,290.377 162.088,290.492C165.056,290.884 169.353,289.657 169.155,284.973C169.066,282.859 167.542,280.754 165.252,280.663C164.747,280.643 163.47,280.651 162.345,281.642C160.355,283.394 161.052,285.638 160.938,285.411C160.903,285.341 160.443,284.209 160.613,283.159C161.002,280.758 163.134,279.358 165.391,279.429C167.629,279.5 169.83,280.796 171.103,282.62C173.872,286.59 171.238,292.54 166.635,292.976C159.139,293.686 158.737,289.32 158.737,289.32Z" />
        ),
        light: (
          <path d="M170.446,279.809C170.317,279.576 167.962,278.753 167.094,278.638C164.126,278.245 159.829,279.472 160.027,284.156C160.117,286.27 161.64,288.376 163.93,288.466C164.435,288.486 165.712,288.479 166.837,287.488C168.828,285.735 168.131,283.491 168.244,283.718C168.279,283.789 168.739,284.92 168.569,285.971C168.18,288.372 166.048,289.771 163.791,289.7C161.553,289.629 158.498,287.87 158.195,284.576C157.751,279.755 161.091,277.267 163.561,276.895C171.006,275.772 170.446,279.809 170.446,279.809Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,277.326C160.715,277.326 157.466,280.575 157.466,284.576C157.466,288.577 160.715,291.826 164.716,291.826C168.718,291.826 171.966,288.577 171.966,284.576C171.966,280.575 168.718,277.326 164.716,277.326ZM164.716,280.951C162.716,280.951 161.091,282.575 161.091,284.576C161.091,286.577 162.716,288.201 164.716,288.201C166.717,288.201 168.341,286.577 168.341,284.576C168.341,282.575 166.717,280.951 164.716,280.951Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,289.32C158.866,289.553 161.22,290.377 162.088,290.492C165.056,290.884 169.353,289.657 169.155,284.973C169.066,282.859 167.542,280.754 165.252,280.663C164.747,280.643 163.47,280.651 162.345,281.642C160.355,283.394 161.052,285.638 160.938,285.411C160.903,285.341 160.443,284.209 160.613,283.159C161.002,280.758 163.134,279.358 165.391,279.429C167.629,279.5 169.83,280.796 171.103,282.62C173.872,286.59 171.238,292.54 166.635,292.976C159.139,293.686 158.737,289.32 158.737,289.32Z" />
        ),
        light: (
          <path d="M170.446,279.809C170.317,279.576 167.962,278.753 167.094,278.638C164.126,278.245 159.829,279.472 160.027,284.156C160.117,286.27 161.64,288.376 163.93,288.466C164.435,288.486 165.712,288.479 166.837,287.488C168.828,285.735 168.131,283.491 168.244,283.718C168.279,283.789 168.739,284.92 168.569,285.971C168.18,288.372 166.048,289.771 163.791,289.7C161.553,289.629 158.498,287.87 158.195,284.576C157.751,279.755 161.091,277.267 163.561,276.895C171.006,275.772 170.446,279.809 170.446,279.809Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M347.863,309.976C345.959,310.081 345.446,306.49 347.863,306.351C351.858,306.122 355.113,309.6 355.113,313.601C355.113,317.602 351.488,315.602 351.488,313.601C351.488,311.6 349.861,309.866 347.863,309.976Z" />
      ),
      basic: {
        dark: (
          <path d="M353.843,318.345C353.714,318.578 351.359,319.402 350.492,319.516C347.523,319.909 343.226,318.682 343.424,313.998C343.514,311.884 345.037,309.779 347.327,309.688C347.832,309.668 349.109,309.676 350.235,310.666C352.225,312.419 351.528,314.663 351.642,314.436C351.677,314.366 352.136,313.234 351.966,312.184C351.578,309.782 349.445,308.383 347.189,308.454C344.95,308.525 342.749,309.821 341.477,311.645C338.707,315.615 341.341,321.565 345.945,322.001C353.44,322.711 353.843,318.345 353.843,318.345Z" />
        ),
        light: (
          <path d="M342.134,308.834C342.263,308.601 344.617,307.778 345.485,307.663C348.454,307.27 352.751,308.497 352.552,313.181C352.463,315.295 350.94,317.401 348.65,317.491C348.144,317.511 346.867,317.504 345.742,316.513C343.752,314.76 344.449,312.516 344.335,312.743C344.3,312.814 343.841,313.945 344.011,314.995C344.399,317.397 346.531,318.796 348.788,318.725C351.026,318.654 354.082,316.895 354.385,313.601C354.828,308.78 351.489,306.292 349.019,305.92C341.574,304.797 342.134,308.834 342.134,308.834Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M347.863,306.351C351.864,306.351 355.113,309.6 355.113,313.601C355.113,317.602 351.864,320.851 347.863,320.851C343.862,320.851 340.613,317.602 340.613,313.601C340.613,309.6 343.862,306.351 347.863,306.351ZM347.863,309.976C349.864,309.976 351.488,311.6 351.488,313.601C351.488,315.602 349.864,317.226 347.863,317.226C345.862,317.226 344.238,315.602 344.238,313.601C344.238,311.6 345.862,309.976 347.863,309.976Z" />
      ),
      basic: {
        dark: (
          <path d="M353.843,318.345C353.714,318.578 351.359,319.402 350.492,319.516C347.523,319.909 343.226,318.682 343.424,313.998C343.514,311.884 345.037,309.779 347.327,309.688C347.832,309.668 349.109,309.676 350.235,310.666C352.225,312.419 351.528,314.663 351.642,314.436C351.677,314.366 352.136,313.234 351.966,312.184C351.578,309.782 349.445,308.383 347.189,308.454C344.95,308.525 342.749,309.821 341.477,311.645C338.707,315.615 341.341,321.565 345.945,322.001C353.44,322.711 353.843,318.345 353.843,318.345Z" />
        ),
        light: (
          <path d="M342.134,308.834C342.263,308.601 344.617,307.778 345.485,307.663C348.454,307.27 352.751,308.497 352.552,313.181C352.463,315.295 350.94,317.401 348.65,317.491C348.144,317.511 346.867,317.504 345.742,316.513C343.752,314.76 344.449,312.516 344.335,312.743C344.3,312.814 343.841,313.945 344.011,314.995C344.399,317.397 346.531,318.796 348.788,318.725C351.026,318.654 354.082,316.895 354.385,313.601C354.828,308.78 351.489,306.292 349.019,305.92C341.574,304.797 342.134,308.834 342.134,308.834Z" />
        ),
      },
    },
  },
};

const shapeEar23 = {
  left: {
    front: {
      base: (
        <path d="M157.921,280.083C159.825,280.188 160.338,276.597 157.921,276.458C153.927,276.228 150.671,279.706 150.671,283.708C150.671,287.709 154.296,285.709 154.296,283.708C154.296,281.707 155.924,279.973 157.921,280.083Z" />
      ),
      basic: {
        dark: (
          <path d="M151.942,288.452C152.071,288.685 154.425,289.509 155.293,289.623C158.261,290.016 162.558,288.789 162.36,284.105C162.271,281.991 160.747,279.886 158.457,279.795C157.952,279.775 156.675,279.783 155.55,280.773C153.56,282.526 154.257,284.77 154.143,284.543C154.108,284.473 153.648,283.341 153.818,282.291C154.207,279.889 156.339,278.49 158.596,278.561C160.834,278.632 163.035,279.928 164.308,281.752C167.077,285.722 164.443,291.672 159.84,292.108C152.344,292.818 151.942,288.452 151.942,288.452Z" />
        ),
        light: (
          <path d="M163.651,278.941C163.522,278.708 161.167,277.884 160.299,277.77C157.331,277.377 153.034,278.604 153.232,283.288C153.322,285.402 154.845,287.508 157.135,287.598C157.64,287.618 158.917,287.611 160.042,286.62C162.033,284.867 161.336,282.623 161.449,282.85C161.484,282.921 161.944,284.052 161.774,285.102C161.385,287.504 159.253,288.903 156.996,288.832C154.758,288.761 151.703,287.002 151.4,283.708C150.956,278.887 154.296,276.399 156.766,276.027C164.211,274.904 163.651,278.941 163.651,278.941Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M157.921,276.458C153.92,276.458 150.671,279.706 150.671,283.708C150.671,287.709 153.92,290.958 157.921,290.958C161.923,290.958 165.171,287.709 165.171,283.708C165.171,279.706 161.923,276.458 157.921,276.458ZM157.921,280.083C155.921,280.083 154.296,281.707 154.296,283.708C154.296,285.709 155.921,287.333 157.921,287.333C159.922,287.333 161.546,285.709 161.546,283.708C161.546,281.707 159.922,280.083 157.921,280.083Z" />
      ),
      basic: {
        dark: (
          <path d="M151.942,288.452C152.071,288.685 154.425,289.509 155.293,289.623C158.261,290.016 162.558,288.789 162.36,284.105C162.271,281.991 160.747,279.886 158.457,279.795C157.952,279.775 156.675,279.783 155.55,280.773C153.56,282.526 154.257,284.77 154.143,284.543C154.108,284.473 153.648,283.341 153.818,282.291C154.207,279.889 156.339,278.49 158.596,278.561C160.834,278.632 163.035,279.928 164.308,281.752C167.077,285.722 164.443,291.672 159.84,292.108C152.344,292.818 151.942,288.452 151.942,288.452Z" />
        ),
        light: (
          <path d="M163.651,278.941C163.522,278.708 161.167,277.884 160.299,277.77C157.331,277.377 153.034,278.604 153.232,283.288C153.322,285.402 154.845,287.508 157.135,287.598C157.64,287.618 158.917,287.611 160.042,286.62C162.033,284.867 161.336,282.623 161.449,282.85C161.484,282.921 161.944,284.052 161.774,285.102C161.385,287.504 159.253,288.903 156.996,288.832C154.758,288.761 151.703,287.002 151.4,283.708C150.956,278.887 154.296,276.399 156.766,276.027C164.211,274.904 163.651,278.941 163.651,278.941Z" />
        ),
      },
    },
  },

  right: {
    front: {
      base: (
        <path d="M342.079,280.083C340.175,280.188 339.662,276.597 342.079,276.458C346.073,276.228 349.329,279.706 349.329,283.708C349.329,287.709 345.704,285.709 345.704,283.708C345.704,281.707 344.076,279.973 342.079,280.083Z" />
      ),
      basic: {
        dark: (
          <path d="M348.058,288.452C347.929,288.685 345.575,289.509 344.707,289.623C341.739,290.016 337.442,288.789 337.64,284.105C337.729,281.991 339.253,279.886 341.543,279.795C342.048,279.775 343.325,279.783 344.45,280.773C346.44,282.526 345.743,284.77 345.857,284.543C345.892,284.473 346.352,283.341 346.182,282.291C345.793,279.889 343.661,278.49 341.404,278.561C339.166,278.632 336.965,279.928 335.692,281.752C332.923,285.722 335.557,291.672 340.16,292.108C347.656,292.818 348.058,288.452 348.058,288.452Z" />
        ),
        light: (
          <path d="M336.349,278.941C336.478,278.708 338.833,277.884 339.701,277.77C342.669,277.377 346.966,278.604 346.768,283.288C346.678,285.402 345.155,287.508 342.865,287.598C342.36,287.618 341.083,287.611 339.958,286.62C337.967,284.867 338.664,282.623 338.551,282.85C338.516,282.921 338.056,284.052 338.226,285.102C338.615,287.504 340.747,288.903 343.004,288.832C345.242,288.761 348.297,287.002 348.6,283.708C349.044,278.887 345.704,276.399 343.234,276.027C335.789,274.904 336.349,278.941 336.349,278.941Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M342.079,276.458C346.08,276.458 349.329,279.706 349.329,283.708C349.329,287.709 346.08,290.958 342.079,290.958C338.077,290.958 334.829,287.709 334.829,283.708C334.829,279.706 338.077,276.458 342.079,276.458ZM342.079,280.083C344.079,280.083 345.704,281.707 345.704,283.708C345.704,285.709 344.079,287.333 342.079,287.333C340.078,287.333 338.454,285.709 338.454,283.708C338.454,281.707 340.078,280.083 342.079,280.083Z" />
      ),
      basic: {
        dark: (
          <path d="M348.058,288.452C347.929,288.685 345.575,289.509 344.707,289.623C341.739,290.016 337.442,288.789 337.64,284.105C337.729,281.991 339.253,279.886 341.543,279.795C342.048,279.775 343.325,279.783 344.45,280.773C346.44,282.526 345.743,284.77 345.857,284.543C345.892,284.473 346.352,283.341 346.182,282.291C345.793,279.889 343.661,278.49 341.404,278.561C339.166,278.632 336.965,279.928 335.692,281.752C332.923,285.722 335.557,291.672 340.16,292.108C347.656,292.818 348.058,288.452 348.058,288.452Z" />
        ),
        light: (
          <path d="M336.349,278.941C336.478,278.708 338.833,277.884 339.701,277.77C342.669,277.377 346.966,278.604 346.768,283.288C346.678,285.402 345.155,287.508 342.865,287.598C342.36,287.618 341.083,287.611 339.958,286.62C337.967,284.867 338.664,282.623 338.551,282.85C338.516,282.921 338.056,284.052 338.226,285.102C338.615,287.504 340.747,288.903 343.004,288.832C345.242,288.761 348.297,287.002 348.6,283.708C349.044,278.887 345.704,276.399 343.234,276.027C335.789,274.904 336.349,278.941 336.349,278.941Z" />
        ),
      },
    },
  },
};

const shapeEar24 = {
  left: {
    front: {
      base: (
        <path d="M164.716,280.921C166.62,281.026 167.133,277.435 164.716,277.296C160.722,277.067 157.466,280.545 157.466,284.546C157.466,288.548 161.091,286.547 161.091,284.546C161.091,282.546 162.719,280.811 164.716,280.921Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,289.291C158.866,289.523 161.22,290.347 162.088,290.462C165.056,290.854 169.353,289.628 169.155,284.943C169.066,282.829 167.542,280.724 165.252,280.633C164.747,280.613 163.47,280.621 162.345,281.612C160.355,283.364 161.052,285.609 160.938,285.381C160.903,285.311 160.443,284.179 160.613,283.129C161.002,280.728 163.134,279.328 165.391,279.4C167.629,279.471 169.83,280.767 171.103,282.59C173.872,286.561 171.238,292.511 166.635,292.947C159.139,293.657 158.737,289.291 158.737,289.291Z" />
        ),
        light: (
          <path d="M170.446,279.779C170.317,279.547 167.962,278.723 167.094,278.608C164.126,278.216 159.829,279.442 160.027,284.127C160.117,286.241 161.64,288.346 163.93,288.437C164.435,288.457 165.712,288.449 166.837,287.458C168.828,285.706 168.131,283.461 168.244,283.689C168.279,283.759 168.739,284.891 168.569,285.941C168.18,288.342 166.048,289.742 163.791,289.67C161.553,289.599 158.498,287.84 158.195,284.546C157.751,279.726 161.091,277.237 163.561,276.865C171.006,275.743 170.446,279.779 170.446,279.779Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,277.296C160.715,277.296 157.466,280.545 157.466,284.546C157.466,288.548 160.715,291.796 164.716,291.796C168.718,291.796 171.966,288.548 171.966,284.546C171.966,280.545 168.718,277.296 164.716,277.296ZM164.716,280.921C162.716,280.921 161.091,282.546 161.091,284.546C161.091,286.547 162.716,288.171 164.716,288.171C166.717,288.171 168.341,286.547 168.341,284.546C168.341,282.546 166.717,280.921 164.716,280.921Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,289.291C158.866,289.523 161.22,290.347 162.088,290.462C165.056,290.854 169.353,289.628 169.155,284.943C169.066,282.829 167.542,280.724 165.252,280.633C164.747,280.613 163.47,280.621 162.345,281.612C160.355,283.364 161.052,285.609 160.938,285.381C160.903,285.311 160.443,284.179 160.613,283.129C161.002,280.728 163.134,279.328 165.391,279.4C167.629,279.471 169.83,280.767 171.103,282.59C173.872,286.561 171.238,292.511 166.635,292.947C159.139,293.657 158.737,289.291 158.737,289.291Z" />
        ),
        light: (
          <path d="M170.446,279.779C170.317,279.547 167.962,278.723 167.094,278.608C164.126,278.216 159.829,279.442 160.027,284.127C160.117,286.241 161.64,288.346 163.93,288.437C164.435,288.457 165.712,288.449 166.837,287.458C168.828,285.706 168.131,283.461 168.244,283.689C168.279,283.759 168.739,284.891 168.569,285.941C168.18,288.342 166.048,289.742 163.791,289.67C161.553,289.599 158.498,287.84 158.195,284.546C157.751,279.726 161.091,277.237 163.561,276.865C171.006,275.743 170.446,279.779 170.446,279.779Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.284,280.921C333.38,281.026 332.867,277.435 335.284,277.296C339.278,277.067 342.534,280.545 342.534,284.546C342.534,288.548 338.909,286.547 338.909,284.546C338.909,282.546 337.281,280.811 335.284,280.921Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,289.291C341.134,289.523 338.78,290.347 337.912,290.462C334.944,290.854 330.647,289.628 330.845,284.943C330.934,282.829 332.458,280.724 334.748,280.633C335.253,280.613 336.53,280.621 337.655,281.612C339.645,283.364 338.948,285.609 339.062,285.381C339.097,285.311 339.557,284.179 339.387,283.129C338.998,280.728 336.866,279.328 334.609,279.4C332.371,279.471 330.17,280.767 328.897,282.59C326.128,286.561 328.762,292.511 333.365,292.947C340.861,293.657 341.263,289.291 341.263,289.291Z" />
        ),
        light: (
          <path d="M329.554,279.779C329.683,279.547 332.038,278.723 332.906,278.608C335.874,278.216 340.171,279.442 339.973,284.127C339.883,286.241 338.36,288.346 336.07,288.437C335.565,288.457 334.288,288.449 333.163,287.458C331.172,285.706 331.869,283.461 331.756,283.689C331.721,283.759 331.261,284.891 331.431,285.941C331.82,288.342 333.952,289.742 336.209,289.67C338.447,289.599 341.502,287.84 341.805,284.546C342.249,279.726 338.909,277.237 336.439,276.865C328.994,275.743 329.554,279.779 329.554,279.779Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.284,277.296C339.285,277.296 342.534,280.545 342.534,284.546C342.534,288.548 339.285,291.796 335.284,291.796C331.282,291.796 328.034,288.548 328.034,284.546C328.034,280.545 331.282,277.296 335.284,277.296ZM335.284,280.921C337.284,280.921 338.909,282.546 338.909,284.546C338.909,286.547 337.284,288.171 335.284,288.171C333.283,288.171 331.659,286.547 331.659,284.546C331.659,282.546 333.283,280.921 335.284,280.921Z" />
      ),
      basic: {
        dark: (
          <path d="M341.263,289.291C341.134,289.523 338.78,290.347 337.912,290.462C334.944,290.854 330.647,289.628 330.845,284.943C330.934,282.829 332.458,280.724 334.748,280.633C335.253,280.613 336.53,280.621 337.655,281.612C339.645,283.364 338.948,285.609 339.062,285.381C339.097,285.311 339.557,284.179 339.387,283.129C338.998,280.728 336.866,279.328 334.609,279.4C332.371,279.471 330.17,280.767 328.897,282.59C326.128,286.561 328.762,292.511 333.365,292.947C340.861,293.657 341.263,289.291 341.263,289.291Z" />
        ),
        light: (
          <path d="M329.554,279.779C329.683,279.547 332.038,278.723 332.906,278.608C335.874,278.216 340.171,279.442 339.973,284.127C339.883,286.241 338.36,288.346 336.07,288.437C335.565,288.457 334.288,288.449 333.163,287.458C331.172,285.706 331.869,283.461 331.756,283.689C331.721,283.759 331.261,284.891 331.431,285.941C331.82,288.342 333.952,289.742 336.209,289.67C338.447,289.599 341.502,287.84 341.805,284.546C342.249,279.726 338.909,277.237 336.439,276.865C328.994,275.743 329.554,279.779 329.554,279.779Z" />
        ),
      },
    },
  },
};

const shapeEar25 = {
  left: {
    front: {
      base: (
        <path d="M168.129,200.149C170.033,200.254 170.545,196.663 168.129,196.524C164.134,196.295 160.879,199.773 160.879,203.774C160.879,207.776 164.504,205.775 164.504,203.774C164.504,201.774 166.131,200.039 168.129,200.149Z" />
      ),
      basic: {
        dark: (
          <path d="M162.149,208.519C162.278,208.751 164.632,209.575 165.5,209.69C168.469,210.082 172.766,208.856 172.568,204.171C172.478,202.057 170.955,199.952 168.665,199.861C168.159,199.841 166.882,199.849 165.757,200.84C163.767,202.592 164.464,204.837 164.35,204.609C164.315,204.539 163.856,203.407 164.026,202.357C164.414,199.956 166.547,198.556 168.803,198.628C171.042,198.699 173.243,199.995 174.515,201.818C177.285,205.789 174.651,211.739 170.047,212.175C162.552,212.885 162.149,208.519 162.149,208.519Z" />
        ),
        light: (
          <path d="M173.858,199.007C173.729,198.775 171.375,197.951 170.507,197.836C167.538,197.444 163.241,198.67 163.44,203.355C163.529,205.469 165.052,207.574 167.342,207.665C167.848,207.685 169.125,207.677 170.25,206.686C172.24,204.934 171.543,202.69 171.657,202.917C171.692,202.987 172.151,204.119 171.981,205.169C171.593,207.57 169.461,208.97 167.204,208.898C164.965,208.827 161.91,207.068 161.607,203.774C161.164,198.954 164.503,196.465 166.973,196.093C174.418,194.971 173.858,199.007 173.858,199.007Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M168.129,196.524C164.127,196.524 160.879,199.773 160.879,203.774C160.879,207.776 164.127,211.024 168.129,211.024C172.13,211.024 175.379,207.776 175.379,203.774C175.379,199.773 172.13,196.524 168.129,196.524ZM168.129,200.149C166.128,200.149 164.504,201.774 164.504,203.774C164.504,205.775 166.128,207.399 168.129,207.399C170.13,207.399 171.754,205.775 171.754,203.774C171.754,201.774 170.13,200.149 168.129,200.149Z" />
      ),
      basic: {
        dark: (
          <path d="M162.149,208.519C162.278,208.751 164.632,209.575 165.5,209.69C168.469,210.082 172.766,208.856 172.568,204.171C172.478,202.057 170.955,199.952 168.665,199.861C168.159,199.841 166.882,199.849 165.757,200.84C163.767,202.592 164.464,204.837 164.35,204.609C164.315,204.539 163.856,203.407 164.026,202.357C164.414,199.956 166.547,198.556 168.803,198.628C171.042,198.699 173.243,199.995 174.515,201.818C177.285,205.789 174.651,211.739 170.047,212.175C162.552,212.885 162.149,208.519 162.149,208.519Z" />
        ),
        light: (
          <path d="M173.858,199.007C173.729,198.775 171.375,197.951 170.507,197.836C167.538,197.444 163.241,198.67 163.44,203.355C163.529,205.469 165.052,207.574 167.342,207.665C167.848,207.685 169.125,207.677 170.25,206.686C172.24,204.934 171.543,202.69 171.657,202.917C171.692,202.987 172.151,204.119 171.981,205.169C171.593,207.57 169.461,208.97 167.204,208.898C164.965,208.827 161.91,207.068 161.607,203.774C161.164,198.954 164.503,196.465 166.973,196.093C174.418,194.971 173.858,199.007 173.858,199.007Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M331.871,200.149C329.967,200.254 329.455,196.663 331.871,196.524C335.866,196.295 339.121,199.773 339.121,203.774C339.121,207.776 335.496,205.775 335.496,203.774C335.496,201.774 333.869,200.039 331.871,200.149Z" />
      ),
      basic: {
        dark: (
          <path d="M337.851,208.519C337.722,208.751 335.368,209.575 334.5,209.69C331.531,210.082 327.234,208.856 327.432,204.171C327.522,202.057 329.045,199.952 331.335,199.861C331.841,199.841 333.118,199.849 334.243,200.84C336.233,202.592 335.536,204.837 335.65,204.609C335.685,204.539 336.144,203.407 335.974,202.357C335.586,199.956 333.453,198.556 331.197,198.628C328.958,198.699 326.757,199.995 325.485,201.818C322.715,205.789 325.349,211.739 329.953,212.175C337.448,212.885 337.851,208.519 337.851,208.519Z" />
        ),
        light: (
          <path d="M326.142,199.007C326.271,198.775 328.625,197.951 329.493,197.836C332.462,197.444 336.759,198.67 336.56,203.355C336.471,205.469 334.948,207.574 332.658,207.665C332.152,207.685 330.875,207.677 329.75,206.686C327.76,204.934 328.457,202.69 328.343,202.917C328.308,202.987 327.849,204.119 328.019,205.169C328.407,207.57 330.539,208.97 332.796,208.898C335.035,208.827 338.09,207.068 338.393,203.774C338.836,198.954 335.497,196.465 333.027,196.093C325.582,194.971 326.142,199.007 326.142,199.007Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M331.871,196.524C335.873,196.524 339.121,199.773 339.121,203.774C339.121,207.776 335.873,211.024 331.871,211.024C327.87,211.024 324.621,207.776 324.621,203.774C324.621,199.773 327.87,196.524 331.871,196.524ZM331.871,200.149C333.872,200.149 335.496,201.774 335.496,203.774C335.496,205.775 333.872,207.399 331.871,207.399C329.87,207.399 328.246,205.775 328.246,203.774C328.246,201.774 329.87,200.149 331.871,200.149Z" />
      ),
      basic: {
        dark: (
          <path d="M337.851,208.519C337.722,208.751 335.368,209.575 334.5,209.69C331.531,210.082 327.234,208.856 327.432,204.171C327.522,202.057 329.045,199.952 331.335,199.861C331.841,199.841 333.118,199.849 334.243,200.84C336.233,202.592 335.536,204.837 335.65,204.609C335.685,204.539 336.144,203.407 335.974,202.357C335.586,199.956 333.453,198.556 331.197,198.628C328.958,198.699 326.757,199.995 325.485,201.818C322.715,205.789 325.349,211.739 329.953,212.175C337.448,212.885 337.851,208.519 337.851,208.519Z" />
        ),
        light: (
          <path d="M326.142,199.007C326.271,198.775 328.625,197.951 329.493,197.836C332.462,197.444 336.759,198.67 336.56,203.355C336.471,205.469 334.948,207.574 332.658,207.665C332.152,207.685 330.875,207.677 329.75,206.686C327.76,204.934 328.457,202.69 328.343,202.917C328.308,202.987 327.849,204.119 328.019,205.169C328.407,207.57 330.539,208.97 332.796,208.898C335.035,208.827 338.09,207.068 338.393,203.774C338.836,198.954 335.497,196.465 333.027,196.093C325.582,194.971 326.142,199.007 326.142,199.007Z" />
        ),
      },
    },
  },
};

const shapeEar26 = {
  left: {
    front: {
      base: (
        <path d="M163.589,290.441C165.492,290.546 166.005,286.955 163.589,286.816C159.594,286.587 156.339,290.065 156.339,294.066C156.339,298.067 159.964,296.067 159.964,294.066C159.964,292.065 161.591,290.331 163.589,290.441Z" />
      ),
      basic: {
        dark: (
          <path d="M157.609,298.81C157.738,299.043 160.092,299.867 160.96,299.981C163.929,300.374 168.225,299.147 168.027,294.463C167.938,292.349 166.415,290.244 164.125,290.153C163.619,290.133 162.342,290.141 161.217,291.131C159.227,292.884 159.924,295.128 159.81,294.901C159.775,294.831 159.316,293.699 159.485,292.649C159.874,290.247 162.006,288.848 164.263,288.919C166.501,288.99 168.703,290.286 169.975,292.11C172.744,296.08 170.111,302.03 165.507,302.466C158.011,303.176 157.609,298.81 157.609,298.81Z" />
        ),
        light: (
          <path d="M169.318,289.299C169.189,289.066 166.834,288.243 165.966,288.128C162.998,287.735 158.701,288.962 158.899,293.646C158.989,295.76 160.512,297.866 162.802,297.956C163.307,297.976 164.584,297.969 165.71,296.978C167.7,295.225 167.003,292.981 167.116,293.208C167.151,293.279 167.611,294.41 167.441,295.46C167.053,297.862 164.92,299.261 162.664,299.19C160.425,299.119 157.37,297.36 157.067,294.066C156.623,289.245 159.963,286.757 162.433,286.385C169.878,285.262 169.318,289.299 169.318,289.299Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M163.589,286.816C159.587,286.816 156.339,290.065 156.339,294.066C156.339,298.067 159.587,301.316 163.589,301.316C167.59,301.316 170.839,298.067 170.839,294.066C170.839,290.065 167.59,286.816 163.589,286.816ZM163.589,290.441C161.588,290.441 159.964,292.065 159.964,294.066C159.964,296.067 161.588,297.691 163.589,297.691C165.589,297.691 167.214,296.067 167.214,294.066C167.214,292.065 165.589,290.441 163.589,290.441Z" />
      ),
      basic: {
        dark: (
          <path d="M157.609,298.81C157.738,299.043 160.092,299.867 160.96,299.981C163.929,300.374 168.225,299.147 168.027,294.463C167.938,292.349 166.415,290.244 164.125,290.153C163.619,290.133 162.342,290.141 161.217,291.131C159.227,292.884 159.924,295.128 159.81,294.901C159.775,294.831 159.316,293.699 159.485,292.649C159.874,290.247 162.006,288.848 164.263,288.919C166.501,288.99 168.703,290.286 169.975,292.11C172.744,296.08 170.111,302.03 165.507,302.466C158.011,303.176 157.609,298.81 157.609,298.81Z" />
        ),
        light: (
          <path d="M169.318,289.299C169.189,289.066 166.834,288.243 165.966,288.128C162.998,287.735 158.701,288.962 158.899,293.646C158.989,295.76 160.512,297.866 162.802,297.956C163.307,297.976 164.584,297.969 165.71,296.978C167.7,295.225 167.003,292.981 167.116,293.208C167.151,293.279 167.611,294.41 167.441,295.46C167.053,297.862 164.92,299.261 162.664,299.19C160.425,299.119 157.37,297.36 157.067,294.066C156.623,289.245 159.963,286.757 162.433,286.385C169.878,285.262 169.318,289.299 169.318,289.299Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M336.374,290.441C334.47,290.546 333.957,286.955 336.374,286.816C340.369,286.587 343.624,290.065 343.624,294.066C343.624,298.067 339.999,296.067 339.999,294.066C339.999,292.065 338.372,290.331 336.374,290.441Z" />
      ),
      basic: {
        dark: (
          <path d="M342.354,298.81C342.225,299.043 339.87,299.867 339.002,299.981C336.034,300.374 331.737,299.147 331.935,294.463C332.025,292.349 333.548,290.244 335.838,290.153C336.343,290.133 337.62,290.141 338.746,291.131C340.736,292.884 340.039,295.128 340.152,294.901C340.187,294.831 340.647,293.699 340.477,292.649C340.089,290.247 337.956,288.848 335.699,288.919C333.461,288.99 331.26,290.286 329.988,292.11C327.218,296.08 329.852,302.03 334.456,302.466C341.951,303.176 342.354,298.81 342.354,298.81Z" />
        ),
        light: (
          <path d="M330.645,289.299C330.774,289.066 333.128,288.243 333.996,288.128C336.964,287.735 341.261,288.962 341.063,293.646C340.974,295.76 339.45,297.866 337.16,297.956C336.655,297.976 335.378,297.969 334.253,296.978C332.263,295.225 332.96,292.981 332.846,293.208C332.811,293.279 332.351,294.41 332.521,295.46C332.91,297.862 335.042,299.261 337.299,299.19C339.537,299.119 342.593,297.36 342.896,294.066C343.339,289.245 340,286.757 337.529,286.385C330.085,285.262 330.645,289.299 330.645,289.299Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M336.374,286.816C340.375,286.816 343.624,290.065 343.624,294.066C343.624,298.067 340.375,301.316 336.374,301.316C332.372,301.316 329.124,298.067 329.124,294.066C329.124,290.065 332.372,286.816 336.374,286.816ZM336.374,290.441C338.375,290.441 339.999,292.065 339.999,294.066C339.999,296.067 338.375,297.691 336.374,297.691C334.373,297.691 332.749,296.067 332.749,294.066C332.749,292.065 334.373,290.441 336.374,290.441Z" />
      ),
      basic: {
        dark: (
          <path d="M342.354,298.81C342.225,299.043 339.87,299.867 339.002,299.981C336.034,300.374 331.737,299.147 331.935,294.463C332.025,292.349 333.548,290.244 335.838,290.153C336.343,290.133 337.62,290.141 338.746,291.131C340.736,292.884 340.039,295.128 340.152,294.901C340.187,294.831 340.647,293.699 340.477,292.649C340.089,290.247 337.956,288.848 335.699,288.919C333.461,288.99 331.26,290.286 329.988,292.11C327.218,296.08 329.852,302.03 334.456,302.466C341.951,303.176 342.354,298.81 342.354,298.81Z" />
        ),
        light: (
          <path d="M330.645,289.299C330.774,289.066 333.128,288.243 333.996,288.128C336.964,287.735 341.261,288.962 341.063,293.646C340.974,295.76 339.45,297.866 337.16,297.956C336.655,297.976 335.378,297.969 334.253,296.978C332.263,295.225 332.96,292.981 332.846,293.208C332.811,293.279 332.351,294.41 332.521,295.46C332.91,297.862 335.042,299.261 337.299,299.19C339.537,299.119 342.593,297.36 342.896,294.066C343.339,289.245 340,286.757 337.529,286.385C330.085,285.262 330.645,289.299 330.645,289.299Z" />
        ),
      },
    },
  },
};

const shapeEar27 = {
  left: {
    front: {
      base: (
        <path d="M150.715,286.836C152.619,286.941 153.132,283.349 150.715,283.211C146.72,282.981 143.465,286.459 143.465,290.461C143.465,294.462 147.09,292.461 147.09,290.461C147.09,288.46 148.718,286.726 150.715,286.836Z" />
      ),
      basic: {
        dark: (
          <path d="M144.735,295.205C144.864,295.438 147.219,296.261 148.087,296.376C151.055,296.769 155.352,295.542 155.154,290.858C155.064,288.744 153.541,286.638 151.251,286.548C150.746,286.528 149.469,286.535 148.344,287.526C146.353,289.279 147.05,291.523 146.937,291.296C146.902,291.225 146.442,290.094 146.612,289.044C147.001,286.642 149.133,285.243 151.39,285.314C153.628,285.385 155.829,286.681 157.101,288.505C159.871,292.475 157.237,298.425 152.633,298.861C145.138,299.571 144.735,295.205 144.735,295.205Z" />
        ),
        light: (
          <path d="M156.444,285.694C156.315,285.461 153.961,284.637 153.093,284.523C150.125,284.13 145.828,285.357 146.026,290.041C146.115,292.155 147.639,294.26 149.929,294.351C150.434,294.371 151.711,294.363 152.836,293.373C154.826,291.62 154.129,289.376 154.243,289.603C154.278,289.673 154.738,290.805 154.568,291.855C154.179,294.257 152.047,295.656 149.79,295.585C147.552,295.514 144.496,293.755 144.193,290.461C143.75,285.64 147.09,283.152 149.56,282.779C157.004,281.657 156.444,285.694 156.444,285.694Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M150.715,283.211C146.714,283.211 143.465,286.459 143.465,290.461C143.465,294.462 146.714,297.711 150.715,297.711C154.717,297.711 157.965,294.462 157.965,290.461C157.965,286.459 154.717,283.211 150.715,283.211ZM150.715,286.836C148.715,286.836 147.09,288.46 147.09,290.461C147.09,292.461 148.715,294.086 150.715,294.086C152.716,294.086 154.34,292.461 154.34,290.461C154.34,288.46 152.716,286.836 150.715,286.836Z" />
      ),
      basic: {
        dark: (
          <path d="M144.735,295.205C144.864,295.438 147.219,296.261 148.087,296.376C151.055,296.769 155.352,295.542 155.154,290.858C155.064,288.744 153.541,286.638 151.251,286.548C150.746,286.528 149.469,286.535 148.344,287.526C146.353,289.279 147.05,291.523 146.937,291.296C146.902,291.225 146.442,290.094 146.612,289.044C147.001,286.642 149.133,285.243 151.39,285.314C153.628,285.385 155.829,286.681 157.101,288.505C159.871,292.475 157.237,298.425 152.633,298.861C145.138,299.571 144.735,295.205 144.735,295.205Z" />
        ),
        light: (
          <path d="M156.444,285.694C156.315,285.461 153.961,284.637 153.093,284.523C150.125,284.13 145.828,285.357 146.026,290.041C146.115,292.155 147.639,294.26 149.929,294.351C150.434,294.371 151.711,294.363 152.836,293.373C154.826,291.62 154.129,289.376 154.243,289.603C154.278,289.673 154.738,290.805 154.568,291.855C154.179,294.257 152.047,295.656 149.79,295.585C147.552,295.514 144.496,293.755 144.193,290.461C143.75,285.64 147.09,283.152 149.56,282.779C157.004,281.657 156.444,285.694 156.444,285.694Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M349.285,286.836C347.381,286.941 346.868,283.349 349.285,283.211C353.28,282.981 356.535,286.459 356.535,290.461C356.535,294.462 352.91,292.461 352.91,290.461C352.91,288.46 351.282,286.726 349.285,286.836Z" />
      ),
      basic: {
        dark: (
          <path d="M355.265,295.205C355.136,295.438 352.781,296.261 351.913,296.376C348.945,296.769 344.648,295.542 344.846,290.858C344.936,288.744 346.459,286.638 348.749,286.548C349.254,286.528 350.531,286.535 351.656,287.526C353.647,289.279 352.95,291.523 353.063,291.296C353.098,291.225 353.558,290.094 353.388,289.044C352.999,286.642 350.867,285.243 348.61,285.314C346.372,285.385 344.171,286.681 342.899,288.505C340.129,292.475 342.763,298.425 347.367,298.861C354.862,299.571 355.265,295.205 355.265,295.205Z" />
        ),
        light: (
          <path d="M343.556,285.694C343.685,285.461 346.039,284.637 346.907,284.523C349.875,284.13 354.172,285.357 353.974,290.041C353.885,292.155 352.361,294.26 350.071,294.351C349.566,294.371 348.289,294.363 347.164,293.373C345.174,291.62 345.871,289.376 345.757,289.603C345.722,289.673 345.262,290.805 345.432,291.855C345.821,294.257 347.953,295.656 350.21,295.585C352.448,295.514 355.504,293.755 355.807,290.461C356.25,285.64 352.91,283.152 350.44,282.779C342.996,281.657 343.556,285.694 343.556,285.694Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M349.285,283.211C353.286,283.211 356.535,286.459 356.535,290.461C356.535,294.462 353.286,297.711 349.285,297.711C345.283,297.711 342.035,294.462 342.035,290.461C342.035,286.459 345.283,283.211 349.285,283.211ZM349.285,286.836C351.285,286.836 352.91,288.46 352.91,290.461C352.91,292.461 351.285,294.086 349.285,294.086C347.284,294.086 345.66,292.461 345.66,290.461C345.66,288.46 347.284,286.836 349.285,286.836Z" />
      ),
      basic: {
        dark: (
          <path d="M355.265,295.205C355.136,295.438 352.781,296.261 351.913,296.376C348.945,296.769 344.648,295.542 344.846,290.858C344.936,288.744 346.459,286.638 348.749,286.548C349.254,286.528 350.531,286.535 351.656,287.526C353.647,289.279 352.95,291.523 353.063,291.296C353.098,291.225 353.558,290.094 353.388,289.044C352.999,286.642 350.867,285.243 348.61,285.314C346.372,285.385 344.171,286.681 342.899,288.505C340.129,292.475 342.763,298.425 347.367,298.861C354.862,299.571 355.265,295.205 355.265,295.205Z" />
        ),
        light: (
          <path d="M343.556,285.694C343.685,285.461 346.039,284.637 346.907,284.523C349.875,284.13 354.172,285.357 353.974,290.041C353.885,292.155 352.361,294.26 350.071,294.351C349.566,294.371 348.289,294.363 347.164,293.373C345.174,291.62 345.871,289.376 345.757,289.603C345.722,289.673 345.262,290.805 345.432,291.855C345.821,294.257 347.953,295.656 350.21,295.585C352.448,295.514 355.504,293.755 355.807,290.461C356.25,285.64 352.91,283.152 350.44,282.779C342.996,281.657 343.556,285.694 343.556,285.694Z" />
        ),
      },
    },
  },
};

const shapeEar28 = {
  left: {
    front: {
      base: (
        <path d="M168.129,196.359C170.033,196.464 170.545,192.873 168.129,192.734C164.134,192.505 160.879,195.983 160.879,199.984C160.879,203.986 164.504,201.985 164.504,199.984C164.504,197.984 166.131,196.249 168.129,196.359Z" />
      ),
      basic: {
        dark: (
          <path d="M162.149,204.729C162.278,204.961 164.632,205.785 165.5,205.9C168.469,206.292 172.766,205.066 172.568,200.381C172.478,198.267 170.955,196.162 168.665,196.071C168.159,196.051 166.882,196.059 165.757,197.05C163.767,198.802 164.464,201.047 164.35,200.819C164.315,200.749 163.856,199.617 164.026,198.567C164.414,196.166 166.547,194.766 168.803,194.838C171.042,194.909 173.243,196.205 174.515,198.028C177.285,201.999 174.651,207.948 170.047,208.385C162.552,209.095 162.149,204.729 162.149,204.729Z" />
        ),
        light: (
          <path d="M173.858,195.217C173.729,194.985 171.375,194.161 170.507,194.046C167.538,193.654 163.241,194.88 163.44,199.565C163.529,201.679 165.052,203.784 167.342,203.875C167.848,203.895 169.125,203.887 170.25,202.896C172.24,201.144 171.543,198.899 171.657,199.127C171.692,199.197 172.151,200.329 171.981,201.379C171.593,203.78 169.461,205.18 167.204,205.108C164.965,205.037 161.91,203.278 161.607,199.984C161.164,195.164 164.503,192.675 166.973,192.303C174.418,191.181 173.858,195.217 173.858,195.217Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M168.129,192.734C164.127,192.734 160.879,195.983 160.879,199.984C160.879,203.986 164.127,207.234 168.129,207.234C172.13,207.234 175.379,203.986 175.379,199.984C175.379,195.983 172.13,192.734 168.129,192.734ZM168.129,196.359C166.128,196.359 164.504,197.984 164.504,199.984C164.504,201.985 166.128,203.609 168.129,203.609C170.13,203.609 171.754,201.985 171.754,199.984C171.754,197.984 170.13,196.359 168.129,196.359Z" />
      ),
      basic: {
        dark: (
          <path d="M162.149,204.729C162.278,204.961 164.632,205.785 165.5,205.9C168.469,206.292 172.766,205.066 172.568,200.381C172.478,198.267 170.955,196.162 168.665,196.071C168.159,196.051 166.882,196.059 165.757,197.05C163.767,198.802 164.464,201.047 164.35,200.819C164.315,200.749 163.856,199.617 164.026,198.567C164.414,196.166 166.547,194.766 168.803,194.838C171.042,194.909 173.243,196.205 174.515,198.028C177.285,201.999 174.651,207.948 170.047,208.385C162.552,209.095 162.149,204.729 162.149,204.729Z" />
        ),
        light: (
          <path d="M173.858,195.217C173.729,194.985 171.375,194.161 170.507,194.046C167.538,193.654 163.241,194.88 163.44,199.565C163.529,201.679 165.052,203.784 167.342,203.875C167.848,203.895 169.125,203.887 170.25,202.896C172.24,201.144 171.543,198.899 171.657,199.127C171.692,199.197 172.151,200.329 171.981,201.379C171.593,203.78 169.461,205.18 167.204,205.108C164.965,205.037 161.91,203.278 161.607,199.984C161.164,195.164 164.503,192.675 166.973,192.303C174.418,191.181 173.858,195.217 173.858,195.217Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M331.871,196.359C329.967,196.464 329.455,192.873 331.871,192.734C335.866,192.505 339.121,195.983 339.121,199.984C339.121,203.986 335.496,201.985 335.496,199.984C335.496,197.984 333.869,196.249 331.871,196.359Z" />
      ),
      basic: {
        dark: (
          <path d="M337.851,204.729C337.722,204.961 335.368,205.785 334.5,205.9C331.531,206.292 327.234,205.066 327.432,200.381C327.522,198.267 329.045,196.162 331.335,196.071C331.841,196.051 333.118,196.059 334.243,197.05C336.233,198.802 335.536,201.047 335.65,200.819C335.685,200.749 336.144,199.617 335.974,198.567C335.586,196.166 333.453,194.766 331.197,194.838C328.958,194.909 326.757,196.205 325.485,198.028C322.715,201.999 325.349,207.948 329.953,208.385C337.448,209.095 337.851,204.729 337.851,204.729Z" />
        ),
        light: (
          <path d="M326.142,195.217C326.271,194.985 328.625,194.161 329.493,194.046C332.462,193.654 336.759,194.88 336.56,199.565C336.471,201.679 334.948,203.784 332.658,203.875C332.152,203.895 330.875,203.887 329.75,202.896C327.76,201.144 328.457,198.899 328.343,199.127C328.308,199.197 327.849,200.329 328.019,201.379C328.407,203.78 330.539,205.18 332.796,205.108C335.035,205.037 338.09,203.278 338.393,199.984C338.836,195.164 335.497,192.675 333.027,192.303C325.582,191.181 326.142,195.217 326.142,195.217Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M331.871,192.734C335.873,192.734 339.121,195.983 339.121,199.984C339.121,203.986 335.873,207.234 331.871,207.234C327.87,207.234 324.621,203.986 324.621,199.984C324.621,195.983 327.87,192.734 331.871,192.734ZM331.871,196.359C333.872,196.359 335.496,197.984 335.496,199.984C335.496,201.985 333.872,203.609 331.871,203.609C329.87,203.609 328.246,201.985 328.246,199.984C328.246,197.984 329.87,196.359 331.871,196.359Z" />
      ),
      basic: {
        dark: (
          <path d="M337.851,204.729C337.722,204.961 335.368,205.785 334.5,205.9C331.531,206.292 327.234,205.066 327.432,200.381C327.522,198.267 329.045,196.162 331.335,196.071C331.841,196.051 333.118,196.059 334.243,197.05C336.233,198.802 335.536,201.047 335.65,200.819C335.685,200.749 336.144,199.617 335.974,198.567C335.586,196.166 333.453,194.766 331.197,194.838C328.958,194.909 326.757,196.205 325.485,198.028C322.715,201.999 325.349,207.948 329.953,208.385C337.448,209.095 337.851,204.729 337.851,204.729Z" />
        ),
        light: (
          <path d="M326.142,195.217C326.271,194.985 328.625,194.161 329.493,194.046C332.462,193.654 336.759,194.88 336.56,199.565C336.471,201.679 334.948,203.784 332.658,203.875C332.152,203.895 330.875,203.887 329.75,202.896C327.76,201.144 328.457,198.899 328.343,199.127C328.308,199.197 327.849,200.329 328.019,201.379C328.407,203.78 330.539,205.18 332.796,205.108C335.035,205.037 338.09,203.278 338.393,199.984C338.836,195.164 335.497,192.675 333.027,192.303C325.582,191.181 326.142,195.217 326.142,195.217Z" />
        ),
      },
    },
  },
};

const shapeEar29 = {
  left: {
    front: {
      base: (
        <path d="M152.186,241.321C154.09,241.426 154.603,237.835 152.186,237.696C148.192,237.466 144.936,240.944 144.936,244.946C144.936,248.947 148.561,246.946 148.561,244.946C148.561,242.945 150.189,241.211 152.186,241.321Z" />
      ),
      basic: {
        dark: (
          <path d="M146.207,249.69C146.336,249.923 148.69,250.747 149.558,250.861C152.526,251.254 156.823,250.027 156.625,245.343C156.536,243.229 155.012,241.124 152.722,241.033C152.217,241.013 150.94,241.021 149.815,242.011C147.825,243.764 148.522,246.008 148.408,245.781C148.373,245.711 147.913,244.579 148.083,243.529C148.472,241.127 150.604,239.728 152.861,239.799C155.099,239.87 157.3,241.166 158.573,242.99C161.342,246.96 158.708,252.91 154.105,253.346C146.609,254.056 146.207,249.69 146.207,249.69Z" />
        ),
        light: (
          <path d="M157.916,240.179C157.787,239.946 155.432,239.122 154.564,239.008C151.596,238.615 147.299,239.842 147.497,244.526C147.587,246.64 149.11,248.745 151.4,248.836C151.905,248.856 153.182,248.848 154.307,247.858C156.298,246.105 155.601,243.861 155.714,244.088C155.749,244.158 156.209,245.29 156.039,246.34C155.65,248.742 153.518,250.141 151.261,250.07C149.023,249.999 145.968,248.24 145.665,244.946C145.221,240.125 148.561,237.637 151.031,237.264C158.476,236.142 157.916,240.179 157.916,240.179Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M152.186,237.696C148.185,237.696 144.936,240.944 144.936,244.946C144.936,248.947 148.185,252.196 152.186,252.196C156.188,252.196 159.436,248.947 159.436,244.946C159.436,240.944 156.188,237.696 152.186,237.696ZM152.186,241.321C150.186,241.321 148.561,242.945 148.561,244.946C148.561,246.946 150.186,248.571 152.186,248.571C154.187,248.571 155.811,246.946 155.811,244.946C155.811,242.945 154.187,241.321 152.186,241.321Z" />
      ),
      basic: {
        dark: (
          <path d="M146.207,249.69C146.336,249.923 148.69,250.747 149.558,250.861C152.526,251.254 156.823,250.027 156.625,245.343C156.536,243.229 155.012,241.124 152.722,241.033C152.217,241.013 150.94,241.021 149.815,242.011C147.825,243.764 148.522,246.008 148.408,245.781C148.373,245.711 147.913,244.579 148.083,243.529C148.472,241.127 150.604,239.728 152.861,239.799C155.099,239.87 157.3,241.166 158.573,242.99C161.342,246.96 158.708,252.91 154.105,253.346C146.609,254.056 146.207,249.69 146.207,249.69Z" />
        ),
        light: (
          <path d="M157.916,240.179C157.787,239.946 155.432,239.122 154.564,239.008C151.596,238.615 147.299,239.842 147.497,244.526C147.587,246.64 149.11,248.745 151.4,248.836C151.905,248.856 153.182,248.848 154.307,247.858C156.298,246.105 155.601,243.861 155.714,244.088C155.749,244.158 156.209,245.29 156.039,246.34C155.65,248.742 153.518,250.141 151.261,250.07C149.023,249.999 145.968,248.24 145.665,244.946C145.221,240.125 148.561,237.637 151.031,237.264C158.476,236.142 157.916,240.179 157.916,240.179Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M347.814,241.321C345.91,241.426 345.397,237.835 347.814,237.696C351.808,237.466 355.064,240.944 355.064,244.946C355.064,248.947 351.439,246.946 351.439,244.946C351.439,242.945 349.811,241.211 347.814,241.321Z" />
      ),
      basic: {
        dark: (
          <path d="M353.793,249.69C353.664,249.923 351.31,250.747 350.442,250.861C347.474,251.254 343.177,250.027 343.375,245.343C343.464,243.229 344.988,241.124 347.278,241.033C347.783,241.013 349.06,241.021 350.185,242.011C352.175,243.764 351.478,246.008 351.592,245.781C351.627,245.711 352.087,244.579 351.917,243.529C351.528,241.127 349.396,239.728 347.139,239.799C344.901,239.87 342.7,241.166 341.427,242.99C338.658,246.96 341.292,252.91 345.895,253.346C353.391,254.056 353.793,249.69 353.793,249.69Z" />
        ),
        light: (
          <path d="M342.084,240.179C342.213,239.946 344.568,239.122 345.436,239.008C348.404,238.615 352.701,239.842 352.503,244.526C352.413,246.64 350.89,248.745 348.6,248.836C348.095,248.856 346.818,248.848 345.693,247.858C343.702,246.105 344.399,243.861 344.286,244.088C344.251,244.158 343.791,245.29 343.961,246.34C344.35,248.742 346.482,250.141 348.739,250.07C350.977,249.999 354.032,248.24 354.335,244.946C354.779,240.125 351.439,237.637 348.969,237.264C341.524,236.142 342.084,240.179 342.084,240.179Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M347.814,237.696C351.815,237.696 355.064,240.944 355.064,244.946C355.064,248.947 351.815,252.196 347.814,252.196C343.812,252.196 340.564,248.947 340.564,244.946C340.564,240.944 343.812,237.696 347.814,237.696ZM347.814,241.321C349.814,241.321 351.439,242.945 351.439,244.946C351.439,246.946 349.814,248.571 347.814,248.571C345.813,248.571 344.189,246.946 344.189,244.946C344.189,242.945 345.813,241.321 347.814,241.321Z" />
      ),
      basic: {
        dark: (
          <path d="M353.793,249.69C353.664,249.923 351.31,250.747 350.442,250.861C347.474,251.254 343.177,250.027 343.375,245.343C343.464,243.229 344.988,241.124 347.278,241.033C347.783,241.013 349.06,241.021 350.185,242.011C352.175,243.764 351.478,246.008 351.592,245.781C351.627,245.711 352.087,244.579 351.917,243.529C351.528,241.127 349.396,239.728 347.139,239.799C344.901,239.87 342.7,241.166 341.427,242.99C338.658,246.96 341.292,252.91 345.895,253.346C353.391,254.056 353.793,249.69 353.793,249.69Z" />
        ),
        light: (
          <path d="M342.084,240.179C342.213,239.946 344.568,239.122 345.436,239.008C348.404,238.615 352.701,239.842 352.503,244.526C352.413,246.64 350.89,248.745 348.6,248.836C348.095,248.856 346.818,248.848 345.693,247.858C343.702,246.105 344.399,243.861 344.286,244.088C344.251,244.158 343.791,245.29 343.961,246.34C344.35,248.742 346.482,250.141 348.739,250.07C350.977,249.999 354.032,248.24 354.335,244.946C354.779,240.125 351.439,237.637 348.969,237.264C341.524,236.142 342.084,240.179 342.084,240.179Z" />
        ),
      },
    },
  },
};

const shapeEar30 = {
  left: {
    front: {
      base: (
        <path d="M174.418,190.392C176.321,190.497 176.834,186.906 174.418,186.767C170.423,186.538 167.168,190.016 167.168,194.017C167.168,198.019 170.793,196.018 170.793,194.017C170.793,192.016 172.42,190.282 174.418,190.392Z" />
      ),
      basic: {
        dark: (
          <path d="M168.438,198.761C168.567,198.994 170.921,199.818 171.789,199.933C174.758,200.325 179.055,199.099 178.856,194.414C178.767,192.3 177.244,190.195 174.954,190.104C174.448,190.084 173.171,190.092 172.046,191.083C170.056,192.835 170.753,195.079 170.639,194.852C170.604,194.782 170.145,193.65 170.315,192.6C170.703,190.199 172.835,188.799 175.092,188.871C177.33,188.942 179.532,190.238 180.804,192.061C183.574,196.032 180.94,201.981 176.336,202.418C168.84,203.128 168.438,198.761 168.438,198.761Z" />
        ),
        light: (
          <path d="M180.147,189.25C180.018,189.018 177.663,188.194 176.795,188.079C173.827,187.687 169.53,188.913 169.728,193.597C169.818,195.712 171.341,197.817 173.631,197.908C174.136,197.928 175.413,197.92 176.539,196.929C178.529,195.177 177.832,192.932 177.945,193.16C177.981,193.23 178.44,194.362 178.27,195.412C177.882,197.813 175.749,199.213 173.493,199.141C171.254,199.07 168.199,197.311 167.896,194.017C167.453,189.196 170.792,186.708 173.262,186.336C180.707,185.214 180.147,189.25 180.147,189.25Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M174.418,186.767C170.416,186.767 167.168,190.016 167.168,194.017C167.168,198.019 170.416,201.267 174.418,201.267C178.419,201.267 181.668,198.019 181.668,194.017C181.668,190.016 178.419,186.767 174.418,186.767ZM174.418,190.392C172.417,190.392 170.793,192.016 170.793,194.017C170.793,196.018 172.417,197.642 174.418,197.642C176.418,197.642 178.043,196.018 178.043,194.017C178.043,192.016 176.418,190.392 174.418,190.392Z" />
      ),
      basic: {
        dark: (
          <path d="M168.438,198.761C168.567,198.994 170.921,199.818 171.789,199.933C174.758,200.325 179.055,199.099 178.856,194.414C178.767,192.3 177.244,190.195 174.954,190.104C174.448,190.084 173.171,190.092 172.046,191.083C170.056,192.835 170.753,195.079 170.639,194.852C170.604,194.782 170.145,193.65 170.315,192.6C170.703,190.199 172.835,188.799 175.092,188.871C177.33,188.942 179.532,190.238 180.804,192.061C183.574,196.032 180.94,201.981 176.336,202.418C168.84,203.128 168.438,198.761 168.438,198.761Z" />
        ),
        light: (
          <path d="M180.147,189.25C180.018,189.018 177.663,188.194 176.795,188.079C173.827,187.687 169.53,188.913 169.728,193.597C169.818,195.712 171.341,197.817 173.631,197.908C174.136,197.928 175.413,197.92 176.539,196.929C178.529,195.177 177.832,192.932 177.945,193.16C177.981,193.23 178.44,194.362 178.27,195.412C177.882,197.813 175.749,199.213 173.493,199.141C171.254,199.07 168.199,197.311 167.896,194.017C167.453,189.196 170.792,186.708 173.262,186.336C180.707,185.214 180.147,189.25 180.147,189.25Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M325.582,190.392C323.679,190.497 323.166,186.906 325.582,186.767C329.577,186.538 332.832,190.016 332.832,194.017C332.832,198.019 329.207,196.018 329.207,194.017C329.207,192.016 327.58,190.282 325.582,190.392Z" />
      ),
      basic: {
        dark: (
          <path d="M331.562,198.761C331.433,198.994 329.079,199.818 328.211,199.933C325.242,200.325 320.945,199.099 321.144,194.414C321.233,192.3 322.756,190.195 325.046,190.104C325.552,190.084 326.829,190.092 327.954,191.083C329.944,192.835 329.247,195.079 329.361,194.852C329.396,194.782 329.855,193.65 329.685,192.6C329.297,190.199 327.165,188.799 324.908,188.871C322.67,188.942 320.468,190.238 319.196,192.061C316.426,196.032 319.06,201.981 323.664,202.418C331.16,203.128 331.562,198.761 331.562,198.761Z" />
        ),
        light: (
          <path d="M319.853,189.25C319.982,189.018 322.337,188.194 323.205,188.079C326.173,187.687 330.47,188.913 330.272,193.597C330.182,195.712 328.659,197.817 326.369,197.908C325.864,197.928 324.587,197.92 323.461,196.929C321.471,195.177 322.168,192.932 322.055,193.16C322.019,193.23 321.56,194.362 321.73,195.412C322.118,197.813 324.251,199.213 326.507,199.141C328.746,199.07 331.801,197.311 332.104,194.017C332.547,189.196 329.208,186.708 326.738,186.336C319.293,185.214 319.853,189.25 319.853,189.25Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M325.582,186.767C329.584,186.767 332.832,190.016 332.832,194.017C332.832,198.019 329.584,201.267 325.582,201.267C321.581,201.267 318.332,198.019 318.332,194.017C318.332,190.016 321.581,186.767 325.582,186.767ZM325.582,190.392C327.583,190.392 329.207,192.016 329.207,194.017C329.207,196.018 327.583,197.642 325.582,197.642C323.582,197.642 321.957,196.018 321.957,194.017C321.957,192.016 323.582,190.392 325.582,190.392Z" />
      ),
      basic: {
        dark: (
          <path d="M331.562,198.761C331.433,198.994 329.079,199.818 328.211,199.933C325.242,200.325 320.945,199.099 321.144,194.414C321.233,192.3 322.756,190.195 325.046,190.104C325.552,190.084 326.829,190.092 327.954,191.083C329.944,192.835 329.247,195.079 329.361,194.852C329.396,194.782 329.855,193.65 329.685,192.6C329.297,190.199 327.165,188.799 324.908,188.871C322.67,188.942 320.468,190.238 319.196,192.061C316.426,196.032 319.06,201.981 323.664,202.418C331.16,203.128 331.562,198.761 331.562,198.761Z" />
        ),
        light: (
          <path d="M319.853,189.25C319.982,189.018 322.337,188.194 323.205,188.079C326.173,187.687 330.47,188.913 330.272,193.597C330.182,195.712 328.659,197.817 326.369,197.908C325.864,197.928 324.587,197.92 323.461,196.929C321.471,195.177 322.168,192.932 322.055,193.16C322.019,193.23 321.56,194.362 321.73,195.412C322.118,197.813 324.251,199.213 326.507,199.141C328.746,199.07 331.801,197.311 332.104,194.017C332.547,189.196 329.208,186.708 326.738,186.336C319.293,185.214 319.853,189.25 319.853,189.25Z" />
        ),
      },
    },
  },
};

const shapeEar31 = {
  left: {
    front: {
      base: (
        <path d="M163.546,285.7C165.45,285.805 165.963,282.214 163.546,282.075C159.551,281.846 156.296,285.324 156.296,289.325C156.296,293.326 159.921,291.326 159.921,289.325C159.921,287.324 161.549,285.59 163.546,285.7Z" />
      ),
      basic: {
        dark: (
          <path d="M157.566,294.069C157.695,294.302 160.05,295.126 160.918,295.241C163.886,295.633 168.183,294.406 167.985,289.722C167.895,287.608 166.372,285.503 164.082,285.412C163.577,285.392 162.3,285.4 161.175,286.391C159.184,288.143 159.881,290.387 159.768,290.16C159.733,290.09 159.273,288.958 159.443,287.908C159.831,285.507 161.964,284.107 164.221,284.178C166.459,284.249 168.66,285.545 169.932,287.369C172.702,291.339 170.068,297.289 165.464,297.725C157.969,298.435 157.566,294.069 157.566,294.069Z" />
        ),
        light: (
          <path d="M169.275,284.558C169.146,284.325 166.792,283.502 165.924,283.387C162.956,282.994 158.659,284.221 158.857,288.905C158.946,291.019 160.47,293.125 162.76,293.215C163.265,293.235 164.542,293.228 165.667,292.237C167.657,290.484 166.96,288.24 167.074,288.467C167.109,288.538 167.569,289.669 167.399,290.72C167.01,293.121 164.878,294.52 162.621,294.449C160.383,294.378 157.327,292.619 157.024,289.325C156.581,284.504 159.92,282.016 162.391,281.644C169.835,280.521 169.275,284.558 169.275,284.558Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M163.546,282.075C159.545,282.075 156.296,285.324 156.296,289.325C156.296,293.326 159.545,296.575 163.546,296.575C167.548,296.575 170.796,293.326 170.796,289.325C170.796,285.324 167.548,282.075 163.546,282.075ZM163.546,285.7C161.545,285.7 159.921,287.324 159.921,289.325C159.921,291.326 161.545,292.95 163.546,292.95C165.547,292.95 167.171,291.326 167.171,289.325C167.171,287.324 165.547,285.7 163.546,285.7Z" />
      ),
      basic: {
        dark: (
          <path d="M157.566,294.069C157.695,294.302 160.05,295.126 160.918,295.241C163.886,295.633 168.183,294.406 167.985,289.722C167.895,287.608 166.372,285.503 164.082,285.412C163.577,285.392 162.3,285.4 161.175,286.391C159.184,288.143 159.881,290.387 159.768,290.16C159.733,290.09 159.273,288.958 159.443,287.908C159.831,285.507 161.964,284.107 164.221,284.178C166.459,284.249 168.66,285.545 169.932,287.369C172.702,291.339 170.068,297.289 165.464,297.725C157.969,298.435 157.566,294.069 157.566,294.069Z" />
        ),
        light: (
          <path d="M169.275,284.558C169.146,284.325 166.792,283.502 165.924,283.387C162.956,282.994 158.659,284.221 158.857,288.905C158.946,291.019 160.47,293.125 162.76,293.215C163.265,293.235 164.542,293.228 165.667,292.237C167.657,290.484 166.96,288.24 167.074,288.467C167.109,288.538 167.569,289.669 167.399,290.72C167.01,293.121 164.878,294.52 162.621,294.449C160.383,294.378 157.327,292.619 157.024,289.325C156.581,284.504 159.92,282.016 162.391,281.644C169.835,280.521 169.275,284.558 169.275,284.558Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M336.454,285.7C334.55,285.805 334.037,282.214 336.454,282.075C340.449,281.846 343.704,285.324 343.704,289.325C343.704,293.326 340.079,291.326 340.079,289.325C340.079,287.324 338.451,285.59 336.454,285.7Z" />
      ),
      basic: {
        dark: (
          <path d="M342.434,294.069C342.305,294.302 339.95,295.126 339.082,295.241C336.114,295.633 331.817,294.406 332.015,289.722C332.105,287.608 333.628,285.503 335.918,285.412C336.423,285.392 337.7,285.4 338.825,286.391C340.816,288.143 340.119,290.387 340.232,290.16C340.267,290.09 340.727,288.958 340.557,287.908C340.169,285.507 338.036,284.107 335.779,284.178C333.541,284.249 331.34,285.545 330.068,287.369C327.298,291.339 329.932,297.289 334.536,297.725C342.031,298.435 342.434,294.069 342.434,294.069Z" />
        ),
        light: (
          <path d="M330.725,284.558C330.854,284.325 333.208,283.502 334.076,283.387C337.044,282.994 341.341,284.221 341.143,288.905C341.054,291.019 339.53,293.125 337.24,293.215C336.735,293.235 335.458,293.228 334.333,292.237C332.343,290.484 333.04,288.24 332.926,288.467C332.891,288.538 332.431,289.669 332.601,290.72C332.99,293.121 335.122,294.52 337.379,294.449C339.617,294.378 342.673,292.619 342.976,289.325C343.419,284.504 340.08,282.016 337.609,281.644C330.165,280.521 330.725,284.558 330.725,284.558Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M336.454,282.075C340.455,282.075 343.704,285.324 343.704,289.325C343.704,293.326 340.455,296.575 336.454,296.575C332.452,296.575 329.204,293.326 329.204,289.325C329.204,285.324 332.452,282.075 336.454,282.075ZM336.454,285.7C338.455,285.7 340.079,287.324 340.079,289.325C340.079,291.326 338.455,292.95 336.454,292.95C334.453,292.95 332.829,291.326 332.829,289.325C332.829,287.324 334.453,285.7 336.454,285.7Z" />
      ),
      basic: {
        dark: (
          <path d="M342.434,294.069C342.305,294.302 339.95,295.126 339.082,295.241C336.114,295.633 331.817,294.406 332.015,289.722C332.105,287.608 333.628,285.503 335.918,285.412C336.423,285.392 337.7,285.4 338.825,286.391C340.816,288.143 340.119,290.387 340.232,290.16C340.267,290.09 340.727,288.958 340.557,287.908C340.169,285.507 338.036,284.107 335.779,284.178C333.541,284.249 331.34,285.545 330.068,287.369C327.298,291.339 329.932,297.289 334.536,297.725C342.031,298.435 342.434,294.069 342.434,294.069Z" />
        ),
        light: (
          <path d="M330.725,284.558C330.854,284.325 333.208,283.502 334.076,283.387C337.044,282.994 341.341,284.221 341.143,288.905C341.054,291.019 339.53,293.125 337.24,293.215C336.735,293.235 335.458,293.228 334.333,292.237C332.343,290.484 333.04,288.24 332.926,288.467C332.891,288.538 332.431,289.669 332.601,290.72C332.99,293.121 335.122,294.52 337.379,294.449C339.617,294.378 342.673,292.619 342.976,289.325C343.419,284.504 340.08,282.016 337.609,281.644C330.165,280.521 330.725,284.558 330.725,284.558Z" />
        ),
      },
    },
  },
};

const shapeEar32 = {
  left: {
    front: {
      base: (
        <path d="M152.213,274.826C154.117,274.931 154.63,271.34 152.213,271.201C148.218,270.972 144.963,274.45 144.963,278.451C144.963,282.453 148.588,280.452 148.588,278.451C148.588,276.451 150.215,274.716 152.213,274.826Z" />
      ),
      basic: {
        dark: (
          <path d="M146.233,283.196C146.362,283.428 148.717,284.252 149.585,284.367C152.553,284.759 156.85,283.533 156.652,278.849C156.562,276.734 155.039,274.629 152.749,274.538C152.244,274.518 150.967,274.526 149.841,275.517C147.851,277.269 148.548,279.514 148.435,279.286C148.399,279.216 147.94,278.084 148.11,277.034C148.498,274.633 150.631,273.233 152.887,273.305C155.126,273.376 157.327,274.672 158.599,276.495C161.369,280.466 158.735,286.416 154.131,286.852C146.636,287.562 146.233,283.196 146.233,283.196Z" />
        ),
        light: (
          <path d="M157.942,273.685C157.813,273.452 155.459,272.628 154.591,272.513C151.622,272.121 147.325,273.347 147.524,278.032C147.613,280.146 149.136,282.251 151.426,282.342C151.932,282.362 153.209,282.354 154.334,281.363C156.324,279.611 155.627,277.367 155.741,277.594C155.776,277.664 156.235,278.796 156.065,279.846C155.677,282.247 153.545,283.647 151.288,283.575C149.05,283.504 145.994,281.746 145.691,278.451C145.248,273.631 148.587,271.142 151.057,270.77C158.502,269.648 157.942,273.685 157.942,273.685Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M152.213,271.201C148.212,271.201 144.963,274.45 144.963,278.451C144.963,282.453 148.212,285.701 152.213,285.701C156.214,285.701 159.463,282.453 159.463,278.451C159.463,274.45 156.214,271.201 152.213,271.201ZM152.213,274.826C150.212,274.826 148.588,276.451 148.588,278.451C148.588,280.452 150.212,282.076 152.213,282.076C154.214,282.076 155.838,280.452 155.838,278.451C155.838,276.451 154.214,274.826 152.213,274.826Z" />
      ),
      basic: {
        dark: (
          <path d="M146.233,283.196C146.362,283.428 148.717,284.252 149.585,284.367C152.553,284.759 156.85,283.533 156.652,278.849C156.562,276.734 155.039,274.629 152.749,274.538C152.244,274.518 150.967,274.526 149.841,275.517C147.851,277.269 148.548,279.514 148.435,279.286C148.399,279.216 147.94,278.084 148.11,277.034C148.498,274.633 150.631,273.233 152.887,273.305C155.126,273.376 157.327,274.672 158.599,276.495C161.369,280.466 158.735,286.416 154.131,286.852C146.636,287.562 146.233,283.196 146.233,283.196Z" />
        ),
        light: (
          <path d="M157.942,273.685C157.813,273.452 155.459,272.628 154.591,272.513C151.622,272.121 147.325,273.347 147.524,278.032C147.613,280.146 149.136,282.251 151.426,282.342C151.932,282.362 153.209,282.354 154.334,281.363C156.324,279.611 155.627,277.367 155.741,277.594C155.776,277.664 156.235,278.796 156.065,279.846C155.677,282.247 153.545,283.647 151.288,283.575C149.05,283.504 145.994,281.746 145.691,278.451C145.248,273.631 148.587,271.142 151.057,270.77C158.502,269.648 157.942,273.685 157.942,273.685Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M347.787,274.826C345.883,274.931 345.37,271.34 347.787,271.201C351.782,270.972 355.037,274.45 355.037,278.451C355.037,282.453 351.412,280.452 351.412,278.451C351.412,276.451 349.785,274.716 347.787,274.826Z" />
      ),
      basic: {
        dark: (
          <path d="M353.767,283.196C353.638,283.428 351.283,284.252 350.415,284.367C347.447,284.759 343.15,283.533 343.348,278.849C343.438,276.734 344.961,274.629 347.251,274.538C347.756,274.518 349.033,274.526 350.159,275.517C352.149,277.269 351.452,279.514 351.565,279.286C351.601,279.216 352.06,278.084 351.89,277.034C351.502,274.633 349.369,273.233 347.113,273.305C344.874,273.376 342.673,274.672 341.401,276.495C338.631,280.466 341.265,286.416 345.869,286.852C353.364,287.562 353.767,283.196 353.767,283.196Z" />
        ),
        light: (
          <path d="M342.058,273.685C342.187,273.452 344.541,272.628 345.409,272.513C348.378,272.121 352.675,273.347 352.476,278.032C352.387,280.146 350.864,282.251 348.574,282.342C348.068,282.362 346.791,282.354 345.666,281.363C343.676,279.611 344.373,277.367 344.259,277.594C344.224,277.664 343.765,278.796 343.935,279.846C344.323,282.247 346.455,283.647 348.712,283.575C350.95,283.504 354.006,281.746 354.309,278.451C354.752,273.631 351.413,271.142 348.943,270.77C341.498,269.648 342.058,273.685 342.058,273.685Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M347.787,271.201C351.788,271.201 355.037,274.45 355.037,278.451C355.037,282.453 351.788,285.701 347.787,285.701C343.786,285.701 340.537,282.453 340.537,278.451C340.537,274.45 343.786,271.201 347.787,271.201ZM347.787,274.826C349.788,274.826 351.412,276.451 351.412,278.451C351.412,280.452 349.788,282.076 347.787,282.076C345.786,282.076 344.162,280.452 344.162,278.451C344.162,276.451 345.786,274.826 347.787,274.826Z" />
      ),
      basic: {
        dark: (
          <path d="M353.767,283.196C353.638,283.428 351.283,284.252 350.415,284.367C347.447,284.759 343.15,283.533 343.348,278.849C343.438,276.734 344.961,274.629 347.251,274.538C347.756,274.518 349.033,274.526 350.159,275.517C352.149,277.269 351.452,279.514 351.565,279.286C351.601,279.216 352.06,278.084 351.89,277.034C351.502,274.633 349.369,273.233 347.113,273.305C344.874,273.376 342.673,274.672 341.401,276.495C338.631,280.466 341.265,286.416 345.869,286.852C353.364,287.562 353.767,283.196 353.767,283.196Z" />
        ),
        light: (
          <path d="M342.058,273.685C342.187,273.452 344.541,272.628 345.409,272.513C348.378,272.121 352.675,273.347 352.476,278.032C352.387,280.146 350.864,282.251 348.574,282.342C348.068,282.362 346.791,282.354 345.666,281.363C343.676,279.611 344.373,277.367 344.259,277.594C344.224,277.664 343.765,278.796 343.935,279.846C344.323,282.247 346.455,283.647 348.712,283.575C350.95,283.504 354.006,281.746 354.309,278.451C354.752,273.631 351.413,271.142 348.943,270.77C341.498,269.648 342.058,273.685 342.058,273.685Z" />
        ),
      },
    },
  },
};

const shapeEar33 = {
  left: {
    front: {
      base: (
        <path d="M158.686,272.423C160.589,272.528 161.102,268.937 158.686,268.798C154.691,268.569 151.436,272.047 151.436,276.048C151.436,280.05 155.061,278.049 155.061,276.048C155.061,274.048 156.688,272.313 158.686,272.423Z" />
      ),
      basic: {
        dark: (
          <path d="M152.706,280.793C152.835,281.025 155.189,281.849 156.057,281.964C159.025,282.356 163.322,281.13 163.124,276.445C163.035,274.331 161.511,272.226 159.222,272.135C158.716,272.115 157.439,272.123 156.314,273.114C154.324,274.866 155.021,277.111 154.907,276.883C154.872,276.813 154.412,275.681 154.582,274.631C154.971,272.23 157.103,270.83 159.36,270.902C161.598,270.973 163.799,272.269 165.072,274.092C167.841,278.063 165.207,284.013 160.604,284.449C153.108,285.159 152.706,280.793 152.706,280.793Z" />
        ),
        light: (
          <path d="M164.415,271.281C164.286,271.049 161.931,270.225 161.063,270.11C158.095,269.718 153.798,270.944 153.996,275.629C154.086,277.743 155.609,279.848 157.899,279.939C158.404,279.959 159.681,279.951 160.807,278.96C162.797,277.208 162.1,274.963 162.213,275.191C162.248,275.261 162.708,276.393 162.538,277.443C162.15,279.844 160.017,281.244 157.76,281.172C155.522,281.101 152.467,279.342 152.164,276.048C151.72,271.228 155.06,268.739 157.53,268.367C164.975,267.245 164.415,271.281 164.415,271.281Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M158.686,268.798C154.684,268.798 151.436,272.047 151.436,276.048C151.436,280.05 154.684,283.298 158.686,283.298C162.687,283.298 165.936,280.05 165.936,276.048C165.936,272.047 162.687,268.798 158.686,268.798ZM158.686,272.423C156.685,272.423 155.061,274.048 155.061,276.048C155.061,278.049 156.685,279.673 158.686,279.673C160.686,279.673 162.311,278.049 162.311,276.048C162.311,274.048 160.686,272.423 158.686,272.423Z" />
      ),
      basic: {
        dark: (
          <path d="M152.706,280.793C152.835,281.025 155.189,281.849 156.057,281.964C159.025,282.356 163.322,281.13 163.124,276.445C163.035,274.331 161.511,272.226 159.222,272.135C158.716,272.115 157.439,272.123 156.314,273.114C154.324,274.866 155.021,277.111 154.907,276.883C154.872,276.813 154.412,275.681 154.582,274.631C154.971,272.23 157.103,270.83 159.36,270.902C161.598,270.973 163.799,272.269 165.072,274.092C167.841,278.063 165.207,284.013 160.604,284.449C153.108,285.159 152.706,280.793 152.706,280.793Z" />
        ),
        light: (
          <path d="M164.415,271.281C164.286,271.049 161.931,270.225 161.063,270.11C158.095,269.718 153.798,270.944 153.996,275.629C154.086,277.743 155.609,279.848 157.899,279.939C158.404,279.959 159.681,279.951 160.807,278.96C162.797,277.208 162.1,274.963 162.213,275.191C162.248,275.261 162.708,276.393 162.538,277.443C162.15,279.844 160.017,281.244 157.76,281.172C155.522,281.101 152.467,279.342 152.164,276.048C151.72,271.228 155.06,268.739 157.53,268.367C164.975,267.245 164.415,271.281 164.415,271.281Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M341.314,272.423C339.411,272.528 338.898,268.937 341.314,268.798C345.309,268.569 348.564,272.047 348.564,276.048C348.564,280.05 344.939,278.049 344.939,276.048C344.939,274.048 343.312,272.313 341.314,272.423Z" />
      ),
      basic: {
        dark: (
          <path d="M347.294,280.793C347.165,281.025 344.811,281.849 343.943,281.964C340.975,282.356 336.678,281.13 336.876,276.445C336.965,274.331 338.489,272.226 340.778,272.135C341.284,272.115 342.561,272.123 343.686,273.114C345.676,274.866 344.979,277.111 345.093,276.883C345.128,276.813 345.588,275.681 345.418,274.631C345.029,272.23 342.897,270.83 340.64,270.902C338.402,270.973 336.201,272.269 334.928,274.092C332.159,278.063 334.793,284.013 339.396,284.449C346.892,285.159 347.294,280.793 347.294,280.793Z" />
        ),
        light: (
          <path d="M335.585,271.281C335.714,271.049 338.069,270.225 338.937,270.11C341.905,269.718 346.202,270.944 346.004,275.629C345.914,277.743 344.391,279.848 342.101,279.939C341.596,279.959 340.319,279.951 339.193,278.96C337.203,277.208 337.9,274.963 337.787,275.191C337.752,275.261 337.292,276.393 337.462,277.443C337.85,279.844 339.983,281.244 342.24,281.172C344.478,281.101 347.533,279.342 347.836,276.048C348.28,271.228 344.94,268.739 342.47,268.367C335.025,267.245 335.585,271.281 335.585,271.281Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M341.314,268.798C345.316,268.798 348.564,272.047 348.564,276.048C348.564,280.05 345.316,283.298 341.314,283.298C337.313,283.298 334.064,280.05 334.064,276.048C334.064,272.047 337.313,268.798 341.314,268.798ZM341.314,272.423C343.315,272.423 344.939,274.048 344.939,276.048C344.939,278.049 343.315,279.673 341.314,279.673C339.314,279.673 337.689,278.049 337.689,276.048C337.689,274.048 339.314,272.423 341.314,272.423Z" />
      ),
      basic: {
        dark: (
          <path d="M347.294,280.793C347.165,281.025 344.811,281.849 343.943,281.964C340.975,282.356 336.678,281.13 336.876,276.445C336.965,274.331 338.489,272.226 340.778,272.135C341.284,272.115 342.561,272.123 343.686,273.114C345.676,274.866 344.979,277.111 345.093,276.883C345.128,276.813 345.588,275.681 345.418,274.631C345.029,272.23 342.897,270.83 340.64,270.902C338.402,270.973 336.201,272.269 334.928,274.092C332.159,278.063 334.793,284.013 339.396,284.449C346.892,285.159 347.294,280.793 347.294,280.793Z" />
        ),
        light: (
          <path d="M335.585,271.281C335.714,271.049 338.069,270.225 338.937,270.11C341.905,269.718 346.202,270.944 346.004,275.629C345.914,277.743 344.391,279.848 342.101,279.939C341.596,279.959 340.319,279.951 339.193,278.96C337.203,277.208 337.9,274.963 337.787,275.191C337.752,275.261 337.292,276.393 337.462,277.443C337.85,279.844 339.983,281.244 342.24,281.172C344.478,281.101 347.533,279.342 347.836,276.048C348.28,271.228 344.94,268.739 342.47,268.367C335.025,267.245 335.585,271.281 335.585,271.281Z" />
        ),
      },
    },
  },
};

const shapeEar34 = {
  left: {
    front: {
      base: (
        <path d="M163.546,269.158C165.45,269.262 165.963,265.671 163.546,265.533C159.551,265.303 156.296,268.781 156.296,272.783C156.296,276.784 159.921,274.783 159.921,272.783C159.921,270.782 161.549,269.047 163.546,269.158Z" />
      ),
      basic: {
        dark: (
          <path d="M157.566,277.527C157.695,277.76 160.05,278.583 160.918,278.698C163.886,279.09 168.183,277.864 167.985,273.18C167.895,271.066 166.372,268.96 164.082,268.87C163.577,268.85 162.3,268.857 161.175,269.848C159.184,271.601 159.881,273.845 159.768,273.618C159.733,273.547 159.273,272.415 159.443,271.365C159.831,268.964 161.964,267.564 164.221,267.636C166.459,267.707 168.66,269.003 169.932,270.827C172.702,274.797 170.068,280.747 165.464,281.183C157.969,281.893 157.566,277.527 157.566,277.527Z" />
        ),
        light: (
          <path d="M169.275,268.016C169.146,267.783 166.792,266.959 165.924,266.844C162.956,266.452 158.659,267.679 158.857,272.363C158.946,274.477 160.47,276.582 162.76,276.673C163.265,276.693 164.542,276.685 165.667,275.694C167.657,273.942 166.96,271.698 167.074,271.925C167.109,271.995 167.569,273.127 167.399,274.177C167.01,276.578 164.878,277.978 162.621,277.906C160.383,277.836 157.327,276.077 157.024,272.783C156.581,267.962 159.92,265.474 162.391,265.101C169.835,263.979 169.275,268.016 169.275,268.016Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M163.546,265.533C159.545,265.533 156.296,268.781 156.296,272.783C156.296,276.784 159.545,280.033 163.546,280.033C167.548,280.033 170.796,276.784 170.796,272.783C170.796,268.781 167.548,265.533 163.546,265.533ZM163.546,269.158C161.545,269.158 159.921,270.782 159.921,272.783C159.921,274.783 161.545,276.408 163.546,276.408C165.547,276.408 167.171,274.783 167.171,272.783C167.171,270.782 165.547,269.158 163.546,269.158Z" />
      ),
      basic: {
        dark: (
          <path d="M157.566,277.527C157.695,277.76 160.05,278.583 160.918,278.698C163.886,279.09 168.183,277.864 167.985,273.18C167.895,271.066 166.372,268.96 164.082,268.87C163.577,268.85 162.3,268.857 161.175,269.848C159.184,271.601 159.881,273.845 159.768,273.618C159.733,273.547 159.273,272.415 159.443,271.365C159.831,268.964 161.964,267.564 164.221,267.636C166.459,267.707 168.66,269.003 169.932,270.827C172.702,274.797 170.068,280.747 165.464,281.183C157.969,281.893 157.566,277.527 157.566,277.527Z" />
        ),
        light: (
          <path d="M169.275,268.016C169.146,267.783 166.792,266.959 165.924,266.844C162.956,266.452 158.659,267.679 158.857,272.363C158.946,274.477 160.47,276.582 162.76,276.673C163.265,276.693 164.542,276.685 165.667,275.694C167.657,273.942 166.96,271.698 167.074,271.925C167.109,271.995 167.569,273.127 167.399,274.177C167.01,276.578 164.878,277.978 162.621,277.906C160.383,277.836 157.327,276.077 157.024,272.783C156.581,267.962 159.92,265.474 162.391,265.101C169.835,263.979 169.275,268.016 169.275,268.016Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M336.454,269.158C334.55,269.262 334.037,265.671 336.454,265.533C340.449,265.303 343.704,268.781 343.704,272.783C343.704,276.784 340.079,274.783 340.079,272.783C340.079,270.782 338.451,269.047 336.454,269.158Z" />
      ),
      basic: {
        dark: (
          <path d="M342.434,277.527C342.305,277.76 339.95,278.583 339.082,278.698C336.114,279.09 331.817,277.864 332.015,273.18C332.105,271.066 333.628,268.96 335.918,268.87C336.423,268.85 337.7,268.857 338.825,269.848C340.816,271.601 340.119,273.845 340.232,273.618C340.267,273.547 340.727,272.415 340.557,271.365C340.169,268.964 338.036,267.564 335.779,267.636C333.541,267.707 331.34,269.003 330.068,270.827C327.298,274.797 329.932,280.747 334.536,281.183C342.031,281.893 342.434,277.527 342.434,277.527Z" />
        ),
        light: (
          <path d="M330.725,268.016C330.854,267.783 333.208,266.959 334.076,266.844C337.044,266.452 341.341,267.679 341.143,272.363C341.054,274.477 339.53,276.582 337.24,276.673C336.735,276.693 335.458,276.685 334.333,275.694C332.343,273.942 333.04,271.698 332.926,271.925C332.891,271.995 332.431,273.127 332.601,274.177C332.99,276.578 335.122,277.978 337.379,277.906C339.617,277.836 342.673,276.077 342.976,272.783C343.419,267.962 340.08,265.474 337.609,265.101C330.165,263.979 330.725,268.016 330.725,268.016Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M336.454,265.533C340.455,265.533 343.704,268.781 343.704,272.783C343.704,276.784 340.455,280.033 336.454,280.033C332.452,280.033 329.204,276.784 329.204,272.783C329.204,268.781 332.452,265.533 336.454,265.533ZM336.454,269.158C338.455,269.158 340.079,270.782 340.079,272.783C340.079,274.783 338.455,276.408 336.454,276.408C334.453,276.408 332.829,274.783 332.829,272.783C332.829,270.782 334.453,269.158 336.454,269.158Z" />
      ),
      basic: {
        dark: (
          <path d="M342.434,277.527C342.305,277.76 339.95,278.583 339.082,278.698C336.114,279.09 331.817,277.864 332.015,273.18C332.105,271.066 333.628,268.96 335.918,268.87C336.423,268.85 337.7,268.857 338.825,269.848C340.816,271.601 340.119,273.845 340.232,273.618C340.267,273.547 340.727,272.415 340.557,271.365C340.169,268.964 338.036,267.564 335.779,267.636C333.541,267.707 331.34,269.003 330.068,270.827C327.298,274.797 329.932,280.747 334.536,281.183C342.031,281.893 342.434,277.527 342.434,277.527Z" />
        ),
        light: (
          <path d="M330.725,268.016C330.854,267.783 333.208,266.959 334.076,266.844C337.044,266.452 341.341,267.679 341.143,272.363C341.054,274.477 339.53,276.582 337.24,276.673C336.735,276.693 335.458,276.685 334.333,275.694C332.343,273.942 333.04,271.698 332.926,271.925C332.891,271.995 332.431,273.127 332.601,274.177C332.99,276.578 335.122,277.978 337.379,277.906C339.617,277.836 342.673,276.077 342.976,272.783C343.419,267.962 340.08,265.474 337.609,265.101C330.165,263.979 330.725,268.016 330.725,268.016Z" />
        ),
      },
    },
  },
};

const shapeEar35 = {
  left: {
    front: {
      base: (
        <path d="M164.716,286.361C166.62,286.466 167.133,282.875 164.716,282.736C160.722,282.507 157.466,285.985 157.466,289.986C157.466,293.988 161.091,291.987 161.091,289.986C161.091,287.986 162.719,286.251 164.716,286.361Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,294.731C158.866,294.963 161.22,295.787 162.088,295.902C165.056,296.294 169.353,295.068 169.155,290.383C169.066,288.269 167.542,286.164 165.252,286.073C164.747,286.053 163.47,286.061 162.345,287.052C160.355,288.804 161.052,291.049 160.938,290.821C160.903,290.751 160.443,289.619 160.613,288.569C161.002,286.168 163.134,284.768 165.391,284.84C167.629,284.911 169.83,286.207 171.103,288.03C173.872,292.001 171.238,297.95 166.635,298.387C159.139,299.097 158.737,294.731 158.737,294.731Z" />
        ),
        light: (
          <path d="M170.446,285.219C170.317,284.987 167.962,284.163 167.094,284.048C164.126,283.656 159.829,284.882 160.027,289.567C160.117,291.681 161.64,293.786 163.93,293.877C164.435,293.897 165.712,293.889 166.837,292.898C168.828,291.146 168.131,288.901 168.244,289.129C168.279,289.199 168.739,290.331 168.569,291.381C168.18,293.782 166.048,295.182 163.791,295.11C161.553,295.039 158.498,293.28 158.195,289.986C157.751,285.166 161.091,282.677 163.561,282.305C171.006,281.183 170.446,285.219 170.446,285.219Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,282.736C160.715,282.736 157.466,285.985 157.466,289.986C157.466,293.988 160.715,297.236 164.716,297.236C168.718,297.236 171.966,293.988 171.966,289.986C171.966,285.985 168.718,282.736 164.716,282.736ZM164.716,286.361C162.716,286.361 161.091,287.986 161.091,289.986C161.091,291.987 162.716,293.611 164.716,293.611C166.717,293.611 168.341,291.987 168.341,289.986C168.341,287.986 166.717,286.361 164.716,286.361Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,294.731C158.866,294.963 161.22,295.787 162.088,295.902C165.056,296.294 169.353,295.068 169.155,290.383C169.066,288.269 167.542,286.164 165.252,286.073C164.747,286.053 163.47,286.061 162.345,287.052C160.355,288.804 161.052,291.049 160.938,290.821C160.903,290.751 160.443,289.619 160.613,288.569C161.002,286.168 163.134,284.768 165.391,284.84C167.629,284.911 169.83,286.207 171.103,288.03C173.872,292.001 171.238,297.95 166.635,298.387C159.139,299.097 158.737,294.731 158.737,294.731Z" />
        ),
        light: (
          <path d="M170.446,285.219C170.317,284.987 167.962,284.163 167.094,284.048C164.126,283.656 159.829,284.882 160.027,289.567C160.117,291.681 161.64,293.786 163.93,293.877C164.435,293.897 165.712,293.889 166.837,292.898C168.828,291.146 168.131,288.901 168.244,289.129C168.279,289.199 168.739,290.331 168.569,291.381C168.18,293.782 166.048,295.182 163.791,295.11C161.553,295.039 158.498,293.28 158.195,289.986C157.751,285.166 161.091,282.677 163.561,282.305C171.006,281.183 170.446,285.219 170.446,285.219Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.321,286.361C333.417,286.466 332.904,282.875 335.321,282.736C339.316,282.507 342.571,285.985 342.571,289.986C342.571,293.988 338.946,291.987 338.946,289.986C338.946,287.986 337.319,286.251 335.321,286.361Z" />
      ),
      basic: {
        dark: (
          <path d="M341.301,294.731C341.172,294.963 338.817,295.787 337.949,295.902C334.981,296.294 330.684,295.068 330.882,290.383C330.972,288.269 332.495,286.164 334.785,286.073C335.29,286.053 336.567,286.061 337.692,287.052C339.683,288.804 338.986,291.049 339.099,290.821C339.134,290.751 339.594,289.619 339.424,288.569C339.036,286.168 336.903,284.768 334.646,284.84C332.408,284.911 330.207,286.207 328.935,288.03C326.165,292.001 328.799,297.95 333.403,298.387C340.898,299.097 341.301,294.731 341.301,294.731Z" />
        ),
        light: (
          <path d="M329.592,285.219C329.721,284.987 332.075,284.163 332.943,284.048C335.911,283.656 340.208,284.882 340.01,289.567C339.921,291.681 338.397,293.786 336.107,293.877C335.602,293.897 334.325,293.889 333.2,292.898C331.21,291.146 331.907,288.901 331.793,289.129C331.758,289.199 331.298,290.331 331.468,291.381C331.857,293.782 333.989,295.182 336.246,295.11C338.484,295.039 341.54,293.28 341.843,289.986C342.286,285.166 338.947,282.677 336.476,282.305C329.032,281.183 329.592,285.219 329.592,285.219Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.321,282.736C339.322,282.736 342.571,285.985 342.571,289.986C342.571,293.988 339.322,297.236 335.321,297.236C331.319,297.236 328.071,293.988 328.071,289.986C328.071,285.985 331.319,282.736 335.321,282.736ZM335.321,286.361C337.322,286.361 338.946,287.986 338.946,289.986C338.946,291.987 337.322,293.611 335.321,293.611C333.32,293.611 331.696,291.987 331.696,289.986C331.696,287.986 333.32,286.361 335.321,286.361Z" />
      ),
      basic: {
        dark: (
          <path d="M341.301,294.731C341.172,294.963 338.817,295.787 337.949,295.902C334.981,296.294 330.684,295.068 330.882,290.383C330.972,288.269 332.495,286.164 334.785,286.073C335.29,286.053 336.567,286.061 337.692,287.052C339.683,288.804 338.986,291.049 339.099,290.821C339.134,290.751 339.594,289.619 339.424,288.569C339.036,286.168 336.903,284.768 334.646,284.84C332.408,284.911 330.207,286.207 328.935,288.03C326.165,292.001 328.799,297.95 333.403,298.387C340.898,299.097 341.301,294.731 341.301,294.731Z" />
        ),
        light: (
          <path d="M329.592,285.219C329.721,284.987 332.075,284.163 332.943,284.048C335.911,283.656 340.208,284.882 340.01,289.567C339.921,291.681 338.397,293.786 336.107,293.877C335.602,293.897 334.325,293.889 333.2,292.898C331.21,291.146 331.907,288.901 331.793,289.129C331.758,289.199 331.298,290.331 331.468,291.381C331.857,293.782 333.989,295.182 336.246,295.11C338.484,295.039 341.54,293.28 341.843,289.986C342.286,285.166 338.947,282.677 336.476,282.305C329.032,281.183 329.592,285.219 329.592,285.219Z" />
        ),
      },
    },
  },
};

const shapeEar36 = {
  left: {
    front: {
      base: (
        <path d="M167.892,291.537C169.795,291.642 170.308,288.051 167.892,287.912C163.897,287.682 160.642,291.16 160.642,295.162C160.642,299.163 164.267,297.162 164.267,295.162C164.267,293.161 165.894,291.427 167.892,291.537Z" />
      ),
      basic: {
        dark: (
          <path d="M161.912,299.906C162.041,300.139 164.395,300.963 165.263,301.077C168.232,301.47 172.528,300.243 172.33,295.559C172.241,293.445 170.718,291.339 168.428,291.249C167.922,291.229 166.645,291.236 165.52,292.227C163.53,293.98 164.227,296.224 164.113,295.997C164.078,295.927 163.619,294.795 163.788,293.745C164.177,291.343 166.309,289.944 168.566,290.015C170.804,290.086 173.006,291.382 174.278,293.206C177.047,297.176 174.414,303.126 169.81,303.562C162.314,304.272 161.912,299.906 161.912,299.906Z" />
        ),
        light: (
          <path d="M173.621,290.395C173.492,290.162 171.137,289.338 170.269,289.224C167.301,288.831 163.004,290.058 163.202,294.742C163.292,296.856 164.815,298.961 167.105,299.052C167.61,299.072 168.887,299.064 170.013,298.074C172.003,296.321 171.306,294.077 171.419,294.304C171.454,294.374 171.914,295.506 171.744,296.556C171.356,298.958 169.223,300.357 166.967,300.286C164.728,300.215 161.673,298.456 161.37,295.162C160.926,290.341 164.266,287.853 166.736,287.48C174.181,286.358 173.621,290.395 173.621,290.395Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M167.892,287.912C163.89,287.912 160.642,291.16 160.642,295.162C160.642,299.163 163.89,302.412 167.892,302.412C171.893,302.412 175.142,299.163 175.142,295.162C175.142,291.16 171.893,287.912 167.892,287.912ZM167.892,291.537C165.891,291.537 164.267,293.161 164.267,295.162C164.267,297.162 165.891,298.787 167.892,298.787C169.892,298.787 171.517,297.162 171.517,295.162C171.517,293.161 169.892,291.537 167.892,291.537Z" />
      ),
      basic: {
        dark: (
          <path d="M161.912,299.906C162.041,300.139 164.395,300.963 165.263,301.077C168.232,301.47 172.528,300.243 172.33,295.559C172.241,293.445 170.718,291.339 168.428,291.249C167.922,291.229 166.645,291.236 165.52,292.227C163.53,293.98 164.227,296.224 164.113,295.997C164.078,295.927 163.619,294.795 163.788,293.745C164.177,291.343 166.309,289.944 168.566,290.015C170.804,290.086 173.006,291.382 174.278,293.206C177.047,297.176 174.414,303.126 169.81,303.562C162.314,304.272 161.912,299.906 161.912,299.906Z" />
        ),
        light: (
          <path d="M173.621,290.395C173.492,290.162 171.137,289.338 170.269,289.224C167.301,288.831 163.004,290.058 163.202,294.742C163.292,296.856 164.815,298.961 167.105,299.052C167.61,299.072 168.887,299.064 170.013,298.074C172.003,296.321 171.306,294.077 171.419,294.304C171.454,294.374 171.914,295.506 171.744,296.556C171.356,298.958 169.223,300.357 166.967,300.286C164.728,300.215 161.673,298.456 161.37,295.162C160.926,290.341 164.266,287.853 166.736,287.48C174.181,286.358 173.621,290.395 173.621,290.395Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M332.108,291.537C330.205,291.642 329.692,288.051 332.108,287.912C336.103,287.682 339.358,291.16 339.358,295.162C339.358,299.163 335.733,297.162 335.733,295.162C335.733,293.161 334.106,291.427 332.108,291.537Z" />
      ),
      basic: {
        dark: (
          <path d="M338.088,299.906C337.959,300.139 335.605,300.963 334.737,301.077C331.768,301.47 327.472,300.243 327.67,295.559C327.759,293.445 329.282,291.339 331.572,291.249C332.078,291.229 333.355,291.236 334.48,292.227C336.47,293.98 335.773,296.224 335.887,295.997C335.922,295.927 336.381,294.795 336.212,293.745C335.823,291.343 333.691,289.944 331.434,290.015C329.196,290.086 326.994,291.382 325.722,293.206C322.953,297.176 325.586,303.126 330.19,303.562C337.686,304.272 338.088,299.906 338.088,299.906Z" />
        ),
        light: (
          <path d="M326.379,290.395C326.508,290.162 328.863,289.338 329.731,289.224C332.699,288.831 336.996,290.058 336.798,294.742C336.708,296.856 335.185,298.961 332.895,299.052C332.39,299.072 331.113,299.064 329.987,298.074C327.997,296.321 328.694,294.077 328.581,294.304C328.546,294.374 328.086,295.506 328.256,296.556C328.644,298.958 330.777,300.357 333.033,300.286C335.272,300.215 338.327,298.456 338.63,295.162C339.074,290.341 335.734,287.853 333.264,287.48C325.819,286.358 326.379,290.395 326.379,290.395Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M332.108,287.912C336.11,287.912 339.358,291.16 339.358,295.162C339.358,299.163 336.11,302.412 332.108,302.412C328.107,302.412 324.858,299.163 324.858,295.162C324.858,291.16 328.107,287.912 332.108,287.912ZM332.108,291.537C334.109,291.537 335.733,293.161 335.733,295.162C335.733,297.162 334.109,298.787 332.108,298.787C330.108,298.787 328.483,297.162 328.483,295.162C328.483,293.161 330.108,291.537 332.108,291.537Z" />
      ),
      basic: {
        dark: (
          <path d="M338.088,299.906C337.959,300.139 335.605,300.963 334.737,301.077C331.768,301.47 327.472,300.243 327.67,295.559C327.759,293.445 329.282,291.339 331.572,291.249C332.078,291.229 333.355,291.236 334.48,292.227C336.47,293.98 335.773,296.224 335.887,295.997C335.922,295.927 336.381,294.795 336.212,293.745C335.823,291.343 333.691,289.944 331.434,290.015C329.196,290.086 326.994,291.382 325.722,293.206C322.953,297.176 325.586,303.126 330.19,303.562C337.686,304.272 338.088,299.906 338.088,299.906Z" />
        ),
        light: (
          <path d="M326.379,290.395C326.508,290.162 328.863,289.338 329.731,289.224C332.699,288.831 336.996,290.058 336.798,294.742C336.708,296.856 335.185,298.961 332.895,299.052C332.39,299.072 331.113,299.064 329.987,298.074C327.997,296.321 328.694,294.077 328.581,294.304C328.546,294.374 328.086,295.506 328.256,296.556C328.644,298.958 330.777,300.357 333.033,300.286C335.272,300.215 338.327,298.456 338.63,295.162C339.074,290.341 335.734,287.853 333.264,287.48C325.819,286.358 326.379,290.395 326.379,290.395Z" />
        ),
      },
    },
  },
};

const shapeEar37 = {
  left: {
    front: {
      base: (
        <path d="M162.901,290.628C164.805,290.733 165.318,287.141 162.901,287.003C158.907,286.773 155.651,290.251 155.651,294.253C155.651,298.254 159.276,296.253 159.276,294.253C159.276,292.252 160.904,290.518 162.901,290.628Z" />
      ),
      basic: {
        dark: (
          <path d="M156.922,298.997C157.05,299.23 159.405,300.053 160.273,300.168C163.241,300.561 167.538,299.334 167.34,294.65C167.251,292.536 165.727,290.43 163.437,290.34C162.932,290.32 161.655,290.327 160.53,291.318C158.539,293.071 159.237,295.315 159.123,295.088C159.088,295.017 158.628,293.886 158.798,292.836C159.187,290.434 161.319,289.035 163.576,289.106C165.814,289.177 168.015,290.473 169.287,292.297C172.057,296.267 169.423,302.217 164.82,302.653C157.324,303.363 156.922,298.997 156.922,298.997Z" />
        ),
        light: (
          <path d="M168.631,289.486C168.502,289.253 166.147,288.429 165.279,288.315C162.311,287.922 158.014,289.149 158.212,293.833C158.302,295.947 159.825,298.052 162.115,298.143C162.62,298.163 163.897,298.155 165.022,297.165C167.013,295.412 166.316,293.168 166.429,293.395C166.464,293.465 166.924,294.597 166.754,295.647C166.365,298.049 164.233,299.448 161.976,299.377C159.738,299.306 156.682,297.547 156.38,294.253C155.936,289.432 159.276,286.944 161.746,286.571C169.191,285.449 168.631,289.486 168.631,289.486Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M162.901,287.003C158.9,287.003 155.651,290.251 155.651,294.253C155.651,298.254 158.9,301.503 162.901,301.503C166.903,301.503 170.151,298.254 170.151,294.253C170.151,290.251 166.903,287.003 162.901,287.003ZM162.901,290.628C160.901,290.628 159.276,292.252 159.276,294.253C159.276,296.253 160.901,297.878 162.901,297.878C164.902,297.878 166.526,296.253 166.526,294.253C166.526,292.252 164.902,290.628 162.901,290.628Z" />
      ),
      basic: {
        dark: (
          <path d="M156.922,298.997C157.05,299.23 159.405,300.053 160.273,300.168C163.241,300.561 167.538,299.334 167.34,294.65C167.251,292.536 165.727,290.43 163.437,290.34C162.932,290.32 161.655,290.327 160.53,291.318C158.539,293.071 159.237,295.315 159.123,295.088C159.088,295.017 158.628,293.886 158.798,292.836C159.187,290.434 161.319,289.035 163.576,289.106C165.814,289.177 168.015,290.473 169.287,292.297C172.057,296.267 169.423,302.217 164.82,302.653C157.324,303.363 156.922,298.997 156.922,298.997Z" />
        ),
        light: (
          <path d="M168.631,289.486C168.502,289.253 166.147,288.429 165.279,288.315C162.311,287.922 158.014,289.149 158.212,293.833C158.302,295.947 159.825,298.052 162.115,298.143C162.62,298.163 163.897,298.155 165.022,297.165C167.013,295.412 166.316,293.168 166.429,293.395C166.464,293.465 166.924,294.597 166.754,295.647C166.365,298.049 164.233,299.448 161.976,299.377C159.738,299.306 156.682,297.547 156.38,294.253C155.936,289.432 159.276,286.944 161.746,286.571C169.191,285.449 168.631,289.486 168.631,289.486Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M337.099,290.628C335.195,290.733 334.682,287.141 337.099,287.003C341.093,286.773 344.349,290.251 344.349,294.253C344.349,298.254 340.724,296.253 340.724,294.253C340.724,292.252 339.096,290.518 337.099,290.628Z" />
      ),
      basic: {
        dark: (
          <path d="M343.078,298.997C342.95,299.23 340.595,300.053 339.727,300.168C336.759,300.561 332.462,299.334 332.66,294.65C332.749,292.536 334.273,290.43 336.563,290.34C337.068,290.32 338.345,290.327 339.47,291.318C341.461,293.071 340.763,295.315 340.877,295.088C340.912,295.017 341.372,293.886 341.202,292.836C340.813,290.434 338.681,289.035 336.424,289.106C334.186,289.177 331.985,290.473 330.713,292.297C327.943,296.267 330.577,302.217 335.18,302.653C342.676,303.363 343.078,298.997 343.078,298.997Z" />
        ),
        light: (
          <path d="M331.369,289.486C331.498,289.253 333.853,288.429 334.721,288.315C337.689,287.922 341.986,289.149 341.788,293.833C341.698,295.947 340.175,298.052 337.885,298.143C337.38,298.163 336.103,298.155 334.978,297.165C332.987,295.412 333.684,293.168 333.571,293.395C333.536,293.465 333.076,294.597 333.246,295.647C333.635,298.049 335.767,299.448 338.024,299.377C340.262,299.306 343.318,297.547 343.62,294.253C344.064,289.432 340.724,286.944 338.254,286.571C330.809,285.449 331.369,289.486 331.369,289.486Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M337.099,287.003C341.1,287.003 344.349,290.251 344.349,294.253C344.349,298.254 341.1,301.503 337.099,301.503C333.097,301.503 329.849,298.254 329.849,294.253C329.849,290.251 333.097,287.003 337.099,287.003ZM337.099,290.628C339.099,290.628 340.724,292.252 340.724,294.253C340.724,296.253 339.099,297.878 337.099,297.878C335.098,297.878 333.474,296.253 333.474,294.253C333.474,292.252 335.098,290.628 337.099,290.628Z" />
      ),
      basic: {
        dark: (
          <path d="M343.078,298.997C342.95,299.23 340.595,300.053 339.727,300.168C336.759,300.561 332.462,299.334 332.66,294.65C332.749,292.536 334.273,290.43 336.563,290.34C337.068,290.32 338.345,290.327 339.47,291.318C341.461,293.071 340.763,295.315 340.877,295.088C340.912,295.017 341.372,293.886 341.202,292.836C340.813,290.434 338.681,289.035 336.424,289.106C334.186,289.177 331.985,290.473 330.713,292.297C327.943,296.267 330.577,302.217 335.18,302.653C342.676,303.363 343.078,298.997 343.078,298.997Z" />
        ),
        light: (
          <path d="M331.369,289.486C331.498,289.253 333.853,288.429 334.721,288.315C337.689,287.922 341.986,289.149 341.788,293.833C341.698,295.947 340.175,298.052 337.885,298.143C337.38,298.163 336.103,298.155 334.978,297.165C332.987,295.412 333.684,293.168 333.571,293.395C333.536,293.465 333.076,294.597 333.246,295.647C333.635,298.049 335.767,299.448 338.024,299.377C340.262,299.306 343.318,297.547 343.62,294.253C344.064,289.432 340.724,286.944 338.254,286.571C330.809,285.449 331.369,289.486 331.369,289.486Z" />
        ),
      },
    },
  },
};

const shapeEar38 = {
  left: {
    front: {
      base: (
        <path d="M164.716,282.533C166.62,282.638 167.133,279.047 164.716,278.908C160.722,278.678 157.466,282.156 157.466,286.158C157.466,290.159 161.091,288.158 161.091,286.158C161.091,284.157 162.719,282.423 164.716,282.533Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,290.902C158.866,291.135 161.22,291.959 162.088,292.073C165.056,292.466 169.353,291.239 169.155,286.555C169.066,284.441 167.542,282.335 165.252,282.245C164.747,282.225 163.47,282.232 162.345,283.223C160.355,284.976 161.052,287.22 160.938,286.993C160.903,286.922 160.443,285.791 160.613,284.741C161.002,282.339 163.134,280.94 165.391,281.011C167.629,281.082 169.83,282.378 171.103,284.202C173.872,288.172 171.238,294.122 166.635,294.558C159.139,295.268 158.737,290.902 158.737,290.902Z" />
        ),
        light: (
          <path d="M170.446,281.391C170.317,281.158 167.962,280.334 167.094,280.22C164.126,279.827 159.829,281.054 160.027,285.738C160.117,287.852 161.64,289.957 163.93,290.048C164.435,290.068 165.712,290.06 166.837,289.07C168.828,287.317 168.131,285.073 168.244,285.3C168.279,285.37 168.739,286.502 168.569,287.552C168.18,289.954 166.048,291.353 163.791,291.282C161.553,291.211 158.498,289.452 158.195,286.158C157.751,281.337 161.091,278.849 163.561,278.476C171.006,277.354 170.446,281.391 170.446,281.391Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,278.908C160.715,278.908 157.466,282.156 157.466,286.158C157.466,290.159 160.715,293.408 164.716,293.408C168.718,293.408 171.966,290.159 171.966,286.158C171.966,282.156 168.718,278.908 164.716,278.908ZM164.716,282.533C162.716,282.533 161.091,284.157 161.091,286.158C161.091,288.158 162.716,289.783 164.716,289.783C166.717,289.783 168.341,288.158 168.341,286.158C168.341,284.157 166.717,282.533 164.716,282.533Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,290.902C158.866,291.135 161.22,291.959 162.088,292.073C165.056,292.466 169.353,291.239 169.155,286.555C169.066,284.441 167.542,282.335 165.252,282.245C164.747,282.225 163.47,282.232 162.345,283.223C160.355,284.976 161.052,287.22 160.938,286.993C160.903,286.922 160.443,285.791 160.613,284.741C161.002,282.339 163.134,280.94 165.391,281.011C167.629,281.082 169.83,282.378 171.103,284.202C173.872,288.172 171.238,294.122 166.635,294.558C159.139,295.268 158.737,290.902 158.737,290.902Z" />
        ),
        light: (
          <path d="M170.446,281.391C170.317,281.158 167.962,280.334 167.094,280.22C164.126,279.827 159.829,281.054 160.027,285.738C160.117,287.852 161.64,289.957 163.93,290.048C164.435,290.068 165.712,290.06 166.837,289.07C168.828,287.317 168.131,285.073 168.244,285.3C168.279,285.37 168.739,286.502 168.569,287.552C168.18,289.954 166.048,291.353 163.791,291.282C161.553,291.211 158.498,289.452 158.195,286.158C157.751,281.337 161.091,278.849 163.561,278.476C171.006,277.354 170.446,281.391 170.446,281.391Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.321,282.533C333.417,282.638 332.904,279.047 335.321,278.908C339.316,278.678 342.571,282.156 342.571,286.158C342.571,290.159 338.946,288.158 338.946,286.158C338.946,284.157 337.319,282.423 335.321,282.533Z" />
      ),
      basic: {
        dark: (
          <path d="M341.301,290.902C341.172,291.135 338.817,291.959 337.949,292.073C334.981,292.466 330.684,291.239 330.882,286.555C330.972,284.441 332.495,282.335 334.785,282.245C335.29,282.225 336.567,282.232 337.692,283.223C339.683,284.976 338.986,287.22 339.099,286.993C339.134,286.922 339.594,285.791 339.424,284.741C339.036,282.339 336.903,280.94 334.646,281.011C332.408,281.082 330.207,282.378 328.935,284.202C326.165,288.172 328.799,294.122 333.403,294.558C340.898,295.268 341.301,290.902 341.301,290.902Z" />
        ),
        light: (
          <path d="M329.592,281.391C329.721,281.158 332.075,280.334 332.943,280.22C335.911,279.827 340.208,281.054 340.01,285.738C339.921,287.852 338.397,289.957 336.107,290.048C335.602,290.068 334.325,290.06 333.2,289.07C331.21,287.317 331.907,285.073 331.793,285.3C331.758,285.37 331.298,286.502 331.468,287.552C331.857,289.954 333.989,291.353 336.246,291.282C338.484,291.211 341.54,289.452 341.843,286.158C342.286,281.337 338.947,278.849 336.476,278.476C329.032,277.354 329.592,281.391 329.592,281.391Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.321,278.908C339.322,278.908 342.571,282.156 342.571,286.158C342.571,290.159 339.322,293.408 335.321,293.408C331.319,293.408 328.071,290.159 328.071,286.158C328.071,282.156 331.319,278.908 335.321,278.908ZM335.321,282.533C337.322,282.533 338.946,284.157 338.946,286.158C338.946,288.158 337.322,289.783 335.321,289.783C333.32,289.783 331.696,288.158 331.696,286.158C331.696,284.157 333.32,282.533 335.321,282.533Z" />
      ),
      basic: {
        dark: (
          <path d="M341.301,290.902C341.172,291.135 338.817,291.959 337.949,292.073C334.981,292.466 330.684,291.239 330.882,286.555C330.972,284.441 332.495,282.335 334.785,282.245C335.29,282.225 336.567,282.232 337.692,283.223C339.683,284.976 338.986,287.22 339.099,286.993C339.134,286.922 339.594,285.791 339.424,284.741C339.036,282.339 336.903,280.94 334.646,281.011C332.408,281.082 330.207,282.378 328.935,284.202C326.165,288.172 328.799,294.122 333.403,294.558C340.898,295.268 341.301,290.902 341.301,290.902Z" />
        ),
        light: (
          <path d="M329.592,281.391C329.721,281.158 332.075,280.334 332.943,280.22C335.911,279.827 340.208,281.054 340.01,285.738C339.921,287.852 338.397,289.957 336.107,290.048C335.602,290.068 334.325,290.06 333.2,289.07C331.21,287.317 331.907,285.073 331.793,285.3C331.758,285.37 331.298,286.502 331.468,287.552C331.857,289.954 333.989,291.353 336.246,291.282C338.484,291.211 341.54,289.452 341.843,286.158C342.286,281.337 338.947,278.849 336.476,278.476C329.032,277.354 329.592,281.391 329.592,281.391Z" />
        ),
      },
    },
  },
};

const shapeEar39 = {
  left: {
    front: {
      base: (
        <path d="M164.716,280.951C166.62,281.056 167.133,277.465 164.716,277.326C160.722,277.097 157.466,280.575 157.466,284.576C157.466,288.577 161.091,286.577 161.091,284.576C161.091,282.575 162.719,280.841 164.716,280.951Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,289.32C158.866,289.553 161.22,290.377 162.088,290.492C165.056,290.884 169.353,289.657 169.155,284.973C169.066,282.859 167.542,280.754 165.252,280.663C164.747,280.643 163.47,280.651 162.345,281.642C160.355,283.394 161.052,285.638 160.938,285.411C160.903,285.341 160.443,284.209 160.613,283.159C161.002,280.758 163.134,279.358 165.391,279.429C167.629,279.5 169.83,280.796 171.103,282.62C173.872,286.59 171.238,292.54 166.635,292.976C159.139,293.686 158.737,289.32 158.737,289.32Z" />
        ),
        light: (
          <path d="M170.446,279.809C170.317,279.576 167.962,278.753 167.094,278.638C164.126,278.245 159.829,279.472 160.027,284.156C160.117,286.27 161.64,288.376 163.93,288.466C164.435,288.486 165.712,288.479 166.837,287.488C168.828,285.735 168.131,283.491 168.244,283.718C168.279,283.789 168.739,284.92 168.569,285.971C168.18,288.372 166.048,289.771 163.791,289.7C161.553,289.629 158.498,287.87 158.195,284.576C157.751,279.755 161.091,277.267 163.561,276.895C171.006,275.772 170.446,279.809 170.446,279.809Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M164.716,277.326C160.715,277.326 157.466,280.575 157.466,284.576C157.466,288.577 160.715,291.826 164.716,291.826C168.718,291.826 171.966,288.577 171.966,284.576C171.966,280.575 168.718,277.326 164.716,277.326ZM164.716,280.951C162.716,280.951 161.091,282.575 161.091,284.576C161.091,286.577 162.716,288.201 164.716,288.201C166.717,288.201 168.341,286.577 168.341,284.576C168.341,282.575 166.717,280.951 164.716,280.951Z" />
      ),
      basic: {
        dark: (
          <path d="M158.737,289.32C158.866,289.553 161.22,290.377 162.088,290.492C165.056,290.884 169.353,289.657 169.155,284.973C169.066,282.859 167.542,280.754 165.252,280.663C164.747,280.643 163.47,280.651 162.345,281.642C160.355,283.394 161.052,285.638 160.938,285.411C160.903,285.341 160.443,284.209 160.613,283.159C161.002,280.758 163.134,279.358 165.391,279.429C167.629,279.5 169.83,280.796 171.103,282.62C173.872,286.59 171.238,292.54 166.635,292.976C159.139,293.686 158.737,289.32 158.737,289.32Z" />
        ),
        light: (
          <path d="M170.446,279.809C170.317,279.576 167.962,278.753 167.094,278.638C164.126,278.245 159.829,279.472 160.027,284.156C160.117,286.27 161.64,288.376 163.93,288.466C164.435,288.486 165.712,288.479 166.837,287.488C168.828,285.735 168.131,283.491 168.244,283.718C168.279,283.789 168.739,284.92 168.569,285.971C168.18,288.372 166.048,289.771 163.791,289.7C161.553,289.629 158.498,287.87 158.195,284.576C157.751,279.755 161.091,277.267 163.561,276.895C171.006,275.772 170.446,279.809 170.446,279.809Z" />
        ),
      },
    },
  },
  right: {
    front: {
      base: (
        <path d="M335.321,280.951C333.417,281.056 332.904,277.465 335.321,277.326C339.316,277.097 342.571,280.575 342.571,284.576C342.571,288.577 338.946,286.577 338.946,284.576C338.946,282.575 337.319,280.841 335.321,280.951Z" />
      ),
      basic: {
        dark: (
          <path d="M341.301,289.32C341.172,289.553 338.817,290.377 337.949,290.492C334.981,290.884 330.684,289.657 330.882,284.973C330.972,282.859 332.495,280.754 334.785,280.663C335.29,280.643 336.567,280.651 337.692,281.642C339.683,283.394 338.986,285.638 339.099,285.411C339.134,285.341 339.594,284.209 339.424,283.159C339.036,280.758 336.903,279.358 334.646,279.429C332.408,279.5 330.207,280.796 328.935,282.62C326.165,286.59 328.799,292.54 333.403,292.976C340.898,293.686 341.301,289.32 341.301,289.32Z" />
        ),
        light: (
          <path d="M329.592,279.809C329.721,279.576 332.075,278.753 332.943,278.638C335.911,278.245 340.208,279.472 340.01,284.156C339.921,286.27 338.397,288.376 336.107,288.466C335.602,288.486 334.325,288.479 333.2,287.488C331.21,285.735 331.907,283.491 331.793,283.718C331.758,283.789 331.298,284.92 331.468,285.971C331.857,288.372 333.989,289.771 336.246,289.7C338.484,289.629 341.54,287.87 341.843,284.576C342.286,279.755 338.947,277.267 336.476,276.895C329.032,275.772 329.592,279.809 329.592,279.809Z" />
        ),
      },
    },
    back: {
      base: (
        <path d="M335.321,277.326C339.322,277.326 342.571,280.575 342.571,284.576C342.571,288.577 339.322,291.826 335.321,291.826C331.319,291.826 328.071,288.577 328.071,284.576C328.071,280.575 331.319,277.326 335.321,277.326ZM335.321,280.951C337.322,280.951 338.946,282.575 338.946,284.576C338.946,286.577 337.322,288.201 335.321,288.201C333.32,288.201 331.696,286.577 331.696,284.576C331.696,282.575 333.32,280.951 335.321,280.951Z" />
      ),
      basic: {
        dark: (
          <path d="M341.301,289.32C341.172,289.553 338.817,290.377 337.949,290.492C334.981,290.884 330.684,289.657 330.882,284.973C330.972,282.859 332.495,280.754 334.785,280.663C335.29,280.643 336.567,280.651 337.692,281.642C339.683,283.394 338.986,285.638 339.099,285.411C339.134,285.341 339.594,284.209 339.424,283.159C339.036,280.758 336.903,279.358 334.646,279.429C332.408,279.5 330.207,280.796 328.935,282.62C326.165,286.59 328.799,292.54 333.403,292.976C340.898,293.686 341.301,289.32 341.301,289.32Z" />
        ),
        light: (
          <path d="M329.592,279.809C329.721,279.576 332.075,278.753 332.943,278.638C335.911,278.245 340.208,279.472 340.01,284.156C339.921,286.27 338.397,288.376 336.107,288.466C335.602,288.486 334.325,288.479 333.2,287.488C331.21,285.735 331.907,283.491 331.793,283.718C331.758,283.789 331.298,284.92 331.468,285.971C331.857,288.372 333.989,289.771 336.246,289.7C338.484,289.629 341.54,287.87 341.843,284.576C342.286,279.755 338.947,277.267 336.476,276.895C329.032,275.772 329.592,279.809 329.592,279.809Z" />
        ),
      },
    },
  },
};

export const earring3 = {
  id: "earring3",
  name: "Earring 3",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Smooth ears
    ear_shape_TEKWA: { ...shapeEar1 },
    ear_shape_0775v: { ...shapeEar2 },
    ear_shape_HOZ9g: { ...shapeEar3 },
    ear_shape_vBJ7j: { ...shapeEar4 },
    ear_shape_FYH9c: { ...shapeEar5 },
    ear_shape_aoGZG: { ...shapeEar6 },
    ear_shape_ZgR2p: { ...shapeEar7 },
    ear_shape_vdyix: { ...shapeEar8 },
    ear_shape_TbLPe: { ...shapeEar9 },
    ear_shape_3wnpn: { ...shapeEar10 },
    ear_shape_PDULw: { ...shapeEar11 },
    ear_shape_kOBSt: { ...shapeEar12 },
    ear_shape_yAT34: { ...shapeEar13 },
    ear_shape_8wAS4: { ...shapeEar14 },
    ear_shape_gcoC9: { ...shapeEar15 },
    ear_shape_bPDFx: { ...shapeEar16 },
    ear_shape_oEAG6: { ...shapeEar17 },
    ear_shape_Dp6oZ: { ...shapeEar18 },
    ear_shape_kf82L: { ...shapeEar19 },
    ear_shape_8sDmX: { ...shapeEar20 },
    ear_shape_k9vVR: { ...shapeEar21 },
    ear_shape_aKjrI: { ...shapeEar22 },
    ear_shape_2rsVH: { ...shapeEar23 },
    ear_shape_axjXx: { ...shapeEar24 },
    ear_shape_Zxpa2: { ...shapeEar25 },
    ear_shape_jWOL0: { ...shapeEar26 },
    ear_shape_TUUUj: { ...shapeEar27 },
    ear_shape_Wk3es: { ...shapeEar28 },
    ear_shape_XkDvO: { ...shapeEar29 },
    ear_shape_xyzCw: { ...shapeEar30 },
    ear_shape_epGZS: { ...shapeEar31 },
    ear_shape_4qBKl: { ...shapeEar32 },
    ear_shape_zwyyL: { ...shapeEar33 },
    ear_shape_kDDB4: { ...shapeEar34 },
    ear_shape_AwTvI: { ...shapeEar35 },
    ear_shape_0U6iQ: { ...shapeEar36 },
    ear_shape_BTIMM: { ...shapeEar37 },
    ear_shape_cnX1o: { ...shapeEar38 },
    ear_shape_UEVQI: { ...shapeEar39 },
    // Fuzzy ears
    ear_shape_T2K38: { ...shapeEar1 },
    ear_shape_02738: { ...shapeEar2 },
    ear_shape_H2Z38: { ...shapeEar3 },
    ear_shape_v2J38: { ...shapeEar4 },
    ear_shape_F2H38: { ...shapeEar5 },
    ear_shape_a2G38: { ...shapeEar6 },
    ear_shape_Z2R38: { ...shapeEar7 },
    ear_shape_v2y38: { ...shapeEar8 },
    ear_shape_T2L38: { ...shapeEar9 },
    ear_shape_32n38: { ...shapeEar10 },
    ear_shape_P2U38: { ...shapeEar11 },
    ear_shape_k2B38: { ...shapeEar12 },
    ear_shape_y2T38: { ...shapeEar13 },
    ear_shape_82A38: { ...shapeEar14 },
    ear_shape_g2o38: { ...shapeEar15 },
    ear_shape_b2D38: { ...shapeEar16 },
    ear_shape_o2A38: { ...shapeEar17 },
    ear_shape_D2638: { ...shapeEar18 },
    ear_shape_k2838: { ...shapeEar19 },
    ear_shape_82D38: { ...shapeEar20 },
    ear_shape_k2v38: { ...shapeEar21 },
    ear_shape_a2j38: { ...shapeEar22 },
    ear_shape_22s38: { ...shapeEar23 },
    ear_shape_a34f8: { ...shapeEar24 },
    ear_shape_Z2p38: { ...shapeEar25 },
    ear_shape_j2O38: { ...shapeEar26 },
    ear_shape_T2U38: { ...shapeEar27 },
    ear_shape_W2338: { ...shapeEar28 },
    ear_shape_X2D38: { ...shapeEar29 },
    ear_shape_x2z38: { ...shapeEar30 },
    ear_shape_e2G38: { ...shapeEar31 },
    ear_shape_42B38: { ...shapeEar32 },
    ear_shape_z2y38: { ...shapeEar33 },
    ear_shape_k2D38: { ...shapeEar34 },
    ear_shape_A2T38: { ...shapeEar35 },
    ear_shape_02638: { ...shapeEar36 },
    ear_shape_B2I38: { ...shapeEar37 },
    ear_shape_c2X38: { ...shapeEar38 },
    ear_shape_U2V38: { ...shapeEar39 },
    // Bumpy
    ear_shape_T4KrT: { ...shapeEar1 },
    ear_shape_047rT: { ...shapeEar2 },
    ear_shape_H4ZrT: { ...shapeEar3 },
    ear_shape_v4JrT: { ...shapeEar4 },
    ear_shape_F4HrT: { ...shapeEar5 },
    ear_shape_a4GrT: { ...shapeEar6 },
    ear_shape_Z4RrT: { ...shapeEar7 },
    ear_shape_v4yrT: { ...shapeEar8 },
    ear_shape_T4LrT: { ...shapeEar9 },
    ear_shape_34nrT: { ...shapeEar10 },
    ear_shape_P4UrT: { ...shapeEar11 },
    ear_shape_k4BrT: { ...shapeEar12 },
    ear_shape_y4TrT: { ...shapeEar13 },
    ear_shape_84ArT: { ...shapeEar14 },
    ear_shape_g4orT: { ...shapeEar15 },
    ear_shape_b4DrT: { ...shapeEar16 },
    ear_shape_o4ArT: { ...shapeEar17 },
    ear_shape_D46rT: { ...shapeEar18 },
    ear_shape_k48rT: { ...shapeEar19 },
    ear_shape_84DrT: { ...shapeEar20 },
    ear_shape_k4vrT: { ...shapeEar21 },
    ear_shape_a4jrT: { ...shapeEar22 },
    ear_shape_24srT: { ...shapeEar23 },
    ear_shape_a442T: { ...shapeEar24 },
    ear_shape_Z4prT: { ...shapeEar25 },
    ear_shape_j4OrT: { ...shapeEar26 },
    ear_shape_T4UrT: { ...shapeEar27 },
    ear_shape_W43rT: { ...shapeEar28 },
    ear_shape_X4DrT: { ...shapeEar29 },
    ear_shape_x4zrT: { ...shapeEar30 },
    ear_shape_e4GrT: { ...shapeEar31 },
    ear_shape_44BrT: { ...shapeEar32 },
    ear_shape_z4yrT: { ...shapeEar33 },
    ear_shape_k4DrT: { ...shapeEar34 },
    ear_shape_A4TrT: { ...shapeEar35 },
    ear_shape_046rT: { ...shapeEar36 },
    ear_shape_B4IrT: { ...shapeEar37 },
    ear_shape_c4XrT: { ...shapeEar38 },
    ear_shape_U4VrT: { ...shapeEar39 },
    // Furry
    ear_shape_1TEKWA: { ...shapeEar1 },
    ear_shape_10775v: { ...shapeEar2 },
    ear_shape_1HOZ9g: { ...shapeEar3 },
    ear_shape_1vBJ7j: { ...shapeEar4 },
    ear_shape_1FYH9c: { ...shapeEar5 },
    ear_shape_1aoGZG: { ...shapeEar6 },
    ear_shape_1ZgR2p: { ...shapeEar7 },
    ear_shape_1vdyix: { ...shapeEar8 },
    ear_shape_1TbLPe: { ...shapeEar9 },
    ear_shape_13wnpn: { ...shapeEar10 },
    ear_shape_1PDULw: { ...shapeEar11 },
    ear_shape_1kOBSt: { ...shapeEar12 },
    ear_shape_1yAT34: { ...shapeEar13 },
    ear_shape_18wAS4: { ...shapeEar14 },
    ear_shape_1gcoC9: { ...shapeEar15 },
    ear_shape_1bPDFx: { ...shapeEar16 },
    ear_shape_1oEAG6: { ...shapeEar17 },
    ear_shape_1Dp6oZ: { ...shapeEar18 },
    ear_shape_1kf82L: { ...shapeEar19 },
    ear_shape_18sDmX: { ...shapeEar20 },
    ear_shape_1k9vVR: { ...shapeEar21 },
    ear_shape_1aKjrI: { ...shapeEar22 },
    ear_shape_12rsVH: { ...shapeEar23 },
    ear_shape_1axjXx: { ...shapeEar24 },
    ear_shape_1Zxpa2: { ...shapeEar25 },
    ear_shape_1jWOL0: { ...shapeEar26 },
    ear_shape_1TUUUj: { ...shapeEar27 },
    ear_shape_1Wk3es: { ...shapeEar28 },
    ear_shape_1XkDvO: { ...shapeEar29 },
    ear_shape_1xyzCw: { ...shapeEar30 },
    ear_shape_1epGZS: { ...shapeEar31 },
    ear_shape_14qBKl: { ...shapeEar32 },
    ear_shape_1zwyyL: { ...shapeEar33 },
    ear_shape_1kDDB4: { ...shapeEar34 },
    ear_shape_1AwTvI: { ...shapeEar35 },
    ear_shape_10U6iQ: { ...shapeEar36 },
    ear_shape_1BTIMM: { ...shapeEar37 },
    ear_shape_1cnX1o: { ...shapeEar38 },
    ear_shape_1UEVQI: { ...shapeEar39 },
  },
};

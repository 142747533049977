import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { Feature } from "feature-voting/utilities/getFeatures";
import { Link, useParams } from "react-router-dom";
import UnauthorisedPage from "./Unauthorised";

const FeaturePage = (props) => {
  const [currentUser, setCurrentUser] = useState(auth.currentUser);
  const { id } = useParams();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  }, []);

  if (!!currentUser) {
    // getFeatureData(id);
    return <Feature featureId={id} />;
  } else {
    return <UnauthorisedPage />;
  }
};

export default FeaturePage;

import React from "react";
import CharacterHead from "character-creator/components/head/CharacterHead";
import Item from "character-creator/components/Item";
import AllGreys from "character-creator/colours/collections/Greys";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  const previewItem = { ...component, shape: { ...component.shape["left"] } };
  return (
    <>
      {/* <CharacterHead preview={true} /> */}
      <Item
        id={"shine"}
        previewId={previewId}
        previewItem={previewItem}
        defaultColour={AllGreys[0]}
      />
    </>
  );
};

export const viewbox = "170 205 80 80";

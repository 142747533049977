import AllSkintones from "character-creator/colours/skin";
import { BodyExtrasListArray } from "character-creator/components/body/extras/BodyExtrasList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const bodyExtrasPath = "bodyExtras.";

const bodyExtrasPageConfig = {
  id: "bodyExtrasPage",
  // dataAttribute: bodyExtrasPath,
  // maximum: 3,
  name: "Extras",
  description: "",
  controls: [
    {
      id: "bodyExtraShape0",
      name: "Shape 1",
      description: "",
      dataAttribute: [bodyExtrasPath + "0.shape"],
      // attributeType: "list",
      variationAttribute: "",
      previewType: "preview",
      options: BodyExtrasListArray,
      colourControls: [
        {
          id: "bodyExtraColour0",
          name: "Colour 1",
          description: "",
          dataAttribute: [bodyExtrasPath + "0.colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
    },
    {
      id: "bodyExtraShape1",
      name: "Shape 2",
      description: "",
      dataAttribute: [bodyExtrasPath + "1.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyExtrasListArray,
      colourControls: [
        {
          id: "bodyExtraColour1",
          name: "Colour 2",
          description: "",
          dataAttribute: [bodyExtrasPath + "1.colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
    },
    {
      id: "bodyExtraShape2",
      name: "Shape 3",
      description: "",
      dataAttribute: [bodyExtrasPath + "2.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyExtrasListArray,
      colourControls: [
        {
          id: "bodyExtraColour2",
          name: "Colour 3",
          description: "",
          dataAttribute: [bodyExtrasPath + "2.colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
    },
  ],
};

const BodyExtrasPage = {
  id: "bodyExtrasPage",
  name: "Extras",
  description: "",
  content: <SubPage page={bodyExtrasPageConfig} />,
};

export default BodyExtrasPage;

import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { BackgroundShapesPack1 } from "./packs/BackgroundShapesPack1";
import { BackgroundShapesPack2 } from "./packs/BackgroundShapesPack2";

export const BackgroundShapesList = {
  "0": BlankListOption,
  ...BackgroundShapesPack1,
  ...BackgroundShapesPack2,
};

export const BackgroundShapesListArray = Object.values(BackgroundShapesList);

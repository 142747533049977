import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import {
  NoseAccessoriesListLeft,
  NoseAccessoriesListRight,
} from "./NoseAccessoriesList";
import AllGreys from "character-creator/colours/collections/Greys";
import {
  buildTransform,
  filterListById,
} from "character-creator/menu/menu-helper";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";

function mapStateToProps(state, ownProps) {
  // Configs
  const noseAccessoriesConfig = getCharacterConfigValue(
    state,
    "accessories.nose"
  );
  const noseAccessoryLeftConfig = noseAccessoriesConfig?.left;
  const noseAccessoryRightConfig = noseAccessoriesConfig?.right;
  // const noseAccessorySeptumConfig = noseAccessoriesConfig.septum;

  // Left
  let LeftObject;
  if (
    ownProps.preview &&
    ownProps.previewSide === "left" &&
    ownProps.accessory
  ) {
    LeftObject = ownProps.accessory;
  } else if (
    !ownProps.preview &&
    noseAccessoryLeftConfig?.shape &&
    noseAccessoryLeftConfig.shape !== "0"
  ) {
    LeftObject = filterListById(
      NoseAccessoriesListLeft,
      noseAccessoryLeftConfig.shape
    );
  } else {
    LeftObject = null;
  }

  const LeftColours = noseAccessoryLeftConfig?.colour
    ? noseAccessoryLeftConfig.colour
    : AllGreys[0];

  const LeftTransform =
    noseAccessoryLeftConfig?.transform &&
    buildTransform(noseAccessoryLeftConfig.transform);

  // Right
  let RightObject;
  if (
    ownProps.preview &&
    ownProps.previewSide === "right" &&
    ownProps.accessory
  ) {
    RightObject = ownProps.accessory;
  } else if (
    !ownProps.preview &&
    noseAccessoryRightConfig?.shape &&
    noseAccessoryRightConfig.shape !== "0"
  ) {
    RightObject = filterListById(
      NoseAccessoriesListRight,
      noseAccessoryRightConfig.shape
    );
  } else {
    RightObject = null;
  }

  const RightColours = noseAccessoryRightConfig?.colour
    ? noseAccessoryRightConfig.colour
    : AllGreys[0];

  const RightTransform =
    noseAccessoryRightConfig?.transform &&
    buildTransform(noseAccessoryRightConfig.transform);

  return {
    Left: {
      shape: LeftObject ? LeftObject.shape : null,
      colour: LeftColours,
      transform: LeftTransform,
    },
    Right: {
      shape: RightObject ? RightObject.shape : null,
      colour: RightColours,
      transform: RightTransform,
    },
  };
}

const NoseAccessories = (props) => {
  const { Left, Right, previewId } = props;

  if (Left.shape || Right.shape) {
    return (
      <g
        id={
          previewId ? `g-nose-accessories~${previewId}` : "g-nose-accessories"
        }
      >
        {/* Left */}
        {Left.shape && (
          <ItemComponent
            id={
              previewId
                ? `nose-accessories-left~${previewId}`
                : "nose-accessories-left"
            }
            component={Left}
            colour={Left.colour}
            transform={Left.transform}
            previewTransform={true}
            preview={previewId ? true : false}
          />
        )}

        {/* Right */}
        {Right.shape && (
          <ItemComponent
            id={
              previewId
                ? `nose-accessories-right~${previewId}`
                : "nose-accessories-right"
            }
            component={Right}
            colour={Right.colour}
            transform={Right.transform}
            previewTransform={true}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(NoseAccessories);

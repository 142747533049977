import AllSkintones from "character-creator/colours/skin";
import { HeadShapeListArray } from "character-creator/components/head/shape/HeadShapeList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";
// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const headPath = "head.";

const headShapePageConfig = {
  id: "headShapePage",
  name: "Shape",
  description: "Get ahead of the game.",
  controls: [
    {
      id: "headShape",
      name: "Shape and texture",
      description: "",
      dataAttribute: [headPath + "shape"],
      variationAttribute: "",
      previewType: "preview",
      previewViewbox: "125 140 250 250",
      options: HeadShapeListArray,
      colourControls: [
        {
          id: "headColour",
          name: "Colour",
          description: "",
          dataAttribute: [headPath + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
    },
  ],
};

const HeadShapePage = {
  id: "headShapePage",
  name: "Shape",
  description: "Get ahead of the game.",
  content: <SubPage page={headShapePageConfig} />,
};

export default HeadShapePage;

import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import MoustacheList from "./MoustacheList";
import {
  buildTransform,
  filterListById,
  translateTransformObject,
} from "character-creator/menu/menu-helper";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import HairColours from "character-creator/colours/hair";

function mapStateToProps(state, ownProps) {
  const defaultHairColour =
    getCharacterConfigValue(state, "hair.colour") ?? HairColours[0];

  const moustacheConfig = getCharacterConfigValue(state, "moustache");
  let moustacheObject = null;
  if (ownProps.preview && ownProps.moustache) {
    moustacheObject = ownProps.moustache;
  } else if (moustacheConfig?.shape && moustacheConfig.shape !== "0") {
    moustacheObject = filterListById(MoustacheList, moustacheConfig.shape);
  }

  const colour = moustacheConfig?.colour
    ? moustacheConfig.colour
    : defaultHairColour;

  const transform =
    !ownProps.preview && moustacheConfig?.transform
      ? translateTransformObject(buildTransform(moustacheConfig.transform))
      : "";

  return {
    colour,
    moustache: moustacheObject,

    transform,
  };
}

const Moustache = (props) => {
  const { colour, moustache, previewId, transform } = props;

  if (moustache) {
    return (
      <g
        id={previewId ? `g-moustache~${previewId}` : "g-moustache"}
        {...transform}
        transform-origin="50% 50%"
      >
        {moustache.shape.left && (
          <ItemComponent
            id={previewId ? `moustache-left~${previewId}` : "moustache-left"}
            component={{ shape: moustache.shape.left }}
            colour={colour}
            preview={previewId ? true : false}
          />
        )}
        {moustache.shape.left && (
          <ItemComponent
            id={previewId ? `moustache-right~${previewId}` : "moustache-right"}
            component={{ shape: moustache.shape.right }}
            colour={colour}
            preview={previewId ? true : false}
          />
        )}
        {!moustache.shape.left && (
          <ItemComponent
            id={previewId ? `moustache-full~${previewId}` : "moustache-full"}
            component={moustache}
            colour={colour}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Moustache);

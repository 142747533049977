import React from "react";
import CharacterHead from "../../CharacterHead";
import Item from "character-creator/components/Item";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { connect } from "react-redux";

function mapStateToProps(state, ownProps) {
  const skintone = getCharacterConfigValue(state, "skintone");

  return {
    skintone: {
      darker: skintone.darker,
      dark: skintone.dark,
      base: skintone.light,
      light: skintone.lighter,
    },
  };
}

const PreviewBase = (props) => {
  const { component, previewId, skintone } = props;

  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <Item
        id={"nose"}
        previewId={previewId}
        previewItem={component}
        defaultColour={skintone}
      />
    </>
  );
};

export const previewViewbox = "200 210 100 100";

export default connect(mapStateToProps)(PreviewBase);

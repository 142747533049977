import React from "react";
import { get, cloneDeep, random, clone } from "lodash";
import Control from "./elements/controls/Control";
import {
  getCharacterConfigValue,
  getConfigValue,
  getStyleConfigValue,
} from "character-creator/utilities/storeHelpers";

export const getIsColouringStyle = (state) => {
  return getStyleConfigValue(state, "colours") === "coloursBlank";
};

/********************************************************
Transforms
 ********************************************************/
export const buildTransform = (transformConfig) => {
  const scale = transformConfig.scale ? `scale(${transformConfig.scale})` : "";
  // const rotate = transformConfig.rotate ? `rotate(${transformConfig.rotate})` : "";
  const translate =
    transformConfig.x != null || transformConfig.y != null
      ? `translate(${transformConfig.x ? transformConfig.x : 0}, ${
          transformConfig.y ? transformConfig.y : 0
        })`
      : "";

  return {
    position: translate.concat(" ", scale),
    opacity: transformConfig.opacity,
  };
};

export const translateTransformObject = (transform) => {
  let transformAttribute = (transform.position || transform.opacity) && {
    transform: transform.position,
    opacity: transform.opacity ? transform.opacity : 1,
  };

  return transformAttribute;
};

// const calculateTransformOpposite = (transformConfig) => {};

/********************************************************
  Dependencies! Does this section/control group depend on any other controls? If so, does it meet the conditions? E.g. can't have pupils without an iris, or an iris without an eye!

  If a value list is provided, the value for the given dependency attribute must be in that list, otherwise assume it has to be != "" and != "0"

  dependencies: [
    {
      description: "short description here for me",
      attribute: eyeBasePath + "left.shape",
    },
    {
      description: "short description here for me",
      attribute: hairSidePath + "left.shape",
      value: ["value1", "value2", "value3"]
    }
  ]
  */
export const checkDependencies = (controlDependencies, state) => {
  // Check each dependency
  let dependenciesPassed = controlDependencies.find((dependency) => {
    let thisDependencyPassed = false;

    //////////////////////////////////////////
    // If dependent on an attribute
    //////////////////////////////////////////
    if (dependency.attribute) {
      const dependencyAttributeList = dependency.attribute;

      // Check every attribute to see if any are valid
      thisDependencyPassed = dependencyAttributeList.find((attribute) => {
        const attributeValue = getConfigValue(
          dependency.stateType,
          state,
          dependencyAttributeList[0]
        );
        let thisAttributeValid = dependency.value
          ? dependency.value.includes(attributeValue)
          : !!attributeValue && attributeValue !== "" && attributeValue !== "0";

        return thisAttributeValid;
      });
    }

    return thisDependencyPassed;
  });
  return dependenciesPassed;
};

/********************************************************
Variations

Considered having these as a sort of dependency but would be better if they were a child menu option like colours/transforms?

Variations are better than blanket "dependent" options, because they may only be available for certain components, such as horn texture/breakages (breakages may be difficult/impossible for some horns), glasses (only some styles have bridges/sides), but also keeps main options a bit leaner

// TODO: Use these for hair partings as originally intended?

********************************************************/

// If "variationAttribute" = something AND chosen option has variations then show variations menu. Also used to display whether a component has variations on its button
export const hasVariation = (componentObject, variationName) => {
  return (
    true &&
    !!componentObject.variations &&
    !!componentObject.variations[variationName]
  );
};

/********************************************************
Dynamic shape
 ********************************************************/
export const hasValidDynamicShape = (itemObject, dynamicAttribute) => {
  return !!itemObject.shape[dynamicAttribute];
};

export const filterListByDynamicShape = (list, id) => {
  return list.filter((listOption) => listOption.shape[id]);
};

/********************************************************
Filter shape list by ID
 ********************************************************/
export const filterListById = (list, id) => {
  return list.filter((shape) => shape.id === id)[0];
};

/********************************************************
Randomising
 ********************************************************/

export const getRandomFromList = (list) => {
  return list[random(1, list.length - 1)].id;
};

/********************************************************
Controls
 ********************************************************/

export const ControlOptionsList = (props) => {
  const { options } = props;
  const list = options.map((controlOption, index) => (
    <Control
      key={index}
      controlId={options[index]}
      controlOption={controlOption}
      {...props}
    />
  ));
  return <>{list}</>;
};

/********************************************************
Custom colours
 ********************************************************/

// If newColour already in recentColours, return null, otherwise return new recent colours list
export const buildRecentColoursList = (currentList, newColour) => {
  let newList = currentList ? clone(currentList) : [];

  let addToList = true;
  if (!!filterListById(newList, newColour.id)) {
    addToList = false;
  }
  if (addToList) {
    newList.push(newColour);
    if (Object.keys(newList).length >= 6) {
      newList.shift();
    }
  }

  return newList;
};

// Update config list if one in the list has changed
export const buildConfigList = (currentList, index, newItem) => {
  let newList = cloneDeep(currentList);

  newList[index] = newItem;

  return newList;
};

// Update custom colour palettes
export const buildCustomPalettesList = (currentList, newColour) => {
  let newList = clone(currentList);

  let addToList = true;
  if (!!filterListById(newList, newColour.id)) {
    addToList = false;
  }
  if (addToList) {
    newList.push(newColour);
  }

  return newList;
};

/********************************************************
Controls
 ********************************************************/

export const SymmetryControl = (props) => {
  const { controlId, symmetryValue, symmetryAttribute, updateSymmetry } = props;

  return (
    <label
      htmlFor={controlId + "-symmetryToggle"}
      className="button-secondary button-toggle"
      data-checked={!!symmetryValue}
      role="button"
      title={!!symmetryValue ? `Symmetry on` : `Symmetry off`}
    >
      {!!symmetryValue ? `Symmetry on` : `Symmetry off`}

      <input
        id={controlId + "-symmetryToggle"}
        type="checkbox"
        role="switch"
        checked={!!symmetryValue}
        onChange={($this) =>
          updateSymmetry(symmetryAttribute, $this.target.checked)
        }
      />
    </label>
  );
};

/*

If shape has single component:
  just say "Colour" rather than "Main/whatever colour"

else:
For each component,
generate a colour control per component

*/

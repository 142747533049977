import { allColours } from "character-creator/colours/colourSchemes";

import { ScarsListArray } from "character-creator/components/head/markings/scars/HeadScarsShapeList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const scarsPath = "headScars.";

const headScarsPageConfig = {
  id: "headScarsPage",
  name: "Scars",
  description: "",
  controls: [
    {
      id: "scarShape1",
      name: "Scar 1",
      description: "",
      dataAttribute: [scarsPath + "0.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: ScarsListArray,
      colourControls: [
        {
          id: "scarColour1",
          name: "Scar Colour",
          description: "",
          dataAttribute: [scarsPath + "0.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "headScarTransformX1",
          name: "Horizontal",
          description: "",
          dataAttribute: [scarsPath + "0.transform.x"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "headScarTransformY1",
          name: "Vertical",
          description: "",
          dataAttribute: [scarsPath + "0.transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "headScarTransformScale1",
          name: "Size",
          description: "",
          dataAttribute: [scarsPath + "0.transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
        {
          id: "headScarTransformO1",
          name: "Opacity",
          description: "",
          dataAttribute: [scarsPath + "0.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "scarShape2",
      name: "Scar 2",
      description: "",
      dataAttribute: [scarsPath + "1.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: ScarsListArray,
      colourControls: [
        {
          id: "scarColour2",
          name: "Scar Colour",
          description: "",
          dataAttribute: [scarsPath + "1.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "headScarTransformX2",
          name: "Horizontal",
          description: "",
          dataAttribute: [scarsPath + "1.transform.x"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "headScarTransformY2",
          name: "Vertical",
          description: "",
          dataAttribute: [scarsPath + "1.transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "headScarTransformScale2",
          name: "Size",
          description: "",
          dataAttribute: [scarsPath + "1.transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
        {
          id: "headScarTransformO2",
          name: "Opacity",
          description: "",
          dataAttribute: [scarsPath + "1.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "scarShape3",
      name: "Scar 3",
      description: "",
      dataAttribute: [scarsPath + "2.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: ScarsListArray,
      colourControls: [
        {
          id: "scarColour3",
          name: "Scar Colour",
          description: "",
          dataAttribute: [scarsPath + "2.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "headScarTransformX3",
          name: "Horizontal",
          description: "",
          dataAttribute: [scarsPath + "2.transform.x"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "headScarTransformY3",
          name: "Vertical",
          description: "",
          dataAttribute: [scarsPath + "2.transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "headScarTransformScale3",
          name: "Size",
          description: "",
          dataAttribute: [scarsPath + "2.transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
        {
          id: "headScarTransformO3",
          name: "Opacity",
          description: "",
          dataAttribute: [scarsPath + "2.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

const HeadScarsPage = {
  id: "headScarsPage",
  name: "Scars",
  description: "",
  content: <SubPage page={headScarsPageConfig} />,
};

export default HeadScarsPage;

import React from "react";
import CharacterHead from "character-creator/components/head/CharacterHead";
import { connect } from "react-redux";
import { EyeShapesList } from "../shapes/EyeShapeList";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import Item from "character-creator/components/Item";

function mapStateToProps(state, ownProps) {
  const eyeConfigLeftShape = getCharacterConfigValue(
    state,
    "eyes.base.left.shape"
  );
  return {
    defaultEyeShape:
      eyeConfigLeftShape && eyeConfigLeftShape !== "0"
        ? eyeConfigLeftShape
        : "eye_shape_Uugu5",
  };
}

export const PreviewBase = (props) => {
  const { component, defaultEyeShape, previewId } = props;

  const previewItem = {
    ...component,
    shape: component.shape[defaultEyeShape].left?.lower
      ? component.shape[defaultEyeShape].left?.lower
      : component.shape[defaultEyeShape].left?.upper,
  };

  return (
    <>
      {/* <CharacterHead preview={true} /> */}
      {/* <Eyes
        preview={true}
        previewId={previewId}
        eyes={EyeShapesList[defaultEyeShape]}
        eyelash={component}
        defaultEyeShape={defaultEyeShape}
      /> */}
      <Item id={"eyelashes"} previewId={previewId} previewItem={previewItem} />
    </>
  );
};

export const viewbox = "170 205 80 80";

export default connect(mapStateToProps)(PreviewBase);

import React from "react";
import { PreviewBase, viewbox } from "../../Preview";

//////////////////////////////////////
// Shawl 1
//////////////////////////////////////

const shawl_jeEPZ476cK_AC = {
  base: (
    <path d="M201.26 500s-14.913-54.532-13.783-116.077c.207-11.261-54.355 5.743-94.926 19.933-25.747 9.031-42.864 33.48-42.542 60.764.213 18.368.414 35.38.414 35.38H201.26Zm97.48 0s14.913-54.532 13.783-116.077c-.207-11.261 54.355 5.743 94.926 19.933 25.747 9.031 42.864 33.48 42.542 60.764-.213 18.368-.414 35.38-.414 35.38H298.74Z" />
  ),
  basic: {
    dark: (
      <path d="M211.654 477.91c10.485 30.63-8.514 45.823-10.101 43.431-8.563-12.908-10.351-21.942-13.054-38.658-4.146-25.635-5.847-50.745-4.619-76.646.309-6.522.295-15.43 3.163-25.777.465-1.677 9.278 39.907 12.542 54.596 3.241 14.583 7.229 28.913 12.069 43.054Zm77.003 0c-10.485 30.63 8.514 45.823 10.101 43.431 8.563-12.908 10.351-21.942 13.054-38.658 4.146-25.635 5.847-50.745 4.619-76.646-.309-6.522-.295-15.43-3.163-25.777-.464-1.677-9.278 39.907-12.542 54.596-3.241 14.583-7.228 28.913-12.069 43.054ZM173.115 376.904s2.486-.467 2.002.609c-1.554 3.452-4.113 11.315-4.952 15.009-3.043 13.387-2.583 28.12-2.123 41.721.424 12.537 1.389 25.084 3.055 37.519 1.624 12.119 3.793 24.157 6.435 36.095.62 2.8-1.245 4.58-1.441 3.84-2.438-9.16-5.106-18.332-6.939-27.636-4.106-20.849-6.109-42.1-5.208-63.357.63-14.865 2.714-30.24 9.171-43.8Zm154.081 0s-2.486-.467-2.002.609c1.554 3.452 4.113 11.315 4.953 15.009 3.042 13.387 2.583 28.12 2.122 41.721-.424 12.537-1.389 25.084-3.055 37.519-1.624 12.119-3.793 24.157-6.435 36.095-.62 2.8 1.245 4.58 1.442 3.84 2.437-9.16 5.105-18.332 6.938-27.636 4.107-20.849 6.109-42.1 5.208-63.357-.63-14.865-2.714-30.24-9.171-43.8ZM51.418 448.359c.148.889.166 1.817.208 2.245.483 4.972 1.217 9.557 2.64 14.343 3.436 11.559 6.951 25.529 17.615 32.638 1.254.837 2.539 1.651 3.852 2.415H50.759c-.493-11.986-.887-31.802.606-50.989.017-.217.035-.434.053-.652Zm397.475 0c-.148.889-.166 1.817-.208 2.245-.483 4.972-1.217 9.557-2.64 14.343-3.436 11.559-6.951 25.529-17.614 32.638-1.255.837-2.54 1.651-3.853 2.415h24.974c.493-11.986.887-31.802-.606-50.989-.017-.217-.034-.434-.053-.652Z" />
    ),
  },
};

const shawl_jeEPZ476cK_B = {
  base: (
    <path d="M201.26 500s-14.913-74.962-13.783-136.507c.207-11.261-54.355 18.264-94.926 32.453-25.747 9.032-42.864 41.39-42.542 68.674.213 18.368.414 35.38.414 35.38H201.26Zm97.48 0s14.913-74.962 13.783-136.507c-.207-11.261 54.355 18.264 94.926 32.453 25.747 9.032 42.864 41.39 42.542 68.674-.213 18.368-.414 35.38-.414 35.38H298.74Z" />
  ),
  basic: {
    dark: (
      <path d="M211.654 468.655c10.485 37.158-8.514 55.587-10.101 52.686-8.563-15.658-10.351-26.617-13.054-46.895-4.146-31.098-5.847-61.558-4.619-92.977.309-7.912.295-18.719 3.163-31.27.465-2.035 9.278 48.41 12.542 66.229 3.241 17.69 7.229 35.073 12.069 52.227Zm77.003 0c-10.485 37.158 8.514 55.587 10.101 52.686 8.563-15.658 10.351-26.617 13.054-46.895 4.146-31.098 5.847-61.558 4.619-92.977-.309-7.912-.295-18.719-3.163-31.27-.464-2.035-9.278 48.41-12.542 66.229-3.241 17.69-7.228 35.073-12.069 52.227ZM173.115 346.127s2.486-.566 2.002.739c-1.554 4.187-4.113 13.726-4.952 18.207-3.043 16.24-2.583 34.112-2.123 50.611.424 15.209 1.389 30.429 3.055 45.513 1.624 14.702 3.793 29.305 6.435 43.787.62 3.396-1.245 5.556-1.441 4.659-2.438-11.113-5.106-22.238-6.939-33.525-4.106-25.292-6.109-51.071-5.208-76.858.63-18.032 2.714-36.683 9.171-53.133Zm154.081 0s-2.486-.566-2.002.739c1.554 4.187 4.113 13.726 4.953 18.207 3.042 16.24 2.583 34.112 2.122 50.611-.424 15.209-1.389 30.429-3.055 45.513-1.624 14.702-3.793 29.305-6.435 43.787-.62 3.396 1.245 5.556 1.442 4.659 2.437-11.113 5.105-22.238 6.938-33.525 4.107-25.292 6.109-51.071 5.208-76.858-.63-18.032-2.714-36.683-9.171-53.133ZM51.418 448.359c.148.889.166 1.817.208 2.245.483 4.972 1.217 9.557 2.64 14.343 3.436 11.559 6.951 25.529 17.615 32.638 1.254.837 2.539 1.651 3.852 2.415H50.759c-.493-11.986-.887-31.802.606-50.989.017-.217.035-.434.053-.652Zm397.475 0c-.148.889-.166 1.817-.208 2.245-.483 4.972-1.217 9.557-2.64 14.343-3.436 11.559-6.951 25.529-17.614 32.638-1.255.837-2.54 1.651-3.853 2.415h24.974c.493-11.986.887-31.802-.606-50.989-.017-.217-.034-.434-.053-.652Z" />
    ),
  },
};

const shawl_jeEPZ476cK = {
  id: "shawl_jeEPZ476cK",
  name: "Shawl 1",
  description: "Some clothing",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1A
    base_body_shape_lcUGV: {
      ...shawl_jeEPZ476cK_AC,
    },
    // Body 1B
    base_body_shape_taw74: {
      ...shawl_jeEPZ476cK_B,
    },
    // Body 1C
    base_body_shape_jnr29: {
      ...shawl_jeEPZ476cK_AC,
    },
    // Body 2A
    base_body_shape_g825h: {
      ...shawl_jeEPZ476cK_AC,
    },
    // Body 2B
    base_body_shape_fa048: {
      ...shawl_jeEPZ476cK_B,
    },
    // Body 2C
    base_body_shape_mge5o: {
      ...shawl_jeEPZ476cK_AC,
    },
    // Body 3A
    base_body_shape_gj245: {
      ...shawl_jeEPZ476cK_AC,
    },
    // Body 3B
    base_body_shape_942n5: {
      ...shawl_jeEPZ476cK_B,
    },
    // Body 3C
    base_body_shape_0424n: {
      ...shawl_jeEPZ476cK_AC,
    },
    // Body 4A
    base_body_shape_fds8g: {
      ...shawl_jeEPZ476cK_AC,
    },
    // Body 4B
    base_body_shape_sg894: {
      ...shawl_jeEPZ476cK_B,
    },
    // Body 4C
    base_body_shape_fa84m: {
      ...shawl_jeEPZ476cK_AC,
    },
  },
};

//////////////////////////////////////
// Shawl 2
//////////////////////////////////////

const shawl_DtggyLJqBS_AC = {
  base: (
    <path d="M201.26 500s-14.641-57.592-13.511-119.137c.072-3.956-17.482-3.355-18.087 3.676-14.634 6.54-50.447 9.991-77.111 19.317-25.747 9.031-42.864 33.48-42.542 60.764.213 18.368.414 35.38.414 35.38H201.26Zm97.48 0s14.641-57.592 13.511-119.137c-.072-3.956 17.482-3.355 18.087 3.676 14.634 6.54 50.447 9.991 77.111 19.317 25.747 9.031 42.864 33.48 42.542 60.764-.213 18.368-.414 35.38-.414 35.38H298.74Z" />
  ),
  basic: {
    dark: (
      <path d="M211.654 477.91c10.485 30.63-8.514 45.823-10.101 43.431-8.563-12.908-10.351-21.942-13.054-38.658-4.146-25.635-5.847-50.745-4.619-76.646.309-6.522.295-15.43 3.163-25.777.465-1.677 9.278 39.907 12.542 54.596 3.241 14.583 7.229 28.913 12.069 43.054Zm77.003 0c-10.485 30.63 8.514 45.823 10.101 43.431 8.563-12.908 10.351-21.942 13.054-38.658 4.146-25.635 5.847-50.745 4.619-76.646-.309-6.522-.295-15.43-3.163-25.777-.464-1.677-9.278 39.907-12.542 54.596-3.241 14.583-7.228 28.913-12.069 43.054ZM173.115 376.904s2.486-.467 2.002.609c-1.554 3.452-4.113 11.315-4.952 15.009-3.043 13.387-2.583 28.12-2.123 41.721.424 12.537 1.389 25.084 3.055 37.519 1.624 12.119 3.793 24.157 6.435 36.095.62 2.8-1.245 4.58-1.441 3.84-2.438-9.16-5.106-18.332-6.939-27.636-4.106-20.849-6.109-42.1-5.208-63.357.63-14.865 2.714-30.24 9.171-43.8Zm154.081 0s-2.486-.467-2.002.609c1.554 3.452 4.113 11.315 4.953 15.009 3.042 13.387 2.583 28.12 2.122 41.721-.424 12.537-1.389 25.084-3.055 37.519-1.624 12.119-3.793 24.157-6.435 36.095-.62 2.8 1.245 4.58 1.442 3.84 2.437-9.16 5.105-18.332 6.938-27.636 4.107-20.849 6.109-42.1 5.208-63.357-.63-14.865-2.714-30.24-9.171-43.8ZM51.418 448.359c.148.889.166 1.817.208 2.245.483 4.972 1.217 9.557 2.64 14.343 3.436 11.559 6.951 25.529 17.615 32.638 1.254.837 2.539 1.651 3.852 2.415H50.759c-.493-11.986-.887-31.802.606-50.989.017-.217.035-.434.053-.652Zm397.475 0c-.148.889-.166 1.817-.208 2.245-.483 4.972-1.217 9.557-2.64 14.343-3.436 11.559-6.951 25.529-17.614 32.638-1.255.837-2.54 1.651-3.853 2.415h24.974c.493-11.986.887-31.802-.606-50.989-.017-.217-.034-.434-.053-.652Z" />
    ),
  },
};

const shawl_DtggyLJqBS_B = {
  base: (
    <path d="M201.26 500s-15.189-77.654-14.439-139.205c.071-5.834-23.769 1.2-24.345 7.622-5.435 8.197-43.662 14.476-69.925 27.529-24.434 12.145-42.864 41.39-42.542 68.674.213 18.368.414 35.38.414 35.38H201.26Zm97.481 0s15.188-77.654 14.438-139.205c-.071-5.834 23.769 1.2 24.345 7.622 5.435 8.197 43.662 14.476 69.925 27.529 24.434 12.145 42.865 41.39 42.543 68.674-.214 18.368-.414 35.38-.414 35.38H298.741Z" />
  ),
  basic: {
    dark: (
      <path d="M211.654 468.655c10.485 37.158-8.514 55.587-10.101 52.686-8.563-15.658-10.351-26.617-13.054-46.895-4.146-31.098-5.847-61.558-4.619-92.977.309-7.912.295-18.719 3.163-31.27.465-2.035 9.278 48.41 12.542 66.229 3.241 17.69 7.229 35.073 12.069 52.227Zm77.003 0c-10.485 37.158 8.514 55.587 10.101 52.686 8.563-15.658 10.351-26.617 13.054-46.895 4.146-31.098 5.847-61.558 4.619-92.977-.309-7.912-.295-18.719-3.163-31.27-.464-2.035-9.278 48.41-12.542 66.229-3.241 17.69-7.228 35.073-12.069 52.227ZM167.556 352.063s2.486-.565 2.002.74c-1.554 4.187-4.113 13.726-4.953 18.207-3.042 16.24-2.583 34.111-2.122 50.611.424 15.209 1.389 30.429 3.055 45.513 1.624 14.701 3.793 29.304 6.435 43.787.62 3.396-1.245 5.556-1.442 4.658-2.437-11.113-5.105-22.238-6.938-33.525-4.107-25.292-6.109-51.071-5.208-76.857.63-18.033 2.714-36.683 9.171-53.134Zm165.199 0s-2.486-.565-2.002.74c1.554 4.187 4.113 13.726 4.953 18.207 3.042 16.24 2.583 34.111 2.122 50.611-.424 15.209-1.389 30.429-3.055 45.513-1.624 14.701-3.793 29.304-6.435 43.787-.619 3.396 1.245 5.556 1.442 4.658 2.437-11.113 5.105-22.238 6.938-33.525 4.107-25.292 6.109-51.071 5.208-76.857-.63-18.033-2.714-36.683-9.171-53.134ZM51.418 448.359c.148.889.166 1.817.208 2.245.483 4.972 1.217 9.557 2.64 14.343 3.436 11.559 6.951 25.529 17.615 32.638 1.254.837 2.539 1.651 3.852 2.415H50.759c-.493-11.986-.887-31.802.606-50.989.017-.217.035-.434.053-.652ZM424.578 500h24.974c.493-11.986.887-31.802-.606-50.989-.017-.217-.034-.434-.053-.652-.148.889-.166 1.817-.208 2.245-.483 4.972-1.217 9.557-2.64 14.343-3.436 11.559-6.951 25.529-17.614 32.638-1.255.837-2.54 1.651-3.853 2.415Z" />
    ),
  },
};

const shawl_DtggyLJqBS = {
  id: "shawl_DtggyLJqBS",
  name: "Shawl 2",
  description: "Some clothing",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1A
    base_body_shape_lcUGV: {
      ...shawl_DtggyLJqBS_AC,
    },
    // Body 1B
    base_body_shape_taw74: {
      ...shawl_DtggyLJqBS_B,
    },
    // Body 1C
    base_body_shape_jnr29: {
      ...shawl_DtggyLJqBS_AC,
    },
    // Body 2A
    base_body_shape_g825h: {
      ...shawl_DtggyLJqBS_AC,
    },
    // Body 2B
    base_body_shape_fa048: {
      ...shawl_DtggyLJqBS_B,
    },
    // Body 2C
    base_body_shape_mge5o: {
      ...shawl_DtggyLJqBS_AC,
    },
    // Body 3A
    base_body_shape_gj245: {
      ...shawl_DtggyLJqBS_AC,
    },
    // Body 3B
    base_body_shape_942n5: {
      ...shawl_DtggyLJqBS_B,
    },
    // Body 3C
    base_body_shape_0424n: {
      ...shawl_DtggyLJqBS_AC,
    },
    // Body 4A
    base_body_shape_fds8g: {
      ...shawl_DtggyLJqBS_AC,
    },
    // Body 4B
    base_body_shape_sg894: {
      ...shawl_DtggyLJqBS_B,
    },
    // Body 4C
    base_body_shape_fa84m: {
      ...shawl_DtggyLJqBS_AC,
    },
  },
};

export const ShawlsPack1 = {
  shawl_jeEPZ476cK,
  shawl_DtggyLJqBS,
};

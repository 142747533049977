import React, { useState, useEffect, createContext } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./firebase";
import { BrowserRouter, Route, Link, Routes, redirect, useNavigate } from "react-router-dom";
import HomePage from "pages/Home";
import AvatarCreator from "pages/AvatarCreator";
import Characters from "pages/Characters";
import { HeaderEmoji } from "character-creator/utilities/HomeElements";
import Login from "pages/Login";
import FeatureVoting from "pages/FeatureVoting";
import FeaturePage from "pages/Feature";
import KofiLogo from "assets/images/social/ko-fi.png"
import PatreonLogo from "assets/images/social/patreon.png"
import InstagramLogo from "assets/images/social/instagram.png"
// import { handleLogout } from "account/utilities/logout";
// import { CreateYourBlank, KofiButton, DemoButton, Feature } from "character-creator/utilities/HomeElements"

export const CurrentUserContext = createContext(auth.currentUser);

// #TODO Add skip link
export default function CharacterArtSite() {
  const [currentUser, setCurrentUser] = useState(auth.currentUser);
  
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {setCurrentUser(user);
      // if (user) {
      //   setCurrentUser(user);
      // } else {
      //   setCurrentUser(null);
      // }
    });
  }, []);

  const handleLogout = () => {
  signOut(auth)
    .then(() => {
      console.log("Clicked log out");
    })
    .catch((error) => {
      console.log("Error during logout");
    });
};

  return (
    // <CurrentUserContext.Provider value={CurrentUserContext}>
      <BrowserRouter>
        <header>
          <div className="header-title">
            <Link to="/">
              <h1>Character.Art {process.env.NODE_ENV === "development" && `(Dev)`}</h1>
            </Link>
            <HeaderEmoji />
          </div>
          <nav title="Main navigation" className="nav nav-inline nav-header">
            <ul>
              <li className="button button-primary">
                <Link to="/create">Create</Link>
              </li>
              {currentUser && <li className="">
                <Link to="/characters">Characters</Link>
              </li>}
              {currentUser && <li className="">
                <Link to="/feature-voting">Vote</Link>
              </li>}
              {!currentUser && (
                <li id="button-sign-in" className="button button-primary">
                  <Link to="/login">Login</Link>
                </li>
              )}
              <li className="nav-social">
                <a
                  href="https://ko-fi.com/characterart"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Join us on Ko-fi (opens in new tab)"
                >
                  <img src={KofiLogo} title="Join us on Ko-fi" alt="Ko-fi logo" className="social-img"></img>
                </a>
              </li>
              <li className="nav-social">
                <a
                  href="https://patreon.com/characterartapp"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Join us on Patreon (opens in new tab)"
                >
                  <img src={PatreonLogo} title="Join us on Patreon" alt="Patreon logo" className="social-img"></img>
                </a>
              </li>
              <li className="nav-social">
                <a
                  href="https://instagram.com/characterartapp"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Follow on Instagram (opens in new tab)"
                >
                  <img src={InstagramLogo} title="Join us on Instagram" alt="Instagram logo" className="social-img"></img>
                </a>
              </li>
              {currentUser && (
                <li id="button-log-out" className="button button-secondary" onClick={handleLogout}>
                  Log out
                </li>
              )}
            </ul>
          </nav>
        </header>
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/create" element={<AvatarCreator />} />
            <Route path="/characters" element={<Characters />} />
            <Route path="/feature-voting" element={<FeatureVoting />} />
            <Route path="/feature-voting/:id" element={<FeaturePage/>} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </main>
      </BrowserRouter>
    // </CurrentUserContext.Provider>
  );
}

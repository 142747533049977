import { PreviewBase } from "../Preview";
import {
  horn_1ejoH,
  horn_1o7sQ,
  horn_2KAy6,
  horn_5hJxn,
  horn_8VORY,
  horn_a6buF,
  horn_akeSD,
  horn_E9d6o,
  horn_EEtKp,
  horn_gzKJD,
  horn_krtQg,
  horn_OIlzY,
  horn_PS7EE,
  horn_SBneR,
  horn_sKEBM,
  horn_T0Axo,
  horn_Wa16s,
  horn_WDF2W,
  horn_XtBFK,
  horn_yi4mB,
  horn_zkrvm,
} from "../../shared/packs/HornsSharedPack1";

const horn_back_side_gzKJD = {
  ...horn_gzKJD,
  id: "horn_back_side_gzKJD",
  preview: {
    ...horn_gzKJD.preview,
    base: PreviewBase,
  },
};

const horn_back_side_WDF2W = {
  ...horn_WDF2W,
  id: "horn_back_side_WDF2W",
  preview: {
    ...horn_WDF2W.preview,
    base: PreviewBase,
  },
};

const horn_back_side_5hJxn = {
  ...horn_5hJxn,
  id: "horn_back_side_5hJxn",
  preview: {
    ...horn_5hJxn.preview,
    base: PreviewBase,
  },
};

const horn_back_side_yi4mB = {
  ...horn_yi4mB,
  id: "horn_back_side_yi4mB",
  preview: {
    ...horn_yi4mB.preview,
    base: PreviewBase,
  },
};

const horn_back_side_2KAy6 = {
  ...horn_2KAy6,
  id: "horn_back_side_2KAy6",
  preview: {
    ...horn_2KAy6.preview,
    base: PreviewBase,
  },
};

const horn_back_side_a6buF = {
  ...horn_a6buF,
  id: "horn_back_side_a6buF",
  preview: {
    ...horn_a6buF.preview,
    base: PreviewBase,
  },
};

const horn_back_side_XtBFK = {
  ...horn_XtBFK,
  id: "horn_back_side_XtBFK",
  preview: {
    ...horn_XtBFK.preview,
    base: PreviewBase,
  },
};

const horn_back_side_EEtKp = {
  ...horn_EEtKp,
  id: "horn_back_side_EEtKp",
  preview: {
    ...horn_EEtKp.preview,
    base: PreviewBase,
  },
};

const horn_back_side_PS7EE = {
  ...horn_PS7EE,
  id: "horn_back_side_PS7EE",
  preview: {
    ...horn_PS7EE.preview,
    base: PreviewBase,
  },
};

const horn_back_side_Wa16s = {
  ...horn_Wa16s,
  id: "horn_back_side_Wa16s",
  preview: {
    ...horn_Wa16s.preview,
    base: PreviewBase,
  },
};

const horn_back_side_1ejoH = {
  ...horn_1ejoH,
  id: "horn_back_side_1ejoH",
  preview: {
    ...horn_1ejoH.preview,
    base: PreviewBase,
  },
};

const horn_back_side_E9d6o = {
  ...horn_E9d6o,
  id: "horn_back_side_E9d6o",
  preview: {
    ...horn_E9d6o.preview,
    base: PreviewBase,
  },
};

const horn_back_side_1o7sQ = {
  ...horn_1o7sQ,
  id: "horn_back_side_1o7sQ",
  preview: {
    ...horn_1o7sQ.preview,
    base: PreviewBase,
  },
};

const horn_back_side_SBneR = {
  ...horn_SBneR,
  id: "horn_back_side_SBneR",
  preview: {
    ...horn_SBneR.preview,
    base: PreviewBase,
  },
};

const horn_back_side_sKEBM = {
  ...horn_sKEBM,
  id: "horn_back_side_sKEBM",
  preview: {
    ...horn_sKEBM.preview,
    base: PreviewBase,
  },
};

const horn_back_side_zkrvm = {
  ...horn_zkrvm,
  id: "horn_back_side_zkrvm",
  preview: {
    ...horn_zkrvm.preview,
    base: PreviewBase,
  },
};

const horn_back_side_krtQg = {
  ...horn_krtQg,
  id: "horn_back_side_krtQg",
  preview: {
    ...horn_krtQg.preview,
    base: PreviewBase,
  },
};

const horn_back_side_akeSD = {
  ...horn_akeSD,
  id: "horn_back_side_akeSD",
  preview: {
    ...horn_akeSD.preview,
    base: PreviewBase,
  },
};

const horn_back_side_OIlzY = {
  ...horn_OIlzY,
  id: "horn_back_side_OIlzY",
  preview: {
    ...horn_OIlzY.preview,
    base: PreviewBase,
  },
};

const horn_back_side_8VORY = {
  ...horn_8VORY,
  id: "horn_back_side_8VORY",
  preview: {
    ...horn_8VORY.preview,
    base: PreviewBase,
  },
};

const horn_back_side_T0Axo = {
  ...horn_T0Axo,
  id: "horn_back_side_T0Axo",
  preview: {
    ...horn_T0Axo.preview,
    base: PreviewBase,
  },
};

export const BackHornsPack1 = {
  horn_back_side_gzKJD,
  horn_back_side_WDF2W,
  horn_back_side_5hJxn,
  horn_back_side_yi4mB,
  horn_back_side_2KAy6,
  horn_back_side_a6buF,
  horn_back_side_XtBFK,
  horn_back_side_EEtKp,
  horn_back_side_PS7EE,
  horn_back_side_Wa16s,
  horn_back_side_1ejoH,
  horn_back_side_E9d6o,
  horn_back_side_1o7sQ,
  horn_back_side_SBneR,
  horn_back_side_sKEBM,
  horn_back_side_zkrvm,
  horn_back_side_krtQg,
  horn_back_side_akeSD,
  horn_back_side_OIlzY,
  horn_back_side_8VORY,
  horn_back_side_T0Axo,
};

import React from "react";
import { connect } from "react-redux";
import { NeckAccessoriesList } from "./NeckAccessoriesList";
import NeckAccessoryBack from "./NeckAccessoryBack";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";

/**
This will be used to build the different back layers of neck accessories
 */

function mapStateToProps(state, ownProps) {
  const neckAccessoriesConfig = getCharacterConfigValue(
    state,
    "accessories.neck"
  );
  const Layer1 = neckAccessoriesConfig?.layer1;
  const Layer2 = neckAccessoriesConfig?.layer2;
  const Layer3 = neckAccessoriesConfig?.layer3;

  return { Layer1, Layer2, Layer3 };
}

const NeckAccessoriesBackLayers = (props) => {
  const { previewId, Layer1, Layer2, Layer3 } = props;

  if (
    Layer1?.shape &&
    filterListById(NeckAccessoriesList, Layer1.shape).shape.back
  ) {
    return (
      <g
        id={
          previewId
            ? `g-neckAccessory-back-layers~${previewId}`
            : "g-neckAccessory-back-layers"
        }
      >
        {Layer1.shape && <NeckAccessoryBack layer="1" layerConfig={Layer1} />}
        {Layer2?.shape &&
          filterListById(NeckAccessoriesList, Layer2.shape).shape.back && (
            <NeckAccessoryBack layer="2" layerConfig={Layer2} />
          )}
        {Layer3?.shape &&
          filterListById(NeckAccessoriesList, Layer3.shape).shape.back && (
            <NeckAccessoryBack layer="3" layerConfig={Layer3} />
          )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(NeckAccessoriesBackLayers);

import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { GlassesPack1 } from "./packs/GlassesPack1";
import { GlassesShinePack1 } from "./packs/GlassesShinePack1";

export const EyeAccessoriesList = Object.values({
  "0": BlankListOption,
  ...GlassesPack1,
});

export const GlassesShineList = Object.values({
  "0": BlankListOption,
  ...GlassesShinePack1,
});

import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { hairTopPack1 } from "./packs/HairTopPack1";
import { hairTopPack2 } from "./packs/HairTopPack2";

export const hairTopFullList = Object.values({
  "0": BlankListOption,
  ...hairTopPack1,
  ...hairTopPack2,
});

export default hairTopFullList;

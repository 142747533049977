import { CreateYourBlank } from "character-creator/utilities/HomeElements";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "firebase/auth";
// import { connect } from "react-redux";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import UnauthorisedPage from "./Unauthorised";

// function mapStateToProps(state) {
//   return {};
// }

const PageContent = (props) => {
  const { user } = props;
  if (!!user) {
    return (
      <>
        <section className="panel">
          <h2>
            Create your <CreateYourBlank /> now!
          </h2>
          <p>
            Welcome back, {user?.displayName ? user.displayName : ""} your
            characters are all below
          </p>
        </section>
      </>
    );
  } else {
    return <UnauthorisedPage />;
  }
};

const CharactersPage = (props) => {
  const [currentUser, setCurrentUser] = useState(auth.currentUser);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    unsubscribe();
  }, []);

  return <PageContent user={currentUser} />;
};

export default CharactersPage;

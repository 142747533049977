import React from "react";
import PreviewBase, {
  viewbox,
} from "character-creator/components/head/face/eyes/eyelashes/Preview";

const eyelash_shape_ur294 = {
  id: "eyelash_shape_ur294",
  name: "Eyelash 1",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M197.21,257.82c-1.76-0.85-4.02-0.51-5.46,0.81c-1.44,1.32-1.96,3.55-1.26,5.37C190.84,260.81,193.98,258.19,197.21,257.82z" />
              <path d="M195.25,254.3c-1.26-1.63-3.51-2.42-5.5-1.93c-2,0.48-3.64,2.21-4.01,4.23C187.86,253.6,192.4,252.65,195.25,254.3z" />
              <path d="M200.46,260.79c-1.61-0.66-3.61-0.17-4.74,1.14c-1.13,1.32-1.3,3.37-0.41,4.86C195.09,263.96,197.44,261.18,200.46,260.79z" />
              <path d="M225.63,259.66c1.09-0.66,2.63-0.41,3.46,0.55c0.83,0.96,0.85,2.52,0.05,3.5C229.26,261.81,227.62,259.99,225.63,259.66z" />
              <path d="M222.59,262.07c2.51,1.17,3.35,4.89,1.59,7.02C224.68,266.74,223.91,264.16,222.59,262.07z" />
              <path d="M214.58,265.7c0.72,2.56,0.62,5.67-0.24,8.11C213.54,271.08,213.66,267.69,214.58,265.7z" />
              <path d="M217.75,264.57c2.69,1.03,3.47,5.16,1.33,7.1C220.2,269.29,219.44,266.18,217.75,264.57z" />
              <path d="M203.34,263.31c-1.6-0.18-3.27,0.61-4.14,1.96c-0.88,1.35-0.92,3.19-0.11,4.58C199.02,267.09,200.88,264.39,203.34,263.31z" />
              <path d="M207.11,264.21c-3.35,1.49-4.78,6.18-2.83,9.28C203.73,270.35,204.76,266.97,207.11,264.21z" />
              <path d="M211.26,265.38c-2.91,2.5-3.32,5.97-1.02,8.69C208.88,271.26,209.5,268.12,211.26,265.38z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M302.79,257.82c1.76-0.85,4.02-0.51,5.46,0.81c1.44,1.32,1.96,3.55,1.26,5.37C309.16,260.81,306.02,258.19,302.79,257.82z" />
              <path d="M304.75,254.3c1.26-1.63,3.51-2.42,5.5-1.93c2,0.48,3.64,2.21,4.01,4.23C312.14,253.6,307.6,252.65,304.75,254.3z" />
              <path d="M299.54,260.79c1.61-0.66,3.61-0.17,4.74,1.14c1.13,1.32,1.3,3.37,0.41,4.86C304.91,263.96,302.56,261.18,299.54,260.79z" />
              <path d="M274.37,259.66c-1.09-0.66-2.63-0.41-3.46,0.55s-0.85,2.52-0.05,3.5C270.74,261.81,272.38,259.99,274.37,259.66z" />
              <path d="M277.41,262.07c-2.51,1.17-3.35,4.89-1.59,7.02C275.32,266.74,276.09,264.16,277.41,262.07z" />
              <path d="M285.42,265.7c-0.72,2.56-0.62,5.67,0.24,8.11C286.46,271.08,286.34,267.69,285.42,265.7z" />
              <path d="M282.25,264.57c-2.69,1.03-3.47,5.16-1.33,7.1C279.8,269.29,280.56,266.18,282.25,264.57z" />
              <path d="M296.66,263.31c1.6-0.18,3.27,0.61,4.14,1.96c0.88,1.35,0.92,3.19,0.11,4.58C300.98,267.09,299.12,264.39,296.66,263.31z" />
              <path d="M292.89,264.21c3.35,1.49,4.78,6.18,2.83,9.28C296.27,270.35,295.24,266.97,292.89,264.21z" />
              <path d="M288.74,265.38c2.91,2.5,3.32,5.97,1.02,8.69C291.12,271.26,290.5,268.12,288.74,265.38z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.57,251.97c-1.46-1.34-3.6-1.9-5.52-1.44c-1.93,0.46-3.58,1.93-4.27,3.79C184.76,251.08,189.54,249.95,192.57,251.97z" />
              <path d="M193.75,254.27c-3.04-0.48-6.24,1.92-6.63,4.97C188.4,256.59,191.14,254.71,193.75,254.27z" />
              <path d="M196.31,257.92c-1.4-0.31-2.94,0.17-3.93,1.21c-0.99,1.04-1.38,2.61-1,3.99C191.79,260.68,193.85,258.61,196.31,257.92z" />
              <path d="M226.43,253.25c0.97-0.3,2.11,0.08,2.7,0.91c0.59,0.82,0.6,2.02,0.01,2.85C229.54,255.38,228.16,253.57,226.43,253.25z" />
              <path d="M220.01,258.82c1.98,0.58,2.9,3.35,1.66,5C221.86,261.99,221.06,260.07,220.01,258.82z" />
              <path d="M215.82,260.4c1.28,1.71,1.57,4.1,0.75,6.06C216.3,264.47,216.03,262.47,215.82,260.4z" />
              <path d="M199.82,260.38c-2.58,0.19-4.51,3.22-3.61,5.64C196.33,263.78,197.7,261.64,199.82,260.38z" />
              <path d="M203.14,261.85c-1.76,1.47-2.54,4.01-1.93,6.22C201.91,265.93,202.61,263.78,203.14,261.85z" />
              <path d="M211.81,261.96c-0.25,2.04-0.49,4.08-0.74,6.11C210.32,266.02,210.49,263.65,211.81,261.96z" />
              <path d="M208.11,262.48c-1.54,1.57-2.09,3.93-1.38,5.94C205.09,266.45,205.89,263.17,208.11,262.48z" />
              <path d="M223.1,256.34c2-0.5,4.06,1.85,3.3,3.77C226,258.38,224.61,256.93,223.1,256.34z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.43,251.97c1.46-1.34,3.6-1.9,5.52-1.44s3.58,1.93,4.27,3.79C315.24,251.08,310.46,249.95,307.43,251.97z" />
              <path d="M306.25,254.27c3.04-0.48,6.24,1.92,6.63,4.97C311.6,256.59,308.86,254.71,306.25,254.27z" />
              <path d="M303.69,257.92c1.4-0.31,2.94,0.17,3.93,1.21c0.99,1.04,1.38,2.61,1,3.99C308.21,260.68,306.15,258.61,303.69,257.92z" />
              <path d="M273.57,253.25c-0.97-0.3-2.11,0.08-2.7,0.91c-0.59,0.82-0.6,2.02-0.01,2.85C270.46,255.38,271.84,253.57,273.57,253.25z" />
              <path d="M279.99,258.82c-1.98,0.58-2.9,3.35-1.66,5C278.14,261.99,278.94,260.07,279.99,258.82z" />
              <path d="M284.18,260.4c-1.28,1.71-1.57,4.1-0.75,6.06C283.7,264.47,283.97,262.47,284.18,260.4z" />
              <path d="M300.18,260.38c2.58,0.19,4.51,3.22,3.61,5.64C303.67,263.78,302.3,261.64,300.18,260.38z" />
              <path d="M296.86,261.85c1.76,1.47,2.54,4.01,1.93,6.22C298.09,265.93,297.39,263.78,296.86,261.85z" />
              <path d="M288.19,261.96c0.25,2.04,0.49,4.08,0.74,6.11C289.68,266.02,289.51,263.65,288.19,261.96z" />
              <path d="M291.89,262.48c1.54,1.57,2.09,3.93,1.38,5.94C294.91,266.45,294.11,263.17,291.89,262.48z" />
              <path d="M276.9,256.34c-2-0.5-4.06,1.85-3.3,3.77C274,258.38,275.39,256.93,276.9,256.34z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M199.22,255.33c-1.71-0.81-3.69-0.54-5.2,0.45c-1.51,0.99-2.44,2.64-1.85,4.19C192.7,257.37,195.97,255.43,199.22,255.33z" />
              <path d="M192.09,242.65c-1.16-1.42-3.37-2.22-5.39-1.96c-2.02,0.26-3.75,1.58-4.24,3.22C184.76,241.58,189.34,241.11,192.09,242.65z" />
              <path d="M202.29,258.11c-1.57-0.65-3.59-0.38-4.79,0.62c-1.2,1.01-1.49,2.68-0.68,3.97C196.75,260.37,199.25,258.24,202.29,258.11z" />
              <path d="M228.15,259.83c1.12-0.47,2.64-0.16,3.42,0.68c0.78,0.84,0.71,2.13-0.14,2.88C231.66,261.84,230.12,260.23,228.15,259.83z" />
              <path d="M224.98,261.6c2.44,1.13,3.07,4.24,1.2,5.88C226.82,265.59,226.19,263.41,224.98,261.6z" />
              <path d="M216.83,263.44c0.58,2.15,0.31,4.71-0.69,6.65C215.5,267.79,215.81,265.01,216.83,263.44z" />
              <path d="M220.02,263.33c2.63,1.03,3.18,4.48,0.94,5.93C222.21,267.38,221.62,264.77,220.02,263.33z" />
              <path d="M206.05,260.12c-1.58-0.25-3.29,0.28-4.24,1.33s-1.09,2.56-0.36,3.76C201.53,262.94,203.53,260.84,206.05,260.12z" />
              <path d="M209.47,261.72c-3.41,0.87-5.07,4.2-3.28,6.6C205.79,265.98,206.99,263.57,209.47,261.72z" />
              <path d="M213.31,263.25c-3.04,1.86-3.64,4.69-1.5,7.08C210.6,267.93,211.4,265.38,213.31,263.25z" />
              <path d="M192.83,245.87c-1.45-1.07-3.53-1.53-5.39-1.17c-1.86,0.35-3.45,1.5-4.12,2.98C186.1,245.98,189.84,245.39,192.83,245.87z" />
              <path d="M194.35,248.98c-1.41-1.23-3.69-1.71-5.59-1.17c-1.9,0.53-3.31,2.05-3.45,3.71C187.11,249.02,191.41,248.04,194.35,248.98z" />
              <path d="M196.23,252.45c-1.64-0.82-3.85-0.95-5.57-0.33c-1.71,0.62-2.86,1.96-2.89,3.38C189.01,253.07,193.19,251.76,196.23,252.45z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M300.78,255.33c1.71-0.81,3.69-0.54,5.2,0.45c1.51,0.99,2.44,2.64,1.85,4.19C307.3,257.37,304.03,255.43,300.78,255.33z" />
              <path d="M307.91,242.65c1.17-1.42,3.37-2.22,5.39-1.96c2.02,0.26,3.75,1.58,4.24,3.22C315.24,241.58,310.66,241.11,307.91,242.65z" />
              <path d="M297.71,258.11c1.57-0.65,3.59-0.38,4.79,0.62c1.2,1.01,1.49,2.68,0.68,3.97C303.25,260.37,300.75,258.24,297.71,258.11z" />
              <path d="M271.85,259.83c-1.12-0.47-2.64-0.16-3.42,0.68c-0.78,0.84-0.71,2.13,0.14,2.88C268.34,261.84,269.88,260.23,271.85,259.83z" />
              <path d="M275.02,261.6c-2.44,1.13-3.07,4.24-1.2,5.88C273.18,265.59,273.81,263.41,275.02,261.6z" />
              <path d="M283.17,263.44c-0.58,2.15-0.31,4.71,0.69,6.65C284.5,267.79,284.19,265.01,283.17,263.44z" />
              <path d="M279.98,263.33c-2.63,1.03-3.18,4.48-0.94,5.93C277.79,267.38,278.38,264.77,279.98,263.33z" />
              <path d="M293.95,260.12c1.58-0.25,3.29,0.28,4.24,1.33s1.09,2.56,0.36,3.76C298.47,262.94,296.47,260.84,293.95,260.12z" />
              <path d="M290.53,261.72c3.41,0.87,5.07,4.2,3.28,6.6C294.21,265.98,293.01,263.57,290.53,261.72z" />
              <path d="M286.69,263.25c3.04,1.86,3.64,4.69,1.5,7.08C289.4,267.93,288.6,265.38,286.69,263.25z" />
              <path d="M307.17,245.87c1.45-1.07,3.53-1.53,5.39-1.17s3.45,1.5,4.12,2.98C313.9,245.98,310.16,245.39,307.17,245.87z" />
              <path d="M305.65,248.98c1.41-1.23,3.69-1.71,5.59-1.17c1.9,0.53,3.31,2.05,3.45,3.71C312.89,249.02,308.59,248.04,305.65,248.98z" />
              <path d="M303.77,252.45c1.64-0.82,3.85-0.95,5.57-0.33c1.71,0.62,2.86,1.96,2.89,3.38C310.99,253.07,306.81,251.76,303.77,252.45z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.32,258.97c-1.46-1.34-3.6-1.9-5.52-1.44c-1.93,0.46-3.58,1.93-4.27,3.79C185.51,258.08,190.29,256.95,193.32,258.97z" />
              <path d="M196.5,260.77c-3.04-0.48-6.24,1.92-6.63,4.97C191.15,263.09,193.89,261.21,196.5,260.77z" />
              <path d="M200.31,261.92c-1.4-0.31-2.94,0.17-3.93,1.21c-0.99,1.04-1.38,2.61-1,3.99C195.79,264.68,197.85,262.61,200.31,261.92z" />
              <path d="M231.43,254c0.97-0.3,2.11,0.08,2.7,0.91c0.59,0.82,0.6,2.02,0.01,2.85C234.54,256.13,233.16,254.32,231.43,254z" />
              <path d="M225.01,259.57c1.98,0.58,2.9,3.35,1.66,5C226.86,262.74,226.06,260.82,225.01,259.57z" />
              <path d="M220.82,261.15c1.28,1.71,1.57,4.1,0.75,6.06C221.3,265.22,221.03,263.22,220.82,261.15z" />
              <path d="M204.82,262.38c-2.58,0.19-4.51,3.22-3.61,5.64C201.33,265.78,202.7,263.64,204.82,262.38z" />
              <path d="M208.14,262.35c-1.76,1.47-2.54,4.01-1.93,6.22C206.91,266.43,207.61,264.28,208.14,262.35z" />
              <path d="M216.81,261.96c-0.25,2.04-0.49,4.08-0.74,6.11C215.32,266.02,215.49,263.65,216.81,261.96z" />
              <path d="M213.11,262.23c-1.54,1.57-2.09,3.93-1.38,5.94C210.09,266.2,210.89,262.92,213.11,262.23z" />
              <path d="M228.35,257.34c2-0.5,4.06,1.85,3.3,3.77C231.25,259.38,229.86,257.93,228.35,257.34z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.68,258.97c1.46-1.34,3.6-1.9,5.52-1.44c1.93,0.46,3.58,1.93,4.27,3.79C314.49,258.08,309.71,256.95,306.68,258.97z" />
              <path d="M303.5,260.77c3.04-0.48,6.24,1.92,6.63,4.97C308.85,263.09,306.11,261.21,303.5,260.77z" />
              <path d="M299.69,261.92c1.4-0.31,2.94,0.17,3.93,1.21c0.99,1.04,1.38,2.61,1,3.99C304.21,264.68,302.15,262.61,299.69,261.92z" />
              <path d="M268.57,254c-0.97-0.3-2.11,0.08-2.7,0.91c-0.59,0.82-0.6,2.02-0.01,2.85C265.46,256.13,266.84,254.32,268.57,254z" />
              <path d="M274.99,259.57c-1.98,0.58-2.9,3.35-1.66,5C273.14,262.74,273.94,260.82,274.99,259.57z" />
              <path d="M279.18,261.15c-1.28,1.71-1.57,4.1-0.75,6.06C278.7,265.22,278.97,263.22,279.18,261.15z" />
              <path d="M295.18,262.38c2.58,0.19,4.51,3.22,3.61,5.64C298.67,265.78,297.3,263.64,295.18,262.38z" />
              <path d="M291.86,262.35c1.76,1.47,2.54,4.01,1.93,6.22C293.09,266.43,292.39,264.28,291.86,262.35z" />
              <path d="M283.19,261.96c0.25,2.04,0.49,4.08,0.74,6.11C284.68,266.02,284.51,263.65,283.19,261.96z" />
              <path d="M286.89,262.23c1.54,1.57,2.09,3.93,1.38,5.94C289.91,266.2,289.11,262.92,286.89,262.23z" />
              <path d="M271.65,257.34c-2-0.5-4.06,1.85-3.3,3.77C268.75,259.38,270.14,257.93,271.65,257.34z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M197.97,259.08c-1.71-0.81-3.69-0.54-5.2,0.45c-1.51,0.99-2.44,2.64-1.85,4.19C191.45,261.12,194.72,259.18,197.97,259.08z" />
              <path d="M191.34,247.4c-1.16-1.42-3.37-2.22-5.39-1.96c-2.02,0.26-3.75,1.58-4.24,3.22C184.01,246.33,188.59,245.86,191.34,247.4z" />
              <path d="M201.54,261.11c-1.57-0.65-3.59-0.38-4.79,0.62c-1.2,1.01-1.49,2.68-0.68,3.97C196,263.37,198.5,261.24,201.54,261.11z" />
              <path d="M227.4,261.33c1.12-0.47,2.64-0.16,3.42,0.68c0.78,0.84,0.71,2.13-0.14,2.88C230.91,263.34,229.37,261.73,227.4,261.33z" />
              <path d="M223.98,262.1c2.44,1.13,3.07,4.24,1.2,5.88C225.82,266.09,225.19,263.91,223.98,262.1z" />
              <path d="M216.33,262.44c0.58,2.15,0.31,4.71-0.69,6.65C215,266.79,215.31,264.01,216.33,262.44z" />
              <path d="M219.77,262.58c2.63,1.03,3.18,4.48,0.94,5.93C221.96,266.63,221.37,264.02,219.77,262.58z" />
              <path d="M205.55,262.12c-1.58-0.25-3.29,0.28-4.24,1.33s-1.09,2.56-0.36,3.76C201.03,264.94,203.03,262.84,205.55,262.12z" />
              <path d="M208.72,262.22c-3.41,0.87-5.07,4.2-3.28,6.6C205.04,266.48,206.24,264.07,208.72,262.22z" />
              <path d="M212.56,262.5c-3.04,1.86-3.64,4.69-1.5,7.08C209.85,267.18,210.65,264.63,212.56,262.5z" />
              <path d="M192.08,250.62c-1.45-1.07-3.53-1.53-5.39-1.17c-1.86,0.35-3.45,1.5-4.12,2.98C185.35,250.73,189.09,250.14,192.08,250.62z" />
              <path d="M193.6,253.73c-1.41-1.23-3.69-1.71-5.59-1.17c-1.9,0.53-3.31,2.05-3.45,3.71C186.36,253.77,190.66,252.79,193.6,253.73z" />
              <path d="M195.48,257.2c-1.64-0.82-3.85-0.95-5.57-0.33c-1.71,0.62-2.86,1.96-2.89,3.38C188.26,257.82,192.44,256.51,195.48,257.2z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M302.03,259.08c1.71-0.81,3.69-0.54,5.2,0.45c1.51,0.99,2.44,2.64,1.85,4.19C308.55,261.12,305.28,259.18,302.03,259.08z" />
              <path d="M308.66,247.4c1.17-1.42,3.37-2.22,5.39-1.96c2.02,0.26,3.75,1.58,4.24,3.22C315.99,246.33,311.41,245.86,308.66,247.4z" />
              <path d="M298.46,261.11c1.57-0.65,3.59-0.38,4.79,0.62c1.2,1.01,1.49,2.68,0.68,3.97C304,263.37,301.5,261.24,298.46,261.11z" />
              <path d="M272.6,261.33c-1.12-0.47-2.64-0.16-3.42,0.68c-0.78,0.84-0.71,2.13,0.14,2.88C269.09,263.34,270.63,261.73,272.6,261.33z" />
              <path d="M276.02,262.1c-2.44,1.13-3.07,4.24-1.2,5.88C274.18,266.09,274.81,263.91,276.02,262.1z" />
              <path d="M283.67,262.44c-0.58,2.15-0.31,4.71,0.69,6.65C285,266.79,284.69,264.01,283.67,262.44z" />
              <path d="M280.23,262.58c-2.63,1.03-3.18,4.48-0.94,5.93C278.04,266.63,278.63,264.02,280.23,262.58z" />
              <path d="M294.45,262.12c1.58-0.25,3.29,0.28,4.24,1.33s1.09,2.56,0.36,3.76C298.97,264.94,296.97,262.84,294.45,262.12z" />
              <path d="M291.28,262.22c3.41,0.87,5.07,4.2,3.28,6.6C294.96,266.48,293.76,264.07,291.28,262.22z" />
              <path d="M287.44,262.5c3.04,1.86,3.64,4.69,1.5,7.08C290.15,267.18,289.35,264.63,287.44,262.5z" />
              <path d="M307.92,250.62c1.45-1.07,3.53-1.53,5.39-1.17s3.45,1.5,4.12,2.98C314.65,250.73,310.91,250.14,307.92,250.62z" />
              <path d="M306.4,253.73c1.41-1.23,3.69-1.71,5.59-1.17c1.9,0.53,3.31,2.05,3.45,3.71C313.64,253.77,309.34,252.79,306.4,253.73z" />
              <path d="M304.52,257.2c1.64-0.82,3.85-0.95,5.57-0.33c1.71,0.62,2.86,1.96,2.89,3.38C311.74,257.82,307.56,256.51,304.52,257.2z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M195.21,260.32c-1.76-0.85-4.02-0.51-5.46,0.81c-1.44,1.32-1.96,3.55-1.26,5.37C188.84,263.31,191.98,260.69,195.21,260.32z" />
              <path d="M193.25,256.55c-1.26-1.63-3.51-2.42-5.5-1.93c-2,0.48-3.64,2.21-4.01,4.23C185.86,255.85,190.4,254.9,193.25,256.55z" />
              <path d="M199.21,264.29c-1.61-0.66-3.61-0.17-4.74,1.14c-1.13,1.32-1.3,3.37-0.41,4.86C193.84,267.46,196.19,264.68,199.21,264.29z" />
              <path d="M227.13,262.91c1.09-0.66,2.63-0.41,3.46,0.55c0.83,0.96,0.85,2.52,0.05,3.5C230.76,265.06,229.12,263.24,227.13,262.91z" />
              <path d="M223.84,265.07c2.51,1.17,3.35,4.89,1.59,7.02C225.93,269.74,225.16,267.16,223.84,265.07z" />
              <path d="M215.08,266.7c0.72,2.56,0.62,5.67-0.24,8.11C214.04,272.08,214.16,268.69,215.08,266.7z" />
              <path d="M218.25,266.57c2.69,1.03,3.47,5.16,1.33,7.1C220.7,271.29,219.94,268.18,218.25,266.57z" />
              <path d="M203.34,266.06c-1.6-0.18-3.27,0.61-4.14,1.96c-0.88,1.35-0.92,3.19-0.11,4.58C199.02,269.84,200.88,267.14,203.34,266.06z" />
              <path d="M207.61,266.21c-3.35,1.49-4.78,6.18-2.83,9.28C204.23,272.35,205.26,268.97,207.61,266.21z" />
              <path d="M211.76,266.38c-2.91,2.5-3.32,5.97-1.02,8.69C209.38,272.26,210,269.12,211.76,266.38z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M304.79,260.32c1.76-0.85,4.02-0.51,5.46,0.81c1.44,1.32,1.96,3.55,1.26,5.37C311.16,263.31,308.02,260.69,304.79,260.32z" />
              <path d="M306.75,256.55c1.26-1.63,3.51-2.42,5.5-1.93c2,0.48,3.64,2.21,4.01,4.23C314.14,255.85,309.6,254.9,306.75,256.55z" />
              <path d="M300.79,264.29c1.61-0.66,3.61-0.17,4.74,1.14c1.13,1.32,1.3,3.37,0.41,4.86C306.16,267.46,303.81,264.68,300.79,264.29z" />
              <path d="M272.87,262.91c-1.09-0.66-2.63-0.41-3.46,0.55s-0.85,2.52-0.05,3.5C269.24,265.06,270.88,263.24,272.87,262.91z" />
              <path d="M276.16,265.07c-2.51,1.17-3.35,4.89-1.59,7.02C274.07,269.74,274.84,267.16,276.16,265.07z" />
              <path d="M284.92,266.7c-0.72,2.56-0.62,5.67,0.24,8.11C285.96,272.08,285.84,268.69,284.92,266.7z" />
              <path d="M281.75,266.57c-2.69,1.03-3.47,5.16-1.33,7.1C279.3,271.29,280.06,268.18,281.75,266.57z" />
              <path d="M296.66,266.06c1.6-0.18,3.27,0.61,4.14,1.96c0.88,1.35,0.92,3.19,0.11,4.58C300.98,269.84,299.12,267.14,296.66,266.06z" />
              <path d="M292.39,266.21c3.35,1.49,4.78,6.18,2.83,9.28C295.77,272.35,294.74,268.97,292.39,266.21z" />
              <path d="M288.24,266.38c2.91,2.5,3.32,5.97,1.02,8.69C290.62,272.26,290,269.12,288.24,266.38z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M196.96,259.57c-1.76-0.85-4.02-0.51-5.46,0.81c-1.44,1.32-1.96,3.55-1.26,5.37C190.59,262.56,193.73,259.94,196.96,259.57z" />
              <path d="M195,256.05c-1.26-1.63-3.51-2.42-5.5-1.93c-2,0.48-3.64,2.21-4.01,4.23C187.61,255.35,192.15,254.4,195,256.05z" />
              <path d="M200.21,262.54c-1.61-0.66-3.61-0.17-4.74,1.14c-1.13,1.32-1.3,3.37-0.41,4.86C194.84,265.71,197.19,262.93,200.21,262.54z" />
              <path d="M225.38,261.41c1.09-0.66,2.63-0.41,3.46,0.55c0.83,0.96,0.85,2.52,0.05,3.5C229.01,263.56,227.37,261.74,225.38,261.41z" />
              <path d="M222.34,263.82c2.51,1.17,3.35,4.89,1.59,7.02C224.43,268.49,223.66,265.91,222.34,263.82z" />
              <path d="M214.33,267.45c0.72,2.56,0.62,5.67-0.24,8.11C213.29,272.83,213.41,269.44,214.33,267.45z" />
              <path d="M217.5,266.32c2.69,1.03,3.47,5.16,1.33,7.1C219.95,271.04,219.19,267.93,217.5,266.32z" />
              <path d="M203.09,265.06c-1.6-0.18-3.27,0.61-4.14,1.96c-0.88,1.35-0.92,3.19-0.11,4.58C198.77,268.84,200.63,266.14,203.09,265.06z" />
              <path d="M206.86,265.96c-3.35,1.49-4.78,6.18-2.83,9.28C203.48,272.1,204.51,268.72,206.86,265.96z" />
              <path d="M211.01,267.13c-2.91,2.5-3.32,5.97-1.02,8.69C208.63,273.01,209.25,269.87,211.01,267.13z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M303.04,259.57c1.76-0.85,4.02-0.51,5.46,0.81c1.44,1.32,1.96,3.55,1.26,5.37C309.41,262.56,306.27,259.94,303.04,259.57z" />
              <path d="M305,256.05c1.26-1.63,3.51-2.42,5.5-1.93c2,0.48,3.64,2.21,4.01,4.23C312.39,255.35,307.85,254.4,305,256.05z" />
              <path d="M299.79,262.54c1.61-0.66,3.61-0.17,4.74,1.14c1.13,1.32,1.3,3.37,0.41,4.86C305.16,265.71,302.81,262.93,299.79,262.54z" />
              <path d="M274.62,261.41c-1.09-0.66-2.63-0.41-3.46,0.55s-0.85,2.52-0.05,3.5C270.99,263.56,272.63,261.74,274.62,261.41z" />
              <path d="M277.66,263.82c-2.51,1.17-3.35,4.89-1.59,7.02C275.57,268.49,276.34,265.91,277.66,263.82z" />
              <path d="M285.67,267.45c-0.72,2.56-0.62,5.67,0.24,8.11C286.71,272.83,286.59,269.44,285.67,267.45z" />
              <path d="M282.5,266.32c-2.69,1.03-3.47,5.16-1.33,7.1C280.05,271.04,280.81,267.93,282.5,266.32z" />
              <path d="M296.91,265.06c1.6-0.18,3.27,0.61,4.14,1.96c0.88,1.35,0.92,3.19,0.11,4.58C301.23,268.84,299.37,266.14,296.91,265.06z" />
              <path d="M293.14,265.96c3.35,1.49,4.78,6.18,2.83,9.28C296.52,272.1,295.49,268.72,293.14,265.96z" />
              <path d="M288.99,267.13c2.91,2.5,3.32,5.97,1.02,8.69C291.37,273.01,290.75,269.87,288.99,267.13z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M199.75,256.47c-1.62-0.62-3.42-0.26-4.74,0.71c-1.32,0.97-2.08,2.49-1.44,3.82C193.88,258.66,196.77,256.75,199.75,256.47z" />
              <path d="M203.15,258.06c-1.48-0.48-3.32-0.13-4.36,0.84c-1.04,0.96-1.2,2.47-0.38,3.56C198.2,260.38,200.36,258.35,203.15,258.06z" />
              <path d="M226.41,256.21c1-0.48,2.42-0.3,3.18,0.4c0.76,0.7,0.79,1.84,0.05,2.56C229.75,257.79,228.24,256.45,226.41,256.21z" />
              <path d="M223.56,257.35c2.31,0.86,3.08,3.58,1.46,5.14C225.49,260.78,224.78,258.88,223.56,257.35z" />
              <path d="M216.81,258.86c0.66,1.87,0.57,4.15-0.22,5.93C215.85,262.79,215.97,260.31,216.81,258.86z" />
              <path d="M219.97,258.28c2.48,0.75,3.19,3.78,1.23,5.2C222.23,261.74,221.53,259.46,219.97,258.28z" />
              <path d="M206.64,258.71c-1.47-0.13-3.01,0.45-3.81,1.43c-0.81,0.99-0.85,2.34-0.1,3.35C202.66,261.48,204.37,259.5,206.64,258.71z" />
              <path d="M209.81,258.86c-3.08,0.97-4.39,4.02-2.61,6.04C206.69,262.85,207.64,260.65,209.81,258.86z" />
              <path d="M213.1,258.88c-2.68,1.83-3.05,4.37-0.94,6.36C210.9,263.19,211.48,260.88,213.1,258.88z" />
              <path d="M192.07,249.32c-1.4-0.86-3.34-1.14-5.03-0.72s-3.08,1.54-3.6,2.88C185.89,249.82,189.29,249.08,192.07,249.32z" />
              <path d="M193.4,252.49c-1.37-1-3.5-1.29-5.21-0.71c-1.71,0.59-2.91,2.01-2.93,3.49C186.76,252.95,190.65,251.83,193.4,252.49z" />
              <path d="M196.34,254.95c-1.56-0.63-3.6-0.61-5.13,0.04c-1.54,0.65-2.51,1.91-2.44,3.16C189.75,255.93,193.5,254.52,196.34,254.95z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M300.25,256.47c1.62-0.62,3.42-0.26,4.74,0.71c1.32,0.97,2.08,2.49,1.44,3.82C306.12,258.66,303.23,256.75,300.25,256.47z" />
              <path d="M296.85,258.06c1.48-0.48,3.32-0.13,4.36,0.84s1.2,2.47,0.38,3.56C301.8,260.38,299.64,258.35,296.85,258.06z" />
              <path d="M273.59,256.21c-1-0.48-2.42-0.3-3.18,0.4c-0.76,0.7-0.79,1.84-0.05,2.56C270.25,257.79,271.76,256.45,273.59,256.21z" />
              <path d="M276.44,257.35c-2.31,0.86-3.08,3.58-1.46,5.14C274.51,260.78,275.22,258.88,276.44,257.35z" />
              <path d="M283.19,258.86c-0.66,1.87-0.57,4.15,0.22,5.93C284.15,262.79,284.03,260.31,283.19,258.86z" />
              <path d="M280.03,258.28c-2.48,0.75-3.19,3.78-1.23,5.2C277.77,261.74,278.47,259.46,280.03,258.28z" />
              <path d="M293.36,258.71c1.47-0.13,3.01,0.45,3.81,1.43c0.81,0.99,0.85,2.34,0.1,3.35C297.34,261.48,295.63,259.5,293.36,258.71z" />
              <path d="M290.19,258.86c3.08,0.97,4.39,4.02,2.61,6.04C293.31,262.85,292.36,260.65,290.19,258.86z" />
              <path d="M286.9,258.88c2.68,1.83,3.05,4.37,0.94,6.36C289.1,263.19,288.52,260.88,286.9,258.88z" />
              <path d="M307.93,249.32c1.4-0.86,3.34-1.14,5.03-0.72s3.08,1.54,3.6,2.88C314.11,249.82,310.71,249.08,307.93,249.32z" />
              <path d="M306.6,252.49c1.37-1,3.5-1.29,5.21-0.71c1.71,0.59,2.91,2.01,2.93,3.49C313.24,252.95,309.35,251.83,306.6,252.49z" />
              <path d="M303.66,254.95c1.56-0.63,3.6-0.61,5.13,0.04c1.54,0.65,2.51,1.91,2.44,3.16C310.25,255.93,306.5,254.52,303.66,254.95z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M199.75,254.72c-1.62-0.62-3.42-0.26-4.74,0.71s-2.08,2.49-1.44,3.82C193.88,256.91,196.77,255,199.75,254.72z" />
              <path d="M203.15,256.31c-1.48-0.48-3.32-0.13-4.36,0.84c-1.04,0.96-1.2,2.47-0.38,3.56C198.2,258.63,200.36,256.6,203.15,256.31z" />
              <path d="M226.41,254.46c1-0.48,2.42-0.3,3.18,0.4c0.76,0.7,0.79,1.84,0.05,2.56C229.75,256.04,228.24,254.7,226.41,254.46z" />
              <path d="M223.56,255.6c2.31,0.86,3.08,3.58,1.46,5.14C225.49,259.03,224.78,257.13,223.56,255.6z" />
              <path d="M216.81,257.11c0.66,1.87,0.57,4.15-0.22,5.93C215.85,261.04,215.97,258.56,216.81,257.11z" />
              <path d="M219.97,256.53c2.48,0.75,3.19,3.78,1.23,5.2C222.23,259.99,221.53,257.71,219.97,256.53z" />
              <path d="M206.64,256.96c-1.47-0.13-3.01,0.45-3.81,1.43c-0.81,0.99-0.85,2.34-0.1,3.35C202.66,259.73,204.37,257.75,206.64,256.96z" />
              <path d="M209.81,257.11c-3.08,0.97-4.39,4.02-2.61,6.04C206.69,261.1,207.64,258.9,209.81,257.11z" />
              <path d="M213.1,257.13c-2.68,1.83-3.05,4.37-0.94,6.36C210.9,261.44,211.48,259.13,213.1,257.13z" />
              <path d="M192.07,247.57c-1.4-0.86-3.34-1.14-5.03-0.72s-3.08,1.54-3.6,2.88C185.89,248.07,189.29,247.33,192.07,247.57z" />
              <path d="M193.4,250.74c-1.37-1-3.5-1.29-5.21-0.71c-1.71,0.59-2.91,2.01-2.93,3.49C186.76,251.2,190.65,250.08,193.4,250.74z" />
              <path d="M196.34,253.2c-1.56-0.63-3.6-0.61-5.13,0.04c-1.54,0.65-2.51,1.91-2.44,3.16C189.75,254.18,193.5,252.77,196.34,253.2z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M300.25,254.72c1.62-0.62,3.42-0.26,4.74,0.71c1.32,0.97,2.08,2.49,1.44,3.82C306.12,256.91,303.23,255,300.25,254.72z" />
              <path d="M296.85,256.31c1.48-0.48,3.32-0.13,4.36,0.84s1.2,2.47,0.38,3.56C301.8,258.63,299.64,256.6,296.85,256.31z" />
              <path d="M273.59,254.46c-1-0.48-2.42-0.3-3.18,0.4c-0.76,0.7-0.79,1.84-0.05,2.56C270.25,256.04,271.76,254.7,273.59,254.46z" />
              <path d="M276.44,255.6c-2.31,0.86-3.08,3.58-1.46,5.14C274.51,259.03,275.22,257.13,276.44,255.6z" />
              <path d="M283.19,257.11c-0.66,1.87-0.57,4.15,0.22,5.93C284.15,261.04,284.03,258.56,283.19,257.11z" />
              <path d="M280.03,256.53c-2.48,0.75-3.19,3.78-1.23,5.2C277.77,259.99,278.47,257.71,280.03,256.53z" />
              <path d="M293.36,256.96c1.47-0.13,3.01,0.45,3.81,1.43c0.81,0.99,0.85,2.34,0.1,3.35C297.34,259.73,295.63,257.75,293.36,256.96z" />
              <path d="M290.19,257.11c3.08,0.97,4.39,4.02,2.61,6.04C293.31,261.1,292.36,258.9,290.19,257.11z" />
              <path d="M286.9,257.13c2.68,1.83,3.05,4.37,0.94,6.36C289.1,261.44,288.52,259.13,286.9,257.13z" />
              <path d="M307.93,247.57c1.4-0.86,3.34-1.14,5.03-0.72s3.08,1.54,3.6,2.88C314.11,248.07,310.71,247.33,307.93,247.57z" />
              <path d="M306.6,250.74c1.37-1,3.5-1.29,5.21-0.71c1.71,0.59,2.91,2.01,2.93,3.49C313.24,251.2,309.35,250.08,306.6,250.74z" />
              <path d="M303.66,253.2c1.56-0.63,3.6-0.61,5.13,0.04c1.54,0.65,2.51,1.91,2.44,3.16C310.25,254.18,306.5,252.77,303.66,253.2z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M199.27,256.2c-1.7-0.58-3.59-0.24-4.97,0.66c-1.39,0.91-2.18,2.33-1.51,3.57C193.12,258.25,196.15,256.46,199.27,256.2z" />
              <path d="M203.08,257.19c-1.55-0.45-3.48-0.12-4.57,0.78c-1.09,0.9-1.26,2.31-0.4,3.33C197.9,259.36,200.17,257.46,203.08,257.19z" />
              <path d="M227.72,255.21c1.05-0.45,2.53-0.28,3.34,0.37c0.8,0.66,0.82,1.72,0.05,2.4C231.22,256.68,229.65,255.43,227.72,255.21z" />
              <path d="M224.74,256.02c2.42,0.8,3.23,3.35,1.53,4.81C226.77,259.23,226.02,257.46,224.74,256.02z" />
              <path d="M217.66,257.43c0.69,1.75,0.6,3.88-0.23,5.55C216.66,261.11,216.78,258.79,217.66,257.43z" />
              <path d="M220.73,256.89c2.6,0.71,3.35,3.53,1.29,4.86C223.1,260.12,222.36,258,220.73,256.89z" />
              <path d="M206.99,257.55c-1.54-0.12-3.15,0.42-4,1.34c-0.85,0.92-0.89,2.19-0.1,3.14C202.83,260.14,204.62,258.29,206.99,257.55z" />
              <path d="M210.57,257.43c-3.23,0.91-4.61,3.76-2.73,5.65C207.3,261.17,208.3,259.11,210.57,257.43z" />
              <path d="M213.77,257.21c-2.81,1.71-3.2,4.09-0.98,5.95C211.47,261.23,212.07,259.08,213.77,257.21z" />
              <path d="M191.72,249.76c-1.46-0.81-3.5-1.07-5.27-0.67c-1.77,0.4-3.23,1.44-3.77,2.7C185.24,250.23,188.81,249.54,191.72,249.76z" />
              <path d="M193.12,252.73c-1.44-0.94-3.67-1.21-5.46-0.66c-1.79,0.55-3.05,1.88-3.08,3.27C186.15,253.16,190.23,252.11,193.12,252.73z" />
              <path d="M195.95,254.78c-1.63-0.58-3.77-0.57-5.38,0.04c-1.61,0.61-2.63,1.78-2.56,2.96C189.04,255.7,192.97,254.38,195.95,254.78z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M300.73,256.2c1.7-0.58,3.59-0.24,4.97,0.66c1.39,0.91,2.18,2.33,1.51,3.57C306.88,258.25,303.85,256.46,300.73,256.2z" />
              <path d="M296.92,257.19c1.55-0.45,3.48-0.12,4.57,0.78s1.26,2.31,0.4,3.33C302.1,259.36,299.83,257.46,296.92,257.19z" />
              <path d="M272.28,255.21c-1.05-0.45-2.53-0.28-3.34,0.37c-0.8,0.66-0.82,1.72-0.05,2.4C268.78,256.68,270.35,255.43,272.28,255.21z" />
              <path d="M275.26,256.02c-2.42,0.8-3.23,3.35-1.53,4.81C273.23,259.23,273.98,257.46,275.26,256.02z" />
              <path d="M282.34,257.43c-0.69,1.75-0.6,3.88,0.23,5.55C283.34,261.11,283.22,258.79,282.34,257.43z" />
              <path d="M279.27,256.89c-2.6,0.71-3.35,3.53-1.29,4.86C276.9,260.12,277.64,258,279.27,256.89z" />
              <path d="M293.01,257.55c1.54-0.12,3.15,0.42,4,1.34c0.85,0.92,0.89,2.19,0.1,3.14C297.17,260.14,295.38,258.29,293.01,257.55z" />
              <path d="M289.43,257.43c3.23,0.91,4.61,3.76,2.73,5.65C292.7,261.17,291.7,259.11,289.43,257.43z" />
              <path d="M286.23,257.21c2.81,1.71,3.2,4.09,0.98,5.95C288.53,261.23,287.93,259.08,286.23,257.21z" />
              <path d="M308.28,249.76c1.46-0.81,3.5-1.07,5.27-0.67c1.77,0.4,3.23,1.44,3.77,2.7C314.76,250.23,311.19,249.54,308.28,249.76z" />
              <path d="M306.88,252.73c1.44-0.94,3.67-1.21,5.46-0.66c1.79,0.55,3.05,1.88,3.08,3.27C313.85,253.16,309.77,252.11,306.88,252.73z" />
              <path d="M304.05,254.78c1.63-0.58,3.77-0.57,5.38,0.04c1.61,0.61,2.63,1.78,2.56,2.96C310.96,255.7,307.03,254.38,304.05,254.78z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M199.02,252.95c-1.7-0.58-3.59-0.24-4.97,0.66c-1.39,0.91-2.18,2.33-1.51,3.57C192.87,255,195.9,253.21,199.02,252.95z" />
              <path d="M203.33,254.94c-1.55-0.45-3.48-0.12-4.57,0.78c-1.09,0.9-1.26,2.31-0.4,3.33C198.15,257.11,200.42,255.21,203.33,254.94z" />
              <path d="M228.47,254.46c1.05-0.45,2.53-0.28,3.34,0.37c0.8,0.66,0.82,1.72,0.05,2.4C231.97,255.93,230.4,254.68,228.47,254.46z" />
              <path d="M225.49,255.27c2.42,0.8,3.23,3.35,1.53,4.81C227.52,258.48,226.77,256.71,225.49,255.27z" />
              <path d="M218.41,256.68c0.69,1.75,0.6,3.88-0.23,5.55C217.41,260.36,217.53,258.04,218.41,256.68z" />
              <path d="M221.48,256.14c2.6,0.71,3.35,3.53,1.29,4.86C223.85,259.37,223.11,257.25,221.48,256.14z" />
              <path d="M207.49,256.3c-1.54-0.12-3.15,0.42-4,1.34c-0.85,0.92-0.89,2.19-0.1,3.14C203.33,258.89,205.12,257.04,207.49,256.3z" />
              <path d="M211.32,256.68c-3.23,0.91-4.61,3.76-2.73,5.65C208.05,260.42,209.05,258.36,211.32,256.68z" />
              <path d="M214.52,256.46c-2.81,1.71-3.2,4.09-0.98,5.95C212.22,260.48,212.82,258.33,214.52,256.46z" />
              <path d="M190.47,246.51c-1.46-0.81-3.5-1.07-5.27-0.67c-1.77,0.4-3.23,1.44-3.77,2.7C183.99,246.98,187.56,246.29,190.47,246.51z" />
              <path d="M193.12,249.23c-1.44-0.94-3.67-1.21-5.46-0.66c-1.79,0.55-3.05,1.88-3.08,3.27C186.15,249.66,190.23,248.61,193.12,249.23z" />
              <path d="M195.7,251.03c-1.63-0.58-3.77-0.57-5.38,0.04c-1.61,0.61-2.63,1.78-2.56,2.96C188.79,251.95,192.72,250.63,195.7,251.03z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M300.98,252.95c1.7-0.58,3.59-0.24,4.97,0.66s2.18,2.33,1.51,3.57C307.13,255,304.1,253.21,300.98,252.95z" />
              <path d="M296.67,254.94c1.55-0.45,3.48-0.12,4.57,0.78s1.26,2.31,0.4,3.33C301.85,257.11,299.58,255.21,296.67,254.94z" />
              <path d="M271.53,254.46c-1.05-0.45-2.53-0.28-3.34,0.37c-0.8,0.66-0.82,1.72-0.05,2.4C268.03,255.93,269.6,254.68,271.53,254.46z" />
              <path d="M274.51,255.27c-2.42,0.8-3.23,3.35-1.53,4.81C272.48,258.48,273.23,256.71,274.51,255.27z" />
              <path d="M281.59,256.68c-0.69,1.75-0.6,3.88,0.23,5.55C282.59,260.36,282.47,258.04,281.59,256.68z" />
              <path d="M278.52,256.14c-2.6,0.71-3.35,3.53-1.29,4.86C276.15,259.37,276.89,257.25,278.52,256.14z" />
              <path d="M292.51,256.3c1.54-0.12,3.15,0.42,4,1.34c0.85,0.92,0.89,2.19,0.1,3.14C296.67,258.89,294.88,257.04,292.51,256.3z" />
              <path d="M288.68,256.68c3.23,0.91,4.61,3.76,2.73,5.65C291.95,260.42,290.95,258.36,288.68,256.68z" />
              <path d="M285.48,256.46c2.81,1.71,3.2,4.09,0.98,5.95C287.78,260.48,287.18,258.33,285.48,256.46z" />
              <path d="M309.53,246.51c1.46-0.81,3.5-1.07,5.27-0.67c1.77,0.4,3.23,1.44,3.77,2.7C316.01,246.98,312.44,246.29,309.53,246.51z" />
              <path d="M306.88,249.23c1.44-0.94,3.67-1.21,5.46-0.66c1.79,0.55,3.05,1.88,3.08,3.27C313.85,249.66,309.77,248.61,306.88,249.23z" />
              <path d="M304.3,251.03c1.63-0.58,3.77-0.57,5.38,0.04c1.61,0.61,2.63,1.78,2.56,2.96C311.21,251.95,307.28,250.63,304.3,251.03z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M199.16,252.7c-1.63-0.74-3.55-0.59-5.02,0.18c-1.47,0.77-2.39,2.11-1.84,3.41C192.84,254.15,196.03,252.65,199.16,252.7z" />
              <path d="M203.26,255.09c-1.5-0.6-3.45-0.45-4.63,0.34c-1.17,0.79-1.47,2.18-0.71,3.27C197.9,256.75,200.33,255.08,203.26,255.09z" />
              <path d="M228.08,255.77c1.09-0.35,2.55-0.04,3.28,0.69c0.74,0.73,0.65,1.79-0.18,2.39C231.43,257.58,229.98,256.18,228.08,255.77z" />
              <path d="M225.29,256.8c2.33,1.03,2.9,3.64,1.06,4.93C226.99,260.18,226.42,258.35,225.29,256.8z" />
              <path d="M218.11,258.27c0.52,1.81,0.23,3.92-0.77,5.5C216.75,261.84,217.1,259.54,218.11,258.27z" />
              <path d="M221.21,258.03c2.52,0.95,2.99,3.84,0.81,4.96C223.26,261.47,222.73,259.28,221.21,258.03z" />
              <path d="M207.27,256.84c-1.52-0.27-3.18,0.11-4.11,0.95c-0.93,0.84-1.09,2.09-0.4,3.11C202.88,259.02,204.84,257.35,207.27,256.84z" />
              <path d="M211.04,257.59c-3.3,0.6-4.95,3.3-3.26,5.36C207.44,261,208.62,259.04,211.04,257.59z" />
              <path d="M214.5,258.17c-2.96,1.43-3.58,3.76-1.55,5.83C211.82,261.96,212.63,259.87,214.5,258.17z" />
              <path d="M191.27,245.47c-1.38-0.95-3.38-1.4-5.18-1.18c-1.8,0.22-3.35,1.12-4.01,2.32C184.77,245.31,188.39,244.96,191.27,245.47z" />
              <path d="M193.64,248.42c-1.34-1.07-3.53-1.55-5.37-1.18c-1.84,0.37-3.22,1.58-3.38,2.96C186.66,248.19,190.83,247.53,193.64,248.42z" />
              <path d="M196.04,250.47c-1.57-0.74-3.7-0.93-5.36-0.48c-1.66,0.45-2.79,1.52-2.83,2.7C189.07,250.71,193.12,249.78,196.04,250.47z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M300.84,252.7c1.63-0.74,3.55-0.59,5.02,0.18s2.39,2.11,1.84,3.41C307.16,254.15,303.97,252.65,300.84,252.7z" />
              <path d="M296.74,255.09c1.5-0.6,3.45-0.45,4.63,0.34c1.17,0.79,1.47,2.18,0.71,3.27C302.1,256.75,299.67,255.08,296.74,255.09z" />
              <path d="M271.92,255.77c-1.09-0.35-2.55-0.04-3.28,0.69c-0.74,0.73-0.65,1.79,0.18,2.39C268.57,257.58,270.02,256.18,271.92,255.77z" />
              <path d="M274.71,256.8c-2.33,1.03-2.9,3.64-1.06,4.93C273.01,260.18,273.58,258.35,274.71,256.8z" />
              <path d="M281.89,258.27c-0.52,1.81-0.23,3.92,0.77,5.5C283.25,261.84,282.9,259.54,281.89,258.27z" />
              <path d="M278.79,258.03c-2.52,0.95-2.99,3.84-0.81,4.96C276.74,261.47,277.27,259.28,278.79,258.03z" />
              <path d="M292.73,256.84c1.52-0.27,3.18,0.11,4.11,0.95c0.93,0.84,1.09,2.09,0.4,3.11C297.12,259.02,295.16,257.35,292.73,256.84z" />
              <path d="M288.96,257.59c3.3,0.6,4.95,3.3,3.26,5.36C292.56,261,291.38,259.04,288.96,257.59z" />
              <path d="M285.5,258.17c2.96,1.43,3.58,3.76,1.55,5.83C288.18,261.96,287.37,259.87,285.5,258.17z" />
              <path d="M308.73,245.47c1.38-0.95,3.38-1.4,5.18-1.18c1.8,0.22,3.35,1.12,4.01,2.32C315.23,245.31,311.61,244.96,308.73,245.47z" />
              <path d="M306.36,248.42c1.34-1.07,3.53-1.55,5.37-1.18c1.84,0.37,3.22,1.58,3.38,2.96C313.34,248.19,309.17,247.53,306.36,248.42z" />
              <path d="M303.96,250.47c1.57-0.74,3.7-0.93,5.36-0.48c1.66,0.45,2.79,1.52,2.83,2.7C310.93,250.71,306.88,249.78,303.96,250.47z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M200.41,254.34c-1.74-0.43-3.6,0.06-4.9,1.08c-1.3,1.02-1.97,2.5-1.2,3.69C194.46,256.91,197.32,254.87,200.41,254.34z" />
              <path d="M204.88,255.96c-1.58-0.32-3.48,0.18-4.49,1.17c-1.01,0.99-1.06,2.41-0.11,3.35C199.9,258.56,202,256.47,204.88,255.96z" />
              <path d="M229.42,252.16c1.01-0.54,2.5-0.5,3.36,0.09c0.85,0.58,0.97,1.65,0.25,2.38C233.03,253.33,231.35,252.22,229.42,252.16z" />
              <path d="M226.85,253.67c2.48,0.59,3.51,3.06,1.94,4.66C229.14,256.69,228.24,254.99,226.85,253.67z" />
              <path d="M220.05,256.41c0.84,1.69,0.93,3.82,0.24,5.55C219.37,260.16,219.29,257.84,220.05,256.41z" />
              <path d="M223.06,255.61c2.65,0.48,3.63,3.24,1.7,4.73C225.7,258.63,224.78,256.57,223.06,255.61z" />
              <path d="M209.14,256.96c-1.55,0.01-3.1,0.68-3.87,1.67s-0.7,2.25,0.17,3.13C205.21,259.89,206.83,257.9,209.14,256.96z" />
              <path d="M212.98,257.02c-3.14,1.18-4.27,4.14-2.24,5.86C210.05,261.02,210.87,258.88,212.98,257.02z" />
              <path d="M216.49,256.97c-2.65,1.94-2.84,4.35-0.47,6.01C214.54,261.17,214.96,258.97,216.49,256.97z" />
              <path d="M191.35,248.66c-1.53-0.68-3.58-0.77-5.31-0.22c-1.73,0.54-3.09,1.71-3.53,3.01C184.93,249.67,188.42,248.68,191.35,248.66z" />
              <path d="M194.22,251.13c-1.51-0.81-3.76-0.89-5.5-0.19s-2.88,2.14-2.79,3.52C187.31,252.16,191.29,250.76,194.22,251.13z" />
              <path d="M196.94,252.71c-1.68-0.44-3.81-0.25-5.36,0.49s-2.47,2-2.3,3.16C190.13,254.21,193.94,252.56,196.94,252.71z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M299.59,254.34c1.74-0.43,3.6,0.06,4.9,1.08c1.3,1.02,1.97,2.5,1.2,3.69C305.54,256.91,302.68,254.87,299.59,254.34z" />
              <path d="M295.12,255.96c1.58-0.32,3.48,0.18,4.49,1.17c1.01,0.99,1.06,2.41,0.11,3.35C300.1,258.56,298,256.47,295.12,255.96z" />
              <path d="M270.58,252.16c-1.01-0.54-2.5-0.5-3.36,0.09c-0.85,0.58-0.97,1.65-0.25,2.38C266.97,253.33,268.65,252.22,270.58,252.16z" />
              <path d="M273.15,253.67c-2.48,0.59-3.51,3.06-1.94,4.66C270.86,256.69,271.76,254.99,273.15,253.67z" />
              <path d="M279.95,256.41c-0.84,1.69-0.93,3.82-0.24,5.55C280.63,260.16,280.71,257.84,279.95,256.41z" />
              <path d="M276.94,255.61c-2.65,0.48-3.63,3.24-1.7,4.73C274.3,258.63,275.22,256.57,276.94,255.61z" />
              <path d="M290.86,256.96c1.55,0.01,3.1,0.68,3.87,1.67c0.76,0.99,0.7,2.25-0.17,3.13C294.79,259.89,293.17,257.9,290.86,256.96z" />
              <path d="M287.02,257.02c3.14,1.18,4.27,4.14,2.24,5.86C289.95,261.02,289.13,258.88,287.02,257.02z" />
              <path d="M283.51,256.97c2.65,1.94,2.84,4.35,0.47,6.01C285.46,261.17,285.04,258.97,283.51,256.97z" />
              <path d="M308.65,248.66c1.53-0.68,3.58-0.77,5.31-0.22c1.73,0.54,3.09,1.71,3.53,3.01C315.07,249.67,311.58,248.68,308.65,248.66z" />
              <path d="M305.78,251.13c1.51-0.81,3.76-0.89,5.5-0.19s2.88,2.14,2.79,3.52C312.69,252.16,308.71,250.76,305.78,251.13z" />
              <path d="M303.06,252.71c1.68-0.44,3.81-0.25,5.36,0.49s2.47,2,2.3,3.16C309.87,254.21,306.06,252.56,303.06,252.71z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_gfs84 = {
  id: "eyelash_shape_gfs84",
  name: "Eyelash 2",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.92,249.57c1.75,8.59,9.62,15.3,18.24,15.38c8.62,0.07,16.83-6.6,18.5-15.06c-1.25,7.17-6.99,13.36-14.05,15.15c-7.06,1.79-15.05-0.93-19.56-6.64c-2.25-2.85-3.55-5.13-4.93-7.05c2.72,2.18,1.15,1.25,3.11,3.44C194.66,253.77,193.9,252.69,193.92,249.57z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.08,249.57c-1.75,8.59-9.62,15.3-18.24,15.38c-8.62,0.07-16.83-6.6-18.5-15.06c1.25,7.17,6.99,13.36,14.05,15.15c7.06,1.79,15.05-0.93,19.56-6.64c2.25-2.85,3.55-5.13,4.93-7.05c-2.72,2.18-1.15,1.25-3.11,3.44C305.34,253.77,306.1,252.69,306.08,249.57z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M230.89,247.02c-2.95,6.97-9.9,12.51-17.31,14.48c-9.84,2.61-14.7,0.4-20.63-6.11c-1.82-2-2.17-1.7-3.04-3.32c1.92,1.34,0.19,0.25,2.39,1.42c-0.33-1.22-0.66-2.44-0.99-3.66c3.97,7.2,7.34,12.09,15.81,11.76C215.33,261.27,224.75,257.18,230.89,247.02z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269.11,247.02c2.95,6.97,9.9,12.51,17.31,14.48c9.84,2.61,14.7,0.4,20.63-6.11c1.82-2,2.17-1.7,3.04-3.32c-1.92,1.34-0.19,0.25-2.39,1.42c0.33-1.22,0.66-2.44,0.99-3.66c-3.97,7.2-7.34,12.09-15.81,11.76C284.67,261.27,275.25,257.18,269.11,247.02z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.88,254.9c-3.43,5.34-10.18,8.8-15.86,8.14c-7.81-0.91-13.3-4.94-17.52-9.68c-4.22-4.74-6.3-9.78-7.61-15.99c0.17,1.68,0.34,3.37,0.51,5.05c-1.09-1.4-2.19-2.8-3.28-4.2c2.41,6.15,6.47,12.67,11.01,17.48c4.54,4.8,11.48,8.31,18.08,8.57C223.8,264.53,228.94,260.58,231.88,254.9z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.12,254.9c3.43,5.34,10.18,8.8,15.86,8.14c7.81-0.91,13.3-4.94,17.52-9.68c4.22-4.74,6.3-9.78,7.61-15.99c-0.17,1.68-0.34,3.37-0.51,5.05c1.09-1.4,2.19-2.8,3.28-4.2c-2.41,6.15-6.47,12.67-11.01,17.48c-4.54,4.8-11.48,8.31-18.08,8.57C276.2,264.53,271.06,260.58,268.12,254.9z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.11,254.13c-1.98,5.47-9.35,8.42-13.37,8.67c-6.49,0.41-17.19,1.36-25.92-2.57c3.15,0.36,2.4,0.49,3.91,0.46c-1.54-0.42-1.76-0.97-2.2-1.89c4.49,2.99,8.93,2.82,14.57,3.07C218.11,262.32,224.93,262.08,231.11,254.13z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.89,254.13c1.98,5.47,9.35,8.42,13.37,8.67c6.49,0.41,17.19,1.36,25.92-2.57c-3.15,0.36-2.4,0.49-3.91,0.46c1.54-0.42,1.76-0.97,2.2-1.89c-4.49,2.99-8.93,2.82-14.57,3.07C281.89,262.32,275.07,262.08,268.89,254.13z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M230.83,259.39c-4.2,2.09-10.1,2.59-17.39,2.42c-9.79-0.24-12.96-1.58-15.02-3.13c-3.37-2.52-5.94-5.93-7.17-10.97c-0.5,1.55-0.03,3.7,0.68,5.16c-1.4-0.69-2.62-1.74-3.51-3.03c-0.22,1.62,3.11,4.98,4.33,6.07c2.8,2.49,3.14,3,6.51,4.64c3.85,1.87,7.93,2.49,12.21,2.43c5.83-0.08,11.29,0.15,16.29-1.39C228.84,261.24,230.23,260.06,230.83,259.39z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269.17,259.39c4.2,2.09,10.1,2.59,17.39,2.42c9.79-0.24,12.96-1.58,15.02-3.13c3.37-2.52,5.94-5.93,7.17-10.97c0.5,1.55,0.03,3.7-0.68,5.16c1.4-0.69,2.62-1.74,3.51-3.03c0.22,1.62-3.11,4.98-4.33,6.07c-2.8,2.49-3.14,3-6.51,4.64c-3.85,1.87-7.93,2.49-12.21,2.43c-5.83-0.08-11.29,0.15-16.29-1.39C271.16,261.24,269.77,260.06,269.17,259.39z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M230.48,258.38c-1.63,2.83-2.51,3.78-4.69,5.4c-4.87,3.63-22.63,2.52-25.98,0.62c-1.58-0.9-6.02-4.23-6.83-8.6c-0.6,1.97,0.37,2.91,1.21,4.79c-1.81-1.23-3.39-2.81-4.63-4.64c0.42,2.49,6.06,9.66,13.23,10.56c6.71,0.84,16.05,1.17,21.55-1.03C228.28,263.9,230.68,259.58,230.48,258.38z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269.52,258.38c1.63,2.83,2.51,3.78,4.69,5.4c4.87,3.63,22.63,2.52,25.98,0.62c1.58-0.9,6.02-4.23,6.83-8.6c0.6,1.97-0.37,2.91-1.21,4.79c1.81-1.23,3.39-2.81,4.63-4.64c-0.42,2.49-6.06,9.66-13.23,10.56c-6.71,0.84-16.05,1.17-21.55-1.03C271.72,263.9,269.32,259.58,269.52,258.38z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.91,249.07c1.94,9.8,10.67,17.44,20.23,17.53c9.57,0.08,18.66-7.53,20.52-17.17c-1.39,8.18-7.75,15.23-15.58,17.27c-7.83,2.04-16.69-1.06-21.7-7.57c-2.5-3.25-3.93-5.85-5.47-8.04c3.01,2.49,1.27,1.42,3.45,3.92C192.72,253.86,191.89,252.63,191.91,249.07z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.09,249.07c-1.94,9.8-10.67,17.44-20.23,17.53c-9.57,0.08-18.66-7.53-20.52-17.17c1.39,8.18,7.75,15.23,15.58,17.27c7.83,2.04,16.69-1.06,21.7-7.57c2.5-3.25,3.93-5.85,5.47-8.04c-3.01,2.49-1.27,1.42-3.45,3.92C307.28,253.86,308.11,252.63,308.09,249.07z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.51,252.04c-4.52,3.73-10.21,6.09-16.09,6.67c-5.88,0.59-11.94-0.6-17.14-3.36c-3.04-1.62-5.86-3.84-7.46-6.84c0.42,1.58,1.25,3.06,2.39,4.26c-1.55-0.54-2.99-1.4-4.19-2.5c1.49,2.76,4.4,4.47,7.3,5.76c5.84,2.6,12.31,4.06,18.71,3.5S227.72,256.28,231.51,252.04z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.49,252.04c4.52,3.73,10.21,6.09,16.09,6.67c5.88,0.59,11.94-0.6,17.14-3.36c3.04-1.62,5.86-3.84,7.46-6.84c-0.42,1.58-1.25,3.06-2.39,4.26c1.55-0.54,2.99-1.4,4.19-2.5c-1.49,2.76-4.4,4.47-7.3,5.76c-5.84,2.6-12.31,4.06-18.71,3.5C278.58,258.97,272.28,256.28,268.49,252.04z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.76,250.54c-4.52,3.73-10.21,6.09-16.09,6.67c-5.88,0.59-11.94-0.6-17.14-3.36c-3.04-1.62-5.86-3.84-7.46-6.84c0.42,1.58,1.25,3.06,2.39,4.26c-1.55-0.54-2.99-1.4-4.19-2.5c1.49,2.76,4.4,4.47,7.3,5.76c5.84,2.6,12.31,4.06,18.71,3.5S227.97,254.78,231.76,250.54z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.24,250.54c4.52,3.73,10.21,6.09,16.09,6.67c5.88,0.59,11.94-0.6,17.14-3.36c3.04-1.62,5.86-3.84,7.46-6.84c-0.42,1.58-1.25,3.06-2.39,4.26c1.55-0.54,2.99-1.4,4.19-2.5c-1.49,2.76-4.4,4.47-7.3,5.76c-5.84,2.6-12.31,4.06-18.71,3.5C278.33,257.47,272.03,254.78,268.24,250.54z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.76,253.03c-4.52,2.51-9.21,3.6-15.09,3.99s-11.99-0.46-18.14-1.76c-3.16-0.67-5.86-2.59-7.46-4.61c0.42,1.07,1.25,2.06,2.39,2.87c-1.55-0.37-2.99-0.94-4.19-1.68c1.49,1.86,4.4,3.01,7.3,3.88c5.84,1.75,12.31,2.74,18.71,2.36S227.97,255.88,231.76,253.03z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.24,253.03c4.52,2.51,9.21,3.6,15.09,3.99s11.99-0.46,18.14-1.76c3.16-0.67,5.86-2.59,7.46-4.61c-0.42,1.07-1.25,2.06-2.39,2.87c1.55-0.37,2.99-0.94,4.19-1.68c-1.49,1.86-4.4,3.01-7.3,3.88c-5.84,1.75-12.31,2.74-18.71,2.36C278.33,257.69,272.03,255.88,268.24,253.03z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M232.98,252.54c-3.66,1.44-4.3,1.75-10.47,3.05c-5.88,1.24-12.09,0.55-17.86-1.11c-5.77-1.66-11.18-4.94-14.75-9.77c0.39,1.97,1.63,3.75,3.34,4.8c-2.08-0.5-4.1-1.27-5.98-2.3c6.18,5.47,14.07,8.98,22.27,9.9C217.72,258.04,226.2,256.36,232.98,252.54z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M267.02,252.54c3.66,1.44,4.3,1.75,10.47,3.05c5.88,1.24,12.09,0.55,17.86-1.11s11.18-4.94,14.75-9.77c-0.39,1.97-1.63,3.75-3.34,4.8c2.08-0.5,4.1-1.27,5.98-2.3c-6.18,5.47-14.07,8.98-22.27,9.9C282.28,258.04,273.8,256.36,267.02,252.54z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M226.72,256.6c-3.57,0.78-4.2,0.98-10.01,1.21c-5.53,0.21-11.02-1.5-15.93-4.08c-4.91-2.58-9.21-6.68-11.55-11.97c-0.01,1.98,0.79,3.92,2.14,5.22c-1.79-0.83-3.47-1.92-4.98-3.24c4.59,6.36,11.08,11.09,18.32,13.36C211.96,259.38,219.91,259.17,226.72,256.6z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M273.28,256.6c3.57,0.78,4.2,0.98,10.01,1.21c5.53,0.21,11.02-1.5,15.93-4.08c4.91-2.58,9.21-6.68,11.55-11.97c0.01,1.98-0.79,3.92-2.14,5.22c1.79-0.83,3.47-1.92,4.98-3.24c-4.59,6.36-11.08,11.09-18.32,13.36C288.04,259.38,280.09,259.17,273.28,256.6z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.64,251.17c-3.27,1.76-3.83,2.13-9.44,3.98c-5.34,1.76-11.19,1.67-16.73,0.58c-5.53-1.09-10.89-3.81-14.69-8.23c0.55,1.9,1.88,3.54,3.58,4.41c-1.99-0.29-3.94-0.87-5.79-1.7c6.29,4.81,13.97,7.52,21.69,7.66C217.99,258,225.71,255.56,231.64,251.17z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.36,251.17c3.27,1.76,3.83,2.13,9.44,3.98c5.34,1.76,11.19,1.67,16.73,0.58c5.53-1.09,10.89-3.81,14.69-8.23c-0.55,1.9-1.88,3.54-3.58,4.41c1.99-0.29,3.94-0.87,5.79-1.7c-6.29,4.81-13.97,7.52-21.69,7.66C282.01,258,274.29,255.56,268.36,251.17z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_fa931 = {
  id: "eyelash_shape_fa931",
  name: "Eyelash 3",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M194.12,249.2c-2.95-1.52-6.62-1.57-9.61-0.13C187.79,249.17,191.07,249.28,194.12,249.2z" />
              <path d="M194.3,250.46c-2.62-0.81-5.54-0.64-8.05,0.47C188.91,250.84,191.57,250.74,194.3,250.46z" />
              <path d="M194.36,251.85c-2.51-0.87-5.41-0.55-7.67,0.85C189.34,252.46,191.99,252.22,194.36,251.85z" />
              <path d="M195.17,253.51c-2.45-1.05-5.45-0.68-7.57,0.93C190.41,254.18,193.21,253.92,195.17,253.51z" />
              <path d="M196.31,255.18c-2.18-1.26-5.07-1.19-7.19,0.18C191.55,255.31,193.99,255.27,196.31,255.18z" />
              <path d="M197.59,256.99c-2.18-0.85-4.79-0.5-6.68,0.9C193.03,257.5,195.15,257.12,197.59,256.99z" />
              <path d="M198.04,258.39c-2-0.76-4.48,0.09-5.58,1.92C194.21,259.61,195.97,258.91,198.04,258.39z" />
              <path d="M199.33,259.92c-1.96-0.72-4.35,0-5.58,1.69C195.57,261.03,197.4,260.46,199.33,259.92z" />
              <path d="M201.32,261.74c-1.7-0.27-3.51,0.71-4.22,2.27C198.44,263.38,199.79,262.74,201.32,261.74z" />
              <path d="M200.29,261.01c-1.74-0.84-4.09-0.01-4.9,1.75C197.02,262.14,198.64,261.52,200.29,261.01z" />
              <path d="M203.42,263.11c-1.74-0.15-3.47,1.2-3.77,2.91C200.76,265.16,201.87,264.3,203.42,263.11z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M305.88,249.2c2.95-1.52,6.62-1.57,9.61-0.13C312.21,249.17,308.93,249.28,305.88,249.2z" />
              <path d="M305.7,250.46c2.62-0.81,5.54-0.64,8.05,0.47C311.09,250.84,308.43,250.74,305.7,250.46z" />
              <path d="M305.64,251.85c2.51-0.87,5.41-0.55,7.67,0.85C310.66,252.46,308.01,252.22,305.64,251.85z" />
              <path d="M304.83,253.51c2.45-1.05,5.45-0.68,7.57,0.93C309.59,254.18,306.79,253.92,304.83,253.51z" />
              <path d="M303.69,255.18c2.18-1.26,5.07-1.19,7.19,0.18C308.45,255.31,306.01,255.27,303.69,255.18z" />
              <path d="M302.41,256.99c2.18-0.85,4.79-0.5,6.68,0.9C306.97,257.5,304.85,257.12,302.41,256.99z" />
              <path d="M301.96,258.39c2-0.76,4.48,0.09,5.58,1.92C305.79,259.61,304.03,258.91,301.96,258.39z" />
              <path d="M300.67,259.92c1.96-0.72,4.35,0,5.58,1.69C304.43,261.03,302.6,260.46,300.67,259.92z" />
              <path d="M298.68,261.74c1.7-0.27,3.51,0.71,4.22,2.27C301.56,263.38,300.21,262.74,298.68,261.74z" />
              <path d="M299.71,261.01c1.74-0.84,4.09-0.01,4.9,1.75C302.98,262.14,301.36,261.52,299.71,261.01z" />
              <path d="M296.58,263.11c1.74-0.15,3.47,1.2,3.77,2.91C299.24,265.16,298.13,264.3,296.58,263.11z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.87,249.45c-2.95-1.52-6.62-1.57-9.61-0.13C185.54,249.42,188.82,249.53,191.87,249.45z" />
              <path d="M192.05,250.71c-2.62-0.81-5.54-0.64-8.05,0.47C186.66,251.09,189.32,250.99,192.05,250.71z" />
              <path d="M192.11,252.1c-2.51-0.87-5.41-0.55-7.67,0.85C187.09,252.71,189.74,252.47,192.11,252.1z" />
              <path d="M192.92,253.76c-2.45-1.05-5.45-0.68-7.57,0.93C188.16,254.43,190.96,254.17,192.92,253.76z" />
              <path d="M194.06,255.43c-2.18-1.26-5.07-1.19-7.19,0.18C189.3,255.56,191.74,255.52,194.06,255.43z" />
              <path d="M195.34,256.74c-2.18-0.85-4.79-0.5-6.68,0.9C190.78,257.25,192.9,256.87,195.34,256.74z" />
              <path d="M196.04,257.89c-2-0.76-4.48,0.09-5.58,1.92C192.21,259.11,193.97,258.41,196.04,257.89z" />
              <path d="M197.58,258.92c-1.96-0.72-4.35,0-5.58,1.69C193.82,260.03,195.65,259.46,197.58,258.92z" />
              <path d="M200.82,260.99c-1.7-0.27-3.51,0.71-4.22,2.27C197.94,262.63,199.29,261.99,200.82,260.99z" />
              <path d="M199.29,260.26c-1.74-0.84-4.09-0.01-4.9,1.75C196.02,261.39,197.64,260.77,199.29,260.26z" />
              <path d="M202.42,261.86c-1.74-0.15-3.47,1.2-3.77,2.91C199.76,263.91,200.87,263.05,202.42,261.86z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.13,249.45c2.95-1.52,6.62-1.57,9.61-0.13C314.46,249.42,311.18,249.53,308.13,249.45z" />
              <path d="M307.95,250.71c2.62-0.81,5.54-0.64,8.05,0.47C313.34,251.09,310.68,250.99,307.95,250.71z" />
              <path d="M307.89,252.1c2.51-0.87,5.41-0.55,7.67,0.85C312.91,252.71,310.26,252.47,307.89,252.1z" />
              <path d="M307.08,253.76c2.45-1.05,5.45-0.68,7.57,0.93C311.84,254.43,309.04,254.17,307.08,253.76z" />
              <path d="M305.94,255.43c2.18-1.26,5.07-1.19,7.19,0.18C310.7,255.56,308.26,255.52,305.94,255.43z" />
              <path d="M304.66,256.74c2.18-0.85,4.79-0.5,6.68,0.9C309.22,257.25,307.1,256.87,304.66,256.74z" />
              <path d="M303.96,257.89c2-0.76,4.48,0.09,5.58,1.92C307.79,259.11,306.03,258.41,303.96,257.89z" />
              <path d="M302.42,258.92c1.96-0.72,4.35,0,5.58,1.69C306.18,260.03,304.35,259.46,302.42,258.92z" />
              <path d="M299.18,260.99c1.7-0.27,3.51,0.71,4.22,2.27C302.06,262.63,300.71,261.99,299.18,260.99z" />
              <path d="M300.71,260.26c1.74-0.84,4.09-0.01,4.9,1.75C303.98,261.39,302.36,260.77,300.71,260.26z" />
              <path d="M297.58,261.86c1.74-0.15,3.47,1.2,3.77,2.91C300.24,263.91,299.13,263.05,297.58,261.86z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.37,237.7c-2.95-1.52-6.62-1.57-9.61-0.13C185.04,237.67,188.32,237.78,191.37,237.7z" />
              <path d="M191.55,238.96c-2.62-0.81-5.54-0.64-8.05,0.47C186.16,239.34,188.82,239.24,191.55,238.96z" />
              <path d="M191.61,240.35c-2.51-0.87-5.41-0.55-7.67,0.85C186.59,240.96,189.24,240.72,191.61,240.35z" />
              <path d="M191.92,242.01c-2.45-1.05-5.45-0.68-7.57,0.93C187.16,242.68,189.96,242.42,191.92,242.01z" />
              <path d="M192.31,243.93c-2.18-1.26-5.07-1.19-7.19,0.18C187.55,244.06,189.99,244.02,192.31,243.93z" />
              <path d="M192.84,245.24c-2.18-0.85-4.79-0.5-6.68,0.9C188.28,245.75,190.4,245.37,192.84,245.24z" />
              <path d="M193.54,246.39c-2-0.76-4.48,0.09-5.58,1.92C189.71,247.61,191.47,246.91,193.54,246.39z" />
              <path d="M194.33,248.17c-1.96-0.72-4.35,0-5.58,1.69C190.57,249.28,192.4,248.71,194.33,248.17z" />
              <path d="M196.32,251.74c-1.7-0.27-3.51,0.71-4.22,2.27C193.44,253.38,194.79,252.74,196.32,251.74z" />
              <path d="M195.29,250.01c-1.74-0.84-4.09-0.01-4.9,1.75C192.02,251.14,193.64,250.52,195.29,250.01z" />
              <path d="M197.42,253.11c-1.15-0.15-2.3,1.23-2.5,2.99C195.66,255.22,196.39,254.34,197.42,253.11z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.63,237.7c2.95-1.52,6.62-1.57,9.61-0.13C314.96,237.67,311.68,237.78,308.63,237.7z" />
              <path d="M308.45,238.96c2.62-0.81,5.54-0.64,8.05,0.47C313.84,239.34,311.18,239.24,308.45,238.96z" />
              <path d="M308.39,240.35c2.51-0.87,5.41-0.55,7.67,0.85C313.41,240.96,310.76,240.72,308.39,240.35z" />
              <path d="M308.08,242.01c2.45-1.05,5.45-0.68,7.57,0.93C312.84,242.68,310.04,242.42,308.08,242.01z" />
              <path d="M307.69,243.93c2.18-1.26,5.07-1.19,7.19,0.18C312.45,244.06,310.01,244.02,307.69,243.93z" />
              <path d="M307.16,245.24c2.18-0.85,4.79-0.5,6.68,0.9C311.72,245.75,309.6,245.37,307.16,245.24z" />
              <path d="M306.46,246.39c2-0.76,4.48,0.09,5.58,1.92C310.29,247.61,308.53,246.91,306.46,246.39z" />
              <path d="M305.67,248.17c1.96-0.72,4.35,0,5.58,1.69C309.43,249.28,307.6,248.71,305.67,248.17z" />
              <path d="M303.68,251.74c1.7-0.27,3.51,0.71,4.22,2.27C306.56,253.38,305.21,252.74,303.68,251.74z" />
              <path d="M304.71,250.01c1.74-0.84,4.09-0.01,4.9,1.75C307.98,251.14,306.36,250.52,304.71,250.01z" />
              <path d="M302.58,253.11c1.15-0.15,2.3,1.23,2.5,2.99C304.34,255.22,303.61,254.34,302.58,253.11z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.85,256.66c-3.32,0.18-6.5,2-8.35,4.76C186.38,259.85,189.26,258.27,191.85,256.66z" />
              <path d="M192.65,257.66c-2.67,0.63-5.1,2.25-6.7,4.48C188.19,260.71,190.43,259.28,192.65,257.66z" />
              <path d="M193.4,258.83c-2.61,0.52-4.94,2.27-6.18,4.62C189.38,261.89,191.54,260.34,193.4,258.83z" />
              <path d="M194.94,259.84c-2.64,0.33-5.04,2.17-6.05,4.64C191.17,262.83,193.46,261.19,194.94,259.84z" />
              <path d="M196.77,260.71c-2.52,0.02-4.98,1.54-6.11,3.79C192.73,263.23,194.81,261.96,196.77,260.71z" />
              <path d="M198.54,261.19c-2.31,0.38-4.39,2-5.3,4.15C194.87,263.94,196.5,262.54,198.54,261.19z" />
              <path d="M199.73,261.83c-2.11,0.35-3.82,2.35-3.84,4.48C197.04,264.82,198.2,263.33,199.73,261.83z" />
              <path d="M201.57,261.94c-2.05,0.37-3.75,2.21-3.96,4.28C198.89,264.8,200.18,263.38,201.57,261.94z" />
              <path d="M205.42,262.08c-1.6,0.63-2.67,2.39-2.49,4.1C203.76,264.95,204.6,263.72,205.42,262.08z" />
              <path d="M203.73,262.22c-1.92,0.15-3.53,2.06-3.34,3.99C201.47,264.86,202.56,263.5,203.73,262.22z" />
              <path d="M207.23,262.02c-1.57,0.75-2.39,2.79-1.77,4.42C205.98,265.14,206.5,263.83,207.23,262.02z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.15,256.66c3.32,0.18,6.5,2,8.35,4.76C313.62,259.85,310.74,258.27,308.15,256.66z" />
              <path d="M307.35,257.66c2.67,0.63,5.1,2.25,6.7,4.48C311.81,260.71,309.57,259.28,307.35,257.66z" />
              <path d="M306.6,258.83c2.61,0.52,4.94,2.27,6.18,4.62C310.62,261.89,308.46,260.34,306.6,258.83z" />
              <path d="M305.06,259.84c2.64,0.33,5.04,2.17,6.05,4.64C308.83,262.83,306.54,261.19,305.06,259.84z" />
              <path d="M303.23,260.71c2.52,0.02,4.98,1.54,6.11,3.79C307.27,263.23,305.19,261.96,303.23,260.71z" />
              <path d="M301.46,261.19c2.31,0.38,4.39,2,5.3,4.15C305.13,263.94,303.5,262.54,301.46,261.19z" />
              <path d="M300.27,261.83c2.11,0.35,3.82,2.35,3.84,4.48C302.96,264.82,301.8,263.33,300.27,261.83z" />
              <path d="M298.43,261.94c2.05,0.37,3.75,2.21,3.96,4.28C301.11,264.8,299.82,263.38,298.43,261.94z" />
              <path d="M294.58,262.08c1.6,0.63,2.67,2.39,2.49,4.1C296.24,264.95,295.4,263.72,294.58,262.08z" />
              <path d="M296.27,262.22c1.92,0.15,3.53,2.06,3.34,3.99C298.53,264.86,297.44,263.5,296.27,262.22z" />
              <path d="M292.77,262.02c1.57,0.75,2.39,2.79,1.77,4.42C294.02,265.14,293.5,263.83,292.77,262.02z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.37,248.7c-2.95-1.52-6.62-1.57-9.61-0.13C185.04,248.67,188.32,248.78,191.37,248.7z" />
              <path d="M191.8,250.21c-2.62-0.81-5.54-0.64-8.05,0.47C186.41,250.59,189.07,250.49,191.8,250.21z" />
              <path d="M192.36,251.6c-2.51-0.87-5.41-0.55-7.67,0.85C187.34,252.21,189.99,251.97,192.36,251.6z" />
              <path d="M192.92,253.01c-2.45-1.05-5.45-0.68-7.57,0.93C188.16,253.68,190.96,253.42,192.92,253.01z" />
              <path d="M194.06,254.93c-2.18-1.26-5.07-1.19-7.19,0.18C189.3,255.06,191.74,255.02,194.06,254.93z" />
              <path d="M195.09,256.24c-2.18-0.85-4.79-0.5-6.68,0.9C190.53,256.75,192.65,256.37,195.09,256.24z" />
              <path d="M196.29,257.39c-2-0.76-4.48,0.09-5.58,1.92C192.46,258.61,194.22,257.91,196.29,257.39z" />
              <path d="M197.58,258.67c-1.96-0.72-4.35,0-5.58,1.69C193.82,259.78,195.65,259.21,197.58,258.67z" />
              <path d="M201.07,260.74c-1.7-0.27-3.51,0.71-4.22,2.27C198.19,262.38,199.54,261.74,201.07,260.74z" />
              <path d="M199.54,260.01c-1.74-0.84-4.09-0.01-4.9,1.75C196.27,261.14,197.89,260.52,199.54,260.01z" />
              <path d="M202.67,261.36c-1.74-0.15-3.47,1.2-3.77,2.91C200.01,263.41,201.12,262.55,202.67,261.36z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.63,248.7c2.95-1.52,6.62-1.57,9.61-0.13C314.96,248.67,311.68,248.78,308.63,248.7z" />
              <path d="M308.2,250.21c2.62-0.81,5.54-0.64,8.05,0.47C313.59,250.59,310.93,250.49,308.2,250.21z" />
              <path d="M307.64,251.6c2.51-0.87,5.41-0.55,7.67,0.85C312.66,252.21,310.01,251.97,307.64,251.6z" />
              <path d="M307.08,253.01c2.45-1.05,5.45-0.68,7.57,0.93C311.84,253.68,309.04,253.42,307.08,253.01z" />
              <path d="M305.94,254.93c2.18-1.26,5.07-1.19,7.19,0.18C310.7,255.06,308.26,255.02,305.94,254.93z" />
              <path d="M304.91,256.24c2.18-0.85,4.79-0.5,6.68,0.9C309.47,256.75,307.35,256.37,304.91,256.24z" />
              <path d="M303.71,257.39c2-0.76,4.48,0.09,5.58,1.92C307.54,258.61,305.78,257.91,303.71,257.39z" />
              <path d="M302.42,258.67c1.96-0.72,4.35,0,5.58,1.69C306.18,259.78,304.35,259.21,302.42,258.67z" />
              <path d="M298.93,260.74c1.7-0.27,3.51,0.71,4.22,2.27C301.81,262.38,300.46,261.74,298.93,260.74z" />
              <path d="M300.46,260.01c1.74-0.84,4.09-0.01,4.9,1.75C303.73,261.14,302.11,260.52,300.46,260.01z" />
              <path d="M297.33,261.36c1.74-0.15,3.47,1.2,3.77,2.91C299.99,263.41,298.88,262.55,297.33,261.36z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.12,254.7c-2.95-1.52-6.62-1.57-9.61-0.13C186.79,254.67,190.07,254.78,193.12,254.7z" />
              <path d="M193.3,255.96c-2.62-0.81-5.54-0.64-8.05,0.47C187.91,256.34,190.57,256.24,193.3,255.96z" />
              <path d="M193.36,257.35c-2.51-0.87-5.41-0.55-7.67,0.85C188.34,257.96,190.99,257.72,193.36,257.35z" />
              <path d="M194.17,259.01c-2.45-1.05-5.45-0.68-7.57,0.93C189.41,259.68,192.21,259.42,194.17,259.01z" />
              <path d="M195.06,260.93c-2.18-1.26-5.07-1.19-7.19,0.18C190.3,261.06,192.74,261.02,195.06,260.93z" />
              <path d="M196.09,262.24c-2.18-0.85-4.79-0.5-6.68,0.9C191.53,262.75,193.65,262.37,196.09,262.24z" />
              <path d="M197.29,263.14c-2-0.76-4.48,0.09-5.58,1.92C193.46,264.36,195.22,263.66,197.29,263.14z" />
              <path d="M198.58,264.17c-1.96-0.72-4.35,0-5.58,1.69C194.82,265.28,196.65,264.71,198.58,264.17z" />
              <path d="M202.07,265.74c-1.7-0.27-3.51,0.71-4.22,2.27C199.19,267.38,200.54,266.74,202.07,265.74z" />
              <path d="M200.29,265.26c-1.74-0.84-4.09-0.01-4.9,1.75C197.02,266.39,198.64,265.77,200.29,265.26z" />
              <path d="M204.42,266.36c-1.74-0.15-3.47,1.2-3.77,2.91C201.76,268.41,202.87,267.55,204.42,266.36z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.88,254.7c2.95-1.52,6.62-1.57,9.61-0.13C313.21,254.67,309.93,254.78,306.88,254.7z" />
              <path d="M306.7,255.96c2.62-0.81,5.54-0.64,8.05,0.47C312.09,256.34,309.43,256.24,306.7,255.96z" />
              <path d="M306.64,257.35c2.51-0.87,5.41-0.55,7.67,0.85C311.66,257.96,309.01,257.72,306.64,257.35z" />
              <path d="M305.83,259.01c2.45-1.05,5.45-0.68,7.57,0.93C310.59,259.68,307.79,259.42,305.83,259.01z" />
              <path d="M304.94,260.93c2.18-1.26,5.07-1.19,7.19,0.18C309.7,261.06,307.26,261.02,304.94,260.93z" />
              <path d="M303.91,262.24c2.18-0.85,4.79-0.5,6.68,0.9C308.47,262.75,306.35,262.37,303.91,262.24z" />
              <path d="M302.71,263.14c2-0.76,4.48,0.09,5.58,1.92C306.54,264.36,304.78,263.66,302.71,263.14z" />
              <path d="M301.42,264.17c1.96-0.72,4.35,0,5.58,1.69C305.18,265.28,303.35,264.71,301.42,264.17z" />
              <path d="M297.93,265.74c1.7-0.27,3.51,0.71,4.22,2.27C300.81,267.38,299.46,266.74,297.93,265.74z" />
              <path d="M299.71,265.26c1.74-0.84,4.09-0.01,4.9,1.75C302.98,266.39,301.36,265.77,299.71,265.26z" />
              <path d="M295.58,266.36c1.74-0.15,3.47,1.2,3.77,2.91C298.24,268.41,297.13,267.55,295.58,266.36z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.12,249.95c-2.95-1.52-6.62-1.57-9.61-0.13C186.79,249.92,190.07,250.03,193.12,249.95z" />
              <path d="M193.3,251.21c-2.62-0.81-5.54-0.64-8.05,0.47C187.91,251.59,190.57,251.49,193.3,251.21z" />
              <path d="M193.36,252.6c-2.51-0.87-5.41-0.55-7.67,0.85C188.34,253.21,190.99,252.97,193.36,252.6z" />
              <path d="M194.17,254.26c-2.45-1.05-5.45-0.68-7.57,0.93C189.41,254.93,192.21,254.67,194.17,254.26z" />
              <path d="M195.31,255.93c-2.18-1.26-5.07-1.19-7.19,0.18C190.55,256.06,192.99,256.02,195.31,255.93z" />
              <path d="M196.59,257.74c-2.18-0.85-4.79-0.5-6.68,0.9C192.03,258.25,194.15,257.87,196.59,257.74z" />
              <path d="M197.04,259.14c-2-0.76-4.48,0.09-5.58,1.92C193.21,260.36,194.97,259.66,197.04,259.14z" />
              <path d="M198.33,260.67c-1.96-0.72-4.35,0-5.58,1.69C194.57,261.78,196.4,261.21,198.33,260.67z" />
              <path d="M200.32,262.49c-1.7-0.27-3.51,0.71-4.22,2.27C197.44,264.13,198.79,263.49,200.32,262.49z" />
              <path d="M199.29,261.76c-1.74-0.84-4.09-0.01-4.9,1.75C196.02,262.89,197.64,262.27,199.29,261.76z" />
              <path d="M202.42,263.86c-1.74-0.15-3.47,1.2-3.77,2.91C199.76,265.91,200.87,265.05,202.42,263.86z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.88,249.95c2.95-1.52,6.62-1.57,9.61-0.13C313.21,249.92,309.93,250.03,306.88,249.95z" />
              <path d="M306.7,251.21c2.62-0.81,5.54-0.64,8.05,0.47C312.09,251.59,309.43,251.49,306.7,251.21z" />
              <path d="M306.64,252.6c2.51-0.87,5.41-0.55,7.67,0.85C311.66,253.21,309.01,252.97,306.64,252.6z" />
              <path d="M305.83,254.26c2.45-1.05,5.45-0.68,7.57,0.93C310.59,254.93,307.79,254.67,305.83,254.26z" />
              <path d="M304.69,255.93c2.18-1.26,5.07-1.19,7.19,0.18C309.45,256.06,307.01,256.02,304.69,255.93z" />
              <path d="M303.41,257.74c2.18-0.85,4.79-0.5,6.68,0.9C307.97,258.25,305.85,257.87,303.41,257.74z" />
              <path d="M302.96,259.14c2-0.76,4.48,0.09,5.58,1.92C306.79,260.36,305.03,259.66,302.96,259.14z" />
              <path d="M301.67,260.67c1.96-0.72,4.35,0,5.58,1.69C305.43,261.78,303.6,261.21,301.67,260.67z" />
              <path d="M299.68,262.49c1.7-0.27,3.51,0.71,4.22,2.27C302.56,264.13,301.21,263.49,299.68,262.49z" />
              <path d="M300.71,261.76c1.74-0.84,4.09-0.01,4.9,1.75C303.98,262.89,302.36,262.27,300.71,261.76z" />
              <path d="M297.58,263.86c1.74-0.15,3.47,1.2,3.77,2.91C300.24,265.91,299.13,265.05,297.58,263.86z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.76,248.75c-3.22-0.8-6.8-0.01-9.39,2.08C185.59,250.17,188.81,249.52,191.76,248.75z" />
              <path d="M192.22,249.94c-2.74-0.19-5.54,0.64-7.72,2.3C187.07,251.53,189.63,250.82,192.22,249.94z" />
              <path d="M192.6,251.27c-2.65-0.27-5.39,0.71-7.27,2.59C187.85,253.02,190.38,252.17,192.6,251.27z" />
              <path d="M193.77,252.7c-2.62-0.46-5.46,0.59-7.15,2.64C189.29,254.44,191.96,253.55,193.77,252.7z" />
              <path d="M195.26,254.07c-2.42-0.73-5.21,0-6.96,1.82C190.66,255.29,193.03,254.68,195.26,254.07z" />
              <path d="M196.81,255.04c-2.32-0.32-4.78,0.61-6.29,2.4C192.49,256.59,194.47,255.74,196.81,255.04z" />
              <path d="M197.76,256.01c-2.12-0.29-4.34,1.12-4.99,3.15C194.31,258.07,195.85,256.99,197.76,256.01z" />
              <path d="M199.49,256.65c-2.07-0.25-4.24,1-5.05,2.92C196.08,258.6,197.73,257.62,199.49,256.65z" />
              <path d="M203.12,257.93c-1.71,0.13-3.26,1.49-3.59,3.18C200.69,260.18,201.86,259.25,203.12,257.93z" />
              <path d="M201.46,257.57c-1.88-0.42-3.98,0.93-4.37,2.82C198.54,259.42,199.98,258.44,201.46,257.57z" />
              <path d="M204.87,258.41c-1.72,0.26-3.11,1.96-3,3.7C202.76,261.02,203.64,259.93,204.87,258.41z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.24,248.75c3.22-0.8,6.8-0.01,9.39,2.08C314.41,250.17,311.19,249.52,308.24,248.75z" />
              <path d="M307.78,249.94c2.74-0.19,5.54,0.64,7.72,2.3C312.93,251.53,310.37,250.82,307.78,249.94z" />
              <path d="M307.4,251.27c2.65-0.27,5.39,0.71,7.27,2.59C312.15,253.02,309.62,252.17,307.4,251.27z" />
              <path d="M306.23,252.7c2.62-0.46,5.46,0.59,7.15,2.64C310.71,254.44,308.04,253.55,306.23,252.7z" />
              <path d="M304.74,254.07c2.42-0.73,5.21,0,6.96,1.82C309.34,255.29,306.97,254.68,304.74,254.07z" />
              <path d="M303.19,255.04c2.32-0.32,4.78,0.61,6.29,2.4C307.51,256.59,305.53,255.74,303.19,255.04z" />
              <path d="M302.24,256.01c2.12-0.29,4.34,1.12,4.99,3.15C305.69,258.07,304.15,256.99,302.24,256.01z" />
              <path d="M300.51,256.65c2.07-0.25,4.24,1,5.05,2.92C303.92,258.6,302.27,257.62,300.51,256.65z" />
              <path d="M296.88,257.93c1.71,0.13,3.26,1.49,3.59,3.18C299.31,260.18,298.14,259.25,296.88,257.93z" />
              <path d="M298.54,257.57c1.88-0.42,3.98,0.93,4.37,2.82C301.46,259.42,300.02,258.44,298.54,257.57z" />
              <path d="M295.13,258.41c1.72,0.26,3.11,1.96,3,3.7C297.24,261.02,296.36,259.93,295.13,258.41z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.76,247.5c-3.22-0.8-6.8-0.01-9.39,2.08C185.59,248.92,188.81,248.27,191.76,247.5z" />
              <path d="M192.22,248.69c-2.74-0.19-5.54,0.64-7.72,2.3C187.07,250.28,189.63,249.57,192.22,248.69z" />
              <path d="M193.1,250.02c-2.65-0.27-5.39,0.71-7.27,2.59C188.35,251.77,190.88,250.92,193.1,250.02z" />
              <path d="M194.27,251.45c-2.62-0.46-5.46,0.59-7.15,2.64C189.79,253.19,192.46,252.3,194.27,251.45z" />
              <path d="M196.01,252.82c-2.42-0.73-5.21,0-6.96,1.82C191.41,254.04,193.78,253.43,196.01,252.82z" />
              <path d="M197.56,253.79c-2.32-0.32-4.78,0.61-6.29,2.4C193.24,255.34,195.22,254.49,197.56,253.79z" />
              <path d="M198.51,254.76c-2.12-0.29-4.34,1.12-4.99,3.15C195.06,256.82,196.6,255.74,198.51,254.76z" />
              <path d="M200.24,255.4c-2.07-0.25-4.24,1-5.05,2.92C196.83,257.35,198.48,256.37,200.24,255.4z" />
              <path d="M203.87,256.68c-1.71,0.13-3.26,1.49-3.59,3.18C201.44,258.93,202.61,258,203.87,256.68z" />
              <path d="M202.21,256.32c-1.88-0.42-3.98,0.93-4.37,2.82C199.29,258.17,200.73,257.19,202.21,256.32z" />
              <path d="M205.62,257.16c-1.72,0.26-3.11,1.96-3,3.7C203.51,259.77,204.39,258.68,205.62,257.16z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.24,247.5c3.22-0.8,6.8-0.01,9.39,2.08C314.41,248.92,311.19,248.27,308.24,247.5z" />
              <path d="M307.78,248.69c2.74-0.19,5.54,0.64,7.72,2.3C312.93,250.28,310.37,249.57,307.78,248.69z" />
              <path d="M306.9,250.02c2.65-0.27,5.39,0.71,7.27,2.59C311.65,251.77,309.12,250.92,306.9,250.02z" />
              <path d="M305.73,251.45c2.62-0.46,5.46,0.59,7.15,2.64C310.21,253.19,307.54,252.3,305.73,251.45z" />
              <path d="M303.99,252.82c2.42-0.73,5.21,0,6.96,1.82C308.59,254.04,306.22,253.43,303.99,252.82z" />
              <path d="M302.44,253.79c2.32-0.32,4.78,0.61,6.29,2.4C306.76,255.34,304.78,254.49,302.44,253.79z" />
              <path d="M301.49,254.76c2.12-0.29,4.34,1.12,4.99,3.15C304.94,256.82,303.4,255.74,301.49,254.76z" />
              <path d="M299.76,255.4c2.07-0.25,4.24,1,5.05,2.92C303.17,257.35,301.52,256.37,299.76,255.4z" />
              <path d="M296.13,256.68c1.71,0.13,3.26,1.49,3.59,3.18C298.56,258.93,297.39,258,296.13,256.68z" />
              <path d="M297.79,256.32c1.88-0.42,3.98,0.93,4.37,2.82C300.71,258.17,299.27,257.19,297.79,256.32z" />
              <path d="M294.38,257.16c1.72,0.26,3.11,1.96,3,3.7C296.49,259.77,295.61,258.68,294.38,257.16z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.42,250.32c-3.32-0.13-6.66,1.38-8.76,3.96C185.67,252.97,188.68,251.68,191.42,250.32z" />
              <path d="M192.11,251.38c-2.72,0.38-5.29,1.76-7.09,3.84C187.39,254,189.76,252.79,192.11,251.38z" />
              <path d="M192.76,252.61c-2.64,0.28-5.13,1.79-6.59,4.02C188.47,255.29,190.76,253.95,192.76,252.61z" />
              <path d="M194.19,253.77c-2.66,0.09-5.22,1.69-6.46,4.05C190.16,256.4,192.59,254.97,194.19,253.77z" />
              <path d="M195.93,254.81c-2.51-0.22-5.1,1.07-6.44,3.21C191.68,256.94,193.87,255.87,195.93,254.81z" />
              <path d="M197.65,255.45c-2.34,0.16-4.55,1.58-5.67,3.64C193.74,257.84,195.5,256.6,197.65,255.45z" />
              <path d="M198.77,256.19c-2.13,0.15-4.02,1.98-4.24,4.11C195.82,258.92,197.11,257.55,198.77,256.19z" />
              <path d="M200.6,256.47c-2.08,0.17-3.94,1.85-4.34,3.89C197.67,259.07,199.08,257.78,200.6,256.47z" />
              <path d="M204.41,256.98c-1.65,0.47-2.88,2.13-2.86,3.85C202.5,259.68,203.45,258.53,204.41,256.98z" />
              <path d="M202.72,256.96c-1.93-0.03-3.71,1.73-3.7,3.66C200.23,259.37,201.44,258.12,202.72,256.96z" />
              <path d="M206.23,257.09c-1.64,0.6-2.64,2.55-2.18,4.24C204.69,260.07,205.34,258.82,206.23,257.09z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.58,250.32c3.32-0.13,6.66,1.38,8.76,3.96C314.33,252.97,311.32,251.68,308.58,250.32z" />
              <path d="M307.89,251.38c2.72,0.38,5.29,1.76,7.09,3.84C312.61,254,310.24,252.79,307.89,251.38z" />
              <path d="M307.24,252.61c2.64,0.28,5.13,1.79,6.59,4.02C311.53,255.29,309.24,253.95,307.24,252.61z" />
              <path d="M305.81,253.77c2.66,0.09,5.22,1.69,6.46,4.05C309.84,256.4,307.41,254.97,305.81,253.77z" />
              <path d="M304.07,254.81c2.51-0.22,5.1,1.07,6.44,3.21C308.32,256.94,306.13,255.87,304.07,254.81z" />
              <path d="M302.35,255.45c2.34,0.16,4.55,1.58,5.67,3.64C306.26,257.84,304.5,256.6,302.35,255.45z" />
              <path d="M301.23,256.19c2.13,0.15,4.02,1.98,4.24,4.11C304.18,258.92,302.89,257.55,301.23,256.19z" />
              <path d="M299.4,256.47c2.08,0.17,3.94,1.85,4.34,3.89C302.33,259.07,300.92,257.78,299.4,256.47z" />
              <path d="M295.59,256.98c1.65,0.47,2.88,2.13,2.86,3.85C297.5,259.68,296.55,258.53,295.59,256.98z" />
              <path d="M297.28,256.96c1.93-0.03,3.71,1.73,3.7,3.66C299.77,259.37,298.56,258.12,297.28,256.96z" />
              <path d="M293.77,257.09c1.64,0.6,2.64,2.55,2.18,4.24C295.31,260.07,294.66,258.82,293.77,257.09z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.17,245.32c-3.32-0.13-6.66,1.38-8.76,3.96C184.42,247.97,187.43,246.68,190.17,245.32z" />
              <path d="M190.86,246.38c-2.72,0.38-5.29,1.76-7.09,3.84C186.14,249,188.51,247.79,190.86,246.38z" />
              <path d="M191.51,247.61c-2.64,0.28-5.13,1.79-6.59,4.02C187.22,250.29,189.51,248.95,191.51,247.61z" />
              <path d="M192.94,248.77c-2.66,0.09-5.22,1.69-6.46,4.05C188.91,251.4,191.34,249.97,192.94,248.77z" />
              <path d="M194.68,250.06c-2.51-0.22-5.1,1.07-6.44,3.21C190.43,252.19,192.62,251.12,194.68,250.06z" />
              <path d="M196.15,250.95c-2.34,0.16-4.55,1.58-5.67,3.64C192.24,253.34,194,252.1,196.15,250.95z" />
              <path d="M197.52,251.94c-2.13,0.15-4.02,1.98-4.24,4.11C194.57,254.67,195.86,253.3,197.52,251.94z" />
              <path d="M199.1,252.72c-2.08,0.17-3.94,1.85-4.34,3.89C196.17,255.32,197.58,254.03,199.1,252.72z" />
              <path d="M200.97,253.71c-1.93-0.03-3.71,1.73-3.7,3.66C198.48,256.12,199.69,254.87,200.97,253.71z" />
              <path d="M202.66,254.23c-1.65,0.47-2.88,2.13-2.86,3.85C200.75,256.93,201.7,255.78,202.66,254.23z" />
              <path d="M204.48,254.84c-1.64,0.6-2.64,2.55-2.18,4.24C202.94,257.82,203.59,256.57,204.48,254.84z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.83,245.32c3.32-0.13,6.66,1.38,8.76,3.96C315.58,247.97,312.57,246.68,309.83,245.32z" />
              <path d="M309.14,246.38c2.72,0.38,5.29,1.76,7.09,3.84C313.86,249,311.49,247.79,309.14,246.38z" />
              <path d="M308.49,247.61c2.64,0.28,5.13,1.79,6.59,4.02C312.78,250.29,310.49,248.95,308.49,247.61z" />
              <path d="M307.06,248.77c2.66,0.09,5.22,1.69,6.46,4.05C311.09,251.4,308.66,249.97,307.06,248.77z" />
              <path d="M305.32,250.06c2.51-0.22,5.1,1.07,6.44,3.21C309.57,252.19,307.38,251.12,305.32,250.06z" />
              <path d="M303.85,250.95c2.34,0.16,4.55,1.58,5.67,3.64C307.76,253.34,306,252.1,303.85,250.95z" />
              <path d="M302.48,251.94c2.13,0.15,4.02,1.98,4.24,4.11C305.43,254.67,304.14,253.3,302.48,251.94z" />
              <path d="M300.9,252.72c2.08,0.17,3.94,1.85,4.34,3.89C303.83,255.32,302.42,254.03,300.9,252.72z" />
              <path d="M299.03,253.71c1.93-0.03,3.71,1.73,3.7,3.66C301.52,256.12,300.31,254.87,299.03,253.71z" />
              <path d="M297.34,254.23c1.65,0.47,2.88,2.13,2.86,3.85C299.25,256.93,298.3,255.78,297.34,254.23z" />
              <path d="M295.52,254.84c1.64,0.6,2.64,2.55,2.18,4.24C297.06,257.82,296.41,256.57,295.52,254.84z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.49,244.7c-3.27-0.58-6.79,0.45-9.22,2.71C185.43,246.55,188.6,245.67,191.49,244.7z" />
              <path d="M192.03,245.86c-2.75,0-5.48,1.02-7.55,2.83C187,247.8,189.51,246.92,192.03,245.86z" />
              <path d="M192.5,247.16c-2.66-0.09-5.33,1.07-7.08,3.08C187.88,249.23,190.34,248.22,192.5,247.16z" />
              <path d="M193.76,248.51c-2.65-0.28-5.41,0.96-6.96,3.13C189.41,250.55,192.01,249.48,193.76,248.51z" />
              <path d="M195.31,250.02c-2.46-0.56-5.2,0.36-6.82,2.29C190.81,251.55,193.12,250.79,195.31,250.02z" />
              <path d="M196.64,251.1c-2.34-0.17-4.73,0.94-6.12,2.83C192.44,252.94,194.35,251.95,196.64,251.1z" />
              <path d="M197.87,252.28c-2.13-0.14-4.25,1.41-4.76,3.48C194.57,254.58,196.03,253.39,197.87,252.28z" />
              <path d="M199.32,253.27c-2.08-0.11-4.16,1.29-4.84,3.26C196.06,255.44,197.63,254.35,199.32,253.27z" />
              <path d="M201.04,254.5c-1.91-0.29-3.91,1.2-4.17,3.11C198.24,256.55,199.61,255.48,201.04,254.5z" />
              <path d="M202.64,255.25c-1.7,0.24-3.15,1.71-3.37,3.42C200.38,257.66,201.47,256.65,202.64,255.25z" />
              <path d="M204.36,256.1c-1.7,0.37-2.96,2.17-2.74,3.9C202.43,258.85,203.23,257.7,204.36,256.1z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.51,244.7c3.27-0.58,6.79,0.45,9.22,2.71C314.57,246.55,311.4,245.67,308.51,244.7z" />
              <path d="M307.97,245.86c2.75,0,5.48,1.02,7.55,2.83C313,247.8,310.49,246.92,307.97,245.86z" />
              <path d="M307.5,247.16c2.66-0.09,5.33,1.07,7.08,3.08C312.12,249.23,309.66,248.22,307.5,247.16z" />
              <path d="M306.24,248.51c2.65-0.28,5.41,0.96,6.96,3.13C310.59,250.55,307.99,249.48,306.24,248.51z" />
              <path d="M304.69,250.02c2.46-0.56,5.2,0.36,6.82,2.29C309.19,251.55,306.88,250.79,304.69,250.02z" />
              <path d="M303.36,251.1c2.34-0.17,4.73,0.94,6.12,2.83C307.56,252.94,305.65,251.95,303.36,251.1z" />
              <path d="M302.13,252.28c2.13-0.14,4.25,1.41,4.76,3.48C305.43,254.58,303.97,253.39,302.13,252.28z" />
              <path d="M300.68,253.27c2.08-0.11,4.16,1.29,4.84,3.26C303.94,255.44,302.37,254.35,300.68,253.27z" />
              <path d="M298.96,254.5c1.91-0.29,3.91,1.2,4.17,3.11C301.76,256.55,300.39,255.48,298.96,254.5z" />
              <path d="M297.36,255.25c1.7,0.24,3.15,1.71,3.37,3.42C299.62,257.66,298.53,256.65,297.36,255.25z" />
              <path d="M295.64,256.1c1.7,0.37,2.96,2.17,2.74,3.9C297.57,258.85,296.77,257.7,295.64,256.1z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.46,248.02c-3.32,0.08-6.56,1.79-8.5,4.48C185.89,251.03,188.81,249.55,191.46,248.02z" />
              <path d="M192.22,249.05c-2.69,0.54-5.17,2.08-6.84,4.26C187.67,251.95,189.96,250.59,192.22,249.05z" />
              <path d="M192.94,250.24c-2.62,0.44-5.01,2.1-6.33,4.42C188.82,253.17,191.03,251.69,192.94,250.24z" />
              <path d="M194.44,251.3c-2.65,0.25-5.11,2.01-6.2,4.44C190.58,254.17,192.92,252.6,194.44,251.3z" />
              <path d="M196.26,252.48c-2.52-0.07-5.03,1.38-6.23,3.59C192.14,254.87,194.26,253.66,196.26,252.48z" />
              <path d="M197.77,253.28c-2.32,0.3-4.45,1.85-5.44,3.98C194.02,255.91,195.7,254.56,197.77,253.28z" />
              <path d="M199.21,254.19c-2.12,0.28-3.89,2.22-3.98,4.36C196.43,257.09,197.63,255.64,199.21,254.19z" />
              <path d="M200.83,254.87c-2.07,0.3-3.82,2.08-4.1,4.15C198.06,257.65,199.39,256.27,200.83,254.87z" />
              <path d="M202.76,255.74c-1.93,0.09-3.59,1.95-3.47,3.88C200.42,258.3,201.55,256.98,202.76,255.74z" />
              <path d="M204.48,256.16c-1.62,0.57-2.75,2.3-2.62,4.02C202.73,258.97,203.61,257.76,204.48,256.16z" />
              <path d="M206.33,256.65c-1.6,0.7-2.48,2.71-1.92,4.36C204.98,259.73,205.54,258.44,206.33,256.65z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.54,248.02c3.32,0.08,6.56,1.79,8.5,4.48C314.11,251.03,311.19,249.55,308.54,248.02z" />
              <path d="M307.78,249.05c2.69,0.54,5.17,2.08,6.84,4.26C312.33,251.95,310.04,250.59,307.78,249.05z" />
              <path d="M307.06,250.24c2.62,0.44,5.01,2.1,6.33,4.42C311.18,253.17,308.97,251.69,307.06,250.24z" />
              <path d="M305.56,251.3c2.65,0.25,5.11,2.01,6.2,4.44C309.42,254.17,307.08,252.6,305.56,251.3z" />
              <path d="M303.74,252.48c2.52-0.07,5.03,1.38,6.23,3.59C307.86,254.87,305.74,253.66,303.74,252.48z" />
              <path d="M302.23,253.28c2.32,0.3,4.45,1.85,5.44,3.98C305.98,255.91,304.3,254.56,302.23,253.28z" />
              <path d="M300.79,254.19c2.12,0.28,3.89,2.22,3.98,4.36C303.57,257.09,302.37,255.64,300.79,254.19z" />
              <path d="M299.17,254.87c2.07,0.3,3.82,2.08,4.1,4.15C301.94,257.65,300.61,256.27,299.17,254.87z" />
              <path d="M297.24,255.74c1.93,0.09,3.59,1.95,3.47,3.88C299.58,258.3,298.45,256.98,297.24,255.74z" />
              <path d="M295.52,256.16c1.62,0.57,2.75,2.3,2.62,4.02C297.27,258.97,296.39,257.76,295.52,256.16z" />
              <path d="M293.67,256.65c1.6,0.7,2.48,2.71,1.92,4.36C295.02,259.73,294.46,258.44,293.67,256.65z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_ggh48 = {
  id: "eyelash_shape_ggh48",
  name: "Eyelash 4",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M229.54,254.91c-3.65,7.22-12.25,11.57-20.23,10.25c-7.98-1.33-14.71-8.22-15.84-16.24c-2.81-1.39-6.58-0.36-8.3,2.26c2-1.61,5.09-1.69,7.17-0.18c-2.68-0.81-5.81,1.49-5.83,4.29c1.28-2.04,3.88-3.16,6.25-2.7c-2.79,0.48-5.07,3.05-5.21,5.87c1.59-1.98,3.46-2.93,5.61-4.04c-2.76,0.8-3.94,3.81-3.69,6.43c0.67-2.03,2.44-3.67,4.51-4.18c-2.84,1.47-3.83,5.64-1.96,8.23c-0.16-2.67,1.7-5.32,4.26-6.08c-2.7,1.62-3.56,5.67-1.74,8.24c-0.1-2.4,1.1-4.81,3.08-6.17c-2.16,2.04-2.34,5.82-0.39,8.06c0-3.85,1.16-4.09,1.74-6.13c-1.62,5.52-0.29,5.03,0.04,7.78c1.23-4.75,0.7-3.81,3.08-5.84c0.07-0.06,0.22-0.05,0.18,0.04c-1.03,2.48-2.44,3.06,0.22,7.51c-0.6-3.33,1.14-4.63,1.33-7.07c0,0-0.12,7,1.72,7.45c0.65,0.16-0.94-1.46,2.01-6.22c0.87-1.4,1,7.15,1.48,6.53c1.34-1.74,1.14-6.69,1.14-6.69l2.22,6.47c1.27-1.72,1.48-4.16,0.53-6.07c2.4,0.56,4.28,2.87,4.34,5.33c1.4-1.7,0.9-4.59-0.99-5.72c2.12-0.3,4.34,1.15,4.92,3.21c0.89-1.52,0.31-3.71-1.22-4.58c0,0,3.41,0.72,4.57,2.45c0.57,0.85-0.95-4.01-0.95-4.01s1.71,0.29,3.77,1.3c0.84,0.41,0.11-1.85-1.45-3.45c-0.61-0.62,2.46,0.08,3.72,0.91c0.68,0.45-0.49-1.84-1.75-2.75c0.89-0.92,2.77-0.31,2.95,0.96c0.52-1.08-0.72-2.52-1.87-2.15c0.5-0.99,2.01-1.27,2.83-0.52C231.82,256.34,230.05,255.38,229.54,254.91z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M270.46,254.91c3.65,7.22,12.25,11.57,20.23,10.25c7.98-1.33,14.71-8.22,15.84-16.24c2.81-1.39,6.58-0.36,8.3,2.26c-2-1.61-5.09-1.69-7.17-0.18c2.68-0.81,5.81,1.49,5.83,4.29c-1.28-2.04-3.88-3.16-6.25-2.7c2.79,0.48,5.07,3.05,5.21,5.87c-1.59-1.98-3.46-2.93-5.61-4.04c2.76,0.8,3.94,3.81,3.69,6.43c-0.67-2.03-2.44-3.67-4.51-4.18c2.84,1.47,3.83,5.64,1.96,8.23c0.16-2.67-1.7-5.32-4.26-6.08c2.7,1.62,3.56,5.67,1.74,8.24c0.1-2.4-1.1-4.81-3.08-6.17c2.16,2.04,2.34,5.82,0.39,8.06c0-3.85-1.16-4.09-1.74-6.13c1.62,5.52,0.29,5.03-0.04,7.78c-1.23-4.75-0.7-3.81-3.08-5.84c-0.07-0.06-0.22-0.05-0.18,0.04c1.03,2.48,2.44,3.06-0.22,7.51c0.6-3.33-1.14-4.63-1.33-7.07c0,0,0.12,7-1.72,7.45c-0.65,0.16,0.94-1.46-2.01-6.22c-0.87-1.4-1,7.15-1.48,6.53c-1.34-1.74-1.14-6.69-1.14-6.69l-2.22,6.47c-1.27-1.72-1.48-4.16-0.53-6.07c-2.4,0.56-4.28,2.87-4.34,5.33c-1.4-1.7-0.9-4.59,0.99-5.72c-2.12-0.3-4.34,1.15-4.92,3.21c-0.89-1.52-0.31-3.71,1.22-4.58c0,0-3.41,0.72-4.57,2.45c-0.57,0.85,0.95-4.01,0.95-4.01s-1.71,0.29-3.77,1.3c-0.84,0.41-0.11-1.85,1.45-3.45c0.61-0.62-2.46,0.08-3.72,0.91c-0.68,0.45,0.49-1.84,1.75-2.75c-0.89-0.92-2.77-0.31-2.95,0.96c-0.52-1.08,0.72-2.52,1.87-2.15c-0.5-0.99-2.01-1.27-2.83-0.52C268.18,256.34,269.95,255.38,270.46,254.91z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M229.98,247.76c-2.99,5.18-9.83,12-18.35,13.65c-10.94,2.11-15.75-3.46-19.57-10.83c-0.71-1.37-3.55-2.47-5.07-2.31s-2.9,1.33-3.31,2.81c1.81-1.88,5.09-2.08,7.12-0.43c-2.05-1.1-4.96,0.05-5.72,2.25c1.88-1.36,4.58-1.48,6.57-0.29c-2.1-0.68-4.62,0.68-5.21,2.8c3.23-2.07,3.87-0.89,5.81-1.34c-2.35,0.23-4.29,2.55-4.09,4.9c0.83-1.99,3.17-3.23,5.28-2.78c-2.14,0.68-3.44,3.28-2.69,5.39c0.44-1.96,2.26-3.53,4.27-3.68c-1.59,1.64-2.01,4.31-0.98,6.35c0.06-1.98,1.64-3.8,3.58-4.15c-1.5,1.1-2.12,3.25-1.45,4.98c2.34-3.8,2.96-2.34,4.44-3.51c-0.57,1.62-1.25,1.37-1.71,4.87c2.07-3.37,2.85-2.49,4.27-3.74c-0.99,1.36-1.05,3.33-0.16,4.75c-0.33-1.93,1.02-3.99,2.91-4.47c-1.19,1.36-1.17,3.62,0.03,4.97c0.9-3.83,1.68-3.39,2.52-5.08c1.3,2.17,1.01,3.46,1.49,4.79c0.21-1.47,0.84-1.21,0.58-5.22c1.11,0.54,2.7,2.04,3.55,4.88c0.66-2.42,0-3.21,0-4.82c1.2,0.92,2.54,1.17,2.8,3.53c1.2-1.12,0.65-3.5-0.93-3.98c1.78-0.58,3.89,0.09,5.01,1.58c0.84-1.28-0.49-3.29-2-3.02c1.61-1.42,4.14-1.68,6.01-0.63c0.14-1.15-1.33-2.12-2.34-1.55c1.1-1.94,4.1-2.44,5.76-0.95c-0.01-1.34-1.53-2.42-2.8-1.99c0.8-1.52,2.81-2.28,4.42-1.67c-0.49-1.4-1.24-1.35-1.87-2.02c1.17,0.16,2-0.4,3.5,0.48c-0.73-1.48-1.58-1.22-2.37-1.83C229.47,249.47,229.88,249.24,229.98,247.76z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path
                id="Bottom_22_"
                d="M270.02,247.76c2.99,5.18,9.83,12,18.35,13.65c10.94,2.11,15.75-3.46,19.57-10.83c0.71-1.37,3.55-2.47,5.07-2.31s2.9,1.33,3.31,2.81c-1.81-1.88-5.09-2.08-7.12-0.43c2.05-1.1,4.96,0.05,5.72,2.25c-1.88-1.36-4.58-1.48-6.57-0.29c2.1-0.68,4.62,0.68,5.21,2.8c-3.23-2.07-3.87-0.89-5.81-1.34c2.35,0.23,4.29,2.55,4.09,4.9c-0.83-1.99-3.17-3.23-5.28-2.78c2.14,0.68,3.44,3.28,2.69,5.39c-0.44-1.96-2.26-3.53-4.27-3.68c1.59,1.64,2.01,4.31,0.98,6.35c-0.06-1.98-1.64-3.8-3.58-4.15c1.5,1.1,2.12,3.25,1.45,4.98c-2.34-3.8-2.96-2.34-4.44-3.51c0.57,1.62,1.25,1.37,1.71,4.87c-2.07-3.37-2.85-2.49-4.27-3.74c0.99,1.36,1.05,3.33,0.16,4.75c0.32-1.93-1.02-3.99-2.91-4.47c1.19,1.36,1.17,3.62-0.03,4.97c-0.9-3.83-1.68-3.39-2.52-5.08c-1.3,2.17-1.01,3.46-1.49,4.79c-0.21-1.47-0.84-1.21-0.58-5.22c-1.11,0.54-2.7,2.04-3.55,4.88c-0.66-2.42,0-3.21,0-4.82c-1.2,0.92-2.54,1.17-2.8,3.53c-1.2-1.12-0.65-3.5,0.93-3.98c-1.78-0.58-3.89,0.09-5.01,1.58c-0.84-1.28,0.49-3.29,2-3.02c-1.61-1.42-4.14-1.68-6.01-0.63c-0.14-1.15,1.33-2.12,2.34-1.55c-1.1-1.94-4.1-2.44-5.76-0.95c0.01-1.34,1.53-2.42,2.8-1.99c-0.8-1.52-2.81-2.28-4.42-1.67c0.49-1.4,1.24-1.35,1.87-2.02c-1.17,0.16-2-0.4-3.5,0.48c0.73-1.48,1.58-1.22,2.37-1.83C270.53,249.47,270.12,249.24,270.02,247.76z"
              />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M229.73,256.96c-3.62,4.64-9.52,7.26-14.53,6.17c-6.56-1.43-11.86-3.87-15.79-8.24c-3.93-4.37-6.63-9.72-8.73-15.21c-0.6-1.57-2.16-2.72-3.83-2.84c-1.68-0.12-3.38,0.81-4.19,2.28c2.5-1.86,6.63-0.75,7.85,2.13c-1.77-2.24-5.86-1.85-7.18,0.69c2.74-1.54,6.62-0.54,8.27,2.13c-2.5-1.7-6.44-0.54-7.61,2.26c2.45-1.54,5.68-1.77,8.32-0.6c-2.71-0.88-5.97,0.62-7.06,3.25c2.12-2.31,6.15-2.49,8.46-0.37c-2.64-0.59-5.6,0.89-6.71,3.36c2.13-2.25,6.02-2.5,8.42-0.55c-2.7-0.55-5.67,1.29-6.37,3.95c4.79-3.11,5.39-1.4,8.06-1.53c-2.5-1.05-5.43,2.44-5.35,5.25c1.34-2.71,5.17-3.8,7.73-2.21c-2.26,0.72-3.78,3.25-3.36,5.58c0.9-2.26,3.57-3.66,5.95-3.12c-1.86,0.82-3.12,2.85-3.01,4.88c1.78-1.19,3.56-2.37,5.34-3.56c-0.97,1.73-1.35,2.01-1.69,6.45c1.73-3.35,2.78-4.22,5.19-4.69c0.57,1.96-1.19,3.86-1.78,5.79c2.08-0.93,3.68-2.88,4.18-5.1c1.78,1.2,2.53,3.72,1.69,5.7c1.66-0.62,2.47-2.85,1.6-4.39c1.99,0.09,3.66,2.14,3.34,4.1c1.45-0.67,2.05-2.72,1.18-4.07c2.07-0.16,4.17,0.99,5.17,2.81c0.74-1.39,0.93-3.38-0.64-3.55c2.25-0.38,3.41-0.58,4.86,1.13c0.14-1.21-0.42-2.47-2.07-2.53c2.66-1.67,3.57-0.82,4.95,0.4c-0.06-1.91-0.96-2.36-1.44-3.16c0.88-0.2,1.74,0.19,2.61,0.29C231.36,258.46,230.01,258.08,229.73,256.96z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M270.27,256.96c3.62,4.64,9.52,7.26,14.53,6.17c6.56-1.43,11.86-3.87,15.79-8.24c3.93-4.37,6.63-9.72,8.73-15.21c0.6-1.57,2.16-2.72,3.83-2.84s3.38,0.81,4.19,2.28c-2.5-1.86-6.63-0.75-7.85,2.13c1.77-2.24,5.86-1.85,7.18,0.69c-2.74-1.54-6.62-0.54-8.27,2.13c2.5-1.7,6.44-0.54,7.61,2.26c-2.45-1.54-5.68-1.77-8.32-0.6c2.71-0.88,5.97,0.62,7.06,3.25c-2.12-2.31-6.15-2.49-8.46-0.37c2.64-0.59,5.6,0.89,6.71,3.36c-2.13-2.25-6.02-2.5-8.42-0.55c2.7-0.55,5.67,1.29,6.37,3.95c-4.79-3.11-5.39-1.4-8.06-1.53c2.5-1.05,5.43,2.44,5.35,5.25c-1.34-2.71-5.17-3.8-7.73-2.21c2.26,0.72,3.78,3.25,3.36,5.58c-0.9-2.26-3.57-3.66-5.95-3.12c1.86,0.82,3.12,2.85,3.01,4.88c-1.78-1.19-3.56-2.37-5.34-3.56c0.97,1.73,1.35,2.01,1.69,6.45c-1.73-3.35-2.78-4.22-5.19-4.69c-0.57,1.96,1.19,3.86,1.78,5.79c-2.08-0.93-3.68-2.88-4.18-5.1c-1.78,1.2-2.53,3.72-1.69,5.7c-1.66-0.62-2.47-2.85-1.6-4.39c-1.99,0.09-3.66,2.14-3.34,4.1c-1.45-0.67-2.05-2.72-1.18-4.07c-2.07-0.16-4.17,0.99-5.17,2.81c-0.74-1.39-0.93-3.38,0.64-3.55c-2.25-0.38-3.41-0.58-4.86,1.13c-0.14-1.21,0.42-2.47,2.07-2.53c-2.66-1.67-3.57-0.82-4.95,0.4c0.06-1.91,0.96-2.36,1.44-3.16c-0.88-0.2-1.74,0.19-2.61,0.29C268.64,258.46,269.99,258.08,270.27,256.96z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.7,252.77c-5.25,5.38-5.4,6.85-11.39,8.13c-7.35,1.57-22.94,2.19-28.45-3.05c-2.3-2.18-6.74-0.49-7.93,2.45c2.47-1.35,5.52-1.58,8.18-0.64c-2.65-0.92-5.85,1.49-5.7,4.29c1.67-2.26,4.69-3.43,7.45-2.89c-1.36,0.07-2.64,1.03-3.07,2.33c-0.43,1.3,0.02,2.83,1.07,3.7c-0.32-2.65,2.19-5.26,4.85-5.05c-2.04,0.97-2.6,4.1-1.02,5.71c0.13-2.67,3.13-4.78,5.68-3.99c-1.19,0.25-2.19,1.28-2.39,2.49c-0.21,1.2,0.39,2.5,1.43,3.14c0.11-2.61,2.23-4.99,4.81-5.38c-1.56,1.5-1.17,4.49,0.71,5.54c-0.44-2.03,0.69-4.28,2.58-5.14c1.2,2.96,0.29,3.64,0.43,5.45c1.54-1.32,2.36-3.45,2.1-5.47c0.81,1.23,2.5,1.04,2.22,4.95c2.12-0.69,2.53-4.14,0.62-5.3c2.3,0.05,4.4,2.05,4.57,4.35c1.71-1.17,1.33-4.28-0.61-5.01c2.12-0.59,4.59,0.66,5.36,2.73c1.24-1.27,0.46-3.81-1.27-4.17c1.67-0.9,3.97-0.38,5.09,1.15c1.05-0.87,0.09-2.96-1.25-2.74c1.22-1.14,3.29-1.21,4.58-0.16c0.59-0.92-0.14-2.37-1.24-2.43c1.23-0.43,1.45-2.93,3.7-1.29c0.24-0.52-0.66-1.46-1.05-1.36C231.95,254.31,231.88,253.65,231.7,252.77z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.3,252.77c5.25,5.38,5.4,6.85,11.39,8.13c7.35,1.57,22.94,2.19,28.45-3.05c2.3-2.18,6.74-0.49,7.93,2.45c-2.47-1.35-5.52-1.58-8.18-0.64c2.65-0.92,5.85,1.49,5.7,4.29c-1.67-2.26-4.69-3.43-7.45-2.89c1.36,0.07,2.64,1.03,3.07,2.33c0.43,1.3-0.02,2.83-1.07,3.7c0.32-2.65-2.19-5.26-4.85-5.05c2.04,0.97,2.6,4.1,1.02,5.71c-0.13-2.67-3.13-4.78-5.68-3.99c1.19,0.25,2.19,1.28,2.39,2.49s-0.39,2.5-1.43,3.14c-0.11-2.61-2.23-4.99-4.81-5.38c1.56,1.5,1.17,4.49-0.71,5.54c0.44-2.03-0.69-4.28-2.58-5.14c-1.2,2.96-0.29,3.64-0.43,5.45c-1.54-1.32-2.36-3.45-2.1-5.47c-0.81,1.23-2.5,1.04-2.22,4.95c-2.12-0.69-2.53-4.14-0.62-5.3c-2.3,0.05-4.4,2.05-4.57,4.35c-1.71-1.17-1.33-4.28,0.61-5.01c-2.12-0.59-4.59,0.66-5.36,2.73c-1.24-1.27-0.46-3.81,1.27-4.17c-1.67-0.9-3.97-0.38-5.09,1.15c-1.05-0.87-0.09-2.96,1.25-2.74c-1.22-1.14-3.29-1.21-4.58-0.16c-0.59-0.92,0.14-2.37,1.24-2.43c-1.23-0.43-1.45-2.93-3.7-1.29c-0.24-0.52,0.66-1.46,1.05-1.36C268.05,254.31,268.12,253.65,268.3,252.77z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M230.43,259.26c1.98,0.14,3.81,2.55,3.34,4.48c-1.01-1.79-3.09-2.92-5.14-2.79c1.93,0.9,2.38,3.91,0.81,5.34c0.01-1.87-1.53-3.62-3.39-3.86c1.02,1.53,0.51,3.85-1.05,4.82c0.2-1.93-1.06-3.91-2.89-4.56c0.84,1.52,0.44,3.62-0.91,4.72c-0.29-1.71-1.29-3.29-2.7-4.28c0.46,1.58,0.11,3.37-0.91,4.65c-0.54-1.51-1.08-3.01-1.62-4.52c-0.4,1.64-0.8,3.27-1.2,4.91c-1.53-1.29-2.39-3.33-2.26-5.33c-1.1,1.19-1.63,2.88-1.41,4.49c-1.53-0.63-2.37-2.57-1.78-4.12c-1.02,1.34-1.72,2.93-2,4.6c-1.07-0.27-1.9-1.21-2.22-2.28c-0.31-1.06-0.16-2.22,0.24-3.26c-4.16,0.89-4.05,4.05-4.52,3.68c-1.83-1.44-0.04-3.91,0.81-4.49c-1.97-0.04-3.89,1.27-4.56,3.12c-1-0.42-1.67-1.53-1.56-2.62c0.11-1.08,0.99-2.04,2.06-2.24c-2.31-0.76-5.05,0.06-6.57,1.96c-0.9-1.17-0.71-3.02,0.4-3.99c1.11-0.97,2.97-0.91,4.01,0.14c-1.61-1.94-4.92-2.12-6.73-0.37c-0.38-1.17,0.13-2.57,1.17-3.23s2.53-0.51,3.42,0.34c-1.51-2.1-4.84-2.59-6.88-1c-0.21-2.04,2.1-3.9,4.05-3.24c-1.39-1.43-3.59-2.01-5.5-1.47c1.25-1.79,4.66-1.92,6.57-0.86c1.3,6.15,4.06,10.1,10.06,12.18c6,2.07,13.8,1.98,20.12,1.36C224.96,261.22,228.12,261.03,230.43,259.26z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269.57,259.26c-1.98,0.14-3.81,2.55-3.34,4.48c1.01-1.79,3.09-2.92,5.14-2.79c-1.93,0.9-2.38,3.91-0.81,5.34c-0.01-1.87,1.53-3.62,3.39-3.86c-1.02,1.53-0.51,3.85,1.05,4.82c-0.2-1.93,1.06-3.91,2.89-4.56c-0.84,1.52-0.44,3.62,0.91,4.72c0.29-1.71,1.29-3.29,2.7-4.28c-0.46,1.58-0.11,3.37,0.91,4.65c0.54-1.51,1.08-3.01,1.62-4.52c0.4,1.64,0.8,3.27,1.2,4.91c1.53-1.29,2.39-3.33,2.26-5.33c1.1,1.19,1.63,2.88,1.41,4.49c1.53-0.63,2.37-2.57,1.78-4.12c1.02,1.34,1.72,2.93,2,4.6c1.07-0.27,1.9-1.21,2.22-2.28s0.16-2.22-0.24-3.26c4.16,0.89,4.05,4.05,4.52,3.68c1.83-1.44,0.04-3.91-0.81-4.49c1.97-0.04,3.89,1.27,4.56,3.12c1-0.42,1.67-1.53,1.56-2.62c-0.11-1.08-0.99-2.04-2.06-2.24c2.31-0.76,5.05,0.06,6.57,1.96c0.9-1.17,0.71-3.02-0.4-3.99c-1.11-0.97-2.97-0.91-4.01,0.14c1.61-1.94,4.92-2.12,6.73-0.37c0.38-1.17-0.13-2.57-1.17-3.23c-1.04-0.66-2.53-0.51-3.42,0.34c1.51-2.1,4.84-2.59,6.88-1c0.21-2.04-2.1-3.9-4.05-3.24c1.39-1.43,3.59-2.01,5.5-1.47c-1.25-1.79-4.66-1.92-6.57-0.86c-1.3,6.15-4.06,10.1-10.06,12.18c-6,2.07-13.8,1.98-20.12,1.36C275.04,261.22,271.88,261.03,269.57,259.26z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M226.38,263.76c1.72,0.12,3.31,2.27,2.9,3.98c-0.87-1.59-2.68-2.59-4.46-2.48c1.67,0.8,2.07,3.47,0.71,4.74c0.01-1.66-1.33-3.22-2.95-3.42c0.88,1.36,0.44,3.42-0.92,4.27c0.17-1.71-0.92-3.47-2.51-4.04c0.73,1.35,0.38,3.21-0.79,4.19c-0.25-1.52-1.12-2.92-2.35-3.8c0.4,1.4,0.1,2.99-0.79,4.13c-0.47-1.34-0.94-2.68-1.41-4.01c-0.35,1.45-0.7,2.91-1.04,4.36c-1.33-1.15-2.08-2.96-1.96-4.73c-0.95,1.06-1.42,2.56-1.23,3.98c-1.33-0.56-2.06-2.28-1.55-3.65c-0.89,1.19-1.49,2.6-1.74,4.08c-0.93-0.24-1.65-1.08-1.93-2.02s-0.14-1.97,0.2-2.89c-3.61,0.79-3.52,3.59-3.92,3.26c-1.59-1.28-0.04-3.47,0.7-3.98c-1.71-0.04-3.38,1.13-3.96,2.77c-0.87-0.37-1.45-1.36-1.35-2.32c0.1-0.96,0.86-1.81,1.79-1.99c-2.01-0.68-4.39,0.05-5.71,1.74c-0.78-1.04-0.62-2.68,0.34-3.54s2.58-0.81,3.48,0.12c-1.4-1.72-4.27-1.88-5.85-0.32c-0.33-1.04,0.11-2.28,1.02-2.87c0.91-0.58,2.19-0.46,2.97,0.3c-1.31-1.87-4.2-2.3-5.98-0.88c-0.18-1.81,1.83-3.46,3.52-2.88c-1.21-1.27-3.12-1.79-4.78-1.3c1.08-1.59,4.05-1.7,5.71-0.77c1.13,5.46,3.53,9.47,8.74,11.31c5.22,1.84,11.99,1.26,17.48,0.71C221.62,265.5,224.37,265.33,226.38,263.76z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M273.62,263.76c-1.72,0.12-3.31,2.27-2.9,3.98c0.87-1.59,2.68-2.59,4.46-2.48c-1.67,0.8-2.07,3.47-0.71,4.74c-0.01-1.66,1.33-3.22,2.95-3.42c-0.88,1.36-0.44,3.42,0.92,4.27c-0.17-1.71,0.92-3.47,2.51-4.04c-0.73,1.35-0.38,3.21,0.79,4.19c0.25-1.52,1.12-2.92,2.35-3.8c-0.4,1.4-0.1,2.99,0.79,4.13c0.47-1.34,0.94-2.68,1.41-4.01c0.35,1.45,0.7,2.91,1.04,4.36c1.33-1.15,2.08-2.96,1.96-4.73c0.95,1.06,1.42,2.56,1.23,3.98c1.33-0.56,2.06-2.28,1.55-3.65c0.89,1.19,1.49,2.6,1.74,4.08c0.93-0.24,1.65-1.08,1.93-2.02c0.27-0.94,0.14-1.97-0.2-2.89c3.61,0.79,3.52,3.59,3.92,3.26c1.59-1.28,0.04-3.47-0.7-3.98c1.71-0.04,3.38,1.13,3.96,2.77c0.87-0.37,1.45-1.36,1.35-2.32c-0.1-0.96-0.86-1.81-1.79-1.99c2.01-0.68,4.39,0.05,5.71,1.74c0.78-1.04,0.62-2.68-0.34-3.54s-2.58-0.81-3.48,0.12c1.4-1.72,4.27-1.88,5.85-0.32c0.33-1.04-0.11-2.28-1.02-2.87s-2.19-0.46-2.97,0.3c1.31-1.87,4.2-2.3,5.98-0.88c0.18-1.81-1.83-3.46-3.52-2.88c1.21-1.27,3.12-1.79,4.78-1.3c-1.08-1.59-4.05-1.7-5.71-0.77c-1.13,5.46-3.53,9.47-8.74,11.31c-5.22,1.84-11.99,1.26-17.48,0.71C278.38,265.5,275.63,265.33,273.62,263.76z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M229.63,256.16c-3.7,7.22-12.41,11.57-20.5,10.25c-8.09-1.33-14.9-8.22-16.05-16.24c-2.85-1.39-6.67-0.36-8.41,2.26c2.03-1.61,5.16-1.69,7.26-0.18c-2.71-0.81-5.88,1.49-5.91,4.29c1.3-2.04,3.93-3.16,6.33-2.7c-2.82,0.48-5.13,3.05-5.27,5.87c1.62-1.98,3.51-2.93,5.69-4.04c-2.79,0.8-3.99,3.81-3.73,6.43c0.68-2.03,2.47-3.67,4.57-4.18c-2.88,1.47-3.88,5.64-1.99,8.23c-0.16-2.67,1.72-5.32,4.32-6.08c-2.74,1.62-3.61,5.67-1.77,8.24c-0.1-2.4,1.12-4.81,3.12-6.17c-2.19,2.04-2.38,5.82-0.4,8.06c0-3.85,1.18-4.09,1.77-6.13c-1.64,5.52-0.29,5.03,0.04,7.78c1.24-4.75,0.71-3.81,3.12-5.84c0.07-0.06,0.22-0.05,0.19,0.04c-1.04,2.48-2.47,3.06,0.22,7.51c-0.6-3.33,1.15-4.63,1.35-7.07c0,0-0.12,7,1.75,7.45c0.66,0.16-0.95-1.46,2.03-6.22c0.88-1.4,1.01,7.15,1.5,6.53c1.35-1.74,1.15-6.69,1.15-6.69l2.25,6.47c1.29-1.72,1.5-4.16,0.54-6.07c2.43,0.56,4.34,2.87,4.4,5.33c1.42-1.7,0.91-4.59-1-5.72c2.14-0.3,4.39,1.15,4.99,3.21c0.9-1.52,0.31-3.71-1.23-4.58c0,0,3.46,0.72,4.63,2.45c0.58,0.85-0.97-4.01-0.97-4.01s1.73,0.29,3.82,1.3c0.85,0.41,0.11-1.85-1.47-3.45c-0.62-0.62,2.49,0.08,3.77,0.91c0.69,0.45-0.5-1.84-1.77-2.75c0.91-0.92,2.8-0.31,2.99,0.96c0.53-1.08-0.73-2.52-1.89-2.15c0.5-0.99,2.04-1.27,2.87-0.52C231.94,257.59,230.14,256.63,229.63,256.16z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M270.37,256.16c3.7,7.22,12.41,11.57,20.5,10.25c8.09-1.33,14.9-8.22,16.05-16.24c2.85-1.39,6.67-0.36,8.41,2.26c-2.03-1.61-5.16-1.69-7.26-0.18c2.71-0.81,5.88,1.49,5.91,4.29c-1.3-2.04-3.93-3.16-6.33-2.7c2.82,0.48,5.13,3.05,5.27,5.87c-1.62-1.98-3.51-2.93-5.69-4.04c2.79,0.8,3.99,3.81,3.73,6.43c-0.68-2.03-2.47-3.67-4.57-4.18c2.88,1.47,3.88,5.64,1.99,8.23c0.16-2.67-1.72-5.32-4.32-6.08c2.74,1.62,3.61,5.67,1.77,8.24c0.1-2.4-1.12-4.81-3.12-6.17c2.19,2.04,2.38,5.82,0.4,8.06c0-3.85-1.18-4.09-1.77-6.13c1.64,5.52,0.29,5.03-0.04,7.78c-1.24-4.75-0.71-3.81-3.12-5.84c-0.07-0.06-0.22-0.05-0.19,0.04c1.04,2.48,2.47,3.06-0.22,7.51c0.6-3.33-1.15-4.63-1.35-7.07c0,0,0.12,7-1.75,7.45c-0.66,0.16,0.95-1.46-2.03-6.22c-0.88-1.4-1.01,7.15-1.5,6.53c-1.35-1.74-1.15-6.69-1.15-6.69l-2.25,6.47c-1.29-1.72-1.5-4.16-0.54-6.07c-2.43,0.56-4.34,2.87-4.4,5.33c-1.42-1.7-0.91-4.59,1-5.72c-2.14-0.3-4.39,1.15-4.99,3.21c-0.9-1.52-0.31-3.71,1.23-4.58c0,0-3.46,0.72-4.63,2.45c-0.58,0.85,0.97-4.01,0.97-4.01s-1.73,0.29-3.82,1.3c-0.85,0.41-0.11-1.85,1.47-3.45c0.62-0.62-2.49,0.08-3.77,0.91c-0.69,0.45,0.5-1.84,1.77-2.75c-0.91-0.92-2.8-0.31-2.99,0.96c-0.53-1.08,0.73-2.52,1.89-2.15c-0.5-0.99-2.04-1.27-2.87-0.52C268.06,257.59,269.86,256.63,270.37,256.16z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.68,251.97c-4.08,4.71-13.67,7.54-22.57,6.68c-8.9-0.86-16.41-5.36-17.67-10.59c-3.14-0.9-7.34-0.23-9.26,1.47c2.23-1.05,5.68-1.1,8-0.11c-2.99-0.53-6.48,0.97-6.51,2.8c1.43-1.33,4.33-2.06,6.97-1.76c-3.11,0.31-5.65,1.99-5.81,3.83c1.78-1.29,3.86-1.91,6.26-2.64c-3.08,0.52-4.39,2.48-4.11,4.19c0.75-1.33,2.72-2.39,5.04-2.73c-3.17,0.96-4.28,3.68-2.19,5.37c-0.18-1.74,1.9-3.47,4.75-3.97c-3.02,1.06-3.97,3.69-1.95,5.37c-0.11-1.56,1.23-3.14,3.44-4.02c-2.41,1.33-2.62,3.8-0.44,5.26c0-2.51,1.3-2.67,1.95-4c-1.81,3.6-0.32,3.28,0.05,5.07c1.37-3.1,0.79-2.48,3.43-3.81c0.08-0.04,0.24-0.03,0.2,0.02c-1.15,1.61-2.72,1.99,0.24,4.9c-0.66-2.17,1.27-3.02,1.49-4.61c0,0-0.13,4.56,1.92,4.86c0.72,0.1-1.04-0.95,2.24-4.06c0.97-0.92,1.12,4.66,1.65,4.26c1.49-1.14,1.27-4.36,1.27-4.36l2.48,4.22c1.42-1.12,1.66-2.71,0.6-3.96c2.68,0.37,4.77,1.87,4.85,3.48c1.56-1.11,1-3-1.1-3.73c2.36-0.19,4.84,0.75,5.49,2.09c0.99-0.99,0.34-2.42-1.36-2.99c0,0,3.81,0.47,5.1,1.6c0.64,0.56-1.06-2.61-1.06-2.61s1.91,0.19,4.2,0.84c0.94,0.27,0.13-1.21-1.62-2.25c-0.68-0.4,2.74,0.05,4.15,0.59c0.76,0.29-0.55-1.2-1.95-1.79c1-0.6,3.09-0.2,3.29,0.63c0.58-0.71-0.8-1.64-2.08-1.4c0.56-0.65,2.25-0.83,3.16-0.34C234.22,252.9,232.24,252.28,231.68,251.97z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.32,251.97c4.08,4.71,13.67,7.54,22.57,6.68s16.41-5.36,17.67-10.59c3.14-0.9,7.34-0.23,9.26,1.47c-2.23-1.05-5.68-1.1-8-0.11c2.99-0.53,6.48,0.97,6.51,2.8c-1.43-1.33-4.33-2.06-6.97-1.76c3.11,0.31,5.65,1.99,5.81,3.83c-1.78-1.29-3.86-1.91-6.26-2.64c3.08,0.52,4.39,2.48,4.11,4.19c-0.75-1.33-2.72-2.39-5.04-2.73c3.17,0.96,4.28,3.68,2.19,5.37c0.18-1.74-1.9-3.47-4.75-3.97c3.02,1.06,3.97,3.69,1.95,5.37c0.11-1.56-1.23-3.14-3.44-4.02c2.41,1.33,2.62,3.8,0.44,5.26c0-2.51-1.3-2.67-1.95-4c1.81,3.6,0.32,3.28-0.05,5.07c-1.37-3.1-0.79-2.48-3.43-3.81c-0.08-0.04-0.24-0.03-0.2,0.02c1.15,1.61,2.72,1.99-0.24,4.9c0.66-2.17-1.27-3.02-1.49-4.61c0,0,0.13,4.56-1.92,4.86c-0.72,0.1,1.04-0.95-2.24-4.06c-0.97-0.92-1.12,4.66-1.65,4.26c-1.49-1.14-1.27-4.36-1.27-4.36l-2.48,4.22c-1.42-1.12-1.66-2.71-0.6-3.96c-2.68,0.37-4.77,1.87-4.85,3.48c-1.56-1.11-1-3,1.1-3.73c-2.36-0.19-4.84,0.75-5.49,2.09c-0.99-0.99-0.34-2.42,1.36-2.99c0,0-3.81,0.47-5.1,1.6c-0.64,0.56,1.06-2.61,1.06-2.61s-1.91,0.19-4.2,0.84c-0.94,0.27-0.13-1.21,1.62-2.25c0.68-0.4-2.74,0.05-4.15,0.59c-0.76,0.29,0.55-1.2,1.95-1.79c-1-0.6-3.09-0.2-3.29,0.63c-0.58-0.71,0.8-1.64,2.08-1.4c-0.56-0.65-2.25-0.83-3.16-0.34C265.78,252.9,267.76,252.28,268.32,251.97z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.48,250.91c-4,4.23-13.4,6.78-22.13,6.01s-16.09-4.82-17.32-9.52c-3.07-0.81-7.2-0.21-9.07,1.33c2.19-0.94,5.57-0.99,7.84-0.1c-2.93-0.47-6.35,0.88-6.38,2.52c1.4-1.2,4.25-1.85,6.84-1.58c-3.05,0.28-5.54,1.79-5.69,3.44c1.74-1.16,3.78-1.72,6.14-2.37c-3.01,0.47-4.31,2.23-4.03,3.77c0.74-1.19,2.67-2.15,4.94-2.45c-3.11,0.86-4.19,3.3-2.15,4.83c-0.17-1.56,1.86-3.12,4.66-3.57c-2.96,0.95-3.89,3.32-1.91,4.83c-0.11-1.41,1.21-2.82,3.37-3.62c-2.36,1.2-2.56,3.41-0.43,4.73c0-2.26,1.27-2.4,1.91-3.59c-1.77,3.24-0.31,2.95,0.05,4.56c1.34-2.78,0.77-2.23,3.36-3.42c0.08-0.03,0.24-0.03,0.2,0.02c-1.13,1.45-2.67,1.79,0.24,4.4c-0.65-1.95,1.25-2.71,1.46-4.14c0,0-0.13,4.1,1.88,4.37c0.71,0.09-1.02-0.86,2.19-3.65c0.95-0.82,1.09,4.19,1.62,3.83c1.46-1.02,1.25-3.92,1.25-3.92l2.43,3.79c1.39-1.01,1.62-2.44,0.58-3.56c2.63,0.33,4.68,1.68,4.75,3.12c1.53-0.99,0.98-2.69-1.08-3.36c2.31-0.17,4.74,0.67,5.38,1.88c0.97-0.89,0.34-2.17-1.33-2.69c0,0,3.73,0.42,5,1.43c0.63,0.5-1.04-2.35-1.04-2.35s1.87,0.17,4.12,0.76c0.92,0.24,0.12-1.09-1.59-2.02c-0.66-0.36,2.69,0.05,4.07,0.53c0.74,0.26-0.54-1.08-1.91-1.61c0.98-0.54,3.03-0.18,3.22,0.56c0.57-0.64-0.79-1.48-2.04-1.26c0.54-0.58,2.2-0.74,3.1-0.3C233.97,251.75,232.03,251.19,231.48,250.91z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.52,250.91c4,4.23,13.4,6.78,22.13,6.01s16.09-4.82,17.32-9.52c3.07-0.81,7.2-0.21,9.07,1.33c-2.19-0.94-5.57-0.99-7.84-0.1c2.93-0.47,6.35,0.88,6.38,2.52c-1.4-1.2-4.25-1.85-6.84-1.58c3.05,0.28,5.54,1.79,5.69,3.44c-1.74-1.16-3.78-1.72-6.14-2.37c3.01,0.47,4.31,2.23,4.03,3.77c-0.74-1.19-2.67-2.15-4.94-2.45c3.11,0.86,4.19,3.3,2.15,4.83c0.17-1.56-1.86-3.12-4.66-3.57c2.96,0.95,3.89,3.32,1.91,4.83c0.11-1.41-1.21-2.82-3.37-3.62c2.36,1.2,2.56,3.41,0.43,4.73c0-2.26-1.27-2.4-1.91-3.59c1.77,3.24,0.31,2.95-0.05,4.56c-1.34-2.78-0.77-2.23-3.36-3.42c-0.08-0.03-0.24-0.03-0.2,0.02c1.13,1.45,2.67,1.79-0.24,4.4c0.65-1.95-1.25-2.71-1.46-4.14c0,0,0.13,4.1-1.88,4.37c-0.71,0.09,1.02-0.86-2.19-3.65c-0.95-0.82-1.09,4.19-1.62,3.83c-1.46-1.02-1.25-3.92-1.25-3.92l-2.43,3.79c-1.39-1.01-1.62-2.44-0.58-3.56c-2.63,0.33-4.68,1.68-4.75,3.12c-1.53-0.99-0.98-2.69,1.08-3.36c-2.31-0.17-4.74,0.67-5.38,1.88c-0.97-0.89-0.34-2.17,1.33-2.69c0,0-3.73,0.42-5,1.43c-0.63,0.5,1.04-2.35,1.04-2.35s-1.87,0.17-4.12,0.76c-0.92,0.24-0.12-1.09,1.59-2.02c0.66-0.36-2.69,0.05-4.07,0.53c-0.74,0.26,0.54-1.08,1.91-1.61c-0.98-0.54-3.03-0.18-3.22,0.56c-0.57-0.64,0.79-1.48,2.04-1.26c-0.54-0.58-2.2-0.74-3.1-0.3C266.03,251.75,267.97,251.19,268.52,250.91z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M230.49,253.43c-4.28,2.82-8.57,3.37-13.67,3.89c-5.1,0.51-10.27,0.01-15.28-1.04c-2.44-0.51-4.93-1.2-6.86-2.77c-1.55-1.26-2.68-3.04-4.43-4.02c-2.34-1.31-5.57-0.74-7.33,1.29c2.34-1.03,5.12-1,7.43,0.1c-2.42-0.95-5.5,0.72-6.04,3.26c1.94-1.62,4.56-2.38,7.06-2.06c-1.26-0.2-2.6,0.24-3.51,1.14c-0.9,0.9-1.34,2.25-1.13,3.51c0.21-1.16,1.14-2.1,2.2-2.62c1.06-0.52,2.25-0.7,3.42-0.88c-2.21,0.68-3.76,3.07-3.48,5.37c0.73-2.25,3.03-3.88,5.39-3.81c-2.25,0.37-3.76,3.11-2.86,5.21c0.63-2.08,2.51-3.74,4.66-4.1c-1.77,1.21-2.48,3.76-1.59,5.71c0.68-2.22,2.56-4.03,4.8-4.65c-1.49,1.5-1.93,3.93-1.06,5.85c-0.32-2.05,1.04-4.23,3.02-4.83c-1.02,1.53-1.11,3.63-0.2,5.23c0.18-0.99,0.36-2,0.85-2.88c0.49-0.88,1.34-1.62,2.34-1.71c-1.11,1.39-1,3.59,0.23,4.87c-0.41-1.9,0.63-4.01,2.38-4.84c-0.58,1.7-0.42,3.64,0.41,5.23c0.5-1.59,1.01-3.18,1.51-4.76c0.23,1.49,0.47,2.98,0.7,4.48c1-1.41,1.59-3.11,1.68-4.84c0.62,1.68,1.23,3.35,1.85,5.03c1.08-1.08,1.39-2.86,0.73-4.24c1.23,0.79,2.06,2.16,2.2,3.61c0.83-1.22,0.88-2.92,0.13-4.19c1.42,0.71,2.54,2.01,3.03,3.52c1-1.17,1.22-2.96,0.53-4.34c1.53,0.43,2.73,1.85,2.9,3.43c0.49-1.33,0.26-2.9-0.6-4.02c1.44,0.09,2.75,1.19,3.09,2.59c0.35-1.16,0.21-2.46-0.37-3.51c1.24,0.15,2.33,1.13,2.62,2.34c0.43-0.94,0.37-2.08-0.15-2.97c1-0.59,2.45-0.25,3.08,0.73c0.06-0.79-0.37-1.6-1.07-1.99c0.8-0.49,1.88-0.45,2.65,0.08C231.67,254.1,230.52,254.44,230.49,253.43z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269.51,253.43c4.28,2.82,8.57,3.37,13.67,3.89c5.1,0.51,10.27,0.01,15.28-1.04c2.44-0.51,4.92-1.2,6.86-2.77c1.55-1.26,2.68-3.04,4.43-4.02c2.34-1.31,5.57-0.74,7.33,1.29c-2.34-1.03-5.12-1-7.43,0.1c2.42-0.95,5.5,0.72,6.04,3.26c-1.94-1.62-4.56-2.38-7.06-2.06c1.26-0.2,2.6,0.24,3.51,1.14c0.9,0.9,1.34,2.25,1.13,3.51c-0.21-1.16-1.14-2.1-2.2-2.62c-1.06-0.52-2.25-0.7-3.42-0.88c2.21,0.68,3.76,3.07,3.48,5.37c-0.73-2.25-3.03-3.88-5.39-3.81c2.25,0.37,3.76,3.11,2.86,5.21c-0.63-2.08-2.51-3.74-4.66-4.1c1.77,1.21,2.48,3.76,1.59,5.71c-0.68-2.22-2.56-4.03-4.8-4.65c1.49,1.5,1.93,3.93,1.06,5.85c0.32-2.05-1.04-4.23-3.02-4.83c1.02,1.53,1.11,3.63,0.2,5.23c-0.18-0.99-0.36-2-0.85-2.88c-0.49-0.88-1.34-1.62-2.34-1.71c1.11,1.39,1,3.59-0.23,4.87c0.41-1.9-0.63-4.01-2.38-4.84c0.58,1.7,0.42,3.64-0.41,5.23c-0.5-1.59-1.01-3.18-1.51-4.76c-0.23,1.49-0.47,2.98-0.7,4.48c-1-1.41-1.59-3.11-1.68-4.84c-0.62,1.68-1.23,3.35-1.85,5.03c-1.08-1.08-1.39-2.86-0.73-4.24c-1.23,0.79-2.06,2.16-2.2,3.61c-0.83-1.22-0.88-2.92-0.13-4.19c-1.42,0.71-2.54,2.01-3.03,3.52c-1-1.17-1.22-2.96-0.53-4.34c-1.53,0.43-2.73,1.85-2.9,3.43c-0.49-1.33-0.26-2.9,0.6-4.02c-1.44,0.09-2.75,1.19-3.09,2.59c-0.35-1.16-0.21-2.46,0.38-3.51c-1.24,0.15-2.33,1.13-2.62,2.34c-0.43-0.94-0.37-2.08,0.15-2.97c-1-0.59-2.45-0.25-3.08,0.73c-0.06-0.79,0.37-1.6,1.07-1.99c-0.8-0.49-1.88-0.45-2.65,0.08C268.33,254.1,269.48,254.44,269.51,253.43z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M183.18,247.27c-0.05-1.07,0.88-1.97,1.87-2.47c1.1-0.56,2.42-0.81,3.58-0.42c1.43,0.47,3.13,3.3,8.21,6.78c3.74,2.56,7.15,4.44,11.58,5.04c4.43,0.6,8.93,0.75,13.34,0.02c3.95-0.66,8.01-1.71,11.06-4.38c0.21-0.18,0.88,0.13,1.27,0.56c-0.71,0.08-1.42,0.16-2.12,0.24c0.73-0.06,1.47,0.43,1.73,1.14c-0.98-0.42-2.15-0.36-3.08,0.16c0.69,0.29,1.28,0.81,1.68,1.46c-0.79-0.86-2.11-1.15-3.16-0.69c0.8,0.38,1.38,1.21,1.47,2.11c-0.62-1.02-1.76-1.68-2.92-1.69c1.01,0.45,1.67,1.63,1.53,2.75c-0.7-1.14-1.98-1.87-3.28-1.9c1.09,0.51,1.64,1.99,1.17,3.12c-0.62-1.46-2.01-2.54-3.53-2.77c1.26,0.53,2.08,2.01,1.88,3.4c-0.47-1.38-1.63-2.48-3-2.85c1.01,0.65,1.32,2.22,0.65,3.23c-0.42-1.42-1.45-2.63-2.76-3.25c0.91,0.99,1.16,2.57,0.6,3.81c-0.16-1.46-1.2-2.77-2.54-3.23c0.99,1.21,1.1,3.09,0.26,4.4c0-1.59-0.8-3.16-2.06-4.07c0.63,1.72,0.28,3.78-0.87,5.17c0.05-1.74-0.37-3.49-1.19-5.02c0.2,1.67-0.21,3.4-1.12,4.79c0.42-1.68,0.1-3.54-0.85-4.97c-0.27,1.65-0.54,3.3-0.81,4.94c-0.47-1.49-0.6-3.1-0.36-4.65c-1.19,1.35-1.95,3.09-2.14,4.91c-0.83-1.59-0.87-3.59-0.11-5.2c-1.4,1.02-2.42,2.59-2.81,4.32c-0.53-1.6-0.28-3.45,0.65-4.82c-1.69,1.1-2.93,2.93-3.34,4.96c-1-1.7-0.84-4.04,0.37-5.56c-1.59,0.94-2.55,2.89-2.37,4.78c-1.02-1.58-0.89-3.85,0.3-5.28c-1.95,0.83-3.38,2.83-3.59,5.01c-1.19-1.96-0.2-4.92,1.9-5.7c-2.07,0.21-3.84,2.16-3.91,4.31c-1.02-1.9-0.18-4.61,1.72-5.53c-1.98,0.87-3.48,2.82-3.86,5.02c-0.69-0.99-0.9-2.32-0.55-3.49c0.35-1.16,1.25-2.13,2.36-2.54c-2.01,0.29-3.86,1.62-4.82,3.47c-0.2-2.32,1.5-4.65,3.71-5.08c-2.4,0.26-4.74,1.1-6.78,2.42c0.67-2.21,2.96-3.76,5.19-3.51c-2.32-0.69-4.92-0.31-6.95,1.01c0.8-2.06,3.36-3.16,5.34-2.3c-2.08-1.1-4.72-0.96-6.68,0.35c0.55-1.4,2.06-2.22,3.52-2.24c0.39-0.01,0.78,0.04,1.17,0.13c-0.84-0.61-1.75-1.12-2.77-1.34C185.61,245.81,184,246.17,183.18,247.27z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M316.82,247.27c0.05-1.07-0.88-1.97-1.87-2.47c-1.1-0.56-2.42-0.81-3.58-0.42c-1.43,0.47-3.13,3.3-8.21,6.78c-3.74,2.56-7.15,4.44-11.58,5.04c-4.43,0.6-8.93,0.75-13.34,0.02c-3.95-0.66-8.01-1.71-11.06-4.38c-0.21-0.18-0.88,0.13-1.27,0.56c0.71,0.08,1.42,0.16,2.12,0.24c-0.73-0.06-1.47,0.43-1.73,1.14c0.98-0.42,2.15-0.36,3.08,0.16c-0.69,0.29-1.28,0.81-1.68,1.46c0.79-0.86,2.11-1.15,3.16-0.69c-0.8,0.38-1.38,1.21-1.47,2.11c0.62-1.02,1.76-1.68,2.92-1.69c-1.01,0.45-1.67,1.63-1.53,2.75c0.7-1.14,1.98-1.87,3.28-1.9c-1.09,0.51-1.64,1.99-1.17,3.12c0.62-1.46,2.01-2.54,3.53-2.77c-1.26,0.53-2.08,2.01-1.88,3.4c0.47-1.38,1.63-2.48,3-2.85c-1.01,0.65-1.32,2.22-0.65,3.23c0.42-1.42,1.45-2.63,2.76-3.25c-0.91,0.99-1.16,2.57-0.6,3.81c0.16-1.46,1.2-2.77,2.54-3.23c-0.99,1.21-1.1,3.09-0.26,4.4c0-1.59,0.8-3.16,2.06-4.07c-0.63,1.72-0.28,3.78,0.87,5.17c-0.05-1.74,0.37-3.49,1.19-5.02c-0.2,1.67,0.21,3.4,1.12,4.79c-0.42-1.68-0.1-3.54,0.85-4.97c0.27,1.65,0.54,3.3,0.81,4.94c0.47-1.49,0.6-3.1,0.36-4.65c1.19,1.35,1.95,3.09,2.14,4.91c0.83-1.59,0.87-3.59,0.11-5.2c1.4,1.02,2.42,2.59,2.81,4.32c0.53-1.6,0.28-3.45-0.65-4.82c1.69,1.1,2.93,2.93,3.34,4.96c1-1.7,0.84-4.04-0.37-5.56c1.59,0.94,2.55,2.89,2.37,4.78c1.02-1.58,0.89-3.85-0.3-5.28c1.95,0.83,3.38,2.83,3.58,5.01c1.19-1.96,0.2-4.92-1.9-5.7c2.07,0.21,3.84,2.16,3.91,4.31c1.02-1.9,0.18-4.61-1.72-5.53c1.98,0.87,3.48,2.82,3.86,5.02c0.69-0.99,0.9-2.32,0.55-3.49c-0.35-1.16-1.25-2.13-2.36-2.54c2.01,0.29,3.86,1.62,4.82,3.47c0.2-2.32-1.5-4.65-3.71-5.08c2.4,0.26,4.74,1.1,6.78,2.42c-0.67-2.21-2.96-3.76-5.19-3.51c2.32-0.69,4.92-0.31,6.95,1.01c-0.8-2.06-3.36-3.16-5.34-2.3c2.08-1.1,4.72-0.96,6.68,0.35c-0.55-1.4-2.06-2.22-3.52-2.24c-0.39-0.01-0.78,0.04-1.17,0.13c0.84-0.61,1.75-1.12,2.77-1.34C314.39,245.81,316,246.17,316.82,247.27z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M182.87,245.28c0.02-1.12,1-1.99,2.02-2.45c1.13-0.51,2.46-0.68,3.6-0.21c1.4,0.59,2.24,2.01,2.94,3.35c0.27,0.51,0.53,1.04,0.84,1.54c1.14,0.96,2.12,2.12,3.21,3.15c3.34,3.19,7.61,5.13,11.99,6.04c4.38,0.91,8.9,0.85,13.35,0.38c3.98-0.42,8.11-1.25,11.32-3.82c0.22-0.18,0.87,0.2,1.24,0.66c-0.71,0.04-1.42,0.07-2.13,0.11c0.73-0.02,1.44,0.54,1.65,1.3c-0.95-0.5-2.12-0.52-3.08-0.04c0.67,0.35,1.23,0.92,1.59,1.62c-0.73-0.95-2.03-1.34-3.11-0.93c0.77,0.45,1.3,1.35,1.34,2.29c-0.56-1.1-1.65-1.85-2.81-1.95c0.98,0.53,1.56,1.8,1.36,2.96c-0.63-1.23-1.86-2.08-3.16-2.19c1.06,0.6,1.51,2.18,0.97,3.32c-0.53-1.56-1.84-2.77-3.35-3.11c1.22,0.63,1.95,2.22,1.67,3.65c-0.38-1.46-1.47-2.68-2.81-3.16c0.97,0.74,1.18,2.39,0.44,3.39c-0.33-1.5-1.28-2.83-2.55-3.55c0.85,1.09,1,2.75,0.36,4c-0.06-1.52-1.02-2.96-2.34-3.52c0.91,1.32,0.9,3.28-0.01,4.59c0.1-1.65-0.6-3.34-1.8-4.36c0.52,1.83,0.05,3.95-1.19,5.32c0.16-1.8-0.15-3.65-0.87-5.29c0.09,1.75-0.42,3.52-1.41,4.9c0.52-1.71,0.32-3.67-0.53-5.22c-0.37,1.69-0.75,3.39-1.12,5.08c-0.38-1.58-0.4-3.26-0.07-4.86c-1.27,1.32-2.14,3.08-2.45,4.96c-0.73-1.7-0.64-3.78,0.22-5.41c-1.46,0.97-2.58,2.53-3.08,4.3c-0.43-1.69-0.06-3.6,0.95-4.97c-1.76,1.03-3.11,2.85-3.65,4.93c-0.89-1.83-0.59-4.25,0.72-5.75c-1.64,0.88-2.73,2.84-2.66,4.81c-0.92-1.71-0.64-4.06,0.63-5.47c-2,0.74-3.55,2.72-3.89,4.97c-1.06-2.11,0.11-5.13,2.25-5.79c-2.08,0.08-3.97,1.99-4.18,4.22c-0.9-2.05,0.11-4.8,2.06-5.63c-2.03,0.77-3.65,2.7-4.17,4.96c-0.63-1.08-0.75-2.47-0.33-3.66c0.42-1.19,1.38-2.13,2.52-2.48c-2.03,0.17-3.95,1.43-5.03,3.29c-0.05-2.42,1.79-4.73,4.02-5.03c-2.41,0.11-4.8,0.83-6.92,2.07c0.8-2.26,3.19-3.72,5.41-3.3c-2.27-0.87-4.89-0.65-7,0.6c0.93-2.09,3.55-3.06,5.48-2.04c-2.01-1.28-4.65-1.31-6.69-0.08c0.64-1.42,2.19-2.17,3.65-2.09c0.39,0.02,0.78,0.09,1.16,0.21c-0.8-0.69-1.68-1.28-2.68-1.57C185.39,243.92,183.77,244.19,182.87,245.28z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M317.13,245.28c-0.02-1.12-1-1.99-2.02-2.45c-1.13-0.51-2.46-0.68-3.6-0.21c-1.4,0.59-2.24,2.01-2.94,3.35c-0.27,0.51-0.53,1.04-0.84,1.54c-1.14,0.96-2.12,2.12-3.21,3.15c-3.34,3.19-7.61,5.13-11.99,6.04c-4.38,0.91-8.9,0.85-13.35,0.38c-3.98-0.42-8.11-1.25-11.32-3.82c-0.22-0.18-0.87,0.2-1.24,0.66c0.71,0.04,1.42,0.07,2.13,0.11c-0.73-0.02-1.44,0.54-1.65,1.3c0.95-0.5,2.12-0.52,3.08-0.04c-0.67,0.35-1.23,0.92-1.59,1.62c0.73-0.95,2.03-1.34,3.11-0.93c-0.77,0.45-1.3,1.35-1.34,2.29c0.56-1.1,1.65-1.85,2.81-1.95c-0.98,0.53-1.56,1.8-1.36,2.96c0.63-1.23,1.86-2.08,3.16-2.19c-1.06,0.6-1.51,2.18-0.97,3.32c0.53-1.56,1.84-2.77,3.35-3.11c-1.22,0.63-1.95,2.22-1.67,3.65c0.38-1.46,1.47-2.68,2.81-3.16c-0.97,0.74-1.18,2.39-0.44,3.39c0.33-1.5,1.28-2.83,2.55-3.55c-0.85,1.09-1,2.75-0.36,4c0.06-1.52,1.02-2.96,2.34-3.52c-0.91,1.32-0.9,3.28,0.01,4.59c-0.1-1.65,0.6-3.34,1.8-4.36c-0.52,1.83-0.05,3.95,1.19,5.32c-0.16-1.8,0.15-3.65,0.87-5.29c-0.09,1.75,0.42,3.52,1.41,4.9c-0.52-1.71-0.32-3.67,0.53-5.22c0.37,1.69,0.75,3.39,1.12,5.08c0.38-1.58,0.4-3.26,0.07-4.86c1.27,1.32,2.14,3.08,2.45,4.96c0.73-1.7,0.64-3.78-0.22-5.41c1.46,0.97,2.58,2.53,3.08,4.3c0.43-1.69,0.06-3.6-0.95-4.97c1.76,1.03,3.11,2.85,3.65,4.93c0.89-1.83,0.59-4.25-0.72-5.75c1.64,0.88,2.73,2.84,2.66,4.81c0.92-1.71,0.64-4.06-0.63-5.47c2,0.74,3.55,2.72,3.89,4.97c1.06-2.11-0.11-5.13-2.25-5.79c2.08,0.08,3.97,1.99,4.18,4.22c0.9-2.05-0.11-4.8-2.06-5.63c2.03,0.77,3.65,2.7,4.17,4.96c0.63-1.08,0.75-2.47,0.33-3.66c-0.42-1.19-1.38-2.13-2.52-2.48c2.03,0.17,3.95,1.43,5.03,3.29c0.05-2.42-1.79-4.73-4.02-5.03c2.41,0.11,4.8,0.83,6.92,2.07c-0.8-2.26-3.19-3.72-5.41-3.3c2.27-0.87,4.89-0.65,7,0.6c-0.93-2.09-3.55-3.06-5.48-2.04c2.01-1.28,4.65-1.31,6.69-0.08c-0.64-1.42-2.19-2.17-3.65-2.09c-0.39,0.02-0.78,0.09-1.16,0.21c0.8-0.69,1.68-1.28,2.68-1.57C314.61,243.92,316.23,244.19,317.13,245.28z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M184.64,250.55c-0.13-0.96,0.67-1.85,1.55-2.39c0.98-0.6,2.2-0.94,3.32-0.69c1.38,0.3,3.19,2.69,8.22,5.38c3.7,1.98,7.05,3.37,11.24,3.52c4.2,0.15,8.43-0.1,12.49-1.14c3.65-0.94,7.37-2.23,10.01-4.9c0.18-0.18,0.84,0.04,1.24,0.39c-0.66,0.13-1.31,0.26-1.97,0.4c0.68-0.12,1.41,0.26,1.71,0.88c-0.95-0.29-2.04-0.14-2.87,0.41c0.67,0.2,1.27,0.62,1.69,1.16c-0.8-0.71-2.06-0.85-3.01-0.35c0.78,0.27,1.39,0.97,1.55,1.77c-0.66-0.86-1.78-1.35-2.87-1.27c0.98,0.31,1.69,1.32,1.65,2.34c-0.75-0.96-2-1.51-3.22-1.42c1.06,0.36,1.7,1.65,1.34,2.71c-0.7-1.26-2.08-2.11-3.52-2.18c1.22,0.37,2.11,1.62,2.03,2.89c-0.55-1.2-1.72-2.09-3.03-2.31c1,0.5,1.42,1.88,0.86,2.85c-0.51-1.24-1.57-2.24-2.84-2.68c0.93,0.82,1.29,2.21,0.87,3.37c-0.26-1.3-1.34-2.39-2.64-2.68c1.02,1,1.27,2.68,0.59,3.93c-0.12-1.43-1-2.78-2.25-3.48c0.73,1.49,0.56,3.37-0.41,4.73c-0.09-1.57-0.62-3.11-1.51-4.41c0.32,1.48,0.07,3.08-0.67,4.4c0.26-1.54-0.18-3.19-1.18-4.39c-0.12,1.51-0.24,3.01-0.37,4.52c-0.56-1.3-0.8-2.74-0.7-4.15c-1.01,1.32-1.58,2.95-1.62,4.6c-0.9-1.36-1.1-3.15-0.51-4.67c-1.23,1.04-2.06,2.54-2.29,4.13c-0.62-1.39-0.54-3.07,0.23-4.39c-1.5,1.14-2.51,2.89-2.74,4.75c-1.07-1.44-1.11-3.56-0.09-5.03c-1.41,0.99-2.16,2.82-1.84,4.51c-1.08-1.33-1.14-3.38-0.13-4.78c-1.76,0.92-2.94,2.84-2.96,4.82c-1.26-1.66-0.58-4.41,1.33-5.29c-1.93,0.37-3.43,2.27-3.32,4.22c-1.11-1.62-0.53-4.13,1.17-5.12c-1.79,0.95-3.04,2.84-3.22,4.85c-0.73-0.83-1.03-2.01-0.79-3.09c0.24-1.08,1-2.03,2.01-2.49c-1.86,0.44-3.49,1.79-4.24,3.54c-0.37-2.07,1.04-4.31,3.07-4.89c-2.23,0.44-4.35,1.4-6.16,2.77c0.45-2.05,2.48-3.64,4.59-3.6c-2.23-0.42-4.63,0.15-6.43,1.52c0.59-1.92,2.9-3.13,4.82-2.54c-2.04-0.81-4.5-0.45-6.23,0.9c0.4-1.31,1.75-2.17,3.12-2.32c0.37-0.04,0.74-0.03,1.1,0.01c-0.83-0.48-1.73-0.86-2.7-0.96C186.8,249.03,185.33,249.49,184.64,250.55z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M315.36,250.55c0.13-0.96-0.67-1.85-1.55-2.39c-0.98-0.6-2.2-0.94-3.32-0.69c-1.38,0.3-3.19,2.69-8.22,5.38c-3.7,1.98-7.05,3.37-11.24,3.52c-4.2,0.15-8.43-0.1-12.49-1.14c-3.65-0.94-7.37-2.23-10.01-4.9c-0.18-0.18-0.84,0.04-1.24,0.39c0.66,0.13,1.31,0.26,1.97,0.4c-0.68-0.12-1.41,0.26-1.71,0.88c0.95-0.29,2.04-0.14,2.87,0.41c-0.67,0.2-1.27,0.62-1.69,1.16c0.8-0.71,2.06-0.85,3.01-0.35c-0.78,0.27-1.39,0.97-1.55,1.77c0.66-0.86,1.78-1.35,2.87-1.27c-0.98,0.31-1.69,1.32-1.65,2.34c0.75-0.96,2-1.51,3.22-1.42c-1.06,0.36-1.7,1.65-1.34,2.71c0.7-1.26,2.08-2.11,3.52-2.18c-1.22,0.37-2.11,1.62-2.03,2.89c0.55-1.2,1.72-2.09,3.03-2.31c-1,0.5-1.42,1.88-0.86,2.85c0.51-1.24,1.57-2.24,2.84-2.68c-0.93,0.82-1.29,2.21-0.87,3.37c0.26-1.3,1.34-2.39,2.64-2.68c-1.02,1-1.27,2.68-0.59,3.93c0.12-1.43,1-2.78,2.25-3.48c-0.73,1.49-0.56,3.37,0.41,4.73c0.09-1.57,0.62-3.11,1.51-4.41c-0.32,1.48-0.07,3.08,0.67,4.4c-0.26-1.54,0.18-3.19,1.18-4.39c0.12,1.51,0.24,3.01,0.37,4.52c0.56-1.3,0.8-2.74,0.7-4.15c1.01,1.32,1.58,2.95,1.62,4.6c0.9-1.36,1.1-3.15,0.51-4.67c1.23,1.04,2.06,2.54,2.29,4.13c0.62-1.39,0.54-3.07-0.23-4.39c1.5,1.14,2.51,2.89,2.74,4.75c1.07-1.44,1.11-3.56,0.09-5.03c1.41,0.99,2.16,2.82,1.84,4.51c1.08-1.33,1.14-3.38,0.13-4.78c1.76,0.92,2.94,2.84,2.96,4.82c1.26-1.66,0.58-4.41-1.33-5.29c1.93,0.37,3.43,2.27,3.32,4.22c1.11-1.62,0.53-4.13-1.17-5.12c1.79,0.95,3.04,2.84,3.22,4.85c0.73-0.83,1.03-2.01,0.79-3.09c-0.24-1.08-1-2.03-2.01-2.49c1.86,0.44,3.49,1.79,4.24,3.54c0.37-2.07-1.04-4.31-3.07-4.89c2.23,0.44,4.35,1.4,6.16,2.77c-0.45-2.05-2.48-3.64-4.59-3.6c2.23-0.42,4.63,0.15,6.43,1.52c-0.59-1.92-2.9-3.13-4.82-2.54c2.04-0.81,4.5-0.45,6.23,0.9c-0.4-1.31-1.75-2.17-3.12-2.32c-0.37-0.04-0.74-0.03-1.1,0.01c0.83-0.48,1.73-0.86,2.7-0.96C313.2,249.03,314.67,249.49,315.36,250.55z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_fa92j = {
  id: "eyelash_shape_fa92j",
  name: "Eyelash 5",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.96,249.44c-5.62-3.06-13.17-2.08-17.81,2.33c5.22-3.05,11.79-3.67,17.48-1.65c-2.5-0.74-5.12-1.33-7.7-0.93c-2.57,0.4-5.11,2.01-5.93,4.48c3.74-3.14,9.1-4.22,13.76-2.79c-3.01-0.71-6.41,0.86-7.81,3.62c2.53-1.84,5.65-2.86,8.78-2.87C194.41,250.91,194.08,250.16,193.96,249.44z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.04,249.44c5.62-3.06,13.17-2.08,17.81,2.33c-5.22-3.05-11.79-3.67-17.48-1.65c2.5-0.74,5.12-1.33,7.7-0.93c2.57,0.4,5.11,2.01,5.93,4.48c-3.74-3.14-9.1-4.22-13.76-2.79c3.01-0.71,6.41,0.86,7.81,3.62c-2.53-1.84-5.65-2.86-8.78-2.87C305.59,250.91,305.92,250.16,306.04,249.44z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.71,249.69c-5.62-3.06-13.17-2.08-17.81,2.33c5.22-3.05,11.79-3.67,17.48-1.65c-2.5-0.74-5.12-1.33-7.7-0.93c-2.57,0.4-5.11,2.01-5.93,4.48c3.74-3.14,9.1-4.22,13.76-2.79c-3.01-0.71-6.41,0.86-7.81,3.62c2.53-1.84,5.65-2.86,8.78-2.87C192.16,251.16,191.83,250.41,191.71,249.69z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.29,249.69c5.62-3.06,13.17-2.08,17.81,2.33c-5.22-3.05-11.79-3.67-17.48-1.65c2.5-0.74,5.12-1.33,7.7-0.93c2.57,0.4,5.11,2.01,5.93,4.48c-3.74-3.14-9.1-4.22-13.76-2.79c3.01-0.71,6.41,0.86,7.81,3.62c-2.53-1.84-5.65-2.86-8.78-2.87C307.84,251.16,308.17,250.41,308.29,249.69z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.12,237.61c-2.15-2.49-12.52-4.57-17.92-1.14c5.71-1.98,12.27-1.32,17.47,1.75c-2.31-1.2-4.77-2.29-7.37-2.4c-2.6-0.1-5.4,0.98-6.68,3.25c4.27-2.36,9.74-2.39,14.04-0.08c-2.82-1.28-6.46-0.39-8.36,2.05c2.84-1.31,5.36-2.95,9.17-1.12C192.2,240.28,191.6,238.17,191.12,237.61z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.88,237.61c2.15-2.49,12.52-4.57,17.92-1.14c-5.71-1.98-12.27-1.32-17.47,1.75c2.31-1.2,4.77-2.29,7.37-2.4s5.4,0.98,6.68,3.25c-4.27-2.36-9.74-2.39-14.04-0.08c2.82-1.28,6.46-0.39,8.36,2.05c-2.84-1.31-5.36-2.95-9.17-1.12C307.8,240.28,308.4,238.17,308.88,237.61z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.38,257.97c-6.37-0.6-12.92,3.28-15.45,9.16c3.6-4.85,9.39-8.01,15.42-8.4c-2.59,0.31-5.23,0.79-7.44,2.18c-2.21,1.38-3.9,3.86-3.69,6.45c2.2-4.35,6.7-7.46,11.55-7.98c-3.05,0.53-5.55,3.32-5.75,6.41c1.6-2.69,4.07-4.85,6.94-6.09C193.36,259.14,192.77,258.59,192.38,257.97z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.62,257.97c6.37-0.6,12.92,3.28,15.45,9.16c-3.6-4.85-9.39-8.01-15.42-8.4c2.59,0.31,5.23,0.79,7.44,2.18c2.21,1.38,3.9,3.86,3.69,6.45c-2.2-4.35-6.7-7.46-11.55-7.98c3.05,0.53,5.55,3.32,5.75,6.41c-1.6-2.69-4.07-4.85-6.94-6.09C306.64,259.14,307.23,258.59,307.62,257.97z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.96,248.19c-5.62-3.06-13.17-2.08-17.81,2.33c5.22-3.05,11.79-3.67,17.48-1.65c-2.5-0.74-5.12-1.33-7.7-0.93c-2.57,0.4-5.11,2.01-5.93,4.48c3.74-3.14,9.1-4.22,13.76-2.79c-3.01-0.71-6.41,0.86-7.81,3.62c2.53-1.84,5.65-2.86,8.78-2.87C191.41,249.66,191.08,248.91,190.96,248.19z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.04,248.19c5.62-3.06,13.17-2.08,17.81,2.33c-5.22-3.05-11.79-3.67-17.48-1.65c2.5-0.74,5.12-1.33,7.7-0.93c2.57,0.4,5.11,2.01,5.93,4.48c-3.74-3.14-9.1-4.22-13.76-2.79c3.01-0.71,6.41,0.86,7.81,3.62c-2.53-1.84-5.65-2.86-8.78-2.87C308.59,249.66,308.92,248.91,309.04,248.19z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.46,255.19c-5.62-3.06-13.17-2.08-17.81,2.33c5.22-3.05,11.79-3.67,17.48-1.65c-2.5-0.74-5.12-1.33-7.7-0.93c-2.57,0.4-5.11,2.01-5.93,4.48c3.74-3.14,9.1-4.22,13.76-2.79c-3.01-0.71-6.41,0.86-7.81,3.62c2.53-1.84,5.65-2.86,8.78-2.87C192.91,256.66,192.58,255.91,192.46,255.19z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.54,255.19c5.62-3.06,13.17-2.08,17.81,2.33c-5.22-3.05-11.79-3.67-17.48-1.65c2.5-0.74,5.12-1.33,7.7-0.93c2.57,0.4,5.11,2.01,5.93,4.48c-3.74-3.14-9.1-4.22-13.76-2.79c3.01-0.71,6.41,0.86,7.81,3.62c-2.53-1.84-5.65-2.86-8.78-2.87C307.09,256.66,307.42,255.91,307.54,255.19z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.21,248.94c-5.62-3.06-13.17-2.08-17.81,2.33c5.22-3.05,11.79-3.67,17.48-1.65c-2.5-0.74-5.12-1.33-7.7-0.93c-2.57,0.4-5.11,2.01-5.93,4.48c3.74-3.14,9.1-4.22,13.76-2.79c-3.01-0.71-6.41,0.86-7.81,3.62c2.53-1.84,5.65-2.86,8.78-2.87C192.66,250.41,192.33,249.66,192.21,248.94z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.79,248.94c5.62-3.06,13.17-2.08,17.81,2.33c-5.22-3.05-11.79-3.67-17.48-1.65c2.5-0.74,5.12-1.33,7.7-0.93c2.57,0.4,5.11,2.01,5.93,4.48c-3.74-3.14-9.1-4.22-13.76-2.79c3.01-0.71,6.41,0.86,7.81,3.62c-2.53-1.84-5.65-2.86-8.78-2.87C307.34,250.41,307.67,249.66,307.79,248.94z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.46,248.69c-5.62-3.06-13.17-2.08-17.81,2.33c5.22-3.05,11.79-3.67,17.48-1.65c-2.5-0.74-5.12-1.33-7.7-0.93c-2.57,0.4-5.11,2.01-5.93,4.48c3.74-3.14,9.1-4.22,13.76-2.79c-3.01-0.71-6.41,0.86-7.81,3.62c2.53-1.84,5.65-2.86,8.78-2.87C191.91,250.16,191.58,249.41,191.46,248.69z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.54,248.69c5.62-3.06,13.17-2.08,17.81,2.33c-5.22-3.05-11.79-3.67-17.48-1.65c2.5-0.74,5.12-1.33,7.7-0.93c2.57,0.4,5.11,2.01,5.93,4.48c-3.74-3.14-9.1-4.22-13.76-2.79c3.01-0.71,6.41,0.86,7.81,3.62c-2.53-1.84-5.65-2.86-8.78-2.87C308.09,250.16,308.42,249.41,308.54,248.69z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.89,248.25c-6-2.24-13.33-0.19-17.3,4.83c4.73-3.75,11.15-5.3,17.07-4.11c-2.58-0.37-5.26-0.59-7.75,0.17c-2.49,0.76-4.77,2.71-5.23,5.27c3.26-3.63,8.41-5.47,13.23-4.71c-3.08-0.28-6.23,1.76-7.22,4.69c2.25-2.18,5.19-3.63,8.29-4.08C192.55,249.64,192.11,248.95,191.89,248.25z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.11,248.25c6-2.24,13.33-0.19,17.3,4.83c-4.73-3.75-11.15-5.3-17.07-4.11c2.58-0.37,5.26-0.59,7.75,0.17c2.49,0.76,4.77,2.71,5.23,5.27c-3.26-3.63-8.41-5.47-13.23-4.71c3.08-0.28,6.23,1.76,7.22,4.69c-2.25-2.18-5.19-3.63-8.29-4.08C307.45,249.64,307.89,248.95,308.11,248.25z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.39,250.25c-6-2.24-13.33-0.19-17.3,4.83c4.73-3.75,11.15-5.3,17.07-4.11c-2.58-0.37-5.26-0.59-7.75,0.17c-2.49,0.76-4.77,2.71-5.23,5.27c3.26-3.63,8.41-5.47,13.23-4.71c-3.08-0.28-6.23,1.76-7.22,4.69c2.25-2.18,5.19-3.63,8.29-4.08C192.05,251.64,191.61,250.95,191.39,250.25z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.61,250.25c6-2.24,13.33-0.19,17.3,4.83c-4.73-3.75-11.15-5.3-17.07-4.11c2.58-0.37,5.26-0.59,7.75,0.17c2.49,0.76,4.77,2.71,5.23,5.27c-3.26-3.63-8.41-5.47-13.23-4.71c3.08-0.28,6.23,1.76,7.22,4.69c-2.25-2.18-5.19-3.63-8.29-4.08C307.95,251.64,308.39,250.95,308.61,250.25z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M189.85,245.44c-6.37-0.57-12.9,3.34-15.4,9.24c3.57-4.87,9.35-8.06,15.38-8.48c-2.59,0.32-5.23,0.82-7.43,2.21c-2.2,1.4-3.88,3.88-3.65,6.47c2.18-4.37,6.66-7.5,11.51-8.04c-3.05,0.55-5.54,3.35-5.72,6.44c1.59-2.69,4.04-4.87,6.91-6.13C190.85,246.6,190.24,246.05,189.85,245.44z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M310.15,245.44c6.37-0.57,12.9,3.34,15.4,9.24c-3.57-4.87-9.35-8.06-15.38-8.48c2.59,0.32,5.23,0.82,7.43,2.21c2.2,1.4,3.88,3.88,3.65,6.47c-2.18-4.37-6.66-7.5-11.51-8.04c3.05,0.55,5.54,3.35,5.72,6.44c-1.59-2.69-4.04-4.87-6.91-6.13C309.15,246.6,309.76,246.05,310.15,245.44z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path
                id="Bottom_00000157304522580165062880000006080422537871422904_"
                d="M191.15,244.28c-5.99-2.26-13.33-0.23-17.31,4.77c4.75-3.74,11.17-5.26,17.09-4.05c-2.58-0.38-5.26-0.61-7.75,0.14c-2.49,0.76-4.78,2.69-5.25,5.26c3.27-3.62,8.42-5.44,13.24-4.66c-3.08-0.29-6.23,1.74-7.24,4.67c2.25-2.17,5.2-3.61,8.3-4.05C191.8,245.67,191.37,244.98,191.15,244.28z"
              />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.85,244.28c5.99-2.26,13.33-0.23,17.31,4.77c-4.75-3.74-11.17-5.26-17.09-4.05c2.58-0.38,5.26-0.61,7.75,0.14c2.49,0.76,4.78,2.69,5.25,5.26c-3.27-3.62-8.42-5.44-13.24-4.66c3.08-0.29,6.23,1.74,7.24,4.67c-2.25-2.17-5.2-3.61-8.3-4.05C308.2,245.67,308.63,244.98,308.85,244.28z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.4,248.03c-5.99-2.26-13.33-0.23-17.31,4.77c4.75-3.74,11.17-5.26,17.09-4.05c-2.58-0.38-5.26-0.61-7.75,0.14c-2.49,0.76-4.78,2.69-5.25,5.26c3.27-3.62,8.42-5.44,13.24-4.66c-3.08-0.29-6.23,1.74-7.24,4.67c2.25-2.17,5.2-3.61,8.3-4.05C192.05,249.42,191.62,248.73,191.4,248.03z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.6,248.03c5.99-2.26,13.33-0.23,17.31,4.77c-4.75-3.74-11.17-5.26-17.09-4.05c2.58-0.38,5.26-0.61,7.75,0.14c2.49,0.76,4.78,2.69,5.25,5.26c-3.27-3.62-8.42-5.44-13.24-4.66c3.08-0.29,6.23,1.74,7.24,4.67c-2.25-2.17-5.2-3.61-8.3-4.05C307.95,249.42,308.38,248.73,308.6,248.03z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_fka41 = {
  id: "eyelash_shape_fka41",
  name: "Eyelash 6",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.43,248.73c-7.14-3.55-16.38-2.33-22.35,2.96c6.51-4.09,14.91-5.02,22.16-2.44c-6.34-2.8-14.49-0.58-18.53,5.05c5.04-4.53,12.6-6.03,18.99-3.78c-5.71-1.69-12.37,0.66-15.77,5.54c4.3-3.72,10.26-5.45,15.89-4.61c-4.12-1.13-8.89,0.88-10.97,4.61c3.29-2.81,7.86-4.07,12.13-3.33c-2.42-0.01-4.83,0.93-6.59,2.57c2.41-0.56,4.82-1.11,7.23-1.67C194.8,251.92,193.98,250.2,193.43,248.73z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.57,248.73c7.14-3.55,16.38-2.33,22.35,2.96c-6.51-4.09-14.91-5.02-22.16-2.44c6.34-2.8,14.49-0.58,18.53,5.05c-5.04-4.53-12.6-6.03-18.99-3.78c5.71-1.69,12.37,0.66,15.77,5.54c-4.3-3.72-10.26-5.45-15.89-4.61c4.12-1.13,8.89,0.88,10.97,4.61c-3.29-2.81-7.86-4.07-12.13-3.33c2.42-0.01,4.83,0.93,6.59,2.57c-2.41-0.56-4.82-1.11-7.23-1.67C305.2,251.92,306.02,250.2,306.57,248.73z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.68,249.48c-7.14-3.55-16.38-2.33-22.35,2.96c6.51-4.09,14.91-5.02,22.16-2.44c-6.34-2.8-14.49-0.58-18.53,5.05c5.04-4.53,12.6-6.03,18.99-3.78c-5.71-1.69-12.37,0.66-15.77,5.54c4.3-3.72,10.26-5.45,15.89-4.61c-4.12-1.13-8.89,0.88-10.97,4.61c3.29-2.81,7.86-4.07,12.13-3.33c-2.42-0.01-4.83,0.93-6.59,2.57c2.41-0.56,4.82-1.11,7.23-1.67C193.05,252.67,192.23,250.95,191.68,249.48z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.32,249.48c7.14-3.55,16.38-2.33,22.35,2.96c-6.51-4.09-14.91-5.02-22.16-2.44c6.34-2.8,14.49-0.58,18.53,5.05c-5.04-4.53-12.6-6.03-18.99-3.78c5.71-1.69,12.37,0.66,15.77,5.54c-4.3-3.72-10.26-5.45-15.89-4.61c4.12-1.13,8.89,0.88,10.97,4.61c-3.29-2.81-7.86-4.07-12.13-3.33c2.42-0.01,4.83,0.93,6.59,2.57c-2.41-0.56-4.82-1.11-7.23-1.67C306.95,252.67,307.77,250.95,308.32,249.48z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.28,237.17c-1.93-3.07-15.22-6.48-22.35-2.92c7.34-2.27,15.7-1,22.03,3.36c-5.4-4.34-13.85-4.3-19.21,0.09c6.04-3.07,13.74-2.57,19.32,1.25c-5.08-3.11-12.12-2.56-16.67,1.28c5.12-2.48,11.32-2.61,16.54-0.35c-3.69-2.15-8.82-1.45-11.79,1.62c3.91-1.87,8.25-2.05,12.18-0.24c-2.33-0.64-4.5-0.2-6.63,0.93c6.03-1.24,7.45,1.1,7.41,0.26C191.77,240.61,192.2,239.69,191.28,237.17z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.72,237.17c1.93-3.07,15.22-6.48,22.35-2.92c-7.34-2.27-15.7-1-22.03,3.36c5.4-4.34,13.85-4.3,19.21,0.09c-6.04-3.07-13.74-2.57-19.32,1.25c5.08-3.11,12.12-2.56,16.67,1.28c-5.12-2.48-11.32-2.61-16.54-0.35c3.69-2.15,8.82-1.45,11.79,1.62c-3.91-1.87-8.25-2.05-12.18-0.24c2.33-0.64,4.5-0.2,6.63,0.93c-6.03-1.24-7.45,1.1-7.41,0.26C308.23,240.61,307.8,239.69,308.72,237.17z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.73,257.18c-7.95-0.65-16.08,3.91-19.66,11.03c4.53-6.21,11.99-10.19,19.67-10.48c-6.92-0.25-13.67,4.83-15.34,11.56c3-6.07,9.47-10.28,16.24-10.55c-5.93,0.55-11.25,5.19-12.59,10.99c2.62-5.05,7.51-8.86,13.05-10.17c-4.25,0.48-7.93,4.11-8.48,8.35c2.02-3.83,5.8-6.69,10.03-7.59c-2.25,0.88-4.14,2.65-5.17,4.83c2.03-1.41,4.06-2.82,6.09-4.23C194.18,259.64,192.79,258.35,191.73,257.18z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.27,257.18c7.95-0.65,16.08,3.91,19.66,11.03c-4.53-6.21-11.99-10.19-19.67-10.48c6.92-0.25,13.67,4.83,15.34,11.56c-3-6.07-9.47-10.28-16.24-10.55c5.93,0.55,11.25,5.19,12.59,10.99c-2.62-5.05-7.51-8.86-13.05-10.17c4.25,0.48,7.93,4.11,8.48,8.35c-2.02-3.83-5.8-6.69-10.03-7.59c2.25,0.88,4.14,2.65,5.17,4.83c-2.03-1.41-4.06-2.82-6.09-4.23C305.82,259.64,307.21,258.35,308.27,257.18z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.93,248.48c-7.14-3.55-16.38-2.33-22.35,2.96c6.51-4.09,14.91-5.02,22.16-2.44c-6.34-2.8-14.49-0.58-18.53,5.05c5.04-4.53,12.6-6.03,18.99-3.78c-5.71-1.69-12.37,0.66-15.77,5.54c4.3-3.72,10.26-5.45,15.89-4.61c-4.12-1.13-8.89,0.88-10.97,4.61c3.29-2.81,7.86-4.07,12.13-3.33c-2.42-0.01-4.83,0.93-6.59,2.57c2.41-0.56,4.82-1.11,7.23-1.67C192.3,251.67,191.48,249.95,190.93,248.48z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.07,248.48c7.14-3.55,16.38-2.33,22.35,2.96c-6.51-4.09-14.91-5.02-22.16-2.44c6.34-2.8,14.49-0.58,18.53,5.05c-5.04-4.53-12.6-6.03-18.99-3.78c5.71-1.69,12.37,0.66,15.77,5.54c-4.3-3.72-10.26-5.45-15.89-4.61c4.12-1.13,8.89,0.88,10.97,4.61c-3.29-2.81-7.86-4.07-12.13-3.33c2.42-0.01,4.83,0.93,6.59,2.57c-2.41-0.56-4.82-1.11-7.23-1.67C307.7,251.67,308.52,249.95,309.07,248.48z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.68,254.98c-7.14-3.55-16.38-2.33-22.35,2.96c6.51-4.09,14.91-5.02,22.16-2.44c-6.34-2.8-14.49-0.58-18.53,5.05c5.04-4.53,12.6-6.03,18.99-3.78c-5.71-1.69-12.37,0.66-15.77,5.54c4.3-3.72,10.26-5.45,15.89-4.61c-4.12-1.13-8.89,0.88-10.97,4.61c3.29-2.81,7.86-4.07,12.13-3.33c-2.42-0.01-4.83,0.93-6.59,2.57c2.41-0.56,4.82-1.11,7.23-1.67C194.05,258.17,193.23,256.45,192.68,254.98z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.32,254.98c7.14-3.55,16.38-2.33,22.35,2.96c-6.51-4.09-14.91-5.02-22.16-2.44c6.34-2.8,14.49-0.58,18.53,5.05c-5.04-4.53-12.6-6.03-18.99-3.78c5.71-1.69,12.37,0.66,15.77,5.54c-4.3-3.72-10.26-5.45-15.89-4.61c4.12-1.13,8.89,0.88,10.97,4.61c-3.29-2.81-7.86-4.07-12.13-3.33c2.42-0.01,4.83,0.93,6.59,2.57c-2.41-0.56-4.82-1.11-7.23-1.67C305.95,258.17,306.77,256.45,307.32,254.98z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.43,250.98c-7.14-3.55-16.38-2.33-22.35,2.96c6.51-4.09,14.91-5.02,22.16-2.44c-6.34-2.8-14.49-0.58-18.53,5.05c5.04-4.53,12.6-6.03,18.99-3.78c-5.71-1.69-12.37,0.66-15.77,5.54c4.3-3.72,10.26-5.45,15.89-4.61c-4.12-1.13-8.89,0.88-10.97,4.61c3.29-2.81,7.86-4.07,12.13-3.33c-2.42-0.01-4.83,0.93-6.59,2.57c2.41-0.56,4.82-1.11,7.23-1.67C193.8,254.17,192.98,252.45,192.43,250.98z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.57,250.98c7.14-3.55,16.38-2.33,22.35,2.96c-6.51-4.09-14.91-5.02-22.16-2.44c6.34-2.8,14.49-0.58,18.53,5.05c-5.04-4.53-12.6-6.03-18.99-3.78c5.71-1.69,12.37,0.66,15.77,5.54c-4.3-3.72-10.26-5.45-15.89-4.61c4.12-1.13,8.89,0.88,10.97,4.61c-3.29-2.81-7.86-4.07-12.13-3.33c2.42-0.01,4.83,0.93,6.59,2.57c-2.41-0.56-4.82-1.11-7.23-1.67C306.2,254.17,307.02,252.45,307.57,250.98z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.13,248.25c-7.45-2.83-16.53-0.71-21.95,5.13c6.08-4.71,14.35-6.46,21.81-4.6c-6.58-2.16-14.48,0.85-17.95,6.84c4.57-5,11.95-7.24,18.53-5.62c-5.85-1.12-12.25,1.87-15.15,7.06c3.92-4.12,9.68-6.43,15.36-6.14c-4.21-0.72-8.76,1.74-10.47,5.66c3-3.12,7.43-4.82,11.75-4.51c-2.41,0.22-4.71,1.39-6.31,3.21c2.34-0.79,4.69-1.58,7.03-2.37C192.8,251.29,191.82,249.67,191.13,248.25z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.87,248.25c7.45-2.83,16.53-0.71,21.95,5.13c-6.08-4.71-14.35-6.46-21.81-4.6c6.58-2.16,14.48,0.85,17.95,6.84c-4.57-5-11.95-7.24-18.53-5.62c5.85-1.12,12.25,1.87,15.15,7.06c-3.92-4.12-9.68-6.43-15.36-6.14c4.21-0.72,8.76,1.74,10.47,5.66c-3-3.12-7.43-4.82-11.75-4.51c2.41,0.22,4.71,1.39,6.31,3.21c-2.34-0.79-4.69-1.58-7.03-2.37C307.2,251.29,308.18,249.67,308.87,248.25z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.61,248.09c-7.74-1.91-16.49,1.3-21.17,7.76c5.46-5.42,13.46-8.15,21.09-7.22c-6.79-1.35-14.27,2.6-16.99,8.97c3.93-5.52,10.98-8.64,17.71-7.83c-5.94-0.4-11.93,3.34-14.18,8.85c3.39-4.57,8.83-7.56,14.5-7.96c-4.27-0.2-8.49,2.79-9.7,6.89c2.6-3.46,6.79-5.69,11.11-5.9c-2.36,0.51-4.51,1.96-5.87,3.95c2.23-1.07,4.46-2.13,6.69-3.2C193.64,250.91,192.47,249.41,191.61,248.09z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.39,248.09c7.74-1.91,16.49,1.3,21.17,7.76c-5.46-5.42-13.46-8.15-21.09-7.22c6.79-1.35,14.27,2.6,16.99,8.97c-3.93-5.52-10.98-8.64-17.71-7.83c5.94-0.4,11.93,3.34,14.18,8.85c-3.39-4.57-8.83-7.56-14.5-7.96c4.27-0.2,8.49,2.79,9.7,6.89c-2.6-3.46-6.79-5.69-11.11-5.9c2.36,0.51,4.51,1.96,5.87,3.95c-2.23-1.07-4.46-2.13-6.69-3.2C306.36,250.91,307.53,249.41,308.39,248.09z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.36,250.09c-7.74-1.91-16.49,1.3-21.17,7.76c5.46-5.42,13.46-8.15,21.09-7.22c-6.79-1.35-14.27,2.6-16.99,8.97c3.93-5.52,10.98-8.64,17.71-7.83c-5.94-0.4-11.93,3.34-14.18,8.85c3.39-4.57,8.83-7.56,14.5-7.96c-4.27-0.2-8.49,2.79-9.7,6.89c2.6-3.46,6.79-5.69,11.11-5.9c-2.36,0.51-4.51,1.96-5.87,3.95c2.23-1.07,4.46-2.13,6.69-3.2C193.39,252.91,192.22,251.41,191.36,250.09z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.64,250.09c7.74-1.91,16.49,1.3,21.17,7.76c-5.46-5.42-13.46-8.15-21.09-7.22c6.79-1.35,14.27,2.6,16.99,8.97c-3.93-5.52-10.98-8.64-17.71-7.83c5.94-0.4,11.93,3.34,14.18,8.85c-3.39-4.57-8.83-7.56-14.5-7.96c4.27-0.2,8.49,2.79,9.7,6.89c-2.6-3.46-6.79-5.69-11.11-5.9c2.36,0.51,4.51,1.96,5.87,3.95c-2.23-1.07-4.46-2.13-6.69-3.2C306.61,252.91,307.78,251.41,308.64,250.09z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M189.71,244.81c-7.8-1.67-16.44,1.8-20.92,8.4c5.29-5.58,13.2-8.56,20.86-7.86c-6.83-1.14-14.18,3.03-16.71,9.48c3.76-5.64,10.72-8.97,17.46-8.37c-5.95-0.22-11.83,3.7-13.9,9.28c3.25-4.67,8.59-7.82,14.25-8.4c-4.27-0.07-8.4,3.05-9.49,7.19c2.49-3.54,6.61-5.89,10.93-6.24c-2.34,0.58-4.45,2.09-5.75,4.13c2.2-1.13,4.39-2.27,6.59-3.4C191.82,247.56,190.61,246.11,189.71,244.81z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M310.29,244.81c7.8-1.67,16.44,1.8,20.92,8.4c-5.29-5.58-13.2-8.56-20.86-7.86c6.83-1.14,14.18,3.03,16.71,9.48c-3.76-5.64-10.72-8.97-17.46-8.37c5.95-0.22,11.83,3.7,13.9,9.28c-3.25-4.67-8.59-7.82-14.25-8.4c4.27-0.07,8.4,3.05,9.49,7.19c-2.49-3.54-6.61-5.89-10.93-6.24c2.34,0.58,4.45,2.09,5.75,4.13c-2.2-1.13-4.39-2.27-6.59-3.4C308.18,247.56,309.39,246.11,310.29,244.81z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.02,244.47c-7.53-2.62-16.54-0.23-21.79,5.76c5.94-4.89,14.16-6.87,21.67-5.23c-6.64-1.97-14.45,1.26-17.74,7.36c4.42-5.13,11.74-7.58,18.36-6.16c-5.88-0.95-12.19,2.22-14.94,7.5c3.8-4.24,9.49-6.7,15.18-6.58c-4.23-0.6-8.71,2-10.3,5.96c2.91-3.21,7.28-5.03,11.61-4.85c-2.4,0.29-4.67,1.53-6.21,3.39c2.32-0.86,4.64-1.71,6.96-2.57C192.77,247.46,191.75,245.87,191.02,244.47z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.98,244.47c7.53-2.62,16.54-0.23,21.79,5.76c-5.94-4.89-14.16-6.87-21.67-5.23c6.64-1.97,14.45,1.26,17.74,7.36c-4.42-5.13-11.74-7.58-18.36-6.16c5.88-0.95,12.19,2.22,14.94,7.5c-3.8-4.24-9.49-6.7-15.18-6.58c4.23-0.6,8.71,2,10.3,5.96c-2.91-3.21-7.28-5.03-11.61-4.85c2.4,0.29,4.67,1.53,6.21,3.39c-2.32-0.86-4.64-1.71-6.96-2.57C307.23,247.46,308.25,245.87,308.98,244.47z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.9,247.95c-7.82-1.58-16.42,1.99-20.82,8.64c5.23-5.64,13.1-8.71,20.77-8.1c-6.85-1.06-14.15,3.2-16.59,9.68c3.69-5.68,10.61-9.09,17.37-8.57c-5.95-0.15-11.78,3.84-13.79,9.44c3.19-4.71,8.5-7.92,14.15-8.57c-4.28-0.02-8.36,3.15-9.4,7.3c2.45-3.57,6.54-5.97,10.86-6.36c-2.34,0.61-4.42,2.14-5.7,4.19c2.18-1.16,4.37-2.32,6.55-3.48C193.05,250.68,191.82,249.24,190.9,247.95z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.1,247.95c7.82-1.58,16.42,1.99,20.82,8.64c-5.23-5.64-13.1-8.71-20.77-8.1c6.85-1.06,14.15,3.2,16.59,9.68c-3.69-5.68-10.61-9.09-17.37-8.57c5.95-0.15,11.78,3.84,13.79,9.44c-3.19-4.71-8.5-7.92-14.15-8.57c4.28-0.02,8.36,3.15,9.4,7.3c-2.45-3.57-6.54-5.97-10.86-6.36c2.34,0.61,4.42,2.14,5.7,4.19c-2.18-1.16-4.37-2.32-6.55-3.48C306.95,250.68,308.18,249.24,309.1,247.95z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_fa94j = {
  id: "eyelash_shape_fa94j",
  name: "Eyelash 7",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.37,248.69c-1.76-1.42-4.19-1.98-6.39-1.47c-2.2,0.51-4.14,2.08-5.1,4.13c3.39-1.66,7.5-1.78,10.98-0.32c-2.85-0.92-6.26,1.26-6.62,4.23c0.92-0.99,2.36-1.25,3.69-1.45c1.33-0.2,2.78-0.46,3.69-1.45S194.34,249.48,193.37,248.69z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.63,248.69c1.76-1.42,4.19-1.98,6.39-1.47c2.2,0.51,4.14,2.08,5.1,4.13c-3.39-1.66-7.5-1.78-10.98-0.32c2.85-0.92,6.26,1.26,6.62,4.23c-0.92-0.99-2.36-1.25-3.69-1.45c-1.33-0.2-2.78-0.46-3.69-1.45C305.46,251.38,305.66,249.48,306.63,248.69z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.62,249.44c-1.76-1.42-4.19-1.98-6.39-1.47c-2.2,0.51-4.14,2.08-5.1,4.13c3.39-1.66,7.5-1.78,10.98-0.32c-2.85-0.92-6.26,1.26-6.62,4.23c0.92-0.99,2.36-1.25,3.69-1.45c1.33-0.2,2.78-0.46,3.69-1.45S192.59,250.23,191.62,249.44z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.38,249.44c1.76-1.42,4.19-1.98,6.39-1.47c2.2,0.51,4.14,2.08,5.1,4.13c-3.39-1.66-7.5-1.78-10.98-0.32c2.85-0.92,6.26,1.26,6.62,4.23c-0.92-0.99-2.36-1.25-3.69-1.45c-1.33-0.2-2.78-0.46-3.69-1.45C307.21,252.13,307.41,250.23,308.38,249.44z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.87,236.69c-1.76-1.42-4.19-1.98-6.39-1.47c-2.2,0.51-4.14,2.08-5.1,4.13c3.39-1.66,7.5-1.78,10.98-0.32c-2.85-0.92-6.26,1.26-6.62,4.23c0.92-0.99,2.36-1.25,3.69-1.45c1.33-0.2,2.78-0.46,3.69-1.45S191.84,237.48,190.87,236.69z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.13,236.69c1.76-1.42,4.19-1.98,6.39-1.47c2.2,0.51,4.14,2.08,5.1,4.13c-3.39-1.66-7.5-1.78-10.98-0.32c2.85-0.92,6.26,1.26,6.62,4.23c-0.92-0.99-2.36-1.25-3.69-1.45c-1.33-0.2-2.78-0.46-3.69-1.45C307.96,239.38,308.16,237.48,309.13,236.69z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.37,257.33c-2.24-0.32-4.61,0.43-6.25,2s-2.5,3.9-2.28,6.15c2.07-3.16,5.55-5.36,9.29-5.87c-2.92,0.66-4.74,4.27-3.54,7.01c0.28-1.32,1.39-2.27,2.44-3.13c1.05-0.85,2.16-1.81,2.44-3.13S192.61,257.51,191.37,257.33z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.63,257.33c2.24-0.32,4.61,0.43,6.25,2c1.64,1.56,2.5,3.9,2.28,6.15c-2.07-3.16-5.55-5.36-9.29-5.87c2.92,0.66,4.74,4.27,3.54,7.01c-0.28-1.32-1.39-2.27-2.44-3.13c-1.05-0.85-2.16-1.81-2.44-3.13C306.26,259.04,307.39,257.51,308.63,257.33z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.87,247.69c-1.76-1.42-4.19-1.98-6.39-1.47c-2.2,0.51-4.14,2.08-5.1,4.13c3.39-1.66,7.5-1.78,10.98-0.32c-2.85-0.92-6.26,1.26-6.62,4.23c0.92-0.99,2.36-1.25,3.69-1.45c1.33-0.2,2.78-0.46,3.69-1.45S191.84,248.48,190.87,247.69z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.13,247.69c1.76-1.42,4.19-1.98,6.39-1.47c2.2,0.51,4.14,2.08,5.1,4.13c-3.39-1.66-7.5-1.78-10.98-0.32c2.85-0.92,6.26,1.26,6.62,4.23c-0.92-0.99-2.36-1.25-3.69-1.45c-1.33-0.2-2.78-0.46-3.69-1.45C307.96,250.38,308.16,248.48,309.13,247.69z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.71,254.78c-2.14-0.73-4.61-0.43-6.51,0.8c-1.9,1.23-3.18,3.37-3.38,5.62c2.62-2.72,6.44-4.24,10.22-4.05c-2.99,0.11-5.45,3.32-4.78,6.24c0.52-1.24,1.79-1.98,2.98-2.62c1.19-0.64,2.46-1.38,2.97-2.62C193.72,256.9,192.89,255.18,191.71,254.78z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.29,254.78c2.14-0.73,4.61-0.43,6.51,0.8c1.9,1.23,3.18,3.37,3.38,5.62c-2.62-2.72-6.44-4.24-10.22-4.05c2.99,0.11,5.45,3.32,4.78,6.24c-0.52-1.24-1.79-1.98-2.98-2.62c-1.19-0.64-2.46-1.38-2.97-2.62C306.28,256.9,307.11,255.18,308.29,254.78z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.46,248.28c-2.14-0.73-4.61-0.43-6.51,0.8c-1.9,1.23-3.18,3.37-3.38,5.62c2.62-2.72,6.44-4.24,10.22-4.05c-2.99,0.11-5.45,3.32-4.78,6.24c0.52-1.24,1.79-1.98,2.98-2.62s2.46-1.38,2.97-2.62C193.47,250.4,192.64,248.68,191.46,248.28z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.54,248.28c2.14-0.73,4.61-0.43,6.51,0.8s3.18,3.37,3.38,5.62c-2.62-2.72-6.44-4.24-10.22-4.05c2.99,0.11,5.45,3.32,4.78,6.24c-0.52-1.24-1.79-1.98-2.98-2.62s-2.46-1.38-2.97-2.62C306.53,250.4,307.36,248.68,308.54,248.28z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.03,248.63c-2.07-0.91-4.56-0.81-6.55,0.26s-3.45,3.09-3.83,5.32c2.84-2.49,6.77-3.69,10.52-3.19c-2.99-0.14-5.71,2.86-5.28,5.82c0.62-1.2,1.95-1.82,3.18-2.36c1.24-0.54,2.56-1.17,3.18-2.37C192.87,250.92,192.18,249.14,191.03,248.63z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.97,248.63c2.07-0.91,4.56-0.81,6.55,0.26s3.45,3.09,3.83,5.32c-2.84-2.49-6.77-3.69-10.52-3.19c2.99-0.14,5.71,2.86,5.28,5.82c-0.62-1.2-1.95-1.82-3.18-2.36c-1.24-0.54-2.56-1.17-3.18-2.37C307.13,250.92,307.82,249.14,308.97,248.63z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.75,247.92c-2.23-0.38-4.62,0.31-6.3,1.83s-2.61,3.83-2.45,6.09c2.16-3.1,5.69-5.2,9.44-5.62c-2.93,0.58-4.86,4.14-3.73,6.92c0.32-1.31,1.46-2.24,2.53-3.06c1.07-0.82,2.21-1.75,2.52-3.06S191.98,248.13,190.75,247.92z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.25,247.92c2.23-0.38,4.62,0.31,6.3,1.83c1.68,1.52,2.61,3.83,2.45,6.09c-2.16-3.1-5.69-5.2-9.44-5.62c2.93,0.58,4.86,4.14,3.73,6.92c-0.32-1.31-1.46-2.24-2.53-3.06c-1.07-0.82-2.21-1.75-2.52-3.06C306.93,249.69,308.02,248.13,309.25,247.92z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.5,250.17c-2.23-0.38-4.62,0.31-6.3,1.83s-2.61,3.83-2.45,6.09c2.16-3.1,5.69-5.2,9.44-5.62c-2.93,0.58-4.86,4.14-3.73,6.92c0.32-1.31,1.46-2.24,2.53-3.06c1.07-0.82,2.21-1.75,2.52-3.06S191.73,250.38,190.5,250.17z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.5,250.17c2.23-0.38,4.62,0.31,6.3,1.83c1.68,1.52,2.61,3.83,2.45,6.09c-2.16-3.1-5.69-5.2-9.44-5.62c2.93,0.58,4.86,4.14,3.73,6.92c-0.32-1.31-1.46-2.24-2.53-3.06c-1.07-0.82-2.21-1.75-2.52-3.06C307.18,251.94,308.27,250.38,309.5,250.17z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M189.25,245.17c-2.23-0.38-4.62,0.31-6.3,1.83s-2.61,3.83-2.45,6.09c2.16-3.1,5.69-5.2,9.44-5.62c-2.93,0.58-4.86,4.14-3.73,6.92c0.32-1.31,1.46-2.24,2.53-3.06c1.07-0.82,2.21-1.75,2.52-3.06S190.48,245.38,189.25,245.17z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M310.75,245.17c2.23-0.38,4.62,0.31,6.3,1.83c1.68,1.52,2.61,3.83,2.45,6.09c-2.16-3.1-5.69-5.2-9.44-5.62c2.93,0.58,4.86,4.14,3.73,6.92c-0.32-1.31-1.46-2.24-2.53-3.06c-1.07-0.82-2.21-1.75-2.52-3.06C308.43,246.94,309.52,245.38,310.75,245.17z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.4,244.03c-2.04-0.98-4.53-0.96-6.56,0.05c-2.03,1.01-3.55,2.98-4.01,5.19c2.92-2.4,6.89-3.47,10.61-2.85c-2.98-0.24-5.8,2.67-5.47,5.64c0.66-1.18,2.01-1.76,3.26-2.26c1.25-0.5,2.6-1.09,3.26-2.26S191.54,244.57,190.4,244.03z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.6,244.03c2.04-0.98,4.53-0.96,6.56,0.05c2.03,1.01,3.55,2.98,4.01,5.19c-2.92-2.4-6.89-3.47-10.61-2.85c2.98-0.24,5.8,2.67,5.47,5.64c-0.66-1.18-2.01-1.76-3.26-2.26c-1.25-0.5-2.6-1.09-3.26-2.26C307.84,246.37,308.46,244.57,309.6,244.03z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.9,247.53c-2.04-0.98-4.53-0.96-6.56,0.05c-2.03,1.01-3.55,2.98-4.01,5.19c2.92-2.4,6.89-3.47,10.61-2.85c-2.98-0.24-5.8,2.67-5.47,5.64c0.66-1.18,2.01-1.76,3.26-2.26c1.25-0.5,2.6-1.09,3.26-2.26S192.04,248.07,190.9,247.53z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.1,247.53c2.04-0.98,4.53-0.96,6.56,0.05c2.03,1.01,3.55,2.98,4.01,5.19c-2.92-2.4-6.89-3.47-10.61-2.85c2.98-0.24,5.8,2.67,5.47,5.64c-0.66-1.18-2.01-1.76-3.26-2.26c-1.25-0.5-2.6-1.09-3.26-2.26C307.34,249.87,307.96,248.07,309.1,247.53z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_fa941 = {
  id: "eyelash_shape_fa941",
  name: "Eyelash 8",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.55,248.44c-3.13-1.26-6.83-0.99-9.74,0.71c3.31-0.22,6.66,0.4,9.68,1.78c-2.41,0.19-4.75,1.23-6.51,2.89c2.55-0.5,5.1-1,7.65-1.5C194.78,250.94,194.39,249.51,193.55,248.44z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.45,248.44c3.13-1.26,6.83-0.99,9.74,0.71c-3.31-0.22-6.66,0.4-9.68,1.78c2.41,0.19,4.75,1.23,6.51,2.89c-2.55-0.5-5.1-1-7.65-1.5C305.22,250.94,305.61,249.51,306.45,248.44z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.3,248.69c-3.13-1.26-6.83-0.99-9.74,0.71c3.31-0.22,6.66,0.4,9.68,1.78c-2.41,0.19-4.75,1.23-6.51,2.89c2.55-0.5,5.1-1,7.65-1.5C192.53,251.19,192.14,249.76,191.3,248.69z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.7,248.69c3.13-1.26,6.83-0.99,9.74,0.71c-3.31-0.22-6.66,0.4-9.68,1.78c2.41,0.19,4.75,1.23,6.51,2.89c-2.55-0.5-5.1-1-7.65-1.5C307.47,251.19,307.86,249.76,308.7,248.69z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.19,237.14c-2.79-1.89-6.47-2.4-9.67-1.35c3.28,0.48,6.43,1.79,9.09,3.77c-2.4-0.32-4.9,0.2-6.97,1.46c2.6,0.05,5.19,0.09,7.79,0.14C191.86,239.85,191.78,238.37,191.19,237.14z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.81,237.14c2.79-1.89,6.47-2.4,9.67-1.35c-3.28,0.48-6.43,1.79-9.09,3.77c2.4-0.32,4.9,0.2,6.97,1.46c-2.6,0.05-5.19,0.09-7.79,0.14C308.14,239.85,308.22,238.37,308.81,237.14z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.7,257.05c-3.37-0.06-6.74,1.51-8.84,4.14c3.01-1.38,6.37-2.01,9.68-1.8c-2.19,1.04-4,2.84-5.05,5.03c2.2-1.38,4.41-2.75,6.61-4.13C193.74,258.95,192.86,257.76,191.7,257.05z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.3,257.05c3.37-0.06,6.74,1.51,8.84,4.14c-3.01-1.38-6.37-2.01-9.68-1.8c2.19,1.04,4,2.84,5.05,5.03c-2.2-1.38-4.41-2.75-6.61-4.13C306.26,258.95,307.14,257.76,308.3,257.05z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.8,247.94c-3.13-1.26-6.83-0.99-9.74,0.71c3.31-0.22,6.66,0.4,9.68,1.78c-2.41,0.19-4.75,1.23-6.51,2.89c2.55-0.5,5.1-1,7.65-1.5C192.03,250.44,191.64,249.01,190.8,247.94z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.2,247.94c3.13-1.26,6.83-0.99,9.74,0.71c-3.31-0.22-6.66,0.4-9.68,1.78c2.41,0.19,4.75,1.23,6.51,2.89c-2.55-0.5-5.1-1-7.65-1.5C307.97,250.44,308.36,249.01,309.2,247.94z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.55,254.69c-3.13-1.26-6.83-0.99-9.74,0.71c3.31-0.22,6.66,0.4,9.68,1.78c-2.41,0.19-4.75,1.23-6.51,2.89c2.55-0.5,5.1-1,7.65-1.5C193.78,257.19,193.39,255.76,192.55,254.69z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.45,254.69c3.13-1.26,6.83-0.99,9.74,0.71c-3.31-0.22-6.66,0.4-9.68,1.78c2.41,0.19,4.75,1.23,6.51,2.89c-2.55-0.5-5.1-1-7.65-1.5C306.22,257.19,306.61,255.76,307.45,254.69z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.05,249.44c-3.13-1.26-6.83-0.99-9.74,0.71c3.31-0.22,6.66,0.4,9.68,1.78c-2.41,0.19-4.75,1.23-6.51,2.89c2.55-0.5,5.1-1,7.65-1.5C193.28,251.94,192.89,250.51,192.05,249.44z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.95,249.44c3.13-1.26,6.83-0.99,9.74,0.71c-3.31-0.22-6.66,0.4-9.68,1.78c2.41,0.19,4.75,1.23,6.51,2.89c-2.55-0.5-5.1-1-7.65-1.5C306.72,251.94,307.11,250.51,307.95,249.44z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.3,247.94c-3.13-1.26-6.83-0.99-9.74,0.71c3.31-0.22,6.66,0.4,9.68,1.78c-2.41,0.19-4.75,1.23-6.51,2.89c2.55-0.5,5.1-1,7.65-1.5C192.53,250.44,192.14,249.01,191.3,247.94z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.7,247.94c3.13-1.26,6.83-0.99,9.74,0.71c-3.31-0.22-6.66,0.4-9.68,1.78c2.41,0.19,4.75,1.23,6.51,2.89c-2.55-0.5-5.1-1-7.65-1.5C307.47,250.44,307.86,249.01,308.7,247.94z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.42,248.26c-3.36-0.28-6.82,1.08-9.09,3.58c3.09-1.19,6.48-1.6,9.77-1.18c-2.25,0.9-4.17,2.59-5.35,4.7c2.29-1.24,4.57-2.47,6.86-3.71C193.34,250.28,192.54,249.04,191.42,248.26z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.58,248.26c3.36-0.28,6.82,1.08,9.09,3.58c-3.09-1.19-6.48-1.6-9.77-1.18c2.25,0.9,4.17,2.59,5.35,4.7c-2.29-1.24-4.57-2.47-6.86-3.71C306.66,250.28,307.46,249.04,308.58,248.26z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.92,250.01c-3.36-0.28-6.82,1.08-9.09,3.58c3.09-1.19,6.48-1.6,9.77-1.18c-2.25,0.9-4.17,2.59-5.35,4.7c2.29-1.24,4.57-2.47,6.86-3.71C192.84,252.03,192.04,250.79,190.92,250.01z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.08,250.01c3.36-0.28,6.82,1.08,9.09,3.58c-3.09-1.19-6.48-1.6-9.77-1.18c2.25,0.9,4.17,2.59,5.35,4.7c-2.29-1.24-4.57-2.47-6.86-3.71C307.16,252.03,307.96,250.79,309.08,250.01z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M189.3,245.24c-3.36,0.28-6.54,2.19-8.37,5.03c2.85-1.68,6.13-2.65,9.44-2.78c-2.07,1.26-3.69,3.24-4.51,5.52c2.05-1.59,4.1-3.19,6.15-4.78C191.53,246.92,190.54,245.82,189.3,245.24z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M310.7,245.24c3.36,0.28,6.54,2.19,8.37,5.03c-2.85-1.68-6.13-2.65-9.44-2.78c2.07,1.26,3.69,3.24,4.51,5.52c-2.05-1.59-4.1-3.19-6.15-4.78C308.47,246.92,309.46,245.82,310.7,245.24z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.84,244.47c-3.3-0.7-6.9,0.2-9.47,2.39c3.22-0.79,6.63-0.76,9.84,0.08c-2.34,0.6-4.47,2.03-5.91,3.98c2.42-0.93,4.85-1.87,7.27-2.8C192.48,246.73,191.85,245.39,190.84,244.47z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.16,244.47c3.3-0.7,6.9,0.2,9.47,2.39c-3.22-0.79-6.63-0.76-9.84,0.08c2.34,0.6,4.47,2.03,5.91,3.98c-2.42-0.93-4.85-1.87-7.27-2.8C307.52,246.73,308.15,245.39,309.16,244.47z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.97,247.8c-3.36-0.32-6.83,0.99-9.13,3.46c3.11-1.15,6.5-1.52,9.78-1.06c-2.26,0.87-4.2,2.53-5.41,4.63c2.3-1.2,4.6-2.41,6.9-3.61C192.86,249.86,192.08,248.6,190.97,247.8z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.03,247.8c3.36-0.32,6.83,0.99,9.13,3.46c-3.11-1.15-6.5-1.52-9.78-1.06c2.26,0.87,4.2,2.53,5.41,4.63c-2.3-1.2-4.6-2.41-6.9-3.61C307.14,249.86,307.92,248.6,309.03,247.8z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_o19a8 = {
  id: "eyelash_shape_o19a8",
  name: "Eyelash 9",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M228.76,255.15c2.56,0.48,4.96,3.75,4.49,6.31c-1.2-3-5.73-3.11-7.81-0.65c-3.74,4.43-9.89,6.18-15.66,5.57s-11.13-4.22-13.86-9.33c-1.98-3.7-7.61-4.76-10.8-2.03c1.08-2.94,5.09-4.33,7.76-2.71c-3.2-2.69-8.04-3.22-11.75-1.29c1.84-2.26,4.92-3.44,7.79-2.99c2.88,0.45,5.45,2.5,6.52,5.21c2.56,6.47,9.07,11.18,16.01,11.59S224.64,260.6,228.76,255.15z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M271.24,255.15c-2.56,0.48-4.96,3.75-4.49,6.31c1.2-3,5.73-3.11,7.81-0.65c3.74,4.43,9.89,6.18,15.66,5.57s11.13-4.22,13.86-9.33c1.98-3.7,7.61-4.76,10.8-2.03c-1.08-2.94-5.09-4.33-7.76-2.71c3.2-2.69,8.04-3.22,11.75-1.29c-1.84-2.26-4.92-3.44-7.79-2.99c-2.88,0.45-5.45,2.5-6.52,5.21c-2.56,6.47-9.07,11.18-16.01,11.59C281.59,265.25,275.36,260.6,271.24,255.15z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M219.67,258.06c-4.93,2.71-10.17,4.43-15.36,3.59c-5.2-0.85-9.84-5.04-11.66-9.98c-0.78-2.11-2.88-3.67-5.13-3.8c-2.25-0.13-4.51,1.19-5.52,3.2c3.03-1.93,7.34-1.55,9.97,0.89c-2.9-1.23-6.6-0.13-8.36,2.49c3.28-2.03,7.94-0.7,10.61,2.08c4.06,4.21,7.81,6.43,12.57,6.29c5.85-0.16,15.01-3.68,18.66-8.26c1.91-2.4,5.82-2.73,7.82-0.39c-0.14-1.89-2.03-3.45-3.91-3.24C226.05,252.43,224.9,255.18,219.67,258.06z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M280.33,258.06c4.93,2.71,10.17,4.43,15.36,3.59s9.84-5.04,11.66-9.98c0.78-2.11,2.88-3.67,5.13-3.8c2.25-0.13,4.51,1.19,5.52,3.2c-3.03-1.93-7.34-1.55-9.97,0.89c2.9-1.23,6.6-0.13,8.36,2.49c-3.28-2.03-7.94-0.7-10.61,2.08c-4.06,4.21-7.81,6.43-12.57,6.29c-5.85-0.16-15.01-3.68-18.66-8.26c-1.91-2.4-5.82-2.73-7.82-0.39c0.14-1.89,2.03-3.45,3.91-3.24C273.95,252.43,275.1,255.18,280.33,258.06z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M229.28,257.08c0.8-0.97,3.6,1.62,2.31,3.25c0.5-1.06-2.09-2.67-2.73-1.68c-4.98,7.63-14.47,5.56-17.86,4.35c-8.61-3.07-15.82-9.97-18.25-16.93c-1.48-4.24-5.7-5.24-9.93-3.74c2.44-2.11,5.84-2.79,8.46-0.91c-1.82-3.55-6.31-4.52-9.77-2.53c1.78-1.95,4.71-2.76,7.23-1.99c2.52,0.77,3.11,1.68,3.55,4.28c1.47,8.7,8.88,16.29,15.24,19.13C216.33,264.23,222.78,264.91,229.28,257.08z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M270.72,257.08c-0.8-0.97-3.6,1.62-2.31,3.25c-0.5-1.06,2.09-2.67,2.73-1.68c4.98,7.63,14.47,5.56,17.86,4.35c8.61-3.07,15.82-9.97,18.25-16.93c1.48-4.24,5.7-5.24,9.93-3.74c-2.44-2.11-5.84-2.79-8.46-0.91c1.82-3.55,6.31-4.52,9.77-2.53c-1.78-1.95-4.71-2.76-7.23-1.99c-2.52,0.77-3.11,1.68-3.55,4.28c-1.47,8.7-8.88,16.29-15.24,19.13C283.67,264.23,277.22,264.91,270.72,257.08z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M230.04,254.67c0.43-1.1,2.87-0.24,3.76,0.53c0.89,0.77,1.12,2.21,0.51,3.22c-0.75-1.51-3.18-2.43-4.32-1.19c-6.77,7.32-23.41,5.86-32.68,4.65c-4.06-0.53-8.36,0.16-9.78,4c-0.27-2.89,2.28-5.72,5.18-5.76c-2.76-1.61-6.67-0.8-8.58,1.77c0.82-3.43,6.91-6.39,8.85-3.44C195.27,261.96,225.9,265.18,230.04,254.67z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269.96,254.67c-0.43-1.1-2.87-0.24-3.76,0.53s-1.12,2.21-0.51,3.22c0.75-1.51,3.18-2.43,4.32-1.19c6.77,7.32,23.41,5.86,32.68,4.65c4.06-0.53,8.36,0.16,9.78,4c0.27-2.89-2.28-5.72-5.18-5.76c2.76-1.61,6.67-0.8,8.58,1.77c-0.82-3.43-6.91-6.39-8.85-3.44C304.73,261.96,274.1,265.18,269.96,254.67z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M229.19,260.56c1.58,0.77,2.42,2.78,1.88,4.45c-0.1-0.97-0.86-1.78-1.75-2.2c-0.89-0.42-1.89-0.51-2.87-0.56c-5.22-0.27-9.7,0.94-14.93,0.85c-5.23-0.09-11.43-1.57-15.56-4.77c-1.67-1.29-3.08-2.91-4.9-3.98c-1.81-1.07-4.26-1.48-5.96-0.24c0.39-1.25,1.41-2.29,2.65-2.69c1.24-0.4,2.68-0.17,3.73,0.62c-1.72-2.78-5.78-3.79-8.59-2.13c0.55-1.59,2.52-2.08,4.19-2.33c1.2-0.18,2.55-0.32,3.49,0.45c0.75,0.62,1,1.64,1.31,2.56c1.62,4.76,6,8.21,10.76,9.81c4.77,1.6,9.92,1.6,14.94,1.27c2.79-0.18,5.57-0.46,8.33-0.84C227.24,260.66,228.65,260.3,229.19,260.56z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M270.81,260.56c-1.58,0.77-2.42,2.78-1.88,4.45c0.1-0.97,0.86-1.78,1.75-2.2c0.89-0.42,1.89-0.51,2.87-0.56c5.22-0.27,9.7,0.94,14.93,0.85s11.43-1.57,15.56-4.77c1.67-1.29,3.08-2.91,4.9-3.98c1.81-1.07,4.26-1.48,5.96-0.24c-0.39-1.25-1.41-2.29-2.65-2.69c-1.24-0.4-2.68-0.17-3.73,0.62c1.72-2.78,5.78-3.79,8.59-2.13c-0.55-1.59-2.52-2.08-4.19-2.33c-1.2-0.18-2.55-0.32-3.49,0.45c-0.75,0.62-1,1.64-1.31,2.56c-1.62,4.76-6,8.21-10.76,9.81c-4.77,1.6-9.92,1.6-14.94,1.27c-2.79-0.18-5.57-0.46-8.33-0.84C272.76,260.66,271.35,260.3,270.81,260.56z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M228.55,261.08c0.83-1.43,2.79,2.57,2.28,4.11c-0.1-0.9-1.32-2.15-2.16-2.53s-1.6,4.96-16.09,4.52c-7.93-0.24-11.02,0.1-15.23-3.67c-1.47-1.32-2.92-2.44-4.63-3.43c-1.72-0.99-4.03-1.37-5.64-0.22c0.37-1.16,1.33-2.11,2.51-2.49s2.54-0.15,3.53,0.57c-1.63-2.57-5.47-3.5-8.13-1.97c0.52-1.47,2.39-1.92,3.96-2.15c1.13-0.17,2.41-0.29,3.3,0.42c0.71,0.57,1.14,0.97,1.24,1.87c0.51,4.51,5.83,9.39,12.79,9.92C211.84,266.46,224.84,267.48,228.55,261.08z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M271.45,261.08c-0.83-1.43-2.79,2.57-2.28,4.11c0.1-0.9,1.32-2.15,2.16-2.53s1.6,4.96,16.09,4.52c7.93-0.24,11.02,0.1,15.23-3.67c1.47-1.32,2.92-2.44,4.63-3.43c1.72-0.99,4.03-1.37,5.64-0.22c-0.37-1.16-1.33-2.11-2.51-2.49s-2.54-0.15-3.53,0.57c1.63-2.57,5.47-3.5,8.13-1.97c-0.52-1.47-2.39-1.92-3.96-2.15c-1.13-0.17-2.41-0.29-3.3,0.42c-0.71,0.57-1.14,0.97-1.24,1.87c-0.51,4.51-5.83,9.39-12.79,9.92C288.16,266.46,275.16,267.48,271.45,261.08z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M229.72,256.9c2.73,0.48,5.28,3.75,4.78,6.31c-1.27-3-6.09-3.11-8.31-0.65c-3.98,4.43-10.52,6.18-16.66,5.57s-11.84-4.22-14.74-9.33c-2.11-3.7-8.1-4.76-11.49-2.03c1.15-2.94,5.41-4.33,8.26-2.71c-3.4-2.69-8.55-3.22-12.5-1.29c1.96-2.26,5.23-3.44,8.29-2.99s5.8,2.5,6.94,5.21c2.72,6.47,9.65,11.18,17.03,11.59C218.71,267,225.33,262.35,229.72,256.9z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M270.28,256.9c-2.73,0.48-5.28,3.75-4.78,6.31c1.27-3,6.09-3.11,8.31-0.65c3.98,4.43,10.52,6.18,16.66,5.57s11.84-4.22,14.74-9.33c2.11-3.7,8.1-4.76,11.49-2.03c-1.15-2.94-5.41-4.33-8.26-2.71c3.4-2.69,8.55-3.22,12.5-1.29c-1.96-2.26-5.23-3.44-8.29-2.99s-5.8,2.5-6.94,5.21c-2.72,6.47-9.65,11.18-17.03,11.59S274.67,262.35,270.28,256.9z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M230.88,252.13c2.92,0.33,5.65,2.57,5.12,4.34c-1.36-2.06-6.52-2.14-8.9-0.44c-4.26,3.04-11.27,4.25-17.83,3.83c-6.57-0.42-12.67-2.9-15.79-6.41c-2.26-2.55-8.67-3.27-12.3-1.39c1.23-2.02,5.79-2.98,8.84-1.86c-3.64-1.84-9.16-2.21-13.38-0.89c2.1-1.55,5.6-2.36,8.88-2.05s6.21,1.72,7.43,3.58c2.92,4.44,10.33,7.68,18.24,7.96C219.09,259.06,226.18,255.87,230.88,252.13z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269.12,252.13c-2.92,0.33-5.65,2.57-5.12,4.34c1.36-2.06,6.52-2.14,8.9-0.44c4.26,3.04,11.27,4.25,17.83,3.83c6.57-0.42,12.67-2.9,15.79-6.41c2.26-2.55,8.67-3.27,12.3-1.39c-1.23-2.02-5.79-2.98-8.84-1.86c3.64-1.84,9.16-2.21,13.38-0.89c-2.1-1.55-5.6-2.36-8.88-2.05s-6.21,1.72-7.43,3.58c-2.92,4.44-10.33,7.68-18.24,7.96C280.91,259.06,273.82,255.87,269.12,252.13z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.13,250.63c2.92,0.33,5.65,2.57,5.12,4.34c-1.36-2.06-6.52-2.14-8.9-0.44c-4.26,3.04-11.27,4.25-17.83,3.83c-6.57-0.42-12.67-2.9-15.79-6.41c-2.26-2.55-8.67-3.27-12.3-1.39c1.23-2.02,5.79-2.98,8.84-1.86c-3.64-1.84-9.16-2.21-13.38-0.89c2.1-1.55,5.6-2.36,8.88-2.05s6.21,1.72,7.43,3.58c2.92,4.44,10.33,7.68,18.24,7.96C219.34,257.56,226.43,254.37,231.13,250.63z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.87,250.63c-2.92,0.33-5.65,2.57-5.12,4.34c1.36-2.06,6.52-2.14,8.9-0.44c4.26,3.04,11.27,4.25,17.83,3.83c6.57-0.42,12.67-2.9,15.79-6.41c2.26-2.55,8.67-3.27,12.3-1.39c-1.23-2.02-5.79-2.98-8.84-1.86c3.64-1.84,9.16-2.21,13.38-0.89c-2.1-1.55-5.6-2.36-8.88-2.05s-6.21,1.72-7.43,3.58c-2.92,4.44-10.33,7.68-18.24,7.96C280.66,257.56,273.57,254.37,268.87,250.63z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.38,253.13c0.95-0.46,2.17,0.49,2.98,1.15c0.81,0.66,1.25,1.72,1.16,2.75c-0.55-1.35-2.98-2.5-4.47-2.44c-1.49,0.06-3.17,1.14-4.59,1.59c-8.3,2.66-21.95,2.02-27.82,0.4c-2.37-0.65-4.67-1.85-7.1-2.22c-2.43-0.37-5.13-0.07-6.99,1.51c1.79-2.3,5.16-3.31,7.97-2.39c-3.25-1.73-7.53-1.43-10.49,0.73c0.05-0.89,0.72-1.67,1.52-2.1c0.8-0.44,1.73-0.6,2.63-0.76c1.4-0.24,2.88-0.48,4.23-0.03c1.36,0.45,2.38,1.03,3.54,1.85c2.52,1.8,5.7,2.44,8.79,2.82C212.66,257.23,223.3,257.04,231.38,253.13z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.62,253.13c-0.95-0.46-2.17,0.49-2.98,1.15s-1.25,1.72-1.16,2.75c0.55-1.35,2.98-2.5,4.47-2.44c1.49,0.06,3.17,1.14,4.59,1.59c8.3,2.66,21.95,2.02,27.82,0.4c2.37-0.65,4.67-1.85,7.1-2.22c2.43-0.37,5.13-0.07,6.99,1.51c-1.79-2.3-5.16-3.31-7.97-2.39c3.25-1.73,7.53-1.43,10.49,0.73c-0.05-0.89-0.72-1.67-1.52-2.1c-0.8-0.44-1.73-0.6-2.63-0.76c-1.4-0.24-2.88-0.48-4.23-0.03c-1.36,0.45-2.38,1.03-3.54,1.85c-2.52,1.8-5.7,2.44-8.79,2.82C287.34,257.23,276.7,257.04,268.62,253.13z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.73,252.67c1.84,0.19,3.37,1.96,3.29,3.81c-0.59-1.42-2.2-2.19-3.74-2.27c-1.54-0.08-3.29,0.89-4.77,1.32c-4.77,1.37-9.48,1.75-14.44,1.6s-9.95-1.38-14.17-4c-1.45-0.9-2.8-1.95-4.31-2.75c-1.51-0.8-3.22-1.33-4.91-1.07c-1.68,0.26-3.3,1.46-3.65,3.13c-0.46-1.41,0.6-2.94,1.95-3.55c1.35-0.61,2.89-0.52,4.37-0.43c-2.61-1.76-6.38-1.53-8.76,0.53c1.16-1.75,3.1-3.11,5.2-3.09c2.66,0.03,4.76,2.14,6.84,3.8c5.13,4.12,11.75,6.16,18.33,6.42C219.54,256.38,226.1,254.95,231.73,252.67z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.27,252.67c-1.84,0.19-3.37,1.96-3.29,3.81c0.59-1.42,2.2-2.19,3.74-2.27c1.54-0.08,3.29,0.89,4.77,1.32c4.77,1.37,9.48,1.75,14.44,1.6c4.96-0.15,9.95-1.38,14.17-4c1.45-0.9,2.8-1.95,4.31-2.75c1.51-0.8,3.22-1.33,4.91-1.07c1.68,0.26,3.3,1.46,3.65,3.13c0.46-1.41-0.6-2.94-1.95-3.55c-1.35-0.61-2.89-0.52-4.37-0.43c2.61-1.76,6.38-1.53,8.76,0.53c-1.16-1.75-3.1-3.11-5.2-3.09c-2.66,0.03-4.76,2.14-6.84,3.8c-5.13,4.12-11.75,6.16-18.33,6.42C280.46,256.38,273.9,254.95,268.27,252.67z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M229.84,254.97c1.81,0.39,3.13,2.33,2.84,4.16c-0.43-1.48-1.19-1.67-2.71-1.93c-1.52-0.25-3.37,0.52-4.88,0.78c-4.9,0.82-9.61,0.68-14.53-0.03c-4.92-0.71-9.74-2.49-13.64-5.56c-1.34-1.05-2.56-2.26-3.97-3.22c-1.41-0.96-3.06-1.68-4.76-1.62c-1.7,0.07-3.44,1.08-3.98,2.7c-0.3-1.45,0.92-2.86,2.33-3.31c1.41-0.45,2.93-0.19,4.39,0.07c-2.4-2.04-6.17-2.23-8.77-0.45c1.35-1.61,3.43-2.75,5.51-2.48c2.64,0.33,4.49,2.66,6.37,4.55c4.64,4.67,10.99,7.44,17.49,8.43S223.53,257.79,229.84,254.97z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M270.16,254.97c-1.81,0.39-3.13,2.33-2.84,4.16c0.43-1.48,1.19-1.67,2.71-1.93c1.52-0.25,3.37,0.52,4.88,0.78c4.9,0.82,9.61,0.68,14.53-0.03c4.92-0.71,9.74-2.49,13.64-5.56c1.34-1.05,2.56-2.26,3.97-3.22s3.06-1.68,4.76-1.62s3.44,1.08,3.98,2.7c0.3-1.45-0.92-2.86-2.33-3.31c-1.41-0.45-2.93-0.19-4.39,0.07c2.4-2.04,6.17-2.23,8.77-0.45c-1.35-1.61-3.43-2.75-5.51-2.48c-2.64,0.33-4.49,2.66-6.37,4.55c-4.64,4.67-10.99,7.44-17.49,8.43C281.94,258.04,276.47,257.79,270.16,254.97z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231,251.34c1.85,0.06,4.25,1.98,4.29,3.83c-0.69-1.37-2.35-2.04-3.88-2.01c-1.54,0.02-3.22,1.12-4.67,1.64c-4.67,1.69-9.33,2.4-14.3,2.59c-4.96,0.19-10.03-0.7-14.42-3.02c-1.51-0.8-2.93-1.76-4.49-2.45c-1.56-0.69-3.31-1.11-4.97-0.73s-3.19,1.68-3.43,3.37c-0.55-1.37,0.39-2.98,1.7-3.68s2.85-0.72,4.33-0.72c-2.72-1.57-6.47-1.08-8.7,1.13c1.04-1.82,2.88-3.32,4.97-3.44c2.66-0.15,4.9,1.8,7.09,3.33c5.41,3.76,12.14,5.34,18.73,5.15C219.84,256.13,225.54,254,231,251.34z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269,251.34c-1.85,0.06-4.25,1.98-4.29,3.83c0.69-1.37,2.35-2.04,3.88-2.01c1.54,0.02,3.22,1.12,4.67,1.64c4.67,1.69,9.33,2.4,14.3,2.59c4.96,0.19,10.03-0.7,14.42-3.02c1.51-0.8,2.93-1.76,4.49-2.45c1.56-0.69,3.31-1.11,4.97-0.73s3.19,1.68,3.43,3.37c0.55-1.37-0.39-2.98-1.7-3.68c-1.3-0.7-2.85-0.72-4.33-0.72c2.72-1.57,6.47-1.08,8.7,1.13c-1.04-1.82-2.88-3.32-4.97-3.44c-2.66-0.15-4.9,1.8-7.09,3.33c-5.41,3.76-12.14,5.34-18.73,5.15C280.16,256.13,274.46,254,269,251.34z" />
            </>
          ),
        },
      },
    },
  },
};

export const eyelashPack1Lower = {
  eyelash_shape_ur294: eyelash_shape_ur294,
  eyelash_shape_gfs84: eyelash_shape_gfs84,
  eyelash_shape_fa931: eyelash_shape_fa931,
  eyelash_shape_ggh48: eyelash_shape_ggh48,
  eyelash_shape_fa92j: eyelash_shape_fa92j,
  eyelash_shape_fka41: eyelash_shape_fka41,
  eyelash_shape_fa94j: eyelash_shape_fa94j,
  eyelash_shape_fa941: eyelash_shape_fa941,
  eyelash_shape_o19a8: eyelash_shape_o19a8,
};

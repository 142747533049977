// Components
// import skintones from 'character-creator/colours/skin'
import HairColours from "character-creator/colours/hair";
import hairTopFullList from "character-creator/components/hair/top/HairTopList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

const hairTopPath = "hair.top.";

const HairTopPageConfig = {
  id: "hairTopPage",
  name: "Top",
  description: "",
  controls: [
    {
      id: "hairTopFull",
      name: "Shape",
      description: "",
      dataAttribute: [hairTopPath + "full.shape"],
      variationAttribute: "",
      previewType: "preview",
      previewSide: "F",
      previewViewbox: "0 0 500 500",
      options: hairTopFullList,
      colourControls: [
        {
          id: "hairTopColour",
          name: "Colour",
          description: "",
          dataAttribute: [hairTopPath + "colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
    },
  ],
};

const HairTopPage = {
  id: "hairTopPage",
  name: "Top",
  description: "",
  content: <SubPage page={HairTopPageConfig} />,
};

export default HairTopPage;

import React from "react";
import { connect } from "react-redux";
import { hairBackFullList } from "character-creator/components/hair/back/full/HairBackFullList";
import hairBackRightList from "./right/HairBackRightList";
import hairBackLeftList from "./left/HairBackLeftList";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";
import HairColours from "character-creator/colours/hair";
import ItemComponent from "character-creator/components/ItemComponent";

function mapStateToProps(state, ownProps) {
  const defaultHairColour =
    getCharacterConfigValue(state, "hair.colour") ?? HairColours[0];

  const hairConfig = getCharacterConfigValue(state, "hair.back");

  const hairBackColour = hairConfig?.colour
    ? hairConfig.colour
    : defaultHairColour;

  ////////////////////////
  // Full style
  ////////////////////////
  const hairConfigFull = hairConfig?.full;
  const hairObjectFull = ownProps.preview
    ? ownProps.hair.shape
    : hairConfigFull?.shape && hairConfigFull.shape !== "0"
    ? filterListById(hairBackFullList, hairConfigFull.shape).shape
    : null;
  const coloursFull = hairConfigFull?.colour
    ? hairConfigFull.colour
    : hairBackColour;

  ////////////////////////
  // Left
  ////////////////////////
  const hairConfigLeft = hairConfig?.left;
  let hairObjectLeft = ownProps.preview
    ? ownProps.hair.shape
    : hairConfigLeft?.shape && hairConfigLeft.shape !== "0"
    ? filterListById(hairBackLeftList, hairConfigLeft.shape).shape
    : null;

  const coloursLeft = hairConfigLeft?.colour
    ? hairConfigLeft.colour
    : hairBackColour;

  ////////////////////////
  // Right
  ////////////////////////
  const hairConfigRight = hairConfig?.right;
  let hairObjectRight = ownProps.preview
    ? ownProps.hair.shape
    : hairConfigRight?.shape && hairConfigRight.shape !== "0"
    ? filterListById(hairBackRightList, hairConfigRight.shape).shape
    : null;

  const coloursRight = hairConfigRight?.colour
    ? hairConfigRight.colour
    : hairBackColour;

  return {
    fullStyle: hairConfigFull?.shape && hairConfigFull?.shape !== "0",
    full: {
      shape: hairObjectFull,
      colour: coloursFull,
    },
    left: {
      shape: hairObjectLeft,
      colour: coloursLeft,
    },
    right: {
      shape: hairObjectRight,
      colour: coloursRight,
    },
  };
}

const CharacterHairBack = (props) => {
  const { fullStyle, full, left, right, previewId } = props;

  if (fullStyle || left.shape || right.shape) {
    return (
      <g id={previewId ? `g-hair-back~${previewId}` : "g-hair-back"}>
        {fullStyle && full.shape && (
          <ItemComponent
            id={previewId ? `hair-back-full~${previewId}` : "hair-back-full"}
            component={full}
            colour={full.colour}
            preview={previewId ? true : false}
          />
        )}
        {!fullStyle && left.shape && (
          <ItemComponent
            id={previewId ? `hair-back-left~${previewId}` : "hair-back-left"}
            component={left}
            colour={left.colour}
            preview={previewId ? true : false}
          />
        )}
        {!fullStyle && right.shape && (
          <ItemComponent
            id={previewId ? `hair-back-right~${previewId}` : "hair-back-right"}
            component={right}
            colour={right.colour}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(CharacterHairBack);

import React from "react";
import Background from "./background/Background";
import CharacterBody from "./body/CharacterBody";
import CharacterHead from "./head/CharacterHead";
import CharacterTail from "./body/extras/tails/CharacterTail";
import BackHorns from "./head/extras/horns/back/BackHorns";
import CharacterEars from "./head/ears/CharacterEars";
import FrontHorns from "./head/extras/horns/front/FrontHorns";
import CharacterHairBase from "./hair/base/CharacterHairBase";
import CharacterHairBack from "./hair/back/CharacterHairBack";
import CharacterHairMid from "./hair/mid/CharacterHairMid";
import CharacterHairSide from "./hair/side/CharacterHairSide";
import CharacterHairTop from "./hair/top/CharacterHairTop";
import CharacterHairFront from "./hair/front/CharacterHairFront";
import ClothingBackLayers from "./clothing/ClothingBackLayers";
import ClothingFrontLayers from "./clothing/ClothingFrontLayers";
import EyeAccessories from "./clothing/Accessories/Face/Eyes/EyeAccessories";
import NeckAccessoriesBackLayers from "./clothing/Accessories/Neck/NeckAccessoriesBackLayers";
import NeckAccessoriesFrontLayers from "./clothing/Accessories/Neck/NeckAccessoriesFrontLayers";

const Portrait = (props) => {
  return (
    <svg
      id="portrait-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <Background />
      <g id="character-base">
        <CharacterTail />

        <CharacterHairBack />

        <ClothingBackLayers />
        <NeckAccessoriesBackLayers />

        <CharacterBody />

        <ClothingFrontLayers />
        <NeckAccessoriesFrontLayers />

        <CharacterHairMid />
        <BackHorns />
        <CharacterEars />
        <CharacterHairSide />
        <CharacterHead />
        <CharacterHairBase />

        <CharacterHairTop />
        <EyeAccessories />
        <FrontHorns />
        <CharacterHairFront />
      </g>
    </svg>
  );
};

export default Portrait;

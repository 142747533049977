// Sections

import TabbedPanelContainer from "character-creator/menu/elements/TabbedPanelContainer";
import ClothingLayersPage from "./pages/ClothingLayersPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

// Attribute paths
// export const basePath = "base.";

const ClothingMenuConfig = {
  id: "Clothing",
  name: "Clothing",
  // icon: icon,
  description: "Dress to impress!",
  content: (
    <TabbedPanelContainer
      id="tabbedPanelLayer1"
      tabs={{ Layer1Page: ClothingLayersPage }}
      containerClasses={"menu-block"}
      navClasses={"nav-inline"}
    />
  ),
};

export default ClothingMenuConfig;

import React from "react";
import { PreviewBaseLeft, PreviewBaseRight, viewbox } from "../Preview";

///////////////////////////////////////
// First two studs (metal and gem)
///////////////////////////////////////
// TODO: Change to left/right in "shape"
const nose_stud_HhRGRByb2B_L = {
  id: "nose_stud_HhRGRByb2B_L",
  name: "Nose stud 1",
  description: "A basic metal nose stud",
  tags: ["metal", "basic", "stud"],
  preview: {
    viewbox: viewbox,
    base: PreviewBaseLeft,
  },
  shape: {
    base: <circle cx="239.33" cy="267.757" r="2.1" />,

    dark: (
      <path d="M238.071 266.363c-.728.728-.725 1.913.007 2.645s1.917.736 2.645.008c-.528-.091-1.193-.402-1.722-.931-.529-.529-.839-1.194-.93-1.722Z" />
    ),
    light: (
      <path d="M239.009 266.249c.437-.437 1.201-.381 1.705.123.505.505.56 1.269.124 1.705-.083-.344-.313-.787-.677-1.151-.365-.365-.807-.595-1.152-.677Z" />
    ),
  },
};

const nose_stud_HhRGRByb2B_R = {
  id: "nose_stud_HhRGRByb2B_R",
  name: "Nose stud 1",
  description: "A basic metal nose stud",
  tags: ["metal", "basic", "stud"],
  preview: {
    viewbox: viewbox,
    base: PreviewBaseRight,
  },
  shape: {
    base: <circle cx="260.67" cy="267.735" r="2.1" />,

    dark: (
      <path d="M259.412 266.341c-.728.728-.725 1.913.007 2.645s1.917.736 2.645.008c-.528-.091-1.193-.402-1.722-.931-.529-.529-.839-1.194-.93-1.722Z" />
    ),
    light: (
      <path d="M260.35 266.227c.437-.437 1.201-.381 1.705.123.505.505.56 1.269.124 1.705-.083-.344-.313-.787-.677-1.151-.365-.365-.808-.595-1.152-.677Z" />
    ),
  },
};

const nose_stud_3dqrFwShqv_L = {
  id: "nose_stud_3dqrFwShqv_L",
  name: "Nose stud 2",
  description: "A basic gem nose stud",
  tags: ["gem", "basic", "stud"],
  preview: {
    viewbox: viewbox,
    base: PreviewBaseLeft,
  },
  shape: {
    base: <circle cx="239.33" cy="267.757" r="2.1" />,

    dark: (
      <path d="m238.576 268.918-.226.696c-.136-.071-.263-.158-.379-.256l.605-.44Zm1.517-.002.462-1.432h.857c.012.089.018.18.018.273 0 .639-.286 1.211-.737 1.597l-.603-.438h.003Z" />
    ),
    light: (
      <path d="m241.171 269.701-1.081-.785.465-1.432h1.028c-.004-.425-.037-.726-.037-.726l-.998.726-1.218-.886-.466-1.432-1.971 1.432 1.218.886h-1.503l.753 2.317 1.215-.883.001-.002h1.505l.422 1.298c.294-.06.509-.25.667-.513Z" />
    ),
    lighter: (
      <path d="m238.944 265.412.706.201-.32.985 1.218.886-.455 1.432h-1.514l-.465-1.432h-1.035l-.005-.754 1.037.754 1.219-.886-.386-1.186Z" />
    ),
  },
};

const nose_stud_3dqrFwShqv_R = {
  id: "nose_stud_3dqrFwShqv_R",
  name: "Nose stud 2",
  description: "A basic gem nose stud",
  tags: ["gem", "basic", "stud"],
  preview: {
    viewbox: viewbox,
    base: PreviewBaseRight,
  },
  shape: {
    base: <circle cx="260.67" cy="267.735" r="2.1" />,

    dark: (
      <path d="m259.917 268.896-.227.696c-.135-.071-.262-.158-.378-.256l.605-.44Zm1.517-.002.462-1.432h.857c.011.089.017.18.017.273 0 .639-.285 1.211-.736 1.597l-.603-.438h.003Z" />
    ),
    light: (
      <path d="m262.512 269.679-1.081-.785.465-1.432h1.028c-.004-.425-.037-.726-.037-.726l-.998.726-1.219-.886-.465-1.432-1.971 1.432 1.218.886h-1.503l.753 2.317 1.215-.883.001-.002h1.505l.422 1.298c.294-.06.509-.25.667-.513Z" />
    ),
    lighter: (
      <path d="m260.285 265.39.706.201-.321.985 1.219.886-.455 1.432h-1.514l-.465-1.432h-1.035l-.005-.754 1.037.754 1.218-.886-.385-1.186Z" />
    ),
  },
};

export const NoseStudsPack1Left = {
  nose_stud_HhRGRByb2B_L,
  nose_stud_3dqrFwShqv_L,
};

export const NoseStudsPack1Right = {
  nose_stud_HhRGRByb2B_R,
  nose_stud_3dqrFwShqv_R,
};

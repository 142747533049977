import React from "react";

import CharacterHead from "character-creator/components/head/CharacterHead";
import Item from "character-creator/components/Item";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { connect } from "react-redux";
import { filterListById } from "character-creator/menu/menu-helper";
import { EyeballColours } from "character-creator/colours/eye";
import { EyeShapesListArray } from "../../face/eyes/shapes/EyeShapeList";

function mapStateToProps(state, ownProps) {
  const skintone = getCharacterConfigValue(state, "skintone");

  const eyeConfigLeftShape = getCharacterConfigValue(
    state,
    "eyes.base.left.shape"
  );

  console.log("undereye preview", ownProps.component.shape);
  console.log("undereyeShape", ownProps.component.id);
  console.log(
    "eye shape",
    eyeConfigLeftShape && eyeConfigLeftShape !== "0"
      ? eyeConfigLeftShape
      : "eye_shape_Uugu5"
  );

  return {
    defaultColour: { base: skintone.dark },
    defaultEyeShape:
      eyeConfigLeftShape && eyeConfigLeftShape !== "0"
        ? eyeConfigLeftShape
        : "eye_shape_Uugu5",
  };
}

const PreviewBase = (props) => {
  const { component, previewId, defaultColour, defaultEyeShape } = props;

  const previewEye = {
    ...filterListById(EyeShapesListArray, defaultEyeShape),
    shape: filterListById(EyeShapesListArray, defaultEyeShape).shape.left,
  };

  const previewItem = {
    ...component,
    shape: component.shape[defaultEyeShape]?.left,
  };
  return (
    <>
      {/* <CharacterHead preview={true} /> */}
      <Item
        id={"undereye"}
        previewId={previewId}
        previewItem={previewItem}
        defaultColour={defaultColour}
      />
      <Item
        id={"eyes"}
        previewId={previewId}
        previewItem={previewEye}
        defaultColour={filterListById(EyeballColours, "colour_eyeball_29JeE")}
      />
    </>
  );
};

export const viewbox = "170 205 80 80";
export default connect(mapStateToProps)(PreviewBase);

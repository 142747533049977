import React from "react";
import { connect } from "react-redux";
import Eyes from "character-creator/components/head/face/eyes/Eyes";
import Eyebrows from "character-creator/components/head/face/facialHair/eyebrows/CharacterEyebrows";
import Nose from "character-creator/components/head/face/nose/Nose";
import Mouth from "character-creator/components/head/face/mouth/Mouth";
import Muzzle from "character-creator/components/head/face/extras/muzzle/CharacterMuzzle";
import Whiskers from "character-creator/components/head/face/extras/whiskers/CharacterWhiskers";
import Moustache from "./facialHair/moustache/Moustache";
import Beard from "./facialHair/beard/Beard";
import FacePaint from "character-creator/components/head/markings/facePaint/CharacterFacePaint";
import Item from "character-creator/components/Item";

function mapStateToProps(state, ownProps) {
  return {};
}

const CharacterFace = (props) => {
  // const { } = props;

  return (
    <g id="g-Face">
      <FacePaint />
      <Muzzle />
      <Mouth />
      <Whiskers />
      <Beard />
      <Moustache />
      <Nose />
      <Eyebrows />

      <Eyes />
    </g>
  );
};

export default connect(mapStateToProps)(CharacterFace);

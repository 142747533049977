import { allColours } from "character-creator/colours/colourSchemes";
import { EarAccessoriesListArray } from "character-creator/components/clothing/Accessories/Ears/EarAccessoriesList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

const earPath = "ears.";
const earAccessoriesPath = "accessories.ears.";

const earAccessoriesLeftPath = earAccessoriesPath + "left.";
const earAccessoriesLeftPath1 = earAccessoriesLeftPath + "layer1.";
const earAccessoriesLeftPath2 = earAccessoriesLeftPath + "layer2.";
const earAccessoriesLeftPath3 = earAccessoriesLeftPath + "layer3.";

const earAccessoriesRightPath = earAccessoriesPath + "right.";
const earAccessoriesRightPath1 = earAccessoriesRightPath + "layer1.";
const earAccessoriesRightPath2 = earAccessoriesRightPath + "layer2.";
const earAccessoriesRightPath3 = earAccessoriesRightPath + "layer3.";

const EarAccessoriesPageConfig = {
  id: "earAccessoriesPage",
  name: "Ears",
  description: "Depending on your ears you may need to adjust the placement",
  symmetryAttribute: earAccessoriesPath + "symmetry",
  dependencies: [
    {
      description: "Ear shape",
      attribute: [earPath + "left.shape", earPath + "left.shape"],
    },
  ],
  dependencyMessage: "Please choose some ears first",
  controls: {
    symmetrical: [
      {
        id: "accessoriesEarsStyleGroup1",
        name: "Accessory 1",
        description: "",
        dataAttribute: [
          earAccessoriesLeftPath1 + "shape",
          earAccessoriesRightPath1 + "shape",
        ],
        previewType: "preview",
        options: EarAccessoriesListArray,
        colourControls: [
          {
            id: "accessoriesEarColour1",
            name: "Colour",
            description: "",
            dataAttribute: [
              earAccessoriesLeftPath1 + "colour",
              earAccessoriesRightPath1 + "colour",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "accessoriesEarTransformX1",
            name: "Horizontal",
            description: "",
            transformOpposite: true,
            dataAttribute: [
              earAccessoriesLeftPath1 + "transform.x",
              earAccessoriesRightPath1 + "transform.x",
            ],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarTransformY1",
            name: "Vertical",
            description: "",
            dataAttribute: [
              earAccessoriesLeftPath1 + "transform.y",
              earAccessoriesRightPath1 + "transform.y",
            ],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarTransformScale1",
            name: "Size",
            description: "",
            dataAttribute: [
              earAccessoriesLeftPath1 + "transform.scale",
              earAccessoriesRightPath1 + "transform.scale",
            ],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
      {
        id: "accessoriesEarsStyleGroup2",
        name: "Accessory 2",
        description: "",
        dataAttribute: [
          earAccessoriesLeftPath2 + "shape",
          earAccessoriesRightPath2 + "shape",
        ],
        previewType: "preview",
        options: EarAccessoriesListArray,
        colourControls: [
          {
            id: "accessoriesEarColour2",
            name: "Colour",
            description: "",
            dataAttribute: [
              earAccessoriesLeftPath2 + "colour",
              earAccessoriesRightPath2 + "colour",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "accessoriesEarTransformX2",
            name: "Horizontal",
            description: "",
            dataAttribute: [
              earAccessoriesLeftPath2 + "transform.x",
              earAccessoriesRightPath2 + "transform.x",
            ],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarTransformY2",
            name: "Vertical",
            description: "",
            dataAttribute: [
              earAccessoriesLeftPath2 + "transform.y",
              earAccessoriesRightPath2 + "transform.y",
            ],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarTransformScale2",
            name: "Size",
            description: "",
            dataAttribute: [
              earAccessoriesLeftPath2 + "transform.scale",
              earAccessoriesRightPath2 + "transform.scale",
            ],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
      {
        id: "accessoriesEarsStyleGroup3",
        name: "Accessory 3",
        description: "",
        dataAttribute: [
          earAccessoriesLeftPath3 + "shape",
          earAccessoriesRightPath3 + "shape",
        ],
        previewType: "preview",
        options: EarAccessoriesListArray,
        colourControls: [
          {
            id: "accessoriesEarColour3",
            name: "Colour",
            description: "",
            dataAttribute: [
              earAccessoriesLeftPath3 + "colour",
              earAccessoriesRightPath3 + "colour",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "accessoriesEarTransformX3",
            name: "Horizontal",
            description: "",
            dataAttribute: [
              earAccessoriesLeftPath3 + "transform.x",
              earAccessoriesRightPath3 + "transform.x",
            ],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarTransformY3",
            name: "Vertical",
            description: "",
            dataAttribute: [
              earAccessoriesLeftPath3 + "transform.y",
              earAccessoriesRightPath3 + "transform.y",
            ],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarTransformScale3",
            name: "Size",
            description: "",
            dataAttribute: [
              earAccessoriesLeftPath3 + "transform.scale",
              earAccessoriesRightPath3 + "transform.scale",
            ],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
    ],
    asymmetrical: [
      /////////////////////////////////////
      // Left
      /////////////////////////////////////
      {
        id: "accessoriesEarLeftStyleGroup1",
        name: "Left Accessory 1",
        description: "",
        dataAttribute: [earAccessoriesLeftPath1 + "shape"],
        previewType: "preview",
        options: EarAccessoriesListArray,

        colourControls: [
          {
            id: "accessoriesEarLeftColour1",
            name: "Colour",
            description: "",
            dataAttribute: [earAccessoriesLeftPath1 + "colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "accessoriesEarLeftTransformX1",
            name: "Horizontal",
            description: "",
            dataAttribute: [earAccessoriesLeftPath1 + "transform.x"],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarLeftTransformY1",
            name: "Vertical",
            description: "",
            dataAttribute: [earAccessoriesLeftPath1 + "transform.y"],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarLeftTransformScale1",
            name: "Size",
            description: "",
            dataAttribute: [earAccessoriesLeftPath1 + "transform.scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
      {
        id: "accessoriesEarLeftStyleGroup2",
        name: "Left Accessory 2",
        description: "",
        dataAttribute: [earAccessoriesLeftPath2 + "shape"],
        previewType: "preview",
        options: EarAccessoriesListArray,

        colourControls: [
          {
            id: "accessoriesEarLeftColour2",
            name: "Colour",
            description: "",
            dataAttribute: [earAccessoriesLeftPath2 + "colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "accessoriesEarLeftTransformX2",
            name: "Horizontal",
            description: "",
            dataAttribute: [earAccessoriesLeftPath2 + "transform.x"],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarLeftTransformY2",
            name: "Vertical",
            description: "",
            dataAttribute: [earAccessoriesLeftPath2 + "transform.y"],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarLeftTransformScale2",
            name: "Size",
            description: "",
            dataAttribute: [earAccessoriesLeftPath2 + "transform.scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
      {
        id: "accessoriesEarLeftStyleGroup3",
        name: "Left Accessory 3",
        description: "",
        dataAttribute: [earAccessoriesLeftPath3 + "shape"],
        previewType: "preview",
        options: EarAccessoriesListArray,

        colourControls: [
          {
            id: "accessoriesEarLeftColour3",
            name: "Colour",
            description: "",
            dataAttribute: [earAccessoriesLeftPath3 + "colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "accessoriesEarLeftTransformX3",
            name: "Horizontal",
            description: "",
            dataAttribute: [earAccessoriesLeftPath3 + "transform.x"],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarLeftTransformY3",
            name: "Vertical",
            description: "",
            dataAttribute: [earAccessoriesLeftPath3 + "transform.y"],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarLeftTransformScale3",
            name: "Size",
            description: "",
            dataAttribute: [earAccessoriesLeftPath3 + "transform.scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
      /////////////////////////////////////
      // Right
      /////////////////////////////////////
      {
        id: "accessoriesEarRightStyleGroup1",
        name: "Left Accessory 1",
        description: "",
        dataAttribute: [earAccessoriesRightPath1 + "shape"],
        previewType: "preview",
        options: EarAccessoriesListArray,

        colourControls: [
          {
            id: "accessoriesEarRightColour1",
            name: "Colour",
            description: "",
            dataAttribute: [earAccessoriesRightPath1 + "colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "accessoriesEarRightTransformX1",
            name: "Horizontal",
            description: "",
            dataAttribute: [earAccessoriesRightPath1 + "transform.x"],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarRightTransformY1",
            name: "Vertical",
            description: "",
            dataAttribute: [earAccessoriesRightPath1 + "transform.y"],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarRightTransformScale1",
            name: "Size",
            description: "",
            dataAttribute: [earAccessoriesRightPath1 + "transform.scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
      {
        id: "accessoriesEarRightStyleGroup2",
        name: "Right Accessory 2",
        description: "",
        dataAttribute: [earAccessoriesRightPath2 + "shape"],
        previewType: "preview",
        options: EarAccessoriesListArray,

        colourControls: [
          {
            id: "accessoriesEarRightColour2",
            name: "Colour",
            description: "",
            dataAttribute: [earAccessoriesRightPath2 + "colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "accessoriesEarRightTransformX2",
            name: "Horizontal",
            description: "",
            dataAttribute: [earAccessoriesRightPath2 + "transform.x"],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarRightTransformY2",
            name: "Vertical",
            description: "",
            dataAttribute: [earAccessoriesRightPath2 + "transform.y"],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarRightTransformScale2",
            name: "Size",
            description: "",
            dataAttribute: [earAccessoriesRightPath2 + "transform.scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
      {
        id: "accessoriesEarRightStyleGroup3",
        name: "Right Accessory 3",
        description: "",
        dataAttribute: [earAccessoriesRightPath3 + "shape"],
        previewType: "preview",
        options: EarAccessoriesListArray,

        colourControls: [
          {
            id: "accessoriesEarRightColour3",
            name: "Colour",
            description: "",
            dataAttribute: [earAccessoriesRightPath3 + "colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "accessoriesEarRightTransformX3",
            name: "Horizontal",
            description: "",
            dataAttribute: [earAccessoriesRightPath3 + "transform.x"],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarRightTransformY3",
            name: "Vertical",
            description: "",
            dataAttribute: [earAccessoriesRightPath3 + "transform.y"],
            range: {
              min: -100,
              max: 100,
              step: 1,
            },
          },
          {
            id: "accessoriesEarRightTransformScale3",
            name: "Size",
            description: "",
            dataAttribute: [earAccessoriesRightPath3 + "transform.scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
    ],
  },
};

const EarAccessoriesPage = {
  id: "earAccessoriesPage",
  name: "Ears",
  description: "",
  content: <SubPage page={EarAccessoriesPageConfig} />,
};

export default EarAccessoriesPage;

import { createSlice } from "@reduxjs/toolkit";

import { set } from "lodash";
import { characterConfig } from "./characterConfig";

const configSlice = createSlice({
  name: "config",
  initialState: characterConfig,
  reducers: {
    updateConfig: (state, action) => {
      set(state, action.payload.attribute, action.payload.value);
    },
  },
});

export const selectConfig = (state) => state.config;

export const { updateConfig } = configSlice.actions;

export default configSlice.reducer;

import React from "react";
import PreviewBase, { previewViewbox } from "../Preview";

const teeth_shape_q4dxbyxCxA_upper = {
  id: "teeth_shape_q4dxbyxCxA_upper",
  name: "Teeth 9 (Upper)",
  description: "Small fangs with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M263.736 301.981c-.924 1.599-2.504 2.955-4.036 4.21-.29.24-.76.09-.82-.26-.288-1.825-.618-2.733-1.018-3.747l-.178.019v-.468c-.107-.27-.219-.556-.335-.875 1.48.093 2.847.345 4.224.711.664.176 1.399.31 2.163.41Zm-6.63-1.135c.024.163.036.327.036.493v.93c-4.033.527-4.21 1.482-6.878 1.682v-2.565a9.755 9.755 0 0 0 1.308-.144c2.098-.367 3.889-.479 5.534-.396Zm-7.37.54v2.565c-2.668-.2-2.845-1.155-6.878-1.682v-.93c0-.166.012-.33.036-.493 1.645-.083 3.436.029 5.534.396.48.083.915.13 1.308.144Zm-7.085-.526c-.116.319-.228.605-.335.875v.468l-.178-.019c-.4 1.014-.73 1.922-1.018 3.747-.06.35-.53.5-.82.26-1.532-1.255-3.112-2.611-4.036-4.21a17.32 17.32 0 0 0 2.163-.41c1.377-.366 2.744-.618 4.224-.711Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M234.524 296.811a2.553 2.553 0 0 1 1.124 2.117s.098 1.471 0 2.197c-.215 1.6-.75 3.97-1.29 7.4-.06.35-.53.5-.82.26-1.99-1.63-3.159-4.652-3.79-6.163-.374-.893 0-2.902 0-2.902a3.342 3.342 0 0 1 1.473-2.773c1.107-.051 2.214-.096 3.303-.136Zm30.952 0c1.089.04 2.196.084 3.303.136a3.342 3.342 0 0 1 1.473 2.773s.374 2.009 0 2.902c-.631 1.511-1.8 4.533-3.79 6.163-.29.24-.76.09-.82-.26-.54-3.43-1.075-5.8-1.29-7.4-.098-.726 0-2.197 0-2.197a2.553 2.553 0 0 1 1.124-2.117Zm-24.05-.21a2.63 2.63 0 0 1 .89 1.975v6.153c0 .558-.453 1.011-1.011 1.011h-4.155a1.011 1.011 0 0 1-1.011-1.011v-5.362a3.429 3.429 0 0 1 1.256-2.651c1.419-.043 2.778-.08 4.031-.115Zm17.148 0c1.253.035 2.612.072 4.031.115a3.429 3.429 0 0 1 1.256 2.651v5.362c0 .558-.453 1.011-1.011 1.011h-4.155a1.011 1.011 0 0 1-1.011-1.011v-6.153c0-.786.344-1.492.89-1.975Zm-7.823-.117c2.174.002 4.19.011 4.675.026l.19.006a3.426 3.426 0 0 1 1.526 2.851v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-7.031c0-.471.186-.898.487-1.214Zm-1.502 0c.301.316.487.743.487 1.214v7.031c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362a3.426 3.426 0 0 1 1.526-2.851l.19-.006c.485-.015 2.501-.024 4.675-.026Zm22.376.61c.906.053 1.803.111 2.678.176a2.63 2.63 0 0 1 1.037 2.082l.01 2.238a3.027 3.027 0 0 1-3.024 3.038h-.003a1.39 1.39 0 0 1-1.39-1.384l-.021-4.711a1.792 1.792 0 0 1 .713-1.439Zm-43.25 0a1.792 1.792 0 0 1 .713 1.439l-.021 4.711a1.39 1.39 0 0 1-1.39 1.384h-.003a3.026 3.026 0 0 1-3.024-3.038l.01-2.238a2.63 2.63 0 0 1 1.037-2.082c.875-.065 1.771-.123 2.678-.176Zm-4.368 2.512a1.756 1.756 0 0 0-1.754-1.762h-.003a1.47 1.47 0 0 0-1.47 1.464l-.012 2.706a1.583 1.583 0 0 0 1.584 1.591h.003c.904 0 1.638-.731 1.642-1.635l.01-2.364Zm51.986 0a1.756 1.756 0 0 1 1.754-1.762h.003a1.47 1.47 0 0 1 1.47 1.464l.012 2.706a1.583 1.583 0 0 1-1.584 1.591h-.003a1.642 1.642 0 0 1-1.642-1.635l-.01-2.364Z" />
        </>
      ),
    },
  },
};

const teeth_shape_q4dxbyxCxA_lower = {
  id: "teeth_shape_q4dxbyxCxA_lower",
  name: "Teeth 9 (Lower)",
  description: "Small fangs with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M235.138 302.104c.7-2.145 2.679-3.861 4.585-5.422.29-.24.76-.09.82.26.414 2.63.916 3.355 1.592 5.241-1.364-.144-3.117-.243-5.438-.263a26.28 26.28 0 0 1-1.559.184Zm7.72.165v-.93c0-.166.012-.33.036-.493 1.645-.083 3.436.029 5.534.396.48.083.915.13 1.308.144v2.565c-2.668-.2-2.845-1.155-6.878-1.682Zm7.406 1.682v-2.565a9.755 9.755 0 0 0 1.308-.144c2.098-.367 3.889-.479 5.534-.396.024.163.036.327.036.493v.93c-4.033.527-4.21 1.482-6.878 1.682Zm7.601-1.768c.676-1.886 1.178-2.611 1.592-5.241.06-.35.53-.5.82-.26 1.906 1.561 3.885 3.277 4.585 5.422a26.28 26.28 0 0 1-1.559-.184c-2.321.02-4.074.119-5.438.263Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M243.778 311.997v-4.285a1.126 1.126 0 0 1 1.123-1.123h3.711a1.124 1.124 0 0 1 1.124 1.123v4.306c-1.334-.03-3.456.054-5.958-.021Zm6.486.021v-4.306a1.126 1.126 0 0 1 1.124-1.123h3.711a1.123 1.123 0 0 1 1.123 1.123v4.285c-2.502.075-4.624-.009-5.958.021Zm-12.443-.55v-3.756a1.126 1.126 0 0 1 1.123-1.123h3.294a1.12 1.12 0 0 1 1.123 1.123v4.271c-1.724-.064-3.614-.207-5.54-.515Zm18.818.515v-4.271a1.122 1.122 0 0 1 1.123-1.123h3.294a1.122 1.122 0 0 1 1.123 1.123v3.756c-1.927.308-3.816.451-5.54.515Zm-23.741-1.714c-.067-.525-.166-1.596.043-2.153.481-1.28 1.37-3.839 2.885-5.22a.375.375 0 0 1 .624.221c.411 2.904.818 4.912.982 6.266.074.615 0 1.861 0 1.861 0 .053-.002.106-.005.158a29.101 29.101 0 0 1-4.529-1.133Zm29.702 1.129a2.64 2.64 0 0 1-.005-.154s-.074-1.246 0-1.861c.164-1.354.571-3.362.982-6.266a.375.375 0 0 1 .624-.221c1.514 1.381 2.404 3.94 2.885 5.22.208.554.111 1.615.044 2.143a29.108 29.108 0 0 1-4.53 1.139Zm-34.449-3.45a2.297 2.297 0 0 1 2.113-1.397h.004a2.296 2.296 0 0 1 2.296 2.296v1.305c-1.502-.54-2.954-1.234-4.293-2.123l-.12-.081Zm39.285 2.204v-1.305a2.296 2.296 0 0 1 2.296-2.296h.004c.949 0 1.763.576 2.113 1.397l-.12.081c-1.339.889-2.791 1.583-4.293 2.123Z" />
        </>
      ),
    },
  },
};

const teeth_shape_GmapRSENie_upper = {
  id: "teeth_shape_GmapRSENie_upper",
  name: "Teeth 10 (Upper)",
  description: "Small fangs with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M264.155 302.032c-.871 3.565-2.704 6.509-4.485 9.206-.3.45-.79.16-.84-.5-.424-4.968-.933-6.391-1.614-9.886 1.531.086 2.939.342 4.357.719.785.208 1.669.357 2.582.461Zm-7.049-1.186c.024.163.036.327.036.493v.93c-4.033.527-4.21 1.482-6.878 1.682v-2.565a9.755 9.755 0 0 0 1.308-.144c2.098-.367 3.889-.479 5.534-.396Zm-7.37.54v2.565c-2.668-.2-2.845-1.155-6.878-1.682v-.93c0-.166.012-.33.036-.493 1.645-.083 3.436.029 5.534.396.48.083.915.13 1.308.144Zm-6.952-.534c-.681 3.495-1.19 4.918-1.614 9.886-.05.66-.54.95-.84.5-1.781-2.697-3.614-5.641-4.485-9.206a18.368 18.368 0 0 0 2.582-.461c1.418-.377 2.826-.633 4.357-.719Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M234.524 296.811c.134.091.26.195.376.311.435.435.762 1.028.741 1.622-.089 2.628-.719 7.575-1.279 14.145-.05.66-.54.95-.84.5-2.06-3.12-3.145-9.604-3.774-11.882-.159-.575 0-1.787 0-1.787a3.342 3.342 0 0 1 1.473-2.773c1.107-.051 2.214-.096 3.303-.136Zm30.952 0c1.089.04 2.196.084 3.303.136a3.342 3.342 0 0 1 1.473 2.773s.159 1.212 0 1.787c-.629 2.278-1.714 8.762-3.774 11.882-.3.45-.79.16-.84-.5-.56-6.57-1.19-11.517-1.279-14.145-.021-.594.306-1.187.741-1.622.116-.116.242-.22.376-.311Zm-24.05-.21a2.63 2.63 0 0 1 .89 1.975v6.153c0 .558-.453 1.011-1.011 1.011h-4.155a1.011 1.011 0 0 1-1.011-1.011v-5.362a3.429 3.429 0 0 1 1.256-2.651c1.419-.043 2.778-.08 4.031-.115Zm17.148 0c1.253.035 2.612.072 4.031.115a3.429 3.429 0 0 1 1.256 2.651v5.362c0 .558-.453 1.011-1.011 1.011h-4.155a1.011 1.011 0 0 1-1.011-1.011v-6.153c0-.786.344-1.492.89-1.975Zm-7.823-.117c2.174.002 4.19.011 4.675.026l.19.006a3.426 3.426 0 0 1 1.526 2.851v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-7.031c0-.471.186-.898.487-1.214Zm-1.502 0c.301.316.487.743.487 1.214v7.031c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362a3.426 3.426 0 0 1 1.526-2.851l.19-.006c.485-.015 2.501-.024 4.675-.026Zm22.376.61c.906.053 1.803.111 2.678.176a2.63 2.63 0 0 1 1.037 2.082l.01 2.238a3.027 3.027 0 0 1-3.024 3.038h-.003a1.39 1.39 0 0 1-1.39-1.384l-.021-4.711a1.792 1.792 0 0 1 .713-1.439Zm-43.25 0a1.792 1.792 0 0 1 .713 1.439l-.021 4.711a1.39 1.39 0 0 1-1.39 1.384h-.003a3.026 3.026 0 0 1-3.024-3.038l.01-2.238a2.63 2.63 0 0 1 1.037-2.082c.875-.065 1.771-.123 2.678-.176Zm-4.368 2.512a1.756 1.756 0 0 0-1.754-1.762h-.003a1.47 1.47 0 0 0-1.47 1.464l-.012 2.706a1.583 1.583 0 0 0 1.584 1.591h.003c.904 0 1.638-.731 1.642-1.635l.01-2.364Zm51.986 0a1.756 1.756 0 0 1 1.754-1.762h.003a1.47 1.47 0 0 1 1.47 1.464l.012 2.706a1.583 1.583 0 0 1-1.584 1.591h-.003a1.642 1.642 0 0 1-1.642-1.635l-.01-2.364Z" />
        </>
      ),
    },
  },
};

const teeth_shape_GmapRSENie_lower = {
  id: "teeth_shape_GmapRSENie_lower",
  name: "Teeth 10 (Lower)",
  description: "Long fangs with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M235.254 302.093c.745-4.033 2.757-7.279 4.705-10.231.3-.45.79-.16.84.5.422 4.952.93 6.382 1.608 9.851-1.401-.16-3.232-.272-5.71-.293-.47.071-.954.128-1.443.173Zm7.604.176v-.93c0-.166.012-.33.036-.493 1.645-.083 3.436.029 5.534.396.48.083.915.13 1.308.144v2.565c-2.668-.2-2.845-1.155-6.878-1.682Zm7.406 1.682v-2.565a9.755 9.755 0 0 0 1.308-.144c2.098-.367 3.889-.479 5.534-.396.024.163.036.327.036.493v.93c-4.033.527-4.21 1.482-6.878 1.682Zm7.329-1.738c.678-3.469 1.186-4.899 1.608-9.851.05-.66.54-.95.84-.5 1.948 2.952 3.96 6.198 4.705 10.231a25.08 25.08 0 0 1-1.443-.173c-2.478.021-4.309.133-5.71.293Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M243.778 311.997v-4.285a1.126 1.126 0 0 1 1.123-1.123h3.711a1.124 1.124 0 0 1 1.124 1.123v4.306c-1.334-.03-3.456.054-5.958-.021Zm6.486.021v-4.306a1.126 1.126 0 0 1 1.124-1.123h3.711a1.123 1.123 0 0 1 1.123 1.123v4.285c-2.502.075-4.624-.009-5.958.021Zm-12.443-.55v-3.756a1.126 1.126 0 0 1 1.123-1.123h3.294a1.12 1.12 0 0 1 1.123 1.123v4.271c-1.724-.064-3.614-.207-5.54-.515Zm18.818.515v-4.271a1.122 1.122 0 0 1 1.123-1.123h3.294a1.122 1.122 0 0 1 1.123 1.123v3.756c-1.927.308-3.816.451-5.54.515Zm5.935-.581v-2.648c0-.202.471-7.076.634-8.99.05-.66.54-.95.84-.5.902 1.365 2.265 6.601 2.614 8.252.173.819.287 1.815.362 2.78a29.198 29.198 0 0 1-4.45 1.106Zm-29.598-1.106c.075-.965.189-1.961.362-2.78.349-1.651 1.712-6.887 2.614-8.252.3-.45.79-.16.84.5.163 1.914.634 8.788.634 8.99v2.648a29.405 29.405 0 0 1-4.45-1.106Zm-4.825-2.348a2.297 2.297 0 0 1 2.113-1.397h.004a2.296 2.296 0 0 1 2.296 2.296v1.305c-1.502-.54-2.954-1.234-4.293-2.123l-.12-.081Zm39.285 2.204v-1.305a2.296 2.296 0 0 1 2.296-2.296h.004c.949 0 1.763.576 2.113 1.397l-.12.081c-1.339.889-2.791 1.583-4.293 2.123Z" />
        </>
      ),
    },
  },
};

const teeth_shape_4399ARGGxd_upper = {
  id: "teeth_shape_4399ARGGxd_upper",
  name: "Teeth 11 (Upper)",
  description: "Small square tusks with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="m264.105 302.026-1.08 4.139a.758.758 0 0 1-.733.566h-3.008a.759.759 0 0 1-.733-.566l-1.387-5.316c1.551.083 2.975.341 4.409.722.77.205 1.636.351 2.532.455Zm-6.999-1.18c.024.163.036.327.036.493v.93c-4.033.527-4.21 1.482-6.878 1.682v-2.565a9.755 9.755 0 0 0 1.308-.144c2.098-.367 3.889-.479 5.534-.396Zm-7.37.54v2.565c-2.668-.2-2.845-1.155-6.878-1.682v-.93c0-.166.012-.33.036-.493 1.645-.083 3.436.029 5.534.396.48.083.915.13 1.308.144Zm-6.9-.537-1.387 5.316a.759.759 0 0 1-.733.566h-3.008a.758.758 0 0 1-.733-.566l-1.08-4.139c.896-.104 1.762-.25 2.532-.455 1.434-.381 2.858-.639 4.409-.722Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M241.426 296.601a2.63 2.63 0 0 1 .89 1.975s-.513 8.346-.867 9.703a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566c-.354-1.357-.836-8.912-.836-8.912a3.429 3.429 0 0 1 1.256-2.651c1.419-.043 2.778-.08 4.031-.115Zm17.148 0c1.253.035 2.612.072 4.031.115a3.429 3.429 0 0 1 1.256 2.651s-.482 7.555-.836 8.912a.757.757 0 0 1-.733.566h-3.008a.757.757 0 0 1-.733-.566c-.354-1.357-.867-9.703-.867-9.703 0-.786.344-1.492.89-1.975Zm-9.325-.117c.301.316.487.743.487 1.214v7.031c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362a3.426 3.426 0 0 1 1.526-2.851l.19-.006c.485-.015 2.501-.024 4.675-.026Zm1.502 0c2.174.002 4.19.011 4.675.026l.19.006a3.426 3.426 0 0 1 1.526 2.851v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-7.031c0-.471.186-.898.487-1.214Zm-16.227.327a2.553 2.553 0 0 1 1.124 2.117v4.042a2.77 2.77 0 0 1-2.77 2.77h-.288a2.842 2.842 0 0 1-2.842-2.842v-3.178a3.342 3.342 0 0 1 1.473-2.773c1.107-.051 2.214-.096 3.303-.136Zm30.952 0c1.089.04 2.196.084 3.303.136a3.342 3.342 0 0 1 1.473 2.773v3.178a2.842 2.842 0 0 1-2.842 2.842h-.288a2.77 2.77 0 0 1-2.77-2.77v-4.042a2.553 2.553 0 0 1 1.124-2.117Zm6.149.283c.906.053 1.803.111 2.678.176a2.63 2.63 0 0 1 1.037 2.082l.01 2.238a3.027 3.027 0 0 1-3.024 3.038h-.003a1.39 1.39 0 0 1-1.39-1.384l-.021-4.711a1.792 1.792 0 0 1 .713-1.439Zm-43.25 0a1.792 1.792 0 0 1 .713 1.439l-.021 4.711a1.39 1.39 0 0 1-1.39 1.384h-.003a3.026 3.026 0 0 1-3.024-3.038l.01-2.238a2.63 2.63 0 0 1 1.037-2.082c.875-.065 1.771-.123 2.678-.176Zm-4.368 2.512a1.756 1.756 0 0 0-1.754-1.762h-.003a1.47 1.47 0 0 0-1.47 1.464l-.012 2.706a1.583 1.583 0 0 0 1.584 1.591h.003c.904 0 1.638-.731 1.642-1.635l.01-2.364Zm51.986 0a1.756 1.756 0 0 1 1.754-1.762h.003a1.47 1.47 0 0 1 1.47 1.464l.012 2.706a1.583 1.583 0 0 1-1.584 1.591h-.003a1.642 1.642 0 0 1-1.642-1.635l-.01-2.364Z" />
        </>
      ),
    },
  },
};

const teeth_shape_4399ARGGxd_lower = {
  id: "teeth_shape_4399ARGGxd_lower",
  name: "Teeth 11 (Lower)",
  description: "Small square tusks with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="m234.998 302.116 1.301-4.985a.759.759 0 0 1 .733-.566h3.008c.345 0 .646.233.733.566l1.317 5.048c-1.358-.142-3.098-.239-5.393-.259-.552.083-1.124.147-1.699.196Zm7.86.153v-.93c0-.166.012-.33.036-.493 1.645-.083 3.436.029 5.534.396.48.083.915.13 1.308.144v2.565c-2.668-.2-2.845-1.155-6.878-1.682Zm7.406 1.682v-2.565a9.755 9.755 0 0 0 1.308-.144c2.098-.367 3.889-.479 5.534-.396.024.163.036.327.036.493v.93c-4.033.527-4.21 1.482-6.878 1.682Zm7.646-1.772 1.317-5.048a.758.758 0 0 1 .733-.566h3.008c.344 0 .646.233.733.566l1.301 4.985a26.782 26.782 0 0 1-1.699-.196c-2.295.02-4.035.117-5.393.259Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M243.778 311.997v-4.285a1.126 1.126 0 0 1 1.123-1.123h3.711a1.124 1.124 0 0 1 1.124 1.123v4.306c-1.334-.03-3.456.054-5.958-.021Zm6.486.021v-4.306a1.126 1.126 0 0 1 1.124-1.123h3.711a1.123 1.123 0 0 1 1.123 1.123v4.285c-2.502.075-4.624-.009-5.958.021Zm6.446-.038.462-6.873a.757.757 0 0 1 .733-.566h3.008c.344 0 .646.232.733.566l.428 6.377c-1.864.293-3.69.433-5.364.496Zm-18.784-.496.428-6.377a.757.757 0 0 1 .733-.566h3.008c.345 0 .646.232.733.566l.462 6.873a45.71 45.71 0 0 1-5.364-.496Zm24.636-.08v-3.055a1.76 1.76 0 0 1 1.76-1.76h1.076a1.76 1.76 0 0 1 1.76 1.76v1.901a29.084 29.084 0 0 1-4.596 1.154Zm-29.72-1.154v-1.901a1.76 1.76 0 0 1 1.76-1.76h1.076a1.76 1.76 0 0 1 1.76 1.76v3.055a29.084 29.084 0 0 1-4.596-1.154Zm-4.691-2.302a2.297 2.297 0 0 1 2.113-1.397h.004a2.296 2.296 0 0 1 2.296 2.296v1.305c-1.502-.54-2.954-1.234-4.293-2.123l-.12-.081Zm39.285 2.204v-1.305a2.296 2.296 0 0 1 2.296-2.296h.004c.949 0 1.763.576 2.113 1.397l-.12.081c-1.339.889-2.791 1.583-4.293 2.123Z" />
        </>
      ),
    },
  },
};

const teeth_shape_ryzjkBaXYx_upper = {
  id: "teeth_shape_ryzjkBaXYx_upper",
  name: "Teeth 12 (Upper)",
  description: "Small round tusks with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="m264.105 302.026-.7 2.684a2.704 2.704 0 0 1-2.617 2.021h-.467c-.955 0-1.79-.645-2.031-1.57l-1.126-4.312c1.551.083 2.975.341 4.409.722.77.205 1.636.351 2.532.455Zm-6.999-1.18c.024.163.036.327.036.493v.93c-4.033.527-4.21 1.482-6.878 1.682v-2.565a9.755 9.755 0 0 0 1.308-.144c2.098-.367 3.889-.479 5.534-.396Zm-7.37.54v2.565c-2.668-.2-2.845-1.155-6.878-1.682v-.93c0-.166.012-.33.036-.493 1.645-.083 3.436.029 5.534.396.48.083.915.13 1.308.144Zm-6.9-.537-1.126 4.312a2.099 2.099 0 0 1-2.031 1.57h-.467a2.704 2.704 0 0 1-2.617-2.021l-.7-2.684c.896-.104 1.762-.25 2.532-.455 1.434-.381 2.858-.639 4.409-.722Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M241.426 296.601a2.63 2.63 0 0 1 .89 1.975s-.067 6.635-.488 8.248a2.704 2.704 0 0 1-5.233 0c-.421-1.613-.456-7.457-.456-7.457a3.429 3.429 0 0 1 1.256-2.651c1.419-.043 2.778-.08 4.031-.115Zm17.148 0c1.253.035 2.612.072 4.031.115a3.429 3.429 0 0 1 1.256 2.651s-.035 5.844-.456 7.457a2.703 2.703 0 0 1-5.233 0c-.421-1.613-.488-8.248-.488-8.248 0-.786.344-1.492.89-1.975Zm-9.325-.117c.301.316.487.743.487 1.214v7.031c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362a3.426 3.426 0 0 1 1.526-2.851l.19-.006c.485-.015 2.501-.024 4.675-.026Zm1.502 0c2.174.002 4.19.011 4.675.026l.19.006a3.426 3.426 0 0 1 1.526 2.851v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-7.031c0-.471.186-.898.487-1.214Zm-16.227.327a2.553 2.553 0 0 1 1.124 2.117v4.042a2.77 2.77 0 0 1-2.77 2.77h-.288a2.842 2.842 0 0 1-2.842-2.842v-3.178a3.342 3.342 0 0 1 1.473-2.773c1.107-.051 2.214-.096 3.303-.136Zm30.952 0c1.089.04 2.196.084 3.303.136a3.342 3.342 0 0 1 1.473 2.773v3.178a2.842 2.842 0 0 1-2.842 2.842h-.288a2.77 2.77 0 0 1-2.77-2.77v-4.042a2.553 2.553 0 0 1 1.124-2.117Zm6.149.283c.906.053 1.803.111 2.678.176a2.63 2.63 0 0 1 1.037 2.082l.01 2.238a3.027 3.027 0 0 1-3.024 3.038h-.003a1.39 1.39 0 0 1-1.39-1.384l-.021-4.711a1.792 1.792 0 0 1 .713-1.439Zm-43.25 0a1.792 1.792 0 0 1 .713 1.439l-.021 4.711a1.39 1.39 0 0 1-1.39 1.384h-.003a3.026 3.026 0 0 1-3.024-3.038l.01-2.238a2.63 2.63 0 0 1 1.037-2.082c.875-.065 1.771-.123 2.678-.176Zm-4.368 2.512a1.756 1.756 0 0 0-1.754-1.762h-.003a1.47 1.47 0 0 0-1.47 1.464l-.012 2.706a1.583 1.583 0 0 0 1.584 1.591h.003c.904 0 1.638-.731 1.642-1.635l.01-2.364Zm51.986 0a1.756 1.756 0 0 1 1.754-1.762h.003a1.47 1.47 0 0 1 1.47 1.464l.012 2.706a1.583 1.583 0 0 1-1.584 1.591h-.003a1.642 1.642 0 0 1-1.642-1.635l-.01-2.364Z" />
        </>
      ),
    },
  },
};

const teeth_shape_ryzjkBaXYx_lower = {
  id: "teeth_shape_ryzjkBaXYx_lower",
  name: "Teeth 12 (Lower)",
  description: "Small round tusks with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="m234.998 302.116.922-3.53a2.702 2.702 0 0 1 2.616-2.021h.467c.955 0 1.79.645 2.032 1.57l1.055 4.044c-1.358-.142-3.098-.239-5.393-.259-.552.083-1.124.147-1.699.196Zm7.86.153v-.93c0-.166.012-.33.036-.493 1.645-.083 3.436.029 5.534.396.48.083.915.13 1.308.144v2.565c-2.668-.2-2.845-1.155-6.878-1.682Zm7.406 1.682v-2.565a9.755 9.755 0 0 0 1.308-.144c2.098-.367 3.889-.479 5.534-.396.024.163.036.327.036.493v.93c-4.033.527-4.21 1.482-6.878 1.682Zm7.646-1.772 1.055-4.044a2.101 2.101 0 0 1 2.032-1.57h.467c1.231 0 2.306.83 2.616 2.021l.922 3.53a26.782 26.782 0 0 1-1.699-.196c-2.295.02-4.035.117-5.393.259Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M243.778 311.997v-4.285a1.126 1.126 0 0 1 1.123-1.123h3.711a1.124 1.124 0 0 1 1.124 1.123v4.306c-1.334-.03-3.456.054-5.958-.021Zm6.486.021v-4.306a1.126 1.126 0 0 1 1.124-1.123h3.711a1.123 1.123 0 0 1 1.123 1.123v4.285c-2.502.075-4.624-.009-5.958.021Zm-12.448-.551c.009-1.775.074-4.541.379-5.475.289-.888 1.373-1.451 2.396-1.451s2.107.563 2.397 1.451c.336 1.03.381 4.291.38 5.991-1.728-.063-3.621-.207-5.552-.516Zm18.816.516c-.001-1.7.044-4.961.38-5.991.29-.888 1.374-1.451 2.397-1.451 1.023 0 2.107.563 2.396 1.451.305.934.37 3.7.379 5.475-1.931.309-3.824.453-5.552.516Zm5.93-.579v-3.055a1.76 1.76 0 0 1 1.76-1.76h1.076a1.76 1.76 0 0 1 1.76 1.76v1.901a29.084 29.084 0 0 1-4.596 1.154Zm-29.72-1.154v-1.901a1.76 1.76 0 0 1 1.76-1.76h1.076a1.76 1.76 0 0 1 1.76 1.76v3.055a29.084 29.084 0 0 1-4.596-1.154Zm-4.691-2.302a2.297 2.297 0 0 1 2.113-1.397h.004a2.296 2.296 0 0 1 2.296 2.296v1.305c-1.502-.54-2.954-1.234-4.293-2.123l-.12-.081Zm39.285 2.204v-1.305a2.296 2.296 0 0 1 2.296-2.296h.004c.949 0 1.763.576 2.113 1.397l-.12.081c-1.339.889-2.791 1.583-4.293 2.123Z" />
        </>
      ),
    },
  },
};

const teeth_shape_nZe8wRtBbE_upper = {
  id: "teeth_shape_nZe8wRtBbE_upper",
  name: "Teeth 13 (Upper)",
  description: "Long square tusks with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="m264.105 302.026-1.125 6.957a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.313-8.127c1.505.09 2.892.343 4.29.715.77.205 1.636.351 2.532.455Zm-6.999-1.18c.024.163.036.327.036.493v.93c-4.033.527-4.21 1.482-6.878 1.682v-2.565a9.755 9.755 0 0 0 1.308-.144c2.098-.367 3.889-.479 5.534-.396Zm-7.37.54v2.565c-2.668-.2-2.845-1.155-6.878-1.682v-.93c0-.166.012-.33.036-.493 1.645-.083 3.436.029 5.534.396.48.083.915.13 1.308.144Zm-7.019-.53-1.313 8.127a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-1.125-6.957c.896-.104 1.762-.25 2.532-.455 1.398-.372 2.785-.625 4.29-.715Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M241.426 296.601a2.63 2.63 0 0 1 .89 1.975l-.912 14.552a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-.881-13.761a3.429 3.429 0 0 1 1.256-2.651c1.419-.043 2.778-.08 4.031-.115Zm17.148 0c1.253.035 2.612.072 4.031.115a3.429 3.429 0 0 1 1.256 2.651l-.881 13.761a.758.758 0 0 1-.748.637h-2.888a.758.758 0 0 1-.748-.637l-.912-14.552c0-.786.344-1.492.89-1.975Zm-9.325-.117c.301.316.487.743.487 1.214v7.031c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362a3.426 3.426 0 0 1 1.526-2.851l.19-.006c.485-.015 2.501-.024 4.675-.026Zm1.502 0c2.174.002 4.19.011 4.675.026l.19.006a3.426 3.426 0 0 1 1.526 2.851v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-7.031c0-.471.186-.898.487-1.214Zm-16.227.327a2.553 2.553 0 0 1 1.124 2.117v4.042a2.77 2.77 0 0 1-2.77 2.77h-.288a2.842 2.842 0 0 1-2.842-2.842v-3.178a3.342 3.342 0 0 1 1.473-2.773c1.107-.051 2.214-.096 3.303-.136Zm30.952 0c1.089.04 2.196.084 3.303.136a3.342 3.342 0 0 1 1.473 2.773v3.178a2.842 2.842 0 0 1-2.842 2.842h-.288a2.77 2.77 0 0 1-2.77-2.77v-4.042a2.553 2.553 0 0 1 1.124-2.117Zm6.149.283c.906.053 1.803.111 2.678.176a2.63 2.63 0 0 1 1.037 2.082l.01 2.238a3.027 3.027 0 0 1-3.024 3.038h-.003a1.39 1.39 0 0 1-1.39-1.384l-.021-4.711a1.792 1.792 0 0 1 .713-1.439Zm-43.25 0a1.792 1.792 0 0 1 .713 1.439l-.021 4.711a1.39 1.39 0 0 1-1.39 1.384h-.003a3.026 3.026 0 0 1-3.024-3.038l.01-2.238a2.63 2.63 0 0 1 1.037-2.082c.875-.065 1.771-.123 2.678-.176Zm-4.368 2.512a1.756 1.756 0 0 0-1.754-1.762h-.003a1.47 1.47 0 0 0-1.47 1.464l-.012 2.706a1.583 1.583 0 0 0 1.584 1.591h.003c.904 0 1.638-.731 1.642-1.635l.01-2.364Zm51.986 0a1.756 1.756 0 0 1 1.754-1.762h.003a1.47 1.47 0 0 1 1.47 1.464l.012 2.706a1.583 1.583 0 0 1-1.584 1.591h-.003a1.642 1.642 0 0 1-1.642-1.635l-.01-2.364Z" />
        </>
      ),
    },
  },
};

const teeth_shape_nZe8wRtBbE_lower = {
  id: "teeth_shape_nZe8wRtBbE_lower",
  name: "Teeth 13 (Lower)",
  description: "Long square tusks with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="m234.843 302.129 1.592-9.847a.758.758 0 0 1 .748-.637h2.888c.372 0 .688.27.748.637l1.605 9.933c-1.403-.161-3.239-.273-5.727-.295-.601.091-1.227.159-1.854.209Zm8.015.14v-.93c0-.166.012-.33.036-.493 1.645-.083 3.436.029 5.534.396.48.083.915.13 1.308.144v2.565c-2.668-.2-2.845-1.155-6.878-1.682Zm7.406 1.682v-2.565a9.755 9.755 0 0 0 1.308-.144c2.098-.367 3.889-.479 5.534-.396.024.163.036.327.036.493v.93c-4.033.527-4.21 1.482-6.878 1.682Zm7.312-1.736 1.605-9.933a.758.758 0 0 1 .748-.637h2.888c.372 0 .689.27.748.637l1.592 9.847a26.586 26.586 0 0 1-1.854-.209c-2.488.022-4.324.134-5.727.295Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M243.778 311.997v-4.285a1.126 1.126 0 0 1 1.123-1.123h3.711a1.124 1.124 0 0 1 1.124 1.123v4.306c-1.334-.03-3.456.054-5.958-.021Zm6.486.021v-4.306a1.126 1.126 0 0 1 1.124-1.123h3.711a1.123 1.123 0 0 1 1.123 1.123v4.285c-2.502.075-4.624-.009-5.958.021Zm-12.368-.538.498-10.337a.759.759 0 0 1 .747-.63h2.901c.369 0 .684.266.746.63l.523 10.838c-1.689-.064-3.533-.204-5.415-.501Zm18.793.501.523-10.838a.757.757 0 0 1 .746-.63h2.901c.369 0 .684.266.747.63l.498 10.337c-1.882.297-3.726.437-5.415.501Zm5.873-.577v-3.055a1.76 1.76 0 0 1 1.76-1.76h1.076a1.76 1.76 0 0 1 1.76 1.76v1.901a29.084 29.084 0 0 1-4.596 1.154Zm-29.72-1.154v-1.901a1.76 1.76 0 0 1 1.76-1.76h1.076a1.76 1.76 0 0 1 1.76 1.76v3.055a29.084 29.084 0 0 1-4.596-1.154Zm-4.691-2.302a2.297 2.297 0 0 1 2.113-1.397h.004a2.296 2.296 0 0 1 2.296 2.296v1.305c-1.502-.54-2.954-1.234-4.293-2.123l-.12-.081Zm39.285 2.204v-1.305a2.296 2.296 0 0 1 2.296-2.296h.004c.949 0 1.763.576 2.113 1.397l-.12.081c-1.339.889-2.791 1.583-4.293 2.123Z" />
        </>
      ),
    },
  },
};

const teeth_shape_m5EiacKuY7_upper = {
  id: "teeth_shape_m5EiacKuY7_upper",
  name: "Teeth 14 (Upper)",
  description: "Long round tusks with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="m264.105 302.026-.847 5.239a2.803 2.803 0 0 1-2.766 2.355h-.175a1.9 1.9 0 0 1-1.876-1.597l-1.158-7.167c1.505.09 2.892.343 4.29.715.77.205 1.636.351 2.532.455Zm-6.999-1.18c.024.163.036.327.036.493v.93c-4.033.527-4.21 1.482-6.878 1.682v-2.565a9.755 9.755 0 0 0 1.308-.144c2.098-.367 3.889-.479 5.534-.396Zm-7.37.54v2.565c-2.668-.2-2.845-1.155-6.878-1.682v-.93c0-.166.012-.33.036-.493 1.645-.083 3.436.029 5.534.396.48.083.915.13 1.308.144Zm-7.019-.53-1.158 7.167a1.9 1.9 0 0 1-1.876 1.597h-.175a2.803 2.803 0 0 1-2.766-2.355l-.847-5.239c.896-.104 1.762-.25 2.532-.455 1.398-.372 2.785-.625 4.29-.715Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M241.426 296.601a2.63 2.63 0 0 1 .89 1.975l-.682 13.128a2.453 2.453 0 0 1-2.421 2.061h-.003a2.452 2.452 0 0 1-2.42-2.061l-.651-12.337a3.429 3.429 0 0 1 1.256-2.651c1.419-.043 2.778-.08 4.031-.115Zm17.148 0c1.253.035 2.612.072 4.031.115a3.429 3.429 0 0 1 1.256 2.651l-.651 12.337a2.452 2.452 0 0 1-2.42 2.061h-.003a2.453 2.453 0 0 1-2.421-2.061l-.682-13.128c0-.786.344-1.492.89-1.975Zm-9.325-.117c.301.316.487.743.487 1.214v7.031c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-5.362a3.426 3.426 0 0 1 1.526-2.851l.19-.006c.485-.015 2.501-.024 4.675-.026Zm1.502 0c2.174.002 4.19.011 4.675.026l.19.006a3.426 3.426 0 0 1 1.526 2.851v5.362c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-7.031c0-.471.186-.898.487-1.214Zm-16.227.327a2.553 2.553 0 0 1 1.124 2.117v4.042a2.77 2.77 0 0 1-2.77 2.77h-.288a2.842 2.842 0 0 1-2.842-2.842v-3.178a3.342 3.342 0 0 1 1.473-2.773c1.107-.051 2.214-.096 3.303-.136Zm30.952 0c1.089.04 2.196.084 3.303.136a3.342 3.342 0 0 1 1.473 2.773v3.178a2.842 2.842 0 0 1-2.842 2.842h-.288a2.77 2.77 0 0 1-2.77-2.77v-4.042a2.553 2.553 0 0 1 1.124-2.117Zm6.149.283c.906.053 1.803.111 2.678.176a2.63 2.63 0 0 1 1.037 2.082l.01 2.238a3.027 3.027 0 0 1-3.024 3.038h-.003a1.39 1.39 0 0 1-1.39-1.384l-.021-4.711a1.792 1.792 0 0 1 .713-1.439Zm-43.25 0a1.792 1.792 0 0 1 .713 1.439l-.021 4.711a1.39 1.39 0 0 1-1.39 1.384h-.003a3.026 3.026 0 0 1-3.024-3.038l.01-2.238a2.63 2.63 0 0 1 1.037-2.082c.875-.065 1.771-.123 2.678-.176Zm-4.368 2.512a1.756 1.756 0 0 0-1.754-1.762h-.003a1.47 1.47 0 0 0-1.47 1.464l-.012 2.706a1.583 1.583 0 0 0 1.584 1.591h.003c.904 0 1.638-.731 1.642-1.635l.01-2.364Zm51.986 0a1.756 1.756 0 0 1 1.754-1.762h.003a1.47 1.47 0 0 1 1.47 1.464l.012 2.706a1.583 1.583 0 0 1-1.584 1.591h-.003a1.642 1.642 0 0 1-1.642-1.635l-.01-2.364Z" />
        </>
      ),
    },
  },
};

const teeth_shape_m5EiacKuY7_lower = {
  id: "teeth_shape_m5EiacKuY7_lower",
  name: "Teeth 14 (Lower)",
  description: "Long round tusks with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {},
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M243.778 311.997v-4.285a1.126 1.126 0 0 1 1.123-1.123h3.711a1.124 1.124 0 0 1 1.124 1.123v4.306c-1.334-.03-3.456.054-5.958-.021Zm6.486.021v-4.306a1.126 1.126 0 0 1 1.124-1.123h3.711a1.123 1.123 0 0 1 1.123 1.123v4.285c-2.502.075-4.624-.009-5.958.021Zm-12.459-.553c-.013-2.416.011-6.966.343-8.894a2.479 2.479 0 0 1 2.442-2.058h.002c1.207 0 2.238.869 2.442 2.058.358 2.077.358 7.195.34 9.412-1.733-.063-3.632-.207-5.569-.518Zm18.821.518c-.018-2.217-.018-7.335.34-9.412a2.477 2.477 0 0 1 2.442-2.058h.002c1.206 0 2.237.869 2.442 2.058.332 1.928.356 6.478.343 8.894-1.937.311-3.836.455-5.569.518Zm5.936-.579v-3.055a1.76 1.76 0 0 1 1.76-1.76h1.076a1.76 1.76 0 0 1 1.76 1.76v1.901a29.084 29.084 0 0 1-4.596 1.154Zm-29.72-1.154v-1.901a1.76 1.76 0 0 1 1.76-1.76h1.076a1.76 1.76 0 0 1 1.76 1.76v3.055a29.084 29.084 0 0 1-4.596-1.154Zm-4.691-2.302a2.297 2.297 0 0 1 2.113-1.397h.004a2.296 2.296 0 0 1 2.296 2.296v1.305c-1.502-.54-2.954-1.234-4.293-2.123l-.12-.081Zm39.285 2.204v-1.305a2.296 2.296 0 0 1 2.296-2.296h.004c.949 0 1.763.576 2.113 1.397l-.12.081c-1.339.889-2.791 1.583-4.293 2.123Z" />
        </>
      ),
    },
  },
};

const teeth_shape_7LfQi6SdSB_upper = {
  id: "teeth_shape_7LfQi6SdSB_upper",
  name: "Teeth 15 (Upper)",
  description: "Buck teeth with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "upper",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M263.303 301.92c-2.425.021-4.23.128-5.619.283v-1.319c1.352.108 2.616.349 3.889.687.537.143 1.121.257 1.73.349Zm-6.197-1.074c.024.163.036.327.036.493v6.175a1.01 1.01 0 0 1-1.011 1.011h-4.855a1.011 1.011 0 0 1-1.012-1.011v-6.128a9.755 9.755 0 0 0 1.308-.144c2.002-.351 3.724-.469 5.307-.406l.227.01Zm-7.37.54v6.128c0 .559-.453 1.011-1.012 1.011h-4.855a1.01 1.01 0 0 1-1.011-1.011v-6.175c0-.166.012-.33.036-.493a16.5 16.5 0 0 1 .227-.01c1.583-.063 3.305.055 5.307.406.48.083.915.13 1.308.144Zm-7.42-.502v1.319c-1.389-.155-3.194-.262-5.619-.283a16.165 16.165 0 0 0 1.73-.349 21.684 21.684 0 0 1 3.889-.687Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M249.249 296.484c.301.316.487.743.487 1.214v10.876c0 .558-.453 1.011-1.012 1.011h-4.855a1.011 1.011 0 0 1-1.011-1.011v-9.207a3.426 3.426 0 0 1 1.526-2.851l.19-.006c.485-.015 2.501-.024 4.675-.026Zm1.502 0c2.174.002 4.19.011 4.675.026l.19.006a3.426 3.426 0 0 1 1.526 2.851v9.207c0 .558-.453 1.011-1.011 1.011h-4.855a1.012 1.012 0 0 1-1.012-1.011v-10.876c0-.471.186-.898.487-1.214Zm-9.325.117a2.63 2.63 0 0 1 .89 1.975v6.153c0 .558-.453 1.011-1.011 1.011h-4.155a1.011 1.011 0 0 1-1.011-1.011v-5.362a3.429 3.429 0 0 1 1.256-2.651c1.419-.043 2.778-.08 4.031-.115Zm17.148 0c1.253.035 2.612.072 4.031.115a3.429 3.429 0 0 1 1.256 2.651v5.362c0 .558-.453 1.011-1.011 1.011h-4.155a1.011 1.011 0 0 1-1.011-1.011v-6.153c0-.786.344-1.492.89-1.975Zm-24.05.21a2.553 2.553 0 0 1 1.124 2.117v4.042a2.77 2.77 0 0 1-2.77 2.77h-.288a2.842 2.842 0 0 1-2.842-2.842v-3.178a3.342 3.342 0 0 1 1.473-2.773c1.107-.051 2.214-.096 3.303-.136Zm30.952 0c1.089.04 2.196.084 3.303.136a3.342 3.342 0 0 1 1.473 2.773v3.178a2.842 2.842 0 0 1-2.842 2.842h-.288a2.77 2.77 0 0 1-2.77-2.77v-4.042a2.553 2.553 0 0 1 1.124-2.117Zm-37.101.283a1.792 1.792 0 0 1 .713 1.439l-.021 4.711a1.39 1.39 0 0 1-1.39 1.384h-.003a3.026 3.026 0 0 1-3.024-3.038l.01-2.238a2.63 2.63 0 0 1 1.037-2.082c.875-.065 1.771-.123 2.678-.176Zm43.25 0c.906.053 1.803.111 2.678.176a2.63 2.63 0 0 1 1.037 2.082l.01 2.238a3.027 3.027 0 0 1-3.024 3.038h-.003a1.39 1.39 0 0 1-1.39-1.384l-.021-4.711a1.792 1.792 0 0 1 .713-1.439Zm-47.618 2.512a1.756 1.756 0 0 0-1.754-1.762h-.003a1.47 1.47 0 0 0-1.47 1.464l-.012 2.706a1.583 1.583 0 0 0 1.584 1.591h.003c.904 0 1.638-.731 1.642-1.635l.01-2.364Zm51.986 0a1.756 1.756 0 0 1 1.754-1.762h.003a1.47 1.47 0 0 1 1.47 1.464l.012 2.706a1.583 1.583 0 0 1-1.584 1.591h-.003a1.642 1.642 0 0 1-1.642-1.635l-.01-2.364Z" />
        </>
      ),
    },
  },
};

const teeth_shape_7LfQi6SdSB_lower = {
  id: "teeth_shape_7LfQi6SdSB_lower",
  name: "Teeth 15 (Lower)",
  description: "Buck teeth with straight teeth",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
    side: "lower",
  },
  shape: {
    mouth_shape_PixtqyxHDJ: {
      base: (
        <path d="M236.697 301.92a16.165 16.165 0 0 0 1.73-.349 21.684 21.684 0 0 1 3.889-.687v1.319c-1.389-.155-3.194-.262-5.619-.283Zm6.161.349v-4.637a1.01 1.01 0 0 1 1.011-1.011h4.855c.559 0 1.012.452 1.012 1.011v6.319c-2.668-.2-2.845-1.155-6.878-1.682Zm7.406 1.682v-6.319c0-.559.453-1.011 1.012-1.011h4.855a1.01 1.01 0 0 1 1.011 1.011v4.637c-4.033.527-4.21 1.482-6.878 1.682Zm7.42-1.748v-1.319c1.352.108 2.616.349 3.889.687.537.143 1.121.257 1.73.349-2.425.021-4.23.128-5.619.283Z" />
      ),
    },
    mouth_shape_Y3drg5QeSM: {
      base: (
        <>
          <path d="M243.778 311.997v-7.223a1.126 1.126 0 0 1 1.123-1.123h3.711a1.124 1.124 0 0 1 1.124 1.123v7.244c-1.334-.03-3.456.054-5.958-.021Zm6.486.021v-7.244a1.126 1.126 0 0 1 1.124-1.123h3.711a1.123 1.123 0 0 1 1.123 1.123v7.223c-2.502.075-4.624-.009-5.958.021Zm-12.443-.55v-3.756a1.126 1.126 0 0 1 1.123-1.123h3.294a1.12 1.12 0 0 1 1.123 1.123v4.271c-1.724-.064-3.614-.207-5.54-.515Zm18.818.515v-4.271a1.122 1.122 0 0 1 1.123-1.123h3.294a1.122 1.122 0 0 1 1.123 1.123v3.756c-1.927.308-3.816.451-5.54.515Zm5.923-.579v-3.055a1.76 1.76 0 0 1 1.76-1.76h1.076a1.76 1.76 0 0 1 1.76 1.76v1.901a29.084 29.084 0 0 1-4.596 1.154Zm-29.72-1.154v-1.901a1.76 1.76 0 0 1 1.76-1.76h1.076a1.76 1.76 0 0 1 1.76 1.76v3.055a29.084 29.084 0 0 1-4.596-1.154Zm-4.691-2.302a2.297 2.297 0 0 1 2.113-1.397h.004a2.296 2.296 0 0 1 2.296 2.296v1.305c-1.502-.54-2.954-1.234-4.293-2.123l-.12-.081Zm39.285 2.204v-1.305a2.296 2.296 0 0 1 2.296-2.296h.004c.949 0 1.763.576 2.113 1.397l-.12.081c-1.339.889-2.791 1.583-4.293 2.123Z" />
        </>
      ),
    },
  },
};

export const teethUpperPack2 = {
  teeth_shape_q4dxbyxCxA_upper,
  teeth_shape_GmapRSENie_upper,
  teeth_shape_4399ARGGxd_upper,
  teeth_shape_ryzjkBaXYx_upper,
  teeth_shape_nZe8wRtBbE_upper,
  teeth_shape_m5EiacKuY7_upper,
  teeth_shape_7LfQi6SdSB_upper,
};

export const teethLowerPack2 = {
  teeth_shape_q4dxbyxCxA_lower,
  teeth_shape_GmapRSENie_lower,
  teeth_shape_4399ARGGxd_lower,
  teeth_shape_ryzjkBaXYx_lower,
  teeth_shape_nZe8wRtBbE_lower,
  teeth_shape_m5EiacKuY7_lower,
  teeth_shape_7LfQi6SdSB_lower,
};

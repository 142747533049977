import React, { useState } from "react";
import TabList from "./TabList";
import SubPage from "./SubPage";

export const TabPanel = (props) => {
  const { panel, classes } = props;

  return (
    <section
      id={`panel-${panel.id}`}
      className={`${classes}`}
      role="tabpanel"
      // aria-hidden={!isSelected}
    >
      {panel.content ?? <p>No content</p>}
    </section>
  );
};

import React from "react";
import { viewbox, PreviewBase } from "../Preview";

const wrinkle_shape_42394 = {
  id: "wrinkle_shape_42394",
  name: "Wrinkle 1 (Left)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M228.3 280.84c-10.83 7.34-18.89 18.71-22.24 31.36-.13.49.58.73.78.26 4.95-11.7 12.67-22.27 21.97-30.97.38-.35-.08-.94-.51-.65z" />
    ),
  },
};

const wrinkle_shape_46546 = {
  id: "wrinkle_shape_46546",
  name: "Wrinkle 1 (Right)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M271.7 280.84c10.83 7.34 18.89 18.71 22.24 31.36.13.49-.58.73-.78.26-4.95-11.7-12.67-22.27-21.97-30.97-.38-.35.08-.94.51-.65z" />
    ),
  },
};

const wrinkle_shape_923mg = {
  id: "wrinkle_shape_923mg",
  name: "Wrinkle 1 (Both)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M228.3 280.84c-10.83 7.34-18.89 18.71-22.24 31.36-.13.49.58.73.78.26 4.95-11.7 12.67-22.27 21.97-30.97.38-.35-.08-.94-.51-.65zm43.4 0c10.83 7.34 18.89 18.71 22.24 31.36.13.49-.58.73-.78.26-4.95-11.7-12.67-22.27-21.97-30.97-.38-.35.08-.94.51-.65z" />
    ),
  },
};

const wrinkle_shape_f9ew3 = {
  id: "wrinkle_shape_f9ew3",
  name: "Wrinkle 2 (Left)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M237.12,239.51c3.8-2.75,5.3-7.58,5.65-12.09C244.41,231.24,241.96,240.7,237.12,239.51z" />
    ),
  },
};

const wrinkle_shape_392j5 = {
  id: "wrinkle_shape_392j5",
  name: "Wrinkle 2 (Right)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <>
        <path d="M262.88,239.51c-3.8-2.75-5.3-7.58-5.65-12.09C255.59,231.24,258.04,240.7,262.88,239.51z" />
      </>
    ),
  },
};

const wrinkle_shape_r9145 = {
  id: "wrinkle_shape_r9145",
  name: "Wrinkle 2 (Both)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M237.12 239.51c3.8-2.75 5.3-7.58 5.65-12.09 1.64 3.82-.81 13.28-5.65 12.09zm25.76 0c-3.8-2.75-5.3-7.58-5.65-12.09-1.64 3.82.81 13.28 5.65 12.09z" />
    ),
  },
};

const wrinkle_shape_fsi4o = {
  id: "wrinkle_shape_fsi4o",
  name: "Wrinkle 3",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <>
        <ellipse cx="250" cy="233.06" rx=".52" ry="7.69" />
        <path d="M237.99 249.04c-3.52 7.22-9.56 13.36-16.99 17.25.05.43.74.38 1.15.2 7.87-3.44 14-10.12 15.84-17.45zm24.02 0c3.52 7.22 9.56 13.36 16.99 17.25-.05.43-.74.38-1.15.2-7.87-3.44-14-10.12-15.84-17.45z" />
      </>
    ),
  },
};

const wrinkle_shape_oi24j = {
  id: "wrinkle_shape_oi24j",
  name: "Wrinkle 4 (Left)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M175.09,262.41c1.22,9.41,3.45,18.77,7.61,27.29c4.16,8.53,10.34,16.21,18.39,21.23C187.15,297.83,177.89,279.87,175.09,262.41z" />
    ),
  },
};

const wrinkle_shape_eow31 = {
  id: "wrinkle_shape_eow31",
  name: "Wrinkle 4 (Right)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M324.91,262.41c-1.22,9.41-3.45,18.77-7.61,27.29c-4.16,8.53-10.34,16.21-18.39,21.23C312.85,297.83,322.11,279.87,324.91,262.41z" />
    ),
  },
};

const wrinkle_shape_94234 = {
  id: "wrinkle_shape_94234",
  name: "Wrinkle 4 (Both)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M175.09 262.41c1.22 9.41 3.45 18.77 7.61 27.29 4.16 8.53 10.34 16.21 18.39 21.23-13.94-13.1-23.2-31.06-26-48.52zm149.82 0c-1.22 9.41-3.45 18.77-7.61 27.29-4.16 8.53-10.34 16.21-18.39 21.23 13.94-13.1 23.2-31.06 26-48.52z" />
    ),
  },
};

const wrinkle_shape_dwk71 = {
  id: "wrinkle_shape_dwk71",
  name: "Wrinkle 5",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M270.22 296.74c-1.37-4.87-3.51-9.36-6.25-13.08 1.23 5.06 3.86 9.29 6.25 13.08zm-40.44 0c1.37-4.87 3.51-9.36 6.25-13.08-1.23 5.06-3.86 9.29-6.25 13.08zm32.25 2.25c-.68-4.87-1.75-9.36-3.12-13.08.62 5.06 1.93 9.29 3.12 13.08zm-24.06 0c.68-4.87 1.75-9.36 3.12-13.08-.62 5.06-1.93 9.29-3.12 13.08zm7.5.76c.33-4.87.84-9.36 1.5-13.08-.3 5.06-.93 9.29-1.5 13.08zm9.06 0c-.33-4.87-.84-9.36-1.5-13.08.3 5.06.93 9.29 1.5 13.08z" />
    ),
  },
};

const wrinkle_shape_i2139 = {
  id: "wrinkle_shape_i2139",
  name: "Wrinkle 6",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M278.75 333.72c-1.94-4.87-4.99-9.36-8.89-13.08 1.76 5.06 5.5 9.29 8.89 13.08zm-57.5 0c1.94-4.87 4.99-9.36 8.89-13.08-1.76 5.06-5.5 9.29-8.89 13.08zm45.87 2.25c-.97-4.87-2.5-9.36-4.44-13.08.87 5.06 2.74 9.29 4.44 13.08zm-34.24 0c.97-4.87 2.5-9.36 4.44-13.08-.87 5.06-2.74 9.29-4.44 13.08zm10.67.76c.47-4.87 1.2-9.36 2.13-13.08-.42 5.07-1.32 9.3-2.13 13.08zm12.9 0c-.47-4.87-1.2-9.36-2.13-13.08.42 5.07 1.32 9.3 2.13 13.08z" />
    ),
  },
};

export const wrinklePack1 = {
  wrinkle_shape_42394,
  wrinkle_shape_46546,
  wrinkle_shape_923mg,
  wrinkle_shape_f9ew3,
  wrinkle_shape_392j5,
  wrinkle_shape_r9145,
  wrinkle_shape_fsi4o,
  wrinkle_shape_oi24j,
  wrinkle_shape_eow31,
  wrinkle_shape_94234,
  wrinkle_shape_dwk71,
  wrinkle_shape_i2139,
};

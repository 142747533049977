const teethColours = Object.values({
  teeth_colour_jf249: {
    id: "teeth_colour_jf249",
    name: "White",
    dark: "#DDDDDD",
    base: "#FEFEFE",
  },
  teeth_colour_jf2gd: {
    id: "teeth_colour_jf2gd",
    name: "Yellow",
    dark: "#C5AE81",
    base: "#DDD587",
  },
});

export default teethColours;

import React from "react";
// import { connect } from "react-redux";
import ItemComponent from "./ItemComponent";
import AllGreys from "character-creator/colours/collections/Greys";
import {
  buildTransform,
  translateTransformObject,
} from "character-creator/menu/menu-helper";

const Item = (props) => {
  const {
    id, // Used for labelling the element
    previewId, // Used for labelling the element
    previewItem, // The item to preview
    itemState, // The state object for the Item
    itemStateShape, // The shape from the config
    transform = false,
    defaultColour, // If there's a default colour to use
  } = props;

  const elementId = previewId ? `g-${id}~${previewId}` : `g-${id}`;

  const itemConfig = previewItem ?? itemStateShape;

  if (!itemConfig) {
    console.log("no itemConfig found for: ", elementId);
    // previewId && console.log("Preview item:", previewItem);
    // itemStateShape && console.log("Item state shape:", itemStateShape);
    console.log(props);
  }

  // Build the componentsList from the itemConfig, in the [{id: itemId, shape: {...}, colour: {...}}]
  let componentsList;

  // For Items that don't have new-style components, they may just have a single "shape". NOTE: for sided (i.e. left/right) items, only one side will be passed into Item at a time

  let defaultItemColour = defaultColour ?? AllGreys[Object.keys(AllGreys)[0]];

  if (itemConfig.shape) {
    componentsList = [
      {
        id: `${elementId}-shape`,
        shape: { ...itemConfig.shape },
        colour:
          itemState && itemState.colour ? itemState.colour : defaultItemColour,
      },
    ];
  } else if (itemConfig.components) {
    componentsList = Object.keys(itemConfig.components).map(
      (componentId, index) => {
        return {
          key: index,
          id: `${elementId}-${componentId}-shape`,
          shape: { ...itemConfig.components[componentId].shape },
          colour:
            itemState.colour && itemState.colour[componentId]
              ? itemState.colour[componentId]
              : defaultItemColour,
        };
      }
    );
  } else {
    console.log("error with itemConfig - no shape or components", props);
  }

  const ComponentElements = componentsList.map((component, index) => {
    return (
      <ItemComponent
        key={index}
        id={component.id}
        component={{ shape: component.shape }}
        colour={component.colour}
      />
    );
  });

  let transformAttributes = {};
  if (transform && !previewId && itemConfig && itemConfig.transform) {
    transformAttributes = translateTransformObject(
      buildTransform(itemConfig.transform)
    );
  }

  if (componentsList) {
    return (
      <g
        id={elementId}
        transform-box="fill-box"
        transform-origin="50% 50%"
        {...transformAttributes}
      >
        {ComponentElements}
      </g>
    );
  } else {
    <></>;
  }
};

export default Item;

import HairColours from "character-creator/colours/hair";
import { hairSideListLeft } from "character-creator/components/hair/side/Left/HairSideListLeft";
import { hairSideListRight } from "character-creator/components/hair/side/Right/HairSideListRight";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

const hairSidePath = "hair.side.";

const HairSidePageConfig = {
  id: "hairSidePage",
  name: "Side",
  description: "In front of the ears.",
  controls: [
    {
      id: "hairSideLeft",
      name: "Left style",
      description: "",
      dataAttribute: [hairSidePath + "left.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: hairSideListLeft,
      colourControls: [
        {
          id: "hairSideLeftColour",
          name: "Left colour",
          description: "",
          dataAttribute: [hairSidePath + "left.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
    },
    {
      id: "hairSideRight",
      name: "Right style",
      description: "",
      dataAttribute: [hairSidePath + "right.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: hairSideListRight,
      colourControls: [
        {
          id: "hairSideRightColour",
          name: "Right colour",
          description: "",
          dataAttribute: [hairSidePath + "right.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
    },
  ],
};

const HairSidePage = {
  id: "hairSidePage",
  name: "Sides",
  description: "",
  content: <SubPage page={HairSidePageConfig} />,
};

export default HairSidePage;

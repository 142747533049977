const colour_grey_5PpEs = {
  id: "colour_grey_5PpEs",
  name: "Neutral Grey",
  outline: "#333333",
  darker: "#444444",
  dark: "#666666",
  base: "#999999",
  light: "#BBBBBB",
  lighter: "#EEEEEE",
};

const colour_grey_dgJZw = {
  id: "colour_grey_dgJZw",
  name: "Silver Black",
  outline: "#050505",
  darker: "#2A2A33",
  dark: "#4A4B4F",
  base: "#65666A",
  light: "#868996",
  lighter: "#BDC6D1",
};

const colour_grey_4fDpj = {
  id: "colour_grey_4fDpj",
  name: "Mid Silver",
  outline: "#2F2A26",
  darker: "#433E40",
  dark: "#686868",
  base: "#8B8B8D",
  light: "#ADB5B8",
  lighter: "#C1CDCC",
};

const colour_grey_oer4R = {
  id: "colour_grey_oer4R",
  name: "Warm Grey",
  outline: "#13100F",
  darker: "#403830",
  dark: "#5A5248",
  base: "#776E64",
  light: "#A89D94",
  lighter: "#D8D2CC",
};

const colour_grey_6pvbA = {
  id: "colour_grey_6pvbA",
  name: "Dark Grey",
  outline: "#19181b",
  darker: "#19181b",
  dark: "#4b4950",
  base: "#7c7986",
  light: "#b1afb6",
  lighter: "#e5e4e7",
};
const colour_grey_kXNFC = {
  id: "colour_grey_kXNFC",
  name: "Black",
  outline: "#070707",
  darker: "#070707",
  dark: "#161616",
  base: "#252525",
  light: "#343434",
  lighter: "#434343",
};

export const AllGreys = Object.values({
  colour_grey_5PpEs: colour_grey_5PpEs,
  colour_grey_dgJZw: colour_grey_dgJZw,
  colour_grey_4fDpj: colour_grey_4fDpj,
  colour_grey_oer4R: colour_grey_oer4R,
  colour_grey_6pvbA: colour_grey_6pvbA,
  colour_grey_kXNFC: colour_grey_kXNFC,
});

export default AllGreys;

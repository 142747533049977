import React from "react";
import Accordion from "./Accordion";
import Tab from "./Tab";
import store, {
  getMenuConfigValue,
} from "character-creator/utilities/storeHelpers";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const navKeys = getMenuConfigValue(state, "navKeys");
  return { navKeys };
};

/*
  Tabs may be in groups! It makes the menu much tidier/better looking
*/
const TabList = (props) => {
  const { tabs, openTab, setOpenTabFunction, navKeys } = props;

  const tabKeys = Object.keys(tabs);

  const onKeyPress = (event, id) => {
    const pressedKey = event.which || event.keyCode;
    if (navKeys.includes(pressedKey)) {
      setOpenTabFunction(id);
    }
  };

  const tabList = tabKeys.map((tabKey, index) => {
    const tab = tabs[tabKey];
    const isSelected = openTab === tabKey;

    // const accordionTitle = (
    //   <>
    //     {tab.icon ? <img src={tab.icon} alt="" aria-hidden="true" /> : ""}
    //     <span>{tab.name}</span>
    //   </>
    // );

    // let ListItem = <></>;

    // if (useGroups && Object.keys(tab.pages).length > 1) {
    //   ListItem = (
    //     <li key={index} className="menu-tab menu-accordion">
    //       <Accordion
    //         key={index}
    //         id={tab.id}
    //         title={accordionTitle}
    //         expanded={index === 0 ? true : false}
    //       >
    //         <TabList
    //           tabs={tab.pages}
    //           openTab={openTab}
    //           setOpenTab={setOpenTab}
    //         />
    //       </Accordion>
    //     </li>
    //   );
    // } else if (useGroups && Object.keys(tab.pages).length === 1) {
    //   let pageKey = Object.keys(tab.pages)[0];
    //   ListItem = (
    //     <Tab
    //       key={index}
    //       id={pageKey}
    //       isSelected={pageKey === openTab}
    //       name={tab.pages[pageKey].name}
    //       // icon={tab.icon}
    //       onClick={setOpenTab}
    //     />
    //   );
    // } else {
    // ListItem = (
    //   <Tab
    //     key={index}
    //     id={tabKey}
    //     isSelected={tabKey === openTab}
    //     name={tab.name}
    //     // icon={tab.icon}
    //     onClick={setOpenTab}
    //   />
    // );
    // }

    // return ListItem;
    return (
      // <Tab
      //   key={index}
      //   id={tabKey}
      //   isSelected={tabKey === openTab}
      //   name={tab.name}
      //   // icon={tab.icon}
      //   onClick={setOpenTab}
      // />

      <li
        key={index}
        id={`tab-${tabKey}`}
        aria-controls={`panel-${tabKey}`}
        role="tab"
        tabIndex={isSelected ? -1 : 0}
        aria-selected={isSelected}
        className={`menu-tab ${isSelected ? "selected" : ""}`}
        title={`Open ${tab.name} menu`}
        data-navcontroltype="tab"
        onClick={() => setOpenTabFunction(tabKey)}
        onKeyDown={(event) => onKeyPress(event, tabKey)}
      >
        {/* {icon ? <img src={icon} alt="" aria-hidden="true" /> : ""} */}
        <span>{tab.name}</span>
      </li>
    );
  });
  // });

  return <ul role="tablist">{tabList}</ul>;
};

export default connect(mapStateToProps)(TabList);

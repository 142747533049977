import React from "react";
import { connect } from "react-redux";
import EarAccessoryFront from "./EarAccessoryFront";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";

/**
This will be used to build the different front layers of accessory
 */

function mapStateToProps(state, ownProps) {
  const earAccessoriesConfig = getCharacterConfigValue(
    state,
    "accessories.ears"
  );
  // Left
  const LeftLayer1 = earAccessoriesConfig?.left?.layer1;
  const LeftLayer2 = earAccessoriesConfig?.left?.layer2;
  const LeftLayer3 = earAccessoriesConfig?.left?.layer3;

  // Right
  const RightLayer1 = earAccessoriesConfig?.right?.layer1;
  const RightLayer2 = earAccessoriesConfig?.right?.layer2;
  const RightLayer3 = earAccessoriesConfig?.right?.layer3;

  return {
    LeftLayer1,
    LeftLayer2,
    LeftLayer3,
    RightLayer1,
    RightLayer2,
    RightLayer3,
  };
}

const EarAccessoryFrontLayers = (props) => {
  const {
    previewId,
    LeftLayer1,
    LeftLayer2,
    LeftLayer3,
    RightLayer1,
    RightLayer2,
    RightLayer3,
  } = props;

  if (
    (LeftLayer1?.shape && LeftLayer1.shape !== "0") ||
    (RightLayer1?.shape && RightLayer1.shape !== "0")
  ) {
    return (
      <g
        id={
          previewId
            ? `g-ear-accessories-front-layers~${previewId}`
            : "g-ear-accessories-front-layers"
        }
      >
        {LeftLayer3?.shape && LeftLayer3?.shape !== "0" && (
          <EarAccessoryFront layer="3" side="left" layerConfig={LeftLayer3} />
        )}
        {LeftLayer2?.shape && LeftLayer2?.shape !== "0" && (
          <EarAccessoryFront layer="2" side="left" layerConfig={LeftLayer2} />
        )}
        {LeftLayer1.shape && (
          <EarAccessoryFront layer="1" side="left" layerConfig={LeftLayer1} />
        )}

        {RightLayer3?.shape && RightLayer3?.shape !== "0" && (
          <EarAccessoryFront layer="3" side="right" layerConfig={RightLayer3} />
        )}
        {RightLayer2?.shape && RightLayer2?.shape !== "0" && (
          <EarAccessoryFront layer="2" side="right" layerConfig={RightLayer2} />
        )}
        {RightLayer1.shape && (
          <EarAccessoryFront layer="1" side="right" layerConfig={RightLayer1} />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(EarAccessoryFrontLayers);

import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import {
  ContouringCheeksShapeList,
  ContouringEyesShapeList,
  ContouringLipsShapeList,
} from "./ContouringShapeList";
import {
  buildTransform,
  filterListById,
} from "character-creator/menu/menu-helper";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";

function mapStateToProps(state, ownProps) {
  const contouringConfig = getCharacterConfigValue(state, "face-contouring");

  // ////////////////////////////////
  // Contouring 1 - Cheeks
  // ////////////////////////////////

  const contouringCheeksConfig = contouringConfig?.contouringCheeks;
  const contouringCheeksObject =
    ownProps.preview && ownProps.contouringCheeks
      ? ownProps.contouringCheeks
      : !ownProps.preview && contouringCheeksConfig?.left?.shape
      ? filterListById(
          ContouringCheeksShapeList,
          contouringCheeksConfig.left.shape
        )
      : null;

  const contouringCheeksTransformLeft =
    contouringCheeksConfig && contouringCheeksConfig.left?.transform
      ? buildTransform(contouringCheeksConfig.left.transform)
      : null;
  const contouringCheeksTransformRight = contouringCheeksConfig?.right
    ?.transform
    ? buildTransform(contouringCheeksConfig.right.transform)
    : null;

  const contouringCheeksColoursLeft =
    contouringCheeksConfig && contouringCheeksConfig.left?.colour
      ? contouringCheeksConfig.left.colour
      : { base: getCharacterConfigValue(state, "skintone").dark };

  const contouringCheeksColoursRight =
    contouringCheeksConfig && contouringCheeksConfig.right?.colour
      ? contouringCheeksConfig.right.colour
      : { base: getCharacterConfigValue(state, "skintone").dark };

  const contouringCheeksGradient = contouringCheeksObject?.gradient ?? "";

  // ////////////////////////////////
  // Contouring 2 - Eyes
  // ////////////////////////////////

  const contouringEyesConfig = contouringConfig?.contouringEyes;
  const contouringEyesObject =
    ownProps.preview && ownProps.contouringEyes
      ? ownProps.contouringEyes
      : contouringEyesConfig?.left?.shape
      ? !ownProps.preview &&
        filterListById(ContouringEyesShapeList, contouringEyesConfig.left.shape)
      : null;

  const contouringEyesGradient = contouringEyesObject?.gradient ?? "";

  const contouringEyesTransformLeft =
    contouringEyesConfig && contouringEyesConfig.left?.transform
      ? buildTransform(contouringEyesConfig.left.transform)
      : null;
  const contouringEyesTransformRight =
    contouringEyesConfig && contouringEyesConfig.right?.transform
      ? buildTransform(contouringEyesConfig.right.transform)
      : null;

  const contouringEyesColoursLeft = contouringEyesConfig?.left?.colour
    ? contouringEyesConfig.left.colour
    : { base: getCharacterConfigValue(state, "skintone").dark };
  const contouringEyesColoursRight = contouringEyesConfig?.right?.colour
    ? contouringEyesConfig.right.colour
    : { base: getCharacterConfigValue(state, "skintone").dark };

  // ////////////////////////////////
  // Contouring 3 - Lips
  // ////////////////////////////////

  const contouringLipsConfig = contouringConfig?.contouringLips;
  const contouringLipsObject =
    ownProps.preview && ownProps.contouringLips
      ? ownProps.contouringLips
      : contouringLipsConfig?.shape
      ? !ownProps.preview &&
        filterListById(ContouringLipsShapeList, contouringLipsConfig.shape)
      : null;

  const contouringLipsGradient = contouringLipsObject?.gradient ?? null;

  const contouringLipsTransform = contouringLipsConfig?.transform
    ? buildTransform(contouringLipsConfig.transform)
    : null;

  const contouringLipsColours = contouringLipsConfig?.colour
    ? contouringLipsConfig.colour
    : { base: getCharacterConfigValue(state, "skintone").dark };

  return {
    ContouringCheeks: {
      left: {
        shape: contouringCheeksObject?.shape?.left,
        colour: contouringCheeksColoursLeft,
        transform: contouringCheeksTransformLeft,
        gradient: contouringCheeksGradient?.left,
      },
      right: {
        shape: contouringCheeksObject?.shape?.right,
        colour: contouringCheeksColoursRight,
        transform: contouringCheeksTransformRight,
        gradient: contouringCheeksGradient?.right,
      },
    },
    ContouringEyes: {
      left: {
        shape: contouringEyesObject?.shape?.left,
        colour: contouringEyesColoursLeft,
        transform: contouringEyesTransformLeft,
        gradient: contouringEyesGradient?.left,
      },
      right: {
        shape: contouringEyesObject?.shape?.right,
        colour: contouringEyesColoursRight,
        transform: contouringEyesTransformRight,
        gradient: contouringEyesGradient?.right,
      },
    },
    ContouringLips: {
      shape: contouringLipsObject?.shape
        ? { shape: contouringLipsObject?.shape }
        : null,
      colour: contouringLipsColours,
      transform: contouringLipsTransform,
      gradient: contouringLipsGradient ?? "",
    },
    previewId: ownProps.previewId,
    alternateId: ownProps.alternateId,
    alternateMask: ownProps.alternateMask,
  };
}

const Contouring = (props) => {
  const { ContouringCheeks, ContouringEyes, ContouringLips, previewId } = props;

  if (
    ContouringCheeks.left.shape ||
    ContouringEyes.left.shape ||
    ContouringLips.shape
  ) {
    return (
      <g
        id={previewId ? `g-head-contouring~${previewId}` : "g-head-contouring"}
        mask="url(#head_mask)"
      >
        {ContouringCheeks.left.shape && (
          <ItemComponent
            id={
              previewId
                ? `contouring-cheeks-l~${previewId}`
                : "contouring-cheeks-l"
            }
            component={ContouringCheeks.left}
            colour={ContouringCheeks.left.colour}
            preview={previewId ? true : false}
            transform={ContouringCheeks.left.transform}
            //strokeOverride={false}
            gradient={ContouringCheeks.left.gradient}
          />
        )}
        {ContouringCheeks.right.shape && (
          <ItemComponent
            id={
              previewId
                ? `contouring-cheeks-r~${previewId}`
                : "contouring-cheeks-r"
            }
            component={ContouringCheeks.right}
            colour={ContouringCheeks.right.colour}
            preview={previewId ? true : false}
            transform={ContouringCheeks.right.transform}
            //strokeOverride={false}
            gradient={ContouringCheeks.right.gradient}
          />
        )}

        {ContouringEyes.left.shape && (
          <ItemComponent
            id={
              previewId ? `contouring-eyes-l~${previewId}` : "contouring-eyes-l"
            }
            component={ContouringEyes.left}
            colour={ContouringEyes.left.colour}
            preview={previewId ? true : false}
            transform={ContouringEyes.left.transform}
            //strokeOverride={false}
            gradient={ContouringEyes.left.gradient}
          />
        )}
        {ContouringEyes.right.shape && (
          <ItemComponent
            id={
              previewId ? `contouring-eyes-r~${previewId}` : "contouring-eyes-r"
            }
            component={ContouringEyes.right}
            colour={ContouringEyes.right.colour}
            preview={previewId ? true : false}
            transform={ContouringEyes.right.transform}
            //strokeOverride={false}
            gradient={ContouringEyes.right.gradient}
          />
        )}
        {ContouringLips.shape && (
          <ItemComponent
            id={previewId ? `contouring-lips~${previewId}` : "contouring-lips"}
            component={ContouringLips.shape}
            colour={ContouringLips.colour}
            preview={previewId ? true : false}
            transform={ContouringLips.transform}
            //strokeOverride={false}
            // gradient={ContouringLips.gradient}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Contouring);

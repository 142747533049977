import React from "react";
import { Link } from "react-router-dom";

const InvalidPage = (props) => {
  const { pageType } = props;
  return (
    <section className="panel">
      <h2>Oops! That doesn't exist...</h2>
      <p>
        Lets's{" "}
        <Link to=".." relative="path">
          go back a step
        </Link>
      </p>
    </section>
  );
};

export default InvalidPage;

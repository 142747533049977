const colour_blue_y4PNe = {
  id: "colour_blue_y4PNe",
  name: "Bright Aqua",
  outline: "hsl(203, 69%, 59%)",
  darker: "hsl(194, 73%, 59%)",
  dark: "hsl(188, 70%, 61%)",
  base: "hsl(180, 66%, 63%)",
  light: "hsl(171, 80%, 69%)",
  lighter: "hsl(163, 100%, 75%)",
};

const colour_blue_0Pc0b = {
  id: "colour_blue_0Pc0b",
  name: "Mid Blue",
  outline: "hsl(248, 58%, 21%)",
  darker: "hsl(208, 79%, 28%)",
  dark: "hsl(195, 100%, 33%)",
  base: "hsl(195, 67%, 51%)",
  light: "hsl(195, 89%, 63%)",
  lighter: "hsl(195, 85%, 76%)",
};

const colour_blue_G7MFe = {
  id: "colour_blue_G7MFe",
  name: "Mid Blue 2",
  outline: "#274C77",
  darker: "#2675BF",
  dark: "#489FF0",
  base: "#7BBAF4",
  light: "#A7CCED",
  lighter: "#CCE2F5",
};

const colour_blue_LnTh7 = {
  id: "colour_blue_LnTh7",
  name: "Grey Blue",
  outline: "hsl(240, 62%, 23%)",
  darker: "hsl(239, 36%, 32%)",
  dark: "hsl(236, 21%, 40%)",
  base: "hsl(236, 12%, 46%)",
  light: "hsl(240, 7%, 53%)",
  lighter: "hsl(240, 7%, 75%)",
};

const colour_blue_wSBzX = {
  id: "colour_blue_wSBzX",
  name: "Dark Blue",
  outline: "hsl(240, 61%, 8%)",
  darker: "hsl(240, 63%, 16%)",
  dark: "hsl(240, 62%, 23%)",
  base: "hsl(238, 39%, 34%)",
  light: "hsl(236, 28%, 44%)",
  lighter: "hsl(237, 23%, 56%)",
};

const colour_blue_hhK_4 = {
  id: "colour_blue_hhK_4",
  name: "Blurple",
  outline: "hsl(278, 100%, 36%)",
  darker: "hsl(263, 60%, 48%)",
  dark: "hsl(239, 53%, 59%)",
  base: "hsl(213, 64%, 59%)",
  light: "hsl(203, 69%, 59%)",
  lighter: "hsl(194, 73%, 59%)",
};

const colour_blue_bhHkS = {
  id: "colour_blue_bhHkS",
  name: "Light Blue",
  outline: "#599AC5",
  darker: "#6EB1DD",
  dark: "#A2D5E6",
  base: "#B1E0E8",
  light: "#D3EAEE",
  lighter: "#F2F7F8",
};

const colour_blue_fT3Wz = {
  id: "colour_blue_fT3Wz",
  name: "Icy Blue",
  outline: "#3D476A",
  darker: "#496A90",
  dark: "#85A6C7",
  base: "#A8C6DB",
  light: "#C7D9E5",
  lighter: "#EAF4F9",
};

export const AllBlues = Object.values({
  colour_blue_y4PNe: colour_blue_y4PNe,
  colour_blue_0Pc0b: colour_blue_0Pc0b,
  colour_blue_G7MFe: colour_blue_G7MFe,
  colour_blue_LnTh7: colour_blue_LnTh7,
  colour_blue_wSBzX: colour_blue_wSBzX,
  colour_blue_hhK_4: colour_blue_hhK_4,
  colour_blue_bhHkS: colour_blue_bhHkS,
  colour_blue_fT3Wz: colour_blue_fT3Wz,
});

export default AllBlues;

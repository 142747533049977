import React from "react";
import PreviewBase, { viewbox } from "../Preview";

const undereye_shape_414gs = {
  id: "undereye_shape_414gs",
  name: "Undereye (Small)",
  description: "A small undereye crescent",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        base: <circle cx="212.25" cy="250" r="19.9" />,
      },
      right: {
        base: <circle cx="287.75" cy="250" r="19.9" />,
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        base: (
          <path d="M233.88 245.72c0-7.46-15.45-6.19-25.44-6.19-9.99 0-18.1 6.05-18.1 13.51s8.24 14.75 18.1 13.51c16.3-2.04 25.44-13.36 25.44-20.83z" />
        ),
      },
      right: {
        base: (
          <path d="M266.12 245.72c0-7.46 15.45-6.19 25.44-6.19s18.1 6.05 18.1 13.51-8.24 14.75-18.1 13.51c-16.3-2.04-25.44-13.36-25.44-20.83z" />
        ),
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        base: (
          <path d="M190.25 242.73c0-8.77 14.68-7.28 24.18-7.28s17.2 7.11 17.2 15.87c0 8.77-7.83 17.33-17.2 15.87-15.49-2.4-24.18-15.7-24.18-24.46z" />
        ),
      },
      right: {
        base: (
          <path d="M309.75 242.73c0-8.77-14.68-7.28-24.18-7.28s-17.2 7.11-17.2 15.87c0 8.77 7.83 17.33 17.2 15.87 15.49-2.4 24.18-15.7 24.18-24.46z" />
        ),
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        base: (
          <path d="M191.15 259.63c0 7.92 15.07 6.58 24.83 6.58 9.75 0 17.66-6.42 17.66-14.35s-8.04-15.66-17.66-14.35c-15.91 2.18-24.83 14.19-24.83 22.12z" />
        ),
      },
      right: {
        base: (
          <path d="M308.85 259.63c0 7.92-15.07 6.58-24.83 6.58-9.75 0-17.66-6.42-17.66-14.35s8.04-15.66 17.66-14.35c15.91 2.18 24.83 14.19 24.83 22.12z" />
        ),
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        base: (
          <path d="M232.25 259.16c0 7.92-14.19 6.58-23.37 6.58-9.18 0-16.63-6.42-16.63-14.35s7.57-15.66 16.63-14.35c14.98 2.18 23.37 14.19 23.37 22.12z" />
        ),
      },
      right: {
        base: (
          <path d="M267.75 259.16c0 7.92 14.19 6.58 23.37 6.58 9.18 0 16.63-6.42 16.63-14.35s-7.57-15.66-16.63-14.35c-14.98 2.18-23.37 14.19-23.37 22.12z" />
        ),
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        base: (
          <path d="M212.25 232.54c-10.39 0-18.81 8.42-18.81 18.81v5.59c0 7.31 5.92 13.23 13.23 13.23h11.17c7.31 0 13.23-5.92 13.23-13.23v-5.59c0-10.38-8.43-18.81-18.82-18.81z" />
        ),
      },
      right: {
        base: (
          <path d="M287.75 232.54c-10.39 0-18.81 8.42-18.81 18.81v5.59c0 7.31 5.92 13.23 13.23 13.23h11.17c7.31 0 13.23-5.92 13.23-13.23v-5.59c-.01-10.38-8.43-18.81-18.82-18.81z" />
        ),
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        base: (
          <path d="M212.25 270.85c-10.51 0-19.03-8.52-19.03-19.03v-5.65c0-7.39 5.99-13.38 13.38-13.38h11.3c7.39 0 13.38 5.99 13.38 13.38v5.65c0 10.51-8.52 19.03-19.03 19.03z" />
        ),
      },
      right: {
        base: (
          <path d="M287.75 270.85c-10.51 0-19.03-8.52-19.03-19.03v-5.65c0-7.39 5.99-13.38 13.38-13.38h11.3c7.39 0 13.38 5.99 13.38 13.38v5.65c-.01 10.51-8.52 19.03-19.03 19.03z" />
        ),
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        base: (
          <>
            <ellipse cx="212.25" cy="251.58" rx="21.96" ry="11.5" />
          </>
        ),
      },
      right: {
        base: (
          <>
            <ellipse cx="287.75" cy="251.58" rx="21.96" ry="11.5" />
          </>
        ),
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        base: (
          <path d="M190.73 250.92c0 6.22 9.61 11.25 21.47 11.25s21.47-5.04 21.47-11.25-9.61-7.94-21.47-7.94c-11.85 0-21.47 1.72-21.47 7.94z" />
        ),
      },
      right: {
        base: (
          <path d="M309.22 250.92c0 6.22-9.61 11.25-21.47 11.25s-21.47-5.04-21.47-11.25 9.61-7.94 21.47-7.94 21.47 1.72 21.47 7.94z" />
        ),
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        base: (
          <path d="M190.45 253.48c0-6.22 9.74-11.25 21.76-11.25s21.76 5.04 21.76 11.25c0 6.22-9.74 7.94-21.76 7.94s-21.76-1.72-21.76-7.94z" />
        ),
      },
      right: {
        base: (
          <path d="M309.55 253.48c0-6.22-9.74-11.25-21.76-11.25-12.02 0-21.76 5.04-21.76 11.25s9.74 7.94 21.76 7.94c12.02 0 21.76-1.72 21.76-7.94z" />
        ),
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        base: (
          <path d="M233.11 252.1c0 5.92-8.96 9.13-22.39 9.13-9.86 0-18.33-7.97-22.14-12.2-1.1-1.22-.79-3.39.59-4.1 4.81-2.48 15.22-6.99 25.04-5.73 13.07 1.67 18.9 8.37 18.9 12.9z" />
        ),
      },
      right: {
        base: (
          <path d="M266.89 252.1c0 5.92 8.96 9.13 22.39 9.13 9.86 0 18.33-7.97 22.14-12.2 1.1-1.22.79-3.39-.59-4.1-4.81-2.48-15.22-6.99-25.04-5.73-13.07 1.67-18.9 8.37-18.9 12.9z" />
        ),
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        base: (
          <path d="M191.99 250.31c4.49 6.68 13.86 12.1 24.5 12.1 11.18 0 19.51-5.42 15.02-12.1-4.49-6.68-13.86-12.1-24.5-12.1-11.19 0-19.51 5.42-15.02 12.1z" />
        ),
      },
      right: {
        base: (
          <path d="M308.01 250.31c-4.49 6.68-13.86 12.1-24.5 12.1-11.18 0-19.51-5.42-15.02-12.1 4.49-6.68 13.86-12.1 24.5-12.1 11.19 0 19.51 5.42 15.02 12.1z" />
        ),
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        base: (
          <>
            <ellipse cx="212.25" cy="250.13" rx="21.96" ry="10.05" />
          </>
        ),
      },
      right: {
        base: (
          <>
            <ellipse cx="287.75" cy="250.13" rx="21.96" ry="10.05" />
          </>
        ),
      },
    },
  },
};

const undereye_shape_94jfk = {
  id: "undereye_shape_94jfk",
  name: "Undereye (Large)",
  description: "A larger undereye crescent",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        base: (
          <>
            <ellipse cx="212.25" cy="251.43" rx="19.9" ry="21.25" />
          </>
        ),
      },
      right: {
        base: (
          <>
            <ellipse cx="287.75" cy="251.43" rx="19.9" ry="21.25" />
          </>
        ),
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        base: (
          <path d="M233.88 246.53c0-8.42-15.45-6.99-25.44-6.99-9.99 0-18.1 6.83-18.1 15.25s8.24 16.65 18.1 15.25c16.3-2.31 25.44-15.09 25.44-23.51z" />
        ),
      },
      right: {
        base: (
          <path d="M266.12 246.53c0-8.42 15.45-6.99 25.44-6.99s18.1 6.83 18.1 15.25-8.24 16.65-18.1 15.25c-16.3-2.31-25.44-15.09-25.44-23.51z" />
        ),
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        base: (
          <path d="M189.25 242.27c0-9.59 15.26-7.96 25.13-7.96s17.87 7.77 17.87 17.36-8.14 18.95-17.87 17.36c-16.1-2.63-25.13-17.17-25.13-26.76z" />
        ),
      },
      right: {
        base: (
          <path d="M310.75 242.27c0-9.59-15.26-7.96-25.13-7.96s-17.87 7.77-17.87 17.36 8.14 18.95 17.87 17.36c16.1-2.63 25.13-17.17 25.13-26.76z" />
        ),
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        base: (
          <path d="M191.15 260.84c0 8.35 15.07 6.93 24.83 6.93s17.66-6.77 17.66-15.13-8.04-16.51-17.66-15.13c-15.91 2.3-24.83 14.97-24.83 23.33z" />
        ),
      },
      right: {
        base: (
          <path d="M308.85 260.84c0 8.35-15.07 6.93-24.83 6.93-9.75 0-17.66-6.77-17.66-15.13s8.04-16.51 17.66-15.13c15.91 2.3 24.83 14.97 24.83 23.33z" />
        ),
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        base: (
          <path d="M232.82 259.85c0 9.04-15.08 7.5-24.83 7.5s-17.67-7.33-17.67-16.37c0-9.04 8.04-17.87 17.67-16.37 15.91 2.49 24.83 16.2 24.83 25.24z" />
        ),
      },
      right: {
        base: (
          <path d="M267.18 259.85c0 9.04 15.08 7.5 24.83 7.5s17.67-7.33 17.67-16.37c0-9.04-8.04-17.87-17.67-16.37-15.91 2.49-24.83 16.2-24.83 25.24z" />
        ),
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        base: (
          <path d="M212.25 232.54c-10.39 0-18.81 8.95-18.81 20v5.94c0 7.77 5.92 14.06 13.23 14.06h11.17c7.31 0 13.23-6.3 13.23-14.06v-5.94c0-11.04-8.43-20-18.82-20z" />
        ),
      },
      right: {
        base: (
          <path d="M287.75 232.54c-10.39 0-18.81 8.95-18.81 20v5.94c0 7.77 5.92 14.06 13.23 14.06h11.17c7.31 0 13.23-6.3 13.23-14.06v-5.94c-.01-11.04-8.43-20-18.82-20z" />
        ),
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        base: (
          <path d="M212.25 273.05c-10.51 0-19.03-8.62-19.03-19.25v-5.72c0-7.47 5.99-13.53 13.38-13.53h11.3c7.39 0 13.38 6.06 13.38 13.53v5.72c0 10.63-8.52 19.25-19.03 19.25z" />
        ),
      },
      right: {
        base: (
          <path d="M287.75 273.05c-10.51 0-19.03-8.62-19.03-19.25v-5.72c0-7.47 5.99-13.53 13.38-13.53h11.3c7.39 0 13.38 6.06 13.38 13.53v5.72c-.01 10.63-8.52 19.25-19.03 19.25z" />
        ),
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        base: (
          <>
            <ellipse cx="212.25" cy="252.85" rx="21.96" ry="12.78" />
          </>
        ),
      },
      right: {
        base: (
          <>
            <ellipse cx="287.75" cy="252.85" rx="21.96" ry="12.78" />
          </>
        ),
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        base: (
          <path d="M190.73 251.97c0 7.04 9.61 12.75 21.47 12.75s21.47-5.71 21.47-12.75-9.61-9-21.47-9c-11.85.01-21.47 1.96-21.47 9z" />
        ),
      },
      right: {
        base: (
          <path d="M309.22 251.97c0 7.04-9.61 12.75-21.47 12.75s-21.47-5.71-21.47-12.75 9.61-9 21.47-9c11.86.01 21.47 1.96 21.47 9z" />
        ),
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        base: (
          <path d="M190.45 254.98c0-7.04 9.74-12.75 21.76-12.75s21.76 5.71 21.76 12.75-9.74 9-21.76 9-21.76-1.95-21.76-9z" />
        ),
      },
      right: {
        base: (
          <path d="M309.55 254.98c0-7.04-9.74-12.75-21.76-12.75-12.02 0-21.76 5.71-21.76 12.75s9.74 9 21.76 9c12.02 0 21.76-1.95 21.76-9z" />
        ),
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        base: (
          <path d="M233.11 253.28c0 6.45-8.96 9.95-22.39 9.95-9.86 0-18.33-8.68-22.14-13.3-1.1-1.33-.79-3.69.59-4.46 4.81-2.7 15.22-7.62 25.04-6.25 13.07 1.82 18.9 9.12 18.9 14.06z" />
        ),
      },
      right: {
        base: (
          <path d="M266.89 253.28c0 6.45 8.96 9.95 22.39 9.95 9.86 0 18.33-8.68 22.14-13.3 1.1-1.33.79-3.69-.59-4.46-4.81-2.7-15.22-7.62-25.04-6.25-13.07 1.82-18.9 9.12-18.9 14.06z" />
        ),
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        base: (
          <path d="M192.03 251.33c4.62 7.25 14.26 13.13 25.22 13.12 11.51 0 20.08-5.88 15.46-13.12-4.62-7.25-14.26-13.13-25.22-13.12-11.52 0-20.08 5.88-15.46 13.12z" />
        ),
      },
      right: {
        base: (
          <path d="M307.97 251.33c-4.62 7.25-14.26 13.13-25.22 13.12-11.51 0-20.08-5.88-15.46-13.12 4.62-7.25 14.26-13.13 25.22-13.12 11.52 0 20.08 5.88 15.46 13.12z" />
        ),
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        base: (
          <>
            <ellipse cx="212.25" cy="251.24" rx="21.96" ry="11.16" />
          </>
        ),
      },
      right: {
        base: (
          <>
            <ellipse cx="287.75" cy="251.24" rx="21.96" ry="11.16" />
          </>
        ),
      },
    },
  },
};

export const UndereyePack1 = {
  undereye_shape_414gs: undereye_shape_414gs,
  undereye_shape_94jfk: undereye_shape_94jfk,
};

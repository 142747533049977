// Icons
// import icon from "character-creator/assets/menu-icons/streamline-cog.png";

import {
  colourStyles,
  shadingStyles,
} from "character-creator/components/StyleOptions";
import SubPage from "character-creator/menu/elements/SubPage";
import TabbedPanelContainer from "character-creator/menu/elements/TabbedPanelContainer";
import React from "react";

// Sections
// import ShadingPage from "./pages/ShadingStyle";

const subTabSettingsStyle = {
  id: "subTabSettingsStyle",
  name: "Style",
  pages: {
    pageShading: {
      id: "pageShading",
      name: "Shading and Outline",
      description: "Choose the style of art to use.",
      controls: [
        {
          id: "colourStyle",
          name: "Colours",
          dataAttribute: ["colour"],
          previewType: "preview",
          stateType: "style",
          options: colourStyles,
        },
        {
          id: "shadingStyle",
          name: "Shading",
          dataAttribute: ["shading"],
          previewType: "preview",
          options: shadingStyles,
          stateType: "style",
          dependencies: [
            {
              description: "Must not be colouring style",
              stateType: "style",
              attribute: ["colour"],
              value: ["coloursNormal"],
            },
          ],
        },
      ],
    },
  },
};

export const SettingsMenuConfig = {
  id: "tabSettings",
  name: "Settings",
  // icon: icon,
  description: "Basic settings for your portrait",
  content: <SubPage page={subTabSettingsStyle.pages["pageShading"]} />,
};

// export default SettingsMenuConfig;

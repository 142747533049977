// Components
// import skintones from 'character-creator/colours/skin'
import AllSkintones from "character-creator/colours/skin";
import { NoseShapeListArray } from "character-creator/components/head/face/nose/NoseShapeList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const nosePath = "face.nose.";
const transformPath = nosePath + "transform.";

const nosePageConfig = {
  id: "nosePage",
  name: "Nose",
  description: "",
  controls: [
    {
      id: "noseShapeGroup",
      name: "Shape",
      description: "",
      dataAttribute: [nosePath + "shape"],
      previewType: "preview",
      options: NoseShapeListArray,
      colourControls: [
        {
          id: "noseColour",
          name: "Colour",
          description: "",
          dataAttribute: [nosePath + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
      transformControls: [
        {
          id: "noseTransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [transformPath + "y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "noseTransformScale",
          name: "Size",
          description: "",
          dataAttribute: [transformPath + "scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

const NosePage = {
  id: "nosePage",
  name: "Nose",
  description: "",
  content: <SubPage page={nosePageConfig} />,
};

export default NosePage;

// Components
import { ShineColours } from "character-creator/colours/eye";
import { shineShapeList } from "character-creator/components/head/face/eyes/shine/ShineShapeList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const eyePath = "eyes.";
const eyeBasePath = eyePath + "base.";
const shinePath = eyePath + "shine.";

const eyeShinePageConfig = {
  id: "eyeShinePage",
  name: "Shine",
  description: "",
  dependencyMessage: "Please choose an eye shape first",
  dependencies: [
    {
      description: "Eye shape chosen",
      attribute: [eyeBasePath + "left.shape", eyeBasePath + "right.shape"],
    },
  ],
  symmetryAttribute: shinePath + "symmetry",
  controls: {
    symmetrical: [
      {
        id: "shineShape",
        name: "Shape",
        description: "",
        dataAttribute: [shinePath + "left.shape", shinePath + "right.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: shineShapeList,
        dependencies: [
          {
            description: "Eye shape chosen",
            attribute: [
              eyeBasePath + "left.shape",
              eyeBasePath + "right.shape",
            ],
          },
        ],
        colourControls: [
          {
            id: "shineColour",
            name: "Colour",
            description: "",
            dataAttribute: [
              shinePath + "left.colour",
              shinePath + "right.colour",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: ShineColours,
          },
        ],
      },
    ],
    asymmetrical: [
      {
        id: "leftShineShape",
        name: "Left Shape",
        description: "",
        dataAttribute: [shinePath + "left.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: shineShapeList,
        dependencies: [
          {
            description: "Left eye shape chosen",
            attribute: [eyeBasePath + "left.shape"],
          },
        ],
        colourControls: [
          {
            id: "leftShineColour",
            name: "Left Colour",
            description: "",
            dataAttribute: [shinePath + "left.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: ShineColours,
          },
        ],
      },
      {
        id: "rightShineShape",
        name: "Right Shape",
        description: "",
        dataAttribute: [shinePath + "right.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: shineShapeList,
        dependencies: [
          {
            description: "Right Eye shape chosen",
            attribute: [eyeBasePath + "right.shape"],
          },
        ],
        colourControls: [
          {
            id: "rightShineColour",
            name: "Right Colour",
            description: "",
            dataAttribute: [shinePath + "right.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: ShineColours,
          },
        ],
      },
    ],
  },
};

const EyeShinePage = {
  id: "eyeShinePage",
  name: "Shine",
  description: "",
  content: <SubPage page={eyeShinePageConfig} />,
};

export default EyeShinePage;

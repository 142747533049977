// Components
import { EyeShapesListArray } from "character-creator/components/head/face/eyes/shapes/EyeShapeList";
import { EyeballColours } from "character-creator/colours/eye";
import AllSkintones from "character-creator/colours/skin";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const eyeBasePath = "eyes.base.";

const eyeShapePageConfig = {
  id: "eyeShapePage",
  name: "Eye Shape",
  description: "",
  symmetryAttribute: eyeBasePath + "symmetry",
  controls: {
    symmetrical: [
      {
        id: "eyeShape",
        name: "Shape",
        description: "",
        dataAttribute: [
          eyeBasePath + "left.shape",
          eyeBasePath + "right.shape",
        ],
        variationAttribute: "",
        previewType: "preview",
        options: EyeShapesListArray,
        colourControls: [
          {
            id: "eyeballColour",
            name: "Eyeball",
            description: "",
            dataAttribute: [
              eyeBasePath + "left.colour.eyeball",
              eyeBasePath + "right.colour.eyeball",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: EyeballColours,
          },
          {
            id: "eyelidColour",
            name: "Eyelids",
            description: "",
            dataAttribute: [
              eyeBasePath + "left.colour.eyelid",
              eyeBasePath + "right.colour.eyelid",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: AllSkintones,
          },
        ],
        transformControls: [
          {
            id: "eyesTransformY",
            name: "Height",
            description: "",
            dataAttribute: [
              eyeBasePath + "left.transform.y",
              eyeBasePath + "right.transform.y",
            ],
            range: {
              min: -50,
              max: 50,
              step: 10,
            },
          },
          {
            id: "eyesTransformX",
            name: "Distance",
            description: "",
            transformOpposite: true,
            dataAttribute: [
              eyeBasePath + "left.transform.x",
              eyeBasePath + "right.transform.x",
            ],
            range: {
              min: -25,
              max: 25,
              step: 5,
            },
          },
          {
            id: "eyesTransformScale",
            name: "Size",
            description: "",
            dataAttribute: [
              eyeBasePath + "left.transform.scale",
              eyeBasePath + "right.transform.scale",
            ],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.125,
            },
          },
        ],
      },
    ],
    asymmetrical: [
      {
        id: "leftEyeShape",
        name: "Left Shape",
        description: "",
        dataAttribute: [eyeBasePath + "left.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: EyeShapesListArray,
        colourControls: [
          {
            id: "eyeballLeftColour",
            name: "Eyeball",
            description: "",
            dataAttribute: [eyeBasePath + "left.colour.eyeball"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: EyeballColours,
          },
          {
            id: "eyelidLeftColour",
            name: "Eyelids",
            description: "",
            dataAttribute: [eyeBasePath + "left.colour.eyelid"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: AllSkintones,
          },
        ],
        transformControls: [
          {
            id: "eyesLeftTransformY",
            name: "Height",
            description: "",
            dataAttribute: [eyeBasePath + "left.transform.y"],
            range: {
              min: -50,
              max: 50,
              step: 10,
            },
          },
          {
            id: "eyesLeftTransformScale",
            name: "Size",
            description: "",
            dataAttribute: [eyeBasePath + "left.transform.scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
      {
        id: "rightEyeShape",
        name: "Right Shape",
        description: "",
        dataAttribute: [eyeBasePath + "right.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: EyeShapesListArray,
        colourControls: [
          {
            id: "eyeballRightColour",
            name: "Eyeball",
            description: "",
            dataAttribute: [eyeBasePath + "right.colour.eyeball"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: EyeballColours,
          },
          {
            id: "eyelidRightColour",
            name: "Eyelids",
            description: "",
            dataAttribute: [eyeBasePath + "right.colour.eyelid"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: AllSkintones,
          },
        ],
        transformControls: [
          {
            id: "eyesRightTransformY",
            name: "Height",
            description: "",
            dataAttribute: [eyeBasePath + "right.transform.y"],
            range: {
              min: -50,
              max: 50,
              step: 10,
            },
          },
          {
            id: "eyesRightTransformScale",
            name: "Size",
            description: "",
            dataAttribute: [eyeBasePath + "right.transform.scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
    ],
  },
};

const EyeShapePage = {
  id: "eyeShapePage",
  name: "Eye Shape",
  description: "",
  content: <SubPage page={eyeShapePageConfig} />,
};

export default EyeShapePage;

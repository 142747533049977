import React from "react";
import { Link } from "react-router-dom";
import { random } from "lodash";

import iconKofi from "assets/images/social/kofi-xs.png";
// import placeholder200 from "character-creator/assets/placeholder200.png";

const phrases = [
  "edgy anti-hero",
  "well-meaning villain",
  "vampire overlord",
  "alien fashionista",
  "posh space merchant",
  "reluctant bank robber",
  "work-appropriate avatar",
  "cheeky social media picture",
  "superhero alter-ego",
  "supervillain alter-ego",
  "ridiculous one-shot character",
  "TTRPG character who definitely isn't based on your neighbour",
  "punk-rock goblin",
];

const emojis = [
  "😾",
  "🙈",
  "😸",
  "🤖",
  "👹",
  "👽",
  "💀",
  "😈",
  "😳",
  "🧐",
  "🤓",
  "😎",
  "🥳",
  "🤠",
  "😌",
  "😏",
  "🤭",
  "🤪",
  "😚",
  "😇",
  "🧝",
  "🧞",
  "🧜",
  "🦸",
  "🦹",
  "🧙",
  "🧚",
  "🧛",
  "🧟",
];

export const CreateYourBlank = () => {
  let phrase = phrases[random(0, phrases.length)];

  return <span className="create-your-blank">{phrase}</span>;
};

export const HeaderEmoji = () => {
  let emoji = emojis[random(0, emojis.length)];

  return (
    <span className="header-icon" role="img" aria-hidden="true">
      {emoji}
    </span>
  );
};

export const Feature = (props) => {
  const { name, image } = props;
  return (
    <li className="feature-card">
      <img className="feature-img" alt="" src={image} />
      <span className="feature-name">{name}</span>
    </li>
  );
};

export const DemoButton = () => {
  return (
    <Link to="/create">
      <button className="cta cta-primary">Try it out!</button>
    </Link>
  );
};

const ActionButton = (props) => {
  const { url, text } = props;
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" title={text}>
      <div className="cta cta-primary" role="button">
        {/* <img src={icon} alt={`${name} icon`} className="cta-button-icon" /> */}
        {text && <span className="cta-button-text">{text}</span>}
      </div>
    </a>
  );
};

export const KofiButton = (props) => {
  return (
    <ActionButton
      url="https://ko-fi.com/characterart"
      name="Ko-fi"
      icon={iconKofi}
      text="Join us on Ko-fi"
    />
  );
};

export const PatreonButton = (props) => {
  return (
    <ActionButton
      url="https://patreon.com/characterartapp"
      name="Patreon"
      icon={iconKofi}
      text="Join us on Patreon"
    />
  );
};

import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import { WhiskersShapeList } from "./WhiskersShapeList";
import {
  translateTransformObject,
  buildTransform,
  filterListById,
} from "character-creator/menu/menu-helper";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";

function mapStateToProps(state, ownProps) {
  const whiskersConfig = getCharacterConfigValue(state, "whiskers");

  let whiskersObject = null;
  if (ownProps.preview && ownProps.whiskers) {
    whiskersObject = ownProps.whiskers;
  } else if (
    whiskersConfig &&
    whiskersConfig.shape &&
    whiskersConfig.shape !== "0"
  ) {
    whiskersObject = filterListById(WhiskersShapeList, whiskersConfig.shape);
  }
  const colour =
    whiskersConfig && whiskersConfig.colour
      ? {
          base: whiskersConfig.colour.lighter,
          outline: whiskersConfig.colour.outline,
        }
      : {
          base: getCharacterConfigValue(state, "skintone").lighter,
          outline: getCharacterConfigValue(state, "skintone").outline,
        };

  const transform =
    !ownProps.preview && whiskersConfig && whiskersConfig.transform
      ? translateTransformObject(buildTransform(whiskersConfig.transform))
      : "";

  return {
    colour: colour,
    Whiskers: whiskersObject,
    transform,
  };
}

const Whiskers = (props) => {
  const { colour, Whiskers, transform, previewId } = props;

  // Add other things, like extras, markings and all that fun stuff

  if (Whiskers) {
    return (
      <g
        id={previewId ? `g-whiskers~${previewId}` : "g-whiskers"}
        transform-origin="50% 50%"
        {...transform}
      >
        <ItemComponent
          id={previewId ? `whiskers~${previewId}` : "whiskers"}
          component={Whiskers}
          colour={colour}
          // strokeOverride={true}
          // linework={Whiskers.linework}
        />
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Whiskers);

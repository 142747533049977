import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const eyebrow_shape_DJ42i = {
  id: "eyebrow_shape_DJ42i",
  name: "Eyebrow 7",
  description: "Pencil-thin, straight eyebrows",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M192.78 215.64a.5.5 0 0 1 0-1h41.18a.5.5 0 0 1 0 1h-41.18Z" />
      ),
    },
    right: {
      base: (
        <path d="M266.29 215.64a.5.5 0 0 1 0-1h41.18a.5.5 0 0 1 0 1h-41.18Z" />
      ),
    },
  },
};

const eyebrow_shape_381Bj = {
  id: "eyebrow_shape_381Bj",
  name: "Eyebrow 8",
  description:
    "Pencil-thin eyebrows that arch sharply at the centre of the eye",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M237.784 220.083a.502.502 0 0 1-.408.914l-24.006-10.709-23.996 10.709a.502.502 0 0 1-.408-.914l24.2-10.8a.508.508 0 0 1 .408 0l24.21 10.8Z" />
      ),
    },
    right: {
      base: (
        <path d="M262.874 220.997a.502.502 0 0 1-.408-.914l24.21-10.8a.508.508 0 0 1 .408 0l24.2 10.8a.502.502 0 0 1-.408.914l-23.996-10.709-24.006 10.709Z" />
      ),
    },
  },
};

const eyebrow_shape_52RBH = {
  id: "eyebrow_shape_52RBH",
  name: "Eyebrow 9",
  description:
    "Pencil-thin eyebrows that angle upwards from the inner corner and end at the centre of the eye",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M238.179 220.426a.499.499 0 1 1-.418.908l-24.98-11.48a.499.499 0 1 1 .418-.908l24.98 11.48Z" />
      ),
    },
    right: {
      base: (
        <path d="M262.489 221.334a.499.499 0 1 1-.418-.908l24.98-11.48a.499.499 0 1 1 .418.908l-24.98 11.48Z" />
      ),
    },
  },
};

const eyebrow_shape_51TBH = {
  id: "eyebrow_shape_51TBH",
  name: "Eyebrow 10",
  description:
    "Sharp eyebrows that rise towards the outer corner, with a very slight straight arch",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: true,
  shape: {
    left: {
      base: (
        <path d="M239.64 221.34v-6.51c0-.22-.14-.43-.35-.51l-22.72-8.91a.566.566 0 0 0-.26-.03l-17.24 1.86c-.61.07-.67.92-.08 1.07l21.53 5.44.09.03 18.25 8.05c.38.17.78-.09.78-.49Z" />
      ),
      dark: (
        <path d="m219.579 213.512-1.829-1.372c4.26.18 8.46 1.42 12.13 3.58-.34-.42-.69-.84-1.04-1.26 1.91.92 3.83 1.83 5.74 2.75-1.35-1.68-2.69-3.35-4.04-5.02 3.09.83 5.88 2.73 7.78 5.31a21.164 21.164 0 0 1-.259-3.662l1.229.482c.21.08.35.29.35.51v6.51c0 .4-.4.66-.78.49l-18.25-8.05-.09-.03-.941-.238Z" />
      ),
      light: (
        <path d="M223.358 208.072c.218.405.398.831.532 1.278a14.351 14.351 0 0 0-5.76-1.89c.07.41.13.81.2 1.22-2.47-1.54-5.61-1.94-8.39-1.09.89.49 1.79.99 2.68 1.48-4.638-.314-9.276-.619-13.914-.931-.257-.298-.119-.844.364-.899l17.24-1.86c.09-.01.18 0 .26.03l6.788 2.662Z" />
      ),
    },
    right: {
      base: (
        <path d="M260.61 221.34v-6.51c0-.22.14-.43.35-.51l22.72-8.91c.08-.03.17-.04.26-.03l17.24 1.86c.61.07.67.92.08 1.07l-21.53 5.44-.09.03-18.25 8.05a.541.541 0 0 1-.78-.49Z" />
      ),
      dark: (
        <path d="m280.671 213.512-.941.238-.09.03-18.25 8.05a.541.541 0 0 1-.78-.49v-6.51c0-.22.14-.43.35-.51l1.229-.482a21.164 21.164 0 0 1-.259 3.662c1.9-2.58 4.69-4.48 7.78-5.31-1.35 1.67-2.69 3.34-4.04 5.02 1.91-.92 3.83-1.83 5.74-2.75-.35.42-.7.84-1.04 1.26 3.67-2.16 7.87-3.4 12.13-3.58l-1.829 1.372Z" />
      ),
      light: (
        <path d="M301.544 208.139c-4.638.312-9.276.617-13.914.931.89-.49 1.79-.99 2.68-1.48-2.78-.85-5.92-.45-8.39 1.09.07-.41.13-.81.2-1.22-2.03.21-4 .86-5.76 1.89.134-.447.314-.873.532-1.278l6.788-2.662c.08-.03.17-.04.26-.03l17.24 1.86c.483.055.621.601.364.899Z" />
      ),
    },
  },
};

const eyebrow_shape_418BE = {
  id: "eyebrow_shape_418BE",
  name: "Eyebrow 11",
  description:
    "Comma-shaped, feathered brows that curve up and end above the centre of the eye",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M221.93 215.09c5.29 5.35 8.65 9.11 10.57 9.52 7.19 1.52 5.75-6.74 4.45-9.77-.16-.37-.38-.77-.68-1.19-.07 2.46-.59 5.1-1.45 6.74-.08-.9.35-4.33.28-5.23-.09-1.12-.21-2.28-.48-3.38-.42-.4-.89-.8-1.42-1.19.19 1.8-.05 3.89-.91 4.5-.3-1.79-1.27-4.42-2.53-6.6-.76-.36-1.58-.72-2.46-1.05 1.03 1.73 1.38 3.86.91 5.83-.8-2.28-1.8-4.74-3.3-6.59-.64-.18-1.32-.35-2.02-.5.69.98 1.26 2.05 1.69 3.17-1.24-1.22-2.56-2.48-3.98-3.57-1.39-.2-2.88-.33-4.46-.38.72.68 1.38 1.43 1.97 2.23-2.58-.68-5.16-1.37-7.74-2.05-.61 1.02-2.26 1-2.47 1.02-7.56 1.03 6.09.46 14.03 8.49Z" />
      ),
      dark: (
        <path d="M225.396 218.626a3.201 3.201 0 0 1-.016-.096c1.07.8 2.38 1.28 3.71 1.37a9.805 9.805 0 0 1-1.77-4.43c1.26 1.37 2.64 2.63 4.12 3.76a9.556 9.556 0 0 0 2.39-6.28 8.749 8.749 0 0 1 1.238 3.386c-.092 1.385-.306 3.396-.248 4.054.86-1.64 1.38-4.28 1.45-6.74.3.42.52.82.68 1.19 1.3 3.03 2.74 11.29-4.45 9.77-1.462-.312-3.758-2.566-7.104-5.984Z" />
      ),
      light: (
        <path d="M225.748 207.85a3.609 3.609 0 0 1-.248 1.7 6.534 6.534 0 0 0-2.193-2.744 15.456 15.456 0 0 0-.417-.626c.7.15 1.38.32 2.02.5.298.368.577.76.838 1.17Zm-4.203-1.309c.314 1.267.426 2.58.325 3.879a11.074 11.074 0 0 0-3.982-3.084 16.643 16.643 0 0 0-1.748-1.936c1.58.05 3.07.18 4.46.38.32.246.635.5.945.761Zm-6.074.391c.013.503.023 1.005.039 1.508a33.453 33.453 0 0 0-6.25-2.053c.431-.143.859-.387 1.11-.807 1.7.448 3.401.901 5.101 1.352Z" />
      ),
    },
    right: {
      base: (
        <path d="M278.32 215.09c-5.29 5.35-8.65 9.11-10.57 9.52-7.19 1.52-5.75-6.74-4.45-9.77.16-.37.38-.77.68-1.19.07 2.46.59 5.1 1.45 6.74.08-.9-.35-4.33-.28-5.23.09-1.12.21-2.28.48-3.38.42-.4.89-.8 1.42-1.19-.19 1.8.05 3.89.91 4.5.3-1.79 1.27-4.42 2.53-6.6.76-.36 1.58-.72 2.46-1.05-1.03 1.73-1.38 3.86-.91 5.83.8-2.28 1.8-4.74 3.3-6.59.64-.18 1.32-.35 2.02-.5-.69.98-1.26 2.05-1.69 3.17 1.24-1.22 2.56-2.48 3.98-3.57 1.39-.2 2.88-.33 4.46-.38-.72.68-1.38 1.43-1.97 2.23 2.58-.68 5.16-1.37 7.74-2.05.61 1.02 2.26 1 2.47 1.02 7.56 1.03-6.09.46-14.03 8.49Z" />
      ),
      dark: (
        <path d="M274.854 218.626c-3.346 3.418-5.642 5.672-7.104 5.984-7.19 1.52-5.75-6.74-4.45-9.77.16-.37.38-.77.68-1.19.07 2.46.59 5.1 1.45 6.74.058-.658-.156-2.669-.248-4.054a8.749 8.749 0 0 1 1.238-3.386c0 2.29.87 4.57 2.39 6.28 1.48-1.13 2.86-2.39 4.12-3.76a9.805 9.805 0 0 1-1.77 4.43c1.33-.09 2.64-.57 3.71-1.37l-.016.096Z" />
      ),
      light: (
        <path d="M276.943 206.806a6.534 6.534 0 0 0-2.193 2.744 3.609 3.609 0 0 1-.248-1.7c.261-.41.54-.802.838-1.17.64-.18 1.32-.35 2.02-.5-.144.205-.283.414-.417.626Zm5.419.53a11.074 11.074 0 0 0-3.982 3.084 12.213 12.213 0 0 1 .325-3.879c.31-.261.625-.515.945-.761 1.39-.2 2.88-.33 4.46-.38a16.643 16.643 0 0 0-1.748 1.936Zm8.628-.949a33.453 33.453 0 0 0-6.25 2.053c.016-.503.026-1.005.039-1.508 1.7-.451 3.401-.904 5.101-1.352.251.42.679.664 1.11.807Z" />
      ),
    },
  },
};

const eyebrow_shape_fiw7F = {
  id: "eyebrow_shape_fiw7F",
  name: "Eyebrow 12",
  description:
    "Thin, slightly comma-shaped, sharp brows that curve up slightly and end above the centre of the eye",
  tags: ["side"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M232.74 223.52c3.29-.01 5.58-3.35 4.29-6.37-1.62-3.78-7.96-8.53-24.5-12.47-7.99-1.9 7.74 2.44 12.75 12.56 3.12 6.31 5.49 6.28 7.46 6.28Z" />
      ),
      dark: (
        <path d="M226.634 219.672c.172.131.347.257.526.378-.13-.86-.27-1.72-.4-2.58 1.11 1.13 2.21 2.25 3.31 3.38.23-1.02.45-2.04.68-3.06l1.17 1.71c.58-1.05.77-2.31.52-3.48 1.05.97 1.77 2.3 2.01 3.72.867-2.015.664-4.429-.432-6.316 1.562 1.299 2.512 2.559 3.012 3.726 1.29 3.02-1 6.36-4.29 6.37-1.675 0-3.638.022-6.106-3.848Z" />
      ),
      light: (
        <path d="M213.692 206.253c-3.34-1.951-5.028-2.492-1.162-1.573 6.854 1.633 11.956 3.404 15.697 5.188.311.613.488 1.287.453 1.972-.8-.4-1.59-.79-2.39-1.18-.12.23-.25.46-.38.69-1.5-1.57-3.33-2.84-5.33-3.7.32.79.63 1.58.95 2.37a147.977 147.977 0 0 0-7.838-3.767Z" />
      ),
    },
    right: {
      base: (
        <path d="M267.51 223.52c-3.29-.01-5.58-3.35-4.29-6.37 1.62-3.78 7.96-8.53 24.5-12.47 7.99-1.9-7.74 2.44-12.75 12.56-3.12 6.31-5.49 6.28-7.46 6.28Z" />
      ),
      dark: (
        <path d="M273.616 219.672c-2.468 3.87-4.431 3.848-6.106 3.848-3.29-.01-5.58-3.35-4.29-6.37.5-1.167 1.45-2.427 3.012-3.726-1.096 1.887-1.299 4.301-.432 6.316.24-1.42.96-2.75 2.01-3.72-.25 1.17-.06 2.43.52 3.48l1.17-1.71c.23 1.02.45 2.04.68 3.06 1.1-1.13 2.2-2.25 3.31-3.38-.13.86-.27 1.72-.4 2.58.179-.121.354-.247.526-.378Z" />
      ),
      light: (
        <path d="M286.558 206.253a147.977 147.977 0 0 0-7.838 3.767c.32-.79.63-1.58.95-2.37-2 .86-3.83 2.13-5.33 3.7-.13-.23-.26-.46-.38-.69-.8.39-1.59.78-2.39 1.18-.035-.685.142-1.359.453-1.972 3.741-1.784 8.843-3.555 15.697-5.188 3.866-.919 2.178-.378-1.162 1.573Z" />
      ),
    },
  },
};

const eyebrow_shape_jRw93 = {
  id: "eyebrow_shape_jRw93",
  name: "Eyebrow 13",
  description: "Jagged, thin eyebrows that curve slightly",
  tags: ["side"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M237.71 220.37c-2.36-.66-2.34-4.58-4.64-5.4-.64-.23-1.38-.17-1.93-.55-.47-.32-.75-.93-1.3-1.07-.79-.21-1.45.64-2.25.8-1.02.21-1.88-.7-2.63-1.43-2.82-2.72-7.25-3.62-10.9-2.2l.36 1.23c-3.35-.44-6.7-.87-10.05-1.31-.71-.09-1.56-.14-2.03.4-.28.32-.35.77-.57 1.13-.41.69-1.25.96-2.02 1.18-2.26.64-4.51 1.29-6.77 1.93-.23.07-.48.13-.7.06-.71-.24-.36-1.35.23-1.82 2.01-1.59 4.99-1.15 7.25-2.37 1.09-.59 2-1.56 3.21-1.82 1.4-.3 3.14.33 4.11-.73-.42-.56.31-1.36 1.01-1.35.7.01 1.3.47 1.94.75 2.46 1.07 5.28-.56 7.95-.31.68.06 1.37.25 2.03.09.66-.16 1.26-.88.99-1.5 2.17 1.41 4.35 2.82 6.52 4.22.11.07.23.15.37.14.54-.03.2-.88.32-1.41.13-.62 1.26-.54 1.31.09-.01-.16.48-.5.6-.58.67 1.76 1.17.49 2.13.47 1.37-.03 1.38.31 2.21 1.79 1.63 2.97 2.75 6.22 3.25 9.57Z" />
      ),
      dark: (
        <path d="M224.724 212.5c-.072-.551.179-1.185.696-1.38.84.42 1.7.79 2.58 1.11.29.11.61.21.88.06.5-.27.27-1.07-.16-1.44-.201-.169-.432-.315-.642-.475.261-.212.03-.894.132-1.345.13-.62 1.26-.54 1.31.09-.01-.16.48-.5.6-.58.67 1.76 1.17.49 2.13.47 1.37-.03 1.38.31 2.21 1.79 1.63 2.97 2.75 6.22 3.25 9.57-2.36-.66-2.34-4.58-4.64-5.4-.64-.23-1.38-.17-1.93-.55-.47-.32-.75-.93-1.3-1.07-.79-.21-1.45.64-2.25.8-1.02.21-1.88-.7-2.63-1.43a8.184 8.184 0 0 0-.236-.22Z" />
      ),
    },
    right: {
      base: (
        <path d="M262.54 220.37c2.36-.66 2.34-4.58 4.64-5.4.64-.23 1.38-.17 1.93-.55.47-.32.75-.93 1.3-1.07.79-.21 1.45.64 2.25.8 1.02.21 1.88-.7 2.63-1.43 2.82-2.72 7.25-3.62 10.9-2.2l-.36 1.23c3.35-.44 6.7-.87 10.05-1.31.71-.09 1.56-.14 2.03.4.28.32.35.77.57 1.13.41.69 1.25.96 2.02 1.18 2.26.64 4.51 1.29 6.77 1.93.23.07.48.13.7.06.71-.24.36-1.35-.23-1.82-2.01-1.59-4.99-1.15-7.25-2.37-1.09-.59-2-1.56-3.21-1.82-1.4-.3-3.14.33-4.11-.73.42-.56-.31-1.36-1.01-1.35-.7.01-1.3.47-1.94.75-2.46 1.07-5.28-.56-7.95-.31-.68.06-1.37.25-2.03.09-.66-.16-1.26-.88-.99-1.5-2.17 1.41-4.35 2.82-6.52 4.22-.11.07-.23.15-.37.14-.54-.03-.2-.88-.32-1.41-.13-.62-1.26-.54-1.31.09.01-.16-.48-.5-.6-.58-.67 1.76-1.17.49-2.13.47-1.37-.03-1.38.31-2.21 1.79a28.784 28.784 0 0 0-3.25 9.57Z" />
      ),
      dark: (
        <path d="M275.526 212.5c-.08.072-.159.145-.236.22-.75.73-1.61 1.64-2.63 1.43-.8-.16-1.46-1.01-2.25-.8-.55.14-.83.75-1.3 1.07-.55.38-1.29.32-1.93.55-2.3.82-2.28 4.74-4.64 5.4.5-3.35 1.62-6.6 3.25-9.57.83-1.48.84-1.82 2.21-1.79.96.02 1.46 1.29 2.13-.47.12.08.61.42.6.58.05-.63 1.18-.71 1.31-.09.102.451-.129 1.133.132 1.345-.21.16-.441.306-.642.475-.43.37-.66 1.17-.16 1.44.27.15.59.05.88-.06.88-.32 1.74-.69 2.58-1.11.517.195.768.829.696 1.38Z" />
      ),
    },
  },
};

const eyebrow_shape_dHd13 = {
  id: "eyebrow_shape_dHd13",
  name: "Eyebrow 14",
  description:
    "Sparse and gappy eyebrows that almost meet in the middle, curving slightly over the centre of the eye",
  tags: ["side"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M245.59 225.4a27.017 27.017 0 0 1-.91-13.7c.7.43.84 1.37.92 2.18.22 2.48.43 4.95.68 7.42.07.73.54 1.36.39 2.11-.03.13-1.01 2.19-1.08 1.99Zm-6.49-16.05c.62 2.17 1.85 12.33 3.95 12.36-.18-2.2-.62-4.51-1.22-6.64-.57-2.05-2.19-3.83-2.73-5.72Zm-4.93.03c-.86-1.44-1.87-2.92-3.4-3.61 1.21.54 3.29 8.16 4.03 9.65.96 1.94 2.31 6.04 4.79 6.28-.61-4.18-3.27-8.72-5.42-12.32Zm-8.64-2.7c-3.74-1.8.67 3.87 1.33 4.47 1.46 1.32 3.02 3.24 4.99 3.69-.85-2.3-3.99-7.04-6.32-8.16Zm-10.87-1.94c-.48 3.47 5.89 8.67 9.24 7.63-.76-1.43-9.31-7.09-9.24-7.63Zm-6.07 1.22c-1.63-.63-3.56-.69-5.04.23.71-.44 5.49 2.61 6.27 3.04 1.39.78 3.34 2.75 4.91 1.58-1.16-1.95-3.98-4.01-6.14-4.85Zm-10.4 1.63c-2.38-.68-4.88-1.37-7.31-.86.08.83 1.02 1.25 1.82 1.5 1.74.54 7.03 3.26 8.73 2.34 2.36-1.29-2.02-2.63-3.24-2.98Zm-13.62 7.25c-.06.51.62.73 1.13.73 3.92 0 7.45-2.39 11.35-2.78-4.09-.97-8.65.71-12.48 2.05Zm67.27 5.55c.84-4.33 1.35-8.93-.24-13.04-.44.63-.53 1.43-.61 2.19-.34 3.38-.69 6.77-1.03 10.15-.19 1.83-1.38 4.12-1.25 5.85 1.83-.21 3.13-3.5 3.13-5.15Z" />
      ),
    },
    right: {
      base: (
        <path d="m269.477 205.772.003-.002-.003.002Zm0 0c-1.211.552-3.288 8.159-4.027 9.648-.96 1.94-2.31 6.04-4.79 6.28.61-4.18 3.27-8.72 5.42-12.32.859-1.439 1.868-2.918 3.397-3.608ZM254.66 225.4c-.07.2-1.05-1.86-1.08-1.99-.15-.75.32-1.38.39-2.11.25-2.47.46-4.94.68-7.42.08-.81.22-1.75.92-2.18.88 4.54.57 9.31-.91 13.7Zm6.49-16.05c-.62 2.17-1.85 12.33-3.95 12.36.18-2.2.62-4.51 1.22-6.64.57-2.05 2.19-3.83 2.73-5.72Zm13.57-2.67c3.74-1.8-.67 3.87-1.33 4.47-1.46 1.32-3.02 3.24-4.99 3.69.85-2.3 3.99-7.04 6.32-8.16Zm10.87-1.94c.48 3.47-5.89 8.67-9.24 7.63.76-1.43 9.31-7.09 9.24-7.63Zm6.07 1.22c1.63-.63 3.56-.69 5.04.23-.71-.44-5.49 2.61-6.27 3.04-1.39.78-3.34 2.75-4.91 1.58 1.16-1.95 3.98-4.01 6.14-4.85Zm10.4 1.63c2.38-.68 4.88-1.37 7.31-.86-.08.83-1.02 1.25-1.82 1.5-1.74.54-7.03 3.26-8.73 2.34-2.36-1.29 2.02-2.63 3.24-2.98Zm13.62 7.25c.06.51-.62.73-1.13.73-3.92 0-7.45-2.39-11.35-2.78 4.09-.97 8.65.71 12.48 2.05Zm-63.84 5.55c0 1.65-1.3 4.94-3.13 5.15-.13-1.73 1.06-4.02 1.25-5.85.34-3.38.69-6.77 1.03-10.15.08-.76.17-1.56.61-2.19 1.59 4.11 1.08 8.71.24 13.04Z" />
      ),
    },
  },
};

const eyebrow_shape_38JJ4 = {
  id: "eyebrow_shape_38JJ4",
  name: "Eyebrow 15",
  description:
    "Sharp, thick brows that are gappy but not messy, with a rectangular inner corner and curving into a sharp outer corner",
  tags: ["side"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M245.08 213.54v10.85c-.83-.49-1.82-1.02-2.95-1.58-.25-2.57-.71-5.13-1.07-7.49-.2-1.34-.42-2.72-.7-4.08 2.99 1.29 4.72 2.3 4.72 2.3Zm-7.84-3.55c.48 1.91.93 3.82 1.36 5.74.45 1.99.87 3.99 1.26 6a88.13 88.13 0 0 0-3.95-1.7c.07-1.75-.22-3.68-.4-4.92-.33-2.17-.94-4.3-1.79-6.33 1.26.39 2.44.8 3.52 1.21Zm-7.73-2.38c.78.19 1.54.39 2.27.6.62 1.95.95 4.01 1.25 6.04.18 1.2.23 3.08.5 4.84-.79-.29-1.6-.59-2.43-.89-.09-1.26-.33-2.63-.39-3.79-.12-2.31-.58-4.57-1.2-6.8Zm-4.14 1.63c-.21-.86-.44-1.74-.7-2.61 1.36.22 2.66.48 3.92.76h.01c.39 2.45.67 4.93.81 7.41.06 1 .1 2 .38 2.95-.84-.29-1.7-.57-2.57-.85-.31-2.52-1.25-5.18-1.85-7.66Zm-5.85-3.2c.9.05 1.78.14 2.65.24.35 1.04.74 2.07 1.14 3.09.79 1.99 1.67 5.5 3.25 7.32-1.37-.42-2.78-.83-4.2-1.21-.21-.97-.69-1.86-1.07-2.78-.86-2.12-1.18-4.45-1.77-6.66Zm-.48 8.61c.26-2.02-1.41-5.33-1.97-6.71-.28-.69-.58-1.37-.91-2.04.77.01 1.53.03 2.29.07.61 3.12 1.66 6.17 2.7 9.18v.01c-.7-.18-1.4-.35-2.11-.51Zm-9.1-8.4c1.12-.14 2.23-.23 3.33-.28.57 1.43 1.27 2.82 2 4.16.73 1.35 1.43 2.8 2.2 4.16-1.12-.24-2.24-.46-3.36-.66-.48-1.29-1.47-2.66-1.88-3.48-.69-1.33-1.43-2.66-2.29-3.9Zm-6.83 1.37c1.51-.43 3.02-.77 4.52-1.03 1.32 2.25 2.66 4.61 4.16 6.67-.65-.09-1.3-.18-1.95-.25-.77-.09-1.55-.12-2.36-.1-.04-.08-.09-.14-.13-.21a30.813 30.813 0 0 0-4.24-5.08Zm-3.54 3.88c-.75-.62-1.54-1.21-2.37-1.73 1.35-.61 2.71-1.14 4.07-1.59.64.46 1.29.9 1.91 1.36 1.37 1.01 2.69 2.15 3.92 3.38-1.68.06-3.43.33-5.19.73-.75-.76-1.54-1.48-2.34-2.15Zm-8.39 1.58c1.23-.81 2.47-1.54 3.72-2.19.15.09.29.16.44.24 1.05.54 2.93 1.94 4.7 3.01-1.44.4-2.88.88-4.27 1.4-1.32-.93-3.11-1.79-4.59-2.46Zm-1.99 4.01c-.95-.14-1.93-.28-2.9-.31a46.08 46.08 0 0 1 3.54-2.77c1.71.59 3.28 1.19 4.81 1.96-1.23.49-2.42 1-3.55 1.5-.63-.15-1.26-.28-1.9-.38Zm1.15.72c-7.24 3.3-11.56 6.21-6.42 1.16.38-.37.76-.73 1.14-1.08.92.14 1.85.22 2.79.18.82-.03 1.66-.17 2.49-.26Z" />
      ),
    },
    right: {
      base: (
        <path d="M255.17 213.54s1.73-1.01 4.72-2.3c-.28 1.36-.5 2.74-.7 4.08-.36 2.36-.82 4.92-1.07 7.49-1.13.56-2.12 1.09-2.95 1.58v-10.85Zm7.84-3.55c1.08-.41 2.26-.82 3.52-1.21-.85 2.03-1.46 4.16-1.79 6.33-.18 1.24-.47 3.17-.4 4.92a88.13 88.13 0 0 0-3.95 1.7c.39-2.01.81-4.01 1.26-6 .43-1.92.88-3.83 1.36-5.74Zm7.73-2.38c-.62 2.23-1.08 4.49-1.2 6.8-.06 1.16-.3 2.53-.39 3.79-.83.3-1.64.6-2.43.89.27-1.76.32-3.64.5-4.84.3-2.03.63-4.09 1.25-6.04.73-.21 1.49-.41 2.27-.6Zm4.14 1.63c-.6 2.48-1.54 5.14-1.85 7.66-.87.28-1.73.56-2.57.85.28-.95.32-1.95.38-2.95.14-2.48.42-4.96.81-7.41h.01c1.26-.28 2.56-.54 3.92-.76-.26.87-.49 1.75-.7 2.61Zm5.85-3.2c-.59 2.21-.91 4.54-1.77 6.66-.38.92-.86 1.81-1.07 2.78-1.42.38-2.83.79-4.2 1.21 1.58-1.82 2.46-5.33 3.25-7.32.4-1.02.79-2.05 1.14-3.09.87-.1 1.75-.19 2.65-.24Zm.48 8.61c-.71.16-1.41.33-2.11.51v-.01c1.04-3.01 2.09-6.06 2.7-9.18.76-.04 1.52-.06 2.29-.07-.33.67-.63 1.35-.91 2.04-.56 1.38-2.23 4.69-1.97 6.71Zm9.1-8.4c-.86 1.24-1.6 2.57-2.29 3.9-.41.82-1.4 2.19-1.88 3.48-1.12.2-2.24.42-3.36.66.77-1.36 1.47-2.81 2.2-4.16.73-1.34 1.43-2.73 2-4.16 1.1.05 2.21.14 3.33.28Zm6.83 1.37a30.813 30.813 0 0 0-4.24 5.08c-.04.07-.09.13-.13.21-.81-.02-1.59.01-2.36.1-.65.07-1.3.16-1.95.25 1.5-2.06 2.84-4.42 4.16-6.67 1.5.26 3.01.6 4.52 1.03Zm3.54 3.88c-.8.67-1.59 1.39-2.34 2.15-1.76-.4-3.51-.67-5.19-.73 1.23-1.23 2.55-2.37 3.92-3.38.62-.46 1.27-.9 1.91-1.36 1.36.45 2.72.98 4.07 1.59-.83.52-1.62 1.11-2.37 1.73Zm8.39 1.58c-1.48.67-3.27 1.53-4.59 2.46-1.39-.52-2.83-1-4.27-1.4 1.77-1.07 3.65-2.47 4.7-3.01.15-.08.29-.15.44-.24 1.25.65 2.49 1.38 3.72 2.19Zm1.99 4.01c-.64.1-1.27.23-1.9.38-1.13-.5-2.32-1.01-3.55-1.5 1.53-.77 3.1-1.37 4.81-1.96 1.19.84 2.37 1.76 3.54 2.77-.97.03-1.95.17-2.9.31Zm-1.15.72c.83.09 1.67.23 2.49.26.94.04 1.87-.04 2.79-.18.38.35.76.71 1.14 1.08 5.14 5.05.82 2.14-6.42-1.16Z" />
      ),
    },
  },
};

const eyebrow_shape_88Jey = {
  id: "eyebrow_shape_88Jey",
  name: "Eyebrow 16",
  description:
    "Sharp brows with a high, smooth arch, a long sharp tail, and a rectangular inner corner",
  tags: ["side"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="m237.58 216.6-2.39 7.9s-13.72-4.53-21.25-10.49c-7.54-5.96-18.7-5.43-32.44 5.78-13.74 11.21 8.45-13.53 18.75-15.67 10.3-2.14 37.33 12.48 37.33 12.48Z" />
      ),
      dark: (
        <path d="M238.72 226.22c1.52-2.59 1.82-5.88.79-8.7-1.03-2.83-3.37-5.15-6.2-6.16 1.23 2.45 1.66 5.28 1.21 7.99-.58-2.27-2.26-4.24-4.4-5.18.98.84 1.61 2.08 1.7 3.37-.73-.45-1.46-.9-2.18-1.35.32.81.64 1.61.95 2.42-2.28-1.74-5.02-2.87-7.86-3.25.78.82 1.56 1.65 2.35 2.47-1.94-.1-3.88-.21-5.83-.31 2.78 3.1 5.97 5.83 9.46 8.11 1.96 1.28 4.17 2.45 6.51 2.33 2.34-.13 4.73-2.02 3.5-1.74z" />
      ),
      light: (
        <path d="M215.323 206.682a33.535 33.535 0 0 0-9.703-.672c1.6.3 3.13.98 4.43 1.97-3.24-.5-6.48-1-9.72-1.49.35.57.7 1.15 1.06 1.72-2.87-1.14-6.27-.87-8.96.65a12.73 12.73 0 0 0-3.91 2.7c.131-.309.275-.611.432-.906 3.971-3.166 8.179-5.886 11.298-6.534 3.602-.748 9.251.553 15.073 2.562Z" />
      ),
    },
    right: {
      base: (
        <path d="m262.67 216.6 2.39 7.9s13.72-4.53 21.25-10.49c7.54-5.96 18.7-5.43 32.44 5.78 13.74 11.21-8.45-13.53-18.75-15.67-10.3-2.14-37.33 12.48-37.33 12.48Z" />
      ),
      dark: (
        <path d="M265.746 215.004a13.072 13.072 0 0 0-.016 4.346c.58-2.27 2.26-4.24 4.4-5.18-.98.84-1.61 2.08-1.7 3.37.73-.45 1.46-.9 2.18-1.35-.32.81-.64 1.61-.95 2.42 2.28-1.74 5.02-2.87 7.86-3.25-.78.82-1.56 1.65-2.35 2.47 1.932-.1 3.875-.209 5.807-.309-7.319 4.14-15.917 6.979-15.917 6.979l-2.39-7.9s1.154-.624 3.076-1.596Z" />
      ),
      light: (
        <path d="M311.298 210.654c.157.295.301.597.432.906a12.73 12.73 0 0 0-3.91-2.7c-2.69-1.52-6.09-1.79-8.96-.65.36-.57.71-1.15 1.06-1.72-3.24.49-6.48.99-9.72 1.49 1.3-.99 2.83-1.67 4.43-1.97a33.535 33.535 0 0 0-9.703.672c5.822-2.009 11.471-3.31 15.073-2.562 3.119.648 7.327 3.368 11.298 6.534Z" />
      ),
    },
  },
};

export const eyebrowsPack2 = {
  eyebrow_shape_DJ42i,
  eyebrow_shape_381Bj,
  eyebrow_shape_52RBH,
  eyebrow_shape_51TBH,
  eyebrow_shape_418BE,
  eyebrow_shape_fiw7F,
  eyebrow_shape_jRw93,
  eyebrow_shape_dHd13,
  eyebrow_shape_38JJ4,
  eyebrow_shape_88Jey,
};

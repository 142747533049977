import { allColours } from "character-creator/colours/colourSchemes";

import { BackHornsListArray } from "character-creator/components/head/extras/horns/back/BackHornsList";
import {
  CentreHornsListArray,
  FrontSideHornsListArray,
} from "character-creator/components/head/extras/horns/front/FrontHornsList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";
// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const hornsPath = "horns.";
const hornsBackPath = hornsPath + "back.";
const hornsFrontPath = hornsPath + "front.";
const hornsCentrePath = hornsPath + "centre.";

const hornsPageConfig = {
  id: "hornsPage",
  name: "Horns",
  description:
    "Feeling a bit devilish? Or perhaps you want to get in touch with your natural side with some antlers?",
  sections: [
    {
      id: "backHornsGroup",
      name: "Back horns",
      description: "These horns come out from behind the head.",
      symmetryAttribute: [hornsBackPath + "symmetry"],
      controls: {
        symmetrical: [
          {
            id: "backHornsShape",
            name: "Shape",
            description: "",
            dataAttribute: [
              hornsBackPath + "left.shape",
              hornsBackPath + "right.shape",
            ],
            previewType: "preview",
            options: BackHornsListArray,
            colourControls: [
              {
                id: "backHornsColour",
                name: "Colour",
                description: "",
                dataAttribute: [
                  hornsBackPath + "left.colour",
                  hornsBackPath + "right.colour",
                ],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
          },
        ],
        asymmetrical: [
          {
            id: "backHornsLeftShape",
            name: "Left shape",
            description: "",
            dataAttribute: [hornsBackPath + "left.shape"],
            previewType: "preview",
            options: BackHornsListArray,
            colourControls: [
              {
                id: "backHornsLeftColour",
                name: "Left Colour",
                description: "",
                dataAttribute: [hornsBackPath + "left.colour"],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
          },
          {
            id: "backHornsRightShape",
            name: "Right shape",
            description: "",
            dataAttribute: [hornsBackPath + "right.shape"],
            previewType: "preview",
            options: BackHornsListArray,
            colourControls: [
              {
                id: "backHornsRightColour",
                name: "Right Colour",
                description: "",
                dataAttribute: [hornsBackPath + "right.colour"],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
          },
        ],
      },
    },
    {
      id: "frontHornsGroup",
      name: "Front horns",
      description:
        "These horns come out from the front/side the head. Note - at the moment these will be behind any 'Front Hair'.",
      symmetryAttribute: [hornsFrontPath + "symmetry"],
      controls: {
        symmetrical: [
          {
            id: "frontHornsShape",
            name: "Shape",
            description: "",
            dataAttribute: [
              hornsFrontPath + "left.shape",
              hornsFrontPath + "right.shape",
            ],
            previewType: "preview",
            options: FrontSideHornsListArray,
            colourControls: [
              {
                id: "frontHornsColour",
                name: "Colour",
                description: "",
                dataAttribute: [
                  hornsFrontPath + "left.colour",
                  hornsFrontPath + "right.colour",
                ],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
          },
        ],
        asymmetrical: [
          {
            id: "frontHornsLeftShape",
            name: "Left shape",
            description: "",
            dataAttribute: [hornsFrontPath + "left.shape"],
            previewType: "preview",
            options: FrontSideHornsListArray,
            colourControls: [
              {
                id: "frontHornsLeftColour",
                name: "Left Colour",
                description: "",
                dataAttribute: [hornsFrontPath + "left.colour"],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
          },
          {
            id: "frontHornsRightShape",
            name: "Right shape",
            description: "",
            dataAttribute: [hornsFrontPath + "right.shape"],
            previewType: "preview",
            options: FrontSideHornsListArray,
            colourControls: [
              {
                id: "frontHornsColour",
                name: "Right Colour",
                description: "",
                dataAttribute: [hornsFrontPath + "right.colour"],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
          },
        ],
      },
    },
    {
      id: "centreHornsGroup",
      name: "Centre horn",
      description: "Note - at the moment these will be behind any 'Front Hair'",
      controls: [
        {
          id: "centreHornsShape",
          name: "Shape",
          description: "",
          dataAttribute: [hornsCentrePath + "shape"],
          previewType: "preview",
          options: CentreHornsListArray,
          colourControls: [
            {
              id: "centreHornsColour",
              name: "Colour",
              description: "",
              dataAttribute: [hornsCentrePath + "colour"],
              attributeType: "multi",
              previewType: "swatch",
              options: allColours,
            },
          ],
        },
      ],
    },
  ],
};

const HornsPage = {
  id: "hornsPage",
  name: "Horns",
  description: "",
  content: <SubPage page={hornsPageConfig} />,
};

export default HornsPage;

import React from "react";

import { PreviewBase } from "../Preview";

const beak_shape_rwo24 = {
  id: "beak_shape_rwo24",
  name: "Beak 1 (Small)",
  description: "A short, wide triangular beak with a rounded tip",
  tags: [],
  preview: {
    viewbox: "200 250 100 100",
    base: PreviewBase,
  },
  bite: "",
  invertLipColours: true,
  shape: {
    lips: {
      upper: {
        shape: {
          base: (
            <path d="M283.08 294.05c-5.16-8.88-11.63-17.37-19.28-25.3-1.85-1.92-3.89-3.88-6.82-4.93-2.14-.77-11.8-.77-13.95 0-2.93 1.05-4.97 3.01-6.82 4.93-7.65 7.93-14.12 16.42-19.28 25.3-1.51 2.6-2.93 5.41-2.09 8.16 1.03 3.39 5.21 5.79 9.26 7.74 5.46 2.63 11.19 4.99 17.13 7.05 2.73.95 5.85 1.82 8.78 1.74 2.93.07 6.06-.8 8.78-1.74 5.93-2.06 11.66-4.42 17.13-7.05 4.05-1.95 8.23-4.35 9.26-7.74.83-2.75-.59-5.57-2.1-8.16z" />
          ),
          basic: {
            dark: (
              <>
                <ellipse cx="238.5" cy="276.5" rx="2" ry="3.19" />
                <ellipse cx="261.5" cy="276.5" rx="2" ry="3.19" />
                <ellipse cx="250" cy="291.58" rx=".75" ry="25.88" />
              </>
            ),
          },
        },
      },
    },
  },
};

const beak_shape_31ik1 = {
  id: "beak_shape_31ik1",
  name: "Beak 1 (Large)",
  description: "A long, wide triangular beak with a rounded tip",
  tags: [],
  preview: {
    viewbox: "200 240 100 100",
    base: PreviewBase,
  },
  bite: "",
  invertLipColours: true,
  shape: {
    lips: {
      upper: {
        shape: {
          base: (
            <path d="M283.08 294.99c-5.16-12.52-11.63-24.51-19.28-35.68-1.85-2.71-3.89-5.47-6.82-6.95-2.14-1.08-11.8-1.08-13.95 0-2.93 1.48-4.97 4.24-6.82 6.95-7.65 11.18-14.12 23.16-19.28 35.68-1.51 3.66-2.93 7.63-2.09 11.5 1.03 4.79 5.21 8.17 9.26 10.92 5.46 3.71 11.19 7.04 17.13 9.94 2.73 1.33 5.85 2.56 8.78 2.46 2.93.1 6.06-1.13 8.78-2.46 5.93-2.9 11.66-6.23 17.13-9.94 4.05-2.75 8.23-6.13 9.26-10.92.83-3.87-.59-7.84-2.1-11.5z" />
          ),
          basic: {
            dark: (
              <>
                <ellipse cx="238.5" cy="270.24" rx="2" ry="4.5" />
                <ellipse cx="261.5" cy="270.24" rx="2" ry="4.5" />
                <ellipse cx="250" cy="291.52" rx="0.75" ry="36.5" />
              </>
            ),
          },
        },
      },
    },
  },
};

const beak_shape_ri294 = {
  id: "beak_shape_ri294",
  name: "Beak 2 (Small)",
  description: "A short, oval beak with a slight pointed tip",
  tags: [],
  preview: {
    viewbox: "200 230 100 100",
    base: PreviewBase,
  },
  bite: "",
  invertLipColours: true,
  shape: {
    lips: {
      upper: {
        shape: {
          base: (
            <path d="M259.31 248.64c-.87-1.49-1.81-3.01-3.43-4.13-1.29-.89-3.12-1.46-4.86-1.33-.68.05-1.36.05-2.05 0-1.74-.13-3.57.44-4.86 1.33-1.62 1.12-2.56 2.64-3.43 4.13-5.87 10.03-10.6 20.62-10.69 31.5-.09 10.88 4.97 22.15 16.15 29.48 1 .66 3.52 1.53 3.6 1.51h.01c.88.17 2.23-.34 2.92-.77 11.99-7.3 17.41-18.97 17.32-30.23-.08-10.87-4.81-21.46-10.68-31.49z" />
          ),
          basic: {
            dark: (
              <>
                <ellipse cx="241.53" cy="258.42" rx="1.52" ry="2.29" />
                <ellipse cx="258.97" cy="258.42" rx="1.52" ry="2.29" />
                <ellipse cx="250.25" cy="275.7" rx="0.75" ry="27.63" />
              </>
            ),
          },
        },
      },
    },
  },
};

const beak_shape_r4239 = {
  id: "beak_shape_r4239",
  name: "Beak 2 (Large)",
  description: "A long, oval beak with a slight pointed tip",
  tags: [],
  preview: {
    viewbox: "190 230 120 120",
    base: PreviewBase,
  },
  bite: "",
  invertLipColours: true,
  shape: {
    lips: {
      upper: {
        shape: {
          base: (
            <path d="M259.08 250.8c-.87-2.07-1.81-4.19-3.43-5.75-1.29-1.24-3.13-2.03-4.87-1.86-.68.07-1.37.07-2.05 0-1.74-.18-3.58.62-4.87 1.86-1.62 1.56-2.56 3.68-3.43 5.75-5.88 13.97-10.62 28.74-10.71 43.9-.09 15.16 4.98 30.87 16.17 41.09 1 .92 3.53 2.14 3.61 2.1h.01c.88.24 2.23-.48 2.93-1.07 12.02-10.18 17.44-26.44 17.35-42.12-.1-15.17-4.83-29.93-10.71-43.9z" />
          ),
          basic: {
            dark: (
              <>
                <ellipse cx="241.27" cy="264.43" rx="1.52" ry="3.19" />
                <ellipse cx="258.73" cy="264.43" rx="1.52" ry="3.19" />
                <ellipse cx="250" cy="288.5" rx="0.75" ry="38.5" />
              </>
            ),
          },
        },
      },
    },
  },
};

const beak_shape_as8w8 = {
  id: "beak_shape_as8w8",
  name: "Beak 3 (Small)",
  description: "A short, wide triangular beak with a hooked tip",
  tags: [],
  preview: {
    viewbox: "200 230 100 100",
    base: PreviewBase,
  },
  bite: "",
  invertLipColours: true,
  shape: {
    lips: {
      upper: {
        shape: {
          base: (
            <path d="M274.71 274.8c-3.61-7.76-8.05-14.95-13.17-21.31-2.6-3.23-5.57-6.37-9.22-7.25-.75-.18-1.54-.22-2.32-.14-.78-.08-1.56-.04-2.32.14-3.64.87-6.62 4.02-9.22 7.25-5.13 6.37-9.57 13.55-13.17 21.31-2.82 6.08-5.11 13.79-2.17 19.79 4.25 8.69 15.83 7.04 21.19 14.76 1.59 2.28 2.91 5.56 5.36 5.71.11.01.22 0 .33-.01.11.01.22.02.33.01 2.45-.15 3.77-3.42 5.36-5.71 5.36-7.72 16.95-6.07 21.19-14.76 2.93-6.01.65-13.72-2.17-19.79z" />
          ),
          basic: {
            dark: (
              <>
                <ellipse cx="241.53" cy="258.42" rx="1.52" ry="2.29" />
                <ellipse cx="258.97" cy="258.42" rx="1.52" ry="2.29" />
                <ellipse cx="250.25" cy="275.7" rx="0.75" ry="27.63" />
              </>
            ),
          },
        },
      },
    },
  },
};

const beak_shape_5239g = {
  id: "beak_shape_5239g",
  name: "Beak 3 (Large)",
  description: "A long, wide triangular beak with a hooked tip",
  tags: [],
  preview: {
    viewbox: "190 230 120 120",
    base: PreviewBase,
  },
  bite: "",
  invertLipColours: true,
  shape: {
    lips: {
      upper: {
        shape: {
          base: (
            <path d="M280.18 284.58c-4.4-10.41-9.83-20.04-16.09-28.57-3.18-4.33-6.81-8.54-11.26-9.72-.92-.24-1.88-.29-2.83-.19-.95-.11-1.91-.05-2.83.19-4.45 1.17-8.08 5.39-11.26 9.72-6.26 8.53-11.69 18.17-16.09 28.57-3.45 8.15-6.24 18.48-2.65 26.53 5.18 11.65 19.33 9.44 25.88 19.79 1.94 3.06 3.55 7.45 6.55 7.65.14.01.27-.01.4-.01.13.01.27.02.4.01 3-.2 4.61-4.59 6.55-7.65 6.55-10.35 20.69-8.14 25.88-19.79 3.58-8.05.79-18.38-2.65-26.53z" />
          ),
          basic: {
            dark: (
              <>
                <ellipse cx="239.66" cy="262.64" rx="1.85" ry="3.07" />
                <ellipse cx="260.95" cy="262.64" rx="1.85" ry="3.07" />
                <ellipse cx="250.31" cy="285.79" rx="0.91" ry="37.03" />
              </>
            ),
          },
        },
      },
    },
  },
};

export const beaksPack1 = {
  beak_shape_rwo24,
  beak_shape_31ik1,
  beak_shape_ri294,
  beak_shape_r4239,
  beak_shape_as8w8,
  beak_shape_5239g,
};

import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const hair_base_ySGtz = {
  id: "hair_base_ySGtz",
  name: "Hair style 1",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M329.97 230.34c.08 1.1.15 2.2.22 3.3-.89-.42-1.75-.88-2.59-1.38-4.22-2.52-7.83-6.14-10.42-10.45-2.57-4.3-4.14-9.26-7.19-13.2-2.99-3.87-9.78-8.5-12.59-4.74-.07.08-.13.17-.19.26-.21.32-.41.63-.61.93-.02.02-.03.05-.05.07-1.19 1.67-2.56 3.27-4.09 4.78-9.37 9.27-24.89 15.33-42.46 15.33-17.5 0-32.99-6.02-42.37-15.24l-.03-.03c-1.64-1.61-3.08-3.31-4.33-5.1-.05-.07-.1-.15-.15-.23l-.33-.51c-.04-.06-.08-.12-.14-.18-2.77-3.9-9.64.77-12.65 4.66-3.04 3.94-4.61 8.9-7.19 13.2-2.58 4.31-6.19 7.92-10.42 10.44-.84.51-1.7.97-2.59 1.39.07-1.1.14-2.21.22-3.31-12.23-10.29-19.46-23.04-19.46-36.85 0-34.26 44.52-62.02 99.44-62.02s99.44 27.76 99.44 62.02c0 13.81-7.24 26.56-19.47 36.86z" />
    ),
    basic: {
      dark: (
        <path d="M333.74 240.3c-.4.39-.98.52-1.54.6-3.6.51-7.24-.25-10.82-.87-17.29-3.03-34.97-2.93-52.52-2.45-35.5.97-70.95 3.45-106.24 7.43-.21-9.25.26-18.52 1.39-27.7.36-2.87 1.44-15.32 5.22-15.65 5.02-.44 3.71 10.91 4.27 13.21.92-2.6 1.87-5.14 3.26-7.54 1.17-2.01 3.98-4.25 4.72-6.29-1 2.76-.94 5.89.18 8.61 3.75-2.24 7.5-4.47 11.25-6.71 4.41-2.63 9.08-5.54 11.16-10.23 3.55 7.88 9.68 14.57 17.23 18.77-3.32-4.87-4.52-11.14-3.22-16.89 9.25 12.81 23.32 22.05 38.75 25.42-3.14-3.34-5.4-7.51-6.48-11.96 4.79 1.14 9.59 2.29 14.38 3.44-1.4-1.04-2.35-2.67-2.58-4.4 6.62-.27 14.13.41 20.62-.84 4.11-.8 1.13-2.11-.66-4.31-1.72-2.12-2.94-4.67-3.45-7.36 1.11-.06 2.18.35 3.21.75 3.66 1.42 7.32 2.85 10.98 4.27l4.23 1.65c-.79-1-1.56-2.01-2.3-3.05-1.06-1.48-2.06-3-3.01-4.55 8.61.98 15.16 7.89 20.91 14.37.53.59 1.08 1.24 1.2 2.02.02.09.02.19.02.27.79.9 1.56 1.81 2.34 2.71-.39-3.66-.79-7.32-1.19-10.98 6.09 4.5 10.55 11.15 12.39 18.49.57-1.73 1.13-3.47 1.7-5.2 3.07 7.19 4.83 14.94 5.14 22.76.04.78.02 1.66-.54 2.21z" />
      ),
    },
  },
};

const hair_base_fs73J = {
  id: "hair_base_fs73J",
  name: "Hair style 2",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M357.49 171.96c.48 16.73-15.81 33.06-36.63 42.28-8.32-4.02-8.29-16.44-13.67-23.37-18.61-26.57-32.83 6.42-45.38 18.64-6.18 7.31-17.45 7.31-23.63 0-12.54-12.24-26.77-45.19-45.38-18.64-5.39 6.93-5.32 19.37-13.67 23.37-20.83-9.23-37.11-25.53-36.63-42.28-.83-73.15 215.84-73.15 214.99 0z" />
    ),
    basic: {
      dark: (
        <path d="M159.6 196.87c-.67-1.47-.82-3.17-.43-4.74 3.91 4.32 8.84 7.71 14.28 9.81-.3-1.35-.29-2.77.02-4.12 2.73.31 5.51.14 8.17-.49-1.3-5.21-.98-10.81.88-15.84 1.4 2.18 3.82 3.67 6.4 3.94-.85-1.03-1.22-2.45-.97-3.77 1.63 2.38 6.02.68 4.43-2.12 3.12 1.61 8.4 2.72 10.02-1.32.72-2.12-.62-4.83.85-6.53 2.41 2.36 5.18 4.35 8.2 5.86.17-1 .33-1.99.5-2.99.8.71 1.59 1.42 2.39 2.13 3.8-3.04 4.86 7.03 8.48 5.57.02-1 .05-1.99.07-2.99 5.24 7.24 12.19 13.23 20.12 17.35-1.61-2.09-1.91-5.11-.75-7.48 3.07 3.23 6.99 5.63 11.27 6.89l-1.86-5.73c1.4.84 2.79 1.69 4.19 2.53.06-1.2-.26-2.42-.9-3.43 4.23-.07 8.45-.15 12.68-.22-1.87-3.58-2.02-8.02-.4-11.72 2.86 2.11 6.3 3.41 9.84 3.74-.87-2.12-.59-4.68.72-6.56 1.44 1.22 3.05 2.24 4.77 3 0-1.78.48-3.56 1.39-5.1 2.31.89 4.5 2.1 6.47 3.6-1.43-4.99 2.65-7.87 5.37-2.97 3.07 4.44 7.38 8.01 12.32 10.19-1.3-2.15-1.71-4.82-1.12-7.26 5.88 3.36 9.78 9.94 9.92 16.71 2.31-2.67 3.4-6.36 2.9-9.85 4.02 2.13 6.66 6.62 6.58 11.17 2.98-2.27 4.96-5.8 5.36-9.53 2.63 2.84 4.42 6.47 5.06 10.29.27-2.52.53-5.03.8-7.55 8.8.11 6.54 12.43 10.3 17.6-33.88 40.37-95.8 49.36-146.38 45.14-27.3-6.31-59.83-20.64-67.12-50.33-1.86-8.51-1.26-20.01 3.66-27.54 5.65-8.28 3.91 17.23 21.52 24.66z" />
      ),
    },
  },
};

const hair_base_7Uawj = {
  id: "hair_base_7Uawj",
  name: "Hair style 3",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M351.57 173.95c.37 15.19-12.39 30.19-29.2 39.29-11.26-5.04-9.03-29.85-26.76-33.21-.78 6.76-6.52 12-13.5 12h-64.23c-6.96 0-12.71-5.24-13.5-11.99-17.77 3.45-15.43 27.97-26.73 33.21-16.8-9.1-29.59-24.08-29.22-39.3-.65-72.92 203.8-72.91 203.14 0z" />
    ),
    basic: {
      dark: (
        <path d="M157.6 197.87c1.4.82 3.54.6 4.25-.86 2.91 5.69 13.11.91 13.5-4.46 1.55-3.84 8.03-2.42 10.56-5.69.68-.89 1.01-2.05 1.86-2.77 1-.84 2.54-.9 3.37-1.91 1.16-3.95 1.04-8.94 6.05-10.44 1.78.41 3.76-.18 5.04-1.5 1.91 1.09 4.28 1.33 6.36.65.83 1.49.41 3.57-.94 4.61 5.45 2.39 3.58 11.85 11.52 4.66 7.25 10.35 10.46-13.65 19.49 2.32 3.31.42 7.24.62 9.37-1.95 4.22 5.31 10.21-3.07 14.99-2.81 0 4.79 13.66 1.93 17.23 2.33 3.37-.34 14.53-.92 10.18-6.3 3.68-.3 7.36-.59 11.04-.89 3.61 2.51 7.97 4.25 11.14 7.28.71 1.11.58 2.59 1.18 3.76s2.77 1.56 2.98.26c-1.07 3.25 3.51 6.59 6.28 4.57-2.12 6.86 3.47 16.57 9.67 9.24 1.8 1.86 5.35 1.46 6.69-.76 6.56 2.02-1.57 12.75-2.08 17.18-1.37 5.36-5.24 8.96-10.88 8.89-17.5 2.11-34.23 8.74-51.76 10.56-31.85 1.97-63.68-5.23-95.61-5.19-17.61 1.58-26.6-12.17-25.11-28.55-.13-4.05-.23-6.88 3.63-2.23z" />
      ),
    },
  },
};

const hair_base_2M48g = {
  id: "hair_base_2M48g",
  name: "Hair style 4",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M364.53 181.04c0 18.21-12.55 34.72-32.89 46.75-.7.42-1.41.83-2.14 1.23-4.36-.86-9.36-17.17-21.6-31.48-5.76-7.17-13.25-13.85-22.09-18.95-2.85 2.26-5.55 4.48-8.05 6.59-9.69 7.8-16.67 14.21-27.76 13.83-11.1.38-18.08-6.03-27.77-13.83-2.5-2.11-5.19-4.33-8.04-6.59-8.84 5.1-16.33 11.78-22.09 18.95-12.26 14.32-17.26 30.65-21.61 31.48-.72-.39-1.42-.8-2.12-1.22-20.35-12.03-32.9-28.54-32.9-46.76 0-36.82 51.27-66.68 114.53-66.68 63.25 0 114.53 29.86 114.53 66.68z" />
    ),
    basic: {
      dark: (
        <path d="M143.8 212.31c3.93 2.52 9.06.01 12.9-2.65 3.79 3.8 11.54 1.45 12.57-3.82 4.7-2.98 9.24-6.22 13.59-9.69 2.64-2.11 5.45-4.86 5.19-8.23-.11-1.41-.72-2.97.1-4.12.72-1 2.15-1.08 3.35-1.37 3.43-.8 6.05-4.2 5.95-7.72 1.93 2.38 5.81.4 7.75-1.98s4.19-5.45 7.21-4.92c2.96.52 4.81 4.43 7.79 4.09 1.35-.16 2.54-1.21 3.89-1.05.82.1 1.5.63 2.13 1.16 3.93 3.26 7.56 6.89 10.81 10.82.62.75 1.26 1.54 2.15 1.96 2.83 1.35 5.69-1.71 7.35-4.37 1.34 1.79 4.22.96 5.97-.42s3.47-3.27 5.7-3.2c1.46.04 2.76.95 4.2 1.16 1.15.17 2.32-.12 3.44-.4 2.14-.54 4.34-1.1 6.11-2.43 1.76-1.32 3-3.61 2.42-5.74.61 2.72 4.62 1.91 7.24.98 4.47-1.58 9.52-1.48 13.92.27-.99 2.4 1.93 4.63 4.5 4.98 2.57.35 5.49-.01 7.45 1.69 1.78 1.54 2.02 4.18 3.1 6.27 1.95 3.76 7.13 5.43 10.91 3.52 2.21 5.2 4.41 10.4 6.62 15.6.82 1.94 1.74 3.99 3.51 5.12 2.73 1.75 7.04 1.04 8.68 3.83.48.81.61 1.79 1.1 2.59 1.4 2.3 4.75 2.1 7.4 1.62.79 4.64 1.25 9.34 1.38 14.05.06 2.16.03 4.4-.8 6.4-1.88 4.55-7.14 6.53-11.9 7.77-18.47 4.83-37.68 5.95-56.74 7.06-10.92.63-21.85 1.26-32.78 1.76-21.38.98-42.84 1.45-64.14-.55-8.43-.79-16.92-2-24.76-5.21-4.31-1.77-8.45-4.18-11.54-7.67-7.5-8.46-7.62-21.33-6.69-31.98.41 1.79 1.26 3.72 2.97 4.82z" />
      ),
    },
  },
};

const hair_base_146NN = {
  id: "hair_base_146NN",
  name: "Hair style 5",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M383.27 183.37c0 21.6-16.19 41.08-42.09 54.77-.94 1.11-2.05 2.06-3.35 2.76-1.35.73-2.91 1.16-4.43 1.05h-.01c-.45-.03-.91-.1-1.35-.24-4-1.21-5.52-6.06-5.78-10.24-.06-1.04-.1-2.1-.15-3.17v-.01c-.63-.76-1.13-1.55-1.47-2.33-.98-2.24-1.28-4.78-1.59-7.29-3.04-3.13-8.21-4.45-9.52-8.6-.42-1.33-.34-2.72-.17-4.13.21-1.8.59-3.63.26-5.4-.62-3.29-3.51-5.66-6.48-7.22-1.52-.8-3.12-1.47-4.67-2.23-1.47-.71-2.88-1.51-4.12-2.57-2.32-1.97-3.87-4.7-5.68-7.17-1.05-1.45-2.19-2.8-3.63-3.86-2.71-1.99-5.83-2.59-9.15-2.6-7.12-.02-15.12 2.65-21.8-.06-3.18-1.29-5.77-3.7-8.59-5.67-2.82 1.97-5.41 4.38-8.59 5.67-6.68 2.71-14.69.04-21.8.06-3.32.01-6.44.61-9.15 2.6-1.44 1.06-2.59 2.41-3.65 3.86-1.8 2.47-3.34 5.2-5.66 7.17-1.24 1.06-2.65 1.86-4.12 2.57-1.55.76-3.15 1.43-4.67 2.23-2.97 1.56-5.87 3.93-6.48 7.22-.33 1.76.04 3.59.26 5.39.17 1.41.25 2.8-.18 4.14-1.3 4.15-6.48 5.47-9.51 8.6-.23.24-.45.49-.65.75-1.93 2.47-2.26 5.68-2.42 8.89-.05 1.06-.08 2.13-.14 3.16-.26 4.18-1.78 9.03-5.78 10.24-.19.06-.38.11-.58.14-1.76.35-3.62-.09-5.21-.95-1.64-.88-2.99-2.17-4.06-3.68-24.9-13.64-40.38-32.73-40.38-53.85 0-41.49 59.67-75.12 133.27-75.12s133.27 33.63 133.27 75.12z" />
    ),
    basic: {
      dark: (
        <path d="M370.43 225.84c-2.46 5.09-5.04 10.34-9.36 13.98-2.32 1.96-5.06 3.37-7.77 4.76-4.45 2.28-8.98 4.58-13.64 6.38-.03.02-.07.03-.1.04-4.39 1.93-9.17 2.81-13.78 4.12-6.87 1.95-13.42 4.86-20.07 7.47-27.91 10.96-58.04 16.73-87.97 15.09-29.94-1.64-59.66-10.87-84.3-27.95-2.03-1.41-4.09-2.94-5.26-5.11-1.46-2.7-1.32-5.96-.95-9.01.33-2.73.9-13.18 4.2-14.11 2.06-.58 6.78 5.52 9.69 6.22 4.74 1.15 10.53-1.97 10.87-6.83 6.97 1.62 14.61-4.59 14.46-11.75 6.45-.25 11.52-7.65 9.44-13.76 3.94-1.04 7.13-4.49 7.86-8.49.22-1.2.27-2.53 1.06-3.46.81-.97 2.16-1.24 3.3-1.79 2.14-1.03 3.63-3.3 3.72-5.68 2.34 1.15 4.82-1.39 5.85-3.79 1.03-2.4 2-5.36 4.52-6.03 1.84-.49 3.74.51 5.64.6 3.35.15 6.11-2.47 8.42-4.89.83 2.11 3.44 2.91 5.71 2.89 2.27-.03 4.58-.59 6.79-.05 1.36.34 2.6 1.08 3.98 1.38 3.39.74 7.13-1.85 7.63-5.29.21-1.4.15-3.21 1.46-3.77.93-.4 1.96.14 2.96.26 2.94.33 5.26-3.1 8.22-2.97 3.11.14 4.62 3.88 7.27 5.51 4.05 2.49 9.14-.52 12.9-3.43 1.75 4.05 7.9 1.84 12.17 2.93 4.8 1.23 7.22 6.99 11.9 8.6 1.24.42 2.61.55 3.67 1.31 2.12 1.52 1.96 4.66 2.9 7.1 2.41 6.22 10.83 6.52 17.41 7.64 1.67.28 3.43.75 4.53 2.04.91 1.08 1.2 2.53 1.46 3.92.91 4.86 1.81 9.72 2.71 14.58.39 2.09.95 4.43 2.76 5.54 2 1.21 5.5 1.19 5.55 3.52.02 1.26-1.17 2.14-1.82 3.21-3.09 5.1 3.65 10.15 7.69 11.67 2.23.84 4.97.78 6.78-.77 1.24-1.06 2.14-2.81 3.77-2.92.81-.06 1.57.33 2.3.69 4.26 2.13 9.81 3.46 13.47.4z" />
      ),
    },
  },
};

const hair_base_uq6BF = {
  id: "hair_base_uq6BF",
  name: "Hair style 6",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M369.97 184.36c0 22.77-15.39 43.24-39.86 57.33-.93.55-1.87 1.07-2.83 1.58-5.65-2.92-10.01-23.54-21.54-40.1-11.8-18.58-31.92-32.44-55.75-32.35-23.82-.09-43.94 13.77-55.74 32.35-11.54 16.56-15.89 37.18-21.54 40.1-.95-.51-1.89-1.03-2.82-1.58-24.47-14.09-39.86-34.56-39.86-57.33 0-42.54 53.71-77.03 119.97-77.03s119.97 34.49 119.97 77.03z" />
    ),
    basic: {
      dark: (
        <path d="M136.85 214.41c4.95 3.27 11.42 4.11 17.04 2.22 4.19-1.41 7.81-4.22 10.75-7.52.99-1.11 2-2.34 3.43-2.75 1.13-.33 2.34-.08 3.52-.05 5.83.15 10.37-5 13.16-10.13 2.78-5.13 4.94-10.96 9.67-14.37 2.53-1.82 5.57-2.76 8.37-4.13 5.68-2.77 10.33-7.24 15.7-10.58 11.75-7.31 26.1-8.72 39.89-9.95 2.54-.23 5.16-.45 7.6.27 2.68.79 4.91 2.63 7.4 3.91 7.53 3.85 17.65 2.65 23.45 8.81 3.86 4.11 4.71 10.58 9.11 14.12 5.65 4.55 15.87 3.23 18.67 9.93 1.75 4.2-.65 9.72 2.27 13.22 2.36 2.83 7.01 2.66 9.55 5.33 1.52 1.6 2.01 3.96 3.49 5.61 2.24 2.49 6.1 2.67 9.36 1.92s6.36-2.24 9.7-2.48c1.09-.08 2.27.01 3.14.69 1.43 1.12 1.4 3.26 1.17 5.06-1 7.98-3.16 15.82-6.39 23.18-1.1 2.51-2.37 5.03-4.38 6.9-2.55 2.38-6.02 3.47-9.38 4.37-14.73 3.95-30.06 5.65-45.3 5.01-11.55-.48-23.02-2.29-34.57-2.7-19.52-.69-38.95 2.63-58.48 2.97-15.81.28-31.65-1.4-47.05-5-8.25-1.92-16.79-4.65-22.51-10.9-5.2-5.68-7.32-13.53-8.27-21.17-.98-7.9-1.53-17.18.55-24.94 1.82 1.14 2.28 4.97 3.47 6.94 1.46 2.49 3.47 4.63 5.87 6.21z" />
      ),
    },
  },
};

export const HairBasePack1 = {
  hair_base_ySGtz,
  hair_base_fs73J,
  hair_base_7Uawj,
  hair_base_2M48g,
  hair_base_146NN,
  hair_base_uq6BF,
};

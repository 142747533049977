import { allColours } from "character-creator/colours/colourSchemes";
import { TailShapeListArray } from "character-creator/components/body/extras/tails/TailShapeList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

// const basePath = "base.";
// const bodyPath = "body.";
const configPath = "tail.";

const tailsPageConfig = {
  id: "tailsPage",
  name: "Tails",
  description: "",
  controls: [
    {
      id: "tailShape",
      name: "Shape",
      description: "",
      dataAttribute: [configPath + "shape"],
      previewType: "preview",
      options: TailShapeListArray,
      colourControls: [
        {
          id: "tailColour",
          name: "Colour",
          description: "",
          dataAttribute: [configPath + "colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
        // {
        //   id: "tailTipColour",
        //   name: "Tip Colour",
        //   description: "",
        //   dataAttribute: [configPath + "variations.tip.colour"],
        //   attributeType: "multi",
        //   previewType: "swatch",
        //   options: allColours,
        // },
      ],
      // variations: [
      //   { name: "Tips", value: "tip", description: "In tip top shape" },
      // ],
      // variationsAttribute: [configPath + "variations"],
    },
  ],
};

const TailsPage = {
  id: "tailsPage",
  name: "Tails",
  description: "Start your new career as a bodybuilder",
  content: <SubPage page={tailsPageConfig} />,
};

export default TailsPage;

import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { eyelashPack1Upper } from "./packs/EyelashesPack1Upper";
import { eyelashPack1Lower } from "./packs/EyelashesPack1Lower";
import { eyelashPack2Upper } from "./packs/EyelashesPack2Upper";
import { eyelashPack2Lower } from "./packs/EyelashesPack2Lower";

export const eyelashShapeListUpper = Object.values({
  "0": BlankListOption,
  ...eyelashPack1Upper,
  ...eyelashPack2Upper,
});

export const eyelashShapeListLower = Object.values({
  "0": BlankListOption,
  ...eyelashPack1Lower,
  ...eyelashPack2Lower,
});

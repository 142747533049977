// Icons
// import icon from "character-creator/assets/menu-icons/streamline-cog.png";

// Sections
import TabbedPanelContainer from "character-creator/menu/elements/TabbedPanelContainer";
import BackgroundShapesPage from "./pages/BackgroundShapesPage";
import React from "react";

const BackgroundMenuConfig = {
  id: "Background",
  name: "Background",
  // icon: icon,
  description:
    "Want something a bit more exciting than a plain white background?",
  content: (
    <TabbedPanelContainer
      id="tabbedPanelBackground"
      tabs={{ backgroundShapesPage: BackgroundShapesPage }}
      containerClasses={"menu-block"}
      navClasses={"nav-inline"}
    />
  ),
};

export default BackgroundMenuConfig;

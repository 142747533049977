import React, { Fragment, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { get, random } from "lodash";
import ControlColours from "./colour/ControlColours";
import {
  checkDependencies,
  ControlOptionsList,
  hasVariation,
  buildRecentColoursList,
  filterListById,
} from "../../menu-helper";
import { BlankListOption } from "./options/BlankOption";
// import { updateConfig } from "character-creator/utilities/characterSlice";
import {
  getColourConfigValue,
  getConfigValue,
  updateCharacterConfig,
  updateConfig,
  updateRecentColoursConfig,
  updateSettingsConfig,
  updateStyleConfig,
} from "character-creator/utilities/storeHelpers";
import ControlGroup from "./ControlGroup";
import { range } from "lodash";

const mapStateToProps = (state, ownProps) => {
  const configType = ownProps.control.stateType;
  const itemIndex = ownProps.control.itemIndex ?? 0;
  const dynamicAttributeValue = ownProps.control.dynamicAttribute
    ? getConfigValue(configType, state, ownProps.control.dynamicDataAttribute)
    : null;

  let controlConfigValue = "0";
  let newConfigObject = [{}];
  if (ownProps.control.parentDataAttribute) {
    const parentValue = getConfigValue(
      configType,
      state,
      ownProps.control.parentDataAttribute
    );
    if (parentValue && parentValue.length > 0) {
      controlConfigValue =
        parentValue[itemIndex][ownProps.control.dataAttribute];
    }
  } else {
    controlConfigValue = getConfigValue(
      configType,
      state,
      ownProps.control.dataAttribute[0]
    );
  }

  const onChangeFunction =
    configType === "settings"
      ? updateSettingsConfig
      : configType === "style"
      ? updateStyleConfig
      : updateCharacterConfig;

  const controlDependencies = ownProps.control.dependencies;

  const dependenciesMet = controlDependencies
    ? checkDependencies(controlDependencies, state)
    : true;

  const variationsStateValue =
    ownProps.control.variationsAttribute &&
    getConfigValue(configType, state, ownProps.control.variationsAttribute[0]);

  const selectedOption = ownProps.control.attributeType
    ? ownProps.control.id
    : controlConfigValue;

  // const selectedOption = controlConfigValue;

  // const allControlOptionsKeys = Object.keys(ownProps.control.options);
  // const allControlOptionsValues = Object.values(ownProps.control.options);

  // let controlOptionsKeys = allControlOptionsKeys;
  // let controlOptionsValues = allControlOptionsValues;

  return {
    dependenciesMet,
    itemsPerPage: state.menu.controlsPerPage,
    dataAttributeValue: controlConfigValue,
    selectedOption: selectedOption ? selectedOption : ownProps.selectedOption,
    sectionLevel: ownProps.sectionLevel,
    variationsStateValue,
    recentColours: getColourConfigValue(state, "recentColours"),
    onChangeFunction,
    parentDataAttribute: ownProps.control.parentDataAttribute,
    itemIndex,
    newConfigObject,
    dynamicAttributeValue,
    // controlOptionsKeys,
    // controlOptionsValues,
  };
};

const ControlContainer = (props) => {
  // Current page, total pages, page limit
  const {
    dependenciesMet,
    control,
    itemsPerPage,
    sectionLevel,
    colourControl = false,
    variationsStateValue,
    recentColours,
    hideHeader = false,
    onChangeFunction,
    parentDataAttribute,
    itemIndex,
    dynamicAttributeValue,
    // controlOptionsKeys,
    // controlOptionsValues,
  } = props;

  const dispatch = useDispatch();

  const selectedOption = props.selectedOption
    ? typeof props.selectedOption == "string"
      ? props.selectedOption
      : props.selectedOption.id
    : null;

  // const allControlOptionsKeys = Object.keys(control.options);
  // const allControlOptionsValues = Object.values(control.options);

  // Will need to work out filters and searches at some point here

  // const controlOptions = control.options;

  let allControlOptions = control.options;
  let controlOptions = allControlOptions;

  // control.previewType !== "swatch" && console.log(control.id);
  // control.previewType !== "swatch" &&
  //   control.dynamicAttribute &&
  //   console.log(
  //     "dynamic shape found",
  //     control.dynamicAttribute,
  //     "(" + dynamicAttributeValue + ")"
  //   );

  if (
    control.previewType !== "swatch" &&
    !!control.dynamicShape &&
    dynamicAttributeValue
  ) {
    console.log("all controls length:", allControlOptions.length);
    // go through controlOptionsValues and make a new list where only valid options exist based on the dynamicAttribute
    controlOptions = allControlOptions.filter((option) => {
      let validShape = false;

      if (option.shape && option.shape[dynamicAttributeValue]) {
        validShape = true;
      }

      return validShape;
    });

    console.log("new values length:", controlOptions.length);
  }

  const totalPages = Math.ceil(controlOptions.length / itemsPerPage);
  const [currentPage, updateCurrentPage] = useState(1);
  currentPage > totalPages && updateCurrentPage(1);

  const pageNumbers = range(1, totalPages + 1);

  // Get current shown items
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;

  const currentOptions = ControlOptionsList({
    controlName: control.id,
    dataAttribute: control.dataAttribute,
    selected: selectedOption,
    previewType: control.previewType,
    previewSide: control.previewSide,
    attributeType: control.attributeType,
    options: controlOptions.slice(firstItemIndex, lastItemIndex),
    onChangeFunction,
    parentDataAttribute,
    itemIndex,
  });

  // Variations
  const optionVariations =
    selectedOption &&
    selectedOption !== "0" &&
    control.variations &&
    control.variations.map((variation, variationId) => {
      console.log(variationId, variationsStateValue);

      const selectedMainOption = filterListById(controlOptions, selectedOption);

      const isVariationAvailable = hasVariation(
        selectedMainOption,
        variation.value
      );

      console.log("is this variation available?", isVariationAvailable);

      const variationOptions = selectedMainOption
        ? selectedMainOption.variations[variation.value].options
        : {};

      let variationObjectsList =
        isVariationAvailable &&
        [BlankListOption].concat(
          Array.isArray(variationOptions)
            ? variationOptions
            : Object.values(variationOptions)
        );

      console.log("available variations", variationObjectsList);

      const variationDataAttribute = [
        `${control.variationsAttribute}.${variation.value}`,
      ];

      return (
        isVariationAvailable && (
          <Fragment key={variationId}>
            <div className="header">
              {sectionLevel ? (
                <h3>{variation.name}</h3>
              ) : (
                <h4>{variation.name}</h4>
              )}
              {variation.description && (
                <p className="menu-description">{variation.description}</p>
              )}
            </div>
            <fieldset
              id={control.id + "_variations_" + variation.value}
              // key={variation.value}
            >
              <legend className="sr-only">{variation.name}</legend>
              <ul>
                {ControlOptionsList({
                  controlName: variation.value,
                  dataAttribute: variationDataAttribute,
                  selected:
                    !!variationsStateValue &&
                    variationsStateValue[variation.value]
                      ? variationsStateValue[variation.value]
                      : "0",
                  previewType: control.previewType,
                  previewSide: control.previewSide,
                  attributeType: control.attributeType,
                  options: variationObjectsList,
                  onChangeFunction,
                  parentDataAttribute,
                  itemIndex,
                })}
              </ul>
            </fieldset>
          </Fragment>
        )
      );
    });

  // Page controls
  const pageControls = pageNumbers.map((number) => {
    const isCurrentPage = currentPage === number;
    const classNames = isCurrentPage ? "selected" : "";

    return (
      <li
        key={number}
        aria-label={
          isCurrentPage
            ? `Current page, page ${number} of ${control.name}`
            : `Page ${number} of ${control.name}`
        }
        data-page={number}
        onClick={() => updateCurrentPage(number)}
        className={classNames ? "selected" : ""}
        aria-current={isCurrentPage ? "true" : "false"}
        data-currentpage={isCurrentPage ? "true" : "false"}
      >
        {number}
      </li>
    );
  });

  const pickRandom = () => {
    let randomValue = controlOptions[random(1, controlOptions.length - 1)].id;
    if (control.attributeType === "multi") {
      if (colourControl) {
        let updatedColours = buildRecentColoursList(recentColours, randomValue);

        updateConfig(dispatch, updateRecentColoursConfig, [""], updatedColours);
        // updateRecentColoursConfig(updatedColours);
      }
    }

    if (control.parentDataAttribute) {
      let updatedParentArray = control.parentDataArray;
      updatedParentArray[itemIndex][control.dataAttribute] = randomValue;

      updateConfig(
        dispatch,
        onChangeFunction,
        parentDataAttribute,
        updatedParentArray
      );
    } else {
      updateConfig(
        dispatch,
        onChangeFunction,
        control.dataAttribute,
        randomValue
      );
    }
  };

  const PageControlsSection = (
    <div
      className="pagination"
      aria-label={`Pagination controls for ${control.name}`}
    >
      <nav className="nav-inline">
        <ul>{pageControls}</ul>
      </nav>
    </div>
  );

  // Return the whole thing
  if (dependenciesMet) {
    return (
      <div className="control-container">
        {!hideHeader && (
          <div className="header">
            {sectionLevel ? <h2>{control.name}</h2> : <h3>{control.name}</h3>}
            <div className="actions">
              <button
                className="button-actions"
                onClick={() => pickRandom()}
                id={control.id + "_random"}
              >
                Random
              </button>
            </div>
            {control.description && (
              <p className="menu-description">{control.description}</p>
            )}
          </div>
        )}

        <fieldset>
          <legend className="sr-only">{control.name}</legend>

          {colourControl && (
            <ControlColours
              control={control}
              onChangeFunction={onChangeFunction}
            />
          )}
          <ul>{currentOptions}</ul>
        </fieldset>
        {totalPages > 1 && PageControlsSection}
        {control.variations && (
          <div className="item-variations">{optionVariations}</div>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export const BuildControlContainer = (props) => {
  const { controlGroups, sectionData, section, symmetry } = props;

  return controlGroups.map((control, index) => {
    if (control.attributeType === "list") {
      const dataList = sectionData && sectionData[control.dataAttribute];
      if (dataList && dataList.length > 0) {
        const controlList = dataList.map((item, itemIndex) => {
          control.parentDataAttribute = section.dataAttribute;
          control.itemIndex = itemIndex;
          return (
            <div key={`${index}-${itemIndex}`}>
              <ControlGroup
                sectionLevel={false}
                group={control}
                parentDataAttribute={section.dataAttribute}
                itemIndex={itemIndex}
              />
            </div>
          );
        });

        return controlList;
      } else {
        return (
          <div key={index}>
            <ControlGroup
              sectionLevel={false}
              group={control}
              parentDataAttribute={section.dataAttribute}
              itemIndex={0}
            />
          </div>
        );
      }
    }

    return (
      <div key={index}>
        <ControlGroup sectionLevel={false} group={control} />
      </div>
    );
  });
};

export default connect(mapStateToProps)(ControlContainer);

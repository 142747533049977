import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import AllPurples from "character-creator/colours/collections/Purples";
import { PatternsList } from "./PatternList";

function mapStateToProps(state, ownProps) {
  // Config
  const patternConfig = ownProps.pattern;

  // Shape 1
  const patternObject =
    ownProps.preview && ownProps.pattern
      ? ownProps.pattern
      : PatternsList[patternConfig.shape];

  const patternColours = patternConfig.colour
    ? patternConfig.colour
    : AllPurples[Object.keys(AllPurples)[0]];

  return {
    patternObject,
    patternColours,
    patternTransform: patternConfig.transform,
  };
}

const Pattern = (props) => {
  const {
    patternObject,
    patternColours,
    patternTransform,
    previewId,
    patternId,
    maskId,
  } = props;

  if (patternObject?.shape) {
    return (
      <ItemComponent
        id={previewId ? `pattern~${previewId}` : patternId}
        component={patternObject}
        colour={patternColours}
        preview={previewId ? true : false}
        maskId={maskId}
        transform={patternTransform}
        style={{ mixBlendMode: "color" }}
      />
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Pattern);

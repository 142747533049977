import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import { HeadShapeList, HeadShapeListArray } from "../head/shape/HeadShapeList";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  // const colour = ownProps.colour;

  const headShadowObject = {
    id: "headShadow",
    shape: {
      base: (
        <g transform="translate(0 15)" opacity="0.75" stroke="none">
          {
            filterListById(
              HeadShapeListArray,
              getCharacterConfigValue(state, "head.shape")
            ).shape.base
          }
        </g>
      ),
    },
  };
  const headShadowColour = {
    base: getCharacterConfigValue(state, "skintone").dark,
  };

  return {
    HeadShadow: { shape: headShadowObject, colour: headShadowColour },
  };
}
const CharacterHeadShadow = (props) => {
  const { HeadShadow, previewId, alternateId, alternateMask } = props;

  let componentId = "headShadow";
  let maskId = previewId ? `body~${previewId}_mask` : "body_mask";

  if (alternateId) {
    componentId = alternateId;
  }
  if (alternateMask) {
    maskId = alternateMask;
  }

  return (
    <g
      id={previewId ? `g-${componentId}~${previewId}` : `g-${componentId}`}
      style={{
        mask: `url(#${maskId})`,
      }}
    >
      <ItemComponent
        id={previewId ? `${componentId}~${previewId}` : componentId}
        component={HeadShadow.shape}
        colour={HeadShadow.colour}
        // strokeOverride={false}
      />
    </g>
  );
};

export default connect(mapStateToProps)(CharacterHeadShadow);

import { BodyShapePack1 } from "./packs/BodyShapePack1";
import { BodyShapePack2 } from "./packs/BodyShapePack2";
import { BodyShapePack3 } from "./packs/BodyShapePack3";
import { BodyShapePack4 } from "./packs/BodyShapePack4";

export const BodyShapeList = {
  ...BodyShapePack1,
  ...BodyShapePack2,
  ...BodyShapePack3,
  ...BodyShapePack4,
};

export const BodyShapeListArray = Object.values(BodyShapeList);

import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const eyebrow_aWAA8kBq4x = {
  id: "eyebrow_aWAA8kBq4x",
  name: "Eyebrow 17",
  description:
    "Narrow, short and straight brows that have a slightly jagged outer corner",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M226.5 219.559c1.827.564 6.6 2.244 5.822-.396-.318-1.075-1.259-2.184-2.112-2.915-2.327-1.995-6.483-2.266-9.403-2.401-6.209-.288-12.136-.092-18.182 1.837-2.901.926-5.227 3.908-5.019 4.072.189.149 5.077-2.722 6.598-2.494.035.005-.921 2.946-.121 3.172.418.118 1.15-.618 1.513-.768 1.053-.436 1.27.51 2.854-.156 3.104-1.305 6.087-1.117 9.484-.975 2.836.119 6.007.234 8.566 1.024Z" />
      ),
      dark: (
        <path d="M202.165 215.849c-.137.311-.296.638-.147.7.351.148 3.86-1.161 4.906-.766.71.268-.58 1.896-.453 2.019.18.174 3.987-.916 3.883-.15-.066.493-.284.961-.618 1.397-.427.128-.855.28-1.286.461-1.584.666-1.801-.28-2.854.156-.363.15-1.095.886-1.513.768-.8-.226.156-3.167.121-3.172-1.521-.228-6.409 2.643-6.598 2.494-.197-.156 1.877-2.836 4.559-3.907Zm25.71-.913c.878.324 1.679.75 2.335 1.312.853.731 1.794 1.84 2.112 2.915.778 2.64-3.995.96-5.822.396-2.559-.79-5.73-.905-8.566-1.024-.447-.019-.887-.038-1.321-.055 2.321-.675 5.254-1.152 8.284-.922.078.006-1.238-1.541-1.067-1.612.67-.281 3.591 1.735 3.785 1.565.326-.285.296-1.632.26-2.575Z" />
      ),
    },
    right: {
      base: (
        <path d="M273.5 219.559c-1.827.564-6.6 2.244-5.822-.396.318-1.075 1.259-2.184 2.112-2.915 2.327-1.995 6.483-2.266 9.403-2.401 6.209-.288 12.136-.092 18.182 1.837 2.901.926 5.227 3.908 5.019 4.072-.189.149-5.077-2.722-6.598-2.494-.035.005.921 2.946.121 3.172-.418.118-1.15-.618-1.513-.768-1.053-.436-1.27.51-2.854-.156-3.104-1.305-6.087-1.117-9.484-.975-2.836.119-6.007.234-8.566 1.024Z" />
      ),
      basic: {
        dark: (
          <path d="M283.387 218.48c-.434.017-.874.036-1.321.055-2.836.119-6.007.234-8.566 1.024-1.827.564-6.6 2.244-5.822-.396.318-1.075 1.259-2.184 2.112-2.915.656-.562 1.457-.988 2.335-1.312-.036.943-.066 2.29.26 2.575.194.17 3.115-1.846 3.785-1.565.171.071-1.145 1.618-1.067 1.612 3.03-.23 5.963.247 8.284.922Zm14.448-2.631c2.682 1.071 4.756 3.751 4.559 3.907-.189.149-5.077-2.722-6.598-2.494-.035.005.921 2.946.121 3.172-.418.118-1.15-.618-1.513-.768-1.053-.436-1.27.51-2.854-.156a12.823 12.823 0 0 0-1.286-.461c-.334-.436-.552-.904-.618-1.397-.104-.766 3.703.324 3.883.15.127-.123-1.163-1.751-.453-2.019 1.046-.395 4.555.914 4.906.766.149-.062-.01-.389-.147-.7Z" />
        ),
      },
    },
  },
};

const eyebrow_kmi4k6hrMZ = {
  id: "eyebrow_kmi4k6hrMZ",
  name: "Eyebrow 18",
  description:
    "Thick, smooth and straight brows that have a very slight arch at the outer corner",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M226.519 226.231c5.794.435 12.122 1.508 10.188-4.374-1.101-3.35-3.635-4.937-6.292-6.414-4.796-2.664-24.638-3.804-30.069-3.949-7.539-.201-13.12.497-17.808 6.455-3.807 4.839-2.922 9.186 3.466 6.345 6.415-2.852 10.827-2.401 17.042-2.233 8.484.229 16.871 3.675 23.473 4.17Z" />
      ),
      dark: (
        <path d="M183.571 216.736c1.128-.632 2.347-.994 3.6-.947.48.018-2.71 2.986-1.963 3.488 1.12.753 5.079-2.817 6.09-2.276.233.125-.422 1.009-.207 1.357.16.259.994-.053.956.054-.236.653-2.026 2.417-1.808 2.601.257.217 3.192-.566 4.23-.783 3.92-.822 9.982.383 9.58.911-.105.137-1.304.448-3.139.854-5.151-.163-9.25-.216-14.906 2.299-6.388 2.841-7.273-1.506-3.466-6.345.34-.431.684-.835 1.033-1.213Z" />
      ),
      light: (
        <path d="M235.969 220.188c-.028.088-.06.154-.083.198-.097.188-3.559-1.788-3.73-1.604-.228.244.743 1.758.697 2.012-.039.211-.412.206-.522.416-.231.438.058 1.786-.757 1.628-.928-.181-5.881-4.474-7.836-5.096-1.159-.369.057 1.076-.606 1.286-.585.185-1.393-.688-1.963-.807-.424-.088-.718.301-1.24.141-1.403-.428-3.091-1.926-4.389-2.577-5.121-2.567-8.6-3.638-10.718-4.096 7.976.44 21.711 1.598 25.593 3.754 2.172 1.207 4.262 2.488 5.554 4.745Z" />
      ),
    },
    right: {
      base: (
        <path d="M273.481 226.231c-5.794.435-12.122 1.508-10.188-4.374 1.101-3.35 3.635-4.937 6.292-6.414 4.796-2.664 24.638-3.804 30.069-3.949 7.539-.201 13.12.497 17.808 6.455 3.807 4.839 2.922 9.186-3.466 6.345-6.415-2.852-10.827-2.401-17.042-2.233-8.484.229-16.871 3.675-23.473 4.17Z" />
      ),
      dark: (
        <path d="M299.09 221.995c-1.835-.406-3.034-.717-3.139-.854-.402-.528 5.66-1.733 9.58-.911 1.038.217 3.973 1 4.23.783.218-.184-1.572-1.948-1.808-2.601-.038-.107.796.205.956-.054.215-.348-.44-1.232-.207-1.357 1.011-.541 4.97 3.029 6.09 2.276.747-.502-2.443-3.47-1.963-3.488 1.253-.047 2.472.315 3.6.947.349.378.693.782 1.033 1.213 3.807 4.839 2.922 9.186-3.466 6.345-5.656-2.515-9.755-2.462-14.906-2.299Z" />
      ),
      light: (
        <path d="M264.031 220.188c1.292-2.257 3.382-3.538 5.554-4.745 3.882-2.156 17.617-3.314 25.593-3.754-2.118.458-5.597 1.529-10.718 4.096-1.298.651-2.986 2.149-4.389 2.577-.522.16-.816-.229-1.24-.141-.57.119-1.378.992-1.963.807-.663-.21.553-1.655-.606-1.286-1.955.622-6.908 4.915-7.836 5.096-.815.158-.526-1.19-.757-1.628-.11-.21-.483-.205-.522-.416-.046-.254.925-1.768.697-2.012-.171-.184-3.633 1.792-3.73 1.604a1.214 1.214 0 0 1-.083-.198Z" />
      ),
    },
  },
};

const eyebrow_ec4K4U2V5r = {
  id: "eyebrow_ec4K4U2V5r",
  name: "Eyebrow 19",
  description:
    "Widely-spaced narrow, straight and smooth brows that taper slightly to a sharp outer corner",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M181.664 226.034c.012.011 4.405-10.352 14.628-11.326 8.529-.813 17.3-1.831 25.569.312 1.885.489 4.306 1.585 5.415 3.265.813 1.231.394 3.331-.964 4.004-1.866.924-6.662-1.189-21.228-1.172-15.357.018-23.42 4.917-23.42 4.917Z" />
      ),
      dark: (
        <path d="M207.686 221.137c-.831-.014-1.697-.021-2.602-.02-15.315.018-23.377 4.89-23.42 4.917.029-.034 3.13-7.303 10.153-10.214.881.689-.205 2.741 1.298 3.286 1.443.524 3.198.18 4.685.123 5.173-.196 8.255.961 9.886 1.908Zm-26.022 4.897Z" />
      ),
      light: (
        <path d="M227.282 221.344c-5.585-5.012-13.295-3.208-15.94-4.675-1.271-.705 1.82-1.33 1.478-1.777-.857-1.123-3.17-.794-4.379-1.087a.146.146 0 0 1-.066-.029c4.563-.141 9.095.106 13.486 1.244 1.885.489 4.306 1.585 5.415 3.265.566.857.535 2.134.006 3.059Z" />
      ),
    },
    right: {
      base: (
        <path d="M318.336 226.034c-.012.011-4.405-10.352-14.628-11.326-8.529-.813-17.3-1.831-25.569.312-1.885.489-4.306 1.585-5.415 3.265-.813 1.231-.394 3.331.964 4.004 1.866.924 6.662-1.189 21.228-1.172 15.357.018 23.42 4.917 23.42 4.917Z" />
      ),
      dark: (
        <path d="M292.314 221.137c1.631-.947 4.713-2.104 9.886-1.908 1.487.057 3.242.401 4.685-.123 1.503-.545.417-2.597 1.298-3.286 7.023 2.911 10.124 10.18 10.153 10.214-.043-.027-8.105-4.899-23.42-4.917-.905-.001-1.771.006-2.602.02Zm26.022 4.897Z" />
      ),
      light: (
        <path d="M291.625 213.776a.146.146 0 0 1-.066.029c-1.209.293-3.522-.036-4.379 1.087-.342.447 2.749 1.072 1.478 1.777-2.645 1.467-10.355-.337-15.94 4.675-.529-.925-.56-2.202.006-3.059 1.109-1.68 3.53-2.776 5.415-3.265 4.391-1.138 8.923-1.385 13.486-1.244Z" />
      ),
    },
  },
};

const eyebrow_uczmUn6gFF = {
  id: "eyebrow_uczmUn6gFF",
  name: "Eyebrow 20",
  description:
    "Narrow brows that are slightly rounded in the inner corner and taper to a point just above the centre of the eye",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M198.098 217.077c8.993-5.375 34.749-1.455 33.935 3.195-.134.767-1.218 1.075-1.893 1.241-1.814.445-9.374-.121-12.024-1.803-6.284-3.987-21.855-1.535-20.018-2.633Z" />
      ),
      dark: (
        <path d="M229.79 217.693c1.546.789 2.402 1.67 2.243 2.579-.134.767-1.218 1.075-1.893 1.241-1.814.445-9.374-.121-12.024-1.803-.935-.593-2.075-1.043-3.333-1.384.03-.226.225-.42.145-.64-.066-.184-.594-.654-.426-.834.142-.153 2.076-.315 2.777-.269 2.89.191 8.436 2.408 8.615 2.395 1.045-.078.105-2.059.23-2.226.135-.18 2.07.244 3.666.941Z" />
      ),
    },
    right: {
      base: (
        <path d="M301.894 217.077c-8.994-5.375-34.749-1.455-33.936 3.195.135.767 1.218 1.075 1.894 1.241 1.813.445 9.373-.121 12.024-1.803 6.284-3.987 21.855-1.535 20.018-2.633Z" />
      ),
      dark: (
        <path
          id="dark38"
          d="M270.202 217.693c1.596-.697 3.53-1.121 3.666-.941.125.167-.816 2.148.229 2.226.18.013 5.726-2.204 8.615-2.395.701-.046 2.635.116 2.777.269.169.18-.359.65-.426.834-.08.22.115.414.145.64-1.258.341-2.398.791-3.332 1.384-2.651 1.682-10.211 2.248-12.024 1.803-.676-.166-1.759-.474-1.894-1.241-.159-.909.697-1.79 2.244-2.579Z"
        />
      ),
    },
  },
};

const eyebrow_72GWCT3UZc = {
  id: "eyebrow_72GWCT3UZc",
  name: "Eyebrow 21",
  description: "Smooth brows that curve in a slight S-shape",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M219.958 216.279c9.027 5.615 20.533 6.107 18.094 10.547-.832 1.515-4.349 1.169-6.518.61-8.723-2.246-16.603-8.273-26.532-7.712-5.292.299-11.149 3.448-12.172 4.012-.775.426-2.101-1.074-.349-3.315 6.777-8.669 21.967-7.569 27.477-4.142Z" />
      ),
      dark: (
        <path d="M237.157 223.809c1.161.827 1.582 1.765.895 3.017-.832 1.515-4.349 1.169-6.518.61-3.872-.997-7.577-2.739-11.369-4.318.22-.269.702-.349 1-.405 4.56-.856 8.255 3.277 9.141 1.633.203-.376.143-1.127.755-1.039 1.534.221 2.702 1.523 4.263 1.736.643.088 1.361-.614 1.833-1.234Z" />
      ),
      light: (
        <path d="M222.467 217.689c.036.062.05.093.039.089-1.568-.568-3.267-1.377-4.942-1.58-.134-.016 1.232 1.236.927 1.303-.812.181-4.171-1.404-9.742-1.512-7.391-.143-11.333.894-13.35 1.612 7.525-5.536 19.742-4.318 24.559-1.322.82.51 1.66.978 2.509 1.41Z" />
      ),
    },
    right: {
      base: (
        <path d="M280.042 216.279c-9.027 5.615-20.533 6.107-18.094 10.547.832 1.515 4.349 1.169 6.518.61 8.723-2.246 16.603-8.273 26.532-7.712 5.292.299 11.149 3.448 12.172 4.012.775.426 2.101-1.074.349-3.315-6.777-8.669-21.967-7.569-27.477-4.142Z" />
      ),
      dark: (
        <path d="M262.843 223.809c.472.62 1.19 1.322 1.833 1.234 1.561-.213 2.729-1.515 4.263-1.736.612-.088.552.663.755 1.039.886 1.644 4.581-2.489 9.141-1.633.298.056.78.136 1 .405-3.792 1.579-7.497 3.321-11.369 4.318-2.169.559-5.686.905-6.518-.61-.687-1.252-.266-2.19.895-3.017Z" />
      ),
      light: (
        <path d="M277.533 217.689c.849-.432 1.689-.9 2.509-1.41 4.817-2.996 17.034-4.214 24.559 1.322-2.017-.718-5.959-1.755-13.35-1.612-5.571.108-8.93 1.693-9.742 1.512-.305-.067 1.061-1.319.927-1.303-1.675.203-3.374 1.012-4.942 1.58-.011.004.003-.027.039-.089Z" />
      ),
    },
  },
};

const eyebrow_7dAGXNBJkt = {
  id: "eyebrow_7dAGXNBJkt",
  name: "Eyebrow 22",
  description:
    "Smooth brows that feather slightly in the middle, then curve slightly towards the outer corner before tapering to a point",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M233.836 225.852c1.532-.137 1.419-3.399 1.082-4.331-1.197-3.315-4.712-6.607-8.108-7.878-3.442-1.287-.157 1.39-1.457 1.995-.718.334-5.8-1.344-7.863-1.898-36.115-9.699-36.512 8.13-36.483 8.372.288 2.394 5.734-6.809 17.654-5.811 11.303.945 26.8 10.303 35.175 9.551Z" />
      ),
      dark: (
        <path d="M182.268 217.953c.173-.099.354-.166.537-.182.256-.023-.259.983-.105 1.086.099.066 8.132-3.763 9.403-3.028.29.168.072.559-.484 1.089-7.057 1.922-10.389 7.052-10.612 5.194-.009-.078.025-1.957 1.261-4.159Zm50.99.615c.721.945 1.297 1.948 1.66 2.953.337.932.45 4.194-1.082 4.331-6.794.61-18.275-5.433-28.402-8.241 8.741-.697 14.176 1.78 15.699 1.976 1.355.174.247-1.276.882-1.434 2.782-.693 8.984 4.496 9.506 4.152 1.662-1.098-2.543-4.993-1.204-5.291.74-.164 2.158.938 2.632 1.31.098.077.202.158.309.244Z" />
      ),
    },
    right: {
      base: (
        <path d="M266.164 225.852c-1.532-.137-1.419-3.399-1.082-4.331 1.197-3.315 4.712-6.607 8.108-7.878 3.442-1.287.157 1.39 1.457 1.995.718.334 5.8-1.344 7.863-1.898 36.115-9.699 36.512 8.13 36.483 8.372-.288 2.394-5.734-6.809-17.654-5.811-11.303.945-26.8 10.303-35.175 9.551Z" />
      ),
      dark: (
        <path d="M294.566 217.611c-10.127 2.808-21.608 8.851-28.402 8.241-1.532-.137-1.419-3.399-1.082-4.331.363-1.005.939-2.008 1.66-2.953.107-.086.211-.167.309-.244.474-.372 1.892-1.474 2.632-1.31 1.339.298-2.866 4.193-1.204 5.291.522.344 6.724-4.845 9.506-4.152.635.158-.473 1.608.882 1.434 1.523-.196 6.958-2.673 15.699-1.976Zm13.815-.693c-.556-.53-.774-.921-.484-1.089 1.271-.735 9.304 3.094 9.403 3.028.154-.103-.361-1.109-.105-1.086.183.016.364.083.537.182 1.236 2.202 1.27 4.081 1.261 4.159-.223 1.858-3.555-3.272-10.612-5.194Z" />
      ),
    },
  },
};

const eyebrow_S3ZhmL7NMv = {
  id: "eyebrow_S3ZhmL7NMv",
  name: "Eyebrow 23",
  description:
    "Thick, short and straight eyebrows that get thicker and bluntly jagged on the outer end",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M235.794 228.572c2.296-.801 1.335-5.133-.646-6.904-2.887-2.581-7.261-2.833-10.831-3.097-2.552-.189-9.895.287-12.426.247-4.519-.07-12.556-4.373-13.002-4.07-.334.227 1.676 3.158 1.545 3.987-.058.371-3.401.229-3.58.334-.807.475 1.382 2.18.9 2.845-.779 1.078-5.072.746-5.134 1.005-.335 1.392 4.81 3.325 5.597 5.124.222.508-2.83.221-2.747.5.177.59 2.217 2.801 9.447 2.068 4.655-.473 9.437-2.534 14.073-3.029 5.006-.535 13.503 2.142 16.804.99Z" />
      ),
      dark: (
        <path d="M192.939 222.832c2.207.68 4.424.362 4.476.479.152.341-1.717 1.618-1.479 1.748 2.203 1.212 5.133-.446 5.978-.117.354.138-.157.679.161.897.203.139.879-.15.989.064.545 1.065-3.088 2.306-2.315 2.711 1.984 1.04 10.699-1.912 10.757-1.287.073.795-.883 1.666-2.427 2.528-1.394.337-2.784.616-4.162.756-7.23.733-9.27-1.478-9.447-2.068-.083-.279 2.969.008 2.747-.5-.787-1.799-5.932-3.732-5.597-5.124.01-.041.126-.067.319-.087Zm40.256-2.483a9.037 9.037 0 0 1 1.953 1.319c1.981 1.771 2.942 6.103.646 6.904-1.945.679-5.694.028-9.47-.511-1.767-.795-2.993-1.583-2.971-1.608.343-.391 1.987-.217 2.628-.138 3.906.488 1.152-.844 2.582-.636.569.083 1.732.479 2.223.038 1.374-1.233-2.96-3.681-1.964-3.811 2.773-.36 5.432 2.019 5.585 1.772.469-.76-.732-2.565-1.212-3.329Z" />
      ),
      light: (
        <path d="M214.685 218.765c3.056 1.125 8.006.942 6.864 2.067-4.028 3.964-13.349-1.094-13.469-.933-1.017 1.374 2.82 3.253 2.596 3.395-2.505 1.589-5.915-.629-6.388-.593-.996.077 1.296 1.464.071 1.498-.865.024-5.142-1.98-7.306-3.97-.326-.497-.557-.949-.199-1.16.179-.105 3.522.037 3.58-.334.131-.829-1.879-3.76-1.545-3.987.446-.303 8.483 4 13.002 4.07.66.011 1.649-.014 2.794-.053Z" />
      ),
    },
    right: {
      base: (
        <path d="M264.206 228.572c-2.296-.801-1.335-5.133.646-6.904 2.887-2.581 7.261-2.833 10.831-3.097 2.552-.189 9.895.287 12.426.247 4.519-.07 12.556-4.373 13.002-4.07.334.227-1.676 3.158-1.545 3.987.058.371 3.401.229 3.58.334.807.475-1.382 2.18-.9 2.845.779 1.078 5.072.746 5.134 1.005.335 1.392-4.81 3.325-5.597 5.124-.222.508 2.83.221 2.747.5-.177.59-2.217 2.801-9.447 2.068-4.655-.473-9.437-2.534-14.073-3.029-5.006-.535-13.503 2.142-16.804.99Z" />
      ),
      dark: (
        <path d="M273.676 228.061c-3.776.539-7.525 1.19-9.47.511-2.296-.801-1.335-5.133.646-6.904a9.037 9.037 0 0 1 1.953-1.319c-.48.764-1.681 2.569-1.212 3.329.153.247 2.812-2.132 5.585-1.772.996.13-3.338 2.578-1.964 3.811.491.441 1.654.045 2.223-.038 1.43-.208-1.324 1.124 2.582.636.641-.079 2.285-.253 2.628.138.022.025-1.204.813-2.971 1.608Zm17.245 1.794c-1.544-.862-2.5-1.733-2.427-2.528.058-.625 8.773 2.327 10.757 1.287.773-.405-2.86-1.646-2.315-2.711.11-.214.786.075.989-.064.318-.218-.193-.759.161-.897.845-.329 3.775 1.329 5.978.117.238-.13-1.631-1.407-1.479-1.748.052-.117 2.269.201 4.476-.479.193.02.309.046.319.087.335 1.392-4.81 3.325-5.597 5.124-.222.508 2.83.221 2.747.5-.177.59-2.217 2.801-9.447 2.068-1.378-.14-2.768-.419-4.162-.756Z" />
      ),
      light: (
        <path d="M285.315 218.765c1.145.039 2.134.064 2.794.053 4.519-.07 12.556-4.373 13.002-4.07.334.227-1.676 3.158-1.545 3.987.058.371 3.401.229 3.58.334.358.211.127.663-.199 1.16-2.164 1.99-6.441 3.994-7.306 3.97-1.225-.034 1.067-1.421.071-1.498-.473-.036-3.883 2.182-6.388.593-.224-.142 3.613-2.021 2.596-3.395-.12-.161-9.441 4.897-13.469.933-1.142-1.125 3.808-.942 6.864-2.067Z" />
      ),
    },
  },
};

const eyebrow_E2eEhNaPeM = {
  id: "eyebrow_E2eEhNaPeM",
  name: "Eyebrow 24",
  description:
    "Straight, jagged eyebrows that have get thicker and more jagged towards the outside",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M233.794 225.921c4.244.744 8.551-.957 4.416-5.098-2.419-2.422-3.489-2.887-6.664-4.006-3.888-1.369-10.201-1.221-14.26-1.336-2.734-.079-10.482-2.13-15.459-1.205-.965.179-1.632 1.026-2.59 1.249-1.514.351-3.015-.993-4.604-.463-1.543.514-2.716 2.279-3.595 3.55-.103.149-.954 1.178-.909 1.357.001.003 2.079.273 2.107.821.06 1.194-1.887 2.233-2.119 3.491-.064.345 1.514-.229 1.764.074.201.242-.763 2.071-.434 2.601.694 1.118 1.909.132 2.696.584.786.452-.586 2.283.854 2.535 2.845.498 4.381-3.287 6.702-4.282.31-.133.967 1.085 1.234 1.277.725.52 2.125.904 2.663.239 2.036-2.52 11.072-5.858 11.993-5.27 1.403.895-.484 1.987.813 2.247.916.183 1.797-.458 2.737-.259.965.204.924.486 1.887.714 1.69.4 3.868.273 5.581.53 1.408.211 3.786.404 5.187.65Z" />
      ),
      dark: (
        <path d="M191.21 220.185c.767-.304 1.545-1.291 2.389-1.445.574-.104-.079 1.989.281 2.321.198.182 2.224-1.54 2.876-1.478.762.072-.367 2.067.494 2.045 2.056-.051 3.802-4.058 5.408-3.478 1.542.556-.274 3.393 1.258 4.131.666.321 1.525-.495 2.199-.667.697-.178.555.471 1.008.426 2.001-.201 3.122-2.739 4.779-2.296 1.166.312-1.758 3.283-.6 3.453.343.051 3.598-2.758 5.772-2.549.974.093.656.893 1.339 1.216.4.188.481-.625.941-.526 2.68.583 2.935 3.118 5.185 2.753.431-.071.497-.575 1.036-.587.61-.014 1.488.346 2.062.085.537-.244-.188-1.004.727-1.096 2.737-.277 3.469 1.459 5.518 1.101.613-.107.27-.889.73-1 1.269-.306 2.184 1.72 3.088 1.444.47-.144-.246-2.824-.033-2.884.145-.041.494-.025.875.016 3.559 3.889-.625 5.474-4.748 4.751-1.401-.246-3.779-.439-5.187-.65-1.713-.257-3.891-.13-5.581-.53-.963-.228-.922-.51-1.887-.714-.94-.199-1.821.442-2.737.259-1.297-.26.59-1.352-.813-2.247-.921-.588-9.957 2.75-11.993 5.27-.538.665-1.938.281-2.663-.239-.267-.192-.924-1.41-1.234-1.277-2.321.995-3.857 4.78-6.702 4.282-1.44-.252-.068-2.083-.854-2.535-.787-.452-2.002.534-2.696-.584-.329-.53.635-2.359.434-2.601-.25-.303-1.828.271-1.764-.074.232-1.258 2.179-2.297 2.119-3.491-.014-.267-.516-.469-1.026-.605Z" />
      ),
    },
    right: {
      base: (
        <path d="M266.206 225.921c-4.244.744-8.551-.957-4.416-5.098 2.419-2.422 3.489-2.887 6.664-4.006 3.888-1.369 10.201-1.221 14.26-1.336 2.734-.079 10.482-2.13 15.459-1.205.965.179 1.632 1.026 2.59 1.249 1.514.351 3.015-.993 4.604-.463 1.543.514 2.716 2.279 3.595 3.55.103.149.954 1.178.909 1.357-.001.003-2.079.273-2.107.821-.06 1.194 1.887 2.233 2.119 3.491.064.345-1.514-.229-1.764.074-.201.242.763 2.071.434 2.601-.694 1.118-1.909.132-2.696.584-.786.452.586 2.283-.854 2.535-2.845.498-4.381-3.287-6.702-4.282-.31-.133-.967 1.085-1.234 1.277-.725.52-2.125.904-2.663.239-2.036-2.52-11.072-5.858-11.993-5.27-1.403.895.484 1.987-.813 2.247-.916.183-1.797-.458-2.737-.259-.965.204-.924.486-1.887.714-1.69.4-3.868.273-5.581.53-1.408.211-3.786.404-5.187.65Z" />
      ),
      dark: (
        <path d="M308.79 220.185c-.51.136-1.012.338-1.026.605-.06 1.194 1.887 2.233 2.119 3.491.064.345-1.514-.229-1.764.074-.201.242.763 2.071.434 2.601-.694 1.118-1.909.132-2.696.584-.786.452.586 2.283-.854 2.535-2.845.498-4.381-3.287-6.702-4.282-.31-.133-.967 1.085-1.234 1.277-.725.52-2.125.904-2.663.239-2.036-2.52-11.072-5.858-11.993-5.27-1.403.895.484 1.987-.813 2.247-.916.183-1.797-.458-2.737-.259-.965.204-.924.486-1.887.714-1.69.4-3.868.273-5.581.53-1.408.211-3.786.404-5.187.65-4.123.723-8.307-.862-4.748-4.751.381-.041.73-.057.875-.016.213.06-.503 2.74-.033 2.884.904.276 1.819-1.75 3.088-1.444.46.111.117.893.73 1 2.049.358 2.781-1.378 5.518-1.101.915.092.19.852.727 1.096.574.261 1.452-.099 2.062-.085.539.012.605.516 1.036.587 2.25.365 2.505-2.17 5.185-2.753.46-.099.541.714.941.526.683-.323.365-1.123 1.339-1.216 2.174-.209 5.429 2.6 5.772 2.549 1.158-.17-1.766-3.141-.6-3.453 1.657-.443 2.778 2.095 4.779 2.296.453.045.311-.604 1.008-.426.674.172 1.533.988 2.199.667 1.532-.738-.284-3.575 1.258-4.131 1.606-.58 3.352 3.427 5.408 3.478.861.022-.268-1.973.494-2.045.652-.062 2.678 1.66 2.876 1.478.36-.332-.293-2.425.281-2.321.844.154 1.622 1.141 2.389 1.445Z" />
      ),
    },
  },
};

const eyebrow_Gn5Ekfp4Dz = {
  id: "eyebrow_Gn5Ekfp4Dz",
  name: "Eyebrow 25",
  description:
    "Smooth, narrow eyebrows that arch then curve downwards, tapering slightly to a rounded outer corner",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M202.709 216.127c5.341-1.699 14.72-4.122 26.517-.52 6.64 2.027 11.433 7.299 7.93 9.441-7.425 4.543-20.94-6.8-28.87-6.295-10.249.652-13.661 11.512-16.242 8.756-1.895-2.023 3.568-9.125 10.665-11.382Z" />
      ),
      dark: (
        <path d="M231.55 216.477c5.357 2.39 8.684 6.689 5.606 8.571-5.99 3.665-15.942-3.008-23.729-5.46.668-.234 1.151-.388 1.363-.417 3.101-.429 4.951 1.712 7.063 1.307 1.142-.22.17-1.822 1.428-1.833 2.028-.018 8.092 2.206 9.064 2.041 3.549-.605-.079-3.373-.795-4.209Zm-32.316 6.134c-3.67 3.082-5.59 6.607-7.19 4.898-.598-.638-.463-1.783.225-3.122.439-.386.889-.627 1.224-.587.068.008-.218 1.224.369.987.098-.039 2.84-3.41 5.257-3.323.557.02.189.579.115 1.147Z" />
      ),
    },
    right: {
      base: (
        <path d="M297.291 216.127c-5.341-1.699-14.72-4.122-26.517-.52-6.64 2.027-11.433 7.299-7.93 9.441 7.425 4.543 20.94-6.8 28.87-6.295 10.249.652 13.661 11.512 16.242 8.756 1.895-2.023-3.568-9.125-10.665-11.382Z" />
      ),
      dark: (
        <path d="M268.45 216.477c-.716.836-4.344 3.604-.795 4.209.972.165 7.036-2.059 9.064-2.041 1.258.011.286 1.613 1.428 1.833 2.112.405 3.962-1.736 7.063-1.307.212.029.695.183 1.363.417-7.787 2.452-17.739 9.125-23.729 5.46-3.078-1.882.249-6.181 5.606-8.571Zm32.316 6.134c-.074-.568-.442-1.127.115-1.147 2.417-.087 5.159 3.284 5.257 3.323.587.237.301-.979.369-.987.335-.04.785.201 1.224.587.688 1.339.823 2.484.225 3.122-1.6 1.709-3.52-1.816-7.19-4.898Z" />
      ),
    },
  },
};

const eyebrow_F2AmaimmRC = {
  id: "eyebrow_F2AmaimmRC",
  name: "Eyebrow 26",
  description:
    "Smooth, almost straight eyebrows that angle upwards and have a feathered top",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M192.475 212.426c3.086.968 8.73-2.354 17.001-.133 6.749 1.813 13.918 4.975 20.436 9.485 1.935 1.339 7.256 2.534 7.861-1.044.272-1.605.144-3.912-1.513-4.756-.391-.199-.861.118-1.275.053-1.305-.204-3.384-4.742-5.833-5.019-1.04-.118-.931 2.901-2.118 2.39-.689-.296-1.929-4.836-6.576-5.648-1.065-.186-.558 2.17-1.483 1.933-1.754-.449-2.866-2.686-4.602-3.372-1.232-.486-.738 2.677-4.801.338-12.609-7.26-19.106 5.142-17.097 5.773Z" />
      ),
      dark: (
        <path d="M237.42 217.171c.538 1.059.538 2.473.353 3.563-.605 3.578-5.926 2.383-7.861 1.044-5.11-3.536-10.621-6.243-16.015-8.122-.18-.223-.265-.358-.238-.384 1.183-1.14 3.818 1.225 5.02 1.321.873.069-.296-1.537.58-1.518 2.151.047 3.6 2.894 5.238 2.677.379-.05.547-.538.907-.34.692.381 1.346 1.635 2.124 1.857.435.124.688-.785 1.117-.605.723.304 1.226 1.62 1.986 1.863.285.091.505-1.752 1.301-1.487 2.016.672 2.689 3.791 3.804 3.409 1.141-.391.27-3.372 1.272-3.325.14.006.278.022.412.047Z" />
      ),
      light: (
        <path d="M209.503 206.613c-.491.138-1.242-.113-1.601-.192-1.567-.348-3.847-1.169-5.349-.429-.513.253 2.346 1.554 1.482 2.115-.638.414-3.025-.403-3.769-.268-.53.097.202.884-.332 1.033-1.214.339-2.392-.013-3.683.839-1.984 1.308-3.332 1.918-4.136 2.035-.003-2.636 6.333-11.446 17.388-5.133Zm22.325 6.162c-.057.055-.117.11-.179.166-.405.358.235 1.526-.522 1.61-.814.091-1.726-.562-2.298-1.063-.228-.2-.508-.465-.822-.774.317-.745.53-1.772 1.145-1.702.956.108 1.857.866 2.676 1.763Zm-8.001-3.475c.436.713.61 1.39-.112 1.587-.465.128-1.701-1.394-2.332-1.577-.461-.134-.42.824-1.115.509a3.54 3.54 0 0 1-.763-.489c.232-.601.211-1.705.953-1.576a7.144 7.144 0 0 1 3.369 1.546Zm-7.282-1.33c-.032.132-.084.247-.188.293-.277.122-1.714-.59-3.038-1.363.344-.389.56-.78 1.054-.585.818.324 1.498.992 2.172 1.655Z" />
      ),
    },
    right: {
      base: (
        <path d="M307.525 212.426c-3.086.968-8.73-2.354-17.001-.133-6.749 1.813-13.918 4.975-20.436 9.485-1.935 1.339-7.256 2.534-7.861-1.044-.272-1.605-.144-3.912 1.513-4.756.391-.199.861.118 1.275.053 1.305-.204 3.384-4.742 5.833-5.019 1.04-.118.931 2.901 2.118 2.39.689-.296 1.929-4.836 6.576-5.648 1.065-.186.558 2.17 1.483 1.933 1.754-.449 2.866-2.686 4.602-3.372 1.232-.486.738 2.677 4.801.338 12.609-7.26 19.106 5.142 17.097 5.773Z" />
      ),
      dark: (
        <path d="M286.103 213.656c-5.394 1.879-10.905 4.586-16.015 8.122-1.935 1.339-7.256 2.534-7.861-1.044-.185-1.09-.185-2.504.353-3.563.134-.025.272-.041.412-.047 1.002-.047.131 2.934 1.272 3.325 1.115.382 1.788-2.737 3.804-3.409.796-.265 1.016 1.578 1.301 1.487.76-.243 1.263-1.559 1.986-1.863.429-.18.682.729 1.117.605.778-.222 1.432-1.476 2.124-1.857.36-.198.528.29.907.34 1.638.217 3.087-2.63 5.238-2.677.876-.019-.293 1.587.58 1.518 1.202-.096 3.837-2.461 5.02-1.321.027.026-.058.161-.238.384Z" />
      ),
      light: (
        <path d="M268.172 212.775c.819-.897 1.72-1.655 2.676-1.763.615-.07.828.957 1.145 1.702-.314.309-.594.574-.822.774-.572.501-1.484 1.154-2.298 1.063-.757-.084-.117-1.252-.522-1.61a7.449 7.449 0 0 1-.179-.166Zm8.001-3.475a7.144 7.144 0 0 1 3.369-1.546c.742-.129.721.975.953 1.576a3.54 3.54 0 0 1-.763.489c-.695.315-.654-.643-1.115-.509-.631.183-1.867 1.705-2.332 1.577-.722-.197-.548-.874-.112-1.587Zm7.282-1.33c.674-.663 1.354-1.331 2.172-1.655.494-.195.71.196 1.054.585-1.324.773-2.761 1.485-3.038 1.363-.104-.046-.156-.161-.188-.293Zm7.042-1.357c11.055-6.313 17.391 2.497 17.388 5.133-.804-.117-2.152-.727-4.136-2.035-1.291-.852-2.469-.5-3.683-.839-.534-.149.198-.936-.332-1.033-.744-.135-3.131.682-3.769.268-.864-.561 1.995-1.862 1.482-2.115-1.502-.74-3.782.081-5.349.429-.359.079-1.11.33-1.601.192Z" />
      ),
    },
  },
};

const eyebrow_ajC6U5YJ4C = {
  id: "eyebrow_ajC6U5YJ4C",
  name: "Eyebrow 27",
  description:
    "Thin eyebrows with a thicker, jagged inner corner and a smooth curve",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M234.377 224.385c2.681 2.783 3.216-5.198.548-8.809-1.132-1.532-3.717 2.256-4.083 2.187-1.086-.203-1.862-3.695-4.063-4.022-.297-.044-1.579 2.482-2.555 2.468-2.592-.036-3.238-1.817-9.54-1.77-9.539.071-21.788 10.382-21.35 10.718.235.18 1.745.613 4.106-.922 3.296-2.144 11.754-7.12 17.244-6.894 9.102.375 17.118 4.371 19.693 7.044Z" />
      ),
      dark: (
        <path d="M195.629 222.959c1.826.561 3.767-1.099 5.659-1.553.564-.135 1.04-.221 1.436-.263a67.853 67.853 0 0 0-5.284 3.092c-2.361 1.535-3.871 1.102-4.106.922-.123-.094.756-.977 2.295-2.198Zm39.72-6.728c2.176 3.829 1.557 10.778-.972 8.154-2.575-2.673-10.591-6.669-19.693-7.044-.831-.034-1.729.051-2.667.227a2.58 2.58 0 0 1-.206-.143c.228-.227 3.804-2.364 13.669.597 2.464.74-.413-2.556 1.71-1.977 1.165.318 3.783 3.746 4.448 3.855.982.16.144-1.969.828-2.026.656-.055 1.695 2.545 1.923 2.523.27-.027.74-2.961.684-4.109-.015-.316.093-.295.276-.057Z" />
      ),
    },
    right: {
      base: (
        <path d="M265.623 224.385c-2.681 2.783-3.216-5.198-.548-8.809 1.132-1.532 3.717 2.256 4.083 2.187 1.086-.203 1.862-3.695 4.063-4.022.297-.044 1.579 2.482 2.555 2.468 2.592-.036 3.238-1.817 9.54-1.77 9.539.071 21.788 10.382 21.35 10.718-.235.18-1.745.613-4.106-.922-3.296-2.144-11.754-7.12-17.244-6.894-9.102.375-17.118 4.371-19.693 7.044Z" />
      ),
      dark: (
        <path d="M287.983 217.568c-.938-.176-1.836-.261-2.667-.227-9.102.375-17.118 4.371-19.693 7.044-2.529 2.624-3.148-4.325-.972-8.154.183-.238.291-.259.276.057-.056 1.148.414 4.082.684 4.109.228.022 1.267-2.578 1.923-2.523.684.057-.154 2.186.828 2.026.665-.109 3.283-3.537 4.448-3.855 2.123-.579-.754 2.717 1.71 1.977 9.865-2.961 13.441-.824 13.669-.597a2.58 2.58 0 0 1-.206.143Zm9.293 3.575c.396.042.872.128 1.436.263 1.892.454 3.833 2.114 5.659 1.553 1.539 1.221 2.418 2.104 2.295 2.198-.235.18-1.745.613-4.106-.922a67.853 67.853 0 0 0-5.284-3.092Z" />
      ),
    },
  },
};

export const eyebrowsPack3 = {
  eyebrow_aWAA8kBq4x,
  eyebrow_kmi4k6hrMZ,
  eyebrow_ec4K4U2V5r,
  eyebrow_uczmUn6gFF,
  eyebrow_72GWCT3UZc,
  eyebrow_7dAGXNBJkt,
  eyebrow_S3ZhmL7NMv,
  eyebrow_E2eEhNaPeM,
  eyebrow_Gn5Ekfp4Dz,
  eyebrow_F2AmaimmRC,
  eyebrow_ajC6U5YJ4C,
};

// Components
import { allColours } from "character-creator/colours/colourSchemes";
import { facePaintShapeList } from "character-creator/components/head/markings/facePaint/FacePaintShapeList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const facePaintPath = "face-paint.";

const FacePaintPageConfig = {
  id: "facePaintPage",
  name: "Face Paint",
  description: "",
  controls: [
    {
      id: "facePaintShapeGroup",
      name: "Shape",
      description: "",
      dataAttribute: [facePaintPath + "shape"],
      previewType: "preview",
      options: facePaintShapeList,
      colourControls: [
        {
          id: "FacePaintColour",
          name: "Colour",
          description: "",
          dataAttribute: [facePaintPath + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "facePaintTransformX",
          name: "Horizontal",
          description: "",
          dataAttribute: [facePaintPath + "transform.x"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "facePaintTransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [facePaintPath + "transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "facePaintTransformScale",
          name: "Size",
          description: "",
          dataAttribute: [facePaintPath + "transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
        {
          id: "facePaintTransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [facePaintPath + "transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

const FacePaintPage = {
  id: "facePaintPage",
  name: "Face Paint",
  description: "",
  content: <SubPage page={FacePaintPageConfig} />,
};

export default FacePaintPage;

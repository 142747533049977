import React from "react";
import { connect } from "react-redux";
import { HeadShapeListArray } from "character-creator/components/head/shape/HeadShapeList";
import CharacterFace from "character-creator/components/head/face/CharacterFace";
import ItemComponent from "character-creator/components/ItemComponent";
import Ears from "character-creator/components/head/ears/CharacterEars";
import HeadScar from "./markings/scars/HeadScar";
import Cheeks from "./markings/cheeks/Cheeks";
import Contouring from "./markings/contouring/Contouring";
import Tattoo from "./markings/tattoos/Tattoo";
import HeadMarkings from "./markings/HeadMarkings";
import { getGlobalStyle } from "../StyleOptions";
import { outlineOnly } from "character-creator/colours/colourSchemes";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const useHeadOutline = getGlobalStyle(state).outline;

  const headConfig = getCharacterConfigValue(state, "head");
  const headObject =
    ownProps.preview && ownProps.head
      ? ownProps.head
      : filterListById(HeadShapeListArray, headConfig.shape);
  const colour = headConfig.colour
    ? headConfig.colour
    : getCharacterConfigValue(state, "skintone");

  return {
    colour: colour,
    Head: headObject,
    preview: ownProps.preview,
    useHeadOutline,
  };
}

const CharacterHead = (props) => {
  const { Head, colour, preview, previewId, useHeadOutline } = props;

  return (
    <g id={previewId ? `g-head~${previewId}` : "g-head"}>
      {!preview && <Ears />}
      <ItemComponent
        id={previewId ? `head~${previewId}` : "head"}
        component={Head}
        colour={colour}
      />
      {/* Base */}
      {!preview && (
        <>
          <HeadMarkings index={"0"} />
          <HeadMarkings index={"1"} />
          <HeadMarkings index={"2"} />
        </>
      )}

      {!preview && <Contouring />}
      {!preview && <Cheeks />}

      {!preview && <Tattoo />}

      {!preview && (
        <>
          <HeadScar index={"0"} />
          <HeadScar index={"1"} />
          <HeadScar index={"2"} />
        </>
      )}

      {!preview && <CharacterFace />}

      {useHeadOutline && (
        <ItemComponent
          id={previewId ? `headOutline~${previewId}` : "headOutline"}
          component={Head}
          // linework={true}
          colour={{ ...outlineOnly, outline: colour.outline }}
        />
      )}
    </g>
  );
};

export default connect(mapStateToProps)(CharacterHead);

import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import { CheekShapeList } from "./CheekShapeList";
import {
  buildTransform,
  filterListById,
} from "character-creator/menu/menu-helper";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";

function mapStateToProps(state, ownProps) {
  const cheeksConfig = getCharacterConfigValue(state, "cheeks");

  let cheeksObjectLeft = null;
  if (ownProps.preview && ownProps.cheeks.shape?.left) {
    cheeksObjectLeft = ownProps.cheeks.shape.left;
  } else if (
    cheeksConfig &&
    cheeksConfig.left?.shape &&
    cheeksConfig.left?.shape !== "0"
  ) {
    cheeksObjectLeft = filterListById(CheekShapeList, cheeksConfig.left.shape)
      .shape.left;
  }

  const coloursLeft =
    cheeksConfig && cheeksConfig.left?.colour
      ? cheeksConfig.left.colour
      : { base: getCharacterConfigValue(state, "skintone").light };

  let cheeksObjectRight = null;
  if (ownProps.preview && ownProps.cheeks.shape?.right) {
    cheeksObjectRight = ownProps.cheeks.shape.right;
  } else if (
    cheeksConfig &&
    cheeksConfig.right?.shape &&
    cheeksConfig.right?.shape !== "0"
  ) {
    cheeksObjectRight = filterListById(CheekShapeList, cheeksConfig.right.shape)
      .shape.right;
  }

  const coloursRight =
    cheeksConfig && cheeksConfig.right?.colour
      ? cheeksConfig.right.colour
      : { base: getCharacterConfigValue(state, "skintone").light };

  const leftTransform =
    cheeksConfig && cheeksConfig.left?.transform
      ? buildTransform(cheeksConfig.left.transform)
      : null;
  const rightTransform =
    cheeksConfig && cheeksConfig.right?.tranform
      ? buildTransform(cheeksConfig.right.transform)
      : null;

  return {
    cheeks: {
      left: {
        shape: cheeksObjectLeft,
        colour: coloursLeft,
        transform: leftTransform,
      },
      right: {
        shape: cheeksObjectRight,
        colour: coloursRight,
        transform: rightTransform,
      },
    },
  };
}

const Cheeks = (props) => {
  const { cheeks, previewId } = props;

  if (cheeks.left.shape || cheeks.right.shape) {
    return (
      <g
        id={previewId ? `g-head-cheeks~${previewId}` : "g-head-cheeks"}
        mask="url(#head_mask)"
      >
        {cheeks.left.shape && (
          <ItemComponent
            id={previewId ? `cheeks-left~${previewId}` : "cheeks-left"}
            component={cheeks.left}
            colour={cheeks.left.colour}
            preview={previewId ? true : false}
            transform={cheeks.left.transform}
          />
        )}
        {cheeks.right.shape && (
          <ItemComponent
            id={previewId ? `cheeks-right~${previewId}` : "cheeks-right"}
            component={cheeks.right}
            colour={cheeks.right.colour}
            preview={previewId ? true : false}
            transform={cheeks.left.transform}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Cheeks);
function filterByListId(
  cheekShapeList: {
    cheek_shape_3123j: {
      id: string;
      name: string;
      description: string;
      tags: never[];
      preview: { viewbox: string; base: (props: any) => React.JSX.Element };
      stroke: boolean;
      shape: {
        left: { base: React.JSX.Element };
        right: { base: React.JSX.Element };
      };
    };
    cheek_shape_fa942: {
      id: string;
      name: string;
      description: string;
      tags: never[];
      preview: { viewbox: string; base: (props: any) => React.JSX.Element };
      stroke: boolean;
      shape: {
        left: { base: React.JSX.Element };
        right: { base: React.JSX.Element };
      };
    };
    cheek_shape_fsd84: {
      id: string;
      name: string;
      description: string;
      tags: never[];
      preview: { viewbox: string; base: (props: any) => React.JSX.Element };
      stroke: boolean;
      shape: {
        left: { base: React.JSX.Element };
        right: { base: React.JSX.Element };
      };
    };
    cheek_shape_a92mn: {
      id: string;
      name: string;
      description: string;
      tags: never[];
      preview: { viewbox: string; base: (props: any) => React.JSX.Element };
      stroke: boolean;
      shape: {
        left: { base: React.JSX.Element };
        right: { base: React.JSX.Element };
      };
    };
    cheek_shape_00nak: {
      id: string;
      name: string;
      description: string;
      tags: never[];
      preview: { viewbox: string; base: (props: any) => React.JSX.Element };
      stroke: boolean;
      shape: {
        left: { base: React.JSX.Element };
        right: { base: React.JSX.Element };
      };
    };
    cheek_shape_dao21: {
      id: string;
      name: string;
      description: string;
      tags: never[];
      preview: { viewbox: string; base: (props: any) => React.JSX.Element };
      stroke: boolean;
      shape: {
        left: { base: React.JSX.Element };
        right: { base: React.JSX.Element };
      };
    };
    cheek_shape_da94n: {
      id: string;
      name: string;
      description: string;
      tags: never[];
      preview: { viewbox: string; base: (props: any) => React.JSX.Element };
      stroke: boolean;
      shape: {
        left: { base: React.JSX.Element };
        right: { base: React.JSX.Element };
      };
    };
    cheek_shape_fakei: {
      id: string;
      name: string;
      description: string;
      tags: never[];
      preview: { viewbox: string; base: (props: any) => React.JSX.Element };
      stroke: boolean;
      shape: {
        left: { base: React.JSX.Element };
        right: { base: React.JSX.Element };
      };
    };
    "0": {
      id: string;
      name: string;
      description: string;
      preview: { viewbox: string; base: (props: any) => React.JSX.Element };
      shape: { base: React.JSX.Element };
    };
  },
  shape: any
) {
  throw new Error("Function not implemented.");
}

import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import {
  buildTransform,
  filterListById,
} from "character-creator/menu/menu-helper";
import { BodyMarkingsListArray } from "./BodyMarkingsList";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";

function mapStateToProps(state, ownProps) {
  const itemConfig = getCharacterConfigValue(state, "bodyMarkings");

  const index = ownProps.index;

  let itemObject = {};

  if (!ownProps.preview && itemConfig && itemConfig[index]) {
    let thisItemObject = filterListById(
      BodyMarkingsListArray,
      itemConfig[index].shape
    );

    let thisItemShape = thisItemObject.shape;

    if (thisItemShape.left) {
      let newShape = {
        base: (
          <>
            {thisItemShape.left.base}
            {thisItemShape.right.base}
          </>
        ),
        light: (
          <>
            {thisItemShape.left.light}
            {thisItemShape.right.light}
          </>
        ),
        dark: (
          <>
            {thisItemShape.left.dark}
            {thisItemShape.right.dark}
          </>
        ),
      };

      thisItemShape = newShape;
    }

    const thisExtraTransform =
      itemConfig[index].transform &&
      buildTransform(itemConfig[index].transform);

    const thisItemColours = itemConfig[index].colour
      ? itemConfig[index].colour
      : { base: getCharacterConfigValue(state, "skintone").light };

    // Assign to Extras object array
    itemObject = {
      shape: { shape: thisItemShape },
      colour: thisItemColours,
      transform: thisExtraTransform,
    };
    // });
  } else if (ownProps.preview && ownProps.marking) {
    // const thisitemConfig = ownProps.extra;
    let thisItemObject = ownProps.marking;

    let thisItemShape = thisItemObject.shape;

    if (thisItemShape.left) {
      let newShape = {
        base: (
          <>
            {thisItemShape.left.base}
            {thisItemShape.right.base}
          </>
        ),
        light: (
          <>
            {thisItemShape.left.light}
            {thisItemShape.right.light}
          </>
        ),
        dark: (
          <>
            {thisItemShape.left.dark}
            {thisItemShape.right.dark}
          </>
        ),
      };

      thisItemShape = newShape;
    }

    const thisItemColours =
      itemConfig && itemConfig["0"].colour
        ? itemConfig["0"].colour
        : { base: getCharacterConfigValue(state, "skintone").light };

    // Assign to Extras object array
    itemObject = {
      shape: { shape: thisItemShape },
      colour: thisItemColours,
    };
  }

  return {
    itemObject,
    previewId: ownProps.previewId,
    index,
  };
}

const BodyMarkings = (props) => {
  const { itemObject, previewId, index } = props;

  if (itemObject.shape) {
    return (
      <g
        id={
          previewId
            ? `g-body-markings~${previewId}`
            : `g-body-markings-${index}`
        }
        mask="url(#body_mask)"
      >
        <ItemComponent
          id={
            previewId
              ? `body-marking${index}~${previewId}`
              : `body-marking${index}`
          }
          component={itemObject.shape}
          colour={itemObject.colour}
          preview={previewId ? true : false}
          transform={itemObject.transform ?? null}
          strokeOverride={false}
        />
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(BodyMarkings);

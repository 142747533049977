import AllBlues from "./collections/Blues";
import AllBrowns from "./collections/Browns";
import AllGreens from "./collections/Greens";
import AllGreys from "./collections/Greys";
import AllMetals from "./collections/Metals";
import AllPinks from "./collections/Pinks";
import AllPurples from "./collections/Purples";
import AllReds from "./collections/Reds";

export const colourSchemesList = Object.values({
  0: {
    id: 0,
    name: "Colouring",
    darker: "#FFF",
    dark: "#FFF",
    base: "#FFF",
    light: "#FFF",
    lighter: "#FFF",
    outline: "#000",
  },
});

export const outlineOnly = {
  darker: "hsla(0,0,0,0)",
  dark: "hsla(0,0,0,0)",
  base: "hsla(0,0,0,0)",
  light: "hsla(0,0,0,0)",
  lighter: "hsla(0,0,0,0)",
};

export const allColours = [
  ...AllBrowns,
  ...AllBlues,
  ...AllGreens,
  ...AllGreys,
  ...AllMetals,
  ...AllPinks,
  ...AllPurples,
  ...AllReds,
];

export default colourSchemesList;

import AllSkintones from "character-creator/colours/skin";
import { EarShapeListArray } from "character-creator/components/head/ears/EarShapeList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";
// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const earsPath = "ears.";
const earsPathLeft = earsPath + "left.";
const earsPathRight = earsPath + "right.";

const earsPageConfig = {
  id: "earsPage",
  name: "Ears",
  description: "",
  symmetryAttribute: [earsPath + "symmetry"],
  controls: {
    symmetrical: [
      {
        id: "earShape",
        name: "Shape and texture",
        description: "",
        dataAttribute: [earsPathLeft + "shape", earsPathRight + "shape"],
        variationAttribute: "",
        previewType: "preview",
        options: EarShapeListArray,
        colourControls: [
          {
            id: "earColour",
            name: "Colour",
            description: "",
            dataAttribute: [earsPathLeft + "colour", earsPathRight + "colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: AllSkintones,
          },
        ],
      },
    ],
    asymmetrical: [
      {
        id: "earLeftShape",
        name: "Left shape",
        description: "",
        dataAttribute: [earsPathLeft + "shape"],
        variationAttribute: "",
        previewType: "preview",
        options: EarShapeListArray,
        colourControls: [
          {
            id: "earLeftColour",
            name: "Colour",
            description: "",
            dataAttribute: [earsPathLeft + "colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: AllSkintones,
          },
        ],
      },
      {
        id: "earRightShape",
        name: "Right shape",
        description: "",
        dataAttribute: [earsPathRight + "shape"],
        variationAttribute: "",
        previewType: "preview",
        options: EarShapeListArray,
        colourControls: [
          {
            id: "earRightColour",
            name: "Colour",
            description: "",
            dataAttribute: [earsPathRight + "colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: AllSkintones,
          },
        ],
      },
    ],
  },
};

const EarsPage = {
  id: "earsPage",
  name: "Ears",
  description: "Get ahead of the game.",
  content: <SubPage page={earsPageConfig} />,
};

export default EarsPage;

import React from "react";
import { connect } from "react-redux";
import {
  CentreHornsListArray,
  FrontSideHornsListArray,
} from "./FrontHornsList";
import ItemComponent from "character-creator/components/ItemComponent";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  // Centre
  const hornConfigCentre = getCharacterConfigValue(state, "horns.centre");

  let hornObjectCentre = null;
  if (ownProps.preview && ownProps.horns.shape.centre) {
    hornObjectCentre = ownProps.horns.shape.centre;
  } else if (hornConfigCentre && hornConfigCentre.shape !== "0") {
    hornObjectCentre = filterListById(
      CentreHornsListArray,
      hornConfigCentre.shape
    ).shape.centre;
  }

  const coloursCentre =
    hornConfigCentre && hornConfigCentre.colour
      ? hornConfigCentre.colour
      : getCharacterConfigValue(state, "skintone");

  // Left
  const hornConfigLeft = getCharacterConfigValue(state, "horns.front.left");

  let hornObjectLeft = null;
  if (ownProps.preview && ownProps.horns.shape.left) {
    hornObjectLeft = ownProps.horns.shape.left;
  } else if (hornConfigLeft && hornConfigLeft.shape !== "0") {
    hornObjectLeft = filterListById(
      FrontSideHornsListArray,
      hornConfigLeft.shape
    ).shape.left;
  }

  const coloursLeft =
    hornConfigLeft && hornConfigLeft.colour
      ? hornConfigLeft.colour
      : getCharacterConfigValue(state, "skintone");

  // Right
  const hornConfigRight = getCharacterConfigValue(state, "horns.front.right");

  let hornObjectRight = null;
  if (ownProps.preview && ownProps.horns.shape.right) {
    hornObjectRight = ownProps.horns.shape.right;
  } else if (hornConfigRight && hornConfigRight.shape !== "0") {
    hornObjectRight = filterListById(
      FrontSideHornsListArray,
      hornConfigRight.shape
    ).shape.right;
  }

  const coloursRight =
    hornConfigRight && hornConfigRight.colour
      ? hornConfigRight.colour
      : getCharacterConfigValue(state, "skintone");

  return {
    left: {
      shape: hornObjectLeft,
      colour: coloursLeft,
    },
    right: {
      shape: hornObjectRight,
      colour: coloursRight,
    },
    centre: {
      shape: hornObjectCentre,
      colour: coloursCentre,
    },
  };
}

const FrontHorns = (props) => {
  const { left, right, centre, previewId } = props;

  if (centre.shape || left.shape || right.shape) {
    return (
      <g id={previewId ? `g-horns-front~${previewId}` : "g-horns-front"}>
        {left.shape && (
          <ItemComponent
            id={
              previewId ? `horns-front-left~${previewId}` : "horns-front-left"
            }
            component={left}
            colour={left.colour}
            preview={previewId ? true : false}
          />
        )}
        {right.shape && (
          <ItemComponent
            id={
              previewId ? `horns-front-right~${previewId}` : "horns-front-right"
            }
            component={right}
            colour={right.colour}
            preview={previewId ? true : false}
          />
        )}
        {centre.shape && (
          <ItemComponent
            id={
              previewId
                ? `horns-front-centre~${previewId}`
                : "horns-front-centre"
            }
            component={centre}
            colour={centre.colour}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(FrontHorns);

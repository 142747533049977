import React from "react";
import { PreviewBase, previewViewbox } from "../Preview";

const body_centre_jCTwu = {
  id: "body_centre_jCTwu",
  name: "Body centre 1",
  description:
    "A wide underbelly that tapers from the armpits and covers the neck",
  tags: ["neck"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  useClip: false,
  shape: {
    base: (
      <path d="M392.19 500c-1.76-28.16-8.69-40.74-20.82-57.14-12.83-17.36-29.22-31.78-42.85-48.52-13.63-16.74-24.77-37.03-24.1-58.61.14-4.59.77-9.46-1.33-13.55-3.54-6.9-12.71-8.13-20.46-8.49-6.97-.33-13.93-.65-20.9-.98-3.65-.87-7.62-.74-11.42-.56-.1 0-.21.01-.31.01s-.21-.01-.31-.01c-3.8-.18-7.77-.31-11.42.56-6.97.33-13.93.65-20.9.98-7.75.36-16.92 1.59-20.46 8.49-2.1 4.09-1.47 8.96-1.33 13.55.67 21.58-10.46 41.87-24.1 58.61-13.63 16.74-30.02 31.16-42.85 48.52-12.12 16.4-19.06 28.98-20.82 57.14h284.38Z" />
    ),
  },
};

const body_centre_asfk3 = {
  id: "body_centre_asfk3",
  name: "Body centre 2",
  description:
    "A narrow underbelly that tapers from the chest to the neck, not quite covering either",
  tags: ["neck"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  useClip: false,
  shape: {
    base: (
      <path d="M353.26 500c-1.28-28.16-6.31-40.74-15.12-57.14-9.32-17.36-21.22-31.78-31.12-48.52-9.9-16.74-17.99-37.03-17.5-58.61.1-4.59.56-9.46-.97-13.55-2.57-6.9-9.23-8.13-14.86-8.49-5.06-.33-10.12-.65-15.18-.98-2.65-.87-5.54-.74-8.3-.56-.08 0-.15.01-.23.01-.08 0-.15-.01-.23-.01-2.76-.18-5.65-.31-8.3.56-5.06.33-10.12.65-15.18.98-5.63.36-12.29 1.59-14.86 8.49-1.52 4.09-1.07 8.96-.97 13.55.49 21.58-7.6 41.87-17.5 58.61-9.9 16.74-21.8 31.16-31.12 48.52-8.8 16.4-13.84 28.98-15.12 57.14h206.56Z" />
    ),
  },
};

const body_centre_03glf = {
  id: "body_centre_03glf",
  name: "Body centre 3",
  description:
    "A wide, curved underbelly that covers the whole chest up to around the clavicle",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  useClip: false,
  shape: {
    base: (
      <path d="M392.41 500c-1.5-59.48-64.68-107.34-142.41-107.34-77.73 0-140.91 47.86-142.41 107.34h284.82Z" />
    ),
  },
};

const body_centre_731kj = {
  id: "body_centre_731kj",
  name: "Body centre 4",
  description:
    "A wide, curved underbelly that covers the whole chest up to midway up the sternum",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  useClip: false,
  shape: {
    base: (
      <path d="M392.41 500c-1.5-39.98-64.68-72.16-142.41-72.16-77.73 0-140.91 32.18-142.41 72.16h284.82Z" />
    ),
  },
};

export const BodyCentresPack1 = {
  body_centre_jCTwu,
  body_centre_asfk3,
  body_centre_03glf,
  body_centre_731kj,
};

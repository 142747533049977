import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { EarShapePack1 } from "./packs/EarsSmoothPack1";
import { EarShapePack2 } from "./packs/EarsFuzzyPack1";
import { EarShapePack3 } from "./packs/EarsBumpyPack1";
import { EarShapePack4 } from "./packs/EarsFurryPack1";

const EarShapeList = {
  "0": BlankListOption,
  ...EarShapePack1,
  ...EarShapePack2,
  ...EarShapePack3,
  ...EarShapePack4,
};

export const EarShapeListArray = Object.values(EarShapeList);

import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const iris_inner_ARACcZ8tpr = {
  id: "iris_inner_ARACcZ8tpr",
  name: "iris_inner_ARACcZ8tpr",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <>
          <ellipse cx="212.25" cy="245.85" rx="7" ry="15.75" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <ellipse cx="287.75" cy="245.85" rx="7" ry="15.75" />
        </>
      ),
    },
  },
};

const iris_inner_7LfyGmUUvh = {
  id: "iris_inner_7LfyGmUUvh",
  name: "iris_inner_7LfyGmUUvh",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <>
          <circle id="left5" cx="212.25" cy="246.78" r="6.62" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <circle cx="287.75" cy="246.78" r="6.62" />
        </>
      ),
    },
  },
};

const iris_inner_9yYwYEFp9d = {
  id: "iris_inner_9yYwYEFp9d",
  name: "iris_inner_9yYwYEFp9d",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M212.25 240.16c3.654 0 6.62 2.966 6.62 6.62s-2.966 6.62-6.62 6.62-6.62-2.966-6.62-6.62 2.966-6.62 6.62-6.62Zm0 1.655c2.74 0 4.965 2.225 4.965 4.965 0 2.74-2.225 4.965-4.965 4.965-2.74 0-4.965-2.225-4.965-4.965 0-2.74 2.225-4.965 4.965-4.965Z" />
      ),
    },
    right: {
      base: (
        <path d="M287.75 240.16c3.654 0 6.62 2.966 6.62 6.62s-2.966 6.62-6.62 6.62-6.62-2.966-6.62-6.62 2.966-6.62 6.62-6.62Zm0 1.655c2.74 0 4.965 2.225 4.965 4.965 0 2.74-2.225 4.965-4.965 4.965-2.74 0-4.965-2.225-4.965-4.965 0-2.74 2.225-4.965 4.965-4.965Z" />
      ),
    },
  },
};

const iris_inner_bgygKfiJpL = {
  id: "iris_inner_bgygKfiJpL",
  name: "iris_inner_bgygKfiJpL",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M213.344 240.251c-.724-.121-1.464-.121-2.188 0l-.225 1.575c-.625.166-1.212.449-1.731.834l-1.372-.806c-.547.49-1.008 1.069-1.365 1.711l1.091 1.158c-.259.592-.404 1.227-.427 1.873l-1.486.57c.043.733.208 1.454.487 2.134l1.586-.131c.301.571.707 1.081 1.198 1.502l-.481 1.517c.6.423 1.267.744 1.972.949l.886-1.321c.635.121 1.287.121 1.922 0l.886 1.321c.705-.205 1.372-.526 1.972-.949l-.481-1.517c.491-.421.897-.931 1.198-1.502l1.586.131c.279-.68.444-1.401.487-2.134l-1.486-.57c-.023-.646-.168-1.281-.427-1.873l1.091-1.158c-.357-.642-.818-1.221-1.365-1.711l-1.372.806c-.519-.385-1.106-.668-1.731-.834l-.225-1.575Z" />
      ),
    },
    right: {
      base: (
        <path d="M288.844 240.251c-.724-.121-1.464-.121-2.188 0l-.225 1.575c-.625.166-1.212.449-1.731.834l-1.372-.806c-.547.49-1.008 1.069-1.365 1.711l1.091 1.158c-.259.592-.404 1.227-.427 1.873l-1.486.57c.043.733.208 1.454.487 2.134l1.586-.131c.301.571.707 1.081 1.198 1.502l-.481 1.517c.6.423 1.267.744 1.972.949l.886-1.321c.635.121 1.287.121 1.922 0l.886 1.321c.705-.205 1.372-.526 1.972-.949l-.481-1.517c.491-.421.897-.931 1.198-1.502l1.586.131c.279-.68.444-1.401.487-2.134l-1.486-.57c-.023-.646-.168-1.281-.427-1.873l1.091-1.158c-.357-.642-.818-1.221-1.365-1.711l-1.372.806c-.519-.385-1.106-.668-1.731-.834l-.225-1.575Z" />
      ),
    },
  },
};

const iris_inner_C6kmTqWbci = {
  id: "iris_inner_C6kmTqWbci",
  name: "iris_inner_C6kmTqWbci",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M211.126 242.585c0-1.338.504-2.425 1.124-2.425.62 0 1.124 1.087 1.124 2.425.669-1.159 1.649-1.848 2.186-1.538.537.31.43 1.503-.239 2.662 1.159-.669 2.352-.776 2.662-.239.31.537-.379 1.517-1.538 2.186 1.338 0 2.425.504 2.425 1.124 0 .62-1.087 1.124-2.425 1.124 1.159.669 1.848 1.649 1.538 2.186-.31.537-1.503.43-2.662-.239.669 1.159.776 2.352.239 2.662-.537.31-1.517-.379-2.186-1.538 0 1.338-.504 2.425-1.124 2.425-.62 0-1.124-1.087-1.124-2.425-.669 1.159-1.649 1.848-2.186 1.538-.537-.31-.43-1.503.239-2.662-1.159.669-2.352.776-2.662.239-.31-.537.379-1.517 1.538-2.186-1.338 0-2.425-.504-2.425-1.124 0-.62 1.087-1.124 2.425-1.124-1.159-.669-1.848-1.649-1.538-2.186.31-.537 1.503-.43 2.662.239-.669-1.159-.776-2.352-.239-2.662.537-.31 1.517.379 2.186 1.538Z" />
      ),
    },
    right: {
      base: (
        <path d="M286.626 242.585c0-1.338.504-2.425 1.124-2.425.62 0 1.124 1.087 1.124 2.425.669-1.159 1.649-1.848 2.186-1.538.537.31.43 1.503-.239 2.662 1.159-.669 2.352-.776 2.662-.239.31.537-.379 1.517-1.538 2.186 1.338 0 2.425.504 2.425 1.124 0 .62-1.087 1.124-2.425 1.124 1.159.669 1.848 1.649 1.538 2.186-.31.537-1.503.43-2.662-.239.669 1.159.776 2.352.239 2.662-.537.31-1.517-.379-2.186-1.538 0 1.338-.504 2.425-1.124 2.425-.62 0-1.124-1.087-1.124-2.425-.669 1.159-1.649 1.848-2.186 1.538-.537-.31-.43-1.503.239-2.662-1.159.669-2.352.776-2.662.239-.31-.537.379-1.517 1.538-2.186-1.338 0-2.425-.504-2.425-1.124 0-.62 1.087-1.124 2.425-1.124-1.159-.669-1.848-1.649-1.538-2.186.31-.537 1.503-.43 2.662.239-.669-1.159-.776-2.352-.239-2.662.537-.31 1.517.379 2.186 1.538Z" />
      ),
    },
  },
};

const iris_inner_CH4GCCu8pA = {
  id: "iris_inner_CH4GCCu8pA",
  name: "iris_inner_CH4GCCu8pA",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <>
          <path d="M212.25,240.16L218.87,246.78L212.25,253.4L205.63,246.78L212.25,240.16Z" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <path d="M287.75,240.16L294.37,246.78L287.75,253.4L281.13,246.78L287.75,240.16Z" />
        </>
      ),
    },
  },
};

const iris_inner_FcFf7Q7Lvr = {
  id: "iris_inner_FcFf7Q7Lvr",
  name: "iris_inner_FcFf7Q7Lvr",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="m212.25 240.16 2.222 3.562 4.074 1.012-2.701 3.214.296 4.188-3.891-1.576-3.891 1.576.296-4.188-2.701-3.214 4.074-1.012 2.222-3.562Z" />
      ),
    },
    right: {
      base: (
        <path d="m287.75 240.16 2.222 3.562 4.074 1.012-2.701 3.214.296 4.188-3.891-1.576-3.891 1.576.296-4.188-2.701-3.214 4.074-1.012 2.222-3.562Z" />
      ),
    },
  },
};

const iris_inner_czY8SaKZhh = {
  id: "iris_inner_czY8SaKZhh",
  name: "iris_inner_czY8SaKZhh",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M212.25 242.808c1.394-2.648 4.181-2.648 5.575-1.324 1.393 1.324 1.393 3.972 0 6.62-.976 1.986-3.484 3.972-5.575 5.296-2.091-1.324-4.599-3.31-5.575-5.296-1.393-2.648-1.393-5.296 0-6.62 1.394-1.324 4.181-1.324 5.575 1.324Z" />
      ),
    },
    right: {
      base: (
        <path d="M287.75 242.808c1.394-2.648 4.181-2.648 5.575-1.324 1.393 1.324 1.393 3.972 0 6.62-.976 1.986-3.484 3.972-5.575 5.296-2.091-1.324-4.599-3.31-5.575-5.296-1.393-2.648-1.393-5.296 0-6.62 1.394-1.324 4.181-1.324 5.575 1.324Z" />
      ),
    },
  },
};

export const IrisInnerPack1 = {
  iris_inner_ARACcZ8tpr,
  iris_inner_7LfyGmUUvh,
  iris_inner_9yYwYEFp9d,
  iris_inner_bgygKfiJpL,
  iris_inner_C6kmTqWbci,
  iris_inner_CH4GCCu8pA,
  iris_inner_FcFf7Q7Lvr,
  iris_inner_czY8SaKZhh,
};

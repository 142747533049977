import { allColours } from "character-creator/colours/colourSchemes";

import { HeadMarkingsListArray } from "character-creator/components/head/markings/HeadMarkingsList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const markingsPath = "headMarkings.";

const headMarkingsPageConfig = {
  id: "headMarkingsPage",
  name: "Markings",
  description: "",
  controls: [
    {
      id: "headMarkingShape1",
      name: "Marking 1",
      description: "",
      dataAttribute: [markingsPath + "0.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: HeadMarkingsListArray,
      colourControls: [
        {
          id: "headMarkingColour1",
          name: "Colour",
          description: "",
          dataAttribute: [markingsPath + "0.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "headMarkingTransformO1",
          name: "Opacity",
          description: "",
          dataAttribute: [markingsPath + "0.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "headMarkingShape2",
      name: "Marking 2",
      description: "",
      dataAttribute: [markingsPath + "1.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: HeadMarkingsListArray,
      colourControls: [
        {
          id: "headMarkingColour2",
          name: "Colour",
          description: "",
          dataAttribute: [markingsPath + "1.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "headMarkingTransformO2",
          name: "Opacity",
          description: "",
          dataAttribute: [markingsPath + "1.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "headMarkingShape3",
      name: "Marking 3",
      description: "",
      dataAttribute: [markingsPath + "2.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: HeadMarkingsListArray,
      colourControls: [
        {
          id: "headMarkingColour3",
          name: "Colour",
          description: "",
          dataAttribute: [markingsPath + "2.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "headMarkingTransformO3",
          name: "Opacity",
          description: "",
          dataAttribute: [markingsPath + "2.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

const HeadMarkingsPage = {
  id: "headMarkingsPage",
  name: "Markings",
  description: "",
  content: <SubPage page={headMarkingsPageConfig} />,
};

export default HeadMarkingsPage;

import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { hairSidePack1Right } from "./packs/HairSideRightPack1";
import { hairSidePack2Right } from "./packs/HairSideRightPack2";
import { hairSidePack3Right } from "./packs/HairSideRightPack3";

export const hairSideListRight = Object.values({
  "0": BlankListOption,
  ...hairSidePack1Right,
  ...hairSidePack2Right,
  ...hairSidePack3Right,
});

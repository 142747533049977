import React from "react";

import CharacterBody from "character-creator/components/body/CharacterBody";
import CharacterHead from "character-creator/components/head/CharacterHead";
import BodyExtras from "./BodyExtras";
import CharacterHeadShadow from "character-creator/components/head/CharacterHeadShadow";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterBody preview={true} previewId={previewId} />
      <BodyExtras extra={component} preview={true} previewId={previewId} />
      <CharacterHeadShadow previewId={previewId} />
      <CharacterHead preview={true} previewId={previewId} />
    </>
  );
};

export const previewViewbox = "30 300 200 200";

import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import { muzzleShapeList } from "./MuzzleShapeList";
import ClippedMarkings from "character-creator/components/head/markings/ClippedMarkings";
import {
  translateTransformObject,
  buildTransform,
  filterListById,
} from "character-creator/menu/menu-helper";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";

function mapStateToProps(state, ownProps) {
  const muzzleConfig = getCharacterConfigValue(state, "muzzle");
  let muzzleObject = null;
  if (ownProps.preview && ownProps.muzzle) {
    muzzleObject = ownProps.muzzle;
  } else if (muzzleConfig && muzzleConfig.shape && muzzleConfig.shape !== "0") {
    muzzleObject = filterListById(muzzleShapeList, muzzleConfig.shape);
  }

  const colour =
    muzzleConfig && muzzleConfig.colour
      ? {
          base: muzzleConfig.colour.dark,
          dark: muzzleConfig.colour.darker,
        }
      : {
          base: getCharacterConfigValue(state, "skintone").dark,
          dark: getCharacterConfigValue(state, "skintone").darker,
        };

  const transform =
    !ownProps.preview && muzzleConfig && muzzleConfig.transform
      ? translateTransformObject(buildTransform(muzzleConfig.transform))
      : "";

  return {
    colour: colour,
    Muzzle: muzzleObject,
    transform,
  };
}

const Muzzle = (props) => {
  const { colour, Muzzle, transform, previewId } = props;

  // Add other things, like extras, markings and all that fun stuff

  if (Muzzle?.shape) {
    return (
      <g
        id={previewId ? `g-muzzle~${previewId}` : "g-muzzle"}
        transform-origin="50% 50%"
        {...transform}
      >
        <ItemComponent
          id={previewId ? `muzzle~${previewId}` : "muzzle"}
          component={Muzzle}
          colour={colour}
        />
        {!previewId && Muzzle && (
          <ClippedMarkings componentName="muzzle" maskUrl="muzzle_mask" />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Muzzle);

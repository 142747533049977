import AllSkintones from "character-creator/colours/skin";
import { BodyShapeListArray } from "character-creator/components/body/shape/BodyShapeList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const bodyPath = "body.";

const bodyShapePageConfig = {
  id: "bodyShapePage",
  name: "Shape",
  description: "Start your new career as a bodybuilder",
  controls: [
    {
      id: "bodyShape",
      name: "Shape and texture",
      description: "",
      dataAttribute: [bodyPath + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyShapeListArray,
      colourControls: [
        {
          id: "bodyColour",
          name: "Colour",
          description: "",
          dataAttribute: [bodyPath + "colour", "skintone"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
    },
  ],
};

const BodyShapePage = {
  id: "bodyShapePage",
  name: "Shape",
  description: "Start your new career as a bodybuilder",
  content: <SubPage page={bodyShapePageConfig} />,
};

export default BodyShapePage;

// Sections
import TabbedPanelContainer from "character-creator/menu/elements/TabbedPanelContainer";
import HairBackPage from "./pages/head/HairBackPage";
import HairBasePage from "./pages/head/HairBasePage";
import HairFrontPage from "./pages/head/HairFrontPage";
import HairMidPage from "./pages/head/HairMidPage";
import HairSidePage from "./pages/head/HairSidesPage";
import HairTopPage from "./pages/head/HairTopPage";
import React from "react";

// Attribute paths
// export const basePath = "base.";

const HairMenuPages = {
  hairBasePage: HairBasePage,
  hairBackPage: HairBackPage,
  hairMidPage: HairMidPage,
  hairSidePage: HairSidePage,
  hairTopPage: HairTopPage,
  hairFrontPage: HairFrontPage,
};

const HairMenuConfig = {
  id: "Hair",
  name: "Hair",
  description: "Hair today, gone tomorrow",
  content: (
    <TabbedPanelContainer
      id="tabbedPanelHair"
      tabs={HairMenuPages}
      containerClasses={"menu-block"}
      navClasses={"nav-inline"}
    />
  ),
};

export default HairMenuConfig;

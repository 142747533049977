// Components
import { IrisColours } from "character-creator/colours/eye";
import { irisOuterShapeList } from "character-creator/components/head/face/eyes/irises/outer/IrisOuterShapeList";
import { irisInnerShapeList } from "character-creator/components/head/face/eyes/irises/inner/IrisInnerShapeList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const eyeBasePath = "eyes.base.";
const irisPath = "eyes.iris.";

const IrisPageConfig = {
  id: "irisPage",
  name: "Irises",
  description: "",
  dependencies: [
    {
      description: "Eye shape",
      attribute: [eyeBasePath + "left.shape", eyeBasePath + "right.shape"],
    },
  ],
  dependencyMessage: "Please choose an eye shape first",
  symmetryAttribute: irisPath + "symmetry",
  controls: {
    symmetrical: [
      {
        id: "irisOuterShape",
        name: "Iris Outer Shape",
        description: "",
        dataAttribute: [
          irisPath + "outer.left.shape",
          irisPath + "outer.right.shape",
        ],
        variationAttribute: "",
        previewType: "preview",
        options: irisOuterShapeList,
        dependencies: [
          {
            description: "Eye shape chosen",
            attribute: [
              eyeBasePath + "left.shape",
              eyeBasePath + "right.shape",
            ],
          },
        ],
        colourControls: [
          {
            id: "irisOuterColour",
            name: "Iris Outer Colour",
            description: "",
            dataAttribute: [
              irisPath + "outer.left.colour",
              irisPath + "outer.right.colour",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: IrisColours,
          },
        ],
        transformControls: [
          {
            id: "irisTransformY",
            name: "Height",
            description: "",
            dataAttribute: [irisPath + "transform.y", irisPath + "transform.y"],
            range: {
              min: -20,
              max: 20,
              step: 10,
            },
          },
          {
            id: "irisTransformX",
            name: "Horizontal",
            description: "",
            dataAttribute: [irisPath + "transform.x", irisPath + "transform.x"],
            range: {
              min: -10,
              max: 10,
              step: 5,
            },
          },
          {
            id: "irisTransformScale",
            name: "Size",
            description: "",
            dataAttribute: [
              irisPath + "transform.scale",
              irisPath + "transform.scale",
            ],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.125,
            },
          },
        ],
      },
      {
        id: "irisInnerShape",
        name: "Iris Inner Shape",
        description: "",
        dataAttribute: [
          irisPath + "inner.left.shape",
          irisPath + "inner.right.shape",
        ],
        variationAttribute: "",
        previewType: "preview",
        options: irisInnerShapeList,
        dependencies: [
          {
            description: "Eye shape chosen",
            attribute: [
              eyeBasePath + "left.shape",
              eyeBasePath + "right.shape",
            ],
          },
        ],
        colourControls: [
          {
            id: "irisInnerColour",
            name: "Iris Inner Colour",
            description: "",
            dataAttribute: [
              irisPath + "inner.left.colour",
              irisPath + "inner.right.colour",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: IrisColours,
          },
        ],
      },
    ],
    asymmetrical: [
      {
        id: "leftIrisOuterShape",
        name: "Left Outer Shape",
        description: "",
        dataAttribute: [irisPath + "outer.left.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: irisOuterShapeList,
        dependencies: [
          {
            description: "Left eye shape chosen",
            attribute: [eyeBasePath + "left.shape"],
          },
        ],
        colourControls: [
          {
            id: "leftIrisOuterColour",
            name: "Left Outer Colour",
            description: "",
            dataAttribute: [irisPath + "outer.left.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: IrisColours,
          },
        ],
      },
      {
        id: "leftIrisInnerShape",
        name: "Left Inner Shape",
        description: "",
        dataAttribute: [irisPath + "inner.left.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: irisInnerShapeList,
        dependencies: [
          {
            description: "Left eye shape chosen",
            attribute: [eyeBasePath + "left.shape"],
          },
        ],
        colourControls: [
          {
            id: "leftIrisInnerColour",
            name: "Left Inner Colour",
            description: "",
            dataAttribute: [irisPath + "inner.left.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: IrisColours,
          },
        ],
      },
      {
        id: "rightIrisOuterShape",
        name: "Right Outer Shape",
        description: "",
        dataAttribute: [irisPath + "outer.right.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: irisOuterShapeList,
        dependencies: [
          {
            description: "Right eye shape chosen",
            attribute: [eyeBasePath + "right.shape"],
          },
        ],
        colourControls: [
          {
            id: "rightIrisOuterColour",
            name: "Right Outer Colour",
            description: "",
            dataAttribute: [irisPath + "outer.right.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: IrisColours,
          },
        ],
      },
      {
        id: "rightIrisInnerShape",
        name: "Right Inner Shape",
        description: "",
        dataAttribute: [irisPath + "inner.right.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: irisInnerShapeList,
        dependencies: [
          {
            description: "Right eye shape chosen",
            attribute: [eyeBasePath + "right.shape"],
          },
        ],
        colourControls: [
          {
            id: "rightIrisInnerColour",
            name: "Right Inner Colour",
            description: "",
            dataAttribute: [irisPath + "inner.right.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: IrisColours,
          },
        ],
      },
    ],
  },
};

const IrisPage = {
  id: "irisPage",
  name: "Iris",
  description: "",
  content: <SubPage page={IrisPageConfig} />,
};

export default IrisPage;

import React from "react";
import { connect } from "react-redux";
import { BackgroundShapesListArray } from "./BackgroundShapesList";
import AllPurples from "character-creator/colours/collections/Purples";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";
import ItemComponent from "character-creator/components/ItemComponent";

function mapStateToProps(state, ownProps) {
  // Configs
  const shapesConfig = getCharacterConfigValue(state, "background.shapes");
  const shape1Config = shapesConfig?.shape1;
  const shape2Config = shapesConfig?.shape2;
  const shape3Config = shapesConfig?.shape3;

  // Shape 1
  const Shape1Object =
    ownProps.preview && ownProps.shape
      ? ownProps.shape
      : shape1Config?.shape && shape1Config.shape !== "0"
      ? filterListById(BackgroundShapesListArray, shape1Config.shape)
      : null;

  const Shape1Colours = shape1Config?.colour
    ? shape1Config.colour
    : AllPurples[0];

  // Shape 2
  const Shape2Object =
    !ownProps.previewId && shape2Config?.shape && shape2Config.shape !== "0"
      ? filterListById(BackgroundShapesListArray, shape2Config.shape)
      : null;

  const Shape2Colours = shape2Config?.colour
    ? shape2Config.colour
    : AllPurples[1];

  // Shape 3
  const Shape3Object =
    !ownProps.previewId && shape3Config?.shape && shape3Config.shape !== "0"
      ? filterListById(BackgroundShapesListArray, shape3Config.shape)
      : null;

  const Shape3Colours = shape3Config?.colour
    ? shape3Config.colour
    : AllPurples[2];

  return {
    Shape1Object,
    Shape1Colours,
    Shape2Object,
    Shape2Colours,
    Shape3Object,
    Shape3Colours,
  };
}

const BackgroundShapes = (props) => {
  const {
    Shape1Object,
    Shape1Colours,
    Shape2Object,
    Shape2Colours,
    Shape3Object,
    Shape3Colours,
    preview,
    previewId,
  } = props;

  if (Shape1Object?.shape || Shape2Object?.shape || Shape3Object?.shape) {
    return (
      <g
        id={
          previewId ? `g-background-shapes~${previewId}` : "g-background-shapes"
        }
      >
        {/* Shape 1 */}
        <ItemComponent
          id={
            previewId ? `background-shape1~${previewId}` : "background-shape1"
          }
          component={Shape1Object}
          colour={Shape1Colours}
          preview={previewId ? true : false}
        />

        {/* Shape 2 */}
        {!preview && Shape2Object?.shape && Shape2Object.shape !== "0" && (
          <ItemComponent
            id={"background-shape2"}
            component={Shape2Object}
            colour={Shape2Colours}
            preview={false}
          />
        )}

        {/* Shape 3 */}
        {!preview && Shape3Object?.shape && Shape3Object?.shape !== "0" && (
          <ItemComponent
            id={"background-shape3"}
            component={Shape3Object}
            colour={Shape3Colours}
            preview={false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(BackgroundShapes);

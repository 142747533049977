// Components
import { allColours } from "character-creator/colours/colourSchemes";
import {
  ContouringCheeksShapeList,
  ContouringEyesShapeList,
  ContouringLipsShapeList,
} from "character-creator/components/head/markings/contouring/ContouringShapeList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const contouringPath = "face-contouring.";

const ContouringPageConfig = {
  id: "contouringPage",
  name: "Contouring",
  description: "",
  controls: [
    {
      id: "contouringEyesShape",
      name: "Eyes",
      description: "",
      dataAttribute: [
        contouringPath + "contouringEyes.left.shape",
        contouringPath + "contouringEyes.right.shape",
      ],
      variationAttribute: "",
      previewType: "preview",
      options: ContouringEyesShapeList,
      colourControls: [
        {
          id: "contouringEyesColour",
          name: "Colour",
          description: "",
          dataAttribute: [
            contouringPath + "contouringEyes.left.colour",
            contouringPath + "contouringEyes.right.colour",
          ],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "contouringEyesTransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [
            contouringPath + "contouringEyes.left.transform.opacity",
            contouringPath + "contouringEyes.right.transform.opacity",
          ],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "contouringCheeksShape",
      name: "Cheeks",
      description: "",
      dataAttribute: [
        contouringPath + "contouringCheeks.left.shape",
        contouringPath + "contouringCheeks.right.shape",
      ],
      variationAttribute: "",
      previewType: "preview",
      options: ContouringCheeksShapeList,
      colourControls: [
        {
          id: "contouringCheeksColour",
          name: "Colour",
          description: "",
          dataAttribute: [
            contouringPath + "contouringCheeks.left.colour",
            contouringPath + "contouringCheeks.right.colour",
          ],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "contouringCheeksTransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [
            contouringPath + "contouringCheeks.left.transform.opacity",
            contouringPath + "contouringCheeks.right.transform.opacity",
          ],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "contouringLipsShape",
      name: "Lips",
      description: "",
      dataAttribute: [contouringPath + "contouringLips.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: ContouringLipsShapeList,
      colourControls: [
        {
          id: "contouringLipsColour",
          name: "Colour",
          description: "",
          dataAttribute: [contouringPath + "contouringLips.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "contouringLipsTransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [contouringPath + "contouringLips.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

const ContouringPage = {
  id: "contouringPage",
  name: "Contouring",
  description: "",
  content: <SubPage page={ContouringPageConfig} />,
};

export default ContouringPage;

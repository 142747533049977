import {
  CreateYourBlank,
  KofiButton,
  PatreonButton,
  Feature,
} from "character-creator/utilities/HomeElements";
import exampleImage1 from "assets/images/placeholders/ca_example1.png";
import exampleImage2 from "assets/images/placeholders/ca_example2.png";
import exampleImage3 from "assets/images/placeholders/ca_example3.png";
import exampleImage4 from "assets/images/placeholders/ca_example4.png";
import exampleImage5 from "assets/images/placeholders/ca_example5.png";
import React from "react";

const HomePage = (props) => {
  return (
    <>
      <section className="panel cta-panel">
        <div className="cta-text">
          <h2>
            Create your <CreateYourBlank /> now!
          </h2>
          <p>
            <strong>
              Making awesome character art should be fun regardless of genre,
              equipment or artistic ability.
            </strong>
          </p>
          <p>
            We're making a <strong>multi-genre creative behemoth</strong> that
            can help you create your new avatar, online persona, RPG character
            or <CreateYourBlank />. You can use your creations for drawing and
            colouring practice, commissioning art from your favourite artists or
            just as they are!
          </p>
        </div>
        <div className="cta-buttons cta-buttons-col">
          <PatreonButton />
          <KofiButton />
        </div>
      </section>
      <section className="panel feature-panel">
        <h2>Who and what can I make?</h2>
        <ul className="features-list">
          <Feature name={"TTRPG characters"} image={exampleImage4} />
          <Feature
            name={"Work or social media avatars"}
            image={exampleImage1}
          />
          <Feature name={"Art commission references"} image={exampleImage2} />
          <Feature name={"Colouring sheets"} image={exampleImage3} />
          <Feature name={"Strange characters"} image={exampleImage5} />
        </ul>
      </section>
      <section className="cta-panel cta-panel-alt">
        <PatreonButton />
        <KofiButton />
      </section>
    </>
  );
};

export default HomePage;

import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const eyebrow_shape_DJdii = {
  id: "eyebrow_shape_DJdii",
  name: "Eyebrow 1",
  description:
    "Thick, curved eyebrows that have a sharp, rectangular inner corner and taper to a sharp outer corner",
  tags: ["thick", "curved"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M239.26 223.58v-10.22s-30.35-19.22-53.18 5.11c-8.03 8.56 10.52-7.12 22.54-5.62 12.03 1.51 25.24 7.3 30.64 10.73Z" />
      ),
      dark: (
        <path d="M235.225 211.223c2.554 1.199 4.035 2.137 4.035 2.137v10.22c-4.951-3.145-16.467-8.273-27.62-10.268.742-.07 1.48-.07 1.746-.091 2.675-.205 5.339-.086 7.957.525.989.231 1.96.512 2.931.811.216.066.845.38 1.078.303.084-.028-.259-.51-.281-.542-.129-.194-1.393-2.003-1.29-2.048.354-.154 1.326.33 1.683.413 1.278.296 2.599.561 3.746 1.229.697.406 1.684 1.471 2.508 1.557.596.062-.402-2.568.407-2.312.914.289 1.769 1.539 2.362 2.244.051.061.59.849.648.822.358-.162.416-1.068.446-1.363.123-1.192-.084-2.448-.356-3.637Z" />
      ),
      light: (
        <path d="M190.012 214.754c12.772-10.633 27.126-9.507 37.028-6.625a7.74 7.74 0 0 1 .705 1.667c.061.21.459 1.51.258 1.711-.02.02-.816-.592-.853-.617a13 13 0 0 0-1.84-1.047c-2.239-1.043-4.306-2.418-6.826-2.637-.665-.058-1.332-.048-1.998-.048h-1.024s.593.322.912.427c1.46.48 4.217 2.155.51 1.262-.825-.198-1.686-.182-2.533-.182-2.552 0-5.179.029-7.702.435-4.923.791-9.692 2.849-14.328 4.626-.581.223-1.433.669-2.309 1.028Z" />
      ),
    },
    right: {
      base: (
        <path d="M260.99 223.58v-10.22s30.35-19.22 53.18 5.11c8.03 8.56-10.52-7.12-22.54-5.62-12.03 1.51-25.24 7.3-30.64 10.73Z" />
      ),
      dark: (
        <path d="M265.025 211.223c-2.554 1.199-4.035 2.137-4.035 2.137v10.22c4.951-3.145 16.467-8.273 27.62-10.268-.742-.07-1.48-.07-1.746-.091-2.675-.205-5.339-.086-7.957.525-.989.231-1.96.512-2.931.811-.216.066-.845.38-1.078.303-.084-.028.259-.51.281-.542.129-.194 1.393-2.003 1.29-2.048-.354-.154-1.326.33-1.683.413-1.278.296-2.599.561-3.746 1.229-.697.406-1.684 1.471-2.508 1.557-.596.062.402-2.568-.407-2.312-.914.289-1.769 1.539-2.362 2.244-.051.061-.59.849-.648.822-.358-.162-.416-1.068-.446-1.363-.123-1.192.084-2.448.356-3.637Z" />
      ),
      light: (
        <path d="M310.238 214.754c-12.772-10.633-27.126-9.507-37.028-6.625a7.74 7.74 0 0 0-.705 1.667c-.061.21-.459 1.51-.258 1.711.02.02.816-.592.853-.617a13 13 0 0 1 1.84-1.047c2.239-1.043 4.306-2.418 6.826-2.637.665-.058 1.332-.048 1.998-.048h1.024s-.593.322-.912.427c-1.46.48-4.217 2.155-.51 1.262.825-.198 1.686-.182 2.533-.182 2.552 0 5.179.029 7.702.435 4.923.791 9.692 2.849 14.328 4.626.581.223 1.433.669 2.309 1.028Z" />
      ),
    },
  },
};

const eyebrow_shape_Rp1Jl = {
  id: "eyebrow_shape_Rp1Jl",
  name: "Eyebrow 2",
  description:
    "Sparser eyebrows with a few gaps. Thicker towards the inner corner and thinner at the outer corner",
  tags: ["sparse"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M236.58 223.13c-1.83 1.46-2.8.58-2.12-1.36.68-1.96 1.25-3.66.97-5.78-.12-.85-.34-1.73-.14-2.57 1.29 1.24 2.66 2.63 2.83 4.41.02.18.08.43.27.43.11-.59.22-1.19.33-1.79.05-.29.18-.65.47-.66.3-.01.46.34.5.64.19 1.45-.49 2.86-1.2 4.14-.52.94-1.08 1.88-1.91 2.54Zm-2.55-3.89c-.29 1.32-1.32 3.45-2.93 2.6-.72-.38-1.07-1.66-1.6-2.28-.55-.66-1.24-1.2-2.01-1.59v.97c-.7.51-1.64-.14-2.25-.75l-2.83-2.83c.09-.22.28-.4.5-.5.4.46 1.05.69 1.65.57a27.423 27.423 0 0 0-9.37-8.96c.95-.22 1.95-.01 2.88.28 3.54 1.09 6.7 3.38 8.84 6.4.14-.9-.05-1.84-.52-2.62 2.74 1.57 4.15 4.66 5.39 7.56 0-2.87-.29-5.74-.86-8.55 1.28 1.7 2.24 3.64 2.82 5.69.37 1.3.58 2.69.29 4.01Zm-26.71-12.55c2.53.28 5.1.78 7.41 1.88 1.36.66 5.53 3.22 5.02 5.01-1.88-.87-3.4-2.38-5.39-3.12-1.09-.4-2.24-.61-3.38-.81-2.58-.46-5.15-.91-7.73-1.37-1.84-.32-3.76-.69-5.2-1.87 3.09-.16 6.19-.07 9.27.28Zm-16.02-.48c1.97.06 6.43.36 7.61 2.22-1.88.33-4.52-.41-6.48-.64-.69-.08-5.61-.79-5.62-.95-.02-.23 3.16-.67 4.49-.63Z" />
      ),
      dark: (
        <path d="M231.071 216.48c.247.536.481 1.076.709 1.61 0-.716-.018-1.431-.054-2.146.251.275.442.738.581.979.424.732.919 1.876 1.684 2.483-.337 1.324-1.347 3.249-2.891 2.434-.72-.38-1.07-1.66-1.6-2.28-.55-.66-1.24-1.2-2.01-1.59v.97c-.7.51-1.64-.14-2.25-.75l-.934-.934c.158.03.316.06.474.088.443.079 1.246.322 1.659.024.622-.448-1.287-2.477-1.555-2.867-.125-.18-.587-.77-.289-.985.422-.307 1.438.345 1.783.538 1.098.613 2.042 1.485 2.938 2.357.338.33.876 1.11 1.378 1.203.321.06.329-.584.377-1.134Zm8.569 1.307c-.185.976-.66 1.92-1.15 2.803-.52.94-1.08 1.88-1.91 2.54-1.83 1.46-2.8.58-2.12-1.36.233-.671.453-1.312.63-1.95 1.144.046 2.392-.557 3.308-1.151.127-.083 1.067-.877 1.242-.882Z" />
      ),
      light: (
        <path d="M263.67 223.13c-.83-.66-1.39-1.6-1.91-2.54-.71-1.28-1.39-2.69-1.2-4.14.04-.3.2-.65.5-.64.29.01.42.37.47.66.11.6.22 1.2.33 1.79.19 0 .25-.25.27-.43.17-1.78 1.54-3.17 2.83-4.41.2.84-.02 1.72-.14 2.57-.28 2.12.29 3.82.97 5.78.68 1.94-.29 2.82-2.12 1.36Zm2.55-3.89c-.29-1.32-.08-2.71.29-4.01.58-2.05 1.54-3.99 2.82-5.69-.57 2.81-.86 5.68-.86 8.55 1.24-2.9 2.65-5.99 5.39-7.56-.47.78-.66 1.72-.52 2.62 2.14-3.02 5.3-5.31 8.84-6.4.93-.29 1.93-.5 2.88-.28a27.423 27.423 0 0 0-9.37 8.96c.6.12 1.25-.11 1.65-.57.22.1.41.28.5.5l-2.83 2.83c-.61.61-1.55 1.26-2.25.75v-.97c-.77.39-1.46.93-2.01 1.59-.53.62-.88 1.9-1.6 2.28-1.61.85-2.64-1.28-2.93-2.6Zm26.71-12.55c3.08-.35 6.18-.44 9.27-.28-1.44 1.18-3.36 1.55-5.2 1.87-2.58.46-5.15.91-7.73 1.37-1.14.2-2.29.41-3.38.81-1.99.74-3.51 2.25-5.39 3.12-.51-1.79 3.66-4.35 5.02-5.01 2.31-1.1 4.89-1.6 7.41-1.88Zm16.02-.48c1.33-.04 4.51.4 4.49.63-.01.16-4.93.87-5.62.95-1.96.23-4.6.97-6.48.64 1.18-1.86 5.64-2.16 7.61-2.22Z" />
      ),
    },
    right: {
      base: (
        <path d="M263.67 223.13c-.83-.66-1.39-1.6-1.91-2.54-.71-1.28-1.39-2.69-1.2-4.14.04-.3.2-.65.5-.64.29.01.42.37.47.66.11.6.22 1.2.33 1.79.19 0 .25-.25.27-.43.17-1.78 1.54-3.17 2.83-4.41.2.84-.02 1.72-.14 2.57-.28 2.12.29 3.82.97 5.78.68 1.94-.29 2.82-2.12 1.36Zm2.55-3.89c-.29-1.32-.08-2.71.29-4.01.58-2.05 1.54-3.99 2.82-5.69-.57 2.81-.86 5.68-.86 8.55 1.24-2.9 2.65-5.99 5.39-7.56-.47.78-.66 1.72-.52 2.62 2.14-3.02 5.3-5.31 8.84-6.4.93-.29 1.93-.5 2.88-.28a27.423 27.423 0 0 0-9.37 8.96c.6.12 1.25-.11 1.65-.57.22.1.41.28.5.5l-2.83 2.83c-.61.61-1.55 1.26-2.25.75v-.97c-.77.39-1.46.93-2.01 1.59-.53.62-.88 1.9-1.6 2.28-1.61.85-2.64-1.28-2.93-2.6Zm26.71-12.55c3.08-.35 6.18-.44 9.27-.28-1.44 1.18-3.36 1.55-5.2 1.87-2.58.46-5.15.91-7.73 1.37-1.14.2-2.29.41-3.38.81-1.99.74-3.51 2.25-5.39 3.12-.51-1.79 3.66-4.35 5.02-5.01 2.31-1.1 4.89-1.6 7.41-1.88Zm16.02-.48c1.33-.04 4.51.4 4.49.63-.01.16-4.93.87-5.62.95-1.96.23-4.6.97-6.48.64 1.18-1.86 5.64-2.16 7.61-2.22Z" />
      ),
      dark: (
        <path d="M269.097 216.48a62.212 62.212 0 0 0-.709 1.61c0-.716.018-1.431.054-2.146-.251.275-.442.738-.581.979-.424.732-.919 1.876-1.683 2.483.336 1.324 1.346 3.249 2.89 2.434.72-.38 1.07-1.66 1.6-2.28.55-.66 1.24-1.2 2.01-1.59v.97c.7.51 1.64-.14 2.25-.75l.935-.934c-.159.03-.317.06-.475.088-.443.079-1.245.322-1.659.024-.622-.448 1.287-2.477 1.556-2.867.124-.18.586-.77.288-.985-.422-.307-1.438.345-1.783.538-1.097.613-2.042 1.485-2.938 2.357-.338.33-.875 1.11-1.377 1.203-.322.06-.33-.584-.378-1.134Zm-8.569 1.307c.185.976.661 1.92 1.15 2.803.52.94 1.08 1.88 1.91 2.54 1.83 1.46 2.8.58 2.12-1.36a32.444 32.444 0 0 1-.63-1.95c-1.143.046-2.392-.557-3.308-1.151-.127-.083-1.067-.877-1.242-.882Z" />
      ),
      light: (
        <path d="m263.73 225.57.76-2.17c.44-1.25 1.7-3.36 1.45-4.65-.88.21-1.56 1.41-2 2.17a6 6 0 0 0-.75 2c-.07.7.7 2.24.54 2.65Zm-1.57.43c-.23-.8-.33-5.59-1.39-4.68-1.55 1.3 1.12 3.74 1.39 4.68Zm5.68-4.53a29.39 29.39 0 0 1 4.65-5.13c-.6 1.4-1.2 3-.68 4.41a22.23 22.23 0 0 1 5.66-5.41 8.4 8.4 0 0 0-2.06 3.5 32.92 32.92 0 0 1 10.08-6.73 18.2 18.2 0 0 0-9.6 2.74 1.93 1.93 0 0 1-.68.32 2.11 2.11 0 0 1-.71 0c-1.35-.24-4.45-.84-5.51.22s-1.21 4.54-1.15 6.08Z" />
      ),
    },
  },
};

const eyebrow_shape_ZZFCm = {
  id: "eyebrow_shape_ZZFCm",
  name: "Eyebrow 3",
  description:
    "Thick and short comma-shaped eyebrows with a large rounded inner corner tapering to a sharp corner above the centre of eye",
  tags: ["side"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M231.39,230.19c5.45.91,8.27-6.62,7-9.64-1.62-3.78-10.27-10.91-27.19-9.2-11.35,1.14,9,1.91,12.75,12.56A9.79,9.79,0,0,0,231.39,230.19Z" />
      ),
      basic: {
        dark: (
          <path d="M238.26,219.92c-.09,2.51-.33,5.31-2.36,7a3.81,3.81,0,0,0,.14-3.2c-.5.35-1.33,2.28-1.9,1.45a5.69,5.69,0,0,1-1.92,1.23,5.76,5.76,0,0,0-1.56-4.92,13,13,0,0,1-.44,4.35l-.43-.29-.93.6a14.81,14.81,0,0,0-3.65-4.84,11,11,0,0,1,1.56,3.65,59.57,59.57,0,0,0-4.72-4.68,21,21,0,0,1,2.77,4.25,14.17,14.17,0,0,0-3.26-2.29C226.43,233.12,241.35,233.5,238.26,219.92Z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M268.61,230.19c-5.45.91-8.27-6.62-7-9.64,1.62-3.78,10.27-10.91,27.19-9.2,11.35,1.14-9,1.91-12.75,12.56A9.79,9.79,0,0,1,268.61,230.19Z" />
      ),
      basic: {
        dark: (
          <path d="M261.74,219.92c.09,2.51.33,5.31,2.36,7a3.81,3.81,0,0,1-.14-3.2c.5.35,1.33,2.28,1.9,1.45a5.69,5.69,0,0,0,1.92,1.23,5.76,5.76,0,0,1,1.56-4.92,13,13,0,0,0,.44,4.35l.43-.29.93.6a14.81,14.81,0,0,1,3.65-4.84,11,11,0,0,0-1.56,3.65,59.57,59.57,0,0,1,4.72-4.68,21,21,0,0,0-2.77,4.25,14.17,14.17,0,0,1,3.26-2.29C273.57,233.12,258.65,233.5,261.74,219.92Z" />
        ),
      },
    },
  },
};

const eyebrow_shape_HFvFd = {
  id: "eyebrow_shape_HFvFd",
  name: "Eyebrow 4",
  description:
    "Large, thick eyebrows that arch from a rounded inner corner to a sharp outer corner",
  tags: ["side"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <>
          <path d="M237.1,232.36a4.94,4.94,0,0,0,4.5-6.81c-1.76-4.17-7.77-9.93-26-14.58-11.84-3-22.57,3.51-30.74,16.17-3.37,5.23,20.22-22.38,36.67-4C226.31,228.45,231.71,232.52,237.1,232.36Z" />
        </>
      ),
      basic: {
        light: (
          <path d="M233.348 211.09c.002.119.348.552.375.588.156.206 1.776 2.359 1.469 2.481-.149.06-1.452-.595-1.61-.655-1.081-.412-3.376-1.573-4.667-1.432-1.65.179 2.014 3.737-.213 2.508-.24-.133-10.605-5.611-19.079-7.071-2.159-.372-3.167-.843-4.844-1.309-2.235-.62 3.037-.571 7.175-.014.358.048 1.947.27 2.247.091.038-.023-.998-.364-1.057-.383a78.596 78.596 0 0 0-2.013-.623c-.558-.164-2.623-.689-4.308-1.274 2.849-.415 5.795-.267 8.812.503 8.064 2.059 13.739 4.333 17.713 6.59Z" />
        ),
        dark: (
          <path d="M198.2 212.581c-8.822 3.874-15.164 10.978-13.305 8.099 1.438-2.228 6.654-7.022 7.033-7.289 1.575-1.11 3.164-2.081 4.949-2.82.367-.153 1.39-.675 1.812-.545.116.036-2.554 2.802-2.435 2.921.217.217 1.208-.127 1.946-.366Zm21.289 2.013c.52.237 3.166 1.447 7.456 4.893.478.384 1.733 1.756 2.452 1.697.156-.013-.813-2.87-.471-3.056.371-.202 1.201.348 1.501.513 1.191.66 2.662 3.055 4.152 2.757 1.165-.232.629-2.116.467-2.842-.038-.169-.312-.919-.018-1.007.27-.082.712.335.888.491.546.48 1.45 2.805 2.237 2.611.988-.242 1.042-2.037 1.011-2.816-.006-.152-.217-1.352-.09-1.312.925.289 2.548 2.489 2.581 2.567 1.36 3.21-1.01 6.71-4.49 6.81-5.39.16-10.79-3.92-15.6-9.26a22.904 22.904 0 0 0-2.076-2.046Z" />
        ),
      },
    },
    right: {
      base: (
        <>
          <path d="M262.77,232.36a4.94,4.94,0,0,1-4.49-6.81c1.76-4.17,7.77-9.93,26-14.58,11.84-3,22.58,3.51,30.75,16.17,3.37,5.23-20.23-22.38-36.68-4C273.57,228.45,268.17,232.52,262.77,232.36Z" />
        </>
      ),
      basic: {
        light: (
          <path d="M266.652 211.09c-.002.119-.348.552-.375.588-.156.206-1.776 2.359-1.469 2.481.149.06 1.452-.595 1.61-.655 1.081-.412 3.376-1.573 4.667-1.432 1.65.179-2.014 3.737.213 2.508.24-.133 10.605-5.611 19.079-7.071 2.159-.372 3.167-.843 4.844-1.309 2.235-.62-3.037-.571-7.175-.014-.358.048-1.947.27-2.247.091-.038-.023.998-.364 1.057-.383a78.596 78.596 0 0 1 2.013-.623c.558-.164 2.623-.689 4.308-1.274-2.849-.415-5.795-.267-8.812.503-8.064 2.059-13.739 4.333-17.713 6.59Z" />
        ),
        dark: (
          <path d="M301.8 212.581c8.822 3.874 15.164 10.978 13.305 8.099-1.438-2.228-6.654-7.022-7.033-7.289-1.575-1.11-3.164-2.081-4.949-2.82-.367-.153-1.39-.675-1.812-.545-.116.036 2.554 2.802 2.435 2.921-.217.217-1.208-.127-1.946-.366Zm-21.289 2.013c-.52.237-3.166 1.447-7.456 4.893-.478.384-1.733 1.756-2.452 1.697-.156-.013.813-2.87.471-3.056-.371-.202-1.201.348-1.501.513-1.191.66-2.662 3.055-4.152 2.757-1.165-.232-.629-2.116-.467-2.842.038-.169.312-.919.018-1.007-.27-.082-.712.335-.888.491-.546.48-1.45 2.805-2.237 2.611-.988-.242-1.042-2.037-1.011-2.816.006-.152.217-1.352.09-1.312-.925.289-2.548 2.489-2.581 2.567-1.36 3.21 1.01 6.71 4.49 6.81 5.39.16 10.79-3.92 15.6-9.26a22.904 22.904 0 0 1 2.076-2.046Z" />
        ),
      },
    },
  },
};

const eyebrow_shape_7znz0 = {
  id: "eyebrow_shape_7znz0",
  name: "Eyebrow 5",
  description:
    "Pencil-thin eyebrows that have a slight arch towards the outer corner",
  tags: ["side"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M239.597 214.762a.501.501 0 0 1-.294.956l-31.891-9.784c-.636-.119-12.176-2.1-19.702 9.577a.5.5 0 1 1-.84-.542c8.139-12.629 20.771-10.009 20.771-10.009a.39.39 0 0 1 .046.012l31.91 9.79Z" />
      ),
    },
    right: {
      base: (
        <path d="M260.947 215.718a.501.501 0 0 1-.294-.956l31.91-9.79a.39.39 0 0 1 .046-.012s12.632-2.62 20.771 10.009a.5.5 0 1 1-.84.542c-7.55-11.715-19.141-9.683-19.709-9.575l-31.884 9.782Z" />
      ),
    },
  },
};

const eyebrow_shape_koAsN = {
  id: "eyebrow_shape_koAsN",
  name: "Eyebrow 6",
  description:
    "Pencil-thin eyebrows that curve smoothly upwards at the centre of the eye",
  tags: ["side"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M239.232 212.454a.501.501 0 0 1-.524.852s-25.781-15.868-49.722-.009a.5.5 0 0 1-.552-.834c24.459-16.201 50.798-.009 50.798-.009Z" />
      ),
    },
    right: {
      base: <path d="M261.28 212.88s26.06-16.03 50.26 0" />,
    },
  },
};

export const eyebrowsPack1 = {
  eyebrow_shape_DJdii,
  eyebrow_shape_Rp1Jl,
  eyebrow_shape_ZZFCm,
  eyebrow_shape_HFvFd,
  eyebrow_shape_7znz0,
  eyebrow_shape_koAsN,
};

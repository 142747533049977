// Sections

import TabbedPanelContainer from "character-creator/menu/elements/TabbedPanelContainer";
import EarAccessoriesPage from "./EarAccessoriesPage";
import EyeAccessoriesPage from "./pages/eyes/EyeAccessoriesFullPage";
import NeckAccessoriesPage from "./pages/neck/NeckAccessoriesPage";
import NoseAccessoriesPage from "./pages/NoseAccessoriesPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

// Attribute paths
// export const basePath = "base.";

const AccessoriesMenuConfig = {
  id: "Accessories",
  name: "Accessories",
  // icon: icon,
  description: "Time to accessorise",
  content: (
    <TabbedPanelContainer
      id="tabbedPanelAccessories"
      tabs={{
        eyeAccessoriesPage: EyeAccessoriesPage,
        noseAccessoriesPage: NoseAccessoriesPage,
        neckAccessoriesPage: NeckAccessoriesPage,
        earAccessoriesPage: EarAccessoriesPage,
      }}
      containerClasses={"menu-block"}
      navClasses={"nav-inline"}
    />
  ),
};

export default AccessoriesMenuConfig;

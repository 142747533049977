// individual menu option component
import React from "react";
import { connect, useDispatch } from "react-redux";
// import { updateConfig } from "character-creator/utilities/characterSlice";
import Preview from "character-creator/menu/elements/controls/options/PreviewOption";
import Swatch from "character-creator/menu/elements/controls/options/SwatchOption";
import { buildRecentColoursList } from "../../menu-helper";
import {
  getColourConfigValue,
  getMenuConfigValue,
  updateConfig,
  updateRecentColoursConfig,
} from "character-creator/utilities/storeHelpers";

const mapDispatchToProps = {
  // updateConfig,
};

const mapStateToProps = (state, ownProps) => {
  return {
    navKeys: getMenuConfigValue(state, "navKeys"),
    recentColours: getColourConfigValue(state, "recentColours"),
    ...ownProps,
  };
};

const Control = (props) => {
  const {
    controlName,
    dataAttribute,
    selected,
    attributeType,
    previewType,
    previewSide,
    controlOption,
    navKeys,
    // controlId,
    recentColours,
    controlType = "radio",
    onChangeFunction,
    parentDataAttribute,
    itemIndex,
    newConfigObject,
  } = props;

  const controlId = controlOption.id;

  const dispatch = useDispatch();

  const updateValue = attributeType === "multi" ? controlOption : controlId;

  const onKeyPress = (event) => {
    const pressedKey = event.which || event.keyCode;
    if (navKeys.includes(pressedKey)) {
      onChange(event);
    }
  };

  const optionElementId = controlName + "_" + controlId;
  const isSelected =
    selected === (controlOption.id ? controlOption.id : controlId);

  const onContextMenu = (event) => {
    event.preventDefault(event);
    navigator.clipboard.writeText(updateValue);
  };

  const onChange = (event) => {
    if (parentDataAttribute) {
      if (newConfigObject) {
        let updatedConfigObject = newConfigObject;
        updatedConfigObject[itemIndex][dataAttribute] = updateValue;
        updateConfig(
          dispatch,
          onChangeFunction,
          parentDataAttribute,
          updatedConfigObject
        );
      } else {
        updateConfig(
          dispatch,
          onChangeFunction,
          parentDataAttribute,
          updateValue
        );
      }
    } else {
      updateConfig(dispatch, onChangeFunction, dataAttribute, updateValue);
    }
    if (previewType === "swatch") {
      let updatedColours = buildRecentColoursList(recentColours, updateValue);

      updateConfig(dispatch, updateRecentColoursConfig, [""], updatedColours);
    }
  };

  return (
    <li
      data-selected={isSelected}
      tabIndex={0}
      onKeyPress={(e) => onKeyPress(e)}
      data-navcontroltype="control-label"
      className="control-label"
      onContextMenu={($this) => onContextMenu($this)}
    >
      <label htmlFor={optionElementId}>
        <input
          id={optionElementId}
          type={controlType}
          name={controlName}
          title={controlOption.description}
          aria-label={controlOption.name}
          value={controlOption.id ? controlOption.id : controlId}
          data-navcontroltype="control"
          onChange={($this) => onChange($this)}
          checked={isSelected}
          className="hidden"
        />
        <span className="sr-only">{controlOption.name}</span>
        {previewType === "swatch" && <Swatch component={controlOption} />}
        {previewType === "preview" && (
          <Preview
            id={controlId}
            component={controlOption}
            side={previewSide}
          />
        )}
        {isSelected && (
          <span
            className="control-selected"
            aria-hidden="true"
            title="Selected"
          >
            ✓
          </span>
        )}
      </label>
    </li>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Control);

// Components
import HairColours from "character-creator/colours/hair";
import BeardList from "character-creator/components/head/face/facialHair/beard/BeardList";
import SubPage from "character-creator/menu/elements/SubPage";
import React from "react";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const beardPath = "beard.";

const BeardPageConfig = {
  id: "beardPage",
  name: "Beards and Sideburns",
  description: "Oh this? It just abeard one day!",
  controls: [
    {
      id: "beardShape",
      name: "Shape",
      description: "",
      dataAttribute: [beardPath + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BeardList,
      colourControls: [
        {
          id: "beardColour",
          name: "Beard Colour",
          description: "",
          dataAttribute: [beardPath + "colour"],
          variationAttribute: "",
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
    },
  ],
};

const BeardPage = {
  id: "beardPage",
  name: "Beard",
  description: "",
  content: <SubPage page={BeardPageConfig} />,
};

export default BeardPage;

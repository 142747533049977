const colour_green_Yoqs8 = {
  id: "colour_green_Yoqs8",
  name: "Racing Green",
  outline: "hsl(161, 56%, 16%)",
  darker: "hsl(161,56%,22%)",
  dark: "hsl(161, 56%, 27%)",
  base: "hsl(161, 57%, 33%)",
  light: "hsl(161, 43%, 44%)",
  lighter: "hsl(161, 35%, 60%)",
};

const colour_green_iKgCC = {
  id: "colour_green_iKgCC",
  name: "Emerald Green",
  outline: "hsl(118, 72%, 17%)",
  darker: "hsl(118, 46%, 27%)",
  dark: "hsl(118, 55%, 33%)",
  base: "hsl(118, 37%, 43%)",
  light: "hsl(118, 38%, 53%)",
  lighter: "hsl(118, 38%, 68%)",
};

const colour_green_fp4Ru = {
  id: "colour_green_fp4Ru",
  name: "Mid Khaki",
  outline: "#46351D",
  darker: "#625824",
  dark: "#7D7A2A",
  base: "#A09D56",
  light: "#C2C082",
  lighter: "#E6E49F",
};

const colour_green_elP9S = {
  id: "colour_green_elP9S",
  name: "Grey Green",
  outline: "hsl(109, 11%, 20%)",
  darker: "hsl(108, 13%, 36%)",
  dark: "hsl(107, 15%, 52%)",
  base: "hsl(108, 28%, 61%)",
  light: "hsl(107, 31%, 68%)",
  lighter: "hsl(107, 46%, 84%)",
};

const colour_green_W4ZB2 = {
  id: "colour_green_W4ZB2",
  name: "Mint Green",
  outline: "#1C634F",
  darker: "#058A64",
  dark: "#0DAB7E",
  base: "#16DEA5",
  light: "#25F5BA",
  lighter: "#8BF9DA",
};

const colour_green_W0lig = {
  id: "colour_green_W0lig",
  name: "Light Green",
  outline: "#4C7826",
  darker: "#679B39",
  dark: "#81BE4B",
  base: "#9ED66D",
  light: "#B2E189",
  lighter: "#C5EBA4",
};

const colour_green_iOn9f = {
  id: "colour_green_iOn9f",
  name: "Silver Green",
  outline: "#615552",
  darker: "#838168",
  dark: "#A9AC92",
  base: "#C7CDBF",
  light: "#E8EBE1",
  lighter: "#F8FAF8",
};

export const AllGreens = Object.values({
  colour_green_Yoqs8: colour_green_Yoqs8,
  colour_green_iKgCC: colour_green_iKgCC,
  colour_green_fp4Ru: colour_green_fp4Ru,
  colour_green_elP9S: colour_green_elP9S,
  colour_green_W4ZB2: colour_green_W4ZB2,
  colour_green_W0lig: colour_green_W0lig,
  colour_green_iOn9f: colour_green_iOn9f,
});

export default AllGreens;

import { headShapePack1 } from "./packs/HeadShapesPack1";
import { headShapePack2 } from "./packs/HeadShapesPack2";
import { headShapePack3 } from "./packs/HeadShapesPack3";
import { headShapePack4 } from "./packs/HeadShapesPack4";

//
export const HeadShapeList = {
  ...headShapePack1,
  ...headShapePack2,
  ...headShapePack3,
  ...headShapePack4,
};

export const HeadShapeListArray = Object.values(HeadShapeList);

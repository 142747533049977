import React from "react";
import CharacterHead from "character-creator/components/head/CharacterHead";
import Eyes from "../Eyes";
import Item from "character-creator/components/Item";
import { EyeballColours } from "character-creator/colours/eye";
import { filterListById } from "character-creator/menu/menu-helper";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  const previewItem = { ...component, shape: { ...component.shape["left"] } };

  return (
    <>
      <CharacterHead preview={true} />
      <Item
        id={"eyes"}
        previewId={previewId}
        previewItem={previewItem}
        defaultColour={filterListById(EyeballColours, "colour_eyeball_29JeE")}
      />
    </>
  );
};

export const viewbox = "170 170 160 160";

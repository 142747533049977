import React from "react";
import { viewbox, PreviewBase } from "../../Preview";

// V neck, narrow straps

const vest_6tViT6LcpK_AC = {
  base: (
    <path d="M109 500s5.235-82.212 24.147-101.08c2.416-2.411 15.375-5.02 16.448-2.841 22.418 45.56 51.543 69.142 79.383 85.854l.016-.153A448.902 448.902 0 0 0 250 493.685a448.902 448.902 0 0 0 21.006-11.905l.016.153c27.84-16.712 56.965-40.294 79.383-85.854 1.073-2.179 14.032.43 16.448 2.841C385.765 417.788 391 500 391 500H109Z" />
  ),
  basic: {
    dark: (
      <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
    ),
    light: (
      <path d="M148.3 394.154h203.4c5.736-4.541-15.025 43.134-48.744 72.436-5.983 5.199-40.089 27.677-50.857 33.594-.253.569-3.945.569-4.198 0-10.768-5.917-44.874-28.395-50.857-33.594-33.719-29.302-54.48-76.977-48.744-72.436Z" />
    ),
  },
};

const vest_6tViT6LcpK_B = {
  base: (
    <path d="M109 500s5.174-94.867 24.087-113.735c2.415-2.41 15.301-6.405 16.374-4.225 28.102 57.11 66.875 93.664 100.319 111.528l.22.117.22-.117c33.444-17.864 72.217-54.418 100.319-111.528 1.073-2.18 13.959 1.815 16.374 4.225C385.826 405.133 391 500 391 500H109Z" />
  ),
  basic: {
    dark: (
      <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
    ),
    light: (
      <path d="M148.3 377.212h203.4c5.736-5.366-15.025 50.964-48.744 85.586-5.983 6.142-40.089 32.701-50.857 39.691-.253.673-3.945.673-4.198 0-10.768-6.99-44.874-33.549-50.857-39.691-33.719-34.622-54.48-90.952-48.744-85.586Z" />
    ),
  },
};

const vest_6tViT6LcpK = {
  id: "vest_6tViT6LcpK",
  name: "V neck vest - narrow straps",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1
    base_body_shape_lcUGV: {
      ...vest_6tViT6LcpK_AC,
    },
    base_body_shape_taw74: {
      ...vest_6tViT6LcpK_B,
    },
    base_body_shape_jnr29: {
      ...vest_6tViT6LcpK_AC,
    },
    // Body 2
    base_body_shape_g825h: {
      ...vest_6tViT6LcpK_AC,
    },
    base_body_shape_fa048: {
      ...vest_6tViT6LcpK_B,
    },
    base_body_shape_mge5o: {
      ...vest_6tViT6LcpK_AC,
    },
    // Body 3
    base_body_shape_gj245: {
      ...vest_6tViT6LcpK_AC,
    },
    base_body_shape_942n5: {
      base: (
        <path d="M109 500s9.538-94.648 28.45-113.516c2.416-2.41 13.966-11.209 14.589-8.461 14.076 62.073 64.297 97.681 97.741 115.545l.22.117.22-.117c33.444-17.864 75.011-56.507 103.37-113.49 1.782-3.582 11.955 5.321 13.681 8.265C378.064 406.75 391 500 391 500H109Z" />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
        ),
        light: (
          <path d="m149.718 374.975 205.714 1.3c5.936-5.304-15.549 50.378-50.443 84.601-6.191 6.072-41.487 32.326-52.629 39.236-.263.665-4.083.665-4.346 0-11.142-6.91-46.438-33.164-52.629-39.236-34.894-34.223-49.605-77.027-45.667-85.901Z" />
        ),
      },
    },
    base_body_shape_0424n: {
      ...vest_6tViT6LcpK_AC,
    },
    // Body 4
    base_body_shape_fds8g: {
      base: (
        <path d="M109 500s5.294-84.99 24.206-103.858c2.416-2.411 15.375-5.02 16.448-2.841 22.418 45.56 51.484 71.92 79.324 88.632l.016-.153A448.902 448.902 0 0 0 250 493.685a448.902 448.902 0 0 0 21.006-11.905l.016.153c27.84-16.712 57.908-42.908 80.327-88.467 1.073-2.18 14.031.43 16.447 2.84C386.709 415.174 391 500 391 500H109Z" />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
        ),
        light: (
          <path d="M148.031 389.99h203.938c5.751-4.719-15.065 44.821-48.873 75.269-5.998 5.403-40.195 28.76-50.991 34.908-.254.592-3.956.592-4.21 0-10.796-6.148-44.993-29.505-50.991-34.908-33.808-30.448-54.624-79.988-48.873-75.269Z" />
        ),
      },
    },
    base_body_shape_sg894: {
      base: (
        <path d="M109 500s6.434-98.973 25.346-117.841c2.416-2.41 15.933-5.406 17.005-3.226 28.102 57.109 64.985 96.771 98.429 114.635l.22.117.22-.117c33.444-17.864 71.012-57.157 99.114-114.266 1.073-2.18 15.173.135 17.589 2.545C385.835 400.715 391 500 391 500H109Z" />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
        ),
        light: (
          <path d="M148.3 376.238h203.4c5.736-5.312-15.025 50.449-48.744 84.721-5.983 6.081-40.089 32.372-50.857 39.292-.253.665-3.945.665-4.198 0-10.768-6.92-44.874-33.211-50.857-39.292-33.719-34.272-54.48-90.033-48.744-84.721Z" />
        ),
      },
    },
    base_body_shape_fa84m: {
      ...vest_6tViT6LcpK_AC,
    },
  },
};

// V neck, medium straps

const vest_v6KKbrqtYb_AC = {
  base: (
    <path d="M250 493.685a448.902 448.902 0 0 0 21.006-11.905l.016.153c27.84-16.712 49.472-28.97 65.554-88.992.629-2.346 27.861 3.568 30.277 5.979C385.765 417.788 391 500 391 500H109s5.235-82.212 24.147-101.08c2.416-2.411 29.648-8.325 30.277-5.979 16.082 60.022 37.714 72.28 65.554 88.992l.016-.153A448.902 448.902 0 0 0 250 493.685Z" />
  ),
  basic: {
    dark: (
      <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
    ),
    light: (
      <path d="M154.314 373.19h191.372c5.396-4.818-14.137 65.152-45.862 96.238-5.629 5.515-37.718 24.592-47.849 30.869-.238.604-3.712.604-3.95 0-10.131-6.277-42.22-25.354-47.849-30.869-31.725-31.086-51.258-101.056-45.862-96.238Z" />
    ),
  },
};

const vest_v6KKbrqtYb_B = {
  base: (
    <path d="M247.337 492.266c.811.259 1.625.6 2.443 1.036l.22.118.22-.118c33.444-17.863 75.892-38.217 90.651-115.567.456-2.386 23.627 5.854 26.042 8.265C385.826 404.868 391 499.735 391 499.735h-97.067c.231.167.332.265.332.265H109s5.174-94.867 24.087-113.735c2.415-2.41 25.586-10.651 26.042-8.265 14.402 75.478 55.169 96.685 88.208 114.266Z" />
  ),
  basic: {
    dark: (
      <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
    ),
    light: (
      <path d="M154.314 373.19h191.372c5.396-4.818-14.137 65.152-45.862 96.238-5.629 5.515-37.718 24.592-47.849 30.869-.238.604-3.712.604-3.95 0-10.131-6.277-42.22-25.354-47.849-30.869-31.725-31.086-51.258-101.056-45.862-96.238Z" />
    ),
  },
};

const vest_v6KKbrqtYb = {
  id: "vest_v6KKbrqtYb",
  name: "V neck vest - medium straps",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1
    base_body_shape_lcUGV: {
      ...vest_v6KKbrqtYb_AC,
    },
    base_body_shape_taw74: {
      ...vest_v6KKbrqtYb_B,
    },
    base_body_shape_jnr29: {
      ...vest_v6KKbrqtYb_AC,
    },
    // Body 2
    base_body_shape_g825h: {
      ...vest_v6KKbrqtYb_AC,
    },
    base_body_shape_fa048: {
      base: (
        <path d="M109 500s5.174-94.867 24.087-113.735c2.415-2.41 23.957-7.959 24.422-5.575 13.501 69.254 58.827 95.014 92.271 112.878l.007.003.006-.003c33.444-17.864 80.302-42.777 93.804-112.031.465-2.384 20.474 2.318 22.89 4.728C385.399 405.133 390.573 500 390.573 500H109Z" />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
        ),
        light: (
          <path d="M151.08 375.131h197.84c5.58-4.671-14.614 63.166-47.411 93.304-5.819 5.347-38.994 23.843-49.467 29.928-.246.586-3.838.586-4.084 0-10.473-6.085-43.648-24.581-49.467-29.928-32.797-30.138-52.991-97.975-47.411-93.304Z" />
        ),
      },
    },
    base_body_shape_mge5o: {
      ...vest_v6KKbrqtYb_AC,
    },
    // Body 3
    base_body_shape_gj245: {
      ...vest_v6KKbrqtYb_AC,
    },
    base_body_shape_942n5: {
      base: (
        <path d="M109 500s13.359-91.472 28.45-113.516c5.824-8.506 21.207-11.565 22.135-7.762 21.107 86.473 56.751 96.982 90.195 114.846l.22.117.22-.117c33.444-17.864 69.088-28.373 90.195-114.846.928-3.803 16.311-.744 22.135 7.762C377.641 408.528 391 500 391 500H109Z" />
      ),
      basic: {
        dark: (
          <path d="M116.893 511.008s-1.431-69.118 4.076-88.735c5.507-19.617-19.908 38.694-19.908 38.694s4.04 45.822 15.832 50.041Zm266.214 0s1.431-69.118-4.076-88.735c-5.507-19.617 19.908 38.694 19.908 38.694s-4.04 45.822-15.832 50.041Z" />
        ),
        light: (
          <path d="M154.314 373.19h191.372c5.396-4.818-14.137 65.152-45.862 96.238-5.629 5.515-37.718 24.592-47.849 30.869-.238.604-3.712.604-3.95 0-10.131-6.277-42.22-25.354-47.849-30.869-31.725-31.086-51.258-101.056-45.862-96.238Z" />
        ),
      },
    },
    base_body_shape_0424n: {
      ...vest_v6KKbrqtYb_AC,
    },
    // Body 4
    base_body_shape_fds8g: {
      base: (
        <path d="M109 500s5.294-84.99 24.206-103.858c2.416-2.411 22.683-6.905 23.756-4.725 22.418 45.559 44.176 73.804 72.016 90.516l.016-.153A448.902 448.902 0 0 0 250 493.685a448.902 448.902 0 0 0 21.006-11.905l.016.153c27.84-16.712 47.083-45.774 69.501-91.333 1.073-2.18 24.857 3.296 27.273 5.706C386.709 415.174 391 500 391 500H109Z" />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
        ),
        light: (
          <path d="M154.304 385.219h189.16c5.334-4.364-13.974 52.589-45.332 80.743-5.563 4.996-37.282 28.693-47.296 34.378-.235.547-3.669.547-3.904 0-10.014-5.685-41.733-29.382-47.296-34.378-31.359-28.154-50.666-85.107-45.332-80.743Z" />
        ),
      },
    },
    base_body_shape_sg894: {
      base: (
        <path d="M109 500s6.434-98.973 25.346-117.841c2.416-2.41 18.495-5.735 19.568-3.555 28.102 57.11 62.422 97.1 95.866 114.964l.22.117.22-.117c33.444-17.864 66.987-58.077 95.089-115.187 1.073-2.179 19.198 1.056 21.614 3.466C385.835 400.715 391 500 391 500H109Z" />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
        ),
        light: (
          <path d="M151.589 373.19h196.822c5.55-4.818-14.54 52.449-47.168 83.535-5.789 5.515-38.792 37.295-49.212 43.572-.245.604-3.817.604-4.062 0-10.42-6.277-43.423-38.057-49.212-43.572-32.628-31.086-52.718-88.353-47.168-83.535Z" />
        ),
      },
    },
    base_body_shape_fa84m: {
      base: (
        <path d="M109 500s5.235-82.212 24.147-101.08c2.416-2.411 23.574-7.115 24.646-4.935 22.419 45.559 43.345 71.236 71.185 87.948l.016-.153A448.902 448.902 0 0 0 250 493.685a448.902 448.902 0 0 0 21.006-11.905l.016.153c27.84-16.712 47.566-42.015 69.984-87.574 1.073-2.18 24.96 1.784 27.376 4.194C387.294 417.421 391 500 391 500H109Z" />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
        ),
        light: (
          <path d="M151.999 385.219h193.769c5.465-4.364-14.313 52.589-46.436 80.743-5.699 4.996-38.191 28.693-48.448 34.378-.241.547-3.759.547-4 0-10.258-5.685-42.749-29.382-48.448-34.378-32.123-28.154-51.901-85.107-46.437-80.743Z" />
        ),
      },
    },
  },
};

// V neck, wide straps

const vest_2qirGzw9UX_A = {
  base: (
    <path d="M108.097 510.546s8.101-82.045 26.466-101.446c5.261-5.558 44.683-18.019 45.395-11.022 6.292 61.82 20.278 77.689 48.117 94.401l.016-.153c7.105 4.287 14.194 8.281 21.006 11.905 6.812-3.624 13.902-7.618 21.007-11.905l.015.153c27.84-16.712 41.825-32.581 48.117-94.401.712-6.997 40.134 5.464 45.396 11.022 18.365 19.401 26.465 101.446 26.465 101.446h-282Z" />
  ),
  basic: {
    dark: (
      <path d="M114.649 521.554s-1.445-69.118 4.117-88.735c5.563-19.617-20.108 38.694-20.108 38.694s4.081 45.822 15.991 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
    ),
    light: (
      <path d="M170.611 390.488h154.74c4.364-4.563-4.136 58.648-28.042 84.14-11.919 12.709-39.539 30.295-47.731 36.239-.192.572-3.001.572-3.194 0-8.191-5.944-35.378-24.502-43.951-33.469-30.794-32.208-36.185-91.473-31.822-86.91Z" />
    ),
  },
};

const vest_2qirGzw9UX_B = {
  base: (
    <path d="M249.318 503.848c.817-.436 1.632-.777 2.442-1.036 33.039-17.581 68.201-51.47 67.543-120.034-.056-5.793 44.292 11.623 46.708 14.033 18.912 18.868 24.086 113.735 24.086 113.735s-86.301-.046-141-.128c-54.699.082-141 .128-141 .128s5.175-94.867 24.087-113.735c2.416-2.41 46.764-19.826 46.708-14.033-.659 68.564 34.503 102.453 67.543 120.034.81.259 1.625.6 2.442 1.036l.22.118.221-.118Z" />
  ),
  basic: {
    dark: (
      <path d="M114.649 521.554s-1.445-69.118 4.117-88.735c5.563-19.617-20.108 38.694-20.108 38.694s4.081 45.822 15.991 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
    ),
    light: (
      <path d="M170.611 376.8h154.74c4.364-5.08-4.136 65.294-28.042 93.673-11.919 14.149-39.539 33.728-47.731 40.345-.192.637-3.001.637-3.194 0-8.191-6.617-35.378-27.278-43.951-37.261-30.794-35.857-36.185-101.837-31.822-96.757Z" />
    ),
  },
};

const vest_2qirGzw9UX_C = {
  base: (
    <path d="M108.097 510.546s5.207-81.255 24.034-100.208c4.758-4.789 48.448-16.92 49.173-10.969 6.441 52.929 18.932 76.398 46.771 93.11l.016-.153c6.661 4.019 13.308 7.78 19.726 11.221l1.28.684 1.281-.684c6.418-3.441 13.065-7.202 19.726-11.221l.015.153c27.84-16.712 40.331-40.181 46.772-93.11.724-5.951 44.415 6.18 49.172 10.969 18.827 18.953 24.034 100.208 24.034 100.208h-282Z" />
  ),
  basic: {
    dark: (
      <path d="M114.649 521.554s-1.445-69.118 4.117-88.735c5.563-19.617-20.108 38.694-20.108 38.694s4.081 45.822 15.991 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
    ),
    light: (
      <path d="M173.203 390.398h149.548c4.218-4.567-3.997 58.692-27.101 84.202-11.519 12.719-38.212 30.318-46.129 36.267-.186.572-2.901.572-3.087 0-7.917-5.949-34.191-24.521-42.477-33.495-29.76-32.231-34.971-91.541-30.754-86.974Z" />
    ),
  },
};

const vest_2qirGzw9UX = {
  id: "vest_2qirGzw9UX",
  name: "V neck vest - wide straps",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1
    base_body_shape_lcUGV: {
      ...vest_2qirGzw9UX_A,
    },
    base_body_shape_taw74: {
      ...vest_2qirGzw9UX_B,
    },
    base_body_shape_jnr29: {
      ...vest_2qirGzw9UX_C,
    },
    // Body 2
    base_body_shape_g825h: {
      ...vest_2qirGzw9UX_A,
    },
    base_body_shape_fa048: {
      ...vest_2qirGzw9UX_B,
    },
    base_body_shape_mge5o: {
      ...vest_2qirGzw9UX_C,
    },
    // Body 3
    base_body_shape_gj245: {
      ...vest_2qirGzw9UX_A,
    },
    base_body_shape_942n5: {
      base: (
        <path d="M249.097 503.657c.693-.346 1.384-.625 2.072-.845 33.04-17.581 70.81-54.29 70.151-122.854-.056-5.793 41.684 14.443 44.1 16.853 18.912 18.868 24.086 113.735 24.086 113.735s-88.727-.053-140.409-.075c-51.681.022-140.409.075-140.409.075s5.174-94.867 24.087-113.735c2.416-2.41 44.155-22.646 44.1-16.853-.659 68.564 37.111 105.273 70.15 122.854.688.22 1.379.499 2.072.845Z" />
      ),
      basic: {
        dark: (
          <path d="M114.649 521.554s-1.445-69.118 4.117-88.735c5.563-19.617-20.108 38.694-20.108 38.694s4.081 45.822 15.991 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
        ),
        light: (
          <path d="M170.611 376.8h154.74c4.364-5.08-4.136 65.294-28.042 93.673-11.919 14.149-39.539 33.728-47.731 40.345-.192.637-3.001.637-3.194 0-8.191-6.617-35.378-27.278-43.951-37.261-30.794-35.857-36.185-101.837-31.822-96.757Z" />
        ),
      },
    },
    base_body_shape_0424n: {
      ...vest_2qirGzw9UX_C,
    },
    // Body 4
    base_body_shape_fds8g: {
      base: (
        <path d="M250 493.685a448.902 448.902 0 0 0 21.006-11.905l.016.153c27.84-16.712 41.827-35.852 48.119-97.671.712-6.997 40.134 5.464 45.395 11.022C382.901 414.685 391 500 391 500H109s8.099-85.315 26.464-104.716c5.261-5.558 44.683-18.019 45.395-11.022 6.292 61.819 20.279 80.959 48.119 97.671l.016-.153A448.902 448.902 0 0 0 250 493.685Z" />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
        ),
        light: (
          <path d="M171.514 379.309h154.74c4.363-4.587-4.137 58.956-28.042 84.58-11.919 12.776-39.54 30.454-47.731 36.43-.193.575-3.001.575-3.194 0-8.192-5.976-35.378-24.631-43.951-33.645-30.794-32.376-36.186-91.952-31.822-87.365Z" />
        ),
      },
    },
    base_body_shape_sg894: {
      base: (
        <path d="M253.279 491.936c32.864-17.74 68.051-57.007 66.408-125.13-.185-7.666 43.252 11.837 45.668 14.247C384.267 399.921 391 500 391 500s-85.408-.031-140.705-.046c-55.296.015-140.704.046-140.704.046s6.733-100.079 25.645-118.947c2.416-2.41 45.852-21.913 45.667-14.247-1.643 68.123 33.545 107.39 66.409 125.13.543.084.951.194 1.207.33.59.188 1.183.421 1.776.702a14.324 14.324 0 0 1 1.777-.702c.256-.136.664-.246 1.207-.33Z" />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
        ),
        light: (
          <path d="M171.514 360.182h154.74c4.363-5.363-4.137 68.938-28.042 98.901-11.919 14.939-39.54 35.61-47.731 42.597-.193.673-3.001.673-3.194 0-8.192-6.987-35.378-28.801-43.951-39.341-30.794-37.858-36.186-107.52-31.822-102.157Z" />
        ),
      },
    },
    base_body_shape_fa84m: {
      base: (
        <path d="M109 500s5.207-81.255 24.034-100.208c4.757-4.789 48.448-16.92 49.172-10.969 6.441 52.929 18.932 76.398 46.772 93.11l.016-.153a448.485 448.485 0 0 0 19.726 11.221l1.28.684 1.28-.684a448.485 448.485 0 0 0 19.726-11.221l.016.153c27.84-16.712 40.331-40.181 46.772-93.11.724-5.951 44.415 6.18 49.172 10.969C385.793 418.745 391 500 391 500H109Z" />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Z" />
        ),
        light: (
          <path d="M174.106 379.851h149.548c4.217-4.566-3.997 58.693-27.101 84.203-11.519 12.719-38.213 30.318-46.129 36.267-.187.572-2.901.572-3.087 0-7.917-5.949-34.191-24.521-42.477-33.495-29.761-32.231-34.971-91.541-30.754-86.975Z" />
        ),
      },
    },
  },
};

export const VestsPack3 = {
  vest_6tViT6LcpK,
  vest_v6KKbrqtYb,
  vest_2qirGzw9UX,
};

import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { FeaturesList } from "feature-voting/utilities/getFeatures";
import UnauthorisedPage from "./Unauthorised";
import TabbedPanelContainer from "character-creator/menu/elements/TabbedPanelContainer";

const PageContent = (props) => {
  if (!!props.user) {
    return (
      <>
        <section className="panel feature-panel">
          <h2>Feature requests</h2>
          <p>
            Character.Art is driven by what you want to see - have a look at
            what other people have suggested, vote and comment on your
            favourites or submit your own ideas.
          </p>
          <p>
            <strong>
              All submissions will be reviewed before being published.
            </strong>{" "}
            This is to help prevent anything harmful, inappropriate etc from
            being immediately visible on the website.
          </p>
        </section>
        <section className="panel feature-panel">
          <nav className="nav-primary nav-inline">
            <ul role="tablist">
              <li role="tab">All</li>
              <li role="tab">Features</li>
              <li role="tab">Items</li>
              <li role="tab">In Progress</li>
              <li role="tab">Complete</li>
            </ul>
          </nav>
          <FeaturesList />
        </section>
      </>
    );
  } else {
    return <UnauthorisedPage />;
  }
};

const FeatureVotingPage = (props) => {
  const [currentUser, setCurrentUser] = useState(auth.currentUser);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  }, []);

  return <PageContent user={currentUser} />;
};

export default FeatureVotingPage;

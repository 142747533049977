import React from "react";
import { connect } from "react-redux";
import ItemComponent from "character-creator/components/ItemComponent";
import { hairSideListLeft } from "./Left/HairSideListLeft";
import { hairSideListRight } from "./Right/HairSideListRight";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import { filterListById } from "character-creator/menu/menu-helper";
import HairColours from "character-creator/colours/hair";

function mapStateToProps(state, ownProps) {
  const hairConfig = getCharacterConfigValue(state, "hair.side");

  const defaultHairColour =
    getCharacterConfigValue(state, "hair.colour") ?? HairColours[0];

  const hairSideColour = hairConfig?.colour
    ? hairConfig.colour
    : defaultHairColour;

  ////////////////////////
  // Left
  ////////////////////////
  const hairConfigLeft = hairConfig?.left;
  let hairObjectLeft = null;
  if (ownProps.preview && ownProps.hair.shape) {
    hairObjectLeft = ownProps.hair.shape;
  } else if (hairConfigLeft?.shape && hairConfigLeft.shape !== "0") {
    hairObjectLeft = filterListById(
      hairSideListLeft,
      hairConfigLeft.shape
    ).shape;
  }
  const coloursLeft = hairConfigLeft?.colour
    ? hairConfigLeft.colour
    : hairSideColour;

  ////////////////////////
  // Right
  ////////////////////////
  const hairConfigRight = hairConfig?.right;
  let hairObjectRight = null;
  if (ownProps.preview && ownProps.hair.shape) {
    hairObjectRight = ownProps.hair.shape;
  } else if (hairConfigRight?.shape && hairConfigRight.shape !== "0") {
    hairObjectRight = filterListById(
      hairSideListRight,
      hairConfigRight.shape
    ).shape;
  }
  const coloursRight = hairConfigRight?.colour
    ? hairConfigRight.colour
    : hairSideColour;

  return {
    left: {
      shape: hairObjectLeft,
      colour: coloursLeft,
    },
    right: {
      shape: hairObjectRight,
      colour: coloursRight,
    },
  };
}

const CharacterHairSide = (props) => {
  const { left, right, previewId } = props;

  if (left.shape || right.shape) {
    return (
      <g id={previewId ? `g-hair-side~${previewId}` : "g-hair-side"}>
        {left.shape && (
          <ItemComponent
            id={previewId ? `hair_side_left~${previewId}` : "hair_side_left"}
            component={left}
            colour={left.colour}
            preview={previewId ? true : false}
          />
        )}
        {right.shape && (
          <ItemComponent
            id={previewId ? `hair_side_right~${previewId}` : "hair_side_right"}
            component={right}
            colour={right.colour}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(CharacterHairSide);

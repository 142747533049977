import React from "react";
import CharacterHead from "character-creator/components/head/CharacterHead";
import Item from "character-creator/components/Item";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  const previewItem = { ...component, shape: { ...component.shape["left"] } };

  return (
    <>
      {/* <CharacterHead preview={true} /> */}
      {/* <CharacterFace
      /> */}
      <Item id={"iris-outer"} previewId={previewId} previewItem={previewItem} />
    </>
  );
};

export const viewbox = "170 205 80 80";

import React, { useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import PaletteEditor from "./PaletteEditor";
import { ControlOptionsList } from "character-creator/menu/menu-helper";
import {
  getCharacterConfigValue,
  getColourConfigValue,
} from "character-creator/utilities/storeHelpers";

const mapStateToProps = (state, ownProps) => {
  const dataAttributeValue = getCharacterConfigValue(
    state,
    ownProps.control.dataAttribute[0]
  );
  const selectedOption = ownProps.control.attributeType
    ? dataAttributeValue?.id
    : dataAttributeValue;
  return {
    dataAttributeValue: dataAttributeValue,
    selectedOption: selectedOption ? selectedOption : ownProps.selectedOption,
    recentColours: getColourConfigValue(state, "recentColours"),
    customColours: getColourConfigValue(state, "customPalettes"),
    onChangeFunction: ownProps.onChangeFunction,
  };
};

const ColourControls = (props) => {
  const {
    control,
    selectedOption,
    recentColours,
    customColours,
    onChangeFunction,
  } = props;

  const [showPaletteEditor, togglePaletteEditor] = useState(false);

  // If colour control then custom colours too
  const customColoursControl = ControlOptionsList({
    controlName: control.id,
    dataAttribute: control.dataAttribute,
    selected: selectedOption,
    previewType: control.previewType,
    previewSide: control.previewSide,
    attributeType: control.attributeType,
    options: customColours,
    onChangeFunction,
  });

  // Custom colours section
  const CustomColoursSection = (
    <>
      <div className="header">
        <h4>Custom</h4>
        <div className="actions">
          <button
            className="button-actions"
            onClick={() => togglePaletteEditor(true)}
          >
            Add new
          </button>
        </div>
      </div>
      {customColours.length > 0 ? (
        <ul>{customColoursControl}</ul>
      ) : (
        <p>
          None yet - try creating a custom palette using the "Add new" button to
          the right.
        </p>
      )}

      {showPaletteEditor && (
        <PaletteEditor onCloseAction={togglePaletteEditor} />
      )}
    </>
  );

  // If colour control then recent colours too
  const recentColoursControl =
    recentColours &&
    recentColours.length > 0 &&
    ControlOptionsList({
      controlName: control.id,
      dataAttribute: control.dataAttribute,
      selected: selectedOption,
      previewType: control.previewType,
      previewSide: control.previewSide,
      attributeType: control.attributeType,
      options: recentColours,
      onChangeFunction,
    });

  // Recent colours section
  const RecentColoursSection = !!recentColours && recentColours.length > 0 && (
    <>
      <div className="header">
        <h4>Recent</h4>
      </div>
      <p>Your five most recent colours will be saved here for easy use.</p>

      <ul>{recentColoursControl}</ul>
    </>
  );

  return (
    <>
      {RecentColoursSection}
      {CustomColoursSection}
      <div className="header">
        <h4>Premade</h4>
      </div>
      <p>Here are some pre-made palettes for your items.</p>
    </>
  );
};

export default connect(mapStateToProps)(ColourControls);

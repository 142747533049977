// import React from 'react'

import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { eyebrowsPack1 } from "./packs/EyebrowsPack1";
import { eyebrowsPack2 } from "./packs/EyebrowsPack2";
import { eyebrowsPack3 } from "./packs/EyebrowsPack3";
import { EyebrowsPack4 } from "./packs/EyebrowsPack4";

const eyebrowList = Object.values({
  "0": BlankListOption,
  ...eyebrowsPack1,
  ...eyebrowsPack2,
  ...eyebrowsPack3,
  ...EyebrowsPack4,
});

export default eyebrowList;

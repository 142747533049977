import {
  EyeballColours,
  IrisColours,
  pupilColours,
  ShineColours,
} from "character-creator/colours/eye";
import {
  buildTransform,
  filterListById,
  translateTransformObject,
} from "character-creator/menu/menu-helper";
import { getCharacterConfigValue } from "character-creator/utilities/storeHelpers";
import React from "react";
import { connect } from "react-redux";
import { EyeShapesListArray } from "./shapes/EyeShapeList";
import { undereyeShapeList } from "../../markings/undereye/UndereyeList";
import Item from "character-creator/components/Item";
import { irisInnerShapeList } from "./irises/inner/IrisInnerShapeList";
import { irisOuterShapeList } from "./irises/outer/IrisOuterShapeList";
import { pupilShapeList } from "./pupils/PupilShapeList";
import { shineShapeList } from "./shine/ShineShapeList";
import ItemComponent from "character-creator/components/ItemComponent";
import {
  eyelashShapeListLower,
  eyelashShapeListUpper,
} from "./eyelashes/EyelashShapeList";
import { allColours } from "character-creator/colours/colourSchemes";

function mapStateToProps(state, ownProps) {
  // Used for eyelid colours
  const headColour = getCharacterConfigValue(state, "head.colour")
    ? getCharacterConfigValue(state, "head.colour")
    : getCharacterConfigValue(state, "skintone");
  const eyeState = getCharacterConfigValue(state, "eyes");

  const defaultEyeballColour = filterListById(
    EyeballColours,
    "colour_eyeball_29JeE"
  );

  const leftShape = eyeState?.base?.left?.shape
    ? filterListById(EyeShapesListArray, eyeState.base.left.shape).shape.left
    : null;

  const rightShape = eyeState?.base?.right?.shape
    ? filterListById(EyeShapesListArray, eyeState.base.right.shape).shape.right
    : null;

  ////////////////////////////////
  // Left
  const left = leftShape
    ? constructEyeConfig(
        eyeState,
        leftShape,
        "left",
        defaultEyeballColour,
        headColour
      )
    : null;

  ////////////////////////////////
  // Right
  const right = rightShape
    ? constructEyeConfig(
        eyeState,
        rightShape,
        "right",
        defaultEyeballColour,
        headColour
      )
    : null;

  return {
    left,
    right,
  };
}

const constructEyeConfig = (
  eyeState,
  baseShape,
  side,
  defaultEyeballColour,
  headColour
) => {
  return {
    ////////////////////////////////
    // Base
    base: {
      shape: { base: baseShape.base },
      colour: eyeState.base[side].colour?.eyeball ?? defaultEyeballColour,
    },
    eyelid: {
      upper: {
        shape: baseShape.eyelid?.top,
        colour: eyeState.base[side].colour?.eyelid ?? {
          base: headColour.light,
        },
      },
      lower: {
        shape: baseShape.eyelid?.bottom,
        colour: { base: headColour.dark },
      },
    },
    eyelidShadow: {
      shape: baseShape.eyelidShadow,
      colour: eyeState.base[side].colour?.eyeball
        ? { base: eyeState.base[side].colour?.eyeball.dark }
        : { base: defaultEyeballColour.dark },
    },
    ////////////////////////////////
    // Undereye
    undereye: {
      shape:
        eyeState.undereye && eyeState.undereye[side]?.shape
          ? filterListById(undereyeShapeList, eyeState.undereye[side].shape)
              .shape[eyeState.base[side].shape][side]
          : null,
      colour:
        eyeState.undereye && eyeState.undereye[side]?.colour
          ? eyeState.undereye[side]?.colour
          : { base: headColour.dark },
    },
    ////////////////////////////////
    // Iris
    iris: {
      // Inner
      inner: {
        shape:
          eyeState.iris?.inner && eyeState.iris?.inner[side]?.shape
            ? filterListById(
                irisInnerShapeList,
                eyeState.iris.inner[side].shape
              ).shape[side]
            : null,
        colour:
          eyeState.iris?.inner && eyeState.iris?.inner[side]?.colour
            ? eyeState.iris?.inner[side]?.colour
            : IrisColours[0],
      },
      // Outer
      outer: {
        shape:
          eyeState.iris?.outer && eyeState.iris?.outer[side]?.shape
            ? filterListById(
                irisOuterShapeList,
                eyeState.iris.outer[side].shape
              ).shape[side]
            : null,
        colour:
          eyeState.iris?.outer && eyeState.iris?.outer[side]?.colour
            ? eyeState.iris?.outer[side]?.colour
            : IrisColours[1],
      },
      transform: eyeState.iris?.transform
        ? buildTransform(eyeState.iris.transform)
        : "",
    },
    ////////////////////////////////
    // Pupil
    pupil: {
      shape:
        eyeState.pupil && eyeState.pupil[side]?.shape
          ? filterListById(pupilShapeList, eyeState.pupil[side].shape).shape[
              side
            ]
          : null,
      colour:
        eyeState.pupil && eyeState.pupil[side]?.colour
          ? eyeState.pupil[side]?.colour
          : pupilColours[0],
      transform:
        eyeState.iris?.transform || eyeState.pupil?.transform
          ? buildTransform({
              ...eyeState.iris?.transform,
              ...eyeState.pupil?.transform,
            })
          : null,
    },
    ////////////////////////////////
    // Shine
    shine: {
      shape:
        eyeState.shine && eyeState.shine[side]?.shape
          ? filterListById(shineShapeList, eyeState.shine[side].shape).shape[
              side
            ]
          : null,
      colour:
        eyeState.shine && eyeState.shine[side]?.colour
          ? eyeState.shine[side]?.colour
          : filterListById(ShineColours, "colour_metal_4K6zI"),
      transform:
        eyeState.iris?.transform || eyeState.shine?.transform
          ? buildTransform({
              ...eyeState.iris?.transform,
              ...eyeState.shine?.transform,
            })
          : null,
    },
    ////////////////////////////////
    // Eyelashes
    eyelashes: {
      // Upper
      upper: {
        shape:
          eyeState.eyelashes && eyeState.eyelashes[side].upper?.shape
            ? filterListById(
                eyelashShapeListUpper,
                eyeState.eyelashes[side].upper.shape
              ).shape[eyeState.base[side].shape][side].upper
            : null,
        colour:
          eyeState.eyelashes && eyeState.eyelashes[side].upper?.colour
            ? eyeState.eyelashes[side].upper.colour
            : allColours[0],
      },
      // Lower
      lower: {
        shape:
          eyeState.eyelashes && eyeState.eyelashes[side].lower?.shape
            ? filterListById(
                eyelashShapeListLower,
                eyeState.eyelashes[side].lower.shape
              ).shape[eyeState.base[side].shape][side].lower
            : null,
        colour:
          eyeState.eyelashes && eyeState.eyelashes[side].lower?.colour
            ? eyeState.eyelashes[side].lower.colour
            : allColours[0],
      },
    },
    ////////////////////////////////
    // Transform
    transform: eyeState.base[side].transform
      ? translateTransformObject(buildTransform(eyeState.base[side].transform))
      : "",
  };
};

const constructEyeElement = (eyeConfig, id) => {
  const blendMultiply = { mixBlendMode: "multiply" };
  return (
    <g id={`g-eye-${id}`} transform-origin="50% 50%" {...eyeConfig.transform}>
      {/* undereye */}
      {eyeConfig.undereye?.shape && (
        <ItemComponent
          id={`eye-${id}-undereye`}
          component={eyeConfig.undereye}
          colour={eyeConfig.undereye.colour}
        />
      )}
      {/* main shape */}
      <ItemComponent
        id={`eye-${id}-base`}
        component={eyeConfig.base}
        colour={eyeConfig.base.colour}
      />
      {/* shadow */}
      {eyeConfig.eyelidShadow?.shape && (
        <ItemComponent
          id={`eye-${id}-base-shadow`}
          component={eyeConfig.eyelidShadow}
          colour={eyeConfig.eyelidShadow.colour}
          maskId={`eye-${id}-base_mask`}
          style={{ opacity: "0.5", ...blendMultiply }}
        />
      )}

      {/* iris */}
      <g
        id={`g-iris-${id}`}
        transform-origin="50% 50%"
        // {...eyeConfig.iris?.transform}
        style={{ mask: `url(#eye-${id}-base_mask)` }}
      >
        {eyeConfig.iris?.outer?.shape && (
          <ItemComponent
            id={`eye-${id}-iris-outer`}
            component={eyeConfig.iris.outer}
            colour={eyeConfig.iris.outer.colour}
            maskId={`eye-${id}-base_mask`}
            transform={eyeConfig.iris?.transform}
          />
        )}
        {eyeConfig.iris?.inner?.shape && (
          <ItemComponent
            id={`eye-${id}-iris-inner`}
            component={eyeConfig.iris.inner}
            colour={eyeConfig.iris.inner.colour}
            maskId={`eye-${id}-iris-outer_mask`}
            transform={eyeConfig.iris?.transform}
          />
        )}
        {/* pupil */}
        {eyeConfig.pupil?.shape && (
          <ItemComponent
            id={`eye-${id}-pupil`}
            component={eyeConfig.pupil}
            colour={eyeConfig.pupil.colour}
            maskId={`eye-${id}-iris-outer_mask`}
            transform={eyeConfig.pupil?.transform}
          />
        )}
        {/* shine */}
        {eyeConfig.shine?.shape && (
          <ItemComponent
            id={`eye-${id}-shine`}
            component={eyeConfig.shine}
            colour={eyeConfig.shine.colour}
            transform={eyeConfig.shine?.transform}
          />
        )}
      </g>

      {/* iris shadow */}
      {eyeConfig.eyelidShadow?.shape && (
        <g style={{ mask: `url(#eye-${id}-base_mask)` }}>
          <ItemComponent
            id={`eye-${id}-iris-shadow`}
            component={eyeConfig.eyelidShadow}
            colour={{ base: eyeConfig.iris.outer.colour.dark }}
            maskId={`eye-${id}-iris-outer_mask`}
            style={{ opacity: "0.5", ...blendMultiply }}
          />
        </g>
      )}

      {/* eyelids */}
      {eyeConfig.eyelid?.lower?.shape && (
        <ItemComponent
          id={`eye-${id}-eyelid-lower`}
          component={eyeConfig.eyelid.lower}
          colour={eyeConfig.eyelid.lower.colour}
        />
      )}
      {eyeConfig.eyelid?.upper?.shape && (
        <ItemComponent
          id={`eye-${id}-eyelid-upper`}
          component={eyeConfig.eyelid.upper}
          colour={eyeConfig.eyelid.upper.colour}
        />
      )}
      {/* eyelash */}
      {eyeConfig.eyelashes?.lower?.shape && (
        <ItemComponent
          id={`eye-${id}-eyelashes-lower`}
          component={eyeConfig.eyelashes.lower}
          colour={eyeConfig.eyelashes.lower.colour}
        />
      )}
      {eyeConfig.eyelashes?.upper?.shape && (
        <ItemComponent
          id={`eye-${id}-eyelashes-upper`}
          component={eyeConfig.eyelashes.upper}
          colour={eyeConfig.eyelashes.upper.colour}
        />
      )}
    </g>
  );
};

const Eyes = (props) => {
  const { left, right } = props;

  if (left?.base?.shape || right?.base?.shape) {
    return (
      <g id="g-Eyes">
        {left.base?.shape && constructEyeElement(left, "left")}

        {right.base?.shape && constructEyeElement(right, "right")}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Eyes);
